import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Card, Typography } from 'src/components/common';
import { ideaHubDashboard } from 'src/assets/images';
import { BulbIcon, NoteIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';

import './dashboardCard.scss';
import { TIdeaHubTabs } from '../ideaHub.types';

const DashboardCard = () => {
  // Hooks
  const navigate = useNavigate();

  const goToIdeaHub = () => {
    const tabName: TIdeaHubTabs = 'myIdea';
    navigate(`/idea-hub?tab=${tabName}`);
  };

  const goToResources = () => {
    navigate('/resources');
  };

  return (
    <Card className="idea-hub-dashboard-card">
      <img
        src={ideaHubDashboard}
        alt="image of a person climbing stairs shaped like a bar chart"
        lang="lazy"
      />
      <div className="idea-hub-dashboard-card__right">
        <Typography
          as="span"
          variant="body-2"
          fontWeight="semiBold"
          className="idea-hub-dashboard-card__right__heading"
        >
          Craft your{' '}
          <Typography
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="idea-hub-dashboard-card__right__heading--highlight"
          >
            ideas
          </Typography>
          .Take a{' '}
          <Typography
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="idea-hub-dashboard-card__right__heading--highlight"
          >
            collaborative
          </Typography>{' '}
          journey. Explore our curated{' '}
          <Typography
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="idea-hub-dashboard-card__right__heading--highlight"
          >
            resource.
          </Typography>{' '}
          It is important to take steps and keep moving
        </Typography>
        <div className="idea-hub-dashboard-card__right__buttons">
          <Button variant="primary" size="small" onClick={goToIdeaHub}>
            <BulbIcon />
            <Typography as="span" variant="body-2">
              Idea Hub
            </Typography>
          </Button>
          <Button variant="secondary" size="small" onClick={goToResources}>
            <NoteIcon color={colors.primary[600]} />
            <Typography as="span" variant="body-2">
              Resource Hub
            </Typography>
          </Button>
        </div>
      </div>
    </Card>
  );
};

export default DashboardCard;
