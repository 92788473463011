import { useEffect, useState } from 'react';
import { InfiniteScroll, Loader, Separator, SubMenuModal, Typography } from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { TSearchCollaborator, TSearchEvaluatorsRequest } from 'src/services/apiEndpoint.types.ts';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  incrementSearchEvaluatorsPage,
  resetSearchEvaluators,
  searchEvaluators
} from 'src/store/ideaHub/ideaHub.slice';
import useDebounce from 'src/hooks/useDebounce/useDebounce';

import './searchAndSelectEvaluators.scss';

import SearchUserResult from '../../searchUserResult/SearchUserResult';
import SelectedEvaluator from '../selectedEvaluator/SelectedEvaluator';
import { TSelectedEvaluator } from '../ShareToEvaluate';

const searchUsersResultModalId = 'searchUsersResultModal';

export type TSearchAndSelectEvaluatorsProps = {
  ideaId: string;
  selectedEvaluators: TSelectedEvaluator[];
  updateSelectedUsers: ({ userId }: { userId: string }) => void;
};

const SearchAndSelectEvaluators = ({
  ideaId,
  selectedEvaluators,
  updateSelectedUsers
}: TSearchAndSelectEvaluatorsProps) => {
  const [searchInput, setSearchInput] = useState<string>('');

  // Hooks
  const dispatch = useAppDispatch();
  const { dropdownId }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const {
    searchEvaluatorsList,
    searchEvaluatorsPageNumber,
    searchUserLoading,
    searchEvaluatorsItemsPerPage,
    searchEvaluatorsMoreItemsLeft
  } = useAppSelector((store) => store.ideaHub);

  const openSearchUsersResultModal = dropdownId === searchUsersResultModalId;

  const fetchUsers = ({ resetUsers = false }: { resetUsers: boolean }) => {
    if (resetUsers) {
      dispatch(resetSearchEvaluators());
    }
    if (searchInput) {
      const payload: TSearchEvaluatorsRequest = {
        filterString: searchInput,
        offset: searchEvaluatorsPageNumber,
        limit: searchEvaluatorsItemsPerPage,
        ideaId
      };
      dispatch(searchEvaluators(payload));
    } else {
      dispatch(resetSearchEvaluators());
    }
  };

  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchInput
  });

  useEffect(() => {
    fetchUsers({ resetUsers: false });
  }, [searchEvaluatorsPageNumber]);

  const loadMoreItems = () => {
    if (searchEvaluatorsMoreItemsLeft && !searchUserLoading) {
      dispatch(incrementSearchEvaluatorsPage(1));
    }
  };

  return (
    <div className="search-select-evaluators">
      <div className="search-select-evaluators__search-users">
        <InputField
          label="Search and select mentors and investors from whom you want to get your idea evaluated"
          id="email"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Search user by name, email address"
          startIcon={<SearchIcon />}
          type="text"
          value={searchInput}
          onChange={(e) => {
            setSearchInput(e.target.value);
          }}
          onClick={(e) => {
            e.stopPropagation();
            dispatch(assignSubMenuModalId(searchUsersResultModalId));
          }}
        />
        {openSearchUsersResultModal && (
          <SubMenuModal>
            <div
              className="search-select-evaluators__search-users__container"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <Typography as="p" variant="caption" fontWeight="semiBold">
                Search for “{searchInput}”
              </Typography>
              <InfiniteScroll
                className="search-select-evaluators__search-users__container__result"
                onScrollEnd={loadMoreItems}
                loading={searchUserLoading}
                showLoader={false}
                moreItemsLeft={searchEvaluatorsMoreItemsLeft}
              >
                {searchEvaluatorsList?.length > 0 &&
                  searchEvaluatorsList.map(
                    ({ name = '', role = '', id, profilePic = '' }: TSearchCollaborator, index) => {
                      const selectIndex = selectedEvaluators.findIndex((user) => user.id === id);
                      return (
                        <SearchUserResult
                          key={index}
                          userId={id}
                          profilePic={profilePic}
                          name={name}
                          role={role}
                          updateSelectedUsers={updateSelectedUsers}
                          isUserSelected={selectIndex > -1}
                        />
                      );
                    }
                  )}
              </InfiniteScroll>

              {searchUserLoading && <Loader />}
            </div>
          </SubMenuModal>
        )}
      </div>
      <Separator />
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        Selected users
      </Typography>
      <div className="search-select-evaluators__selected-users">
        {selectedEvaluators.map(
          ({ name = '', role = '', email = '', id, profilePic = '' }, index) => {
            return (
              <SelectedEvaluator
                key={index}
                profilePic={profilePic}
                name={name}
                role={role}
                email={email}
                handleRemoveUser={() => updateSelectedUsers({ userId: id })}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default SearchAndSelectEvaluators;
