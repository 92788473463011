import { useRef } from 'react';
import { useCalculateElementHeight } from 'src/hooks';
import Header from 'src/components/header/Header';
import { Typography } from 'src/components/common';
import { comingSoon } from 'src/assets/images';

import './resources.scss';

const Resources = () => {
  // Hooks
  const resourcesContentRef = useRef<HTMLDivElement>(null);

  const { elementHeight } = useCalculateElementHeight({
    ref: resourcesContentRef,
    triggers: [window.location.search],
    heightOffset: 64
  });

  return (
    <div className="resources">
      <Header />
      <div className="resources__body">
        <Typography
          variant="subHeading-2"
          fontWeight="semiBold"
          className="resources__body__heading"
        >
          Resources
        </Typography>

        <div
          className="resources__body__content"
          ref={resourcesContentRef}
          style={{ height: `${elementHeight}px` }}
        >
          <Typography variant="subHeading-1" fontWeight="semiBold">
            Stay Tuned: Startup News & Resources Coming Soon!
          </Typography>
          <img src={comingSoon} alt="coming soon" />
        </div>
      </div>
    </div>
  );
};

export default Resources;
