import linkifyStr from 'linkify-string';

export type TTransformTextToHyperlink = {
  text: string;
};

const transformTextToHyperlink = ({ text }: TTransformTextToHyperlink) => {
  try {
    const replacedText = linkifyStr(text, {
      className: 'social-media-hyperlink',
      target: '_blank'
    });
    return replacedText;
  } catch {
    return text;
  }
};

export default transformTextToHyperlink;
