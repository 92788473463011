import * as Yup from 'yup';
import { EMAIL } from 'src/constants/regex.constants';

const contactDetailsValidationSchema = Yup.object().shape({
  companyEmailAddress: Yup.string()
    .matches(EMAIL, 'Please enter a valid email address')
    .max(250, 'Email should not have more than 250 characters'),
  contactUsUrl: Yup.string().max(250, 'Contact us URL should not have more than 250 characters'),
  addressLine1: Yup.string().max(250, 'Address Line 1 should not have more than 250 characters'),
  addressLine2: Yup.string().max(250, 'Address Line 2 should not have more than 250 characters'),
  country: Yup.string().max(250, 'Country should not have more than 250 characters'),
  city: Yup.string().max(250, 'City should not have more than 250 characters'),
  zipCode: Yup.string().max(250, 'Zip code should not have more than 250 characters')
});

export default contactDetailsValidationSchema;
