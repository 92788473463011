import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PlusIconCircle = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M17.167 11V23M11.167 17H23.167M32.167 17C32.167 25.2843 25.4513 32 17.167 32C8.88272 32 2.16699 25.2843 2.16699 17C2.16699 8.71573 8.88272 2 17.167 2C25.4513 2 32.167 8.71573 32.167 17Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIconCircle;
