/* eslint-disable  multiline-ternary */
import { ReactNode, useContext, useMemo, useRef, useState } from 'react';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import { Card, LeftMenu, Typography } from 'src/components/common';
import { useAppDispatch } from 'src/store/hooks';
import { TCreateIdeaRequest, TEditIdeaRequest } from 'src/services/apiEndpoint.types.ts';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { createIdea, editIdea } from 'src/store/ideaHub/ideaHub.slice';
import { assignModalId } from 'src/store/common/common.slice';

import useAllowedActions from './ideaDetailsActions/useAllowedActions';
import './ideaDetails.scss';

import {
  TIdeaDetailsSections,
  TIdeaStatus,
  TIdeaCollaborationType,
  TIdeaViewType,
  TGetIdea,
  TEvaluatorResponse,
  TIdea,
  TIdeaCollaborator,
  TIdeaMarketDetailsData,
  TIdeaProblemsSolutionsData,
  TIdeaBusinessModelCompetitorsData,
  TIdeaSharedFor
} from '../ideaHub.types';
import { IdeaViewTypeContext } from '../ideaSkeleton/IdeaSkeleton';

import {
  Details,
  EditBusinessModelCompetitors,
  EditDetails,
  EditMarketDetails,
  EditProblemsSolutions,
  IdeaDetailsActions,
  IdeaDetailsSectionView,
  ViewRatings
} from '.';

export type TIdeaDetailsProps = {
  ideaStatus: TIdeaStatus;
  ideaCollaborationType: TIdeaCollaborationType;
  ideaData: TGetIdea;
  ratings: TEvaluatorResponse[];
  ideaSharedFor?: TIdeaSharedFor;
  ideaRatedByUser?: boolean;
};

export type TEditIdeaFormValues = {
  title?: string;
  domain?: string;
  description?: string;
  marketSize?: string;
  targetAudience?: string;
  marketTrends?: string;
  identifiedProblem?: string;
  solutionEffectiveness?: string;
  uniqueValueProposition?: string;
  majorRevenueStream?: string;
  scaliblityOfIdea?: string;
  competitors?: string;
  keyDifferentiatingFactor?: string;
};

const IdeaDetails = ({
  ideaStatus,
  ideaCollaborationType,
  ideaData,
  ratings,
  ideaSharedFor,
  ideaRatedByUser
}: TIdeaDetailsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();

  const { loggedInUserId } = getIdentities();
  const editIdeaType: 'newIdea' | 'draft' = paramValue({ paramName: 'editType' }) as
    | 'newIdea'
    | 'draft';
  const editIdeaId: string = paramValue({ paramName: 'id' }) as unknown as string;

  const { idea, collaborators }: { idea: TIdea; collaborators: TIdeaCollaborator[] } = ideaData;

  const {
    title,
    domain,
    description,
    owner,
    marketSize,
    targetAudience,
    marketTrends,
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition,
    majorRevenueStream,
    scaliblityOfIdea,
    competitors,
    keyDifferentiatingFactor
  } = idea;

  const [currentIdeaDetailsIndex, setCurrentIdeaDetailsIndex] = useState<number>(0);
  const [editIdeaFormValues, setEditIdeaFormValues] = useState<TEditIdeaFormValues>({
    title,
    domain,
    description,
    marketSize,
    targetAudience,
    marketTrends,
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition,
    majorRevenueStream,
    scaliblityOfIdea,
    competitors,
    keyDifferentiatingFactor
  });
  const submitButtonRef = useRef<FormikProps<TEditIdeaFormValues>>(null);

  const ideaSteps: TIdeaDetailsSections[] = [
    'details',
    'marketDetails',
    'problemsSolutions',
    'BusinessModelCompetitors'
  ];

  const marketDetails: TIdeaMarketDetailsData = {
    marketSize,
    targetAudience,
    marketTrends
  };
  const ideaProblemsSolutions: TIdeaProblemsSolutionsData = {
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition
  };
  const ideaBusinessModelCompetitors: TIdeaBusinessModelCompetitorsData = {
    majorRevenueStream,
    scaliblityOfIdea,
    competitors,
    keyDifferentiatingFactor
  };

  const ideaViewContext: TIdeaViewType = useContext(IdeaViewTypeContext);

  const updateFormValues = ({ values }: { values: TEditIdeaFormValues }) => {
    setEditIdeaFormValues((prev) => ({ ...prev, ...values }));
  };

  const goToNextTab = ({ fromEditDetails }: { fromEditDetails: boolean }) => {
    setCurrentIdeaDetailsIndex((prev) => {
      if (prev === 0 && !fromEditDetails) {
        handleNext();
        return prev;
      } else if (ideaSteps[prev + 1]) {
        return prev + 1;
      }
      return prev;
    });
  };

  const handleNext = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.handleSubmit();
    }
  };

  const { checkButtonsVisibility } = useAllowedActions({
    ideaStatus,
    ideaCollaborationType,
    currentIdeaDetailsSection: ideaSteps[currentIdeaDetailsIndex],
    ideaSharedFor,
    viewType: ideaViewContext
  });

  // This button is present in Idea hub -> Idea -> Idea details -> Edit details
  const { showShareManageAccessButtonsEditDetails } = checkButtonsVisibility();

  const handleDiscard = () => {
    if (submitButtonRef.current) {
      submitButtonRef.current.handleReset();
    }
    setEditIdeaFormValues({
      title: '',
      domain: '',
      description: '',
      marketSize: '',
      targetAudience: '',
      marketTrends: '',
      identifiedProblem: '',
      solutionEffectiveness: '',
      uniqueValueProposition: '',
      majorRevenueStream: '',
      scaliblityOfIdea: '',
      competitors: '',
      keyDifferentiatingFactor: ''
    });
    if (editIdeaType === 'newIdea') {
      navigate('/view-empty-idea?tab=ideaDetails');
      dispatch(assignModalId(''));
    } else {
      const payload: TEditIdeaRequest = {
        ideaId: editIdeaId,
        status: 'Discard',
        title: 'Title that best reflects your idea',
        domain: 'Select a specific domain your idea relates to',
        description: 'Elaborate on your idea with details.',
        marketSize: 'Describe size of the market for your idea.',
        targetAudience: 'Describe the specific demographic or group your idea.',
        marketTrends: 'Outline current and emerging trends relevant to your idea',
        identifiedProblem: 'Clearly state and elaborate on the problem your idea aims to address',
        solutionEffectiveness:
          'Explain how effective your proposed solution is in addressing the identified problem.',
        uniqueValueProposition: 'Describe the distinctive value your idea offers',
        majorRevenueStream:
          'Specify the primary source through which your business generates revenue',
        scaliblityOfIdea: 'Describe the potential for your idea to grow and expand',
        competitors: 'List and analyze the key competitors in your idea.',
        keyDifferentiatingFactor:
          'Highlight the unique feature or aspect that sets your idea apart from competitors.',
        discardIdea: true,
        userId: loggedInUserId
      };

      dispatch(editIdea(payload)).then(() => {
        navigate(`/view-idea?id=${editIdeaId}&tab=ideaDetails`);
      });
    }
  };

  const handleSave = () => {
    const {
      title,
      domain,
      description,
      marketSize,
      targetAudience,
      marketTrends,
      identifiedProblem,
      solutionEffectiveness,
      uniqueValueProposition,
      majorRevenueStream,
      scaliblityOfIdea,
      competitors,
      keyDifferentiatingFactor
    } = editIdeaFormValues;

    if (editIdeaType === 'newIdea') {
      const payload: TCreateIdeaRequest = {
        ownerId: loggedInUserId,
        title: title || 'Title that best reflects your idea',
        domain: domain || 'Select a specific domain your idea relates to',
        description: description || 'Elaborate on your idea with details.',
        marketSize: marketSize || 'Describe size of the market for your idea.',
        targetAudience: targetAudience || 'Describe the specific demographic or group your idea.',
        marketTrends: marketTrends || 'Outline current and emerging trends relevant to your idea',
        identifiedProblem:
          identifiedProblem ||
          'Clearly state and elaborate on the problem your idea aims to address',
        solutionEffectiveness:
          solutionEffectiveness ||
          'Explain how effective your proposed solution is in addressing the identified problem.',
        uniqueValueProposition:
          uniqueValueProposition || 'Describe the distinctive value your idea offers',
        majorRevenueStream:
          majorRevenueStream ||
          'Specify the primary source through which your business generates revenue',
        scaliblityOfIdea:
          scaliblityOfIdea || 'Describe the potential for your idea to grow and expand',
        competitors: competitors || 'List and analyze the key competitors in your idea.',
        keyDifferentiatingFactor:
          keyDifferentiatingFactor ||
          'Highlight the unique feature or aspect that sets your idea apart from competitors.'
      };

      dispatch(createIdea(payload));
    } else if (editIdeaId && editIdeaType === 'draft') {
      const payload: TEditIdeaRequest = {
        ideaId: editIdeaId,
        status: ideaStatus,
        userId: loggedInUserId,
        title: title || 'Title that best reflects your idea',
        domain: domain || 'Select a specific domain your idea relates to',
        description: description || 'Elaborate on your idea with details.',
        ...(domain && { domain }),
        ...(description && { description }),
        ...(marketSize && { marketSize }),
        ...(targetAudience && { targetAudience }),
        ...(marketTrends && { marketTrends }),
        ...(identifiedProblem && { identifiedProblem }),
        ...(solutionEffectiveness && { solutionEffectiveness }),
        ...(uniqueValueProposition && { uniqueValueProposition }),
        ...(majorRevenueStream && { majorRevenueStream }),
        ...(scaliblityOfIdea && { scaliblityOfIdea }),
        ...(competitors && { competitors }),
        ...(keyDifferentiatingFactor && { keyDifferentiatingFactor })
      };

      dispatch(editIdea(payload));
    }
  };

  const ideaDetailsSections: { key: TIdeaDetailsSections; label: string; component: ReactNode }[] =
    [
      {
        key: 'details',
        label: 'Details',
        component:
          ideaViewContext === 'editIdea' ? (
            <EditDetails
              title={editIdeaFormValues?.title}
              domain={editIdeaFormValues?.domain}
              description={editIdeaFormValues?.description}
              owner={idea?.owner}
              collaborators={collaborators}
              submitButtonRef={submitButtonRef}
              updateFormValues={updateFormValues}
              handleNext={handleNext}
              goToNextTab={goToNextTab}
              showShareManageAccessButtonsEditDetails={showShareManageAccessButtonsEditDetails}
            />
          ) : (
            <Details
              title={title}
              domain={domain}
              description={description}
              owner={owner}
              collaborators={collaborators}
            />
          )
      },
      {
        key: 'marketDetails',
        label: 'Market Details',
        component:
          ideaViewContext === 'editIdea' ? (
            <EditMarketDetails
              marketSize={editIdeaFormValues.marketSize}
              targetAudience={editIdeaFormValues.targetAudience}
              marketTrends={editIdeaFormValues.marketTrends}
              submitButtonRef={submitButtonRef}
              updateFormValues={updateFormValues}
              handleNext={handleNext}
              goToNextTab={goToNextTab}
            />
          ) : (
            <IdeaDetailsSectionView sectionTitle="Market Details" data={marketDetails} />
          )
      },
      {
        key: 'problemsSolutions',
        label: 'Problem & Solutions',
        component:
          ideaViewContext === 'editIdea' ? (
            <EditProblemsSolutions
              identifiedProblem={editIdeaFormValues.identifiedProblem}
              solutionEffectiveness={editIdeaFormValues.solutionEffectiveness}
              uniqueValueProposition={editIdeaFormValues.uniqueValueProposition}
              submitButtonRef={submitButtonRef}
              updateFormValues={updateFormValues}
              handleNext={handleNext}
              goToNextTab={goToNextTab}
            />
          ) : (
            <IdeaDetailsSectionView
              sectionTitle="Problem & Solutions"
              data={ideaProblemsSolutions}
            />
          )
      },
      {
        key: 'BusinessModelCompetitors',
        label: 'Business Model & Competitors',
        component:
          ideaViewContext === 'editIdea' ? (
            <EditBusinessModelCompetitors
              majorRevenueStream={editIdeaFormValues.majorRevenueStream}
              scaliblityOfIdea={editIdeaFormValues.scaliblityOfIdea}
              competitors={editIdeaFormValues.competitors}
              keyDifferentiatingFactor={editIdeaFormValues.keyDifferentiatingFactor}
              submitButtonRef={submitButtonRef}
              updateFormValues={updateFormValues}
            />
          ) : (
            <IdeaDetailsSectionView
              sectionTitle="Business Model & Competitors"
              data={ideaBusinessModelCompetitors}
            />
          )
      }
    ];

  if (ideaViewContext !== 'editIdea') {
    ideaDetailsSections.push({
      key: 'ideaRating',
      label: 'Idea Rating',
      component: <ViewRatings ratings={ratings} />
    });
    ideaSteps.push('ideaRating');
  }

  const currentIdeaDetailsComponent: ReactNode = useMemo(() => {
    try {
      const section = ideaDetailsSections[currentIdeaDetailsIndex];

      return section?.component;
    } catch {
      return <>-</>;
    }
  }, [currentIdeaDetailsIndex, JSON.stringify(ideaData), JSON.stringify(ratings)]);

  return (
    <div className="idea-details">
      <IdeaDetailsActions
        idea={idea}
        ideaStatus={ideaStatus}
        ideaCollaborationType={ideaCollaborationType}
        currentIdeaDetailsSection={ideaSteps[currentIdeaDetailsIndex]}
        ideaRatedByUser={ideaRatedByUser}
        submitButtonRef={submitButtonRef}
        ideaSharedFor={ideaSharedFor}
        handleNext={handleNext}
        handleSave={handleSave}
        handleDiscard={handleDiscard}
      />
      <div className="idea-details__sub-sections">
        <Card className="idea-details__sub-sections__menu">
          {ideaDetailsSections.map(({ label, key }, index) => {
            const isActive: boolean = ideaSteps[currentIdeaDetailsIndex] === key;

            return (
              <LeftMenu
                key={key}
                state={isActive ? 'active' : 'default'}
                onClick={() => {
                  setCurrentIdeaDetailsIndex(index);
                }}
                className="idea-details__sub-sections__menu__item"
              >
                <Typography as="p" variant="body-2" fontWeight="medium">
                  {label}
                </Typography>
              </LeftMenu>
            );
          })}
        </Card>
        <div className="idea-details__sub-sections__component">{currentIdeaDetailsComponent}</div>
      </div>
    </div>
  );
};

export default IdeaDetails;
