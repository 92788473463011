import { ActionIcon, Typography } from 'src/components/common';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';

import { TCustomApplicationSection } from '../customApplicationForm.types';

const TextArea = ({
  id,
  sectionId,
  type,
  label,
  placeholder,
  required,
  options,
  validations,
  response,
  files,
  hasCharacterLimit,
  openChangeFieldModal,
  openDeleteFieldModal,
  handleUpdateField,
  errors
}: TDynamicFieldProps) => {
  const updateField = ({ fieldChunk }: { fieldChunk: Partial<TCustomApplicationSection> }) => {
    const field: TCustomApplicationSection = {
      id,
      type,
      label,
      placeholder,
      required,
      options,
      validations,
      response,
      files,
      hasCharacterLimit,
      ...fieldChunk
    };

    handleUpdateField({ sectionId, fieldId: id, field });
  };

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          Text Area title
        </Typography>
        <div className="dynamic-field__header__actions">
          <div className="dynamic-field__header__actions__required">
            <input
              type="checkbox"
              id="textFieldRequired"
              checked={required}
              onChange={(e) => {
                updateField({ fieldChunk: { required: e.target.checked } });
              }}
            />
            <Typography
              as="label"
              htmlFor="textFieldRequired"
              variant="caption"
              fontWeight="regular"
            >
              Required
            </Typography>
          </div>
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              openDeleteFieldModal({ fieldId: id, sectionId });
            }}
          >
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>
      <div className="dynamic-field__field">
        {' '}
        <InputField
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Enter field title"
          type="text"
          value={label}
          onChange={(e) => {
            updateField({ fieldChunk: { label: e.target.value } });
          }}
          errorMessage={errors?.[id]}
          isTouched={true}
        />
        <div className="dynamic-field__field__character-limit-checkbox">
          <input
            type="checkbox"
            id="characterLimit"
            checked={hasCharacterLimit}
            onChange={(e) => {
              updateField({
                fieldChunk: {
                  hasCharacterLimit: e.target.checked,
                  validations: { ...validations, maxCharacters: 0 }
                }
              });
            }}
          />
          <Typography as="label" htmlFor="characterLimit" variant="caption" fontWeight="regular">
            Add character limit
          </Typography>
        </div>
        {hasCharacterLimit && (
          <InputField
            label="Enter character limit"
            id={id}
            value={validations.maxCharacters}
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Character limit"
            type="number"
            className="dynamic-field__field__character-limit"
            onChange={(e) => {
              updateField({
                fieldChunk: {
                  validations: { ...validations, maxCharacters: parseInt(e.target.value) }
                }
              });
            }}
          />
        )}
      </div>
    </div>
  );
};

export default TextArea;
