import { useEffect, useState } from 'react';
import classNames from 'classnames';
import { ArrowIcon } from 'src/components/common/common.icons';
import { videoPlayIcon } from 'src/assets/images';

import './carousel.scss';
import Typography from '../typography/Typography';

export type TCarouselItem = {
  type: 'image' | 'video';
  key: string;
};

export type TCarousel = {
  items: TCarouselItem[];
  openMediaModal?: () => void;
  enableVideoControls?: boolean;
  setCurrentIndex?: any,
  currIndex?: number
};

const Carousel = ({ items, openMediaModal, enableVideoControls = false, setCurrentIndex, currIndex }: TCarousel) => {
  const [currentItemIndex, setCurrentItemIndex] = useState(0);

  const goToPreviousItem = () => {
    const newIndex = (currentItemIndex - 1 + items.length) % items.length;
    setCurrentIndex && setCurrentIndex(newIndex)
    setCurrentItemIndex(newIndex);
  };
  const goToNextItem = () => {
    const newIndex = (currentItemIndex + 1) % items.length;
    setCurrentIndex && setCurrentIndex(newIndex)
    setCurrentItemIndex(newIndex);
  };

  const currentItem = items[currentItemIndex];
  useEffect(() => {
    if (currIndex) {
      setCurrentItemIndex(currIndex);
    }
  }, [currIndex])
  return (
    <div className="carousel-component" key={currentItem?.key}>
      <div
        onClick={goToPreviousItem}
        className={classNames('carousel-component__handler carousel-component__previous', {
          'carousel-component__handler carousel-component__previous--is-hidden': items?.length < 2
        })}
      >
        <ArrowIcon />
      </div>
      {currentItem.type === 'image' && (
        <img src={currentItem.key} alt={`Image ${currentItemIndex + 1}`} onClick={openMediaModal} />
      )}
      {currentItem.type === 'video' && (
        <div
          className="carousel-component__video"
          onClick={(e) => {
            if (openMediaModal) {
              e.preventDefault();
              openMediaModal();
            }
          }}
        >
          {!enableVideoControls && (
            <img src={videoPlayIcon} className="carousel-component__video__play-button" />
          )}
          <video controls={enableVideoControls}>
            <source src={currentItem.key} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      )}
      <div
        onClick={goToNextItem}
        className={classNames('carousel-component__handler carousel-component__next', {
          'carousel-component__handler carousel-component__next--is-hidden': items?.length < 2
        })}
      >
        <ArrowIcon />
      </div>
      {items?.length > 1 && (
        <div className="carousel-component__label">
          <Typography>
            {currentItemIndex + 1}/{items?.length}
          </Typography>
        </div>
      )}
    </div>
  );
};

export default Carousel;
