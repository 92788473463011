import { TSvgIcon } from 'src/types/common.types';

const ClockIcon = ({ color = '#000', ...rest }: TSvgIcon) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9 4.5625V9.0625L12 10.5625M16.5 9.0625C16.5 13.2046 13.1421 16.5625 9 16.5625C4.85786 16.5625 1.5 13.2046 1.5 9.0625C1.5 4.92036 4.85786 1.5625 9 1.5625C13.1421 1.5625 16.5 4.92036 16.5 9.0625Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ClockIcon;
