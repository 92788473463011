import axios from 'axios';

import { APIResponse, BaseAPIService } from './base.api.service';
import {
  TDeRegisterDeviceRequest,
  TGetAllNotificationsRequest,
  TGetInviteNotificationsRequest,
  TRegisterDeviceRequest
} from './apiEndpoint.types.ts';

export class NotificationsAPIService extends BaseAPIService {
  private registerDevicePath: string = '/notification/register-device';
  private deRegisterDevicePath: string = 'notification/deregister-device';
  private getAllNotificationsPath: string = 'notification/all';
  private getInviteNotificationsPath: string = 'notification/invites';

  constructor() {
    // Ideahub api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_NOTIFICATIONS_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async registerDevice<TRegisterDeviceResponse>(
    registerDeviceRequest: TRegisterDeviceRequest
  ): Promise<APIResponse<TRegisterDeviceResponse>> {
    const registerDeviceResponse: APIResponse<TRegisterDeviceResponse> = await this.post(
      this.registerDevicePath,
      registerDeviceRequest,
      true
    );
    return registerDeviceResponse;
  }

  public async deRegisterDevice<TDeRegisterDeviceResponse>(
    deRegisterDeviceRequest: TDeRegisterDeviceRequest
  ): Promise<APIResponse<TDeRegisterDeviceResponse>> {
    const deRegisterDeviceResponse: APIResponse<TDeRegisterDeviceResponse> = await this.post(
      this.deRegisterDevicePath,
      deRegisterDeviceRequest,
      true
    );
    return deRegisterDeviceResponse;
  }

  public async getAllNotifications<TGetAllNotificationsResponse>(
    getAllNotificationsRequest: TGetAllNotificationsRequest
  ): Promise<APIResponse<TGetAllNotificationsResponse>> {
    const getAllNotificationsResponse: APIResponse<TGetAllNotificationsResponse> = await this.get(
      this.getAllNotificationsPath,
      true,
      getAllNotificationsRequest
    );
    return getAllNotificationsResponse;
  }

  public async getInviteNotifications<TGetInviteNotificationsResponse>(
    getInviteNotificationsRequest: TGetInviteNotificationsRequest
  ): Promise<APIResponse<TGetInviteNotificationsResponse>> {
    const getInviteNotificationsResponse: APIResponse<TGetInviteNotificationsResponse> =
      await this.get(this.getInviteNotificationsPath, true, getInviteNotificationsRequest);
    return getInviteNotificationsResponse;
  }
}
