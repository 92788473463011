import { useEffect } from 'react';
import { TGetProgramGroupParticipantsRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import {
  getProgramGroupParticipants,
  resetIdeaGroupsParticipants
} from 'src/store/chat/chat.slice';

import { TUseGetDiscussionGroupParticipants } from './discussion.types';

/**
 * Fetches members of discussion group
 */
const useGetDiscussionGroupMembers = ({ groupId }: TUseGetDiscussionGroupParticipants) => {
  // Hooks
  const dispatch = useAppDispatch();

  const handleGetProgramGroupParticipants = () => {
    const payload: TGetProgramGroupParticipantsRequest = {
      programConversationId: groupId,
      limit: 1000,
      offset: 1
    };

    dispatch(getProgramGroupParticipants(payload));
  };

  useEffect(() => {
    if (groupId) {
      handleGetProgramGroupParticipants();
    }

    return () => {
      dispatch(resetIdeaGroupsParticipants());
    };
  }, [groupId]);

  return handleGetProgramGroupParticipants;
};

export default useGetDiscussionGroupMembers;
