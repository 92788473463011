/* eslint-disable no-unused-vars */

export enum EmploymentType {
  FULL_TIME = 'Full time',
  PART_TIME = 'Part time',
  SELF_EMPLOYED = 'Self employed',
  OTHER = 'Other'
}

export enum Degree {
  BARCH = 'Bachelor of Architecture (BArch)',
  BA = 'Bachelor of Arts (BA, AB, BS, BSc, SB, ScB)',
  BAA = 'Bachelor of Applied Arts (BAA)',
  BAAS = 'Bachelor of Applied Arts and Science (BAAS)',
  BAPPSCIT = 'Bachelor of Applied Science in Information Technology (BAppSc(IT))',
  BDES = 'Bachelor of Design (BDes, or SDes in Indonesia)',
  BENG = 'Bachelor of Engineering (BEng, BE, BSE, BESc, BSEng, BASc, BTech, BSc(Eng), AMIE, GradIETE)',
  BSBBA = 'Bachelor of Science in Business (BSBA)',
  BSET = 'Bachelor of Engineering Technology (BSET)',
  BTECH = 'Bachelor of Technology (B.Tech. or B.Tech.)',
  BIBE = 'International Business Economics (BIBE)',
  BBA = 'Bachelor of Business Administration (BBA)',
  BMS = 'Bachelor of Management Studies (BMS)',
  BAS = 'Bachelor of Administrative Studies',
  BCOM = 'Bachelor of Commerce (BCom, or BComm)',
  BFA = 'Bachelor of Fine Arts (BFA)',
  BBUS = 'Bachelor of Business (BBus or BBus)',
  BMOS = 'Bachelor of Management and Organizational Studies (BMOS)',
  BBUSSC = 'Bachelor of Business Science (BBusSc)',
  BACC = 'Bachelor of Accountancy (B.Acy. or B.Acc. or B. Accty)',
  BCOMPT = 'Bachelor of Comptrolling (B.Acc.Sci. or B.Compt.)',
  BEC = 'Bachelor of Economics (BEc, BEconSc; sometimes BA(Econ) or BSc(Econ))',
  BAOM = 'Bachelor of Arts in Organizational Management (BAOM)',
  BCOMPSC = 'Bachelor of Computer Science (BCompSc)',
  BCOMP = 'Bachelor of Computing (BComp)'
}

export enum Role {
  ASPIRING_ENTREPRENEUR = 'Aspiring Entrepreneur',
  ENTREPRENEUR = 'Entrepreneur',
  MENTOR = 'Mentor',
  INVESTOR = 'Investor',
  SERVICE_PROVIDERS_STARTUP = 'Service providers/Startup',
  MENTOR_INVESTOR = 'Mentor and Investor'
}

export enum InviteMentorInvestorRoles {
  MENTOR = 'Mentor',
  INVESTOR = 'Investor',
  MENTOR_INVESTOR = 'Mentor and Investor'
}

export enum Expertise {
  BUSINESS_OPERATIONS = 'Business Operations',
  SUPPLY_CHAIN = 'Supply Chain',
  STRATEGY = 'Strategy',
  SALES_DISTRIBUTION = 'Sales & Distribution',
  DESIGN_THINKING = 'Design Thinking',
  ENTERPRISE_SALES = 'Enterprise Sales',
  FINANCIAL_MANAGEMENT = 'Financial Management',
  FUND_RAISING = 'Fund Raising',
  GO_TO_MARKET = 'Go-To-Market',
  LEGAL_COMPLIANCE = 'Legal / Compliance',
  MARKETING = 'Marketing',
  RESOURCE_MANAGEMENT = 'Resource Management',
  SAAS_STRATEGY = 'SaaS Strategy',
  LEADERSHIP = 'Leadership',
  TECHNOLOGY = 'Technology'
}

export enum Industries {
  AEROSPACE = 'Aerospace',
  AGRICULTURE = 'Agriculture',
  AUTOMOTIVE = 'Automotive',
  BIG_DATA = 'Big Data',
  CLEANTECH = 'Cleantech',
  CYBER_SECURITY = 'Cyber Security',
  DEFENCE = 'Defence',
  DRONES = 'Drones',
  EDUCATION_AND_SKILLING = 'Education and Skilling',
  ELECTRIC_VEHICLES = 'Electric Vehicles',
  ENERGY = 'Energy',
  ENTERPRISE_SAAS = 'Enterprise SaaS',
  ENTERTAINMENT_AND_MEDIA = 'Entertainment & Media',
  FINANCE = 'Finance',
  FOOD_AND_BEVERAGES = 'Food & Beverages',
  GAMING = 'Gaming',
  GENERATIVE_AI = 'Generative AI',
  HARDWARE = 'Hardware',
  HEALTHCARE = 'Healthcare',
  INFRASTRUCTURE = 'Infrastructure',
  LIFE_SCIENCES = 'Life Sciences',
  MATERIAL_SCIENCES = 'Material Sciences',
  RENEWABLE_ENERGY = 'Renewable Energy',
  RETAIL = 'Retail',
  ROBOTICS = 'Robotics',
  SMART_MANUFACTURING = 'Smart Manufacturing',
  SPACETECH = 'Spacetech',
  SUPPLY_CHAIN_AND_LOGISTICS = 'Supply Chain & Logistics',
  SUSTAINABILITY_AND_ENVIRONMENT = 'Sustainability & Environment',
  TELECOM = 'Telecom',
  TEXTILE = 'Textile',
  WAREHOUSING = 'Warehousing',
  TRAVEL_AND_LEISURE = 'Travel and Leisure',
  WASTE_MANAGEMENT = 'Waste Management',
  WEB3 = 'Web3'
}

export enum Services {
  PRODUCT_DEVELOPMENT = 'Product Development',
  LEGAL_AND_COMPLIANCE = 'Legal & compliance',
  MARKETING_AND_BRANDING = 'Marketing & Branding',
  HUMAN_RESOURCES = 'Human Resoucres',
  ACCOUNTING_AND_FINANCE = 'Accounting & Finance',
  COWORKING_AND_FACILITIES = 'Coworking & Facilities',
  HARDWARE_PROCUREMENT_AND_LEASING = 'Hardware Procurement & Leasing'
}

export enum Profession {
  STUDENT = 'Student',
  WORKING_PROFESSIONAL = 'Working Professional',
  OTHER = 'Other'
}

export type TUserTypes = 'User' | 'Company';
