import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const FileIcon2 = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="19"
      height="23"
      viewBox="0 0 19 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5 1.76946V5.9C11.5 6.46005 11.5 6.74008 11.609 6.95399C11.7049 7.14215 11.8578 7.29513 12.046 7.39101C12.2599 7.5 12.5399 7.5 13.1 7.5H17.2305M17.5 9.48822V16.7C17.5 18.3802 17.5 19.2202 17.173 19.862C16.8854 20.4265 16.4265 20.8854 15.862 21.173C15.2202 21.5 14.3802 21.5 12.7 21.5H6.3C4.61984 21.5 3.77976 21.5 3.13803 21.173C2.57354 20.8854 2.1146 20.4265 1.82698 19.862C1.5 19.2202 1.5 18.3802 1.5 16.7V6.3C1.5 4.61984 1.5 3.77976 1.82698 3.13803C2.1146 2.57354 2.57354 2.1146 3.13803 1.82698C3.77976 1.5 4.61984 1.5 6.3 1.5H9.51178C10.2455 1.5 10.6124 1.5 10.9577 1.58289C11.2638 1.65638 11.5564 1.77759 11.8249 1.94208C12.1276 2.1276 12.387 2.38703 12.9059 2.90589L16.0941 6.09411C16.613 6.61297 16.8724 6.8724 17.0579 7.17515C17.2224 7.44356 17.3436 7.7362 17.4171 8.0423C17.5 8.38757 17.5 8.75445 17.5 9.48822Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon2;
