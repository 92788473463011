import { Modal } from 'src/components/common';
import { Form, Formik } from 'formik';
import { TApplicationDemoRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { shareDemoInvite } from 'src/store/program/program.slice';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import InputField from 'src/components/common/formFields/inputField/InputField';

import { TApplicationDemoFormValues, TShareDemoInviteProps } from './shareDemoInvite.types';
import './shareDemoInvite.scss';
import shareDemoInviteValidationSchema from './shareDemoInvite.validationSchema';

const ShareDemoInvite = ({
  programId,
  userId,
  isModalOpen,
  onModalClose
}: TShareDemoInviteProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector((store) => store.common.loading);

  const initialValues: TApplicationDemoFormValues = {
    conferenceLink: '',
    demoDate: '',
    demoTime: '',
    description: ''
  };

  const handleAdvanceApplication = (values: TApplicationDemoFormValues) => {
    const { conferenceLink, demoDate, demoTime, description } = values;

    const payload: TApplicationDemoRequest = {
      programId,
      userId,
      conferenceLink,
      demoDate,
      demoTime,
      description
    };

    dispatch(shareDemoInvite(payload));
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Share Demo Invite"
      className="share-demo"
    >
      <Formik
        initialValues={initialValues}
        onSubmit={handleAdvanceApplication}
        validationSchema={shareDemoInviteValidationSchema}
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="share-demo__form">
              <InputField
                label="Please paste the Invite conference link"
                id="conferenceLink"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="URL"
                type="text"
                required
              />

              <div className="share-demo__form__row">
                <InputField
                  label="Select the demo date"
                  id="demoDate"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="URL"
                  type="date"
                  required
                  className="share-demo__form__row__field"
                />
                <InputField
                  label="Select the demo time"
                  id="demoTime"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="URL"
                  type="time"
                  required
                  className="share-demo__form__row__field"
                />
              </div>

              <TextAreaField
                label="Additional Description"
                placeholder="Description"
                variant={componentSizeVariantsEnum.SMALL}
                value={values.description}
                onChange={(e) => {
                  setFieldValue('description', e.target.value);
                }}
                id="description"
              />
              <div className="share-demo__form__buttons">
                <Button size="small" variant="secondary" onClick={onModalClose}>
                  Cancel
                </Button>
                <Button size="small" variant="primary" type="submit" loading={loading}>
                  Confirm
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ShareDemoInvite;
