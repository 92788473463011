import { TUserTypes } from 'src/constants/user.constants';
import { StorageUtils } from 'src/utils';

export type TIdentity = {
  loggedInUserId: string;
  loggedInCompanyId: string;
  loggedInUserType: TUserTypes | '';
};

const useGetIdentity = () => {
  const getIdentities = () => {
    const loggedInUserId = StorageUtils.get('userId') as string;
    const loggedInCompanyId = StorageUtils.get('companyId') as string;
    const loggedInUserType = StorageUtils.get('userType') as TUserTypes;
    const loggedInCompanyEmail = StorageUtils.get('companyEmail') as string;
    const loggedInUserEmail = StorageUtils.get('email') as string;

    /*
    The id used is logged in.
    If the user is incubator or accelerator, the logged in Id is companyId
    If the user is aspiring entrepreneur, or entrepreneur, or mentor, or investor or startup/service provider,
    the logged id Id is userId
  */
    const authenticatedId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;
    const authenticatedEmail =
      loggedInUserType === 'User' ? loggedInUserEmail : loggedInCompanyEmail;

    return {
      loggedInUserId,
      loggedInCompanyId,
      loggedInUserType,
      authenticatedId,
      authenticatedEmail,
      loggedInCompanyEmail,
      loggedInUserEmail
    };
  };

  return { getIdentities };
};

export default useGetIdentity;
