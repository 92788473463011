import { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { nanoid } from 'nanoid';

import './selectCardRadio.scss';

import SelectCard, { TSelectCardProps } from '../selectCard/SelectCard';

export type TSelectCardRadioButton = {
  label: string;
  value: string | number;
  id: string;
} & TSelectCardProps;

export type TSelectCardRadioProps = {
  options: TSelectCardRadioButton[];
  selectedValue: string;
  name: string;
  variant?: componentSizeVariantsEnum;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Represents a radio button option for a select card.
 * @param {string} label - The label for the radio button.
 * @param {string|number} value - The value associated with the radio button.
 * @param {string} id - The unique identifier for the radio button.
 * @param {TSelectCardProps} - Additional props inherited from TSelectCardProps.
 */

/**
 * This is a radio component, that tries to replicate html radio input functionality
 * But the radio input have an appearance of a button.
 * @param {TSelectCardRadioButton[]} options - An array of radio button options.
 * @param {string} selectedValue - The value of the selected radio button.
 * @param {string} name - The name attribute for the radio button group.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the select card radio component.
 * @param {InputHTMLAttributes<HTMLInputElement>} - Additional HTML input element attributes.
 */
const SelectCardRadio = ({
  variant,
  options,
  selectedValue,
  name,
  className,
  ...rest
}: TSelectCardRadioProps) => {
  return (
    <div className={classNames('select-card-radio-component', className)}>
      {options.map((option: TSelectCardRadioButton) => (
        <div
          key={option.id || nanoid()}
          className={classNames('select-card-radio-component__option')}
        >
          <label htmlFor={option.id}>
            <SelectCard
              variant={variant}
              startIcon={option.startIcon}
              endIcon={option.endIcon}
              selected={selectedValue === option.value}
              className={classNames('select-card-radio-component__option__label')}
            >
              {option.label}
            </SelectCard>
          </label>
          <input
            type="radio"
            name={name}
            id={option.id}
            value={option.value}
            className={classNames('select-card-radio-component__option__input')}
            checked={option.value === selectedValue}
            {...rest}
          />
        </div>
      ))}
    </div>
  );
};

export default SelectCardRadio;
