import { TCustomField } from 'src/components/company/programs/createProgram';

export const programDetailsInitialFields: TCustomField[] = [
  {
    id: 'aboutProgram',
    type: 'textEditor',
    label: 'About Program',
    placeholder: 'Description',
    values: ''
  },
  {
    id: 'whoShouldAttend',
    type: 'textEditor',
    label: 'Who should attend',
    placeholder: 'Description',
    values: ''
  },
  {
    id: 'enterApplicationProcess',
    type: 'textEditor',
    label: 'Enter Application Process',
    placeholder: 'Description',
    values: ''
  }
];
