import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const TickMarkIcon = ({ color = colors.white[100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13 1.5625L4.75 9.8125L1 6.0625"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TickMarkIcon;
