// src/services/socketService.ts
import { io } from 'socket.io-client';

const socketUrl = process.env.REACT_APP_API_CHAT_SOCKET_ENDPOINT || '';

// class SocketService {
//   private socket: Socket | null = null;
//   private socketUrl = process.env.REACT_APP_API_CHAT_ENDPOINT || '';

//   connect(): void {
//     this.socket = io(this.socketUrl);

//     console.log(12);

//     this.socket.on('connect', () => {
//       console.log('Connected to socket server');
//     });

//     this.socket.on('disconnect', () => {
//       console.log('Disconnected from socket server');
//     });
//   }

//   disconnect(): void {
//     this.socket?.disconnect();
//   }

//   emit(event: string, payload: unknown): void {
//     console.log(event, payload);
//     this.socket?.emit(event, payload);
//   }

//   on(event: string, callback: (data: unknown) => void): void {
//     this.socket?.on(event, callback);
//   }

//   off(event: string, callback?: (data: unknown) => void): void {
//     this.socket?.off(event, callback);
//   }
// }

// export default new SocketService();

export const socketService = io(socketUrl, { transports: ['websocket'] });

// export const localSocketService = io(SOCKET_URL);
