import React, { useMemo, useRef, useState } from 'react';
import { useElementPosition } from 'src/hooks';
import { TElementPosition } from 'src/hooks/useElementPosition/useElementPosition.types';

import { ITooltipProps, TooltipPlacement } from './tooltip.types';
import './tooltip.scss';

/*
This component is similar to Material UI tooltip, but gives more control over content and syle of tootip.
This also different from component "SimpleTooltip", which was created to enable hover and selection etc.
This component can be further modified based on the design requirements.
*/
const Tooltip = ({
  children,
  text,
  limitWidth = true,
  offsetY = 8,
  offsetX = 12,
  placement = 'top',
  containerProps,
  conditionToDisplay = true
}: ITooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const simpleTooltipRef = useRef<HTMLDivElement>(null);
  const tooltipMessageRef = useRef<HTMLDivElement>(null);

  const simpleTooltipPosition = useElementPosition({
    ref: simpleTooltipRef,
    triggers: [showTooltip, text]
  }) as TElementPosition;
  const tooltipMessagePosition = useElementPosition({
    ref: tooltipMessageRef,
    triggers: [showTooltip, text]
  }) as TElementPosition;

  const toolTiptop: Record<TooltipPlacement, string> = useMemo(() => {
    return {
      top: `${simpleTooltipPosition.y - tooltipMessagePosition.height - offsetY}px`,
      right: `${simpleTooltipPosition.y - offsetY}px`,
      bottom: `${simpleTooltipPosition.y + simpleTooltipPosition.height + offsetY}px`,
      left: `${simpleTooltipPosition.y - offsetY}px`
    };
  }, [simpleTooltipPosition, tooltipMessagePosition]);

  const toolTipLeft: Record<TooltipPlacement, string> = useMemo(() => {
    const top: number =
      simpleTooltipPosition.x - tooltipMessagePosition.width / 2 + simpleTooltipPosition.width / 2;
    return {
      top: `${top <= 0 ? 0 : `${top}px`}`,
      right: `${simpleTooltipPosition.x + simpleTooltipPosition.width + offsetX}px`,
      bottom: `${
        simpleTooltipPosition.x - tooltipMessagePosition.width / 2 + simpleTooltipPosition.width / 2
      }px`,
      left: `${simpleTooltipPosition.x - tooltipMessagePosition.width - offsetX}px`
    };
  }, [simpleTooltipPosition, tooltipMessagePosition]);

  return (
    <div
      className="tooltip-custom"
      onMouseEnter={() => {
        if (conditionToDisplay) {
          setShowTooltip(true);
        }
      }}
      onMouseLeave={() => {
        setShowTooltip(false);
      }}
      ref={simpleTooltipRef}
      {...containerProps}
    >
      {children}
      {
        <>
          <p
            className={`tooltip-custom__tooltip tooltip-custom__tooltip--is-on-${placement} ${
              limitWidth ? 'tooltip-custom__tooltip--limit-width' : ''
            } `}
            ref={tooltipMessageRef}
            style={{
              top: toolTiptop[placement as keyof typeof toolTiptop],
              left: toolTipLeft[placement as keyof typeof toolTipLeft],
              visibility: showTooltip ? 'visible' : 'hidden',
              opacity: showTooltip ? 100 : 0
            }}
          >
            {text}
          </p>
        </>
      }
    </div>
  );
};

export default Tooltip;
