import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const BulbIcon = ({ color = colors.primary[100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10.6654 19.6668V22.3984C10.6654 23.6871 11.71 24.7318 12.9987 24.7318C14.2874 24.7318 15.332 23.6871 15.332 22.3984V19.6668M12.9987 1.39844V2.5651M2.4987 13.0651H1.33203M5.41536 5.48177L4.71525 4.78166M20.582 5.48177L21.2823 4.78166M24.6654 13.0651H23.4987M19.9987 13.0651C19.9987 16.9311 16.8647 20.0651 12.9987 20.0651C9.1327 20.0651 5.9987 16.9311 5.9987 13.0651C5.9987 9.19911 9.1327 6.0651 12.9987 6.0651C16.8647 6.0651 19.9987 9.19911 19.9987 13.0651Z"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BulbIcon;
