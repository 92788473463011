import { useNavigate } from 'react-router-dom';
import { useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { Tag, Typography } from 'src/components/common';
import { SingleChevronIcon } from 'src/components/common/common.icons';

import useFilterOptionsByType from './useFilterOptionsByType';
import './filters.scss';

import MultiSelect from '../multiSelect/MultiSelect';

export type TSearchQueryTypes =
  | 'query'
  | 'resultType'
  | 'location'
  | 'connection'
  | 'company'
  | 'industry'
  | 'postedIn'
  | 'postedBy';

export type TSearchResultTypes =
  | 'all'
  | 'people'
  | 'mentor-investor'
  | 'company'
  | 'announcement'
  | 'post';

const filterDropdownId = 'filterDropdown';

export type TSearchFilters = {
  updateParams: ({ type, value }: { type: TSearchQueryTypes; value: string }) => void;
};

const Filters = ({ updateParams }: TSearchFilters) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dropdownId } = useAppSelector((store) => store.common);
  const { paramValue } = useUrlParamValue();
  const filterOptionsByType = useFilterOptionsByType();

  const query = paramValue({ paramName: 'query' });
  const resultType = paramValue({ paramName: 'resultType' });
  const location = paramValue({ paramName: 'location' });
  const connection = paramValue({ paramName: 'connection' });
  const company = paramValue({ paramName: 'company' });
  const industry = paramValue({ paramName: 'industry' });
  const postedIn = paramValue({ paramName: 'postedIn' });
  const postedBy = paramValue({ paramName: 'postedBy' });

  const currentParams = {
    resultType,
    location,
    connection,
    industry,
    postedIn,
    postedBy,
    company
  };

  const openFilterDropdown = ({ filterId }: { filterId: string }) => {
    dispatch(assignSubMenuModalId(`${filterDropdownId}-${filterId}`));
  };

  const closeFilterDropdown = () => {
    dispatch(assignSubMenuModalId(''));
  };

  return (
    <div className="search-filters">
      <Typography as="span" variant="body-1" fontWeight="semiBold">
        Filter:
      </Typography>
      {filterOptionsByType.map(
        ({ label, value: filterTypeValue, type: filterType, options: dropDownOptions }, index) => {
          const openFilters = dropdownId === `${filterDropdownId}-${filterTypeValue}`;
          const hasOptions = dropDownOptions?.length > 0 || filterType === 'location';

          const queryValue = currentParams[filterType as keyof typeof currentParams];

          let isButtonActive: boolean = false;

          if (filterTypeValue === resultType) {
            isButtonActive = true;
          }
          if (filterType === 'location' && location) {
            isButtonActive = true;
          }
          if (filterType === 'connection' && connection) {
            isButtonActive = true;
          }
          if (filterType === 'company' && company) {
            isButtonActive = true;
          }
          if (filterType === 'industry' && industry) {
            isButtonActive = true;
          }
          if (filterType === 'postedIn' && postedIn) {
            isButtonActive = true;
          }
          if (filterType === 'postedBy' && postedBy) {
            isButtonActive = true;
          }

          return (
            <div
              key={index}
              onClick={(e) => {
                e.stopPropagation();
              }}
              className="search-filters__filter"
            >
              <Tag
                size="small"
                variant={isButtonActive ? 'active' : 'border'}
                onClick={() => {
                  if (resultType === 'all') {
                    navigate(`/search?query=${query}&resultType=${filterTypeValue}`);
                  } else if (hasOptions) {
                    openFilterDropdown({ filterId: filterTypeValue });
                  }
                  if (openFilters) {
                    closeFilterDropdown();
                  }
                }}
                endIcon={hasOptions ? <SingleChevronIcon /> : ''}
              >
                {label}
              </Tag>
              {hasOptions && (
                <MultiSelect
                  openFilters={openFilters}
                  closeFilterDropdown={closeFilterDropdown}
                  dropDownOptions={dropDownOptions}
                  updateParams={updateParams}
                  filterType={filterType}
                  queryValue={queryValue || ''}
                  resultType={resultType || ''}
                />
              )}
            </div>
          );
        }
      )}
    </div>
  );
};

export default Filters;
