import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const EditIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.33398 2.72916H4.53398C3.41388 2.72916 2.85383 2.72916 2.426 2.94714C2.04968 3.13889 1.74372 3.44485 1.55197 3.82117C1.33398 4.249 1.33398 4.80905 1.33398 5.92916V11.5292C1.33398 12.6493 1.33398 13.2093 1.55197 13.6371C1.74372 14.0135 2.04968 14.3194 2.426 14.5112C2.85383 14.7292 3.41388 14.7292 4.53398 14.7292H10.134C11.2541 14.7292 11.8141 14.7292 12.242 14.5112C12.6183 14.3194 12.9243 14.0135 13.116 13.6371C13.334 13.2093 13.334 12.6493 13.334 11.5292V8.72916M5.33397 10.7292H6.45033C6.77645 10.7292 6.93951 10.7292 7.09296 10.6923C7.22901 10.6597 7.35907 10.6058 7.47836 10.5327C7.61292 10.4502 7.72822 10.3349 7.95882 10.1043L14.334 3.72916C14.8863 3.17687 14.8863 2.28144 14.334 1.72916C13.7817 1.17687 12.8863 1.17687 12.334 1.72915L5.95881 8.10432C5.7282 8.33492 5.6129 8.45022 5.53045 8.58478C5.45734 8.70407 5.40347 8.83413 5.37081 8.97018C5.33397 9.12363 5.33397 9.28669 5.33397 9.61281V10.7292Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
