import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PasswordIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.55338 5.72917V4.39583C9.55338 2.55488 8.061 1.0625 6.22005 1.0625C4.3791 1.0625 2.88672 2.55488 2.88672 4.39583V5.72917M6.22005 8.72917V10.0625M4.08672 13.0625H8.35338C9.47349 13.0625 10.0335 13.0625 10.4614 12.8445C10.8377 12.6528 11.1437 12.3468 11.3354 11.9705C11.5534 11.5427 11.5534 10.9826 11.5534 9.8625V8.92917C11.5534 7.80906 11.5534 7.24901 11.3354 6.82119C11.1437 6.44486 10.8377 6.1389 10.4614 5.94715C10.0335 5.72917 9.47349 5.72917 8.35338 5.72917H4.08672C2.96661 5.72917 2.40656 5.72917 1.97874 5.94715C1.60241 6.1389 1.29645 6.44486 1.10471 6.82119C0.886719 7.24901 0.886719 7.80906 0.886719 8.92917V9.8625C0.886719 10.9826 0.886719 11.5427 1.10471 11.9705C1.29645 12.3468 1.60241 12.6528 1.97874 12.8445C2.40656 13.0625 2.96661 13.0625 4.08672 13.0625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PasswordIcon;
