import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const CheckboxIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.125 8.1875L6.375 10.4375L10.875 5.9375M4.35 14.9375H10.65C11.9101 14.9375 12.5402 14.9375 13.0215 14.6923C13.4448 14.4765 13.789 14.1323 14.0048 13.709C14.25 13.2277 14.25 12.5976 14.25 11.3375V5.0375C14.25 3.77738 14.25 3.14732 14.0048 2.66602C13.789 2.24266 13.4448 1.89845 13.0215 1.68274C12.5402 1.4375 11.9101 1.4375 10.65 1.4375H4.35C3.08988 1.4375 2.45982 1.4375 1.97852 1.68274C1.55516 1.89845 1.21095 2.24266 0.995235 2.66602C0.75 3.14732 0.75 3.77738 0.75 5.0375V11.3375C0.75 12.5976 0.75 13.2277 0.995235 13.709C1.21095 14.1323 1.55516 14.4765 1.97852 14.6923C2.45982 14.9375 3.08988 14.9375 4.35 14.9375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckboxIcon;
