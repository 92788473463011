import { FC } from 'react';
import { Card, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { TEvaluatorResponse } from 'src/components/ideaHub/ideaHub.types';
import { textFormatter, timeAgo } from 'src/utils';

import './viewRating.scss';

export type TEvaluatorResponseProps = TEvaluatorResponse;

const ViewRating: FC<TEvaluatorResponseProps> = ({
  User,
  updatedAt,
  productMarketFit,
  marketSize,
  uniqueness,
  details
}: TEvaluatorResponseProps) => {
  const ratings: { label: string; rating: number }[] = [
    { label: 'Product market fit', rating: productMarketFit },
    { label: 'Market size', rating: marketSize },
    { label: 'Uniqueness', rating: uniqueness }
  ];

  return (
    <Card className="view-rating">
      <div className="view-rating__header">
        <ProfilePicture
          url={User?.profilePic || ''}
          fallback={textFormatter({ value: User?.name[0] })}
        />
        <div className="view-rating__header__user">
          <Typography
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="view-rating__header__user__name"
          >
            {User?.name}
          </Typography>
          <div className="view-rating__header__user__dot"></div>
          <Typography
            as="span"
            variant="caption"
            fontWeight="regular"
            className="view-rating__header__user__updated-at"
          >
            {timeAgo({ timestamp: updatedAt })}
          </Typography>
        </div>
      </div>
      <div className="view-rating__parameters">
        {ratings.map(({ label, rating }, index) => {
          return (
            <div className="view-rating__parameters__parameter" key={index}>
              <Typography as="span" variant="body-2" fontWeight="semiBold">
                {label}&#58;
              </Typography>
              <Typography as="span" variant="body-2" fontWeight="regular">
                {rating}
              </Typography>
            </div>
          );
        })}
      </div>
      <div className="view-rating__description">
        <Typography as="p" variant="body-2" fontWeight="semiBold">
          Addition Description
        </Typography>
        <Typography as="p" variant="body-2" fontWeight="regular">
          {details}
        </Typography>
      </div>
    </Card>
  );
};

ViewRating.defaultProps = {
  User: { id: '0', name: '', email: '', profilePic: '' },
  productMarketFit: 0,
  marketSize: 0,
  uniqueness: 0,
  details: ''
};

export default ViewRating;
