import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './errorMessage.scss';

import Typography from '../../typography/Typography';

type TErrorMessageProps = {
  message?: string;
} & HTMLAttributes<HTMLParagraphElement>;

/**
 * Props for the ErrorMessage component.
 * @typedef {Object} TErrorMessageProps
 * @param {string} [message] - The error message to be displayed.
 */
const ErrorMessage = ({ message, className, ...rest }: TErrorMessageProps) => {
  return (
    <Typography
      {...rest}
      variant="body-2"
      fontWeight="medium"
      className={classNames(className, 'error-message-component', {
        'error-message-component--hide': !message
      })}
    >
      {message}
    </Typography>
  );
};

export default ErrorMessage;
