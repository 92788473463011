import { Link } from 'react-router-dom';
import Typography from '../common/typography/Typography';
import './footer.scss';

const Footer = () => {
  return (
    <div className="footer">
      <Typography variant="body-1" fontWeight="semiBold">
        <div className='btnarea'>
          <Link to="/feedback">Feedback</Link>
        </div>
        Copyright © 2024 htraction All rights reserved
      </Typography>
    </div>
  );
};
export default Footer;
