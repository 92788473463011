import * as Yup from 'yup';
import { validatePhoneNumber } from 'src/utils';

const editProfileValidationSchema = Yup.object().shape({
  name: Yup.string().required('Name should not be empty'),
  role: Yup.string().required('Role should not be empty'),
  phoneNumber: Yup.string().test(
    'isValidPhoneNumber',
    'Please enter a valid phone number',
    function (value) {
      if (value === '') {
        return true;
      }
      const countryCode = 'IN'; // Replace with the appropriate country code or pass it dynamically
      const result = validatePhoneNumber({ phoneNumber: value || '', countryCode });
      if (result) {
        return true;
      }
      return false;
    }
  )
});

export default editProfileValidationSchema;
