import * as Yup from 'yup';

const createCustomApplicationFormSchema = ({
  sectionFields
}: {
  sectionFields: Record<string, string>;
}) => {
  const shape: { [key: string]: any } = {};

  try {
    const fields: string[] = Object.keys(sectionFields);

    fields.forEach((field) => {
      const validator = Yup.string().required('Please enter details in this field');
      shape[field] = validator;
    });

    return Yup.object().shape(shape);
  } catch {
    return Yup.object().shape(shape);
  }
};

export default createCustomApplicationFormSchema;
