import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const TrashIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.66667 3.5651V3.03177C9.66667 2.28503 9.66667 1.91167 9.52134 1.62645C9.39351 1.37557 9.18954 1.17159 8.93865 1.04376C8.65344 0.898438 8.28007 0.898438 7.53333 0.898438H6.46667C5.71993 0.898438 5.34656 0.898438 5.06135 1.04376C4.81046 1.17159 4.60649 1.37557 4.47866 1.62645C4.33333 1.91167 4.33333 2.28503 4.33333 3.03177V3.5651M5.66667 7.23177V10.5651M8.33333 7.23177V10.5651M1 3.5651H13M11.6667 3.5651V11.0318C11.6667 12.1519 11.6667 12.7119 11.4487 13.1398C11.2569 13.5161 10.951 13.822 10.5746 14.0138C10.1468 14.2318 9.58677 14.2318 8.46667 14.2318H5.53333C4.41323 14.2318 3.85318 14.2318 3.42535 14.0138C3.04903 13.822 2.74307 13.5161 2.55132 13.1398C2.33333 12.7119 2.33333 12.1519 2.33333 11.0318V3.5651"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrashIcon;
