import { MouseEvent, useMemo, useState } from 'react';
import InputElement from 'src/components/common/formFields/inputElement/InputElement';
import { SearchIcon, SingleChevronIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import { componentSizeVariantsEnum } from 'src/constants';
import {
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { useUrlParamValue } from 'src/hooks';
import { resetMyIdeasPage, resetMyIdeasSharedPage } from 'src/store/ideaHub/ideaHub.slice';

import './searchFilterIdeas.scss';

import { TIdeaHubFilters, TIdeaHubSearchParams } from '../ideaHub.types';

const ideaHubFiltersDropdownId = 'ideaHubFiltersDropdown';

export type TSearchFilterIdeasProps = {
  updateParams: ({ param, value }: { param: TIdeaHubSearchParams; value: string }) => void;
  increaseFetchIdeasCounter: () => void;
};

const filterLabels = {
  latest: 'Latest',
  draftFirst: 'Draft first',
  finalizedFirst: 'Finalized first'
};

const SearchFilterIdeas = ({
  updateParams,
  increaseFetchIdeasCounter
}: TSearchFilterIdeasProps) => {
  // Hooks
  const dropdownId: string = useAppSelector((store) => store.common.dropdownId);
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();

  const query: string = paramValue({ paramName: 'query' }) as string;
  const ideasFilterBy: TIdeaHubFilters = paramValue({
    paramName: 'filterBy'
  }) as TIdeaHubFilters;

  const [searchQuery, setSearchQuery] = useState<string>(query || '');

  const handleSearchIdeas = () => {
    updateParams({ param: 'query', value: searchQuery });
  };

  useDebounce({ func: handleSearchIdeas, delay: 500, dependency: searchQuery });

  const resetExistingData = () => {
    // Reset data when filters are changed
    dispatch(resetMyIdeasSharedPage());
    dispatch(resetMyIdeasPage());
  };

  const ideaFilterDropdownItems: TDropdownOptions[] = useMemo(() => {
    const items: TDropdownOptions[] = [
      {
        label: 'Latest',
        onClick: () => {
          resetExistingData();
          updateParams({ param: 'filterBy', value: 'latest' });
        },
        ...(ideasFilterBy === 'latest' && { state: 'active' })
      },
      {
        label: 'Draft first',
        onClick: () => {
          resetExistingData();
          updateParams({ param: 'filterBy', value: 'draftFirst' });
        },
        ...(ideasFilterBy === 'draftFirst' && { state: 'active' })
      },
      {
        label: 'Finalized first',
        onClick: () => {
          resetExistingData();
          updateParams({ param: 'filterBy', value: 'finalizedFirst' });
        },
        ...(ideasFilterBy === 'finalizedFirst' && { state: 'active' })
      }
    ];

    return items;
  }, [ideasFilterBy]);

  const ideaFilterDropdownProps: TDropdown = {
    id: ideaHubFiltersDropdownId,
    size: 'large',
    alignment: 'left',
    items: ideaFilterDropdownItems
  };

  const openFiltersDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(ideaHubFiltersDropdownId));
    }
  };

  return (
    <div className="search-filter-ideas">
      <form
        className="search-filter-ideas__search"
        onSubmit={(e) => {
          e.preventDefault();
          handleSearchIdeas();
        }}
      >
        <InputElement
          startIcon={<SearchIcon />}
          placeholder="Search"
          value={searchQuery}
          onChange={(e) => {
            resetExistingData();
            setSearchQuery(e.target.value);
          }}
          variant={componentSizeVariantsEnum.LARGE}
          className="search-filter-ideas__search__input-field"
        />
        <Button variant="primary" size="small" type="submit" onClick={increaseFetchIdeasCounter}>
          Search
        </Button>
      </form>

      <div className="search-filter-ideas__filter">
        <Typography as="span" variant="body-2" fontWeight="semiBold">
          Sort:
        </Typography>
        <div className="search-filter-ideas__filter__dropdown">
          <Button
            variant="secondary"
            size="small"
            endIcon={<SingleChevronIcon />}
            onClick={openFiltersDropdown}
          >
            <Typography variant="body-2" fontWeight="semiBold">
              {filterLabels[ideasFilterBy] || 'Latest'}
            </Typography>
          </Button>
          <SubMenuModal>
            <Dropdown {...ideaFilterDropdownProps} />
          </SubMenuModal>
        </div>
      </div>
    </div>
  );
};

export default SearchFilterIdeas;
