import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const BookmarkIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="11"
      height="15"
      viewBox="0 0 11 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.833984 4.7625C0.833984 3.6424 0.833984 3.08234 1.05197 2.65452C1.24372 2.27819 1.54968 1.97223 1.926 1.78049C2.35383 1.5625 2.91388 1.5625 4.03398 1.5625H6.96732C8.08742 1.5625 8.64748 1.5625 9.0753 1.78049C9.45162 1.97223 9.75758 2.27819 9.94933 2.65452C10.1673 3.08234 10.1673 3.6424 10.1673 4.7625V13.5625L5.50065 10.8958L0.833984 13.5625V4.7625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookmarkIcon;
