import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
import { Typography } from 'src/components/common';

import './filterResultCards.scss';

import { TSearchResultTypes } from '../filters/Filters';

export type TFilterResultCards = {
  title: string;
  onFetchAll: () => void;
  resultType: TSearchResultTypes;
  content: ReactNode[];
  totalResults: number;
  className?: string;
};

const FilterResultCards = ({
  className,
  title,
  content,
  resultType,
  totalResults,
  onFetchAll
}: TFilterResultCards) => {
  return (
    <div className={classNames('filter-result-cards', className)}>
      <div className="filter-result-cards__header">
        {resultType !== 'all' && (
          <Typography
            as="span"
            variant="body-2"
            fontWeight="regular"
            onClick={onFetchAll}
            className="filter-result-cards__header__total-results"
          >
            About {totalResults} results found
          </Typography>
        )}
        {resultType === 'all' && (
          <>
            <Typography as="span" variant="body-1" fontWeight="semiBold">
              {title}
            </Typography>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              onClick={onFetchAll}
              className="filter-result-cards__header__see-all"
            >
              See all
            </Typography>
          </>
        )}
      </div>

      <div className={classNames('filter-result-cards__items', `${className}__items`)}>
        {content.map((item, index) => {
          return <Fragment key={index}>{item}</Fragment>;
        })}
      </div>
    </div>
  );
};

export default FilterResultCards;
