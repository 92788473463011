import { useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';

const useToggleManageGroupModalModal = () => {
  // Constants
  const manageGroupMembersModalId = 'manageGroupMembersModal';

  // Hooks
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);

  // Constants
  const isToggleManageGroupModalModalOpen = modalId === manageGroupMembersModalId;

  const toggleManageGroupModalModal = () => {
    toggleOpenModal({ modalId: manageGroupMembersModalId });
  };

  return { toggleManageGroupModalModal, isToggleManageGroupModalModalOpen };
};

export default useToggleManageGroupModalModal;
