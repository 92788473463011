import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ActionIcon,
  // ActionIcon,
  TDropdown,
  TDropdownOptions,
  ThreeDotsDropdown,
  Tooltip,
  Typography,
  VerticalSeparator
} from 'src/components/common';
import { colors } from 'src/constants';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { textEllipsis } from 'src/utils';
import { SingleChevronIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useComponentMount, useGetIdentity, useUrlParamValue } from 'src/hooks';
import { getViewUserProfile, resetViewProfile } from 'src/store/profile/profile.slice';
import { TClearDirectMessageChatRequest } from 'src/services/apiEndpoint.types.ts';
import { clearDirectMessageChat } from 'src/store/chat/chat.slice';
import { TUserTypes } from 'src/constants/user.constants';
import { getViewCompany } from 'src/store/company/company.slice';
// import { MicIcon, VideoIcon } from 'src/components/common/common.icons';

import './chatMessagesHeader.scss';
import { TChatMessagesHeaderProps } from './chatMessagesHeader.types';

const ChatMessagesHeader: FC<TChatMessagesHeaderProps> = ({ conversationId }) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { paramValue } = useUrlParamValue();
  const recipientUserProfile = useAppSelector((store) => store.profile.viewUserProfile);
  const { viewCompany: recipientCompanyProfile, viewCompanyProfilePic } = useAppSelector(
    (store) => store.company
  );

  // Constants
  const recipientId = paramValue({ paramName: 'userId' });
  const recipientType = paramValue({ paramName: 'userType' }) as unknown as TUserTypes;
  const { loggedInUserId, authenticatedId, loggedInUserType } = getIdentities();

  const viewProfile = recipientType === 'User' ? recipientUserProfile : recipientCompanyProfile;
  const profilePic =
    recipientType === 'User' ? recipientUserProfile?.profilePic : viewCompanyProfilePic;
  const role =
    recipientType === 'User' ? recipientUserProfile?.role : recipientCompanyProfile?.type;
  const name = viewProfile?.name;
  const viewerId = viewProfile?.id;

  // Utils
  const nameWitEllipsis = textEllipsis({
    text: name || '',
    charLimit: 12
  });
  console.log(nameWitEllipsis, 'nameWitEllipsis');
  const clearChat = () => {
    const payload: TClearDirectMessageChatRequest = {
      conversationId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(clearDirectMessageChat(payload));
  };

  const handleViewProfile = () => {
    if (recipientType === 'User') {
      navigate(`/view-profile?id=${viewerId}`);
    } else if (recipientType === 'Company') {
      navigate(`/view-company?id=${viewerId}`);
    }
  };

  const dropdownItems: TDropdownOptions[] = [
    {
      label: 'View profile',
      onClick: handleViewProfile
    },
    {
      label: 'Clear chat',
      onClick: clearChat
    }
  ];

  const dropdownOptionsProps: TDropdown = {
    id: `chat-messages-header-${recipientUserProfile?.id}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const closeChatWindow = () => {
    navigate('/chat');
  };

  const getRecipientData = () => {
    if (recipientType === 'User' && recipientId) {
      dispatch(getViewUserProfile({ id: recipientId }));
    } else if (recipientType === 'Company' && recipientId) {
      dispatch(getViewCompany({ id: recipientId, userId: loggedInUserId }));
    }
  };

  useEffect(() => {
    getRecipientData();
  }, [recipientId]);

  useComponentMount({
    onMountFunc: () => {},
    onUnMountFunc: () => {
      dispatch(resetViewProfile());
    }
  });

  return (
    <div className="chat-messages-header">
      <div className="chat-messages-header__left" onClick={handleViewProfile}>
        <ActionIcon
          onClick={closeChatWindow}
          className="chat-messages-header__left__go-back-mobile"
        >
          <SingleChevronIcon direction="left" />
        </ActionIcon>
        <ProfilePicture url={profilePic} fallback={name?.[0]} />
        <Tooltip text={name} conditionToDisplay={nameWitEllipsis?.hasEllipsis} limitWidth>
          <Typography
            as="p"
            variant="body-2"
            fontWeight="semiBold"
            style={{ color: colors?.neutral?.[1100] }}
          >
            {nameWitEllipsis?.text}
          </Typography>
        </Tooltip>
        <VerticalSeparator />
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          style={{ color: colors?.neutral?.[900] }}
        >
          {role}
        </Typography>
      </div>
      <div className="chat-messages-header__right">
        {/* <ActionIcon>
          <MicIcon />
        </ActionIcon>
        <ActionIcon>
          <VideoIcon />
        </ActionIcon> */}
        <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />
      </div>
    </div>
  );
};

export default ChatMessagesHeader;
