import { useEffect } from 'react';
import { TProgramMembersListRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getProgramMembers, resetProgramMembers } from 'src/store/program/program.slice';
import { useGetIdentity } from 'src/hooks';

import { TUseGetProgramMembers } from './programMembers.types';

const useGetProgramMembers = ({ programId }: TUseGetProgramMembers) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { programMembersPageNumber, programMembersPerPage } = useAppSelector(
    (store) => store.program
  );

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const fetchProgramMembers = () => {
    const payload: TProgramMembersListRequest = {
      programId: programId,
      limit: programMembersPerPage,
      offset: programMembersPageNumber,
      userId: authenticatedId,
      userType: loggedInUserType
    };
    if (programId) {
      dispatch(getProgramMembers(payload));
    }
  };

  useEffect(() => {
    fetchProgramMembers();

    return () => {
      dispatch(resetProgramMembers());
    };
  }, [programMembersPageNumber, programMembersPerPage]);

  return fetchProgramMembers;
};

export default useGetProgramMembers;
