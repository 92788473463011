import { InputHTMLAttributes } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { nanoid } from 'nanoid';
import selectDeselectCheckbox from 'src/utils/selectDeselectCheckbox/selectDeselectCheckbox';

import './selectCardCheckbox.scss';

import SelectCard, { TSelectCardProps } from '../selectCard/SelectCard';

export type TSelectedValuesReturn = {
  selectedValues: (string | number)[];
};

export type TSelectCardCheckboxButton = {
  label: string;
  value: string | number;
  id: string;
} & TSelectCardProps;

export type TSelectCardCheckboxProps = {
  options: TSelectCardCheckboxButton[];
  selectedValues: (string | number)[];
  onSelection: ({ selectedValues }: TSelectedValuesReturn) => void;
  name: string;
  variant?: componentSizeVariantsEnum;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Represents a checkbox button option for a select card.
 * @param {string} label - The label for the checkbox button.
 * @param {string|number} value - The value associated with the checkbox button.
 * @param {string} id - The unique identifier for the checkbox button.
 * @param {TSelectCardProps} - Additional props inherited from TSelectCardProps.
 */

/**
 * This is a checkbox component, that tries to replicate html checkboxes functionality
 * But the checkboxes have an appearance of a button.
 * @param {TSelectCardcheckboxButton[]} options - An array of checkbox button options.
 * @param {string} selectedValues - Array of selected values.
 * @param {string} name - The name attribute for the checkbox button group.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the select card checkbox component.
 * @param {InputHTMLAttributes<HTMLInputElement>} - Additional HTML input element attributes.
 */
const SelectCardCheckbox = ({
  variant,
  options,
  selectedValues,
  name,
  className,
  onSelection,
  ...rest
}: TSelectCardCheckboxProps) => {
  return (
    <div className={classNames('select-card-checkbox-component', className)}>
      {options.map((option) => {
        // Whether the option is selected.
        const isOptionSelected: boolean = selectedValues.indexOf(option.value) > -1;
        return (
          <div
            key={option.id || nanoid()}
            className={classNames('select-card-checkbox-component__option')}
          >
            <label htmlFor={option.id}>
              <SelectCard
                variant={variant}
                startIcon={option.startIcon}
                endIcon={option.endIcon}
                selected={isOptionSelected}
                className={classNames('select-card-checkbox-component__option__label')}
              >
                {option.label}
              </SelectCard>
            </label>
            <input
              type="checkbox"
              name={name}
              id={option.id}
              value={option.value}
              className={classNames('select-card-checkbox-component__option__input')}
              checked={isOptionSelected}
              onChange={(e) => {
                const updatedArray = selectDeselectCheckbox({
                  arr: [...selectedValues],
                  value: e.target.value
                });
                onSelection({ selectedValues: updatedArray });
              }}
              {...rest}
            />
          </div>
        );
      })}
    </div>
  );
};

export default SelectCardCheckbox;
