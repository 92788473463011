import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';

import { TToggleOpenDropdownProps } from './useToggleOpenDropdown.types';

const useToggleOpenDropdown = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const currentDropdownId = useAppSelector((store) => store.common.dropdownId);

  const toggleOpenDropdown = ({ dropdownId }: TToggleOpenDropdownProps) => {
    if (currentDropdownId === dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownId));
    }
  };

  return toggleOpenDropdown;
};

export default useToggleOpenDropdown;
