import { useEffect } from 'react';
import Header from 'src/components/header/Header';
import { InfiniteScroll, Typography } from 'src/components/common';
import { InviteNotifications } from 'src/components/notifications';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  incrementAllNotificationsPageNumber,
  resetAllNotifications
} from 'src/store/notifications/notifications.slice';

import './notifications.scss';

export const allNotificationsLoadingId = 'allNotificationsLoading';
export const inviteNotificationsLoadingId = 'inviteNotificationsLoading';

const Notifications = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { allNotificationsMoreItemsLeft } = useAppSelector((store) => store.notifications);
  const { loadingId } = useAppSelector((store) => store.common);

  // Constants
  const isAllNotificationsLoading = loadingId === allNotificationsLoadingId;

  const loadMoreActivities = () => {
    if (allNotificationsMoreItemsLeft && !isAllNotificationsLoading) {
      dispatch(incrementAllNotificationsPageNumber(1));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetAllNotifications());
    };
  }, []);

  return (
    <InfiniteScroll
      className="notifications"
      onScrollEnd={loadMoreActivities}
      loading={isAllNotificationsLoading}
      moreItemsLeft={allNotificationsMoreItemsLeft}
      showFooter={true}
    >
      <Header />
      <div className="notifications__body">
        <Typography
          variant="subHeading-2"
          fontWeight="semiBold"
          className="notifications__body__heading"
        >
          Notification
        </Typography>
        <InviteNotifications />
      </div>
    </InfiniteScroll>
  );
};

export default Notifications;
