import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import InputElement from 'src/components/common/formFields/inputElement/InputElement';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import { textFormatter } from 'src/utils';
import { TUserTypes } from 'src/constants/user.constants';

import './createPost.scss';
import CreateEditPostModal from '../createEditPostModal/CreateEditPostModal';

const createPostModalId = 'create-post-modal';

export type TCreatePost = {
  companyProfile: boolean;
  refetchPost?: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

const CreatePost = ({ refetchPost, companyProfile, viewerId, viewerType }: TCreatePost) => {
  const dispatch = useAppDispatch();

  const { modalId } = useAppSelector((store) => store.common);
  const { profile } = useAppSelector((store) => store.profile);
  const { companyProfilePic, myCompany } = useAppSelector((store) => store.company);

  const isModalOpen = modalId === createPostModalId;

  const openCreatePostModal = () => {
    dispatch(assignModalId(createPostModalId));
  };

  const closeCreatePostModal = () => {
    dispatch(resetModalId());
  };

  return (
    <div className="create-post">
      <Card className="create-post__card">
        <Typography
          as="p"
          variant="body-1"
          fontWeight="semiBold"
          className="create-post__card__heading"
        >
          Create Post
        </Typography>
        <div className="create-post__card__form">
          <ProfilePicture
            url={companyProfile ? companyProfilePic : profile.profilePic}
            fallback={textFormatter({ value: profile?.name[0] || myCompany?.name[0] || '' })}
            userType={viewerType}
          />
          <InputElement
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Start typing..."
            onClick={openCreatePostModal}
            value={''}
          />
        </div>
      </Card>
      {isModalOpen && (
        <CreateEditPostModal
          title="Create Post"
          companyProfile={companyProfile}
          isModalOpen={isModalOpen}
          onModalClose={closeCreatePostModal}
          refetchPost={refetchPost}
          viewerId={viewerId}
          viewerType={viewerType}
        />
      )}
    </div>
  );
};

export default CreatePost;
