import { Modal, Typography } from 'src/components/common';
import { Form, Formik } from 'formik';
import {
  TGetApplicationRequest,
  TPromoteApplicationRequest
} from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getApplication, promoteApplication } from 'src/store/program/program.slice';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useGetIdentity } from 'src/hooks';

import { TAdvanceApplicationProps } from './advanceApplication.types';
import './advanceApplication.scss';

const AdvanceApplication = ({
  programId,
  applicationId,
  isModalOpen,
  onModalClose
}: TAdvanceApplicationProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const loading: boolean = useAppSelector((store) => store.common.loading);

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const initialValues: { feedback: string } = {
    feedback: ''
  };

  const handleGetApplication = () => {
    const payload: TGetApplicationRequest = {
      applicationId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getApplication(payload));
  };

  const handleAdvanceApplication = (values: { feedback: string }) => {
    const { feedback } = values;

    const payload: TPromoteApplicationRequest = {
      programId,
      applicationId,
      ...(feedback && { feedback })
    };

    dispatch(promoteApplication(payload)).then(() => {
      handleGetApplication();
    });
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Next Stage Confirmation"
      className="advance-application"
    >
      <Formik initialValues={initialValues} onSubmit={handleAdvanceApplication}>
        {({ values, setFieldValue }) => {
          return (
            <Form className="advance-application__form">
              <Typography as="p" variant="caption" fontWeight="regular">
                To advance this application to the next stage, please provide feedback below.
              </Typography>
              <TextAreaField
                label="Application feedback"
                placeholder="Feedback"
                variant={componentSizeVariantsEnum.SMALL}
                value={values.feedback}
                onChange={(e) => {
                  setFieldValue('feedback', e.target.value);
                }}
                id="feedback"
              />
              <div className="advance-application__form__buttons">
                <Button size="small" variant="secondary" onClick={onModalClose}>
                  Cancel
                </Button>
                <Button size="small" variant="primary" type="submit" loading={loading}>
                  Confirm
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AdvanceApplication;
