import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ImageIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="21"
      height="21"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.5 6.3C1.5 4.61984 1.5 3.77976 1.82698 3.13803C2.1146 2.57354 2.57354 2.1146 3.13803 1.82698C3.77976 1.5 4.61984 1.5 6.3 1.5H14.7C16.3802 1.5 17.2202 1.5 17.862 1.82698C18.4265 2.1146 18.8854 2.57354 19.173 3.13803C19.5 3.77976 19.5 4.61984 19.5 6.3V14.7C19.5 16.3802 19.5 17.2202 19.173 17.862C18.8854 18.4265 18.4265 18.8854 17.862 19.173C17.2202 19.5 16.3802 19.5 14.7 19.5H6.3C4.61984 19.5 3.77976 19.5 3.13803 19.173C2.57354 18.8854 2.1146 18.4265 1.82698 17.862C1.5 17.2202 1.5 16.3802 1.5 14.7V6.3Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 8L4.5 13.5H16.5L12 6L9.5 9.5L7.5 8Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ImageIcon;
