import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ErrorIcon = ({ color = colors.error[800], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.0003 12.7305V20.0638M20.0003 27.3971H20.0187M38.3337 20.0638C38.3337 30.189 30.1255 38.3971 20.0003 38.3971C9.8751 38.3971 1.66699 30.189 1.66699 20.0638C1.66699 9.93858 9.8751 1.73047 20.0003 1.73047C30.1255 1.73047 38.3337 9.93858 38.3337 20.0638Z"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ErrorIcon;
