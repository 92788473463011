import { Formik, Form } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import Button from 'src/components/button/Button';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TComment, TReplyToCommentRequest } from 'src/services/apiEndpoint.types.ts';
import { replyToComment } from 'src/store/socialMedia/socialMedia.slice';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { TUserTypes } from 'src/constants/user.constants';

import './createReply.scss';
import createReplyValidationSchema from './createReply.validationSchema';

export type TCreateReply = {
  onClose: () => void;
  postId: string;
  comment: TComment;
  handleGetReplies: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

export type TReplyTOCommentFormValues = {
  text: string;
};

// This component must know id and type of the viewer
const CreateReply = ({
  onClose,
  postId,
  comment,
  handleGetReplies,
  viewerId,
  viewerType
}: TCreateReply) => {
  const initialValues: TReplyTOCommentFormValues = { text: '' };

  const dispatch = useAppDispatch();

  const profile = useAppSelector((store) => store.profile.profile);
  const replyToCommentLoader: boolean = useAppSelector<boolean>(
    (store) => store.socialMedia.replyToCommentLoader
  );
  const { companyProfilePic, myCompany } = useAppSelector((store) => store.company);

  const commentId = comment?.comments?.id;
  const createReplyProfilePic = viewerType === 'User' ? profile?.profilePic : companyProfilePic;
  const createReplyName = viewerType === 'User' ? profile?.name : myCompany?.name;

  const handleSubmit = async (
    { text }: TReplyTOCommentFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // create reply api call
    const payload: TReplyToCommentRequest = {
      userId: viewerId,
      replyId: uuidv4(),
      postId,
      text,
      commentId,
      timestamp: Date.now(),
      userType: viewerType
    };

    dispatch(replyToComment(payload)).then(() => {
      handleGetReplies();
      resetForm();
    });
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={createReplyValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form className="create-reply">
              <div className="create-reply__header">
                <ProfilePicture
                  url={createReplyProfilePic || ''}
                  fallback={createReplyName[0] || ''}
                />
                <InputField
                  placeholder="Reply"
                  id="text"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    setFieldValue('text', e.target.value);
                  }}
                  value={values.text}
                  errorMessage={errors.text}
                  isTouched={touched.text}
                />
              </div>
              <div className="create-reply__buttons">
                <Button variant="secondary" size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="primary" size="small" type="submit" loading={replyToCommentLoader}>
                  Reply
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default CreateReply;
