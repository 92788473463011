import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const SpeakerIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M21 5.99845L21 9.99845M9.25 3.49845L5.8 3.49845C4.11984 3.49845 3.27976 3.49845 2.63803 3.82543C2.07354 4.11305 1.6146 4.57199 1.32698 5.13648C0.999998 5.77821 0.999999 6.61829 1 8.29845L1 9.49845C1 10.4303 1 10.8963 1.15224 11.2638C1.35523 11.7539 1.74458 12.1432 2.23463 12.3462C2.60218 12.4984 3.06812 12.4984 4 12.4984L4 16.7484C4 16.9806 4 17.0967 4.00963 17.1945C4.10316 18.144 4.85441 18.8953 5.80397 18.9888C5.90175 18.9984 6.01783 18.9984 6.25 18.9984C6.48217 18.9984 6.59826 18.9984 6.69604 18.9888C7.64559 18.8953 8.39685 18.144 8.49037 17.1945C8.5 17.0967 8.5 16.9806 8.5 16.7484V12.4984H9.25C11.0164 12.4984 13.1772 13.4453 14.8443 14.3541C15.8168 14.8842 16.3031 15.1493 16.6216 15.1103C16.9169 15.0741 17.1402 14.9415 17.3133 14.6996C17.5 14.4386 17.5 13.9164 17.5 12.8722L17.5 3.12473C17.5 2.08044 17.5 1.5583 17.3133 1.29734C17.1402 1.05535 16.9169 0.922747 16.6216 0.886576C16.3031 0.847568 15.8168 1.11265 14.8443 1.6428C13.1772 2.55156 11.0164 3.49845 9.25 3.49845Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SpeakerIcon;
