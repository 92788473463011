import Tag from 'src/components/common/tag/Tag';
import { ReloadIcon } from 'src/components/common/common.icons';

import './filterPosts.scss';

export type TPostFilters = 'latest' | 'popular' | 'following' | 'saved' | 'post' | 'announcement';

export type TPostFilterOptions = { value: TPostFilters; label: string };

export type TFilterPosts = {
  filterOptions: TPostFilterOptions[];
  filter: string;
  setFilter: (value: TPostFilters) => void;
  reloadFeed: ({ reload }: { reload: boolean }) => void;
};

const FilterPosts = ({ filterOptions, filter, setFilter, reloadFeed }: TFilterPosts) => {
  return (
    <div className="filter-posts">
      <div className="filter-posts__options">
        {filterOptions.map(({ label, value }) => {
          return (
            <Tag
              key={value}
              size="small"
              variant={filter === value ? 'active' : 'border'}
              onClick={() => {
                setFilter(value);
              }}
            >
              {label}
            </Tag>
          );
        })}
      </div>
      <hr></hr>
      <div className="filter-posts__reload" onClick={() => reloadFeed({ reload: true })}>
        <ReloadIcon />
      </div>
    </div>
  );
};

export default FilterPosts;
