/* eslint-disable space-before-function-paren */

import { useState } from 'react';
import { Form, Formik } from 'formik';
import { Link } from 'react-router-dom';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import {
  EyeIcon,
  EyeOffIcon,
  MailIcon,
  PasswordIcon,
  PersonIcon
} from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { signup } from 'src/store/auth/auth.slice';
import { useUrlParamValue } from 'src/hooks';

import './signup.scss';
import signupValidationSchema from './signup.validationSchema';

export type TSignupFormValues = {
  name: string;
  email: string;
  password: string;
  confirmPassword: string;
};

const SignUp = () => {
  const { loading, name, email } = useAppSelector((store) => store.auth);

  /*
   ** Prefill the form with existing redux data
   */
  const initialValues: TSignupFormValues = {
    name: name || '',
    email: email || '',
    password: '',
    confirmPassword: ''
  };

  const [maskPassword, setMaskPassword] = useState<{ password: boolean; confirmPassword: boolean }>(
    { password: true, confirmPassword: true }
  );

  // Hooks
  const { paramValue } = useUrlParamValue();

  const dispatch = useAppDispatch();

  const prevRoute = paramValue({ paramName: 'prev' });

  const handleSubmit = async ({ name, email, password }: TSignupFormValues) => {
    // Signup api call
    dispatch(
      signup({
        name,
        email,
        password
      })
    );
  };

  return (
    <div className="sign-up">
      <BackNavigation text="Back" route={prevRoute || '/'} />
      <Typography as="h3" variant="heading-3" fontWeight="semiBold" className="sign-up__heading">
        Create an Account!
      </Typography>
      <Typography as="p" variant="body-2" fontWeight="regular" className="sign-up__caption">
        Your journey starts here. Create your account today
      </Typography>

      {/* Form starts */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={signupValidationSchema}
        enableReinitialize
      >
        {() => {
          return (
            <Form className="sign-up__form" noValidate>
              {/* NoValidate is used, because yup is used to validate the user input */}
              <InputField
                label="Enter Full Name"
                id="name"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Full Name"
                startIcon={<PersonIcon />}
                type="text"
              />
              <InputField
                label="Enter Email Address"
                id="email"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                startIcon={<MailIcon />}
                type="email"
              />
              <InputField
                label="Enter Password"
                id="password"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Password"
                startIcon={<PasswordIcon />}
                endIcon={maskPassword.password ? <EyeOffIcon /> : <EyeIcon />}
                maskField={maskPassword.password}
                endIconClickHandler={() =>
                  setMaskPassword((prev) => ({ ...prev, password: !prev.password }))
                }
              />
              <InputField
                label="Confirm password"
                id="confirmPassword"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Confirm password"
                startIcon={<PasswordIcon />}
                endIcon={maskPassword.confirmPassword ? <EyeOffIcon /> : <EyeIcon />}
                maskField={maskPassword.confirmPassword}
                endIconClickHandler={() =>
                  setMaskPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
                }
              />
              <Button variant="primary" size="small" type="submit" loading={loading}>
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Continue
                </Typography>
              </Button>
            </Form>
          );
        }}
      </Formik>
      {/* Form ends */}

      <div className="sign-up__footer">
        <Typography as="span" variant="body-2" fontWeight="medium">
          Already have an account?
        </Typography>
        <Link to="/login?prev=/sign-up">Login</Link>
      </div>
    </div>
  );
};

export default SignUp;
