import React from 'react';
import { useNavigate } from 'react-router-dom';
import { heroBanner } from 'src/assets/images';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';

const HeroSection: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="heroBanner">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 mb-3">
            <div className="landing-page__hero">
              <div className="landing-page__hero__heading">
                <Typography as="p" variant="heading-2" fontWeight="semiBold">
                  From{' '}
                  <Typography
                    as="span"
                    variant="heading-2"
                    fontWeight="semiBold"
                    className="landing-page__hero__heading--highlight"
                  >
                    thought
                  </Typography>{' '}
                  to{' '}
                  <Typography
                    as="span"
                    variant="heading-2"
                    fontWeight="semiBold"
                    className="landing-page__hero__heading--highlight"
                  >
                    venture
                  </Typography>
                  ,
                </Typography>
                <Typography as="p" variant="heading-2" fontWeight="semiBold">
                  your entrepreneurial adventure starts here
                </Typography>
              </div>
              <div className="landing-page__hero__sub-heading">
                <Typography as="p" variant="body-1" fontWeight="regular">
                  Ideate. Collaborate. Magic
                </Typography>
                <Typography as="p" variant="body-1" fontWeight="regular">
                  An integrated platform experience. The ultimate platform for aspiring entrepreneurs.
                </Typography>
              </div>
              <Button
                variant="primary"
                size="medium"
                className="landing-page__hero__button"
                onClick={() => navigate('/sign-up')}
              >
                Get Started!
              </Button>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 mb-3">
            <div className="heroImage">
              <img src={heroBanner} alt='hero banner'/>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
