import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const CheckMarkIcon = ({ color = colors.success[800], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="40"
      height="41"
      viewBox="0 0 40 41"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M38.3346 18.9501V20.6368C38.3324 24.5902 37.0522 28.437 34.6851 31.6035C32.3179 34.7699 28.9907 37.0863 25.1995 38.2073C21.4083 39.3282 17.3563 39.1936 13.6478 37.8235C9.93938 36.4535 6.77317 33.9213 4.6214 30.6048C2.46962 27.2882 1.44759 23.3649 1.70771 19.42C1.96783 15.4752 3.49618 11.7201 6.06482 8.71476C8.63345 5.70946 12.1047 3.615 15.961 2.74373C19.8172 1.87246 23.8518 2.27108 27.463 3.88013M38.3346 5.95964L20.0013 24.3113L14.5013 18.8113"
        stroke={color}
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CheckMarkIcon;
