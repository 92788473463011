import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const DownloadIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.5 9.5V10.3C13.5 11.4201 13.5 11.9802 13.282 12.408C13.0903 12.7843 12.7843 13.0903 12.408 13.282C11.9802 13.5 11.4201 13.5 10.3 13.5H4.7C3.57989 13.5 3.01984 13.5 2.59202 13.282C2.21569 13.0903 1.90973 12.7843 1.71799 12.408C1.5 11.9802 1.5 11.4201 1.5 10.3V9.5M10.8333 6.16667L7.5 9.5M7.5 9.5L4.16667 6.16667M7.5 9.5V1.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DownloadIcon;
