import React from 'react';
import { heroBanner, hTractionLogoWithText } from 'src/assets/images';
import Typography from 'src/components/common/typography/Typography';
import './success.scss';

const SuccessScreen = () => {
  return (
    <div className="heroBanner" style={{ paddingTop: '15px', height: '100vh', alignItems: 'center', display: 'flex' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-7 col-md-12 mb-3">
            <div className="landing-page__hero">
              <div className={'logoBox landing-page__hero__heading'}>
                <img
                  src={hTractionLogoWithText}
                  alt="hTraction logo"
                  className="page-loader__content__logo logo"
                />
                <Typography as="p" variant="heading-2" fontWeight="semiBold" style={{ textAlign: 'center' }}>
                  Thanks For <br />
                  <Typography
                    as="span"
                    variant="heading-3"
                    fontWeight="semiBold"
                    style={{ textAlign: 'center', display: 'block' }}
                    className="landing-page__hero__heading--highlight"
                  >
                    Feedback
                  </Typography>
                </Typography>
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-md-12 mb-3">
            <div className="heroImage">
              <img src={heroBanner} alt='hero banner' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
