import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const MailIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.5508 11.0638L10.1222 7.0638M6.31269 7.0638L1.88414 11.0638M1.55078 3.73047L6.99406 7.54076C7.43484 7.84931 7.65523 8.00358 7.89496 8.06334C8.10671 8.11612 8.32818 8.11612 8.53994 8.06334C8.77966 8.00358 9.00005 7.84931 9.44083 7.54076L14.8841 3.73047M4.75078 12.3971H11.6841C12.8042 12.3971 13.3643 12.3971 13.7921 12.1791C14.1684 11.9874 14.4744 11.6814 14.6661 11.3051C14.8841 10.8773 14.8841 10.3172 14.8841 9.19713V4.93047C14.8841 3.81036 14.8841 3.25031 14.6661 2.82249C14.4744 2.44616 14.1684 2.1402 13.7921 1.94846C13.3643 1.73047 12.8042 1.73047 11.6841 1.73047H4.75078C3.63068 1.73047 3.07062 1.73047 2.6428 1.94846C2.26648 2.1402 1.96051 2.44616 1.76877 2.82249C1.55078 3.25031 1.55078 3.81036 1.55078 4.93047V9.19713C1.55078 10.3172 1.55078 10.8773 1.76877 11.3051C1.96051 11.6814 2.26648 11.9874 2.6428 12.1791C3.07062 12.3971 3.63068 12.3971 4.75078 12.3971Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MailIcon;
