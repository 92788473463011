import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store/hooks';
import { Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { NonRegisteredUsers } from 'src/components/accessDenied';

import './accessDenied.scss';

const AccessDenied = () => {
  const isAuthorized: boolean = useAppSelector<boolean>((store) => store.auth.isAuthorized);

  const navigate = useNavigate();

  const handleHome = () => {
    if (isAuthorized) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  if (!isAuthorized) {
    return <NonRegisteredUsers />;
  }

  return (
    <div className="access-denied">
      <div className="access-denied__content">
        <Typography variant="display-3" fontWeight="bold">
          Access Denied!
        </Typography>
        <Typography as="p" variant="body-1" fontWeight="regular">
          Oops! Looks like you don&apos;t have permission to view this page.
        </Typography>
        <Button variant="primary" size="large" onClick={handleHome}>
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default AccessDenied;
