import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PeopleIcon = ({ color = colors.primary[100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="26"
      height="24"
      viewBox="0 0 26 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.9983 16.5389C21.6969 17.3921 23.1532 18.7614 24.2161 20.4737C24.4266 20.8128 24.5319 20.9824 24.5683 21.2171C24.6422 21.6942 24.316 22.2808 23.8716 22.4696C23.6529 22.5625 23.407 22.5625 22.915 22.5625M17.665 11.5168C19.3937 10.6577 20.5817 8.87384 20.5817 6.8125C20.5817 4.75116 19.3937 2.9673 17.665 2.10822M15.3317 6.8125C15.3317 9.71199 12.9812 12.0625 10.0817 12.0625C7.18218 12.0625 4.83168 9.71199 4.83168 6.8125C4.83168 3.913 7.18218 1.5625 10.0817 1.5625C12.9812 1.5625 15.3317 3.913 15.3317 6.8125ZM1.98411 20.1572C3.84414 17.3645 6.77928 15.5625 10.0817 15.5625C13.3841 15.5625 16.3192 17.3645 18.1792 20.1572C18.5867 20.769 18.7905 21.075 18.767 21.4657C18.7487 21.77 18.5493 22.1425 18.3062 22.3264C17.9939 22.5625 17.5645 22.5625 16.7056 22.5625H3.45776C2.59888 22.5625 2.16944 22.5625 1.85719 22.3264C1.61407 22.1425 1.41461 21.77 1.39635 21.4657C1.37289 21.075 1.57663 20.769 1.98411 20.1572Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PeopleIcon;
