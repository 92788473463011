import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { Typography, Modal } from 'src/components/common';
import { ErrorIcon } from 'src/components/common/common.icons';
import { deleteExperience } from 'src/store/profile/profile.slice';
import { TDeleteExperienceRequest } from 'src/services/apiEndpoint.types.ts';

import './deleteWorkExperience.scss';
import { TCompanyCard } from '../companyCard/CompanyCard';

export type TDeleteWorkExperience = {
  isModalOpen: boolean;
  experienceToUpdate: number;
  onModalClose: () => void;
};

const DeleteWorkExperience = ({
  isModalOpen,
  experienceToUpdate,
  onModalClose
}: TDeleteWorkExperience) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector((store) => store.profile.profile);
  const loading: boolean = useAppSelector<boolean>((store) => store.common.loading);

  const handleDelete = () => {
    const experience = [...((profile.experience || []) as unknown as TCompanyCard[])];

    experience.splice(experienceToUpdate, 1);

    const payload: TDeleteExperienceRequest = {
      id: profile.id,
      experience
    };

    // delete experience api call
    dispatch(deleteExperience(payload));
  };

  return (
    <Modal isModalOpen={isModalOpen} onModalClose={onModalClose} className="delete-work-experience">
      <div className="delete-work-experience__header">
        <div className="delete-work-experience__header__icon">
          <ErrorIcon />
        </div>
      </div>
      <Typography
        className="delete-work-experience__title"
        as="h4"
        variant="subHeading-2"
        fontWeight="semiBold"
      >
        Are you sure you want to delete this work experience record?
      </Typography>

      <div className="delete-work-experience__buttons">
        <Button
          className="delete-work-experience__buttons__button"
          variant="secondary"
          size={componentSizeVariantsEnum.SMALL}
          onClick={onModalClose}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Cancel
          </Typography>
        </Button>
        <Button
          className="delete-work-experience__buttons__button"
          variant="primary"
          size={componentSizeVariantsEnum.SMALL}
          onClick={handleDelete}
          loading={loading}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Delete
          </Typography>
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteWorkExperience;
