export type TInitialState = {
  // View program
  viewProgram: any;
};

export const initialState: TInitialState = {
  // View program
  viewProgram: {
    id: '',
    companyId: '',
    userId: '',
    title: '',
    startDate: '',
    endDate: '',
    stages: 0,
    programDetails: [],
    applicationForm: { sections: [] },
    status: 'Active',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    company: { id: '', email: '', name: '' }
  }
};
