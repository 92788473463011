import * as Yup from 'yup';
import { EMAIL } from 'src/constants/regex.constants';

const applicantDetailsValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter your full name'),
  mobile: Yup.string().required('Please enter your Mobile Number'),
  email: Yup.string()
    .matches(EMAIL, 'Please enter a valid email address')
    .required('Please enter your email')
});

export default applicantDetailsValidationSchema;
