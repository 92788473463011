export const countries = [
  {
    id: '1',
    label: 'Afghanistan',
    value: 'Afghanistan'
  },
  {
    id: '2',
    label: 'Albania',
    value: 'Albania'
  },
  {
    id: '3',
    label: 'Algeria',
    value: 'Algeria'
  },
  {
    id: '4',
    label: 'Andorra',
    value: 'Andorra'
  },
  {
    id: '5',
    label: 'Angola',
    value: 'Angola'
  },
  {
    id: '6',
    label: 'Antigua and Barbuda',
    value: 'Antigua and Barbuda'
  },
  {
    id: '7',
    label: 'Argentina',
    value: 'Argentina'
  },
  {
    id: '8',
    label: 'Armenia',
    value: 'Armenia'
  },
  {
    id: '9',
    label: 'Australia',
    value: 'Australia'
  },
  {
    id: '10',
    label: 'Austria',
    value: 'Austria'
  },
  {
    id: '11',
    label: 'Azerbaijan',
    value: 'Azerbaijan'
  },
  {
    id: '12',
    label: 'Bahamas',
    value: 'Bahamas'
  },
  {
    id: '13',
    label: 'Bahrain',
    value: 'Bahrain'
  },
  {
    id: '14',
    label: 'Bangladesh',
    value: 'Bangladesh'
  },
  {
    id: '15',
    label: 'Barbados',
    value: 'Barbados'
  },
  {
    id: '16',
    label: 'Belarus',
    value: 'Belarus'
  },
  {
    id: '17',
    label: 'Belgium',
    value: 'Belgium'
  },
  {
    id: '18',
    label: 'Belize',
    value: 'Belize'
  },
  {
    id: '19',
    label: 'Benin',
    value: 'Benin'
  },
  {
    id: '20',
    label: 'Bhutan',
    value: 'Bhutan'
  },
  {
    id: '21',
    label: 'Bolivia',
    value: 'Bolivia'
  },
  {
    id: '22',
    label: 'Bosnia and Herzegovina',
    value: 'Bosnia and Herzegovina'
  },
  {
    id: '23',
    label: 'Botswana',
    value: 'Botswana'
  },
  {
    id: '24',
    label: 'Brazil',
    value: 'Brazil'
  },
  {
    id: '25',
    label: 'Brunei',
    value: 'Brunei'
  },
  {
    id: '26',
    label: 'Bulgaria',
    value: 'Bulgaria'
  },
  {
    id: '27',
    label: 'Burkina Faso',
    value: 'Burkina Faso'
  },
  {
    id: '28',
    label: 'Burundi',
    value: 'Burundi'
  },
  {
    id: '29',
    label: "Côte d'Ivoire",
    value: "Côte d'Ivoire"
  },
  {
    id: '30',
    label: 'Cabo Verde',
    value: 'Cabo Verde'
  },
  {
    id: '31',
    label: 'Cambodia',
    value: 'Cambodia'
  },
  {
    id: '32',
    label: 'Cameroon',
    value: 'Cameroon'
  },
  {
    id: '33',
    label: 'Canada',
    value: 'Canada'
  },
  {
    id: '34',
    label: 'Central African Republic',
    value: 'Central African Republic'
  },
  {
    id: '35',
    label: 'Chad',
    value: 'Chad'
  },
  {
    id: '36',
    label: 'Chile',
    value: 'Chile'
  },
  {
    id: '37',
    label: 'China',
    value: 'China'
  },
  {
    id: '38',
    label: 'Colombia',
    value: 'Colombia'
  },
  {
    id: '39',
    label: 'Comoros',
    value: 'Comoros'
  },
  {
    id: '40',
    label: 'Congo (Congo-Brazzaville)',
    value: 'Congo (Congo-Brazzaville)'
  },
  {
    id: '41',
    label: 'Costa Rica',
    value: 'Costa Rica'
  },
  {
    id: '42',
    label: 'Croatia',
    value: 'Croatia'
  },
  {
    id: '43',
    label: 'Cuba',
    value: 'Cuba'
  },
  {
    id: '44',
    label: 'Cyprus',
    value: 'Cyprus'
  },
  {
    id: '45',
    label: 'Czechia (Czech Republic)',
    value: 'Czechia (Czech Republic)'
  },
  {
    id: '46',
    label: 'Democratic Republic of the Congo',
    value: 'Democratic Republic of the Congo'
  },
  {
    id: '47',
    label: 'Denmark',
    value: 'Denmark'
  },
  {
    id: '48',
    label: 'Djibouti',
    value: 'Djibouti'
  },
  {
    id: '49',
    label: 'Dominica',
    value: 'Dominica'
  },
  {
    id: '50',
    label: 'Dominican Republic',
    value: 'Dominican Republic'
  },
  {
    id: '51',
    label: 'Ecuador',
    value: 'Ecuador'
  },
  {
    id: '52',
    label: 'Egypt',
    value: 'Egypt'
  },
  {
    id: '53',
    label: 'El Salvador',
    value: 'El Salvador'
  },
  {
    id: '54',
    label: 'Equatorial Guinea',
    value: 'Equatorial Guinea'
  },
  {
    id: '55',
    label: 'Eritrea',
    value: 'Eritrea'
  },
  {
    id: '56',
    label: 'Estonia',
    value: 'Estonia'
  },
  {
    id: '57',
    label: 'Eswatini (fmr. "Swaziland")',
    value: 'Eswatini (fmr. "Swaziland")'
  },
  {
    id: '58',
    label: 'Ethiopia',
    value: 'Ethiopia'
  },
  {
    id: '59',
    label: 'Fiji',
    value: 'Fiji'
  },
  {
    id: '60',
    label: 'Finland',
    value: 'Finland'
  },
  {
    id: '61',
    label: 'France',
    value: 'France'
  },
  {
    id: '62',
    label: 'Gabon',
    value: 'Gabon'
  },
  {
    id: '63',
    label: 'Gambia',
    value: 'Gambia'
  },
  {
    id: '64',
    label: 'Georgia',
    value: 'Georgia'
  },
  {
    id: '65',
    label: 'Germany',
    value: 'Germany'
  },
  {
    id: '66',
    label: 'Ghana',
    value: 'Ghana'
  },
  {
    id: '67',
    label: 'Greece',
    value: 'Greece'
  },
  {
    id: '68',
    label: 'Grenada',
    value: 'Grenada'
  },
  {
    id: '69',
    label: 'Guatemala',
    value: 'Guatemala'
  },
  {
    id: '70',
    label: 'Guinea',
    value: 'Guinea'
  },
  {
    id: '71',
    label: 'Guinea-Bissau',
    value: 'Guinea-Bissau'
  },
  {
    id: '72',
    label: 'Guyana',
    value: 'Guyana'
  },
  {
    id: '73',
    label: 'Haiti',
    value: 'Haiti'
  },
  {
    id: '74',
    label: 'Holy See',
    value: 'Holy See'
  },
  {
    id: '75',
    label: 'Honduras',
    value: 'Honduras'
  },
  {
    id: '76',
    label: 'Hungary',
    value: 'Hungary'
  },
  {
    id: '77',
    label: 'Iceland',
    value: 'Iceland'
  },
  {
    id: '78',
    label: 'India',
    value: 'India'
  },
  {
    id: '79',
    label: 'Indonesia',
    value: 'Indonesia'
  },
  {
    id: '80',
    label: 'Iran',
    value: 'Iran'
  },
  {
    id: '81',
    label: 'Iraq',
    value: 'Iraq'
  },
  {
    id: '82',
    label: 'Ireland',
    value: 'Ireland'
  },
  {
    id: '83',
    label: 'Israel',
    value: 'Israel'
  },
  {
    id: '84',
    label: 'Italy',
    value: 'Italy'
  },
  {
    id: '85',
    label: 'Jamaica',
    value: 'Jamaica'
  },
  {
    id: '86',
    label: 'Japan',
    value: 'Japan'
  },
  {
    id: '87',
    label: 'Jordan',
    value: 'Jordan'
  },
  {
    id: '88',
    label: 'Kazakhstan',
    value: 'Kazakhstan'
  },
  {
    id: '89',
    label: 'Kenya',
    value: 'Kenya'
  },
  {
    id: '90',
    label: 'Kiribati',
    value: 'Kiribati'
  },
  {
    id: '91',
    label: 'Kuwait',
    value: 'Kuwait'
  },
  {
    id: '92',
    label: 'Kyrgyzstan',
    value: 'Kyrgyzstan'
  },
  {
    id: '93',
    label: 'Laos',
    value: 'Laos'
  },
  {
    id: '94',
    label: 'Latvia',
    value: 'Latvia'
  },
  {
    id: '95',
    label: 'Lebanon',
    value: 'Lebanon'
  },
  {
    id: '96',
    label: 'Lesotho',
    value: 'Lesotho'
  },
  {
    id: '97',
    label: 'Liberia',
    value: 'Liberia'
  },
  {
    id: '98',
    label: 'Libya',
    value: 'Libya'
  },
  {
    id: '99',
    label: 'Liechtenstein',
    value: 'Liechtenstein'
  },
  {
    id: '100',
    label: 'Lithuania',
    value: 'Lithuania'
  },
  {
    id: '101',
    label: 'Luxembourg',
    value: 'Luxembourg'
  },
  {
    id: '102',
    label: 'Madagascar',
    value: 'Madagascar'
  },
  {
    id: '103',
    label: 'Malawi',
    value: 'Malawi'
  },
  {
    id: '104',
    label: 'Malaysia',
    value: 'Malaysia'
  },
  {
    id: '105',
    label: 'Maldives',
    value: 'Maldives'
  },
  {
    id: '106',
    label: 'Mali',
    value: 'Mali'
  },
  {
    id: '107',
    label: 'Malta',
    value: 'Malta'
  },
  {
    id: '108',
    label: 'Marshall Islands',
    value: 'Marshall Islands'
  },
  {
    id: '109',
    label: 'Mauritania',
    value: 'Mauritania'
  },
  {
    id: '110',
    label: 'Mauritius',
    value: 'Mauritius'
  },
  {
    id: '111',
    label: 'Mexico',
    value: 'Mexico'
  },
  {
    id: '112',
    label: 'Micronesia',
    value: 'Micronesia'
  },
  {
    id: '113',
    label: 'Moldova',
    value: 'Moldova'
  },
  {
    id: '114',
    label: 'Monaco',
    value: 'Monaco'
  },
  {
    id: '115',
    label: 'Mongolia',
    value: 'Mongolia'
  },
  {
    id: '116',
    label: 'Montenegro',
    value: 'Montenegro'
  },
  {
    id: '117',
    label: 'Morocco',
    value: 'Morocco'
  },
  {
    id: '118',
    label: 'Mozambique',
    value: 'Mozambique'
  },
  {
    id: '119',
    label: 'Myanmar (formerly Burma)',
    value: 'Myanmar (formerly Burma)'
  },
  {
    id: '120',
    label: 'Namibia',
    value: 'Namibia'
  },
  {
    id: '121',
    label: 'Nauru',
    value: 'Nauru'
  },
  {
    id: '122',
    label: 'Nepal',
    value: 'Nepal'
  },
  {
    id: '123',
    label: 'Netherlands',
    value: 'Netherlands'
  },
  {
    id: '124',
    label: 'New Zealand',
    value: 'New Zealand'
  },
  {
    id: '125',
    label: 'Nicaragua',
    value: 'Nicaragua'
  },
  {
    id: '126',
    label: 'Niger',
    value: 'Niger'
  },
  {
    id: '127',
    label: 'Nigeria',
    value: 'Nigeria'
  },
  {
    id: '128',
    label: 'North Korea',
    value: 'North Korea'
  },
  {
    id: '129',
    label: 'North Macedonia',
    value: 'North Macedonia'
  },
  {
    id: '130',
    label: 'Norway',
    value: 'Norway'
  },
  {
    id: '131',
    label: 'Oman',
    value: 'Oman'
  },
  {
    id: '132',
    label: 'Pakistan',
    value: 'Pakistan'
  },
  {
    id: '133',
    label: 'Palau',
    value: 'Palau'
  },
  {
    id: '134',
    label: 'Palestine State',
    value: 'Palestine State'
  },
  {
    id: '135',
    label: 'Panama',
    value: 'Panama'
  },
  {
    id: '136',
    label: 'Papua New Guinea',
    value: 'Papua New Guinea'
  },
  {
    id: '137',
    label: 'Paraguay',
    value: 'Paraguay'
  },
  {
    id: '138',
    label: 'Peru',
    value: 'Peru'
  },
  {
    id: '139',
    label: 'Philippines',
    value: 'Philippines'
  },
  {
    id: '140',
    label: 'Poland',
    value: 'Poland'
  },
  {
    id: '141',
    label: 'Portugal',
    value: 'Portugal'
  },
  {
    id: '142',
    label: 'Qatar',
    value: 'Qatar'
  },
  {
    id: '143',
    label: 'Romania',
    value: 'Romania'
  },
  {
    id: '144',
    label: 'Russia',
    value: 'Russia'
  },
  {
    id: '145',
    label: 'Rwanda',
    value: 'Rwanda'
  },
  {
    id: '146',
    label: 'Saint Kitts and Nevis',
    value: 'Saint Kitts and Nevis'
  },
  {
    id: '147',
    label: 'Saint Lucia',
    value: 'Saint Lucia'
  },
  {
    id: '148',
    label: 'Saint Vincent and the Grenadines',
    value: 'Saint Vincent and the Grenadines'
  },
  {
    id: '149',
    label: 'Samoa',
    value: 'Samoa'
  },
  {
    id: '150',
    label: 'San Marino',
    value: 'San Marino'
  },
  {
    id: '151',
    label: 'Sao Tome and Principe',
    value: 'Sao Tome and Principe'
  },
  {
    id: '152',
    label: 'Saudi Arabia',
    value: 'Saudi Arabia'
  },
  {
    id: '153',
    label: 'Senegal',
    value: 'Senegal'
  },
  {
    id: '154',
    label: 'Serbia',
    value: 'Serbia'
  },
  {
    id: '155',
    label: 'Seychelles',
    value: 'Seychelles'
  },
  {
    id: '156',
    label: 'Sierra Leone',
    value: 'Sierra Leone'
  },
  {
    id: '157',
    label: 'Singapore',
    value: 'Singapore'
  },
  {
    id: '158',
    label: 'Slovakia',
    value: 'Slovakia'
  },
  {
    id: '159',
    label: 'Slovenia',
    value: 'Slovenia'
  },
  {
    id: '160',
    label: 'Solomon Islands',
    value: 'Solomon Islands'
  },
  {
    id: '161',
    label: 'Somalia',
    value: 'Somalia'
  },
  {
    id: '162',
    label: 'South Africa',
    value: 'South Africa'
  },
  {
    id: '163',
    label: 'South Korea',
    value: 'South Korea'
  },
  {
    id: '164',
    label: 'South Sudan',
    value: 'South Sudan'
  },
  {
    id: '165',
    label: 'Spain',
    value: 'Spain'
  },
  {
    id: '166',
    label: 'Sri Lanka',
    value: 'Sri Lanka'
  },
  {
    id: '167',
    label: 'Sudan',
    value: 'Sudan'
  },
  {
    id: '168',
    label: 'Suriname',
    value: 'Suriname'
  },
  {
    id: '169',
    label: 'Sweden',
    value: 'Sweden'
  },
  {
    id: '170',
    label: 'Switzerland',
    value: 'Switzerland'
  },
  {
    id: '171',
    label: 'Syria',
    value: 'Syria'
  },
  {
    id: '172',
    label: 'Tajikistan',
    value: 'Tajikistan'
  },
  {
    id: '173',
    label: 'Tanzania',
    value: 'Tanzania'
  },
  {
    id: '174',
    label: 'Thailand',
    value: 'Thailand'
  },
  {
    id: '175',
    label: 'Timor-Leste',
    value: 'Timor-Leste'
  },
  {
    id: '176',
    label: 'Togo',
    value: 'Togo'
  },
  {
    id: '177',
    label: 'Tonga',
    value: 'Tonga'
  },
  {
    id: '178',
    label: 'Trinidad and Tobago',
    value: 'Trinidad and Tobago'
  },
  {
    id: '179',
    label: 'Tunisia',
    value: 'Tunisia'
  },
  {
    id: '180',
    label: 'Turkey',
    value: 'Turkey'
  },
  {
    id: '181',
    label: 'Turkmenistan',
    value: 'Turkmenistan'
  },
  {
    id: '182',
    label: 'Tuvalu',
    value: 'Tuvalu'
  },
  {
    id: '183',
    label: 'Uganda',
    value: 'Uganda'
  },
  {
    id: '184',
    label: 'Ukraine',
    value: 'Ukraine'
  },
  {
    id: '185',
    label: 'United Arab Emirates',
    value: 'United Arab Emirates'
  },
  {
    id: '186',
    label: 'United Kingdom',
    value: 'United Kingdom'
  },
  {
    id: '187',
    label: 'United States of America',
    value: 'United States of America'
  },
  {
    id: '188',
    label: 'Uruguay',
    value: 'Uruguay'
  },
  {
    id: '189',
    label: 'Uzbekistan',
    value: 'Uzbekistan'
  },
  {
    id: '190',
    label: 'Vanuatu',
    value: 'Vanuatu'
  },
  {
    id: '191',
    label: 'Venezuela',
    value: 'Venezuela'
  },
  {
    id: '192',
    label: 'Vietnam',
    value: 'Vietnam'
  },
  {
    id: '193',
    label: 'Yemen',
    value: 'Yemen'
  },
  {
    id: '194',
    label: 'Zambia',
    value: 'Zambia'
  },
  {
    id: '195',
    label: 'Zimbabwe',
    value: 'Zimbabwe'
  },
  {
    id: '196',
    label: '',
    value: ''
  }
];
