import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Typography from 'src/components/common/typography/Typography';
import Like from 'src/components/common/like/Like';
import { TDropdownOptions, TDropdown, SubMenuModal, Dropdown, Loader } from 'src/components/common';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import Comment from 'src/components/common/comment/Comment';

import {
  TComment,
  TDeleteCommentRequest,
  TGetRepliesRequest,
  TLikeCommentRequest,
  TUnLikeCommentRequest
} from 'src/services/apiEndpoint.types.ts';
import { transformTextToHyperlink } from 'src/utils';
import {
  clearReplies,
  deleteComment,
  getReplies,
  incrementRepliesPage,
  likeComment,
  unLikeComment,
  updateShowRepliesId
} from 'src/store/socialMedia/socialMedia.slice';
import { useCopyToClipboard } from 'src/hooks';
import { TUserTypes } from 'src/constants/user.constants';

import './viewComment.scss';

import ViewReply from '../viewReply/ViewReply';
import CreateReply from '../createReply/CreateReply';
import EditComment from '../editComment/EditComment';

export type TViewComment = {
  comment: TComment;
  postId: string;
  handleGetComments: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

// This component must be aware of the id and type of the viewer
const ViewComment = ({
  comment,
  postId,
  handleGetComments,
  viewerId,
  viewerType
}: TViewComment) => {
  const commentTextRef = useRef<HTMLElement>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyToClipboard();

  const {
    repliesPageNumber,
    repliesPerPage,
    replies,
    showRepliesId,
    repliesLoading,
    moreRepliesLeft
  } = useAppSelector((store) => store.socialMedia);
  const dropdownId: string = useAppSelector<string>((store) => store.common.dropdownId);
  const commentId = comment?.comments?.id;
  const commenterCreatorType = comment?.userType[0] as TUserTypes;
  const showReplies = commentId === showRepliesId;
  const commentCreatorId = comment?.user?.id;
  const isViewerCreatorOfComment = viewerId === commentCreatorId;
  const commentCreatorProfilePic: string =
    commenterCreatorType === 'User' ? comment?.user?.profilePic : comment?.user?.logo;
  const commentCreatorName: string = comment?.user?.name;
  console.log(commentCreatorProfilePic, 'commentt')
  const viewUserProfile = () => {
    const prevRoute: string = `${window.location.pathname}?postId=${postId}`;
    if (isViewerCreatorOfComment) {
      navigate(
        `/my-${commenterCreatorType === 'User' ? `profile?prev=${prevRoute}` : `company?prev=${prevRoute}`}`
      );
    } else {
      navigate(
        `/view-${commenterCreatorType === 'User' ? 'profile' : 'company'}?id=${commentCreatorId}&prev=${prevRoute}`
      );
    }
  };

  const copyComment = () => {
    copyToClipboard({ text: comment?.comments?.text });
  };

  const viewCommentDropdownItems: TDropdownOptions[] = [
    { label: <>View user&apos;s profile</>, onClick: viewUserProfile },
    { label: <>Copy</>, onClick: copyComment }
  ];

  const handleDeleteComment = () => {
    const payload: TDeleteCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      postId,
      commentId
    };

    dispatch(deleteComment(payload));
  };

  const handleLikeComment = () => {
    const payload: TLikeCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      postId,
      commentId
    };

    dispatch(likeComment(payload));
  };

  const handleUnLikeComment = () => {
    const payload: TUnLikeCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      postId,
      commentId
    };

    dispatch(unLikeComment(payload));
  };

  const dropdownOptions: TDropdown = {
    id: `post-comment-dropdown-${commentId}`,
    size: 'large',
    alignment: 'center',
    items: viewCommentDropdownItems
  };

  const postMoreOptions = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  const handleGetReplies = () => {
    const payload: TGetRepliesRequest = {
      userId: viewerId,
      userType: viewerType,
      pageNumber: repliesPageNumber,
      commentsPerPage: repliesPerPage,
      commentId
    };

    dispatch(getReplies(payload));
  };

  const onRepliesClick = () => {
    dispatch(updateShowRepliesId(commentId));
  };

  const handleCloseCreateComment = () => {
    dispatch(clearReplies());
  };

  if (isViewerCreatorOfComment) {
    // Enable edit of comment, if the viewer is creator of this comment
    viewCommentDropdownItems.push(
      {
        label: <>Edit</>,
        onClick: () => {
          setEditMode(true);
        }
      },
      { label: <>Delete</>, onClick: handleDeleteComment }
    );
  }

  const handleFetchMoreReplies = () => {
    dispatch(incrementRepliesPage(1));
  };

  useEffect(() => {
    if (showReplies) {
      handleGetReplies();
    }
  }, [showReplies, repliesPageNumber]);

  useEffect(() => {
    const ele: HTMLElement | null = commentTextRef.current;
    if (ele) {
      const text = ele.innerText;
      const replacedText = transformTextToHyperlink({ text });
      ele.innerHTML = replacedText;
    }
  }, [commentTextRef.current]);

  if (editMode) {
    return (
      <EditComment
        onClose={() => setEditMode(false)}
        comment={comment}
        postId={postId}
        handleGetComments={handleGetComments}
        viewerId={viewerId}
        viewerType={viewerType}
      />
    );
  }

  return (
    <>
      <div className="view-comment">
        <div className="view-comment__content">
          <div className="view-comment__content__left">
            <ProfilePicture url={commentCreatorProfilePic || ''} fallback={commentCreatorName[0]} />
          </div>
          <div className="view-comment__content__right">
            <div className="view-comment__content__right__header">
              <div className="view-comment__content__right__header__row">
                <div className="view-comment__content__right__header__row__user-info">
                  <Typography
                    as="span"
                    variant="body-2"
                    fontWeight="semiBold"
                    className="view-comment__content__right__header__row__user-info__name"
                  >
                    {commentCreatorName}
                  </Typography>
                  <hr></hr>
                  <Typography
                    as="span"
                    variant="caption"
                    fontWeight="regular"
                    className="view-comment__content__right__header__row__user-info__role"
                  >
                    {comment?.user?.role}
                  </Typography>
                </div>
                <div
                  className="view-comment__content__right__header__row__dropdown"
                  onClick={(e) => {
                    e.stopPropagation();
                    postMoreOptions();
                  }}
                >
                  {<MoreOptionsIcon />}{' '}
                  <SubMenuModal>
                    <Dropdown {...dropdownOptions} />
                  </SubMenuModal>
                </div>
              </div>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="regular"
                className="view-comment__content__right__header__content"
                typographyRef={commentTextRef}
              >
                {comment?.comments?.text}
              </Typography>
            </div>
            <div className="view-comment__content__right__interactions">
              <Like
                count={comment?.comments?.likeCount}
                onLike={handleLikeComment}
                onUnLike={handleUnLikeComment}
                className="view-comment__content__right__interactions__like"
                active={comment?.comments?.likedByUser}
              />
              <Comment
                count={comment?.comments?.replyCount}
                onClick={onRepliesClick}
                className="view-comment__content__right__interactions__replies"
                isActive={showReplies}
              />
            </div>{' '}
          </div>
        </div>

        {/*
         ** Replies
         */}
        {showReplies && (
          <>
            <div className="view-comment__replies">
              {replies.map((reply, index) => {
                return (
                  <ViewReply
                    postId={postId}
                    key={index}
                    reply={reply}
                    commentId={commentId}
                    handleGetReplies={handleGetReplies}
                    viewerId={viewerId}
                    viewerType={viewerType}
                  />
                );
              })}
              {repliesLoading && <Loader />}
              {moreRepliesLeft && !repliesLoading && (
                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="semiBold"
                  className="view-post__load-more-comments"
                  onClick={handleFetchMoreReplies}
                >
                  Show more replies
                </Typography>
              )}
              <CreateReply
                onClose={handleCloseCreateComment}
                postId={postId}
                comment={comment}
                handleGetReplies={handleGetReplies}
                viewerId={viewerId}
                viewerType={viewerType}
              />
            </div>{' '}
          </>
        )}
      </div>
    </>
  );
};

export default ViewComment;
