import { useContext, useState } from 'react';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';

import './workExperience.scss';
import CompanyCard, { TCompanyCard, updateExperienceModalId } from './companyCard/CompanyCard';
import UpdateWorkExperience from './updateWorkExperience/UpdateWorkExperience';

export type TWorkExperience = {
  experiences: TCompanyCard[];
};

const WorkExperience = ({ experiences = [] }: TWorkExperience) => {
  const [updateWorkExperienceMode, setUpdateWorkExperienceMode] = useState<'Add' | 'Update'>('Add');
  const [experienceToUpdate, setExperienceToUpdate] = useState<number>(0);

  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.
  const dispatch = useAppDispatch();
  const { modalId } = useAppSelector((store) => store.common);

  // Constants
  const isWorkExperienceModalOpen = modalId === updateExperienceModalId;

  const handleOpenModal = () => {
    dispatch(assignModalId(updateExperienceModalId));
  };

  const handleCloseModal = () => {
    dispatch(assignModalId(''));
  };

  return (
    <div className="work-experience">
      <div className="work-experience__header">
        <Typography
          as="h5"
          variant="subHeading-2"
          fontWeight="semiBold"
          className="work-experience__header__title"
        >
          Work Experience
        </Typography>
        {isSelfUser && (
          <Button
            variant="secondary"
            size="extraSmall"
            className="work-experience__header__action"
            onClick={() => {
              setUpdateWorkExperienceMode('Add');
              dispatch(handleOpenModal);
            }}
          >
            Add Experience
          </Button>
        )}
      </div>

      {/*
       ** Company Cards
       */}
      <div className="work-experience__companies">
        {experiences.map(
          (
            {
              jobTitle,
              employmentType,
              companyName,
              start,
              end,
              currentlyWorking,
              description
            }: TCompanyCard,
            index
          ) => {
            return (
              <CompanyCard
                key={index}
                jobTitle={jobTitle}
                employmentType={employmentType}
                companyName={companyName}
                start={start}
                end={end}
                currentlyWorking={currentlyWorking}
                description={description}
                experienceIndex={index}
                experienceToUpdate={experienceToUpdate}
                setExperienceToUpdate={setExperienceToUpdate}
                setUpdateWorkExperienceMode={setUpdateWorkExperienceMode}
              />
            );
          }
        )}
      </div>

      {/*
       ** Add or update work experience
       */}
      {isSelfUser && isWorkExperienceModalOpen && (
        <UpdateWorkExperience
          isModalOpen={isWorkExperienceModalOpen}
          onModalClose={handleCloseModal}
          mode={updateWorkExperienceMode}
          experienceToUpdate={experienceToUpdate}
          experiences={experiences}
        />
      )}
    </div>
  );
};

export default WorkExperience;
