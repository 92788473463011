import React, { InputHTMLAttributes, memo, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { nanoid } from 'nanoid';

import './verificationCode.scss';

import Typography from '../../typography/Typography';
import InputElement from '../inputElement/InputElement';

type TVerificationCodeProps = {
  id?: string;
  fieldsCount: number;
  label: string;
  value: string[];
  setVerificationCode: React.Dispatch<React.SetStateAction<string[]>>;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Props for the VerificationCode component.
 * @typedef {Object} TVerificationCodeProps
 * @param {string} [id] - The ID attribute for the verification code component.
 * @param {number} fieldsCount - The number of input fields for the verification code.
 * @param {string} label - The label for the verification code component.
 * @param {string} [value] - The value of the verification code.
 * @param {InputHTMLAttributes<HTMLInputElement>} [HTMLInputElement] - HTML attributes of the input element.
 */
const VerificationCode = ({
  label,
  id,
  value,
  className,
  fieldsCount,
  setVerificationCode,
  ...rest
}: TVerificationCodeProps) => {
  // Creates unique id, if not available
  const inputId: string = id || nanoid();
  const inputRefs = useRef<HTMLInputElement[]>([]);
  /*
   ** The input the focus is on
   ** 0 -> First input field
   */
  const [focusIndex, setFocusIndex] = useState<number>(0);

  const inputsArray = [...new Array(fieldsCount).keys()];

  useEffect(() => {
    /*
     ** Updating the focus based on {focusIndex} value
     */
    const element = inputRefs.current[focusIndex];
    if (element) {
      element.focus();
    }
  }, [focusIndex, JSON.stringify(value)]);

  return (
    <div className={classNames(className, 'verification-code-component')}>
      <Typography
        htmlFor={inputId}
        as="label"
        variant="body-1"
        fontWeight="semiBold"
        className="verification-code-component__label"
      >
        {label}
      </Typography>
      <div className="verification-code-component__input-fields">
        {inputsArray.map((index) => {
          return (
            <InputElement
              {...rest}
              key={nanoid()}
              value={value[index] || ''}
              className="verification-code-component__input-fields__input-component"
              inputRef={(el: HTMLInputElement) => {
                inputRefs.current[index] = el;
              }}
              onClick={() => {
                /*
                 ** Updating the focus index onclick
                 ** On typing, focus will be set to next input field
                 */
                setFocusIndex(index);
              }}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  /*
                   ** On change focus to next field, when user adds input
                   */
                  setFocusIndex(index + value?.length || 1);
                  setVerificationCode((prev) => {
                    // creating a new array of current state
                    const newArray = JSON.parse(JSON.stringify(prev));

                    // if the user pastes the otp from the clipboard
                    if (value?.length > 1) {
                      // split the value in to any array of single character
                      // '123' becomes => ['1', '2', '3']
                      value.split('').forEach((item, index) => {
                        // looping through the each item of split array and inserting into the new array index.
                        // The otp input item which is focus is very important.
                        // for e.g. if the user focus is 2nd input field ie. [] [*] [] [] []
                        // focusIndex will be 1, and first item in the loop will be inserted at index 1.
                        newArray[focusIndex + index] = item;
                      });
                    } else {
                      // If the user enters only one digit
                      newArray[index] = value;
                    }

                    // restricting the array to fieldsCount which is the number of characters in OTP field.
                    newArray.length = fieldsCount;
                    return newArray;
                  });
                }
              }}
              onKeyDown={(e) => {
                // When user enters backspace
                if (e.key === 'Backspace') {
                  setVerificationCode((prev) => {
                    const newArray = JSON.parse(JSON.stringify(prev));

                    // empty the value from the input the user has focused.
                    newArray[focusIndex] = '';
                    return newArray;
                  });

                  // Moving the focus to the previous field
                  setFocusIndex((prev) => (prev > 0 ? prev - 1 : prev));
                }
              }}
            />
          );
        })}
      </div>
    </div>
  );
};

export default memo(VerificationCode);
