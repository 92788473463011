import classNames from 'classnames';
import Button from 'src/components/button/Button';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { closePopup as closePopupAction } from 'src/store/common/common.slice';
import { useNavigate } from 'react-router-dom';

import { icon } from './popup.constants';
import './popup.scss';

import { CheckMarkIcon, CrossIcon } from '../common.icons';
import Typography from '../typography/Typography';

const Popup = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isPopupOpen, popupMessage, popupDescription, popupType, navigateTo } = useAppSelector(
    (store) => store.common
  );

  const closePopup = () => {
    dispatch(closePopupAction());
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div
      className={classNames('popup-component-outer', {
        'popup-component-outer--is-open': isPopupOpen
      })}
    >
      <div className="popup-component">
        <div className="popup-component__header">
          <div className="popup-component__header__close" onClick={closePopup}>
            <CrossIcon />
          </div>
          <div className="popup-component__header__icon">
            {icon[popupType as keyof typeof icon] || <CheckMarkIcon />}
          </div>
        </div>
        <Typography
          className="popup-component__title"
          as="h4"
          variant="subHeading-1"
          fontWeight="semiBold"
        >
          {popupMessage}
        </Typography>
        {popupDescription && (
          <Typography
            className="popup-component__description"
            as="p"
            variant="body-2"
            fontWeight="regular"
          >
            {popupDescription}
          </Typography>
        )}
        <Button
          className="popup-component__button"
          variant="secondary"
          size={componentSizeVariantsEnum.SMALL}
          onClick={closePopup}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Ok
          </Typography>
        </Button>
      </div>
    </div>
  );
};

export default Popup;
