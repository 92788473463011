import React from 'react';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { useGetFormikField } from 'src/hooks';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const TextField = ({ id, label, validations, required, createProgramStep }: TPreviewFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });

  function removeTags(str: any) {
    if ((str === null) || (str === ' ') || (str === undefined)) {
      return '';
    } else {
      str = str.toString();
      str = str.replace(/&nbsp;/g, '');
      return str.replace(/(<([^>]+)>)/ig, '');
    }
  }

  function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    const value = removeTags(e.target.value);
    const fieldValue = removeTags(field.value);
    
    if (!validations.maxCharacters || value.length <= validations.maxCharacters) {
      helpers?.setValue(e.target.value);
    }
  }

  return (
    <div key={id} className="dynamic-field">
      <Label required={required}>{label}</Label>
      <div className="dynamic-field__field">
        <TextAreaEditor
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder={label}
          type="text"
          {...(validations?.maxCharacters && { textLengthLimit: validations.maxCharacters })}
          value={field?.value}
          onChange={handleChange}
          isTouched={fieldMetaData?.touched}
          errorMessage={
            (fieldMetaData?.touched && field.value.length > (validations.maxCharacters || 0)) 
              ? `Exceeds maximum length of ${validations.maxCharacters} characters` 
              : fieldMetaData?.error
          }
          createProgramStep={createProgramStep}
        />
      </div>
    </div>
  );
};

export default TextField;
