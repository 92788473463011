import { useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  Modal,
  Separator,
  SubMenuModal,
  Typography
} from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import {
  TAddCollaboratorsRequest,
  TSearchCollaborator,
  TSearchCollaboratorsRequest
} from 'src/services/apiEndpoint.types.ts';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import {
  addCollaborators,
  getIdea,
  incrementSearchCollaboratorsPage,
  resetSearchCollaborators,
  searchCollaborators
} from 'src/store/ideaHub/ideaHub.slice';
import { useGetIdentity } from 'src/hooks';

import SelectedCollaborator from './selectedCollaborator/SelectedCollaborator';
import './shareToCollaborate.scss';

import SearchUserResult from '../searchUserResult/SearchUserResult';
import { TIdeaCollaborationType } from '../../ideaHub.types';

export type TShareToCollaborateProps = {
  ideaId: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
};

export type TSelectedCollaborator = {
  id: string;
  name: string;
  role: string;
  email: string;
  profilePic: string;
  collaborationType: TIdeaCollaborationType;
};

const searchCollaboratorsModalId = 'searchCollaboratorsModal';

const ShareToCollaborate = ({
  ideaId,
  isModalOpen,
  handleCloseModal
}: TShareToCollaborateProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedCollaborators, setSelectedCollaborators] = useState<TSelectedCollaborator[]>([]);

  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, authenticatedId } = getIdentities();

  const { dropdownId, loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const {
    searchCollaboratorsList,
    searchCollaboratorsPageNumber,
    searchUserLoading,
    searchCollaboratorsItemsPerPage,
    searchCollaboratorsMoreItemsLeft
  } = useAppSelector((store) => store.ideaHub);

  const openSearchUsersResultModalId =
    dropdownId === searchCollaboratorsModalId &&
    (searchCollaboratorsList?.length > 0 || searchUserLoading);

  const fetchUsers = ({ resetUsers = false }: { resetUsers: boolean }) => {
    if (resetUsers) {
      dispatch(resetSearchCollaborators());
    }
    if (searchInput) {
      const payload: TSearchCollaboratorsRequest = {
        filterString: searchInput,
        offset: searchCollaboratorsPageNumber,
        limit: searchCollaboratorsItemsPerPage,
        ideaId
      };
      dispatch(searchCollaborators(payload));
    } else {
      dispatch(resetSearchCollaborators());
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedCollaborators((prev) => {
      const currentList: TSelectedCollaborator[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex(
        (member: TSelectedCollaborator) => member.id === userId
      );

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectUser = searchCollaboratorsList.find(
          (member: TSearchCollaborator) => member.id === userId
        );
        if (selectUser) {
          const { id, name, role, email, profilePic } = selectUser;
          currentList.push({ id, name, role, email, profilePic, collaborationType: 'Edit' });
        }
      }

      return currentList;
    });
  };

  const handleAddMembers = () => {
    const usersToAdd = selectedCollaborators.map((user) => ({
      userId: user?.id,
      collaborationType: user?.collaborationType
    }));

    const payload: TAddCollaboratorsRequest = {
      ideaId,
      usersToAdd,
      userId: authenticatedId
    };

    dispatch(addCollaborators(payload)).then(() => {
      dispatch(getIdea({ ideaId, userId: loggedInUserId }));
    });
  };

  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchInput
  });

  const loadMoreItems = () => {
    if (searchCollaboratorsMoreItemsLeft && !searchUserLoading) {
      dispatch(incrementSearchCollaboratorsPage(1));
    }
  };

  const updatedCollaborationType = ({ id, type }: { id: string; type: TIdeaCollaborationType }) => {
    const currentUsers: TSelectedCollaborator[] = JSON.parse(JSON.stringify(selectedCollaborators));
    const selectedUserIndex: number = currentUsers.findIndex((user) => user.id === id);

    if (currentUsers[selectedUserIndex]) {
      currentUsers[selectedUserIndex].collaborationType = type;

      setSelectedCollaborators(currentUsers);
    }
  };

  useEffect(() => {
    fetchUsers({ resetUsers: false });
  }, [searchCollaboratorsPageNumber]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="share-to-collaborate-modal"
      title="Share Idea"
    >
      <div className="share-to-collaborate">
        <div className="share-to-collaborate__search-users">
          <InputField
            label="Search and select users with whom you want to collaborate"
            id="email"
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Search user by name, email address"
            startIcon={<SearchIcon />}
            type="text"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(assignSubMenuModalId(searchCollaboratorsModalId));
            }}
          />
          {openSearchUsersResultModalId && (
            <SubMenuModal>
              <div
                className="share-to-collaborate__search-users__container"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography as="p" variant="caption" fontWeight="semiBold">
                  Search for “{searchInput}”
                </Typography>
                <InfiniteScroll
                  className="share-to-collaborate__search-users__container__result"
                  onScrollEnd={loadMoreItems}
                  loading={searchUserLoading}
                  showLoader={false}
                  moreItemsLeft={searchCollaboratorsMoreItemsLeft}
                >
                  {searchCollaboratorsList?.length > 0 &&
                    searchCollaboratorsList.map(
                      (
                        { name = '', role = '', id, profilePic = '' }: TSearchCollaborator,
                        index
                      ) => {
                        const selectIndex = selectedCollaborators.findIndex(
                          (user) => user.id === id
                        );
                        return (
                          <SearchUserResult
                            key={index}
                            userId={id}
                            profilePic={profilePic}
                            name={name}
                            role={role}
                            updateSelectedUsers={updateSelectedUsers}
                            isUserSelected={selectIndex > -1}
                          />
                        );
                      }
                    )}
                </InfiniteScroll>

                {searchUserLoading && <Loader />}
              </div>
            </SubMenuModal>
          )}
        </div>
        <Separator />
        <Typography as="p" variant="body-2" fontWeight="semiBold">
          Selected users
        </Typography>
        <Typography as="p" variant="body-2" fontWeight="regular">
          You can update the access of your idea to the selected user by clicking on the dropdown
          button.
        </Typography>
        <div className="share-to-collaborate__selected-users">
          {selectedCollaborators.map(
            (
              {
                name = '',
                role = '',
                email = '',
                id,
                profilePic = '',
                collaborationType
              }: TSelectedCollaborator,
              index
            ) => {
              return (
                <SelectedCollaborator
                  key={index}
                  profilePic={profilePic}
                  name={name}
                  role={role}
                  email={email}
                  id={id}
                  collaborationType={collaborationType}
                  handleRemoveUser={() => updateSelectedUsers({ userId: id })}
                  updatedCollaborationType={updatedCollaborationType}
                />
              );
            }
          )}
        </div>
        <div className="share-to-collaborate__buttons">
          <Button variant="secondary" size="small" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" size="small" loading={loading} onClick={handleAddMembers}>
            Share Idea
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareToCollaborate;
