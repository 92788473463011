import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const EyeOffIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="16"
      height="14"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.38071 2.45738C7.65165 2.41732 7.93115 2.39583 8.21903 2.39583C11.6224 2.39583 13.8556 5.39906 14.6059 6.58705C14.6967 6.73083 14.7421 6.80273 14.7675 6.91362C14.7866 6.99689 14.7866 7.12828 14.7675 7.21155C14.742 7.32243 14.6963 7.3948 14.6049 7.53955C14.405 7.85593 14.1002 8.30057 13.6964 8.7828M4.70163 3.53919C3.26025 4.51697 2.28172 5.87542 1.83282 6.58602C1.74161 6.73041 1.696 6.80261 1.67058 6.91349C1.65148 6.99676 1.65147 7.12813 1.67056 7.21141C1.69597 7.32229 1.74137 7.39418 1.83217 7.53795C2.58244 8.72594 4.81569 11.7292 8.21903 11.7292C9.5913 11.7292 10.7733 11.2409 11.7446 10.5802M2.21903 1.0625L14.219 13.0625M6.80481 5.64829C6.44288 6.01022 6.21903 6.51022 6.21903 7.0625C6.21903 8.16707 7.11446 9.0625 8.21903 9.0625C8.77131 9.0625 9.27131 8.83864 9.63324 8.47671"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeOffIcon;
