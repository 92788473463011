/* eslint-disable multiline-ternary */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from 'src/components/header/Header';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import {
  BackNavigation,
  ConditionalComponent,
  Loader,
  TDropdownOptions
} from 'src/components/common';
import useUrlParamValue from 'src/hooks/useUrlParamValue/useUrlParamValue';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
// import { clearNewsFeed, getPost } from 'src/store/socialMedia/socialMedia.slice';
import { getPost } from 'src/store/socialMedia/socialMedia.slice';
import { TGetPostRequest, TPost } from 'src/services/apiEndpoint.types.ts';
import { useCopyToClipboard, useGetIdentity } from 'src/hooks';
import { TUserTypes } from 'src/constants/user.constants';
import {
  CompanyCard,
  CreateCompanyCard,
  InviteNetwork,
  StartupNewsResources
} from 'src/components/cards';

import './viewPostSingle.scss';

// The component can be accessed by both authenticated id and NOT by secondary company Id
// Secondary company id: The company, the user creates in addition to their main profile.
// Must be careful about userId and userType in api requests
const ViewPostSingle = () => {
  const { newsFeed, viewPostOwner } = useAppSelector((store) => store.socialMedia);
  const { loading } = useAppSelector((store) => store.common);

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { copyToClipboard } = useCopyToClipboard();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();

  const post: TPost = newsFeed[0];
  const { loggedInCompanyId, loggedInUserType, authenticatedId } = getIdentities();
  const isAnnouncement = post?.post?.isAnnouncement;

  const prevRoute = paramValue({ paramName: 'prev' });
  const postId = paramValue({ paramName: 'postId' }) || '';
  const viewAs = (paramValue({ paramName: 'viewAs' }) || loggedInUserType) as TUserTypes;

  const copyPostLink = () => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const viewUserProfile = () => {
    const postCreatorId: string = newsFeed[0]?.user?.id;
    const userType = newsFeed?.[0]?.userType[0];
    navigate(
      `/view-${userType === 'User' ? 'profile' : 'company'}?id=${postCreatorId}&prev=/view-post?postId=${postId}`
    );
  };

  const dropdownOptions: TDropdownOptions[] = [
    { label: `Copy ${isAnnouncement ? 'announcement' : 'post'} link`, onClick: copyPostLink },
    {
      label: <>View {isAnnouncement ? 'announcement' : 'post'}&apos;s profile</>,
      onClick: viewUserProfile
    }
  ];

  const fetchPost = () => {
    const payload: TGetPostRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      postId
    };

    dispatch(getPost(payload));
  };

  useEffect(() => {
    fetchPost();

    // return () => {
    //   dispatch(clearNewsFeed());
    // };
  }, [postId]);

  useEffect(() => {
    if (newsFeed?.length > 0) {
      const post: TPost = newsFeed[0];
      const creatorType = post?.userType[0] as TUserTypes;
      const postCreatorId = post?.user?.id;

      // This comparison is required because, company Id and user id can bear the same number.
      // So creator type must be considered.
      let isOwner = false;

      if (viewAs === 'User') {
        // No need to check for {loggedInCompanyId}, because viewer is a User
        isOwner = creatorType === 'User' ? authenticatedId === postCreatorId : false;
      } else if (viewAs === 'Company') {
        // No need to check for {loggedInUserId}, because viewer is a Company
        isOwner = creatorType === 'Company' ? authenticatedId === postCreatorId : false;
      }

      if (isOwner && !loading) {
        navigate(`/view-post-owned?postId=${postId}`);
      }
    }
  }, [viewPostOwner]);

  return (
    <div className="view-post-single">
      <Header />
      <div className="view-post-single__body">
        <div className="view-post-single__body__content">
          {
            <BackNavigation
              text="Back"
              route={prevRoute || '/dashboard'}
              onClick={() => {
                navigate(-1);
              }}
            />
          }
          {newsFeed?.length < 1 || loading ? (
            <Loader />
          ) : (
            <ViewPost
              dropdownOptions={dropdownOptions}
              post={newsFeed[0]}
              viewerId={authenticatedId}
              viewerType={loggedInUserType}
              expandPost={true}
            />
          )}
        </div>
        <div className="view-post-single__body__cards">
          {loggedInUserType === 'User' && (
            <ConditionalComponent
              condition={Boolean(loggedInCompanyId)}
              whenTrue={<CompanyCard selfCompany={true} companyId={loggedInCompanyId} />}
              whenFalse={<CreateCompanyCard />}
            />
          )}

          <InviteNetwork />

          <StartupNewsResources />
        </div>
      </div>
    </div>
  );
};

export default ViewPostSingle;
