import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const SearchIcon = ({ color = colors.neutral[500], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13 13.0625L10.1 10.1625M11.6667 6.39583C11.6667 9.34135 9.27885 11.7292 6.33333 11.7292C3.38781 11.7292 1 9.34135 1 6.39583C1 3.45031 3.38781 1.0625 6.33333 1.0625C9.27885 1.0625 11.6667 3.45031 11.6667 6.39583Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SearchIcon;
