import * as Yup from 'yup';

const createEditPostModalValidationSchema = Yup.object().shape({});

export const createEditAnnouncementModalValidationSchema = Yup.object().shape({
  title: Yup.string().required('Please enter announcement title'),
  redirectUrl: Yup.string().test(
    'is-required-when-button-text-add',
    'Please enter the redirect URL',
    function (value) {
      const { customButton } = this.parent;

      if (customButton && !value) {
        return false;
      } else {
        return true;
      }
    }
  )
});

export default createEditPostModalValidationSchema;
