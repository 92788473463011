import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const MoreOptionsIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="3"
      height="14"
      viewBox="0 0 3 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.5 7.8125C1.91421 7.8125 2.25 7.47671 2.25 7.0625C2.25 6.64829 1.91421 6.3125 1.5 6.3125C1.08579 6.3125 0.75 6.64829 0.75 7.0625C0.75 7.47671 1.08579 7.8125 1.5 7.8125Z"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 2.5625C1.91421 2.5625 2.25 2.22671 2.25 1.8125C2.25 1.39829 1.91421 1.0625 1.5 1.0625C1.08579 1.0625 0.75 1.39829 0.75 1.8125C0.75 2.22671 1.08579 2.5625 1.5 2.5625Z"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.5 13.0625C1.91421 13.0625 2.25 12.7267 2.25 12.3125C2.25 11.8983 1.91421 11.5625 1.5 11.5625C1.08579 11.5625 0.75 11.8983 0.75 12.3125C0.75 12.7267 1.08579 13.0625 1.5 13.0625Z"
        stroke="#121212"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MoreOptionsIcon;
