import React, { useState } from 'react';
import cx from './index.module.scss';
// import Image from 'next/image';
// import { logo } from '../../public/images';

const CheckPassword = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const defaultPassword = 'htraction@123'

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // Check if password meets your validation criteria
    if (password.trim() === '') {
      setError('Password is required.');
    } else {
      // eslint-disable-next-line eqeqeq
      if (password == defaultPassword) {
        localStorage.setItem('access', defaultPassword);
        window.location.reload()
      } else {
        setError('incorrect password')
      }
      // Clear password field and error message
      setPassword('');
      setError('');
    }
  };

  return (
    <div className={`${cx.main_box} pt-1 mt-4`}>
      <div className={`${cx.fillPassword} pt-1 mt-4`}>
        <div className='text-center mb-2 pb-1'>
          {/* <Image src={logo} alt='favourite-icon' className={cx.main_logo} /> */}
        </div>
        <h3>Password Required to Enter this Website</h3>
        <form onSubmit={handleSubmit}>
          <div className={`${cx.formCmg} ${cx.labelForm} mt-5 mb-4`} >
            <label className="form-label">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="form-control"
              placeholder="Your password"
            />
            {error && <div className="error text-danger mt-1">{error}</div>}
          </div>
          <div className={`${cx.formCmg} mb-0`}>
            <button type="submit" className="btn w-100">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
export default CheckPassword
