import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Button from 'src/components/button/Button';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TEditReplyToCommentRequest, TReply } from 'src/services/apiEndpoint.types.ts';
import { editReplyToComment } from 'src/store/socialMedia/socialMedia.slice';
import { TUserTypes } from 'src/constants/user.constants';

import './editReply.scss';
import editCommentValidationSchema from './editReply.validationSchema';

export type TEditReply = {
  onClose: () => void;
  reply: TReply;
  commentId: string;
  handleGetReplies: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

export type TEditReplyFormValues = {
  text: string;
};

// This component must be aware of the id and type of the viewer
const EditReply = ({
  reply,
  commentId,
  onClose,
  handleGetReplies,
  viewerId,
  viewerType
}: TEditReply) => {
  const initialValues: TEditReplyFormValues = { text: reply?.replies?.text };

  const replyId = reply?.replies?.id;
  const replyEditorProfilePic = viewerType === 'User' ? reply?.user?.profilePic : reply?.user?.logo;
  const replyEditorName = reply?.user?.name;

  const dispatch = useAppDispatch();

  const editReplyToCommentLoader: boolean = useAppSelector(
    (store) => store.socialMedia.editReplyToCommentLoader
  );

  const handleSubmit = async (
    { text }: TEditReplyFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // edit comment api call
    const payload: TEditReplyToCommentRequest = {
      userId: viewerId,
      replyId,
      userType: viewerType,
      text,
      commentId
    };

    dispatch(editReplyToComment(payload)).then(() => {
      handleGetReplies();
      resetForm();
      onClose();
    });
  };

  useEffect(() => {
    initialValues.text = reply?.replies?.text;
  }, [JSON.stringify(reply)]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={editCommentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form noValidate className="edit-reply">
              <div className="edit-reply__header">
                <ProfilePicture url={replyEditorProfilePic || ''} fallback={replyEditorName[0]} />
                <InputField
                  placeholder="Comment"
                  id="text"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    setFieldValue('text', e.target.value);
                  }}
                  value={values.text}
                  errorMessage={errors.text}
                  isTouched={touched.text}
                />
              </div>
              <div className="edit-reply__buttons">
                <Button variant="secondary" size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  loading={editReplyToCommentLoader}
                >
                  Reply
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditReply;
