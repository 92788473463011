import { useMemo } from 'react';
import { Typography } from 'src/components/common';

import { keyLabelMapping } from './keyLabelMapping';
import './IdeaDetailsSectionView.scss';

import IdeaLabelValue, { TIdeaLabelValue } from '../ideaLabelValue/IdeaLabelValue';

export type TIdeaDetailsSectionViewProps = {
  sectionTitle: string;
  data: Record<string, string>;
};

const IdeaDetailsSectionView = ({ sectionTitle, data }: TIdeaDetailsSectionViewProps) => {
  const fields: TIdeaLabelValue[] = useMemo(() => {
    try {
      const keys: string[] = Object.keys(data);

      return keys.map((key) => {
        const label: string = keyLabelMapping[key as keyof typeof keyLabelMapping] || '';
        const value: string = data[key as keyof typeof data];

        return { label, value };
      });
    } catch {
      return [];
    }
  }, [JSON.stringify(data), sectionTitle]);

  return (
    <div className="idea-details-section-view">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        {sectionTitle}
      </Typography>
      <div className="idea-details-section-view__fields">
        {fields.map((field, index) => {
          return <IdeaLabelValue key={index} {...field} />;
        })}
      </div>
    </div>
  );
};

export default IdeaDetailsSectionView;
