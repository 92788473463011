import classNames from 'classnames';
import { hTractionLogoWithText } from 'src/assets/images';
import { useAppSelector } from 'src/store/hooks';

import './pageLoader.scss';

import Typography from '../typography/Typography';

const PageLoader = ({ message }: { message?: string }) => {
  const showPageLoader: boolean = useAppSelector<boolean>((store:any) => store.common.showPageLoader);
  return (
    <div className={classNames('page-loader', { 'page-loader--is-visible': showPageLoader })}>
      <div className="page-loader__content">
        <img
          src={hTractionLogoWithText}
          alt="hTraction logo"
          className="page-loader__content__logo"
        />
        <Typography
          as="h3"
          variant="heading-3"
          fontWeight="bold"
          className="page-loader__content__title"
        >
          {message
            ? (
              <>
                Please wait...
              </>
              )
            : (
              <>
                Welcome to <span className="page-loader__content__title--highlight">htraction</span>!
              </>
              )}
        </Typography>
        <div className="page-loader__content__loading-container">
          <div className="page-loader__content__loading-container__bar"></div>
        </div>
      </div>
    </div >
  );
};

export default PageLoader;
