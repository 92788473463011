import { HTMLAttributes, ReactNode, useState, useMemo, useRef, useEffect } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId, resetDropdownId } from 'src/store/common/common.slice';

import './selectField.scss';

import Label from '../label/Label';
import ErrorMessage from '../errorMessage/ErrorMessage';
import Typography from '../../typography/Typography';
import { SelectIcon } from '../../common.icons';
import SubMenuModal from '../../subMenuModal/SubMenuModal';

export type TSelectOptions = {
  label: string;
  value: string | number;
  id: string;
};

type TSelectFieldProps = {
  errorMessage?: string;
  label?: string | ReactNode;
  placeholder: string;
  variant?: componentSizeVariantsEnum;
  options: TSelectOptions[];
  selectedValue: string | number;
  onSelection: ({ value }: { value: string | number }) => void;
  isTouched?: boolean;
  required?: boolean;
} & HTMLAttributes<HTMLDivElement>;

/**
 * This is select component that tries to replicate the behavior of native html Select field.
 * Only create to match the the visual design of other components in tha app.
 * This need improvements based on how close the behavior is to the native element.
 * Represents the props for a select field component.
 * @typedef {Object} TSelectFieldProps
 * @param {string} [errorMessage] - The error message to display.
 * @param {string|ReactNode} label - The label for the select field.
 * @param {string} placeholder - The placeholder text for the select field.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the select field.
 * @param {TSelectOptions[]} options - An array of options for the select field.
 * @param {string|number} selectedValue - The value of the selected option.
 * @param {function} onSelection - The callback function triggered when an option is selected.
 * @param {HTMLAttributes<HTMLDivElement>} - Additional HTML div element attributes.
 */
const SelectField = ({
  id,
  errorMessage,
  className,
  label,
  variant = componentSizeVariantsEnum.LARGE,
  options,
  placeholder,
  onSelection,
  selectedValue,
  isTouched,
  required,
  ...rest
}: TSelectFieldProps) => {
  const [openOptions, setOpenOptions] = useState<boolean>(false);

  // Refs
  const selectFieldRef = useRef<HTMLDivElement>(null);

  // Hooks
  const dispatch = useAppDispatch();
  const { dropdownId } = useAppSelector((store) => store.common);

  const openDropdown = () => {
    dispatch(assignSubMenuModalId(`select-field-${id}`));
    setOpenOptions(true);
  };

  const toggleOptionsDisplay = () => {
    setOpenOptions((prev) => !prev);
    if (dropdownId) {
      resetDropdownId();
    } else {
      openDropdown();
    }
  };

  const handleSelection = ({ value }: { value: string | number }) => {
    onSelection({ value });
    setOpenOptions(false);
  };

  const selectedLabel: string = useMemo(() => {
    // Finding the label for the selected value
    const selectedOption = options.find((option: TSelectOptions) => option.value === selectedValue);

    if (selectedOption) {
      return selectedOption.label;
    }
    return '';
  }, [selectedValue]);

  const handleEscapeKey = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' || e.key === 'Esc') {
      setOpenOptions(false);
      resetDropdownId();
    }
  };

  useEffect(() => {
    const ele = selectFieldRef?.current;

    if (ele) {
      ele.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      ele?.addEventListener('keydown', handleEscapeKey);
    };
  }, [selectFieldRef]);

  return (
    <div
      className={classNames(
        className,
        'select-field-component',
        `select-field-component--${variant}`
      )}
      {...rest}
      ref={selectFieldRef}
    >
      {label && (
        <Label htmlFor={id} variant={variant} required={required}>
          {label}
        </Label>
      )}
      <div className="select-field-component__custom-select" onClick={(e) => e.stopPropagation()}>
        <div
          onClick={toggleOptionsDisplay}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggleOptionsDisplay();
            }
          }}
          className={classNames('select-field-component__custom-select__select', {
            'select-field-component__custom-select__select--selected': selectedValue
          })}
          tabIndex={0}
        >
          <Typography as="span" variant="caption" fontWeight="regular">
            {selectedLabel || placeholder}
          </Typography>
          <SelectIcon
            className={classNames('select-field-component__custom-select__select__icon', {
              'select-field-component__custom-select__select__icon--is-open': openOptions
            })}
          />
        </div>
        {/* To close the select when clicked outside */}
        <SubMenuModal setOpenSubMenuModal={setOpenOptions}>
          <ul
            id={id}
            className={classNames('select-field-component__custom-select__options', {
              'select-field-component__custom-select__options--is-open': openOptions
            })}
          >
            {options.map((item) => (
              <li
                value={item.value}
                key={item.id}
                className={classNames('select-field-component__custom-select__options__option', {
                  'select-field-component__custom-select__options__option--selected':
                    selectedValue === item.value
                })}
                tabIndex={0}
                onClick={() => handleSelection({ value: item.value })}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSelection({ value: item.value });
                  }
                }}
              >
                {item.label}
              </li>
            ))}
          </ul>
        </SubMenuModal>
      </div>
      {errorMessage && isTouched && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default SelectField;
