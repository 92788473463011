// import { useState, ReactNode, useMemo, useContext } from 'react';
// import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';
import React, { useState, ReactNode, useMemo, useEffect, useContext } from 'react';
import { Card, LeftMenu, Typography } from 'src/components/common';
import { TGetProgramApplication } from 'src/services/apiEndpoint.types.ts';
import { useAppSelector } from 'src/store/hooks';

import './applicationSections.scss';

import ApplicationSectionView from '../applicationSectionView/ApplicationSectionView';
import BootstrapModal from 'src/components/common/BootstrapModal/BootstrapModal';
import { httpRequest } from 'src/services/commonApis';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { ViewApplicationSelf } from 'src/pages/viewMyApplication/ViewMyApplication';
interface TStageInput {
  rating: number,
  comments: string,
  maxRating: number,
  criteriaName: string
}
// interface TGStage { stageNumer: number, ratings: TRating[] }
interface ProgramApplicationStage {
  stageNumber: number,
  stageTitle: string,
}
interface TRating {
  id: string,
  ProgramApplicationStage: ProgramApplicationStage,
  applicationId: string,
  programId: string,
  stageId: string,
  mentorId: string | null,
  mentorEmail: string,
  stageNumber?: number,
  stagesInput: TStageInput[],
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null
}
const ApplicationSections = () => {
  // Hooks
  const { application: viewApplication, companyId }: TGetProgramApplication = useAppSelector((store: any) => store.program.viewApplication);
  const { paramValue } = useUrlParamValue();
  // State
  const [show, setShow] = useState<boolean>(false);
  const [activeSection, setActiveSection] = useState<number>(0);
  const [ratingData, setRatingData] = useState<TRating[]>([]);
  const [selectedRating, setSelectedRating] = useState<TRating[]>([])
  const applicationId: string = paramValue({ paramName: 'id' }) as string;
  // const isUserViewingApplication = useContext(ViewApplicationSelf);
  const isUserViewingApplication = useContext(ViewApplicationSelf);
  const { getIdentities } = useGetIdentity();
  const { loggedInCompanyId } = getIdentities();
  const isViewerProgramCreator = loggedInCompanyId === companyId;
  const showActionButtons = useMemo(() => {
    if (!isViewerProgramCreator) {
      return false;
    }

    return true;
  }, [isUserViewingApplication, isViewerProgramCreator]);
  const handleClose = () => setShow(false)
  const programApplicationSections = useMemo(() => {
    const items = viewApplication?.response?.sections.map((section, index) => {
      const { sectionTitle, sectionItems } = section;

      const data = sectionItems.map((item) => {
        return { label: item?.label, value: item?.response, type: item?.type, files: item?.files };
      });

      return {
        key: sectionTitle,
        label: sectionTitle,
        component: <ApplicationSectionView sectionTitle={sectionTitle} data={data} />
      };
    });
    // const feedbackData = viewApplication?.feedback?.map((item) => {
    //   return { label: `Feedback on advancing ${item?.stageTitle}`, value: item?.feedback };
    // });
    // items.push({
    //   key: 'Feedback',
    //   label: 'Application Rating',
    //   component: <ApplicationSectionView sectionTitle={'Feedback'} data={feedbackData} />
    // });
    return items;
  }, [JSON.stringify(viewApplication)]);

  const currentIdeaDetailsComponent: ReactNode = useMemo(() => {
    try {
      const section = programApplicationSections[activeSection];
      return section?.component;
    } catch {
      return <>-</>;
    }
  }, [activeSection, viewApplication]);

  async function getEvaluationData() {
    const response = await httpRequest(`application/getEvaluation?programId=${viewApplication?.programId}&applicationId=${applicationId}`, 'GET', null, 'json', 'program');
    if (response?.data?.length > 0) {
      setRatingData(response?.data)
    }
  }
  useEffect(() => {
    if (viewApplication?.programId && applicationId && showActionButtons) {
      getEvaluationData()
    }
  }, [viewApplication?.programId, applicationId, showActionButtons]);
  // const filterRatingData = ratingData?.filter((rating: TRating) => rating?.stagesInput && rating?.stagesInput?.length > 0);
  const groupedByStageId = ratingData.reduce(
    (acc: Record<string, TRating[]>, current: TRating) => {
      const stageTitle = current?.ProgramApplicationStage?.stageTitle;
      if (stageTitle !== undefined) {
        if (!acc[stageTitle]) {
          acc[stageTitle] = [];
        }
        acc[stageTitle].push(current);
      }
      return acc;
    },
    {} as Record<string, TRating[]>
  );
  const groupedArray = showActionButtons
    ? Object.keys(groupedByStageId).map((key) => ({
      stageTitle: key,
      ratings: groupedByStageId[key]
    }))
    : []

  return (
    <>
      {show && <BootstrapModal
        show={show}
        handleClose={handleClose}
        title={'Application Rating'}
        stage={null}
        type={'view'}
        ratingData={selectedRating}
      />}
      <div className="view-program-application-sections">
        <Card className="view-program-application-sections__menu">
          {programApplicationSections.map(({ label, key }, index) => {
            const isActive: boolean = activeSection === index;
            return (
              <LeftMenu
                key={key}
                state={isActive ? 'active' : 'default'}
                onClick={() => {
                  setActiveSection(index);
                }}
                className="view-program-application-sections__menu__item"
              >
                <Typography as="p" variant="body-2" fontWeight="medium">
                  {label}
                </Typography>
              </LeftMenu>
            );
          })}
          {groupedArray?.map((item: any, i: number) => {
            console.log(item, 'itemData');
            return (
              <LeftMenu
                key={i}
                state={'default'}
                onClick={() => {
                  setSelectedRating(item?.ratings)
                  setShow(true)
                }}
                className="view-program-application-sections__menu__item"
              >
                <Typography as="p" variant="body-2" fontWeight="medium">
                  Application Rating:{(<br />)} {` ${item?.stageTitle}`}
                </Typography>
              </LeftMenu>
            )
          })}
        </Card>
        <div className="view-program-application-sections__component">
          {currentIdeaDetailsComponent}
        </div>
      </div>
    </>
  );
};

export default ApplicationSections;
