import { useToggleOpenDropdown } from 'src/hooks';

import { TThreeDotsDropdownProps } from './threeDotsDropdown.types';
import './threeDotsDropdown.scss';

import { MoreOptionsIcon } from '../common.icons';
import SubMenuModal from '../subMenuModal/SubMenuModal';
import Dropdown from '../dropdown/Dropdown';
import ActionIcon from '../actionIcon/ActionIcon';

const ThreeDotsDropdown = ({ dropdownOptions }: TThreeDotsDropdownProps) => {
  // Hooks
  const toggleOpenDropdown = useToggleOpenDropdown();

  return (
    <div
      className="three-dots-dropdown-component"
      onClick={(e) => {
        e.stopPropagation();
        toggleOpenDropdown({ dropdownId: dropdownOptions?.id });
      }}
    >
      <ActionIcon>
        <MoreOptionsIcon />
      </ActionIcon>
      <SubMenuModal>
        <Dropdown {...dropdownOptions} />
      </SubMenuModal>
    </div>
  );
};

export default ThreeDotsDropdown;
