import {
  TFindCreateConversationData,
  TGetIdeaConversationParticipants,
  TGetIdeaGroup,
  TGetOneToOneConversationMessagesResponseMessage,
  TGetProgramGroup,
  TListOneToOneChatsResponseData,
  TReceiveMessageResponse,
  TSearchUsersToAddIdeaGroupUser,
  TSearchUsersToAddProgramGroupUser,
  TListIdeaChatsResponseData,
  TGetProgramGroupParticipantsResponseData
} from 'src/services/apiEndpoint.types.ts';

export type TInitialState = {
  // Loaders
  listOneToOneChatsLoader: boolean;
  chatLoader: boolean;
  getChatGroupsLoader: boolean;

  connected: boolean;
  // One to one chat
  messages: Record<string, TReceiveMessageResponse[]>;
  oneToOneChatListPageNumber: number;
  oneToOneChatListItemsPerPage: number;
  oneToOneChatMessagesPageNumber: number;
  oneToOneChatMessagesItemsPerPage: number;
  oneToOneChatMessagesMoreItemsLeft: boolean;
  oneToOneChatLists: TListOneToOneChatsResponseData[];
  OneToOneConversationMessages: TGetOneToOneConversationMessagesResponseMessage[];
  startCreateConversation: TFindCreateConversationData;
  currentOneToOneRecipientUser: { id: string; name: string; role: string; profilePic: string };
  isDirectChatNotInitiated: boolean;
  isIdeaDirectChatNotInitiated: boolean;
  // Idea chat
  ideaGroups: TGetIdeaGroup[];
  ideaGroupParticipants: TGetIdeaConversationParticipants[];
  ideaMessages: Record<string, TReceiveMessageResponse[]>;
  ideaChatMessagesPageNumber: number;
  ideaChatMessagesItemsPerPage: number;
  ideaChatMessagesMoreItemsLeft: boolean;
  searchUsersToAddIdeaGroupList: TSearchUsersToAddIdeaGroupUser[];
  searchUsersToAddIdeaGroupPageNumber: number;
  searchUsersToAddIdeaGroupItemsPerPage: number;
  searchUsersToAddIdeaGroupMoreItemsLeft: boolean;
  listIdeaChats: TListIdeaChatsResponseData[];
  // Program chat
  programGroups: TGetProgramGroup[];
  programDiscussion: TGetProgramGroup;
  programGroupParticipants: TGetProgramGroupParticipantsResponseData[];
  programMessages: Record<string, TReceiveMessageResponse[]>;
  programChatMessagesPageNumber: number;
  programChatMessagesItemsPerPage: number;
  searchUsersToAddProgramGroupList: TSearchUsersToAddProgramGroupUser[];
  searchUsersToAddProgramGroupPageNumber: number;
  searchUsersToAddProgramGroupItemsPerPage: number;
  searchUsersToAddProgramGroupMoreItemsLeft: boolean;
  // Files to upload
  filesToUpload: { id: string; fileName: string }[];
  chatNotificationNumbers: number;
};

export const initialState: TInitialState = {
  // Loaders
  listOneToOneChatsLoader: false,
  chatLoader: false,
  getChatGroupsLoader: false,

  messages: {},
  connected: false,
  // One to one chat
  oneToOneChatListPageNumber: 1,
  oneToOneChatListItemsPerPage: 50,
  oneToOneChatMessagesPageNumber: 1,
  oneToOneChatMessagesItemsPerPage: 10,
  oneToOneChatMessagesMoreItemsLeft: true,
  oneToOneChatLists: [],
  OneToOneConversationMessages: [],
  currentOneToOneRecipientUser: { id: '0', name: '', role: '', profilePic: '' },
  startCreateConversation: { id: '', updatedAt: '', createdAt: '', deletedAt: '', Users: [] },
  isDirectChatNotInitiated: true,
  isIdeaDirectChatNotInitiated: true,
  listIdeaChats: [],
  // Idea chat
  ideaGroups: [],
  ideaGroupParticipants: [],
  ideaMessages: {},
  ideaChatMessagesPageNumber: 1,
  ideaChatMessagesItemsPerPage: 10,
  ideaChatMessagesMoreItemsLeft: true,
  searchUsersToAddIdeaGroupList: [],
  searchUsersToAddIdeaGroupPageNumber: 1,
  searchUsersToAddIdeaGroupItemsPerPage: 10,
  searchUsersToAddIdeaGroupMoreItemsLeft: true,
  // Program chat
  programGroups: [],
  programDiscussion: {
    id: '',
    programId: '',
    groupName: '',
    type: 'GroupChat',
    createdAt: '',
    updatedAt: '',
    deletedAt: ''
  },
  programGroupParticipants: [],
  programMessages: {},
  programChatMessagesPageNumber: 1,
  programChatMessagesItemsPerPage: 50,
  searchUsersToAddProgramGroupList: [],
  searchUsersToAddProgramGroupPageNumber: 1,
  searchUsersToAddProgramGroupItemsPerPage: 50,
  searchUsersToAddProgramGroupMoreItemsLeft: true,
  // Files to upload
  filesToUpload: [],
  chatNotificationNumbers: 0
};
