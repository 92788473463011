import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackNavigation, Stepper, TStep, Typography } from 'src/components/common';
import { CompanyDetails, ContactDetails, PageDetails } from 'src/components/company';
import useUrlParamValue from 'src/hooks/useUrlParamValue/useUrlParamValue';
import { StorageUtils } from 'src/utils';
import Header from 'src/components/header/Header';
import { TPageDetailsFormValues } from 'src/components/company/createCompany/pageDetails/PageDetails';
import { TCompanyDetailsFormValues } from 'src/components/company/createCompany/companyDetails/CompanyDetails';
import { TContactDetailsFormValues } from 'src/components/company/createCompany/contactDetails/ContactDetails';
import {
  TCreateCompanyRequest,
  TEditCompanyRequest,
  TGetCompanyPresignedUrlActionRequest
} from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  createCompany,
  editCompany,
  getCompanyPresignedUrl
} from 'src/store/company/company.slice';
import { TService } from 'src/services/apiEndpoint.types.ts/company/createCompany.endpoint.types';
import { TAddEditServiceModalMode } from 'src/components/company/services/addEditService/AddEditService';
import { TUserTypes } from 'src/constants/user.constants';
import { useGetIdentity, useToggleOpenModal } from 'src/hooks';

import './createCompany.scss';

export type TCreateCompanyFormValues = TPageDetailsFormValues &
  TCompanyDetailsFormValues &
  TContactDetailsFormValues;

export type TCompanyLogoData = string | ArrayBuffer | null;

const createCompanyServiceModalId = 'createCompanyServiceModal';

const CreateCompany = () => {
  const { myCompany } = useAppSelector((store) => store.company);
  const { profile } = useAppSelector((store) => store.profile);

  const [createCompanyStep, setCreateCompanyStep] = useState<number>(0);
  const [createCompanyFormValues, setCreateCompanyFormValues] = useState<TCreateCompanyFormValues>({
    name: myCompany?.name || '',
    type: '',
    companyEmailAddress: myCompany?.companyEmailAddress
  });
  const [createCompanyServices, setCreateCompanyServices] = useState<TService[]>([]);
  const [serviceModalMode, setServiceModalMode] = useState<TAddEditServiceModalMode>('Add');
  const [companyLogoData, setCompanyLogoData] = useState<TCompanyLogoData>(null);
  const [canUseSkipCompanyCreationFlow, setCanUseSkipCompanyCreationFlow] = useState<boolean>(true);

  // Hooks
  const modalId = useAppSelector((store) => store.common.modalId);
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const toggleOpenModal = useToggleOpenModal();

  // Constants
  const isCreateCompanyServiceModalOpen = modalId === createCompanyServiceModalId;

  const prevRoute = paramValue({ paramName: 'prev' });
  const { loggedInUserType } = getIdentities();

  const toggleOpenCreateCompanyServiceModal = () => {
    toggleOpenModal({ modalId: createCompanyServiceModalId });
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const updateSkipCompanyCreationState = () => {
    if (loggedInUserType === 'Company') {
      setCanUseSkipCompanyCreationFlow(false);
    } else if (loggedInUserType === 'User' && profile.role === 'Service providers/Startup') {
      setCanUseSkipCompanyCreationFlow(false);
    }
  };

  const redirectToViewCompany = () => {
    // If the company is already created

    if (myCompany.type) {
      navigate('/my-company');
    }
  };

  const handleOpenServiceModal = ({ mode }: { mode: TAddEditServiceModalMode }) => {
    toggleOpenCreateCompanyServiceModal();
    setServiceModalMode(mode);
  };

  const handleCloseServiceModal = () => {
    toggleOpenCreateCompanyServiceModal();
  };

  const updateCreateCompanyFormValues = ({
    values
  }: {
    values: Partial<TCreateCompanyFormValues>;
  }) => {
    setCreateCompanyFormValues((prev) => ({ ...prev, ...values }));
  };

  const storageCompanyLogo = (logoData: TCompanyLogoData) => {
    setCompanyLogoData(logoData);
  };

  const removeCompanyLogo = () => {
    setCompanyLogoData(null);
  };

  const handleNext = () => {
    setCreateCompanyStep((prev) => prev + 1);
  };

  const handleCreateCompany = ({ values }: { values: TContactDetailsFormValues }) => {
    setCreateCompanyFormValues((prev) => ({ ...prev, ...values }));

    const email = StorageUtils.get('email') as unknown as string;
    const userType = StorageUtils.get('userType') as TUserTypes;

    const {
      contactNumber,
      companyEmailAddress,
      contactUsUrl,
      addressLine1,
      addressLine2,
      country,
      state,
      city,
      zipCode
    } = values;

    const { name, type, about, website, introduction, foundedYear, industry } =
      createCompanyFormValues;

    const fieldsPayload = {
      ...(about && { about }),
      ...(website && { website }),
      ...(introduction && { introduction }),
      ...(foundedYear && { foundedYear }),
      ...(industry && { industry }),
      ...(contactNumber && { contactNumber }),
      ...(companyEmailAddress && { companyEmailAddress }),
      ...(contactUsUrl && { contactUsUrl })
    };

    // Incubator/Accelerator creates company
    if (userType === 'Company') {
      const companyId = StorageUtils.get('companyId') as unknown as string;
      const editCompanyPayload: TEditCompanyRequest = {
        id: companyId,
        name,
        type,
        navigateToMyCompany: true,
        ...fieldsPayload,
        ...(addressLine1 && { addressLine1 }),
        ...(addressLine2 && { addressLine2 }),
        ...(country && { country }),
        ...(state && { state }),
        ...(city && { city }),
        ...(zipCode && { zipCode }),
        ...(createCompanyServices?.length > 0 && { services: createCompanyServices })
      };

      dispatch(editCompany(editCompanyPayload)).then(() => {
        if (companyLogoData) {
          const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
            id: companyId,
            data: companyLogoData,
            showPopup: false
          };

          dispatch(getCompanyPresignedUrl(uploadProfilePicturePayload));
        }
      });
    } else {
      // User creates company
      const createCompanyPayload: TCreateCompanyRequest = {
        email,
        name,
        type,
        ...fieldsPayload,
        ...(addressLine1 && { addressLine1 }),
        ...(addressLine2 && { addressLine2 }),
        ...(country && { country }),
        ...(state && { state }),
        ...(city && { city }),
        ...(zipCode && { zipCode }),
        ...(createCompanyServices?.length > 0 && { services: createCompanyServices })
      };

      dispatch(createCompany(createCompanyPayload)).then(() => {
        const companyId = StorageUtils.get('companyId') as unknown as string;

        if (companyLogoData) {
          const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
            id: companyId,
            data: companyLogoData,
            showPopup: false
          };

          dispatch(getCompanyPresignedUrl(uploadProfilePicturePayload));
        }
      });
    }
  };

  const stepComponents = [
    <CompanyDetails
      key="company-details"
      handleNext={handleNext}
      isOpenServiceModal={isCreateCompanyServiceModalOpen}
      serviceModalMode={serviceModalMode}
      handleOpenServiceModal={handleOpenServiceModal}
      handleCloseServiceModal={handleCloseServiceModal}
      createCompanyFormValues={createCompanyFormValues}
      updateCreateCompanyFormValues={updateCreateCompanyFormValues}
      createCompanyServices={createCompanyServices}
      setCreateCompanyServices={setCreateCompanyServices}
      showCancelButton={canUseSkipCompanyCreationFlow}
      handleCancel={handleCancel}
    />,
    <PageDetails
      key="page-details"
      handleNext={handleNext}
      createCompanyFormValues={createCompanyFormValues}
      updateCreateCompanyFormValues={updateCreateCompanyFormValues}
      storageCompanyLogo={storageCompanyLogo}
      companyLogoData={companyLogoData}
      removeCompanyLogo={removeCompanyLogo}
      showCancelButton={canUseSkipCompanyCreationFlow}
      handleCancel={handleCancel}
    />,
    <ContactDetails
      key="contact-details"
      createCompanyFormValues={createCompanyFormValues}
      handleCreateCompany={handleCreateCompany}
      companyLogoData={companyLogoData}
      updateCreateCompanyFormValues={updateCreateCompanyFormValues}
      showCancelButton={canUseSkipCompanyCreationFlow}
      handleCancel={handleCancel}
    />
  ];

  const steps: TStep[] = [
    { label: 'Company Details' },
    { label: 'Page Details' },
    { label: 'Contact Details' }
  ];

  const handleBack = () => {
    if (createCompanyStep > 0) {
      setCreateCompanyStep((prev) => prev - 1);
    } else {
      navigate(prevRoute || '/dashboard');
    }
  };

  useEffect(() => {
    setCreateCompanyFormValues((prev) => ({
      ...prev,
      name: myCompany?.name || '',
      type: '',
      companyEmailAddress: myCompany?.companyEmailAddress
    }));
  }, [JSON.stringify(myCompany)]);

  useEffect(() => {
    updateSkipCompanyCreationState();
  }, [loggedInUserType]);

  useEffect(() => {
    redirectToViewCompany();
  }, [myCompany.type]);

  return (
    <div className="create-company">
      <Header />
      <div className="create-company__body">
        <BackNavigation text="Back" route="/dashboard" onClick={handleBack} />
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Create company page
        </Typography>
        <Stepper
          currentStep={createCompanyStep}
          steps={steps}
          className="create-company__body__stepper"
        />
        {stepComponents[createCompanyStep]}
      </div>
    </div>
  );
};

export default CreateCompany;
