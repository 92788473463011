import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { MailIcon } from 'src/components/common/common.icons';
import { forgotPassword, resetRedirection } from 'src/store/auth/auth.slice';

import './forgotPassword.scss';
import forgotPasswordValidationSchema from './forgotPassword.validationSchema';

export type TForgotPasswordFormValues = {
  email: string;
};

const ForgotPassword = () => {
  // Hooks
  const { loading, redirectToConfirmForgotPassword, forgotPasswordEmail } = useAppSelector(
    (store) => store.auth
  );
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const initialValues: TForgotPasswordFormValues = {
    email: forgotPasswordEmail || ''
  };

  const handleSubmit = async ({ email }: TForgotPasswordFormValues) => {
    // forgot password api call
    dispatch(
      forgotPassword({
        email
      })
    );
  };

  useEffect(() => {
    if (redirectToConfirmForgotPassword) {
      navigate('/set-new-password');
    }

    return () => {
      dispatch(resetRedirection());
    };
  }, [redirectToConfirmForgotPassword]);

  return (
    <div className="forgot-password">
      <BackNavigation text="Back to login" route="/login" />
      <Typography
        as="h3"
        variant="heading-3"
        fontWeight="semiBold"
        className="forgot-password__heading"
      >
        Forgot Password?
      </Typography>

      {/* Form starts */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={forgotPasswordValidationSchema}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form className="forgot-password__form" noValidate>
              {/* NoValidate is used, because yup is used to validate the user input */}
              <InputField
                label="Enter Registered Email Address"
                id="email"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                startIcon={<MailIcon />}
                type="email"
                value={values.email}
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                }}
                errorMessage={errors.email}
                isTouched={touched.email}
              />

              <Button variant="primary" size="small" type="submit" loading={loading}>
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Continue
                </Typography>
              </Button>
            </Form>
          );
        }}
      </Formik>

      {/* Form ends */}
    </div>
  );
};

export default ForgotPassword;
