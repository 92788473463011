import { Dispatch, SetStateAction, useState } from 'react';
import { isNumber } from 'lodash';
import { Form, Formik } from 'formik';
import { Card, ConfirmModal, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { Industries } from 'src/constants/user.constants';
import { enumToJson } from 'src/utils';
// import { PlusIcon } from 'src/components/common/common.icons';
import { TCreateCompanyFormValues } from 'src/pages';
import { TService } from 'src/services/apiEndpoint.types.ts/company/createCompany.endpoint.types';
import { useGetIdentity, useToggleOpenModal } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';

import companyDetailsValidationSchema from './companyDetails.validationSchema';
import './companyDetails.scss';

import AddEditService, {
  TAddEditServiceModalMode
} from '../../services/addEditService/AddEditService';
// import ViewService from '../../services/viewService/ViewService';
import useCompanyTypes from '../../companyTypes/useCompanyTypes';

const confirmDeleteServiceModalId = 'confirmDeleteServiceModal';

export type TCompanyDetailsFormValues = {
  type: string;
  industry?: string;
  foundedYear?: number;
};

export type TCompanyDetails = {
  handleNext: () => void;
  isOpenServiceModal: boolean;
  serviceModalMode: TAddEditServiceModalMode;
  handleOpenServiceModal: ({ mode }: { mode: TAddEditServiceModalMode }) => void;
  handleCloseServiceModal: () => void;
  createCompanyFormValues: Partial<TCreateCompanyFormValues>;
  updateCreateCompanyFormValues: ({
    values
  }: {
    values: Partial<TCreateCompanyFormValues>;
  }) => void;
  createCompanyServices: TService[];
  setCreateCompanyServices: Dispatch<SetStateAction<TService[]>>;
  showCancelButton: boolean;
  handleCancel: () => void;
};

const CompanyDetails = ({
  handleNext,
  isOpenServiceModal,
  serviceModalMode,
  handleOpenServiceModal,
  handleCloseServiceModal,
  createCompanyFormValues,
  updateCreateCompanyFormValues,
  createCompanyServices,
  setCreateCompanyServices,
  showCancelButton,
  handleCancel
}: TCompanyDetails) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const toggleOpenModal = useToggleOpenModal();
  const dispatch = useAppDispatch();
  const modalId = useAppSelector((store) => store.common.modalId);

  // Constants
  const isConfirmDeleteServiceModalOpen = modalId === confirmDeleteServiceModalId;

  const currentServices: string[] = createCompanyServices.map((service) => service.type);

  const { loggedInUserType } = getIdentities();

  const [editServiceValues, setEditServiceValues] = useState<TService>({
    type: '',
    description: ''
  });
  const [editServiceIndex, setEditServiceIndex] = useState<number | null>(null);
  const [deleteServiceIndex, setDeleteServiceIndex] = useState<number | null>(null);

  const initialValues: TCompanyDetailsFormValues = {
    type: createCompanyFormValues.type || '',
    industry: createCompanyFormValues.industry || '',
    foundedYear: createCompanyFormValues.foundedYear
  };

  const availableCompanyTypes = useCompanyTypes();

  const industriesOptions: TSelectCardRadioButton[] = enumToJson({
    enumData: Industries
  });

  const toggleOpenDeleteServiceModal = () => {
    toggleOpenModal({ modalId: confirmDeleteServiceModalId });
  };

  const handleSubmit = (values: TCompanyDetailsFormValues) => {
    updateCreateCompanyFormValues({ values });
    handleNext();
  };

  const handleAddService = ({ values }: { values: TService }) => {
    const isServiceAlreadyAdded: boolean =
      currentServices.findIndex((service) => service === values?.type) > -1;

    if (isServiceAlreadyAdded) {
      dispatch(
        openPopup({
          popupMessage: 'You have already added this service!',
          popupType: 'error'
        })
      );
    } else {
      setCreateCompanyServices((prev: TService[]) => {
        const currentServices = JSON.parse(JSON.stringify(prev)) as TService[];
        return currentServices.concat(values);
      });

      setEditServiceValues({
        type: '',
        description: ''
      });
      dispatch(
        openPopup({
          popupMessage: 'You have successfully added a new services!',
          popupType: 'success'
        })
      );
      handleCloseServiceModal();
    }
  };

  const handleEditService = ({ values }: { values: TService }) => {
    const isServiceAlreadyAdded: boolean =
      currentServices.findIndex((service) => service === values?.type) > -1 &&
      values?.type !== editServiceValues?.type;

    if (isServiceAlreadyAdded) {
      dispatch(
        openPopup({
          popupMessage: 'You have already added this service!',
          popupType: 'error'
        })
      );
    } else if (typeof editServiceIndex === 'number' && editServiceIndex >= 0) {
      setCreateCompanyServices((prev: TService[]) => {
        const currentServices = JSON.parse(JSON.stringify(prev)) as TService[];
        currentServices[editServiceIndex] = values;
        return currentServices;
      });
      setEditServiceIndex(null);
      setEditServiceValues({
        type: '',
        description: ''
      });
      dispatch(
        openPopup({
          popupMessage: 'You have successfully updated your services!',
          popupType: 'success'
        })
      );
      handleCloseServiceModal();
    }
  };

  const handleDeleteService = () => {
    if (isNumber(deleteServiceIndex) && deleteServiceIndex > -1) {
      setCreateCompanyServices((prev: TService[]) => {
        const currentServices = JSON.parse(JSON.stringify(prev)) as TService[];
        currentServices.splice(deleteServiceIndex, 1);
        return currentServices;
      });
    }
    setDeleteServiceIndex(null);
    dispatch(
      openPopup({
        popupMessage: 'You have successfully deleted service!',
        popupType: 'success'
      })
    );
  };

  // const openEditModal = ({ serviceIndex }: { serviceIndex: number }) => {
  //   const serviceToEdit = createCompanyServices[serviceIndex] || { type: '', description: '' };
  //   setEditServiceValues(serviceToEdit);
  //   setEditServiceIndex(serviceIndex);
  //   handleOpenServiceModal({ mode: 'Edit' });
  // };

  return (
    <Card className="create-company-company-details">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        Company Details
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={companyDetailsValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form className="create-company-company-details__form">
              {/*
               ** Company type
               */}
              <SelectField
                label="Company Type"
                required
                placeholder="Select company type"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={values.type}
                options={availableCompanyTypes}
                onSelection={({ value }) => {
                  setFieldValue('type', value as unknown as string);
                }}
                errorMessage={errors.type}
                isTouched={touched.type}
              ></SelectField>
              {/*
               ** Industries
               */}
              {loggedInUserType === 'User' && (
                <SelectField
                  label="Industry"
                  placeholder="Select Industry"
                  variant={componentSizeVariantsEnum.SMALL}
                  selectedValue={values.industry || ''}
                  options={industriesOptions}
                  onSelection={({ value }) => {
                    setFieldValue('industry', value as unknown as string);
                  }}
                  errorMessage={errors.industry}
                  isTouched={touched.industry}
                ></SelectField>
              )}
              {/*
               ** Founding year
               */}
              <InputField
                label="Founding year"
                placeholder="YYYY"
                variant={componentSizeVariantsEnum.SMALL}
                type="number"
                value={values.foundedYear}
                id="foundedYear"
                // onChange={(e) => {
                //   setFieldValue('foundedYear', e.target.value);
                // }}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if (inputValue.length <= 4) {
                    setFieldValue('foundedYear', inputValue);
                  }
                }}
                errorMessage={errors.foundedYear}
                isTouched={touched.foundedYear}
              />

              {/* <div className="create-company-company-details__form__services">
                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="semiBold"
                  className="create-company-company-details__form__services__label"
                >
                  Services Provided
                </Typography>
                <Button
                  variant="secondary"
                  size="extraSmall"
                  className="create-company-company-details__form__services__button"
                  onClick={() => handleOpenServiceModal({ mode: 'Add' })}
                >
                  <PlusIcon />
                  <Typography as="span" variant="caption" fontWeight="semiBold">
                    Add Services
                  </Typography>
                </Button>
                <div className="create-company-company-details__form__services__list">
                  {createCompanyServices.map(({ type = '', description = '' }: TService, index) => {
                    return (
                      <ViewService
                        key={index}
                        title={type}
                        description={description}
                        onEditClick={() => openEditModal({ serviceIndex: index })}
                        onDeleteClick={() => {
                          setDeleteServiceIndex(index);
                          toggleOpenDeleteServiceModal();
                        }}
                        enableEdit={true}
                      />
                    );
                  })}
                </div>
              </div> */}

              <div className="create-company-page-details__form__buttons">
                {showCancelButton && (
                  <Button variant="secondary" type="button" size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
                <Button variant="primary" type="submit" size="small">
                  Continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>

      {isOpenServiceModal && (
        <AddEditService
          mode={serviceModalMode}
          openServiceModal={isOpenServiceModal}
          closeServiceModal={() => {
            setEditServiceValues({
              type: '',
              description: ''
            });
            handleCloseServiceModal();
          }}
          handleAddService={handleAddService}
          handleEditService={handleEditService}
          {...editServiceValues}
        />
      )}

      {isConfirmDeleteServiceModalOpen && (
        <ConfirmModal
          openConfirmModal={isConfirmDeleteServiceModalOpen}
          onClose={toggleOpenDeleteServiceModal}
          title="Are you sure you want to delete this service?"
          type="error"
          onSubmit={handleDeleteService}
          submitText="Delete"
        />
      )}
    </Card>
  );
};

export default CompanyDetails;
