import { useState } from 'react';
import { Form, Formik } from 'formik';
import { InfiniteScroll, Loader, Modal, SubMenuModal, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import Button from 'src/components/button/Button';
import { SearchIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  editIdeaGroup,
  getIdeaGroupParticipants,
  incrementSearchUsersToAddIdeaGroupList,
  resetSearchUsersToAddIdeaGroupSuccess
} from 'src/store/chat/chat.slice';
import SearchMemberItem from 'src/components/ideaHub/discussion/searchMemberItem/SearchMembersItem';
import {
  TEditIdeaGroupActionRequest,
  TGetIdeaGroupParticipantsRequest,
  TSearchUsersToAddIdeaGroupUser,
  TUser
} from 'src/services/apiEndpoint.types.ts';
import { assignSubMenuModalId } from 'src/store/common/common.slice';

import useGetDiscussionGroupUsers from './useGetDiscussionGroupUsers.hook';
import { TManageIdeaGroupMembersProps } from './manageIdeaGroupMembers.types';
import './manageIdeaGroupMembers.scss';

import UserInfo from '../../company/programs/program/discussion/userInfo/UserInfo';

const mangeDiscussionGroupUsersId = 'mangeDiscussionGroupUsers';

const ManageIdeaGroupMembers = ({
  userId,
  userType,
  ideaId,
  groupId,
  isModalOpen,
  onModalClose,
  groupMembers
}: TManageIdeaGroupMembersProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { dropdownId, itemListLoader, loading } = useAppSelector((store) => store.common);
  const { searchUsersToAddIdeaGroupList, searchUsersToAddIdeaGroupMoreItemsLeft } = useAppSelector(
    (store) => store.chat
  );

  // State
  const [filterString, setFilterString] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = useState<TUser[]>(groupMembers?.Users?.slice(1));

  const initialValues = {
    text: ''
  };

  // Constants
  const openSearchUsersResultDropdownId = dropdownId === mangeDiscussionGroupUsersId;

  const openSearchUsersDropdown = () => {
    dispatch(assignSubMenuModalId(mangeDiscussionGroupUsersId));
  };

  const handleClose = () => {
    dispatch(resetSearchUsersToAddIdeaGroupSuccess());
    onModalClose();
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedMembers((prev) => {
      const currentList: TUser[] = JSON.parse(JSON.stringify(prev));

      const userIndex: number = currentList.findIndex((user: TUser) => user?.id === userId);

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectedUser = searchUsersToAddIdeaGroupList?.[0]?.collaborators?.find(
          (user: TUser) => user?.id === userId
        );
        if (selectedUser) {
          currentList.push(selectedUser);
        }
      }

      return currentList;
    });
  };

  const loadMoreItems = () => {
    if (searchUsersToAddIdeaGroupMoreItemsLeft && !itemListLoader) {
      dispatch(incrementSearchUsersToAddIdeaGroupList(1));
    }
  };

  const getIdeaGroups = () => {
    const payload: TGetIdeaGroupParticipantsRequest = {
      ideaConversationId: groupId,
      limit: 1000,
      offset: 1
    };

    dispatch(getIdeaGroupParticipants(payload));
  };

  const handleSubmit = () => {
    const selectedMemberIds = selectedMembers.map((item) => item?.id);
    const currentGroupUsersIds = groupMembers?.Users?.map((item) => item?.id);

    const usersToAdd = selectedMemberIds.filter((userId) => !currentGroupUsersIds.includes(userId));
    const usersToRemove = currentGroupUsersIds.filter(
      (userId) => !selectedMemberIds.includes(userId)
    );

    const ownerId = currentGroupUsersIds?.[0];

    const ownerInRemoveArrayIndex = usersToRemove.findIndex((rId) => rId === ownerId);

    if (ownerInRemoveArrayIndex > -1) {
      // Owner is in the index 0, they should not be removed
      usersToRemove.splice(ownerInRemoveArrayIndex, 1);
    }

    const payload: TEditIdeaGroupActionRequest = {
      ideaId,
      userId,
      userType,
      ideaConversationId: groupId,
      usersToAdd: usersToAdd.map((userId) => ({ userId, userType: 'User' })),
      usersToRemove: usersToRemove.map((userId) => ({ userId, userType: 'User' }))
    };

    dispatch(editIdeaGroup(payload)).then(() => {
      getIdeaGroups();
    });
  };

  // Fetch users to be add to group
  useGetDiscussionGroupUsers({ ideaId, groupId, filterString });

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleClose}
      title="Manage Group Members"
      className="ih-d-manage-group-members"
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => {
          return (
            <Form className="ih-d-manage-group-members__form">
              <div className="ih-d-manage-group-members__form__search-users">
                <InputField
                  label="Select and search users to add in group"
                  id="text"
                  placeholder="Search user by name, email address"
                  onChange={(e) => {
                    const value = e.target.value;

                    setFieldValue('text', value);
                    setFilterString(value);
                  }}
                  startIcon={<SearchIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    openSearchUsersDropdown();
                  }}
                />
                {openSearchUsersResultDropdownId && (
                  <SubMenuModal>
                    <div
                      className="ih-d-manage-group-members__form__search-users__container"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography as="p" variant="caption" fontWeight="semiBold">
                        Search for “{filterString}”
                      </Typography>{' '}
                      <InfiniteScroll
                        className="ih-d-manage-group-members__form__search-users__container__result"
                        onScrollEnd={loadMoreItems}
                        loading={itemListLoader}
                        showLoader={false}
                        moreItemsLeft={searchUsersToAddIdeaGroupMoreItemsLeft}
                      >
                        {searchUsersToAddIdeaGroupList?.length > 0 &&
                          searchUsersToAddIdeaGroupList.map(
                            (searchUser: TSearchUsersToAddIdeaGroupUser, index) => {
                              const { collaborators } = searchUser;
                              const { id, name, role, profilePic } = collaborators?.[0];
                              const selectIndex = selectedMembers.findIndex(
                                (user) => user?.id === id
                              );
                              return (
                                <SearchMemberItem
                                  key={index}
                                  userId={id}
                                  profilePic={profilePic}
                                  name={name}
                                  role={role}
                                  updateSelectedUsers={updateSelectedUsers}
                                  isUserSelected={selectIndex > -1}
                                />
                              );
                            }
                          )}
                      </InfiniteScroll>
                      {itemListLoader && <Loader />}
                    </div>
                  </SubMenuModal>
                )}
              </div>
              <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                style={{ color: colors?.neutral?.[1100] }}
              >
                You can manage the group member adding or removing the user.
              </Typography>
              <div className="ih-d-manage-group-members__form__users">
                {selectedMembers.map((member, index) => {
                  return (
                    <UserInfo
                      key={index}
                      {...member}
                      userId={member?.id}
                      showRemoveButton
                      onRemove={updateSelectedUsers}
                    />
                  );
                })}
              </div>
              <div className="ih-d-manage-group-members__form__buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={onModalClose}
                  className="ih-d-manage-group-members__form__buttons__close"
                  type="button"
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  className="ih-d-manage-group-members__form__buttons__create-group"
                  type="submit"
                  loading={loading}
                >
                  Save Changes
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ManageIdeaGroupMembers;
