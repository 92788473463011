import { Card, Typography } from 'src/components/common';

import { TSectionReviewProps } from './sectionReview.types';
import './sectionReview.scss';

import PreviewField from '../previewField/PreviewField';
import { TCustomApplicationSection } from '../../customApplicationForm/customApplicationForm.types';

const SectionReview = ({ id: sectionId, sectionTitle, sectionItems, createProgramStep }: TSectionReviewProps) => {
  console.log(createProgramStep, 'createProgramStep')
  return (

    <Card className="section-review">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        {sectionTitle}
      </Typography>
      <div className="section-review__fields">
        {sectionItems.map((field: TCustomApplicationSection, index) => {
          return <PreviewField key={index} {...field} sectionId={sectionId} createProgramStep={createProgramStep}
           />;
        })}
      </div>
    </Card>
  );
};

export default SectionReview;
