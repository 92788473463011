import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PersonIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.21995 9.0625C5.10657 9.0625 3.22715 10.0829 2.03062 11.6665C1.77309 12.0073 1.64433 12.1777 1.64854 12.408C1.65179 12.586 1.76353 12.8104 1.90353 12.9203C2.08474 13.0625 2.33587 13.0625 2.83811 13.0625H11.6018C12.104 13.0625 12.3552 13.0625 12.5364 12.9203C12.6764 12.8104 12.7881 12.586 12.7914 12.408C12.7956 12.1777 12.6668 12.0073 12.4093 11.6665C11.2128 10.0829 9.33334 9.0625 7.21995 9.0625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.21995 7.0625C8.87681 7.0625 10.22 5.71935 10.22 4.0625C10.22 2.40565 8.87681 1.0625 7.21995 1.0625C5.5631 1.0625 4.21995 2.40565 4.21995 4.0625C4.21995 5.71935 5.5631 7.0625 7.21995 7.0625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PersonIcon;
