import React, { useRef } from 'react';
import Label from 'src/components/common/formFields/label/Label';
import { Typography } from 'src/components/common';
import { UploadIcon } from 'src/components/common/common.icons';
import { getMimeType, getUuid, StorageUtils } from 'src/utils';
import { useGetFormikField, useUploadMedia } from 'src/hooks';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import './previewField.scss';
import { useAppDispatch } from 'src/store/hooks';
import { TPreviewFieldProps, TGetProgramDocumentRequest } from './previewField.types';
import { getProgramDocument } from 'src/store/program/program.slice';
import { TProgramFileType } from '../../createProgram/programDetails/programDetails.types';
import FileCard from '../../fileCard/FileCard';

const UploadField = ({ id, label, required }: TPreviewFieldProps) => {
  // Hooks
  const draftData:any = StorageUtils.get('ApplicationDraft')
  const { getFormikField } = useGetFormikField();
  const { handleUploadMedia } = useUploadMedia();
const dispatch = useAppDispatch();

  // State
  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };
  const dragDropRef = useRef<HTMLDivElement>(null);
  const uploadImageRef = useRef<HTMLInputElement>(null);

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });
  const showErrorMessage = fieldMetaData?.touched && fieldMetaData?.error;

  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const onMediaLoad = (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: string,
    name: string,
    size: number
  ) => {
    if (helpers?.setValue) {
      // When InputField is wrapped in Formik
      let files: TProgramFileType[] = [];
      try {
        if (field?.value?.length > 0) {
          files = field?.value;
        }
        const newId = getUuid();
        const newFile: TProgramFileType = { key: newId, fileName: name, fileSize: size, data };
        files.push(newFile);
        helpers?.setValue(files);
      } catch (e) {
        console.log(e);
      }
    }
  };
  console.log(helpers?.setValue, field, 'firlddddddddd');
  const removeFile = (e: any, i: number) => {
    e.stopPropagation()
    if (helpers?.setValue) {
      const updatedFiles = field?.value?.filter((file: TProgramFileType, index:number) => index !== i);
      helpers?.setValue(updatedFiles);
    }
  };

  const files: TProgramFileType[] = field?.value;

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Label required={required}>{label}</Label>
      </div>
      <div
        className="dynamic-field__upload-file"
        draggable
        ref={dragDropRef}
        onDrop={(e) => {
          e.preventDefault();
          handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program', dragDrop: true });
        }}
        onDragOver={(e) => {
          e.preventDefault();
        }}
      >
        <span className="dynamic-field__upload-file__drag-drop">
          <UploadIcon />
          <Typography as="p" variant="body-2" fontWeight="regular">
            Drag and drop your logo
          </Typography>
          <input
            type="file"
            hidden
            ref={uploadImageRef}
            accept=".bmp,.jpg,.jpeg,.png,.pdf,.doc,.docx,.mp4"
            className="select-post-image"
            onChange={(e) => {
              handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'program' });
            }}
          />
        </span>
        <Typography as="p" variant="body-2" fontWeight="regular">
          or
        </Typography>
        <Typography
          as="span"
          variant="body-2"
          fontWeight="medium"
          className="dynamic-field__upload-file__browse"
          onClick={openImageFileManager}
        >
          Browse on your device
        </Typography>
      </div>{' '}
      {files?.length > 0 && (
      
        <div className="dynamic-field__files">
          {files.map(({ key, ...file }, i:number) => {
            const fileName = file?.fileName;
            const lastDotIndex = fileName.lastIndexOf('.');
            // Ensure the dot isn't the first character (to avoid hidden files
            let keyExt:string = ''
            if (lastDotIndex > 0 && lastDotIndex < fileName.length - 1) {
              keyExt= fileName.substring(lastDotIndex);
            }
            //
         // conso
            // console.log()
            const fileKey = `Company/${draftData?.Program?.companyId}/Programs/${draftData?.Program?.id}/Applications/${draftData?.id}/${key}${keyExt}`
          
            console.log(file, 'file')

            // eslint-disable-next-line no-undef
            const mimeType = getMimeType({
              fileName
            });

            return (
              <>
              <FileCard
              key={key}
              {...file}
              removeFile={(e:any) => removeFile(e, i)}
              onClick={() => {
                getDocument({ key: fileKey });
              } }/>
              </>

            );
          })}
        </div>
      )}
      {showErrorMessage && <ErrorMessage message={fieldMetaData?.error} />}
    </div>
  );
};

export default UploadField;
