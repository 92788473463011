import { FC } from 'react';
import { Typography } from 'src/components/common';
import { TEvaluatorResponse } from 'src/components/ideaHub';

import './viewRatings.scss';

import { ViewRating } from '..';

export type TEvaluatorResponsesProps = {
  ratings: TEvaluatorResponse[];
};

const ViewRatings: FC<TEvaluatorResponsesProps> = ({ ratings }: TEvaluatorResponsesProps) => {
  return (
    <div className="view-ratings">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        Idea Ratings
      </Typography>
      {ratings?.length === 0 && (
        <Typography as="p" variant="body-2" fontWeight="regular">
          No ratings received for your idea. Share it with industry experts, mentors, and investors
          to receive valuable feedback.
        </Typography>
      )}
      {ratings.map((rating, index) => (
        <ViewRating key={index} {...rating} />
      ))}
    </div>
  );
};

ViewRatings.defaultProps = {
  ratings: []
};

export default ViewRatings;
