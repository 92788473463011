import { useState } from 'react';
import { Form, Formik } from 'formik';
import { Button, Separator, Typography } from 'src/components/common';
import { useGetIdentity, useToggleOpenModal } from 'src/hooks';
import { InputField } from 'src/components/common/formFields';
import { EyeIcon, EyeOffIcon, PasswordIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import { TChangePasswordRequest, TForgotPasswordRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { changePassword, forgotPassword } from 'src/store/auth/auth.slice';

import './changePassword.scss';
import { TChangePasswordInitialValues } from './changePassword.types';
import changePasswordValidationSchema from './changePassword.validationSchema';

import ForgotPassword from '../forgotPassword/ForgotPassword';

const settingsChangePasswordModalId = 'settingsChangePasswordModa';

const ChangePassword = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const { loadingId, modalId } = useAppSelector((store) => store.common);
  const dispatch = useAppDispatch();
  const toggleOpenModal = useToggleOpenModal();

  // State
  const [maskPassword, setMaskPassword] = useState<{
    oldPassword: boolean;
    newPassword: boolean;
    confirmPassword: boolean;
  }>({ oldPassword: true, newPassword: true, confirmPassword: true });

  // Constants
  const { authenticatedEmail } = getIdentities();
  const settingsChangePasswordModalOpen = modalId === settingsChangePasswordModalId;

  const handleToggleChangePasswordModal = () => {
    toggleOpenModal({ modalId: settingsChangePasswordModalId });
  };

  const getVerificationCode = () => {
    const payload: TForgotPasswordRequest = {
      email: authenticatedEmail
    };

    // forgot password api call
    dispatch(forgotPassword(payload)).then((response) => {
      if (response?.status === 200) {
        handleToggleChangePasswordModal();
      }
    });
  };

  const changePasswordInitialValues: TChangePasswordInitialValues = {
    newPassword: '',
    oldPassword: '',
    confirmPassword: ''
  };

  const handleChangePassword = (
    values: TChangePasswordInitialValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    const { oldPassword, newPassword } = values;

    const payload: TChangePasswordRequest = {
      oldPassword,
      newPassword,
      email: authenticatedEmail
    };

    dispatch(changePassword(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        resetForm();
      }
    });
  };

  return (
    <div className="settings-change-password">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="settings-change-password__heading"
      >
        Change Password
      </Typography>
      <Formik
        initialValues={changePasswordInitialValues}
        validationSchema={changePasswordValidationSchema}
        onSubmit={handleChangePassword}
      >
        {() => {
          return (
            <Form className="settings-change-password__form">
              <div className="settings-change-password__form__old-password">
                <div className="settings-change-password__form__old-password__field">
                  <InputField
                    id="oldPassword"
                    label="Enter old password"
                    placeholder="Old password"
                    startIcon={<PasswordIcon />}
                    variant={componentSizeVariantsEnum.SMALL}
                    endIcon={maskPassword.oldPassword ? <EyeOffIcon /> : <EyeIcon />}
                    endIconClickHandler={() =>
                      setMaskPassword((prev) => ({ ...prev, oldPassword: !prev.oldPassword }))
                    }
                    maskField={maskPassword.oldPassword}
                  />
                  <div></div>
                </div>

                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="semiBold"
                  className="settings-change-password__form__old-password__forgot-password"
                  onClick={getVerificationCode}
                >
                  Forgot Password?
                </Typography>
              </div>

              <Separator />

              <div className="settings-change-password__form__new-password">
                <InputField
                  id="newPassword"
                  label="Enter new password"
                  placeholder="New password"
                  startIcon={<PasswordIcon />}
                  variant={componentSizeVariantsEnum.SMALL}
                  endIcon={maskPassword.newPassword ? <EyeOffIcon /> : <EyeIcon />}
                  endIconClickHandler={() =>
                    setMaskPassword((prev) => ({ ...prev, newPassword: !prev.newPassword }))
                  }
                  maskField={maskPassword.newPassword}
                />

                <InputField
                  id="confirmPassword"
                  label="Confirm password"
                  placeholder="Confirm password"
                  startIcon={<PasswordIcon />}
                  variant={componentSizeVariantsEnum.SMALL}
                  endIcon={maskPassword.confirmPassword ? <EyeOffIcon /> : <EyeIcon />}
                  endIconClickHandler={() =>
                    setMaskPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
                  }
                  maskField={maskPassword.confirmPassword}
                />
              </div>

              <Button
                size="small"
                variant="primary"
                type="submit"
                loading={loadingId === 'settings-change-password'}
                className="settings-change-password__form__submit"
              >
                Change Password
              </Button>
            </Form>
          );
        }}
      </Formik>
      {settingsChangePasswordModalOpen && (
        <ForgotPassword
          isModalOpen={settingsChangePasswordModalOpen}
          handleCloseModal={handleToggleChangePasswordModal}
        />
      )}
    </div>
  );
};

export default ChangePassword;
