import { Tooltip, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { textEllipsis } from 'src/utils';

import './searchUserResult.scss';

export type TSearchUserResult = {
  profilePic: string;
  userId: string;
  name: string;
  role: string;
  isUserSelected: boolean;
  updateSelectedUsers: ({ userId }: { userId: string }) => void;
};

const SearchUserResult = ({
  userId,
  profilePic = '',
  name = '',
  role = '',
  isUserSelected,
  updateSelectedUsers
}: TSearchUserResult) => {
  const nameEllipsis = textEllipsis({ charLimit: 14, text: name });

  return (
    <div className="search-ih-user-result">
      <div className="search-ih-user-result__user-info">
        <input
          type="checkbox"
          checked={isUserSelected}
          onChange={() => updateSelectedUsers({ userId })}
        />
        <ProfilePicture
          url={profilePic || ''}
          fallback={name[0]}
          className="search-ih-user-result__user-info__profile-pic"
        />
        <div className="search-ih-user-result__user-info__user">
          <div className="search-ih-user-result__user-info__user__row">
            <Tooltip text={name} conditionToDisplay={nameEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="semiBold"
                className="search-ih-user-result__user-info__user__row__name"
              >
                {nameEllipsis?.text}
              </Typography>
            </Tooltip>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="search-ih-user-result__user-info__user__row__role"
            >
              {role}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchUserResult;
