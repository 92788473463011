import { useNavigate } from 'react-router-dom';
import { ConfirmModal } from 'src/components/common';
import { assignConfirmModalId } from 'src/store/common/common.slice';
import { useAppDispatch } from 'src/store/hooks';

export type TCreateNewIdeaAlertDialog = {
  openAlertDialog: boolean;
};

const CreateNewIdeaAlertDialog = ({ openAlertDialog }: TCreateNewIdeaAlertDialog) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const closeConfirmModal = () => {
    dispatch(assignConfirmModalId(''));
  };

  const goToViewEmptyIdea = () => {
    dispatch(assignConfirmModalId(''));
    navigate('/view-empty-idea?tab=ideaDetails');
  };

  return (
    <ConfirmModal
      title="Ready to embark on a new idea journey? Let's kickstart your creative venture!"
      openConfirmModal={openAlertDialog}
      onClose={closeConfirmModal}
      onSubmit={goToViewEmptyIdea}
      submitText="Get Started"
      type="newIdea"
    />
  );
};

export default CreateNewIdeaAlertDialog;
