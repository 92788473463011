import { Card, Typography } from 'src/components/common';
import { PlusIconCircle } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignConfirmModalId } from 'src/store/common/common.slice';

import './createNewIdeaCard.scss';

import CreateNewIdeaAlertDialog from '../createNewIdeaAlertDialog/CreateNewIdeaAlertDialog';

const createNewIdeaAlertDialogId = 'createNewIdeaAlertDialog';

const CreateNewIdeaCard = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const confirmModalId: string = useAppSelector<string>((store) => store.common.confirmModalId);

  const isCreateNewIdeaAlertDialogOpen: boolean = confirmModalId === createNewIdeaAlertDialogId;

  const openCreateNewIdeaAlertDialog = () => {
    dispatch(assignConfirmModalId(createNewIdeaAlertDialogId));
  };

  return (
    <>
      <Card className="create-new-idea-card" onClick={openCreateNewIdeaAlertDialog}>
        <PlusIconCircle />
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Create New Idea
        </Typography>
      </Card>
      <CreateNewIdeaAlertDialog openAlertDialog={isCreateNewIdeaAlertDialogOpen} />
    </>
  );
};

export default CreateNewIdeaCard;
