import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import classNames from 'classnames';
import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { textFormatter, transformTextToHyperlink } from 'src/utils';
import { BookmarkIcon, MoreOptionsIcon, SpeakerIcon } from 'src/components/common/common.icons';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Button from 'src/components/button/Button';
import SubMenuModal from 'src/components/common/subMenuModal/SubMenuModal';
import { assignSubMenuModalId, openPopup } from 'src/store/common/common.slice';
import {
  Carousel,
  Comment,
  Dropdown,
  Like,
  MediaModal,
  TCarouselItem,
  TDropdown
} from 'src/components/common';
import { TDropdownOptions } from 'src/components/common/dropdown/Dropdown';
import {
  TFollowRequest,
  TLikePostRequest,
  TPost,
  TSavePostRequest,
  TUnFollowRequest,
  TUnSavePostRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  follow,
  likePost,
  savePost,
  unFollow,
  unLikePost,
  unSavePost
} from 'src/store/socialMedia/socialMedia.slice';
import { useGetIdentity, useOpenInNewTab } from 'src/hooks';
import { TUserTypes } from 'src/constants/user.constants';

import './postCard.scss';

export type TPostCardProps = {
  dropdownItems: TDropdownOptions[];
  highlightComment?: boolean;
  onCommentClick: () => void;
  post: TPost;
  viewerId: string;
  viewerType: TUserTypes;
  handleFollowFromSearchResult?: () => void;
};

// This component should know its parent. either dashboard or single post or activities
// This component must know id and type of the viewer
const PostCard = ({
  dropdownItems,
  highlightComment = false,
  onCommentClick,
  viewerId,
  viewerType,
  post,
  handleFollowFromSearchResult
}: TPostCardProps) => {
  const [isMediaModalOpen, setIsMediaModalOpen] = useState<boolean>(false);
  const postTextRef = useRef<HTMLElement>(null);
  const { dropdownId } = useAppSelector((store) => store.common);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { openInNewTab } = useOpenInNewTab();
  const { getIdentities } = useGetIdentity();

  const { loggedInCompanyId, loggedInUserId } = getIdentities();
  const [currIndex, setCurrentIndex] = useState<number>(0)
  const postCreatorId = post?.user.id;
  const following: boolean = post.followUserOrCompany;
  const postSaved: boolean = post?.post?.savedPost;
  const isViewerCreatorOfPost = viewerId === postCreatorId;
  const isAnnouncement = post?.post?.isAnnouncement;
  const postCreatorType = post?.userType[0] as TUserTypes;
  const postCreatorProfilePicture =
    postCreatorType === 'User' ? post?.user?.profilePic : post?.user?.logo;
  const postCreatorName = post?.user?.name;
  const announcementTitle = post?.post?.title;
  // Whether this profile card is viewed by self.
  const isSelfUser =
    viewerType === 'User' ? postCreatorId === loggedInUserId : postCreatorId === loggedInCompanyId;

  const dropdownOptions: TDropdown = {
    id: `postcard-dropdown=${post.post.id}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const postMoreOptions = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  const media: TCarouselItem[] = useMemo(() => {
    try {
      const urls = post?.post?.urls;
      if (urls) {
        return (JSON.parse(post?.post?.urls || '') || []) as unknown as TCarouselItem[];
      }
      return [];
    } catch {
      return [];
    }
  }, []);

  const viewUserProfile = () => {
    if (isViewerCreatorOfPost) {
      navigate(postCreatorType === 'User' ? '/my-profile' : '/my-company');
    } else {
      navigate(
        `${postCreatorType === 'User' ? '/view-profile' : '/view-company'}?id=${postCreatorId}&prev=${location.pathname}?postId=${post?.post?.id}`
      );
    }
  };

  const handleLikePost = () => {
    const payload: TLikePostRequest = {
      userId: viewerId,
      userType: viewerType,
      postId: post?.post?.id
    };

    dispatch(likePost(payload));
  };

  const [loading, setLoading] = useState<boolean>(false)
  const handleFollow = () => {
    const followUserType = post?.userType[0] as 'User' | 'Company';
    const followUserId = post?.user?.id;

    if (handleFollowFromSearchResult) {
      handleFollowFromSearchResult();
    }
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1800)
    if (following) {
      const payload: TUnFollowRequest = {
        userId: viewerId,
        userType: viewerType,
        followUserId,
        followUserType
      };

      dispatch(unFollow(payload)).then(() => {
        dispatch(
          openPopup({
            popupMessage: `You have stopped following ${post?.user?.name}`,
            popupType: 'success'
          })
        );
      });
    } else {
      const payload: TFollowRequest = {
        userId: viewerId,
        userType: viewerType,
        followUserId,
        followUserType
      };

      dispatch(follow(payload));
    }
  };

  const handleUnLikePost = () => {
    const payload: TLikePostRequest = {
      userId: viewerId,
      userType: viewerType,
      postId: post?.post?.id
    };

    dispatch(unLikePost(payload));
  };

  const handleSavePost = () => {
    if (!postSaved) {
      const payload: TSavePostRequest = {
        userId: viewerId,
        userType: viewerType,
        postId: post?.post?.id
      };

      dispatch(savePost(payload));
    } else {
      const payload: TUnSavePostRequest = {
        userId: viewerId,
        userType: viewerType,
        postId: post?.post?.id
      };

      dispatch(unSavePost(payload));
    }
  };

  const openMediaModal = () => {
    setIsMediaModalOpen(true);
  };

  const closeMediaModal = () => {
    setIsMediaModalOpen(false);
  };

  const handleRedirectionToUrl = () => {
    const url: any = post?.post?.redirectUrl;
    if (url) {
      if (url && !url?.startsWith('http://') && !url?.startsWith('https://')) {
        const urlToOpen = 'https://' + url;
        openInNewTab({ url: urlToOpen });
      } else {
        openInNewTab({ url });
      }
    }
  };

  const ProfileHead: ReactNode = (
    <div className="post-card__header__user" onClick={viewUserProfile}>
      <ProfilePicture
        url={postCreatorProfilePicture || ''}
        fallback={textFormatter({ value: postCreatorName[0] })}
        className="post-card__header__user__picture"
        userType={post?.userType?.[0]}
      />
      <div className="post-card__header__user__details">
        <Typography
          as="p"
          variant="body-2"
          fontWeight="semiBold"
          className="post-card__header__user__details__name"
        >
          {textFormatter({ value: postCreatorName })}
        </Typography>
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="post-card__header__user__details__role"
        >
          {textFormatter({ value: post.user?.type || post.user?.role })}
        </Typography>
      </div>
    </div>
  );

  useEffect(() => {
    const ele: HTMLElement | null = postTextRef.current;
    if (ele) {
      const text = ele.innerText;
      const replacedText = transformTextToHyperlink({ text });
      ele.innerHTML = replacedText;
    }
  }, [postTextRef]);

  console.log(currIndex, 'currIndexData');

  return (
    <Card className="post-card">
      {isAnnouncement && (
        <div className="post-card__announcement">
          <SpeakerIcon />
          <Typography as="span" variant="body-1" fontWeight="semiBold">
            Announcement!
          </Typography>
        </div>
      )}
      <div className="post-card__header">
        {ProfileHead}
        <div className="post-card__header__actions">
          {!isSelfUser && !isAnnouncement && (
            <Button
              variant={following ? 'secondary' : 'primary'}
              size="extraSmall"
              disabled={loading}
              onClick={handleFollow}
            >
              {following ? 'Following' : 'Follow'}
            </Button>
          )}
          {isAnnouncement && post?.post?.customButton && (
            <Button variant={'primary'} size="extraSmall" onClick={handleRedirectionToUrl}>
              {post?.post?.customButton}
            </Button>
          )}
          <div
            className="post-card__header__actions__dropdown"
            onClick={(e) => {
              e.stopPropagation();
              postMoreOptions();
            }}
          >
            {<MoreOptionsIcon />}{' '}
            <SubMenuModal>
              <Dropdown {...dropdownOptions} />
            </SubMenuModal>
          </div>
        </div>
      </div>
      {isAnnouncement && announcementTitle && (
        <Typography
          as="p"
          variant="body-2"
          fontWeight="semiBold"
          className="post-card__announcement-title"
        >
          {announcementTitle}
        </Typography>
      )}
      {post?.post?.text?.trim() && (
        <Typography
          as="span"
          variant="body-2"
          fontWeight="regular"
          className="post-card__sample"
          typographyRef={postTextRef}
        >
          {post?.post?.text}
        </Typography>
      )}
      {media?.length > 0 && (
        <div className="post-card__carousel">
          <Carousel items={media} openMediaModal={openMediaModal} setCurrentIndex={setCurrentIndex}/>
        </div>
      )}

      <div className="post-card__interactions">
        <div className="post-card__interactions__left">
          <Like
            count={post?.post?.likeCount}
            onLike={handleLikePost}
            onUnLike={handleUnLikePost}
            active={post?.post?.likedByUser}
          />
          <Comment
            count={post?.post?.commentCount}
            onClick={onCommentClick}
            isActive={highlightComment}
          />
        </div>
        <div
          className={classNames('post-card__interactions__right', {
            'post-card__interactions__right--is-active': postSaved
          })}
          onClick={handleSavePost}
        >
          <BookmarkIcon />
        </div>
      </div>
      {isMediaModalOpen && (
        <MediaModal
          heading={ProfileHead as unknown as Iterable<ReactNode> & string}
          media={media}
          post={post}
          isModalOpen={isMediaModalOpen}
          closeMediaModal={closeMediaModal}
          currIndex={currIndex}
        />
      )}
    </Card>
  );
};

export default PostCard;
