import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { Typography, Modal } from 'src/components/common';
import { ErrorIcon } from 'src/components/common/common.icons';
import { deleteEducation } from 'src/store/profile/profile.slice';
import { TDeleteEducationRequest } from 'src/services/apiEndpoint.types.ts';

import './deleteEducation.scss';

import { TInstituteCard } from '../instituteCard/InstituteCard';

export type TDeleteEducation = {
  isModalOpen: boolean;
  educationToUpdate: number;
  onModalClose: () => void;
};

const DeleteEducation = ({ isModalOpen, educationToUpdate, onModalClose }: TDeleteEducation) => {
  const dispatch = useAppDispatch();

  const profile = useAppSelector((store) => store.profile.profile);
  const loading: boolean = useAppSelector<boolean>((store) => store.common.loading);

  const handleDelete = () => {
    const education = [...((profile.education || []) as unknown as TInstituteCard[])];

    education.splice(educationToUpdate, 1);

    const payload: TDeleteEducationRequest = {
      id: profile.id,
      education
    };

    // delete education api call
    dispatch(deleteEducation(payload));
  };

  return (
    <Modal isModalOpen={isModalOpen} onModalClose={onModalClose} className="delete-education">
      <div className="delete-education__header">
        <div className="delete-education__header__icon">
          <ErrorIcon />
        </div>
      </div>
      <Typography
        className="delete-education__title"
        as="h4"
        variant="subHeading-2"
        fontWeight="semiBold"
      >
        Are you sure you want to delete this education record?
      </Typography>

      <div className="delete-education__buttons">
        <Button
          className="delete-education__buttons__button"
          variant="secondary"
          size={componentSizeVariantsEnum.SMALL}
          onClick={onModalClose}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Cancel
          </Typography>
        </Button>
        <Button
          className="delete-education__buttons__button"
          variant="primary"
          size={componentSizeVariantsEnum.SMALL}
          onClick={handleDelete}
          loading={loading}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Delete
          </Typography>
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteEducation;
