import { Typography } from 'src/components/common';

import './details.scss';

import IdeaLabelValue, { TIdeaLabelValue } from '../ideaLabelValue/IdeaLabelValue';
import { TIdeaCollaborator } from '../../ideaHub.types';
import IdeaPeople from '../ideaPeople/IdeaPeople';

export type TIdeaDetailsDetailsProps = {
  title: string;
  domain: string;
  description: string;
  owner: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
  };
  collaborators: TIdeaCollaborator[];
};

const Details = ({
  title,
  domain,
  description,
  owner,
  collaborators
}: TIdeaDetailsDetailsProps) => {
  const fields: TIdeaLabelValue[] = [
    { label: 'Title', value: title },
    { label: 'Domain', value: domain },
    { label: 'Description', value: description }
  ];

  return (
    <div className="idea-details-details">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="idea-details-details__heading"
      >
        Details
      </Typography>{' '}
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="idea-details-details__heading"
      >
        People access to this idea
      </Typography>
      <IdeaPeople owner={owner} collaborators={collaborators} />
      <div className="idea-details-details__fields">
        {fields.map((field, index) => {
          return <IdeaLabelValue key={index} {...field} />;
        })}
      </div>
    </div>
  );
};

export default Details;
