import { TSocialStatProps } from './socialStat.types';
import './socialStat.scss';

import Typography from '../typography/Typography';

const SocialStat = ({ label = '', value = 0 }: TSocialStatProps) => {
  return (
    <div className="social-stat-component">
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        className="social-stat-component__label"
      >
        {label}
      </Typography>
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="social-stat-component__value"
      >
        {value || 0}
      </Typography>
    </div>
  );
};

export default SocialStat;
