/* eslint-disable indent */
import { Button, Card, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { timeAgo } from 'src/utils';
import { useGetIdentity, useOpenInNewTab } from 'src/hooks';
import { useAppDispatch } from 'src/store/hooks';
import { useNavigate } from 'react-router-dom';
import {
  TRespondToCollaborationRequest,
  TRespondToEvaluationRequest,
  TRespondToKeyMemberSelectionRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  respondToCollaborationRequest,
  respondToEvaluationRequest
} from 'src/store/ideaHub/ideaHub.slice';
import { respondToKeyMemberSelection } from 'src/store/program/program.slice';
import { updateInviteNotificationActionStatus } from 'src/store/notifications/notifications.slice';

import './inviteNotificationCard.scss';
import { TInviteNotificationCard } from './inviteNotificationCard.types';

import useGetNotificationTItle from '../useGetNotificationTItle/useGetNotificationTItle';

const InviteNotificationCard = (notification: TInviteNotificationCard) => {
  // Hooks
  const getNotificationTitle = useGetNotificationTItle();
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { openInNewTab } = useOpenInNewTab();

  // Constants
  const { title, description } = getNotificationTitle({ notification });
  const notificationType = notification?.metadata?.notificationType;
  const { loggedInUserId } = getIdentities();

  let viewButtonText = '';

  if (notificationType === 'CollaborationRequest' || notificationType === 'EvaluationRequest') {
    viewButtonText = 'View Idea';
  } else if (notificationType === 'KeyMemberRequest' && notification?.metadata?.isEndUser) {
    viewButtonText = 'View company profile';
  } else if (notificationType === 'KeyMemberRequest' && !notification?.metadata?.isEndUser) {
    viewButtonText = 'View profile';
  } else if (notificationType === 'DemoInvite') {
    viewButtonText = 'Join Now';
  }

  const acceptCollaborationRequest = () => {
    const payload: TRespondToCollaborationRequest = {
      ideaId: notification?.idea?.id,
      userId: loggedInUserId,
      status: 'Accepted'
    };

    dispatch(respondToCollaborationRequest(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Accepted' })
        );
      }
    });
  };

  const rejectCollaborationRequest = () => {
    const payload: TRespondToCollaborationRequest = {
      ideaId: notification?.idea?.id,
      userId: loggedInUserId,
      status: 'Rejected'
    };

    dispatch(respondToCollaborationRequest(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Rejected' })
        );
      }
    });
  };

  const acceptEvaluationRequest = () => {
    const payload: TRespondToEvaluationRequest = {
      ideaId: notification?.idea?.id,
      userId: loggedInUserId,
      status: 'Accepted'
    };

    dispatch(respondToEvaluationRequest(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Accepted' })
        );
      }
    });
  };

  const rejectEvaluationRequest = () => {
    const payload: TRespondToEvaluationRequest = {
      ideaId: notification?.idea?.id,
      userId: loggedInUserId,
      status: 'Rejected'
    };

    dispatch(respondToEvaluationRequest(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Rejected' })
        );
      }
    });
  };

  const acceptKeyMemberRequest = () => {
    const payload: TRespondToKeyMemberSelectionRequest = {
      companyId: notification?.company?.id,
      userId: loggedInUserId,
      status: 'Accepted'
    };

    dispatch(respondToKeyMemberSelection(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Accepted' })
        );
      }
    });
  };

  const rejectKeyMemberRequest = () => {
    const payload: TRespondToKeyMemberSelectionRequest = {
      companyId: notification?.company?.id,
      userId: loggedInUserId,
      status: 'Rejected'
    };

    dispatch(respondToKeyMemberSelection(payload)).then((status: unknown) => {
      if ((status as unknown as number) === 200) {
        dispatch(
          updateInviteNotificationActionStatus({ id: notification?.id, actionTaken: 'Rejected' })
        );
      }
    });
  };

  const handleAccept = () => {
    if (notificationType === 'CollaborationRequest') {
      acceptCollaborationRequest();
    } else if (notificationType === 'EvaluationRequest') {
      acceptEvaluationRequest();
    } else if (notificationType === 'KeyMemberRequest') {
      acceptKeyMemberRequest();
    }
  };

  const handleReject = () => {
    if (notificationType === 'CollaborationRequest') {
      rejectCollaborationRequest();
    } else if (notificationType === 'EvaluationRequest') {
      rejectEvaluationRequest();
    } else if (notificationType === 'KeyMemberRequest') {
      rejectKeyMemberRequest();
    }
  };

  const handleViewButton = () => {
    if (notificationType === 'CollaborationRequest') {
      navigate(
        `/view-idea?id=${notification?.idea?.id}&tab=ideaDetails&collaborationType=Collaboration`
      );
    } else if (notificationType === 'EvaluationRequest') {
      navigate(
        `/view-idea?id=${notification?.idea?.id}&tab=ideaDetails&collaborationType=Evaluation`
      );
    } else if (notificationType === 'KeyMemberRequest' && notification?.metadata?.isEndUser) {
      navigate(`/view-company?id=${notification?.company?.id}`);
    } else if (notificationType === 'KeyMemberRequest' && !notification?.metadata?.isEndUser) {
      navigate(`/view-profile?id=${notification?.user?.id}`);
    }
  };

  const openDemoInvite = () => {
    const url = notification?.demo?.conferenceLink;
    if (url) {
      openInNewTab({ url });
    }
  };

  const viewKeyMemberNotificationProfile = () => {
    if (notificationType === 'KeyMemberRequest' && notification?.metadata?.isEndUser) {
      navigate(`/view-company?id=${notification?.company?.id}`);
    } else if (notificationType === 'KeyMemberRequest' && !notification?.metadata?.isEndUser) {
      navigate(`/view-profile?id=${notification?.user?.id}`);
    }
  };

  return (
    <Card className="invite-notifications-card">
      <ProfilePicture
        className="invite-notifications-card__profile-pic"
        url={notification?.user?.profilePic || notification?.company?.logo}
        fallback={notification?.user?.name?.[0] || notification?.company?.name?.[0]}
      />

      <div className="invite-notifications-card__body">
        <Typography
          className="invite-notifications-card__body__title"
          as="span"
          variant="body-2"
          fontWeight="semiBold"
        >
          {title}
        </Typography>{' '}
        <Typography
          className="invite-notifications-card__body__title"
          as="span"
          variant="caption"
          fontWeight="regular"
        >
          {description}
        </Typography>
        {
          <div className="invite-notifications-card__body__actions">
            {notification?.notificationTab === 'invite' &&
              notification?.actionTaken === 'Pending' && (
                <Button variant="primary" size="extraSmall" onClick={handleAccept}>
                  Accept
                </Button>
              )}
            {notification?.notificationTab === 'invite' &&
              notification?.actionTaken === 'Pending' && (
                <Button variant="secondary" size="extraSmall" onClick={handleReject}>
                  Reject
                </Button>
              )}
            {(notification?.actionTaken === 'Accepted' ||
              notification?.actionTaken === 'Complete') && (
              <Button variant="secondary" size="extraSmall" onClick={handleViewButton}>
                {viewButtonText}
              </Button>
            )}
            {notificationType === 'KeyMemberRequest' &&
              notification?.actionTaken === 'Rejected' && (
                <Button
                  variant="secondary"
                  size="extraSmall"
                  onClick={viewKeyMemberNotificationProfile}
                >
                  View Profile
                </Button>
              )}
            {notification?.metadata?.notificationType === 'DemoInvite' && (
              <Button variant="primary" size="extraSmall" onClick={openDemoInvite}>
                Join Now
              </Button>
            )}
          </div>
        }
      </div>
      <Typography
        className="invite-notifications-card__timestamp"
        as="p"
        variant="caption"
        fontWeight="regular"
      >
        {timeAgo({ timestamp: notification?.updatedAt || '' })}
      </Typography>
    </Card>
  );
};

export default InviteNotificationCard;
