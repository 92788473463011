import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { uniq } from 'lodash';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Card, SubMenuModal, Typography } from 'src/components/common';
import { ClockIcon, SearchIcon } from 'src/components/common/common.icons';
import InputElement from 'src/components/common/formFields/inputElement/InputElement';
import { componentSizeVariantsEnum } from 'src/constants';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { StorageUtils } from 'src/utils';

import './searchBar.scss';

const headerSearchResultModalId = 'headerSearchResultModal';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState<string>('');

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { dropdownId } = useAppSelector((store) => store.common);

  const showHeaderSearchResults = dropdownId === headerSearchResultModalId;

  const recentSearches: string[] = StorageUtils.get('recentSearches') || [];

  const showRecentSearches: boolean =
    showHeaderSearchResults && recentSearches && recentSearches?.length > 0;

  const openResults = () => {
    dispatch(assignSubMenuModalId(headerSearchResultModalId));
  };

  const closeResults = () => {
    dispatch(assignSubMenuModalId(''));
  };

  const handleSearch = ({ query }: { query: string }) => {
    closeResults();
    navigate(`/search?query=${query}&resultType=all`);
  };

  const handleSearchOnEnter = () => {
    const newRecentSearches = JSON.parse(JSON.stringify(recentSearches)) as string[];
    newRecentSearches.unshift(searchQuery);
    const uniqueArray = uniq(newRecentSearches);
    if (uniqueArray?.length > 3) {
      uniqueArray.length = 3;
    }
    // Storing search in local storage
    StorageUtils.set('recentSearches', uniqueArray);

    handleSearch({ query: searchQuery });
  };

  return (
    <div
      className="header-search"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <form
        onSubmit={(e) => {
          e.preventDefault();
          if (searchQuery) {
            handleSearchOnEnter();
          }
        }}
      >
        <InputElement
          startIcon={<SearchIcon />}
          placeholder="Search"
          variant={componentSizeVariantsEnum.SMALL}
          onClick={openResults}
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
          }}
        />
      </form>
      {showRecentSearches && (
        <SubMenuModal setOpenSubMenuModal={closeResults}>
          <Card className="header-search__results">
            <div className="header-search__results__recent">
              <Typography as="p" variant="body-1" fontWeight="semiBold">
                Recent
              </Typography>
              {recentSearches.map((name, index) => {
                return (
                  <div
                    key={index}
                    className="header-search__results__recent__item"
                    onClick={() => {
                      handleSearch({ query: name });
                    }}
                  >
                    <div className="header-search__results__recent__item__left">
                      <ClockIcon />
                      <Typography as="span" variant="body-2" fontWeight="medium">
                        {name}
                      </Typography>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        </SubMenuModal>
      )}
    </div>
  );
};

export default SearchBar;
