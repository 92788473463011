import { ReactNode } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import LeftMenu, { TLeftMenuState } from 'src/components/common/leftMenu/LeftMenu';
import { hTractionLogoWithText } from 'src/assets/images';
import { useAppSelector } from 'src/store/hooks';
import { TUserTypes } from 'src/constants/user.constants';

import './navigation.scss';

export type TNavigation = {
  url: string;
  key: string;
  startIcon: ReactNode;
  endIcon?: ReactNode;
  title: ReactNode;
  notificationCount: number;
  state: TLeftMenuState;
  availableToUserType: TUserTypes | 'All';
};

const Navigation = ({ items }: { items: TNavigation[] }) => {
  const { showNavBar } = useAppSelector((store) => store.common);

  const navigate = useNavigate();

  const navigateToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <div className={classNames('navigation', { 'navigation--is-open': showNavBar })}>
      <div className="navigation__menu">
        <img
          src={hTractionLogoWithText}
          alt="htraction logo"
          className="navigation__menu__htraction-logo"
          onClick={navigateToDashboard}
        />
        <div className="navigation__menu__items">
          {items.map(({ key, title, url, availableToUserType, ...rest }) => {
            return (
              <LeftMenu key={key} onClick={() => navigate(url)} {...rest}>
                {title}
              </LeftMenu>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Navigation;
