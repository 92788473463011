import { useEffect } from 'react';
import { useAppDispatch } from 'src/store/hooks';
import { socketService } from 'src/services/socket.api.service';
import { setNotificationNumber } from 'src/store/chat/chat.slice';
import { updateChatNotificationCount } from 'src/store/notifications/notifications.slice';

import { useGetIdentity } from '..';

const useListenToChatNotifications = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();

  const { loggedInUserType, authenticatedId } = getIdentities();

  useEffect(() => {
    if (authenticatedId && loggedInUserType) {
      socketService.on('connect', () => {
        console.log('notifications connected');
      });

      socketService.emit('get-conversation-unread-message-count', {
        userId: authenticatedId,
        userType: loggedInUserType
      });

      socketService.on(
        'get-conversation-unread-message-count',
        (event: {
          totalUnreadMessageCount: number;
          unreadMessageCountPerConversationObject: { unreadMessageCount: number }[];
        }) => {
          try {
            const totalUnreadConversationCount =
              event?.unreadMessageCountPerConversationObject?.filter(
                (item) => item?.unreadMessageCount > 0
              )?.length;

            dispatch(setNotificationNumber(event?.totalUnreadMessageCount));
            dispatch(
              updateChatNotificationCount({
                totalUnreadConversationCount,
                totalUnreadMessageCount: event?.totalUnreadMessageCount
              })
            );
          } catch (e) {
            console.log(e);
          }
        }
      );
    }

    // return () => {
    //   socketService.off('get-conversation-unread-message-count', (event: unknown) => {
    //     console.log(event);
    //   });
    // };
  }, [authenticatedId, loggedInUserType]);
};

export default useListenToChatNotifications;
