import { FC, Ref } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import Button from 'src/components/button/Button';
import { Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Industries } from 'src/constants/user.constants';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { enumToJson } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useUrlParamValue } from 'src/hooks';
import { assignModalId } from 'src/store/common/common.slice';

import editDetailsValidationSchema from './editDetails.validationSchema';
import './editDetails.scss';

import { TIdeaCollaborator } from '../../ideaHub.types';
import IdeaPeople from '../ideaPeople/IdeaPeople';
import { TEditIdeaFormValues } from '../IdeaDetails';
import ManageAccess from '../manageAccess/ManageAccess';

export type TEditIdeaDetailsFormValues = {
  title?: string;
  domain?: string;
  description?: string;
};

export type TEdiDetailsProps = {
  title?: string;
  domain?: string;
  description?: string;
  owner: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
  };
  collaborators: TIdeaCollaborator[];
  submitButtonRef: Ref<FormikProps<TEditIdeaFormValues>>;
  updateFormValues: ({ values }: { values: TEditIdeaFormValues }) => void;
  handleNext: () => void;
  goToNextTab: ({ fromEditDetails }: { fromEditDetails: boolean }) => void;
  showShareManageAccessButtonsEditDetails: boolean;
};

const manageAccessModalId = 'manageAccessModal';

const EditDetails: FC<TEdiDetailsProps> = ({
  title = '',
  domain = '',
  description = '',
  owner,
  collaborators,
  submitButtonRef,
  updateFormValues,
  handleNext,
  goToNextTab,
  showShareManageAccessButtonsEditDetails
}: TEdiDetailsProps) => {
  const initialValues: TEditIdeaDetailsFormValues = { title, domain, description };

  const industriesOptions: TSelectCardRadioButton[] = enumToJson({
    enumData: Industries
  });

  // Hooks
  const dispatch = useAppDispatch();
  const { modalId } = useAppSelector((store) => store.common);
  const { paramValue } = useUrlParamValue();

  const ideaId = paramValue({ paramName: 'id' }) as unknown as string;

  const openManageAccessModal = modalId === manageAccessModalId;

  const handleCloseModal = () => {
    dispatch(assignModalId(''));
  };

  const handleSubmit = (values: TEditIdeaDetailsFormValues) => {
    updateFormValues({ values });
    goToNextTab({ fromEditDetails: true });
  };

  return (
    <div className="edit-idea-edit-details">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="edit-idea-edit-details__heading"
      >
        Details
      </Typography>
      {/* People with access to this idea */}
      <div className="edit-idea-edit-details__users">
        <div className="edit-idea-edit-details__users__manage-access">
          <Typography as="span" variant="body-1" fontWeight="semiBold">
            People access to this idea
          </Typography>
          {showShareManageAccessButtonsEditDetails && (
            <Button
              size="extraSmall"
              variant="tertiary"
              type="button"
              onClick={() => {
                dispatch(assignModalId(manageAccessModalId));
              }}
            >
              Manage Access
            </Button>
          )}
        </div>
        <IdeaPeople owner={owner} collaborators={collaborators} />
      </div>

      {/* Details form */}
      <Formik
        initialValues={initialValues}
        validationSchema={editDetailsValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
        innerRef={submitButtonRef}
      >
        {({ values, touched, errors, setFieldValue }) => {
          return (
            <Form className="edit-idea-edit-details__form" id="edit-idea-form">
              {/* Idea title */}
              <InputField
                label={
                  <Typography as="span" variant="caption" fontWeight="semiBold">
                    Idea title{' '}
                    <Typography as="span" variant="caption" fontWeight="regular">
                      (maximum 50 words)
                    </Typography>
                  </Typography>
                }
                id="title"
                onChange={(e) => {
                  setFieldValue('title', e.target.value);
                  updateFormValues({ values: { title: e.target.value } });
                }}
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Title that best reflects your idea"
                type="text"
                required
              />

              {/* Domain */}

              <SelectField
                label="Idea Domain"
                placeholder="Select a specific domain your idea relates to"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={values.domain || ''}
                options={industriesOptions}
                onSelection={({ value }) => {
                  setFieldValue('domain', value as unknown as string);
                  updateFormValues({ values: { domain: value as unknown as string } });
                }}
                errorMessage={errors.domain}
                isTouched={touched.domain}
                required
              ></SelectField>

              {/* Description */}
              <TextAreaField
                label="Describe your idea"
                id="description"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Elaborate on your idea with details."
                onChange={(e) => {
                  setFieldValue('description', e.target.value);
                  updateFormValues({ values: { description: e.target.value } });
                }}
                value={values.description}
                errorMessage={errors.description}
                isTouched={touched.description}
                required
              />
            </Form>
          );
        }}
      </Formik>
      {openManageAccessModal && (
        <ManageAccess
          ideaId={ideaId}
          isModalOpen={openManageAccessModal}
          handleCloseModal={handleCloseModal}
          fetchKeyMembers={() => {}}
        />
      )}
    </div>
  );
};

EditDetails.defaultProps = {
  title: '',
  domain: '',
  description: ''
};

export default EditDetails;
