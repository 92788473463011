import { ReactNode } from 'react';
import { Carousel, Modal, TCarouselItem } from 'src/components/common';
import { TPost } from 'src/services/apiEndpoint.types.ts';

import './mediaModal.scss';

export type TMediaModal = {
  heading?: Iterable<ReactNode> & string;
  media: TCarouselItem[];
  post: TPost;
  isModalOpen: boolean;
  closeMediaModal: () => void;
  currIndex?: number
};

const MediaModal = ({ heading, media, isModalOpen, closeMediaModal, currIndex }: TMediaModal) => {
  return (
    <Modal
      className="post-media-modal"
      title={heading}
      isModalOpen={isModalOpen}
      onModalClose={closeMediaModal}
    >
      {media?.length > 0 && (
        <div className="post-card__carousel">
          <Carousel items={media} enableVideoControls={true} currIndex={currIndex}/>
        </div>
      )}
    </Modal>
  );
};

export default MediaModal;
