import { useMemo } from 'react';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { StorageUtils } from 'src/utils';
import { TUserTypes } from 'src/constants/user.constants';

const useCompanyTypes = () => {
  const loggedInUserType = StorageUtils.get('userType') as TUserTypes;

  const availableCompanyTypes: TSelectCardRadioButton[] = useMemo(() => {
    if (loggedInUserType === 'User') {
      return [
        {
          label: 'Start up',
          value: 'Start up',
          id: 'start-up'
        },
        {
          label: 'Service Provider',
          value: 'Service Provider',
          id: 'service-provider'
        }
      ];
    } else if (loggedInUserType === 'Company') {
      return [
        {
          label: 'Incubators',
          value: 'Incubator',
          id: 'incubator'
        },
        {
          label: 'Accelerators',
          value: 'Accelerator',
          id: 'accelerator'
        },
        {
          label: 'Pre-Incubator',
          value: 'Pre-Incubator',
          id: 'preIncubator'
        }
      ];
    } else {
      return [];
    }
  }, []);

  return availableCompanyTypes;
};

export default useCompanyTypes;
