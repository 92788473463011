import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCalculateElementHeight,
  useGetIdentity,
  useToggleOpenModal,
  useUrlParamValue
} from 'src/hooks';
import { ConfirmModal, TDropdownOptions } from 'src/components/common';
import { useToggleCreateEditGroupModal, useToggleManageGroupModalModal } from 'src/components/chat';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TDiscussionTypes } from 'src/components/chat/chat.type';
import { deleteProgramGroup } from 'src/store/chat/chat.slice';
import { TDeleteProgramGroupActionRequest } from 'src/services/apiEndpoint.types.ts';

import DiscussionList from './discussionList/DiscussionList';
import { TDiscussionProps } from './discussion.types';
import useGetProgramGroups from './discussionList/useGetProgramGroups.hook';
import './discussion.scss';
import useToggleOpenDiscussionMembersModal from './chatMessages/chatMessagesHeader/useToggleOpenDiscussionMembersModal';
import ChatMessages from './chatMessages/ChatMessages';
import EditProgramGroup from './editProgramGroup/EditProgramGroup';

const Discussion = ({ programId, companyId }: TDiscussionProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();
  const programDiscussionRef = useRef<HTMLDivElement>(null);
  const { toggleOpenOpenDiscussionMembersModal } = useToggleOpenDiscussionMembersModal();
  const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
    useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { toggleManageGroupModalModal } = useToggleManageGroupModalModal();
  const toggleOpenModal = useToggleOpenModal();
  // Get program groups
  useGetProgramGroups({ programId });
  const { programGroups, programGroupParticipants } = useAppSelector((store) => store.chat);
  const modalId = useAppSelector((store) => store.common.modalId);
  const { getIdentities } = useGetIdentity();

  const discussionType: TDiscussionTypes = paramValue({
    paramName: 'chatType'
  }) as unknown as TDiscussionTypes;
  const groupId: string = paramValue({ paramName: 'groupId' }) || '';

  // Constants
  const { loggedInUserType, authenticatedId } = getIdentities();
  const groupName = programGroupParticipants?.[0]?.groupName;
  const deleteProgramGroupConfirmModalId = `deleteProgramGroupConfirmModal-${groupId}`;
  const isDeleteGroupConfirmModalOpen = modalId === deleteProgramGroupConfirmModalId;

  const toggleOpenDeleteGroupModal = () => {
    toggleOpenModal({ modalId: deleteProgramGroupConfirmModalId });
  };

  const headerDropdownOptions: TDropdownOptions[] = [];

  if (discussionType === 'programDiscussion') {
    headerDropdownOptions.push(
      { label: 'View members', onClick: toggleOpenOpenDiscussionMembersModal },
      { label: 'Clear chat' }
    );
  } else if (discussionType === 'programGroup') {
    headerDropdownOptions.push(
      { label: 'Manage Members', onClick: toggleManageGroupModalModal },
      { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
      { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
    );
  }

  const handleDeleteProgramGroup = () => {
    const payload: TDeleteProgramGroupActionRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      programId,
      programConversationId: groupId
    };

    dispatch(deleteProgramGroup(payload)).then(() => {
      navigate(`/view-program?id=${programId}&tab=discussion`);
    });
  };

  const { elementHeight } = useCalculateElementHeight({
    ref: programDiscussionRef,
    triggers: [window.location.search],
    heightOffset: 45
  });

  return (
    <div
      className="program-discussion"
      ref={programDiscussionRef}
      style={{ height: `${elementHeight}px` }}
    >
      <DiscussionList
        discussionType={discussionType}
        groupId={groupId}
        groups={programGroups}
        programId={programId}
        companyId={companyId}
      />
      <ChatMessages
        discussionType={discussionType}
        groupId={groupId}
        headerDropdownOptions={headerDropdownOptions}
        members={programGroupParticipants}
        programId={programId}
        companyId={companyId}
      />{' '}
      {isToggleCreateEditGroupModalOpen && (
        <EditProgramGroup
          mode="Edit"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          programId={programId}
          groupId={groupId}
          groupName={groupName}
          groupMembers={programGroupParticipants}
        />
      )}{' '}
      {isDeleteGroupConfirmModalOpen && (
        <ConfirmModal
          title="Do wish to delete this group?"
          description="Deleting this group will permanently remove all the chats."
          openConfirmModal={isDeleteGroupConfirmModalOpen}
          onClose={toggleOpenDeleteGroupModal}
          onSubmit={handleDeleteProgramGroup}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default Discussion;
