import { useNavigate } from 'react-router-dom';

import ActionCard from '../actionCard/ActionCard';

const CreateCompanyCard = () => {
  // Hooks
  const navigate = useNavigate();

  const redirectToCreateCompany = () => {
    navigate('/create-company');
  };

  return (
    <ActionCard
      title="Build Your Brand: Create Your Company Page Now!"
      description="Launch your brand online – create your company page now!"
      buttonText="Get Started"
      onClick={redirectToCreateCompany}
    />
  );
};

export default CreateCompanyCard;
