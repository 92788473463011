import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from 'formik';

export type TGetFormikField = {
  id: string;
};

type TUseFieldReturnProps = [FieldInputProps<any>, FieldMetaProps<any>, FieldHelperProps<any>];

const useGetFormikField = () => {
  const getFormikField = ({ id }: TGetFormikField): TUseFieldReturnProps => {
    try {
      const [field, meta, helpers] = useField(id || '');
      return [field, meta, helpers];
    } catch {
      return [{}, {}, {}] as unknown as TUseFieldReturnProps;
    }
  };

  return { getFormikField };
};

export default useGetFormikField;
