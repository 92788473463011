import * as Yup from 'yup';
import { ALPHA_LOWERCASE, ALPHA_UPPERCASE, PASSWORD } from 'src/constants/regex.constants';

const forceChangePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(ALPHA_LOWERCASE, 'Password must contain at least one lowercase letter')
    .matches(ALPHA_UPPERCASE, 'Password must contain at least one uppercase letter')
    .matches(PASSWORD, 'Password must contain at least One special character')
    .required('Please enter a password'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword'), undefined], 'Passwords did not match. Please try again.')
    .required('Please re-enter the password')
});

export default forceChangePasswordValidationSchema;
