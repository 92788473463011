import Label from 'src/components/common/formFields/label/Label';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const AddLabel = ({ id, label, required }: TPreviewFieldProps) => {
  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Label required={required}>{label}</Label>
      </div>
    </div>
  );
};

export default AddLabel;
