const formatDate = ({ dateString }: { dateString: string }): string => {
  try {
    const date = new Date(dateString);

    if (Number.isNaN(date?.getTime())) {
      return '-';
    }

    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'short' });
    const year = date.getFullYear();

    const getOrdinalSuffix = (n: number) => {
      const suffixes = ['th', 'st', 'nd', 'rd'];
      const remainder = n % 100;
      return n + (suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0]);
    };

    const formattedDay = getOrdinalSuffix(day);

    return `${formattedDay} ${month} ${year}`;
  } catch {
    return '-';
  }
};

export default formatDate;
