import { useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  Modal,
  Separator,
  SubMenuModal,
  Typography
} from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import {
  addKeyMembers,
  incrementSearchUsersPage,
  resetSearchUsers,
  searchKeyMembers
} from 'src/store/company/company.slice';
import {
  TAddMembersRequest,
  TMember,
  TSearchUsersRequest
} from 'src/services/apiEndpoint.types.ts';
import { StorageUtils } from 'src/utils';
import { assignSubMenuModalId } from 'src/store/common/common.slice';

import './manageKeyMembers.scss';

import KeyMemberListItem from '../keyMemberListItem/KeyMemberListItem';
import SearchKeyMemberItem from '../searchKeyMemberItem/SearchKeyMemberItem';

export type TManageKeyMembers = {
  isModalOpen: boolean;
  onModalClose: () => void;
  fetchKeyMembers: () => void;
};

const searchUsersResultModalId = 'searchUsersResultModalId';

const ManageKeyMembers = ({ isModalOpen, onModalClose, fetchKeyMembers }: TManageKeyMembers) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedKeyMembers, setSelectedKeyMembers] = useState<TMember[]>([]);

  const companyId = StorageUtils.get('companyId') as string

  const dispatch = useAppDispatch();

  const { dropdownId, loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const {
    searchUsers,
    searchUsersPageNumber,
    searchUserLoading,
    searchUsersItemsPerPage,
    searchUsersMoreItemsLeft
  } = useAppSelector((store) => store.company);

  const openSearchUsersResultModalId =
    dropdownId === searchUsersResultModalId && (searchUsers?.length > 0 || searchUserLoading);

  const fetchUsers = ({ resetUsers = false }: { resetUsers: boolean }) => {
    if (resetUsers) {
      dispatch(resetSearchUsers());
    }
    if (searchInput) {
      const payload: TSearchUsersRequest = {
        key: searchInput,
        page: searchUsersPageNumber,
        pageSize: searchUsersItemsPerPage,
        companyId
      };
      dispatch(searchKeyMembers(payload));
    } else {
      dispatch(resetSearchUsers());
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedKeyMembers((prev) => {
      const currentList: TMember[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex((member: TMember) => member.id === userId);

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectUser = searchUsers.find((member: TMember) => member.id === userId);
        if (selectUser) {
          currentList.push(selectUser);
        }
      }

      return currentList;
    });
  };

  const handleAddMembers = () => {
    const payload: TAddMembersRequest = {
      companyId,
      userIds: selectedKeyMembers.map((user: TMember) => user.id)
    };

    dispatch(addKeyMembers(payload)).then(() => {
      fetchKeyMembers();
      onModalClose()
    });
  };

  useDebounce({
    func: () => fetchUsers({ resetUsers: true }),
    delay: 500,
    dependency: searchInput
  });

  useEffect(() => {
    fetchUsers({ resetUsers: false });
  }, [searchUsersPageNumber]);

  const loadMoreItems = () => {
    if (searchUsersMoreItemsLeft && !searchUserLoading) {
      dispatch(incrementSearchUsersPage(1));
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      className="manage-key-members-modal"
      title="Manage Access"
    >
      <div className="manage-key-members">
        <div className="manage-key-members__search-users">
          <InputField
            label="Search and select your company ‘s key members"
            id="email"
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Search user by name, email address"
            startIcon={<SearchIcon />}
            type="text"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(assignSubMenuModalId(searchUsersResultModalId));
            }}
          />
          {openSearchUsersResultModalId && (
            <SubMenuModal>
              <div
                className="manage-key-members__search-users__container"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography as="p" variant="caption" fontWeight="semiBold">
                  Search for “{searchInput}”
                </Typography>
                <InfiniteScroll
                  className="manage-key-members__search-users__container__result"
                  onScrollEnd={loadMoreItems}
                  loading={searchUserLoading}
                  showLoader={false}
                  moreItemsLeft={searchUsersMoreItemsLeft}
                >
                  {searchUsers?.length > 0 &&
                    searchUsers.map(
                      ({ name = '', role = '', id, profilePic = '' }: TMember, index) => {
                        const selectIndex = selectedKeyMembers.findIndex((user) => user.id === id);
                        return (
                          <SearchKeyMemberItem
                            key={index}
                            userId={id}
                            profilePic={profilePic}
                            name={name}
                            role={role}
                            updateSelectedUsers={updateSelectedUsers}
                            isUserSelected={selectIndex > -1}
                          />
                        );
                      }
                    )}
                </InfiniteScroll>

                {searchUserLoading && <Loader />}
              </div>
            </SubMenuModal>
          )}
        </div>
        <Separator />
        <Typography as="p" variant="body-2" fontWeight="semiBold">
          Selected users
        </Typography>
        <div className="manage-key-members__selected-users">
          {selectedKeyMembers.map(
            ({ name = '', role = '', email = '', id, profilePic = '' }: TMember, index) => {
              return (
                <KeyMemberListItem
                  key={index}
                  profilePic={profilePic}
                  name={name}
                  role={role}
                  email={email}
                  handleRemoveUser={() => updateSelectedUsers({ userId: id })}
                />
              );
            }
          )}
        </div>
        <div className="manage-key-members__buttons">
          <Button variant="secondary" size="small" onClick={onModalClose}>
            Close
          </Button>
          <Button variant="primary" size="small" loading={loading} onClick={handleAddMembers}>
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageKeyMembers;
