import { Form, Formik } from 'formik';
import { Modal, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { MailIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { sendInvite } from 'src/store/auth/auth.slice';

import inviteUserValidationSchema from './inviteUser.validationSchema';
import './inviteUser.scss';

export type TInviteUser = {
  isModalOpen: boolean;
  closeModal: () => void;
};

export type TInviteUserFormValues = {
  email: string;
};

const InviteUser = ({ isModalOpen, closeModal }: TInviteUser) => {
  const initialValues: TInviteUserFormValues = {
    email: ''
  };

  // Hooks
  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector<boolean>((store) => store.auth.loading);

  const handleSubmit = async ({ email }: TInviteUserFormValues) => {
    dispatch(sendInvite({ email }));
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      title="Invite a User"
      className="invite-user-modal"
    >
      <div className="invite-user">
        <Typography as="p" variant="body-2" fontWeight="regular" className="invite-user__heading">
          Please enter their email address below, and we&apos;ll send them an invitation to join us.
          Thank you for helping us grow and connect!
        </Typography>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={inviteUserValidationSchema}
        >
          {() => {
            return (
              <Form className="invite-user__form" noValidate>
                {/*
                 ** Email Address
                 */}
                <InputField
                  label="Email Address"
                  id="email"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Email Address"
                  startIcon={<MailIcon />}
                  type="email"
                />
                <div className="invite-user__form__buttons">
                  <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                    Close
                  </Button>

                  <Button variant="primary" type="submit" size="small" loading={loading}>
                    Send Invite
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default InviteUser;
