import * as Yup from 'yup';

const pageDetailsValidationSchema = Yup.object().shape({
  name: Yup.string()
    .required('Please enter your company name.')
    .max(250, 'Company name should not have more than 250 characters'),
  introduction: Yup.string().max(250, 'Introduction should not have more than 250 characters'),
  about: Yup.string().max(2000, 'About should not have more than 2000 characters'),
  website: Yup.string().max(250, 'Website should not have more than 250 characters')
});

export default pageDetailsValidationSchema;
