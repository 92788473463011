import { useMemo, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useCalculateElementHeight,
  useGetIdentity,
  useToggleOpenModal,
  useUrlParamValue
} from 'src/hooks';
import { ConfirmModal, TDropdownOptions } from 'src/components/common';
import useToggleCreateEditGroupModal from 'src/components/chat/createEditGroup/useToggleCreateEditGroupModal';
import useToggleManageGroupModalModal from 'src/components/chat/manageIdeaGroupMembers/useToggleManageGroupMembersModal';
import { ChatMessages as DirectChatMessages } from 'src/components/chat';
import { TUserTypes } from 'src/constants/user.constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TDeleteIdeaGroupActionRequest } from 'src/services/apiEndpoint.types.ts';
import { deleteIdeaGroup } from 'src/store/chat/chat.slice';
import { TDiscussionTypes } from 'src/components/chat/chat.type';

import GroupChatWindow from './chatMessages/GroupChatWindow';
import DiscussionList from './discussionList/DiscussionList';
import { TDiscussionProps } from './discussion.types';
import useGetIdeaGroups from './discussionList/useGetIdeaGroups.hook';
import './discussion.scss';

const Discussion = ({ ideaId, ideaOwnerId, ideaCoFounderIds }: TDiscussionProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ideaGroups = useAppSelector((store) => store.chat.ideaGroups);
  const { listIdeaChats } = useAppSelector((store) => store.chat);
  const modalId = useAppSelector((store) => store.common.modalId);
  const { paramValue } = useUrlParamValue();
  const programDiscussionRef = useRef<HTMLDivElement>(null);
  const { toggleCreateEditGroupModal } = useToggleCreateEditGroupModal({ mode: 'Edit' });
  const { toggleManageGroupModalModal } = useToggleManageGroupModalModal();
  const { getIdentities } = useGetIdentity();
  // Get idea groups
  useGetIdeaGroups({ ideaId });
  const toggleOpenModal = useToggleOpenModal();

  // Constants
  const { loggedInUserId, loggedInUserType, authenticatedId } = getIdentities();
  const discussionType: TDiscussionTypes = paramValue({
    paramName: 'chatType'
  }) as TDiscussionTypes;
  const groupId: string = paramValue({ paramName: 'groupId' }) || '';
  const selectedUserId: string = paramValue({ paramName: 'userId' }) || '';
  const selectedUserType = paramValue({ paramName: 'userType' }) as unknown as TUserTypes;
  const showChatWindowOnMobile: boolean = !selectedUserId || !selectedUserType;
  const deleteGroupConfirmModalId = `deleteGroupConfirmModal-${groupId}`;
  const isDeleteGroupConfirmModalOpen = modalId === deleteGroupConfirmModalId;
  const isViewerOwner = loggedInUserId === ideaOwnerId || ideaCoFounderIds.includes(loggedInUserId);

  const toggleOpenDeleteGroupModal = () => {
    toggleOpenModal({ modalId: deleteGroupConfirmModalId });
  };

  const handleDeleteGroup = () => {
    const payload: TDeleteIdeaGroupActionRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      ideaId,
      ideaConversationId: groupId
    };

    dispatch(deleteIdeaGroup(payload)).then(() => {
      navigate(`/view-idea?id=${ideaId}&tab=discussion`);
    });
  };

  const headerDropdownOptions: TDropdownOptions[] = [];

  if (discussionType === 'direct') {
    headerDropdownOptions.push({ label: 'View profile' }, { label: 'Clear profile' });
  } else if (discussionType === 'ideaGroup' && isViewerOwner) {
    headerDropdownOptions.push(
      { label: 'Manage Members', onClick: toggleManageGroupModalModal },
      { label: 'Edit Group Details', onClick: toggleCreateEditGroupModal },
      { label: 'Delete Group', onClick: toggleOpenDeleteGroupModal }
    );
  }

  const { elementHeight } = useCalculateElementHeight({
    ref: programDiscussionRef,
    triggers: [window.location.search],
    heightOffset: 45
  });

  const ChatComponent = useMemo(() => {
    if (discussionType === 'ideaGroup') {
      return (
        <GroupChatWindow
          discussionType={discussionType}
          groupId={groupId}
          headerDropdownOptions={headerDropdownOptions}
          ideaId={ideaId}
          isViewerOwner={isViewerOwner}
        />
      );
    } else if (discussionType === 'direct') {
      return (
        <DirectChatMessages
          selectedUserId={selectedUserId}
          selectedUserType={selectedUserType}
          showChatWindowOnMobile={showChatWindowOnMobile}
          directMessageContext="ideaDirectMessage"
        />
      );
    } else {
      return <></>;
    }
  }, [discussionType, groupId]);

  return (
    <div
      className="program-discussion"
      ref={programDiscussionRef}
      style={{ height: `${elementHeight}px` }}
    >
      <DiscussionList
        discussionType={discussionType}
        groupId={groupId}
        groups={ideaGroups}
        ideaId={ideaId}
        users={listIdeaChats}
        selectedUserId={selectedUserId}
        ideaOwnerId={ideaOwnerId}
        ideaCoFounderIds={ideaCoFounderIds}
      />
      {ChatComponent}{' '}
      {isDeleteGroupConfirmModalOpen && (
        <ConfirmModal
          title="Do wish to delete this group?"
          description="Deleting this group will permanently remove all the chats."
          openConfirmModal={isDeleteGroupConfirmModalOpen}
          onClose={toggleOpenDeleteGroupModal}
          onSubmit={handleDeleteGroup}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default Discussion;
