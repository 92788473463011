import { Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Button from 'src/components/button/Button';

import './keyMemberListItem.scss';

export type TKeyMemberListItem = {
  profilePic: string;
  name: string;
  role: string;
  email: string;
  handleRemoveUser: () => void;
};

const KeyMemberListItem = ({
  profilePic,
  name = '',
  role = '',
  email = '',
  handleRemoveUser
}: TKeyMemberListItem) => {
  return (
    <div className="key-member-list-item">
      <div className="key-member-list-item__user-info">
        <ProfilePicture
          url={profilePic}
          fallback={name[0]}
          className="key-member-list-item__user-info__profile-pic"
        />
        <div className="key-member-list-item__user-info__user">
          <div className="key-member-list-item__user-info__user__row">
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              className="key-member-list-item__user-info__user__row__name"
            >
              {name}
            </Typography>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="key-member-list-item__user-info__user__row__role"
            >
              {role}
            </Typography>
          </div>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            className="key-member-list-item__user-info__user__row__email"
          >
            {email}
          </Typography>
        </div>
      </div>
      <Button
        variant="tertiary"
        size="extraSmall"
        className="key-member-list-item__action"
        onClick={handleRemoveUser}
      >
        Remove
      </Button>
    </div>
  );
};

export default KeyMemberListItem;
