export type TFormFields = {
  fields: Record<string, any>;
  isTouched: boolean;
};

const toggleTouchedFields = ({ fields, isTouched }: TFormFields): Record<any, boolean> => {
  const touchedFields: Record<string, any> = {};
  Object.keys(fields).forEach((key) => {
    touchedFields[key] = isTouched;
  });
  return touchedFields;
};

export default toggleTouchedFields;
