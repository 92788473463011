import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { BackNavigation, Typography } from 'src/components/common';
import './feedback.scss';
import Header from 'src/components/header/Header';
import Footer from 'src/components/footer/footer';
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';

const Feedback = () => {
  const [formData, setFormData] = useState({
    role: '',
    pageURL: '',
    input: '',
    email: '',
    contactNo: '',
    description: ''
  });

  const [errors, setErrors] = useState({
    role: '',
    pageURL: '',
    input: '',
    email: '',
    contactNo: '',
    description: ''
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { id, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: value
    }));
    setErrors(prevErrors => ({
      ...prevErrors,
      [id]: ''
    }));
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = {
      role: '',
      pageURL: '',
      input: '',
      email: '',
      contactNo: '',
      description: ''
    };

    if (!formData.role) {
      newErrors.role = 'Please select your role.';
      isValid = false;
    }
    if (!formData.email) {
      newErrors.email = 'Email is required.';
      isValid = false;
    }
    if (!formData.input) {
      newErrors.input = 'Input is required.';
      isValid = false;
    }
    if (!formData.pageURL) {
      newErrors.pageURL = 'PageURL is required.';
      isValid = false;
    }
    if (!formData.contactNo) {
      newErrors.contactNo = 'Contact number is required.';
      isValid = false;
    }
    if (!formData.description) {
      newErrors.description = 'Please provide a description.';
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if (validateForm()) {
      try {
        const response = await httpRequest('feedback/submit', 'POST', formData, 'withoutjson', 'company');
        console.log(response, 'response');
       if (response.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'Feedback uploaded successfully',
            popupType: 'success'
          })
        );
        setFormData({
          role: '',
          pageURL: '',
          input: '',
          email: '',
          contactNo: '',
          description: ''
        });
      } else {
        console.error('Error: Non-200 status code received');
      }
        setFormData({
          role: '',
          pageURL: '',
          input: '',
          email: '',
          contactNo: '',
          description: ''
        });
      } catch (error) {
        console.error('Error submitting feedback:', error);
      }
    }
  };

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  return (
    <>
      <section>
        <Header />
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 m-auto'>
              <form className='signupFormfeedback'>
                <div className='btnareafeedback'>
                  <button type="button" className='back-buttonFeedback' onClick={handleBackClick}>
                    <BackNavigation text="Back" />
                  </button>
 </div>
                <Typography className="mb-4">
                  <h2>Give Your Feedback</h2>
                </Typography>
                <div className='formGroupfeedback'>
                  <label htmlFor="role">Select Your Role</label>
                  <select id="role" className='formControl' value={formData.role} onChange={handleChange} required>
                    <option value="">Select Your Role</option>
                    <option value="mentor">Mentor</option>
                    <option value="entrepreneur">Entrepreneur</option>
                    <option value="incubator">Incubator</option>
                  </select>
                  {errors.role && <span className="errorFeedback">{errors.role}</span>}
                </div>
                <div className='formGroupfeedback'>
                  <label htmlFor="pageURL">If there is any profile page you like to improve</label>
                  <input
                    type="text"
                    id="pageURL"
                    placeholder="Add Url"
                    className='formControl'
                    value={formData.pageURL}
                    onChange={handleChange}
                  />
                   {errors.pageURL && <span className="errorFeedback">{errors.pageURL}</span>}
                </div>
                <div className='formGroupfeedback'>
                  <label htmlFor="input">Add input</label>
                  <input
                    type="text"
                    id="input"
                    placeholder="Add Your Input Here"
                    className='formControl'
                    value={formData.input}
                    onChange={handleChange}
                  />
                   {errors.input && <span className="errorFeedback">{errors.input}</span>}
                </div>
                <div className='formGroupfeedback'>
                  <label htmlFor="email">Your Email</label>
                  <div className='inputGroup'>
                    <input
                      type="email"
                      id="email"
                      placeholder="Email"
                      className='formControl'
                      value={formData.email}
                      onChange={handleChange}
                      required
                    />
                   {errors.email && <span className="errorFeedback">{errors.email}</span>}
                  </div>
                </div>
                <div className='formGroupfeedback'>
                  <label htmlFor="contactNo">Your Contact</label>
                  <div className='inputGroup'>
                    <input
                      type="text"
                      id="contactNo"
                      placeholder="Contact Number"
                      className='formControl'
                      value={formData.contactNo}
                      onChange={handleChange}
                      required
                    />
                    {errors.contactNo && <span className="errorFeedback">{errors.contactNo}</span>}
                  </div>
                </div>
                <div className='formGroupfeedback'>
                  <label htmlFor="description">What are the areas of Improvement</label>
                  <textarea
                    id="description"
                    placeholder="Describe the areas where improvement is needed"
                    className='formControl'
                    value={formData.description}
                    onChange={handleChange}
                    required
                  />
                  {errors.description && <span className="errorFeedback">{errors.description}</span>}
                </div>
                <div className='mt-5'>
                  <Button type="submit" className="submitButton2" onClick={handleSubmit}>Submit</Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    </>
  );
};

export default Feedback;
