import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ThumbsUpIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import {
  ActionIcon,
  TDropdown,
  TDropdownOptions,
  ThreeDotsDropdown,
  Typography
} from 'src/components/common';
import { timeAgo, transformTextToHyperlink } from 'src/utils';
import { useCopyToClipboard } from 'src/hooks';
import { useAppDispatch } from 'src/store/hooks';
import {
  deleteDirectMessage,
  dislikeConversationMessage,
  likeConversationMessage,
  downloadChatFile,
  clearChatFiles,
  likeMessageSelf,
  dislikeMessageSelf
} from 'src/store/chat/chat.slice';
import {
  TDeleteDirectMessageRequest,
  TDislikeConversationMessageRequest,
  TDownloadChatFileRequest,
  TLikeConversationMessageRequest
} from 'src/services/apiEndpoint.types.ts';

import './chatMessage.scss';
import { TChatMessage } from './chatMessage.types';

import FileCard from '../../fileCard/FileCard';

const ChatMessage = ({
  userId,
  message,
  id: messageId,
  updatedAt,
  updateMessageSettings,
  isLiked,
  likeCount = 0,
  conversationId,
  loggedInUserId,
  loggedInUserType,
  files,
  User,
  Company,
  userType,
  directMessageContext
}: TChatMessage) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { copyToClipboard } = useCopyToClipboard();

  // Ref
  const chatMessageTextRef = useRef<HTMLElement>(null);

  // Constants
  const messageCreatorUserId: string = userType === 'User' ? User?.id : Company?.id;

  const deleteMessage = () => {
    const payload: TDeleteDirectMessageRequest = {
      eventName:
        directMessageContext === 'directMessage'
          ? 'delete-conversation-message'
          : 'delete-idea-conversation-message',
      messageId
    };

    dispatch(deleteDirectMessage(payload));
  };

  const dropdownItems: TDropdownOptions[] = [
    {
      label: 'Copy',
      onClick: () => {
        copyToClipboard({ text: message });
      }
    },
    {
      label: 'Edit',
      onClick: () => {
        dispatch(clearChatFiles());
        updateMessageSettings({ editMode: true, messageId, message });
      }
    },
    {
      label: 'Delete',
      onClick: deleteMessage
    }
  ];

  const dropdownOptionsProps: TDropdown = {
    id: `chat-message-${messageId}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const messageType: 'sent' | 'received' =
    loggedInUserId === messageCreatorUserId && loggedInUserType === userType ? 'sent' : 'received';

  const likeMessage = () => {
    const payload: TLikeConversationMessageRequest = {
      messageId,
      userId: loggedInUserId,
      userType: loggedInUserType,
      eventName:
        directMessageContext === 'directMessage'
          ? 'like-conversation-message'
          : 'like-idea-conversation-message'
    };

    dispatch(likeMessageSelf({ conversationId, messageId }));
    dispatch(likeConversationMessage(payload));
  };

  const unlikeMessage = () => {
    const payload: TDislikeConversationMessageRequest = {
      messageId,
      userId: loggedInUserId,
      userType: loggedInUserType,
      eventName:
        directMessageContext === 'directMessage'
          ? 'dislike-conversation-message'
          : 'dislike-idea-conversation-message'
    };

    dispatch(dislikeMessageSelf({ conversationId, messageId }));
    dispatch(dislikeConversationMessage(payload));
  };

  const downloadFile = ({ s3Key }: { s3Key: string }) => {
    const payload: TDownloadChatFileRequest = { s3Key };

    dispatch(downloadChatFile(payload));
  };

  useEffect(() => {
    const ele: HTMLElement | null = chatMessageTextRef.current;
    if (ele) {
      const text = ele.innerText;
      const replacedText = transformTextToHyperlink({ text });
      ele.innerHTML = replacedText;
    }
  }, [chatMessageTextRef, message]);

  return (
    <div className={classNames('chat-message', `chat-message--is-${messageType}`)}>
      <div
        className={classNames('chat-message__header', `chat-message__header--is-${messageType}`)}
      >
        <div className="chat-message__header__left">
          {message && (
            <Typography
              as="span"
              variant="body-2"
              fontWeight="regular"
              className="chat-message__header__left__message"
              style={{ color: colors?.neutral?.[1100] }}
              typographyRef={chatMessageTextRef}
            >
              {message}
            </Typography>
          )}
          {files?.length > 0 && (
            <div className="chat-message__header__left__files">
              {files.map(({ key, id, fileName }) => {
                return (
                  <FileCard
                    key={key}
                    id={id}
                    fileName={fileName}
                    onClick={() => downloadFile({ s3Key: key })}
                  />
                );
              })}
            </div>
          )}
        </div>
        {messageType === 'sent' && <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />}
      </div>

      <div className="chat-message__actions">
        <div className="discussion-message__actions__left">
          <ActionIcon
            active={isLiked}
            onClick={() => {
              if (isLiked) {
                unlikeMessage();
              } else {
                likeMessage();
              }
            }}
          >
            <ThumbsUpIcon />
          </ActionIcon>
          {likeCount > 0 && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="medium"
              style={{ color: colors?.neutral?.[700] }}
            >
              {likeCount}
            </Typography>
          )}
        </div>

        <Typography
          as="span"
          variant="caption"
          fontWeight="regular"
          style={{ color: colors?.neutral?.[900] }}
        >
          {timeAgo({ timestamp: updatedAt })}
        </Typography>
      </div>
    </div>
  );
};

export default ChatMessage;
