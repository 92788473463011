import { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackNavigation, ConfirmModal, Stepper, TStep, Typography } from 'src/components/common';
import Header from 'src/components/header/Header';
import { useGetIdentity, useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { CreateProgramReview, CustomApplicationForm, ProgramDetails } from 'src/components/company';
import { TApplicationFormSection } from 'src/components/company/programs';
import { StorageUtils } from '../../utils';
import {
  TCustomField,
  TProgramDetailsInitialValues,
  TProgramDetailsRequestPayload
} from 'src/components/company/programs/createProgram';
import { TCreateProgramRequest, TUploadProgramFileAction } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { createProgram, getUploadProgramFile } from 'src/store/program/program.slice';

import './createProgram.scss';
import { programDetailsInitialFields } from './createProgram.data';

export const cancelCreateProgramModalId = 'cancelCreateProgramModal';

const CreateProgram = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const modalId: string = useAppSelector((store) => store.common.modalId);
  const toggleOpenModal = useToggleOpenModal();

  const { loggedInCompanyId } = getIdentities();

  // State
  const [createProgramStep, setCreateProgramStep] = useState<number>(0);
  const [applicationFormSections, setApplicationFormSections] = useState<TApplicationFormSection[]>(
    []
  );
  const [shortlistStages, setShortlistStages] = useState<number>(0);
  const [ProgramDraft, setProgramDraft] = useState<any>({});

  const [customFields, setCustomFields] = useState<TCustomField[]>(programDetailsInitialFields);
  const [programFiles, setProgramFiles] = useState<
    Record<string, { id: string; fileName: string; data?: string | ArrayBuffer | null }[]>
  >({});

  // Constants
  const isCancelCreateProgramModalOpen = modalId === cancelCreateProgramModalId;

  const updateShortlistStages = ({ value }: { value: number }) => {
    setShortlistStages(value);
  };

  const handleToggleOpenCancelCreateProgramModal = () => {
    toggleOpenModal({ modalId: cancelCreateProgramModalId });
  };
  const { paramValue } = useUrlParamValue();
  const programListParams = useMemo(() => {
    const programId: string = paramValue({ paramName: 'Draft' });
    // const filterProgram: TFilterProgram = (paramValue({ paramName: 'filterProgram' }) ||
    //   'All') as TFilterProgram;
    return { programId };
  }, [window.location.search]);
  const [programDetailsFormikValues, setCreateProgramFormikValues] =
    useState<TProgramDetailsInitialValues | null>(null);
  const [programDetailsValues, setCreateProgramValues] = useState<TProgramDetailsRequestPayload>({
    title: '',
    startDate: '',
    endDate: '',
    stages: 0,
    banner: { url: '' },
    stageTitles: [],
    stageNotification: [],
    programDetails: []
  });
  const handleNext = () => {
    setCreateProgramStep((prev) => prev + 1);
  };

  const handleCancelProgram = () => {
    localStorage.removeItem('programDetailsFormValues');
    handleToggleOpenCancelCreateProgramModal();
    navigate('/my-company?tab=programs');
  };

  const continueToCustomApplication = ({
    values,
    formikValues
  }: {
    values: TProgramDetailsRequestPayload;
    formikValues: TProgramDetailsInitialValues;
  }) => {
    console.log(formikValues, 'formikValues', values);
    // Files
    try {
      const filesObject: Record<
        string,
        { id: string; fileName: string; data?: string | ArrayBuffer | null }[]
      > = {};

      values?.programDetails?.forEach((item) => {
        const filesArray: { id: string; fileName: string; data?: string | ArrayBuffer | null }[] =
          [];

        const id = item?.id;
        const files = item?.files;

        if (files && files?.length > 0) {
          files.forEach((file) => {
            filesArray.push({ id: file?.key, fileName: file?.fileName, data: file?.data });
          });
        }

        if (filesArray?.length > 0) {
          filesObject[id] = filesArray;
        }
      });
      setProgramFiles(filesObject);
    } catch { }
    setCreateProgramValues((prev) => ({ ...prev, ...values }));
    setCreateProgramFormikValues(formikValues);
    setCreateProgramStep(1);
  };

  const handleBack = () => {
    if (createProgramStep < 1) {
      handleToggleOpenCancelCreateProgramModal();
    } else {
      setCreateProgramStep((prev) => prev - 1);
    }
  };

  const handleCreateProgram = () => {
    const {
      title,
      startDate,
      endDate,
      banner,
      stages: stagesNumber,
      stageNotification,
      stageTitles,
      programDetails: programDetailsField
    } = programDetailsValues;

    const stages: {
      stageNumber: number;
      stageTitle: string;
      enableApplicantNotification: boolean;
    }[] = [...Array(stagesNumber).keys()].map((num) => ({
      stageNumber: num + 1,
      stageTitle: stageTitles?.[num] || `Stage ${num + 1}`,
      enableApplicantNotification: stageNotification?.[num] || false
    }));
    const programDetails = programDetailsField.map((field) => {
      const { id, label, type, values, files } = field;
      return { id, label, type, values, files };
    });

    const applicationForm = applicationFormSections.map((section) => {
      const { sectionTitle, sectionItems: sectionItemsOriginal } = section;
      const sectionItems = sectionItemsOriginal.map((section) => {
        const { id, label, type, validations, required, response, options, files } = section;
        return { id, label, type, validations, required, response, options, files };
      });
      return { sectionTitle, sectionItems };
    });
    const { programId } = programListParams;
    let createCompanyPayload: TCreateProgramRequest = {
      companyId: loggedInCompanyId,
      title,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      stages,
      draftId: programId,
      programDetails,
      applicationForm: { sections: applicationForm }
    };

    if (banner?.url) {
      createCompanyPayload = {
        ...createCompanyPayload,
        banner
      }
    }
    dispatch(createProgram(createCompanyPayload)).then((response) => {
      const programId = response?.data?.data?.id || '';

      Object.keys(programFiles).forEach((key) => {
        try {
          const files = programFiles[key];

          files.forEach((file) => {
            const payload: TUploadProgramFileAction = {
              programId,
              id: file?.id,
              fileName: file?.fileName,
              data: file?.data || ''
            };

            dispatch(getUploadProgramFile(payload));
          });
        } catch { }
      });
    });
  };
  const handleDraft = () => {
    const {
      title,
      startDate,
      endDate,
      banner,
      stages: stagesNumber,
      stageNotification,
      stageTitles,
      programDetails: programDetailsField
    } = programDetailsValues;
    StorageUtils.remove('applicationFormSections')
    StorageUtils.remove('programDetailsFormValues')
    const stages: {
      stageNumber: number;
      stageTitle: string;
      enableApplicantNotification: boolean;
    }[] = [...Array(stagesNumber).keys()].map((num) => ({
      stageNumber: num + 1,
      stageTitle: stageTitles?.[num] || `Stage ${num + 1}`,
      enableApplicantNotification: stageNotification?.[num] || false
    }));

    const programDetails = programDetailsField.map((field) => {
      const { id, label, type, values, files } = field;
      return { id, label, type, values, files };
    });

    const applicationForm = applicationFormSections.map((section) => {
      const { sectionTitle, sectionItems: sectionItemsOriginal } = section;
      const sectionItems = sectionItemsOriginal.map((section) => {
        const { id, label, type, validations, required, response, options, files } = section;
        return { id, label, type, validations, required, response, options, files };
      });
      return { sectionTitle, sectionItems };
    });
    const { programId } = programListParams;
    let createCompanyPayload: any = {
      companyId: loggedInCompanyId,
      title,
      startDate: new Date(startDate).toISOString(),
      endDate: new Date(endDate).toISOString(),
      stages,
      programDetails,
      ProgramDraft,
      draft: true,
      draftId: programId,
      applicationFormDraft: applicationFormSections,
      applicationForm: { sections: applicationForm }
    };

    if (banner?.url) {
      createCompanyPayload = {
        ...createCompanyPayload,
        banner
      }
    }

    dispatch(createProgram(createCompanyPayload)).then((response) => {
      const programId = response?.data?.data?.id || '';

      Object.keys(programFiles).forEach((key) => {
        try {
          const files = programFiles[key];

          files.forEach((file) => {
            const payload: TUploadProgramFileAction = {
              programId,
              id: file?.id,
              fileName: file?.fileName,
              data: file?.data || ''
            };

            dispatch(getUploadProgramFile(payload));
          });
        } catch { }
      });
    });
  };
  const stepComponents = [
    <ProgramDetails
      key="program-details"
      shortlistStages={shortlistStages}
      updateShortlistStages={updateShortlistStages}
      customFields={customFields}
      setCustomFields={setCustomFields}
      setProgramDraft={setProgramDraft}
      programDetailsFormikValues={programDetailsFormikValues}
      continueToCustomApplication={continueToCustomApplication}
    />,
    <CustomApplicationForm
      key="custom-application-form"
      handleNext={handleNext}
      handleDraft={handleDraft}
      // setApplicationDraft={setApplicationDraft}
      applicationFormSections={applicationFormSections}
      setApplicationFormSections={setApplicationFormSections}
    // handleAddFieldApplicationForm={handleAddFieldApplicationForm}
    />,
    <CreateProgramReview
      key="create-program-review"
      handleSave={handleCreateProgram}
      applicationFormSections={applicationFormSections}
      createProgramStep={createProgramStep}
    />
  ];

  const steps: TStep[] = [
    { label: 'Program Details' },
    { label: 'Custom Application Form' },
    { label: 'Review' }
  ];

  return (
    <div className="create-program">
      <Header />
      <div className="create-program__body">
        <BackNavigation text="Back" route="/dashboard" onClick={handleBack} />
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Create New Program
        </Typography>
        <Stepper
          currentStep={createProgramStep}
          steps={steps}
          className="create-program__body__stepper"
        />
        <div className="create-program__body__step">{stepComponents[createProgramStep]}</div>
      </div>
      {isCancelCreateProgramModalOpen && (
        <ConfirmModal
          title="Are you sure you want to exit?"
          description="Any modifications made here will not be saved"
          openConfirmModal={isCancelCreateProgramModalOpen}
          onClose={handleToggleOpenCancelCreateProgramModal}
          onSubmit={handleCancelProgram}
          submitText="Confirm"
          type="error"
        />
      )}
    </div>
  );
};

export default CreateProgram;
