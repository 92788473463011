import React from 'react';
import { TProgramStage } from '../evaluation.types';
import cx from '../evaluation.module.scss'

export default function GradesItem({ stageData, stageHandler, selectedStage }:{stageData:TProgramStage, stageHandler: (stageData: TProgramStage) => void, selectedStage:TProgramStage | null}) {
  return (
        <li className={`${cx.listItem} ${selectedStage?.id === stageData?.id ? cx.active : ''}`} onClick={() => stageHandler(stageData)}>
            {stageData?.stageTitle || ''}
        </li>
  )
}
