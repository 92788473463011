import PhoneNumberArea from 'src/components/common/formFields/phoneNumber/PhoneNumber';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const PhoneNumber = ({ id, label, hasCharacterLimit, validations, required, createProgramStep }: TPreviewFieldProps) => {
  return (
    <div key={id} className="dynamic-field">
      <Label required={required}>{label}</Label>
      <div className="dynamic-field__field">
        {' '}
        <PhoneNumberArea
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder={label}
          type="text"
          disabled
          {...(hasCharacterLimit && { textLengthLimit: validations.maxCharacters })}
          createProgramStep
        />
      </div>
    </div>
  );
};

export default PhoneNumber;
