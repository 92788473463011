import { useAppSelector } from 'src/store/hooks';
import { Typography } from 'src/components/common';
import Button from 'src/components/button/Button';

import './fallbackComponent.scss';

const FallbackComponent = () => {
  const isAuthorized: boolean = useAppSelector<boolean>((store) => store.auth.isAuthorized);

  const handleHome = () => {
    if (isAuthorized) {
      window.location.href = '/dashboard';
    } else {
      window.location.href = '/';
    }
  };

  return (
    <div className="fallback-component">
      <div className="fallback-component__content">
        <Typography variant="display-3" fontWeight="bold">
          Something went wrong!
        </Typography>
        <Button variant="primary" size="large" onClick={handleHome}>
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default FallbackComponent;
