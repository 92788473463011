import { ActionIcon, Typography } from 'src/components/common';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import InputField from 'src/components/common/formFields/inputField/InputField';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';

import { TCustomApplicationSection } from '../customApplicationForm.types';

const AddLabel = ({
  id,
  label,
  sectionId,
  type,
  options,
  required,
  validations,
  response,
  files,
  placeholder,
  hasCharacterLimit,
  openChangeFieldModal,
  openDeleteFieldModal,
  handleUpdateField,
  errors
}: TDynamicFieldProps) => {
  const updateField = ({ fieldChunk }: { fieldChunk: Partial<TCustomApplicationSection> }) => {
    const field: TCustomApplicationSection = {
      id,
      label,
      type,
      options,
      required,
      validations,
      response,
      files,
      placeholder,
      hasCharacterLimit,
      ...fieldChunk
    };

    handleUpdateField({ sectionId, fieldId: id, field });
  };

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          Label field
        </Typography>
        <div className="dynamic-field__header__actions">
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              openDeleteFieldModal({ fieldId: id, sectionId });
            }}
          >
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>

      <InputField
        id={id}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder="Enter field title"
        type="text"
        value={label}
        onChange={(e) => {
          updateField({ fieldChunk: { label: e.target.value } });
        }}
        errorMessage={errors?.[id]}
        isTouched={true}
      />
    </div>
  );
};

export default AddLabel;
