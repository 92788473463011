interface IConvertToString {
  num: number | string | undefined;
  fallbackValue: string;
}

/**
 * Safely converts a item to string
 * @param  num input value
 * @param fallbackValue fallback value in case of exceptions
 */

const convertToString = ({ num, fallbackValue = '' }: IConvertToString): string => {
  try {
    if (num) {
      return num.toString();
    }
    return fallbackValue;
  } catch {
    return fallbackValue;
  }
};

export default convertToString;
