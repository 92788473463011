import { Fragment, HTMLAttributes } from 'react';
import classNames from 'classnames';

import './stepper.scss';

import Typography from '../typography/Typography';
import { TickMarkIcon } from '../common.icons';

export type TStep = {
  label: string;
};

export type TStepper = {
  steps: TStep[];
  currentStep: number;
} & HTMLAttributes<HTMLDivElement>;

const Stepper = ({ steps, currentStep, className, ...rest }: TStepper) => {
  return (
    <div className={classNames('stepper-component', className)} {...rest}>
      {steps.map((step, index) => {
        const isStepCompleted: boolean = index < currentStep;

        return (
          <Fragment key={index}>
            <div
              key={index}
              className={classNames('stepper-component__step', {
                'stepper-component__step--is-active': index === currentStep,
                'stepper-component__step--is-completed': isStepCompleted
              })}
            >
              <div className="stepper-component__step__number">
                <Typography as="p" variant="body-2" fontWeight="semiBold">
                  {isStepCompleted ? <TickMarkIcon /> : index + 1}
                </Typography>
              </div>
              <Typography
                as="p"
                variant="body-2"
                fontWeight="semiBold"
                className="stepper-component__step__label"
              >
                {step.label}
              </Typography>
            </div>
            <hr
              key={`separator-${index}`}
              className={classNames('stepper-component__separator', {
                'stepper-component__separator--is-active': index === currentStep,
                'stepper-component__separator--is-hidden': index + 1 >= steps?.length
              })}
            ></hr>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Stepper;
