import { useEffect, useContext } from 'react';
import PostCard from 'src/components/posts/postCard/PostCard';
import CreateComment from 'src/components/posts/createComment/CreateComment';
import ViewComment from 'src/components/posts/viewComment/ViewComment';
import { Card, TDropdownOptions, Separator, Loader, Typography } from 'src/components/common';
import { TGetCommentsRequest, TPost } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  clearComments,
  getComments,
  incrementCommentsPage,
  updateShowCommentsId
} from 'src/store/socialMedia/socialMedia.slice';
import { TUserTypes } from 'src/constants/user.constants';
import { SearchResultsContext } from 'src/pages/search/Search';

import './viewPost.scss';

export type TViewPost = {
  dropdownOptions: TDropdownOptions[];
  expandPost: boolean;
  post: TPost;
  viewerId: string;
  viewerType: TUserTypes;
  handleFollowFromSearchResult?: () => void;
  redirectToViewPost?: ({
    postId,
    ownerId,
    post
  }: {
    postId: string;
    ownerId: string;
    post: TPost;
  }) => void;
};

// This component must know id and type of the viewer
const ViewPost = ({
  dropdownOptions,
  post,
  viewerId,
  viewerType,
  expandPost,
  handleFollowFromSearchResult,
  redirectToViewPost
}: TViewPost) => {
  // Hooks
  const {
    commentsPageNumber,
    commentsPerPage,
    comments,
    showCommentsId,
    commentsLoading,
    MoreCommentsLeft
  } = useAppSelector((store) => store.socialMedia);
  const isSearchResultContext = useContext(SearchResultsContext); // Whether rendered from search module

  const postId = post?.post?.id;
  const showComments = postId === showCommentsId;

  const dispatch = useAppDispatch();

  const handleGetComments = () => {
    const payload: TGetCommentsRequest = {
      userId: viewerId,
      userType: viewerType,
      pageNumber: commentsPageNumber,
      commentsPerPage,
      postId
    };

    dispatch(getComments(payload));
  };

  const onCommentClick = () => {
    if (isSearchResultContext && redirectToViewPost) {
      redirectToViewPost({ postId, ownerId: post?.user?.id, post });
    } else {
      dispatch(updateShowCommentsId(postId));
    }
  };

  const handleCloseCreateComment = () => {
    dispatch(clearComments());
  };

  const handleFetchMoreComments = () => {
    dispatch(incrementCommentsPage(1));
  };

  useEffect(() => {
    if (showComments && expandPost) {
      handleGetComments();
    }
  }, [showComments, commentsPageNumber, expandPost]);
  return (
    <Card className="view-post">
      {/*
       ** Post card
       */}
      <PostCard
        dropdownItems={dropdownOptions}
        onCommentClick={onCommentClick}
        highlightComment={showComments}
        post={post}
        viewerId={viewerId}
        viewerType={viewerType}
        handleFollowFromSearchResult={handleFollowFromSearchResult}
      />
      {/*
       ** Comments
       */}
      {showComments && expandPost && (
        <>
          <CreateComment
            onClose={handleCloseCreateComment}
            post={post}
            handleGetComments={handleGetComments}
            viewerId={viewerId}
            viewerType={viewerType}
          />
        </>
      )}

      {showComments && expandPost && (
        <>
          {comments?.length > 0 && (
            <>
              <Separator />
              <div className="view-post__comments">
                {comments.map((item, index) => {
                  return (
                    <ViewComment
                      key={index}
                      comment={item}
                      postId={postId}
                      handleGetComments={handleGetComments}
                      viewerId={viewerId}
                      viewerType={viewerType}
                    />
                  );
                })}
              </div>
            </>
          )}
          {MoreCommentsLeft && !commentsLoading && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="view-post__load-more-comments"
              onClick={handleFetchMoreComments}
            >
              Show more comments
            </Typography>
          )}
          {commentsLoading && <Loader />}
        </>
      )}
    </Card>
  );
};

export default ViewPost;
