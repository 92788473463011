import { Navigate, Outlet } from 'react-router-dom';

const ProtectedRoute = ({
  isAuthorized,
  isPublic
}: {
  isAuthorized: boolean;
  isPublic?: boolean;
}) => {
  // const access = localStorage.getItem('access');
  // eslint-disable-next-line no-constant-condition, no-mixed-operators
  return (isPublic || isAuthorized) ? <Outlet /> : <Navigate to="/" />;
};

export default ProtectedRoute;
