import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const NotificationIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.9987 18.0625C12.9987 19.7194 11.6556 21.0625 9.99874 21.0625C8.34189 21.0625 6.99874 19.7194 6.99874 18.0625M11.7952 5.30106C12.2307 4.85114 12.4987 4.23812 12.4987 3.5625C12.4987 2.18179 11.3795 1.0625 9.99874 1.0625C8.61803 1.0625 7.49874 2.18179 7.49874 3.5625C7.49874 4.23812 7.76675 4.85114 8.20224 5.30106M15.9987 10.2625C15.9987 8.88337 15.3666 7.56073 14.2414 6.58554C13.1162 5.61036 11.59 5.0625 9.99874 5.0625C8.40745 5.0625 6.88132 5.61036 5.7561 6.58554C4.63089 7.56073 3.99874 8.88337 3.99874 10.2625C3.99874 12.5443 3.43288 14.2131 2.72681 15.4072C1.92208 16.7681 1.51972 17.4486 1.53561 17.6111C1.55379 17.7971 1.58726 17.8558 1.73808 17.9661C1.86991 18.0625 2.53225 18.0625 3.85693 18.0625H16.1406C17.4652 18.0625 18.1276 18.0625 18.2594 17.9661C18.4102 17.8558 18.4437 17.7971 18.4619 17.6111C18.4778 17.4486 18.0754 16.7681 17.2707 15.4072C16.5646 14.2131 15.9987 12.5443 15.9987 10.2625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotificationIcon;
