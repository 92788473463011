import React from 'react';
import { nanoid } from 'nanoid';
import { BulbIcon, FileIcon, GlobeIcon, PeopleIcon } from 'src/components/common/common.icons';
import Typography from 'src/components/common/typography/Typography';
import { TFeature } from 'src/constants/landingpage.types';

import FeatureBlock from '../featureBlock/FeatureBlock';

const KeyFeatures: React.FC = () => {
  const items: TFeature[] = [
    {
      icon: <BulbIcon />,
      title: 'Idea Hub',
      description: 'Idea Creation. Collaboration. Validation.'
    },
    {
      icon: <PeopleIcon />,
      title: 'Networking',
      description: 'Connect. Collaborate. Create Magic.'
    },
    {
      icon: <FileIcon />,
      title: 'Apply to Program',
      description: 'Discover Incubator Programs and apply with Ease.'
    },
    {
      icon: <GlobeIcon />,
      title: 'Curated Resources',
      description: 'Empower the journey by curated learning resources.'
    }
  ];

  return (
    <div className="landing-page__key-features">
      <Typography
        as="h2"
        variant="heading-2"
        fontWeight="bold"
        className="landing-page__key-features__heading"
      >
        Key{' '}
        <Typography
          as="span"
          variant="heading-2"
          fontWeight="bold"
          className="landing-page__key-features__heading--highlight"
        >
          Features
        </Typography>{' '}
        of{' '}
        <Typography
          as="span"
          variant="heading-2"
          fontWeight="bold"
          className="landing-page__key-features__heading--highlight"
        >
          htraction
        </Typography>
      </Typography>
      <div className="landing-page__key-features__features">
        {items.map((item: TFeature) => (
          <FeatureBlock {...item} key={nanoid()} />
        ))}
      </div>
    </div>
  );
};

export default KeyFeatures;
