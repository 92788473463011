/* eslint-disable no-unused-vars */

import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Modal, TTabItem, Tabs, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  TEditCollaboratorsRequest,
  TGetCollaboratorsRequest,
  TGetIdeaRequest,
  TListEvaluationResponsesRequest
  // TSearchCollaborator
} from 'src/services/apiEndpoint.types.ts';
import {
  editCollaborators,
  getCollaborators,
  getIdea,
  listEvaluatorResponses,
  resetListEvaluators
} from 'src/store/ideaHub/ideaHub.slice';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { SearchIcon } from 'src/components/common/common.icons';
import { useGetIdentity } from 'src/hooks';

import './manageAccess.scss';
import EditCollaborators from './editCollaborators/EditCollaborators';
import Evaluators from './evaluators/Evaluators';

import { TSelectedCollaborator } from '../shareToCollaborate/ShareToCollaborate';
import { TIdeaCollaborationType, TIdeaCollaborator } from '../../ideaHub.types';

export type TManageAccessProps = {
  ideaId: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
  fetchKeyMembers: () => void;
};

export type TSelectedEvaluator = {
  id: string;
  name: string;
  role: string;
  email: string;
  profilePic: string;
};

const ManageAccess = ({ ideaId, isModalOpen, handleCloseModal }: TManageAccessProps) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedCollaborators, setSelectedCollaborators] = useState<TSelectedCollaborator[]>([]);
  const [currentTab, setCurrentTab] = useState<'collaborators' | 'evaluators'>('collaborators');
  const [removeCollaborators, setRemoveCollaborators] = useState<string[]>([]);

  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, authenticatedId } = getIdentities();

  const { loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const { viewIdea } = useAppSelector((store) => store.ideaHub);

  const collaborators: TIdeaCollaborator[] = viewIdea?.collaborators;

  const [currentCollaborators, setCurrentCollaborators] = useState<TIdeaCollaborator[]>(
    JSON.parse(JSON.stringify(collaborators))
  );

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setRemoveCollaborators((prev) => prev.concat(userId));
    setCurrentCollaborators((prev) => prev.filter((user) => user.id !== userId));
  };

  const updatedCollaborationType = ({ id, type }: { id: string; type: TIdeaCollaborationType }) => {
    const currentUsers: TIdeaCollaborator[] = JSON.parse(JSON.stringify(currentCollaborators));
    const selectedUserIndex: number = currentUsers.findIndex((user) => user.id === id);

    if (currentUsers[selectedUserIndex]) {
      currentUsers[selectedUserIndex].IdeaCollaboratorMapping.collaborationType = type;

      setCurrentCollaborators(currentUsers);
    }
  };

  const changeIdeaHubTab = (value: string) => {
    setCurrentTab(value as 'collaborators' | 'evaluators');
  };

  const tabItems: TTabItem[] = [
    {
      key: 'collaborators',
      text: 'Collaborators',
      disabled: false,
      component: (
        <EditCollaborators
          ideaId={ideaId}
          collaborators={currentCollaborators}
          selectedCollaborators={selectedCollaborators}
          updatedCollaborationType={updatedCollaborationType}
          updateSelectedUsers={updateSelectedUsers}
        />
      )
    },
    {
      key: 'evaluators',
      text: 'Evaluators',
      disabled: false,
      component: <Evaluators ideaId={ideaId} filterString={searchInput} />
    }
  ];

  const handleEditCollaborators = () => {
    const usersToEdit = currentCollaborators.map((user) => ({
      userId: user.id,
      collaborationType: user.IdeaCollaboratorMapping.collaborationType
    }));
    const usersToRemove = removeCollaborators.map((user) => ({ userId: user }));
    const payload: TEditCollaboratorsRequest = {
      ideaId,
      userId: authenticatedId,
      usersToEdit,
      ...(usersToRemove?.length > 0 && { usersToRemove })
    };

    dispatch(editCollaborators(payload)).then(() => {
      fetchIdea();
    });
  };

  const fetchEvaluatorResponses = () => {
    const payload: TListEvaluationResponsesRequest = {
      ideaId,
      limit: 100,
      offset: 1
    };
    dispatch(listEvaluatorResponses(payload));
  };

  const fetchCollaborators = () => {
    const payload: TGetCollaboratorsRequest = {
      ideaId
    };
    dispatch(getCollaborators(payload));
  };

  const fetchIdea = () => {
    const payload: TGetIdeaRequest = {
      ideaId,
      userId: loggedInUserId
    };
    dispatch(getIdea(payload));
  };

  const handleClose = () => {
    dispatch(resetListEvaluators());
    handleCloseModal();
  };

  useEffect(() => {
    fetchCollaborators();
    fetchEvaluatorResponses();
    fetchIdea();
  }, [ideaId, isModalOpen, JSON.stringify(collaborators)]);

  useEffect(() => {
    const newCollaborators: TIdeaCollaborator[] = JSON.parse(JSON.stringify(collaborators));

    if (!searchInput) {
      const filteredCollaborators = newCollaborators.filter(
        (user) => !removeCollaborators.includes(user.id)
      );
      setCurrentCollaborators(filteredCollaborators);
      setCurrentCollaborators(filteredCollaborators);
    } else {
      try {
        const filteredCollaborators = newCollaborators
          .filter(
            (user) =>
              user?.name?.toUpperCase()?.includes(searchInput?.toUpperCase()) ||
              user?.email?.toUpperCase().includes(searchInput.toUpperCase())
          )
          .filter((user) => !removeCollaborators.includes(user.id));
        setCurrentCollaborators(filteredCollaborators);
      } catch {
        setCurrentCollaborators(newCollaborators);
      }
    }
  }, [searchInput]);

  useEffect(() => {
    setCurrentCollaborators(JSON.parse(JSON.stringify(collaborators)));
  }, [isModalOpen, ideaId]);

  useEffect(() => {
    setSearchInput('');
  }, [currentTab]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleClose}
      className="manage-access-modal"
      title="Manage Access"
    >
      <div className="manage-access">
        <div className="edit-collaborators__search-users">
          <Typography as="p" variant="body-2" fontWeight="regular">
            You can manage the group member adding or removing the user.
          </Typography>
          <Formik
            initialValues={{ email: '' }}
            onSubmit={() => {
              // Handled using local state
            }}
          >
            {() => {
              return (
                <Form>
                  <InputField
                    label="Search for users who have access to this idea."
                    id="email"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Search user by name, email address"
                    startIcon={<SearchIcon />}
                    type="text"
                    value={searchInput}
                    onChange={(e) => {
                      setSearchInput(e.target.value);
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
        <Tabs activeItem={currentTab} onTabChange={changeIdeaHubTab} items={tabItems} />
        <div className="manage-access__buttons">
          <Button variant="secondary" size="small" onClick={handleCloseModal}>
            Close
          </Button>
          <Button
            variant="primary"
            size="small"
            loading={loading}
            onClick={handleEditCollaborators}
          >
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageAccess;
