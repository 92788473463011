const timeAgo = ({ timestamp }: { timestamp: string }): string => {
  try {
    const now: number = Date.now();
    const elapsed: number = now - new Date(timestamp).getTime();

    const msInMinute = 60 * 1000;
    const msInHour = 60 * msInMinute;
    const msInDay = 24 * msInHour;

    if (elapsed < msInHour) {
      const minutes = Math.round(elapsed / msInMinute);
      return `${minutes} min${minutes !== 1 ? 's' : ''} ago`;
    } else if (elapsed < msInDay) {
      const hours = Math.round(elapsed / msInHour);
      return `${hours} hour${hours !== 1 ? 's' : ''} ago`;
    } else {
      const days = Math.round(elapsed / msInDay);
      return `${days} day${days !== 1 ? 's' : ''} ago`;
    }
  } catch {
    return '-';
  }
};

export default timeAgo;
