import { Typography } from 'src/components/common';
import { TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { getProgramDocument } from 'src/store/program/program.slice';
import { useAppDispatch } from 'src/store/hooks';
import { formatDate } from 'src/utils';

import './applicationSectionView.scss';
import { TApplicationSectionViewProps } from './applicationSectionView.types';

import FileCard from '../../fileCard/FileCard';
const ApplicationSectionView = ({ sectionTitle, data = [] }: TApplicationSectionViewProps) => {
  const dispatch = useAppDispatch();

  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };
  return (
    <div className="program-application-section-view">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="program-application-section-view__title"
      >
        {sectionTitle}
      </Typography>
      <div className="program-application-section-view__fields">
        {data?.map((field, index) => {
          const { label, value, type, files } = field;
          console.log(value, 'dataaaa')

          let headers:Array<string> = []
          let tableData:Array<string> = []
          if (type === 'tableColumns') {
            tableData = JSON.parse(value)
            headers = Object.keys(tableData[0]);
          }
          return (
            <div key={index} className="program-application-section-view__fields__field">
              <Typography
                as="p"
                variant="body-2"
                fontWeight="semiBold"
                className="program-application-section-view__fields__field__label"
              >
                {/* {label} */}
                {`${index + 1}. ${label}`}
              </Typography>
              {type === 'fileUpload' && files && files?.length > 0 && (
                <div className="view-program-program-details__field__files">
                  {files.map((file) => {
                    return (
                      <FileCard
                        fileName={file?.fileName}
                        fileSize={file?.fileSize}
                        key={file?.key}
                        type='view'
                        onClick={() => {
                          getDocument({ key: file?.key });
                        } } removeFile={undefined}/>
                    );
                  })}
                </div>
              )}
              { type !== 'fileUpload' && type !== 'textEditor' && type !== 'tableColumns' && (
                <Typography
                  className="view-program-program-details__field__value"
                  as="p"
                  variant="caption"
                  fontWeight="regular"
                >
                  {type === 'dateSelection' ? formatDate({ dateString: value }) : value}
                </Typography>
              )}
              {type === 'textEditor' && (
                <div
                  className="view-program-program-details__field__value"
                  dangerouslySetInnerHTML={{ __html: value }}
                />
              )}
              {type === 'tableColumns' && (
                <div>
          <table className="simple-table">
      <thead>
        <tr>
          {headers.map((header:any, index) => (
            <th key={index}>{header.trim()}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableData.map((item:any, rowIndex) => (
          <tr key={rowIndex}>
            {headers.map((header:string, colIndex) => (
              <td key={colIndex}>{item[header]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
          </div>
              )}
              {/* <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                className="program-application-section-view__fields__field__value"
              >
                {value}
              </Typography> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ApplicationSectionView;
