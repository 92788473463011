import {
  TAddMembersRequest,
  TCreateCompanyRequest,
  TDeleteCompanyProfilePictureRequest,
  TDeleteMemberRequest,
  TEditCompanyRequest,
  TEditServiceRequest,
  TGetCompanyPresignedUrlRequest,
  TGetCompanyRequest,
  TGetMembersRequest,
  TGetServicesRequest,
  TSearchUsersRequest,
  TUploadCompanyProfilePictureRequest
} from './apiEndpoint.types.ts';
import { TAddServiceRequest } from './apiEndpoint.types.ts/company/addService.endpoint.types.js';
import { TDeleteServiceRequest } from './apiEndpoint.types.ts/company/deleteService.endpoint.types.js';
import { APIResponse, BaseAPIService } from './base.api.service';

export class CompanyAPIService extends BaseAPIService {
  private createCompanyPath: string = '/company/create';
  private getCompanyPath: string = '/company/get';
  private editCompanyPath: string = '/company/edit';
  private addServicePath: string = '/company/add-service';
  private getServicesPath: string = '/company/get-services';
  private editServicePath: string = '/company/edit-service';
  private deleteServicePath: string = '/company/delete-service';
  private searchUsersPath: string = '/company/search-users';
  private addMembersPath: string = '/company/add-members';
  private getMembersPath: string = '/company/get-members';
  private deleteMemberPath: string = '/company/delete-member';
  private getPresignedUrlPath: string = '/company/get-presigned-url';
  private deleteProfilePicPath: string = '/company/delete-profile-pic';

  public async createCompany<TCreateCommentResponse>(
    createCompanyRequest: TCreateCompanyRequest
  ): Promise<APIResponse<TCreateCommentResponse>> {
    const createCompanyResponse: APIResponse<TCreateCommentResponse> = await this.post(
      this.createCompanyPath,
      createCompanyRequest,
      true
    );
    return createCompanyResponse;
  }

  public async getCompany<TGetCompanyResponse>(
    getCompanyRequest: TGetCompanyRequest
  ): Promise<APIResponse<TGetCompanyResponse>> {
    const getCompanyResponse: APIResponse<TGetCompanyResponse> = await this.get(
      this.getCompanyPath,
      true,
      getCompanyRequest
    );
    return getCompanyResponse;
  }

  public async editCompany<TEditCommentResponse>(
    editCompanyRequest: TEditCompanyRequest
  ): Promise<APIResponse<TEditCommentResponse>> {
    const editCompanyResponse: APIResponse<TEditCommentResponse> = await this.post(
      this.editCompanyPath,
      editCompanyRequest,
      true
    );
    return editCompanyResponse;
  }

  public async addService<TAddServiceResponse>(
    addServiceRequest: TAddServiceRequest
  ): Promise<APIResponse<TAddServiceResponse>> {
    const addServiceResponse: APIResponse<TAddServiceResponse> = await this.post(
      this.addServicePath,
      addServiceRequest,
      true
    );
    return addServiceResponse;
  }

  public async getServices<TGetServicesResponse>(
    getServicesRequest: TGetServicesRequest
  ): Promise<APIResponse<TGetServicesResponse>> {
    const getServicesResponse: APIResponse<TGetServicesResponse> = await this.get(
      this.getServicesPath,
      true,
      getServicesRequest
    );
    return getServicesResponse;
  }

  public async editService<TEditServiceResponse>(
    editServiceRequest: TEditServiceRequest
  ): Promise<APIResponse<TEditServiceResponse>> {
    const editServiceResponse: APIResponse<TEditServiceResponse> = await this.post(
      this.editServicePath,
      editServiceRequest,
      true
    );
    return editServiceResponse;
  }

  public async deleteService<TDeleteServiceResponse>(
    deleteServiceRequest: TDeleteServiceRequest
  ): Promise<APIResponse<TDeleteServiceResponse>> {
    const deleteServiceResponse: APIResponse<TDeleteServiceResponse> = await this.delete(
      this.deleteServicePath,
      true,
      deleteServiceRequest
    );
    return deleteServiceResponse;
  }

  public async searchUsers<TSearchUsersResponse>(
    searchUsersRequest: TSearchUsersRequest
  ): Promise<APIResponse<TSearchUsersResponse>> {
    const searchUsersResponse: APIResponse<TSearchUsersResponse> = await this.get(
      this.searchUsersPath,
      true,
      searchUsersRequest
    );
    return searchUsersResponse;
  }

  public async addMembers<TAddMembersResponse>(
    addMembersRequest: TAddMembersRequest
  ): Promise<APIResponse<TAddMembersResponse>> {
    const addMembersResponse: APIResponse<TAddMembersResponse> = await this.post(
      this.addMembersPath,
      addMembersRequest,
      true
    );
    return addMembersResponse;
  }

  public async getMembers<TGetMembersResponse>(
    getMembersRequest: TGetMembersRequest
  ): Promise<APIResponse<TGetMembersResponse>> {
    const getMembersResponse: APIResponse<TGetMembersResponse> = await this.get(
      this.getMembersPath,
      true,
      getMembersRequest
    );
    return getMembersResponse;
  }

  public async deleteMember<TDeleteMemberResponse>(
    deleteMemberRequest: TDeleteMemberRequest
  ): Promise<APIResponse<TDeleteMemberResponse>> {
    const deleteMemberResponse: APIResponse<TDeleteMemberResponse> = await this.delete(
      this.deleteMemberPath,
      true,
      deleteMemberRequest
    );
    return deleteMemberResponse;
  }

  public async getCompanyPresignedUrl<TGetCompanyPresignedUrlResponse>(
    getCompanyPresignedUrlRequest: TGetCompanyPresignedUrlRequest
  ): Promise<APIResponse<TGetCompanyPresignedUrlResponse>> {
    const getCompanyPresignedUrlResponse: APIResponse<TGetCompanyPresignedUrlResponse> =
      await this.get(this.getPresignedUrlPath, true, getCompanyPresignedUrlRequest);
    return getCompanyPresignedUrlResponse;
  }

  public async uploadCompanyProfilePicture<TUploadCompanyProfilePictureResponse>(
    uploadCompanyProfilePictureRequest: TUploadCompanyProfilePictureRequest
  ): Promise<APIResponse<TUploadCompanyProfilePictureResponse>> {
    const { data, includeAuthorizationHeaders = true } = uploadCompanyProfilePictureRequest;
    const uploadCompanyProfilePictureResponse: APIResponse<TUploadCompanyProfilePictureResponse> =
      await this.put(
        uploadCompanyProfilePictureRequest.presignedUrl,
        data,
        includeAuthorizationHeaders
      );
    return uploadCompanyProfilePictureResponse;
  }

  public async deleteProfilePic<TDeleteCompanyProfilePictureResponse>(
    deleteProfilePicRequest: TDeleteCompanyProfilePictureRequest
  ): Promise<APIResponse<TDeleteCompanyProfilePictureResponse>> {
    const deleteProfilePicResponse: APIResponse<TDeleteCompanyProfilePictureResponse> =
      await this.deleteWithParams(this.deleteProfilePicPath, true, deleteProfilePicRequest);
    return deleteProfilePicResponse;
  }
}
