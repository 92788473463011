import { useEffect } from 'react';
import { InfiniteScroll, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { TListEvaluator, TListEvaluatorsRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { fetchEvaluators, incrementListEvaluatorsPage } from 'src/store/ideaHub/ideaHub.slice';

import './evaluators.scss';

export type TEvaluatorsProps = { ideaId: string; filterString: string };

const Evaluators = ({ ideaId, filterString }: TEvaluatorsProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const {
    listEvaluatorsPageNumber,
    listEvaluatorsItemsPerPage,
    listEvaluatorsMoreItemsLeft,
    searchUserLoading,
    listEvaluators
  } = useAppSelector((store) => store.ideaHub);

  const handleFetchEvaluators = ({ resetUsers }: { resetUsers: boolean }) => {
    const payload: TListEvaluatorsRequest = {
      ideaId,
      limit: listEvaluatorsItemsPerPage,
      offset: resetUsers ? 1 : listEvaluatorsPageNumber,
      ...(filterString && { filterString })
    };

    dispatch(fetchEvaluators(payload));
  };

  const loadMoreItems = () => {
    if (listEvaluatorsMoreItemsLeft && !searchUserLoading) {
      dispatch(incrementListEvaluatorsPage(1));
    }
  };

  useEffect(() => {
    handleFetchEvaluators({ resetUsers: false });
  }, [listEvaluatorsPageNumber]);

  useEffect(() => {
    handleFetchEvaluators({ resetUsers: true });
  }, [filterString]);

  return (
    <InfiniteScroll
      className="manage-access-evaluators"
      onScrollEnd={loadMoreItems}
      loading={searchUserLoading}
      showLoader={false}
      moreItemsLeft={listEvaluatorsMoreItemsLeft}
    >
      {listEvaluators?.length > 0 &&
        listEvaluators.map(
          ({ name = '', role = '', profilePic = '', email = '' }: TListEvaluator, index) => {
            return (
              <div className="selected-collaborator__user-info" key={index}>
                <ProfilePicture
                  url={profilePic}
                  fallback={name[0]}
                  className="selected-collaborator__user-info__profile-pic"
                />
                <div className="selected-collaborator__user-info__user">
                  <div className="selected-collaborator__user-info__user__row">
                    <Typography
                      as="span"
                      variant="body-2"
                      fontWeight="semiBold"
                      className="selected-collaborator__user-info__user__row__name"
                    >
                      {name}
                    </Typography>
                    <hr></hr>
                    <Typography
                      as="p"
                      variant="caption"
                      fontWeight="regular"
                      className="selected-collaborator__user-info__user__row__email"
                    >
                      {role}
                    </Typography>
                  </div>
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    className="selected-collaborator__user-info__user__row__email"
                  >
                    {email}
                  </Typography>
                </div>
              </div>
            );
          }
        )}
    </InfiniteScroll>
  );
};

export default Evaluators;
