import * as Yup from 'yup';

const companyDetailsValidationSchema = Yup.object().shape({
  type: Yup.string().required('Please select your company type.'),
  // foundedYear: Yup.number().max(new Date().getFullYear(), 'Year cannot be in the future')
  foundedYear: Yup.number()
    .required('Founding year is required')
    .test(
      'len',
      'Founding year must be  4 digits',
      (val) => !!val && val.toString().length === 4
    )
    .max(new Date().getFullYear(), 'Year cannot be in the future')
});

export default companyDetailsValidationSchema;
