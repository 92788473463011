import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { ActionIcon, Card, Typography } from 'src/components/common';
import { PlusIcon, SingleChevronIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import { useToggleCreateEditGroupModal } from 'src/components/chat';
import { useGetIdentity } from 'src/hooks';
import { TCreateProgramGroupActionRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { createProgramGroup } from 'src/store/chat/chat.slice';
import { TUserTypes } from 'src/constants/user.constants';
import { TDiscussionTypes } from 'src/components/chat/chat.type';

import { TDiscussionListProps } from './discussionList.types';
import CreateEditGroup from './createEditGroup/CreateEditGroup';
import './discussionList.scss';

import DiscussionGroupItem from '../discussionGroupItem/DiscussionListGroupItem';
import useGetProgramMembers from '../../programMembers/useGetProgramMembers.hook';
import useGetDiscussionGroupMembers from '../useGetDiscussionGroupMembers';

const DiscussionList = ({
  discussionType,
  groupId,
  groups,
  programId,
  companyId
}: TDiscussionListProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen } =
    useToggleCreateEditGroupModal({ mode: 'Add' });
  const { getIdentities } = useGetIdentity();
  useGetProgramMembers({ programId });
  const { programDiscussion } = useAppSelector((store) => store.chat);
  useGetDiscussionGroupMembers({ groupId });

  const [expandGroups, setExpandGroups] = useState<boolean>(true);

  // Constants
  const { loggedInCompanyId, loggedInUserType, authenticatedId } = getIdentities();

  const viewProgramDiscussion = () => {
    const { pathname } = window.location;
    const chatType: TDiscussionTypes = 'programDiscussion';
    const path: string =
      pathname +
      `?id=${programId}&tab=discussion&chatType=${chatType}&groupId=${programDiscussion?.id}`;

    navigate(path);
  };

  const handleCreateGroup = (formValues: { groupName: string; usersToAdd: string[] }) => {
    const usersToAdd: string[] = formValues?.usersToAdd;

    const usersToAddObj: {
      userId: string;
      userType: TUserTypes;
    }[] = usersToAdd.map((userId) => ({ userId, userType: 'User' }));
    // Adding the owner as the first item in the array.
    usersToAddObj.unshift({ userId: loggedInCompanyId, userType: 'Company' });

    // Only program members (userType = 'User') can be added to the group
    const payload: TCreateProgramGroupActionRequest = {
      programId,
      groupName: formValues?.groupName,
      usersToAdd: usersToAddObj,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(createProgramGroup(payload));
  };

  return (
    <Card className="discussion-list">
      <Typography variant="subHeading-2" fontWeight="semiBold">
        Discussion
      </Typography>
      {programDiscussion?.id && (
        <Typography
          as="span"
          variant="body-2"
          fontWeight="semiBold"
          className={classNames('discussion-list__main', {
            'discussion-list__main--is-active': discussionType === 'programDiscussion'
          })}
          onClick={viewProgramDiscussion}
        >
          Program Discussion
        </Typography>
      )}

      <div className="discussion-list__groups">
        <div
          className="discussion-list__groups__header"
          onClick={() => setExpandGroups((prev) => !prev)}
        >
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            My Groups
          </Typography>
          <ActionIcon>
            <SingleChevronIcon direction={expandGroups ? 'up' : 'down'} />
          </ActionIcon>
        </div>
        <div className="discussion-list__groups__body">
          {companyId === loggedInCompanyId && (
            <Button
              variant="secondary"
              size="small"
              startIcon={<PlusIcon />}
              className="discussion-list__groups__body__create-group"
              onClick={toggleCreateEditGroupModal}
            >
              Create Group
            </Button>
          )}
          {expandGroups && (
            <div className="discussion-list__groups__body__list">
              {groups.map((group, index) => (
                <DiscussionGroupItem
                  key={index}
                  group={group}
                  groupId={groupId}
                  discussionType={discussionType}
                  programId={programId}
                />
              ))}
            </div>
          )}
        </div>
      </div>
      {isToggleCreateEditGroupModalOpen && (
        <CreateEditGroup
          mode="Add"
          isModalOpen={isToggleCreateEditGroupModalOpen}
          onModalClose={toggleCreateEditGroupModal}
          onSubmit={handleCreateGroup}
        />
      )}
    </Card>
  );
};

export default DiscussionList;
