import { ReactNode, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './tag.scss';

export type TTagVariants = 'default' | 'hover' | 'active' | 'border';

export type TTagSizes = 'large' | 'medium' | 'small';

export type TTagProps = {
  variant?: TTagVariants; // The variant of the tag.
  size?: TTagSizes; // The size of the tag.
  children?: string | ReactNode; // The content of the tag.
  startIcon?: ReactNode; // The icon element to be positioned at the start of the tag.
  endIcon?: ReactNode; // The icon element to be positioned at the start of the tag.
} & ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Tag component, supports multiple variants and states
 * @param {TTagVariants} [variant] -
 * @param {TTagSizes} [size]
 * @param {string|React.ReactNode} [children]
 * @param {React.ReactNode} [icon]
 * @param {boolean} [startIcon]
 * @param {boolean} [endIcon]
 */
const Tag = ({
  variant = 'active',
  size = 'medium',
  children,
  type = 'button',
  className,
  disabled = false,
  startIcon,
  endIcon,
  ...rest
}: TTagProps) => {
  const tagIcon = (icon: ReactNode) => {
    return (
      <div
        className={classNames(
          'tag-component__icon',
          `tag-component__icon--${variant}`,
          `tag-component__icon--${size}`
        )}
      >
        {icon}
      </div>
    );
  };

  return (
    <button
      {...rest}
      className={classNames(
        className,
        'tag-component',
        `tag-component--${variant}`,
        `tag-component--${size}`
      )}
      type={type}
      disabled={disabled}
    >
      {startIcon && tagIcon(startIcon)}
      {children}
      {endIcon && tagIcon(endIcon)}
    </button>
  );
};

export default Tag;
