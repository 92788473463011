import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Typography } from 'src/components/common';
import { textEllipsis } from 'src/utils';
import { TTextEllipsisResponse } from 'src/utils/textEllipsis/textEllipsis.types';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { TDiscussionTypes } from 'src/components/chat/chat.type';

import { TDiscussionUserItemProps } from './discussionUserItem.types';
import './discussionUserItem.scss';

const DiscussionUserItem = ({
  user,
  ideaId,
  discussionType,
  selectedUserId
}: TDiscussionUserItemProps) => {
  // Hooks
  const navigate = useNavigate();

  const discussionParticipant = user;
  const discussionUser = discussionParticipant?.Users?.[0];

  // Constants
  const isUserActive: boolean =
    discussionUser?.id === selectedUserId && discussionType === 'direct';

  const userNameEllipsis: TTextEllipsisResponse = textEllipsis({
    text: discussionUser?.name,
    charLimit: 15
  });

  const viewIdeaChatMessages = () => {
    const { pathname } = window.location;
    const chatType: TDiscussionTypes = 'direct';
    const path: string =
      pathname +
      `?id=${ideaId}&tab=discussion&chatType=${chatType}&userId=${discussionUser?.id}&userType=User&conversationId=${discussionParticipant?.id}`;

    navigate(path);
  };

  return (
    <div
      className={classNames('discussion-user', {
        'discussion-user--is-active': isUserActive
      })}
    >
      <ProfilePicture url={discussionUser?.profilePic} fallback={discussionUser?.name?.[0]} />
      <Tooltip
        text={discussionUser?.name}
        conditionToDisplay={userNameEllipsis?.hasEllipsis}
        limitWidth
      >
        <Typography
          as="p"
          variant="body-2"
          fontWeight="semiBold"
          className={classNames('discussion-user__user', {
            'discussion-user__user--is-active': isUserActive
          })}
          onClick={viewIdeaChatMessages}
        >
          {userNameEllipsis?.text}
        </Typography>
      </Tooltip>
    </div>
  );
};

export default DiscussionUserItem;
