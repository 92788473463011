import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import Button from 'src/components/button/Button';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TComment, TEditCommentRequest } from 'src/services/apiEndpoint.types.ts';
import { editComment } from 'src/store/socialMedia/socialMedia.slice';
import { TUserTypes } from 'src/constants/user.constants';

import './editComment.scss';
import editCommentValidationSchema from './editComment.validationSchema';

export type TEditComment = {
  onClose: () => void;
  comment: TComment;
  postId: string;
  handleGetComments: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

export type TEditCommentFormValues = {
  text: string;
};

// This component must be aware of the id and type of the viewer
const EditComment = ({
  comment,
  postId,
  onClose,
  handleGetComments,
  viewerId,
  viewerType
}: TEditComment) => {
  const initialValues: TEditCommentFormValues = { text: comment?.comments?.text };

  const dispatch = useAppDispatch();

  const editCommentLoader: boolean = useAppSelector((store) => store.socialMedia.editCommentLoader);

  const commentId = comment?.comments?.id;
  const commentEditorProfilePic =
    viewerType === 'User' ? comment?.user?.profilePic : comment?.user?.logo;
  const commentEditorName = comment?.user?.name;

  const handleSubmit = async (
    { text }: TEditCommentFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // edit comment api call
    const payload: TEditCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      postId,
      text,
      commentId
    };

    dispatch(editComment(payload)).then(() => {
      handleGetComments();
      resetForm();
      onClose();
    });
  };

  useEffect(() => {
    initialValues.text = comment?.comments?.text;
  }, [JSON.stringify(comment)]);

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={editCommentValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form noValidate className="create-comment">
              <div className="create-comment__header">
                <ProfilePicture
                  url={commentEditorProfilePic || ''}
                  fallback={commentEditorName[0]}
                />
                <InputField
                  placeholder="Comment"
                  id="text"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    setFieldValue('text', e.target.value);
                  }}
                  value={values.text}
                  errorMessage={errors.text}
                  isTouched={touched.text}
                />
              </div>
              <div className="create-comment__buttons">
                <Button variant="secondary" size="small" onClick={onClose}>
                  Cancel
                </Button>
                <Button variant="primary" size="small" type="submit" loading={editCommentLoader}>
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EditComment;
