export const updateEducationFormFields = {
  institutionName: '',
  degreeName: '',
  field: '',
  start: '',
  end: '',
  currentlyStudying: false
};

export const updateEducationErrors = {
  institutionName: '',
  degreeName: '',
  field: '',
  start: '',
  end: '',
  currentlyStudying: ''
};
