import React from 'react';
import classNames from 'classnames';
import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

import './arrowIcon.scss';

export type TArrowIcon = {
  direction?: 'up' | 'right' | 'down' | 'left';
} & TSvgIcon;

const ArrowIcon: React.FC<TArrowIcon> = ({
  direction = 'left',
  color = colors.neutral[1100],
  className,
  ...rest
}: TArrowIcon) => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('arrow-icon', `arrow-icon--${direction}`, className)}
      {...rest}
    >
      <path
        d="M13.2188 5.625H1.21875M1.21875 5.625L5.71875 10.125M1.21875 5.625L5.71875 1.125"
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowIcon;
