import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const EyeIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="23"
      height="16"
      viewBox="0 0 23 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.92012 8.71318C1.78394 8.49754 1.71584 8.38972 1.67772 8.22342C1.64909 8.0985 1.64909 7.9015 1.67772 7.77658C1.71584 7.61028 1.78394 7.50246 1.92012 7.28682C3.04553 5.50484 6.3954 1 11.5004 1C16.6054 1 19.9553 5.50484 21.0807 7.28682C21.2169 7.50246 21.285 7.61028 21.3231 7.77658C21.3517 7.9015 21.3517 8.0985 21.3231 8.22342C21.285 8.38972 21.2169 8.49754 21.0807 8.71318C19.9553 10.4952 16.6054 15 11.5004 15C6.3954 15 3.04553 10.4952 1.92012 8.71318Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.5004 11C13.1573 11 14.5004 9.65685 14.5004 8C14.5004 6.34315 13.1573 5 11.5004 5C9.84355 5 8.5004 6.34315 8.5004 8C8.5004 9.65685 9.84355 11 11.5004 11Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EyeIcon;
