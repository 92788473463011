import { useEffect } from 'react';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import { TSearchUsersToAddIdeaGroupRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  resetSearchUsersToAddIdeaGroupSuccess,
  searchUsersToAddIdeaGroup
} from 'src/store/chat/chat.slice';

import { TUseGetDiscussionGroupUsersProps } from './manageIdeaGroupMembers.types';

const useGetDiscussionGroupUsers = ({
  ideaId,
  groupId,
  filterString
}: TUseGetDiscussionGroupUsersProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { searchUsersToAddIdeaGroupPageNumber, searchUsersToAddIdeaGroupItemsPerPage } =
    useAppSelector((store) => store.chat);

  const getUsers = ({ resetUsers }: { resetUsers: boolean }) => {
    if (filterString) {
      const payload: TSearchUsersToAddIdeaGroupRequest = {
        ideaId,
        ideaConversationId: groupId,
        filterString,
        limit: searchUsersToAddIdeaGroupItemsPerPage,
        offset: resetUsers ? 1 : searchUsersToAddIdeaGroupPageNumber
      };

      dispatch(searchUsersToAddIdeaGroup(payload));
    } else {
      dispatch(resetSearchUsersToAddIdeaGroupSuccess());
    }
  };

  useDebounce({
    func: () => getUsers({ resetUsers: true }),
    delay: 500,
    dependency: filterString
  });

  useEffect(() => {
    getUsers({ resetUsers: false });
  }, [searchUsersToAddIdeaGroupPageNumber]);

  return getUsers;
};

export default useGetDiscussionGroupUsers;
