import { Accordion, Col, Dropdown } from 'react-bootstrap';
import { dotMenu, fileIcon } from 'src/assets/images';
import cx from '../resources.module.scss'
import { httpRequest } from 'src/services/commonApis';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import React from 'react';

export default function UploadedResourceBox({ programData, memberData, resourceData, getResources }: any) {
  const dispatch = useDispatch()
  async function handleStatus(resourceId: string, type: string, id?: string, visibility?: boolean) {
    let data: { programId?: string, membersId?: string, isPrivate?: boolean } | any = {};
    if (type !== 'isPrivate') {
      data = {
        [type]: [`${id}`]
      }
    } else {
      data = {
        isPrivate: visibility
      }
    }
    const response = await httpRequest(`resource/share?id=${resourceId}`, 'PATCH', data, 'json', 'program');
    if (response?.message?.includes('successfully')) {
      getResources()
      dispatch(
        openPopup({
          popupMessage: type !== 'isPrivate' ? 'Resource shared successfully' : `Resource is ${visibility ? 'private' : 'public'} now`,
          popupType: 'success'
        })
      );
    } else {
      dispatch(
        openPopup({
          popupMessage: response.message,
          popupType: 'error'
        })
      );
    }
  }

  async function handleDelete(id: string) {
    const response = await httpRequest(`resource?id=${id}`, 'DELETE', null, 'json', 'program');
    if (response?.message?.includes('successfully')) {
      getResources()
      dispatch(
        openPopup({
          popupMessage: 'Resource deleted successfully',
          popupType: 'success'
        })
      );
    } else {
      dispatch(
        openPopup({
          popupMessage: response.message,
          popupType: 'error'
        })
      );
    }
  }

  // const filterProgramData = programData?.filter((program:any) => !resourceData?.programId?.includes(`${program?.id}`))
  // const filterMemberData = memberData?.filter((member:any) => !resourceData?.membersId?.includes(`${member?.id}`))
  return (
    <Col md={3}>
      <div className={`${cx.uploadedBox} ${!resourceData?.type.includes('image') ? cx.document : ''}`}>
      <img src={resourceData?.type.includes('image') ? resourceData?.url : fileIcon} className={cx.icon} alt="upload-resource" />
      {!resourceData?.type.includes('image') && <span>{resourceData?.url?.split('resourses/')?.[1]}</span>}
        <div className={cx.dotDropdown}>
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic-6">
              <img src={dotMenu} alt="dot" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item href="#" onClick={() => handleStatus(resourceData?.id, 'isPrivate', '', !resourceData?.isPrivate)}>
                <p className={cx.dotDropInside}>
                  <span>Make {!resourceData?.isPrivate ? 'private' : 'public'}</span>
                </p>
              </Dropdown.Item> */}
              {programData?.length > 0 && <Dropdown.Item href="#" style={{ padding: '0' }}>
                <Accordion>
                  <Accordion.Header onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                    <p className={cx.dotDropInside}>
                      <span>Share in program</span>
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className={cx.itemList}>
                      {programData?.map((program:any, i: number) => {
                        return (
                          <li className={cx.item} key={i} onClick={() => handleStatus(resourceData?.id, 'programId', program?.id)}><span style={{ display: 'block', whiteSpace: 'normal', wordWrap: 'break-word' }}>{program?.title}</span></li>
                        )
                      })}
                    </ul>
                  </Accordion.Body>
                </Accordion>
              </Dropdown.Item>}
              {/* {memberData?.length > 0 && <Dropdown.Item href="#" style={{ padding: '0' }}>
                <Accordion>
                  <Accordion.Header onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                    <p className={cx.dotDropInside}>
                      <span>Share with members</span>
                    </p>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className={cx.itemList}>
                      {memberData?.map((member: any, i: number) => {
                        return (
                          <li className={cx.item} key={i} onClick={() => handleStatus(resourceData?.id, 'membersId', member?.id)}><span style={{ display: 'block', whiteSpace: 'normal', wordWrap: 'break-word' }}>{member.name}</span></li>
                        )
                      })}
                    </ul>
                  </Accordion.Body>
                </Accordion>
              </Dropdown.Item>} */}
              <Dropdown.Item href="#">
                <p className={cx.dotDropInside} onClick={() => handleDelete(resourceData?.id)}>
                  <span>Delete</span>
                </p>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>
    </Col>
  )
}
