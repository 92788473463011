/* eslint-disable multiline-ternary */
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TGetProgramApplication, TGetProgramData, TGetProgramDocumentRequest } from 'src/services/apiEndpoint.types.ts';
import { formatDate } from 'src/utils';
import { getProgramDocument } from 'src/store/program/program.slice';
import './programDetails.scss';
import FileCard from '../../fileCard/FileCard';

const ProgramDetails = ({ type = '' }: { type?: string }) => {
  // Hooks
  const viewProgram: TGetProgramData = useAppSelector((store) => store.program.viewProgram);
  const dispatch = useAppDispatch();
  const getDocument = ({ key }: { key: string }) => {
    const payload: TGetProgramDocumentRequest = { s3Key: key };

    dispatch(getProgramDocument(payload));
  };

  let programDetailsFields: { label: string; value: string }[] = [
    { label: 'Program Name', value: viewProgram?.title },
    { label: 'Start date', value: formatDate({ dateString: viewProgram?.startDate }) },
    { label: 'End date', value: formatDate({ dateString: viewProgram?.endDate }) }
  ];

  if (type !== 'application' && viewProgram?.banner?.url) {
    programDetailsFields.push({ label: 'Banner', value: viewProgram?.banner?.url })
  } else {
    programDetailsFields = [...programDetailsFields]
  }

  return (
    <div className="view-program-program-details">
      {programDetailsFields.map(({ label, value }, index) => {
        if (label === 'Banner') {
          return (
            <div key={index} className="view-program-program-details__field">
              <Typography
                className="view-program-program-details__field__label"
                as="p"
                variant="body-2"
                fontWeight="semiBold"
              >
                {label}
              </Typography>
              <div style={{ width: '100%', height: '150px', borderRadius: '10px', overflow: 'hidden' }}>
                <img src={value} alt="banner" style={{ height: '100%', width: '100%', backgroundPosition: 'center', objectFit: 'cover' }} />
              </div>
            </div>
          )
        }
        return (
          <div key={index} className="view-program-program-details__field">
            {label && (
              <Typography
                className="view-program-program-details__field__label"
                as="p"
                variant="body-2"
                fontWeight="semiBold"
              >
                {label}
              </Typography>
            )}
            <Typography
              className="view-program-program-details__field__value"
              as="p"
              variant="caption"
              fontWeight="regular"
            >
              {value}
            </Typography>
          </div>
        )
      })}
      {viewProgram?.programDetails.map(({ label, values, type, files }, index) => (
        <div key={index} className="view-program-program-details__field">
          <Typography
            className="view-program-program-details__field__label"
            as="p"
            variant="body-2"
            fontWeight="semiBold"
          >
            {label}
          </Typography>
          {type === 'fileUpload' && files && files?.length > 0 && (
            <div className="view-program-program-details__field__files">
              {files.map((file) => {
                return (
                  <FileCard
                    fileName={file?.fileName}
                    fileSize={file?.fileSize}
                    key={file?.key}
                    onClick={() => {
                      getDocument({ key: file?.key });
                    }} removeFile={undefined} />
                );
              })}
            </div>
          )}
          {type !== 'fileUpload' && (
            <Typography
              className="view-program-program-details__field__value"
              as="p"
              variant="caption"
              fontWeight="regular"
            >
              {type === 'dateSelection' ? (
                formatDate({ dateString: values })
              ) : (
                <div
                  className="view-program-program-details__field__value"
                  dangerouslySetInnerHTML={{ __html: values }}
                />
              )}
            </Typography>
          )}
        </div>
      ))}
    </div>
  );
};

export default ProgramDetails;
