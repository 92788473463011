import React, { useEffect, useState } from 'react'
import { InputField } from 'src/components/common/formFields'
import TextArea from 'src/components/common/formFields/textArea/TextArea'
import { componentSizeVariantsEnum } from 'src/constants';
import ProgressSlider from 'src/components/common/ProgressSlider/ProgressSlider';
import { TrashIcon } from 'src/components/common/common.icons';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import cx from '../evaluation.module.scss';
import { TprogramStageCriteria } from '../evaluation.types';
interface TEvaluationCritiriaForm {
  formObj: TprogramStageCriteria,
  handleCritiriaForm: any,
  index: number,
  handleDeleteForm:(index:number) => void,
  isUpdate: boolean,
  errorTrig: boolean
}

export default function EvaluationCritiriaForm({ formObj, handleCritiriaForm, index, handleDeleteForm, isUpdate, errorTrig }: TEvaluationCritiriaForm) {
  const [ratingIndex, setRatingIndex] = useState<boolean>(false);
  useEffect(() => {
    if (formObj.rating !== '') {
      setRatingIndex(true)
    }
  }, [formObj])
  return (
    <div className={`${cx.evaluationForm}`}>
      {index !== 0 && !isUpdate && <div className={cx.deleteButton}>
        <TrashIcon onClick={() => handleDeleteForm(index)} />
      </div>}
      <div className={`${cx.critiriaInputs}`}>
        <InputField
          className={cx.critriaInput}
          label="Criteria Name"
          id="criteriaName"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Criteria Name"
          type="text"
          value={formObj?.criteriaName}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleCritiriaForm(e, index)
          }}
          required
          errorMessage={''}
          disabled={isUpdate}
        />
      </div>
      {errorTrig && formObj?.criteriaName === '' && <ErrorMessage className='mt-0' message={'please enter criteria name'}/>}
      <div className={cx.ratingContainer}>
        <div className={`${cx.ratingBox}`}>
          <div className={cx.headingContainer}>
            <input
              type="checkbox"
              className={cx.checkBox}
              checked={ratingIndex}
              disabled={isUpdate}
              onChange={(e) => {
                setRatingIndex(e.target.checked);
                handleCritiriaForm({ target: { id: 'rating', value: '' } }, index)
              }}
            />
            <label className="form_label" htmlFor="ratingIndex">Rating Index</label>
          </div>
          <div className={cx.ratingCritiria} id='ratingIndex'>
            <span className="form_label">From</span> <input type="number" disabled placeholder='0' value={0} className={`${cx.formInput}`}
              onChange={() => { }} />
            <span className="form_label">To</span>
            <input type="number" placeholder='10' disabled={!ratingIndex || isUpdate} id="rating" min={0} value={formObj.rating} onChange={(e) => handleCritiriaForm(e, index)}
              className={`${cx.formInput}`} />
          </div>
          <div className={`${cx.progressContainer}`}>
            <ProgressSlider key={index} min={0} disabled={true} max={formObj?.rating ? parseInt(formObj?.rating) : 10} label="Example: " />
          </div>
        </div>
      </div>

      <div className={`${cx.commentBox}`}>
        <div className={cx.headingContainer}>
          <input
            type="checkbox"
            className={cx.checkBox}
            id='comments'
            checked={formObj.comments}
            onChange={(e) => handleCritiriaForm(e, index)}
            disabled={isUpdate}
          />
          <label className="form_label" htmlFor="ratingIndex">Comment Box</label>
        </div>
        <div className={cx.textAreaContainer}>
          <TextArea placeholder='This will be a represtation where you can provide additional comments.' disabled={true} />
        </div>
      </div>
      {/* <div className={`${cx.commentBox}`}>
        <div className={cx.headingContainer}>
          <input
            type="checkbox"
            disabled={isUpdate}
            className={cx.checkBox}
            id='overallComment'
            checked={formObj.overallComment}
            onChange={(e) => handleCritiriaForm(e, index)}
          />
          <label className="form_label" htmlFor="ratingIndex">Overall Comment Box</label>
        </div>
        <div className={cx.textAreaContainer}>
          <TextArea placeholder='This will be a represtation where you can provide additional comments.' disabled={true} />
        </div>
      </div> */}
    </div>
  )
}
