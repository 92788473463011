import React, { LabelHTMLAttributes } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';

import './label.scss';

import Typography from '../../typography/Typography';
import { TypographySize, TypographyVariant } from '../../typography/typography.types';

type TLabelProps = {
  children: string | React.ReactNode;
  variant?: componentSizeVariantsEnum;
  required?: boolean;
} & LabelHTMLAttributes<HTMLLabelElement>;

/**
 * Props for the Label component.
 * @typedef {Object} TLabelProps
 * @param {string|React.ReactNode} children - The content of the label.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the label.
 * @param {LabelHTMLAttributes<HTMLLabelElement>} [HTMLLabelElement] - HTML attributes of the label element.
 */
const Label = ({
  children,
  className,
  variant = componentSizeVariantsEnum.MEDIUM,
  required = false,
  ...rest
}: TLabelProps) => {
  const typographyVariant: Record<componentSizeVariantsEnum, TypographyVariant> = {
    [componentSizeVariantsEnum.SMALL]: 'caption',
    [componentSizeVariantsEnum.MEDIUM]: 'body-2',
    [componentSizeVariantsEnum.LARGE]: 'subHeading-2'
  };

  const typographySize: Record<componentSizeVariantsEnum, TypographySize> = {
    [componentSizeVariantsEnum.SMALL]: 'semiBold',
    [componentSizeVariantsEnum.MEDIUM]: 'semiBold',
    [componentSizeVariantsEnum.LARGE]: 'semiBold'
  };

  return (
    <Typography
      {...rest}
      variant={typographyVariant[variant]}
      fontWeight={typographySize[variant]}
      as="label"
      className={classNames(className, 'label-component', { 'label-mandatory-field': required })}
    >
      {children}
    </Typography>
  );
};

export default Label;
