import { textFormatter } from 'src/utils';

import './companyInfo.scss';
import LabelValue from '../../labelValue/LabelValue';

export type TCompanyInfo = {
  about: string;
  foundedYear: number;
  industry: string;
  type: string;
};

const CompanyInfo = ({ about, foundedYear, industry, type }: TCompanyInfo) => {
  const aboutCompanyFields: { className: string; label: string; value: string | number }[] = [
    {
      className: 'company-info__about',
      label: 'About Company',
      value: textFormatter({ value: about })
    },
    {
      className: 'company-info__founded',
      label: 'Founded Year',
      value: textFormatter({ value: foundedYear > 0 ? foundedYear : '-' })
    }
  ];

  if (type !== 'Incubator' && type !== 'Accelerator') {
    aboutCompanyFields.push({
      className: 'company-info__industry',
      label: 'Industry',
      value: textFormatter({ value: industry })
    });
  }

  return (
    <div className="company-info">
      {aboutCompanyFields.map(({ label, value, className }, index) => {
        return <LabelValue key={index} label={label} value={value} className={className} />;
      })}
    </div>
  );
};

export default CompanyInfo;
