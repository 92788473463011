import { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { resetRedirection } from 'src/store/auth/auth.slice';
import Navigation, { TNavigation } from 'src/components/navigation/Navigation';
import './protectedLayout.scss';
import { useGetIdentity } from 'src/hooks';
import useSideNavigationItems from 'src/components/navigation/useSideNavigationItems';

const ProtectedLayout: FC = () => {
  // Hooks
  const { shouldRedirectToLogin, shouldRedirectToAccessDenied } = useAppSelector(
    (store) => store.auth
  );
  const { ideaHubNotificationCount, totalUnreadConversationCount } = useAppSelector(
    (store) => store.notifications
  );
  const { isAuthorized } = useAppSelector((store) => store.auth);
  const {
    id: userId,
    role: userRole,
    onboardingComplete
  } = useAppSelector((store) => store.profile.profile);
  const sideNavItems = useSideNavigationItems();

  const [items, setItems] = useState<Array<TNavigation>>(sideNavItems);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { getIdentities } = useGetIdentity();

  const redirectIfRoleIsNotCreated = () => {
    const { loggedInUserType } = getIdentities();
    const isUser = loggedInUserType === 'User';
    if (isUser && userId && !onboardingComplete) {
      navigate('/onboarding-user-type');
    }
  };

  useEffect(() => {
    const arr = sideNavItems.map((item) => {
      item.state = item.state === 'disabled' ? 'disabled' : 'default';
      if (location.pathname.includes(item.url)) item.state = 'active';
      return item;
    });
    setItems(arr);
  }, [location, ideaHubNotificationCount, totalUnreadConversationCount]);

  useEffect(() => {
    if (shouldRedirectToLogin) {
      navigate('/login');
    }
    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToLogin]);

  useEffect(() => {
    if (shouldRedirectToAccessDenied) {
      navigate('/access-denied');
    }
    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToAccessDenied]);

  useEffect(() => {
    if (userRole) {
      redirectIfRoleIsNotCreated();
    }
  }, [userRole, onboardingComplete]);
  return (
    <div className="protected-layout">
      {isAuthorized && <Navigation items={items} />}
      <div className="protected-layout__right">
        <Outlet />
      </div>
    </div>
  );
};

export default ProtectedLayout;
