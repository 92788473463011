import { useNavigate } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import { Button, Typography } from 'src/components/common';

import './nonRegisteredUsers.scss';

const NonRegisteredUsers = () => {
  // Hooks
  const navigate = useNavigate();

  const navigateToLogin = () => {
    return navigate('/login');
  };

  const navigateToSignUp = () => {
    return navigate('/sign-up');
  };

  return (
    <div className="no-registered-users">
      <div className="no-registered-users__body">
        <img src={hTractionLogoWithText} alt="htraction logo" />
        <Typography as="p" className="no-registered-users__body__title">
          To see this page, just log in or sign up!
        </Typography>
        <Button variant="primary" size="large" onClick={navigateToLogin}>
          Login
        </Button>
        <div className="no-registered-users__body__footer">
          {' '}
          <Typography as="span" variant="body-2" fontWeight="medium">
            Don’t have an account?
          </Typography>
          <Typography
            as="span"
            className="no-registered-users__body__footer__sign-up"
            onClick={navigateToSignUp}
          >
            Sign Up
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default NonRegisteredUsers;
