import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import './phoneNumberBox.scss';
// type TPhoneNumberBoxProps = {
//   className?: string
//   value?: any; // Ensure value is a string
//   disabled?: boolean;
//   variant?: componentSizeVariantsEnum;
//   createProgramStep?: any;
//   inputRef?: React.Ref<HTMLInputElement>;
//   onChange?: (value?: string) => void; // Adjust the type of onChange
// } & InputHTMLAttributes<HTMLInputElement>;
const PhoneNumberBox = forwardRef<any, any>(({
  className,
  value,
  disabled,
  variant = componentSizeVariantsEnum.MEDIUM,
  createProgramStep,
  inputRef,
  ...rest
}, ref) => {
  const handleChange = (value: string) => {
    if (rest.onChange) {
      console.log(value, 'valueeiiiieeeeee')
      rest.onChange({
        ...event,
        target: {
          ...event?.target,
          value: value
        }
      } as any);
    }
  };

  return (
    <PhoneInput
      {...rest}
      inputClass={classNames(
        className,
        'phone-number-box',
        `phone-number-box--${variant}`
      )}
      value={value}
      disabled={disabled}
      onChange={handleChange} // Use the local handleChange
      inputProps={{
        ref: ref || inputRef
      }}
    />
  );
});

// Adding displayName for debugging purposes
PhoneNumberBox.displayName = 'PhoneNumberBox';

export default PhoneNumberBox;
