import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Typography from 'src/components/common/typography/Typography';
import Like from 'src/components/common/like/Like';
import {
  Separator,
  TDropdownOptions,
  TDropdown,
  SubMenuModal,
  Dropdown
} from 'src/components/common';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import {
  TDeleteReplyToCommentRequest,
  TLikeReplyRequest,
  TReply,
  TUnLikeReplyRequest
} from 'src/services/apiEndpoint.types.ts';
import { textFormatter, transformTextToHyperlink } from 'src/utils';
import {
  deleteReplyToComment,
  likeReply,
  unLikeReply
} from 'src/store/socialMedia/socialMedia.slice';
import { TUserTypes } from 'src/constants/user.constants';

import './viewReply.scss';

import EditReply from '../editReply/EditReply';

export type TViewReply = {
  postId: string;
  reply: TReply;
  commentId: string;
  handleGetReplies: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

// This component must know id and type of the viewer
const ViewReply = ({
  reply,
  commentId,
  handleGetReplies,
  viewerId,
  viewerType,
  postId
}: TViewReply) => {
  const replyTextRef = useRef<HTMLElement>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const dropdownId: string = useAppSelector<string>((store) => store.common.dropdownId);

  const replyId = reply?.replies?.id;
  const replyCreatorType = reply?.userType[0] as TUserTypes;
  const replyCreatorId = reply?.user?.id;
  const isViewerCreatorOfReply = viewerId === reply?.user?.id;
  const replyCreatorProfilePic: string =
    replyCreatorType === 'User' ? reply?.user?.profilePic : reply?.user?.logo;
  const replyCreatorName: string = reply?.user?.name;

  const viewUserProfile = () => {
    const prevRoute: string = `${window.location.pathname}?postId=${postId}`;
    if (isViewerCreatorOfReply) {
      navigate(
        `/my-${replyCreatorType === 'User' ? `profile?prev=${prevRoute}` : `company?prev=${prevRoute}`}`
      );
    } else {
      navigate(
        `/view-${replyCreatorType === 'User' ? 'profile' : 'company'}?id=${replyCreatorId}&prev=${prevRoute}`
      );
    }
  };

  const viewCommentDropdownItems: TDropdownOptions[] = [
    { label: <>View user&apos;s profile</>, onClick: viewUserProfile }
  ];

  const dropdownOptions: TDropdown = {
    id: `post-reply-dropdown-${replyId}`,
    size: 'large',
    alignment: 'center',
    items: viewCommentDropdownItems
  };

  const postMoreOptions = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  const handleLikeReply = () => {
    const payload: TLikeReplyRequest = {
      userId: viewerId,
      userType: viewerType,
      replyId,
      commentId
    };

    dispatch(likeReply(payload));
  };

  const handleUnLikeReply = () => {
    const payload: TUnLikeReplyRequest = {
      userId: viewerId,
      userType: viewerType,
      replyId,
      commentId
    };

    dispatch(unLikeReply(payload));
  };

  const handleDeleteReply = () => {
    const payload: TDeleteReplyToCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      commentId,
      replyId
    };

    dispatch(deleteReplyToComment(payload));
  };

  if (isViewerCreatorOfReply) {
    // Enable edit of reply, if the viewer is creator of this reply
    viewCommentDropdownItems.push(
      {
        label: <>Edit</>,
        onClick: () => {
          setEditMode(true);
        }
      },
      { label: <>Delete</>, onClick: handleDeleteReply }
    );
  }

  useEffect(() => {
    const ele: HTMLElement | null = replyTextRef.current;
    if (ele) {
      const text = ele.innerText;
      const replacedText = transformTextToHyperlink({ text });
      ele.innerHTML = replacedText;
    }
  }, [replyTextRef.current]);

  if (editMode) {
    return (
      <EditReply
        onClose={() => setEditMode(false)}
        reply={reply}
        commentId={commentId}
        handleGetReplies={handleGetReplies}
        viewerId={viewerId}
        viewerType={viewerType}
      />
    );
  }

  return (
    <>
      <div className="view-reply">
        <div className="view-reply__header">
          <div className="view-reply__header__user-info">
            <ProfilePicture url={replyCreatorProfilePic || ''} fallback={replyCreatorName[0]} />
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              className="view-reply__header__user-info__name"
            >
              {textFormatter({ value: replyCreatorName })}
            </Typography>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="view-reply__header__user-info__role"
            >
              {textFormatter({ value: reply?.user?.role })}
            </Typography>
          </div>
          <div
            className="view-reply__header__dropdown"
            onClick={(e) => {
              e.stopPropagation();
              postMoreOptions();
            }}
          >
            {<MoreOptionsIcon />}{' '}
            <SubMenuModal>
              <Dropdown {...dropdownOptions} />
            </SubMenuModal>
          </div>
        </div>
        <Typography
          as="span"
          variant="body-2"
          fontWeight="regular"
          className="view-reply__content"
          typographyRef={replyTextRef}
        >
          {textFormatter({ value: reply?.replies?.text })}
        </Typography>
        <div className="view-reply__interactions">
          <Like
            count={reply?.replies?.likeCount}
            onLike={handleLikeReply}
            onUnLike={handleUnLikeReply}
            className="view-reply__interactions__like"
            active={reply?.replies?.likedByUser}
          />
        </div>
      </div>
      <Separator />
    </>
  );
};

export default ViewReply;
