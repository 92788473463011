import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InviteUser from 'src/components/header/inviteUser/InviteUser';
import { assignModalId } from 'src/store/common/common.slice';

import { ActionCard } from '../actionCard';

export const inviteUserModalId = 'inviteUserModal';

const InviteNetwork = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { modalId } = useAppSelector((store) => store.common);

  // Constants
  const openInviteUserModal = modalId === inviteUserModalId;

  const handleOpenInviteUserModal = () => {
    dispatch(assignModalId(inviteUserModalId));
  };

  const handleCloseInviteUserModal = () => {
    dispatch(assignModalId(''));
  };

  return (
    <>
      <ActionCard
        className="invite-network-component"
        title="Invite your network!"
        description="Invite your network to join htraction and experience the collaborative power of our platform."
        buttonText="Send Invites"
        onClick={handleOpenInviteUserModal}
      />
      {openInviteUserModal && (
        <InviteUser isModalOpen={openInviteUserModal} closeModal={handleCloseInviteUserModal} />
      )}
    </>
  );
};

export default InviteNetwork;
