import { comingSoon } from 'src/assets/images';
import { Card, Typography } from 'src/components/common';

import './startupNewsResources.scss';

const StartupNewsResources = () => {
  return (
    <Card className="startup-news-resources">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        Startup News and Resources
      </Typography>
      <img src={comingSoon} alt="coming soon" />
    </Card>
  );
};

export default StartupNewsResources;
