import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const RadioIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.75 9.19043C1.75 5.46251 4.77208 2.44043 8.5 2.44043C12.2279 2.44043 15.25 5.46251 15.25 9.19043C15.25 12.9184 12.2279 15.9404 8.5 15.9404C4.77208 15.9404 1.75 12.9184 1.75 9.19043ZM8.5 0.94043C3.94365 0.94043 0.25 4.63408 0.25 9.19043C0.25 13.7468 3.94365 17.4404 8.5 17.4404C13.0563 17.4404 16.75 13.7468 16.75 9.19043C16.75 4.63408 13.0563 0.94043 8.5 0.94043ZM8.49982 13.1875C10.709 13.1875 12.4998 11.3966 12.4998 9.1875C12.4998 6.97836 10.709 5.1875 8.49982 5.1875C6.29068 5.1875 4.49982 6.97836 4.49982 9.1875C4.49982 11.3966 6.29068 13.1875 8.49982 13.1875Z"
        fill={color}
      />
    </svg>
  );
};

export default RadioIcon;
