import { Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';

import './searchProgramMemberItem.scss';
import { TSearchProgramMemberItemProps } from './searchProgramMemberItem.types';

const SearchProgramMemberItem = ({
  userId,
  profilePic,
  name = '',
  role = '',
  isUserSelected,
  updateSelectedUsers
}: TSearchProgramMemberItemProps) => {
  return (
    <div className="search-program-member-item">
      <div className="search-program-member-item__user-info">
        <input
          type="checkbox"
          checked={isUserSelected}
          onChange={() => updateSelectedUsers({ userId })}
        />
        <ProfilePicture
          url={profilePic}
          fallback={name[0]}
          className="search-program-member-item__user-info__profile-pic"
        />
        <div className="search-program-member-item__user-info__user">
          <div className="search-program-member-item__user-info__user__row">
            <Typography
              as="span"
              variant="body-2"
              fontWeight="semiBold"
              className="search-program-member-item__user-info__user__row__name"
            >
              {name}
            </Typography>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="search-program-member-item__user-info__user__row__role"
            >
              {role}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchProgramMemberItem;
