import React from 'react';
import { useNavigate } from 'react-router-dom';

import './backNavigation.scss';

import ArrowIcon from '../common.icons/ArrowIcon';
import Typography from '../typography/Typography';

export type TBackNavigation = {
  text: string;
  route?: string;
  onClick?: () => void;
};

const BackNavigation: React.FC<TBackNavigation> = ({ text, route, onClick }: TBackNavigation) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (onClick) {
      onClick();
    } else if (route) {
      navigate(route);
    }
  };
  return (
    <div className="back-navigation-component" onClick={handleClick}>
      <ArrowIcon />
      <Typography variant="body-2" fontWeight="medium">
        {text}
      </Typography>
    </div>
  );
};

export default BackNavigation;
