import { useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';

import { TUseToggleCreateEditGroupModal } from './createEditGroup.types';

const useToggleCreateEditGroupModal = ({ mode }: TUseToggleCreateEditGroupModal) => {
  // Constants
  const createEditDiscussionGroupModalId = `createEditDiscussionGroupModal-${mode}`;

  // Hooks
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);

  // Constants
  const isToggleCreateEditGroupModalOpen = modalId === createEditDiscussionGroupModalId;

  const toggleCreateEditGroupModal = () => {
    toggleOpenModal({ modalId: createEditDiscussionGroupModalId });
  };

  return { toggleCreateEditGroupModal, isToggleCreateEditGroupModalOpen };
};

export default useToggleCreateEditGroupModal;
