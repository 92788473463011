import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const FileIcon = ({ color = colors.primary[100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="22"
      height="26"
      viewBox="0 0 22 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.3346 1.71289V6.53186C13.3346 7.18525 13.3346 7.51195 13.4618 7.76151C13.5736 7.98103 13.7521 8.15951 13.9716 8.27136C14.2212 8.39852 14.5479 8.39852 15.2013 8.39852H20.0203M7.5013 17.7318L9.83464 20.0651L15.0846 14.8151M13.3346 1.39844H7.26797C5.30779 1.39844 4.32769 1.39844 3.579 1.77991C2.92043 2.11547 2.385 2.6509 2.04945 3.30947C1.66797 4.05816 1.66797 5.03825 1.66797 6.99844V19.1318C1.66797 21.092 1.66797 22.072 2.04945 22.8207C2.385 23.4793 2.92043 24.0147 3.579 24.3503C4.32769 24.7318 5.30779 24.7318 7.26797 24.7318H14.7346C16.6948 24.7318 17.6749 24.7318 18.4236 24.3503C19.0822 24.0147 19.6176 23.4793 19.9532 22.8207C20.3346 22.072 20.3346 21.092 20.3346 19.1318V8.39844L13.3346 1.39844Z"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileIcon;
