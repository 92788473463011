import { ReactNode, HTMLAttributes } from 'react';
import classNames from 'classnames';

import TabHeader from './TabHeader';
import './tabs.scss';

export type TTabItem = {
  key: string;
  text: string | ReactNode;
  disabled?: boolean;
  component?: ReactNode;
};

export type TTabs = {
  items: TTabItem[];
  onTabChange: (value: string) => void;
  activeItem: string;
  disabled?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Tabs = ({ items, onTabChange, activeItem, className, ...rest }: TTabs) => {
  const componentToRender = items.find((item) => item.key === activeItem);
  return (
    <div className={classNames('tabs-component', className)} {...rest}>
      <div className="tabs-component__tabs">
        {items?.map(({ key, text, disabled = false }) => {
          return (
            <TabHeader
              key={key}
              tabId={key}
              activeItem={activeItem}
              disabled={disabled}
              onTabChange={onTabChange}
            >
              {text}
            </TabHeader>
          )
        })}
      </div>
      {componentToRender?.component}
    </div>
  );
};

export default Tabs;
