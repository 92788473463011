import { useContext, useState } from 'react';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';

import InstituteCard, { TInstituteCard } from './instituteCard/InstituteCard';
import UpdateEducation from './updateEducation/UpdateEducation';
import './education.scss';

export const updateEducationModalId = 'update-education-modal';

export type TWorkExperience = {
  education: TInstituteCard[];
};

const Education = ({ education = [] }: TWorkExperience) => {
  const [updateEducationMode, setUpdateEducationMode] = useState<'Add' | 'Update'>('Add');
  const [educationToUpdate, setEducationToUpdate] = useState<number>(0);

  // Hooks
  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.
  const dispatch = useAppDispatch();
  const modalId = useAppSelector((store) => store.common.modalId);

  // Constants
  const isUpdateEducationModalOpen = modalId === updateEducationModalId;

  return (
    <div className="education">
      <div className="education__header">
        <Typography
          as="h5"
          variant="subHeading-2"
          fontWeight="semiBold"
          className="education__header__title"
        >
          Education
        </Typography>
        {isSelfUser && (
          <Button
            variant="secondary"
            size="extraSmall"
            className="education__header__action"
            onClick={() => {
              setUpdateEducationMode('Add');
              dispatch(assignModalId(updateEducationModalId));
            }}
          >
            Add Education
          </Button>
        )}
      </div>
      {/*
       ** Institute cards
       */}
      <div className="education__institutes">
        {education.map(
          (
            { institutionName, degreeName, start, end, field, currentlyStudying }: TInstituteCard,
            index
          ) => {
            return (
              <InstituteCard
                key={index}
                institutionName={institutionName}
                degreeName={degreeName}
                start={start}
                end={end}
                field={field}
                currentlyStudying={currentlyStudying}
                instituteIndex={index}
                educationToUpdate={educationToUpdate}
                setInstituteToUpdate={setEducationToUpdate}
                setUpdateInstituteMode={setUpdateEducationMode}
              />
            );
          }
        )}
      </div>

      {/*
       ** Add or update Education
       */}
      {isSelfUser && isUpdateEducationModalOpen && (
        <UpdateEducation
          isModalOpen={isUpdateEducationModalOpen}
          mode={updateEducationMode}
          educationToUpdate={educationToUpdate}
          education={education}
        />
      )}
    </div>
  );
};

export default Education;
