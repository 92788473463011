import { useToggleOpenModal } from 'src/hooks';
import { useAppSelector } from 'src/store/hooks';

const useToggleOpenDiscussionMembersModal = () => {
  // Constants
  const viewDiscussionMembersModalId = 'viewDiscussionMembersModal';

  // Hooks
  const toggleOpenModal = useToggleOpenModal();
  const modalId: string = useAppSelector((store) => store.common.modalId);

  // Constants
  const isDiscussionMembersModalOpen = modalId === viewDiscussionMembersModalId;

  const toggleOpenOpenDiscussionMembersModal = () => {
    toggleOpenModal({ modalId: viewDiscussionMembersModalId });
  };

  return { toggleOpenOpenDiscussionMembersModal, isDiscussionMembersModalOpen };
};

export default useToggleOpenDiscussionMembersModal;
