export interface IEnumToJson {
  enumData: Record<string, string>;
}

const enumToJson = ({ enumData }: IEnumToJson) => {
  return Object.keys(enumData).map((item: string) => {
    return {
      id: item,
      label: enumData[item],
      value: enumData[item]
    };
  });
};

export default enumToJson;
