import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const CrossIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="9"
      height="9"
      viewBox="0 0 9 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M7.58464 1.55859L0.917969 8.22526M0.917969 1.55859L7.58464 8.22526"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CrossIcon;
