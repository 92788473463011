import { useState } from 'react';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { InfiniteScroll, Modal, SubMenuModal, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import Button from 'src/components/button/Button';
import { SearchIcon } from 'src/components/common/common.icons';
import { useToggleOpenDropdown } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useGetDiscussionGroupUsers from 'src/components/chat/manageIdeaGroupMembers/useGetDiscussionGroupUsers.hook';
import {
  TEditIdeaGroupActionRequest,
  TGetIdeaGroupParticipantsRequest,
  TSearchUsersToAddIdeaGroupUser,
  TUser
} from 'src/services/apiEndpoint.types.ts';
import UserInfo from 'src/components/company/programs/program/discussion/userInfo/UserInfo';
import {
  editIdeaGroup,
  getIdeaGroupParticipants,
  incrementSearchUsersToAddIdeaGroupList
} from 'src/store/chat/chat.slice';

import { TEditIdeaGroupProps, TCreateGroupInitialValues } from './editIdeaGroup.types';
import createEditGroupValidationSchema from './editIdeaGroup.validationSchema';
import './editIdeaGroup.scss';

import SearchMemberItem from '../searchMemberItem/SearchMembersItem';

const createEditIdeaGroupSearchUsersId = 'createEditIdeaGroupSearchUsers';

const EditIdeaGroup = ({
  isModalOpen,
  onModalClose,
  mode,
  groupMembers,
  ideaId,
  groupId,
  groupName,
  userId,
  userType
}: TEditIdeaGroupProps) => {
  // Hooks
  const toggleSearchUsersDropdown = useToggleOpenDropdown();
  const { dropdownId, loading, itemListLoader } = useAppSelector((store) => store.common);
  const { searchUsersToAddIdeaGroupList, searchUsersToAddIdeaGroupMoreItemsLeft } = useAppSelector(
    (store) => store.chat
  );
  const dispatch = useAppDispatch();

  // State
  const [filterString, setFilterString] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = useState<TUser[]>(groupMembers?.Users?.slice(1));

  // Constants
  const openSearchUsersResultDropdownId = dropdownId === createEditIdeaGroupSearchUsersId;

  const toggleOpenSearchUsersDropdown = () => {
    toggleSearchUsersDropdown({ dropdownId: createEditIdeaGroupSearchUsersId });
  };

  const initialValues: TCreateGroupInitialValues = {
    text: '',
    groupName
  };

  const loadMoreItems = () => {
    if (searchUsersToAddIdeaGroupMoreItemsLeft && !itemListLoader) {
      dispatch(incrementSearchUsersToAddIdeaGroupList(1));
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedMembers((prev) => {
      const currentList: TUser[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex((member: TUser) => member?.id === userId);

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectedUser = searchUsersToAddIdeaGroupList?.[0]?.collaborators?.find(
          (member: TUser) => member?.id === userId
        );
        if (selectedUser) {
          currentList.push(selectedUser);
        }
      }

      return currentList;
    });
  };

  const getIdeaGroups = () => {
    const payload: TGetIdeaGroupParticipantsRequest = {
      ideaConversationId: groupId,
      limit: 1000,
      offset: 1
    };

    dispatch(getIdeaGroupParticipants(payload));
  };

  const handleSubmit = (values: TCreateGroupInitialValues) => {
    const selectedMemberIds = selectedMembers.map((item) => item?.id);
    const currentGroupUsersIds = groupMembers?.Users?.map((item) => item?.id);

    const usersToAdd = selectedMemberIds.filter((userId) => !currentGroupUsersIds.includes(userId));
    const usersToRemove = currentGroupUsersIds.filter(
      (userId) => !selectedMemberIds.includes(userId)
    );

    const ownerId = currentGroupUsersIds?.[0];

    const ownerInRemoveArrayIndex = usersToRemove.findIndex((rId) => rId === ownerId);

    if (ownerInRemoveArrayIndex > -1) {
      // Owner is in the index 0, they should not be removed
      usersToRemove.splice(ownerInRemoveArrayIndex, 1);
    }

    const payload: TEditIdeaGroupActionRequest = {
      ideaId,
      userId,
      userType,
      ideaConversationId: groupId,
      usersToAdd: usersToAdd.map((userId) => ({ userId, userType: 'User' })),
      usersToRemove: usersToRemove.map((userId) => ({ userId, userType: 'User' })),
      groupName: values?.groupName
    };

    dispatch(editIdeaGroup(payload)).then(() => {
      getIdeaGroups();
    });
  };

  const handleClose = () => {
    setFilterString('');
    setSelectedMembers([]);
    onModalClose();
  };

  // Fetch users to be add to group
  useGetDiscussionGroupUsers({ ideaId, groupId, filterString });

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleClose}
      title={`${mode} Group`}
      className="ihd-create-edit-group"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={createEditGroupValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ setFieldValue }) => {
          return (
            <Form className="ihd-create-edit-group__form">
              <InputField
                label="Enter Group Name"
                id="groupName"
                placeholder="Group Name"
                startIcon={<SearchIcon />}
                variant={componentSizeVariantsEnum.SMALL}
              />
              <div className="ihd-create-edit-group__form__search-users">
                <InputField
                  label="Select and search users to add in group"
                  id="text"
                  placeholder="Search user by name, email address"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    const value = e.target.value;

                    setFieldValue('text', value);
                    setFilterString(value);
                  }}
                  startIcon={<SearchIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOpenSearchUsersDropdown();
                  }}
                />
                {openSearchUsersResultDropdownId && (
                  <SubMenuModal>
                    <div
                      className="ihd-create-edit-group__form__search-users__container"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography as="p" variant="caption" fontWeight="semiBold">
                        Search for “{filterString}”
                      </Typography>
                      <InfiniteScroll
                        className="ihd-create-edit-group__form__search-users__container__result"
                        loading={itemListLoader}
                        showLoader={false}
                        moreItemsLeft={searchUsersToAddIdeaGroupMoreItemsLeft}
                        onScrollEnd={loadMoreItems}
                      >
                        {searchUsersToAddIdeaGroupList?.length > 0 &&
                          searchUsersToAddIdeaGroupList.map(
                            (searchUser: TSearchUsersToAddIdeaGroupUser, index) => {
                              const { collaborators } = searchUser;
                              const { id, name, role, profilePic } = collaborators?.[0];
                              const selectIndex = selectedMembers.findIndex(
                                (user) => user?.id === id
                              );
                              return (
                                <SearchMemberItem
                                  key={index}
                                  userId={id}
                                  profilePic={profilePic}
                                  name={name}
                                  role={role}
                                  updateSelectedUsers={updateSelectedUsers}
                                  isUserSelected={selectIndex > -1}
                                />
                              );
                            }
                          )}
                      </InfiniteScroll>
                    </div>
                  </SubMenuModal>
                )}
              </div>

              {mode === 'Edit' && (
                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="regular"
                  style={{ color: colors?.neutral?.[1100] }}
                >
                  You can manage the group member adding or removing the user.
                </Typography>
              )}
              <div
                className={classNames(
                  'ihd-create-edit-group__form__users',
                  `ihd-create-edit-group__form__users--${mode}`
                )}
              >
                {selectedMembers.map((member, index) => {
                  return (
                    <UserInfo
                      key={index}
                      {...member}
                      userId={member?.id}
                      showRemoveButton
                      onRemove={() => updateSelectedUsers({ userId: member?.id })}
                    />
                  );
                })}
              </div>
              <div className="ihd-create-edit-group__form__buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleClose}
                  className="ihd-create-edit-group__form__buttons__close"
                  type="button"
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  className="ihd-create-edit-group__form__buttons__create-group"
                  loading={loading}
                >
                  {mode === 'Add' ? 'Create Group' : 'Save Changes'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default EditIdeaGroup;
