/* eslint-disable no-unused-vars */

export enum componentSizeVariantsEnum {
  LARGE = 'large',
  MEDIUM = 'medium',
  SMALL = 'small'
}

export enum TimeFrame {
  LAST_24_HRS = '24H',
  LAST_WEEK = 'WEEK',
  LAST_MONTH = 'MONTH',
  LAST_YEAR = 'YEAR'
}

export enum ConnectionType {
  FOLLOWING = 'following',
  FOLLOWERS = 'followers'
}
