import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import { Button, Loader, Modal, Typography } from 'src/components/common';
import { useUrlParamValue } from 'src/hooks';

import './DraftDeleteModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { openDraftModal, openPopup } from 'src/store/common/common.slice';
import { httpRequest } from 'src/services/commonApis';
import {
    TApplicationFormStepDeleteProps
  } from '../applicationFormStep/applicationFormStep.types';

const DraftDeleteModal = ({ goToApplicationHome }:TApplicationFormStepDeleteProps) => {
    const draftState = useSelector((state: any) => state?.common?.draftState);
    const dispatch = useDispatch();
    const [loading, setLoading] = useState<any>(false)
    // Hooks
    const navigate = useNavigate();
    const handleNavigateToSignUp = () => {
        navigate('/sign-up');
    };

    async function handleDeleteDraft() {
        setLoading(true)
        const response: any = await httpRequest(`program/application/draft/delete?draftId=${draftState?.data?.id}`, 'DELETE', null, 'withouttoken', 'external');
        if (response?.status) {
            goToApplicationHome();
            dispatch(
                openPopup({
                    popupMessage: response?.message,
                    popupType: 'success'
                })
            );
           
            dispatch(openDraftModal({ isOpen: false, data: null }))
        } else {
            dispatch(
                openPopup({
                    popupMessage: response?.message,
                    popupType: 'error'
                })
            );
        }
        setLoading(false)
    }
    return (
        <Modal
            isModalOpen={draftState?.isOpen}
            onModalClose={() => dispatch(openDraftModal({ isOpen: false, data: null }))}
            className="ape-navigation-modal"
        >
            <img src={hTractionLogoWithText} />
            <Typography>
                {draftState?.data?.message}
            </Typography>
            <Button size="large" variant="secondary" onClick={handleNavigateToSignUp}>
                Sign up
            </Button>
            {draftState?.data?.applicationType === 'draft' && <Button size="large" variant="primary" onClick={() => {
                handleDeleteDraft()
            }
            }>
                {loading ? <Loader /> : 'Delete Draft'}
            </Button>}
            {/* <div className="ape-navigation-modal__footer">
                <Typography as="span" variant="body-2" fontWeight="medium">
                    Don’t have an account?
                </Typography>
                <Typography
                    as="span"
                    variant="body-2"
                    fontWeight="bold"
                    className="ape-navigation-modal__footer__sign-up"
                    onClick={handleNavigateToSignUp}
                >
                    Sign Up
                </Typography>
            </div> */}
        </Modal>
    );
};

export default DraftDeleteModal;
