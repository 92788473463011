import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const GlobeIcon = ({ color = colors.primary[100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M12.9987 1.39844C16.4987 3.73177 17.5752 8.73914 17.6654 13.0651C17.5752 17.3911 16.4987 22.3984 12.9987 24.7318M12.9987 1.39844C9.4987 3.73177 8.42215 8.73915 8.33203 13.0651C8.42215 17.3911 9.4987 22.3984 12.9987 24.7318M12.9987 1.39844C6.55537 1.39844 1.33203 6.62178 1.33203 13.0651M12.9987 1.39844C19.442 1.39844 24.6654 6.62178 24.6654 13.0651M12.9987 24.7318C19.442 24.7318 24.6654 19.5084 24.6654 13.0651M12.9987 24.7318C6.55538 24.7318 1.33203 19.5084 1.33203 13.0651M24.6654 13.0651C22.332 16.5651 17.3247 17.6417 12.9987 17.7318C8.67274 17.6416 3.66536 16.5651 1.33203 13.0651M24.6654 13.0651C22.332 9.5651 17.3247 8.48856 12.9987 8.39844C8.67274 8.48856 3.66536 9.56511 1.33203 13.0651"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default GlobeIcon;
