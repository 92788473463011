import { Typography } from 'src/components/common';
import { useAppSelector } from 'src/store/hooks';
import { TRecommendedEvaluator } from 'src/services/apiEndpoint.types.ts';

import AddEvaluator from '../addEvaluator/AddEvaluator';
import { TSelectedEvaluator } from '../ShareToEvaluate';

export type TEvaluatorRecommendationsProps = {
  ideaId: string;
  selectedEvaluators: {
    name: string;
    role: string;
    email: string;
    id: string;
    profilePic: string;
  }[];
  updateSelectedUsers: ({ name, role, email, id, profilePic }: TSelectedEvaluator) => void;
};

const EvaluatorRecommendations = ({
  updateSelectedUsers,
  selectedEvaluators
}: TEvaluatorRecommendationsProps) => {
  // Hooks
  const { recommendedEvaluators } = useAppSelector((store) => store.ideaHub);

  return (
    <div className="search-select-evaluators">
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        Recommended Mentors and Investors for You
      </Typography>
      <Typography as="p" variant="caption" fontWeight="regular">
        Select mentors and investors from the recommended list below to share this idea for
        evaluation.
      </Typography>
      <div className="search-select-evaluators__selected-users">
        {recommendedEvaluators.map(
          (
            { name = '', role = '', email = '', id, profilePic = '' }: TRecommendedEvaluator,
            index
          ) => {
            const updateUsers = () => {
              updateSelectedUsers({ name, role, email, id, profilePic });
            };
            const alreadyAdded = selectedEvaluators.findIndex((item) => item.id === id) > -1;
            return (
              <AddEvaluator
                key={index}
                profilePic={profilePic}
                name={name}
                role={role}
                email={email}
                handleAddUser={updateUsers}
                alreadyAdded={alreadyAdded}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default EvaluatorRecommendations;
