import axios from 'axios';

import { APIResponse, BaseAPIService } from './base.api.service';
import { TGetOtpRequest } from './apiEndpoint.types.ts/externalApplicant/getOtp.endpoint.types.js';
import { TVerifyOtpRequest } from './apiEndpoint.types.ts/externalApplicant/verifyOtp.endpoint.types.js';
import { TCreateExternalApplicationRequest } from './apiEndpoint.types.ts/externalApplicant/createExternalApplication.endpoint.types.js';

export class ExternalApplicationAPIService extends BaseAPIService {
  private getOtpPath = '/get-otp';
  private verifyOtpPath = '/verify-otp';
  private createApplicationPath: string = '/program/application/create';

  constructor() {
    // External api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_EXTERNAL_APPLICATION_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async getOtp<TGetOtpResponse>(
    getOtpRequest: TGetOtpRequest
  ): Promise<APIResponse<TGetOtpResponse>> {
    const getOtpResponse: APIResponse<TGetOtpResponse> = await this.get(
      this.getOtpPath,
      false,
      getOtpRequest
    );
    return getOtpResponse;
  }

  public async verifyOtp<TVerifyOtpResponse>(
    verifyOtpRequest: TVerifyOtpRequest
  ): Promise<APIResponse<TVerifyOtpResponse>> {
    const verifyOtpResponse: APIResponse<TVerifyOtpResponse> = await this.get(
      this.verifyOtpPath,
      false,
      verifyOtpRequest
    );
    return verifyOtpResponse;
  }

  public async createExternalApplication<TCreateExternalApplicationResponse>(
    createApplicationRequest: TCreateExternalApplicationRequest
  ): Promise<APIResponse<TCreateExternalApplicationResponse>> {
    const { authorizationToken, ...payload } = createApplicationRequest;
    const createApplicationResponse: APIResponse<TCreateExternalApplicationResponse> =
    await this.post(this.createApplicationPath, payload, false, false, authorizationToken);
    // await this.post(this.createApplicationPath, payload, false, false);
    return createApplicationResponse;
  }
}
