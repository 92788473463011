import { AxiosError } from 'axios';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AuthAPIService } from 'src/services/auth.api.service';
import {
  TSignupRequest,
  TSignupResponse
} from 'src/services/apiEndpoint.types.ts/signup.endpoint.types';
import {
  TContactusRequest,
  TContactUsResponse
} from 'src/services/apiEndpoint.types.ts/contactus/contactus.endpoints.types';
import {
  TSignInForceChangeResponse,
  TSignInRequest,
  TSignInResponse
} from 'src/services/apiEndpoint.types.ts/signIn.endpoint.types';
import {
  TConfirmSignupRequest,
  TConfirmSignupResponse
} from 'src/services/apiEndpoint.types.ts/confirmSignup.endpoint.types';
import {
  TResendSignupOtpRequest,
  TResendSignupOtpResponse
} from 'src/services/apiEndpoint.types.ts/resendSignupOtp.endpoint.types';
import {
  TForgotPasswordRequest,
  TForgotPasswordResponse
} from 'src/services/apiEndpoint.types.ts/forgotPassword.endpoint.types';
import {
  TConfirmForgotPasswordRequest,
  TConfirmForgotPasswordResponse
} from 'src/services/apiEndpoint.types.ts/confirmForgotPassword.endpoint.types';
import {
  TSaveOnboardingInfoRequest,
  TSaveOnboardingInfoResponse
} from 'src/services/apiEndpoint.types.ts/saveOnboardingInfo.endpoint.types';
import { APIResponse } from 'src/services/base.api.service';
import errorMessages, { errorMessagesEnum } from 'src/constants/error.messages.constants';
import { StorageUtils } from 'src/utils';
import {
  TChangePasswordRequest,
  TChangePasswordResponse,
  TForceChangePasswordRequest,
  TForceChangePasswordResponse,
  TInviteMentorInvestorRequest,
  TInviteMentorInvestorResponse,
  TSendInviteRequest,
  TSendInviteResponse
} from 'src/services/apiEndpoint.types.ts';
import { TUserTypes } from 'src/constants/user.constants';
import { inviteMentorInvestorLoaderId } from 'src/components/company';

import { AppDispatch } from '../store';
import {
  assignLoaderId,
  openPopup,
  resetConfirmModalId,
  resetLoaderId,
  showPageLoader
} from '../common/common.slice';
import { getProfile, resetFields } from '../profile/profile.slice';
import { getCompany } from '../company/company.slice';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    /*
     ** signup
     */
    name: '',
    email: '',
    shouldRedirectToEmailVerification: false,

    /*
     ** login
     */
    shouldRedirectToDashboard: false,
    shouldRedirectToRoleSelection: false,

    /*
     ** Forgot password
     */
    forgotPasswordEmail: '',
    redirectToConfirmForgotPassword: false,

    /*
     ** Force change password
     */
    forceChangePasswordEmail: '',
    shouldRedirectToForceChangePassword: false,
    forceChangePasswordToken: '',
    forceChangePasswordChallengeName: '',

    /*
     ** User details
     */
    loggedInUser: {
      id: '',
      email: '',
      name: '',
      role: '',
      gender: '',
      dob: '',
      address: '',
      country: '',
      state: '',
      city: '',
      zipCode: '',
      phoneNumber: '',
      linkedin: '',
      introduction: '',
      about: '',
      profession: '',
      experience: '',
      education: '',
      background: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: ''
    },
    loggedInCompany: {
      id: '',
      email: '',
      name: '',
      about: '',
      foundedYear: '',
      industry: '',
      phoneNumber: '',
      address: '',
      linkedin: '',
      createdAt: '',
      updatedAt: '',
      deletedAt: ''
    },
    profilePic: '',

    /*
     ** user onboarding
     */
    preselectedUserType: '',

    /*
     ** shared state
     */
    shouldOpenPopup: false, // Whether to open the popup
    popupMessage: '', // Message to be displayed on the popup
    loading: false, // loading state
    shouldRedirectToLogin: false, // whether to redirect to login, post confirm and resetting new password
    startResendSignupOtpTimer: false, // whether to start timer on OTP screen
    isAuthorized: false, // whether the user is authorized
    isAuthorizationCheckInProgress: true, // Whether the user is being checked for authorization
    shouldRedirectToAccessDenied: false // when the user doesn't have enough permissions
  },
  reducers: {
    /*
     ** signup
     */
    signupSuccess: (state, action: PayloadAction<any>) => {
      /*
       * To display the email in email verification.
       * Also useful when user returns from email verification screen
       * and prefill the signup form with the values, the uer has entered previously.
       */
      const { name, email } = action.payload;

      state.name = name;
      state.email = email;
    },
    signupError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },

    /*
     ** signIn
     */
    signInSuccess: (state, action: PayloadAction<any>) => {
      const { loggedInUser, profilePic } = action.payload;

      const onboardingComplete: boolean = loggedInUser?.onboardingComplete;

      state.loggedInUser = loggedInUser;
      state.profilePic = profilePic;
      state.isAuthorized = true;

      if (onboardingComplete) {
        state.shouldRedirectToDashboard = true;
      } else {
        state.shouldRedirectToRoleSelection = true;
      }
    },
    incubatorSignInSuccess: (state, action: PayloadAction<any>) => {
      const { loggedInCompany, profilePic } = action.payload;

      state.loggedInCompany = loggedInCompany;
      state.profilePic = profilePic;
      state.shouldRedirectToDashboard = true;
      state.isAuthorized = true;
    },

    signInError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },
    resetSignInRedirection: (state) => {
      state.shouldRedirectToDashboard = false;
    },

    /*
     ** Confirm signup
     */
    confirmSignupSuccess: (state) => {
      state.name = '';
      state.email = '';
    },
    confirmSignupError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },

    /*
     ** Resend signup OTP
     */
    confirmResendSignupOtpSuccess: (state) => {
      state.startResendSignupOtpTimer = true;
    },
    confirmResendSignupOtpError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },
    pauseResendSignupOtpTimer: (state) => {
      state.startResendSignupOtpTimer = false;
    },

    /*
     ** Forgot password
     */
    forgotPasswordSuccess: (state, action: PayloadAction<any>) => {
      state.forgotPasswordEmail = action.payload;
      state.redirectToConfirmForgotPassword = true;
    },
    forgotPasswordError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },

    /*
     ** Confirm Forgot password
     */
    confirmForgotPasswordSuccess: (state) => {
      state.forgotPasswordEmail = '';
      state.shouldRedirectToLogin = true;
      state.redirectToConfirmForgotPassword = false;
    },
    confirmForgotPasswordError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },

    /*
     ** Resend new password OTP
     */
    resendNewPasswordOtpSuccess: (state) => {
      state.startResendSignupOtpTimer = true;
    },
    resendNewPasswordOtpError: (state, action: PayloadAction<any>) => {
      state.popupMessage = action.payload;
      state.shouldOpenPopup = true;
    },

    /*
     ** Loading state
     */
    startLoading: (state) => {
      state.loading = true;
    },
    stopLoading: (state) => {
      state.loading = false;
    },

    /*
     ** User onboarding
     */
    preSelectUserType: (state, action: PayloadAction<any>) => {
      state.preselectedUserType = action.payload;
    },
    saveServiceProviderStartupsOnboardingInfoSuccess: (state) => {
      state.shouldRedirectToDashboard = true;
    },
    saveOnboardingInfoSuccess: (state) => {
      state.shouldRedirectToDashboard = true;
    },
    /*
     ** User authorization
     */
    userAuthorizationSuccess: (state) => {
      state.isAuthorized = true;
      state.isAuthorizationCheckInProgress = false;
    },
    userAuthorizationFailure: (state) => {
      state.isAuthorized = false;
      state.isAuthorizationCheckInProgress = false;
      state.shouldRedirectToAccessDenied = true;
    },
    /*
     ** reset redirection
     */
    resetRedirection: (state) => {
      state.shouldRedirectToLogin = false;
      state.shouldRedirectToEmailVerification = false;
      state.shouldRedirectToDashboard = false;
      state.redirectToConfirmForgotPassword = false;
      state.shouldRedirectToRoleSelection = false;
      state.shouldRedirectToForceChangePassword = false;
      state.shouldRedirectToAccessDenied = false;
    },
    /*
     ** SignOut success
     */
    signOutSuccess: (state) => {
      state.loggedInUser.role = '';
    },
    /*
     ** Force change password
     */
    forceChangePasswordSuccess: (state) => {
      state.shouldRedirectToForceChangePassword = false;
      state.forceChangePasswordEmail = '';
      state.forceChangePasswordToken = '';
      state.forceChangePasswordChallengeName = '';
    },
    forceChangeRedirection: (state, action: PayloadAction<any>) => {
      const { email, session, challengeName } = action.payload;

      state.shouldRedirectToForceChangePassword = true;
      state.forceChangePasswordEmail = email;
      state.forceChangePasswordToken = session;
      state.forceChangePasswordChallengeName = challengeName;
    },
    sendInviteSuccess: () => { },
    startHtractionLoader: (state) => {
      state.isAuthorizationCheckInProgress = true;
    },
    stoptHtractionLoader: (state) => {
      state.isAuthorizationCheckInProgress = false;
    }
  }
});

export const {
  signupSuccess,
  signupError,
  signInSuccess,
  signInError,
  startLoading,
  stopLoading,
  confirmSignupSuccess,
  confirmSignupError,
  confirmResendSignupOtpSuccess,
  confirmResendSignupOtpError,
  pauseResendSignupOtpTimer,
  forgotPasswordSuccess,
  forgotPasswordError,
  confirmForgotPasswordSuccess,
  confirmForgotPasswordError,
  preSelectUserType,
  resendNewPasswordOtpSuccess,
  resendNewPasswordOtpError,
  resetSignInRedirection,
  saveServiceProviderStartupsOnboardingInfoSuccess,
  saveOnboardingInfoSuccess,
  userAuthorizationSuccess,
  userAuthorizationFailure,
  resetRedirection,
  signOutSuccess,
  forceChangePasswordSuccess,
  forceChangeRedirection,
  incubatorSignInSuccess,
  sendInviteSuccess,
  startHtractionLoader,
  stoptHtractionLoader
} = authSlice.actions;

export default authSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error',
      ...(message === errorMessagesEnum.USER_ALREADY_EXISTS && {
        navigateTo: '/login?prev=/sign-up'
      })
    })
  );
};

export const signup = (payload: TSignupRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TSignupResponse> = await new AuthAPIService().signup(payload);

    if (response?.status === 200) {
      dispatch(signupSuccess({ name: payload.name, email: payload.email }));
      dispatch(
        openPopup({
          popupMessage: 'Verification code sent to your entered email!',
          popupType: 'success',
          navigateTo: '/email-verification'
        })
      );
      return response;
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const contactus = (payload: TContactusRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TContactUsResponse> = await new AuthAPIService().contactus(payload);
    if (response?.status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'Contact Request submitted successfully',
          popupType: 'success'
        })
      );
      return response;
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const signIn = (payload: TSignInRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TSignInResponse> = await new AuthAPIService().signIn(payload);

    const responseData = response?.data?.data;

    if (response?.status === 200) {
      const AuthenticationResult = responseData?.AuthenticationResult;
      const loggedInUser = responseData?.user;
      const profilePic = responseData?.profilePic;
      const tokenExpiresIn = AuthenticationResult?.ExpiresIn;
      const userType = responseData?.type as TUserTypes;
      const challengeName = (responseData as unknown as TSignInForceChangeResponse)?.ChallengeName;

      const company = response?.data?.data?.company;

      if (company) {
        if (
          (responseData as unknown as TSignInForceChangeResponse).ChallengeName ===
          'NEW_PASSWORD_REQUIRED'
        ) {
          const forceChangeResponse = responseData as unknown as TSignInForceChangeResponse;
          dispatch(
            forceChangeRedirection({
              email: payload.email,
              session: forceChangeResponse.Session,
              challengeName: 'NEW_PASSWORD_REQUIRED'
            })
          );
          return;
        }

        StorageUtils.set('AuthenticationResult', AuthenticationResult);
        StorageUtils.set('companyId', company?.id);
        StorageUtils.set('companyEmail', company?.email);
        StorageUtils.set('tokenExpiresIn', `${new Date().getTime() + tokenExpiresIn * 1000}`);
        StorageUtils.set('userType', userType);
        StorageUtils.set('loggedInCompany', company);

        if (userType === 'Company') {
          StorageUtils.set('email', company?.email);
          dispatch(getCompany({ id: company?.id })).then(() => {
            dispatch(incubatorSignInSuccess({ loggedInCompany: company, profilePic }));
          });
        }
      }
   
      if (loggedInUser) {
        if (challengeName === 'NEW_PASSWORD_REQUIRED') {
          const forceChangeResponse = responseData as unknown as TSignInForceChangeResponse;
          dispatch(
            forceChangeRedirection({
              email: payload.email,
              session: forceChangeResponse.Session,
              challengeName: 'NEW_PASSWORD_REQUIRED'
            })
          );
          return;
        }

        StorageUtils.set('AuthenticationResult', AuthenticationResult);
        StorageUtils.set('userId', loggedInUser?.id);
        StorageUtils.set('email', loggedInUser?.email);
        StorageUtils.set('tokenExpiresIn', `${new Date().getTime() + tokenExpiresIn * 1000}`);
        StorageUtils.set('userType', responseData?.type);
        StorageUtils.set('loggedInUser', loggedInUser);
        console.log(loggedInUser, 'reinin')
        dispatch(signInSuccess({ loggedInUser, profilePic }));
        dispatch(getProfile({ id: loggedInUser.id }));
      }

      return response;
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const confirmSignup = (payload: TConfirmSignupRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TConfirmSignupResponse> = await new AuthAPIService().confirmSignup(
      payload
    );

    if (response?.status === 200) {
      dispatch(confirmSignupSuccess());
      dispatch(
        openPopup({
          popupMessage: 'Your email has been verified successfully!',
          popupType: 'success',
          navigateTo: '/login'
        })
      );
      return response;
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const resendSignupOtp =
  (payload: TResendSignupOtpRequest) => async (dispatch: AppDispatch) => {
    try {
      const response: APIResponse<TResendSignupOtpResponse> =
        await new AuthAPIService().resendSignupOtp(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'Verification code sent to your entered email!',
            popupType: 'success'
          })
        );
        dispatch(confirmResendSignupOtpSuccess());
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const forgotPassword =
  (payload: TForgotPasswordRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response: APIResponse<TForgotPasswordResponse> =
        await new AuthAPIService().forgotPassword(payload);

      if (response?.status === 200) {
        dispatch(forgotPasswordSuccess(payload.email));
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const confirmForgotPassword =
  (payload: TConfirmForgotPasswordRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response: APIResponse<TConfirmForgotPasswordResponse> =
        await new AuthAPIService().confirmForgotPassword(payload);

      if (response?.status === 200) {
        dispatch(confirmForgotPasswordSuccess());
        dispatch(
          openPopup({
            popupMessage: 'Your password has been successfully updated!',
            popupType: 'success',
            navigateTo: '/login?prev=/set-new-password'
          })
        );
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const resendNewPasswordOtp =
  (payload: TForgotPasswordRequest) => async (dispatch: AppDispatch) => {
    /*
     ** Forgot password api will be used to fetch the new OTP.
     */
    try {
      const response: APIResponse<TForgotPasswordResponse> =
        await new AuthAPIService().forgotPassword(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'Verification code sent to your entered email!',
            popupType: 'success'
          })
        );
        dispatch(resendNewPasswordOtpSuccess());
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const saveServiceProviderStartupsOnboardingInfo =
  (payload: TSaveOnboardingInfoRequest) => async (dispatch: AppDispatch) => {
    /*
     ** Save onboarding information for service providers or startups
     */
    try {
      dispatch(startLoading());
      const response: APIResponse<TSaveOnboardingInfoResponse> =
        await new AuthAPIService().saveOnboardingInfo(payload);

      const id = StorageUtils.get('userId') as unknown as number;

      if (response?.status === 200) {
        dispatch(saveServiceProviderStartupsOnboardingInfoSuccess());
        dispatch(showPageLoader());
        dispatch(getProfile({ id }));
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const saveOnboardingInfo =
  (payload: TSaveOnboardingInfoRequest) => async (dispatch: AppDispatch) => {
    /*
     ** Save onboarding information for roles (other than service providers or startups)
     */
    try {
      dispatch(startLoading());
      const response: APIResponse<TSaveOnboardingInfoResponse> =
        await new AuthAPIService().saveOnboardingInfo(payload);

      const id = StorageUtils.get('userId') as unknown as number;

      if (response?.status === 200) {
        dispatch(saveOnboardingInfoSuccess());
        dispatch(showPageLoader());
        dispatch(getProfile({ id }));
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const signOut = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(signOutSuccess());
    dispatch(resetFields());
    dispatch(resetConfirmModalId());
    StorageUtils.clear();
    window.location.href = '/';
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const forceChangePassword =
  (payload: TForceChangePasswordRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const response: APIResponse<TForceChangePasswordResponse> =
        await new AuthAPIService().forceChangePassword(payload);

      if (response?.status === 200) {
        dispatch(forceChangePasswordSuccess());
        dispatch(
          openPopup({
            popupMessage: 'Your password has been successfully updated!',
            popupType: 'success',
            navigateTo: '/login?prev=/force-change-password'
          })
        );
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const sendInvite = (payload: TSendInviteRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TSendInviteResponse> = await new AuthAPIService().sendInvite(
      payload
    );
    if (response?.status === 200) {
      dispatch(
        openPopup({
          popupMessage: response?.data?.message,
          popupType: 'success'
        })
      );
      dispatch(sendInviteSuccess());
      // throw response
    }
  } catch (err: any) {
    console.log(err, 'errorData');
    dispatch(
      openPopup({
        popupMessage: err?.response?.data?.message || 'email is not sent',
        popupType: 'error'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const changePassword =
  (payload: TChangePasswordRequest) => async (dispatch: AppDispatch) => {
    dispatch(assignLoaderId('settings-change-password'));
    try {
      const response: APIResponse<TChangePasswordResponse> =
        await new AuthAPIService().changePassword(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your password!',
            popupType: 'success'
          })
        );
        return response?.status;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(resetLoaderId());
    }
  };

export const confirmSettingsForgotPassword =
  (payload: TConfirmForgotPasswordRequest) => async (dispatch: AppDispatch) => {
    dispatch(assignLoaderId('settings-forgot-password'));
    try {
      const response: APIResponse<TConfirmForgotPasswordResponse> =
        await new AuthAPIService().confirmForgotPassword(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated your password!',
            popupType: 'success'
          })
        );
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(resetLoaderId());
    }
  };

export const resendSettingsNewPasswordOtp =
  (payload: TForgotPasswordRequest) => async (dispatch: AppDispatch) => {
    /*
     ** Forgot password api will be used to fetch the new OTP.
     */
    try {
      const response: APIResponse<TForgotPasswordResponse> =
        await new AuthAPIService().forgotPassword(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'Verification code sent to your registered email!',
            popupType: 'successRetainModal'
          })
        );
        dispatch(resendNewPasswordOtpSuccess());
        return response;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const inviteMentorInvestor =
  (payload: TInviteMentorInvestorRequest) => async (dispatch: AppDispatch) => {
    dispatch(assignLoaderId(inviteMentorInvestorLoaderId));
    try {
      const response: APIResponse<TInviteMentorInvestorResponse> =
        await new AuthAPIService().inviteMentorInvestor(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'Temporary Password Sent!',
            popupDescription:
              'A temporary password has been successfully sent to the entered email address.',
            popupType: 'success'
          })
        );
        return response;
      }
    } catch (err) {
      const errorResponse = (err as unknown as AxiosError)?.response;
      const message = (errorResponse?.data as { message: string })?.message;

      dispatch(
        openPopup({
          popupMessage:
            errorMessages[message as keyof typeof errorMessages] ||
            message ||
            errorMessages.unknownError,
          popupType: 'error'
        })
      );
    } finally {
      dispatch(resetLoaderId());
    }
  };
