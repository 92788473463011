import { Dispatch, SetStateAction } from 'react';

export type TUpdateFormValue = {
  fieldName: string;
  value: string | boolean;
  setFormValue: Dispatch<SetStateAction<Record<string, string | boolean | any>>>;
};

/**
 * A custom hook for updating form values.
 * @returns {Object} An object containing the updateFormValue function.
 */
const useUpdateFormValue = () => {
  const updateFormValue = ({ fieldName, value, setFormValue }: TUpdateFormValue) => {
    setFormValue((prev) => ({ ...prev, [fieldName]: value }));
  };

  return { updateFormValue };
};

export default useUpdateFormValue;
