import { useMemo } from 'react';

import './previewField.scss';
import TextField from './TextField';
import PhoneNumber from './PhoneNumber';
import TextArea from './TextArea';
import AddLabel from './AddLabel';
import TextEditor from './TextEditor';
import Address from './Address';
import DateTimeSelection from './DateTimeSelection';
import UploadField from './UploadField';
import OptionsField from './OptionsField';
import TableField from './TableField';
import { TPreviewFieldProps } from './previewField.types';
// import Phone from '../../createProgram/customApplicationForm/dynamicField/PhoneNumber';
const createProgramStep = true
const PreviewField = ({
  id,
  label,
  type,
  sectionId,
  required,
  options,
  validations,
  response,
  files,
  errors
}: TPreviewFieldProps) => {
  const fieldComponent = useMemo(() => {
    const fieldProps = {
      id,
      sectionId,
      label,
      type,
      required,
      options,
      validations,
      response,
      files,
      errors,
      createProgramStep
    };
    if (type === 'textInput') {
      return <TextField {...fieldProps} />;
    }
    if (type === 'textArea') {
      return <TextArea {...fieldProps} />;
    }
    if (type === 'address') {
      return <Address {...fieldProps} />;
    }
    if (type === 'textEditor') {
      return <TextEditor {...fieldProps} />;
    }
    if (type === 'phoneNumber') {
      return <PhoneNumber {...fieldProps} />;
    }
    if (type === 'dropdown') {
      return (
        <OptionsField
          fieldTitle="Dropdown field"
          fieldPlaceholder="Multiple choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'singleChoice') {
      return (
        <OptionsField
          fieldTitle="Single choice field"
          fieldPlaceholder="Single choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'multipleChoice') {
      return (
        <OptionsField
          fieldTitle="Multiple choice field"
          fieldPlaceholder="Multiple choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'tableColumns') {
      return (
        <TableField
          fieldTitle="Multiple choice field"
          fieldPlaceholder="Multiple choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'label') {
      return <AddLabel {...fieldProps} />;
    }
    if (type === 'dateSelection') {
      return <DateTimeSelection {...fieldProps} placeholder="Select date" inputType="date" />;
    }
    if (type === 'timeSelection') {
      return <DateTimeSelection {...fieldProps} placeholder="Select time" inputType="time" />;
    }
    if (type === 'fileUpload') {
      // console.log('dddddddddddddddd')
      return <UploadField {...fieldProps} />;
    }
  }, [id, label, type, sectionId, required, options, validations, response, files, errors]);

  return fieldComponent;
};

export default PreviewField;
