import React, { RefObject, TextareaHTMLAttributes } from 'react';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles

import './textArea.scss';

type TTextAreaProps = {
  variant?: componentSizeVariantsEnum;
  inputRef?: RefObject<HTMLTextAreaElement>;
  createProgramStep?: any;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * Props for the TextArea component.
 * @typedef {Object} TTextAreaProps
 * @param {componentSizeVariantsEnum} [variant] - The variant of the text area.
 * @param {TextareaHTMLAttributes<HTMLTextAreaElement>} [HTMLTextAreaElement] - HTML attributes of the text area element.
 */
const TextEditorAreaDescriptive = ({
  className,
  value,
  disabled,
  variant = componentSizeVariantsEnum.MEDIUM,
  createProgramStep,
  ...rest
}: TTextAreaProps) => {
  console.log(createProgramStep, 'createPriiiiogramStepddd');

  const handleChange = (content: string) => {
    if (rest.onChange) {
      rest.onChange({
        ...event,
        target: {
          ...event?.target,
          value: content
        }
      } as unknown as React.ChangeEvent<HTMLTextAreaElement>);
    }
  };

  return (
    <div style={{ maxHeight: '300px', height: '200px' }}>
      <ReactQuill
        value={value as string}
        onChange={handleChange}
        readOnly={disabled}
        style={{ maxHeight: '300px', height: '150px' }}
      />
    </div>
  );
};

export default TextEditorAreaDescriptive;
