import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/store/hooks';
import classNames from 'classnames';
import { Card, Typography } from 'src/components/common';
import { useUrlParamValue } from 'src/hooks';
import { emptyChatLogo } from 'src/assets/images';
import { TUserTypes } from 'src/constants/user.constants';

import ChatMessagesHeader from './chatMessagesHeader/ChatMessagesHeader';
import ChatMessagesBody from './chatMessagesBody/ChatMessagesBody';
import ChatMessagesFooter from './chatMessagesFooter/ChatMessagesFooter';
import { TChatMessagesProps, TEditMessageSettings } from './chatMessages.types';
import './chatMessages.scss';

const ChatMessages = ({
  selectedUserId,
  selectedUserType,
  showChatWindowOnMobile,
  directMessageContext
}: TChatMessagesProps) => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const recipientUserProfile = useAppSelector((store) => store.profile.viewUserProfile);
  const recipientCompanyProfile = useAppSelector((store) => store.company.viewCompany);
  const { isDirectChatNotInitiated, isIdeaDirectChatNotInitiated } = useAppSelector(
    (store) => store.chat
  );

  // State
  const [editMessageSettings, setEditMessageSettings] = useState<TEditMessageSettings>({
    editMode: false,
    messageId: '',
    message: ''
  });

  // Constants
  const conversationId = paramValue({ paramName: 'conversationId' }) || '';
  const recipientUserType = paramValue({ paramName: 'userType' }) as unknown as TUserTypes;
  const recipientName =
    recipientUserType === 'User' ? recipientUserProfile?.name : recipientCompanyProfile.name;

  const updateMessageSettings = ({ editMode, messageId, message }: TEditMessageSettings) => {
    setEditMessageSettings({ editMode, messageId, message });
  };

  useEffect(() => {
    setEditMessageSettings({
      editMode: false,
      messageId: '',
      message: ''
    });
  }, [conversationId]);

  if (
    (directMessageContext === 'directMessage' && isDirectChatNotInitiated) ||
    (directMessageContext === 'ideaDirectMessage' && isIdeaDirectChatNotInitiated)
  ) {
    return (
      <Card
        className={classNames('chat-messages', {
          'chat-messages--show-chat-mobile': showChatWindowOnMobile
        })}
      >
        <div className="chat-messages__not-initiated">
          <img src={emptyChatLogo} alt="no chat initiated" />
          <Typography variant="subHeading-2" fontWeight="semiBold">
            No Chats Yet!
          </Typography>
          <Typography as="p" variant="caption" fontWeight="regular">
            Start chatting with others, and your conversations will appear here.
          </Typography>
        </div>
      </Card>
    );
  }

  if (!conversationId) {
    return (
      <Card
        className={classNames('chat-messages', {
          'chat-messages--show-chat-mobile': showChatWindowOnMobile
        })}
      >
        <div className="chat-messages__no-chat-selected">
          <img src={emptyChatLogo} alt="no chat initiated" />
          <Typography variant="subHeading-2" fontWeight="semiBold">
            Start Chatting with Others!
          </Typography>
          <Typography as="p" variant="caption" fontWeight="regular">
            Your selected conversations will appear here. Choose a chat to begin and enjoy easy,
            friendly interactions!
          </Typography>
        </div>
      </Card>
    );
  }

  return (
    <Card
      className={classNames('chat-messages', {
        'chat-messages--show-chat-mobile': showChatWindowOnMobile
      })}
    >
      <ChatMessagesHeader conversationId={conversationId} />
      <ChatMessagesBody
        selectedUserId={selectedUserId}
        selectedUserType={selectedUserType}
        conversationId={conversationId}
        updateMessageSettings={updateMessageSettings}
        directMessageContext={directMessageContext}
      />
      <ChatMessagesFooter
        key={`${conversationId}-footer`}
        name={recipientName}
        selectedUserId={selectedUserId}
        selectedUserType={selectedUserType}
        conversationId={conversationId}
        editMessageSettings={editMessageSettings}
        updateMessageSettings={updateMessageSettings}
        directMessageContext={directMessageContext}
      />
    </Card>
  );
};

export default ChatMessages;
