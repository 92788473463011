import { FC, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'src/store/hooks';
import { hTractionLogoWithText, userOnboardingBanner } from 'src/assets/images';

import './authLayout.scss';

const AuthLayout: FC = () => {
  // Hooks
  const navigate = useNavigate();

  const isAuthorized: boolean = useAppSelector((store) => store.auth.isAuthorized);

  const redirectIfAuthorized = () => {
    if (isAuthorized) {
      navigate('/dashboard');
    }
  };

  useEffect(() => {
    redirectIfAuthorized();
  }, [isAuthorized]);

  return (
    <div className="auth-layout">
      <div className="auth-layout__content">
        <div className="auth-layout__content__logo">
          <img src={hTractionLogoWithText} alt="htraction logo" />
        </div>
        <div className="auth-layout__content__form">
          <Outlet />
        </div>
      </div>
      <img
        className="auth-layout__banner"
        src={userOnboardingBanner}
        alt="user onboarding banner"
      />
    </div>
  );
};

export default AuthLayout;
