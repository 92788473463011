import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Card, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { TUserTypes } from 'src/constants/user.constants';
import useHandleFollow from 'src/components/posts/handleFollow/useHandleFollow';
import { useGetIdentity } from 'src/hooks';
import { useAppDispatch } from 'src/store/hooks';
import { TSearchItemReduxKeys, handleFollowSearchResult } from 'src/store/search/search.slice';

import './userCard.scss';
import { useState } from 'react';

export type TUserCard = {
  id: string;
  name: string;
  role: string;
  introduction: string;
  follows: boolean;
  profilePic: string;
  cardType: TUserTypes;
  type?: string;
  searchResultType: TSearchItemReduxKeys;
};

const UserCard = ({
  id,
  name,
  role,
  cardType,
  profilePic,
  follows,
  introduction,
  type,
  searchResultType
}: TUserCard) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const followUser = useHandleFollow();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserType, authenticatedId, loggedInUserId, loggedInCompanyId } = getIdentities();
  // Whether this profile card is viewed by self.
  const isSelfUser = loggedInUserType === 'User' ? id === loggedInUserId : id === loggedInCompanyId;
  const [loading, setLoading] = useState<boolean>(false)
  const handleFollowUser = () => {
    followUser({
      following: follows,
      viewerId: authenticatedId,
      loggedInUserType,
      followUserId: id,
      followUserType: cardType,
      followName: name
    });
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 1800)
    dispatch(handleFollowSearchResult({ id, searchResultType }));
  };

  const viewUserProfile = () => {
    if (isSelfUser) {
      navigate(cardType === 'User' ? '/my-profile' : '/my-company');
    } else {
      navigate(`${cardType === 'User' ? '/view-profile' : '/view-company'}?id=${id}`);
    }
  };

  return (
    <Card className="user-card">
      <div className="user-card__header">
        <div
          className={classNames('user-card__header__user', {
            'user-card__header__user--is-company': cardType === 'Company'
          })}
          onClick={viewUserProfile}
        >
          <ProfilePicture
            url={profilePic}
            fallback={name[0]}
            className="user-card__header__user__profile-pic"
          />
          <div className="user-card__header__user__info">
            <Typography as="p" variant="subHeading-2" fontWeight="bold">
              {name}
            </Typography>
            <Typography
              as="p"
              variant="body-2"
              fontWeight="regular"
              className="user-card__header__user__info__role"
            >
              {cardType === 'User' ? role : type}
            </Typography>
          </div>
        </div>
        {!isSelfUser && (
          <Button
            variant={follows ? 'primary' : 'secondary'}
            size="small"
            onClick={handleFollowUser}
            disabled={loading}
          >
            {follows ? 'Following' : 'Follow'}
          </Button>
        )}
      </div>
      <Typography className="user-card__introduction">{introduction}</Typography>
    </Card>
  );
};

export default UserCard;
