import Label from 'src/components/common/formFields/label/Label';
import { Typography } from 'src/components/common';
import { UploadIcon } from 'src/components/common/common.icons';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const UploadField = ({ id, label, required }: TPreviewFieldProps) => {
  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Label required={required}>{label}</Label>
      </div>

      <div className="dynamic-field__upload-file">
        <span className="dynamic-field__upload-file__drag-drop">
          <UploadIcon />
          <Typography as="p" variant="body-2" fontWeight="regular">
            Drag and drop your logo
          </Typography>
          <input type="file" hidden />
        </span>
        <Typography as="p" variant="body-2" fontWeight="regular">
          or
        </Typography>
        <Typography
          as="span"
          variant="body-2"
          fontWeight="medium"
          className="dynamic-field__upload-file__browse"
        >
          Browse on your device
        </Typography>
      </div>
    </div>
  );
};

export default UploadField;
