import { parsePhoneNumberFromString } from 'libphonenumber-js';

import { TValidatePhoneNumberProps } from './validatePhoneNumber.types';

const validatePhoneNumber = ({ phoneNumber = '', countryCode }: TValidatePhoneNumberProps) => {
  try {
    const phoneNumberObject = parsePhoneNumberFromString(phoneNumber, countryCode);

    console.log(phoneNumberObject);
    if (phoneNumberObject && phoneNumberObject.isValid()) {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

export default validatePhoneNumber;
