import * as Yup from 'yup';
import { EMAIL } from 'src/constants/regex.constants';

const editCompanyValidationSchema = Yup.object().shape({
  name: Yup.string().required('Company name should not be empty'),
  type: Yup.string().required('Company type should not be empty'),
  companyEmailAddress: Yup.string().matches(EMAIL, 'Please enter a valid email address'),
  foundedYear: Yup.number().max(new Date().getFullYear(), 'Year cannot be in the future')
});

export default editCompanyValidationSchema;
