import React from 'react';
import { nanoid } from 'nanoid';
import Typography from 'src/components/common/typography/Typography';
import { TFeature } from 'src/constants/landingpage.types';

import './featureBlock.scss';

const FeatureBlock: React.FC<TFeature> = ({ icon, title, description }: TFeature) => {
  return (
    <div key={nanoid()} className="feature-block">
      {icon && <div className="feature-block__icon">{icon}</div>}
      <Typography
        as="p"
        variant="subHeading-2"
        fontWeight="semiBold"
        className="feature-block__title"
      >
        {title}
      </Typography>
      <Typography
        as="p"
        variant="body-1"
        fontWeight="regular"
        className="feature-block__description"
      >
        {description}
      </Typography>
    </div>
  );
};

export default FeatureBlock;
