import { FC, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useAppDispatch } from 'src/store/hooks';
import { Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { useUploadMedia, useUrlParamValue } from 'src/hooks';
import { TGetDocumentPreSignedUrlActionRequest } from 'src/services/apiEndpoint.types.ts';
import { getDocumentPresignedUrl } from 'src/store/ideaHub/ideaHub.slice';

import './viewDocuments.scss';

import { TIdeaDocument } from '../../ideaHub.types';
import { DocumentCard } from '..';

export type TViewDocumentsProps = {
  documents: TIdeaDocument[];
  showUploadButton: boolean;
};

const ViewDocuments: FC<TViewDocumentsProps> = ({
  documents,
  showUploadButton
}: TViewDocumentsProps) => {
  // Hooks
  const { handleUploadMedia } = useUploadMedia();
  const { paramValue } = useUrlParamValue();
  const dispatch = useAppDispatch();

  const ideaId:string = paramValue({ paramName: 'id' }) as unknown as string;

  const uploadDocumentRef = useRef<HTMLInputElement>(null);

  const onMediaLoad = (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: string,
    name: string
  ) => {
    const payload: TGetDocumentPreSignedUrlActionRequest = {
      id: uuidv4(),
      ideaId,
      fileName: name,
      data
    };

    dispatch(getDocumentPresignedUrl(payload));
  };

  return (
    <div className="view-idea-documents">
      <div className="view-idea-documents__header">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Documents
        </Typography>
        {showUploadButton && (
          <Button
            variant="primary"
            size="small"
            onClick={() => {
              if (uploadDocumentRef.current) {
                uploadDocumentRef.current.click();
              }
            }}
          >
            Upload File
          </Button>
        )}
      </div>
      {documents?.length === 0 && (
        <Typography
          as="p"
          variant="body-2"
          fontWeight="regular"
          className="view-idea-documents__no-docs"
        >
          No document added. Upload your idea-related document here
        </Typography>
      )}
      <div className="view-idea-documents__documents">
        {documents.map((document: TIdeaDocument, index) => (
          <DocumentCard key={index} {...document} showDeleteButton={showUploadButton} />
        ))}
      </div>
      <input
        type="file"
        hidden
        data-testid="inputUploadImage"
        ref={uploadDocumentRef}
        accept=".pdf"
        className="view-idea-documents__select-file"
        multiple
        onChange={(e) => {
          handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'pdf' });
        }}
      />
    </div>
  );
};

ViewDocuments.defaultProps = { documents: [] };

export default ViewDocuments;
