import axios from 'axios';

import {
  TUploadProgramFileRequest,
  TUploadApplicationFileRequest,
  TUploadFileToS3Request,
  TGetProgramDocumentRequest
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';

export class ExternalProgramAPIService extends BaseAPIService {
  private getProgramPath: string = '/program/get';
  private uploadProgramFilePath: string = 'program/document/create';
  private uploadApplicationFilePath: string = 'program/application/document/create';
  private getDocumentPath: string = 'program/documents/get';

  constructor() {
    // Program api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_EXTERNAL_APPLICATION_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async getProgram<TGetProgramExternalUserResponse>(
    getProgramRequest: any
  ): Promise<APIResponse<TGetProgramExternalUserResponse>> {
    const getProgramResponse: APIResponse<TGetProgramExternalUserResponse> = await this.get(
      this.getProgramPath,
      false,
      getProgramRequest
    );
    return getProgramResponse;
  }

  public async uploadProgramFile<TUploadProgramFileResponse>(
    uploadProgramFileRequest: TUploadProgramFileRequest
  ): Promise<APIResponse<TUploadProgramFileResponse>> {
    const uploadProgramFileResponse: APIResponse<TUploadProgramFileResponse> = await this.post(
      this.uploadProgramFilePath,
      uploadProgramFileRequest,
      true
    );
    return uploadProgramFileResponse;
  }

  public async uploadApplicationFile<TUploadApplicationFileResponse>(
    uploadApplicationFileRequest: TUploadApplicationFileRequest
  ): Promise<APIResponse<TUploadApplicationFileResponse>> {
    const uploadApplicationFileResponse: APIResponse<TUploadApplicationFileResponse> =
      await this.post(this.uploadApplicationFilePath, uploadApplicationFileRequest, true);
    return uploadApplicationFileResponse;
  }

  public async uploadFileToS3<TUploadFileToS3Response>(
    uploadFileToS3Request: TUploadFileToS3Request
  ): Promise<APIResponse<TUploadFileToS3Response>> {
    const { data, includeAuthorizationHeaders = true } = uploadFileToS3Request;
    const uploadFileToS3Response: APIResponse<TUploadFileToS3Response> = await this.put(
      uploadFileToS3Request.s3Key,
      data,
      includeAuthorizationHeaders
    );
    return uploadFileToS3Response;
  }

  public async getDocument<TGetProgramDocumentResponse>(
    getDocumentRequest: TGetProgramDocumentRequest
  ): Promise<APIResponse<TGetProgramDocumentResponse>> {
    const documentResponse: APIResponse<TGetProgramDocumentResponse> = await this.get(
      this.getDocumentPath,
      true,
      getDocumentRequest
    );
    return documentResponse;
  }
}
