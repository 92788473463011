import { TGetAllNotification, TGetInviteNotification } from 'src/services/apiEndpoint.types.ts';

export type TNotificationsInitialState = {
  deviceId: string;
  allNotificationsPageNumber: number;
  allNotificationsItemsPerPage: number;
  allNotificationsMoreItemsLeft: boolean;
  inviteNotificationsPageNumber: number;
  inviteNotificationsItemsPerPage: number;
  inviteNotificationsMoreItemsLeft: boolean;
  allNotifications: TGetAllNotification[];
  inviteNotifications: TGetInviteNotification[];
  ideaHubNotificationCount: number;
  chatNotificationCount: number;
  totalUnreadConversationCount: number;
  totalUnreadMessageCount: number;
  highlightNotificationBell: boolean;
};

export const notificationInitialState: TNotificationsInitialState = {
  deviceId: '',
  allNotificationsPageNumber: 1,
  allNotificationsItemsPerPage: 15,
  allNotificationsMoreItemsLeft: true,
  inviteNotificationsPageNumber: 1,
  inviteNotificationsItemsPerPage: 15,
  inviteNotificationsMoreItemsLeft: true,
  allNotifications: [],
  inviteNotifications: [],
  ideaHubNotificationCount: 0,
  chatNotificationCount: 0,
  totalUnreadConversationCount: 0,
  totalUnreadMessageCount: 0,
  highlightNotificationBell: false
};
