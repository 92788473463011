import cx from './index.module.scss';
import { Col, Dropdown, Modal, Row } from 'react-bootstrap';
import ProgressSlider from 'src/components/common/ProgressSlider/ProgressSlider';
import { MdClose } from 'react-icons/md';
import TextArea from '../formFields/textArea/TextArea';
// import { componentSizeVariantsEnum } from 'src/constants';
import 'bootstrap/dist/css/bootstrap.min.css';
// import { InputField } from '../formFields';

import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import React, { useState } from 'react';
import { httpRequest } from 'src/services/commonApis';
import { useUrlParamValue } from 'src/hooks';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { TRating, TStageData, TStageInput, TStagesDetails } from 'src/components/company/programs/ApplicationRating/applicationRating.types';
import { dotMenu, fileIcon } from 'src/assets/images';
interface propsData {
  show: boolean,
  handleClose: () => void,
  title?: string,
  stage?: TStageData | null,
  type?: string,
  ratingData?: TRating[] | null,
  resourceData?: any[]
}
interface TSubmitForm {
  rating?: string,
  maxRating?: string,
  criteriaName: string,
  comments?: string,
  overallComment?: string
}
export default function BootstrapModal({ show, handleClose, title, stage, type, ratingData, resourceData }: propsData) {
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate()
  const applicationId: string = paramValue({ paramName: 'id' }) as string;
  const programId: string = paramValue({ paramName: 'programId' }) as string
  const mentorEmail: string = paramValue({ paramName: 'mentorEmail' }) as string;
  const [loading, setLoading] = useState<boolean>(false)
  const [errorTrig, setErrorTrig] = useState<boolean>(false);
  const [overallComment, setOverallComment] = useState<string>('')
  const dispatch = useDispatch();
  const [submitForm, setSubmitForm] = useState<TSubmitForm[]>([])
  const handleRating = (e: React.ChangeEvent<HTMLInputElement>, index: number, maxRating: number, criteriaName: string) => {
    const updatedData = [...submitForm];
    updatedData[index] = {
      ...updatedData[index],
      rating: `${e.target.value}`,
      maxRating: `${maxRating}`,
      criteriaName
    }
    setSubmitForm(updatedData)
  }
  console.log(submitForm, 'submitFormDAta');
  const handleFormInput = (e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>, index: number, criteriaName: string) => {
    const fieldName = e.target.id
    const updatedData = [...submitForm];
    updatedData[index] = {
      ...updatedData[index],
      [fieldName]: `${e.target.value}`,
      criteriaName
    }
    setSubmitForm(updatedData)
  }

  async function handleSubmit(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    if (submitForm?.length === 0 || submitForm?.some((item: TSubmitForm) => item?.rating === '' && item?.comments === '')) {
      setErrorTrig(true)
      setTimeout(() => {
        setErrorTrig(false)
      }, 5000)
      return
    }
    const submitData = {
      applicationId,
      programId,
      stageId: `${stage?.id}`,
      mentorEmail,
      overallComment,
      stagesInput: submitForm
    }
    setLoading(true)
    const response = await httpRequest('application/submitEvaluation', 'POST', submitData, 'json', 'program')
    if (response.status) {
      navigate('/success', { replace: true })
      // dispatch(
      //   openPopup({
      //     popupMessage: response.message,
      //     popupType: 'success'
      //   })
      // );
      // setTimeout(() => {
      //   handleClose()
      // }, 2000)
    } else {
      dispatch(
        openPopup({
          popupMessage: response.message,
          popupType: 'error'
        })
      );
    }
    setLoading(false)
  }
  const handleDownload = (url: string) => {
    const link: HTMLAnchorElement | any = document.createElement('a');
    if (link && url) {
      link.href = url;
      link.download = url.split('/').pop(); // Use the file name from the URL
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  return (
    <>
      <Modal
        centered
        show={show}
        backdrop='static'
        keyboard={false}
        onHide={handleClose}
        aria-labelledby='example-modal-sizes-title-lg'
        className={`${cx.modalCts} ${type === 'resources' ? cx.resources : ''}`}
      >
        <Modal.Title>
          {title} {type === 'edit' ? (stage?.stageTitle) : ''}
          <button
            className={`${cx.closeIcon} ${cx.v2}`}
            onClick={handleClose}
          >
            <MdClose />
          </button>
        </Modal.Title>
        <Modal.Body>
          {type === 'edit' && (
            <>
              {stage?.stagesDetails?.map((stageItem: TStagesDetails, i: number) => {
                const dataObj = submitForm[i];
                return (
                  <Row key={i}>
                    <Col md={12}>
                      <div className={`${cx.evaluationForm}`}>
                        <div className={`${cx.ratingBox}`}>
                          <div className={cx.headingContainer}>
                            <label className="form_label" htmlFor="ratingIndex" style={{ fontSize: '16px', fontWeight: '600' }}>{stageItem?.criteriaName}</label>
                            {errorTrig && (submitForm?.length === 0 || submitForm?.some((item: TSubmitForm) => item?.rating === '' && item?.comments === '')) && <ErrorMessage message={'please add rating'}/>}
                          </div>
                        </div>
                        {stageItem?.rating && <div className={cx.ratingContainer}>
                          <div className={`${cx.ratingBox}`}>
                            <div className={cx.headingContainer}>
                              <label className="form_label" htmlFor="ratingIndex">Rating Index</label>
                            </div>
                            <div className={`${cx.progressContainer} m-2`}>
                              <ProgressSlider key={stageItem?.criteriaName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleRating(e, i, stageItem?.rating, stageItem?.criteriaName)} min={0} value={dataObj?.rating ? Number(dataObj?.rating) : 0} max={stageItem?.rating} label="Rating:" />
                            </div>
                          </div>
                        </div>}
                        <Row>
                          <Col md={12}>
                            {stageItem?.comments && <div className={`${cx.commentBox}`}>
                              <div className={cx.headingContainer}>
                                <label className="form_label" htmlFor="comment">Comment Box</label>
                              </div>
                              <div className={cx.textAreaContainer}>
                                <TextArea placeholder='comment' id='comments' onChange={(e) => handleFormInput(e, i, stageItem?.criteriaName)} />
                              </div>
                            </div>}
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                )
              })}
              {stage?.overallComment && (
                <div className={`${cx.evaluationForm}`}>
                  <div className={`${cx.commentBox}`}>
                    <div className={cx.headingContainer}>
                      <label className="form_label" htmlFor="overallComment">Overall Comment Box</label>
                    </div>
                    <div className={cx.textAreaContainer}>
                      <TextArea placeholder='Overall Comment' id='overallComment' onChange={(e) => setOverallComment(e.target.value)} />
                    </div>
                  </div>
                </div>
              )}
              <div className={cx.submitSection}>
                <button onClick={(e) => handleSubmit(e)}>
                  {
                    loading
                      ? (<div
                        className='spinner-border spinner-border-sm text-light'
                        role='status'
                      ></div>)
                      : (
                          'Save Evaluation Criteria'
                        )
                  }
                </button>
              </div>
            </>
          )}
          {type === 'view' && ratingData?.filter((rate: TRating) => rate?.stagesInput && rate?.stagesInput?.length > 0)?.map((rating: TRating, i: number) => {
            return (
              <Row key={i}>
                <div className={cx.viewRatingBox}>
                  <h5>Rate by : {rating?.mentorEmail}</h5>
                </div>
                {rating?.stagesInput?.map((stageItem: TStageInput, r: number) => {
                  return (
                    <div className={`${cx.evaluationForm}`} key={r}>
                      <div className={`${cx.critiriaInputs}`}>
                        <p style={{ fontSize: '16px', fontWeight: '600' }}>{stageItem?.criteriaName || ''}</p>
                      </div>
                      {stageItem?.rating && <div className={cx.ratingContainer}>
                        <div className={`${cx.ratingBox}`}>
                          <div className={`${cx.progressContainer}`}>
                            <ProgressSlider key={stageItem?.criteriaName} disabled value={stageItem?.rating} min={0} max={stageItem?.maxRating ? Number(stageItem?.maxRating) : 0} label="Rating:" />
                          </div>
                        </div>
                      </div>}

                      {stageItem?.comments && <div className={`${cx.commentBox}`}>
                        <div className={cx.headingContainer}>
                          <label className="form_label" htmlFor="comment">Comment Box</label>
                        </div>
                        <div className={`${cx.textAreaContainer} m-0`}>
                          <TextArea placeholder='comment' id='comments' disabled value={stageItem?.comments} />
                        </div>
                      </div>}
                      {stageItem?.overallComment && <div className={`${cx.commentBox}`}>
                        <div className={cx.headingContainer}>
                          <label className="form_label" htmlFor="overallComment">Overall Comment Box</label>
                        </div>
                        <div className={cx.textAreaContainer}>
                          <TextArea placeholder='Overall Comment' id='overallComment' disabled value={stageItem?.overallComment} />
                        </div>
                      </div>}
                    </div>
                  )
                })}
                {rating?.overallComment && (
                  <div className={`${cx.evaluationForm}`}>
                    <div className={cx.headingContainer}>
                      <label className="form_label" htmlFor="overallComment">Overall Comment Box</label>
                    </div>
                    <div className={cx.textAreaContainer}>
                      <TextArea placeholder='Overall Comment' id='overallComment' disabled value={rating?.overallComment} />
                    </div>
                  </div>
                )
                }
              </Row>
            )
          })}

          {type === 'resources' && (
            <Row>
              {resourceData?.map((item: any, r: number) => {
                return (
                  <Col md={4} key={r}>
                    <div className={`${cx.uploadedBox} ${!item?.type?.includes('image') ? cx.document : ''}`}>
                      <img src={item?.type?.includes('image') ? item?.url : fileIcon} className={`${cx.icon} ${!item?.type?.includes('image') ? cx.docs : ''}`} alt="upload-resource" />
                      <div className={cx.dotDropdown}>
                        <Dropdown>
                          <Dropdown.Toggle id="dropdown-basic-6">
                            <img src={dotMenu} alt="dot" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            <Dropdown.Item href="#" onClick={() => handleDownload(item?.url)}>
                              <span className={cx.dotDropInside}>Download</span>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          )}
        </Modal.Body>
      </Modal >
    </>
  );
}
