import { HTMLAttributes, ReactNode } from 'react';
import classNames from 'classnames';

import './dropdownOptionCard.scss';

export type TDropdownOptionCardStates = 'default' | 'hover' | 'active';
export type TDropdownOptionCardSizes = 'small' | 'large';
export type TDropdownOptionCardAlignments = 'left' | 'center' | 'right';

export type TDropdownOptionCardProps = {
  state?: TDropdownOptionCardStates; // The state of the dropdown option.
  size?: TDropdownOptionCardSizes; // The size of the dropdown option.
  alignment?: TDropdownOptionCardAlignments; // The alignment of the content.
  children?: ReactNode; // The content of the dropdown option.
} & HTMLAttributes<HTMLDivElement>;

const DropdownOptionCard = ({
  state = 'default',
  size = 'small',
  alignment = 'left',
  children,
  className,
  ...rest
}: TDropdownOptionCardProps) => {
  return (
    <div
      className={classNames(
        'dropdown-option-card-component',
        `dropdown-option-card-component--${size}`,
        `dropdown-option-card-component--${state}`,
        `dropdown-option-card-component--${alignment}`,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  );
};

export default DropdownOptionCard;
