import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { useTimer } from 'react-timer-hook';
import { useGetIdentity } from 'src/hooks';
import classNames from 'classnames';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import { Button, Modal, Separator, Typography, VerificationCode } from 'src/components/common';
import { TConfirmForgotPasswordRequest } from 'src/services/apiEndpoint.types.ts';
import {
  confirmSettingsForgotPassword,
  pauseResendSignupOtpTimer,
  resendSettingsNewPasswordOtp
} from 'src/store/auth/auth.slice';
import { InputField } from 'src/components/common/formFields';
import { EyeIcon, EyeOffIcon, PasswordIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

import { TForgotPasswordInitialValues, TForgotPasswordProps } from './forgotPassword.types';
import forgotPasswordValidationSchema from './forgotPassword.validationSchema';
import './forgotPassword.scss';

const timerDurationInSeconds = 30;

const time = new Date();
time.setSeconds(time.getSeconds());

const ForgotPassword = ({ isModalOpen, handleCloseModal }: TForgotPasswordProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const { loadingId } = useAppSelector((store) => store.common);
  const { startResendSignupOtpTimer } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();

  // State
  const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
  const [maskPassword, setMaskPassword] = useState<{
    newPassword: boolean;
    confirmPassword: boolean;
  }>({ newPassword: true, confirmPassword: true });
  const [disableResendButton, setDisableResendButton] = useState<boolean>(false);

  // Constants
  const { authenticatedEmail } = getIdentities();

  // Timer
  const { seconds, restart, pause, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      dispatch(pauseResendSignupOtpTimer());
      setDisableResendButton(false);
    }
  });

  const forgotPasswordInitialValues: TForgotPasswordInitialValues = {
    newPassword: '',
    confirmPassword: '',
    verificationCode
  };

  const resendSignupOTPSubmit = async () => {
    // request new OTP email verification
    dispatch(
      resendSettingsNewPasswordOtp({
        email: authenticatedEmail
      })
    );
  };

  const handleForgotPassword = (values: TForgotPasswordInitialValues) => {
    const { newPassword } = values;

    const payload: TConfirmForgotPasswordRequest = {
      email: authenticatedEmail,
      password: newPassword,
      otp: verificationCode.join('')
    };

    dispatch(confirmSettingsForgotPassword(payload));
  };

  useEffect(() => {
    if (startResendSignupOtpTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + timerDurationInSeconds);
      restart(time);
      setDisableResendButton(true);
    }
  }, [startResendSignupOtpTimer]);

  useEffect(() => {
    /*
     ** Stop the timer on initial load
     */
    pause();
  }, []);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="settings-forgot-password-modal"
      title="Forgot password"
    >
      <Formik
        initialValues={forgotPasswordInitialValues}
        validationSchema={forgotPasswordValidationSchema}
        onSubmit={handleForgotPassword}
        enableReinitialize
      >
        {({ errors }) => {
          return (
            <Form className="settings-forgot-password-modal__form">
              <div className="settings-forgot-password-modal__form__verification-code">
                <VerificationCode
                  label="Enter verification code"
                  id="verificationCode"
                  fieldsCount={6}
                  className="settings-forgot-password-modal__form__verification-code__input"
                  value={verificationCode}
                  setVerificationCode={setVerificationCode}
                />{' '}
                {errors?.verificationCode && (
                  <ErrorMessage message={'Please enter the code sent to your email address'} />
                )}
                <div className="settings-forgot-password-modal__form__verification-code__resend-code">
                  <Typography
                    as="span"
                    variant="caption"
                    fontWeight="semiBold"
                    className={classNames(
                      'settings-forgot-password-modal__form__verification-code__resend-code__link',
                      {
                        'settings-forgot-password-modal__form__verification-code__resend-code__link--disabled':
                          disableResendButton
                      }
                    )}
                    onClick={resendSignupOTPSubmit}
                  >
                    Re-send verification code
                  </Typography>

                  {isRunning && (
                    <Typography as="span" variant="caption" fontWeight="semiBold">
                      00:{seconds}
                    </Typography>
                  )}
                </div>
              </div>

              <Separator />

              <InputField
                id="newPassword"
                label="Enter new password"
                placeholder="New password"
                startIcon={<PasswordIcon />}
                variant={componentSizeVariantsEnum.SMALL}
                endIcon={maskPassword.newPassword ? <EyeOffIcon /> : <EyeIcon />}
                endIconClickHandler={() =>
                  setMaskPassword((prev) => ({ ...prev, newPassword: !prev.newPassword }))
                }
                maskField={maskPassword.newPassword}
              />

              <InputField
                id="confirmPassword"
                label="Confirm password"
                placeholder="Confirm password"
                startIcon={<PasswordIcon />}
                variant={componentSizeVariantsEnum.SMALL}
                endIcon={maskPassword.confirmPassword ? <EyeOffIcon /> : <EyeIcon />}
                endIconClickHandler={() =>
                  setMaskPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
                }
                maskField={maskPassword.confirmPassword}
              />

              <div className="settings-forgot-password-modal__form__buttons">
                {' '}
                <Button
                  variant="secondary"
                  size="small"
                  type="button"
                  onClick={handleCloseModal}
                  className="settings-forgot-password-modal__form__buttons__cancel"
                >
                  <Typography as="span" variant="body-2" fontWeight="semiBold">
                    Cancel
                  </Typography>
                </Button>{' '}
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  loading={loadingId === 'settings-forgot-password'}
                  className="settings-forgot-password-modal__form__buttons__submit"
                >
                  <Typography as="span" variant="body-2" fontWeight="semiBold">
                    Update Password
                  </Typography>
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default ForgotPassword;
