import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import classNames from 'classnames';
import { enumToJson, toggleTouchedFields } from 'src/utils';
import { useUpdateFormValue, useValidateErrors } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Modal, SelectMonthYear } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { EmploymentType } from 'src/constants/user.constants';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import Typography from 'src/components/common/typography/Typography';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import Button from 'src/components/button/Button';
import { addExperience, updateExperience } from 'src/store/profile/profile.slice';
import { TUpdateExperienceRequest } from 'src/services/apiEndpoint.types.ts/updateExperience.endpoint.types';

import updateWorkExperienceValidationSchema, {
  updateWorkExperienceValidationEndSchema
} from './updatedWorkExperience.validationSchema';
import {
  updateWorkExperienceErrors,
  updateWorkExperienceFormFields
} from './updateWorkExperience.constants';
import './updateWorkExperience.scss';

import { TCompanyCard } from '../companyCard/CompanyCard';

export type TUpdateWorkExperienceValues = {
  jobTitle: string;
  employmentType: string;
  companyName: string;
  start: string;
  end: string;
  currentlyWorking: boolean;
  description: string;
};

const allFieldsUntouched = toggleTouchedFields({
  fields: updateWorkExperienceFormFields,
  isTouched: false
});
const allFieldsTouched = toggleTouchedFields({
  fields: updateWorkExperienceFormFields,
  isTouched: true
});

export type TUpdateWorkExperience = {
  isModalOpen: boolean; // whether the modal is open
  onModalClose: () => void; // function to run when the modal is closed
  mode: 'Add' | 'Update';
  experienceToUpdate: number; // index of experience to update
  experiences: TCompanyCard[];
};

const UpdateWorkExperience = ({
  experienceToUpdate,
  isModalOpen,
  onModalClose,
  mode = 'Update',
  experiences
}: TUpdateWorkExperience) => {
  const [formValues, setFormValues] = useState<TUpdateWorkExperienceValues>(
    updateWorkExperienceFormFields
  );
  const [touchedFormFields, setTouchedFormFields] =
    useState<Record<keyof TUpdateWorkExperienceValues, boolean>>(allFieldsUntouched);
  const [errors, setErrors] = useState<Record<keyof TUpdateWorkExperienceValues, string>>(
    updateWorkExperienceErrors
  );

  // get month and value from the month/year string
  const [openStartMonthYearModal, setOpenStartMonthYearModal] = useState<boolean>(false);
  const [openEndMonthYearModal, setOpenEndMonthYearModal] = useState<boolean>(false);

  const [startMonth = '01', startYear = `${new Date().getFullYear()}`] = (
    formValues.start || ''
  ).split('/');
  const [endMonth = '01', endYear = `${new Date().getFullYear()}`] = (formValues.end || '').split(
    '/'
  );

  // Hooks
  const { updateFormValue } = useUpdateFormValue();
  const { validateErrors } = useValidateErrors();

  const { profile } = useAppSelector((store) => store.profile);
  const { loading } = useAppSelector((store) => store.common);
  const dispatch = useAppDispatch();

  const updateForm = ({ fieldName, value }: { fieldName: string; value: string | boolean }) => {
    const setFormValue = setFormValues as Dispatch<
      SetStateAction<Record<string, string | boolean>>
    >;
    updateFormValue({ fieldName, value, setFormValue });
  };

  const validateForm = async () => {
    return await validateErrors({
      validationSchema: formValues.currentlyWorking
        ? updateWorkExperienceValidationSchema
        : updateWorkExperienceValidationSchema.concat(updateWorkExperienceValidationEndSchema),
      formValues,
      errorsInitialState: updateWorkExperienceErrors,
      setErrors
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouchedFormFields(allFieldsTouched);

    const validationErrors = await validateForm();

    if (validationErrors?.length) {
      return validateErrors;
    }

    if (!formValues.currentlyWorking && !formValues?.end) {
      setErrors({ ...errors, end: 'Please select the end month and year' })
      return validateErrors;
    }

    const payload: TUpdateExperienceRequest = {
      id: profile.id,
      experience: [...experiences]
    };

    if (mode === 'Add') {
      payload.experience.push({
        jobTitle: formValues.jobTitle,
        employmentType: formValues.employmentType as unknown as string,
        companyName: formValues.companyName,
        start: formValues.start,
        currentlyWorking: formValues.currentlyWorking,
        ...(formValues.description && { description: formValues.description }),
        ...(formValues.end && { end: formValues.end })
      });
      // add experience api call
      dispatch(addExperience(payload));
    } else if (mode === 'Update') {
      payload.experience[experienceToUpdate] = {
        jobTitle: formValues.jobTitle,
        employmentType: formValues.employmentType as unknown as string,
        companyName: formValues.companyName,
        start: formValues.start,
        currentlyWorking: formValues.currentlyWorking,
        ...(formValues.description && { description: formValues.description }),
        ...(formValues.end && { end: formValues.end })
      };

      // update experience api call
      dispatch(updateExperience(payload));
    }
  };

  const employmentOptions: TSelectCardRadioButton[] = enumToJson({ enumData: EmploymentType });

  const closeModal = () => {
    setErrors(updateWorkExperienceErrors);
    setFormValues(updateWorkExperienceFormFields);
    setTouchedFormFields(allFieldsUntouched);
    onModalClose();
  };

  const adjustMonthYearSelector: boolean =
    Boolean(errors?.start && touchedFormFields?.start) ||
    Boolean(errors?.end && touchedFormFields?.end);

  useEffect(() => {
    validateForm();
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (mode === 'Update') {
      const experience = profile.experience[experienceToUpdate];
      setFormValues(experience);
    }

    return () => {
      setFormValues(updateWorkExperienceFormFields);
    };
  }, [isModalOpen, mode]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      title={`${mode === 'Add' ? 'Add' : 'Edit'} Experience`}
      className="update-work-experience-modal"
    >
      <div className="update-work-experience">
        <form onSubmit={handleSubmit} className="update-work-experience__form">
          <div className="update-work-experience__form__fields">
            <div className="update-work-experience__form__fields__row">
              {/*
               ** Name
               */}
              <InputField
                label="Enter Job Title"
                id="jobTitle"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Job Title"
                type="text"
                value={formValues.jobTitle}
                onChange={(e) => {
                  updateForm({ fieldName: 'jobTitle', value: e.target.value });
                }}
                errorMessage={errors.jobTitle}
                isTouched={touchedFormFields.jobTitle}
                required
              />
              {/*
               ** Role
               */}
              <SelectField
                label="Employment Type"
                placeholder="Select your Employment Type"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={formValues.employmentType}
                options={employmentOptions}
                onSelection={({ value }) => {
                  updateForm({ fieldName: 'employmentType', value: value as unknown as string });
                }}
                errorMessage={errors.employmentType}
                isTouched={touchedFormFields.employmentType}
                required
              />
            </div>

            {/*
             ** Company Name
             */}
            <InputField
              label="Enter Company Name"
              id="companyName"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="Company Name"
              type="text"
              value={formValues.companyName}
              onChange={(e) => {
                updateForm({ fieldName: 'companyName', value: e.target.value });
              }}
              errorMessage={errors.companyName}
              isTouched={touchedFormFields.companyName}
              required
            />

            <div className="update-work-experience__form__fields__row">
              {/*
               ** Start Month and Year
               */}
              <div
                className={classNames(
                  'update-work-experience__form__fields__row__start-month-year',
                  {
                    'update-work-experience__form__fields__row__start-month-year--has-error':
                      adjustMonthYearSelector
                  }
                )}
              >
                {' '}
                {/*
                 ** Month and year selection modals
                 */}
                {openStartMonthYearModal && (
                  <SelectMonthYear
                    isOpen={openStartMonthYearModal}
                    onClose={() => {
                      setOpenStartMonthYearModal(false);
                    }}
                    startYear={1930}
                    yearsRange={100}
                    currentMonth={startMonth}
                    currentYear={parseInt(startYear)}
                    onSelection={({ month, year }) => {
                      updateForm({ fieldName: 'start', value: `${month}/${year}` });
                      setOpenStartMonthYearModal(false);
                    }}
                  />
                )}
                <InputField
                  label="Start Month and Year"
                  id="start"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="MM/YYYY"
                  type="text"
                  value={formValues.start}
                  onChange={(e) => {
                    updateForm({ fieldName: 'start', value: e.target.value });
                  }}
                  errorMessage={errors.start}
                  isTouched={touchedFormFields.start}
                  onClick={() => {
                    setOpenStartMonthYearModal(true);
                  }}
                  required
                />
              </div>

              {/*
               ** End Month and Year
               */}
              <div
                className={classNames('update-work-experience__form__fields__end', {
                  'update-work-experience__form__fields__end--has-error': adjustMonthYearSelector
                })}
              >
                {openEndMonthYearModal && (
                  <SelectMonthYear
                    isOpen={openEndMonthYearModal}
                    onClose={() => {
                      setOpenEndMonthYearModal(false);
                    }}
                    startYear={1930}
                    yearsRange={100}
                    currentMonth={endMonth}
                    currentYear={parseInt(endYear)}
                    onSelection={({ month, year }) => {
                      updateForm({ fieldName: 'end', value: `${month}/${year}` });
                      setOpenEndMonthYearModal(false);
                    }}
                  />
                )}
                <InputField
                  label="End Month and Year"
                  id="end"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="MM/YYYY"
                  type="text"
                  value={formValues.end}
                  onChange={(e) => {
                    updateForm({ fieldName: 'end', value: e.target.value });
                  }}
                  errorMessage={errors.end}
                  isTouched={touchedFormFields.end}
                  disabled={formValues.currentlyWorking}
                  onClick={() => {
                    setOpenEndMonthYearModal(true);
                  }}
                  required
                />
                <div className="update-work-experience__form__fields__end__currently-working">
                  <input
                    type="checkbox"
                    checked={formValues.currentlyWorking}
                    onChange={(e) => {
                      updateForm({
                        fieldName: 'currentlyWorking',
                        value: e.target.checked
                      });
                      if (e.target.checked) {
                        updateForm({ fieldName: 'end', value: '' });
                      }
                    }}
                  />
                  <Typography as="span" variant="body-2" fontWeight="regular">
                    I am currently working in this company
                  </Typography>
                </div>
              </div>
            </div>
            {/*
             ** Description
             */}
            <TextAreaField
              label="Description"
              id="Description"
              variant={componentSizeVariantsEnum.SMALL}
              value={formValues.description}
              onChange={(e) => {
                updateForm({ fieldName: 'description', value: e.target.value });
              }}
              errorMessage={errors.description}
            />
          </div>
          <div className="update-work-experience__form__buttons">
            <Button variant="secondary" type="button" size="small" onClick={closeModal}>
              Close
            </Button>

            <Button
              variant="primary"
              type="submit"
              size="small"
              loading={loading}
              disabled={loading}
            >
              {mode === 'Add' ? 'Add' : 'Save Changes'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UpdateWorkExperience;
