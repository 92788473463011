import { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Label from '../label/Label';
import PhoneNumberBox from '../PhoneNumberBox/PhoneNumberBox';
import { TypographyVariant } from '../../typography/typography.types';
import Typography from '../../typography/Typography';
import ErrorMessage from '../errorMessage/ErrorMessage';
import './phoneNumber.scss';

type TPhoneNumberAreaProps = {
  label?: ReactNode;
  id: string;
  variant?: componentSizeVariantsEnum;
  TextAreaFieldClassName?: string;
  textLengthLimit?: number;
  errorMessage?: string;
  countWords?: boolean;
  required?: boolean;
  isTouched?: boolean;
  createProgramStep?: any;
  inputRef?: RefObject<HTMLInputElement>;
  onChange?: (value?: string) => void; // Ensure onChange has the correct type
} & InputHTMLAttributes<HTMLInputElement>;
const PhoneNumberArea = ({
  label,
  id,
  variant = componentSizeVariantsEnum.MEDIUM,
  TextAreaFieldClassName,
  textLengthLimit,
  className,
  value,
  errorMessage,
  countWords = false,
  required = false,
  isTouched = false,
  inputRef,
  createProgramStep,
  ...rest
}: TPhoneNumberAreaProps) => {
  const inputId: string = id || nanoid();

  const textLimitTypographyVariant: Record<componentSizeVariantsEnum, TypographyVariant> = {
    [componentSizeVariantsEnum.SMALL]: 'caption',
    [componentSizeVariantsEnum.MEDIUM]: 'caption',
    [componentSizeVariantsEnum.LARGE]: 'body-2'
  };

  const textAreaValue = (value || '') as string;

  const contentLength = countWords
    ? textAreaValue.trim().split(/\s+/)?.length
    : textAreaValue?.length;

  return (
    <div
      className={classNames(
        className,
        'textarea-field-component',
        `textarea-field-component--${variant}`
      )}
    >
      {label && (
        <Label htmlFor={inputId} variant={variant} required={required}>
          {label}
        </Label>
      )}
      <PhoneNumberBox
        {...rest}
        variant={variant}
        className={TextAreaFieldClassName}
        id={inputId}
        value={value} // Ensure value is passed as string
        inputRef={inputRef}
        createProgramStep={createProgramStep}
      />
      {textLengthLimit && (
        <Typography
          as="p"
          variant={textLimitTypographyVariant[variant]}
          fontWeight="regular"
          className={classNames(
            'textarea-field-component__text-length',
            `textarea-field-component__text-length--${variant}`
          )}
        >
          {contentLength}&#47;{textLengthLimit}
        </Typography>
      )}
      {isTouched && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default PhoneNumberArea;
