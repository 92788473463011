export const updateWorkExperienceFormFields = {
  jobTitle: '',
  employmentType: '',
  companyName: '',
  start: '',
  end: '',
  currentlyWorking: false,
  description: ''
};

export const updateWorkExperienceErrors = {
  jobTitle: '',
  employmentType: '',
  companyName: '',
  start: '',
  end: '',
  currentlyWorking: '',
  description: ''
};
