import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './comment.scss';

import Typography from '../typography/Typography';
import { CommentIcon } from '../common.icons';

export type TComment = {
  count: number;
  isActive?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Comment = ({ count = 0, className, isActive = false, ...rest }: TComment) => {
  return (
    <div
      className={classNames('comment-component', className, {
        'comment-component--is-active': isActive
      })}
      {...rest}
    >
      <CommentIcon />{' '}
      <Typography as="span" variant="body-2" fontWeight="medium">
        {count}
      </Typography>
    </div>
  );
};

export default Comment;
