import { useState } from 'react';
import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import { Modal, SubMenuModal, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import Button from 'src/components/button/Button';
import { SearchIcon } from 'src/components/common/common.icons';
import { useToggleOpenDropdown } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { openPopup } from 'src/store/common/common.slice';

import {
  TCreateEditGroupProps,
  TCreateGroupInitialValues,
  TGroupMember
} from './createEditGroup.types';
import createEditGroupValidationSchema from './createEditGroup.validationSchema';
import './createEditGroup.scss';

import SearchMemberItem from '../searchMemberItem/SearchMembersItem';
import UserInfo from '../userInfo/UserInfo';
import { TIdeaCollaborator } from '../../ideaHub.types';
const createEditIdeaGroupSearchUsersId = 'createEditIdeaGroupSearchUsers';

const CreateEditGroup = ({
  isModalOpen,
  onModalClose,
  mode,
  selectedCollaborators = [],
  onSubmit
}: TCreateEditGroupProps) => {
  // Hooks
  const toggleSearchUsersDropdown = useToggleOpenDropdown();
  const dispatch = useAppDispatch();
  const { dropdownId, loading } = useAppSelector((store) => store.common);
  const viewIdea = useAppSelector((store) => store.ideaHub.viewIdea);

  // State
  const [filterString, setFilterString] = useState<string>('');
  const [selectedMembers, setSelectedMembers] = useState<TGroupMember[]>(selectedCollaborators);

  // Constants
  const openSearchUsersResultDropdownId = dropdownId === createEditIdeaGroupSearchUsersId;
  let ideaCollaborators: TIdeaCollaborator[] = viewIdea?.collaborators;

  try {
    if (mode === 'Add') {
      ideaCollaborators = viewIdea?.collaborators?.filter((item) => {
        if (item?.name.toUpperCase().includes(filterString.toUpperCase())) {
          return true;
        }
        if (item?.email.toUpperCase().includes(filterString.toUpperCase())) {
          return true;
        }
        return false;
      });
    }
  } catch {}

  const toggleOpenSearchUsersDropdown = () => {
    toggleSearchUsersDropdown({ dropdownId: createEditIdeaGroupSearchUsersId });
  };

  const initialValues: TCreateGroupInitialValues = {
    text: '',
    groupName: ''
  };

  const handleSubmit = (values: TCreateGroupInitialValues) => {
    const usersToAdd: string[] = selectedMembers.map((item) => item?.id);

    if (usersToAdd?.length < 1) {
      dispatch(
        openPopup({ popupMessage: 'Please add atleast one user to the group', popupType: 'error' })
      );
    } else {
      onSubmit({ groupName: values?.groupName, usersToAdd });
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedMembers((prev) => {
      const currentList: TGroupMember[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex(
        (member: TGroupMember) => member.id === userId
      );

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectUser = ideaCollaborators.find((member: TGroupMember) => member.id === userId);
        if (selectUser) {
          currentList.push(selectUser);
        }
      }

      return currentList;
    });
  };

  const handleClose = () => {
    setFilterString('');
    setSelectedMembers([]);
    onModalClose();
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleClose}
      title={`${mode} Group`}
      className="ihd-create-group"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={createEditGroupValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue }) => {
          return (
            <Form className="ihd-create-group__form">
              <InputField
                label="Enter Group Name"
                id="groupName"
                placeholder="Group Name"
                startIcon={<SearchIcon />}
                variant={componentSizeVariantsEnum.SMALL}
              />
              <div className="ihd-create-group__form__search-users">
                <InputField
                  label="Select and search users to add in group"
                  id="text"
                  placeholder="Search user by name, email address"
                  variant={componentSizeVariantsEnum.SMALL}
                  onChange={(e) => {
                    const value = e.target.value;

                    setFieldValue('text', value);
                    setFilterString(value);
                  }}
                  startIcon={<SearchIcon />}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleOpenSearchUsersDropdown();
                  }}
                />
                {openSearchUsersResultDropdownId && (
                  <SubMenuModal>
                    <div
                      className="ihd-create-group__form__search-users__container"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Typography as="p" variant="caption" fontWeight="semiBold">
                        Search for “{filterString}”
                      </Typography>
                      <div className="ihd-create-group__form__search-users__container__result">
                        {ideaCollaborators?.length > 0 &&
                          ideaCollaborators.map(
                            (
                              { name = '', role = '', id, profilePic = '' }: TGroupMember,
                              index
                            ) => {
                              const selectIndex = selectedMembers.findIndex(
                                (user) => user.id === id
                              );
                              return (
                                <SearchMemberItem
                                  key={index}
                                  userId={id}
                                  profilePic={profilePic}
                                  name={name}
                                  role={role}
                                  updateSelectedUsers={updateSelectedUsers}
                                  isUserSelected={selectIndex > -1}
                                />
                              );
                            }
                          )}
                      </div>
                    </div>
                  </SubMenuModal>
                )}
              </div>

              {mode === 'Edit' && (
                <Typography
                  as="p"
                  variant="caption"
                  fontWeight="regular"
                  style={{ color: colors?.neutral?.[1100] }}
                >
                  You can manage the group member adding or removing the user.
                </Typography>
              )}
              <div
                className={classNames(
                  'ihd-create-group__form__users',
                  `ihd-create-group__form__users--${mode}`
                )}
              >
                {selectedMembers.map((member, index) => {
                  return (
                    <UserInfo
                      key={index}
                      {...member}
                      showRemoveButton
                      handleRemoveUser={() => updateSelectedUsers({ userId: member?.id })}
                    />
                  );
                })}
              </div>
              <div className="ihd-create-group__form__buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleClose}
                  className="ihd-create-group__form__buttons__close"
                >
                  Close
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  className="ihd-create-group__form__buttons__create-group"
                  loading={loading}
                >
                  {mode === 'Add' ? 'Create Group' : 'Save Changes'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateEditGroup;
