import { RefObject, TextareaHTMLAttributes } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';

import './textArea.scss';

type TTextAreaProps = {
  variant?: componentSizeVariantsEnum;
  inputRef?: RefObject<HTMLTextAreaElement>;
  createProgramStep?: any;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

/**
 * Props for the TextArea component.
 * @typedef {Object} TTextAreaProps
 * @param {componentSizeVariantsEnum} [variant] - The variant of the text area.
 * @param {TextareaHTMLAttributes<HTMLTextAreaElement>} [HTMLTextAreaElement] - HTML attributes of the text area element.
 */
const TextArea = ({
  className,
  value,
  disabled,
  variant = componentSizeVariantsEnum.MEDIUM,
  inputRef,
  createProgramStep,
  ...rest
}: TTextAreaProps) => {
  console.log(createProgramStep, 'createProgramStepddd');

  const dynamicStyles = createProgramStep ? { maxHeight: '48px' } : {};

  return (
    <div
      className={classNames(className, 'textarea-component', {
        'textarea-component--disabled': disabled
      })}
      style={dynamicStyles}
    >
      <textarea
        {...rest}
        value={value}
        disabled={disabled}
        className={classNames(
          className,
          'textarea-component__textarea',
          `textarea-component__textarea--${variant}`
        )}
        ref={inputRef}
      />
    </div>
  );
};

export default TextArea;
