import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Role, TUserTypes } from 'src/constants/user.constants';
import { TCompany } from 'src/services/apiEndpoint.types.ts/signIn.endpoint.types';
import { useAppSelector } from 'src/store/hooks';
import { StorageUtils } from 'src/utils';

/*
 * This hook forces user to create a company when required in accordance with business requirements
 */
const useForceCreateCompany = () => {
  const { isAuthorized, isAuthorizationCheckInProgress } = useAppSelector((store) => store.auth);
  const { myCompany } = useAppSelector((store) => store.company);
  const profile = useAppSelector((store) => store.profile.profile);

  const navigate = useNavigate();

  const redirectUserToCreateCompany = () => {
    if (!isAuthorizationCheckInProgress && isAuthorized) {
      const loggedInUserType = StorageUtils.get('userType') as TUserTypes;
      const loggedInCompany = StorageUtils.get('loggedInCompany') as TCompany;

      if (loggedInUserType === 'Company' && !myCompany.type) {
        navigate('/create-company');
      } else if (
        loggedInUserType === 'User' &&
        profile?.role === Role.SERVICE_PROVIDERS_STARTUP &&
        !loggedInCompany
      ) {
        navigate('/create-company');
      }
    }
  };

  useEffect(() => {
    redirectUserToCreateCompany();
  }, [
    isAuthorizationCheckInProgress,
    isAuthorized,
    window.location.pathname,
    JSON.stringify(JSON.stringify(myCompany)),
    JSON.stringify(JSON.stringify(profile))
  ]);
};

export default useForceCreateCompany;
