import React from 'react';
import { aboutHTraction } from 'src/assets/images';
import Typography from 'src/components/common/typography/Typography';

const AboutHTraction: React.FC = () => {
  return (
    <div className="landing-page__about">
      <div className="landing-page__about__content">
        <Typography
          as="h2"
          variant="heading-2"
          fontWeight="semiBold"
          className="landing-page__about__content__heading"
        >
          About{' '}
          <Typography
            as="span"
            variant="heading-2"
            fontWeight="semiBold"
            className="landing-page__about__content__heading--highlight"
          >
            htraction
          </Typography>
        </Typography>

        <div className="landing-page__about__content__description">
          <Typography as="p" variant="body-1" fontWeight="regular">
            htraction is an integrated platform designed to assist aspiring entrepreneurs in
            transforming their thought(s) &raquo; idea(s) &raquo; venture(s).
          </Typography>

          <Typography as="p" variant="body-1" fontWeight="regular">
            We are on a mission to revolutionize the entrepreneurial journey, where aspiring
            entrepreneurs, seasoned mentors, visionary investors, and innovative incubators
            converge.
          </Typography>

          <Typography as="p" variant="body-1" fontWeight="regular">
            Together, we&apos;re not just building businesses; we&apos;re crafting a future where
            every entrepreneurial vision has the support it needs to thrive and impact the world.{' '}
          </Typography>
        </div>
      </div>
      <div className="landing-page__about__banner">
        <img src={aboutHTraction} alt="about htraction banner" />
      </div>
    </div>
  );
};

export default AboutHTraction;
