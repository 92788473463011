import { FC, useEffect } from 'react';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Tooltip, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { colors } from 'src/constants';
import { textEllipsis, timeAgo } from 'src/utils';
import { useAppDispatch } from 'src/store/hooks';
import { resetMessages, updateOneToOneRecipientUser } from 'src/store/chat/chat.slice';
import { TUserTypes } from 'src/constants/user.constants';

import { TChatListUserCardProps } from './chatListUserCard.types';
import './chatListUserCard.scss';

const ChatListUserCard: FC<TChatListUserCardProps> = ({
  selectedUserId,
  selectedUserType,
  ...user
}: TChatListUserCardProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const userDetail = user?.Users?.[0];
  const companyDetail = user?.Companies?.[0];
  // Constantsj

  const userId: any = userDetail?.id ? userDetail?.id : companyDetail?.id;
  const userType: TUserTypes = userDetail?.id ? 'User' : 'Company';
  const isUserSelected = userId === selectedUserId && selectedUserType === userType;

  const id: string = userType === 'User' ? userDetail?.id : companyDetail?.id;
  const name: string = userType === 'User' ? userDetail?.name : companyDetail?.name;
  const role: string = userType === 'User' ? userDetail?.role : companyDetail?.type;
  const profilePic: string = userType === 'User' ? userDetail?.profilePic : companyDetail?.logo;

  const nameWithEllipsis = textEllipsis({ charLimit: 12, text: name });

  const handleOpenChatWindow = () => {
    dispatch(resetMessages());
    const path = `${window.location.pathname}?conversationId=${user?.id}&userType=${userType}&userId=${userId}`;

    navigate(path);
  };

  const updateSelectedUser = () => {
    const selectedUser = {
      id,
      name,
      role,
      profilePic
    };

    dispatch(updateOneToOneRecipientUser(selectedUser));
  };

  useEffect(() => {
    if (isUserSelected) {
      updateSelectedUser();
    }
  }, [selectedUserId]);

  return (
    <div
      className={classNames('chat-list-user-card', {
        'chat-list-user-card--is-selected': isUserSelected
      })}
      onClick={handleOpenChatWindow}
    >
      <div className="chat-list-user-card__left">
        <ProfilePicture url={profilePic} fallback={name?.slice(0, 1)} />
        <div className="chat-list-user-card__left__user-details">
          <Tooltip text={name} conditionToDisplay={nameWithEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="p"
              variant="body-2"
              fontWeight="medium"
              style={{ color: colors?.neutral?.[1100] }}
            >
              {nameWithEllipsis?.text}
            </Typography>
          </Tooltip>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            style={{ color: colors?.neutral?.[900] }}
          >
            {role}
          </Typography>
        </div>
      </div>
      <div className="chat-list-user-card__right">
        <Typography
          as="span"
          variant="caption"
          fontWeight="semiBold"
          className={classNames('chat-list-user-card__right__unread-count', {
            'chat-list-user-card__right__unread-count--is-hidden': user?.unreadMessageCount < 1
          })}
        >
          {user?.unreadMessageCount}
        </Typography>
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          style={{ color: colors?.neutral?.[700] }}
          className="chat-list-user-card__right__updated-at"
        >
          {timeAgo({ timestamp: user?.updatedAt })}
        </Typography>
      </div>
    </div>
  );
};

export default ChatListUserCard;
