import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const EmojiIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.33398 8.89844C5.33398 8.89844 6.33398 10.2318 8.00065 10.2318C9.66732 10.2318 10.6673 8.89844 10.6673 8.89844M10.0007 5.5651H10.0073M6.00065 5.5651H6.00732M14.6673 7.5651C14.6673 11.247 11.6826 14.2318 8.00065 14.2318C4.31875 14.2318 1.33398 11.247 1.33398 7.5651C1.33398 3.88321 4.31875 0.898438 8.00065 0.898438C11.6826 0.898438 14.6673 3.88321 14.6673 7.5651ZM10.334 5.5651C10.334 5.7492 10.1847 5.89844 10.0007 5.89844C9.81656 5.89844 9.66732 5.7492 9.66732 5.5651C9.66732 5.38101 9.81656 5.23177 10.0007 5.23177C10.1847 5.23177 10.334 5.38101 10.334 5.5651ZM6.33398 5.5651C6.33398 5.7492 6.18475 5.89844 6.00065 5.89844C5.81656 5.89844 5.66732 5.7492 5.66732 5.5651C5.66732 5.38101 5.81656 5.23177 6.00065 5.23177C6.18475 5.23177 6.33398 5.38101 6.33398 5.5651Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EmojiIcon;
