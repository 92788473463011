import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { hTractionLogoWithText, userOnboardingBanner } from 'src/assets/images';

import '../authLayout/authLayout.scss';

const UserOnboardingLayout: FC = () => {
  return (
    <div className="auth-layout">
      <div className="auth-layout__content">
        <div className="auth-layout__content__logo">
          <img src={hTractionLogoWithText} alt="htraction logo" />
        </div>
        <div className="auth-layout__content__form">
          <Outlet />
        </div>
      </div>
      <img
        className="auth-layout__banner"
        src={userOnboardingBanner}
        alt="user onboarding banner"
      />
    </div>
  );
};

export default UserOnboardingLayout;
