import React, { useState, useEffect, RefObject, TextareaHTMLAttributes, ChangeEvent } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { usePlacesWidget } from 'react-google-autocomplete';
import './addressBox.scss';

type TAddressBoxProps = {
  variant?: componentSizeVariantsEnum;
  inputRef?: RefObject<HTMLTextAreaElement>;
  createProgramStep?: any;
} & TextareaHTMLAttributes<HTMLTextAreaElement>;

const AddressBox = ({
  className,
  value,
  disabled,
  variant = componentSizeVariantsEnum.MEDIUM,
  inputRef,
  createProgramStep,
  ...rest
}: TAddressBoxProps) => {
  const [locationName, setLocationName] = useState<string>((value as string) || '');
  const [formData, setFormData] = useState({ city: '', officeLocation: [0, 0] });

  const handlePlaceSelected = (place: any) => {
    const formattedAddress = place?.formatted_address || '';
    setLocationName(formattedAddress);
    // Update parent component's form data if necessary
    if (rest.onChange) {
      rest.onChange({
        ...event,
        target: {
          ...event?.target,
          value: formattedAddress
        }
      } as any);
    }
  };

  const { ref } = usePlacesWidget<any>({
    apiKey: 'AIzaSyDQWdKXQ-8bh9GGLAj5Dc-hrJixxqQlRRg',
    libraries: ['places'],
    language: 'en',
    onPlaceSelected: handlePlaceSelected,
    options: {
      types: ['address']
    }
  });

  useEffect(() => {
    setLocationName(value as string);
  }, [value]);

  useEffect(() => {
    if (locationName) {
      fetch(`https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(locationName)}&format=json`)
        .then(response => response.json())
        .then(data => {
          if (data.length > 0) {
            const { lat, lon } = data[0];
            setFormData({
              ...formData,
              officeLocation: [parseFloat(lat), parseFloat(lon)]
            });
          } else {
            console.log('Location not found');
          }
        })
        .catch(error => console.error('Error fetching location:', error));
    }
  }, [locationName]);

  const handleLocationChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setLocationName(event.target.value);
    if (rest.onChange) {
      rest.onChange(event);
    }
  };

  return (
    <div
    className={classNames(className, 'textarea-component', {
      'textarea-component--disabled': disabled
    })}
      style={{ width: '100%', maxHeight: '50px' }}
    >
      <textarea
        {...rest}
        value={locationName}
        disabled={disabled}
        className={classNames(
          className,
          'textarea-component__textarea',
          `textarea-component__textarea--${variant}`
        )}
        style={{ width: '100%', maxHeight: '45px' }}
        ref={ref as any}
        onChange={handleLocationChange}
      />
    </div>
  );
};

export default AddressBox;
