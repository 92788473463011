/* eslint-disable multiline-ternary */

import { useMemo, useRef, useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import classNames from 'classnames';
import EmojiPicker from 'emoji-picker-react';
import { v4 as uuidv4 } from 'uuid';
import { Loader, Modal, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import Button from 'src/components/button/Button';
import { PictureIcon, VideoIcon, EmojiIcon, TrashIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { StorageUtils, textFormatter } from 'src/utils';
import {
  TCreatePostRequest,
  TDeleteMediaActionRequest,
  TEditPostRequest,
  TGetPostPresignedUrlActionRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  TCreatePostMedia,
  clearPostMedia,
  createPost,
  deleteMedia,
  editPost,
  getPostPresignedUrl
} from 'src/store/socialMedia/socialMedia.slice';
import { useUploadMedia } from 'src/hooks';
import Label from 'src/components/common/formFields/label/Label';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { TPostType, componentSizeVariantsEnum } from 'src/constants';
import { TUserTypes } from 'src/constants/user.constants';
import { TMediaType } from 'src/hooks/useUploadMedia/useUploadMedia.types';

import './createEditPostModal.scss';
import createEditPostModalValidationSchema, {
  createEditAnnouncementModalValidationSchema
} from './createEditPostModal.validationSchema';

export type TCreateEditPostModal = {
  type?: TPostType;
  companyProfile: boolean;
  title: string;
  isModalOpen: boolean;
  onModalClose: () => void;
  postDescription?: string;
  editMode?: boolean;
  postText?: string;
  postTitle?: string;
  postCustomButton?: string;
  postRedirectUrl?: string;
  editPostId?: string;
  refetchPost?: () => void;
  viewerId: string;
  viewerType: TUserTypes;
  shareProgramMode?: boolean;
};

export type TCreateEditPostModalFormValues = {
  text: string;
  title: string;
  customButton: string;
  redirectUrl: string;
};

const CreateEditPostModal = ({
  type = 'post',
  companyProfile = false,
  title,
  isModalOpen,
  onModalClose,
  postText = '',
  postTitle = '',
  postCustomButton = '',
  postRedirectUrl = '',
  editMode,
  editPostId = '',
  refetchPost,
  shareProgramMode = false
}: TCreateEditPostModal) => {
  const initialValues: TCreateEditPostModalFormValues = {
    text: postText || '',
    title: postTitle || '',
    customButton: postCustomButton || '',
    redirectUrl: postRedirectUrl || ''
  };

  const [isEmojiPickerOpen, setIsEmojiPickerOpen] = useState<boolean>(false);
  const [postType, setPostType] = useState<TPostType>(type);
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const uploadVideoRef = useRef<HTMLInputElement>(null);
  const postDescriptionRef = useRef<HTMLTextAreaElement>(null);

  const { profile } = useAppSelector((store) => store.profile);
  const { myCompany, companyProfilePic } = useAppSelector((store) => store.company);
  const loading = useAppSelector((store) => store.common.loading);
  const { createPostMedia, isMediaUploading } = useAppSelector((store) => store.socialMedia);

  const dispatch = useAppDispatch();
  const { handleUploadMedia } = useUploadMedia();
  const userType = StorageUtils.get('userType') as 'User' | 'Company';
  const companyId = StorageUtils.get('companyId') as string;

  const isAnnouncement = Boolean(postType === 'announcement');
  const postCreatorName: string = companyProfile ? myCompany?.name : profile?.name;
  const postCreatorProfilePic: string = companyProfile ? companyProfilePic : profile.profilePic;

  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const openVideoFileManager = async () => {
    if (uploadVideoRef.current) {
      uploadVideoRef.current.click();
    }
  };

  const removeMedia = (mediaString: string) => {
    const extension = mediaString.split('.').pop() || '';

    const payload: TDeleteMediaActionRequest = {
      email: userType === 'User' ? profile?.email : myCompany?.email,
      id: mediaString,
      postId: editPostId,
      extension,
      userType,
      mediaType: 'image'
    };
    dispatch(deleteMedia(payload));
  };

  const newPostId = useMemo(() => {
    return uuidv4();
  }, [isModalOpen]);

  const onMediaLoad = (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: TMediaType
  ) => {
    const payload: TGetPostPresignedUrlActionRequest = {
      email: userType === 'User' ? profile?.email : myCompany?.email,
      id: uuidv4(),
      postId: newPostId,
      extension: fileType,
      userType,
      imageData: data,
      mediaType
    };
    dispatch(getPostPresignedUrl(payload));
  };

  const handleSubmit = (
    { text, title, customButton, redirectUrl }: TCreateEditPostModalFormValues,
    { setFieldError }: FormikHelpers<TCreateEditPostModalFormValues>
  ) => {
    if (!text && !isAnnouncement && createPostMedia?.length === 0) {
      setFieldError('text', 'Please enter description');
      return undefined;
    }
    if (editMode) {
      const payload: TEditPostRequest = {
        userId: companyProfile ? companyId : profile.id,
        userType: companyProfile ? 'Company' : userType,
        postId: editPostId,
        urls: createPostMedia,
        isAnnouncement,
        newText: text || ' ',
        ...(isAnnouncement && {
          announcement: {
            title,
            ...(customButton && { customButton }),
            ...(redirectUrl && { redirectUrl })
          }
        })
      };

      dispatch(editPost(payload)).then(() => {
        if (refetchPost) {
          refetchPost();
        }
      });
    } else {
      const payload: TCreatePostRequest = {
        userId: companyProfile ? companyId : profile.id,
        userType: companyProfile ? 'Company' : userType,
        postId: newPostId,
        isAnnouncement,
        timestamp: Date.now(),
        text: text || ' ',
        ...(createPostMedia?.length > 0 && { urls: createPostMedia }),
        ...(isAnnouncement && {
          announcement: {
            title,
            ...(customButton && { customButton }),
            ...(redirectUrl && { redirectUrl })
          }
        })
      };

      dispatch(createPost(payload)).then(() => {
        if (refetchPost) {
          refetchPost();
        }
      });
    }
  };

  const handleCloseModal = () => {
    if (isMediaUploading === 0) {
      dispatch(clearPostMedia());
      onModalClose();
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      title={title}
      className="create-edit-post-modal"
    >
      <div className="create-edit-post-modal__content__header">
        <ProfilePicture
          url={postCreatorProfilePic || ''}
          fallback={textFormatter({
            value: postCreatorName[0]
          })}
        />
        <Typography as="span" variant="body-1" fontWeight="semiBold">
          {textFormatter({ value: postCreatorName })}
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        validationSchema={
          isAnnouncement
            ? createEditAnnouncementModalValidationSchema
            : createEditPostModalValidationSchema
        }
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, setFieldValue, touched, values }) => {
          return (
            <Form noValidate className="create-edit-post-modal__content__form">
              {companyProfile && (
                <>
                  <Typography as="p" variant="body-2" fontWeight="semiBold">
                    Select post type
                  </Typography>
                  <div className="create-edit-post-modal__content__post-type">
                    <div
                      className={classNames('create-edit-post-modal__content__post-type__field', {
                        'create-edit-post-modal__content__post-type__field--is-disabled':
                          shareProgramMode
                      })}
                    >
                      <input
                        id="post"
                        type="radio"
                        name="postType"
                        onChange={() => {
                          setFieldValue('text', '');
                          setPostType('post');
                        }}
                        checked={postType === 'post'}
                        disabled={shareProgramMode}
                      />
                      <span className="custom-radio"></span>
                      <Label htmlFor="post">Regular Post</Label>
                    </div>
                    <div className="create-edit-post-modal__content__post-type__field">
                      <input
                        id="announcement"
                        type="radio"
                        name="postType"
                        onChange={() => {
                          setFieldValue('text', '');
                          setPostType('announcement');
                        }}
                        checked={postType === 'announcement'}
                      />
                      <span className="custom-radio"></span>
                      <Label htmlFor="announcement">Announcement</Label>
                    </div>
                  </div>
                </>
              )}
              {isAnnouncement && (
                <>
                  {/* Announcement title */}
                  <InputField
                    label="Enter Announcement Title"
                    id="title"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Announcement title"
                    type="text"
                    required
                  />

                  <div className="create-edit-post-modal__content__form__button-fields">
                    {/* Custom button text */}
                    <InputField
                      label="Enter the custom button Text"
                      id="customButton"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Ex. Apply here"
                      type="text"
                    />
                    {/* Redirect url */}
                    <InputField
                      label="Enter the redirect URL"
                      id="redirectUrl"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="www.example.com"
                      type="text"
                      disabled={shareProgramMode || !values?.customButton}
                    />
                  </div>
                </>
              )}

              <TextAreaField
                id="text"
                label=""
                placeholder="Description"
                onChange={(e) => {
                  setFieldValue('text', e.target.value);
                }}
                value={values.text}
                errorMessage={errors.text}
                isTouched={touched.text}
                required
                inputRef={postDescriptionRef}
              />
              <input
                type="file"
                hidden
                data-testid="inputUploadImage"
                ref={uploadImageRef}
                accept=".bmp,.jpg,.jpeg,.png"
                className="select-post-image"
                multiple
                onChange={(e) => {
                  handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'image' });
                }}
              />
              <input
                type="file"
                hidden
                data-testid="inputUploadVideo"
                ref={uploadVideoRef}
                accept=".mp4"
                className="select-post-video"
                multiple
                onChange={(e) => {
                  handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'video' });
                }}
              />
              <div className="create-edit-post-modal__content__actions">
                <div
                  className="create-edit-post-modal__content__actions__action"
                  onClick={openImageFileManager}
                >
                  <PictureIcon />
                </div>
                <div
                  className="create-edit-post-modal__content__actions__action"
                  onClick={openVideoFileManager}
                >
                  <VideoIcon />
                </div>
                <div
                  className="create-edit-post-modal__content__actions__action"
                  onClick={() => setIsEmojiPickerOpen((prev) => !prev)}
                >
                  <EmojiIcon />
                </div>
              </div>
              <div className="create-edit-post-modal__content__media">
                {createPostMedia.map(({ type, key }: TCreatePostMedia, index) => {
                  return (
                    <div key={index} className="create-edit-post-modal__content__media__item">
                      {type === 'image' ? (
                        <img src={key} alt="selected media" />
                      ) : (
                        <video
                          src={key}
                          width="130"
                          height="130"
                          controls
                          poster="post vide thumbnail"
                        />
                      )}
                      <div
                        className="create-edit-post-modal__content__media__item__delete"
                        onClick={() => removeMedia(key)}
                      >
                        <TrashIcon />
                      </div>
                    </div>
                  );
                })}

                {isMediaUploading > 0 && (
                  <div
                    className={classNames('create-edit-post-modal__content__media__item', {
                      'create-edit-post-modal__content__media__item--is-uploading': isMediaUploading
                    })}
                  >
                    <Loader />
                  </div>
                )}
              </div>
              <div className="create-edit-post-modal__content__buttons">
                <Button
                  variant="secondary"
                  size="small"
                  onClick={handleCloseModal}
                  disabled={isMediaUploading > 0}
                >
                  Cancel
                </Button>
                <Button
                  loading={loading}
                  variant="primary"
                  size="small"
                  type="submit"
                  disabled={isMediaUploading > 0 || loading}
                >
                  {editMode ? 'Save' : 'Post'}
                </Button>
              </div>
              <EmojiPicker
                open={isEmojiPickerOpen}
                onEmojiClick={(e) => {
                  const postDescriptionEle = postDescriptionRef?.current;
                  if (postDescriptionEle) {
                    const startPos = postDescriptionEle?.selectionStart;

                    const textBeforeCursor = values?.text.substring(0, startPos);
                    const textAfterCursor = values?.text.substring(startPos);

                    setFieldValue('text', textBeforeCursor + e.emoji + textAfterCursor);
                  } else {
                    setFieldValue('text', values.text + e.emoji);
                  }
                  setIsEmojiPickerOpen(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default CreateEditPostModal;
