import { useState } from 'react';
import classNames from 'classnames';
import { Card } from 'src/components/common';

import { TEditMessageSettings, TGroupChatWindowProps } from './groupChatWindow.types';
import './groupChatWindow.scss';
import useGetGroupParticipants from './useGetGroupParticipants.hook';
import IdeaDiscussionGroupHeader from './ideaDiscussionGroupHeader/IdeaDiscussionGroupHeader';
import ChatMessagesBody from './chatMessagesBody/ChatMessagesBody';
import ChatMessagesFooter from './chatMessagesFooter/ChatMessagesFooter';

const GroupChatWindow = ({
  discussionType,
  groupId,
  headerDropdownOptions,
  ideaId,
  isViewerOwner
}: TGroupChatWindowProps) => {
  // Hooks
  useGetGroupParticipants({ groupId });
  // State
  const [editMessageSettings, setEditMessageSettings] = useState<TEditMessageSettings>({
    editMode: false,
    messageId: '',
    message: ''
  });

  // Constants
  const placeholder = discussionType === 'ideaGroup' ? 'Message...' : 'Message...';
  const isChatActive = Boolean(groupId);

  const updateMessageSettings = ({ editMode, messageId, message }: TEditMessageSettings) => {
    setEditMessageSettings({ editMode, messageId, message });
  };

  return (
    <Card
      className={classNames('discussion-messages', {
        'discussion-messages--is-hidden': !isChatActive
      })}
    >
      <IdeaDiscussionGroupHeader
        discussionType={discussionType}
        groupId={groupId}
        headerDropdownOptions={headerDropdownOptions}
        ideaId={ideaId}
        isViewerOwner={isViewerOwner}
      />
      <ChatMessagesBody
        groupId={groupId}
        updateMessageSettings={updateMessageSettings}
        groupMessageContext="idea"
      />
      <ChatMessagesFooter
        placeholder={placeholder}
        groupId={groupId}
        editMessageSettings={editMessageSettings}
        updateMessageSettings={updateMessageSettings}
        groupMessageContext="idea"
      />
    </Card>
  );
};

export default GroupChatWindow;
