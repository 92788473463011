import { useMemo, useState } from 'react';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';

import useDebounce from '../useDebounce/useDebounce';

export type TUseGetPlaces = {
  types: string[];
};

export type TGetPlaces = {
  input: string;
};

export type TPlacePrediction = {
  // This type omits keys such as place_id, terms etc
  // Update as per the requirement
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
  types: string[];
};

const useGetPlaces = ({ types }: TUseGetPlaces) => {
  const [query, setQuery] = useState<string>('');

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    options: { types }
  });

  const getPlaces = ({ input }: TGetPlaces) => {
    setQuery(input);
  };

  const startSearch = () => {
    getPlacePredictions({ input: query });
  };

  useDebounce({ func: startSearch, delay: 500, dependency: query });

  const places = useMemo(() => {
    const placesResponse = JSON.parse(JSON.stringify(placePredictions)) as TPlacePrediction[];

    const placesFormatted: TSelectCardRadioButton[] = placesResponse.map(
      (cityResponse: TPlacePrediction) => {
        const cityName = cityResponse?.structured_formatting?.main_text;

        return { label: cityName, value: cityName, id: cityName };
      }
    );

    return placesFormatted;
  }, [JSON.stringify(placePredictions)]);

  return { getPlaces, places, isPlacePredictionsLoading };
};

export default useGetPlaces;
