import Footer from 'src/components/footer/footer';

import TopNavigation from './topNavigation/TopNavigation';
import HeroSection from './heroSection/HeroSection';
import AboutHTraction from './aboutHTraction/AboutHTraction';
import KeyFeatures from './keyFeatures/KeyFeatures';
import WhoIsHTractionFor from './whoIsHTractionFor/WhoIsHTractionFor';
import './landingPage.scss';
import { useEffect } from 'react';
import { useAppSelector } from 'src/store/hooks';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const navigate = useNavigate()
  const isAuthorized: boolean = useAppSelector((store) => store.auth.isAuthorized);

  const redirectIfAuthorized = () => {
    if (isAuthorized) {
      navigate('/dashboard');
    }
  };
  useEffect(() => {
    redirectIfAuthorized();
  }, [isAuthorized]);
  return (
    <div className="landing-page">
      {/* Top Nav */}
      <TopNavigation />

      {/* Hero Section */}
      <HeroSection />
      {/* Key features */}
      <KeyFeatures />

      {/* Who is hTraction for */}
      <WhoIsHTractionFor />
      {/* About hTracton */}
      <AboutHTraction />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default LandingPage;
