import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ShareIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="21"
      height="19"
      viewBox="0 0 21 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M19.2914 10.4207C19.5355 10.2114 19.6575 10.1068 19.7023 9.98232C19.7415 9.87304 19.7415 9.75352 19.7023 9.64424C19.6575 9.51974 19.5355 9.41512 19.2914 9.20587L10.8206 1.94525C10.4004 1.58506 10.1903 1.40496 10.0124 1.40054C9.8578 1.39671 9.71012 1.46463 9.61242 1.58451C9.5 1.72245 9.5 1.99918 9.5 2.55266V6.84791C7.36532 7.22137 5.41159 8.30304 3.95971 9.92715C2.37682 11.6978 1.50123 13.9892 1.5 16.3642V16.9762C2.54934 15.7121 3.85951 14.6898 5.34076 13.9792C6.6467 13.3527 8.05842 12.9816 9.5 12.8838V17.0739C9.5 17.6274 9.5 17.9041 9.61242 18.0421C9.71012 18.1619 9.8578 18.2299 10.0124 18.226C10.1903 18.2216 10.4004 18.0415 10.8206 17.6813L19.2914 10.4207Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ShareIcon;
