import './applyProgramProgramDetails.scss';

import { ProgramDetails } from '../../program';

const ApplyProgramProgramDetails = ({ type = '' }: { type?: string }) => {
  return (
    <div className="apply-program-program-details">
      <ProgramDetails type={type} />
    </div>
  );
};

export default ApplyProgramProgramDetails;
