import * as Yup from 'yup';

export const applicationEmailVerificationValidationSchema = Yup.object().shape({
  otp: Yup.array()
    .of(Yup.string())
    .test(
      'is-six-characters',
      'Please enter the code sent to your email address',
      (value) => value?.join('').length === 6
    )
});

export default applicationEmailVerificationValidationSchema;
