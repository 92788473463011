import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from 'src/components/common/typography/Typography';
import CreatePost from 'src/components/posts/createPost/CreatePost';
import { TDropdownOptions } from 'src/components/common/dropdown/Dropdown';
import EditPost from 'src/components/posts/editPost/EditPost';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import {
  clearNewsFeed,
  deletePost,
  getNewsFeedOwnPopular,
  getNewsFeedSavedPosts,
  getNewsFeedSelfPosted
} from 'src/store/socialMedia/socialMedia.slice';
import { StorageUtils } from 'src/utils';
import { useCopyToClipboard, useGetIdentity } from 'src/hooks';
import {
  TDeletePostActionRequest,
  TGetNewsFeedRequest,
  TPost
} from 'src/services/apiEndpoint.types.ts';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import { TUserTypes } from 'src/constants/user.constants';

import './activities.scss';

import FilterPosts, { TPostFilterOptions, TPostFilters } from '../../posts/filterPosts/FilterPosts';

const editPostModalId = 'edit-post-modal-activities';

export type TActivities = {
  userId: string;
  viewerId: string;
  viewerType: TUserTypes;
};

const Activities = ({ userId, viewerId, viewerType }: TActivities) => {
  const [filter, setFilter] = useState<TPostFilters>('latest');
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [selectedPostForEdit, setSelectedPostForEdit] = useState<TPost | null>(null);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { copyToClipboard } = useCopyToClipboard();

  const { modalId, loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { profile } = useAppSelector((store) => store.profile);
  const { newsFeed, newsFeedItemsPerPage, newsFeedPageNumber } = useAppSelector(
    (store) => store.socialMedia
  );

  const { loggedInUserType, authenticatedId } = getIdentities();

  const redirectToViewPost = ({ postId, ownerId }: { postId: string; ownerId: string }) => {
    const isOwner = ownerId === profile.id;

    const postRoute = isOwner ? 'view-post-owned' : 'view-post';
    navigate(`/${postRoute}?postId=${postId}&prev=${window.location.pathname}?id=${userId}`);
  };

  const isModalOpen = modalId === editPostModalId;

  const copyPostLink = (postId: string) => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const openCreatePostModal = (post: TPost) => {
    setSelectedPostForEdit(post);
    dispatch(assignModalId(editPostModalId));
  };

  const closeCreatePostModal = () => {
    dispatch(resetModalId());
  };

  const filterOptions: TPostFilterOptions[] = useMemo(() => {
    const filters: TPostFilterOptions[] = [
      { value: 'latest', label: 'Latest' },
      { value: 'popular', label: 'Popular' }
    ];

    if (userId === profile.id) {
      filters.push({ value: 'saved', label: 'Saved' });
    }
    return filters;
  }, []);

  const updateFilter = (value: TPostFilters) => {
    setFilterApplied(true);
    dispatch(clearNewsFeed());
    setFilter(value);
  };

  const fetchNewsFeedItems = ({ reload = false }: { reload?: boolean }) => {
    if (reload) {
      dispatch(clearNewsFeed());
    }

    const payload: TGetNewsFeedRequest = {
      userId: `${userId}`,
      pageNumber: reload ? 1 : newsFeedPageNumber,
      postsPerPage: newsFeedItemsPerPage,
      userType: 'User'
    };

    switch (filter) {
      case 'latest': {
        dispatch(getNewsFeedSelfPosted(payload));
        break;
      }
      case 'popular': {
        dispatch(getNewsFeedOwnPopular(payload));
        break;
      }
      case 'saved': {
        dispatch(getNewsFeedSavedPosts(payload));
        break;
      }
      default: {
        dispatch(getNewsFeedSelfPosted(payload));
        break;
      }
    }
  };

  const handleDeletePost = ({ postId }: { postId: string }) => {
    const userType = StorageUtils.get('userType') as 'User' | 'Company';

    const payload: TDeletePostActionRequest = {
      userId: profile?.id,
      userType,
      postId,
      prevRoute: 'my-profile'
    };

    dispatch(deletePost(payload)).then(() => {
      fetchNewsFeedItems({ reload: true });
    });
  };

  useEffect(() => {
    if (filterApplied) {
      fetchNewsFeedItems({ reload: false });
    }
    return () => {
      dispatch(clearNewsFeed());
    };
  }, [filter]);

  useEffect(() => {
    if (!loading) {
      fetchNewsFeedItems({ reload: false });
    }
  }, [newsFeedPageNumber]);

  return (
    <div className="activities">
      <div className="activities__body">
        <div className="activities__header">
          <Typography
            as="h5"
            variant="subHeading-2"
            fontWeight="semiBold"
            className="activities__header__title"
          >
            My Activities
          </Typography>
        </div>
        {/*
         ** Create post
         */}
        {userId === profile.id && (
          <CreatePost
            refetchPost={() => fetchNewsFeedItems({ reload: true })}
            companyProfile={false}
            viewerId={viewerId}
            viewerType={viewerType}
          />
        )}
        {/*
         ** Filter posts
         */}
        <FilterPosts
          filterOptions={filterOptions}
          filter={filter}
          setFilter={updateFilter}
          reloadFeed={fetchNewsFeedItems}
        />
        {/*
         ** List posts
         */}
        {newsFeed.map((post, index) => {
          const ownerOfPost: boolean = userId === profile?.id;

          const postCardDropdownItems: TDropdownOptions[] = [
            {
              label: 'Copy post link',
              onClick: () => {
                copyPostLink(post?.post?.id);
              }
            },
            {
              label: 'View post',
              onClick: () => {
                redirectToViewPost({ postId: post?.post?.id, ownerId: post?.user?.id });
              }
            }
          ];

          if (ownerOfPost) {
            postCardDropdownItems.push(
              { label: 'Edit post', onClick: () => openCreatePostModal(post) },
              {
                label: 'Delete post',
                onClick: () => {
                  handleDeletePost({ postId: post?.post?.id });
                }
              }
            );
          }

          const userType: TUserTypes = post?.userType[0] as unknown as TUserTypes;

          const activityPost: TPost = JSON.parse(JSON.stringify(post));

          try {
            if (
              userType === 'User' &&
              loggedInUserType === 'User' &&
              activityPost?.user?.id === authenticatedId
            ) {
              activityPost.user.profilePic = profile?.profilePic;
            }
          } catch (e) {
            console.log(e);
          }

          return (
            <ViewPost
              key={index}
              dropdownOptions={postCardDropdownItems}
              expandPost={true}
              post={activityPost}
              viewerId={viewerId}
              viewerType={viewerType}
            />
          );
        })}

        {isModalOpen && selectedPostForEdit && (
          <EditPost
            isModalOpen={isModalOpen}
            onModalClose={closeCreatePostModal}
            post={selectedPostForEdit}
            refetchPost={() => fetchNewsFeedItems({ reload: true })}
            companyProfile={false}
            viewerId={viewerId}
            viewerType={viewerType}
          />
        )}
      </div>
    </div>
  );
};

export default Activities;
