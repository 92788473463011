import { useNavigate } from 'react-router-dom';

const useGoBack = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(-1); // Navigate back using the useNavigate hook
  };

  return handleGoBack;
};

export default useGoBack;
