import { useEffect, useState } from 'react';
import { Modal, TTabItem, Tabs } from 'src/components/common';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  TAddEvaluatorsRequest,
  TRecommendedEvaluatorRequest,
  TSearchEvaluator
} from 'src/services/apiEndpoint.types.ts';
import {
  addEvaluators,
  fetchRecommendedEvaluators,
  getIdea
} from 'src/store/ideaHub/ideaHub.slice';
import { useGetIdentity } from 'src/hooks';

import './shareToEvaluate.scss';
import EvaluatorRecommendations from './evaluatorRecommendations/EvaluatorRecommendations';
import SearchAndSelectEvaluators from './searchAndSelectEvaluators/SearchAndSelectEvaluators';

export type TShareToEvaluateProps = {
  ideaId: string;
  isModalOpen: boolean;
  handleCloseModal: () => void;
};

export type TSelectedEvaluator = {
  id: string;
  name: string;
  role: string;
  email: string;
  profilePic: string;
};

const ShareToEvaluate = ({ ideaId, isModalOpen, handleCloseModal }: TShareToEvaluateProps) => {
  const [selectedEvaluators, setSelectedEvaluators] = useState<TSelectedEvaluator[]>([]);
  const [currentTab, setCurrentTab] = useState<'search' | 'recommendations'>('recommendations');

  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, authenticatedId } = getIdentities();

  const { loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const { searchEvaluatorsList } = useAppSelector((store) => store.ideaHub);

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedEvaluators((prev) => {
      const currentList: TSelectedEvaluator[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex(
        (member: TSelectedEvaluator) => member.id === userId
      );

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectUser = searchEvaluatorsList.find(
          (member: TSearchEvaluator) => member.id === userId
        );
        if (selectUser) {
          const { id, name, role, email, profilePic } = selectUser;
          currentList.push({ id, name, role, email, profilePic });
        }
      }
      return currentList;
    });
  };

  const updateRecommendedUsers = (user: TSelectedEvaluator) => {
    const newUser: TSelectedEvaluator = JSON.parse(JSON.stringify(user));
    const isUserAlreadyInTheList = selectedEvaluators.findIndex(
      (evaluator) => evaluator.id === newUser.id
    );

    setSelectedEvaluators((prev) => {
      if (isUserAlreadyInTheList > -1) {
        return prev.filter((prevUser) => prevUser.id !== newUser.id);
      } else {
        return prev.concat(newUser);
      }
    });
  };

  const changeIdeaHubTab = (value: string) => {
    setCurrentTab(value as 'search' | 'recommendations');
  };

  const tabItems: TTabItem[] = [
    {
      key: 'recommendations',
      text: 'Recommendations',
      disabled: false,
      component: (
        <EvaluatorRecommendations
          ideaId={ideaId}
          updateSelectedUsers={updateRecommendedUsers}
          selectedEvaluators={selectedEvaluators}
        />
      )
    },
    {
      key: 'search',
      text: 'Search',
      disabled: false,
      component: (
        <SearchAndSelectEvaluators
          ideaId={ideaId}
          selectedEvaluators={selectedEvaluators}
          updateSelectedUsers={updateSelectedUsers}
        />
      )
    }
  ];

  const handleAddMembers = () => {
    const usersToAdd: { userId: string }[] = selectedEvaluators.map((user) => ({
      userId: user?.id
    }));
    const payload: TAddEvaluatorsRequest = {
      ideaId,
      usersToAdd,
      userId: authenticatedId
    };

    dispatch(addEvaluators(payload)).then(() => {
      dispatch(getIdea({ ideaId, userId: loggedInUserId }));
    });
  };

  const fetchRecommendedUsers = () => {
    const payload: TRecommendedEvaluatorRequest = {
      ideaId,
      limit: 20,
      offset: 1
    };

    dispatch(fetchRecommendedEvaluators(payload));
  };

  useEffect(() => {
    fetchRecommendedUsers();
  }, [ideaId]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="share-to-evaluate-modal"
      title="Share Idea"
    >
      <div className="share-to-evaluate">
        <Tabs activeItem={currentTab} onTabChange={changeIdeaHubTab} items={tabItems} />
        <div className="share-to-evaluate__buttons">
          <Button variant="secondary" size="small" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" size="small" loading={loading} onClick={handleAddMembers}>
            Share Idea
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ShareToEvaluate;
