import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { useGetFormikField } from 'src/hooks';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const TextField = ({ id, label, validations, required }: TPreviewFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });
  console.log(field, fieldMetaData, 'fielD', validations);

  return (
    <div key={id} className="dynamic-field">
      <Label required={required}>{label}</Label>
      <div className="dynamic-field__field">
        {' '}
        <TextAreaField
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Description"
          type="text"
          {...(validations?.maxCharacters < 3000 && { textLengthLimit: validations.maxCharacters })}
          value={field?.value}
          onChange={(e) => {
            if (!validations.maxCharacters || (e.target.value?.length <= field.value?.length) || (e.target.value?.length <= +validations.maxCharacters)) {
              helpers?.setValue(e.target.value);
            }
          }}
          isTouched={fieldMetaData?.touched}
          errorMessage={fieldMetaData?.error}
        />
      </div>
    </div>
  );
};

export default TextField;
