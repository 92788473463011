/* eslint-disable no-useless-catch */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { StorageUtils } from 'src/utils';
import { milSecToMinsFactor, updateTokenBeforeExpiryMins } from 'src/constants';

import { TAuthenticationResult } from './apiEndpoint.types.ts/signIn.endpoint.types';

type TErrorResponse = {
  data: { message: string | null } | unknown;
  status: number;
};

export type APIResponse<TResponse> = {
  data: TResponse;
  status: number;
  message?:string
};

export class BaseAPIService {
  public axiosInstance: AxiosInstance;

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_ENDPOINT
    });
    this.configureInterceptors();
  }

  protected async get<TRequest, TResponse>(
    url: string,
    includeAuthorizationHeaders: boolean = true,
    params?: TRequest,
    includeNgrokHeaders?: boolean,
    query?: Record<string, any>
  ): Promise<APIResponse<TResponse>> {
    const res: AxiosResponse<TResponse> = await this.axiosInstance.get(url, {
      params: { ...params, ...query },
      headers: { includeAuthorizationHeaders, includeNgrokHeaders }
    });
    return { data: res.data, status: res.status };
  }

  protected async post<TRequest, TResponse>(
    url: string,
    payload: TRequest,
    includeAuthorizationHeaders: boolean = true,
    includeNgrokHeaders?: boolean,
    authorizationToken?:any
  ): Promise<APIResponse<TResponse>> {
    const res: AxiosResponse<TResponse> = await this.axiosInstance.post(url, payload, {
      headers: { includeAuthorizationHeaders, includeNgrokHeaders, Authorization: authorizationToken }
    });
    return { data: res.data, status: res.status };
  }

  protected async put<TRequest, TResponse>(
    url: string,
    payload: TRequest,
    includeAuthorizationHeaders: boolean = true
  ): Promise<APIResponse<TResponse>> {
    const res: AxiosResponse<TResponse> = await this.axiosInstance.put(url, payload, {
      headers: { includeAuthorizationHeaders }
    });
    return { data: res.data, status: res.status };
  }

  protected async delete<TRequest, TResponse>(
    url: string,
    includeAuthorizationHeaders: boolean = true,
    params?: TRequest
  ): Promise<APIResponse<TResponse>> {
    const res: AxiosResponse<TResponse> = await this.axiosInstance.delete(url, {
      data: params,
      headers: { includeAuthorizationHeaders }
    });
    return { data: res.data, status: res.status };
  }

  protected async deleteWithParams<TRequest, TResponse>(
    url: string,
    includeAuthorizationHeaders: boolean,
    params?: TRequest
  ): Promise<APIResponse<TResponse>> {
    const res: AxiosResponse<TResponse> = await this.axiosInstance.delete(url, {
      params,
      headers: { includeAuthorizationHeaders }
    });
    return { data: res.data, status: res.status };
  }

  private errorHandler = (error: AxiosError): TErrorResponse => {
    throw error;
  };

  private successHandler = (response: AxiosResponse): AxiosResponse => {
    return response;
  };

  private updateTokens = async () => {
    try {
      const AuthenticationResult = StorageUtils.get(
        'AuthenticationResult'
      ) as unknown as TAuthenticationResult;
      const email = StorageUtils.get('email');
      const refreshToken = AuthenticationResult.RefreshToken;

      // A request  sent to fetch the new access token using the refresh token.
      const response = await axios.post(
        process.env.REACT_APP_API_ENDPOINT + '/auth/get-new-tokens',
        {
          email,
          refreshToken
        }
      );
      const newAuthenticationResult = response.data.data.AuthenticationResult;
      const tokenExpiresIn = newAuthenticationResult?.ExpiresIn;
      newAuthenticationResult.RefreshToken = refreshToken;

      // Store new access token and idToken in the localStorage
      StorageUtils.set('AuthenticationResult', newAuthenticationResult);

      // sets the token expires timestamp
      StorageUtils.set('tokenExpiresIn', `${new Date().getTime() + tokenExpiresIn * 1000}`);
    } catch (error: unknown) {
      console.log('unable to update tokens');
    }
  };

  protected configureInterceptors = () => {
    this.axiosInstance.interceptors.request.use(async (config) => {
      const tokenExpiresInEpoch = StorageUtils.get('tokenExpiresIn') as unknown as number;

      const tokenExpiresInMin: number =
        (tokenExpiresInEpoch - new Date().getTime()) / milSecToMinsFactor;

      // updating tokens within 10 mins of expiry
      if (tokenExpiresInMin < updateTokenBeforeExpiryMins) {
        await this.updateTokens();
      }

      const AuthenticationResult = StorageUtils.get(
        'AuthenticationResult'
      ) as unknown as TAuthenticationResult;

      const includeAuthorizationHeaders = config.headers.includeAuthorizationHeaders;
      const includeNgrokHeaders = config.headers.includeNgrokHeaders;

      /*
       ** Add headers
       */
      if (includeAuthorizationHeaders) {
        config.headers.Authorization = AuthenticationResult?.AccessToken;
        config.headers['id-token'] = AuthenticationResult?.IdToken;
      }

      if (includeNgrokHeaders) {
        config.headers['ngrok-skip-browser-warning'] = 'skip-browser-warning';
      }

      delete config.headers.includeAuthorizationHeaders;
      delete config.headers.includeNgrokHeaders;

      return config;
    });
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => this.successHandler(response),
      (error: AxiosError) => this.errorHandler(error)
    );

    this.axiosInstance.interceptors.request.use((request) => request);
  };
}
