import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from 'src/components/common/typography/Typography';
import CreatePost from 'src/components/posts/createPost/CreatePost';
import { TDropdownOptions } from 'src/components/common/dropdown/Dropdown';
import EditPost from 'src/components/posts/editPost/EditPost';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import {
  clearNewsFeed,
  deletePost,
  getNewsFeedCompanyAnnouncements,
  getNewsFeedCompanyPosts
} from 'src/store/socialMedia/socialMedia.slice';
import { useCopyToClipboard, useGetIdentity } from 'src/hooks';
import {
  TDeletePostActionRequest,
  TGetNewsFeedCompanyRequest,
  TPost
} from 'src/services/apiEndpoint.types.ts';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import { TUserTypes } from 'src/constants/user.constants';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';
import { TPostType } from 'src/constants';

import './activities.scss';

import FilterPosts, { TPostFilterOptions, TPostFilters } from '../../posts/filterPosts/FilterPosts';

const editPostModalId = 'edit-post-modal-activities';

export type TActivities = {
  viewCompanyId: string
  viewerId: string
  viewerType: TUserTypes;
};

const Activities = ({ viewCompanyId, viewerId, viewerType }: TActivities) => {
  const [filter, setFilter] = useState<TPostFilters>('post');
  const [filterApplied, setFilterApplied] = useState<boolean>(false);
  const [selectedPostForEdit, setSelectedPostForEdit] = useState<TPost | null>(null);

  const isSelfCompany = useContext(SelfCompanyContext); // Whether this profile card is viewed by self.

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { copyToClipboard } = useCopyToClipboard();
  const { getIdentities } = useGetIdentity();

  const { modalId, loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { newsFeed, newsFeedItemsPerPage, newsFeedPageNumber } = useAppSelector(
    (store) => store.socialMedia
  );
  const { companyProfilePic } = useAppSelector((store) => store.company);

  const { loggedInUserId, loggedInCompanyId, loggedInUserType, authenticatedId } = getIdentities();

  const redirectToViewPost = ({ postId, ownerId }: { postId: string; ownerId: string }) => {
    const isOwner = ownerId === viewCompanyId;

    const viewAs: TUserTypes = isSelfCompany ? 'Company' : viewerType;

    const postRoute = isOwner ? 'view-post-owned' : 'view-post';
    navigate(
      `/${postRoute}?postId=${postId}&viewAs=${viewAs}&prev=${window.location.pathname}?id=${viewCompanyId}`
    );
  };

  const isModalOpen = modalId === editPostModalId;

  const copyPostLink = (postId: string) => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const openCreatePostModal = (post: TPost) => {
    setSelectedPostForEdit(post);
    dispatch(assignModalId(editPostModalId));
  };

  const closeCreatePostModal = () => {
    dispatch(resetModalId());
  };

  const filterOptions: TPostFilterOptions[] = useMemo(() => {
    const filters: TPostFilterOptions[] = [
      { value: 'post', label: 'Post' },
      { value: 'announcement', label: 'Announcement' }
    ];

    return filters;
  }, []);

  const updateFilter = (value: TPostFilters) => {
    setFilterApplied(true);
    dispatch(clearNewsFeed());
    setFilter(value);
  };

  const fetchNewsFeedItems = ({ reload = false }: { reload?: boolean }) => {
    if (reload) {
      dispatch(clearNewsFeed());
    }
    const payload: TGetNewsFeedCompanyRequest = {
      companyId: `${viewCompanyId}`,
      pageNumber: reload ? 1 : newsFeedPageNumber,
      postsPerPage: newsFeedItemsPerPage,
      userType: 'Company'
    };
    switch (filter) {
      case 'post': {
        dispatch(getNewsFeedCompanyPosts(payload));
        break;
      }
      case 'announcement': {
        dispatch(getNewsFeedCompanyAnnouncements(payload));
        break;
      }
      default: {
        dispatch(getNewsFeedCompanyPosts(payload));
        break;
      }
    }
  };

  const handleDeletePost = ({ postId }: { postId: string }) => {
    const payload: TDeletePostActionRequest = {
      userId: viewerId,
      userType: viewerType,
      postId
    };
    dispatch(deletePost(payload)).then(() => {
      fetchNewsFeedItems({ reload: true });
    });
  };

  useEffect(() => {
    if (filterApplied) {
      fetchNewsFeedItems({ reload: false });
    }
    return () => {
      dispatch(clearNewsFeed());
    };
  }, [filter]);

  useEffect(() => {
    if (!loading) {
      fetchNewsFeedItems({ reload: false });
    }
  }, [newsFeedPageNumber]);

  return (
    <div className="activities">
      <div className="activities__body">
        <div className="activities__header">
          <Typography
            as="h5"
            variant="subHeading-2"
            fontWeight="semiBold"
            className="activities__header__title"
          >
            Activities
          </Typography>
        </div>
        {/*
         ** Create post
         */}
        {isSelfCompany && (
          <CreatePost
            refetchPost={() => fetchNewsFeedItems({ reload: true })}
            companyProfile={viewerType === 'Company'}
            viewerId={viewerId}
            viewerType={viewerType}
          />
        )}
        {/*
         ** Filter posts
         */}
        <FilterPosts
          filterOptions={filterOptions}
          filter={filter}
          setFilter={updateFilter}
          reloadFeed={fetchNewsFeedItems}
        />
        {/*
         ** List posts
         */}
        {newsFeed.map((post, index) => {
          const postCreatorId = post?.user?.id;
          const isViewerCreatorOfPost: boolean =
            viewerType === 'User'
              ? loggedInUserId === postCreatorId
              : loggedInCompanyId === postCreatorId;
          const postType: TPostType = post?.post?.isAnnouncement ? 'announcement' : 'post';

          const postCardDropdownItems: TDropdownOptions[] = [
            {
              label: `Copy ${postType} link`,
              onClick: () => {
                copyPostLink(post?.post?.id);
              }
            },
            {
              label: `View ${postType}`,
              onClick: () => {
                redirectToViewPost({ postId: post?.post?.id, ownerId: post?.user?.id });
              }
            }
          ];

          if (isViewerCreatorOfPost) {
            postCardDropdownItems.push(
              { label: `Edit ${postType}`, onClick: () => openCreatePostModal(post) },
              {
                label: `Delete ${postType}`,
                onClick: () => {
                  handleDeletePost({ postId: post?.post?.id });
                }
              }
            );
          }

          const userType: TUserTypes = post?.userType[0] as unknown as TUserTypes;

          const activityPost: TPost = JSON.parse(JSON.stringify(post));

          try {
            if (
              userType === 'Company' &&
              loggedInUserType === 'Company' &&
              activityPost?.user?.id === authenticatedId
            ) {
              activityPost.user.logo = companyProfilePic;
            }
          } catch (e) {
            console.log(e);
          }

          return (
            <ViewPost
              key={index}
              dropdownOptions={postCardDropdownItems}
              expandPost={true}
              post={activityPost}
              viewerId={viewerId}
              viewerType={viewerType}
            />
          );
        })}

        {isModalOpen && selectedPostForEdit && (
          <EditPost
            isModalOpen={isModalOpen}
            onModalClose={closeCreatePostModal}
            post={selectedPostForEdit}
            refetchPost={() => fetchNewsFeedItems({ reload: true })}
            companyProfile={viewerType === 'Company'}
            viewerId={viewerId}
            viewerType={viewerType}
            postType={selectedPostForEdit?.post?.isAnnouncement ? 'announcement' : 'post'}
          />
        )}
      </div>
    </div>
  );
};

export default Activities;
