import axios from 'axios';

import { APIResponse, BaseAPIService } from './base.api.service';
import {
  TAddCollaboratorsRequest,
  TAddEvaluatorsRequest,
  TCreateIdeaRequest,
  TDeleteDocumentRequest,
  TEditCollaboratorsRequest,
  TEditIdeaRequest,
  TFinalizeIdeaRequest,
  TGetCollaboratorsRequest,
  TGetDocumentRequest,
  TGetIdeaRequest,
  TGetIdeaRequestsCountRequest,
  TIdeasSharedWithMeRequest,
  TListDocumentsRequest,
  TListEvaluationResponsesRequest,
  TListEvaluatorsRequest,
  TMyIdeasRequest,
  TRecommendedEvaluatorRequest,
  TRespondToCollaborationRequest,
  TRespondToEvaluationRequest,
  TSearchCollaboratorsRequest,
  TSubmitEvaluationRequest,
  TUploadDocumentRequest
} from './apiEndpoint.types.ts';
import { TSearchEvaluatorsRequest } from './apiEndpoint.types.ts/ideaHub/searchEvaluators.endpoint.types';
import { TGetDocumentPreSignedUrlRequest } from './apiEndpoint.types.ts/ideaHub/uploadDocument.endpoint.types';

export class IdeaHubAPIService extends BaseAPIService {
  private createIdeaPath: string = '/ideas/create';
  private myIdeasPath: string = '/ideas/my-ideas';
  private sharedWithMePath: string = '/ideas/shared-with-me';
  private getIdeaPath: string = '/ideas/get';
  private listEvaluationResponsesPath: string = '/ideas/evaluators/list-evaluation-responses';
  private getCollaboratorsPath: string = '/ideas/collaborators/get';
  private getDocumentSignUrlPath: string = '/ideas/documents/upload';
  private listDocumentsPath: string = '/ideas/documents/list';
  private getDocumentPath: string = '/ideas/documents/get';
  private deleteDocumentPath: string = '/ideas/documents/delete';
  private editIdeaPath: string = '/ideas/edit';
  private searchCollaboratorsPath: string = '/ideas/collaborators/search';
  private addCollaboratorsPath: string = '/ideas/collaborators/add';
  private editCollaboratorsPath: string = '/ideas/collaborators/edit';
  private searchEvaluatorsPath: string = '/ideas/evaluators/search';
  private addEvaluatorsPath: string = 'ideas/evaluators/add';
  private listEvaluatorsPath: string = 'ideas/evaluators/list';
  private recommendedEvaluatorsPath: string = 'ideas/evaluators/recommended';
  private finalizeIdeaPath: string = '/ideas/finalize';
  private getIdeaRequestsCountPath: string = '/ideas/get/request-count';

  private respondToCollaborationRequestPath: string =
    '/ideas/collaborators/respond-to-collaboration-request';

  private respondToEvaluationRequestPath: string =
    '/ideas/evaluators/respond-to-evaluation-request';

  private submitEvaluationPath: string = 'ideas/evaluators/submit-evaluation';

  constructor() {
    // Ideahub api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_IDEAHUB_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async createIdea<TCreateIdeaResponse>(
    createIdeaRequest: TCreateIdeaRequest
  ): Promise<APIResponse<TCreateIdeaResponse>> {
    const createIdeaResponse: APIResponse<TCreateIdeaResponse> = await this.post(
      this.createIdeaPath,
      createIdeaRequest,
      true
    );
    return createIdeaResponse;
  }

  public async myIdeas<TMyIdeasResponse>(
    myIdeasRequest: TMyIdeasRequest
  ): Promise<APIResponse<TMyIdeasResponse>> {
    const myIdeasResponse: APIResponse<TMyIdeasResponse> = await this.get(
      this.myIdeasPath,
      true,
      myIdeasRequest
    );
    return myIdeasResponse;
  }

  public async sharedWithMe<TIdeasSharedWithMeResponse>(
    sharedWithMeRequest: TIdeasSharedWithMeRequest
  ): Promise<APIResponse<TIdeasSharedWithMeResponse>> {
    const sharedWithMeResponse: APIResponse<TIdeasSharedWithMeResponse> = await this.get(
      this.sharedWithMePath,
      true,
      sharedWithMeRequest
    );
    return sharedWithMeResponse;
  }

  public async getIdea<TGetIdeaResponse>(
    getIdeaRequest: TGetIdeaRequest
  ): Promise<APIResponse<TGetIdeaResponse>> {
    const getIdeaResponse: APIResponse<TGetIdeaResponse> = await this.get(
      this.getIdeaPath,
      true,
      getIdeaRequest
    );
    return getIdeaResponse;
  }

  public async listEvaluationResponses<TListEvaluationResponsesResponse>(
    listEvaluationResponsesRequest: TListEvaluationResponsesRequest
  ): Promise<APIResponse<TListEvaluationResponsesResponse>> {
    const listEvaluationResponsesResponse: APIResponse<TListEvaluationResponsesResponse> =
      await this.get(this.listEvaluationResponsesPath, true, listEvaluationResponsesRequest);
    return listEvaluationResponsesResponse;
  }

  public async getCollaborators<TGetCollaboratorsResponse>(
    getCollaboratorsRequest: TGetCollaboratorsRequest
  ): Promise<APIResponse<TGetCollaboratorsResponse>> {
    const getCollaboratorsResponse: APIResponse<TGetCollaboratorsResponse> = await this.get(
      this.getCollaboratorsPath,
      true,
      getCollaboratorsRequest
    );
    return getCollaboratorsResponse;
  }

  public async listDocuments<TListDocumentsResponse>(
    listDocumentsRequest: TListDocumentsRequest
  ): Promise<APIResponse<TListDocumentsResponse>> {
    const listDocumentsResponse: APIResponse<TListDocumentsResponse> = await this.get(
      this.listDocumentsPath,
      true,
      listDocumentsRequest
    );
    return listDocumentsResponse;
  }

  public async editIdea<TEditIdeaResponse>(
    editIdeaRequest: TEditIdeaRequest
  ): Promise<APIResponse<TEditIdeaResponse>> {
    const editIdeaResponse: APIResponse<TEditIdeaResponse> = await this.post(
      this.editIdeaPath,
      editIdeaRequest,
      true
    );
    return editIdeaResponse;
  }

  public async searchCollaborators<TSearchCollaboratorsResponse>(
    searchCollaboratorsRequest: TSearchCollaboratorsRequest
  ): Promise<APIResponse<TSearchCollaboratorsResponse>> {
    const searchCollaboratorsResponse: APIResponse<TSearchCollaboratorsResponse> = await this.get(
      this.searchCollaboratorsPath,
      true,
      searchCollaboratorsRequest
    );
    return searchCollaboratorsResponse;
  }

  public async addCollaborators<TAddCollaboratorsResponse>(
    addCollaboratorsRequest: TAddCollaboratorsRequest
  ): Promise<APIResponse<TAddCollaboratorsResponse>> {
    const addCollaboratorsResponse: APIResponse<TAddCollaboratorsResponse> = await this.post(
      this.addCollaboratorsPath,
      addCollaboratorsRequest,
      true
    );
    return addCollaboratorsResponse;
  }

  public async editCollaborators<TEditCollaboratorsResponse>(
    editCollaboratorsRequest: TEditCollaboratorsRequest
  ): Promise<APIResponse<TEditCollaboratorsResponse>> {
    const editCollaboratorsResponse: APIResponse<TEditCollaboratorsResponse> = await this.post(
      this.editCollaboratorsPath,
      editCollaboratorsRequest,
      true
    );
    return editCollaboratorsResponse;
  }

  public async searchEvaluators<TSearchEvaluatorsResponse>(
    searchEvaluatorsRequest: TSearchEvaluatorsRequest
  ): Promise<APIResponse<TSearchEvaluatorsResponse>> {
    const searchEvaluatorsResponse: APIResponse<TSearchEvaluatorsResponse> = await this.get(
      this.searchEvaluatorsPath,
      true,
      searchEvaluatorsRequest
    );
    return searchEvaluatorsResponse;
  }

  public async addEvaluators<TSddEvaluatorsResponse>(
    addEvaluatorsRequest: TAddEvaluatorsRequest
  ): Promise<APIResponse<TSddEvaluatorsResponse>> {
    const addEvaluatorsResponse: APIResponse<TSddEvaluatorsResponse> = await this.post(
      this.addEvaluatorsPath,
      addEvaluatorsRequest,
      true
    );
    return addEvaluatorsResponse;
  }

  public async recommendedEvaluators<TRecommendedEvaluatorsResponse>(
    recommendedEvaluatorsRequest: TRecommendedEvaluatorRequest
  ): Promise<APIResponse<TRecommendedEvaluatorsResponse>> {
    const recommendedEvaluatorsResponse: APIResponse<TRecommendedEvaluatorsResponse> =
      await this.get(this.recommendedEvaluatorsPath, true, recommendedEvaluatorsRequest);
    return recommendedEvaluatorsResponse;
  }

  public async finalizeIdea<TFinalizeIdeaResponse>(
    finalizeIdeaRequest: TFinalizeIdeaRequest
  ): Promise<APIResponse<TFinalizeIdeaResponse>> {
    const finalizeIdeaResponse: APIResponse<TFinalizeIdeaResponse> = await this.post(
      this.finalizeIdeaPath,
      finalizeIdeaRequest,
      true
    );
    return finalizeIdeaResponse;
  }

  public async uploadDocument<TUploadDocumentResponse>(
    uploadDocumentRequest: TUploadDocumentRequest
  ): Promise<APIResponse<TUploadDocumentResponse>> {
    const { data, presignedUrl } = uploadDocumentRequest;
    const uploadDocumentResponse: APIResponse<TUploadDocumentResponse> = await this.put(
      presignedUrl,
      data,
      false
    );
    return uploadDocumentResponse;
  }

  public async getDocumentSignedUrl<TGetDocumentPreSignedUrlResponse>(
    documentRequest: TGetDocumentPreSignedUrlRequest
  ): Promise<APIResponse<TGetDocumentPreSignedUrlResponse>> {
    const DocumentResponse: APIResponse<TGetDocumentPreSignedUrlResponse> = await this.get(
      this.getDocumentSignUrlPath,
      true,
      documentRequest
    );
    return DocumentResponse;
  }

  public async getDocument<TGetDocumentResponse>(
    getDocumentRequest: TGetDocumentRequest
  ): Promise<APIResponse<TGetDocumentResponse>> {
    const DocumentResponse: APIResponse<TGetDocumentResponse> = await this.get(
      this.getDocumentPath,
      true,
      getDocumentRequest
    );
    return DocumentResponse;
  }

  public async respondToCollaborationRequest<TRespondToCollaborationResponse>(
    respondToCollaborationRequestRequest: TRespondToCollaborationRequest
  ): Promise<APIResponse<TRespondToCollaborationResponse>> {
    const respondToCollaborationRequestResponse: APIResponse<TRespondToCollaborationResponse> =
      await this.post(
        this.respondToCollaborationRequestPath,
        respondToCollaborationRequestRequest,
        true
      );
    return respondToCollaborationRequestResponse;
  }

  public async respondToEvaluationRequest<TRespondToEvaluationResponse>(
    respondToEvaluationRequestRequest: TRespondToEvaluationRequest
  ): Promise<APIResponse<TRespondToEvaluationResponse>> {
    const respondToEvaluationRequestResponse: APIResponse<TRespondToEvaluationResponse> =
      await this.post(this.respondToEvaluationRequestPath, respondToEvaluationRequestRequest, true);
    return respondToEvaluationRequestResponse;
  }

  public async submitEvaluation<TSubmitEvaluationResponse>(
    submitEvaluationRequest: TSubmitEvaluationRequest
  ): Promise<APIResponse<TSubmitEvaluationResponse>> {
    const submitEvaluationResponse: APIResponse<TSubmitEvaluationResponse> = await this.post(
      this.submitEvaluationPath,
      submitEvaluationRequest,
      true
    );
    return submitEvaluationResponse;
  }

  public async listEvaluators<TListEvaluatorsResponse>(
    listEvaluatorsRequest: TListEvaluatorsRequest
  ): Promise<APIResponse<TListEvaluatorsResponse>> {
    const listEvaluatorsResponse: APIResponse<TListEvaluatorsResponse> = await this.get(
      this.listEvaluatorsPath,
      true,
      listEvaluatorsRequest
    );
    return listEvaluatorsResponse;
  }

  public async deleteDocument<TDeleteDocumentResponse>(
    deleteDocumentRequest: TDeleteDocumentRequest
  ): Promise<APIResponse<TDeleteDocumentResponse>> {
    const DocumentResponse: APIResponse<TDeleteDocumentResponse> = await this.post(
      this.deleteDocumentPath,
      deleteDocumentRequest,
      true
    );
    return DocumentResponse;
  }

  public async getIdeaRequestsCount<TGetIdeaRequestsCountResponse>(
    getIdeaRequestsCountRequest: TGetIdeaRequestsCountRequest
  ): Promise<APIResponse<TGetIdeaRequestsCountResponse>> {
    const getIdeaRequestsCountResponse: APIResponse<TGetIdeaRequestsCountResponse> = await this.get(
      this.getIdeaRequestsCountPath,
      true,
      getIdeaRequestsCountRequest
    );
    return getIdeaRequestsCountResponse;
  }
}
