export class StorageUtils {
  public static get<T>(key: string): T | null {
    const data: string | null = localStorage.getItem(key);

    if (data !== null) {
      return JSON.parse(data).data;
    }

    return null;
  }

  public static set<TData>(key: string, data: TData) {
    localStorage.setItem(key, JSON.stringify({ data }));
  }

  public static clear() {
    localStorage.clear();
  }

  public static remove(key: string) {
    localStorage.removeItem(key);
  }
}
