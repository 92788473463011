import { FC, Fragment, HTMLAttributes } from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { colors } from 'src/constants';

import Typography from '../typography/Typography';
import { SingleChevronIcon } from '../common.icons';

import './breadcrumbs.scss';

export type TBreadcrumb = { label: string; url: string } & HTMLAttributes<HTMLSpanElement>;
export type TBreadcrumbs = {
  breadcrumbs: TBreadcrumb[];
};

const Breadcrumbs: FC<TBreadcrumbs> = ({ breadcrumbs }: TBreadcrumbs) => {
  // Hooks
  const navigate = useNavigate();

  const handleBreadcrumbNavigation = ({ url }: { url: string }) => {
    if (url) {
      navigate(url);
    }
  };

  return (
    <div className="breadcrumbs-component">
      {breadcrumbs?.map(({ label, url, className, ...rest }, index) => {
        return (
          <Fragment key={index}>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="medium"
              onClick={() => handleBreadcrumbNavigation({ url })}
              className={classNames('breadcrumbs-component__label', className)}
              {...rest}
            >
              {label}
            </Typography>
            <div className="breadcrumbs-component__icon">
              <SingleChevronIcon direction="right" color={colors.neutral[1100]} />
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
