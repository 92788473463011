import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import Header from 'src/components/header/Header';
import IdeaSkeleton from 'src/components/ideaHub/ideaSkeleton/IdeaSkeleton';
import { TBreadcrumb } from 'src/components/common';
import { TIdeaSkeltonTabs, TIdeaCollaborationType, TIdeaSharedFor } from 'src/components/ideaHub';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  getCollaborators,
  getIdea,
  listDocuments,
  listEvaluatorResponses
} from 'src/store/ideaHub/ideaHub.slice';
import {
  TGetCollaboratorsRequest,
  TGetIdeaRequest,
  TListDocumentsRequest,
  TListEvaluationResponsesRequest
} from 'src/services/apiEndpoint.types.ts';

import './viewIdea.scss';

const ViewIdea = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { viewIdea, viewEvaluatorResponses, viewDocuments } = useAppSelector(
    (store) => store.ideaHub
  );

  const ideaId: string = paramValue({ paramName: 'id' }) as unknown as string;
  const collaborationType: TIdeaSharedFor = paramValue({
    paramName: 'collaborationType'
  }) as TIdeaSharedFor;
  const { loggedInUserId } = getIdentities();

  const ideaCollaborationType: TIdeaCollaborationType = viewIdea?.userCollaborationType || 'View';
  const ideaSharedFor: TIdeaSharedFor = viewIdea?.sharedFor || 'Collaboration';

  const breadcrumbs: TBreadcrumb[] = [
    { label: 'Idea Hub', url: '/idea-hub?tab=myIdea' },
    { label: viewIdea?.idea?.title || 'Idea', url: '' }
  ];

  const handleChangeTab = (value: string) => {
    let params: string = `id=${ideaId}&tab=${value as TIdeaSkeltonTabs}`;

    if (collaborationType === 'Collaboration' || collaborationType === 'Evaluation') {
      params += `&collaborationType=${collaborationType}`;
    }
    navigate(`/view-idea?${params}`);
  };

  const fetchIdea = () => {
    const hasCollaboration =
      collaborationType === 'Collaboration' || collaborationType === 'Evaluation';
    const payload: TGetIdeaRequest = {
      ideaId,
      userId: loggedInUserId,
      ...(hasCollaboration && { collaborationType })
    };
    if (ideaId) {
      dispatch(getIdea(payload)).then((data: unknown) => {
        const status: number = (data as AxiosError)?.response?.status as number;

        if (status === 404) {
          navigate('/not-found');
        }
      });
    }
  };

  const fetchEvaluatorResponses = () => {
    const payload: TListEvaluationResponsesRequest = {
      ideaId,
      limit: 100,
      offset: 1
    };
    dispatch(listEvaluatorResponses(payload));
  };

  const fetchCollaborators = () => {
    const payload: TGetCollaboratorsRequest = {
      ideaId
    };
    dispatch(getCollaborators(payload));
  };

  const fetchDocuments = () => {
    const payload: TListDocumentsRequest = {
      ideaId
    };
    dispatch(listDocuments(payload));
  };

  useEffect(() => {
    fetchIdea();
    fetchEvaluatorResponses();
    fetchCollaborators();
    fetchDocuments();
  }, [ideaId]);

  return (
    <div className="view-idea">
      <Header />
      <div className="view-idea__body">
        <IdeaSkeleton
          viewType="viewIdea"
          breadCrumbs={breadcrumbs}
          changeTab={handleChangeTab}
          ideaStatus={viewIdea?.idea?.status}
          ideaCollaborationType={ideaCollaborationType}
          ideaData={viewIdea}
          documents={viewDocuments}
          ratings={viewEvaluatorResponses}
          ideaSharedFor={ideaSharedFor}
          ideaRatedByUser={viewIdea?.ideaRatedByUser}
        />
      </div>
    </div>
  );
};

export default ViewIdea;
