import { useEffect } from 'react';

import { TUseComponentMountProps } from './useComponentMount.types';

/**
 * Hook to call simple functions upon mounting and unmounting of component
 */
const useComponentMount = ({ onMountFunc, onUnMountFunc }: TUseComponentMountProps) => {
  useEffect(() => {
    onMountFunc();
    return () => {
      if (onUnMountFunc) {
        onUnMountFunc();
      }
    };
  }, []);
};

export default useComponentMount;
