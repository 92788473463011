import * as Yup from 'yup';
import { EMAIL } from 'src/constants/regex.constants';

const forgotPasswordValidationSchema = Yup.object().shape({
  email: Yup.string()
    .matches(EMAIL, 'Please enter a valid email address')
    .required('Please enter your email')
});

export default forgotPasswordValidationSchema;
