import Label from 'src/components/common/formFields/label/Label';
import { componentSizeVariantsEnum } from 'src/constants';
import InputField from 'src/components/common/formFields/inputField/InputField';

import './previewField.scss';
import { TDateTimeSelectionProps } from './previewField.types';

const DateTimeSelection = ({ id, type, label, required }: TDateTimeSelectionProps) => {
  const inputLabel: string = type === 'dateSelection' ? 'Select date' : 'Select time';
  const inputType: 'date' | 'time' = type === 'dateSelection' ? 'date' : 'time';

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Label required={required}>{label}</Label>
      </div>

      <InputField
        id={id}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder={inputLabel}
        type={inputType}
        disabled
      />
    </div>
  );
};

export default DateTimeSelection;
