import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PlusIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5 0.8125V11.3125M1.25 6.0625H11.75"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PlusIcon;
