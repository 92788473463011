export const colors = {
  // Neutral colors
  neutral: {
    100: '#f8f8f8',
    200: '#f2f2f2',
    300: '#dadada',
    400: '#bfbfbf',
    500: '#a4a4a4',
    600: '#898989',
    700: '#6e6e6e',
    800: '#535353',
    900: '#383838',
    1000: '#1d1d1d',
    1100: '#121212'
  },
  // Primary colors
  primary: {
    100: '#fff3ed',
    200: '#ffe5d5',
    300: '#fec6aa',
    400: '#fe9e73',
    500: '#fc6c3b',
    600: '#fa4616',
    700: '#eb2b0b',
    800: '#c31c0b',
    900: '#9b1911',
    1000: '#7c1812'
  },
  // Error colors
  error: {
    100: '#fef2f2',
    200: '#fee2e2',
    300: '#fecaca',
    400: '#fca5a5',
    500: '#f87171',
    600: '#ef4444',
    700: '#dc2626',
    800: '#b91c1c',
    900: '#991b1b',
    1000: '#7f1d1d'
  },
  // Success colors
  success: {
    100: '#ecfdf5',
    200: '#d1fae5',
    300: '#a7f3d0',
    400: '#6ee7b7',
    500: '#34d399',
    600: '#10b981',
    700: '#059669',
    800: '#047857',
    900: '#065f46',
    1000: '#064e3b'
  },
  // White colors
  white: {
    100: '#ffffff',
    200: '#f3f7fb'
  },
  // Background colors
  background: {
    100: '#fffbf9'
  }
};
