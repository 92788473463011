import classNames from 'classnames';
import Button from 'src/components/button/Button';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { CheckMarkIcon, CrossIcon } from 'src/components/common/common.icons';
import { Typography } from 'src/components/common';

import './confirmModal.scss';
import { icon } from './confirmModal.constants';

export type TConfirmModal = {
  title: string;
  description?: string;
  openConfirmModal: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitText: string;
  type: 'success' | 'error' | 'newIdea';
  loading?: boolean;
};

const ConfirmModal = ({
  title,
  description,
  openConfirmModal,
  onClose,
  onSubmit,
  submitText,
  type,
  loading
}: TConfirmModal) => {
  return (
    <div
      className={classNames('confirm-modal-outer', {
        'confirm-modal-outer--is-open': openConfirmModal
      })}
    >
      <div className="confirm-modal">
        <div className="confirm-modal__header">
          <div className="confirm-modal__header__close" onClick={onClose}>
            <CrossIcon />
          </div>
          <div className="confirm-modal__header__icon">
            {icon[type as keyof typeof icon] || <CheckMarkIcon />}
          </div>
        </div>
        <div className="confirm-modal__content">
          <Typography
            className="confirm-modal__content__title"
            as="h4"
            variant="subHeading-1"
            fontWeight="semiBold"
          >
            {title}
          </Typography>
          {description && (
            <Typography
              className="confirm-modal__content__description"
              as="p"
              variant="body-2"
              fontWeight="regular"
            >
              {description}
            </Typography>
          )}
        </div>
        <div className="confirm-modal__buttons">
          <Button
            className="confirm-modal__buttons__button"
            variant="secondary"
            size={componentSizeVariantsEnum.SMALL}
            onClick={onClose}
          >
            <Typography as="span" variant="body-2" fontWeight="semiBold">
              Cancel
            </Typography>
          </Button>
          <Button
            className="confirm-modal__buttons__button"
            variant="primary"
            size={componentSizeVariantsEnum.SMALL}
            onClick={onSubmit}
            loading={loading}
          >
            <Typography as="span" variant="body-2" fontWeight="semiBold">
              {submitText}
            </Typography>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
