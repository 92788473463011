import React, { useEffect, useMemo, useState } from 'react';
import cx from './dropdown.module.scss';
import { useNavigate } from 'react-router-dom';
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TApplicant, TApplicantsListRequest } from 'src/services/apiEndpoint.types.ts';
import { getApplicants, resetApplicants } from 'src/store/program/program.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';

import SearchSubmittedApplications from './searchSubmittedApplications/SearchSubmittedApplications';
import SubmittedApplicationCard from './submittedApplicationCard/SubmittedApplicationCard';
import { exportToExcel } from 'src/utils/convertToExcel/convertToExcel'; // Adjust the import path accordingly
import './submittedApplications.scss';
import Button from 'src/components/button/Button';
import { TSubmittedApplicationsProps } from './submittedApplications.types';
import { openPopup } from 'src/store/common/common.slice';
import { httpRequest } from 'src/services/commonApis';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { Dropdown } from 'react-bootstrap';

const SubmittedApplications = ({ programId }: TSubmittedApplicationsProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getIdentities } = useGetIdentity();
  const { paramValue } = useUrlParamValue();
  const { viewProgram, applicants, applicantsPageNumber, applicantsPerPage, moreApplicantsLeft } =
    useAppSelector((store) => store.program);
  const { myCompany } =
    useAppSelector((store) => store?.company);
  const programApplicantsParams = useMemo(() => {
    const filterStageTitle = paramValue({ paramName: 'filterStageTitle' }) as string;
    const filterString = paramValue({ paramName: 'filterString' }) as string;

    return { filterStageTitle, filterString };
  }, [window.location.search]);

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  // state
  const [nonPlatformEmail, setNonPlatformEmail] = useState<string>('')
  const [keyMemberData, setKeyMemberData] = useState<any>([]);
  const [selectedApplicant, setSelectApplicant] = useState<any>([])
  const updateParams = ({ param, value }: { param: string; value: string }) => {
    const filterStageTitle = paramValue({ paramName: 'filterStageTitle' }) as string;
    const filterString = paramValue({ paramName: 'filterString' }) as string;

    let path = `/view-program?id=${programId}&tab=submittedApplications`;

    const currentParams = { filterStageTitle, filterString };

    Object.keys(currentParams).forEach((key) => {
      const currentValue = currentParams[key as keyof typeof currentParams];

      if (key === param) {
        path += `&${key}=${encodeURIComponent(value)}`;
      } else if (currentValue) {
        path += `&${key}=${encodeURIComponent(currentValue)}`;
      }
    });

    navigate(path);
  };

  const handleGetApplicants = ({ resetData }: { resetData: boolean }) => {
    if (moreApplicantsLeft) {
      const { filterStageTitle, filterString } = programApplicantsParams;

      const payload: TApplicantsListRequest = {
        userId: authenticatedId,
        userType: loggedInUserType,
        programId: programId,
        offset: resetData ? 1 : applicantsPageNumber,
        limit: applicantsPerPage,
        ...(filterStageTitle !== 'All' && { filterStageTitle }),
        ...(filterString && { filterString })
      };

      dispatch(getApplicants(payload));
    }
  };

  useEffect(() => {
    handleGetApplicants({ resetData: false });
  }, [applicantsPageNumber]);

  useEffect(() => {
    handleGetApplicants({ resetData: true });
  }, [window.location.search]);

  useEffect(() => {
    return () => {
      dispatch(resetApplicants());
    };
  }, []);
  useEffect(() => {
    if (programId) {
      getMemberData()
    }
  }, [programId]);

  async function getMemberData() {
    const response: any = await httpRequest(`company/get-members?companyId=${myCompany?.id}`, 'GET', null, 'json', 'company')
    if (response?.data?.users?.length > 0) {
      setKeyMemberData(response?.data?.users)
    }
  }
  const evaluationArray = selectedApplicant?.map((applicant: TApplicant) => {
    return (
      {
        applicationTitle: `${applicant?.User?.name}`,
        userId: `${applicant?.userId}`,
        applicationId: `${applicant?.id}`,
        stageId: `${applicant?.ProgramApplicationStage?.id}`
      }
    )
  })
  function validateEmail(email: string) {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  }
  async function handleInvite(email: string, type: string) {
    if (email) {
      if (!validateEmail(email)) {
        dispatch(
          openPopup({
            popupMessage: 'Email is not valid',
            popupType: 'error'
          })
        )
        return
      }
      if (selectedApplicant?.length === 0) {
        dispatch(
          openPopup({
            popupMessage: 'Please select applicants',
            popupType: 'error'
          })
        );
        return
      }
      const body = {
        mentorEmail: email,
        programId: `${viewProgram?.id}`,
        userType: loggedInUserType,
        evaluationArray
      }
      const repsonse = await httpRequest('application/sendForEvaluation', 'POST', body, 'json', 'program');
      if (repsonse.status) {
        setNonPlatformEmail('')
        setSelectApplicant([])
        dispatch(
          openPopup({
            popupMessage: 'invitation sent successfully',
            popupType: 'success'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: repsonse?.message,
            popupType: 'error'
          })
        );
      }
    }
  }

  function handleApplicant(e: React.ChangeEvent<HTMLInputElement>, applicant: any) {
    if (applicant === 'all') {
      if (e.target.checked) {
        setSelectApplicant(applicants)
      } else {
        setSelectApplicant([])
      }
    } else {
      if (e.target.checked) {
        setSelectApplicant([...selectedApplicant, applicant])
      } else {
        const filterApplicant = selectedApplicant?.filter((item: any) => {
          return item !== 'all' && item?.id !== applicant?.id
        })
        setSelectApplicant([...filterApplicant])
      }
    }
  }
  return (
    <div className="view-program-submitted-applications">
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        Submitted Application
      </Typography>
      <div className='d-flex gap-2'>
        <div className={cx.dotDropdown}>
          {/* {stage?.stagesDetails && stage?.stagesDetails?.length > 0 && <div className={cx.dotDropdown}> */}
          <Dropdown>
            <Dropdown.Toggle id="dropdown-basic-6">
              Invite for rate
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                <div className={cx.body} onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => e.stopPropagation()}>
                  <div className={`${cx.applicantCard}`}>
                    <label className={cx.label}>
                      <span>Select Applicant</span>
                      <div className={cx.allSelect}>
                        <span>All</span>
                        <input
                          type="checkbox"
                          className={cx.checkBox}
                          checked={selectedApplicant?.length === applicants?.length}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleApplicant(e, 'all')}
                        />
                      </div>
                    </label>
                    <ul className={`${cx.applicantList}`}>
                      {applicants?.map((item: any) => {
                        return (
                          <li key={item?.User?._id}>
                            <span>{item?.User?.name}</span>
                            <span>
                              <input
                                type="checkbox"
                                checked={selectedApplicant?.some((applicant: any) => applicant?.id === item?.id)}
                                value={item?.User?._id}
                                className={cx.checkBox}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleApplicant(e, item)}
                              />
                            </span>
                          </li>
                        )
                      })}
                    </ul>
                  </div>
                  <div className={cx.nonPlatformUser}>
                    <InputField
                      className={cx.critriaInput}
                      label={'Mentor Email'}
                      id="criteriaName"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNonPlatformEmail(e.target.value)}
                      value={nonPlatformEmail}
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Mentor Email"
                      type="text"
                      errorMessage={''}
                    />
                    <button className={cx.sentButton} onClick={() => handleInvite(nonPlatformEmail, 'non-platform')}>
                      Send Invite
                    </button>
                  </div>
                  {keyMemberData?.length > 0 && <p style={{ textAlign: 'center', margin: '0' }}>or</p>}
                  <ul className={cx.mentorList}>
                    {keyMemberData?.map((member: any) => {
                      return (
                        <li key={member?.id} onClick={() => handleInvite(member.email, 'list')}>{member?.name}</li>
                      )
                    })}
                  </ul>
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        {/* <button  onClick={() => exportToExcel(applicants)}>Export to Excel</button> */}
        <div onClick={() => exportToExcel(applicants)} style={{ maxWidth: '250px', width: '100%' }}>
          <Button
            variant="primary"
            size="small"
            type="submit"
          >
            Export Applications to Excel
          </Button>
        </div>
      </div>
      <SearchSubmittedApplications
        updateParams={updateParams}
        increaseFetchApplicantsCounter={() => { }}
        status={viewProgram?.status}
      />
      <div className="view-program-submitted-applications__applicants">
        {applicants.map((applicant, index) => (
          <SubmittedApplicationCard key={index} {...applicant} />
        ))}
      </div>
    </div>
  );
};

export default SubmittedApplications;
