import { nanoid } from 'nanoid';
import Typography from 'src/components/common/typography/Typography';
import { useNavigate } from 'react-router-dom';
import { TFeature } from 'src/constants/landingpage.types';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { contactus } from 'src/store/auth/auth.slice';
import { useAppDispatch } from 'src/store/hooks';

const WhoIsHTractionFor = () => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigateToSignUp = () => {
    return navigate('/sign-up');
  };
  const [formData, setFormData] = useState({
    role: '',
    fullName: '',
    email: '',
    mobile: '',
    location: '',
    comments: 'new'
  });

  const handleChange = (e:{ target:{ name:string, value:string } }) => {
    console.log('name')
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = (e:React.FormEvent<HTMLFormElement>) => {
    console.log('Form submitted:', formData);
    e.preventDefault();
    dispatch(contactus(formData));
  };
  const items: TFeature[] = [
    {
      title: 'Aspiring & Early Stage Entrepreneurs',
      description: (
        <>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Fueling Dreams:
          </Typography>{' '}
          Aspiring Entrepreneurs and Early-stage Start-ups Ignite Your Potential Here
          <button className='contactButton' onClick={navigateToSignUp}>  Join Now!</button>
        </>
      )
    },
    {
      title: 'Investors, Mentors & Industry Experts',
      description: (
        <>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Powering Innovation:
          </Typography>{' '}
          Investors and Mentors, Your Gateway to Groundbreaking Ventures
          <button className='contactButton' onClick={handleShow}>  Contact Us!</button>
          <span className='inviteOnly'>(Invite only)</span>
        </>
      )
    },
    {
      title: (
        <>
          Incubators &<br />
          Accelerators
        </>
      ),
      description: (
        <>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Catalyzing Success:
          </Typography>{' '}
          Incubators and Accelerators, Supercharge Your Start-ups&apos; Growth
          <button className='contactButton' onClick={handleShow}>  Contact Us!</button>
          <span className='inviteOnly'>(Invite only)</span>
        </>
      )
    },
    {
      title: (
        <>
          Service
          <br />
          Providers
        </>
      ),
      description: (
        <>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Empowering Support:
          </Typography>{' '}
          Service Providers, Elevate Start-up Success Together.

          <button className='contactButton' onClick={handleShow}>  Contact Us!</button>
          <span className='inviteOnly'>(Invite only)</span>
        </>
      )
    }
  ];

  return (
    <>
      <div className="landing-page__htraction-for">
        <Typography
          as="h2"
          variant="heading-2"
          fontWeight="bold"
          className="landing-page__htraction-for__heading"
        >
          Who is{' '}
          <Typography
            as="span"
            variant="heading-2"
            fontWeight="bold"
            className="landing-page__htraction-for__heading--highlight"
          >
            htraction
          </Typography>{' '}
          for?
        </Typography>
        <div className="landing-page__htraction-for__items">
          {items.map((item: TFeature) => (
            <div key={nanoid()} className="landing-page__htraction-for__items__item">
              <Typography
                as="p"
                variant="subHeading-2"
                fontWeight="semiBold"
                className="landing-page__htraction-for__items__item__title"
              >
                {item.title}
              </Typography>
              <Typography
                as="p"
                variant="body-2"
                fontWeight="regular"
                className="landing-page__htraction-for__items__item__description"
              >
                {item.description}
              </Typography>
            </div>
          ))}
        </div>
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        centered
        className='contactPopup'
      >
         <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className='contactModal'>
          <Modal.Title>
            <h3>Reach out and <span>connect</span> with us.</h3>
            <p>Submit the form below to connect with us and join htraction as an incubator, accelerator, mentor, or investor.</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>I am<span>*</span></label>
                  <Form.Select
                    aria-label="Default select example"
                    name="role"
                    value={formData.role}
                    onChange={handleChange}
                  >
                    <option value="">Ex: Aspiring Entrepreneur </option>
                    <option value="Mentor">Mentor </option>
                    <option value="Investor">Investor </option>
                    <option value="Service Providers">Service Providers </option>
                    <option value="Incubator">Incubator </option>
                    <option value="Accelerator">Accelerator </option>
                  </Form.Select>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Full Name*</label>
                  <div className="form-input position-relative">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M8 10.0625C5.88661 10.0625 4.0072 11.0829 2.81066 12.6665C2.55313 13.0073 2.42437 13.1777 2.42858 13.408C2.43184 13.586 2.54357 13.8104 2.68357 13.9203C2.86479 14.0625 3.11591 14.0625 3.61815 14.0625H12.3818C12.8841 14.0625 13.1352 14.0625 13.3164 13.9203C13.4564 13.8104 13.5682 13.586 13.5714 13.408C13.5756 13.1777 13.4469 13.0073 13.1893 12.6665C11.9928 11.0829 10.1134 10.0625 8 10.0625Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      <path d="M8 8.0625C9.65685 8.0625 11 6.71935 11 5.0625C11 3.40565 9.65685 2.0625 8 2.0625C6.34314 2.0625 5 3.40565 5 5.0625C5 6.71935 6.34314 8.0625 8 8.0625Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <input
                      type="text"
                      className='form-control'
                      placeholder='Full Name'
                      name="fullName"
                      value={formData.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Email Address*</label>
                  <div className="form-input position-relative">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.3333 12.0625L9.90477 8.0625M6.09525 8.0625L1.6667 12.0625M1.33334 4.72917L6.77662 8.53947C7.2174 8.84801 7.43779 9.00229 7.67752 9.06204C7.88927 9.11483 8.11075 9.11483 8.3225 9.06204C8.56223 9.00229 8.78262 8.84801 9.2234 8.53947L14.6667 4.72917M4.53334 13.3958H11.4667C12.5868 13.3958 13.1468 13.3958 13.5747 13.1779C13.951 12.9861 14.2569 12.6801 14.4487 12.3038C14.6667 11.876 14.6667 11.3159 14.6667 10.1958V5.92917C14.6667 4.80907 14.6667 4.24901 14.4487 3.82119C14.2569 3.44487 13.951 3.13891 13.5747 2.94716C13.1468 2.72917 12.5868 2.72917 11.4667 2.72917H4.53334C3.41324 2.72917 2.85319 2.72917 2.42536 2.94716C2.04904 3.13891 1.74308 3.44487 1.55133 3.82119C1.33334 4.24901 1.33334 4.80907 1.33334 5.92917V10.1958C1.33334 11.3159 1.33334 11.876 1.55133 12.3038C1.74308 12.6801 2.04904 12.9861 2.42536 13.1779C2.85319 13.3958 3.41324 13.3958 4.53334 13.3958Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <input
                      type="email"
                      className='form-control'
                      placeholder='Email Address'
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Contact number<span>*</span></label>
                  <div className="form-input position-relative">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M14.3333 12.0625L9.90477 8.0625M6.09525 8.0625L1.6667 12.0625M1.33334 4.72917L6.77662 8.53947C7.2174 8.84801 7.43779 9.00229 7.67752 9.06204C7.88927 9.11483 8.11075 9.11483 8.3225 9.06204C8.56223 9.00229 8.78262 8.84801 9.2234 8.53947L14.6667 4.72917M4.53334 13.3958H11.4667C12.5868 13.3958 13.1468 13.3958 13.5747 13.1779C13.951 12.9861 14.2569 12.6801 14.4487 12.3038C14.6667 11.876 14.6667 11.3159 14.6667 10.1958V5.92917C14.6667 4.80907 14.6667 4.24901 14.4487 3.82119C14.2569 3.44487 13.951 3.13891 13.5747 2.94716C13.1468 2.72917 12.5868 2.72917 11.4667 2.72917H4.53334C3.41324 2.72917 2.85319 2.72917 2.42536 2.94716C2.04904 3.13891 1.74308 3.44487 1.55133 3.82119C1.33334 4.24901 1.33334 4.80907 1.33334 5.92917V10.1958C1.33334 11.3159 1.33334 11.876 1.55133 12.3038C1.74308 12.6801 2.04904 12.9861 2.42536 13.1779C2.85319 13.3958 3.41324 13.3958 4.53334 13.3958Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    </svg>
                    <input
                      type="text"
                      className='form-control'
                      placeholder='Contact number'
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="form-group">
                  <label>Location</label>
                  <div className="form-input position-relative">
                    <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_2_128)">
                        <path d="M7.99999 8.72916C9.10456 8.72916 9.99999 7.83373 9.99999 6.72916C9.99999 5.62459 9.10456 4.72916 7.99999 4.72916C6.89542 4.72916 5.99999 5.62459 5.99999 6.72916C5.99999 7.83373 6.89542 8.72916 7.99999 8.72916Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M7.99999 14.7292C10.6667 12.0625 13.3333 9.67468 13.3333 6.72916C13.3333 3.78364 10.9455 1.39583 7.99999 1.39583C5.05447 1.39583 2.66666 3.78364 2.66666 6.72916C2.66666 9.67468 5.33332 12.0625 7.99999 14.7292Z" stroke="#898989" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_2_128">
                          <rect width="16" height="16" fill="white" transform="translate(0 0.0625)" />
                        </clipPath>
                      </defs>
                    </svg>
                    <input
                      type="text"
                      className='form-control'
                      placeholder='location'
                      name="location"
                      value={formData.location}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" className='active' type="submit">
            Submit
          </Button>
        </Modal.Footer>
         </Form>
      </Modal>
    </>
  );
};

export default WhoIsHTractionFor;
