import { Card, Typography } from 'src/components/common';
import { useAppSelector } from 'src/store/hooks';
import { TPost } from 'src/services/apiEndpoint.types.ts';

import './postAnalytics.scss';

export type TPostAnalyticsStat = {
  label: string;
  value: number;
};

const PostAnalytics = () => {
  const newsFeed = useAppSelector((store) => store.socialMedia.newsFeed);

  const post: TPost = newsFeed[0] || { };

  const postStats: TPostAnalyticsStat[] = [
    { label: 'Total Views', value: 0 },
    { label: 'Total Likes', value: post?.post?.likeCount },
    { label: 'Total Comments', value: post?.post?.commentCount },
    { label: 'Total Saved', value: 0 }
  ];

  return (
    <Card className="post-analytics">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        Post’s Analytics
      </Typography>
      <div className="post-analytics__stats">
        {postStats.map(({ label = '', value = 0 }: TPostAnalyticsStat, index) => {
          return (
            <div className="post-analytics__stats__stat" key={index}>
              <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                className="post-analytics__stats__stat__label"
              >
                {label}
              </Typography>
              <Typography
                as="p"
                variant="body-1"
                fontWeight="semiBold"
                className="post-analytics__stats__stat__value"
              >
                {value}
              </Typography>
            </div>
          );
        })}
      </div>
    </Card>
  );
};

export default PostAnalytics;
