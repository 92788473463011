import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const UploadIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="22"
      height="21"
      viewBox="0 0 22 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3 14.7422C1.79401 13.935 1 12.5602 1 11C1 8.65643 2.79151 6.73129 5.07974 6.51937C5.54781 3.67213 8.02024 1.5 11 1.5C13.9798 1.5 16.4522 3.67213 16.9203 6.51937C19.2085 6.73129 21 8.65643 21 11C21 12.5602 20.206 13.935 19 14.7422M7 14.5L11 10.5M11 10.5L15 14.5M11 10.5V19.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
