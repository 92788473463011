import { TUserTypes } from 'src/constants/user.constants';
import { TFollowRequest, TUnFollowRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import { follow, unFollow } from 'src/store/socialMedia/socialMedia.slice';

export type THandleFollow = {
  following: boolean;
  viewerId: string;
  loggedInUserType: TUserTypes;
  followUserId: string;
  followUserType: TUserTypes;
  followName: string;
};

const useHandleFollow = () => {
  // Hooks
  const dispatch = useAppDispatch();

  const handleFollow = ({
    following,
    viewerId,
    loggedInUserType,
    followUserId,
    followUserType,
    followName
  }: THandleFollow) => {
    if (following) {
      const payload: TUnFollowRequest = {
        userId: viewerId,
        userType: loggedInUserType,
        followUserId,
        followUserType
      };

      dispatch(unFollow(payload));
    } else {
      const payload: TFollowRequest = {
        userId: viewerId,
        userType: loggedInUserType,
        followUserId,
        followUserType
      };

      dispatch(follow(payload));
    }
  };

  return handleFollow;
};

export default useHandleFollow;
