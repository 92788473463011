import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useTimer } from 'react-timer-hook';
import classNames from 'classnames';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import VerificationCode from 'src/components/common/formFields/verificationCode/VerificationCode';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import { EyeIcon, EyeOffIcon, PasswordIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  confirmForgotPassword,
  pauseResendSignupOtpTimer,
  resendNewPasswordOtp
} from 'src/store/auth/auth.slice';
import { toggleTouchedFields } from 'src/utils';
import { useUpdateFormValue, useValidateErrors } from 'src/hooks';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';

import './setNewPassword.scss';
import { setNewPasswordFormFields } from './setNewPassword.constants';
import setNewPasswordValidationSchema from './setNewPassword.validationSchema';

const timerDurationInSeconds = 30;

const time = new Date();
time.setSeconds(time.getSeconds());

export type TSetNewPasswordFormValues = {
  password: string;
  confirmPassword: string;
  otp: string;
};

export type TSetNewPasswordErrors = { password: string; confirmPassword: string; otp: string };

const allFieldsUntouched = toggleTouchedFields({
  fields: setNewPasswordFormFields,
  isTouched: false
});
const allFieldsTouched = toggleTouchedFields({ fields: setNewPasswordFormFields, isTouched: true });

const SetNewPassword = () => {
  const [formValues, setFormValues] = useState<TSetNewPasswordFormValues>(setNewPasswordFormFields);
  const [touchedFormFields, setTouchedFormFields] =
    useState<Record<keyof TSetNewPasswordFormValues, boolean>>(allFieldsUntouched);
  const [errors, setErrors] =
    useState<Record<keyof TSetNewPasswordErrors, string>>(setNewPasswordFormFields);
  const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
  const [disableResendButton, setDisableResendButton] = useState<boolean>(false);
  const [maskPassword, setMaskPassword] = useState<{ password: boolean; confirmPassword: boolean }>(
    { password: true, confirmPassword: true }
  );

  // Hooks
  const { updateFormValue } = useUpdateFormValue();
  const { validateErrors } = useValidateErrors();

  const { forgotPasswordEmail, loading, startResendSignupOtpTimer } = useAppSelector(
    (store) => store.auth
  );
  const dispatch = useAppDispatch();

  const updateForm = ({ fieldName, value }: { fieldName: string; value: string }) => {
    const setFormValue = setFormValues as Dispatch<SetStateAction<Record<string, string>>>;
    updateFormValue({ fieldName, value, setFormValue });
  };

  const validateForm = async () => {
    const validationValues = { ...formValues, otp: verificationCode };
    return await validateErrors({
      validationSchema: setNewPasswordValidationSchema,
      formValues: validationValues,
      errorsInitialState: setNewPasswordFormFields,
      setErrors
    });
  };

  const { seconds, restart, pause, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      dispatch(pauseResendSignupOtpTimer());
      setDisableResendButton(false);
    }
  });

  const resendSignupOTPSubmit = async () => {
    // request new OTP email verification
    dispatch(
      resendNewPasswordOtp({
        email: forgotPasswordEmail
      })
    );
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouchedFormFields(allFieldsTouched);

    const validationErrors = await validateForm();

    if (validationErrors?.length) {
      return validateErrors;
    }

    // login api call
    dispatch(
      confirmForgotPassword({
        email: forgotPasswordEmail,
        otp: verificationCode.join(''),
        password: formValues.password
      })
    );
  };

  useEffect(() => {
    validateForm();
  }, [JSON.stringify(formValues), JSON.stringify(verificationCode)]);

  useEffect(() => {
    /*
     ** Stop the timer on initial load
     */
    pause();
  }, []);

  useEffect(() => {
    if (startResendSignupOtpTimer) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + timerDurationInSeconds);
      restart(time);
      setDisableResendButton(true);
    }
  }, [startResendSignupOtpTimer]);

  return (
    <div className="set-new-password">
      <BackNavigation text="Back to forgot password" route="/forgot-password" />
      <Typography
        as="h3"
        variant="heading-3"
        fontWeight="semiBold"
        className="set-new-password__heading"
      >
        Set New Password
      </Typography>{' '}
      <Typography
        as="p"
        variant="body-2"
        fontWeight="regular"
        className="set-new-password__caption"
      >
        Reimagine. Reset...Secure your path forward
      </Typography>
      {/* Form starts */}
      <form className="set-new-password__form" onSubmit={handleSubmit} noValidate>
        {/* NoValidate is used, because yup is used to validate the user input */}
        {/* Verification code */}
        <div className="set-new-password__form__verification-code">
          <VerificationCode
            label="Enter verification code"
            id="verificationCode"
            fieldsCount={6}
            className="set-new-password__form__verification-code__input"
            value={verificationCode}
            setVerificationCode={setVerificationCode}
          />{' '}
          {touchedFormFields.otp && errors.otp && <ErrorMessage message={errors.otp} />}
          <div className="set-new-password__form__verification-code__resend-code">
            <Typography
              as="span"
              variant="caption"
              fontWeight="semiBold"
              className={classNames(
                'set-new-password__form__verification-code__resend-code__link',
                {
                  'set-new-password__form__verification-code__resend-code__link--disabled':
                    disableResendButton
                }
              )}
              onClick={resendSignupOTPSubmit}
            >
              Re-send verification code
            </Typography>
            {isRunning && (
              <Typography as="span" variant="caption" fontWeight="semiBold">
                00:{seconds}
              </Typography>
            )}
          </div>
        </div>

        <hr className="set-new-password__form__hr"></hr>

        {/* Enter new password */}
        <InputField
          label="Enter New Password"
          id="password"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="New Password"
          startIcon={<PasswordIcon />}
          endIcon={maskPassword.password ? <EyeOffIcon /> : <EyeIcon />}
          maskField={maskPassword.password}
          endIconClickHandler={() =>
            setMaskPassword((prev) => ({ ...prev, password: !prev.password }))
          }
          onChange={(e) => {
            updateForm({ fieldName: 'password', value: e.target.value });
          }}
          errorMessage={errors.password}
          isTouched={touchedFormFields.password}
        />

        {/* Confirm password */}
        <InputField
          label="Confirm Password"
          id="confirmPassword"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Confirm Password"
          startIcon={<PasswordIcon />}
          endIcon={maskPassword.confirmPassword ? <EyeOffIcon /> : <EyeIcon />}
          maskField={maskPassword.confirmPassword}
          endIconClickHandler={() =>
            setMaskPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
          }
          onChange={(e) => {
            updateForm({ fieldName: 'confirmPassword', value: e.target.value });
          }}
          errorMessage={errors.confirmPassword}
          isTouched={touchedFormFields.confirmPassword}
        />

        <Button variant="primary" size="small" type="submit" loading={loading}>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Continue
          </Typography>
        </Button>
      </form>
      {/* Form ends */}
    </div>
  );
};

export default SetNewPassword;
