import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'src/components/button/Button';

import { monthsOfYear } from './selectMonthYear.constants';
import './selectMonthYear.scss';

import Typography from '../typography/Typography';

export type TSelectMonthYear = {
  isOpen: boolean;
  onClose: () => void;
  startYear: number;
  yearsRange: number;
  currentMonth: string;
  currentYear: number;
  onSelection: ({ month, year }: { month: string; year: number }) => void;
} & HTMLAttributes<HTMLDivElement>;

const SelectMonthYear = ({
  isOpen = false,
  onClose,
  startYear,
  yearsRange,
  currentMonth = '01',
  currentYear = new Date().getFullYear(),
  onSelection,
  className,
  ...rest
}: TSelectMonthYear) => {
  const [selectedMonth, setSelectedMonth] = useState(currentMonth);
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const monthsRef = useRef<HTMLDivElement>(null);
  const yearsRef = useRef<HTMLDivElement>(null);
  const years = [...new Array(yearsRange).keys()].map((item) => startYear + item);
  const currDate : Date = new Date();
  const currYear = currDate.getFullYear();
  const filteredYears = years.filter(year => year <= currYear);
  useEffect(() => {
    const monthEle = monthsRef.current;
    const yearEle = yearsRef.current;
    if (monthEle) {
      const selectedMonthIndex = monthsOfYear.findIndex((mn) => mn.value === selectedMonth);
      monthEle.scrollTop = (selectedMonthIndex - 1) * 35;
    }
    if (yearEle) {
      yearEle.scrollTop = (selectedYear - startYear - 1) * 35;
    }
  }, [yearsRef]);

  return (
    <div
      className={classNames('select-month-year-outer', className, {
        'modal-component-outer--is-open': isOpen
      })}
      {...rest}
    >
      <div className="select-month-year">
        <Typography
          as="p"
          variant="body-2"
          fontWeight="medium"
          className="select-month-year__heading"
        >
          Month and year selector
        </Typography>

        <div className="select-month-year__selection">
          <div className="select-month-year__selection__col" ref={monthsRef}>
            {monthsOfYear.map((month, index) => {
              return (
                <div
                  className={classNames('select-month-year__selection__col__item', {
                    'select-month-year__selection__col__item--is-selected':
                      selectedMonth === month.value
                  })}
                  key={index}
                  onClick={() => setSelectedMonth(month.value)}
                >
                  <Typography as="span" variant="body-2" fontWeight="regular">
                    {month.label}
                  </Typography>
                </div>
              );
            })}
          </div>
          <div className="select-month-year__selection__col" ref={yearsRef}>
            {filteredYears.map((year, index) => {
              return (
                <div
                  className={classNames('select-month-year__selection__col__item', {
                    'select-month-year__selection__col__item--is-selected': selectedYear === year
                  })}
                  key={index}
                  onClick={() => setSelectedYear(year)}
                >
                  <Typography as="span" variant="body-2" fontWeight="regular">
                    {year}
                  </Typography>
                </div>
              );
            })}
          </div>
        </div>

        <div className="select-month-year__buttons">
          <Button size="extraSmall" variant="tertiary" onClick={onClose}>
            Cancel
          </Button>
          <Button
            size="extraSmall"
            variant="tertiary"
            onClick={() => onSelection({ month: selectedMonth, year: selectedYear })}
          >
            OK
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SelectMonthYear;
