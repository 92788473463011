/*
 ** Useful to get value of the given url search parameter
 */
const useUrlParamValue = () => {
  const paramValue = ({ paramName }: { paramName: string }) => {
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.get(paramName);

    return value;
  };

  return { paramValue };
};

export default useUrlParamValue;
