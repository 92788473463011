import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const HomeIcon = ({ color = colors.neutral[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M13.4823 3.264C13.131 2.99075 12.9553 2.85412 12.7613 2.8016C12.5902 2.75526 12.4098 2.75526 12.2387 2.8016C12.0447 2.85412 11.869 2.99075 11.5177 3.264L4.73539 8.53912C4.28202 8.89175 4.05534 9.06806 3.89203 9.28886C3.74737 9.48444 3.6396 9.70478 3.57403 9.93905C3.5 10.2035 3.5 10.4907 3.5 11.0651V18.3C3.5 19.4201 3.5 19.9801 3.71799 20.408C3.90973 20.7843 4.21569 21.0903 4.59202 21.282C5.01984 21.5 5.5799 21.5 6.7 21.5H8.7C8.98003 21.5 9.12004 21.5 9.227 21.4455C9.32108 21.3976 9.39757 21.3211 9.4455 21.227C9.5 21.12 9.5 20.98 9.5 20.7V14.1C9.5 13.5399 9.5 13.2599 9.60899 13.046C9.70487 12.8578 9.85785 12.7049 10.046 12.609C10.2599 12.5 10.5399 12.5 11.1 12.5H13.9C14.4601 12.5 14.7401 12.5 14.954 12.609C15.1422 12.7049 15.2951 12.8578 15.391 13.046C15.5 13.2599 15.5 13.5399 15.5 14.1V20.7C15.5 20.98 15.5 21.12 15.5545 21.227C15.6024 21.3211 15.6789 21.3976 15.773 21.4455C15.88 21.5 16.02 21.5 16.3 21.5H18.3C19.4201 21.5 19.9802 21.5 20.408 21.282C20.7843 21.0903 21.0903 20.7843 21.282 20.408C21.5 19.9801 21.5 19.4201 21.5 18.3V11.0651C21.5 10.4907 21.5 10.2035 21.426 9.93905C21.3604 9.70478 21.2526 9.48444 21.108 9.28886C20.9447 9.06806 20.718 8.89175 20.2646 8.53913L13.4823 3.264Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
