import * as Yup from 'yup';
import { EMAIL } from 'src/constants/regex.constants';
import { InviteMentorInvestorRoles } from 'src/constants/user.constants';

export const inviteMentorInvestorValidationSchema = Yup.object().shape({
  name: Yup.string().required('Please enter full name'),
  email: Yup.string()
    .matches(EMAIL, 'Please enter a valid email address')
    .required('Please enter email'),
  role: Yup.mixed<InviteMentorInvestorRoles>()
    .oneOf(Object.values(InviteMentorInvestorRoles), 'Please select a role from user type list')
    .required('Please select the role')
});

export default inviteMentorInvestorValidationSchema;
