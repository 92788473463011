import { MouseEvent } from 'react';
import {
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Tooltip,
  Typography
} from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { TIdeaCollaborationType } from 'src/components/ideaHub/ideaHub.types';
import { SingleChevronIcon } from 'src/components/common/common.icons';
import { textEllipsis } from 'src/utils';

import './selectedCollaborator.scss';

export type TSelectedCollaborator = {
  id: string;
  profilePic: string;
  name: string;
  role: string;
  email: string;
  handleRemoveUser: () => void;
  collaborationType: TIdeaCollaborationType;
  updatedCollaborationType: ({ id, type }: { id: string; type: TIdeaCollaborationType }) => void;
};

const selectedCollaboratorDropdown: string = 'selectedCollaboratorDropdown';

const SelectedCollaborator = ({
  id,
  collaborationType,
  profilePic = '',
  name = '',
  email = '',
  handleRemoveUser,
  updatedCollaborationType
}: TSelectedCollaborator) => {
  // Hooks
  const dispatch = useAppDispatch();
  const dropdownId: string = useAppSelector((store) => store.common.dropdownId);

  const nameEllipsis = textEllipsis({ charLimit: 14, text: name });
  const emailEllipsis = textEllipsis({ charLimit: 18, text: email });

  const ideaFilterDropdownItems: TDropdownOptions[] = [
    {
      label: 'Co-Founder',
      onClick: () => {
        updatedCollaborationType({ id, type: 'CoFounder' });
      }
    },
    {
      label: 'Edit',
      onClick: () => {
        updatedCollaborationType({ id, type: 'Edit' });
      }
    },
    {
      label: 'Discussion',
      onClick: () => {
        updatedCollaborationType({ id, type: 'Discussion' });
      }
    },
    {
      label: 'View',
      onClick: () => {
        updatedCollaborationType({ id, type: 'View' });
      }
    },
    {
      label: 'Remove user',
      onClick: handleRemoveUser
    }
  ];

  const selectedCollaboratorDropdownProps: TDropdown = {
    id: `${selectedCollaboratorDropdown}-${id}`,
    size: 'large',
    alignment: 'left',
    items: ideaFilterDropdownItems
  };

  const openIdeaDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(selectedCollaboratorDropdownProps.id));
    }
  };

  const collaborationTypeMapping: Record<TIdeaCollaborationType, string> = {
    View: 'View',
    Edit: 'Edit',
    Discussion: 'Discussion',
    CoFounder: 'Co-Founder'
  };

  return (
    <div className="selected-collaborator">
      <div className="selected-collaborator__user-info">
        <ProfilePicture
          url={profilePic || 'NA'}
          fallback={name[0]}
          className="selected-collaborator__user-info__profile-pic"
        />
        <div className="selected-collaborator__user-info__user">
          <div className="selected-collaborator__user-info__user__row">
            <Tooltip text={name} conditionToDisplay={nameEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="semiBold"
                className="selected-collaborator__user-info__user__row__name"
              >
                {nameEllipsis?.text}
              </Typography>
            </Tooltip>
          </div>
          <Tooltip text={email} conditionToDisplay={emailEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="selected-collaborator__user-info__user__row__email"
            >
              {emailEllipsis?.text}
            </Typography>
          </Tooltip>
        </div>
      </div>

      <div className="selected-collaborator__dropdown">
        <Button
          variant="tertiary"
          size="extraSmall"
          endIcon={<SingleChevronIcon />}
          onClick={openIdeaDropdown}
        >
          <Typography variant="body-2" fontWeight="semiBold">
            {collaborationTypeMapping?.[collaborationType]}
          </Typography>
        </Button>
        <SubMenuModal>
          <Dropdown {...selectedCollaboratorDropdownProps} />
        </SubMenuModal>
      </div>
    </div>
  );
};

export default SelectedCollaborator;
