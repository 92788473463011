import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ReloadIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0.833984 8.39583C0.833984 8.39583 0.914865 8.962 3.25801 11.3051C5.60116 13.6483 9.40015 13.6483 11.7433 11.3051C12.5735 10.475 13.1095 9.46202 13.3514 8.39583M0.833984 8.39583V12.3958M0.833984 8.39583H4.83398M14.1673 5.72917C14.1673 5.72917 14.0864 5.16301 11.7433 2.81986C9.40015 0.476713 5.60116 0.476713 3.25801 2.81986C2.42783 3.65004 1.89178 4.66298 1.64987 5.72917M14.1673 5.72917V1.72917M14.1673 5.72917H10.1673"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReloadIcon;
