import { FC } from 'react';
import { Typography } from 'src/components/common';

import './ideaLabelValue.scss';

export type TIdeaLabelValue = {
  label: string;
  value: string;
};

const IdeaLabelValue: FC<TIdeaLabelValue> = ({ label, value }: TIdeaLabelValue) => {
  return (
    <div className="idea-label-value">
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        {label}
      </Typography>
      <Typography as="p" variant="body-2" fontWeight="regular">
        {value}
      </Typography>
    </div>
  );
};

IdeaLabelValue.defaultProps = {
  label: '',
  value: ''
};

export default IdeaLabelValue;
