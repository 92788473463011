import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  TClearDirectMessageChatResponse,
  TDeleteDirectMessageResponse,
  TDislikeConversationMessageResponse,
  TDislikeConversationMessageSuccess,
  TEditDirectMessageResponse,
  TGetIdeaConversationMessagesRequest,
  TGetOneToOneConversationMessagesRequest,
  TLikeConversationMessageResponse,
  TLikeConversationMessageSuccess,
  TReceiveMessageResponse
} from 'src/services/apiEndpoint.types.ts';
import {
  clearDirectMessageSuccess,
  deleteDirectMessageSuccess,
  dislikeMessageSuccess,
  editDirectMessageSuccess,
  getIdeaConversationMessages,
  getOneToOneConversationMessages,
  incrementOneToOneMessagesPageNumber,
  likeConversationMessageSuccess,
  receiveMessageSuccess,
  resetMessages
} from 'src/store/chat/chat.slice';
import { socketService } from 'src/services/socket.api.service';
import { useGetIdentity } from 'src/hooks';
import { ScrollTopPagination } from 'src/components/common';

import './chatMessagesBody.scss';
import { TChatMessagesBodyProps } from './chatMessagesBody.types';

import ChatMessage from '../chatMessage/ChatMessage';

const ChatMessagesBody = ({
  selectedUserId,
  selectedUserType,
  conversationId,
  updateMessageSettings,
  directMessageContext
}: TChatMessagesBodyProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const {
    messages,
    oneToOneChatMessagesPageNumber,
    oneToOneChatMessagesItemsPerPage,
    oneToOneChatMessagesMoreItemsLeft
  } = useAppSelector((store) => store.chat);
  const { itemListLoader } = useAppSelector((store) => store.common);

  // Constants
  const { loggedInUserType, authenticatedId } = getIdentities();
  const chatMessages = messages[conversationId] ? messages[conversationId] : [];

  console.log(chatMessages, messages, 'chatMessages');
  const onSocketConnection = () => {
    console.log('Socket connected');
  };

  const onSocketConversations = (event: TReceiveMessageResponse) => {
    dispatch(receiveMessageSuccess(event));
    socketService.emit('get-conversation-unread-message-count', {
      userId: authenticatedId,
      userType: loggedInUserType
    });
  };

  const editLocalMessageState = (event: TEditDirectMessageResponse) => {
    dispatch(editDirectMessageSuccess(event));
  };

  const deleteLocalMessageState = (event: TDeleteDirectMessageResponse) => {
    dispatch(deleteDirectMessageSuccess(event));
  };

  const clearLocalChat = (event: TClearDirectMessageChatResponse) => {
    dispatch(clearDirectMessageSuccess(event));
  };

  const likeLocalMessageState = (event: TLikeConversationMessageResponse) => {
    const payload: TLikeConversationMessageSuccess = {
      conversationId,
      messageId: event?.messageId
    };
    dispatch(likeConversationMessageSuccess(payload));
  };

  const dislikeLocalMessageState = (event: TDislikeConversationMessageResponse) => {
    const payload: TDislikeConversationMessageSuccess = {
      conversationId,
      messageId: event?.messageId
    };
    dispatch(dislikeMessageSuccess(payload));
  };

  const getChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetOneToOneConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId,
      limit: oneToOneChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : oneToOneChatMessagesPageNumber
    };

    dispatch(getOneToOneConversationMessages(payload));
  };

  const getIdeaChatMessages = ({ resetMessages }: { resetMessages: boolean }) => {
    const payload: TGetIdeaConversationMessagesRequest = {
      userId: authenticatedId,
      userType: loggedInUserType,
      conversationId,
      limit: oneToOneChatMessagesItemsPerPage,
      offset: resetMessages ? 1 : oneToOneChatMessagesPageNumber
    };

    dispatch(getIdeaConversationMessages(payload));
  };

  const loadMoreItems = () => {
    if (oneToOneChatMessagesMoreItemsLeft && !itemListLoader) {
      dispatch(incrementOneToOneMessagesPageNumber(1));
    }
  };

  useEffect(() => {
    if (selectedUserId) {
      socketService.connect();

      socketService.on('connect', onSocketConnection);

      socketService.emit('join', {
        userId: authenticatedId,
        conversationId
      });

      socketService.on('conversation', onSocketConversations);
      socketService.on('edit-conversation-message', editLocalMessageState);
      socketService.on('delete-conversation-message', deleteLocalMessageState);
      socketService.on('clear-conversation', clearLocalChat);

      socketService.on('idea-conversation', onSocketConversations);
      socketService.on('edit-idea-conversation-message', editLocalMessageState);
      socketService.on('delete-idea-conversation-message', deleteLocalMessageState);
      socketService.on('clear-idea-conversation', clearLocalChat);
      socketService.on('like-conversation-message', likeLocalMessageState);
      socketService.on('dislike-conversation-message', dislikeLocalMessageState);
      socketService.on('like-idea-conversation-message', likeLocalMessageState);
      socketService.on('dislike-idea-conversation-message', dislikeLocalMessageState);
    }

    return () => {
      socketService.off('connect', onSocketConnection);
      socketService.off('conversation', onSocketConversations);
      socketService.off('edit-conversation-message', editLocalMessageState);
      socketService.off('delete-conversation-message', deleteLocalMessageState);
      socketService.off('clear-conversation', clearLocalChat);
      socketService.off('idea-conversation', onSocketConversations);
      socketService.off('edit-idea-conversation-message', editLocalMessageState);
      socketService.off('delete-idea-conversation-message', deleteLocalMessageState);
      socketService.off('clear-idea-conversation', clearLocalChat);
      socketService.off('like-conversation-message', likeLocalMessageState);
      socketService.off('dislike-conversation-message', dislikeLocalMessageState);
      socketService.off('like-idea-conversation-message', likeLocalMessageState);
      socketService.off('dislike-idea-conversation-message', dislikeLocalMessageState);
      socketService.disconnect();
    };
  }, [selectedUserId, conversationId]);

  useEffect(() => {
    if (conversationId && directMessageContext === 'directMessage') {
      getChatMessages({ resetMessages: true });
    } else if (conversationId && directMessageContext === 'ideaDirectMessage') {
      getIdeaChatMessages({ resetMessages: true });
    }
  }, [conversationId]);

  useEffect(() => {
    if (conversationId && directMessageContext === 'directMessage') {
      getChatMessages({ resetMessages: false });
    } else if (conversationId && directMessageContext === 'ideaDirectMessage') {
      getIdeaChatMessages({ resetMessages: false });
    }
  }, [oneToOneChatMessagesPageNumber]);

  useEffect(() => {
    dispatch(resetMessages());

    return () => {
      dispatch(resetMessages());
    };
  }, [conversationId]);

  return (
    <ScrollTopPagination
      id={conversationId}
      className="chat-messages-body"
      onScrollTop={loadMoreItems}
      loading={itemListLoader}
      moreItemsLeft={oneToOneChatMessagesMoreItemsLeft}
      scrollToBottomOnLoad={true}
      offset={oneToOneChatMessagesPageNumber}
    >
      {chatMessages.map((message, index) => (
        <ChatMessage
          key={index}
          {...message}
          loggedInUserId={authenticatedId}
          loggedInUserType={loggedInUserType}
          updateMessageSettings={updateMessageSettings}
          directMessageContext={directMessageContext}
        />
      ))}
    </ScrollTopPagination>
  );
};

export default ChatMessagesBody;
