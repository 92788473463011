const useOpenInNewTab = () => {
  const openInNewTab = ({ url }: { url: string }) => {
    if (url) {
      let urlToOpen = url;
      if (!url.startsWith('http://') && !url.startsWith('https://')) {
        urlToOpen = 'https://' + url;
      }
      window.open(urlToOpen, '_blank');
    }
  };
  return { openInNewTab };
};

export default useOpenInNewTab;
