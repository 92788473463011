import { useState } from 'react';
import classNames from 'classnames';
import { SubMenuModal } from 'src/components/common';
import { selectDeselectCheckbox } from 'src/utils';
import InputElement from 'src/components/common/formFields/inputElement/InputElement';
import { SearchIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { searchCities } from 'src/store/search/search.slice';
import { TSearchCitiesRequest } from 'src/services/apiEndpoint.types.ts';
import useDebounce from 'src/hooks/useDebounce/useDebounce';

import { TFilterDropdownOptions } from '../filters/filtersList';
import { TSearchQueryTypes } from '../filters/Filters';

export type TMultiSelect = {
  filterType: TSearchQueryTypes;
  queryValue: string;
  openFilters: boolean;
  closeFilterDropdown: () => void;
  dropDownOptions: TFilterDropdownOptions[];
  updateParams: ({ type, value }: { type: TSearchQueryTypes; value: string }) => void;
  resultType: string;
};

const MultiSelect = ({
  resultType,
  openFilters,
  closeFilterDropdown,
  dropDownOptions,
  updateParams,
  filterType,
  queryValue
}: TMultiSelect) => {
  const [cityQuery, setCityQuery] = useState<string>('');

  // Hooks
  const dispatch = useAppDispatch();

  const cities: string[] = useAppSelector((store) => store.search.cities);

  const hasCheckboxes = !(
    filterType === 'resultType' ||
    filterType === 'postedIn' ||
    filterType === 'connection'
  );

  const isLocation = filterType === 'location';

  const isSelectedFunc = ({ itemValue }: { itemValue: string }) => {
    if (!queryValue) {
      return false;
    } else if (hasCheckboxes) {
      return hasCheckboxes ? queryValue?.split(',').indexOf(itemValue) > -1 : false;
    } else {
      return queryValue === itemValue;
    }
  };

  const citiesDropdown: TFilterDropdownOptions[] = cities
    .filter((cityF) => {
      return cityF.toUpperCase().indexOf(cityQuery.toUpperCase()) > -1;
    })
    .map((city) => ({
      id: city,
      label: city,
      value: city,
      type: 'location'
    }));

  const dropdownFilters = isLocation ? citiesDropdown : dropDownOptions;

  const handleGetPlaces = () => {
    if (isLocation) {
      const payload: TSearchCitiesRequest = {
        type: resultType === 'people' ? 'People' : 'Mentor & Investor'
      };
      dispatch(searchCities(payload));
    }
  };

  useDebounce({ func: handleGetPlaces, delay: 500, dependency: resultType });

  return (
    <SubMenuModal setOpenSubMenuModal={closeFilterDropdown}>
      <ul
        className={classNames('search-filters__filter__options', {
          'search-filters__filter__options--is-open': openFilters,
          'search-filters__filter__options--is-location': isLocation
        })}
      >
        {isLocation && (
          <InputElement
            startIcon={<SearchIcon />}
            placeholder="Search location"
            variant={componentSizeVariantsEnum.SMALL}
            value={cityQuery}
            onChange={(e) => {
              setCityQuery(e.target.value);
            }}
          />
        )}
        {dropdownFilters.map((item, index) => {
          const isSelected = isSelectedFunc({ itemValue: `${item?.value}` });

          return (
            <div
              className={classNames('search-filters__filter__options__option', {
                'search-filters__filter__options__option--selected': isSelected
              })}
              key={index}
              onClick={() => {
                if (!hasCheckboxes) {
                  updateParams({ type: item?.type, value: (item?.value).toString() });
                  closeFilterDropdown();
                }
              }}
            >
              {hasCheckboxes && (
                <input
                  type="checkbox"
                  className="search-filters__filter__options__option__checkbox"
                  value={item.value}
                  checked={isSelected}
                  onChange={() => {
                    const checkboxValue = item?.value;

                    const newValue = selectDeselectCheckbox({
                      arr: queryValue === '' ? [] : queryValue.split(','),
                      value: checkboxValue
                    });

                    if (hasCheckboxes) {
                      updateParams({ type: item?.type, value: newValue.join(',') || '' });
                    }
                  }}
                />
              )}
              <li
                value={item.value}
                className={classNames('search-filters__filter__options__option__li')}
                tabIndex={0}
              >
                {item.label}
              </li>
            </div>
          );
        })}
      </ul>
    </SubMenuModal>
  );
};

export default MultiSelect;
