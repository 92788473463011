import { HTMLAttributes, ReactNode, useEffect, useRef } from 'react';
import classNames from 'classnames';

import './modal.scss';

import { CrossIcon } from '../common.icons';
import Typography from '../typography/Typography';

export type TModal = {
  isModalOpen: boolean; // whether the modal is open
  onModalClose: () => void; // function to run when the modal is closed
  title?: ReactNode; // title of the modal
  children: ReactNode; // content of the modal
} & HTMLAttributes<HTMLDivElement>;

const Modal = ({ isModalOpen, onModalClose, title, children, className, ...rest }: TModal) => {
  // Refs
  const commonModalRef = useRef<HTMLDivElement>(null);

  const handleEscapeKey = (event: KeyboardEvent) => {
    if (event.key === 'Escape' || event.key === 'Esc') {
      onModalClose();
    }
  };

  useEffect(() => {
    const ele = commonModalRef?.current;

    if (ele) {
      window.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      window?.addEventListener('keydown', handleEscapeKey);
    };
  }, [commonModalRef]);

  return (
    <div
      className={classNames('modal-component-outer', className, {
        'modal-component-outer--is-open': isModalOpen
      })}
      {...rest}
      ref={commonModalRef}
    >
      <div className={classNames('modal-component', `${className}__modal`)}>
        <div className="modal-component__header">
          <Typography
            as="span"
            variant="subHeading-1"
            fontWeight="semiBold"
            className="modal-component__header__title"
          >
            {title}
          </Typography>
          <div className="modal-component__header__close" onClick={onModalClose}>
            <CrossIcon />
          </div>
        </div>
        <div
          className={classNames('modal-component__content', {
            [`${className}__content`]: className
          })}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
