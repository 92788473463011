import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Card,
  ProfilePicture,
  SocialStat,
  TSocialStatProps,
  Typography
} from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useGetIdentity } from 'src/hooks';
import { TGetUserStatsRequest } from 'src/services/apiEndpoint.types.ts';
import { getMyCompanyStats } from 'src/store/socialMedia/socialMedia.slice';
import { getCompany } from 'src/store/company/company.slice';

import { TCompanyCardProps } from './companyCard.types';
import './companyCard.scss';

const CompanyCard = ({ selfCompany }: TCompanyCardProps) => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();
  const { myCompany, companyProfilePic } = useAppSelector((store) => store.company);
  const { myCompanyStats } = useAppSelector((store) => store.socialMedia);
  console.log(myCompanyStats, 'myCompanyStats');

  // Constants
  const { loggedInCompanyId, authenticatedId, loggedInUserType } = getIdentities();
  const companyName: string = selfCompany ? myCompany?.name : '';
  const companyType: string = selfCompany ? myCompany?.type : '';
  const companyIntroduction: string = selfCompany ? myCompany?.introduction : '';

  const companyStats: TSocialStatProps[] = [
    { label: 'Follower', value: myCompanyStats?.followerCount || 0 },
    { label: 'Post', value: myCompanyStats?.postCount || 0 },
    { label: 'Announcement', value: myCompanyStats?.announcementCount || 0 }
  ];

  const viewCompany = () => {
    if (selfCompany) {
      navigate('/my-company');
    }
  };

  const handleGetMyCompanyStats = () => {
    const payload: TGetUserStatsRequest = {
      userId: loggedInCompanyId,
      userType: 'Company',
      requestorId: authenticatedId,
      requestorType: loggedInUserType
    };

    dispatch(getMyCompanyStats(payload));
  };

  const handleGetCompany = () => {
    dispatch(getCompany({ id: loggedInCompanyId }));
  };

  useEffect(() => {
    if (loggedInCompanyId && selfCompany) {
      handleGetMyCompanyStats();
      handleGetCompany();
    }
  }, [loggedInCompanyId]);

  return (
    <Card className="company-card-component">
      <div className="company-card-component__header">
        <ProfilePicture
          url={companyProfilePic || ''}
          fallback={companyName?.[0]}
          userType="Company"
        />
        <div className="company-card-component__header__info">
          <Typography
            as="p"
            variant="body-1"
            fontWeight="semiBold"
            className="company-card-component__header__name"
          >
            {companyName}
          </Typography>
          <Typography
            as="p"
            variant="body-2"
            fontWeight="regular"
            className="company-card-component__header__type"
          >
            {companyType}
          </Typography>
        </div>
      </div>
      {companyIntroduction && (
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="company-card-component__introduction"
        >
          {companyIntroduction}
        </Typography>
      )}
      <div className="company-card-component__stats">
        {companyStats.map((stat, index) => {
          return <SocialStat {...stat} key={index} />;
        })}
      </div>
      <Button size="extraSmall" variant="secondary" onClick={viewCompany}>
        View {selfCompany && 'my '}Company
      </Button>
    </Card>
  );
};

export default CompanyCard;
