import moment from 'moment';

export type TCalculateDifferenceInMonthYears = { date1Str: string; date2Str: string };

const calculateDifferenceInMonthYears = ({
  date1Str,
  date2Str
}: TCalculateDifferenceInMonthYears) => {
  // Parse MM/YYYY strings into Moment objects
  const date1 = moment(date1Str, 'MM/YYYY');
  const date2 = moment(date2Str, 'MM/YYYY');

  // Calculate the difference in years and months
  const years = date2.diff(date1, 'years');
  const months = date2.diff(date1, 'months') % 12;

  return { years, months };
};

export default calculateDifferenceInMonthYears;
