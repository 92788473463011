import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const VideoIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M14.6673 3.51935C14.6673 3.11547 14.6673 2.91354 14.5875 2.82003C14.5182 2.73889 14.4142 2.69583 14.3078 2.7042C14.1852 2.71385 14.0424 2.85664 13.7569 3.14223L11.334 5.5651L13.7569 7.98798C14.0424 8.27357 14.1852 8.41636 14.3078 8.42601C14.4142 8.43438 14.5182 8.39132 14.5875 8.31018C14.6673 8.21667 14.6673 8.01473 14.6673 7.61086V3.51935Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.33398 4.09844C1.33398 2.97833 1.33398 2.41828 1.55197 1.99046C1.74372 1.61413 2.04968 1.30817 2.426 1.11642C2.85383 0.898438 3.41388 0.898438 4.53398 0.898438H8.13398C9.25409 0.898438 9.81414 0.898438 10.242 1.11642C10.6183 1.30817 10.9243 1.61413 11.116 1.99046C11.334 2.41828 11.334 2.97833 11.334 4.09844V7.03177C11.334 8.15188 11.334 8.71193 11.116 9.13975C10.9243 9.51608 10.6183 9.82204 10.242 10.0138C9.81414 10.2318 9.25409 10.2318 8.13398 10.2318H4.53398C3.41388 10.2318 2.85383 10.2318 2.426 10.0138C2.04968 9.82204 1.74372 9.51608 1.55197 9.13975C1.33398 8.71193 1.33398 8.15188 1.33398 7.03177V4.09844Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default VideoIcon;
