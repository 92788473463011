/* eslint-disable multiline-ternary */

import { FC, HTMLAttributes, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import Typography from 'src/components/common/typography/Typography';

import './profilePicture.scss';

export type TProfilePicture = {
  url: string;
  fallback: ReactNode;
  userType?: string;
} & HTMLAttributes<HTMLDivElement>;

/*
 ** Component to representative text incase the image is not available or failed loading
 */
const ProfilePicture: FC<TProfilePicture> = ({
  url = '',
  fallback,
  className,
  userType,
  ...rest
}: TProfilePicture) => {
  const [isImageValid, setIsImageValid] = useState<boolean>(true);

  useEffect(() => {
    setIsImageValid(true);
  }, [url]);

  return (
    <div
      className={classNames('profile-picture', `profile-picture--${userType}`, className)}
      {...rest}
    >
      {isImageValid ? (
        <img key={url} src={url || ''} onError={() => setIsImageValid(false)} />
      ) : (
        <Typography
          as="h3"
          variant="body-1"
          fontWeight="semiBold"
          className="profile-picture__error"
        >
          {fallback}
        </Typography>
      )}
    </div>
  );
};

ProfilePicture.defaultProps = {
  url: ''
};

export default ProfilePicture;
