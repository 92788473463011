import { useNavigate } from 'react-router-dom';
import {
  Card,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { MoreOptionsIcon } from 'src/components/common/common.icons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import { TEditProgramMembersRequest } from 'src/services/apiEndpoint.types.ts';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { editProgramMembers } from 'src/store/program/program.slice';

import './programMemberCard.scss';
import { TProgramMemberCardProps } from './programMemberCard.types';
const ProgramMemberCard = ({
  fetchProgramMembers,
  isViewerProgramCreator,
  ...program
}: TProgramMemberCardProps) => {
  const { dropdownId } = useAppSelector((store) => store.common);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const programId = paramValue({ paramName: 'id' }) as unknown as string;

  const handleViewProfile = () => {
    navigate(`/view-profile?id=${program?.userId}`);
  };

  const handleRemoveProgramMember = () => {
    const payload: TEditProgramMembersRequest = {
      programId,
      usersToRemove: [program?.User?.id],
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(editProgramMembers(payload)).then(() => {
      fetchProgramMembers();
    });
  };

  const dropdownItems: TDropdownOptions[] = [{ label: 'View Profile', onClick: handleViewProfile }];

  if (isViewerProgramCreator) {
    dropdownItems.push({ label: 'Remove user', onClick: handleRemoveProgramMember });
  }

  const dropdownOptions: TDropdown = {
    id: `program-member-card-dropdown-${program?.userId}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const openProgramMemberCardDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(dropdownOptions.id));
    }
  };

  const name: string = program?.User?.name;

  return (
    <Card className="program-member-card">
      <div className="program-member-card__header">
        <div className="program-member-card__header__user-info">
          <ProfilePicture
            url={program?.User?.profilePic}
            fallback={name.slice(0, 1)}
            className="program-member-card__header__user-info__profile-pic"
          />
          <div className="program-member-card__header__user-info__user">
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="program-member-card__header__user-info__user__name"
            >
              {name}
            </Typography>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="program-member-card__header__user-info__user__role"
            >
              {program?.User?.role}
            </Typography>
          </div>
        </div>
        <div
          className="program-member-card__header__dropdown"
          onClick={(e) => {
            e.stopPropagation();
            openProgramMemberCardDropdown();
          }}
        >
          <MoreOptionsIcon />
          <SubMenuModal>
            <Dropdown {...dropdownOptions} />
          </SubMenuModal>
        </div>
      </div>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        className="program-member-card__description"
      >
        {'description'}
      </Typography>
    </Card>
  );
};

export default ProgramMemberCard;
