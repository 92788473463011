import { Ref, useContext } from 'react';
import { FormikProps } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  ConfirmModal,
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Button from 'src/components/button/Button';
import { EditIcon, MoreOptionsIcon, ShareIcon } from 'src/components/common/common.icons';
import { assignModalId, assignSubMenuModalId } from 'src/store/common/common.slice';
import { useGetIdentity, useToggleOpenModal, useUrlParamValue } from 'src/hooks';
import { TFinalizeIdeaRequest, TGetIdeaRequest } from 'src/services/apiEndpoint.types.ts';
import { finalizeIdea, getIdea } from 'src/store/ideaHub/ideaHub.slice';

import useAllowedActions from './useAllowedActions';
import './ideaDetailsActions.scss';

import { IdeaViewTypeContext } from '../../ideaSkeleton/IdeaSkeleton';
import {
  TIdeaStatus,
  TIdeaCollaborationType,
  TIdeaViewType,
  TIdeaDetailsSections,
  TIdea,
  TIdeaSharedFor
} from '../../ideaHub.types';
import { RateIdea } from '..';
import ShareToCollaborate from '../shareToCollaborate/ShareToCollaborate';
import { TEditIdeaFormValues } from '../IdeaDetails';
import ShareToEvaluate from '../shareToEvaluate/ShareToEvaluate';
import ManageAccess from '../manageAccess/ManageAccess';

export type TIdeaDetailsActionsProps = {
  idea: TIdea;
  ideaStatus: TIdeaStatus;
  ideaCollaborationType: TIdeaCollaborationType;
  currentIdeaDetailsSection: TIdeaDetailsSections;
  ideaRatedByUser?: boolean;
  submitButtonRef: Ref<FormikProps<TEditIdeaFormValues>>;
  handleNext: () => void;
  handleSave: () => void;
  handleDiscard: () => void;
  ideaSharedFor?: TIdeaSharedFor;
};

const shareIdeaDropdownId = 'shareIdeaDropdown';
const manageIdeaDropdownId = 'manageIdeaDropdown';
const manageAccessModalId = 'manageAccessModal';
const rateIdeaModalId = 'rateIdeaModal';
const shareToCollaboratorsModalId = 'shareToCollaboratorsModal';
const confirmFinalizeIdeaModalId = 'confirmFinalizeIdeaModal';
const shareToEvaluatorsModalId = 'shareToEvaluatorsModal';
const discardIdeaModalId = 'discardIdeaModal';

const IdeaDetailsActions = ({
  idea,
  ideaStatus,
  ideaCollaborationType,
  currentIdeaDetailsSection,
  handleNext,
  handleSave,
  handleDiscard,
  ideaSharedFor,
  ideaRatedByUser
}: TIdeaDetailsActionsProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toggleOpenModal = useToggleOpenModal();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { dropdownId, modalId, loading } = useAppSelector((store) => store.common);

  const ideaId = paramValue({ paramName: 'id' }) as unknown as string;
  const { loggedInUserId } = getIdentities();

  const viewType: TIdeaViewType = useContext(IdeaViewTypeContext);
  const { checkButtonsVisibility } = useAllowedActions({
    ideaStatus,
    ideaCollaborationType,
    currentIdeaDetailsSection,
    ideaSharedFor,
    viewType
  });
  const openRateIdeaModal = modalId === rateIdeaModalId;
  const openShareToCollaboratorsModal = modalId === shareToCollaboratorsModalId;
  const openShareToEvaluatorsModal = modalId === shareToEvaluatorsModalId;
  const openConfirmFinalizeIdeaModal = modalId === confirmFinalizeIdeaModalId;
  const openManageAccessModal = modalId === manageAccessModalId;
  const openDiscardIdeaModal = modalId === discardIdeaModalId;

  const {
    showEditButton,
    showFinalizeButton,
    showShareManageAccessButtons,
    showNextButton,
    showDiscardIdeaButton,
    showSaveButton,
    showRateIdeaButton
  } = checkButtonsVisibility();

  const handleEdit = () => {
    if (viewType === 'emptyIdea') {
      navigate('/edit-idea?tab=ideaDetails&editType=newIdea');
    } else if (viewType === 'viewIdea') {
      navigate(`/edit-idea?id=${idea?.id}&tab=ideaDetails&editType=draft`);
    }
  };

  const shareIdeaDropdownOptions: TDropdownOptions[] = [
    {
      label: 'Share to Evaluate',
      onClick: () => {
        toggleOpenModal({ modalId: shareToEvaluatorsModalId });
      }
    },

    {
      label: 'Share to collaborate',
      onClick: () => {
        toggleOpenModal({ modalId: shareToCollaboratorsModalId });
      }
    }
  ];

  const shareIdeaDropdownProps: TDropdown = {
    id: shareIdeaDropdownId,
    size: 'large',
    alignment: 'left',
    items: shareIdeaDropdownOptions
  };

  const manageIdeaDropdownOptions: TDropdownOptions[] = [
    {
      label: 'Manage Access',
      onClick: () => {
        dispatch(assignModalId(manageAccessModalId));
      }
    },

    {
      label: 'Export Idea',
      onClick: () => {}
    }
  ];

  const manageIdeaDropdownProps: TDropdown = {
    id: manageIdeaDropdownId,
    size: 'large',
    alignment: 'left',
    items: manageIdeaDropdownOptions
  };

  const openShareIdeaDropdown = ({ id }: { id: string }) => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(id));
    }
  };

  const handleOpenRateIdeaModal = () => {
    if (!ideaRatedByUser) {
      dispatch(assignModalId(rateIdeaModalId));
    }
  };

  const openFinalizeIdeaModal = () => {
    dispatch(assignModalId(confirmFinalizeIdeaModalId));
  };

  const handleOpenDiscardIdeaModal = () => {
    dispatch(assignModalId(discardIdeaModalId));
  };

  const handleCloseModal = () => {
    dispatch(assignModalId(''));
  };

  const handleFinalizeIdea = () => {
    const payload: TFinalizeIdeaRequest = {
      ideaId
    };

    dispatch(finalizeIdea(payload)).then(() => {
      const getIdeaPayload: TGetIdeaRequest = {
        ideaId,
        userId: loggedInUserId
      };
      dispatch(getIdea(getIdeaPayload));
    });
  };

  return (
    <div className="idea-details-actions">
      <div className="idea-details-actions__label">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Idea Details
        </Typography>
        <Typography
          as="span"
          variant="caption"
          fontWeight="medium"
          className="idea-details-actions__label__status"
        >
          {ideaStatus}
        </Typography>
      </div>
      {/*
       ** Idea action buttons
       */}
      <div className="idea-details-actions__actions">
        {/*
         ** Finalize
         */}
        {showFinalizeButton && (
          <Button
            variant="primary"
            size="small"
            className="idea-details-actions__actions__finalize-idea"
            onClick={openFinalizeIdeaModal}
          >
            Finalize this idea
          </Button>
        )}
        {/*
         ** Share Idea
         */}
        {showShareManageAccessButtons && (
          <div
            className="idea-details-actions__actions__share"
            onClick={(e) => {
              e.stopPropagation();
              openShareIdeaDropdown({ id: shareIdeaDropdownProps?.id });
            }}
          >
            <div className="idea-details-actions__actions__share__icon">
              <ShareIcon />
            </div>

            <SubMenuModal>
              <Dropdown {...shareIdeaDropdownProps} />
            </SubMenuModal>
          </div>
        )}
        {/*
         ** Edit Idea
         */}
        {showEditButton && (
          <div className="idea-details-actions__actions__edit-icon" onClick={handleEdit}>
            <EditIcon />
          </div>
        )}
        {/*
         ** Go to next step
         */}
        {showNextButton && (
          <Button
            size="small"
            variant="primary"
            className="idea-details-actions__actions__next"
            onClick={handleNext}
            type="button"
          >
            Next
          </Button>
        )}
        {/*
         ** Discard idea
         */}
        {showDiscardIdeaButton && (
          <Button
            size="small"
            variant="secondary"
            className="idea-details-actions__actions__discard-idea"
            onClick={handleOpenDiscardIdeaModal}
          >
            Discard Idea
          </Button>
        )}
        {/*
         ** Save idea
         */}
        {showSaveButton && (
          <Button
            size="small"
            variant={
              currentIdeaDetailsSection === 'BusinessModelCompetitors' ? 'primary' : 'secondary'
            }
            className="idea-details-actions__actions__save-idea"
            type="button"
            onClick={handleSave}
            loading={loading}
          >
            {currentIdeaDetailsSection === 'BusinessModelCompetitors'
              ? 'Save Idea'
              : 'Save and Exit'}
          </Button>
        )}
        {/*
         ** Manage Access
         */}
        {showShareManageAccessButtons && (
          <div
            className="idea-details-actions__actions__manage-idea"
            onClick={(e) => {
              e.stopPropagation();
              openShareIdeaDropdown({ id: manageIdeaDropdownProps?.id });
            }}
          >
            <MoreOptionsIcon />
            <SubMenuModal>
              <Dropdown {...manageIdeaDropdownProps} />
            </SubMenuModal>
          </div>
        )}
        {showRateIdeaButton && (
          <Button
            size="small"
            variant={ideaRatedByUser ? 'secondary' : 'primary'}
            className="idea-details-actions__actions__rate-idea"
            onClick={handleOpenRateIdeaModal}
          >
            {ideaRatedByUser ? 'Idea Rated' : 'Rate Idea'}
          </Button>
        )}
      </div>
      {openRateIdeaModal && (
        <RateIdea
          isModalOpen={openRateIdeaModal}
          handleCloseModal={handleCloseModal}
          loggedInUserId={loggedInUserId}
          ideaId={ideaId}
        />
      )}
      {openShareToCollaboratorsModal && (
        <ShareToCollaborate
          ideaId={ideaId}
          isModalOpen={openShareToCollaboratorsModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {openShareToEvaluatorsModal && (
        <ShareToEvaluate
          ideaId={ideaId}
          isModalOpen={openShareToEvaluatorsModal}
          handleCloseModal={handleCloseModal}
        />
      )}
      {openManageAccessModal && (
        <ManageAccess
          ideaId={ideaId}
          isModalOpen={openManageAccessModal}
          handleCloseModal={handleCloseModal}
          fetchKeyMembers={() => {}}
        />
      )}
      {openConfirmFinalizeIdeaModal && (
        <ConfirmModal
          title="Do you wish to finalize this idea?"
          description="You won't be able to edit this idea once it's finalized."
          openConfirmModal={openConfirmFinalizeIdeaModal}
          onClose={handleCloseModal}
          onSubmit={handleFinalizeIdea}
          type="error"
          submitText="Confirm"
          loading={loading}
        />
      )}{' '}
      {openDiscardIdeaModal && (
        <ConfirmModal
          title="Are you sure you want to discard this idea?"
          description="All the details present in this idea will be cleared."
          openConfirmModal={openDiscardIdeaModal}
          onClose={handleCloseModal}
          onSubmit={handleDiscard}
          type="error"
          submitText="Confirm"
        />
      )}
    </div>
  );
};

export default IdeaDetailsActions;
