export const keyLabelMapping = {
  title: 'Title',
  domain: 'Domain',
  description: 'Description',
  marketSize: 'Market Size',
  targetAudience: 'Target Audience',
  marketTrends: 'Market Trends',
  identifiedProblem: 'Identified Problem',
  solutionEffectiveness: 'Solution Effectiveness',
  uniqueValueProposition: 'Unique Value Proposition',
  majorRevenueStream: 'Major Revenue Stream',
  scaliblityOfIdea: 'Idea Scalability',
  competitors: 'Competitors',
  keyDifferentiatingFactor: 'Differentiating Factor'
};
