import { useContext, useEffect, useMemo, useState } from 'react';
import Typography from 'src/components/common/typography/Typography';
import Card from 'src/components/common/card/Card';
import { EditIcon } from 'src/components/common/common.icons';
import { Modal } from 'src/components/common';
import MultiSelectField from 'src/components/common/formFields/multiSelectField/MultiSelectField';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Expertise, Industries } from 'src/constants/user.constants';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { enumToJson } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import Button from 'src/components/button/Button';
import { TUpdatedExpertiseRequest } from 'src/services/apiEndpoint.types.ts/updatedExpertise.endpoint.types';
import { updateExpertise, updateIndustries } from 'src/store/profile/profile.slice';
import { TUpdatedIndustriesRequest } from 'src/services/apiEndpoint.types.ts/updatedIndustries.endpoint.types';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';

import './additionalInformation.scss';

export type TAdditionalInformation = {
  userExpertise: { field: string }[];
  userIndustries: { industry: string }[];
};

export type TExpertise = {
  field: string;
};

export type TIndustry = {
  industry: string;
};

/*
 ** Generate select options based on enums
 */
const expertiseOptions: TSelectCardRadioButton[] = enumToJson({ enumData: Expertise });
const industriesOptions: TSelectCardRadioButton[] = enumToJson({
  enumData: Industries
});

const updateExpertiseModalId = 'update-expertise-modal';
const updateIndustriesModalId = 'update-industries-modal';

const AdditionalInformation = ({ userExpertise, userIndustries }: TAdditionalInformation) => {
  const { profile } = useAppSelector((store) => store.profile);
  const { loading, modalId } = useAppSelector((store) => store.common);

  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.

  const dispatch = useAppDispatch();

  const [expertise, setExpertise] = useState<(string | number)[]>([]);
  const [industries, setIndustries] = useState<(string | number)[]>([]);

  const currentExpertise = useMemo(() => {
    return ((profile?.userExpertise || []) as TExpertise[]).map((item) => item?.field);
  }, [profile.userExpertise]);

  const currentIndustries = useMemo(() => {
    return ((profile.userIndustries || []) as TIndustry[]).map((item) => item?.industry);
  }, [profile.userIndustries]);

  const handleSaveExpertise = () => {
    const toBeAdded = (expertise as unknown as string[]).filter(
      (item: string) => !currentExpertise.includes(item)
    );
    const toBeRemoved = (currentExpertise as unknown as string[]).filter(
      (item: string) => !expertise.includes(item)
    );

    const payload: TUpdatedExpertiseRequest = {
      id: profile.id,
      ...(toBeAdded?.length && { toBeAdded }),
      ...(toBeRemoved?.length && { toBeRemoved })
    };

    dispatch(updateExpertise(payload));
  };

  const handleSaveIndustries = () => {
    const toBeAdded = (industries as unknown as string[]).filter(
      (item: string) => !currentIndustries.includes(item)
    );
    const toBeRemoved = (currentIndustries as unknown as string[]).filter(
      (item: string) => !industries.includes(item)
    );

    const payload: TUpdatedIndustriesRequest = {
      id: profile.id,
      ...(toBeAdded?.length && { toBeAdded }),
      ...(toBeRemoved?.length && { toBeRemoved })
    };

    dispatch(updateIndustries(payload));
  };

  const openUpdateExpertiseModal = () => {
    dispatch(assignModalId(updateExpertiseModalId));
  };

  const openUpdateIndustriesModal = () => {
    dispatch(assignModalId(updateIndustriesModalId));
  };

  const closeModal = () => {
    dispatch(resetModalId());
  };

  useEffect(() => {
    setExpertise(currentExpertise);
    setIndustries(currentIndustries);
  }, [modalId, currentExpertise, currentIndustries]);

  return (
    <div className="profile-about-additional-info">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="my-profile-contact-info__heading"
      >
        Additional Information
      </Typography>
      <div className="profile-about-additional-info__row">
        <Card className="profile-about-additional-info__row__section">
          <div className="profile-about-additional-info__row__section__header">
            <Typography as="p" variant="body-2" fontWeight="semiBold">
              Area of Expertise
            </Typography>
            {isSelfUser && (
              <div
                className="profile-about-additional-info__row__section__header__icon"
                onClick={openUpdateExpertiseModal}
              >
                <EditIcon />
              </div>
            )}
          </div>
          <div className="profile-about-additional-info__row__section__items">
            {userExpertise.map((item, index) => {
              return (
                <Typography key={index} as="span" variant="body-2" fontWeight="regular">
                  {item.field}
                </Typography>
              );
            })}
          </div>
        </Card>
        <Card className="profile-about-additional-info__row__section">
          <div className="profile-about-additional-info__row__section__header">
            <Typography as="p" variant="body-2" fontWeight="semiBold">
              Industries
            </Typography>
            {isSelfUser && (
              <div
                className="profile-about-additional-info__row__section__header__icon"
                onClick={openUpdateIndustriesModal}
              >
                <EditIcon />
              </div>
            )}
          </div>
          <div className="profile-about-additional-info__row__section__items">
            {userIndustries.map((item, index) => {
              return (
                <Typography key={index} as="span" variant="body-2" fontWeight="regular">
                  {item.industry}
                </Typography>
              );
            })}
          </div>
        </Card>
      </div>
      <Modal
        title={'Area of expertise'}
        isModalOpen={modalId === updateExpertiseModalId}
        onModalClose={closeModal}
        className="profile-about-additional-info__expertise-modal"
      >
        <MultiSelectField
          label={
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__mentor-investor-interests__label"
            >
              Select your area of expertise{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select multiple&#41;
              </Typography>
            </Typography>
          }
          options={expertiseOptions}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Select area of expertise"
          selectedValues={expertise}
          onSelection={({ selectedValues }) => setExpertise(selectedValues)}
          showTags={true}
          selectTagsLabel="Selected area of expertise"
        />
        <div className="profile-about-additional-info__expertise-modal__buttons">
          <Button variant="secondary" type="button" size="small" onClick={closeModal}>
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={loading}
            onClick={handleSaveExpertise}
          >
            Save
          </Button>
        </div>
      </Modal>
      <Modal
        title={'Industries'}
        isModalOpen={modalId === updateIndustriesModalId}
        onModalClose={closeModal}
        className="profile-about-additional-info__industries-modal"
      >
        <MultiSelectField
          label={
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__mentor-investor-interests__label"
            >
              Select Industries
            </Typography>
          }
          options={industriesOptions}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Select area of expertise"
          selectedValues={industries}
          onSelection={({ selectedValues }) => setIndustries(selectedValues)}
          showTags={true}
          selectTagsLabel="Selected Industries"
        />
        <div className="profile-about-additional-info__industries-modal__buttons">
          <Button variant="secondary" type="button" size="small" onClick={closeModal}>
            Close
          </Button>

          <Button
            variant="primary"
            type="submit"
            size="small"
            loading={loading}
            onClick={handleSaveIndustries}
          >
            Save
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default AdditionalInformation;
