import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { APIResponse } from 'src/services/base.api.service';
import { SocialMediaAPIService } from 'src/services/socialMedia.api.service';
import { errorMessages } from 'src/constants';
import {
  TComment,
  TCreateCommentRequest,
  TCreateCommentResponse,
  TCreatePostRequest,
  TCreatePostResponse,
  TCreatePostUploadMediaActionRequest,
  TCreatePostUploadMediaResponse,
  TDeleteCommentRequest,
  TDeleteCommentResponse,
  TDeleteMediaActionRequest,
  TDeletePostActionRequest,
  TDeletePostResponse,
  TDeleteReplyToCommentRequest,
  TDeleteReplyToCommentResponse,
  TDontRecommendRequest,
  TDontRecommendResponse,
  TEditCommentRequest,
  TEditCommentResponse,
  TEditPostRequest,
  TEditReplyToCommentRequest,
  TFollowRequest,
  TFollowResponse,
  TGetCommentsRequest,
  TGetCommentsResponse,
  TGetNewsFeedCompanyRequest,
  TGetNewsFeedRequest,
  TGetNewsFeedResponse,
  TGetPostPresignedUrlActionRequest,
  TGetPostPresignedUrlResponse,
  TGetPostRequest,
  TGetPostResponse,
  TGetRepliesRequest,
  TGetUserStatsRequest,
  TGetUserStatsResponse,
  TLikeCommentRequest,
  TLikeCommentResponse,
  TLikePostRequest,
  TLikePostResponse,
  TLikeReplyRequest,
  TLikeReplyResponse,
  TPost,
  TReply,
  TReplyToCommentRequest,
  TReplyToCommentResponse,
  TSavePostRequest,
  TSavePostResponse,
  TUnFollowRequest,
  TUnFollowResponse,
  TUnLikeCommentRequest,
  TUnLikeCommentResponse,
  TUnLikePostRequest,
  TUnLikePostResponse,
  TUnLikeReplyRequest,
  TUnLikeReplyResponse,
  TUnSavePostRequest,
  TUnSavePostResponse,
  TUserStats
} from 'src/services/apiEndpoint.types.ts';

import { AppDispatch } from '../store';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';
import {
  handleLikeSearchPostSuccess,
  handleSaveSearchPostSuccess,
  handleUnLikeSearchPostSuccess,
  handleUnSaveSearchPostSuccess
} from '../search/search.slice';

export type TCreatePostMedia = { type: 'image' | 'video'; key: string };

type TSocialMediaInitialState = {
  createPostMedia: TCreatePostMedia[];
  isMediaUploading: number;
  /*
   ** News feed
   */
  newsFeed: TPost[];
  viewPost: TPost;
  newsFeedItemsPerPage: number;
  newsFeedPageNumber: number;
  newsFeedMoreItemsLeft: boolean;
  viewPostOwner: string;
  /*
   ** Comments
   */
  comments: TComment[];
  commentsPerPage: number;
  commentsPageNumber: number;
  MoreCommentsLeft: boolean;
  showCommentsId: string;
  commentsLoading: boolean;
  /*
   ** Replies
   */
  replies: TReply[];
  repliesPerPage: number;
  repliesPageNumber: number;
  moreRepliesLeft: boolean;
  showRepliesId: string;
  repliesLoading: boolean;
  /*
   ** User stats
   */
  userStats: {
    followingCount: number;
    followerCount: number;
    postCount: number;
    isFollowing?: boolean;
    announcementCount?: number;
    ideaCount?: number;
  };
  myCompanyStats: {
    followingCount: number;
    followerCount: number;
    postCount: number;
    isFollowing?: boolean;
    announcementCount?: number;
    ideaCount?: number;
  };
  /*
   ** Loaders
   */
  createLoader: boolean;
  editLoader: boolean;
  createCommentLoader: boolean;
  editCommentLoader: boolean;
  replyToCommentLoader: boolean;
  editReplyToCommentLoader: boolean;
};

const initialState: TSocialMediaInitialState = {
  createPostMedia: [],
  isMediaUploading: 0,
  /*
   ** News feed
   */
  newsFeed: [],
  viewPost: {
    user: {
      name: '',
      profilePic: '',
      logo: '',
      id: '',
      type: ''
    },
    followUserOrCompany: false,
    userType: [],
    post: {
      imageUrls: [],
      videoUrls: [],
      customButton: '',
      redirectUrl: '',
      urls: '',
      id: '',
      timestamp: 0,
      isAnnouncement: false,
      text: '',
      title: '',
      likeCount: 0,
      commentCount: 0,
      likedByUser: false,
      savedPost: false
    }
  },
  newsFeedItemsPerPage: 5,
  newsFeedPageNumber: 1,
  newsFeedMoreItemsLeft: true,
  viewPostOwner: '',
  /*
   ** Comments
   */
  comments: [],
  commentsPerPage: 5,
  commentsPageNumber: 1,
  MoreCommentsLeft: true,
  showCommentsId: '',
  commentsLoading: false,
  /*
   ** Replies
   */
  replies: [],
  repliesPerPage: 5,
  repliesPageNumber: 1,
  moreRepliesLeft: true,
  showRepliesId: '',
  repliesLoading: false,
  /*
   ** User stats
   */
  userStats: {
    followingCount: 0,
    followerCount: 0,
    postCount: 0,
    isFollowing: false,
    announcementCount: 0
  },
  myCompanyStats: {
    followingCount: 0,
    followerCount: 0,
    postCount: 0,
    isFollowing: false,
    announcementCount: 0
  },
  /*
   ** Loaders
   */
  createLoader: false,
  editLoader: false,
  createCommentLoader: false,
  editCommentLoader: false,
  replyToCommentLoader: false,
  editReplyToCommentLoader: false
};

export const socialMediaSlice = createSlice({
  name: 'socialMedia',
  initialState,
  reducers: {
    createPostSuccess: (state) => {
      state.createPostMedia = [];
    },
    editPostSuccess: (state) => {
      state.createPostMedia = [];
    },
    deletePostSuccess: () => {},
    savePostSuccess: (state, action: PayloadAction<TSavePostRequest>) => {
      const { postId } = action.payload;

      const newsFeedCurrent: TPost[] = JSON.parse(JSON.stringify(state.newsFeed)) || [];

      newsFeedCurrent.forEach((post) => {
        if (post?.post?.id === postId) {
          post.post.savedPost = true;
        }
      });

      state.newsFeed = newsFeedCurrent;
    },
    unSavePostSuccess: (state, action: PayloadAction<TSavePostRequest>) => {
      const { postId } = action.payload;

      const newsFeedCurrent: TPost[] = JSON.parse(JSON.stringify(state.newsFeed)) || [];

      newsFeedCurrent.forEach((post) => {
        if (post?.post?.id === postId) {
          post.post.savedPost = false;
        }
      });

      state.newsFeed = newsFeedCurrent;
    },

    /*
     ** Upload image success - When the image is successfully added.
     */
    uploadPostImageSuccess: (state, action: PayloadAction<any>) => {
      const imageString: string = action.payload;

      const image: TCreatePostMedia = { type: 'image', key: imageString };

      const currentMedia: TCreatePostMedia[] =
        JSON.parse(JSON.stringify(state.createPostMedia)) || [];

      state.createPostMedia = currentMedia.concat(image);
    },
    /*
     ** Upload video success - When the video is successfully added.
     */
    uploadPostVideoSuccess: (state, action: PayloadAction<any>) => {
      const videoString: string = action.payload;

      const video: TCreatePostMedia = { type: 'video', key: videoString };

      const currentVideo: TCreatePostMedia[] =
        JSON.parse(JSON.stringify(state.createPostMedia)) || [];

      state.createPostMedia = currentVideo.concat(video);
    },
    /*
     ** Remove media from redux state
     */
    removePostMedia: (state, action: PayloadAction<string>) => {
      const mediaString: string = action.payload;

      const currentMedia: TCreatePostMedia[] =
        JSON.parse(JSON.stringify(state.createPostMedia)) || [];

      const deleteImageIndex: number = currentMedia.findIndex((media) => media.key === mediaString);

      if (deleteImageIndex > -1) {
        currentMedia.splice(deleteImageIndex, 1);
      }

      state.createPostMedia = currentMedia;
    },

    /*
     ** update edit post media
     */
    updatePostMedia: (state, action: PayloadAction<{ urls: TCreatePostMedia[] }>) => {
      const { urls } = action.payload;

      state.createPostMedia = urls;
    },
    /*
     ** clear edit post media
     */
    clearPostMedia: (state) => {
      state.createPostMedia = [];
      state.isMediaUploading = 0;
    },
    /*
     ** start media loader
     */
    startMediaLoader: (state) => {
      state.isMediaUploading++;
    },
    stopMediaLoader: (state) => {
      state.isMediaUploading--;
    },
    /*
     ** get news feed success - When the news feed is fetched successfully
     */
    getNewsFeedSuccess: (state, action: PayloadAction<any>) => {
      const currentNewsFeed: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const {
        posts: newItems,
        totalPages,
        following
      }: { posts: TPost[]; totalPages: number; following?: boolean } = action.payload;

      newItems.forEach((item) => {
        const itemId = item.post.id;

        const indexInExistingFeed = currentNewsFeed.findIndex((item) => item.post.id === itemId);

        if (following) {
          // This is applicable only to following field.
          // Set following to true.
          item.followUserOrCompany = true;
        }

        if (indexInExistingFeed < 0) {
          currentNewsFeed.push(item);
        }
      });

      state.newsFeed = currentNewsFeed;
      state.newsFeedMoreItemsLeft = state.newsFeedPageNumber < totalPages;
    },
    /*
     ** get news feed success - When the news feed is fetched successfully
     */
    getNewsFeedFollowingSuccess: (state, action: PayloadAction<any>) => {
      const currentNewsFeed: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const { posts: newItems, totalPages }: { posts: TPost[]; totalPages: number } =
        action.payload;

      newItems.forEach((item) => {
        const itemId = item.post.id;

        const indexInExistingFeed = currentNewsFeed.findIndex((item) => item.post.id === itemId);

        if (indexInExistingFeed < 0) {
          currentNewsFeed.push(item);
        }
      });

      state.newsFeed = currentNewsFeed;
      state.newsFeedMoreItemsLeft = state.newsFeedPageNumber < totalPages;
    },
    /*
     ** dont recommend post success
     */
    dontRecommendPostSuccess: (state, action: PayloadAction<string>) => {
      const postId: string = action.payload;

      const currentNewsFeed: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const postIdIndex = currentNewsFeed.findIndex((post) => post?.post?.id === postId);

      currentNewsFeed.splice(postIdIndex, 1);
      //  Remove the selected post from local state.

      state.newsFeed = currentNewsFeed;
    },
    /*
     ** get post success - When a single post is fetched successfully
     */
    getPostSuccess: (state, action: PayloadAction<TPost[]>) => {
      const posts = action.payload;

      const currentPost = posts?.[0];

      state.showCommentsId = currentPost?.post?.id;
      state.newsFeed = [currentPost];
      state.viewPost = currentPost;
      state.viewPostOwner = currentPost?.user?.id;
    },
    /*
     ** clear news feed
     */
    clearNewsFeed: (state) => {
      // not resetting user stats, intentionally
      return { ...initialState, userStats: state.userStats };
    },
    /*
     ** get comments success - When the comments for a post are fetched successfully
     */
    getCommentsSuccess: (state, action: PayloadAction<any>) => {
      const currentComments: TComment[] = JSON.parse(JSON.stringify(state.comments));

      const { comments: newComments, totalPages }: { comments: TComment[]; totalPages: number } =
        action.payload;

      newComments.forEach((item) => {
        const itemId = item.comments.id;

        const indexInExistingFeed = currentComments.findIndex(
          (item) => item.comments.id === itemId
        );

        if (indexInExistingFeed < 0) {
          currentComments.push(item);
        }
      });

      state.comments = currentComments;
      state.MoreCommentsLeft = state.commentsPageNumber < totalPages;
    },
    /*
     ** In the new feed, which items comment section is open.
     */
    updateShowCommentsId: (state, action: PayloadAction<string>) => {
      state.showCommentsId = action.payload;
      state.comments = [];
      state.commentsPageNumber = 1;
      state.MoreCommentsLeft = true;
    },
    startCommentsLoader: (state) => {
      state.commentsLoading = true;
    },
    stopCommentsLoader: (state) => {
      state.commentsLoading = false;
    },
    /*
     ** clear comments
     */
    clearComments: (state) => {
      state.comments = [];
      state.commentsPageNumber = 1;
      state.MoreCommentsLeft = true;
      state.showCommentsId = '';
      state.replies = [];
      state.repliesPageNumber = 1;
      state.moreRepliesLeft = true;
      state.showRepliesId = '';
    },

    /*
     ** create comment success - When a comment is created successfully
     */
    createCommentSuccess: (state, action: PayloadAction<any>) => {
      // increasing the comment in the local state
      const postId: string = action.payload;

      const currentPosts: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const currentPost = currentPosts.find((post) => post?.post?.id === postId);

      if (currentPost) {
        currentPost.post.commentCount++;
      }

      state.newsFeed = currentPosts;
    },
    /*
     ** edit comment success - When a comment is created successfully
     */
    editCommentSuccess: (state) => {
      state.commentsPageNumber = 1;
      state.comments = [];
    },
    /*
     ** delete comment success - When a comment is deleted successfully
     */
    deleteCommentSuccess: (state, action: PayloadAction<TDeleteCommentRequest>) => {
      const { commentId, postId } = action.payload;

      const newPosts: TPost[] = JSON.parse(JSON.stringify(state.newsFeed)) || [];
      const modifiedPost: TPost | undefined = newPosts.find((post) => post?.post?.id === postId);

      const newComments: TComment[] = JSON.parse(JSON.stringify(state.comments));
      const deletedCommentIndex: number = newComments.findIndex(
        (comment) => comment?.comments?.id === commentId
      );

      if (modifiedPost) {
        modifiedPost.post.commentCount--;
      }

      if (deletedCommentIndex > -1) {
        newComments.splice(deletedCommentIndex, 1);
      }

      state.newsFeed = newPosts;
      state.comments = newComments;
    },
    /*
     ** like post success - When a post is liked successfully
     */
    likePostSuccess: (state, action: PayloadAction<any>) => {
      // increasing the like count in the local state
      const postId: string = action.payload;

      const currentPosts: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const currentPost = currentPosts.find((post) => post?.post?.id === postId);

      if (currentPost) {
        currentPost.post.likeCount++;
        currentPost.post.likedByUser = true;
      }

      state.newsFeed = currentPosts;
    },
    /*
     ** like post success - When a post is liked successfully
     */
    unlikePostSuccess: (state, action: PayloadAction<any>) => {
      // decreasing the like count in the local state
      const postId: string = action.payload;

      const currentPosts: TPost[] = JSON.parse(JSON.stringify(state.newsFeed));

      const currentPost = currentPosts.find((post) => post?.post?.id === postId);

      if (currentPost) {
        currentPost.post.likeCount--;
        currentPost.post.likedByUser = false;
      }

      state.newsFeed = currentPosts;
    },
    likeCommentSuccess: (state, action: PayloadAction<any>) => {
      // increasing the like count of comments in the local state
      const commentId: string = action.payload;

      const currentComments: TComment[] = JSON.parse(JSON.stringify(state.comments));

      const currentComment = currentComments.find((comment) => comment?.comments?.id === commentId);

      if (currentComment) {
        currentComment.comments.likeCount++;
        currentComment.comments.likedByUser = true;
      }

      state.comments = currentComments;
    },
    /*
     ** like post success - When a post is liked successfully
     */
    unlikeCommentSuccess: (state, action: PayloadAction<any>) => {
      // decreasing the like count of comments in the local state
      const commentId: string = action.payload;

      const currentComments: TComment[] = JSON.parse(JSON.stringify(state.comments));

      const currentComment = currentComments.find((comment) => comment?.comments?.id === commentId);

      if (currentComment) {
        currentComment.comments.likeCount--;
        currentComment.comments.likedByUser = false;
      }

      state.comments = currentComments;
    },
    /*
     ** Update page number of posts feed
     */
    incrementNewsFeedPage: (state, action: PayloadAction<number>) => {
      state.newsFeedPageNumber = state.newsFeedPageNumber + action.payload;
    },
    /*
     ** Update page number of comments feed
     */
    incrementCommentsPage: (state, action: PayloadAction<number>) => {
      state.commentsPageNumber = state.commentsPageNumber + action.payload;
    },

    /*
     ** Reply reducers
     */

    /*
     ** Which reply section is open.
     */
    updateShowRepliesId: (state, action: PayloadAction<string>) => {
      state.showRepliesId = action.payload;
      state.replies = [];
      state.repliesPageNumber = 1;
      state.moreRepliesLeft = true;
    },
    /*
     ** get replies success - When the replies for a comment are fetched successfully
     */
    getRepliesSuccess: (state, action: PayloadAction<any>) => {
      const currentReplies: TReply[] = JSON.parse(JSON.stringify(state.replies));

      const { comments: newReplies, totalPages }: { comments: TReply[]; totalPages: number } =
        action.payload;

      newReplies.forEach((item) => {
        const itemId = item.replies.id;

        const indexInExistingFeed = currentReplies.findIndex((item) => item.replies.id === itemId);

        if (indexInExistingFeed < 0) {
          currentReplies.push(item);
        }
      });

      state.replies = currentReplies;
      state.moreRepliesLeft = state.commentsPageNumber < totalPages;
    },
    /*
     ** Update page number of replies feed
     */
    incrementRepliesPage: (state, action: PayloadAction<number>) => {
      state.repliesPageNumber = state.repliesPageNumber + action.payload;
    },
    /*
     ** reply to comment success - When a reply is created for a comment successfully
     */
    replyToCommentSuccess: (state, action: PayloadAction<any>) => {
      // increasing the comment in the local state
      const commentId: string = action.payload;

      const currentComments: TComment[] = JSON.parse(JSON.stringify(state.comments));

      const currentComment = currentComments.find((comment) => comment?.comments?.id === commentId);

      if (currentComment) {
        currentComment.comments.replyCount++;
      }

      state.comments = currentComments;
    },
    /*
     ** edit reply success - When a reply is edited successfully
     */
    editReplyToCommentSuccess: (
      state,
      action: PayloadAction<{ replyId: string; text: string }>
    ) => {
      const { replyId, text } = action.payload;

      const newReplies: TReply[] = JSON.parse(JSON.stringify(state.replies));

      const editedReply: TReply | undefined = newReplies.find(
        (reply) => reply?.replies?.id === replyId
      );

      if (editedReply) {
        editedReply.replies.text = text;
      }

      state.replies = newReplies;
    },
    /*
     ** like reply success - When a reply is liked successfully
     */
    likeReplySuccess: (state, action: PayloadAction<TLikeReplyRequest>) => {
      // increasing the like count of comments in the local state
      const { replyId } = action.payload;

      const currentReplies: TReply[] = JSON.parse(JSON.stringify(state.replies));

      const currentReply = currentReplies.find((reply) => reply?.replies?.id === replyId);

      if (currentReply) {
        currentReply.replies.likeCount++;
        currentReply.replies.likedByUser = true;
      }

      state.replies = currentReplies;
    },
    /*
     ** unlike reply success - When a reply is unliked successfully
     */
    unlikeReplySuccess: (state, action: PayloadAction<TUnLikeReplyRequest>) => {
      // decreasing the like count of comments in the local state
      const { replyId } = action.payload;

      const currentReplies: TReply[] = JSON.parse(JSON.stringify(state.replies));

      const currentReply = currentReplies.find((reply) => reply?.replies?.id === replyId);

      if (currentReply) {
        currentReply.replies.likeCount--;
        currentReply.replies.likedByUser = false;
      }

      state.replies = currentReplies;
    },
    /*
     ** delete reply success - When a reply is deleted successfully
     */
    deleteReplySuccess: (state, action: PayloadAction<TDeleteReplyToCommentRequest>) => {
      const { replyId, commentId } = action.payload;

      const newComments: TComment[] | never[] = JSON.parse(JSON.stringify(state.comments)) || [];
      const modifiedComment: TComment | undefined = newComments.find(
        (comment) => comment?.comments?.id === commentId
      );

      const newReplies: TReply[] = JSON.parse(JSON.stringify(state.replies));
      const deletedReplyIndex: number = newReplies.findIndex(
        (reply) => reply?.replies?.id === replyId
      );

      if (modifiedComment) {
        modifiedComment.comments.replyCount--;
      }

      if (deletedReplyIndex > -1) {
        newReplies.splice(deletedReplyIndex, 1);
      }

      state.comments = newComments;
      state.replies = newReplies;
    },
    /*
     ** follow reducers
     */
    followSuccess: (state, action: PayloadAction<string>) => {
      const followerUserId = action.payload;

      const newsFeedCurrent: TPost[] = JSON.parse(JSON.stringify(state.newsFeed)) || [];

      newsFeedCurrent.forEach((post) => {
        if (post?.user?.id === followerUserId) {
          post.followUserOrCompany = true;
        }
      });

      state.newsFeed = newsFeedCurrent;
      state.userStats.isFollowing = true;
    },
    unFollowSuccess: (state, action: PayloadAction<string>) => {
      const followerUserId = action.payload;

      const newsFeedCurrent: TPost[] = JSON.parse(JSON.stringify(state.newsFeed)) || [];

      newsFeedCurrent.forEach((post) => {
        if (post?.user?.id === followerUserId) {
          post.followUserOrCompany = false;
        }
      });

      state.newsFeed = newsFeedCurrent;
      state.userStats.isFollowing = false;
    },
    /*
     ** follow reducers
     */
    getUserStatsSuccess: (state, action: PayloadAction<TUserStats>) => {
      const userStats = action.payload;

      state.userStats = userStats;
    },
    getUserStatsError: (state) => {
      state.userStats = initialState.userStats;
    },
    getMyCompanyStatsSuccess: (state, action: PayloadAction<TUserStats>) => {
      const userStats = action.payload;

      state.myCompanyStats = userStats;
    },
    getMyCompanyStatsError: (state) => {
      state.myCompanyStats = initialState.myCompanyStats;
    },
    /*
     ** clear replies
     */
    clearReplies: (state) => {
      state.replies = [];
      state.repliesPageNumber = 1;
      state.moreRepliesLeft = true;
      state.showRepliesId = '';
    },
    /*
     ** loaders
     */

    startRepliesLoader: (state) => {
      state.repliesLoading = true;
    },
    stopRepliesLoader: (state) => {
      state.repliesLoading = false;
    },
    startCreateLoader: (state) => {
      state.createLoader = true;
    },
    stopCreateLoader: (state) => {
      state.createLoader = false;
    },
    startEditLoader: (state) => {
      state.editLoader = true;
    },
    stopEditLoader: (state) => {
      state.editLoader = false;
    },
    updateCreateCommentLoader: (state, action: PayloadAction<boolean>) => {
      state.createCommentLoader = action.payload;
    },
    updateEditCommentLoader: (state, action: PayloadAction<boolean>) => {
      state.editCommentLoader = action.payload;
    },
    updateReplyToCommentLoader: (state, action: PayloadAction<boolean>) => {
      state.replyToCommentLoader = action.payload;
    },
    updateEditReplyToCommentLoader: (state, action: PayloadAction<boolean>) => {
      state.editReplyToCommentLoader = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  clearComments,
  clearNewsFeed,
  clearPostMedia,
  clearReplies,
  createCommentSuccess,
  createPostSuccess,
  deleteCommentSuccess,
  deletePostSuccess,
  deleteReplySuccess,
  dontRecommendPostSuccess,
  editCommentSuccess,
  editPostSuccess,
  editReplyToCommentSuccess,
  followSuccess,
  getCommentsSuccess,
  getNewsFeedSuccess,
  getPostSuccess,
  getRepliesSuccess,
  getUserStatsSuccess,
  getUserStatsError,
  getMyCompanyStatsSuccess,
  getMyCompanyStatsError,
  incrementCommentsPage,
  incrementNewsFeedPage,
  incrementRepliesPage,
  likeCommentSuccess,
  likePostSuccess,
  likeReplySuccess,
  removePostMedia,
  replyToCommentSuccess,
  savePostSuccess,
  unSavePostSuccess,
  startCommentsLoader,
  startCreateLoader,
  startEditLoader,
  startMediaLoader,
  stopMediaLoader,
  startRepliesLoader,
  stopCommentsLoader,
  stopCreateLoader,
  stopEditLoader,
  stopRepliesLoader,
  updatePostMedia,
  updateShowRepliesId,
  updateShowCommentsId,
  unFollowSuccess,
  unlikeCommentSuccess,
  unlikePostSuccess,
  unlikeReplySuccess,
  uploadPostImageSuccess,
  uploadPostVideoSuccess,
  updateCreateCommentLoader,
  updateEditCommentLoader,
  updateReplyToCommentLoader,
  updateEditReplyToCommentLoader
} = socialMediaSlice.actions;

export default socialMediaSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message || err?.message;
  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] || errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const createPost = (payload: TCreatePostRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TCreatePostResponse> =
      await new SocialMediaAPIService().createPost(payload);

    if (status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have successfully posted this content!',
          popupType: 'success'
        })
      );
      dispatch(createPostSuccess());
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const editPost = (payload: TEditPostRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TCreatePostResponse> = await new SocialMediaAPIService().editPost(
      payload
    );

    if (status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have successfully updated this post!',
          popupType: 'success'
        })
      );
      dispatch(editPostSuccess());
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getPost = (payload: TGetPostRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TGetPostResponse> =
      await new SocialMediaAPIService().getPost(payload);

    if (status === 200) {
      if (data?.data?.posts?.length < 1) {
        // If there are no posts in the response
        throw Error;
      }
      dispatch(getPostSuccess(data?.data?.posts));
    }
  } catch (err) {
    dispatch(stopLoading());
    dispatch(
      openPopup({
        popupMessage: 'The requested post is not found!',
        popupType: 'error',
        navigateTo: '/dashboard'
      })
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const deletePost = (payload: TDeletePostActionRequest) => async (dispatch: AppDispatch) => {
  const { prevRoute, ...deleteRequestPayload } = payload;
  try {
    const { status }: APIResponse<TDeletePostResponse> =
      await new SocialMediaAPIService().deletePost(deleteRequestPayload);

    if (status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have delete the post!',
          popupType: 'success',
          ...(prevRoute && { navigateTo: prevRoute })
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const savePost = (payload: TSavePostRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status }: APIResponse<TSavePostResponse> = await new SocialMediaAPIService().savePost(
      payload
    );

    if (status === 200) {
      dispatch(savePostSuccess(payload));
      dispatch(handleSaveSearchPostSuccess(payload?.postId));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const unSavePost = (payload: TUnSavePostRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status }: APIResponse<TUnSavePostResponse> =
      await new SocialMediaAPIService().unSavePost(payload);

    if (status === 200) {
      dispatch(unSavePostSuccess(payload));
      dispatch(handleUnSaveSearchPostSuccess(payload?.postId));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};

export const createComment = (payload: TCreateCommentRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateCreateCommentLoader(true));
    const { status }: APIResponse<TCreateCommentResponse> =
      await new SocialMediaAPIService().createComment(payload);

    if (status === 200) {
      dispatch(createCommentSuccess(payload.postId));
      dispatch(updateCreateCommentLoader(false));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopCreateLoader());
  }
};

export const editComment = (payload: TEditCommentRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(updateEditCommentLoader(true));
    const { status }: APIResponse<TEditCommentResponse> =
      await new SocialMediaAPIService().editComment(payload);

    if (status === 200) {
      dispatch(editCommentSuccess());
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(updateEditCommentLoader(false));
  }
};

export const getNewsFeed = (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TGetNewsFeedResponse> =
      await new SocialMediaAPIService().getNewsFeed(payload);
    if (status === 200 && data?.data) {
      dispatch(getNewsFeedSuccess(data?.data));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getNewsFeedPopular =
  (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedPopular(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedFollowing =
  (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedFollowing(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess({ ...data?.data, following: true }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedSelfPosted =
  (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedSelfPosted(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedSavedPosts =
  (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedSavedPosts(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedOwnPopular =
  (payload: TGetNewsFeedRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedOwnPopular(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedCompanyPosts =
  (payload: TGetNewsFeedCompanyRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedCompanyPosts(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getNewsFeedCompanyAnnouncements =
  (payload: TGetNewsFeedCompanyRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TGetNewsFeedResponse> =
        await new SocialMediaAPIService().getNewsFeedCompanyAnnouncements(payload);
      if (status === 200 && data?.data) {
        dispatch(getNewsFeedSuccess(data?.data));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const uploadPostMedia =
  (payload: TCreatePostUploadMediaActionRequest) => async (dispatch: AppDispatch) => {
    const { signedUrl, key, mediaType } = payload;

    try {
      const { status }: APIResponse<TCreatePostUploadMediaResponse> =
        await new SocialMediaAPIService().uploadPostImage({
          signedUrl,
          data: payload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        if (mediaType === 'image') {
          dispatch(uploadPostImageSuccess(key));
        } else if (mediaType === 'video') {
          dispatch(uploadPostVideoSuccess(key));
        }
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopMediaLoader());
    }
  };

export const getPostPresignedUrl =
  (payload: TGetPostPresignedUrlActionRequest) => async (dispatch: AppDispatch) => {
    dispatch(startMediaLoader());
    try {
      const { imageData, mediaType, ...getPostPresignedUrlPayload } = payload;

      const { status, data }: APIResponse<TGetPostPresignedUrlResponse> =
        await new SocialMediaAPIService().getPostPresignedUrl(getPostPresignedUrlPayload);
      if (status === 200 && data?.data && imageData) {
        dispatch(
          uploadPostMedia({
            signedUrl: data?.data?.signedUrl,
            data: imageData,
            key: data?.data?.key,
            mediaType
          })
        );
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const likePost = (payload: TLikePostRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(likePostSuccess(payload.postId));
    dispatch(handleLikeSearchPostSuccess(payload?.postId));
    const response: APIResponse<TLikePostResponse> = await new SocialMediaAPIService().likePost(
      payload
    );

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const unLikePost = (payload: TUnLikePostRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(unlikePostSuccess(payload.postId));
    dispatch(handleUnLikeSearchPostSuccess(payload.postId));
    const response: APIResponse<TUnLikePostResponse> = await new SocialMediaAPIService().unLikePost(
      payload
    );

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const getComments = (payload: TGetCommentsRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startCommentsLoader());
    const { status, data }: APIResponse<TGetCommentsResponse> =
      await new SocialMediaAPIService().getComments(payload);

    if (status === 200) {
      dispatch(getCommentsSuccess(data?.data));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopCommentsLoader());
  }
};

export const deleteComment = (payload: TDeleteCommentRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status }: APIResponse<TDeleteCommentResponse> =
      await new SocialMediaAPIService().deleteComment(payload);

    if (status === 200) {
      dispatch(deleteCommentSuccess(payload));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const likeComment = (payload: TLikeCommentRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(likeCommentSuccess(payload.commentId));
    const response: APIResponse<TLikeCommentResponse> =
      await new SocialMediaAPIService().likeComment(payload);

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const unLikeComment = (payload: TUnLikeCommentRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(unlikeCommentSuccess(payload.commentId));
    const response: APIResponse<TUnLikeCommentResponse> =
      await new SocialMediaAPIService().unLikeComment(payload);

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

/*
 ** Replies
 */
export const replyToComment =
  (payload: TReplyToCommentRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(updateReplyToCommentLoader(true));
      const { status }: APIResponse<TReplyToCommentResponse> =
        await new SocialMediaAPIService().replyToComment(payload);

      if (status === 200) {
        dispatch(replyToCommentSuccess(payload.commentId));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(updateReplyToCommentLoader(false));
    }
  };

export const getReplies = (payload: TGetRepliesRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startRepliesLoader());
    const { status, data }: APIResponse<TGetCommentsResponse> =
      await new SocialMediaAPIService().getReplies(payload);

    if (status === 200) {
      dispatch(getRepliesSuccess(data?.data));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopRepliesLoader());
  }
};

export const editReplyToComment =
  (payload: TEditReplyToCommentRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(updateEditReplyToCommentLoader(true));
      const { status }: APIResponse<TReplyToCommentResponse> =
        await new SocialMediaAPIService().editReplyToComment(payload);

      if (status === 200) {
        const { replyId, text } = payload;

        dispatch(editReplyToCommentSuccess({ replyId, text }));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(updateEditReplyToCommentLoader(false));
    }
  };

export const likeReply = (payload: TLikeReplyRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(likeReplySuccess(payload));
    const response: APIResponse<TLikeReplyResponse> = await new SocialMediaAPIService().likeReply(
      payload
    );

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const unLikeReply = (payload: TUnLikeReplyRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(unlikeReplySuccess(payload));
    const response: APIResponse<TUnLikeReplyResponse> =
      await new SocialMediaAPIService().unLikeReply(payload);

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const deleteReplyToComment =
  (payload: TDeleteReplyToCommentRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TDeleteReplyToCommentResponse> =
        await new SocialMediaAPIService().deleteReplyToComment(payload);

      if (status === 200) {
        dispatch(deleteReplySuccess(payload));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const follow = (payload: TFollowRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status }: APIResponse<TFollowResponse> = await new SocialMediaAPIService().follow(
      payload
    );

    if (status === 200) {
      const followUserId = payload.followUserId;
      dispatch(followSuccess(followUserId));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const unFollow = (payload: TUnFollowRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status }: APIResponse<TUnFollowResponse> = await new SocialMediaAPIService().unFollow(
      payload
    );

    if (status === 200) {
      const followUserId = payload.followUserId;
      dispatch(unFollowSuccess(followUserId));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  }
};

export const getUserStats = (payload: TGetUserStatsRequest) => async (dispatch: AppDispatch) => {
  try {
    const { status, data }: APIResponse<TGetUserStatsResponse> =
      await new SocialMediaAPIService().getUserStats(payload);

    if (status === 200) {
      dispatch(getUserStatsSuccess(data?.data));
    }
  } catch (err) {
    dispatch(getUserStatsError());
  }
};

export const getMyCompanyStats =
  (payload: TGetUserStatsRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status, data }: APIResponse<TGetUserStatsResponse> =
        await new SocialMediaAPIService().getUserStats(payload);

      if (status === 200) {
        dispatch(getMyCompanyStatsSuccess(data?.data));
      }
    } catch (err) {}
  };

export const deleteMedia =
  (payload: TDeleteMediaActionRequest) => async (dispatch: AppDispatch) => {
    const { id } = payload;
    try {
      dispatch(removePostMedia(id));
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const dontRecommentPost =
  (payload: TDontRecommendRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TDontRecommendResponse> =
        await new SocialMediaAPIService().dontRecommendPost(payload);

      if (status === 200) {
        dispatch(dontRecommendPostSuccess(payload.postId));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };
