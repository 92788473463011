import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { textFormatter } from 'src/utils';

import './aboutMe.scss';

export type TAboutMe = {
  about: string;
};

const AboutMe = ({ about }: TAboutMe) => {
  return (
    <Card className="my-profile-about-me">
      <Typography
        as="p"
        variant="body-2"
        fontWeight="semiBold"
        className="my-profile-about-me__heading"
      >
        About Me
      </Typography>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        className="my-profile-about-me__content"
      >
        {textFormatter({ value: about })}
      </Typography>
    </Card>
  );
};

export default AboutMe;
