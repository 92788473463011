import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';

import './selectCard.scss';

import { TypographySize, TypographyVariant } from '../../typography/typography.types';
import Typography from '../../typography/Typography';

export type TSelectCardProps = {
  selected?: React.ReactNode;
  startIcon?: React.ReactNode;
  endIcon?: React.ReactNode;
  variant?: componentSizeVariantsEnum;
} & HTMLAttributes<HTMLDivElement>;

/**
 * Props for the SelectCard component.
 * @typedef {Object} TSelectCardProps
 * @param {boolean} [selected] - Specifies if the select card is selected.
 * @param {React.ReactNode} [startIcon] - The icon element that meant to placed at the start.
 * @param {React.ReactNode} [endIcon] - The icon element that meant to placed at the end.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the select card.
 */
const SelectCard = ({
  className,
  children,
  selected = false,
  variant = componentSizeVariantsEnum.MEDIUM,
  ...rest
}: TSelectCardProps) => {
  const typographyVariant: Record<componentSizeVariantsEnum, TypographyVariant> = {
    [componentSizeVariantsEnum.SMALL]: 'caption',
    [componentSizeVariantsEnum.MEDIUM]: 'body-2',
    [componentSizeVariantsEnum.LARGE]: 'body-1'
  };

  const typographySize: Record<componentSizeVariantsEnum, TypographySize> = {
    [componentSizeVariantsEnum.SMALL]: 'medium',
    [componentSizeVariantsEnum.MEDIUM]: 'medium',
    [componentSizeVariantsEnum.LARGE]: 'medium'
  };

  return (
    <div
      {...rest}
      className={classNames(
        className,
        'select-card-component',
        `select-card-component--${variant}`,
        {
          'select-card-component--selected': selected
        }
      )}
    >
      {rest.startIcon && (
        <div
          className={classNames(
            'select-card-component__icon',
            `select-card-component__icon--${variant}`
          )}
        >
          {rest.startIcon}
        </div>
      )}
      <Typography
        variant={typographyVariant[variant]}
        fontWeight={typographySize[variant]}
        as="span"
        className={classNames(className, 'select-card-component__text', {
          'select-card-component__text--selected': selected
        })}
      >
        {children}
      </Typography>
      {rest.endIcon && (
        <div
          className={classNames(
            'select-card-component__icon',
            `select-card-component__icon--${variant}`
          )}
        >
          {rest.endIcon}
        </div>
      )}
    </div>
  );
};

export default SelectCard;
