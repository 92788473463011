import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUrlParamValue } from 'src/hooks';
import { Card, LeftMenu, Typography } from 'src/components/common';
import Header from 'src/components/header/Header';
import { TSettingsSections } from 'src/components/settings/settings.types';
import { SettingsChangePassword } from 'src/components/settings';

import './settings.scss';

const Settings = () => {
  // Hooks
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();

  // Constants
  const currentTab: TSettingsSections = (paramValue({ paramName: 'tab' }) ||
    'changePassword') as unknown as TSettingsSections;

  const settingsSections: { key: TSettingsSections; label: string; component: ReactNode }[] = [
    { key: 'changePassword', label: 'Change Password', component: <SettingsChangePassword /> }
  ];

  const changeSettingsTab = ({ tab }: { tab: TSettingsSections }) => {
    navigate(`/settings?tab=${tab}`);
  };

  const currentSettingsComponent = settingsSections.find(
    (item) => item?.key === currentTab
  )?.component;

  return (
    <div className="settings">
      <Header />
      <div className="settings__body">
        <Typography
          variant="subHeading-2"
          fontWeight="semiBold"
          className="settings__body__heading"
        >
          Settings
        </Typography>

        <div className="settings__body__sub-sections">
          <Card className="settings__body__sub-sections__menu">
            {settingsSections.map(({ label, key }, index) => {
              const isActive: boolean = currentTab === key;

              return (
                <LeftMenu
                  key={key}
                  state={isActive ? 'active' : 'default'}
                  onClick={() => {
                    changeSettingsTab({ tab: key });
                  }}
                  className="settings__body__sub-sections__menu__item"
                >
                  <Typography as="p" variant="body-2" fontWeight="medium">
                    {label}
                  </Typography>
                </LeftMenu>
              );
            })}
          </Card>
          <div className="settings__body__sub-sections__component">{currentSettingsComponent}</div>
        </div>
      </div>
    </div>
  );
};

export default Settings;
