import { createContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Typography from 'src/components/common/typography/Typography';
import Tabs, { TTabItem } from 'src/components/common/tabs/Tabs';
import { useAppSelector, useAppDispatch } from 'src/store/hooks';
import {
  About,
  Activities,
  AppliedProgramsList,
  Education,
  ProfileCard,
  WorkExperience
} from 'src/components/profile';
import Header from 'src/components/header/Header';
import { StorageUtils } from 'src/utils';
import { getProfile } from 'src/store/profile/profile.slice';
import { TGetUserStatsRequest } from 'src/services/apiEndpoint.types.ts';
import { getUserStats, incrementNewsFeedPage } from 'src/store/socialMedia/socialMedia.slice';
import { useGetIdentity } from 'src/hooks';
import { ConditionalComponent, InfiniteScroll } from 'src/components/common';
import {
  CompanyCard,
  CreateCompanyCard,
  InviteNetwork,
  StartupNewsResources
} from 'src/components/cards';

import './myProfile.scss';
export type TMyProfileTabs =
  | 'about'
  | 'work-experience'
  | 'education'
  | 'my-activities'
  | 'applied-programs';

export const SelfUserContext = createContext(false);

const MyProfile = () => {
  const [activeProfileTab, setActiveProfileTab] = useState<TMyProfileTabs>('about');

  const { loading } = useAppSelector<{ modalId: string; loading: boolean }>(
    (store) => store.common
  );
  const { profile } = useAppSelector((store) => store.profile);
  const { newsFeedMoreItemsLeft } = useAppSelector((store) => store.socialMedia);

  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserId, loggedInUserType, loggedInCompanyId } = getIdentities();

  const loadMoreActivities = () => {
    if (newsFeedMoreItemsLeft && !loading && activeProfileTab === 'my-activities') {
      dispatch(incrementNewsFeedPage(1));
    }
  };

  const {
    about,
    address,
    dob,
    email,
    gender,
    introduction,
    linkedin,
    name,
    phoneNumber,
    profilePic,
    role,
    userExpertise,
    userIndustries,
    country,
    state,
    city,
    zipCode
  } = profile;

  const tabItems: TTabItem[] = [
    {
      key: 'about',
      text: 'About',
      disabled: false,
      component: (
        <About
          about={about}
          address={address}
          dob={dob}
          email={email}
          gender={gender}
          linkedin={linkedin}
          phoneNumber={phoneNumber}
          role={role}
          userExpertise={userExpertise}
          userIndustries={userIndustries}
          country={country}
          state={state}
          city={city}
          zipCode={zipCode}
        />
      )
    },
    {
      key: 'work-experience',
      text: 'Work Experience',
      disabled: false,
      component: <WorkExperience experiences={profile?.experience || []} />
    },
    {
      key: 'education',
      text: 'Education',
      disabled: false,
      component: <Education education={profile?.education || []} />
    },
    {
      key: 'my-activities',
      text: 'My Activities',
      disabled: false,
      component: (
        <Activities userId={profile?.id} viewerId={loggedInUserId} viewerType={loggedInUserType} />
      )
    },
    {
      key: 'applied-programs',
      text: 'Applied Programs',
      disabled: false,
      component: <AppliedProgramsList />
    }
  ];

  const changeMyProfileTab = (value: string) => {
    setActiveProfileTab(value as TMyProfileTabs);
  };

  const handleGetUserStats = ({ id }: { id: string }) => {
    const payload: TGetUserStatsRequest = {
      userId: id,
      userType: 'User'
    };

    dispatch(getUserStats(payload));
  };

  useEffect(() => {
    const isProfileCompany = StorageUtils.get('userType') === 'Company';
    // If it is not a company and
    // If the profile exists and
    // Role doesn't exist, redirect the user to onboarding screen
    if (profile.id && !profile.role && !isProfileCompany) {
      navigate('/onboarding-user-type');
    }
  }, [profile.id]);

  useEffect(() => {
    const id = StorageUtils.get('userId') as unknown as string;
    handleGetUserStats({ id });
    dispatch(getProfile({ id }));
  }, []);

  return (
    <SelfUserContext.Provider value={true}>
      <InfiniteScroll
        className="my-profile"
        onScrollEnd={loadMoreActivities}
        loading={loading}
        moreItemsLeft={newsFeedMoreItemsLeft}
        showFooter={activeProfileTab === 'my-activities'}
      >
        <Header />
        <div className="my-profile__body">
          <Typography as="p" variant="subHeading-1" fontWeight="semiBold">
            My Profile
          </Typography>
          <div className="my-profile__body__content">
            <div className="my-profile__body__content__body">
              <ProfileCard
                introduction={introduction}
                name={name}
                profilePic={profilePic}
                role={role}
                userId={profile.id}
              />
              <Tabs
                activeItem={activeProfileTab}
                onTabChange={changeMyProfileTab}
                items={tabItems}
              />
            </div>
            <div className="my-profile__body__content__cards">
              {loggedInUserType === 'User' && (
                <ConditionalComponent
                  condition={Boolean(loggedInCompanyId)}
                  whenTrue={<CompanyCard selfCompany={true} companyId={loggedInCompanyId} />}
                  whenFalse={<CreateCompanyCard />}
                />
              )}

              <InviteNetwork />

              <StartupNewsResources />
            </div>
          </div>
        </div>
      </InfiniteScroll>
    </SelfUserContext.Provider>
  );
};

export default MyProfile;
