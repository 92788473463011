import axios from 'axios';

import {
  TSearchAnnouncementRequest,
  TSearchCitiesRequest,
  TSearchCompanyRequest,
  TSearchMentorInvestorRequest,
  TSearchPeopleRequest,
  TSearchPostsRequest
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';

export class SearchAPIService extends BaseAPIService {
  private searchPeoplePath = '/search/people';
  private searchMentorInvestorPath = '/search/mentor-investor';
  private searchCompanyPath = '/search/company';
  private searchPostsPath = '/search/posts';
  private searchAnnouncementPath = '/search/announcement';
  private searchCitiesPath = '/search/cities';

  constructor() {
    // Search api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_SEARCH_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async searchPeople<TSearchPeopleResponse>(
    searchPeopleRequest: TSearchPeopleRequest
  ): Promise<APIResponse<TSearchPeopleResponse>> {
    const searchPeopleResponse: APIResponse<TSearchPeopleResponse> = await this.post(
      this.searchPeoplePath,
      searchPeopleRequest,
      true
    );
    return searchPeopleResponse;
  }

  public async searchMentorInvestor<TSearchMentorInvestorResponse>(
    searchMentorInvestorRequest: TSearchMentorInvestorRequest
  ): Promise<APIResponse<TSearchMentorInvestorResponse>> {
    const searchMentorInvestorResponse: APIResponse<TSearchMentorInvestorResponse> =
      await this.post(this.searchMentorInvestorPath, searchMentorInvestorRequest, true);
    return searchMentorInvestorResponse;
  }

  public async searchCompany<TSearchCompanyResponse>(
    searchCompanyRequest: TSearchCompanyRequest
  ): Promise<APIResponse<TSearchCompanyResponse>> {
    const searchCompanyResponse: APIResponse<TSearchCompanyResponse> = await this.post(
      this.searchCompanyPath,
      searchCompanyRequest,
      true
    );
    return searchCompanyResponse;
  }

  public async searchPosts<TSearchPostsResponse>(
    searchPostsRequest: TSearchPostsRequest
  ): Promise<APIResponse<TSearchPostsResponse>> {
    const searchPostsResponse: APIResponse<TSearchPostsResponse> = await this.post(
      this.searchPostsPath,
      searchPostsRequest,
      true
    );
    return searchPostsResponse;
  }

  public async searchAnnouncement<TSearchAnnouncementResponse>(
    searchAnnouncementRequest: TSearchAnnouncementRequest
  ): Promise<APIResponse<TSearchAnnouncementResponse>> {
    const searchAnnouncementResponse: APIResponse<TSearchAnnouncementResponse> = await this.post(
      this.searchAnnouncementPath,
      searchAnnouncementRequest,
      true
    );
    return searchAnnouncementResponse;
  }

  public async searchCities<TSearchCitiesResponse>(
    searchCitiesRequest: TSearchCitiesRequest
  ): Promise<APIResponse<TSearchCitiesResponse>> {
    const searchCitiesResponse: APIResponse<TSearchCitiesResponse> = await this.get(
      this.searchCitiesPath,
      true,
      searchCitiesRequest
    );
    return searchCitiesResponse;
  }
}
