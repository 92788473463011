import {
  TDeleteProfilePictureRequest,
  TEditProfileRequest,
  TGetPresignedUrlRequest,
  TGetProfileRequest,
  TUpdatedIndustriesRequest,
  TUpdateEducationRequest,
  TUpdateExperienceRequest,
  TUpdatedExpertiseRequest,
  TUploadProfilePictureRequest,
  TDeleteExperienceRequest,
  TDeleteEducationRequest
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';

export class ProfileAPIService extends BaseAPIService {
  private getProfilePath: string = '/profile/get-profile';
  private editProfilePath: string = '/profile/edit-profile';
  private getPresignedUrlPath: string = '/profile/get-presigned-url';
  private deleteProfilePicturePath: string = '/profile/delete-profile-pic';
  private updateExperiencePath: string = '/profile/update-experience';
  private updateEducationPath: string = '/profile/update-education';
  private updateExpertisePath: string = '/profile/update-expertise';
  private updateIndustriesPath: string = '/profile/update-industries';
  private deleteExperiencePath: string = '/profile/update-experience';
  private deleteEducationPath: string = '/profile/update-education';

  public async getProfile<TGetProfileResponse>(
    getProfileRequest: TGetProfileRequest
  ): Promise<APIResponse<TGetProfileResponse>> {
    const getProfileResponse: APIResponse<TGetProfileResponse> = await this.get(
      this.getProfilePath,
      true,
      getProfileRequest
    );
    return getProfileResponse;
  }

  public async editProfile<TEditProfileResponse>(
    editProfileRequest: TEditProfileRequest
  ): Promise<APIResponse<TEditProfileResponse>> {
    const editProfileResponse: APIResponse<TEditProfileResponse> = await this.post(
      this.editProfilePath,
      editProfileRequest,
      true
    );
    return editProfileResponse;
  }

  public async uploadProfilePicture<TUploadProfilePictureResponse>(
    uploadProfilePictureRequest: TUploadProfilePictureRequest
  ): Promise<APIResponse<TUploadProfilePictureResponse>> {
    const { data, includeAuthorizationHeaders = true } = uploadProfilePictureRequest;
    const uploadProfilePictureResponse: APIResponse<TUploadProfilePictureResponse> = await this.put(
      uploadProfilePictureRequest.presignedUrl,
      data,
      includeAuthorizationHeaders
    );
    return uploadProfilePictureResponse;
  }

  public async getPresignedUrl<TGetPresignedUrlResponse>(
    getPresignedUrlRequest: TGetPresignedUrlRequest
  ): Promise<APIResponse<TGetPresignedUrlResponse>> {
    const getPresignedUrlResponse: APIResponse<TGetPresignedUrlResponse> = await this.get(
      this.getPresignedUrlPath,
      true,
      getPresignedUrlRequest
    );
    return getPresignedUrlResponse;
  }

  public async deleteProfilePicture<TDeleteProfilePictureResponse>(
    deleteProfilePictureRequest: TDeleteProfilePictureRequest
  ): Promise<APIResponse<TDeleteProfilePictureResponse>> {
    const deleteProfilePictureResponse: APIResponse<TDeleteProfilePictureResponse> =
      await this.deleteWithParams(this.deleteProfilePicturePath, true, deleteProfilePictureRequest);
    return deleteProfilePictureResponse;
  }

  public async updateExperience<TUpdateExperienceResponse>(
    updateExperienceRequest: TUpdateExperienceRequest
  ): Promise<APIResponse<TUpdateExperienceResponse>> {
    const updateExperienceResponse: APIResponse<TUpdateExperienceResponse> = await this.post(
      this.updateExperiencePath,
      updateExperienceRequest,
      true
    );
    return updateExperienceResponse;
  }

  public async updateEducation<TUpdateEducationResponse>(
    updateEducationRequest: TUpdateEducationRequest
  ): Promise<APIResponse<TUpdateEducationResponse>> {
    const updateEducationResponse: APIResponse<TUpdateEducationResponse> = await this.post(
      this.updateEducationPath,
      updateEducationRequest,
      true
    );
    return updateEducationResponse;
  }

  public async updateExpertise<TUpdatedExpertiseResponse>(
    updateExpertiseRequest: TUpdatedExpertiseRequest
  ): Promise<APIResponse<TUpdatedExpertiseResponse>> {
    const updateExpertiseResponse: APIResponse<TUpdatedExpertiseResponse> = await this.post(
      this.updateExpertisePath,
      updateExpertiseRequest,
      true
    );
    return updateExpertiseResponse;
  }

  public async updateIndustries<TUpdatedIndustriesResponse>(
    updateIndustriesRequest: TUpdatedIndustriesRequest
  ): Promise<APIResponse<TUpdatedIndustriesResponse>> {
    const updateIndustriesResponse: APIResponse<TUpdatedIndustriesResponse> = await this.post(
      this.updateIndustriesPath,
      updateIndustriesRequest,
      true
    );
    return updateIndustriesResponse;
  }

  public async deleteExperience<TDeleteExperienceResponse>(
    deleteExperienceRequest: TDeleteExperienceRequest
  ): Promise<APIResponse<TDeleteExperienceResponse>> {
    const deleteExperienceResponse: APIResponse<TDeleteExperienceResponse> = await this.post(
      this.deleteExperiencePath,
      deleteExperienceRequest,
      true
    );
    return deleteExperienceResponse;
  }

  public async deleteEducation<TDeleteEducationResponse>(
    deleteEducationRequest: TDeleteEducationRequest
  ): Promise<APIResponse<TDeleteEducationResponse>> {
    const deleteEducationResponse: APIResponse<TDeleteEducationResponse> = await this.post(
      this.deleteEducationPath,
      deleteEducationRequest,
      true
    );
    return deleteEducationResponse;
  }
}
