import classNames from 'classnames';
import { ActionIcon, Typography } from 'src/components/common';
import { EditIcon, PlusIcon, TrashIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';

import './dynamicField.scss';
import { TDynamicOptionsFieldProps } from './dynamicField.types';

import { TCustomApplicationSection } from '../customApplicationForm.types';

const TableFields = ({
  id,
  sectionId,
  type,
  label,
  placeholder,
  required,
  options,
  validations,
  response,
  files,
  hasCharacterLimit,
  openChangeFieldModal,
  openDeleteFieldModal,
  handleUpdateField,
  fieldTitle,
  fieldPlaceholder,
  errors
}: TDynamicOptionsFieldProps) => {
  const updateField = ({ fieldChunk }: { fieldChunk: Partial<TCustomApplicationSection> }) => {
    const field: TCustomApplicationSection = {
      id,
      type,
      label,
      placeholder,
      required,
      options,
      validations,
      response,
      files,
      hasCharacterLimit,
      ...fieldChunk
    };

    handleUpdateField({ sectionId, fieldId: id, field });
  };

  const addOptionToField = () => {
    const newOptions = options?.concat('');

    updateField({ fieldChunk: { options: newOptions } });
  };

  const deleteOptionToField = ({ optionIndex }: { optionIndex: number }) => {
    const newOptions = JSON.parse(JSON.stringify(options));
    newOptions.splice(optionIndex, 1);

    const field: TCustomApplicationSection = {
      id,
      type,
      label,
      placeholder,
      required,
      options: newOptions,
      validations,
      response,
      files,
      hasCharacterLimit
    };

    handleUpdateField({ sectionId, fieldId: id, field });
  };

  const updateOption = ({ optionIndex, value }: { optionIndex: number; value: string }) => {
    if (options) {
      const newOptions: string[] = JSON.parse(JSON.stringify(options));
      newOptions[optionIndex] = value;

      updateField({ fieldChunk: { options: newOptions } });
    }
  };

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          {fieldTitle}
        </Typography>
        <div className="dynamic-field__header__actions">
          <div className="dynamic-field__header__actions__required">
            <input
              type="checkbox"
              id="textFieldRequired"
              checked={required}
              onChange={(e) => {
                updateField({ fieldChunk: { required: e.target.checked } });
              }}
            />
            <Typography
              as="label"
              htmlFor="textFieldRequired"
              variant="caption"
              fontWeight="regular"
            >
              Required
            </Typography>
          </div>
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              openDeleteFieldModal({ fieldId: id, sectionId });
            }}
          >
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>
      <div className="dynamic-field__field">
        {' '}
        <InputField
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder={fieldPlaceholder}
          type="text"
          value={label}
          onChange={(e) => {
            updateField({ fieldChunk: { label: e.target.value } });
          }}
          errorMessage={errors?.[id]}
          isTouched={true}
        />
        {options && options?.length > 0 && (
          <div className="dynamic-field__field__dropdown-options">
            {options.map((option, index) => {
              return (
                <div key={index} className="dynamic-field__field__dropdown-options__option">
                  <InputField
                    key={index}
                    id={`${index}`}
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder={`Column Name ${index + 1}`}
                    type="text"
                    value={option}
                    onChange={(e) => {
                      updateOption({ optionIndex: index, value: e.target.value });
                    }}
                    errorMessage={errors?.[`Column Name-${index}-${id}`]}
                    isTouched={true}
                  />{' '}
                  <ActionIcon
                    className={classNames('dynamic-field__field__dropdown-options__option', {
                      'hide-icon': index < 2
                    })}
                    onClick={() => {
                      if (index > 1) {
                        deleteOptionToField({ optionIndex: index });
                      }
                    }}
                  >
                    <TrashIcon />
                  </ActionIcon>
                </div>
              );
            })}
          </div>
        )}
        <Button
          size="small"
          variant="tertiary"
          startIcon={<PlusIcon />}
          className="dynamic-field__field__add-option"
          onClick={addOptionToField}
        >
          Add Column
        </Button>{' '}
      </div>
    </div>
  );
};

export default TableFields;
