export const colleges = [
  {
    id: '1',
    label: '1 December University of Alba Iulia',
    value: '1 December University of Alba Iulia'
  },
  {
    id: '2',
    label: '2nd Military Medical University',
    value: '2nd Military Medical University'
  },
  {
    id: '3',
    label: '3rd Military Medical University',
    value: '3rd Military Medical University'
  },
  {
    id: '4',
    label: '42 FR',
    value: '42 FR'
  },
  {
    id: '5',
    label: '42 US',
    value: '42 US'
  },
  {
    id: '6',
    label: '4th Military Medical University',
    value: '4th Military Medical University'
  },
  {
    id: '7',
    label: 'ABM University College',
    value: 'ABM University College'
  },
  {
    id: '8',
    label: 'ABTI American University of Nigeria',
    value: 'ABTI American University of Nigeria'
  },
  {
    id: '9',
    label: 'AGH University of Science and Technology',
    value: 'AGH University of Science and Technology'
  },
  {
    id: '10',
    label: 'AISECT University',
    value: 'AISECT University'
  },
  {
    id: '11',
    label: 'AISTEDA',
    value: 'AISTEDA'
  },
  {
    id: '12',
    label: 'AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig',
    value: 'AKAD Hochschulen für Berufstätige, Fachhochschule Leipzig'
  },
  {
    id: '13',
    label: 'ARYA Institute of Engineering & Technology',
    value: 'ARYA Institute of Engineering & Technology'
  },
  {
    id: '14',
    label: 'ASA College',
    value: 'ASA College'
  },
  {
    id: '15',
    label: 'Aalborg Business College',
    value: 'Aalborg Business College'
  },
  {
    id: '16',
    label: 'Aalborg University',
    value: 'Aalborg University'
  },
  {
    id: '17',
    label: 'Aalto University',
    value: 'Aalto University'
  },
  {
    id: '18',
    label: 'Aaniiih Nakoda College',
    value: 'Aaniiih Nakoda College'
  },
  {
    id: '19',
    label: 'Aarhus School of Architecture',
    value: 'Aarhus School of Architecture'
  },
  {
    id: '20',
    label: 'Aarhus School of Business',
    value: 'Aarhus School of Business'
  },
  {
    id: '21',
    label: 'Aarhus Technical College',
    value: 'Aarhus Technical College'
  },
  {
    id: '22',
    label: 'Aarhus University',
    value: 'Aarhus University'
  },
  {
    id: '23',
    label: 'Abai State University',
    value: 'Abai State University'
  },
  {
    id: '24',
    label: 'Abant Izzet Baysal University',
    value: 'Abant Izzet Baysal University'
  },
  {
    id: '25',
    label: 'Abasyn University Peshawar',
    value: 'Abasyn University Peshawar'
  },
  {
    id: '26',
    label: 'Abdullah Gul University',
    value: 'Abdullah Gul University'
  },
  {
    id: '27',
    label: 'Abdus Salam international centre for theoretical physics',
    value: 'Abdus Salam international centre for theoretical physics'
  },
  {
    id: '28',
    label: 'Aberystwyth University',
    value: 'Aberystwyth University'
  },
  {
    id: '29',
    label: 'Abia State Polytechnic',
    value: 'Abia State Polytechnic'
  },
  {
    id: '30',
    label: 'Abia State University',
    value: 'Abia State University'
  },
  {
    id: '31',
    label: 'Abilene Christian University',
    value: 'Abilene Christian University'
  },
  {
    id: '32',
    label: 'Abo Akademi University',
    value: 'Abo Akademi University'
  },
  {
    id: '33',
    label: 'Abraham Baldwin Agricultural College',
    value: 'Abraham Baldwin Agricultural College'
  },
  {
    id: '34',
    label: 'Abu Dhabi University',
    value: 'Abu Dhabi University'
  },
  {
    id: '35',
    label: 'Abubakar Tafawa Balewa University',
    value: 'Abubakar Tafawa Balewa University'
  },
  {
    id: '36',
    label: 'Abylai Khan University',
    value: 'Abylai Khan University'
  },
  {
    id: '37',
    label: 'Academia Nacional Superior de Orquesta',
    value: 'Academia Nacional Superior de Orquesta'
  },
  {
    id: '38',
    label: 'Academia Tehnica Militara',
    value: 'Academia Tehnica Militara'
  },
  {
    id: '39',
    label: 'Academia de Studii Economice din Bucuresti',
    value: 'Academia de Studii Economice din Bucuresti'
  },
  {
    id: '40',
    label: 'Academic University',
    value: 'Academic University'
  },
  {
    id: '41',
    label: 'Academic medical center at State University of New York at Stony Brook',
    value: 'Academic medical center at State University of New York at Stony Brook'
  },
  {
    id: '42',
    label: 'Academy of Art University',
    value: 'Academy of Art University'
  },
  {
    id: '43',
    label: 'Academy of Arts',
    value: 'Academy of Arts'
  },
  {
    id: '44',
    label: 'Academy of Arts "George Enescu" Iasi',
    value: 'Academy of Arts "George Enescu" Iasi'
  },
  {
    id: '45',
    label: 'Academy of Arts in Banská Bystrica',
    value: 'Academy of Arts in Banská Bystrica'
  },
  {
    id: '46',
    label: 'Academy of Drama and Film',
    value: 'Academy of Drama and Film'
  },
  {
    id: '47',
    label: 'Academy of Economic Studies of Moldova',
    value: 'Academy of Economic Studies of Moldova'
  },
  {
    id: '48',
    label: 'Academy of Economics "Dimitur A. Tscenov"',
    value: 'Academy of Economics "Dimitur A. Tscenov"'
  },
  {
    id: '49',
    label: 'Academy of Economics in Cracow',
    value: 'Academy of Economics in Cracow'
  },
  {
    id: '50',
    label: 'Academy of Economics in Katowice',
    value: 'Academy of Economics in Katowice'
  },
  {
    id: '51',
    label: 'Academy of Economics in Poznan',
    value: 'Academy of Economics in Poznan'
  },
  {
    id: '52',
    label: 'Academy of Economics in Wroclaw',
    value: 'Academy of Economics in Wroclaw'
  },
  {
    id: '53',
    label: 'Academy of Fine Arts',
    value: 'Academy of Fine Arts'
  },
  {
    id: '54',
    label: 'Academy of Fine Arts and Design in Bratislava',
    value: 'Academy of Fine Arts and Design in Bratislava'
  },
  {
    id: '55',
    label: 'Academy of Humanities and Economics in Lodz',
    value: 'Academy of Humanities and Economics in Lodz'
  },
  {
    id: '56',
    label: 'Academy of International Economic and Political Relations, Gdynia',
    value: 'Academy of International Economic and Political Relations, Gdynia'
  },
  {
    id: '57',
    label: 'Academy of Management and Entrepreneurship',
    value: 'Academy of Management and Entrepreneurship'
  },
  {
    id: '58',
    label: 'Academy of Music "Georghe Dima" Cluj-Napoca',
    value: 'Academy of Music "Georghe Dima" Cluj-Napoca'
  },
  {
    id: '59',
    label: 'Academy of Music, Dance and Fine Arts',
    value: 'Academy of Music, Dance and Fine Arts'
  },
  {
    id: '60',
    label: 'Academy of Performing Arts, Film and TV Fakulty',
    value: 'Academy of Performing Arts, Film and TV Fakulty'
  },
  {
    id: '61',
    label: 'Academy of Public Administration of Belarus',
    value: 'Academy of Public Administration of Belarus'
  },
  {
    id: '62',
    label: 'Academy of Sports and Physical Training',
    value: 'Academy of Sports and Physical Training'
  },
  {
    id: '63',
    label: 'Academy of the Ministry of Internal Affairs of the Republic of Belarus',
    value: 'Academy of the Ministry of Internal Affairs of the Republic of Belarus'
  },
  {
    id: '64',
    label: 'Acadia University',
    value: 'Acadia University'
  },
  {
    id: '65',
    label: "Académie d'Aix-Marseille",
    value: "Académie d'Aix-Marseille"
  },
  {
    id: '66',
    label: "Académie d'Orléans-Tours",
    value: "Académie d'Orléans-Tours"
  },
  {
    id: '67',
    label: 'Académie de Besançon',
    value: 'Académie de Besançon'
  },
  {
    id: '68',
    label: 'Académie de Bordeaux',
    value: 'Académie de Bordeaux'
  },
  {
    id: '69',
    label: 'Académie de Créteil',
    value: 'Académie de Créteil'
  },
  {
    id: '70',
    label: 'Académie de Mayotte',
    value: 'Académie de Mayotte'
  },
  {
    id: '71',
    label: 'Académie de Paris',
    value: 'Académie de Paris'
  },
  {
    id: '72',
    label: 'Accra Technical University',
    value: 'Accra Technical University'
  },
  {
    id: '73',
    label: 'Acdemic Center for Law and Business',
    value: 'Acdemic Center for Law and Business'
  },
  {
    id: '74',
    label: 'Acharya Nagarjuna University',
    value: 'Acharya Nagarjuna University'
  },
  {
    id: '75',
    label: 'Acharya Ranga Agricultural University',
    value: 'Acharya Ranga Agricultural University'
  },
  {
    id: '76',
    label: 'Acibadem University',
    value: 'Acibadem University'
  },
  {
    id: '77',
    label: 'Adam Mickiewicz University of Poznan',
    value: 'Adam Mickiewicz University of Poznan'
  },
  {
    id: '78',
    label: 'Adama Science and Technology University',
    value: 'Adama Science and Technology University'
  },
  {
    id: '79',
    label: 'Adamawa State University',
    value: 'Adamawa State University'
  },
  {
    id: '80',
    label: 'Adamson University',
    value: 'Adamson University'
  },
  {
    id: '81',
    label: 'Adana Science and Technology University',
    value: 'Adana Science and Technology University'
  },
  {
    id: '82',
    label: 'Addis Ababa Science & Technology University',
    value: 'Addis Ababa Science & Technology University'
  },
  {
    id: '83',
    label: 'Addis Ababa University',
    value: 'Addis Ababa University'
  },
  {
    id: '84',
    label: 'Adekunle Ajasin University',
    value: 'Adekunle Ajasin University'
  },
  {
    id: '85',
    label: 'Adeleke University',
    value: 'Adeleke University'
  },
  {
    id: '86',
    label: 'Adelphi University',
    value: 'Adelphi University'
  },
  {
    id: '87',
    label: 'Adeyemi College of Education',
    value: 'Adeyemi College of Education'
  },
  {
    id: '88',
    label: 'Adiban Institute of Higher Education',
    value: 'Adiban Institute of Higher Education'
  },
  {
    id: '89',
    label: 'Adigrat University',
    value: 'Adigrat University'
  },
  {
    id: '90',
    label: 'Adiran Collge',
    value: 'Adiran Collge'
  },
  {
    id: '91',
    label: 'Adirondack Community College',
    value: 'Adirondack Community College'
  },
  {
    id: '92',
    label: 'Adiyaman University',
    value: 'Adiyaman University'
  },
  {
    id: '93',
    label: 'Adnan Menderes University',
    value: 'Adnan Menderes University'
  },
  {
    id: '94',
    label: 'Advance Tertiary College',
    value: 'Advance Tertiary College'
  },
  {
    id: '95',
    label: 'Adventist University of Africa',
    value: 'Adventist University of Africa'
  },
  {
    id: '96',
    label: 'Adventist University of Central Africa',
    value: 'Adventist University of Central Africa'
  },
  {
    id: '97',
    label: 'Adventist University of the Philippines',
    value: 'Adventist University of the Philippines'
  },
  {
    id: '98',
    label: 'Adygeja State University',
    value: 'Adygeja State University'
  },
  {
    id: '99',
    label: 'Aegean University',
    value: 'Aegean University'
  },
  {
    id: '100',
    label: 'Afe Babalola University',
    value: 'Afe Babalola University'
  },
  {
    id: '101',
    label: 'Afeka Tel Aviv Academic College of Engineering',
    value: 'Afeka Tel Aviv Academic College of Engineering'
  },
  {
    id: '102',
    label: 'Afghan University',
    value: 'Afghan University'
  },
  {
    id: '103',
    label: 'Africa International University',
    value: 'Africa International University'
  },
  {
    id: '104',
    label: 'Africa Nazarene University',
    value: 'Africa Nazarene University'
  },
  {
    id: '105',
    label: 'Africa University',
    value: 'Africa University'
  },
  {
    id: '106',
    label: 'African University of Science and Technology',
    value: 'African University of Science and Technology'
  },
  {
    id: '107',
    label: 'African Virtual University',
    value: 'African Virtual University'
  },
  {
    id: '108',
    label: 'Aga Khan University',
    value: 'Aga Khan University'
  },
  {
    id: '109',
    label: 'Agder University College',
    value: 'Agder University College'
  },
  {
    id: '110',
    label: 'Agnes Scott College',
    value: 'Agnes Scott College'
  },
  {
    id: '111',
    label: 'Agri Ibrahim Cecen University',
    value: 'Agri Ibrahim Cecen University'
  },
  {
    id: '112',
    label: 'Agricultural University of Athens',
    value: 'Agricultural University of Athens'
  },
  {
    id: '113',
    label: 'Agricultural University of Cracow',
    value: 'Agricultural University of Cracow'
  },
  {
    id: '114',
    label: 'Agricultural University of Lublin',
    value: 'Agricultural University of Lublin'
  },
  {
    id: '115',
    label: 'Agricultural University of Norway',
    value: 'Agricultural University of Norway'
  },
  {
    id: '116',
    label: 'Agricultural University of Plovdiv',
    value: 'Agricultural University of Plovdiv'
  },
  {
    id: '117',
    label: 'Agricultural University of Poznan',
    value: 'Agricultural University of Poznan'
  },
  {
    id: '118',
    label: 'Agricultural University of Szczecin',
    value: 'Agricultural University of Szczecin'
  },
  {
    id: '119',
    label: 'Agricultural University of Tirana',
    value: 'Agricultural University of Tirana'
  },
  {
    id: '120',
    label: 'Agricultural University of Warsaw',
    value: 'Agricultural University of Warsaw'
  },
  {
    id: '121',
    label: 'Agricultural University of Wroclaw',
    value: 'Agricultural University of Wroclaw'
  },
  {
    id: '122',
    label: 'Agricultural-Technical Academy in Olsztyn',
    value: 'Agricultural-Technical Academy in Olsztyn'
  },
  {
    id: '123',
    label: 'Agriculture and Forestry University',
    value: 'Agriculture and Forestry University'
  },
  {
    id: '124',
    label: 'AgroParisTech',
    value: 'AgroParisTech'
  },
  {
    id: '125',
    label: 'AgroSup Dijon',
    value: 'AgroSup Dijon'
  },
  {
    id: '126',
    label: 'Ahfad University for Women',
    value: 'Ahfad University for Women'
  },
  {
    id: '127',
    label: 'Ahi Evran University',
    value: 'Ahi Evran University'
  },
  {
    id: '128',
    label: 'Ahlulbait International University',
    value: 'Ahlulbait International University'
  },
  {
    id: '129',
    label: 'Ahmadu Bello University',
    value: 'Ahmadu Bello University'
  },
  {
    id: '130',
    label: 'Ahmed Medical Institute',
    value: 'Ahmed Medical Institute'
  },
  {
    id: '131',
    label: 'Ahmedabad University',
    value: 'Ahmedabad University'
  },
  {
    id: '132',
    label: 'Ahsanullah University of Science & Technology',
    value: 'Ahsanullah University of Science & Technology'
  },
  {
    id: '133',
    label: 'Ahvaz Jondishapur University of Medical Sciences',
    value: 'Ahvaz Jondishapur University of Medical Sciences'
  },
  {
    id: '134',
    label: 'Ahvaz University of Medical Sciences',
    value: 'Ahvaz University of Medical Sciences'
  },
  {
    id: '135',
    label: 'Aichi Bunkyo University',
    value: 'Aichi Bunkyo University'
  },
  {
    id: '136',
    label: 'Aichi Gakuin University',
    value: 'Aichi Gakuin University'
  },
  {
    id: '137',
    label: 'Aichi Gakusen University',
    value: 'Aichi Gakusen University'
  },
  {
    id: '138',
    label: 'Aichi Institute of Technology',
    value: 'Aichi Institute of Technology'
  },
  {
    id: '139',
    label: 'Aichi Medical University',
    value: 'Aichi Medical University'
  },
  {
    id: '140',
    label: 'Aichi Prefectural University',
    value: 'Aichi Prefectural University'
  },
  {
    id: '141',
    label: 'Aichi Prefectural University of Fine Arts & Music',
    value: 'Aichi Prefectural University of Fine Arts & Music'
  },
  {
    id: '142',
    label: 'Aichi Sangyo University',
    value: 'Aichi Sangyo University'
  },
  {
    id: '143',
    label: 'Aichi Shukutoku University',
    value: 'Aichi Shukutoku University'
  },
  {
    id: '144',
    label: 'Aichi University',
    value: 'Aichi University'
  },
  {
    id: '145',
    label: 'Aichi University of Education',
    value: 'Aichi University of Education'
  },
  {
    id: '146',
    label: 'Aiken Technical College',
    value: 'Aiken Technical College'
  },
  {
    id: '147',
    label: 'Aikoku Gakuen University',
    value: 'Aikoku Gakuen University'
  },
  {
    id: '148',
    label: 'Aims Community College',
    value: 'Aims Community College'
  },
  {
    id: '149',
    label: 'Aimst University',
    value: 'Aimst University'
  },
  {
    id: '150',
    label: 'Ain Shams University',
    value: 'Ain Shams University'
  },
  {
    id: '151',
    label: 'Air Force Academy',
    value: 'Air Force Academy'
  },
  {
    id: '152',
    label: 'Air Force Institute of Technology',
    value: 'Air Force Institute of Technology'
  },
  {
    id: '153',
    label: 'Air University',
    value: 'Air University'
  },
  {
    id: '154',
    label: 'Ajayi Crowther University',
    value: 'Ajayi Crowther University'
  },
  {
    id: '155',
    label: 'Ajman University of Science & Technology',
    value: 'Ajman University of Science & Technology'
  },
  {
    id: '156',
    label: 'Ajou University',
    value: 'Ajou University'
  },
  {
    id: '157',
    label: 'Akademi Farmasi Mitra Sehat Mandiri Sidoarjo',
    value: 'Akademi Farmasi Mitra Sehat Mandiri Sidoarjo'
  },
  {
    id: '158',
    label: 'Akademi Farmasi Yarsis Pekanbaru',
    value: 'Akademi Farmasi Yarsis Pekanbaru'
  },
  {
    id: '159',
    label: 'Akademia Podlaska',
    value: 'Akademia Podlaska'
  },
  {
    id: '160',
    label: 'Akademie der bildenden Künste Wien',
    value: 'Akademie der bildenden Künste Wien'
  },
  {
    id: '161',
    label: 'Akaki Tsereteli State University',
    value: 'Akaki Tsereteli State University'
  },
  {
    id: '162',
    label: 'Akanu Ibiam Federal Polytechnic, Unwana',
    value: 'Akanu Ibiam Federal Polytechnic, Unwana'
  },
  {
    id: '163',
    label: 'Akdeniz University',
    value: 'Akdeniz University'
  },
  {
    id: '164',
    label: 'Akhbar El Yom Academy',
    value: 'Akhbar El Yom Academy'
  },
  {
    id: '165',
    label: 'Akita University',
    value: 'Akita University'
  },
  {
    id: '166',
    label: 'Akita University of Economics and Law',
    value: 'Akita University of Economics and Law'
  },
  {
    id: '167',
    label: 'Akrofi-Christaller Institute of Theeology, Mission and  Culture',
    value: 'Akrofi-Christaller Institute of Theeology, Mission and  Culture'
  },
  {
    id: '168',
    label: 'Aksaray University',
    value: 'Aksaray University'
  },
  {
    id: '169',
    label: 'Aksum University',
    value: 'Aksum University'
  },
  {
    id: '170',
    label: 'Aktau State University',
    value: 'Aktau State University'
  },
  {
    id: '171',
    label: 'Akwa Ibom State University of Technology',
    value: 'Akwa Ibom State University of Technology'
  },
  {
    id: '172',
    label: 'Al Ahlia University',
    value: 'Al Ahlia University'
  },
  {
    id: '173',
    label: 'Al Akhawayn University',
    value: 'Al Akhawayn University'
  },
  {
    id: '174',
    label: 'Al Azhar University',
    value: 'Al Azhar University'
  },
  {
    id: '175',
    label: 'Al Fashir University',
    value: 'Al Fashir University'
  },
  {
    id: '176',
    label: 'Al Ghurair University',
    value: 'Al Ghurair University'
  },
  {
    id: '177',
    label: 'Al Hussein Bin Talal University',
    value: 'Al Hussein Bin Talal University'
  },
  {
    id: '178',
    label: 'Al Imam Al-Ouzai University',
    value: 'Al Imam Al-Ouzai University'
  },
  {
    id: '179',
    label: 'Al Khawarizmi International College',
    value: 'Al Khawarizmi International College'
  },
  {
    id: '180',
    label: 'Al Maarif University College',
    value: 'Al Maarif University College'
  },
  {
    id: '181',
    label: 'Al Madinah International University',
    value: 'Al Madinah International University'
  },
  {
    id: '182',
    label: 'Al Mamon University College',
    value: 'Al Mamon University College'
  },
  {
    id: '183',
    label: 'Al Mansour University College',
    value: 'Al Mansour University College'
  },
  {
    id: '184',
    label: 'Al Muthanna University',
    value: 'Al Muthanna University'
  },
  {
    id: '185',
    label: 'Al Nahrain University',
    value: 'Al Nahrain University'
  },
  {
    id: '186',
    label: 'Al Rafidain University College',
    value: 'Al Rafidain University College'
  },
  {
    id: '187',
    label: 'Al Rasheed University College',
    value: 'Al Rasheed University College'
  },
  {
    id: '188',
    label: 'Al Turath University College',
    value: 'Al Turath University College'
  },
  {
    id: '189',
    label: 'Al al-Bayt University',
    value: 'Al al-Bayt University'
  },
  {
    id: '190',
    label: 'Al-Ahliyya Amman University',
    value: 'Al-Ahliyya Amman University'
  },
  {
    id: '191',
    label: 'Al-Aqsa University',
    value: 'Al-Aqsa University'
  },
  {
    id: '192',
    label: 'Al-Asmarya University for Islamic Studies',
    value: 'Al-Asmarya University for Islamic Studies'
  },
  {
    id: '193',
    label: 'Al-Azhar University of Gaza',
    value: 'Al-Azhar University of Gaza'
  },
  {
    id: '194',
    label: 'Al-Baath University',
    value: 'Al-Baath University'
  },
  {
    id: '195',
    label: 'Al-Balqa Applied University',
    value: 'Al-Balqa Applied University'
  },
  {
    id: '196',
    label: 'Al-Birony University',
    value: 'Al-Birony University'
  },
  {
    id: '197',
    label: 'Al-Bukhari International University',
    value: 'Al-Bukhari International University'
  },
  {
    id: '198',
    label: 'Al-Buraimi University College',
    value: 'Al-Buraimi University College'
  },
  {
    id: '199',
    label: 'Al-Eman University',
    value: 'Al-Eman University'
  },
  {
    id: '200',
    label: 'Al-Farabi Kazakh National University',
    value: 'Al-Farabi Kazakh National University'
  },
  {
    id: '201',
    label: 'Al-Imam Mohamed Ibn Saud Islamic University',
    value: 'Al-Imam Mohamed Ibn Saud Islamic University'
  },
  {
    id: '202',
    label: 'Al-Islah University',
    value: 'Al-Islah University'
  },
  {
    id: '203',
    label: 'Al-Isra University',
    value: 'Al-Isra University'
  },
  {
    id: '204',
    label: 'Al-Jabal Al-Gharbi University',
    value: 'Al-Jabal Al-Gharbi University'
  },
  {
    id: '205',
    label: 'Al-Khair University',
    value: 'Al-Khair University'
  },
  {
    id: '206',
    label: 'Al-Manar University',
    value: 'Al-Manar University'
  },
  {
    id: '207',
    label: 'Al-Mustafa International University',
    value: 'Al-Mustafa International University'
  },
  {
    id: '208',
    label: 'Al-Nasser University',
    value: 'Al-Nasser University'
  },
  {
    id: '209',
    label: 'Al-Quds Open University',
    value: 'Al-Quds Open University'
  },
  {
    id: '210',
    label: 'Al-Quds University - The Arab University in Jerusalem',
    value: 'Al-Quds University - The Arab University in Jerusalem'
  },
  {
    id: '211',
    label: 'Al-Wataniya Private University',
    value: 'Al-Wataniya Private University'
  },
  {
    id: '212',
    label: 'Al-Yamamah College',
    value: 'Al-Yamamah College'
  },
  {
    id: '213',
    label: 'Al-Zaiem Al-Azhari University',
    value: 'Al-Zaiem Al-Azhari University'
  },
  {
    id: '214',
    label: 'Al-Zaytoonah University',
    value: 'Al-Zaytoonah University'
  },
  {
    id: '215',
    label: 'Al-baha University',
    value: 'Al-baha University'
  },
  {
    id: '216',
    label: 'Al-hikmah University',
    value: 'Al-hikmah University'
  },
  {
    id: '217',
    label: 'Alabama A&M University',
    value: 'Alabama A&M University'
  },
  {
    id: '218',
    label: 'Alabama Southern Community College',
    value: 'Alabama Southern Community College'
  },
  {
    id: '219',
    label: 'Alabama State University',
    value: 'Alabama State University'
  },
  {
    id: '220',
    label: 'Alagappa University',
    value: 'Alagappa University'
  },
  {
    id: '221',
    label: 'Alahgaff University',
    value: 'Alahgaff University'
  },
  {
    id: '222',
    label: 'Alain University of Science and Technology',
    value: 'Alain University of Science and Technology'
  },
  {
    id: '223',
    label: 'Alamance Community College',
    value: 'Alamance Community College'
  },
  {
    id: '224',
    label: 'Alamo Colleges',
    value: 'Alamo Colleges'
  },
  {
    id: '225',
    label: 'Alamo Colleges District',
    value: 'Alamo Colleges District'
  },
  {
    id: '226',
    label: 'Alanya Alaaddin Keykubat University',
    value: 'Alanya Alaaddin Keykubat University'
  },
  {
    id: '227',
    label: 'Alanya Hamdullah Emin Pasa University',
    value: 'Alanya Hamdullah Emin Pasa University'
  },
  {
    id: '228',
    label: 'Alaska Pacific University',
    value: 'Alaska Pacific University'
  },
  {
    id: '229',
    label: 'Alayen University',
    value: 'Alayen University'
  },
  {
    id: '230',
    label: 'Albany State University',
    value: 'Albany State University'
  },
  {
    id: '231',
    label: 'Albany Technical College',
    value: 'Albany Technical College'
  },
  {
    id: '232',
    label: 'Albert-Ludwigs-Universität Freiburg',
    value: 'Albert-Ludwigs-Universität Freiburg'
  },
  {
    id: '233',
    label: 'Albertson College of Idaho',
    value: 'Albertson College of Idaho'
  },
  {
    id: '234',
    label: 'Albion College',
    value: 'Albion College'
  },
  {
    id: '235',
    label: 'Alcorn State University',
    value: 'Alcorn State University'
  },
  {
    id: '236',
    label: 'Aleksander Gieysztor School of Humanities in Pultusk',
    value: 'Aleksander Gieysztor School of Humanities in Pultusk'
  },
  {
    id: '237',
    label: 'Aleksander Zelwerowicz State Theatre Academy',
    value: 'Aleksander Zelwerowicz State Theatre Academy'
  },
  {
    id: '238',
    label: 'Aletheia University',
    value: 'Aletheia University'
  },
  {
    id: '239',
    label: 'Alexandria Technical & Community College',
    value: 'Alexandria Technical & Community College'
  },
  {
    id: '240',
    label: 'Alexandria University',
    value: 'Alexandria University'
  },
  {
    id: '241',
    label: 'Alfaisal University',
    value: 'Alfaisal University'
  },
  {
    id: '242',
    label: 'Alfred Nobel University of Economics and Law',
    value: 'Alfred Nobel University of Economics and Law'
  },
  {
    id: '243',
    label: 'Alfred University',
    value: 'Alfred University'
  },
  {
    id: '244',
    label: 'Algebra University College',
    value: 'Algebra University College'
  },
  {
    id: '245',
    label: 'Algonquin College',
    value: 'Algonquin College'
  },
  {
    id: '246',
    label: 'Alhosn University',
    value: 'Alhosn University'
  },
  {
    id: '247',
    label: 'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin',
    value: 'Alice-Salomon-Fachhochschule für Sozialarbeit und Sozialpädagogik Berlin'
  },
  {
    id: '248',
    label: 'Aligarh Muslim University',
    value: 'Aligarh Muslim University'
  },
  {
    id: '249',
    label: 'Aljouf University',
    value: 'Aljouf University'
  },
  {
    id: '250',
    label: 'Alkharj University',
    value: 'Alkharj University'
  },
  {
    id: '251',
    label: 'All India Institute of Medical Sciences',
    value: 'All India Institute of Medical Sciences'
  },
  {
    id: '252',
    label: 'All Nations University College',
    value: 'All Nations University College'
  },
  {
    id: '253',
    label: 'Allahabad Agricultural Institute, Deemed University',
    value: 'Allahabad Agricultural Institute, Deemed University'
  },
  {
    id: '254',
    label: 'Allahabad University',
    value: 'Allahabad University'
  },
  {
    id: '255',
    label: 'Allama Iqbal Open University',
    value: 'Allama Iqbal Open University'
  },
  {
    id: '256',
    label: 'Allameh Tabatabaie University',
    value: 'Allameh Tabatabaie University'
  },
  {
    id: '257',
    label: 'Allan Hancock College',
    value: 'Allan Hancock College'
  },
  {
    id: '258',
    label: 'Allegany College of Maryland',
    value: 'Allegany College of Maryland'
  },
  {
    id: '259',
    label: 'Allegheny College',
    value: 'Allegheny College'
  },
  {
    id: '260',
    label: 'Allen College',
    value: 'Allen College'
  },
  {
    id: '261',
    label: 'Allen County Community College',
    value: 'Allen County Community College'
  },
  {
    id: '262',
    label: 'Allen University',
    value: 'Allen University'
  },
  {
    id: '263',
    label: 'Allentown College of Saint Francis de Sales',
    value: 'Allentown College of Saint Francis de Sales'
  },
  {
    id: '264',
    label: 'Alliant International University',
    value: 'Alliant International University'
  },
  {
    id: '265',
    label: 'Allianze College of Medical Sciences (ACMS)',
    value: 'Allianze College of Medical Sciences (ACMS)'
  },
  {
    id: '266',
    label: 'Alma College',
    value: 'Alma College'
  },
  {
    id: '267',
    label: 'Alpena Community College',
    value: 'Alpena Community College'
  },
  {
    id: '268',
    label: 'Alpha College of Engineering',
    value: 'Alpha College of Engineering'
  },
  {
    id: '269',
    label: 'Alsadrain University',
    value: 'Alsadrain University'
  },
  {
    id: '270',
    label: 'Altai State Medical University',
    value: 'Altai State Medical University'
  },
  {
    id: '271',
    label: 'Altai State Technical University',
    value: 'Altai State Technical University'
  },
  {
    id: '272',
    label: 'Altai State University',
    value: 'Altai State University'
  },
  {
    id: '273',
    label: 'Alverno College',
    value: 'Alverno College'
  },
  {
    id: '274',
    label: 'Alvin Community College',
    value: 'Alvin Community College'
  },
  {
    id: '275',
    label: 'Alzahra University',
    value: 'Alzahra University'
  },
  {
    id: '276',
    label: 'Ama International University',
    value: 'Ama International University'
  },
  {
    id: '277',
    label: 'Amarillo College',
    value: 'Amarillo College'
  },
  {
    id: '278',
    label: 'Amasya University',
    value: 'Amasya University'
  },
  {
    id: '279',
    label: 'Ambassador University',
    value: 'Ambassador University'
  },
  {
    id: '280',
    label: 'Ambo University',
    value: 'Ambo University'
  },
  {
    id: '281',
    label: 'Ambrose Alli University',
    value: 'Ambrose Alli University'
  },
  {
    id: '282',
    label: 'American Academy of Dramatic Arts-New York',
    value: 'American Academy of Dramatic Arts-New York'
  },
  {
    id: '283',
    label: 'American Baptist College',
    value: 'American Baptist College'
  },
  {
    id: '284',
    label: 'American Coastline University',
    value: 'American Coastline University'
  },
  {
    id: '285',
    label: 'American College Of Dubai',
    value: 'American College Of Dubai'
  },
  {
    id: '286',
    label: 'American College of Greece',
    value: 'American College of Greece'
  },
  {
    id: '287',
    label: 'American College of Thessaloniki',
    value: 'American College of Thessaloniki'
  },
  {
    id: '288',
    label: 'American Graduate School in Paris',
    value: 'American Graduate School in Paris'
  },
  {
    id: '289',
    label: 'American InterContinental University - London',
    value: 'American InterContinental University - London'
  },
  {
    id: '290',
    label: 'American International College',
    value: 'American International College'
  },
  {
    id: '291',
    label: 'American International School of Cape Town',
    value: 'American International School of Cape Town'
  },
  {
    id: '292',
    label: 'American International University - Bangladesh',
    value: 'American International University - Bangladesh'
  },
  {
    id: '293',
    label: 'American International University West Africa',
    value: 'American International University West Africa'
  },
  {
    id: '294',
    label: 'American Public University System',
    value: 'American Public University System'
  },
  {
    id: '295',
    label: 'American River College',
    value: 'American River College'
  },
  {
    id: '296',
    label: 'American School of Bahrain',
    value: 'American School of Bahrain'
  },
  {
    id: '297',
    label: 'American University',
    value: 'American University'
  },
  {
    id: '298',
    label: 'American University',
    value: 'American University'
  },
  {
    id: '299',
    label: 'American University College of Technology',
    value: 'American University College of Technology'
  },
  {
    id: '300',
    label: 'American University Extension, Okinawa',
    value: 'American University Extension, Okinawa'
  },
  {
    id: '301',
    label: 'American University In Kosovo',
    value: 'American University In Kosovo'
  },
  {
    id: '302',
    label: 'American University in Bulgaria',
    value: 'American University in Bulgaria'
  },
  {
    id: '303',
    label: 'American University in Cairo',
    value: 'American University in Cairo'
  },
  {
    id: '304',
    label: 'American University in Dubai',
    value: 'American University in Dubai'
  },
  {
    id: '305',
    label: 'American University in the Emirates',
    value: 'American University in the Emirates'
  },
  {
    id: '306',
    label: 'American University of Afghanistan',
    value: 'American University of Afghanistan'
  },
  {
    id: '307',
    label: 'American University of Antigua',
    value: 'American University of Antigua'
  },
  {
    id: '308',
    label: 'American University of Armenia',
    value: 'American University of Armenia'
  },
  {
    id: '309',
    label: 'American University of Beirut',
    value: 'American University of Beirut'
  },
  {
    id: '310',
    label: 'American University of Central Asia',
    value: 'American University of Central Asia'
  },
  {
    id: '311',
    label: 'American University of Iraq, Sulaimani (Kurdistan Region)',
    value: 'American University of Iraq, Sulaimani (Kurdistan Region)'
  },
  {
    id: '312',
    label: 'American University of Kuwait',
    value: 'American University of Kuwait'
  },
  {
    id: '313',
    label: 'American University of Middle East',
    value: 'American University of Middle East'
  },
  {
    id: '314',
    label: 'American University of Paris',
    value: 'American University of Paris'
  },
  {
    id: '315',
    label: 'American University of Rome',
    value: 'American University of Rome'
  },
  {
    id: '316',
    label: 'American University of Science and Technology',
    value: 'American University of Science and Technology'
  },
  {
    id: '317',
    label: 'American University of Sharjah',
    value: 'American University of Sharjah'
  },
  {
    id: '318',
    label: 'American University of Tirana',
    value: 'American University of Tirana'
  },
  {
    id: '319',
    label: 'American University of the Caribbean',
    value: 'American University of the Caribbean'
  },
  {
    id: '320',
    label: 'American University of the Caribbean, School of Medicine',
    value: 'American University of the Caribbean, School of Medicine'
  },
  {
    id: '321',
    label: 'Americanos College',
    value: 'Americanos College'
  },
  {
    id: '322',
    label: 'Amherst College',
    value: 'Amherst College'
  },
  {
    id: '323',
    label: 'Amirkabir College of Management & Technology',
    value: 'Amirkabir College of Management & Technology'
  },
  {
    id: '324',
    label: 'Amirkabir University of Technology',
    value: 'Amirkabir University of Technology'
  },
  {
    id: '325',
    label: 'Amity University',
    value: 'Amity University'
  },
  {
    id: '326',
    label: 'Amman Arab University for Higher Studies',
    value: 'Amman Arab University for Higher Studies'
  },
  {
    id: '327',
    label: 'Amman University',
    value: 'Amman University'
  },
  {
    id: '328',
    label: 'Amoud University',
    value: 'Amoud University'
  },
  {
    id: '329',
    label: 'Amravati University',
    value: 'Amravati University'
  },
  {
    id: '330',
    label: 'Amrita Vishwa Vidyapeetham',
    value: 'Amrita Vishwa Vidyapeetham'
  },
  {
    id: '331',
    label: 'Amur State University',
    value: 'Amur State University'
  },
  {
    id: '332',
    label: 'An-Najah National University',
    value: 'An-Najah National University'
  },
  {
    id: '333',
    label: 'Anadolu University',
    value: 'Anadolu University'
  },
  {
    id: '334',
    label: 'Anambra State University of Science and Technology',
    value: 'Anambra State University of Science and Technology'
  },
  {
    id: '335',
    label: 'Ancilla College',
    value: 'Ancilla College'
  },
  {
    id: '336',
    label: 'Anderson University',
    value: 'Anderson University'
  },
  {
    id: '337',
    label: 'Andhra University',
    value: 'Andhra University'
  },
  {
    id: '338',
    label: 'Andong National University',
    value: 'Andong National University'
  },
  {
    id: '339',
    label: 'Andrassy Gyula German Speaking University Budapest',
    value: 'Andrassy Gyula German Speaking University Budapest'
  },
  {
    id: '340',
    label: 'Andrew College',
    value: 'Andrew College'
  },
  {
    id: '341',
    label: 'Andrews University',
    value: 'Andrews University'
  },
  {
    id: '342',
    label: 'Andrzej Frycz Modrzewski Cracow College',
    value: 'Andrzej Frycz Modrzewski Cracow College'
  },
  {
    id: '343',
    label: 'Angeles University',
    value: 'Angeles University'
  },
  {
    id: '344',
    label: 'Angelina College',
    value: 'Angelina College'
  },
  {
    id: '345',
    label: 'Angelo State University',
    value: 'Angelo State University'
  },
  {
    id: '346',
    label: 'Angkor University',
    value: 'Angkor University'
  },
  {
    id: '347',
    label: 'Anglia Ruskin University',
    value: 'Anglia Ruskin University'
  },
  {
    id: '348',
    label: 'Anglo-American University',
    value: 'Anglo-American University'
  },
  {
    id: '349',
    label: 'Anhui Medical University',
    value: 'Anhui Medical University'
  },
  {
    id: '350',
    label: 'Anhui Normal University',
    value: 'Anhui Normal University'
  },
  {
    id: '351',
    label: 'Anhui Technical College of Water Resources and Hydroelectric Power',
    value: 'Anhui Technical College of Water Resources and Hydroelectric Power'
  },
  {
    id: '352',
    label: 'Anhui University',
    value: 'Anhui University'
  },
  {
    id: '353',
    label: 'Anhui University of Finance and Economics',
    value: 'Anhui University of Finance and Economics'
  },
  {
    id: '354',
    label: 'Anhui University of Traditional Chinese Medicine',
    value: 'Anhui University of Traditional Chinese Medicine'
  },
  {
    id: '355',
    label: 'Ankara Medipol University',
    value: 'Ankara Medipol University'
  },
  {
    id: '356',
    label: 'Ankara Science University',
    value: 'Ankara Science University'
  },
  {
    id: '357',
    label: 'Ankara Social Science University',
    value: 'Ankara Social Science University'
  },
  {
    id: '358',
    label: 'Ankara University',
    value: 'Ankara University'
  },
  {
    id: '359',
    label: 'Ankara Yildirim Beyazit University',
    value: 'Ankara Yildirim Beyazit University'
  },
  {
    id: '360',
    label: 'Anna University',
    value: 'Anna University'
  },
  {
    id: '361',
    label: 'Anna University of Technology, Tirunelveli',
    value: 'Anna University of Technology, Tirunelveli'
  },
  {
    id: '362',
    label: 'Annamalai University',
    value: 'Annamalai University'
  },
  {
    id: '363',
    label: 'Anne Arundel Community College',
    value: 'Anne Arundel Community College'
  },
  {
    id: '364',
    label: 'Anoka Technical College',
    value: 'Anoka Technical College'
  },
  {
    id: '365',
    label: 'Anoka-Ramsey Community College',
    value: 'Anoka-Ramsey Community College'
  },
  {
    id: '366',
    label: 'Antalya international University',
    value: 'Antalya international University'
  },
  {
    id: '367',
    label: 'Antelope Valley College',
    value: 'Antelope Valley College'
  },
  {
    id: '368',
    label: 'Antioch New England',
    value: 'Antioch New England'
  },
  {
    id: '369',
    label: 'Antioch University',
    value: 'Antioch University'
  },
  {
    id: '370',
    label: 'Antioch University - Los Angeles',
    value: 'Antioch University - Los Angeles'
  },
  {
    id: '371',
    label: 'Antioch University - Seattle',
    value: 'Antioch University - Seattle'
  },
  {
    id: '372',
    label: 'Anton de Kom University',
    value: 'Anton de Kom University'
  },
  {
    id: '373',
    label: 'Anurag University',
    value: 'Anurag University'
  },
  {
    id: '374',
    label: 'Aomori Chuoh Gakuin University',
    value: 'Aomori Chuoh Gakuin University'
  },
  {
    id: '375',
    label: 'Aomori Public College',
    value: 'Aomori Public College'
  },
  {
    id: '376',
    label: 'Aomori University',
    value: 'Aomori University'
  },
  {
    id: '377',
    label: 'Aomori University of Health and Welfare',
    value: 'Aomori University of Health and Welfare'
  },
  {
    id: '378',
    label: 'Aoyama Gakuin University',
    value: 'Aoyama Gakuin University'
  },
  {
    id: '379',
    label: 'Appalachian State University',
    value: 'Appalachian State University'
  },
  {
    id: '380',
    label: 'Applied Science University',
    value: 'Applied Science University'
  },
  {
    id: '381',
    label: 'Applied Science University',
    value: 'Applied Science University'
  },
  {
    id: '382',
    label: 'Aquinas College',
    value: 'Aquinas College'
  },
  {
    id: '383',
    label: 'Aquinas University',
    value: 'Aquinas University'
  },
  {
    id: '384',
    label: 'Ara Institute of Canterbury ',
    value: 'Ara Institute of Canterbury '
  },
  {
    id: '385',
    label: 'Arab Academy for Management, Banking and Financial Sciences',
    value: 'Arab Academy for Management, Banking and Financial Sciences'
  },
  {
    id: '386',
    label: 'Arab Academy for Science & Technology',
    value: 'Arab Academy for Science & Technology'
  },
  {
    id: '387',
    label: 'Arab American University - Jenin',
    value: 'Arab American University - Jenin'
  },
  {
    id: '388',
    label: 'Arab European University',
    value: 'Arab European University'
  },
  {
    id: '389',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '390',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '391',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '392',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '393',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '394',
    label: 'Arab Open University',
    value: 'Arab Open University'
  },
  {
    id: '395',
    label: 'Arab Open University, Kuwait Branch',
    value: 'Arab Open University, Kuwait Branch'
  },
  {
    id: '396',
    label: 'Arabian Gulf University',
    value: 'Arabian Gulf University'
  },
  {
    id: '397',
    label: 'Arak University',
    value: 'Arak University'
  },
  {
    id: '398',
    label: 'Arak University of Medical Sciences',
    value: 'Arak University of Medical Sciences'
  },
  {
    id: '399',
    label: 'Arak University of Technology',
    value: 'Arak University of Technology'
  },
  {
    id: '400',
    label: 'Arapahoe Community College',
    value: 'Arapahoe Community College'
  },
  {
    id: '401',
    label: 'Araullo University',
    value: 'Araullo University'
  },
  {
    id: '402',
    label: 'Arba Minch University',
    value: 'Arba Minch University'
  },
  {
    id: '403',
    label: 'Arcadia College',
    value: 'Arcadia College'
  },
  {
    id: '404',
    label: 'Ardabil University of Medical Sciences',
    value: 'Ardabil University of Medical Sciences'
  },
  {
    id: '405',
    label: 'Ardahan University',
    value: 'Ardahan University'
  },
  {
    id: '406',
    label: 'Arellano University',
    value: 'Arellano University'
  },
  {
    id: '407',
    label: 'Arhangelsk State Technical University',
    value: 'Arhangelsk State Technical University'
  },
  {
    id: '408',
    label: 'Aria Institute of Higher Education',
    value: 'Aria Institute of Higher Education'
  },
  {
    id: '409',
    label: 'Arid Agriculture University',
    value: 'Arid Agriculture University'
  },
  {
    id: '410',
    label: 'Ariel University Center of Samaria',
    value: 'Ariel University Center of Samaria'
  },
  {
    id: '411',
    label: 'Aristotle University of Thessaloniki',
    value: 'Aristotle University of Thessaloniki'
  },
  {
    id: '412',
    label: 'Arizona State University',
    value: 'Arizona State University'
  },
  {
    id: '413',
    label: 'Arizona Western College',
    value: 'Arizona Western College'
  },
  {
    id: '414',
    label: 'Arkansas Baptist College',
    value: 'Arkansas Baptist College'
  },
  {
    id: '415',
    label: 'Arkansas Northeastern College',
    value: 'Arkansas Northeastern College'
  },
  {
    id: '416',
    label: 'Arkansas State University',
    value: 'Arkansas State University'
  },
  {
    id: '417',
    label: 'Arkansas State University-Beebe',
    value: 'Arkansas State University-Beebe'
  },
  {
    id: '418',
    label: 'Arkansas State University-Mountain Home',
    value: 'Arkansas State University-Mountain Home'
  },
  {
    id: '419',
    label: 'Arkansas State University-Newport',
    value: 'Arkansas State University-Newport'
  },
  {
    id: '420',
    label: 'Arkansas Tech University',
    value: 'Arkansas Tech University'
  },
  {
    id: '421',
    label: 'Arkansas at Pine Bluff, University of',
    value: 'Arkansas at Pine Bluff, University of'
  },
  {
    id: '422',
    label: 'Armed Forces Academy of General Milan Rastislav Štefánik',
    value: 'Armed Forces Academy of General Milan Rastislav Štefánik'
  },
  {
    id: '423',
    label: 'Armenian State Agrarian University',
    value: 'Armenian State Agrarian University'
  },
  {
    id: '424',
    label: 'Armenian State University of Economics',
    value: 'Armenian State University of Economics'
  },
  {
    id: '425',
    label: 'Armstrong State College',
    value: 'Armstrong State College'
  },
  {
    id: '426',
    label: 'Art University',
    value: 'Art University'
  },
  {
    id: '427',
    label: 'Arthur C Clarke Institute of Modern Technologies',
    value: 'Arthur C Clarke Institute of Modern Technologies'
  },
  {
    id: '428',
    label: 'Arts, Sciences and Technology University',
    value: 'Arts, Sciences and Technology University'
  },
  {
    id: '429',
    label: 'Artvin Coruh University',
    value: 'Artvin Coruh University'
  },
  {
    id: '430',
    label: 'Asa University Bangladesh',
    value: 'Asa University Bangladesh'
  },
  {
    id: '431',
    label: 'Asahi University',
    value: 'Asahi University'
  },
  {
    id: '432',
    label: 'Asahikawa Medical College',
    value: 'Asahikawa Medical College'
  },
  {
    id: '433',
    label: 'Asahikawa University',
    value: 'Asahikawa University'
  },
  {
    id: '434',
    label: 'Asansol Engineering College',
    value: 'Asansol Engineering College'
  },
  {
    id: '435',
    label: 'Ashesi University',
    value: 'Ashesi University'
  },
  {
    id: '436',
    label: 'Asheville-Buncombe Technical Community College',
    value: 'Asheville-Buncombe Technical Community College'
  },
  {
    id: '437',
    label: 'Ashford University',
    value: 'Ashford University'
  },
  {
    id: '438',
    label: 'Ashikaga Institute of Technology',
    value: 'Ashikaga Institute of Technology'
  },
  {
    id: '439',
    label: 'Ashiya University',
    value: 'Ashiya University'
  },
  {
    id: '440',
    label: 'Ashkelon Academic College',
    value: 'Ashkelon Academic College'
  },
  {
    id: '441',
    label: 'Ashland Community and Technical College',
    value: 'Ashland Community and Technical College'
  },
  {
    id: '442',
    label: 'Ashland University',
    value: 'Ashland University'
  },
  {
    id: '443',
    label: 'Ashoka University',
    value: 'Ashoka University'
  },
  {
    id: '444',
    label: 'Ashton College',
    value: 'Ashton College'
  },
  {
    id: '445',
    label: 'Asia E University',
    value: 'Asia E University'
  },
  {
    id: '446',
    label: 'Asia Europe University',
    value: 'Asia Europe University'
  },
  {
    id: '447',
    label: 'Asia Pacific Institute of Information Technology (APIIT)',
    value: 'Asia Pacific Institute of Information Technology (APIIT)'
  },
  {
    id: '448',
    label: 'Asia University',
    value: 'Asia University'
  },
  {
    id: '449',
    label: 'Asia-Pacific International University',
    value: 'Asia-Pacific International University'
  },
  {
    id: '450',
    label: 'Asian Institute of Technology',
    value: 'Asian Institute of Technology'
  },
  {
    id: '451',
    label: 'Asian Medical Institute',
    value: 'Asian Medical Institute'
  },
  {
    id: '452',
    label: 'Asian United Theological University',
    value: 'Asian United Theological University'
  },
  {
    id: '453',
    label: 'Asian University',
    value: 'Asian University'
  },
  {
    id: '454',
    label: 'Asian University of Bangladesh',
    value: 'Asian University of Bangladesh'
  },
  {
    id: '455',
    label: 'Asian University of Science and Technology',
    value: 'Asian University of Science and Technology'
  },
  {
    id: '456',
    label: 'Asmildkloster Agricultural School',
    value: 'Asmildkloster Agricultural School'
  },
  {
    id: '457',
    label: 'Asnuntuck Community College',
    value: 'Asnuntuck Community College'
  },
  {
    id: '458',
    label: 'Assam Agricultural University',
    value: 'Assam Agricultural University'
  },
  {
    id: '459',
    label: 'Assam University',
    value: 'Assam University'
  },
  {
    id: '460',
    label: 'Assiut University',
    value: 'Assiut University'
  },
  {
    id: '461',
    label: 'Assosa University',
    value: 'Assosa University'
  },
  {
    id: '462',
    label: 'Assumption College',
    value: 'Assumption College'
  },
  {
    id: '463',
    label: 'Assumption College for Sisters',
    value: 'Assumption College for Sisters'
  },
  {
    id: '464',
    label: 'Assumption University',
    value: 'Assumption University'
  },
  {
    id: '465',
    label: 'Assumption University of Thailand',
    value: 'Assumption University of Thailand'
  },
  {
    id: '466',
    label: 'Aston University',
    value: 'Aston University'
  },
  {
    id: '467',
    label: 'Astrahan State Medical Academy',
    value: 'Astrahan State Medical Academy'
  },
  {
    id: '468',
    label: 'Astrahan State Technical University',
    value: 'Astrahan State Technical University'
  },
  {
    id: '469',
    label: 'Astrakhan State University',
    value: 'Astrakhan State University'
  },
  {
    id: '470',
    label:
      'Atal Bihari Vajpayee Indian Institute of Information Technology and Management, Gwalior',
    value: 'Atal Bihari Vajpayee Indian Institute of Information Technology and Management, Gwalior'
  },
  {
    id: '471',
    label: 'Atatürk University',
    value: 'Atatürk University'
  },
  {
    id: '472',
    label: 'Ateneo de Davao University',
    value: 'Ateneo de Davao University'
  },
  {
    id: '473',
    label: 'Ateneo de Manila University',
    value: 'Ateneo de Manila University'
  },
  {
    id: '474',
    label: 'Ateneo de Manila University',
    value: 'Ateneo de Manila University'
  },
  {
    id: '475',
    label: 'Ateneo de Naga University',
    value: 'Ateneo de Naga University'
  },
  {
    id: '476',
    label: 'Ateneo de Zamboanga University',
    value: 'Ateneo de Zamboanga University'
  },
  {
    id: '477',
    label: 'Athabasca University',
    value: 'Athabasca University'
  },
  {
    id: '478',
    label: 'Athenaeum Pontificium Regina Apostolorum',
    value: 'Athenaeum Pontificium Regina Apostolorum'
  },
  {
    id: '479',
    label: 'Athens Graduate School of Management (AGSM)',
    value: 'Athens Graduate School of Management (AGSM)'
  },
  {
    id: '480',
    label: 'Athens Laboratory of Business Administration (ALBA)',
    value: 'Athens Laboratory of Business Administration (ALBA)'
  },
  {
    id: '481',
    label: 'Athens School of Fine Arts',
    value: 'Athens School of Fine Arts'
  },
  {
    id: '482',
    label: 'Athens State University',
    value: 'Athens State University'
  },
  {
    id: '483',
    label: 'Athens Technical College',
    value: 'Athens Technical College'
  },
  {
    id: '484',
    label: 'Athens University of Economics and Business',
    value: 'Athens University of Economics and Business'
  },
  {
    id: '485',
    label: 'Athlone Institute of Technology',
    value: 'Athlone Institute of Technology'
  },
  {
    id: '486',
    label: 'Atilim University',
    value: 'Atilim University'
  },
  {
    id: '487',
    label: 'Atish Dipankar University',
    value: 'Atish Dipankar University'
  },
  {
    id: '488',
    label: 'Atlanta Technical College',
    value: 'Atlanta Technical College'
  },
  {
    id: '489',
    label: 'Atlantic Cape Community College',
    value: 'Atlantic Cape Community College'
  },
  {
    id: '490',
    label: 'Atomi College',
    value: 'Atomi College'
  },
  {
    id: '491',
    label: 'Atyrau State University',
    value: 'Atyrau State University'
  },
  {
    id: '492',
    label: 'Auburn University',
    value: 'Auburn University'
  },
  {
    id: '493',
    label: 'Auburn University at Montgomery',
    value: 'Auburn University at Montgomery'
  },
  {
    id: '494',
    label: 'Auchi Polytechnic',
    value: 'Auchi Polytechnic'
  },
  {
    id: '495',
    label: 'Auckland University of Technology',
    value: 'Auckland University of Technology'
  },
  {
    id: '496',
    label: 'Audencia',
    value: 'Audencia'
  },
  {
    id: '497',
    label: 'Augsburg College',
    value: 'Augsburg College'
  },
  {
    id: '498',
    label: 'Augusta Technical College',
    value: 'Augusta Technical College'
  },
  {
    id: '499',
    label: 'Augusta University',
    value: 'Augusta University'
  },
  {
    id: '500',
    label: 'Augustana College (IL)',
    value: 'Augustana College (IL)'
  },
  {
    id: '501',
    label: 'Augustana College (SD)',
    value: 'Augustana College (SD)'
  },
  {
    id: '502',
    label: 'Augustana Hochschule Neuendettelsau',
    value: 'Augustana Hochschule Neuendettelsau'
  },
  {
    id: '503',
    label: 'Augustana University College',
    value: 'Augustana University College'
  },
  {
    id: '504',
    label: 'Aurora University',
    value: 'Aurora University'
  },
  {
    id: '505',
    label: 'Austin College',
    value: 'Austin College'
  },
  {
    id: '506',
    label: 'Austin Community College',
    value: 'Austin Community College'
  },
  {
    id: '507',
    label: 'Austin Peay State University',
    value: 'Austin Peay State University'
  },
  {
    id: '508',
    label: 'Australian Catholic University',
    value: 'Australian Catholic University'
  },
  {
    id: '509',
    label: 'Australian Correspondence Schools',
    value: 'Australian Correspondence Schools'
  },
  {
    id: '510',
    label: 'Australian Defence Force Academy',
    value: 'Australian Defence Force Academy'
  },
  {
    id: '511',
    label: 'Australian International School',
    value: 'Australian International School'
  },
  {
    id: '512',
    label: 'Australian Lutheran College',
    value: 'Australian Lutheran College'
  },
  {
    id: '513',
    label: 'Australian Maritime College',
    value: 'Australian Maritime College'
  },
  {
    id: '514',
    label: 'Australian National University',
    value: 'Australian National University'
  },
  {
    id: '515',
    label: 'Australlian College of Kuwait',
    value: 'Australlian College of Kuwait'
  },
  {
    id: '516',
    label: 'Aventis School of Management',
    value: 'Aventis School of Management'
  },
  {
    id: '517',
    label: 'Averett College',
    value: 'Averett College'
  },
  {
    id: '518',
    label: 'Avicenna International College',
    value: 'Avicenna International College'
  },
  {
    id: '519',
    label: 'Avila College',
    value: 'Avila College'
  },
  {
    id: '520',
    label: 'Avinashilingam Institute for Home Science and Higher Education for Women',
    value: 'Avinashilingam Institute for Home Science and Higher Education for Women'
  },
  {
    id: '521',
    label: 'Avondale University',
    value: 'Avondale University'
  },
  {
    id: '522',
    label: 'Avrasya University',
    value: 'Avrasya University'
  },
  {
    id: '523',
    label: 'Awadhesh Pratap Singh University',
    value: 'Awadhesh Pratap Singh University'
  },
  {
    id: '524',
    label: 'Azabu University',
    value: 'Azabu University'
  },
  {
    id: '525',
    label: 'Azad Jammu and Kashmir University',
    value: 'Azad Jammu and Kashmir University'
  },
  {
    id: '526',
    label: 'Azerbaijan Diplomatic Academy',
    value: 'Azerbaijan Diplomatic Academy'
  },
  {
    id: '527',
    label: 'Azerbaijan Medical University',
    value: 'Azerbaijan Medical University'
  },
  {
    id: '528',
    label: 'Azerbaijan National Conservatorie',
    value: 'Azerbaijan National Conservatorie'
  },
  {
    id: '529',
    label: 'Azerbaijan State Agricultural Academy',
    value: 'Azerbaijan State Agricultural Academy'
  },
  {
    id: '530',
    label: 'Azerbaijan State Customs Committee Academy',
    value: 'Azerbaijan State Customs Committee Academy'
  },
  {
    id: '531',
    label: 'Azerbaijan State Marine Academy',
    value: 'Azerbaijan State Marine Academy'
  },
  {
    id: '532',
    label: 'Azerbaijan State Pedagogic University',
    value: 'Azerbaijan State Pedagogic University'
  },
  {
    id: '533',
    label: 'Azerbaijan State University of Culture and Arts',
    value: 'Azerbaijan State University of Culture and Arts'
  },
  {
    id: '534',
    label: 'Azerbaijan State University of Economics',
    value: 'Azerbaijan State University of Economics'
  },
  {
    id: '535',
    label: 'Azerbaijan State University of Oil and Industry',
    value: 'Azerbaijan State University of Oil and Industry'
  },
  {
    id: '536',
    label: 'Azerbaijan Technical University',
    value: 'Azerbaijan Technical University'
  },
  {
    id: '537',
    label: 'Azerbaijan Toursim Institute',
    value: 'Azerbaijan Toursim Institute'
  },
  {
    id: '538',
    label: 'Azerbaijan University',
    value: 'Azerbaijan University'
  },
  {
    id: '539',
    label: 'Azerbaijan University of Architecture and Construction',
    value: 'Azerbaijan University of Architecture and Construction'
  },
  {
    id: '540',
    label: 'Azerbaijan University of Languages',
    value: 'Azerbaijan University of Languages'
  },
  {
    id: '541',
    label: 'Azusa Pacific University',
    value: 'Azusa Pacific University'
  },
  {
    id: '542',
    label: 'B. R. Ambedkar Bihar University',
    value: 'B. R. Ambedkar Bihar University'
  },
  {
    id: '543',
    label: 'B. S. Abdur Rahman University',
    value: 'B. S. Abdur Rahman University'
  },
  {
    id: '544',
    label: 'B.P.Koirala Institute of Health Sciences',
    value: 'B.P.Koirala Institute of Health Sciences'
  },
  {
    id: '545',
    label: 'BFH - Bern University of Applied Sciences',
    value: 'BFH - Bern University of Applied Sciences'
  },
  {
    id: '546',
    label: 'BGC Trust University, Bangladesh',
    value: 'BGC Trust University, Bangladesh'
  },
  {
    id: '547',
    label: 'Babasaheb Bhimrao Ambedkar University',
    value: 'Babasaheb Bhimrao Ambedkar University'
  },
  {
    id: '548',
    label: 'Babcock University',
    value: 'Babcock University'
  },
  {
    id: '549',
    label: 'Babes-Bolyai University of Cluj-Napoca',
    value: 'Babes-Bolyai University of Cluj-Napoca'
  },
  {
    id: '550',
    label: 'Babol Noshirvani University of Technology',
    value: 'Babol Noshirvani University of Technology'
  },
  {
    id: '551',
    label: 'Babol University of Medical Sciences',
    value: 'Babol University of Medical Sciences'
  },
  {
    id: '552',
    label: 'Babson College',
    value: 'Babson College'
  },
  {
    id: '553',
    label: 'Badakhshan University',
    value: 'Badakhshan University'
  },
  {
    id: '554',
    label: 'Badr University in Cairo',
    value: 'Badr University in Cairo'
  },
  {
    id: '555',
    label: 'Baekseok University',
    value: 'Baekseok University'
  },
  {
    id: '556',
    label: 'Baghdad College of Economic Sciences University',
    value: 'Baghdad College of Economic Sciences University'
  },
  {
    id: '557',
    label: 'Baghdad College of Pharmacy',
    value: 'Baghdad College of Pharmacy'
  },
  {
    id: '558',
    label: 'Baghlan University',
    value: 'Baghlan University'
  },
  {
    id: '559',
    label: "Baha'i Institute for Higher Education",
    value: "Baha'i Institute for Higher Education"
  },
  {
    id: '560',
    label: 'Bahauddin Zakariya University, Multan',
    value: 'Bahauddin Zakariya University, Multan'
  },
  {
    id: '561',
    label: 'Bahcesehir University',
    value: 'Bahcesehir University'
  },
  {
    id: '562',
    label: 'Bahir Dar University',
    value: 'Bahir Dar University'
  },
  {
    id: '563',
    label: 'Bahria University',
    value: 'Bahria University'
  },
  {
    id: '564',
    label: "Baika Women's College",
    value: "Baika Women's College"
  },
  {
    id: '565',
    label: 'Baikal National University of Economics and Law',
    value: 'Baikal National University of Economics and Law'
  },
  {
    id: '566',
    label: "Baiko Women's College",
    value: "Baiko Women's College"
  },
  {
    id: '567',
    label: 'Bainbridge State College',
    value: 'Bainbridge State College'
  },
  {
    id: '568',
    label: 'Baitulmal Management Institute (IPB)',
    value: 'Baitulmal Management Institute (IPB)'
  },
  {
    id: '569',
    label: 'Baker College',
    value: 'Baker College'
  },
  {
    id: '570',
    label: 'Baker University',
    value: 'Baker University'
  },
  {
    id: '571',
    label: 'Bakersfield College',
    value: 'Bakersfield College'
  },
  {
    id: '572',
    label: 'Bakht Er-Ruda University',
    value: 'Bakht Er-Ruda University'
  },
  {
    id: '573',
    label: 'Bakhtar University',
    value: 'Bakhtar University'
  },
  {
    id: '574',
    label: 'Baku Academy of Music',
    value: 'Baku Academy of Music'
  },
  {
    id: '575',
    label: 'Baku Business University',
    value: 'Baku Business University'
  },
  {
    id: '576',
    label: 'Baku Slavic University',
    value: 'Baku Slavic University'
  },
  {
    id: '577',
    label: 'Baku State University',
    value: 'Baku State University'
  },
  {
    id: '578',
    label: 'Balamand University',
    value: 'Balamand University'
  },
  {
    id: '579',
    label: 'Baldwin Wallace University',
    value: 'Baldwin Wallace University'
  },
  {
    id: '580',
    label: 'Balikesir University',
    value: 'Balikesir University'
  },
  {
    id: '581',
    label: 'Balkh University',
    value: 'Balkh University'
  },
  {
    id: '582',
    label: 'Ball State University',
    value: 'Ball State University'
  },
  {
    id: '583',
    label: 'Balochistan University of Engineering and Technology Khuzdar',
    value: 'Balochistan University of Engineering and Technology Khuzdar'
  },
  {
    id: '584',
    label: 'Balochistan University of Information Technology & Management Sciences',
    value: 'Balochistan University of Information Technology & Management Sciences'
  },
  {
    id: '585',
    label: 'Balti State University "Alecu Russo"',
    value: 'Balti State University "Alecu Russo"'
  },
  {
    id: '586',
    label: 'Baltic International Academy',
    value: 'Baltic International Academy'
  },
  {
    id: '587',
    label: 'Baltic State Technical University',
    value: 'Baltic State Technical University'
  },
  {
    id: '588',
    label: 'Baltimore City Community College',
    value: 'Baltimore City Community College'
  },
  {
    id: '589',
    label: 'Bamenda University of Science & Technology',
    value: 'Bamenda University of Science & Technology'
  },
  {
    id: '590',
    label: 'Bamiyan University',
    value: 'Bamiyan University'
  },
  {
    id: '591',
    label: 'Banaras Hindu University',
    value: 'Banaras Hindu University'
  },
  {
    id: '592',
    label: 'Banasthali University',
    value: 'Banasthali University'
  },
  {
    id: '593',
    label: 'Bandirma ONYEDI Eylul University',
    value: 'Bandirma ONYEDI Eylul University'
  },
  {
    id: '594',
    label: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University',
    value: 'Bangabandhu Sheikh Mujibur Rahman Agricultural University'
  },
  {
    id: '595',
    label: 'Bangabandhu Sheikh Mujibur Rahman Medical University',
    value: 'Bangabandhu Sheikh Mujibur Rahman Medical University'
  },
  {
    id: '596',
    label: 'Bangalore University',
    value: 'Bangalore University'
  },
  {
    id: '597',
    label: 'Bangkok University',
    value: 'Bangkok University'
  },
  {
    id: '598',
    label: 'Bangladesh Agricultural University',
    value: 'Bangladesh Agricultural University'
  },
  {
    id: '599',
    label: 'Bangladesh Open University',
    value: 'Bangladesh Open University'
  },
  {
    id: '600',
    label: 'Bangladesh University',
    value: 'Bangladesh University'
  },
  {
    id: '601',
    label: 'Bangladesh University of Business & Technology',
    value: 'Bangladesh University of Business & Technology'
  },
  {
    id: '602',
    label: 'Bangladesh University of Engineering and Technology',
    value: 'Bangladesh University of Engineering and Technology'
  },
  {
    id: '603',
    label: 'Bangladesh University of Professionals',
    value: 'Bangladesh University of Professionals'
  },
  {
    id: '604',
    label: 'Bangladesh University of Textiles',
    value: 'Bangladesh University of Textiles'
  },
  {
    id: '605',
    label: 'Banking University of Ho Chi Minh City',
    value: 'Banking University of Ho Chi Minh City'
  },
  {
    id: '606',
    label: 'Bankura University',
    value: 'Bankura University'
  },
  {
    id: '607',
    label: 'Baptist Bible College',
    value: 'Baptist Bible College'
  },
  {
    id: '608',
    label: 'Baqai Medical University',
    value: 'Baqai Medical University'
  },
  {
    id: '609',
    label: 'Baqiyatallah University of Medical Sciences',
    value: 'Baqiyatallah University of Medical Sciences'
  },
  {
    id: '610',
    label: 'Bar-Ilan University',
    value: 'Bar-Ilan University'
  },
  {
    id: '611',
    label: 'Barani Institute of Information Technology',
    value: 'Barani Institute of Information Technology'
  },
  {
    id: '612',
    label: 'Barani Institute of Management Sciences',
    value: 'Barani Institute of Management Sciences'
  },
  {
    id: '613',
    label: 'Barber-Scotia College',
    value: 'Barber-Scotia College'
  },
  {
    id: '614',
    label: 'Barcelona Graduate School of Economics',
    value: 'Barcelona Graduate School of Economics'
  },
  {
    id: '615',
    label: 'Bard College',
    value: 'Bard College'
  },
  {
    id: '616',
    label: 'Barkatullah University',
    value: 'Barkatullah University'
  },
  {
    id: '617',
    label: 'Barnard College',
    value: 'Barnard College'
  },
  {
    id: '618',
    label: 'Barry University',
    value: 'Barry University'
  },
  {
    id: '619',
    label: 'Barstow Community College',
    value: 'Barstow Community College'
  },
  {
    id: '620',
    label: 'Bartin University',
    value: 'Bartin University'
  },
  {
    id: '621',
    label: 'Barton County Community College',
    value: 'Barton County Community College'
  },
  {
    id: '622',
    label: 'Bashkir State Pedagogical University named After M. Akmullah',
    value: 'Bashkir State Pedagogical University named After M. Akmullah'
  },
  {
    id: '623',
    label: 'Bashkir State University',
    value: 'Bashkir State University'
  },
  {
    id: '624',
    label: 'Basilicata University Potenza',
    value: 'Basilicata University Potenza'
  },
  {
    id: '625',
    label: 'Baskent University',
    value: 'Baskent University'
  },
  {
    id: '626',
    label: 'Bastyr University',
    value: 'Bastyr University'
  },
  {
    id: '627',
    label: 'Batchelor Institute of Indigenous Tertiary Education',
    value: 'Batchelor Institute of Indigenous Tertiary Education'
  },
  {
    id: '628',
    label: 'Bates College',
    value: 'Bates College'
  },
  {
    id: '629',
    label: 'Bates Technical College',
    value: 'Bates Technical College'
  },
  {
    id: '630',
    label: 'Bath Spa University',
    value: 'Bath Spa University'
  },
  {
    id: '631',
    label: 'Batman University',
    value: 'Batman University'
  },
  {
    id: '632',
    label: 'Baton Rouge Community College',
    value: 'Baton Rouge Community College'
  },
  {
    id: '633',
    label: 'Batterjee Medical College',
    value: 'Batterjee Medical College'
  },
  {
    id: '634',
    label: 'Bauchi State University, Gadau',
    value: 'Bauchi State University, Gadau'
  },
  {
    id: '635',
    label: 'Bauhaus Universität Weimar',
    value: 'Bauhaus Universität Weimar'
  },
  {
    id: '636',
    label: 'Bauman Moscow State Technical University',
    value: 'Bauman Moscow State Technical University'
  },
  {
    id: '637',
    label: 'Bay Atlantic University',
    value: 'Bay Atlantic University'
  },
  {
    id: '638',
    label: 'Bay Mills Community College',
    value: 'Bay Mills Community College'
  },
  {
    id: '639',
    label: 'Bay Path University',
    value: 'Bay Path University'
  },
  {
    id: '640',
    label: 'Bay de Noc Community College',
    value: 'Bay de Noc Community College'
  },
  {
    id: '641',
    label: 'Bayan College for Science & Technology',
    value: 'Bayan College for Science & Technology'
  },
  {
    id: '642',
    label: 'Bayburt University',
    value: 'Bayburt University'
  },
  {
    id: '643',
    label: 'Bayerische Julius-Maximilians-Universität Würzburg',
    value: 'Bayerische Julius-Maximilians-Universität Würzburg'
  },
  {
    id: '644',
    label: 'Bayero University Kano',
    value: 'Bayero University Kano'
  },
  {
    id: '645',
    label: 'Baylor College of Medicine',
    value: 'Baylor College of Medicine'
  },
  {
    id: '646',
    label: 'Baylor University',
    value: 'Baylor University'
  },
  {
    id: '647',
    label: 'Baze University Abuja',
    value: 'Baze University Abuja'
  },
  {
    id: '648',
    label: 'Beaufort County Community College',
    value: 'Beaufort County Community College'
  },
  {
    id: '649',
    label: 'Beder University',
    value: 'Beder University'
  },
  {
    id: '650',
    label: 'Begum Rokeya University, Rangpur',
    value: 'Begum Rokeya University, Rangpur'
  },
  {
    id: '651',
    label: 'Beijing Foreign Studies University',
    value: 'Beijing Foreign Studies University'
  },
  {
    id: '652',
    label: 'Beijing Forestry University',
    value: 'Beijing Forestry University'
  },
  {
    id: '653',
    label: 'Beijing Information Science and Technology University',
    value: 'Beijing Information Science and Technology University'
  },
  {
    id: '654',
    label: 'Beijing Institute of Technology',
    value: 'Beijing Institute of Technology'
  },
  {
    id: '655',
    label: 'Beijing International Studies University',
    value: 'Beijing International Studies University'
  },
  {
    id: '656',
    label: 'Beijing Jiaotong University',
    value: 'Beijing Jiaotong University'
  },
  {
    id: '657',
    label: 'Beijing Language and Culture University',
    value: 'Beijing Language and Culture University'
  },
  {
    id: '658',
    label: 'Beijing Medical University',
    value: 'Beijing Medical University'
  },
  {
    id: '659',
    label: 'Beijing New Asia University',
    value: 'Beijing New Asia University'
  },
  {
    id: '660',
    label: 'Beijing Normal University',
    value: 'Beijing Normal University'
  },
  {
    id: '661',
    label: 'Beijing Petroleum University',
    value: 'Beijing Petroleum University'
  },
  {
    id: '662',
    label: 'Beijing Polytechnic University',
    value: 'Beijing Polytechnic University'
  },
  {
    id: '663',
    label: 'Beijing Sport University',
    value: 'Beijing Sport University'
  },
  {
    id: '664',
    label: 'Beijing Union University',
    value: 'Beijing Union University'
  },
  {
    id: '665',
    label: 'Beijing University of Aeronautics and Astronautics',
    value: 'Beijing University of Aeronautics and Astronautics'
  },
  {
    id: '666',
    label: 'Beijing University of Agriculture',
    value: 'Beijing University of Agriculture'
  },
  {
    id: '667',
    label: 'Beijing University of Chemical Technology',
    value: 'Beijing University of Chemical Technology'
  },
  {
    id: '668',
    label: 'Beijing University of Chinese Medicine and Pharmacology',
    value: 'Beijing University of Chinese Medicine and Pharmacology'
  },
  {
    id: '669',
    label: 'Beijing University of Posts and Telecommunications',
    value: 'Beijing University of Posts and Telecommunications'
  },
  {
    id: '670',
    label: 'Beirut Arab University',
    value: 'Beirut Arab University'
  },
  {
    id: '671',
    label: 'Belarusian-Russian University',
    value: 'Belarusian-Russian University'
  },
  {
    id: '672',
    label: 'Belarussian National Technical University',
    value: 'Belarussian National Technical University'
  },
  {
    id: '673',
    label: 'Belarussian State Academy of Music',
    value: 'Belarussian State Academy of Music'
  },
  {
    id: '674',
    label: 'Belarussian State Agrarian Technical University',
    value: 'Belarussian State Agrarian Technical University'
  },
  {
    id: '675',
    label: 'Belarussian State Agricultural Academy',
    value: 'Belarussian State Agricultural Academy'
  },
  {
    id: '676',
    label: 'Belarussian State Economic University',
    value: 'Belarussian State Economic University'
  },
  {
    id: '677',
    label: 'Belarussian State Medical University',
    value: 'Belarussian State Medical University'
  },
  {
    id: '678',
    label: 'Belarussian State Pedagogical University M. Tanka',
    value: 'Belarussian State Pedagogical University M. Tanka'
  },
  {
    id: '679',
    label: 'Belarussian State Technological University',
    value: 'Belarussian State Technological University'
  },
  {
    id: '680',
    label: 'Belarussian State University',
    value: 'Belarussian State University'
  },
  {
    id: '681',
    label: 'Belarussian State University of Culture and Arts',
    value: 'Belarussian State University of Culture and Arts'
  },
  {
    id: '682',
    label: 'Belarussian State University of Informatics and Radioelectronics',
    value: 'Belarussian State University of Informatics and Radioelectronics'
  },
  {
    id: '683',
    label: 'Belarussian State University of Transport',
    value: 'Belarussian State University of Transport'
  },
  {
    id: '684',
    label: 'Belgorod State Agricultural Academy',
    value: 'Belgorod State Agricultural Academy'
  },
  {
    id: '685',
    label: 'Belgorod State Technical University',
    value: 'Belgorod State Technical University'
  },
  {
    id: '686',
    label: 'Belgorod State University',
    value: 'Belgorod State University'
  },
  {
    id: '687',
    label: 'Belhaven University',
    value: 'Belhaven University'
  },
  {
    id: '688',
    label: 'Bellevue College',
    value: 'Bellevue College'
  },
  {
    id: '689',
    label: 'Bellevue University',
    value: 'Bellevue University'
  },
  {
    id: '690',
    label: 'Bellingham Technical College',
    value: 'Bellingham Technical College'
  },
  {
    id: '691',
    label: 'Bells University of Technology',
    value: 'Bells University of Technology'
  },
  {
    id: '692',
    label: 'Belmont College',
    value: 'Belmont College'
  },
  {
    id: '693',
    label: 'Belmont University',
    value: 'Belmont University'
  },
  {
    id: '694',
    label: 'Beloit College',
    value: 'Beloit College'
  },
  {
    id: '695',
    label: 'Bemidji State University',
    value: 'Bemidji State University'
  },
  {
    id: '696',
    label: 'Ben-Gurion University of the Negev',
    value: 'Ben-Gurion University of the Negev'
  },
  {
    id: '697',
    label: 'Benadir University',
    value: 'Benadir University'
  },
  {
    id: '698',
    label: 'Benedict College',
    value: 'Benedict College'
  },
  {
    id: '699',
    label: 'Benedictine College',
    value: 'Benedictine College'
  },
  {
    id: '700',
    label: 'Benemerita Universidad Autónoma de Puebla',
    value: 'Benemerita Universidad Autónoma de Puebla'
  },
  {
    id: '701',
    label: 'Bengal Engineering College',
    value: 'Bengal Engineering College'
  },
  {
    id: '702',
    label: 'Benguet State University',
    value: 'Benguet State University'
  },
  {
    id: '703',
    label: 'Benha University',
    value: 'Benha University'
  },
  {
    id: '704',
    label: 'Beni Suef University',
    value: 'Beni Suef University'
  },
  {
    id: '705',
    label: 'Bennett College',
    value: 'Bennett College'
  },
  {
    id: '706',
    label: 'Bennington College',
    value: 'Bennington College'
  },
  {
    id: '707',
    label: 'Benson Idahosa University',
    value: 'Benson Idahosa University'
  },
  {
    id: '708',
    label: 'Bentley College',
    value: 'Bentley College'
  },
  {
    id: '709',
    label: 'Benue State University',
    value: 'Benue State University'
  },
  {
    id: '710',
    label: 'Beppu University',
    value: 'Beppu University'
  },
  {
    id: '711',
    label: 'Berea College',
    value: 'Berea College'
  },
  {
    id: '712',
    label: 'Bergen Community College',
    value: 'Bergen Community College'
  },
  {
    id: '713',
    label: 'Bergen University College',
    value: 'Bergen University College'
  },
  {
    id: '714',
    label: 'Bergische Universität Gesamthochschule Wuppertal',
    value: 'Bergische Universität Gesamthochschule Wuppertal'
  },
  {
    id: '715',
    label: 'Berhampur University',
    value: 'Berhampur University'
  },
  {
    id: '716',
    label: 'Berkeley City College',
    value: 'Berkeley City College'
  },
  {
    id: '717',
    label: 'Berkeley College',
    value: 'Berkeley College'
  },
  {
    id: '718',
    label: 'Berklee College of Music',
    value: 'Berklee College of Music'
  },
  {
    id: '719',
    label: 'Berkshire Community College',
    value: 'Berkshire Community College'
  },
  {
    id: '720',
    label: 'Bermuda College',
    value: 'Bermuda College'
  },
  {
    id: '721',
    label: 'Berry College',
    value: 'Berry College'
  },
  {
    id: '722',
    label: 'Bethany College',
    value: 'Bethany College'
  },
  {
    id: '723',
    label: 'Bethel College (KS)',
    value: 'Bethel College (KS)'
  },
  {
    id: '724',
    label: 'Bethel University',
    value: 'Bethel University'
  },
  {
    id: '725',
    label: 'Bethlehem University',
    value: 'Bethlehem University'
  },
  {
    id: '726',
    label: 'Bethune-Cookman University',
    value: 'Bethune-Cookman University'
  },
  {
    id: '727',
    label: 'Bevill State Community College',
    value: 'Bevill State Community College'
  },
  {
    id: '728',
    label: 'Beykent University',
    value: 'Beykent University'
  },
  {
    id: '729',
    label: 'Bezalel Academy of Art and Design',
    value: 'Bezalel Academy of Art and Design'
  },
  {
    id: '730',
    label: 'Bezmialem Vakif University',
    value: 'Bezmialem Vakif University'
  },
  {
    id: '731',
    label: 'Bhagwant University',
    value: 'Bhagwant University'
  },
  {
    id: '732',
    label: 'Bharath Institue Of Higher Education & Research',
    value: 'Bharath Institue Of Higher Education & Research'
  },
  {
    id: '733',
    label: 'Bharathiar University',
    value: 'Bharathiar University'
  },
  {
    id: '734',
    label: 'Bharathidasan University',
    value: 'Bharathidasan University'
  },
  {
    id: '735',
    label: 'Bharati Vidyapeeth University',
    value: 'Bharati Vidyapeeth University'
  },
  {
    id: '736',
    label: 'Bhavnagar University',
    value: 'Bhavnagar University'
  },
  {
    id: '737',
    label: 'Bhupendra Narayan Mandal University',
    value: 'Bhupendra Narayan Mandal University'
  },
  {
    id: '738',
    label: 'BiTS - Business and Information Technology School gGmbH',
    value: 'BiTS - Business and Information Technology School gGmbH'
  },
  {
    id: '739',
    label: 'Bicol University',
    value: 'Bicol University'
  },
  {
    id: '740',
    label: 'Bidhan Chandra Agricultural University',
    value: 'Bidhan Chandra Agricultural University'
  },
  {
    id: '741',
    label: 'Bifrost School of Business',
    value: 'Bifrost School of Business'
  },
  {
    id: '742',
    label: 'Big Bend Community College',
    value: 'Big Bend Community College'
  },
  {
    id: '743',
    label: 'Big Sandy Community and Technical College',
    value: 'Big Sandy Community and Technical College'
  },
  {
    id: '744',
    label: 'Biju Pattnaik University of Technology',
    value: 'Biju Pattnaik University of Technology'
  },
  {
    id: '745',
    label: 'Bila Cerkva State Agrarian University',
    value: 'Bila Cerkva State Agrarian University'
  },
  {
    id: '746',
    label: 'Bilecik University',
    value: 'Bilecik University'
  },
  {
    id: '747',
    label: 'Bilkent University',
    value: 'Bilkent University'
  },
  {
    id: '748',
    label: 'Binary University College of Managemant & Entrepreneurship',
    value: 'Binary University College of Managemant & Entrepreneurship'
  },
  {
    id: '749',
    label: 'Bindura University of Science Education',
    value: 'Bindura University of Science Education'
  },
  {
    id: '750',
    label: 'Bingham University',
    value: 'Bingham University'
  },
  {
    id: '751',
    label: 'Bingol University',
    value: 'Bingol University'
  },
  {
    id: '752',
    label: 'Binh Duong University',
    value: 'Binh Duong University'
  },
  {
    id: '753',
    label: 'Binus University',
    value: 'Binus University'
  },
  {
    id: '754',
    label: 'Biola University',
    value: 'Biola University'
  },
  {
    id: '755',
    label: 'Bircham International University',
    value: 'Bircham International University'
  },
  {
    id: '756',
    label: 'Birjand University',
    value: 'Birjand University'
  },
  {
    id: '757',
    label: 'Birjand University of Medical Sciences',
    value: 'Birjand University of Medical Sciences'
  },
  {
    id: '758',
    label: 'Birkbeck College, University of London',
    value: 'Birkbeck College, University of London'
  },
  {
    id: '759',
    label: 'Birla Institute of Technology and Science',
    value: 'Birla Institute of Technology and Science'
  },
  {
    id: '760',
    label: 'Birla Institute of Technology, Ranchi',
    value: 'Birla Institute of Technology, Ranchi'
  },
  {
    id: '761',
    label: 'Birmingham City University',
    value: 'Birmingham City University'
  },
  {
    id: '762',
    label: 'Birmingham-Southern College',
    value: 'Birmingham-Southern College'
  },
  {
    id: '763',
    label: 'Birsa Agricultural University',
    value: 'Birsa Agricultural University'
  },
  {
    id: '764',
    label: 'Biruni University',
    value: 'Biruni University'
  },
  {
    id: '765',
    label: 'Birzeit University',
    value: 'Birzeit University'
  },
  {
    id: '766',
    label: 'Bishkek Humanities University',
    value: 'Bishkek Humanities University'
  },
  {
    id: '767',
    label: 'Bishop Herber College',
    value: 'Bishop Herber College'
  },
  {
    id: '768',
    label: 'Bishop McNamara High School',
    value: 'Bishop McNamara High School'
  },
  {
    id: '769',
    label: 'Bishop State Community College',
    value: 'Bishop State Community College'
  },
  {
    id: '770',
    label: "Bishop's University",
    value: "Bishop's University"
  },
  {
    id: '771',
    label: 'Bismark State College',
    value: 'Bismark State College'
  },
  {
    id: '772',
    label: 'Bitlis Eren University',
    value: 'Bitlis Eren University'
  },
  {
    id: '773',
    label: 'Biysk State Padagogical University after V.M. Shukshin',
    value: 'Biysk State Padagogical University after V.M. Shukshin'
  },
  {
    id: '774',
    label: 'Black Hawk College',
    value: 'Black Hawk College'
  },
  {
    id: '775',
    label: 'Black Hills State University',
    value: 'Black Hills State University'
  },
  {
    id: '776',
    label: 'Black River Technical College',
    value: 'Black River Technical College'
  },
  {
    id: '777',
    label: 'Blackfeet Community College',
    value: 'Blackfeet Community College'
  },
  {
    id: '778',
    label: 'Blackhawk Technical College',
    value: 'Blackhawk Technical College'
  },
  {
    id: '779',
    label: 'Bladen Community College',
    value: 'Bladen Community College'
  },
  {
    id: '780',
    label: 'Blekinge Institute of Technology',
    value: 'Blekinge Institute of Technology'
  },
  {
    id: '781',
    label: 'Blinn College',
    value: 'Blinn College'
  },
  {
    id: '782',
    label: 'Bloomfield College',
    value: 'Bloomfield College'
  },
  {
    id: '783',
    label: 'Bloomsburg University of Pennsylvania',
    value: 'Bloomsburg University of Pennsylvania'
  },
  {
    id: '784',
    label: 'Blue Mountain Community College',
    value: 'Blue Mountain Community College'
  },
  {
    id: '785',
    label: 'Blue Mountains Hotel School',
    value: 'Blue Mountains Hotel School'
  },
  {
    id: '786',
    label: 'Blue Nile University',
    value: 'Blue Nile University'
  },
  {
    id: '787',
    label: 'Blue Ridge Community College',
    value: 'Blue Ridge Community College'
  },
  {
    id: '788',
    label: 'Blue Ridge Community College',
    value: 'Blue Ridge Community College'
  },
  {
    id: '789',
    label: 'Blue Ridge Community and Technical College',
    value: 'Blue Ridge Community and Technical College'
  },
  {
    id: '790',
    label: 'Bluefield State College',
    value: 'Bluefield State College'
  },
  {
    id: '791',
    label: 'Bluegrass Community and Technical College',
    value: 'Bluegrass Community and Technical College'
  },
  {
    id: '792',
    label: 'Bluffton College',
    value: 'Bluffton College'
  },
  {
    id: '793',
    label: 'Bob Jones University',
    value: 'Bob Jones University'
  },
  {
    id: '794',
    label: 'Bocconi University',
    value: 'Bocconi University'
  },
  {
    id: '795',
    label: 'Bodo Regional University',
    value: 'Bodo Regional University'
  },
  {
    id: '796',
    label: 'Bogor Agricultural University',
    value: 'Bogor Agricultural University'
  },
  {
    id: '797',
    label: 'Bohai University',
    value: 'Bohai University'
  },
  {
    id: '798',
    label: 'Boise State University',
    value: 'Boise State University'
  },
  {
    id: '799',
    label: 'Bond University',
    value: 'Bond University'
  },
  {
    id: '800',
    label: 'Bosaso College',
    value: 'Bosaso College'
  },
  {
    id: '801',
    label: 'Bossier Parish Community College',
    value: 'Bossier Parish Community College'
  },
  {
    id: '802',
    label: 'Bost University',
    value: 'Bost University'
  },
  {
    id: '803',
    label: 'Boston College',
    value: 'Boston College'
  },
  {
    id: '804',
    label: 'Boston Graduate School of Psychoanalysis',
    value: 'Boston Graduate School of Psychoanalysis'
  },
  {
    id: '805',
    label: 'Boston University',
    value: 'Boston University'
  },
  {
    id: '806',
    label: 'Botho University',
    value: 'Botho University'
  },
  {
    id: '807',
    label: 'Botswana Accountancy College',
    value: 'Botswana Accountancy College'
  },
  {
    id: '808',
    label: 'Botswana College of Agriculture',
    value: 'Botswana College of Agriculture'
  },
  {
    id: '809',
    label: 'Botswana International University of Science & Technology',
    value: 'Botswana International University of Science & Technology'
  },
  {
    id: '810',
    label: 'Bourgas Free University',
    value: 'Bourgas Free University'
  },
  {
    id: '811',
    label: 'Bourgas University "Prof. Assen Zlatarov"',
    value: 'Bourgas University "Prof. Assen Zlatarov"'
  },
  {
    id: '812',
    label: 'Bournemouth University',
    value: 'Bournemouth University'
  },
  {
    id: '813',
    label: 'Bow Valley College',
    value: 'Bow Valley College'
  },
  {
    id: '814',
    label: 'Bowdoin College',
    value: 'Bowdoin College'
  },
  {
    id: '815',
    label: 'Bowen University',
    value: 'Bowen University'
  },
  {
    id: '816',
    label: 'Bowie State University',
    value: 'Bowie State University'
  },
  {
    id: '817',
    label: 'Bowling Green State University',
    value: 'Bowling Green State University'
  },
  {
    id: '818',
    label: 'Box Hill Institute',
    value: 'Box Hill Institute'
  },
  {
    id: '819',
    label: 'Bozok University',
    value: 'Bozok University'
  },
  {
    id: '820',
    label: 'Boğaziçi University',
    value: 'Boğaziçi University'
  },
  {
    id: '821',
    label: 'Brac University',
    value: 'Brac University'
  },
  {
    id: '822',
    label: 'Bradley University',
    value: 'Bradley University'
  },
  {
    id: '823',
    label: 'Brandeis University',
    value: 'Brandeis University'
  },
  {
    id: '824',
    label: 'Brandenburgische Technische Universität Cottbus',
    value: 'Brandenburgische Technische Universität Cottbus'
  },
  {
    id: '825',
    label: 'Brandman University',
    value: 'Brandman University'
  },
  {
    id: '826',
    label: 'Brandon University',
    value: 'Brandon University'
  },
  {
    id: '827',
    label: 'Bratislava International School of Liberal Arts',
    value: 'Bratislava International School of Liberal Arts'
  },
  {
    id: '828',
    label: 'Bratsk State Technical University',
    value: 'Bratsk State Technical University'
  },
  {
    id: '829',
    label: 'Brenau University',
    value: 'Brenau University'
  },
  {
    id: '830',
    label: 'Brescia University',
    value: 'Brescia University'
  },
  {
    id: '831',
    label: 'Brest State Technical University',
    value: 'Brest State Technical University'
  },
  {
    id: '832',
    label: 'Brest State University',
    value: 'Brest State University'
  },
  {
    id: '833',
    label: 'Brexgata University Academy',
    value: 'Brexgata University Academy'
  },
  {
    id: '834',
    label: 'Briar Cliff College',
    value: 'Briar Cliff College'
  },
  {
    id: '835',
    label: 'Brickfields Asia College',
    value: 'Brickfields Asia College'
  },
  {
    id: '836',
    label: 'Bridgemont Community and Technical College',
    value: 'Bridgemont Community and Technical College'
  },
  {
    id: '837',
    label: 'Bridgewater College',
    value: 'Bridgewater College'
  },
  {
    id: '838',
    label: 'Bridgewater State University',
    value: 'Bridgewater State University'
  },
  {
    id: '839',
    label: 'Bridgwater & Taunton College',
    value: 'Bridgwater & Taunton College'
  },
  {
    id: '840',
    label: 'Brigham Young University',
    value: 'Brigham Young University'
  },
  {
    id: '841',
    label: 'Brigham Young University - Idaho',
    value: 'Brigham Young University - Idaho'
  },
  {
    id: '842',
    label: 'Brigham Young University Hawaii',
    value: 'Brigham Young University Hawaii'
  },
  {
    id: '843',
    label: 'Bristol Community College',
    value: 'Bristol Community College'
  },
  {
    id: '844',
    label: 'British College of Osteopathic Medicine',
    value: 'British College of Osteopathic Medicine'
  },
  {
    id: '845',
    label: 'British Columbia Institute of Technology',
    value: 'British Columbia Institute of Technology'
  },
  {
    id: '846',
    label: 'British Columbia Open University',
    value: 'British Columbia Open University'
  },
  {
    id: '847',
    label: 'British Institute in Paris, University of London',
    value: 'British Institute in Paris, University of London'
  },
  {
    id: '848',
    label: 'British Malaysian Institute',
    value: 'British Malaysian Institute'
  },
  {
    id: '849',
    label: 'British Royal University',
    value: 'British Royal University'
  },
  {
    id: '850',
    label: 'British University in Dubai',
    value: 'British University in Dubai'
  },
  {
    id: '851',
    label: 'Brjansk State Technical University',
    value: 'Brjansk State Technical University'
  },
  {
    id: '852',
    label: 'Brno University of Technology',
    value: 'Brno University of Technology'
  },
  {
    id: '853',
    label: 'Brock University',
    value: 'Brock University'
  },
  {
    id: '854',
    label: 'Brookdale Community College',
    value: 'Brookdale Community College'
  },
  {
    id: '855',
    label: 'Brookdale Community College',
    value: 'Brookdale Community College'
  },
  {
    id: '856',
    label: 'Brookhaven College',
    value: 'Brookhaven College'
  },
  {
    id: '857',
    label: 'Brookings Institution',
    value: 'Brookings Institution'
  },
  {
    id: '858',
    label: 'Broward College',
    value: 'Broward College'
  },
  {
    id: '859',
    label: 'Brown University',
    value: 'Brown University'
  },
  {
    id: '860',
    label: 'Brunel University Uxbridge',
    value: 'Brunel University Uxbridge'
  },
  {
    id: '861',
    label: 'Brunswick Community College',
    value: 'Brunswick Community College'
  },
  {
    id: '862',
    label: 'Brussels Management School (ICHEC)',
    value: 'Brussels Management School (ICHEC)'
  },
  {
    id: '863',
    label: 'Bryant College',
    value: 'Bryant College'
  },
  {
    id: '864',
    label: 'Bryn Mawr College',
    value: 'Bryn Mawr College'
  },
  {
    id: '865',
    label: 'Bu Ali Sina University',
    value: 'Bu Ali Sina University'
  },
  {
    id: '866',
    label: 'Buckinghamshire New University',
    value: 'Buckinghamshire New University'
  },
  {
    id: '867',
    label: 'Bucknell University',
    value: 'Bucknell University'
  },
  {
    id: '868',
    label: 'Bucks County Community College',
    value: 'Bucks County Community College'
  },
  {
    id: '869',
    label: 'Budapest Buddhist University',
    value: 'Budapest Buddhist University'
  },
  {
    id: '870',
    label: 'Budapest Business School',
    value: 'Budapest Business School'
  },
  {
    id: '871',
    label: 'Budapest University of Economic Sciences and Public Administration',
    value: 'Budapest University of Economic Sciences and Public Administration'
  },
  {
    id: '872',
    label: 'Budapest University of Technology and Economics',
    value: 'Budapest University of Technology and Economics'
  },
  {
    id: '873',
    label: 'Buddhasravaka Bhikshu University',
    value: 'Buddhasravaka Bhikshu University'
  },
  {
    id: '874',
    label: 'Buddhist Acamedy of China',
    value: 'Buddhist Acamedy of China'
  },
  {
    id: '875',
    label: 'Buddhist and Pali University of Sri Lanka',
    value: 'Buddhist and Pali University of Sri Lanka'
  },
  {
    id: '876',
    label: 'Buena Vista University',
    value: 'Buena Vista University'
  },
  {
    id: '877',
    label: 'Bugema University',
    value: 'Bugema University'
  },
  {
    id: '878',
    label: 'Bugema University',
    value: 'Bugema University'
  },
  {
    id: '879',
    label: 'Build Bright University',
    value: 'Build Bright University'
  },
  {
    id: '880',
    label: 'Bukkyo University',
    value: 'Bukkyo University'
  },
  {
    id: '881',
    label: 'Bulacan State University',
    value: 'Bulacan State University'
  },
  {
    id: '882',
    label: 'Bule Hora  University',
    value: 'Bule Hora  University'
  },
  {
    id: '883',
    label: 'Bulent Ecevit University',
    value: 'Bulent Ecevit University'
  },
  {
    id: '884',
    label: 'Bundelkhand University',
    value: 'Bundelkhand University'
  },
  {
    id: '885',
    label: "Bunka Women's University",
    value: "Bunka Women's University"
  },
  {
    id: '886',
    label: 'Bunker Hill Community College',
    value: 'Bunker Hill Community College'
  },
  {
    id: '887',
    label: 'Bunkyo University',
    value: 'Bunkyo University'
  },
  {
    id: '888',
    label: 'Burapha University',
    value: 'Burapha University'
  },
  {
    id: '889',
    label: 'Buraydah College for Applied Medical Sciences',
    value: 'Buraydah College for Applied Medical Sciences'
  },
  {
    id: '890',
    label: 'Burjat State University',
    value: 'Burjat State University'
  },
  {
    id: '891',
    label: 'Bursa Tecnical University',
    value: 'Bursa Tecnical University'
  },
  {
    id: '892',
    label: 'Busan National University of Education',
    value: 'Busan National University of Education'
  },
  {
    id: '893',
    label: 'Busan Presbyterian University',
    value: 'Busan Presbyterian University'
  },
  {
    id: '894',
    label: 'Busan University of Foreign Studies',
    value: 'Busan University of Foreign Studies'
  },
  {
    id: '895',
    label: 'Bushehr University of Medical Sciences',
    value: 'Bushehr University of Medical Sciences'
  },
  {
    id: '896',
    label: 'Business & Computer University College',
    value: 'Business & Computer University College'
  },
  {
    id: '897',
    label: 'Business School Lausanne (BSL)',
    value: 'Business School Lausanne (BSL)'
  },
  {
    id: '898',
    label: 'Business and Hotel Management School',
    value: 'Business and Hotel Management School'
  },
  {
    id: '899',
    label: 'Business and Technology University',
    value: 'Business and Technology University'
  },
  {
    id: '900',
    label: 'Busitema University',
    value: 'Busitema University'
  },
  {
    id: '901',
    label: 'Busoga University',
    value: 'Busoga University'
  },
  {
    id: '902',
    label: 'Butler Community College',
    value: 'Butler Community College'
  },
  {
    id: '903',
    label: 'Butler County Community College',
    value: 'Butler County Community College'
  },
  {
    id: '904',
    label: 'Butler University',
    value: 'Butler University'
  },
  {
    id: '905',
    label: 'Butte College',
    value: 'Butte College'
  },
  {
    id: '906',
    label: 'CCS Haryana Agricultural University',
    value: 'CCS Haryana Agricultural University'
  },
  {
    id: '907',
    label: 'CETYS Universidad',
    value: 'CETYS Universidad'
  },
  {
    id: '908',
    label: 'CHA University',
    value: 'CHA University'
  },
  {
    id: '909',
    label: 'CHEONGJU UNIVERSITY',
    value: 'CHEONGJU UNIVERSITY'
  },
  {
    id: '910',
    label: 'CHUV - University Hospital Lausanne',
    value: 'CHUV - University Hospital Lausanne'
  },
  {
    id: '911',
    label: 'CIC - Canadian International College',
    value: 'CIC - Canadian International College'
  },
  {
    id: '912',
    label: 'CMJ University',
    value: 'CMJ University'
  },
  {
    id: '913',
    label: 'CODE University of Applied Sciences Berlin',
    value: 'CODE University of Applied Sciences Berlin'
  },
  {
    id: '914',
    label: 'COEP Technological University',
    value: 'COEP Technological University'
  },
  {
    id: '915',
    label: 'COMSATS Institute of Information Technology',
    value: 'COMSATS Institute of Information Technology'
  },
  {
    id: '916',
    label: 'COMSATS Institute of Information Technology, Abbottabad',
    value: 'COMSATS Institute of Information Technology, Abbottabad'
  },
  {
    id: '917',
    label: 'COMSATS Institute of Information Technology, Attock',
    value: 'COMSATS Institute of Information Technology, Attock'
  },
  {
    id: '918',
    label: 'COMSATS Institute of Information Technology, Lahore',
    value: 'COMSATS Institute of Information Technology, Lahore'
  },
  {
    id: '919',
    label: 'COMSATS Institute of Information Technology, Wah',
    value: 'COMSATS Institute of Information Technology, Wah'
  },
  {
    id: '920',
    label: 'COSMIQ Institute of Technology',
    value: 'COSMIQ Institute of Technology'
  },
  {
    id: '921',
    label: 'CUNY Baruch College',
    value: 'CUNY Baruch College'
  },
  {
    id: '922',
    label: 'CUNY Borough of Manhattan Community College',
    value: 'CUNY Borough of Manhattan Community College'
  },
  {
    id: '923',
    label: 'CUNY Bronx Community College',
    value: 'CUNY Bronx Community College'
  },
  {
    id: '924',
    label: 'CUNY Brooklyn College',
    value: 'CUNY Brooklyn College'
  },
  {
    id: '925',
    label: 'CUNY City College of NY',
    value: 'CUNY City College of NY'
  },
  {
    id: '926',
    label: 'CUNY City Tech',
    value: 'CUNY City Tech'
  },
  {
    id: '927',
    label: 'CUNY College of Staten Island',
    value: 'CUNY College of Staten Island'
  },
  {
    id: '928',
    label: 'CUNY Hostos Community College',
    value: 'CUNY Hostos Community College'
  },
  {
    id: '929',
    label: 'CUNY John Jay College of Criminal Justice',
    value: 'CUNY John Jay College of Criminal Justice'
  },
  {
    id: '930',
    label: 'CUNY Kingsborough Community College',
    value: 'CUNY Kingsborough Community College'
  },
  {
    id: '931',
    label: 'CUNY LaGuardia Community College',
    value: 'CUNY LaGuardia Community College'
  },
  {
    id: '932',
    label: 'CUNY Lehman College',
    value: 'CUNY Lehman College'
  },
  {
    id: '933',
    label: 'CUNY Macauly Honors College',
    value: 'CUNY Macauly Honors College'
  },
  {
    id: '934',
    label: 'CUNY Medgar Evers College',
    value: 'CUNY Medgar Evers College'
  },
  {
    id: '935',
    label: 'CUNY Queens College',
    value: 'CUNY Queens College'
  },
  {
    id: '936',
    label: 'CUNY Queensborough Community College',
    value: 'CUNY Queensborough Community College'
  },
  {
    id: '937',
    label: 'CUNY York College',
    value: 'CUNY York College'
  },
  {
    id: '938',
    label: 'Cabrillo College',
    value: 'Cabrillo College'
  },
  {
    id: '939',
    label: 'Cabrini University',
    value: 'Cabrini University'
  },
  {
    id: '940',
    label: 'Cag University',
    value: 'Cag University'
  },
  {
    id: '941',
    label: 'Cagayan State University',
    value: 'Cagayan State University'
  },
  {
    id: '942',
    label: 'Cairo University',
    value: 'Cairo University'
  },
  {
    id: '943',
    label: 'Caldwell Community College and Technical Institute',
    value: 'Caldwell Community College and Technical Institute'
  },
  {
    id: '944',
    label: 'Caldwell University',
    value: 'Caldwell University'
  },
  {
    id: '945',
    label: 'Caleb University',
    value: 'Caleb University'
  },
  {
    id: '946',
    label: 'Calhoun Community College',
    value: 'Calhoun Community College'
  },
  {
    id: '947',
    label: 'California Baptist University',
    value: 'California Baptist University'
  },
  {
    id: '948',
    label: 'California Coast University',
    value: 'California Coast University'
  },
  {
    id: '949',
    label: 'California Institute of Technology',
    value: 'California Institute of Technology'
  },
  {
    id: '950',
    label: 'California Institute of the Arts',
    value: 'California Institute of the Arts'
  },
  {
    id: '951',
    label: 'California Lutheran University',
    value: 'California Lutheran University'
  },
  {
    id: '952',
    label: 'California Maritime Academy',
    value: 'California Maritime Academy'
  },
  {
    id: '953',
    label: 'California National University',
    value: 'California National University'
  },
  {
    id: '954',
    label: 'California Polytechnic State University, Pomona',
    value: 'California Polytechnic State University, Pomona'
  },
  {
    id: '955',
    label: 'California Polytechnic State University, San Luis Obispo',
    value: 'California Polytechnic State University, San Luis Obispo'
  },
  {
    id: '956',
    label: 'California Southern University',
    value: 'California Southern University'
  },
  {
    id: '957',
    label: 'California State University',
    value: 'California State University'
  },
  {
    id: '958',
    label: 'California State University Channel Islands',
    value: 'California State University Channel Islands'
  },
  {
    id: '959',
    label: 'California State University System',
    value: 'California State University System'
  },
  {
    id: '960',
    label: 'California State University, Bakersfield',
    value: 'California State University, Bakersfield'
  },
  {
    id: '961',
    label: 'California State University, Chico',
    value: 'California State University, Chico'
  },
  {
    id: '962',
    label: 'California State University, Dominguez Hills',
    value: 'California State University, Dominguez Hills'
  },
  {
    id: '963',
    label: 'California State University, Fresno',
    value: 'California State University, Fresno'
  },
  {
    id: '964',
    label: 'California State University, Fullerton',
    value: 'California State University, Fullerton'
  },
  {
    id: '965',
    label: 'California State University, Hayward',
    value: 'California State University, Hayward'
  },
  {
    id: '966',
    label: 'California State University, Long Beach',
    value: 'California State University, Long Beach'
  },
  {
    id: '967',
    label: 'California State University, Los Angeles',
    value: 'California State University, Los Angeles'
  },
  {
    id: '968',
    label: 'California State University, Monterey Bay',
    value: 'California State University, Monterey Bay'
  },
  {
    id: '969',
    label: 'California State University, Northridge',
    value: 'California State University, Northridge'
  },
  {
    id: '970',
    label: 'California State University, Sacramento',
    value: 'California State University, Sacramento'
  },
  {
    id: '971',
    label: 'California State University, San Bernardino',
    value: 'California State University, San Bernardino'
  },
  {
    id: '972',
    label: 'California State University, San Marcos',
    value: 'California State University, San Marcos'
  },
  {
    id: '973',
    label: 'California State University, Stanislaus',
    value: 'California State University, Stanislaus'
  },
  {
    id: '974',
    label: 'California University of Pennsylvania',
    value: 'California University of Pennsylvania'
  },
  {
    id: '975',
    label: 'Calvin College',
    value: 'Calvin College'
  },
  {
    id: '976',
    label: 'Camberwell College of Arts',
    value: 'Camberwell College of Arts'
  },
  {
    id: '977',
    label: 'Cambodia University of Specialties',
    value: 'Cambodia University of Specialties'
  },
  {
    id: '978',
    label: 'Cambodian Mekong University',
    value: 'Cambodian Mekong University'
  },
  {
    id: '979',
    label: 'Cambrian College',
    value: 'Cambrian College'
  },
  {
    id: '980',
    label: 'Camden County College',
    value: 'Camden County College'
  },
  {
    id: '981',
    label: 'Cameron University',
    value: 'Cameron University'
  },
  {
    id: '982',
    label: 'Camosun College',
    value: 'Camosun College'
  },
  {
    id: '983',
    label: 'Campbell University',
    value: 'Campbell University'
  },
  {
    id: '984',
    label: 'Campbellsville College',
    value: 'Campbellsville College'
  },
  {
    id: '985',
    label: 'Campus Bio-Medico University of Rome',
    value: 'Campus Bio-Medico University of Rome'
  },
  {
    id: '986',
    label: 'Campus Saint Marc',
    value: 'Campus Saint Marc'
  },
  {
    id: '987',
    label: 'Can-Tho University',
    value: 'Can-Tho University'
  },
  {
    id: '988',
    label: 'Canada College',
    value: 'Canada College'
  },
  {
    id: '989',
    label: 'Canadian College of Business & Computers',
    value: 'Canadian College of Business & Computers'
  },
  {
    id: '990',
    label: 'Canadian International School of Hong Kong',
    value: 'Canadian International School of Hong Kong'
  },
  {
    id: '991',
    label: 'Canadian Mennonite University',
    value: 'Canadian Mennonite University'
  },
  {
    id: '992',
    label: 'Canadian Sudanese College',
    value: 'Canadian Sudanese College'
  },
  {
    id: '993',
    label: 'Canakkale (18th March) University',
    value: 'Canakkale (18th March) University'
  },
  {
    id: '994',
    label: 'Canisius College',
    value: 'Canisius College'
  },
  {
    id: '995',
    label: 'Cankaya University',
    value: 'Cankaya University'
  },
  {
    id: '996',
    label: 'Cankdeska Cikana Community College',
    value: 'Cankdeska Cikana Community College'
  },
  {
    id: '997',
    label: 'Cankiri karatekin University',
    value: 'Cankiri karatekin University'
  },
  {
    id: '998',
    label: 'Canterbury Christ Church University',
    value: 'Canterbury Christ Church University'
  },
  {
    id: '999',
    label: 'Cape Coast Technical University',
    value: 'Cape Coast Technical University'
  },
  {
    id: '1000',
    label: 'Cape Cod Community College',
    value: 'Cape Cod Community College'
  },
  {
    id: '1001',
    label: 'Cape Fear Community College',
    value: 'Cape Fear Community College'
  },
  {
    id: '1002',
    label: 'Cape Peninsula University of Technology',
    value: 'Cape Peninsula University of Technology'
  },
  {
    id: '1003',
    label: 'Capella University',
    value: 'Capella University'
  },
  {
    id: '1004',
    label: 'Capilano College',
    value: 'Capilano College'
  },
  {
    id: '1005',
    label: 'Capital Community College',
    value: 'Capital Community College'
  },
  {
    id: '1006',
    label: 'Capital Normal University',
    value: 'Capital Normal University'
  },
  {
    id: '1007',
    label: 'Capital University',
    value: 'Capital University'
  },
  {
    id: '1008',
    label: 'Capital University of Economics and Business',
    value: 'Capital University of Economics and Business'
  },
  {
    id: '1009',
    label: 'Capital University of Medical Sciences',
    value: 'Capital University of Medical Sciences'
  },
  {
    id: '1010',
    label: 'Capital University of Science & Technology',
    value: 'Capital University of Science & Technology'
  },
  {
    id: '1011',
    label: 'Capitol Technology University',
    value: 'Capitol Technology University'
  },
  {
    id: '1012',
    label: 'Capitol University',
    value: 'Capitol University'
  },
  {
    id: '1013',
    label: 'Cardiff University',
    value: 'Cardiff University'
  },
  {
    id: '1014',
    label: 'Cardiff and Vale College',
    value: 'Cardiff and Vale College'
  },
  {
    id: '1015',
    label: 'Cardinal Stefan Wyszynski University in Warsaw',
    value: 'Cardinal Stefan Wyszynski University in Warsaw'
  },
  {
    id: '1016',
    label: 'Career College of Northern Nevada',
    value: 'Career College of Northern Nevada'
  },
  {
    id: '1017',
    label: 'Caribbean University',
    value: 'Caribbean University'
  },
  {
    id: '1018',
    label: 'Caritas University',
    value: 'Caritas University'
  },
  {
    id: '1019',
    label: 'Carl Albert State College',
    value: 'Carl Albert State College'
  },
  {
    id: '1020',
    label: 'Carl Sandburg College',
    value: 'Carl Sandburg College'
  },
  {
    id: '1021',
    label: 'Carl von Ossietzky Universität Oldenburg',
    value: 'Carl von Ossietzky Universität Oldenburg'
  },
  {
    id: '1022',
    label: 'Carleton College',
    value: 'Carleton College'
  },
  {
    id: '1023',
    label: 'Carleton University',
    value: 'Carleton University'
  },
  {
    id: '1024',
    label: 'Carlow College',
    value: 'Carlow College'
  },
  {
    id: '1025',
    label: 'Carlow Institute of Technology',
    value: 'Carlow Institute of Technology'
  },
  {
    id: '1026',
    label: 'Carnegie Mellon University',
    value: 'Carnegie Mellon University'
  },
  {
    id: '1027',
    label: 'Carolinas College of Health Sciences',
    value: 'Carolinas College of Health Sciences'
  },
  {
    id: '1028',
    label: 'Carrington College California-Sacramento',
    value: 'Carrington College California-Sacramento'
  },
  {
    id: '1029',
    label: 'Carroll College',
    value: 'Carroll College'
  },
  {
    id: '1030',
    label: 'Carroll Community College',
    value: 'Carroll Community College'
  },
  {
    id: '1031',
    label: 'Carroll University',
    value: 'Carroll University'
  },
  {
    id: '1032',
    label: 'Carson-Newman College',
    value: 'Carson-Newman College'
  },
  {
    id: '1033',
    label: 'Carteret Community College',
    value: 'Carteret Community College'
  },
  {
    id: '1034',
    label: 'Carthage College',
    value: 'Carthage College'
  },
  {
    id: '1035',
    label: 'Carver College',
    value: 'Carver College'
  },
  {
    id: '1036',
    label: 'Cascadia Community College',
    value: 'Cascadia Community College'
  },
  {
    id: '1037',
    label: 'Case Western Reserve University',
    value: 'Case Western Reserve University'
  },
  {
    id: '1038',
    label: 'Casper College',
    value: 'Casper College'
  },
  {
    id: '1039',
    label: 'Castleton State University',
    value: 'Castleton State University'
  },
  {
    id: '1040',
    label: 'Catawba College',
    value: 'Catawba College'
  },
  {
    id: '1041',
    label: 'Catawba Valley Community College',
    value: 'Catawba Valley Community College'
  },
  {
    id: '1042',
    label: 'Catholic Kwandong University',
    value: 'Catholic Kwandong University'
  },
  {
    id: '1043',
    label: 'Catholic University in Ruzomberok',
    value: 'Catholic University in Ruzomberok'
  },
  {
    id: '1044',
    label: 'Catholic University in Zimbabwe',
    value: 'Catholic University in Zimbabwe'
  },
  {
    id: '1045',
    label: 'Catholic University of America',
    value: 'Catholic University of America'
  },
  {
    id: '1046',
    label: 'Catholic University of Eastern Africa',
    value: 'Catholic University of Eastern Africa'
  },
  {
    id: '1047',
    label: 'Catholic University of Korea',
    value: 'Catholic University of Korea'
  },
  {
    id: '1048',
    label: 'Catholic University of Lublin',
    value: 'Catholic University of Lublin'
  },
  {
    id: '1049',
    label: 'Catholic University of Malawi',
    value: 'Catholic University of Malawi'
  },
  {
    id: '1050',
    label: 'Catholic University of Pusan',
    value: 'Catholic University of Pusan'
  },
  {
    id: '1051',
    label: 'Catholic University of Taegu-Hyosung',
    value: 'Catholic University of Taegu-Hyosung'
  },
  {
    id: '1052',
    label: 'Catholic University of the Sacred Heart',
    value: 'Catholic University of the Sacred Heart'
  },
  {
    id: '1053',
    label: 'Católica Lisbon School of Business & Economics',
    value: 'Católica Lisbon School of Business & Economics'
  },
  {
    id: '1054',
    label: 'Caucasus University',
    value: 'Caucasus University'
  },
  {
    id: '1055',
    label: 'Cavendish University',
    value: 'Cavendish University'
  },
  {
    id: '1056',
    label: 'Cayuga County Community College',
    value: 'Cayuga County Community College'
  },
  {
    id: '1057',
    label: 'Cecil College',
    value: 'Cecil College'
  },
  {
    id: '1058',
    label: 'Cecos Univeristy of Information Technology',
    value: 'Cecos Univeristy of Information Technology'
  },
  {
    id: '1059',
    label: 'Cedar Valley College',
    value: 'Cedar Valley College'
  },
  {
    id: '1060',
    label: 'Cedarville College',
    value: 'Cedarville College'
  },
  {
    id: '1061',
    label: 'Celal Bayar University',
    value: 'Celal Bayar University'
  },
  {
    id: '1062',
    label: 'Centenary College of Louisiana',
    value: 'Centenary College of Louisiana'
  },
  {
    id: '1063',
    label: 'Centennial College',
    value: 'Centennial College'
  },
  {
    id: '1064',
    label: 'Center for Entrepreneurship and Small Business Management',
    value: 'Center for Entrepreneurship and Small Business Management'
  },
  {
    id: '1065',
    label: 'Central Academy of  Fine Art',
    value: 'Central Academy of  Fine Art'
  },
  {
    id: '1066',
    label: 'Central Agricultural University',
    value: 'Central Agricultural University'
  },
  {
    id: '1067',
    label: 'Central Alabama Community College',
    value: 'Central Alabama Community College'
  },
  {
    id: '1068',
    label: 'Central America Health Sciences University (Belize Medical College)',
    value: 'Central America Health Sciences University (Belize Medical College)'
  },
  {
    id: '1069',
    label: 'Central Arizona College',
    value: 'Central Arizona College'
  },
  {
    id: '1070',
    label: 'Central Buganda University',
    value: 'Central Buganda University'
  },
  {
    id: '1071',
    label: 'Central Carolina Community College',
    value: 'Central Carolina Community College'
  },
  {
    id: '1072',
    label: 'Central Carolina Technical College',
    value: 'Central Carolina Technical College'
  },
  {
    id: '1073',
    label: 'Central China Normal University',
    value: 'Central China Normal University'
  },
  {
    id: '1074',
    label: 'Central College',
    value: 'Central College'
  },
  {
    id: '1075',
    label: 'Central Community College',
    value: 'Central Community College'
  },
  {
    id: '1076',
    label: 'Central Connecticut State University',
    value: 'Central Connecticut State University'
  },
  {
    id: '1077',
    label: 'Central European University',
    value: 'Central European University'
  },
  {
    id: '1078',
    label: 'Central Georgia Technical College',
    value: 'Central Georgia Technical College'
  },
  {
    id: '1079',
    label: 'Central Institute of English and Foreign Languages',
    value: 'Central Institute of English and Foreign Languages'
  },
  {
    id: '1080',
    label: 'Central Institute of Fisheries Education',
    value: 'Central Institute of Fisheries Education'
  },
  {
    id: '1081',
    label: 'Central Institute of Higher Tibetan Studies',
    value: 'Central Institute of Higher Tibetan Studies'
  },
  {
    id: '1082',
    label: 'Central Lakes College-Brainerd',
    value: 'Central Lakes College-Brainerd'
  },
  {
    id: '1083',
    label: 'Central Luzon State University',
    value: 'Central Luzon State University'
  },
  {
    id: '1084',
    label: 'Central Maine Community College',
    value: 'Central Maine Community College'
  },
  {
    id: '1085',
    label: 'Central Maine Medical Center College of Nursing and Health Professions',
    value: 'Central Maine Medical Center College of Nursing and Health Professions'
  },
  {
    id: '1086',
    label: 'Central Methodist College',
    value: 'Central Methodist College'
  },
  {
    id: '1087',
    label: 'Central Michigan University',
    value: 'Central Michigan University'
  },
  {
    id: '1088',
    label: 'Central Mindanao University',
    value: 'Central Mindanao University'
  },
  {
    id: '1089',
    label: 'Central New Mexico Community College',
    value: 'Central New Mexico Community College'
  },
  {
    id: '1090',
    label: 'Central Ohio Technical College',
    value: 'Central Ohio Technical College'
  },
  {
    id: '1091',
    label: 'Central Oregon Community College',
    value: 'Central Oregon Community College'
  },
  {
    id: '1092',
    label: 'Central Ostrobothnia University of Applied Sciences',
    value: 'Central Ostrobothnia University of Applied Sciences'
  },
  {
    id: '1093',
    label: 'Central Philippine University',
    value: 'Central Philippine University'
  },
  {
    id: '1094',
    label: 'Central Piedmont Community College',
    value: 'Central Piedmont Community College'
  },
  {
    id: '1095',
    label: 'Central Police University',
    value: 'Central Police University'
  },
  {
    id: '1096',
    label: 'Central Queensland University',
    value: 'Central Queensland University'
  },
  {
    id: '1097',
    label: 'Central Radio and TV University',
    value: 'Central Radio and TV University'
  },
  {
    id: '1098',
    label: 'Central Saint Martins College of Art & Design',
    value: 'Central Saint Martins College of Art & Design'
  },
  {
    id: '1099',
    label: 'Central South Forestry University',
    value: 'Central South Forestry University'
  },
  {
    id: '1100',
    label: 'Central South University',
    value: 'Central South University'
  },
  {
    id: '1101',
    label: 'Central State University',
    value: 'Central State University'
  },
  {
    id: '1102',
    label: 'Central Texas College',
    value: 'Central Texas College'
  },
  {
    id: '1103',
    label: 'Central University College',
    value: 'Central University College'
  },
  {
    id: '1104',
    label: 'Central University for Nationalities',
    value: 'Central University for Nationalities'
  },
  {
    id: '1105',
    label: 'Central University of Finance and Economics',
    value: 'Central University of Finance and Economics'
  },
  {
    id: '1106',
    label: 'Central University of Technology',
    value: 'Central University of Technology'
  },
  {
    id: '1107',
    label: 'Central Virginia Community College',
    value: 'Central Virginia Community College'
  },
  {
    id: '1108',
    label: 'Central Washington University',
    value: 'Central Washington University'
  },
  {
    id: '1109',
    label: 'Central Wyoming College',
    value: 'Central Wyoming College'
  },
  {
    id: '1110',
    label: 'Centre College',
    value: 'Centre College'
  },
  {
    id: '1111',
    label: "Centre National d'Etudes Agronomiques des Régions Chaudes",
    value: "Centre National d'Etudes Agronomiques des Régions Chaudes"
  },
  {
    id: '1112',
    label: "Centre Universitaire d'Oum El Bouaghi",
    value: "Centre Universitaire d'Oum El Bouaghi"
  },
  {
    id: '1113',
    label: 'Centre Universitaire de Jijel',
    value: 'Centre Universitaire de Jijel'
  },
  {
    id: '1114',
    label: 'Centre Universitaire de Tebessa',
    value: 'Centre Universitaire de Tebessa'
  },
  {
    id: '1115',
    label: 'Centre Universitaire de Technologie',
    value: 'Centre Universitaire de Technologie'
  },
  {
    id: '1116',
    label: 'Centre Universitaire de Tiaret',
    value: 'Centre Universitaire de Tiaret'
  },
  {
    id: '1117',
    label: "Centre d'Etudes Supérieures Industrielles Paris",
    value: "Centre d'Etudes Supérieures Industrielles Paris"
  },
  {
    id: '1118',
    label: "Centre d'Etudes Supérieures des Techniques Industrielles",
    value: "Centre d'Etudes Supérieures des Techniques Industrielles"
  },
  {
    id: '1119',
    label: 'Centre de Formation et de Perfectionnement des Journalistes',
    value: 'Centre de Formation et de Perfectionnement des Journalistes'
  },
  {
    id: '1120',
    label: 'Centro Escolar University',
    value: 'Centro Escolar University'
  },
  {
    id: '1121',
    label: 'Centro Regional Universitário de Espiríto Santo do Pinhal',
    value: 'Centro Regional Universitário de Espiríto Santo do Pinhal'
  },
  {
    id: '1122',
    label: 'Centro Universitario Ciudad Vieja',
    value: 'Centro Universitario Ciudad Vieja'
  },
  {
    id: '1123',
    label: 'Centro Universitario Ixtlahuaca',
    value: 'Centro Universitario Ixtlahuaca'
  },
  {
    id: '1124',
    label: 'Centro Universitario Villanueva',
    value: 'Centro Universitario Villanueva'
  },
  {
    id: '1125',
    label: 'Centro Universitario de Occidente',
    value: 'Centro Universitario de Occidente'
  },
  {
    id: '1126',
    label: 'Centro Universitário Barao de Maua',
    value: 'Centro Universitário Barao de Maua'
  },
  {
    id: '1127',
    label: 'Centro Universitário Claretiano',
    value: 'Centro Universitário Claretiano'
  },
  {
    id: '1128',
    label: 'Centro Universitário Monte Serrat',
    value: 'Centro Universitário Monte Serrat'
  },
  {
    id: '1129',
    label: 'Centro Universitário Newton Paiva',
    value: 'Centro Universitário Newton Paiva'
  },
  {
    id: '1130',
    label: 'Centro Universitário Plinio Leite',
    value: 'Centro Universitário Plinio Leite'
  },
  {
    id: '1131',
    label: 'Centro Universitário Senac',
    value: 'Centro Universitário Senac'
  },
  {
    id: '1132',
    label: 'Centro Universitário Serra dos Órgãos',
    value: 'Centro Universitário Serra dos Órgãos'
  },
  {
    id: '1133',
    label: 'Centro Universitário UniFatecie',
    value: 'Centro Universitário UniFatecie'
  },
  {
    id: '1134',
    label: 'Centro Universitário de Araraquara',
    value: 'Centro Universitário de Araraquara'
  },
  {
    id: '1135',
    label: 'Centro Universitário de Brasília, UNICEUB',
    value: 'Centro Universitário de Brasília, UNICEUB'
  },
  {
    id: '1136',
    label: 'Centro Universitário de João Pessoa',
    value: 'Centro Universitário de João Pessoa'
  },
  {
    id: '1137',
    label: 'Centro de Enseñanza Técnica Industrial',
    value: 'Centro de Enseñanza Técnica Industrial'
  },
  {
    id: '1138',
    label: 'Centro de Estudios Avanzados de Puerto Rico y el Caribe',
    value: 'Centro de Estudios Avanzados de Puerto Rico y el Caribe'
  },
  {
    id: '1139',
    label: 'Centro de Estudios Investigación y Tecnología (CEIT)',
    value: 'Centro de Estudios Investigación y Tecnología (CEIT)'
  },
  {
    id: '1140',
    label: 'Centro de Estudios Universitarios Monterrey',
    value: 'Centro de Estudios Universitarios Monterrey'
  },
  {
    id: '1141',
    label: 'Centro de Estudios Universitarios Xochicalco',
    value: 'Centro de Estudios Universitarios Xochicalco'
  },
  {
    id: '1142',
    label: 'Centro de Investigación en Química Aplicada',
    value: 'Centro de Investigación en Química Aplicada'
  },
  {
    id: '1143',
    label: 'Century College',
    value: 'Century College'
  },
  {
    id: '1144',
    label: 'Cerritos College',
    value: 'Cerritos College'
  },
  {
    id: '1145',
    label: 'Cerro Coso Community College',
    value: 'Cerro Coso Community College'
  },
  {
    id: '1146',
    label: 'Cevro Institut College',
    value: 'Cevro Institut College'
  },
  {
    id: '1147',
    label: 'Ch. Charan Singh University',
    value: 'Ch. Charan Singh University'
  },
  {
    id: '1148',
    label: 'Chabot College',
    value: 'Chabot College'
  },
  {
    id: '1149',
    label: 'Chabot-Las Positas Community College District',
    value: 'Chabot-Las Positas Community College District'
  },
  {
    id: '1150',
    label: 'Chadron State College',
    value: 'Chadron State College'
  },
  {
    id: '1151',
    label: 'Chaffey College',
    value: 'Chaffey College'
  },
  {
    id: '1152',
    label: 'Chalmers University of Technology',
    value: 'Chalmers University of Technology'
  },
  {
    id: '1153',
    label: 'Chaminade University of Honolulu',
    value: 'Chaminade University of Honolulu'
  },
  {
    id: '1154',
    label: 'Champlain College',
    value: 'Champlain College'
  },
  {
    id: '1155',
    label: 'Chamreun University of Poly Technology',
    value: 'Chamreun University of Poly Technology'
  },
  {
    id: '1156',
    label: 'Chandil Polytechnic School',
    value: 'Chandil Polytechnic School'
  },
  {
    id: '1157',
    label: 'Chandler-Gilbert Community College',
    value: 'Chandler-Gilbert Community College'
  },
  {
    id: '1158',
    label: 'Chandra Shekhar Azad University of Agriculture and Technology',
    value: 'Chandra Shekhar Azad University of Agriculture and Technology'
  },
  {
    id: '1159',
    label: 'Chang Gung University',
    value: 'Chang Gung University'
  },
  {
    id: '1160',
    label: 'Chang Jung University',
    value: 'Chang Jung University'
  },
  {
    id: '1161',
    label: 'Changchun Teachers College',
    value: 'Changchun Teachers College'
  },
  {
    id: '1162',
    label: 'Changchun University of Science and Technology',
    value: 'Changchun University of Science and Technology'
  },
  {
    id: '1163',
    label: 'Changchun University of Technology',
    value: 'Changchun University of Technology'
  },
  {
    id: '1164',
    label: 'Changsha Railway University',
    value: 'Changsha Railway University'
  },
  {
    id: '1165',
    label: 'Changsha University of Electric Power',
    value: 'Changsha University of Electric Power'
  },
  {
    id: '1166',
    label: 'Changshin University',
    value: 'Changshin University'
  },
  {
    id: '1167',
    label: 'Changwon National University',
    value: 'Changwon National University'
  },
  {
    id: '1168',
    label: 'Chao Yang University of Science and Technology',
    value: 'Chao Yang University of Science and Technology'
  },
  {
    id: '1169',
    label: 'Chaopraya University',
    value: 'Chaopraya University'
  },
  {
    id: '1170',
    label: 'Chapman University',
    value: 'Chapman University'
  },
  {
    id: '1171',
    label: 'Charisma University',
    value: 'Charisma University'
  },
  {
    id: '1172',
    label: 'Charles Darwin University',
    value: 'Charles Darwin University'
  },
  {
    id: '1173',
    label: 'Charles Sturt University',
    value: 'Charles Sturt University'
  },
  {
    id: '1174',
    label: 'Charles University Prague',
    value: 'Charles University Prague'
  },
  {
    id: '1175',
    label: 'Charotar University of Science and Technology',
    value: 'Charotar University of Science and Technology'
  },
  {
    id: '1176',
    label: 'Chatfield College',
    value: 'Chatfield College'
  },
  {
    id: '1177',
    label: 'Chatham College',
    value: 'Chatham College'
  },
  {
    id: '1178',
    label: 'Chattahoochee Technical College',
    value: 'Chattahoochee Technical College'
  },
  {
    id: '1179',
    label: 'Chattahoochee Valley Community College',
    value: 'Chattahoochee Valley Community College'
  },
  {
    id: '1180',
    label: 'Chattanooga State Community College',
    value: 'Chattanooga State Community College'
  },
  {
    id: '1181',
    label: 'Chaudhary Charan Singh University',
    value: 'Chaudhary Charan Singh University'
  },
  {
    id: '1182',
    label: 'Cheju National University',
    value: 'Cheju National University'
  },
  {
    id: '1183',
    label: 'Cheju National University of Education',
    value: 'Cheju National University of Education'
  },
  {
    id: '1184',
    label: 'Cheljabinsk State Institute of Teacher Training',
    value: 'Cheljabinsk State Institute of Teacher Training'
  },
  {
    id: '1185',
    label: 'Cheljabinsk State University',
    value: 'Cheljabinsk State University'
  },
  {
    id: '1186',
    label: 'Cheljabinsk University of Agricultural Engineering',
    value: 'Cheljabinsk University of Agricultural Engineering'
  },
  {
    id: '1187',
    label: 'Chelsea College of Art and Design',
    value: 'Chelsea College of Art and Design'
  },
  {
    id: '1188',
    label: 'Chemeketa Community College',
    value: 'Chemeketa Community College'
  },
  {
    id: '1189',
    label: 'Chengdu Institute of Sichuan International Studies University',
    value: 'Chengdu Institute of Sichuan International Studies University'
  },
  {
    id: '1190',
    label: 'Chengdu University',
    value: 'Chengdu University'
  },
  {
    id: '1191',
    label: 'Chengdu University of Technology',
    value: 'Chengdu University of Technology'
  },
  {
    id: '1192',
    label: 'Chengdu University of Traditional Chinese Medicine',
    value: 'Chengdu University of Traditional Chinese Medicine'
  },
  {
    id: '1193',
    label: 'Chennai Institute of Technology',
    value: 'Chennai Institute of Technology'
  },
  {
    id: '1194',
    label: 'Cheongju National University of Education',
    value: 'Cheongju National University of Education'
  },
  {
    id: '1195',
    label: 'Chernivci National University',
    value: 'Chernivci National University'
  },
  {
    id: '1196',
    label: 'Chesapeake College',
    value: 'Chesapeake College'
  },
  {
    id: '1197',
    label: 'Cheyney University',
    value: 'Cheyney University'
  },
  {
    id: '1198',
    label: 'Chhatrapati Shahu Ji Maharaj University',
    value: 'Chhatrapati Shahu Ji Maharaj University'
  },
  {
    id: '1199',
    label: 'Chia Nana College of Pharmacy and Science',
    value: 'Chia Nana College of Pharmacy and Science'
  },
  {
    id: '1200',
    label: 'Chiang Mai Rajabhat University',
    value: 'Chiang Mai Rajabhat University'
  },
  {
    id: '1201',
    label: 'Chiang Mai University',
    value: 'Chiang Mai University'
  },
  {
    id: '1202',
    label: 'Chiang Mai Vocational College',
    value: 'Chiang Mai Vocational College'
  },
  {
    id: '1203',
    label: 'Chiba Institute of Technology',
    value: 'Chiba Institute of Technology'
  },
  {
    id: '1204',
    label: 'Chiba Keizai University',
    value: 'Chiba Keizai University'
  },
  {
    id: '1205',
    label: 'Chiba University',
    value: 'Chiba University'
  },
  {
    id: '1206',
    label: 'Chiba University',
    value: 'Chiba University'
  },
  {
    id: '1207',
    label: 'Chiba University of Commerce',
    value: 'Chiba University of Commerce'
  },
  {
    id: '1208',
    label: 'Chicago School of Professional Psychology',
    value: 'Chicago School of Professional Psychology'
  },
  {
    id: '1209',
    label: 'Chicago State University',
    value: 'Chicago State University'
  },
  {
    id: '1210',
    label: 'Chief Dull Knife College',
    value: 'Chief Dull Knife College'
  },
  {
    id: '1211',
    label: 'Chikushi Jogakuen University',
    value: 'Chikushi Jogakuen University'
  },
  {
    id: '1212',
    label: 'China Academy of Art',
    value: 'China Academy of Art'
  },
  {
    id: '1213',
    label: 'China Agricultural University',
    value: 'China Agricultural University'
  },
  {
    id: '1214',
    label: 'China Agriculture University East',
    value: 'China Agriculture University East'
  },
  {
    id: '1215',
    label: 'China Foreign Affairs University',
    value: 'China Foreign Affairs University'
  },
  {
    id: '1216',
    label: 'China Medical College',
    value: 'China Medical College'
  },
  {
    id: '1217',
    label: 'China Medical University Shenyang',
    value: 'China Medical University Shenyang'
  },
  {
    id: '1218',
    label: 'China Pharmaceutical University Nanjing',
    value: 'China Pharmaceutical University Nanjing'
  },
  {
    id: '1219',
    label: 'China Three Gorges University',
    value: 'China Three Gorges University'
  },
  {
    id: '1220',
    label: 'China USA Business University',
    value: 'China USA Business University'
  },
  {
    id: '1221',
    label: 'China University of Geoscience Beijing',
    value: 'China University of Geoscience Beijing'
  },
  {
    id: '1222',
    label: 'China University of Geosciences Wuhan',
    value: 'China University of Geosciences Wuhan'
  },
  {
    id: '1223',
    label: 'China University of Mining Technology - Beijing',
    value: 'China University of Mining Technology - Beijing'
  },
  {
    id: '1224',
    label: 'China University of Mining Technology - Xuzhou',
    value: 'China University of Mining Technology - Xuzhou'
  },
  {
    id: '1225',
    label: 'China University of Political Science and Law',
    value: 'China University of Political Science and Law'
  },
  {
    id: '1226',
    label: 'China youth college for political science',
    value: 'China youth college for political science'
  },
  {
    id: '1227',
    label: 'Chinese Culture University',
    value: 'Chinese Culture University'
  },
  {
    id: '1228',
    label: "Chinese People's Public Security University",
    value: "Chinese People's Public Security University"
  },
  {
    id: '1229',
    label: 'Ching Kuo Institue of Management & Health',
    value: 'Ching Kuo Institue of Management & Health'
  },
  {
    id: '1230',
    label: 'Chinhoyi University of Technology',
    value: 'Chinhoyi University of Technology'
  },
  {
    id: '1231',
    label: 'Chinju National University',
    value: 'Chinju National University'
  },
  {
    id: '1232',
    label: 'Chinju National University of Education',
    value: 'Chinju National University of Education'
  },
  {
    id: '1233',
    label: 'Chippewa Valley Technical College',
    value: 'Chippewa Valley Technical College'
  },
  {
    id: '1234',
    label: 'Chittagong University of Engineering and Technology',
    value: 'Chittagong University of Engineering and Technology'
  },
  {
    id: '1235',
    label: 'Chodang University',
    value: 'Chodang University'
  },
  {
    id: '1236',
    label: 'Chonbuk National University',
    value: 'Chonbuk National University'
  },
  {
    id: '1237',
    label: 'Chonbuk Sanup University of Technology (Howon University)',
    value: 'Chonbuk Sanup University of Technology (Howon University)'
  },
  {
    id: '1238',
    label: 'Chongju National University of Education',
    value: 'Chongju National University of Education'
  },
  {
    id: '1239',
    label: 'Chongju University',
    value: 'Chongju University'
  },
  {
    id: '1240',
    label: 'Chongqing Education College',
    value: 'Chongqing Education College'
  },
  {
    id: '1241',
    label: 'Chongqing Medical University',
    value: 'Chongqing Medical University'
  },
  {
    id: '1242',
    label: 'Chongqing Normal University',
    value: 'Chongqing Normal University'
  },
  {
    id: '1243',
    label: 'Chongqing Normal University Foreign Trade and Business College',
    value: 'Chongqing Normal University Foreign Trade and Business College'
  },
  {
    id: '1244',
    label: 'Chongqing Technology and Business University',
    value: 'Chongqing Technology and Business University'
  },
  {
    id: '1245',
    label: 'Chongqing Telecommunication College',
    value: 'Chongqing Telecommunication College'
  },
  {
    id: '1246',
    label: 'Chongqing Three Gorges University',
    value: 'Chongqing Three Gorges University'
  },
  {
    id: '1247',
    label: 'Chongqing University',
    value: 'Chongqing University'
  },
  {
    id: '1248',
    label: 'Chongqing University of Communications',
    value: 'Chongqing University of Communications'
  },
  {
    id: '1249',
    label: 'Chongqing University of Post and Telecommunications',
    value: 'Chongqing University of Post and Telecommunications'
  },
  {
    id: '1250',
    label: 'Chongqing University of Science and Technology',
    value: 'Chongqing University of Science and Technology'
  },
  {
    id: '1251',
    label: 'Chongqing University of Technology',
    value: 'Chongqing University of Technology'
  },
  {
    id: '1252',
    label: 'Chongqing Vocational College of Public Transportation',
    value: 'Chongqing Vocational College of Public Transportation'
  },
  {
    id: '1253',
    label: 'Chongqing Wenli University',
    value: 'Chongqing Wenli University'
  },
  {
    id: '1254',
    label: 'Chongshin University',
    value: 'Chongshin University'
  },
  {
    id: '1255',
    label: 'Chonju National University of Education',
    value: 'Chonju National University of Education'
  },
  {
    id: '1256',
    label: 'Chonnam National University',
    value: 'Chonnam National University'
  },
  {
    id: '1257',
    label: 'Chosun University',
    value: 'Chosun University'
  },
  {
    id: '1258',
    label: 'Christ Apostolic University College',
    value: 'Christ Apostolic University College'
  },
  {
    id: '1259',
    label: 'Christ University',
    value: 'Christ University'
  },
  {
    id: '1260',
    label: 'Christchurch Polytechnic Institute of Technology',
    value: 'Christchurch Polytechnic Institute of Technology'
  },
  {
    id: '1261',
    label: 'Christelijke Hogeschool Windesheim',
    value: 'Christelijke Hogeschool Windesheim'
  },
  {
    id: '1262',
    label: 'Christian Brothers University',
    value: 'Christian Brothers University'
  },
  {
    id: '1263',
    label: 'Christian Theological Academy in Warszaw',
    value: 'Christian Theological Academy in Warszaw'
  },
  {
    id: '1264',
    label: 'Christian Theological Seminary',
    value: 'Christian Theological Seminary'
  },
  {
    id: '1265',
    label: 'Christian-Albrechts-Universität Kiel',
    value: 'Christian-Albrechts-Universität Kiel'
  },
  {
    id: '1266',
    label: 'Christopher Newport University',
    value: 'Christopher Newport University'
  },
  {
    id: '1267',
    label: "Chubu Gakuin University & Chubu Women's College",
    value: "Chubu Gakuin University & Chubu Women's College"
  },
  {
    id: '1268',
    label: 'Chubu University',
    value: 'Chubu University'
  },
  {
    id: '1269',
    label: 'Chugye University for the Arts',
    value: 'Chugye University for the Arts'
  },
  {
    id: '1270',
    label: 'Chuka University',
    value: 'Chuka University'
  },
  {
    id: '1271',
    label: 'Chukyo Gakuin University',
    value: 'Chukyo Gakuin University'
  },
  {
    id: '1272',
    label: 'Chukyo University',
    value: 'Chukyo University'
  },
  {
    id: '1273',
    label: "Chukyo Women's University",
    value: "Chukyo Women's University"
  },
  {
    id: '1274',
    label: 'Chulalongkorn University',
    value: 'Chulalongkorn University'
  },
  {
    id: '1275',
    label: 'Chuncheon National University of Education',
    value: 'Chuncheon National University of Education'
  },
  {
    id: '1276',
    label: 'Chunchon National University of Education',
    value: 'Chunchon National University of Education'
  },
  {
    id: '1277',
    label: 'Chung Hua University',
    value: 'Chung Hua University'
  },
  {
    id: '1278',
    label: 'Chung Shan Medical and Dental College',
    value: 'Chung Shan Medical and Dental College'
  },
  {
    id: '1279',
    label: 'Chung Yuan Christian University',
    value: 'Chung Yuan Christian University'
  },
  {
    id: '1280',
    label: 'Chung-Ang University',
    value: 'Chung-Ang University'
  },
  {
    id: '1281',
    label: 'Chung-Ju National University',
    value: 'Chung-Ju National University'
  },
  {
    id: '1282',
    label: 'Chungbuk National University',
    value: 'Chungbuk National University'
  },
  {
    id: '1283',
    label: 'Chungnam National University',
    value: 'Chungnam National University'
  },
  {
    id: '1284',
    label: 'Chungwoon University',
    value: 'Chungwoon University'
  },
  {
    id: '1285',
    label: 'Chuo Gakuin University',
    value: 'Chuo Gakuin University'
  },
  {
    id: '1286',
    label: 'Chuo University',
    value: 'Chuo University'
  },
  {
    id: '1287',
    label: 'Chuvash State University',
    value: 'Chuvash State University'
  },
  {
    id: '1288',
    label: 'Cihan University',
    value: 'Cihan University'
  },
  {
    id: '1289',
    label: 'Cincinnati State Technical and Community College',
    value: 'Cincinnati State Technical and Community College'
  },
  {
    id: '1290',
    label: 'Cisco College',
    value: 'Cisco College'
  },
  {
    id: '1291',
    label: 'Citrus College',
    value: 'Citrus College'
  },
  {
    id: '1292',
    label: 'City College of San Francisco',
    value: 'City College of San Francisco'
  },
  {
    id: '1293',
    label: 'City Colleges of Chicago',
    value: 'City Colleges of Chicago'
  },
  {
    id: '1294',
    label: 'City University',
    value: 'City University'
  },
  {
    id: '1295',
    label: 'City University',
    value: 'City University'
  },
  {
    id: '1296',
    label: 'City University',
    value: 'City University'
  },
  {
    id: '1297',
    label: 'City University Athens',
    value: 'City University Athens'
  },
  {
    id: '1298',
    label: 'City University College of Science and Technology',
    value: 'City University College of Science and Technology'
  },
  {
    id: '1299',
    label: 'City University Programs in Bulgaria',
    value: 'City University Programs in Bulgaria'
  },
  {
    id: '1300',
    label: 'City University of Hong Kong',
    value: 'City University of Hong Kong'
  },
  {
    id: '1301',
    label: 'City University of New York',
    value: 'City University of New York'
  },
  {
    id: '1302',
    label: 'City University of Science and Information Technology',
    value: 'City University of Science and Information Technology'
  },
  {
    id: '1303',
    label: 'Civil Aviation University of China',
    value: 'Civil Aviation University of China'
  },
  {
    id: '1304',
    label: 'Clackamas Community College',
    value: 'Clackamas Community College'
  },
  {
    id: '1305',
    label: 'Claflin University',
    value: 'Claflin University'
  },
  {
    id: '1306',
    label: 'Claremont Colleges',
    value: 'Claremont Colleges'
  },
  {
    id: '1307',
    label: 'Claremont Graduate School',
    value: 'Claremont Graduate School'
  },
  {
    id: '1308',
    label: 'Claremont Graduate University',
    value: 'Claremont Graduate University'
  },
  {
    id: '1309',
    label: 'Claremont McKenna College',
    value: 'Claremont McKenna College'
  },
  {
    id: '1310',
    label: 'Claremont School of Theology',
    value: 'Claremont School of Theology'
  },
  {
    id: '1311',
    label: 'Clarendon College',
    value: 'Clarendon College'
  },
  {
    id: '1312',
    label: 'Clarion University of Pennsylvania',
    value: 'Clarion University of Pennsylvania'
  },
  {
    id: '1313',
    label: 'Clark Atlanta University',
    value: 'Clark Atlanta University'
  },
  {
    id: '1314',
    label: 'Clark College',
    value: 'Clark College'
  },
  {
    id: '1315',
    label: 'Clark State Community College',
    value: 'Clark State Community College'
  },
  {
    id: '1316',
    label: 'Clark University',
    value: 'Clark University'
  },
  {
    id: '1317',
    label: 'Clarke College',
    value: 'Clarke College'
  },
  {
    id: '1318',
    label: 'Clarkson University',
    value: 'Clarkson University'
  },
  {
    id: '1319',
    label: 'Clatsop Community College',
    value: 'Clatsop Community College'
  },
  {
    id: '1320',
    label: 'Clayton State College',
    value: 'Clayton State College'
  },
  {
    id: '1321',
    label: 'Clemson University',
    value: 'Clemson University'
  },
  {
    id: '1322',
    label: 'Cleveland Community College',
    value: 'Cleveland Community College'
  },
  {
    id: '1323',
    label: 'Cleveland State Community College',
    value: 'Cleveland State Community College'
  },
  {
    id: '1324',
    label: 'Cleveland State University',
    value: 'Cleveland State University'
  },
  {
    id: '1325',
    label: 'Clinch Valley College',
    value: 'Clinch Valley College'
  },
  {
    id: '1326',
    label: 'Clinton College',
    value: 'Clinton College'
  },
  {
    id: '1327',
    label: 'Clinton Community College',
    value: 'Clinton Community College'
  },
  {
    id: '1328',
    label: 'Cloud County Community College',
    value: 'Cloud County Community College'
  },
  {
    id: '1329',
    label: 'Clover Park Technical College',
    value: 'Clover Park Technical College'
  },
  {
    id: '1330',
    label: 'Clovis Community College',
    value: 'Clovis Community College'
  },
  {
    id: '1331',
    label: 'Coahoma Community College',
    value: 'Coahoma Community College'
  },
  {
    id: '1332',
    label: 'Coast Colleges',
    value: 'Coast Colleges'
  },
  {
    id: '1333',
    label: 'Coastal Alabama Community College',
    value: 'Coastal Alabama Community College'
  },
  {
    id: '1334',
    label: 'Coastal Bend College',
    value: 'Coastal Bend College'
  },
  {
    id: '1335',
    label: 'Coastal Carolina Community College',
    value: 'Coastal Carolina Community College'
  },
  {
    id: '1336',
    label: 'Coastal Carolina University',
    value: 'Coastal Carolina University'
  },
  {
    id: '1337',
    label: 'Coastal Pines Technical College',
    value: 'Coastal Pines Technical College'
  },
  {
    id: '1338',
    label: 'Coastline Community College',
    value: 'Coastline Community College'
  },
  {
    id: '1339',
    label: 'Cochin University of Science and Technology',
    value: 'Cochin University of Science and Technology'
  },
  {
    id: '1340',
    label: 'Cochise College',
    value: 'Cochise College'
  },
  {
    id: '1341',
    label: 'Coconino Community College',
    value: 'Coconino Community College'
  },
  {
    id: '1342',
    label: 'Coe College',
    value: 'Coe College'
  },
  {
    id: '1343',
    label: 'Coffeyville Community College',
    value: 'Coffeyville Community College'
  },
  {
    id: '1344',
    label: 'Coker College',
    value: 'Coker College'
  },
  {
    id: '1345',
    label: 'Colby College',
    value: 'Colby College'
  },
  {
    id: '1346',
    label: 'Colby Community College',
    value: 'Colby Community College'
  },
  {
    id: '1347',
    label: 'Cold Spring Harbor Laboratory',
    value: 'Cold Spring Harbor Laboratory'
  },
  {
    id: '1348',
    label: 'Colegio de Estudios Superiores de Administración (CESA)',
    value: 'Colegio de Estudios Superiores de Administración (CESA)'
  },
  {
    id: '1349',
    label: 'Colegio de San Juan de Letran',
    value: 'Colegio de San Juan de Letran'
  },
  {
    id: '1350',
    label: 'Colgate University',
    value: 'Colgate University'
  },
  {
    id: '1351',
    label: 'College in Sládkovičovo',
    value: 'College in Sládkovičovo'
  },
  {
    id: '1352',
    label: 'College of Alameda',
    value: 'College of Alameda'
  },
  {
    id: '1353',
    label: 'College of Bois-de-Boulogne',
    value: 'College of Bois-de-Boulogne'
  },
  {
    id: '1354',
    label: 'College of Business Management (CBM)',
    value: 'College of Business Management (CBM)'
  },
  {
    id: '1355',
    label: 'College of Busniess Admnistration',
    value: 'College of Busniess Admnistration'
  },
  {
    id: '1356',
    label: 'College of Charleston',
    value: 'College of Charleston'
  },
  {
    id: '1357',
    label: 'College of Computer Science in Lodz',
    value: 'College of Computer Science in Lodz'
  },
  {
    id: '1358',
    label: 'College of DuPage',
    value: 'College of DuPage'
  },
  {
    id: '1359',
    label: 'College of DuPage',
    value: 'College of DuPage'
  },
  {
    id: '1360',
    label: 'College of Dunaujvaros',
    value: 'College of Dunaujvaros'
  },
  {
    id: '1361',
    label: 'College of Eastern Utah',
    value: 'College of Eastern Utah'
  },
  {
    id: '1362',
    label: 'College of Education Ikere',
    value: 'College of Education Ikere'
  },
  {
    id: '1363',
    label: 'College of Education Oju',
    value: 'College of Education Oju'
  },
  {
    id: '1364',
    label: 'College of Europe',
    value: 'College of Europe'
  },
  {
    id: '1365',
    label: 'College of Lake County',
    value: 'College of Lake County'
  },
  {
    id: '1366',
    label: 'College of Management',
    value: 'College of Management'
  },
  {
    id: '1367',
    label: 'College of Marin',
    value: 'College of Marin'
  },
  {
    id: '1368',
    label: 'College of Mount Saint Vincent',
    value: 'College of Mount Saint Vincent'
  },
  {
    id: '1369',
    label: 'College of New Caledonia',
    value: 'College of New Caledonia'
  },
  {
    id: '1370',
    label: 'College of New Rochelle',
    value: 'College of New Rochelle'
  },
  {
    id: '1371',
    label: 'College of Nursing and Allied Health Scinces',
    value: 'College of Nursing and Allied Health Scinces'
  },
  {
    id: '1372',
    label: 'College of Saint Benedict',
    value: 'College of Saint Benedict'
  },
  {
    id: '1373',
    label: 'College of Saint Catherine',
    value: 'College of Saint Catherine'
  },
  {
    id: '1374',
    label: 'College of Saint Rose',
    value: 'College of Saint Rose'
  },
  {
    id: '1375',
    label: 'College of San Mateo',
    value: 'College of San Mateo'
  },
  {
    id: '1376',
    label: 'College of Science, Baghdad University',
    value: 'College of Science, Baghdad University'
  },
  {
    id: '1377',
    label: 'College of Southern Idaho',
    value: 'College of Southern Idaho'
  },
  {
    id: '1378',
    label: 'College of Southern Maryland',
    value: 'College of Southern Maryland'
  },
  {
    id: '1379',
    label: 'College of Southern Nevada',
    value: 'College of Southern Nevada'
  },
  {
    id: '1380',
    label: 'College of St. Francis',
    value: 'College of St. Francis'
  },
  {
    id: '1381',
    label: 'College of St. Scholastica',
    value: 'College of St. Scholastica'
  },
  {
    id: '1382',
    label: 'College of Technology at Abha',
    value: 'College of Technology at Abha'
  },
  {
    id: '1383',
    label: 'College of Technology at Dammam',
    value: 'College of Technology at Dammam'
  },
  {
    id: '1384',
    label: 'College of Technology at Jazan',
    value: 'College of Technology at Jazan'
  },
  {
    id: '1385',
    label: 'College of Technology at Jeddah',
    value: 'College of Technology at Jeddah'
  },
  {
    id: '1386',
    label: 'College of Technology at Kharj',
    value: 'College of Technology at Kharj'
  },
  {
    id: '1387',
    label: 'College of Technology at Riyadh',
    value: 'College of Technology at Riyadh'
  },
  {
    id: '1388',
    label: 'College of Telecommunication & Information',
    value: 'College of Telecommunication & Information'
  },
  {
    id: '1389',
    label: 'College of Western Idaho',
    value: 'College of Western Idaho'
  },
  {
    id: '1390',
    label: 'College of William and Mary',
    value: 'College of William and Mary'
  },
  {
    id: '1391',
    label: 'College of Wooster',
    value: 'College of Wooster'
  },
  {
    id: '1392',
    label: 'College of the Albemarle',
    value: 'College of the Albemarle'
  },
  {
    id: '1393',
    label: 'College of the Atlantic',
    value: 'College of the Atlantic'
  },
  {
    id: '1394',
    label: 'College of the Canyons',
    value: 'College of the Canyons'
  },
  {
    id: '1395',
    label: 'College of the Desert',
    value: 'College of the Desert'
  },
  {
    id: '1396',
    label: 'College of the Holy Cross',
    value: 'College of the Holy Cross'
  },
  {
    id: '1397',
    label: 'College of the Holy Spirit',
    value: 'College of the Holy Spirit'
  },
  {
    id: '1398',
    label: 'College of the Mainland',
    value: 'College of the Mainland'
  },
  {
    id: '1399',
    label: 'College of the Ouachitas',
    value: 'College of the Ouachitas'
  },
  {
    id: '1400',
    label: 'College of the Redwoods',
    value: 'College of the Redwoods'
  },
  {
    id: '1401',
    label: 'College of the Rockies',
    value: 'College of the Rockies'
  },
  {
    id: '1402',
    label: 'College of the Sequoias',
    value: 'College of the Sequoias'
  },
  {
    id: '1403',
    label: 'College of the Siskiyous',
    value: 'College of the Siskiyous'
  },
  {
    id: '1404',
    label: 'Collegium Civitas',
    value: 'Collegium Civitas'
  },
  {
    id: '1405',
    label: 'Collin County Community College District',
    value: 'Collin County Community College District'
  },
  {
    id: '1406',
    label: 'Collège Boréal',
    value: 'Collège Boréal'
  },
  {
    id: '1407',
    label: 'Collège Universel Gatineau',
    value: 'Collège Universel Gatineau'
  },
  {
    id: '1408',
    label: 'Collège de France',
    value: 'Collège de France'
  },
  {
    id: '1409',
    label: 'Cologne Business School',
    value: 'Cologne Business School'
  },
  {
    id: '1410',
    label: 'Colorado Christian University',
    value: 'Colorado Christian University'
  },
  {
    id: '1411',
    label: 'Colorado College',
    value: 'Colorado College'
  },
  {
    id: '1412',
    label: 'Colorado Community College System',
    value: 'Colorado Community College System'
  },
  {
    id: '1413',
    label: 'Colorado Mesa University',
    value: 'Colorado Mesa University'
  },
  {
    id: '1414',
    label: 'Colorado Northwestern Community College',
    value: 'Colorado Northwestern Community College'
  },
  {
    id: '1415',
    label: 'Colorado School of Mines',
    value: 'Colorado School of Mines'
  },
  {
    id: '1416',
    label: 'Colorado State University',
    value: 'Colorado State University'
  },
  {
    id: '1417',
    label: 'Colorado State University - Global Campus',
    value: 'Colorado State University - Global Campus'
  },
  {
    id: '1418',
    label: 'Colorado State University-Pueblo',
    value: 'Colorado State University-Pueblo'
  },
  {
    id: '1419',
    label: 'Colorado Technical University',
    value: 'Colorado Technical University'
  },
  {
    id: '1420',
    label: 'Columbia Basin College',
    value: 'Columbia Basin College'
  },
  {
    id: '1421',
    label: 'Columbia College',
    value: 'Columbia College'
  },
  {
    id: '1422',
    label: 'Columbia College (CA)',
    value: 'Columbia College (CA)'
  },
  {
    id: '1423',
    label: 'Columbia College (MO)',
    value: 'Columbia College (MO)'
  },
  {
    id: '1424',
    label: 'Columbia College (SC)',
    value: 'Columbia College (SC)'
  },
  {
    id: '1425',
    label: 'Columbia College Chicago',
    value: 'Columbia College Chicago'
  },
  {
    id: '1426',
    label: 'Columbia Gorge Community College',
    value: 'Columbia Gorge Community College'
  },
  {
    id: '1427',
    label: 'Columbia Southern University',
    value: 'Columbia Southern University'
  },
  {
    id: '1428',
    label: 'Columbia State Community College',
    value: 'Columbia State Community College'
  },
  {
    id: '1429',
    label: 'Columbia Union College',
    value: 'Columbia Union College'
  },
  {
    id: '1430',
    label: 'Columbia University',
    value: 'Columbia University'
  },
  {
    id: '1431',
    label: 'Columbia-Greene Community College',
    value: 'Columbia-Greene Community College'
  },
  {
    id: '1432',
    label: 'Columbus State Community College',
    value: 'Columbus State Community College'
  },
  {
    id: '1433',
    label: 'Columbus State University',
    value: 'Columbus State University'
  },
  {
    id: '1434',
    label: 'Columbus Technical College',
    value: 'Columbus Technical College'
  },
  {
    id: '1435',
    label: 'Columbus University',
    value: 'Columbus University'
  },
  {
    id: '1436',
    label: 'Comenius University in Bratislava',
    value: 'Comenius University in Bratislava'
  },
  {
    id: '1437',
    label: 'Comilla University',
    value: 'Comilla University'
  },
  {
    id: '1438',
    label: 'Communication University of China',
    value: 'Communication University of China'
  },
  {
    id: '1439',
    label: 'Community College System of New Hampshire',
    value: 'Community College System of New Hampshire'
  },
  {
    id: '1440',
    label: 'Community College of Allegheny County',
    value: 'Community College of Allegheny County'
  },
  {
    id: '1441',
    label: 'Community College of Aurora',
    value: 'Community College of Aurora'
  },
  {
    id: '1442',
    label: 'Community College of Baltimore County',
    value: 'Community College of Baltimore County'
  },
  {
    id: '1443',
    label: 'Community College of Beaver County',
    value: 'Community College of Beaver County'
  },
  {
    id: '1444',
    label: 'Community College of Denver',
    value: 'Community College of Denver'
  },
  {
    id: '1445',
    label: 'Community College of Philadelphia',
    value: 'Community College of Philadelphia'
  },
  {
    id: '1446',
    label: 'Community College of Rhode Island',
    value: 'Community College of Rhode Island'
  },
  {
    id: '1447',
    label: 'Community College of Vermont',
    value: 'Community College of Vermont'
  },
  {
    id: '1448',
    label: 'Comrat State University',
    value: 'Comrat State University'
  },
  {
    id: '1449',
    label: 'Concordia College - Ann Arbor',
    value: 'Concordia College - Ann Arbor'
  },
  {
    id: '1450',
    label: 'Concordia College - Moorhead',
    value: 'Concordia College - Moorhead'
  },
  {
    id: '1451',
    label: 'Concordia College - Seward',
    value: 'Concordia College - Seward'
  },
  {
    id: '1452',
    label: 'Concordia College - St. Paul',
    value: 'Concordia College - St. Paul'
  },
  {
    id: '1453',
    label: 'Concordia University',
    value: 'Concordia University'
  },
  {
    id: '1454',
    label: 'Concordia University College of Alberta',
    value: 'Concordia University College of Alberta'
  },
  {
    id: '1455',
    label: 'Concordia University Wisconsin',
    value: 'Concordia University Wisconsin'
  },
  {
    id: '1456',
    label: 'Conestoga College',
    value: 'Conestoga College'
  },
  {
    id: '1457',
    label: 'Connecticut College',
    value: 'Connecticut College'
  },
  {
    id: '1458',
    label: 'Connecticut State University System',
    value: 'Connecticut State University System'
  },
  {
    id: '1459',
    label: 'Connors State College',
    value: 'Connors State College'
  },
  {
    id: '1460',
    label: 'Conservatoire National des Arts et Métiers',
    value: 'Conservatoire National des Arts et Métiers'
  },
  {
    id: '1461',
    label: 'Conservatorio del Tolima',
    value: 'Conservatorio del Tolima'
  },
  {
    id: '1462',
    label: 'Constantin Brancoveanu University Pitesti',
    value: 'Constantin Brancoveanu University Pitesti'
  },
  {
    id: '1463',
    label: 'Continental Theological Seminary',
    value: 'Continental Theological Seminary'
  },
  {
    id: '1464',
    label: 'Contra Costa College',
    value: 'Contra Costa College'
  },
  {
    id: '1465',
    label: 'Contra Costa Community College District',
    value: 'Contra Costa Community College District'
  },
  {
    id: '1466',
    label: 'Cooper Union for the Advancement of Science and Art',
    value: 'Cooper Union for the Advancement of Science and Art'
  },
  {
    id: '1467',
    label: 'Copenhagen Business School',
    value: 'Copenhagen Business School'
  },
  {
    id: '1468',
    label: 'Copenhagen University',
    value: 'Copenhagen University'
  },
  {
    id: '1469',
    label: 'Copiah-Lincoln Community College',
    value: 'Copiah-Lincoln Community College'
  },
  {
    id: '1470',
    label: 'Copper Mountain Community College',
    value: 'Copper Mountain Community College'
  },
  {
    id: '1471',
    label: 'Copperbelt University',
    value: 'Copperbelt University'
  },
  {
    id: '1472',
    label: 'Coppin State University',
    value: 'Coppin State University'
  },
  {
    id: '1473',
    label: 'Cork Institute of Technology',
    value: 'Cork Institute of Technology'
  },
  {
    id: '1474',
    label: 'Cornell College',
    value: 'Cornell College'
  },
  {
    id: '1475',
    label: 'Cornell College',
    value: 'Cornell College'
  },
  {
    id: '1476',
    label: 'Cornell University',
    value: 'Cornell University'
  },
  {
    id: '1477',
    label: 'Corning Community College',
    value: 'Corning Community College'
  },
  {
    id: '1478',
    label: 'Corporación Educativa Mayor del Desarrollo "Simón Bolivar"',
    value: 'Corporación Educativa Mayor del Desarrollo "Simón Bolivar"'
  },
  {
    id: '1479',
    label: 'Corporación Universitaria Tecnológica de Bolivar',
    value: 'Corporación Universitaria Tecnológica de Bolivar'
  },
  {
    id: '1480',
    label: 'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)',
    value: 'Corporación Universitaria de Ibagué (CORUNIVERSITARIA)'
  },
  {
    id: '1481',
    label: 'Corporación Universitaria de Santander (UDES)',
    value: 'Corporación Universitaria de Santander (UDES)'
  },
  {
    id: '1482',
    label: 'Corporación Universitaria de la Costa (UNICOSTA)',
    value: 'Corporación Universitaria de la Costa (UNICOSTA)'
  },
  {
    id: '1483',
    label: 'Corvinus University of Budapest',
    value: 'Corvinus University of Budapest'
  },
  {
    id: '1484',
    label: 'Cossatot Community College of the University of Arkansas',
    value: 'Cossatot Community College of the University of Arkansas'
  },
  {
    id: '1485',
    label: 'Cosumnes River College',
    value: 'Cosumnes River College'
  },
  {
    id: '1486',
    label: 'County College of Morris',
    value: 'County College of Morris'
  },
  {
    id: '1487',
    label: 'Courtauld Institute of Art, University of London',
    value: 'Courtauld Institute of Art, University of London'
  },
  {
    id: '1488',
    label: 'Covenant College',
    value: 'Covenant College'
  },
  {
    id: '1489',
    label: 'Covenant University',
    value: 'Covenant University'
  },
  {
    id: '1490',
    label: 'Coventry University',
    value: 'Coventry University'
  },
  {
    id: '1491',
    label: 'Cowley County Community College',
    value: 'Cowley County Community College'
  },
  {
    id: '1492',
    label: 'Crafton Hills College',
    value: 'Crafton Hills College'
  },
  {
    id: '1493',
    label: 'Cranfield University',
    value: 'Cranfield University'
  },
  {
    id: '1494',
    label: 'Craven Community College',
    value: 'Craven Community College'
  },
  {
    id: '1495',
    label: 'Crawford University',
    value: 'Crawford University'
  },
  {
    id: '1496',
    label: 'Creighton University',
    value: 'Creighton University'
  },
  {
    id: '1497',
    label: 'Crescent University',
    value: 'Crescent University'
  },
  {
    id: '1498',
    label: 'Crimea State Medical University',
    value: 'Crimea State Medical University'
  },
  {
    id: '1499',
    label: 'Cross River University of Science and Technology',
    value: 'Cross River University of Science and Technology'
  },
  {
    id: '1500',
    label: 'Crowder College',
    value: 'Crowder College'
  },
  {
    id: '1501',
    label: 'Cruzeiro do Sul University',
    value: 'Cruzeiro do Sul University'
  },
  {
    id: '1502',
    label: 'Cubidor University Switzerland',
    value: 'Cubidor University Switzerland'
  },
  {
    id: '1503',
    label: 'Cuesta College',
    value: 'Cuesta College'
  },
  {
    id: '1504',
    label: 'Cukurova University',
    value: 'Cukurova University'
  },
  {
    id: '1505',
    label: 'Cumberland County College',
    value: 'Cumberland County College'
  },
  {
    id: '1506',
    label: 'Cumhuriyet (Republik) University',
    value: 'Cumhuriyet (Republik) University'
  },
  {
    id: '1507',
    label: 'Curry College',
    value: 'Curry College'
  },
  {
    id: '1508',
    label: 'Curtin University of Technology',
    value: 'Curtin University of Technology'
  },
  {
    id: '1509',
    label: 'Curtin University of Technology, Sarawak Campus',
    value: 'Curtin University of Technology, Sarawak Campus'
  },
  {
    id: '1510',
    label: 'Cuyahoga Community College District',
    value: 'Cuyahoga Community College District'
  },
  {
    id: '1511',
    label: 'Cuyamaca College',
    value: 'Cuyamaca College'
  },
  {
    id: '1512',
    label: 'Cyberjaya University College of Medical Science',
    value: 'Cyberjaya University College of Medical Science'
  },
  {
    id: '1513',
    label: 'Cypress College',
    value: 'Cypress College'
  },
  {
    id: '1514',
    label: 'Cyprus College',
    value: 'Cyprus College'
  },
  {
    id: '1515',
    label: 'Cyprus International Institute of Management (CIIM)',
    value: 'Cyprus International Institute of Management (CIIM)'
  },
  {
    id: '1516',
    label: 'Cyprus International University',
    value: 'Cyprus International University'
  },
  {
    id: '1517',
    label: 'Cyprus University of Technology',
    value: 'Cyprus University of Technology'
  },
  {
    id: '1518',
    label: 'Cyryx College',
    value: 'Cyryx College'
  },
  {
    id: '1519',
    label: 'Czech Technical University of Prague',
    value: 'Czech Technical University of Prague'
  },
  {
    id: '1520',
    label: 'Czech University of Agriculture Prague',
    value: 'Czech University of Agriculture Prague'
  },
  {
    id: '1521',
    label: 'Cégep de Saint-Jérôme',
    value: 'Cégep de Saint-Jérôme'
  },
  {
    id: '1522',
    label: 'DAV Institute of Engineering & Technology',
    value: 'DAV Institute of Engineering & Technology'
  },
  {
    id: '1523',
    label: 'DCT International Hotel & Business Management School',
    value: 'DCT International Hotel & Business Management School'
  },
  {
    id: '1524',
    label: 'DEI Bachelor & Master Degrees',
    value: 'DEI Bachelor & Master Degrees'
  },
  {
    id: '1525',
    label: 'DHA Suffa Uinversity',
    value: 'DHA Suffa Uinversity'
  },
  {
    id: '1526',
    label: 'DIPLOMA-Fachhochschule Ölsnitz/Vogtland',
    value: 'DIPLOMA-Fachhochschule Ölsnitz/Vogtland'
  },
  {
    id: '1527',
    label: 'DUT MMI - Université Bordeaux Montaigne',
    value: 'DUT MMI - Université Bordeaux Montaigne'
  },
  {
    id: '1528',
    label: 'Da-Yeh University',
    value: 'Da-Yeh University'
  },
  {
    id: '1529',
    label: 'Dabney S Lancaster Community College',
    value: 'Dabney S Lancaster Community College'
  },
  {
    id: '1530',
    label: 'Dadabhoy Institute of Higher Education',
    value: 'Dadabhoy Institute of Higher Education'
  },
  {
    id: '1531',
    label: 'Daebul University',
    value: 'Daebul University'
  },
  {
    id: '1532',
    label: 'Daegu Arts University',
    value: 'Daegu Arts University'
  },
  {
    id: '1533',
    label: 'Daegu Gyeongbuk Institute of Science and Technology',
    value: 'Daegu Gyeongbuk Institute of Science and Technology'
  },
  {
    id: '1534',
    label: 'Daegu Haany University',
    value: 'Daegu Haany University'
  },
  {
    id: '1535',
    label: 'Daegu National University of Education',
    value: 'Daegu National University of Education'
  },
  {
    id: '1536',
    label: 'Daegu University',
    value: 'Daegu University'
  },
  {
    id: '1537',
    label: 'Daegu University of Foreign Studies',
    value: 'Daegu University of Foreign Studies'
  },
  {
    id: '1538',
    label: 'Daejeon Catholic University',
    value: 'Daejeon Catholic University'
  },
  {
    id: '1539',
    label: 'Daejeon Theological University',
    value: 'Daejeon Theological University'
  },
  {
    id: '1540',
    label: 'Daejeon University',
    value: 'Daejeon University'
  },
  {
    id: '1541',
    label: 'Daejin University',
    value: 'Daejin University'
  },
  {
    id: '1542',
    label: 'Daemen College',
    value: 'Daemen College'
  },
  {
    id: '1543',
    label: 'Daeshin University',
    value: 'Daeshin University'
  },
  {
    id: '1544',
    label: 'Daffodil International University',
    value: 'Daffodil International University'
  },
  {
    id: '1545',
    label: 'Dagestan State University',
    value: 'Dagestan State University'
  },
  {
    id: '1546',
    label: 'Dai Ichi University, College of Technology',
    value: 'Dai Ichi University, College of Technology'
  },
  {
    id: '1547',
    label: 'Daido Institute of Technology',
    value: 'Daido Institute of Technology'
  },
  {
    id: '1548',
    label: 'Daiichi College of Pharmaceutical Sciences',
    value: 'Daiichi College of Pharmaceutical Sciences'
  },
  {
    id: '1549',
    label: 'Daiichi University of Economics',
    value: 'Daiichi University of Economics'
  },
  {
    id: '1550',
    label: 'Daito Bunka University',
    value: 'Daito Bunka University'
  },
  {
    id: '1551',
    label: 'Dakota College at Bottineau',
    value: 'Dakota College at Bottineau'
  },
  {
    id: '1552',
    label: 'Dakota County Technical College',
    value: 'Dakota County Technical College'
  },
  {
    id: '1553',
    label: 'Dakota State University',
    value: 'Dakota State University'
  },
  {
    id: '1554',
    label: 'Dakota Wesleyan University',
    value: 'Dakota Wesleyan University'
  },
  {
    id: '1555',
    label: 'Dalanj University',
    value: 'Dalanj University'
  },
  {
    id: '1556',
    label: 'Dalarna University College',
    value: 'Dalarna University College'
  },
  {
    id: '1557',
    label: 'Dalhousie University',
    value: 'Dalhousie University'
  },
  {
    id: '1558',
    label: 'Dalian Martime University',
    value: 'Dalian Martime University'
  },
  {
    id: '1559',
    label: 'Dalian Medical University',
    value: 'Dalian Medical University'
  },
  {
    id: '1560',
    label: 'Dalian Neusoft University of Information',
    value: 'Dalian Neusoft University of Information'
  },
  {
    id: '1561',
    label: 'Dalian University',
    value: 'Dalian University'
  },
  {
    id: '1562',
    label: 'Dalian University of Foreign Language',
    value: 'Dalian University of Foreign Language'
  },
  {
    id: '1563',
    label: 'Dalian University of Technology',
    value: 'Dalian University of Technology'
  },
  {
    id: '1564',
    label: 'Dallas Baptist University',
    value: 'Dallas Baptist University'
  },
  {
    id: '1565',
    label: 'Dallas County Community College',
    value: 'Dallas County Community College'
  },
  {
    id: '1566',
    label: 'Dalton State College',
    value: 'Dalton State College'
  },
  {
    id: '1567',
    label: 'Damanhour University',
    value: 'Damanhour University'
  },
  {
    id: '1568',
    label: 'Damascus University',
    value: 'Damascus University'
  },
  {
    id: '1569',
    label: 'Damghan University',
    value: 'Damghan University'
  },
  {
    id: '1570',
    label: 'Damietta University',
    value: 'Damietta University'
  },
  {
    id: '1571',
    label: 'Dammam Community College',
    value: 'Dammam Community College'
  },
  {
    id: '1572',
    label: 'Dana College',
    value: 'Dana College'
  },
  {
    id: '1573',
    label: 'Danang College Of Technology',
    value: 'Danang College Of Technology'
  },
  {
    id: '1574',
    label: 'Daneshestan Institute of Higher Education',
    value: 'Daneshestan Institute of Higher Education'
  },
  {
    id: '1575',
    label: 'Daniel Webster College',
    value: 'Daniel Webster College'
  },
  {
    id: '1576',
    label: 'Danish Business Academy',
    value: 'Danish Business Academy'
  },
  {
    id: '1577',
    label: 'Danish University of Education',
    value: 'Danish University of Education'
  },
  {
    id: '1578',
    label: 'Dankook University',
    value: 'Dankook University'
  },
  {
    id: '1579',
    label: 'Danville Area Community College',
    value: 'Danville Area Community College'
  },
  {
    id: '1580',
    label: 'Danville Community College',
    value: 'Danville Community College'
  },
  {
    id: '1581',
    label: 'Dar Al-Uloom Colleges',
    value: 'Dar Al-Uloom Colleges'
  },
  {
    id: '1582',
    label: 'Dar al Hekma College',
    value: 'Dar al Hekma College'
  },
  {
    id: '1583',
    label: 'Dares Salaam Institute of Technology',
    value: 'Dares Salaam Institute of Technology'
  },
  {
    id: '1584',
    label: 'Dartmouth College',
    value: 'Dartmouth College'
  },
  {
    id: '1585',
    label: 'Darton State College',
    value: 'Darton State College'
  },
  {
    id: '1586',
    label: 'Darul Hikmah Islamic College',
    value: 'Darul Hikmah Islamic College'
  },
  {
    id: '1587',
    label: 'Darul Huda Islamic University',
    value: 'Darul Huda Islamic University'
  },
  {
    id: '1588',
    label: 'Darul Ihsan University',
    value: 'Darul Ihsan University'
  },
  {
    id: '1589',
    label: 'Darul Naim College of Technology',
    value: 'Darul Naim College of Technology'
  },
  {
    id: '1590',
    label: 'Darul Quran Islamic College University',
    value: 'Darul Quran Islamic College University'
  },
  {
    id: '1591',
    label: 'Darul Takzim Institute of Technology',
    value: 'Darul Takzim Institute of Technology'
  },
  {
    id: '1592',
    label: 'Darul Ulum Islamic College',
    value: 'Darul Ulum Islamic College'
  },
  {
    id: '1593',
    label: 'Daugavpils University',
    value: 'Daugavpils University'
  },
  {
    id: '1594',
    label: 'Dav University',
    value: 'Dav University'
  },
  {
    id: '1595',
    label: 'Davao Doctors College',
    value: 'Davao Doctors College'
  },
  {
    id: '1596',
    label: 'Davenport University',
    value: 'Davenport University'
  },
  {
    id: '1597',
    label: 'Davidson College',
    value: 'Davidson College'
  },
  {
    id: '1598',
    label: 'Davidson County Community College',
    value: 'Davidson County Community College'
  },
  {
    id: '1599',
    label: 'Davidson-Davie Community College',
    value: 'Davidson-Davie Community College'
  },
  {
    id: '1600',
    label: 'Davis & Elkins College',
    value: 'Davis & Elkins College'
  },
  {
    id: '1601',
    label: 'Davis & Elkins College',
    value: 'Davis & Elkins College'
  },
  {
    id: '1602',
    label: 'Davis College',
    value: 'Davis College'
  },
  {
    id: '1603',
    label: 'Dawat University',
    value: 'Dawat University'
  },
  {
    id: '1604',
    label: 'Dawood College of Engineering and Technology',
    value: 'Dawood College of Engineering and Technology'
  },
  {
    id: '1605',
    label: 'Dawson Community College',
    value: 'Dawson Community College'
  },
  {
    id: '1606',
    label: 'Dayalbagh Educational Institute',
    value: 'Dayalbagh Educational Institute'
  },
  {
    id: '1607',
    label: 'Daystar University',
    value: 'Daystar University'
  },
  {
    id: '1608',
    label: 'Daytona State College',
    value: 'Daytona State College'
  },
  {
    id: '1609',
    label: 'De Anza College',
    value: 'De Anza College'
  },
  {
    id: '1610',
    label: 'De Haagse Hogeschool',
    value: 'De Haagse Hogeschool'
  },
  {
    id: '1611',
    label: 'De La Salle University',
    value: 'De La Salle University'
  },
  {
    id: '1612',
    label: 'De La Salle University, Araneta',
    value: 'De La Salle University, Araneta'
  },
  {
    id: '1613',
    label: 'De Montfort University Leicester',
    value: 'De Montfort University Leicester'
  },
  {
    id: '1614',
    label: 'DePaul University',
    value: 'DePaul University'
  },
  {
    id: '1615',
    label: 'DePauw University',
    value: 'DePauw University'
  },
  {
    id: '1616',
    label: 'DeVry Institute of Technology',
    value: 'DeVry Institute of Technology'
  },
  {
    id: '1617',
    label: 'DeVry Institute of Technology',
    value: 'DeVry Institute of Technology'
  },
  {
    id: '1618',
    label: 'Deakin University',
    value: 'Deakin University'
  },
  {
    id: '1619',
    label: 'Debre Birhan University',
    value: 'Debre Birhan University'
  },
  {
    id: '1620',
    label: 'Debre Markos University',
    value: 'Debre Markos University'
  },
  {
    id: '1621',
    label: 'Debrecen University of Agricultural Sciences',
    value: 'Debrecen University of Agricultural Sciences'
  },
  {
    id: '1622',
    label: 'Deccan College Postgraduate and Research Institute',
    value: 'Deccan College Postgraduate and Research Institute'
  },
  {
    id: '1623',
    label: 'Dedan Kimathi University of Technology',
    value: 'Dedan Kimathi University of Technology'
  },
  {
    id: '1624',
    label: 'Deen Dayal Upadhyay Gorakhpur University',
    value: 'Deen Dayal Upadhyay Gorakhpur University'
  },
  {
    id: '1625',
    label: 'Defense Language Institute Foreign Language Center',
    value: 'Defense Language Institute Foreign Language Center'
  },
  {
    id: '1626',
    label: 'Defiance College',
    value: 'Defiance College'
  },
  {
    id: '1627',
    label: 'Del Mar College',
    value: 'Del Mar College'
  },
  {
    id: '1628',
    label: 'Delaware College of Art and Design',
    value: 'Delaware College of Art and Design'
  },
  {
    id: '1629',
    label: 'Delaware County Community College',
    value: 'Delaware County Community College'
  },
  {
    id: '1630',
    label: 'Delaware State University',
    value: 'Delaware State University'
  },
  {
    id: '1631',
    label: 'Delaware Technical Community College',
    value: 'Delaware Technical Community College'
  },
  {
    id: '1632',
    label: 'Delaware Valley University',
    value: 'Delaware Valley University'
  },
  {
    id: '1633',
    label: 'Delft University of Technology',
    value: 'Delft University of Technology'
  },
  {
    id: '1634',
    label: 'Delgado Community College',
    value: 'Delgado Community College'
  },
  {
    id: '1635',
    label: 'Delhi College of Engineering (DCE)',
    value: 'Delhi College of Engineering (DCE)'
  },
  {
    id: '1636',
    label: 'Delijan Payame Noor University',
    value: 'Delijan Payame Noor University'
  },
  {
    id: '1637',
    label: 'Delta College',
    value: 'Delta College'
  },
  {
    id: '1638',
    label: 'Delta State University',
    value: 'Delta State University'
  },
  {
    id: '1639',
    label: 'Denison University',
    value: 'Denison University'
  },
  {
    id: '1640',
    label: 'Denmark Technical College',
    value: 'Denmark Technical College'
  },
  {
    id: '1641',
    label: 'Denver College of Nursing',
    value: 'Denver College of Nursing'
  },
  {
    id: '1642',
    label: 'Deraya University',
    value: 'Deraya University'
  },
  {
    id: '1643',
    label: 'Des Moines Area Community College',
    value: 'Des Moines Area Community College'
  },
  {
    id: '1644',
    label: 'Des Moines University',
    value: 'Des Moines University'
  },
  {
    id: '1645',
    label: 'Detroit School of Technology',
    value: 'Detroit School of Technology'
  },
  {
    id: '1646',
    label: 'Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft',
    value: 'Deutsch-Ordens Fachhochschule Riedlingen, Hochschule für Wirtschaft'
  },
  {
    id: '1647',
    label: 'Deutsche Hochschule für Verwaltungswissenschaften Speyer',
    value: 'Deutsche Hochschule für Verwaltungswissenschaften Speyer'
  },
  {
    id: '1648',
    label: 'Deutsche Sporthochschule Köln',
    value: 'Deutsche Sporthochschule Köln'
  },
  {
    id: '1649',
    label: 'Deutsche Telekom Fachhochschule Leipzig',
    value: 'Deutsche Telekom Fachhochschule Leipzig'
  },
  {
    id: '1650',
    label: 'Deutsche Universität für Verwaltungswissenschaften Speyer',
    value: 'Deutsche Universität für Verwaltungswissenschaften Speyer'
  },
  {
    id: '1651',
    label: 'Devi Ahilya University of Indore',
    value: 'Devi Ahilya University of Indore'
  },
  {
    id: '1652',
    label: 'Deylaman Institute of Higher Education',
    value: 'Deylaman Institute of Higher Education'
  },
  {
    id: '1653',
    label: 'Dhaka International University',
    value: 'Dhaka International University'
  },
  {
    id: '1654',
    label: 'Dhaka University of Engineering and Technology',
    value: 'Dhaka University of Engineering and Technology'
  },
  {
    id: '1655',
    label: 'Dharamsinh Desai University',
    value: 'Dharamsinh Desai University'
  },
  {
    id: '1656',
    label: 'Dhirubhai Ambani Institute of Information and Communication Technology',
    value: 'Dhirubhai Ambani Institute of Information and Communication Technology'
  },
  {
    id: '1657',
    label: 'Dhofar University',
    value: 'Dhofar University'
  },
  {
    id: '1658',
    label: 'Dhurakijpundit University',
    value: 'Dhurakijpundit University'
  },
  {
    id: '1659',
    label: 'Diablo Valley College',
    value: 'Diablo Valley College'
  },
  {
    id: '1660',
    label: 'Diaconia University of Applied Sciences',
    value: 'Diaconia University of Applied Sciences'
  },
  {
    id: '1661',
    label: 'Dibrugarh University',
    value: 'Dibrugarh University'
  },
  {
    id: '1662',
    label: 'Dickinson College',
    value: 'Dickinson College'
  },
  {
    id: '1663',
    label: 'Dickinson State University',
    value: 'Dickinson State University'
  },
  {
    id: '1664',
    label: 'Dicle (Tirgris) University',
    value: 'Dicle (Tirgris) University'
  },
  {
    id: '1665',
    label: 'DigiPen Institute of Technology',
    value: 'DigiPen Institute of Technology'
  },
  {
    id: '1666',
    label: 'Dijla University College',
    value: 'Dijla University College'
  },
  {
    id: '1667',
    label: 'Dilla University',
    value: 'Dilla University'
  },
  {
    id: '1668',
    label: 'Dillard University',
    value: 'Dillard University'
  },
  {
    id: '1669',
    label: 'Dimocritus University of Thrace',
    value: 'Dimocritus University of Thrace'
  },
  {
    id: '1670',
    label: 'Dire Dawa University',
    value: 'Dire Dawa University'
  },
  {
    id: '1671',
    label: 'Dirección General de Institutos Tecnológicos',
    value: 'Dirección General de Institutos Tecnológicos'
  },
  {
    id: '1672',
    label: 'Divine Word College of Legazpi',
    value: 'Divine Word College of Legazpi'
  },
  {
    id: '1673',
    label: 'Divine Word University',
    value: 'Divine Word University'
  },
  {
    id: '1674',
    label: 'Diyala University',
    value: 'Diyala University'
  },
  {
    id: '1675',
    label: 'Dneprodzerzhinsk State Technical University',
    value: 'Dneprodzerzhinsk State Technical University'
  },
  {
    id: '1676',
    label: 'Dnepropetrovsk National University',
    value: 'Dnepropetrovsk National University'
  },
  {
    id: '1677',
    label: 'Dnepropetrovsk National University of Railway Transport',
    value: 'Dnepropetrovsk National University of Railway Transport'
  },
  {
    id: '1678',
    label: 'Dodge City Community College',
    value: 'Dodge City Community College'
  },
  {
    id: '1679',
    label: 'Dogus University',
    value: 'Dogus University'
  },
  {
    id: '1680',
    label: 'Doho University',
    value: 'Doho University'
  },
  {
    id: '1681',
    label: 'Dohto University',
    value: 'Dohto University'
  },
  {
    id: '1682',
    label: 'Dokkyo University',
    value: 'Dokkyo University'
  },
  {
    id: '1683',
    label: 'Dokkyo University School of Medicine',
    value: 'Dokkyo University School of Medicine'
  },
  {
    id: '1684',
    label: 'Dokuz Eylül University',
    value: 'Dokuz Eylül University'
  },
  {
    id: '1685',
    label: 'Dominica State College',
    value: 'Dominica State College'
  },
  {
    id: '1686',
    label: 'Dominican College',
    value: 'Dominican College'
  },
  {
    id: '1687',
    label: 'Dominican College of Philosophy and Theology',
    value: 'Dominican College of Philosophy and Theology'
  },
  {
    id: '1688',
    label: 'Dominican University',
    value: 'Dominican University'
  },
  {
    id: '1689',
    label: 'Don Mariano Marcos Memorial State University',
    value: 'Don Mariano Marcos Memorial State University'
  },
  {
    id: '1690',
    label: 'Don State Agrarian University',
    value: 'Don State Agrarian University'
  },
  {
    id: '1691',
    label: 'Don State Technical University',
    value: 'Don State Technical University'
  },
  {
    id: '1692',
    label: 'Donau-Universität Krems',
    value: 'Donau-Universität Krems'
  },
  {
    id: '1693',
    label: 'Donetsk National Technical University',
    value: 'Donetsk National Technical University'
  },
  {
    id: '1694',
    label: 'Donetsk National University',
    value: 'Donetsk National University'
  },
  {
    id: '1695',
    label: 'Donetsk State Medical University',
    value: 'Donetsk State Medical University'
  },
  {
    id: '1696',
    label: 'Dong Eui University',
    value: 'Dong Eui University'
  },
  {
    id: '1697',
    label: 'Dong Yang University of Technology',
    value: 'Dong Yang University of Technology'
  },
  {
    id: '1698',
    label: 'Dong-A University',
    value: 'Dong-A University'
  },
  {
    id: '1699',
    label: 'Dong-Eui University',
    value: 'Dong-Eui University'
  },
  {
    id: '1700',
    label: 'Dongbei University of Finance And Economics',
    value: 'Dongbei University of Finance And Economics'
  },
  {
    id: '1701',
    label: "Dongduk Women's University",
    value: "Dongduk Women's University"
  },
  {
    id: '1702',
    label: 'Dongguk University',
    value: 'Dongguk University'
  },
  {
    id: '1703',
    label: 'Donghua University, Shanghai',
    value: 'Donghua University, Shanghai'
  },
  {
    id: '1704',
    label: 'Dongseo University',
    value: 'Dongseo University'
  },
  {
    id: '1705',
    label: 'Dongshin University',
    value: 'Dongshin University'
  },
  {
    id: '1706',
    label: 'Dongyang University',
    value: 'Dongyang University'
  },
  {
    id: '1707',
    label: 'Dordt College',
    value: 'Dordt College'
  },
  {
    id: '1708',
    label: 'Doshisha University',
    value: 'Doshisha University'
  },
  {
    id: '1709',
    label: "Doshisha Women's College of Liberal Arts",
    value: "Doshisha Women's College of Liberal Arts"
  },
  {
    id: '1710',
    label: 'Douglas College',
    value: 'Douglas College'
  },
  {
    id: '1711',
    label: 'Dow University of Health Sciences',
    value: 'Dow University of Health Sciences'
  },
  {
    id: '1712',
    label: 'Dowling College',
    value: 'Dowling College'
  },
  {
    id: '1713',
    label: 'Dr. B. R. Ambedkar National Institute of Technology Jalandhar',
    value: 'Dr. B. R. Ambedkar National Institute of Technology Jalandhar'
  },
  {
    id: '1714',
    label: 'Dr. B.R. Ambedkar Open University',
    value: 'Dr. B.R. Ambedkar Open University'
  },
  {
    id: '1715',
    label: 'Dr. Babasaheb Ambedkar Marathwada Universtiy',
    value: 'Dr. Babasaheb Ambedkar Marathwada Universtiy'
  },
  {
    id: '1716',
    label: 'Dr. Babasaheb Ambedkar Technological University',
    value: 'Dr. Babasaheb Ambedkar Technological University'
  },
  {
    id: '1717',
    label: 'Dr. Bhim Rao Abdekar University',
    value: 'Dr. Bhim Rao Abdekar University'
  },
  {
    id: '1718',
    label: 'Dr. C.V. Raman University',
    value: 'Dr. C.V. Raman University'
  },
  {
    id: '1719',
    label: 'Dr. D.Y. Patil University',
    value: 'Dr. D.Y. Patil University'
  },
  {
    id: '1720',
    label: 'Dr. Hari Singh Gour University, formerly University of Sagar',
    value: 'Dr. Hari Singh Gour University, formerly University of Sagar'
  },
  {
    id: '1721',
    label: 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth',
    value: 'Dr. Panjabrao Deshmukh Krishi Vidyapeeth'
  },
  {
    id: '1722',
    label: 'Dr. Ram Manohar Lohia Avadh University',
    value: 'Dr. Ram Manohar Lohia Avadh University'
  },
  {
    id: '1723',
    label: 'Dr. Sudhir Chandra Sur Institute of Technology & Sports Complex',
    value: 'Dr. Sudhir Chandra Sur Institute of Technology & Sports Complex'
  },
  {
    id: '1724',
    label: 'Dr. YS Parmar University of Horticulture and Forestry',
    value: 'Dr. YS Parmar University of Horticulture and Forestry'
  },
  {
    id: '1725',
    label: 'Drake University',
    value: 'Drake University'
  },
  {
    id: '1726',
    label: 'Dravidian University',
    value: 'Dravidian University'
  },
  {
    id: '1727',
    label: 'Drew University',
    value: 'Drew University'
  },
  {
    id: '1728',
    label: 'Drexel University',
    value: 'Drexel University'
  },
  {
    id: '1729',
    label: 'Drury College',
    value: 'Drury College'
  },
  {
    id: '1730',
    label: 'Drzavni Univerzitet u Novom Pazaru',
    value: 'Drzavni Univerzitet u Novom Pazaru'
  },
  {
    id: '1731',
    label: 'Duale Hochschule Baden-Wuerttemberg Mannheim',
    value: 'Duale Hochschule Baden-Wuerttemberg Mannheim'
  },
  {
    id: '1732',
    label: 'Duale Hochschule Baden-Württemberg',
    value: 'Duale Hochschule Baden-Württemberg'
  },
  {
    id: '1733',
    label: 'Duale Hochschule Baden-Württemberg Center for Advanced Studies (CAS)',
    value: 'Duale Hochschule Baden-Württemberg Center for Advanced Studies (CAS)'
  },
  {
    id: '1734',
    label: 'Duale Hochschule Baden-Württemberg Heidenheim',
    value: 'Duale Hochschule Baden-Württemberg Heidenheim'
  },
  {
    id: '1735',
    label: 'Duale Hochschule Baden-Württemberg Heilbronn',
    value: 'Duale Hochschule Baden-Württemberg Heilbronn'
  },
  {
    id: '1736',
    label: 'Duale Hochschule Baden-Württemberg Karlsruhe',
    value: 'Duale Hochschule Baden-Württemberg Karlsruhe'
  },
  {
    id: '1737',
    label: 'Duale Hochschule Baden-Württemberg Lörrach',
    value: 'Duale Hochschule Baden-Württemberg Lörrach'
  },
  {
    id: '1738',
    label: 'Duale Hochschule Baden-Württemberg Mosbach',
    value: 'Duale Hochschule Baden-Württemberg Mosbach'
  },
  {
    id: '1739',
    label: 'Duale Hochschule Baden-Württemberg Ravensburg',
    value: 'Duale Hochschule Baden-Württemberg Ravensburg'
  },
  {
    id: '1740',
    label: 'Duale Hochschule Baden-Württemberg Stuttgart',
    value: 'Duale Hochschule Baden-Württemberg Stuttgart'
  },
  {
    id: '1741',
    label: 'Duale Hochschule Baden-Württemberg Villingen-Schwenningen',
    value: 'Duale Hochschule Baden-Württemberg Villingen-Schwenningen'
  },
  {
    id: '1742',
    label: 'Dubai Medical College for Girls',
    value: 'Dubai Medical College for Girls'
  },
  {
    id: '1743',
    label: 'Dubai Pharmacy College',
    value: 'Dubai Pharmacy College'
  },
  {
    id: '1744',
    label: 'Dublin City University',
    value: 'Dublin City University'
  },
  {
    id: '1745',
    label: 'Dublin Institute for Advanced Studies',
    value: 'Dublin Institute for Advanced Studies'
  },
  {
    id: '1746',
    label: 'Dublin Institute of Technology',
    value: 'Dublin Institute of Technology'
  },
  {
    id: '1747',
    label: 'Dubna International University for Nature, Society and Man',
    value: 'Dubna International University for Nature, Society and Man'
  },
  {
    id: '1748',
    label: 'Duke University',
    value: 'Duke University'
  },
  {
    id: '1749',
    label: "Duksung Women's University",
    value: "Duksung Women's University"
  },
  {
    id: '1750',
    label: 'Dumlupinar University',
    value: 'Dumlupinar University'
  },
  {
    id: '1751',
    label: 'Dundalk Institute of Technology',
    value: 'Dundalk Institute of Technology'
  },
  {
    id: '1752',
    label: 'Dunya Institute of Higher Education',
    value: 'Dunya Institute of Higher Education'
  },
  {
    id: '1753',
    label: 'Duquesne University',
    value: 'Duquesne University'
  },
  {
    id: '1754',
    label: 'Durban University of Technology',
    value: 'Durban University of Technology'
  },
  {
    id: '1755',
    label: 'Durham College',
    value: 'Durham College'
  },
  {
    id: '1756',
    label: 'Durham Technical Community College',
    value: 'Durham Technical Community College'
  },
  {
    id: '1757',
    label: 'Duta Bangsa University',
    value: 'Duta Bangsa University'
  },
  {
    id: '1758',
    label: 'Dutch Delta University',
    value: 'Dutch Delta University'
  },
  {
    id: '1759',
    label: 'Dutch University Institute for Art History (DUIA)',
    value: 'Dutch University Institute for Art History (DUIA)'
  },
  {
    id: '1760',
    label: 'Dutchess Community College',
    value: 'Dutchess Community College'
  },
  {
    id: '1761',
    label: 'Duy Tan University',
    value: 'Duy Tan University'
  },
  {
    id: '1762',
    label: 'Duzce University',
    value: 'Duzce University'
  },
  {
    id: '1763',
    label: 'Dyersburg State Community College',
    value: 'Dyersburg State Community College'
  },
  {
    id: '1764',
    label: 'Dániel Berzsenyi Teacher Training College',
    value: 'Dániel Berzsenyi Teacher Training College'
  },
  {
    id: '1765',
    label: 'E.A.P. Europäische Wirtschaftshochschule Berlin',
    value: 'E.A.P. Europäische Wirtschaftshochschule Berlin'
  },
  {
    id: '1766',
    label: 'ECAM - Institut Supérieur Industriel',
    value: 'ECAM - Institut Supérieur Industriel'
  },
  {
    id: '1767',
    label: 'ECPI University',
    value: 'ECPI University'
  },
  {
    id: '1768',
    label: 'EHSAL - Europese Hogeschool Brussel',
    value: 'EHSAL - Europese Hogeschool Brussel'
  },
  {
    id: '1769',
    label: 'EIGSI La Rochelle',
    value: 'EIGSI La Rochelle'
  },
  {
    id: '1770',
    label: 'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória',
    value: 'EMESCAM - Escola Superior de Ciências da Santa Casa de Misericórdia de Vitória'
  },
  {
    id: '1771',
    label: 'ENAE Business School',
    value: 'ENAE Business School'
  },
  {
    id: '1772',
    label: 'ENIC Telecom Lille 1',
    value: 'ENIC Telecom Lille 1'
  },
  {
    id: '1773',
    label: "EPF Ecole d'Ingénieurs",
    value: "EPF Ecole d'Ingénieurs"
  },
  {
    id: '1774',
    label: 'EPFL - EPF Lausanne',
    value: 'EPFL - EPF Lausanne'
  },
  {
    id: '1775',
    label: 'ESADE Business & Law School',
    value: 'ESADE Business & Law School'
  },
  {
    id: '1776',
    label: 'ETHZ - ETH Zurich',
    value: 'ETHZ - ETH Zurich'
  },
  {
    id: '1777',
    label: 'EUCLID University',
    value: 'EUCLID University'
  },
  {
    id: '1778',
    label: 'EVTEK University of Applied Sciences',
    value: 'EVTEK University of Applied Sciences'
  },
  {
    id: '1779',
    label: 'Eada Business School Barcelona',
    value: 'Eada Business School Barcelona'
  },
  {
    id: '1780',
    label: 'Earlham College',
    value: 'Earlham College'
  },
  {
    id: '1781',
    label: 'East Africa University Bosaso',
    value: 'East Africa University Bosaso'
  },
  {
    id: '1782',
    label: 'East Arkansas Community College',
    value: 'East Arkansas Community College'
  },
  {
    id: '1783',
    label: 'East Carolina University',
    value: 'East Carolina University'
  },
  {
    id: '1784',
    label: 'East Central College',
    value: 'East Central College'
  },
  {
    id: '1785',
    label: 'East Central Community College',
    value: 'East Central Community College'
  },
  {
    id: '1786',
    label: 'East Central University',
    value: 'East Central University'
  },
  {
    id: '1787',
    label: 'East China Jiao Tong University',
    value: 'East China Jiao Tong University'
  },
  {
    id: '1788',
    label: 'East China Normal University',
    value: 'East China Normal University'
  },
  {
    id: '1789',
    label: 'East China University of Science and Technology',
    value: 'East China University of Science and Technology'
  },
  {
    id: '1790',
    label: 'East Coast College',
    value: 'East Coast College'
  },
  {
    id: '1791',
    label: 'East Delta University',
    value: 'East Delta University'
  },
  {
    id: '1792',
    label: 'East Kazakhstan State University',
    value: 'East Kazakhstan State University'
  },
  {
    id: '1793',
    label: 'East Kazakstan State Technical University',
    value: 'East Kazakstan State Technical University'
  },
  {
    id: '1794',
    label: 'East Los Angeles College',
    value: 'East Los Angeles College'
  },
  {
    id: '1795',
    label: 'East Mississippi Community College',
    value: 'East Mississippi Community College'
  },
  {
    id: '1796',
    label: 'East Somalia University',
    value: 'East Somalia University'
  },
  {
    id: '1797',
    label: 'East Stroudsburg State University of Pennsylvania',
    value: 'East Stroudsburg State University of Pennsylvania'
  },
  {
    id: '1798',
    label: 'East Tennessee State University',
    value: 'East Tennessee State University'
  },
  {
    id: '1799',
    label: 'East Texas Baptist University',
    value: 'East Texas Baptist University'
  },
  {
    id: '1800',
    label: 'East Ukrainian National University',
    value: 'East Ukrainian National University'
  },
  {
    id: '1801',
    label: 'East West University',
    value: 'East West University'
  },
  {
    id: '1802',
    label: 'East-Siberian State Institute of Culture',
    value: 'East-Siberian State Institute of Culture'
  },
  {
    id: '1803',
    label: 'East-Siberian State University',
    value: 'East-Siberian State University'
  },
  {
    id: '1804',
    label: 'East-Siberian State University of Technology',
    value: 'East-Siberian State University of Technology'
  },
  {
    id: '1805',
    label: 'Eastern Arizona College',
    value: 'Eastern Arizona College'
  },
  {
    id: '1806',
    label: 'Eastern Asia University',
    value: 'Eastern Asia University'
  },
  {
    id: '1807',
    label: 'Eastern Connecticut State University',
    value: 'Eastern Connecticut State University'
  },
  {
    id: '1808',
    label: 'Eastern Florida State College',
    value: 'Eastern Florida State College'
  },
  {
    id: '1809',
    label: 'Eastern Gateway Community College',
    value: 'Eastern Gateway Community College'
  },
  {
    id: '1810',
    label: 'Eastern Idaho Technical College',
    value: 'Eastern Idaho Technical College'
  },
  {
    id: '1811',
    label: 'Eastern Illinois University',
    value: 'Eastern Illinois University'
  },
  {
    id: '1812',
    label: 'Eastern Iowa Community College District',
    value: 'Eastern Iowa Community College District'
  },
  {
    id: '1813',
    label: 'Eastern Kentucky University',
    value: 'Eastern Kentucky University'
  },
  {
    id: '1814',
    label: 'Eastern Maine Community College',
    value: 'Eastern Maine Community College'
  },
  {
    id: '1815',
    label: 'Eastern Mediterranean University',
    value: 'Eastern Mediterranean University'
  },
  {
    id: '1816',
    label: 'Eastern Mennonite University',
    value: 'Eastern Mennonite University'
  },
  {
    id: '1817',
    label: 'Eastern Michigan University',
    value: 'Eastern Michigan University'
  },
  {
    id: '1818',
    label: 'Eastern New Mexico University',
    value: 'Eastern New Mexico University'
  },
  {
    id: '1819',
    label: 'Eastern New Mexico University-Roswell Campus',
    value: 'Eastern New Mexico University-Roswell Campus'
  },
  {
    id: '1820',
    label: 'Eastern Oklahoma State College',
    value: 'Eastern Oklahoma State College'
  },
  {
    id: '1821',
    label: 'Eastern Oregon State College',
    value: 'Eastern Oregon State College'
  },
  {
    id: '1822',
    label: 'Eastern Shore Community College',
    value: 'Eastern Shore Community College'
  },
  {
    id: '1823',
    label: 'Eastern University',
    value: 'Eastern University'
  },
  {
    id: '1824',
    label: 'Eastern University of Sri Lanka',
    value: 'Eastern University of Sri Lanka'
  },
  {
    id: '1825',
    label: 'Eastern Washington University',
    value: 'Eastern Washington University'
  },
  {
    id: '1826',
    label: 'Eastern West Virginia Community and Technical College',
    value: 'Eastern West Virginia Community and Technical College'
  },
  {
    id: '1827',
    label: 'Eastern Wyoming College',
    value: 'Eastern Wyoming College'
  },
  {
    id: '1828',
    label: 'Eastfield College',
    value: 'Eastfield College'
  },
  {
    id: '1829',
    label: 'Easwari Engineering College',
    value: 'Easwari Engineering College'
  },
  {
    id: '1830',
    label: 'Eberhard-Karls-Universität Tübingen',
    value: 'Eberhard-Karls-Universität Tübingen'
  },
  {
    id: '1831',
    label: 'Ebonyi State University',
    value: 'Ebonyi State University'
  },
  {
    id: '1832',
    label: 'Eckerd College',
    value: 'Eckerd College'
  },
  {
    id: '1833',
    label: "Ecole Catholique d'Arts & Metiers",
    value: "Ecole Catholique d'Arts & Metiers"
  },
  {
    id: '1834',
    label: "Ecole Centrale d'Electronique - ECE",
    value: "Ecole Centrale d'Electronique - ECE"
  },
  {
    id: '1835',
    label: 'Ecole Centrale de Lille',
    value: 'Ecole Centrale de Lille'
  },
  {
    id: '1836',
    label: 'Ecole Centrale de Lyon',
    value: 'Ecole Centrale de Lyon'
  },
  {
    id: '1837',
    label: 'Ecole Centrale de Marseille',
    value: 'Ecole Centrale de Marseille'
  },
  {
    id: '1838',
    label: 'Ecole Centrale de Nantes',
    value: 'Ecole Centrale de Nantes'
  },
  {
    id: '1839',
    label: 'Ecole Européen des Affaires',
    value: 'Ecole Européen des Affaires'
  },
  {
    id: '1840',
    label: 'Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg',
    value: 'Ecole Européenne de Chimie, Polymères et Matériaux de Strasbourg'
  },
  {
    id: '1841',
    label: "Ecole Française d'Electronique et d'Informatique",
    value: "Ecole Française d'Electronique et d'Informatique"
  },
  {
    id: '1842',
    label: 'Ecole Française de Papeterie et des Industries Graphiques',
    value: 'Ecole Française de Papeterie et des Industries Graphiques'
  },
  {
    id: '1843',
    label: 'Ecole Hassania des Travaux Publics',
    value: 'Ecole Hassania des Travaux Publics'
  },
  {
    id: '1844',
    label: "Ecole Mohammadia d'Ingénieurs",
    value: "Ecole Mohammadia d'Ingénieurs"
  },
  {
    id: '1845',
    label: "Ecole National d'Agriculture de Meknes",
    value: "Ecole National d'Agriculture de Meknes"
  },
  {
    id: '1846',
    label: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique",
    value: "Ecole Nationale Supérieur d'Ingénieurs de Constructions Aéronautique"
  },
  {
    id: '1847',
    label: 'Ecole Nationale Supérieur de Géologie de Nancy',
    value: 'Ecole Nationale Supérieur de Géologie de Nancy'
  },
  {
    id: '1848',
    label: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique",
    value: "Ecole Nationale Supérieur de Mécanique et d'Aéronautique"
  },
  {
    id: '1849',
    label: 'Ecole Nationale Supérieur de Mécaniques et des Microtechniques',
    value: 'Ecole Nationale Supérieur de Mécaniques et des Microtechniques'
  },
  {
    id: '1850',
    label: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement",
    value: "Ecole Nationale Supérieur des Ingénieur des Etudes et Techniques d'Armement"
  },
  {
    id: '1851',
    label: 'Ecole Nationale Supérieure Agronomique de Toulouse',
    value: 'Ecole Nationale Supérieure Agronomique de Toulouse'
  },
  {
    id: '1852',
    label: 'Ecole Nationale Supérieure Electricité et Mécanique',
    value: 'Ecole Nationale Supérieure Electricité et Mécanique'
  },
  {
    id: '1853',
    label: "Ecole Nationale Supérieure d'Agronomie de Montpellier",
    value: "Ecole Nationale Supérieure d'Agronomie de Montpellier"
  },
  {
    id: '1854',
    label: "Ecole Nationale Supérieure d'Agronomie de Rennes",
    value: "Ecole Nationale Supérieure d'Agronomie de Rennes"
  },
  {
    id: '1855',
    label: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires",
    value: "Ecole Nationale Supérieure d'Agronomie et des Industries Alimentaires"
  },
  {
    id: '1856',
    label: "Ecole Nationale Supérieure d'Arts et Métiers de Paris",
    value: "Ecole Nationale Supérieure d'Arts et Métiers de Paris"
  },
  {
    id: '1857',
    label: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux",
    value: "Ecole Nationale Supérieure d'Electronique et de Radioelectricite de Bordeaux"
  },
  {
    id: '1858',
    label: 'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand',
    value: 'Ecole Nationale Supérieure de Chimie de Clermont-Ferrand'
  },
  {
    id: '1859',
    label: 'Ecole Nationale Supérieure de Chimie de Lille',
    value: 'Ecole Nationale Supérieure de Chimie de Lille'
  },
  {
    id: '1860',
    label: 'Ecole Nationale Supérieure de Chimie de Montpellier',
    value: 'Ecole Nationale Supérieure de Chimie de Montpellier'
  },
  {
    id: '1861',
    label: 'Ecole Nationale Supérieure de Chimie de Mulhouse',
    value: 'Ecole Nationale Supérieure de Chimie de Mulhouse'
  },
  {
    id: '1862',
    label: 'Ecole Nationale Supérieure de Chimie de Paris',
    value: 'Ecole Nationale Supérieure de Chimie de Paris'
  },
  {
    id: '1863',
    label: 'Ecole Nationale Supérieure de Chimie de Rennes',
    value: 'Ecole Nationale Supérieure de Chimie de Rennes'
  },
  {
    id: '1864',
    label: 'Ecole Nationale Supérieure de Chimie de Toulouse',
    value: 'Ecole Nationale Supérieure de Chimie de Toulouse'
  },
  {
    id: '1865',
    label: 'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux',
    value: 'Ecole Nationale Supérieure de Chimie et de Physique de Bordeaux'
  },
  {
    id: '1866',
    label: 'Ecole Nationale Supérieure de Physique de Marseille',
    value: 'Ecole Nationale Supérieure de Physique de Marseille'
  },
  {
    id: '1867',
    label: 'Ecole Nationale Supérieure de Physique de Strasbourg',
    value: 'Ecole Nationale Supérieure de Physique de Strasbourg'
  },
  {
    id: '1868',
    label: 'Ecole Nationale Supérieure de Techniques Avancées',
    value: 'Ecole Nationale Supérieure de Techniques Avancées'
  },
  {
    id: '1869',
    label: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace",
    value: "Ecole Nationale Supérieure de l'Aéronautique et de l'Espace"
  },
  {
    id: '1870',
    label: "Ecole Nationale Supérieure de l'Electronique et de ses Applications",
    value: "Ecole Nationale Supérieure de l'Electronique et de ses Applications"
  },
  {
    id: '1871',
    label: 'Ecole Nationale Supérieure des Arts et Industries Textiles',
    value: 'Ecole Nationale Supérieure des Arts et Industries Textiles'
  },
  {
    id: '1872',
    label: 'Ecole Nationale Supérieure des Arts et Industries de Strasbourg',
    value: 'Ecole Nationale Supérieure des Arts et Industries de Strasbourg'
  },
  {
    id: '1873',
    label: 'Ecole Nationale Supérieure des Industries Chimiques de Nancy',
    value: 'Ecole Nationale Supérieure des Industries Chimiques de Nancy'
  },
  {
    id: '1874',
    label: 'Ecole Nationale Supérieure des Industries Textiles de Mulhouse',
    value: 'Ecole Nationale Supérieure des Industries Textiles de Mulhouse'
  },
  {
    id: '1875',
    label: "Ecole Nationale Supérieure des Mines d'Alès",
    value: "Ecole Nationale Supérieure des Mines d'Alès"
  },
  {
    id: '1876',
    label: 'Ecole Nationale Supérieure des Mines de Douai',
    value: 'Ecole Nationale Supérieure des Mines de Douai'
  },
  {
    id: '1877',
    label: 'Ecole Nationale Supérieure des Mines de Nancy',
    value: 'Ecole Nationale Supérieure des Mines de Nancy'
  },
  {
    id: '1878',
    label: 'Ecole Nationale Supérieure des Mines de Paris',
    value: 'Ecole Nationale Supérieure des Mines de Paris'
  },
  {
    id: '1879',
    label: 'Ecole Nationale Supérieure des Mines de St-Etienne',
    value: 'Ecole Nationale Supérieure des Mines de St-Etienne'
  },
  {
    id: '1880',
    label: 'Ecole Nationale Supérieure des Telecommunications de Bretagne',
    value: 'Ecole Nationale Supérieure des Telecommunications de Bretagne'
  },
  {
    id: '1881',
    label: 'Ecole Nationale Supérieure des Telecommunications de Paris',
    value: 'Ecole Nationale Supérieure des Telecommunications de Paris'
  },
  {
    id: '1882',
    label: 'Ecole Nationale Supérieure du Pétrole et des Monteurs',
    value: 'Ecole Nationale Supérieure du Pétrole et des Monteurs'
  },
  {
    id: '1883',
    label:
      'Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse',
    value:
      'Ecole Nationale Supérieure en Electrotechnique, Electronique, Informatique et Hydraulique de Toulouse'
  },
  {
    id: '1884',
    label: "Ecole Nationale Vétérinaire d'Alfort",
    value: "Ecole Nationale Vétérinaire d'Alfort"
  },
  {
    id: '1885',
    label: 'Ecole Nationale Vétérinaire de Lyon',
    value: 'Ecole Nationale Vétérinaire de Lyon'
  },
  {
    id: '1886',
    label: 'Ecole Nationale Vétérinaire de Nantes',
    value: 'Ecole Nationale Vétérinaire de Nantes'
  },
  {
    id: '1887',
    label: 'Ecole Nationale Vétérinaire de Toulouse',
    value: 'Ecole Nationale Vétérinaire de Toulouse'
  },
  {
    id: '1888',
    label: "Ecole Nationale d'Administration",
    value: "Ecole Nationale d'Administration"
  },
  {
    id: '1889',
    label: "Ecole Nationale d'Architecture",
    value: "Ecole Nationale d'Architecture"
  },
  {
    id: '1890',
    label: "Ecole Nationale d'Ingénieurs de Metz",
    value: "Ecole Nationale d'Ingénieurs de Metz"
  },
  {
    id: '1891',
    label: "Ecole Nationale d'Ingénieurs de Saint-Etienne",
    value: "Ecole Nationale d'Ingénieurs de Saint-Etienne"
  },
  {
    id: '1892',
    label: "Ecole Nationale d'Ingénieurs de Tarbes",
    value: "Ecole Nationale d'Ingénieurs de Tarbes"
  },
  {
    id: '1893',
    label: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires",
    value: "Ecole Nationale d'Ingénieurs des Techniques des Industries Agricoles et Alimentaires"
  },
  {
    id: '1894',
    label: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux",
    value: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Bordeaux"
  },
  {
    id: '1895',
    label: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand",
    value: "Ecole Nationale d'Ingénieurs des Travaux Agricoles de Clermont-Ferrand"
  },
  {
    id: '1896',
    label: "Ecole Nationale de l'Aviation Civile",
    value: "Ecole Nationale de l'Aviation Civile"
  },
  {
    id: '1897',
    label: 'Ecole Nationale de la Météorologie',
    value: 'Ecole Nationale de la Météorologie'
  },
  {
    id: '1898',
    label: "Ecole Nationale de la Statistique et de l'Administration Economique",
    value: "Ecole Nationale de la Statistique et de l'Administration Economique"
  },
  {
    id: '1899',
    label: "Ecole Nationale de la Statistique et de l'Analyse de l'information",
    value: "Ecole Nationale de la Statistique et de l'Analyse de l'information"
  },
  {
    id: '1900',
    label: 'Ecole Nationale des Ponts et Chausees',
    value: 'Ecole Nationale des Ponts et Chausees'
  },
  {
    id: '1901',
    label: 'Ecole Nationale des Sciences Géographiques',
    value: 'Ecole Nationale des Sciences Géographiques'
  },
  {
    id: '1902',
    label: "Ecole Nationale des Travaux Publics de l'Etat",
    value: "Ecole Nationale des Travaux Publics de l'Etat"
  },
  {
    id: '1903',
    label: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg",
    value: "Ecole Nationale du Génie de l'Eau et de l'Environnement de Strasbourg"
  },
  {
    id: '1904',
    label: 'Ecole Normale Supérieure de Cachan',
    value: 'Ecole Normale Supérieure de Cachan'
  },
  {
    id: '1905',
    label: 'Ecole Normale Supérieure de Fontenay-Saint Cloud',
    value: 'Ecole Normale Supérieure de Fontenay-Saint Cloud'
  },
  {
    id: '1906',
    label: 'Ecole Normale Supérieure de Lyon',
    value: 'Ecole Normale Supérieure de Lyon'
  },
  {
    id: '1907',
    label: 'Ecole Normale Supérieure de Paris',
    value: 'Ecole Normale Supérieure de Paris'
  },
  {
    id: '1908',
    label: 'Ecole Polytechnique Marseille',
    value: 'Ecole Polytechnique Marseille'
  },
  {
    id: '1909',
    label: 'Ecole Polytechnique Universitaire de Lille',
    value: 'Ecole Polytechnique Universitaire de Lille'
  },
  {
    id: '1910',
    label: "Ecole Pour l'Informatique et les Techniques Avancees",
    value: "Ecole Pour l'Informatique et les Techniques Avancees"
  },
  {
    id: '1911',
    label: "Ecole Spéciale de Mécanique et d'Electricité",
    value: "Ecole Spéciale de Mécanique et d'Electricité"
  },
  {
    id: '1912',
    label: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie",
    value: "Ecole Spéciale des Travaux Publics du Bâtiment et de l'Industrie"
  },
  {
    id: '1913',
    label: "Ecole Superieur d'Ingenieurs Leonard de Vinci",
    value: "Ecole Superieur d'Ingenieurs Leonard de Vinci"
  },
  {
    id: '1914',
    label: 'Ecole Superieure Robert de Sorbon',
    value: 'Ecole Superieure Robert de Sorbon'
  },
  {
    id: '1915',
    label: 'Ecole Superieure des Télécommunications',
    value: 'Ecole Superieure des Télécommunications'
  },
  {
    id: '1916',
    label: "Ecole Supérieure Internationale d'Administration des Entreprises",
    value: "Ecole Supérieure Internationale d'Administration des Entreprises"
  },
  {
    id: '1917',
    label: "Ecole Supérieure d'Agriculture d'Angers",
    value: "Ecole Supérieure d'Agriculture d'Angers"
  },
  {
    id: '1918',
    label: "Ecole Supérieure d'Agriculture de Purpan",
    value: "Ecole Supérieure d'Agriculture de Purpan"
  },
  {
    id: '1919',
    label: "Ecole Supérieure d'Electricité",
    value: "Ecole Supérieure d'Electricité"
  },
  {
    id: '1920',
    label: "Ecole Supérieure d'Electronique de l'Ouest",
    value: "Ecole Supérieure d'Electronique de l'Ouest"
  },
  {
    id: '1921',
    label: "Ecole Supérieure d'Informatique et de Management",
    value: "Ecole Supérieure d'Informatique et de Management"
  },
  {
    id: '1922',
    label: "Ecole Supérieure d'Informatique-Electronique-Automatique",
    value: "Ecole Supérieure d'Informatique-Electronique-Automatique"
  },
  {
    id: '1923',
    label: "Ecole Supérieure d'Ingénieurs de Marseille",
    value: "Ecole Supérieure d'Ingénieurs de Marseille"
  },
  {
    id: '1924',
    label: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique",
    value: "Ecole Supérieure d'Ingénieurs en Electronique et Electrotechnique"
  },
  {
    id: '1925',
    label: "Ecole Supérieure d'Ingénieurs en Génie Electrique",
    value: "Ecole Supérieure d'Ingénieurs en Génie Electrique"
  },
  {
    id: '1926',
    label: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture",
    value: "Ecole Supérieure d'Ingénieurs et de Techniciens pour l'Agriculture"
  },
  {
    id: '1927',
    label: "Ecole Supérieure d'Optique",
    value: "Ecole Supérieure d'Optique"
  },
  {
    id: '1928',
    label: 'Ecole Supérieure de Chimie Organique et Minérale',
    value: 'Ecole Supérieure de Chimie Organique et Minérale'
  },
  {
    id: '1929',
    label: 'Ecole Supérieure de Chimie Physique Electronique de Lyon',
    value: 'Ecole Supérieure de Chimie Physique Electronique de Lyon'
  },
  {
    id: '1930',
    label: 'Ecole Supérieure de Commerce de Bordeaux',
    value: 'Ecole Supérieure de Commerce de Bordeaux'
  },
  {
    id: '1931',
    label: 'Ecole Supérieure de Commerce de Brest',
    value: 'Ecole Supérieure de Commerce de Brest'
  },
  {
    id: '1932',
    label: 'Ecole Supérieure de Commerce de Clermont-Ferrand',
    value: 'Ecole Supérieure de Commerce de Clermont-Ferrand'
  },
  {
    id: '1933',
    label: 'Ecole Supérieure de Commerce de Dijon',
    value: 'Ecole Supérieure de Commerce de Dijon'
  },
  {
    id: '1934',
    label: 'Ecole Supérieure de Commerce de Le Havre/Caen',
    value: 'Ecole Supérieure de Commerce de Le Havre/Caen'
  },
  {
    id: '1935',
    label: 'Ecole Supérieure de Commerce de Lille',
    value: 'Ecole Supérieure de Commerce de Lille'
  },
  {
    id: '1936',
    label: 'Ecole Supérieure de Commerce de Lyon',
    value: 'Ecole Supérieure de Commerce de Lyon'
  },
  {
    id: '1937',
    label: 'Ecole Supérieure de Commerce de Marseille-Provence',
    value: 'Ecole Supérieure de Commerce de Marseille-Provence'
  },
  {
    id: '1938',
    label: 'Ecole Supérieure de Commerce de Nantes-Atlantique',
    value: 'Ecole Supérieure de Commerce de Nantes-Atlantique'
  },
  {
    id: '1939',
    label: 'Ecole Supérieure de Commerce de Paris',
    value: 'Ecole Supérieure de Commerce de Paris'
  },
  {
    id: '1940',
    label: 'Ecole Supérieure de Commerce de Pau',
    value: 'Ecole Supérieure de Commerce de Pau'
  },
  {
    id: '1941',
    label: 'Ecole Supérieure de Commerce de Reims',
    value: 'Ecole Supérieure de Commerce de Reims'
  },
  {
    id: '1942',
    label: 'Ecole Supérieure de Commerce de Rouen',
    value: 'Ecole Supérieure de Commerce de Rouen'
  },
  {
    id: '1943',
    label: 'Ecole Supérieure de Commerce de Sophia Antipolis',
    value: 'Ecole Supérieure de Commerce de Sophia Antipolis'
  },
  {
    id: '1944',
    label: 'Ecole Supérieure de Commerce de Toulouse',
    value: 'Ecole Supérieure de Commerce de Toulouse'
  },
  {
    id: '1945',
    label: 'Ecole Supérieure de Commerce et Management',
    value: 'Ecole Supérieure de Commerce et Management'
  },
  {
    id: '1946',
    label: 'Ecole Supérieure de Commerce et des Affaires',
    value: 'Ecole Supérieure de Commerce et des Affaires'
  },
  {
    id: '1947',
    label: 'Ecole Supérieure de Physique et de Chimie Industrielles',
    value: 'Ecole Supérieure de Physique et de Chimie Industrielles'
  },
  {
    id: '1948',
    label: "Ecole Supérieure des Sciences Commerciales d'Angers",
    value: "Ecole Supérieure des Sciences Commerciales d'Angers"
  },
  {
    id: '1949',
    label: 'Ecole Supérieure des Sciences Economiques et Commerciales',
    value: 'Ecole Supérieure des Sciences Economiques et Commerciales'
  },
  {
    id: '1950',
    label: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy",
    value: "Ecole Supérieure des Sciences et Technologie de l'Ingénieur de Nancy"
  },
  {
    id: '1951',
    label: "Ecole Universitaire d'Ingénieurs de Lille",
    value: "Ecole Universitaire d'Ingénieurs de Lille"
  },
  {
    id: '1952',
    label: "Ecole d'Architecture de Nancy",
    value: "Ecole d'Architecture de Nancy"
  },
  {
    id: '1953',
    label: "Ecole d'Ingénieurs en Informatique pour l'Industrie",
    value: "Ecole d'Ingénieurs en Informatique pour l'Industrie"
  },
  {
    id: '1954',
    label: "Ecole de l'Air",
    value: "Ecole de l'Air"
  },
  {
    id: '1955',
    label: 'Ecole des Hautes Etudes Commerciales MAROC',
    value: 'Ecole des Hautes Etudes Commerciales MAROC'
  },
  {
    id: '1956',
    label: 'Ecole des Hautes Etudes Commerciales du Nord',
    value: 'Ecole des Hautes Etudes Commerciales du Nord'
  },
  {
    id: '1957',
    label: 'Ecole des Hautes Etudes Industrielles de Lille',
    value: 'Ecole des Hautes Etudes Industrielles de Lille'
  },
  {
    id: '1958',
    label: 'Ecole des Hautes Etudes en Gestion Informatique et Communication',
    value: 'Ecole des Hautes Etudes en Gestion Informatique et Communication'
  },
  {
    id: '1959',
    label: 'Ecole des Ingénieurs de la Ville de Paris',
    value: 'Ecole des Ingénieurs de la Ville de Paris'
  },
  {
    id: '1960',
    label: 'Ecole des technologies numeriques appliquees',
    value: 'Ecole des technologies numeriques appliquees'
  },
  {
    id: '1961',
    label: "Ecole pour l'Informatique et les nouvelles Technologies",
    value: "Ecole pour l'Informatique et les nouvelles Technologies"
  },
  {
    id: '1962',
    label: "Ecole pour l'Informatique et les nouvelles Technologies - Albania",
    value: "Ecole pour l'Informatique et les nouvelles Technologies - Albania"
  },
  {
    id: '1963',
    label: 'Ecole pour les Etudes et la Recherche en Informatique et Electronique',
    value: 'Ecole pour les Etudes et la Recherche en Informatique et Electronique'
  },
  {
    id: '1964',
    label: 'Edge Hill University',
    value: 'Edge Hill University'
  },
  {
    id: '1965',
    label: 'Edgecombe Community College',
    value: 'Edgecombe Community College'
  },
  {
    id: '1966',
    label: 'Edgewood College',
    value: 'Edgewood College'
  },
  {
    id: '1967',
    label: 'Edinboro University of Pennsylvania',
    value: 'Edinboro University of Pennsylvania'
  },
  {
    id: '1968',
    label: 'Edinburgh Napier University',
    value: 'Edinburgh Napier University'
  },
  {
    id: '1969',
    label: 'Edison State Community College',
    value: 'Edison State Community College'
  },
  {
    id: '1970',
    label: 'Edith Cowan University',
    value: 'Edith Cowan University'
  },
  {
    id: '1971',
    label: 'Edmonds Community College',
    value: 'Edmonds Community College'
  },
  {
    id: '1972',
    label: 'Edogawa University',
    value: 'Edogawa University'
  },
  {
    id: '1973',
    label: 'Edward Waters University',
    value: 'Edward Waters University'
  },
  {
    id: '1974',
    label: 'Eelo American University',
    value: 'Eelo American University'
  },
  {
    id: '1975',
    label: 'Effat College',
    value: 'Effat College'
  },
  {
    id: '1976',
    label: 'Ege University',
    value: 'Ege University'
  },
  {
    id: '1977',
    label: 'Egerton University',
    value: 'Egerton University'
  },
  {
    id: '1978',
    label: 'Ehime University',
    value: 'Ehime University'
  },
  {
    id: '1979',
    label: 'Eichi University',
    value: 'Eichi University'
  },
  {
    id: '1980',
    label: 'Eiilm University',
    value: 'Eiilm University'
  },
  {
    id: '1981',
    label: 'Eindhoven University of Technology',
    value: 'Eindhoven University of Technology'
  },
  {
    id: '1982',
    label: 'Einsteinmed',
    value: 'Einsteinmed'
  },
  {
    id: '1983',
    label: 'Ekiti State University',
    value: 'Ekiti State University'
  },
  {
    id: '1984',
    label: 'El Camino College-Compton Center',
    value: 'El Camino College-Compton Center'
  },
  {
    id: '1985',
    label: 'El Camino Community College District',
    value: 'El Camino Community College District'
  },
  {
    id: '1986',
    label: 'El Centro College',
    value: 'El Centro College'
  },
  {
    id: '1987',
    label: 'El Colegio de México',
    value: 'El Colegio de México'
  },
  {
    id: '1988',
    label: 'El Paso Community College',
    value: 'El Paso Community College'
  },
  {
    id: '1989',
    label: 'El Shorouk Academy',
    value: 'El Shorouk Academy'
  },
  {
    id: '1990',
    label: 'Elgin Community College',
    value: 'Elgin Community College'
  },
  {
    id: '1991',
    label: 'Elisabeth University of Music',
    value: 'Elisabeth University of Music'
  },
  {
    id: '1992',
    label: 'Elizabeth City State University',
    value: 'Elizabeth City State University'
  },
  {
    id: '1993',
    label: 'Elizabethtown College',
    value: 'Elizabethtown College'
  },
  {
    id: '1994',
    label: 'Elizabethtown Community and Technical College',
    value: 'Elizabethtown Community and Technical College'
  },
  {
    id: '1995',
    label: 'Elmergib University',
    value: 'Elmergib University'
  },
  {
    id: '1996',
    label: 'Elmhurst College',
    value: 'Elmhurst College'
  },
  {
    id: '1997',
    label: 'Elon College',
    value: 'Elon College'
  },
  {
    id: '1998',
    label: 'Elrazi College Of Medical & Technological Sciences',
    value: 'Elrazi College Of Medical & Technological Sciences'
  },
  {
    id: '1999',
    label: 'Emanuel University',
    value: 'Emanuel University'
  },
  {
    id: '2000',
    label: 'Embry-Riddle Aeronautical University',
    value: 'Embry-Riddle Aeronautical University'
  },
  {
    id: '2001',
    label: 'Emeishan Buddhist College',
    value: 'Emeishan Buddhist College'
  },
  {
    id: '2002',
    label: 'Emeq Yizrael College',
    value: 'Emeq Yizrael College'
  },
  {
    id: '2003',
    label: 'Emerson College',
    value: 'Emerson College'
  },
  {
    id: '2004',
    label: 'Emily Carr Institute of Art + Design',
    value: 'Emily Carr Institute of Art + Design'
  },
  {
    id: '2005',
    label: 'Emmanuel College',
    value: 'Emmanuel College'
  },
  {
    id: '2006',
    label: 'Emory & Henry College',
    value: 'Emory & Henry College'
  },
  {
    id: '2007',
    label: 'Emory University',
    value: 'Emory University'
  },
  {
    id: '2008',
    label: 'Emporia State University',
    value: 'Emporia State University'
  },
  {
    id: '2009',
    label: 'Enderun Colleges',
    value: 'Enderun Colleges'
  },
  {
    id: '2010',
    label: 'Endicott College',
    value: 'Endicott College'
  },
  {
    id: '2011',
    label: 'Engineering College of Aarhus',
    value: 'Engineering College of Aarhus'
  },
  {
    id: '2012',
    label: 'Engineering College of Copenhagen',
    value: 'Engineering College of Copenhagen'
  },
  {
    id: '2013',
    label: 'Enterprise State Community College',
    value: 'Enterprise State Community College'
  },
  {
    id: '2014',
    label: 'Enugu State University of Science and Technology',
    value: 'Enugu State University of Science and Technology'
  },
  {
    id: '2015',
    label: 'Epoka University',
    value: 'Epoka University'
  },
  {
    id: '2016',
    label: 'Erasmus University Rotterdam',
    value: 'Erasmus University Rotterdam'
  },
  {
    id: '2017',
    label: 'Erasmushogeschool Brussel',
    value: 'Erasmushogeschool Brussel'
  },
  {
    id: '2018',
    label: 'Erciyes University',
    value: 'Erciyes University'
  },
  {
    id: '2019',
    label: 'Erie Community College',
    value: 'Erie Community College'
  },
  {
    id: '2020',
    label: 'Eritrea Institute of Technology',
    value: 'Eritrea Institute of Technology'
  },
  {
    id: '2021',
    label: 'Ernst-Moritz-Arndt Universität Greifswald',
    value: 'Ernst-Moritz-Arndt Universität Greifswald'
  },
  {
    id: '2022',
    label: 'Erzincan Binali Yildirim University',
    value: 'Erzincan Binali Yildirim University'
  },
  {
    id: '2023',
    label: 'Erzurum Tecnical University',
    value: 'Erzurum Tecnical University'
  },
  {
    id: '2024',
    label: 'Escola Nautica Infante D. Henrique',
    value: 'Escola Nautica Infante D. Henrique'
  },
  {
    id: '2025',
    label: 'Escola Superior de Artes e Design',
    value: 'Escola Superior de Artes e Design'
  },
  {
    id: '2026',
    label: 'Escola Superior de Hotelaria e Turismo do Estoril',
    value: 'Escola Superior de Hotelaria e Turismo do Estoril'
  },
  {
    id: '2027',
    label: 'Escola Superior de Media Artes e Design',
    value: 'Escola Superior de Media Artes e Design'
  },
  {
    id: '2028',
    label: 'Escuela Agricola Panamericana Zamorano',
    value: 'Escuela Agricola Panamericana Zamorano'
  },
  {
    id: '2029',
    label: 'Escuela Bancaria y Comercial',
    value: 'Escuela Bancaria y Comercial'
  },
  {
    id: '2030',
    label: 'Escuela Colombiana de Carreras Industriales',
    value: 'Escuela Colombiana de Carreras Industriales'
  },
  {
    id: '2031',
    label: 'Escuela Colombiana de Ingeniería Julio Garavito',
    value: 'Escuela Colombiana de Ingeniería Julio Garavito'
  },
  {
    id: '2032',
    label: 'Escuela Militar de Ingeniería',
    value: 'Escuela Militar de Ingeniería'
  },
  {
    id: '2033',
    label: 'Escuela Nacional de Estudios Superiores Unidad León',
    value: 'Escuela Nacional de Estudios Superiores Unidad León'
  },
  {
    id: '2034',
    label: 'Escuela Politécnica Nacional',
    value: 'Escuela Politécnica Nacional'
  },
  {
    id: '2035',
    label: 'Escuela Politécnica de Chimborazo',
    value: 'Escuela Politécnica de Chimborazo'
  },
  {
    id: '2036',
    label: 'Escuela Politécnica del Ejercito',
    value: 'Escuela Politécnica del Ejercito'
  },
  {
    id: '2037',
    label: 'Escuela Superior Politécnica del Litoral',
    value: 'Escuela Superior Politécnica del Litoral'
  },
  {
    id: '2038',
    label: 'Escuela Superior de Gestion Comercial y Marketing (ESIC)',
    value: 'Escuela Superior de Gestion Comercial y Marketing (ESIC)'
  },
  {
    id: '2039',
    label: 'Escuela Superiore de Administración Pública',
    value: 'Escuela Superiore de Administración Pública'
  },
  {
    id: '2040',
    label: 'Escuela de Administración de Negocios',
    value: 'Escuela de Administración de Negocios'
  },
  {
    id: '2041',
    label: 'Escuela de Arquitectura y Diseño',
    value: 'Escuela de Arquitectura y Diseño'
  },
  {
    id: '2042',
    label: 'Escuela de Ingeniería de Antioquia',
    value: 'Escuela de Ingeniería de Antioquia'
  },
  {
    id: '2043',
    label: 'Escuela de Policia "General Santander"',
    value: 'Escuela de Policia "General Santander"'
  },
  {
    id: '2044',
    label: 'Espam Formation University',
    value: 'Espam Formation University'
  },
  {
    id: '2045',
    label: 'Essex County College',
    value: 'Essex County College'
  },
  {
    id: '2046',
    label: 'Estonian Academy of Arts',
    value: 'Estonian Academy of Arts'
  },
  {
    id: '2047',
    label: 'Estonian Academy of Music and Theatre',
    value: 'Estonian Academy of Music and Theatre'
  },
  {
    id: '2048',
    label: 'Estonian Academy of Security Sciences',
    value: 'Estonian Academy of Security Sciences'
  },
  {
    id: '2049',
    label: 'Estonian Business School',
    value: 'Estonian Business School'
  },
  {
    id: '2050',
    label: 'Estonian University of Life Sciences',
    value: 'Estonian University of Life Sciences'
  },
  {
    id: '2051',
    label: 'Esztergom Theological College',
    value: 'Esztergom Theological College'
  },
  {
    id: '2052',
    label: 'Ethiopian Civil Service University',
    value: 'Ethiopian Civil Service University'
  },
  {
    id: '2053',
    label: 'Etisalat University College',
    value: 'Etisalat University College'
  },
  {
    id: '2054',
    label: 'Eulji University',
    value: 'Eulji University'
  },
  {
    id: '2055',
    label: 'Eurasia International University',
    value: 'Eurasia International University'
  },
  {
    id: '2056',
    label: 'Eurasian Institute of market',
    value: 'Eurasian Institute of market'
  },
  {
    id: '2057',
    label: 'Europa Fachhochschule Fresenius',
    value: 'Europa Fachhochschule Fresenius'
  },
  {
    id: '2058',
    label: 'Europa-Universität Viadrina',
    value: 'Europa-Universität Viadrina'
  },
  {
    id: '2059',
    label: 'Europa-Universität Viadrina Frankfurt (Oder)',
    value: 'Europa-Universität Viadrina Frankfurt (Oder)'
  },
  {
    id: '2060',
    label: 'European Academy of Arts in Warsaw',
    value: 'European Academy of Arts in Warsaw'
  },
  {
    id: '2061',
    label: 'European Business School',
    value: 'European Business School'
  },
  {
    id: '2062',
    label: 'European Business School Schloß Reichartshausen',
    value: 'European Business School Schloß Reichartshausen'
  },
  {
    id: '2063',
    label: 'European Carolus Magnus University',
    value: 'European Carolus Magnus University'
  },
  {
    id: '2064',
    label: 'European College of Liberal Arts',
    value: 'European College of Liberal Arts'
  },
  {
    id: '2065',
    label: 'European Graduate School, Media & Communications',
    value: 'European Graduate School, Media & Communications'
  },
  {
    id: '2066',
    label: 'European Institute of Education',
    value: 'European Institute of Education'
  },
  {
    id: '2067',
    label: 'European International University',
    value: 'European International University'
  },
  {
    id: '2068',
    label: 'European Leadership University',
    value: 'European Leadership University'
  },
  {
    id: '2069',
    label: 'European Management Center Paris',
    value: 'European Management Center Paris'
  },
  {
    id: '2070',
    label: 'European Management School',
    value: 'European Management School'
  },
  {
    id: '2071',
    label: 'European Open University',
    value: 'European Open University'
  },
  {
    id: '2072',
    label: 'European Regional Educational Academy of Armenia',
    value: 'European Regional Educational Academy of Armenia'
  },
  {
    id: '2073',
    label: 'European School of Economics',
    value: 'European School of Economics'
  },
  {
    id: '2074',
    label: 'European School of Economics',
    value: 'European School of Economics'
  },
  {
    id: '2075',
    label: 'European School of Management - Tbilisi',
    value: 'European School of Management - Tbilisi'
  },
  {
    id: '2076',
    label: 'European University',
    value: 'European University'
  },
  {
    id: '2077',
    label: 'European University',
    value: 'European University'
  },
  {
    id: '2078',
    label: 'European University Cyprus',
    value: 'European University Cyprus'
  },
  {
    id: '2079',
    label: 'European University Institute',
    value: 'European University Institute'
  },
  {
    id: '2080',
    label: 'European University Portugal',
    value: 'European University Portugal'
  },
  {
    id: '2081',
    label: 'European University at St.Petersburg',
    value: 'European University at St.Petersburg'
  },
  {
    id: '2082',
    label: 'European University of Lefke',
    value: 'European University of Lefke'
  },
  {
    id: '2083',
    label: 'Europäische Betriebswirtschafts-Akademie',
    value: 'Europäische Betriebswirtschafts-Akademie'
  },
  {
    id: '2084',
    label: 'Europäische Fachhochschule',
    value: 'Europäische Fachhochschule'
  },
  {
    id: '2085',
    label:
      'Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik',
    value: 'Evangelische Fachhochschule Berlin, Fachhochschule für Sozialarbeit und Sozialpädagogik'
  },
  {
    id: '2086',
    label: 'Evangelische Fachhochschule Darmstadt',
    value: 'Evangelische Fachhochschule Darmstadt'
  },
  {
    id: '2087',
    label:
      'Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik',
    value:
      'Evangelische Fachhochschule Freiburg, Hochschule für Soziale Arbeit, Diakonie und Religionspädagogik'
  },
  {
    id: '2088',
    label: 'Evangelische Fachhochschule Hannover',
    value: 'Evangelische Fachhochschule Hannover'
  },
  {
    id: '2089',
    label: 'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen',
    value: 'Evangelische Fachhochschule Ludwigshafen Hochschule für Sozial- und Gesundheitswesen'
  },
  {
    id: '2090',
    label: 'Evangelische Fachhochschule Nürnberg',
    value: 'Evangelische Fachhochschule Nürnberg'
  },
  {
    id: '2091',
    label:
      'Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie',
    value:
      'Evangelische Fachhochschule Reutlingen-Ludwigsburg, Hochschule für Soziale Arbeit, Religionspädagogik und Diakonie'
  },
  {
    id: '2092',
    label: 'Evangelische Fachhochschule Rheinland-Westfalen-Lippe',
    value: 'Evangelische Fachhochschule Rheinland-Westfalen-Lippe'
  },
  {
    id: '2093',
    label: 'Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg',
    value: 'Evangelische Fachhochschule für Religionspädagogik, und Gemeindediakonie Moritzburg'
  },
  {
    id: '2094',
    label:
      'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg',
    value:
      'Evangelische Fachhochschule für Sozialpädagogik der "Diakonenanstalt des Rauhen Hauses" Hamburg'
  },
  {
    id: '2095',
    label: 'Evangelische Hochschule für Soziale Arbeit Dresden (FH)',
    value: 'Evangelische Hochschule für Soziale Arbeit Dresden (FH)'
  },
  {
    id: '2096',
    label: 'Evangelische Theologische Faculteit, Leuven',
    value: 'Evangelische Theologische Faculteit, Leuven'
  },
  {
    id: '2097',
    label: 'Everest College',
    value: 'Everest College'
  },
  {
    id: '2098',
    label: 'Everett Community College',
    value: 'Everett Community College'
  },
  {
    id: '2099',
    label: 'Evergreen State College',
    value: 'Evergreen State College'
  },
  {
    id: '2100',
    label: 'Evergreen Valley College',
    value: 'Evergreen Valley College'
  },
  {
    id: '2101',
    label: 'Ewha Womans University',
    value: 'Ewha Womans University'
  },
  {
    id: '2102',
    label: 'Eötvös Lorand University',
    value: 'Eötvös Lorand University'
  },
  {
    id: '2103',
    label: 'FAAP - Fundação Armando Alvares Penteado',
    value: 'FAAP - Fundação Armando Alvares Penteado'
  },
  {
    id: '2104',
    label: 'FAE Business School - Faculdade de Administração e Economia',
    value: 'FAE Business School - Faculdade de Administração e Economia'
  },
  {
    id: '2105',
    label: 'FAST - National University of Computer and Emerging Sciences (NUCES)',
    value: 'FAST - National University of Computer and Emerging Sciences (NUCES)'
  },
  {
    id: '2106',
    label: 'FFHS - Fernfachhochschule Schweiz',
    value: 'FFHS - Fernfachhochschule Schweiz'
  },
  {
    id: '2107',
    label: 'FHNW - Fachhochschule Nordwestschweiz',
    value: 'FHNW - Fachhochschule Nordwestschweiz'
  },
  {
    id: '2108',
    label: 'FHS St.Gallen',
    value: 'FHS St.Gallen'
  },
  {
    id: '2109',
    label: 'FON University',
    value: 'FON University'
  },
  {
    id: '2110',
    label: 'FPT University',
    value: 'FPT University'
  },
  {
    id: '2111',
    label: 'FTMS Global Academy',
    value: 'FTMS Global Academy'
  },
  {
    id: '2112',
    label: 'Fachhochschule Aachen',
    value: 'Fachhochschule Aachen'
  },
  {
    id: '2113',
    label: 'Fachhochschule Aschaffenburg',
    value: 'Fachhochschule Aschaffenburg'
  },
  {
    id: '2114',
    label: 'Fachhochschule Augsburg',
    value: 'Fachhochschule Augsburg'
  },
  {
    id: '2115',
    label: 'Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft',
    value: 'Fachhochschule Biberach, Hochschule für Bauwesen und Wirtschaft'
  },
  {
    id: '2116',
    label: 'Fachhochschule Bielefeld',
    value: 'Fachhochschule Bielefeld'
  },
  {
    id: '2117',
    label: 'Fachhochschule Bingen',
    value: 'Fachhochschule Bingen'
  },
  {
    id: '2118',
    label: 'Fachhochschule Bochum',
    value: 'Fachhochschule Bochum'
  },
  {
    id: '2119',
    label: 'Fachhochschule Brandenburg',
    value: 'Fachhochschule Brandenburg'
  },
  {
    id: '2120',
    label: 'Fachhochschule Burgenland',
    value: 'Fachhochschule Burgenland'
  },
  {
    id: '2121',
    label: 'Fachhochschule Dortmund',
    value: 'Fachhochschule Dortmund'
  },
  {
    id: '2122',
    label: 'Fachhochschule Eberswalde',
    value: 'Fachhochschule Eberswalde'
  },
  {
    id: '2123',
    label: 'Fachhochschule Erfurt',
    value: 'Fachhochschule Erfurt'
  },
  {
    id: '2124',
    label: 'Fachhochschule Flensburg',
    value: 'Fachhochschule Flensburg'
  },
  {
    id: '2125',
    label: 'Fachhochschule Frankfurt am Main',
    value: 'Fachhochschule Frankfurt am Main'
  },
  {
    id: '2126',
    label: 'Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Furtwangen, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2127',
    label: 'Fachhochschule Gelsenkirchen',
    value: 'Fachhochschule Gelsenkirchen'
  },
  {
    id: '2128',
    label: 'Fachhochschule Gießen-Friedberg',
    value: 'Fachhochschule Gießen-Friedberg'
  },
  {
    id: '2129',
    label: 'Fachhochschule Hamburg',
    value: 'Fachhochschule Hamburg'
  },
  {
    id: '2130',
    label: 'Fachhochschule Hannover',
    value: 'Fachhochschule Hannover'
  },
  {
    id: '2131',
    label: 'Fachhochschule Heidelberg',
    value: 'Fachhochschule Heidelberg'
  },
  {
    id: '2132',
    label: 'Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Heilbronn, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2133',
    label:
      'Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst',
    value:
      'Fachhochschule Hildesheim/Holzminden/Göttingen, Hochschule für angewandte Wissenschaft und Kunst'
  },
  {
    id: '2134',
    label: 'Fachhochschule Hof',
    value: 'Fachhochschule Hof'
  },
  {
    id: '2135',
    label: 'Fachhochschule JOANNEUM',
    value: 'Fachhochschule JOANNEUM'
  },
  {
    id: '2136',
    label: 'Fachhochschule Jena',
    value: 'Fachhochschule Jena'
  },
  {
    id: '2137',
    label: 'Fachhochschule Kaiserslautern',
    value: 'Fachhochschule Kaiserslautern'
  },
  {
    id: '2138',
    label: 'Fachhochschule Karlsruhe, Hochschule für Technik',
    value: 'Fachhochschule Karlsruhe, Hochschule für Technik'
  },
  {
    id: '2139',
    label: 'Fachhochschule Kempten, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Kempten, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2140',
    label: 'Fachhochschule Kiel',
    value: 'Fachhochschule Kiel'
  },
  {
    id: '2141',
    label: 'Fachhochschule Koblenz',
    value: 'Fachhochschule Koblenz'
  },
  {
    id: '2142',
    label: 'Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Fachhochschule Konstanz, Hochschule für Technik, Wirtschaft und Gestaltung'
  },
  {
    id: '2143',
    label: 'Fachhochschule Krems',
    value: 'Fachhochschule Krems'
  },
  {
    id: '2144',
    label: 'Fachhochschule Kufstein (Tirol)',
    value: 'Fachhochschule Kufstein (Tirol)'
  },
  {
    id: '2145',
    label: 'Fachhochschule Kärnten',
    value: 'Fachhochschule Kärnten'
  },
  {
    id: '2146',
    label: 'Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik',
    value: 'Fachhochschule Landshut, Hochschule für Wirtschaft - Sozialwesen - Technik'
  },
  {
    id: '2147',
    label: 'Fachhochschule Lausitz',
    value: 'Fachhochschule Lausitz'
  },
  {
    id: '2148',
    label: 'Fachhochschule Lippe',
    value: 'Fachhochschule Lippe'
  },
  {
    id: '2149',
    label: 'Fachhochschule Ludwigshafen, Hochschule für Wirtschaft',
    value: 'Fachhochschule Ludwigshafen, Hochschule für Wirtschaft'
  },
  {
    id: '2150',
    label: 'Fachhochschule Lübeck',
    value: 'Fachhochschule Lübeck'
  },
  {
    id: '2151',
    label: 'Fachhochschule Mainz',
    value: 'Fachhochschule Mainz'
  },
  {
    id: '2152',
    label: 'Fachhochschule Mannheim, Hochschule für Sozialwesen',
    value: 'Fachhochschule Mannheim, Hochschule für Sozialwesen'
  },
  {
    id: '2153',
    label: 'Fachhochschule Mannheim, Hochschule für Technik und Gestaltung',
    value: 'Fachhochschule Mannheim, Hochschule für Technik und Gestaltung'
  },
  {
    id: '2154',
    label: 'Fachhochschule Merseburg',
    value: 'Fachhochschule Merseburg'
  },
  {
    id: '2155',
    label: 'Fachhochschule München',
    value: 'Fachhochschule München'
  },
  {
    id: '2156',
    label: 'Fachhochschule Münster',
    value: 'Fachhochschule Münster'
  },
  {
    id: '2157',
    label: 'Fachhochschule Neubrandenburg',
    value: 'Fachhochschule Neubrandenburg'
  },
  {
    id: '2158',
    label: 'Fachhochschule Niederrhein',
    value: 'Fachhochschule Niederrhein'
  },
  {
    id: '2159',
    label: 'Fachhochschule Nordhausen',
    value: 'Fachhochschule Nordhausen'
  },
  {
    id: '2160',
    label: 'Fachhochschule Nordostniedersachsen',
    value: 'Fachhochschule Nordostniedersachsen'
  },
  {
    id: '2161',
    label: 'Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege',
    value: 'Fachhochschule Nürtingen, Hochschule für Wirtschaft, Landwirtschaft und Landespflege'
  },
  {
    id: '2162',
    label: 'Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Offenburg, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2163',
    label: 'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven',
    value: 'Fachhochschule Oldenburg/Ostfriesland/Wilhelmshaven'
  },
  {
    id: '2164',
    label: 'Fachhochschule Osnabrück',
    value: 'Fachhochschule Osnabrück'
  },
  {
    id: '2165',
    label: 'Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft',
    value: 'Fachhochschule Pforzheim, Hochschule für Gestaltung, Technik und Wirtschaft'
  },
  {
    id: '2166',
    label: 'Fachhochschule Potsdam',
    value: 'Fachhochschule Potsdam'
  },
  {
    id: '2167',
    label: 'Fachhochschule Pur',
    value: 'Fachhochschule Pur'
  },
  {
    id: '2168',
    label: 'Fachhochschule Ravensburg-Weingarten',
    value: 'Fachhochschule Ravensburg-Weingarten'
  },
  {
    id: '2169',
    label: 'Fachhochschule Regensburg',
    value: 'Fachhochschule Regensburg'
  },
  {
    id: '2170',
    label: 'Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Reutlingen, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2171',
    label: 'Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft',
    value: 'Fachhochschule Rosenheim, Hochschule für Technik und Wirtschaft'
  },
  {
    id: '2172',
    label: 'Fachhochschule Rottenburg, Hochschule für Forstwirtschaft',
    value: 'Fachhochschule Rottenburg, Hochschule für Forstwirtschaft'
  },
  {
    id: '2173',
    label: 'Fachhochschule Salzburg',
    value: 'Fachhochschule Salzburg'
  },
  {
    id: '2174',
    label: 'Fachhochschule Schmalkalden',
    value: 'Fachhochschule Schmalkalden'
  },
  {
    id: '2175',
    label: 'Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung',
    value: 'Fachhochschule Schwäbisch Gmünd, Hochschule für Gestaltung'
  },
  {
    id: '2176',
    label: 'Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung',
    value: 'Fachhochschule Schwäbisch Hall, Hochschule für Gestaltung'
  },
  {
    id: '2177',
    label: 'Fachhochschule St. Pölten',
    value: 'Fachhochschule St. Pölten'
  },
  {
    id: '2178',
    label: 'Fachhochschule Stralsund',
    value: 'Fachhochschule Stralsund'
  },
  {
    id: '2179',
    label: 'Fachhochschule Stuttgart, Hochschule der Medien',
    value: 'Fachhochschule Stuttgart, Hochschule der Medien'
  },
  {
    id: '2180',
    label: 'Fachhochschule Stuttgart, Hochschule für Technik',
    value: 'Fachhochschule Stuttgart, Hochschule für Technik'
  },
  {
    id: '2181',
    label: 'Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Fachhochschule Trier, Hochschule für Technik, Wirtschaft und Gestaltung'
  },
  {
    id: '2182',
    label: 'Fachhochschule Vorarlberg',
    value: 'Fachhochschule Vorarlberg'
  },
  {
    id: '2183',
    label: 'Fachhochschule Wedel',
    value: 'Fachhochschule Wedel'
  },
  {
    id: '2184',
    label: 'Fachhochschule Weihenstephan',
    value: 'Fachhochschule Weihenstephan'
  },
  {
    id: '2185',
    label: 'Fachhochschule Westküste, Hochschule für Wirtschaft und Technik',
    value: 'Fachhochschule Westküste, Hochschule für Wirtschaft und Technik'
  },
  {
    id: '2186',
    label: 'Fachhochschule Wiener Neustadt',
    value: 'Fachhochschule Wiener Neustadt'
  },
  {
    id: '2187',
    label: 'Fachhochschule Wiesbaden',
    value: 'Fachhochschule Wiesbaden'
  },
  {
    id: '2188',
    label: 'Fachhochschule Worms',
    value: 'Fachhochschule Worms'
  },
  {
    id: '2189',
    label: 'Fachhochschule Würzburg - Schweinfurt',
    value: 'Fachhochschule Würzburg - Schweinfurt'
  },
  {
    id: '2190',
    label: 'Fachhochschule der Wirtschaft',
    value: 'Fachhochschule der Wirtschaft'
  },
  {
    id: '2191',
    label: 'Fachhochschule des Mittelstandes (FHM)',
    value: 'Fachhochschule des Mittelstandes (FHM)'
  },
  {
    id: '2192',
    label: 'Fachhochschule für Bank- und Finanzwirtschaft',
    value: 'Fachhochschule für Bank- und Finanzwirtschaft'
  },
  {
    id: '2193',
    label: 'Fachhochschule für Oekonomie und Management (FOM)',
    value: 'Fachhochschule für Oekonomie und Management (FOM)'
  },
  {
    id: '2194',
    label: 'Fachhochschule für Verwaltung und Rechtspflege Berlin',
    value: 'Fachhochschule für Verwaltung und Rechtspflege Berlin'
  },
  {
    id: '2195',
    label: 'Fachhochschule für Wirtschaft Berlin',
    value: 'Fachhochschule für Wirtschaft Berlin'
  },
  {
    id: '2196',
    label: 'Fachhochschule für das öffentliche Bibliothekswesen Bonn',
    value: 'Fachhochschule für das öffentliche Bibliothekswesen Bonn'
  },
  {
    id: '2197',
    label: 'Fachhochschule für die Wirtschaft',
    value: 'Fachhochschule für die Wirtschaft'
  },
  {
    id: '2198',
    label: 'Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH',
    value: 'Fachhochschule und Berufskollegs NTA, Prof.Dr. Grübler gemein. GmbH'
  },
  {
    id: '2199',
    label: 'Fachhochschulstudiengänge Hagenberg',
    value: 'Fachhochschulstudiengänge Hagenberg'
  },
  {
    id: '2200',
    label: 'Fachhochschulstudiengänge Krems IMC',
    value: 'Fachhochschulstudiengänge Krems IMC'
  },
  {
    id: '2201',
    label: 'Fachhochschulstudiengänge Steyr',
    value: 'Fachhochschulstudiengänge Steyr'
  },
  {
    id: '2202',
    label: 'Fachhochschulstudiengänge Wels',
    value: 'Fachhochschulstudiengänge Wels'
  },
  {
    id: '2203',
    label: 'Fachhochschulstudiengänge der Wiener Wirtschaft',
    value: 'Fachhochschulstudiengänge der Wiener Wirtschaft'
  },
  {
    id: '2204',
    label: 'Faculdade Dom Bosco',
    value: 'Faculdade Dom Bosco'
  },
  {
    id: '2205',
    label: 'Faculdade Integradas do Ceará',
    value: 'Faculdade Integradas do Ceará'
  },
  {
    id: '2206',
    label: 'Faculdade Italo Brasileira',
    value: 'Faculdade Italo Brasileira'
  },
  {
    id: '2207',
    label: 'Faculdades Integradas Curitiba',
    value: 'Faculdades Integradas Curitiba'
  },
  {
    id: '2208',
    label: 'Faculdades Integradas Toledo',
    value: 'Faculdades Integradas Toledo'
  },
  {
    id: '2209',
    label: 'Faculdades Integradas UPIS',
    value: 'Faculdades Integradas UPIS'
  },
  {
    id: '2210',
    label: 'Faculdades Integradas do Brasil - UNIBRASIL',
    value: 'Faculdades Integradas do Brasil - UNIBRASIL'
  },
  {
    id: '2211',
    label: 'Faculté Polytechnique de Mons',
    value: 'Faculté Polytechnique de Mons'
  },
  {
    id: '2212',
    label: 'Faculté Universitaire des Sciences Agronomiques de Gembloux',
    value: 'Faculté Universitaire des Sciences Agronomiques de Gembloux'
  },
  {
    id: '2213',
    label: 'Facultés Universitaires Catholiques de Mons',
    value: 'Facultés Universitaires Catholiques de Mons'
  },
  {
    id: '2214',
    label: 'Facultés Universitaires Notre-Dame de la Paix',
    value: 'Facultés Universitaires Notre-Dame de la Paix'
  },
  {
    id: '2215',
    label: 'Facultés Universitaires Saint-Louis',
    value: 'Facultés Universitaires Saint-Louis'
  },
  {
    id: '2216',
    label: 'Fahad Bin Sultan University',
    value: 'Fahad Bin Sultan University'
  },
  {
    id: '2217',
    label: 'Fairfield University',
    value: 'Fairfield University'
  },
  {
    id: '2218',
    label: 'Fairleigh Dickinson University',
    value: 'Fairleigh Dickinson University'
  },
  {
    id: '2219',
    label: 'Fairmont State College',
    value: 'Fairmont State College'
  },
  {
    id: '2220',
    label: 'Faisalabad Institute of Textile and Fashion Design',
    value: 'Faisalabad Institute of Textile and Fashion Design'
  },
  {
    id: '2221',
    label: 'Falmouth University',
    value: 'Falmouth University'
  },
  {
    id: '2222',
    label: 'Fanshawe College',
    value: 'Fanshawe College'
  },
  {
    id: '2223',
    label: 'Far East State Transport University',
    value: 'Far East State Transport University'
  },
  {
    id: '2224',
    label: 'Far East University',
    value: 'Far East University'
  },
  {
    id: '2225',
    label: 'Far Easten State University of Humanities',
    value: 'Far Easten State University of Humanities'
  },
  {
    id: '2226',
    label: 'Far Eastern State Technical University',
    value: 'Far Eastern State Technical University'
  },
  {
    id: '2227',
    label: 'Far Eastern State University',
    value: 'Far Eastern State University'
  },
  {
    id: '2228',
    label: 'Far Eastern University',
    value: 'Far Eastern University'
  },
  {
    id: '2229',
    label: 'Faryab Higher Education Institute',
    value: 'Faryab Higher Education Institute'
  },
  {
    id: '2230',
    label: 'Fasa Faculty of Medical Sciences',
    value: 'Fasa Faculty of Medical Sciences'
  },
  {
    id: '2231',
    label: 'Fatih Sultan Mehmet University',
    value: 'Fatih Sultan Mehmet University'
  },
  {
    id: '2232',
    label: 'Fatima Mata National College',
    value: 'Fatima Mata National College'
  },
  {
    id: '2233',
    label: 'Fayetteville State University',
    value: 'Fayetteville State University'
  },
  {
    id: '2234',
    label: 'Fayetteville Technical Community College',
    value: 'Fayetteville Technical Community College'
  },
  {
    id: '2235',
    label: 'Fayoum University',
    value: 'Fayoum University'
  },
  {
    id: '2236',
    label: 'Feather River Community College District',
    value: 'Feather River Community College District'
  },
  {
    id: '2237',
    label: 'Feati University',
    value: 'Feati University'
  },
  {
    id: '2238',
    label: 'Federal College Of Education (Technical), Akoka',
    value: 'Federal College Of Education (Technical), Akoka'
  },
  {
    id: '2239',
    label: 'Federal Institute of Education',
    value: 'Federal Institute of Education'
  },
  {
    id: '2240',
    label: 'Federal University of Petroleum Resources',
    value: 'Federal University of Petroleum Resources'
  },
  {
    id: '2241',
    label: 'Federal University of Technology, Akure',
    value: 'Federal University of Technology, Akure'
  },
  {
    id: '2242',
    label: 'Federal University of Technology, Minna',
    value: 'Federal University of Technology, Minna'
  },
  {
    id: '2243',
    label: 'Federal University of Technology, Owerri',
    value: 'Federal University of Technology, Owerri'
  },
  {
    id: '2244',
    label: 'Federal University of Technology, Yola',
    value: 'Federal University of Technology, Yola'
  },
  {
    id: '2245',
    label: 'Federal Urdu University of Arts,Science and Technology',
    value: 'Federal Urdu University of Arts,Science and Technology'
  },
  {
    id: '2246',
    label: 'Federation University Australia',
    value: 'Federation University Australia'
  },
  {
    id: '2247',
    label: 'Fenerbahce University',
    value: 'Fenerbahce University'
  },
  {
    id: '2248',
    label: 'Feng Chia University',
    value: 'Feng Chia University'
  },
  {
    id: '2249',
    label: 'Ferdowsi University of Mashhad',
    value: 'Ferdowsi University of Mashhad'
  },
  {
    id: '2250',
    label: 'Ferghana Politechnical Institute',
    value: 'Ferghana Politechnical Institute'
  },
  {
    id: '2251',
    label: 'Fern-Fachhochschule Hamburg',
    value: 'Fern-Fachhochschule Hamburg'
  },
  {
    id: '2252',
    label: 'Fernuniversität Gesamthochschule Hagen',
    value: 'Fernuniversität Gesamthochschule Hagen'
  },
  {
    id: '2253',
    label: 'Ferris State University',
    value: 'Ferris State University'
  },
  {
    id: '2254',
    label: 'Ferris University',
    value: 'Ferris University'
  },
  {
    id: '2255',
    label: 'Fielding Institute',
    value: 'Fielding Institute'
  },
  {
    id: '2256',
    label: 'Fiji National University',
    value: 'Fiji National University'
  },
  {
    id: '2257',
    label: 'Fiji School of Medicine',
    value: 'Fiji School of Medicine'
  },
  {
    id: '2258',
    label: 'Finance Academy',
    value: 'Finance Academy'
  },
  {
    id: '2259',
    label: 'Fine Arts Academy "Jan Matejko" in Cracow',
    value: 'Fine Arts Academy "Jan Matejko" in Cracow'
  },
  {
    id: '2260',
    label: 'Fine Arts Academy in Gdansk',
    value: 'Fine Arts Academy in Gdansk'
  },
  {
    id: '2261',
    label: 'Fine Arts Academy in Katowice',
    value: 'Fine Arts Academy in Katowice'
  },
  {
    id: '2262',
    label: 'Fine Arts Academy in Poznan',
    value: 'Fine Arts Academy in Poznan'
  },
  {
    id: '2263',
    label: 'Fine Arts Academy in Warsaw',
    value: 'Fine Arts Academy in Warsaw'
  },
  {
    id: '2264',
    label: 'Fine Arts Academy in Wroclaw',
    value: 'Fine Arts Academy in Wroclaw'
  },
  {
    id: '2265',
    label: 'Fine Arts Academy Wladyslaw Strzeminski in Lodz',
    value: 'Fine Arts Academy Wladyslaw Strzeminski in Lodz'
  },
  {
    id: '2266',
    label: 'Finger Lakes Community College',
    value: 'Finger Lakes Community College'
  },
  {
    id: '2267',
    label: 'Finnmark University College',
    value: 'Finnmark University College'
  },
  {
    id: '2268',
    label: 'Firat (Euphrates) University',
    value: 'Firat (Euphrates) University'
  },
  {
    id: '2269',
    label: 'First Global University to teaching Jainism',
    value: 'First Global University to teaching Jainism'
  },
  {
    id: '2270',
    label: 'First Nations University of Canada',
    value: 'First Nations University of Canada'
  },
  {
    id: '2271',
    label: 'Fisk University',
    value: 'Fisk University'
  },
  {
    id: '2272',
    label: 'Fitchburg State College',
    value: 'Fitchburg State College'
  },
  {
    id: '2273',
    label: 'Flathead Valley Community College',
    value: 'Flathead Valley Community College'
  },
  {
    id: '2274',
    label: 'Flinders University of South Australia',
    value: 'Flinders University of South Australia'
  },
  {
    id: '2275',
    label: 'Flint Hills Technical College',
    value: 'Flint Hills Technical College'
  },
  {
    id: '2276',
    label: 'Florence-Darlington Technical College',
    value: 'Florence-Darlington Technical College'
  },
  {
    id: '2277',
    label: 'Floret Global University',
    value: 'Floret Global University'
  },
  {
    id: '2278',
    label: 'Florida Agricultural and Mechanical University',
    value: 'Florida Agricultural and Mechanical University'
  },
  {
    id: '2279',
    label: 'Florida Atlantic University',
    value: 'Florida Atlantic University'
  },
  {
    id: '2280',
    label: 'Florida Gulf Coast University',
    value: 'Florida Gulf Coast University'
  },
  {
    id: '2281',
    label: 'Florida Institute of Technology',
    value: 'Florida Institute of Technology'
  },
  {
    id: '2282',
    label: 'Florida International University',
    value: 'Florida International University'
  },
  {
    id: '2283',
    label: 'Florida Keys Community College',
    value: 'Florida Keys Community College'
  },
  {
    id: '2284',
    label: 'Florida Memorial University',
    value: 'Florida Memorial University'
  },
  {
    id: '2285',
    label: 'Florida Polytechnic University',
    value: 'Florida Polytechnic University'
  },
  {
    id: '2286',
    label: 'Florida SouthWestern State College',
    value: 'Florida SouthWestern State College'
  },
  {
    id: '2287',
    label: 'Florida State College at Jacksonville',
    value: 'Florida State College at Jacksonville'
  },
  {
    id: '2288',
    label: 'Florida State University',
    value: 'Florida State University'
  },
  {
    id: '2289',
    label: 'Florida Universitaria',
    value: 'Florida Universitaria'
  },
  {
    id: '2290',
    label: 'Folsom Lake College',
    value: 'Folsom Lake College'
  },
  {
    id: '2291',
    label: 'Fomic Polytechnic',
    value: 'Fomic Polytechnic'
  },
  {
    id: '2292',
    label: 'Fond du Lac Tribal and Community College',
    value: 'Fond du Lac Tribal and Community College'
  },
  {
    id: '2293',
    label: 'Fondation Universitaire Luxembourgeoise',
    value: 'Fondation Universitaire Luxembourgeoise'
  },
  {
    id: '2294',
    label: 'Fondazione Sacro Cuore',
    value: 'Fondazione Sacro Cuore'
  },
  {
    id: '2295',
    label: 'Fontbonne College',
    value: 'Fontbonne College'
  },
  {
    id: '2296',
    label: 'Fontys University of Applied Sciences',
    value: 'Fontys University of Applied Sciences'
  },
  {
    id: '2297',
    label: 'Foothill College',
    value: 'Foothill College'
  },
  {
    id: '2298',
    label: 'Foothill-De Anza Community College District',
    value: 'Foothill-De Anza Community College District'
  },
  {
    id: '2299',
    label: 'Fordham University',
    value: 'Fordham University'
  },
  {
    id: '2300',
    label: 'Foreign Trade University',
    value: 'Foreign Trade University'
  },
  {
    id: '2301',
    label: 'Forest Research Institute Dehradun',
    value: 'Forest Research Institute Dehradun'
  },
  {
    id: '2302',
    label: 'Forsyth Technical Community College',
    value: 'Forsyth Technical Community College'
  },
  {
    id: '2303',
    label: 'Fort Hays State University',
    value: 'Fort Hays State University'
  },
  {
    id: '2304',
    label: 'Fort Lewis College',
    value: 'Fort Lewis College'
  },
  {
    id: '2305',
    label: 'Fort Peck Community College',
    value: 'Fort Peck Community College'
  },
  {
    id: '2306',
    label: 'Fort Scott Community College',
    value: 'Fort Scott Community College'
  },
  {
    id: '2307',
    label: 'Fort Valley State University',
    value: 'Fort Valley State University'
  },
  {
    id: '2308',
    label: 'Foshan University',
    value: 'Foshan University'
  },
  {
    id: '2309',
    label: 'Foundation University',
    value: 'Foundation University'
  },
  {
    id: '2310',
    label: 'Foundation University',
    value: 'Foundation University'
  },
  {
    id: '2311',
    label: 'Fountain University',
    value: 'Fountain University'
  },
  {
    id: '2312',
    label: 'Fountainhead College of Technology',
    value: 'Fountainhead College of Technology'
  },
  {
    id: '2313',
    label: 'Fourah Bay College, University of Sierra Leone',
    value: 'Fourah Bay College, University of Sierra Leone'
  },
  {
    id: '2314',
    label: 'Fox College',
    value: 'Fox College'
  },
  {
    id: '2315',
    label: 'Fox Valley Technical College',
    value: 'Fox Valley Technical College'
  },
  {
    id: '2316',
    label: 'Francis Marion University',
    value: 'Francis Marion University'
  },
  {
    id: '2317',
    label: 'Frank Phillips College',
    value: 'Frank Phillips College'
  },
  {
    id: '2318',
    label: 'Frankfurt School of Finance & Management',
    value: 'Frankfurt School of Finance & Management'
  },
  {
    id: '2319',
    label: 'Franklin College Switzerland',
    value: 'Franklin College Switzerland'
  },
  {
    id: '2320',
    label: 'Franklin Pierce Law Center',
    value: 'Franklin Pierce Law Center'
  },
  {
    id: '2321',
    label: 'Franklin University',
    value: 'Franklin University'
  },
  {
    id: '2322',
    label: 'Franklin and Marshall College',
    value: 'Franklin and Marshall College'
  },
  {
    id: '2323',
    label: 'Frederick Community College',
    value: 'Frederick Community College'
  },
  {
    id: '2324',
    label: 'Frederick University',
    value: 'Frederick University'
  },
  {
    id: '2325',
    label: 'Free International University of Moldova',
    value: 'Free International University of Moldova'
  },
  {
    id: '2326',
    label: 'Free University "Maria Santissima Assunta"',
    value: 'Free University "Maria Santissima Assunta"'
  },
  {
    id: '2327',
    label: 'Free University Institute "Carlo Cattaneo"',
    value: 'Free University Institute "Carlo Cattaneo"'
  },
  {
    id: '2328',
    label: 'Free University Stockholm',
    value: 'Free University Stockholm'
  },
  {
    id: '2329',
    label: 'Free University of Bozen',
    value: 'Free University of Bozen'
  },
  {
    id: '2330',
    label: 'Free University of Tbilisi',
    value: 'Free University of Tbilisi'
  },
  {
    id: '2331',
    label: 'Freie Kunst-Studienstätte Ottersberg',
    value: 'Freie Kunst-Studienstätte Ottersberg'
  },
  {
    id: '2332',
    label: 'Freie Universität Berlin',
    value: 'Freie Universität Berlin'
  },
  {
    id: '2333',
    label: 'French Institute of Management',
    value: 'French Institute of Management'
  },
  {
    id: '2334',
    label: 'French University in Armenia (UFAR)',
    value: 'French University in Armenia (UFAR)'
  },
  {
    id: '2335',
    label: 'Fresno City College',
    value: 'Fresno City College'
  },
  {
    id: '2336',
    label: 'Fresno Pacific University',
    value: 'Fresno Pacific University'
  },
  {
    id: '2337',
    label: 'Friedrich-Alexander Universität Erlangen-Nürnberg',
    value: 'Friedrich-Alexander Universität Erlangen-Nürnberg'
  },
  {
    id: '2338',
    label: 'Friedrich-Schiller Universität Jena',
    value: 'Friedrich-Schiller Universität Jena'
  },
  {
    id: '2339',
    label: 'Friends University',
    value: 'Friends University'
  },
  {
    id: '2340',
    label: 'Front Range Community College',
    value: 'Front Range Community College'
  },
  {
    id: '2341',
    label: 'Frostburg State University',
    value: 'Frostburg State University'
  },
  {
    id: '2342',
    label: 'Fu Jen Catholic University',
    value: 'Fu Jen Catholic University'
  },
  {
    id: '2343',
    label: 'Fudan University',
    value: 'Fudan University'
  },
  {
    id: '2344',
    label: 'Fuji University',
    value: 'Fuji University'
  },
  {
    id: '2345',
    label: "Fuji Women's College",
    value: "Fuji Women's College"
  },
  {
    id: '2346',
    label: 'Fujian Agricultural University',
    value: 'Fujian Agricultural University'
  },
  {
    id: '2347',
    label: 'Fujian Medical University',
    value: 'Fujian Medical University'
  },
  {
    id: '2348',
    label: 'Fujian Normal University',
    value: 'Fujian Normal University'
  },
  {
    id: '2349',
    label: 'Fujian University of Traditional Chinese Medicine',
    value: 'Fujian University of Traditional Chinese Medicine'
  },
  {
    id: '2350',
    label: 'Fujita Health University',
    value: 'Fujita Health University'
  },
  {
    id: '2351',
    label: 'Fukui Medical School',
    value: 'Fukui Medical School'
  },
  {
    id: '2352',
    label: 'Fukui Prefectural University',
    value: 'Fukui Prefectural University'
  },
  {
    id: '2353',
    label: 'Fukui University',
    value: 'Fukui University'
  },
  {
    id: '2354',
    label: 'Fukui University of Technology',
    value: 'Fukui University of Technology'
  },
  {
    id: '2355',
    label: 'Fukuoka Dental College',
    value: 'Fukuoka Dental College'
  },
  {
    id: '2356',
    label: 'Fukuoka Institute of Technology',
    value: 'Fukuoka Institute of Technology'
  },
  {
    id: '2357',
    label: 'Fukuoka International University',
    value: 'Fukuoka International University'
  },
  {
    id: '2358',
    label: 'Fukuoka Prefectural University',
    value: 'Fukuoka Prefectural University'
  },
  {
    id: '2359',
    label: 'Fukuoka University',
    value: 'Fukuoka University'
  },
  {
    id: '2360',
    label: 'Fukuoka University of Education',
    value: 'Fukuoka University of Education'
  },
  {
    id: '2361',
    label: "Fukuoka Women's University",
    value: "Fukuoka Women's University"
  },
  {
    id: '2362',
    label: 'Fukushima Medical College',
    value: 'Fukushima Medical College'
  },
  {
    id: '2363',
    label: 'Fukushima University',
    value: 'Fukushima University'
  },
  {
    id: '2364',
    label: 'Fukuyama Heisei University',
    value: 'Fukuyama Heisei University'
  },
  {
    id: '2365',
    label: 'Fukuyama University',
    value: 'Fukuyama University'
  },
  {
    id: '2366',
    label: 'Full Sail University',
    value: 'Full Sail University'
  },
  {
    id: '2367',
    label: 'Fuller Theological Seminary',
    value: 'Fuller Theological Seminary'
  },
  {
    id: '2368',
    label: 'Fullerton College',
    value: 'Fullerton College'
  },
  {
    id: '2369',
    label: 'Fulton-Montgomery Community College',
    value: 'Fulton-Montgomery Community College'
  },
  {
    id: '2370',
    label: 'Fundacion Escuela Colombiana de Rehabiliación',
    value: 'Fundacion Escuela Colombiana de Rehabiliación'
  },
  {
    id: '2371',
    label: 'Fundación Universitaria Luis Amigó',
    value: 'Fundación Universitaria Luis Amigó'
  },
  {
    id: '2372',
    label: 'Fundación Universitaria Manuela Beltrán',
    value: 'Fundación Universitaria Manuela Beltrán'
  },
  {
    id: '2373',
    label: 'Fundación Universitaria San Martín',
    value: 'Fundación Universitaria San Martín'
  },
  {
    id: '2374',
    label: 'Fundación Universitaria de Boyacá',
    value: 'Fundación Universitaria de Boyacá'
  },
  {
    id: '2375',
    label: 'Fundación Universitaria del Area Andina. Sede Pereira',
    value: 'Fundación Universitaria del Area Andina. Sede Pereira'
  },
  {
    id: '2376',
    label: 'Fundação Educacional de Ituverava',
    value: 'Fundação Educacional de Ituverava'
  },
  {
    id: '2377',
    label: 'Furman University',
    value: 'Furman University'
  },
  {
    id: '2378',
    label: 'Fushun Petroleum University',
    value: 'Fushun Petroleum University'
  },
  {
    id: '2379',
    label: 'Future University',
    value: 'Future University'
  },
  {
    id: '2380',
    label: 'Futureworks',
    value: 'Futureworks'
  },
  {
    id: '2381',
    label: 'Fuzhou University',
    value: 'Fuzhou University'
  },
  {
    id: '2382',
    label: 'GMI Engineering and Management Institute',
    value: 'GMI Engineering and Management Institute'
  },
  {
    id: '2383',
    label: 'GSFC University',
    value: 'GSFC University'
  },
  {
    id: '2384',
    label: 'Gaborone Universal College of Law',
    value: 'Gaborone Universal College of Law'
  },
  {
    id: '2385',
    label: 'Gachon University',
    value: 'Gachon University'
  },
  {
    id: '2386',
    label: 'Gadsden State Community College',
    value: 'Gadsden State Community College'
  },
  {
    id: '2387',
    label: 'Gakushuin University',
    value: 'Gakushuin University'
  },
  {
    id: '2388',
    label: 'Galatasaray University',
    value: 'Galatasaray University'
  },
  {
    id: '2389',
    label: 'Galen College of Nursing-Louisville',
    value: 'Galen College of Nursing-Louisville'
  },
  {
    id: '2390',
    label: 'Galillee College',
    value: 'Galillee College'
  },
  {
    id: '2391',
    label: 'Gallaudet University',
    value: 'Gallaudet University'
  },
  {
    id: '2392',
    label: 'Galveston College',
    value: 'Galveston College'
  },
  {
    id: '2393',
    label: 'Galway Mayo Institute of Technology',
    value: 'Galway Mayo Institute of Technology'
  },
  {
    id: '2394',
    label: 'Gandaki University',
    value: 'Gandaki University'
  },
  {
    id: '2395',
    label: 'Gandhara Institute of Medical Sciences',
    value: 'Gandhara Institute of Medical Sciences'
  },
  {
    id: '2396',
    label: 'Gandhi Institute of Technology and Managment',
    value: 'Gandhi Institute of Technology and Managment'
  },
  {
    id: '2397',
    label: 'Gangdara Institute Of Science & Technology',
    value: 'Gangdara Institute Of Science & Technology'
  },
  {
    id: '2398',
    label: 'Gangneung-Wonju National University',
    value: 'Gangneung-Wonju National University'
  },
  {
    id: '2399',
    label: 'Ganja State University',
    value: 'Ganja State University'
  },
  {
    id: '2400',
    label: 'Gannon University',
    value: 'Gannon University'
  },
  {
    id: '2401',
    label: 'Gansu Agricultural University',
    value: 'Gansu Agricultural University'
  },
  {
    id: '2402',
    label: 'Gansu University of Technology',
    value: 'Gansu University of Technology'
  },
  {
    id: '2403',
    label: 'Garden City Community College',
    value: 'Garden City Community College'
  },
  {
    id: '2404',
    label: 'Garrett College',
    value: 'Garrett College'
  },
  {
    id: '2405',
    label: 'Gasden State Community College',
    value: 'Gasden State Community College'
  },
  {
    id: '2406',
    label: 'Gaston College',
    value: 'Gaston College'
  },
  {
    id: '2407',
    label: 'GateWay Community College',
    value: 'GateWay Community College'
  },
  {
    id: '2408',
    label: 'Gateway Community College',
    value: 'Gateway Community College'
  },
  {
    id: '2409',
    label: 'Gateway Community and Technical College',
    value: 'Gateway Community and Technical College'
  },
  {
    id: '2410',
    label: 'Gateway Technical College',
    value: 'Gateway Technical College'
  },
  {
    id: '2411',
    label: 'Gauhati University',
    value: 'Gauhati University'
  },
  {
    id: '2412',
    label: 'Gavilan College',
    value: 'Gavilan College'
  },
  {
    id: '2413',
    label: 'Gazi University Ankara',
    value: 'Gazi University Ankara'
  },
  {
    id: '2414',
    label: 'Gaziantep University',
    value: 'Gaziantep University'
  },
  {
    id: '2415',
    label: 'Gaziosmanpasa University',
    value: 'Gaziosmanpasa University'
  },
  {
    id: '2416',
    label: 'Gdansk Management College',
    value: 'Gdansk Management College'
  },
  {
    id: '2417',
    label: 'Gdynia Maritime Academy',
    value: 'Gdynia Maritime Academy'
  },
  {
    id: '2418',
    label: 'Gebze Institute of Technology',
    value: 'Gebze Institute of Technology'
  },
  {
    id: '2419',
    label: 'Gebze Tecnical University',
    value: 'Gebze Tecnical University'
  },
  {
    id: '2420',
    label: 'Gedik University',
    value: 'Gedik University'
  },
  {
    id: '2421',
    label: 'Gemsville Technical University',
    value: 'Gemsville Technical University'
  },
  {
    id: '2422',
    label: 'Genesee Community College',
    value: 'Genesee Community College'
  },
  {
    id: '2423',
    label: 'Geneva Business School',
    value: 'Geneva Business School'
  },
  {
    id: '2424',
    label: 'Geneva College',
    value: 'Geneva College'
  },
  {
    id: '2425',
    label: 'Georg-August Universität Göttingen',
    value: 'Georg-August Universität Göttingen'
  },
  {
    id: '2426',
    label: 'Georg-Simon-Ohm-Fachhochschule Nürnberg',
    value: 'Georg-Simon-Ohm-Fachhochschule Nürnberg'
  },
  {
    id: '2427',
    label: 'George Brown College',
    value: 'George Brown College'
  },
  {
    id: '2428',
    label: 'George C Wallace State Community College-Dothan',
    value: 'George C Wallace State Community College-Dothan'
  },
  {
    id: '2429',
    label: 'George C Wallace State Community College-Hanceville',
    value: 'George C Wallace State Community College-Hanceville'
  },
  {
    id: '2430',
    label: 'George C Wallace State Community College-Selma',
    value: 'George C Wallace State Community College-Selma'
  },
  {
    id: '2431',
    label: 'George Fox College',
    value: 'George Fox College'
  },
  {
    id: '2432',
    label: 'George Fox University',
    value: 'George Fox University'
  },
  {
    id: '2433',
    label: 'George Mason University',
    value: 'George Mason University'
  },
  {
    id: '2434',
    label: 'George Washington University',
    value: 'George Washington University'
  },
  {
    id: '2435',
    label: 'Georgetown University',
    value: 'Georgetown University'
  },
  {
    id: '2436',
    label: 'Georgia College',
    value: 'Georgia College'
  },
  {
    id: '2437',
    label: 'Georgia Gwinnett College',
    value: 'Georgia Gwinnett College'
  },
  {
    id: '2438',
    label: 'Georgia Highlands College',
    value: 'Georgia Highlands College'
  },
  {
    id: '2439',
    label: 'Georgia Institute of Technology',
    value: 'Georgia Institute of Technology'
  },
  {
    id: '2440',
    label: 'Georgia Military College',
    value: 'Georgia Military College'
  },
  {
    id: '2441',
    label: 'Georgia Northwestern Technical College',
    value: 'Georgia Northwestern Technical College'
  },
  {
    id: '2442',
    label: 'Georgia Perimeter College',
    value: 'Georgia Perimeter College'
  },
  {
    id: '2443',
    label: 'Georgia Piedmont Technical College',
    value: 'Georgia Piedmont Technical College'
  },
  {
    id: '2444',
    label: 'Georgia Southern University',
    value: 'Georgia Southern University'
  },
  {
    id: '2445',
    label: 'Georgia Southwestern State University',
    value: 'Georgia Southwestern State University'
  },
  {
    id: '2446',
    label: 'Georgia State University',
    value: 'Georgia State University'
  },
  {
    id: '2447',
    label: 'Georgian Agricultural University',
    value: 'Georgian Agricultural University'
  },
  {
    id: '2448',
    label: 'Georgian Court University',
    value: 'Georgian Court University'
  },
  {
    id: '2449',
    label: 'Georgian Technical University',
    value: 'Georgian Technical University'
  },
  {
    id: '2450',
    label: 'German Jordanian University',
    value: 'German Jordanian University'
  },
  {
    id: '2451',
    label: 'German University in Cairo',
    value: 'German University in Cairo'
  },
  {
    id: '2452',
    label: 'Germanna Community College',
    value: 'Germanna Community College'
  },
  {
    id: '2453',
    label: 'Gettysburg College',
    value: 'Gettysburg College'
  },
  {
    id: '2454',
    label: 'Geumgang University',
    value: 'Geumgang University'
  },
  {
    id: '2455',
    label: 'Gezira College of Technology',
    value: 'Gezira College of Technology'
  },
  {
    id: '2456',
    label: 'Ghana Baptist University College',
    value: 'Ghana Baptist University College'
  },
  {
    id: '2457',
    label: 'Ghana Christian University College',
    value: 'Ghana Christian University College'
  },
  {
    id: '2458',
    label: 'Ghana Institute of Management and Public Administration (GIMPA)',
    value: 'Ghana Institute of Management and Public Administration (GIMPA)'
  },
  {
    id: '2459',
    label: 'Ghana Telecom University College',
    value: 'Ghana Telecom University College'
  },
  {
    id: '2460',
    label: 'Ghazni University',
    value: 'Ghazni University'
  },
  {
    id: '2461',
    label: 'Ghulam Ishaq Khan Institute of Science & Technology',
    value: 'Ghulam Ishaq Khan Institute of Science & Technology'
  },
  {
    id: '2462',
    label: 'Gift University',
    value: 'Gift University'
  },
  {
    id: '2463',
    label: 'Gifu Keizai University',
    value: 'Gifu Keizai University'
  },
  {
    id: '2464',
    label: 'Gifu Pharmaceutical University',
    value: 'Gifu Pharmaceutical University'
  },
  {
    id: '2465',
    label: 'Gifu Shotoku Gakuen University',
    value: 'Gifu Shotoku Gakuen University'
  },
  {
    id: '2466',
    label: 'Gifu University',
    value: 'Gifu University'
  },
  {
    id: '2467',
    label: 'Gifu University for Education and Languages',
    value: 'Gifu University for Education and Languages'
  },
  {
    id: '2468',
    label: "Gifu Women's University",
    value: "Gifu Women's University"
  },
  {
    id: '2469',
    label: 'Gimcheon University',
    value: 'Gimcheon University'
  },
  {
    id: '2470',
    label: 'Girne American University',
    value: 'Girne American University'
  },
  {
    id: '2471',
    label: 'Glasgow Caledonian University',
    value: 'Glasgow Caledonian University'
  },
  {
    id: '2472',
    label: 'Glasgow School of Art',
    value: 'Glasgow School of Art'
  },
  {
    id: '2473',
    label: 'Glen Oaks Community College',
    value: 'Glen Oaks Community College'
  },
  {
    id: '2474',
    label: 'Glendale Community College',
    value: 'Glendale Community College'
  },
  {
    id: '2475',
    label: 'Glendale Community College',
    value: 'Glendale Community College'
  },
  {
    id: '2476',
    label: 'Glion Institute of Higher Education',
    value: 'Glion Institute of Higher Education'
  },
  {
    id: '2477',
    label: 'Global Business School Barcelona',
    value: 'Global Business School Barcelona'
  },
  {
    id: '2478',
    label: 'Global Open University , Nagaland',
    value: 'Global Open University , Nagaland'
  },
  {
    id: '2479',
    label: 'Global University',
    value: 'Global University'
  },
  {
    id: '2480',
    label: 'Globe University & Minnesota School of Business',
    value: 'Globe University & Minnesota School of Business'
  },
  {
    id: '2481',
    label: 'Gloucester County College',
    value: 'Gloucester County College'
  },
  {
    id: '2482',
    label: 'Gnesins Russian Academy of Music',
    value: 'Gnesins Russian Academy of Music'
  },
  {
    id: '2483',
    label: 'Goa University',
    value: 'Goa University'
  },
  {
    id: '2484',
    label: 'Godfrey Okoye University',
    value: 'Godfrey Okoye University'
  },
  {
    id: '2485',
    label: 'Gogebic Community College',
    value: 'Gogebic Community College'
  },
  {
    id: '2486',
    label: 'Gokhale Institute of Politics and Economics',
    value: 'Gokhale Institute of Politics and Economics'
  },
  {
    id: '2487',
    label: 'Gola Polytechnic School',
    value: 'Gola Polytechnic School'
  },
  {
    id: '2488',
    label: 'Golden Gate University',
    value: 'Golden Gate University'
  },
  {
    id: '2489',
    label: 'Golden West College',
    value: 'Golden West College'
  },
  {
    id: '2490',
    label: 'Goldey-Beacom College',
    value: 'Goldey-Beacom College'
  },
  {
    id: '2491',
    label: 'Goldsmiths College, University of London',
    value: 'Goldsmiths College, University of London'
  },
  {
    id: '2492',
    label: 'Golestan University of Medical Sciences',
    value: 'Golestan University of Medical Sciences'
  },
  {
    id: '2493',
    label: 'Gollis University',
    value: 'Gollis University'
  },
  {
    id: '2494',
    label: 'Gomal University',
    value: 'Gomal University'
  },
  {
    id: '2495',
    label: 'Gombe State University',
    value: 'Gombe State University'
  },
  {
    id: '2496',
    label: 'Gomel State Medical University',
    value: 'Gomel State Medical University'
  },
  {
    id: '2497',
    label: 'Gomel State Technical University Pavel Sukhoi',
    value: 'Gomel State Technical University Pavel Sukhoi'
  },
  {
    id: '2498',
    label: 'Gomel State University Francisk Scarnia',
    value: 'Gomel State University Francisk Scarnia'
  },
  {
    id: '2499',
    label: 'Gonabad University of Medical Sciences',
    value: 'Gonabad University of Medical Sciences'
  },
  {
    id: '2500',
    label: 'Gondar University',
    value: 'Gondar University'
  },
  {
    id: '2501',
    label: 'Gongju National University of Education',
    value: 'Gongju National University of Education'
  },
  {
    id: '2502',
    label: 'Gonzaga University',
    value: 'Gonzaga University'
  },
  {
    id: '2503',
    label: 'Gordon Academic College',
    value: 'Gordon Academic College'
  },
  {
    id: '2504',
    label: 'Gorgan University',
    value: 'Gorgan University'
  },
  {
    id: '2505',
    label: 'Gorno-Altaisk State University',
    value: 'Gorno-Altaisk State University'
  },
  {
    id: '2506',
    label: 'Goshen College',
    value: 'Goshen College'
  },
  {
    id: '2507',
    label: 'Gotland University College',
    value: 'Gotland University College'
  },
  {
    id: '2508',
    label: 'Goucher College',
    value: 'Goucher College'
  },
  {
    id: '2509',
    label: 'Government College University Faisalabad',
    value: 'Government College University Faisalabad'
  },
  {
    id: '2510',
    label: 'Government College University Lahore',
    value: 'Government College University Lahore'
  },
  {
    id: '2511',
    label: 'Governors State University',
    value: 'Governors State University'
  },
  {
    id: '2512',
    label: 'Govind Ballabh Pant University of Agriculture and Technology',
    value: 'Govind Ballabh Pant University of Agriculture and Technology'
  },
  {
    id: '2513',
    label: 'Grace College',
    value: 'Grace College'
  },
  {
    id: '2514',
    label: 'Graceland College',
    value: 'Graceland College'
  },
  {
    id: '2515',
    label: 'Graduate School of Business Administration Zurich (GSBA Zurich)',
    value: 'Graduate School of Business Administration Zurich (GSBA Zurich)'
  },
  {
    id: '2516',
    label: 'Graduate University for Advanced Studies',
    value: 'Graduate University for Advanced Studies'
  },
  {
    id: '2517',
    label: 'Grambling State University',
    value: 'Grambling State University'
  },
  {
    id: '2518',
    label: 'Grand Canyon University',
    value: 'Grand Canyon University'
  },
  {
    id: '2519',
    label: 'Grand Rapids Community College',
    value: 'Grand Rapids Community College'
  },
  {
    id: '2520',
    label: 'Grand Valley State University',
    value: 'Grand Valley State University'
  },
  {
    id: '2521',
    label: 'Grand View University',
    value: 'Grand View University'
  },
  {
    id: '2522',
    label: 'Grays Harbor College',
    value: 'Grays Harbor College'
  },
  {
    id: '2523',
    label: 'Grayson College',
    value: 'Grayson College'
  },
  {
    id: '2524',
    label: 'Great Basin College',
    value: 'Great Basin College'
  },
  {
    id: '2525',
    label: 'Great Bay Community College',
    value: 'Great Bay Community College'
  },
  {
    id: '2526',
    label: 'Great Falls College Montana State University',
    value: 'Great Falls College Montana State University'
  },
  {
    id: '2527',
    label: 'Great Lakes University of Kisumu',
    value: 'Great Lakes University of Kisumu'
  },
  {
    id: '2528',
    label: 'Great Zimbabwe University',
    value: 'Great Zimbabwe University'
  },
  {
    id: '2529',
    label: 'Greater Kolkata College of Engineering & Management',
    value: 'Greater Kolkata College of Engineering & Management'
  },
  {
    id: '2530',
    label: 'Green River Community College',
    value: 'Green River Community College'
  },
  {
    id: '2531',
    label: 'Green University of Bangladesh',
    value: 'Green University of Bangladesh'
  },
  {
    id: '2532',
    label: 'Greenfield Community College',
    value: 'Greenfield Community College'
  },
  {
    id: '2533',
    label: 'Greenford International University',
    value: 'Greenford International University'
  },
  {
    id: '2534',
    label: 'Greenheart Medical School',
    value: 'Greenheart Medical School'
  },
  {
    id: '2535',
    label: 'Greenleaf University',
    value: 'Greenleaf University'
  },
  {
    id: '2536',
    label: 'Greensboro College',
    value: 'Greensboro College'
  },
  {
    id: '2537',
    label: 'Greenville Technical College',
    value: 'Greenville Technical College'
  },
  {
    id: '2538',
    label: 'Greenville University',
    value: 'Greenville University'
  },
  {
    id: '2539',
    label: 'Greenwich University',
    value: 'Greenwich University'
  },
  {
    id: '2540',
    label: 'Grenoble Ecole de Management',
    value: 'Grenoble Ecole de Management'
  },
  {
    id: '2541',
    label: 'Gretsa Universtiy',
    value: 'Gretsa Universtiy'
  },
  {
    id: '2542',
    label: 'Griffith College',
    value: 'Griffith College'
  },
  {
    id: '2543',
    label: 'Griffith University',
    value: 'Griffith University'
  },
  {
    id: '2544',
    label: 'Grinnell College',
    value: 'Grinnell College'
  },
  {
    id: '2545',
    label: 'Grodno State Agrarian University',
    value: 'Grodno State Agrarian University'
  },
  {
    id: '2546',
    label: 'Grodno State Medical University',
    value: 'Grodno State Medical University'
  },
  {
    id: '2547',
    label: 'Grodno State University Yanka Kupaly',
    value: 'Grodno State University Yanka Kupaly'
  },
  {
    id: '2548',
    label: 'Grossmont College',
    value: 'Grossmont College'
  },
  {
    id: '2549',
    label: 'Groupe Sup de Co Amiens Picardie',
    value: 'Groupe Sup de Co Amiens Picardie'
  },
  {
    id: '2550',
    label: 'Groupe Sup de Co Montpellier',
    value: 'Groupe Sup de Co Montpellier'
  },
  {
    id: '2551',
    label: 'Guangdong Peizheng College',
    value: 'Guangdong Peizheng College'
  },
  {
    id: '2552',
    label: 'Guangdong Polytechnic Normal University',
    value: 'Guangdong Polytechnic Normal University'
  },
  {
    id: '2553',
    label: 'Guangdong Radio & TV University',
    value: 'Guangdong Radio & TV University'
  },
  {
    id: '2554',
    label: 'Guangdong University of Foreign Studies',
    value: 'Guangdong University of Foreign Studies'
  },
  {
    id: '2555',
    label: 'Guangdong University of Technology',
    value: 'Guangdong University of Technology'
  },
  {
    id: '2556',
    label: 'Guangxi Medical University',
    value: 'Guangxi Medical University'
  },
  {
    id: '2557',
    label: 'Guangxi Normal University',
    value: 'Guangxi Normal University'
  },
  {
    id: '2558',
    label: 'Guangxi Traditional Chinese Medical University',
    value: 'Guangxi Traditional Chinese Medical University'
  },
  {
    id: '2559',
    label: 'Guangxi University',
    value: 'Guangxi University'
  },
  {
    id: '2560',
    label: 'Guangxi University for Nationalities',
    value: 'Guangxi University for Nationalities'
  },
  {
    id: '2561',
    label: 'Guangzhou Academy of Fine Art',
    value: 'Guangzhou Academy of Fine Art'
  },
  {
    id: '2562',
    label: 'Guangzhou College of South China University of Technology',
    value: 'Guangzhou College of South China University of Technology'
  },
  {
    id: '2563',
    label: 'Guangzhou Normal University',
    value: 'Guangzhou Normal University'
  },
  {
    id: '2564',
    label: 'Guangzhou University',
    value: 'Guangzhou University'
  },
  {
    id: '2565',
    label: 'Guangzhou University of Traditional Chinese Medicine',
    value: 'Guangzhou University of Traditional Chinese Medicine'
  },
  {
    id: '2566',
    label: 'Gubkin Russian State University of Oil and Gas',
    value: 'Gubkin Russian State University of Oil and Gas'
  },
  {
    id: '2567',
    label: 'Guilan University of Medical Sciences',
    value: 'Guilan University of Medical Sciences'
  },
  {
    id: '2568',
    label: 'Guilford College',
    value: 'Guilford College'
  },
  {
    id: '2569',
    label: 'Guilford Technical Community College',
    value: 'Guilford Technical Community College'
  },
  {
    id: '2570',
    label: 'Guizhou Normal University',
    value: 'Guizhou Normal University'
  },
  {
    id: '2571',
    label: 'Guizhou University',
    value: 'Guizhou University'
  },
  {
    id: '2572',
    label: 'Gujarat Ayurved University',
    value: 'Gujarat Ayurved University'
  },
  {
    id: '2573',
    label: 'Gujarat Technological University Ahmedabad',
    value: 'Gujarat Technological University Ahmedabad'
  },
  {
    id: '2574',
    label: 'Gujarat University Ahmedabad',
    value: 'Gujarat University Ahmedabad'
  },
  {
    id: '2575',
    label: 'Gulbarga University',
    value: 'Gulbarga University'
  },
  {
    id: '2576',
    label: 'Gulf Coast State College',
    value: 'Gulf Coast State College'
  },
  {
    id: '2577',
    label: 'Gulf Medical University',
    value: 'Gulf Medical University'
  },
  {
    id: '2578',
    label: 'Gulf University College',
    value: 'Gulf University College'
  },
  {
    id: '2579',
    label: 'Gulf University for Science and Technology',
    value: 'Gulf University for Science and Technology'
  },
  {
    id: '2580',
    label: 'Gulhane military medical Academy',
    value: 'Gulhane military medical Academy'
  },
  {
    id: '2581',
    label: 'Gulu University',
    value: 'Gulu University'
  },
  {
    id: '2582',
    label: 'Gumushane University',
    value: 'Gumushane University'
  },
  {
    id: '2583',
    label: "Gunma Prefectural Women's University",
    value: "Gunma Prefectural Women's University"
  },
  {
    id: '2584',
    label: 'Gunma University',
    value: 'Gunma University'
  },
  {
    id: '2585',
    label: 'Guru Ghasidas University',
    value: 'Guru Ghasidas University'
  },
  {
    id: '2586',
    label: 'Guru Gobind Singh Indraprastha University',
    value: 'Guru Gobind Singh Indraprastha University'
  },
  {
    id: '2587',
    label: 'Guru Jambeshwar University',
    value: 'Guru Jambeshwar University'
  },
  {
    id: '2588',
    label: 'Guru Nanak Dev University',
    value: 'Guru Nanak Dev University'
  },
  {
    id: '2589',
    label: 'Guru Nanak Institute of Dental Sciences & Research',
    value: 'Guru Nanak Institute of Dental Sciences & Research'
  },
  {
    id: '2590',
    label: 'Guru Nanak Institute of Hotel Management',
    value: 'Guru Nanak Institute of Hotel Management'
  },
  {
    id: '2591',
    label: 'Guru Nanak Institute of Pharmaceutical Science & Technology',
    value: 'Guru Nanak Institute of Pharmaceutical Science & Technology'
  },
  {
    id: '2592',
    label: 'Guru Nanak Institute of Technology',
    value: 'Guru Nanak Institute of Technology'
  },
  {
    id: '2593',
    label: 'Gurukul University',
    value: 'Gurukul University'
  },
  {
    id: '2594',
    label: 'Gurukula Kangri University',
    value: 'Gurukula Kangri University'
  },
  {
    id: '2595',
    label: 'Gustav-Siewerth-Akademie',
    value: 'Gustav-Siewerth-Akademie'
  },
  {
    id: '2596',
    label: 'Gustavus Adolphus College',
    value: 'Gustavus Adolphus College'
  },
  {
    id: '2597',
    label: 'Gwangju Catholic University',
    value: 'Gwangju Catholic University'
  },
  {
    id: '2598',
    label: 'Gwangju Institute of Science and Technology',
    value: 'Gwangju Institute of Science and Technology'
  },
  {
    id: '2599',
    label: 'Gwangju National University of Education',
    value: 'Gwangju National University of Education'
  },
  {
    id: '2600',
    label: 'Gwinnett Technical College',
    value: 'Gwinnett Technical College'
  },
  {
    id: '2601',
    label: 'Gwynedd Mercy University',
    value: 'Gwynedd Mercy University'
  },
  {
    id: '2602',
    label: 'Gyeongin National University of Education',
    value: 'Gyeongin National University of Education'
  },
  {
    id: '2603',
    label: 'Gyeongnam National University of Science and Technology',
    value: 'Gyeongnam National University of Science and Technology'
  },
  {
    id: '2604',
    label: 'Gyeongsang National University',
    value: 'Gyeongsang National University'
  },
  {
    id: '2605',
    label: 'Győr Jedlik Ányos Mechanical Engineering and Informatics Technical School',
    value: 'Győr Jedlik Ányos Mechanical Engineering and Informatics Technical School'
  },
  {
    id: '2606',
    label: 'Göteborg University',
    value: 'Göteborg University'
  },
  {
    id: '2607',
    label: 'H Councill Trenholm State Technical College',
    value: 'H Councill Trenholm State Technical College'
  },
  {
    id: '2608',
    label: 'HAN University of Applied Sciences',
    value: 'HAN University of Applied Sciences'
  },
  {
    id: '2609',
    label: 'HEP Vaud - Haute école pédagogique du canton de Vaud',
    value: 'HEP Vaud - Haute école pédagogique du canton de Vaud'
  },
  {
    id: '2610',
    label: 'HEP-BEJUNE - Haute Ecole Pédagogique BEJUNE',
    value: 'HEP-BEJUNE - Haute Ecole Pédagogique BEJUNE'
  },
  {
    id: '2611',
    label: 'HEP-PH FR - University of Teacher Education Fribourg',
    value: 'HEP-PH FR - University of Teacher Education Fribourg'
  },
  {
    id: '2612',
    label: 'HEPVS - Haute école pédagogique du Valais',
    value: 'HEPVS - Haute école pédagogique du Valais'
  },
  {
    id: '2613',
    label: 'HES-SO : UAS Western Switzerland',
    value: 'HES-SO : UAS Western Switzerland'
  },
  {
    id: '2614',
    label: 'HSR - Hochschule für Technik Rapperswil',
    value: 'HSR - Hochschule für Technik Rapperswil'
  },
  {
    id: '2615',
    label: 'HTL Villach',
    value: 'HTL Villach'
  },
  {
    id: '2616',
    label: 'HTL WELS',
    value: 'HTL WELS'
  },
  {
    id: '2617',
    label: 'HTW Chur - University of Applied Sciences HTW Chur',
    value: 'HTW Chur - University of Applied Sciences HTW Chur'
  },
  {
    id: '2618',
    label: 'HUG - Univ. Hospitals of Geneva',
    value: 'HUG - Univ. Hospitals of Geneva'
  },
  {
    id: '2619',
    label: 'HULT Business School',
    value: 'HULT Business School'
  },
  {
    id: '2620',
    label: 'HWP - Hamburger Universität für Wirtschaft und Politik',
    value: 'HWP - Hamburger Universität für Wirtschaft und Politik'
  },
  {
    id: '2621',
    label: 'HWZ - University of Applied Sciences in Business Administration Zurich',
    value: 'HWZ - University of Applied Sciences in Business Administration Zurich'
  },
  {
    id: '2622',
    label: 'Hacettepe University',
    value: 'Hacettepe University'
  },
  {
    id: '2623',
    label: 'Hachinohe Institute of Technology',
    value: 'Hachinohe Institute of Technology'
  },
  {
    id: '2624',
    label: 'Hachinohe University',
    value: 'Hachinohe University'
  },
  {
    id: '2625',
    label: 'Hadhramout University of Science and Technology',
    value: 'Hadhramout University of Science and Technology'
  },
  {
    id: '2626',
    label: 'Hafencity Universität Hamburg',
    value: 'Hafencity Universität Hamburg'
  },
  {
    id: '2627',
    label: 'Hagerstown Community College',
    value: 'Hagerstown Community College'
  },
  {
    id: '2628',
    label: 'Hahnamann Honoeopathic Medical College',
    value: 'Hahnamann Honoeopathic Medical College'
  },
  {
    id: '2629',
    label: 'Hai Duong University of  Economics and Technology',
    value: 'Hai Duong University of  Economics and Technology'
  },
  {
    id: '2630',
    label: 'Haigazian University',
    value: 'Haigazian University'
  },
  {
    id: '2631',
    label: 'Hainan Normal University',
    value: 'Hainan Normal University'
  },
  {
    id: '2632',
    label: 'Hainan University',
    value: 'Hainan University'
  },
  {
    id: '2633',
    label: 'Hajee Mohammad Danesh Science and Technology University',
    value: 'Hajee Mohammad Danesh Science and Technology University'
  },
  {
    id: '2634',
    label: 'Hajvery University Lahore for Women',
    value: 'Hajvery University Lahore for Women'
  },
  {
    id: '2635',
    label: 'Hakkari University',
    value: 'Hakkari University'
  },
  {
    id: '2636',
    label: 'Hakodate University',
    value: 'Hakodate University'
  },
  {
    id: '2637',
    label: 'Hakuoh University',
    value: 'Hakuoh University'
  },
  {
    id: '2638',
    label: 'Halic University',
    value: 'Halic University'
  },
  {
    id: '2639',
    label: 'Halifax Community College',
    value: 'Halifax Community College'
  },
  {
    id: '2640',
    label: 'Halla University',
    value: 'Halla University'
  },
  {
    id: '2641',
    label: 'Hallmark University',
    value: 'Hallmark University'
  },
  {
    id: '2642',
    label: 'Hallym University',
    value: 'Hallym University'
  },
  {
    id: '2643',
    label: 'Halmstad University College',
    value: 'Halmstad University College'
  },
  {
    id: '2644',
    label: 'Hamadan University of Medical Sciences',
    value: 'Hamadan University of Medical Sciences'
  },
  {
    id: '2645',
    label: 'Hamamatsu University',
    value: 'Hamamatsu University'
  },
  {
    id: '2646',
    label: 'Hamamatsu University School of Medicine',
    value: 'Hamamatsu University School of Medicine'
  },
  {
    id: '2647',
    label: 'Hamburg University of Applied Sciences (HAW Hamburg)',
    value: 'Hamburg University of Applied Sciences (HAW Hamburg)'
  },
  {
    id: '2648',
    label: 'Hamdan Bin Mohammed e-University',
    value: 'Hamdan Bin Mohammed e-University'
  },
  {
    id: '2649',
    label: 'Hamdard University',
    value: 'Hamdard University'
  },
  {
    id: '2650',
    label: 'Hamilton College',
    value: 'Hamilton College'
  },
  {
    id: '2651',
    label: 'Hamline University',
    value: 'Hamline University'
  },
  {
    id: '2652',
    label: 'Hampden-Sydney College',
    value: 'Hampden-Sydney College'
  },
  {
    id: '2653',
    label: 'Hampshire College',
    value: 'Hampshire College'
  },
  {
    id: '2654',
    label: 'Hampton University',
    value: 'Hampton University'
  },
  {
    id: '2655',
    label: 'Hanazono University',
    value: 'Hanazono University'
  },
  {
    id: '2656',
    label: 'Hanbat National University',
    value: 'Hanbat National University'
  },
  {
    id: '2657',
    label: 'Handelshochschule Leipzig',
    value: 'Handelshochschule Leipzig'
  },
  {
    id: '2658',
    label: 'Handong Global University',
    value: 'Handong Global University'
  },
  {
    id: '2659',
    label: 'Hanil University and Presbyterian Theological Seminary',
    value: 'Hanil University and Presbyterian Theological Seminary'
  },
  {
    id: '2660',
    label: 'Hankuk Aviation University',
    value: 'Hankuk Aviation University'
  },
  {
    id: '2661',
    label: 'Hankuk University of Foreign Studies',
    value: 'Hankuk University of Foreign Studies'
  },
  {
    id: '2662',
    label: 'Hankyong National University',
    value: 'Hankyong National University'
  },
  {
    id: '2663',
    label: 'Hanlyo University',
    value: 'Hanlyo University'
  },
  {
    id: '2664',
    label: 'Hannam University',
    value: 'Hannam University'
  },
  {
    id: '2665',
    label: 'Hannan University',
    value: 'Hannan University'
  },
  {
    id: '2666',
    label: 'Hanoi Medical University',
    value: 'Hanoi Medical University'
  },
  {
    id: '2667',
    label: 'Hanoi National Economics University',
    value: 'Hanoi National Economics University'
  },
  {
    id: '2668',
    label: 'Hanoi Open University',
    value: 'Hanoi Open University'
  },
  {
    id: '2669',
    label: 'Hanoi Univerisity of Transportation',
    value: 'Hanoi Univerisity of Transportation'
  },
  {
    id: '2670',
    label: 'Hanoi University of Agriculture',
    value: 'Hanoi University of Agriculture'
  },
  {
    id: '2671',
    label: 'Hanoi University of Architecture',
    value: 'Hanoi University of Architecture'
  },
  {
    id: '2672',
    label: 'Hanoi University of Civil Engineering',
    value: 'Hanoi University of Civil Engineering'
  },
  {
    id: '2673',
    label: 'Hanoi University of Mining and Geology',
    value: 'Hanoi University of Mining and Geology'
  },
  {
    id: '2674',
    label: 'Hanoi University of Science',
    value: 'Hanoi University of Science'
  },
  {
    id: '2675',
    label: 'Hanoi University of Science and Technology',
    value: 'Hanoi University of Science and Technology'
  },
  {
    id: '2676',
    label: 'Hanoi University of Technology',
    value: 'Hanoi University of Technology'
  },
  {
    id: '2677',
    label: 'Hanover College',
    value: 'Hanover College'
  },
  {
    id: '2678',
    label: 'Hansei University',
    value: 'Hansei University'
  },
  {
    id: '2679',
    label: 'Hanseo University',
    value: 'Hanseo University'
  },
  {
    id: '2680',
    label: 'Hanshin University',
    value: 'Hanshin University'
  },
  {
    id: '2681',
    label: 'Hansung University Seoul',
    value: 'Hansung University Seoul'
  },
  {
    id: '2682',
    label: 'Hanyang University',
    value: 'Hanyang University'
  },
  {
    id: '2683',
    label: 'Hanze University Groningen',
    value: 'Hanze University Groningen'
  },
  {
    id: '2684',
    label: 'Hanzhong University',
    value: 'Hanzhong University'
  },
  {
    id: '2685',
    label: 'Haramaya University',
    value: 'Haramaya University'
  },
  {
    id: '2686',
    label: 'Harare Institute of Technology',
    value: 'Harare Institute of Technology'
  },
  {
    id: '2687',
    label: 'Harbin Engineering University',
    value: 'Harbin Engineering University'
  },
  {
    id: '2688',
    label: 'Harbin Institute of Technology',
    value: 'Harbin Institute of Technology'
  },
  {
    id: '2689',
    label: 'Harbin Medical University',
    value: 'Harbin Medical University'
  },
  {
    id: '2690',
    label: 'Harbin Normal University',
    value: 'Harbin Normal University'
  },
  {
    id: '2691',
    label: 'Harbin University of Civil Engineering & Architecture',
    value: 'Harbin University of Civil Engineering & Architecture'
  },
  {
    id: '2692',
    label: 'Harbin University of Science and Technology',
    value: 'Harbin University of Science and Technology'
  },
  {
    id: '2693',
    label: 'Harcum College',
    value: 'Harcum College'
  },
  {
    id: '2694',
    label: 'Harding University',
    value: 'Harding University'
  },
  {
    id: '2695',
    label: 'Harford Community College',
    value: 'Harford Community College'
  },
  {
    id: '2696',
    label: 'Hariri Canadian University',
    value: 'Hariri Canadian University'
  },
  {
    id: '2697',
    label: 'Harokopio University',
    value: 'Harokopio University'
  },
  {
    id: '2698',
    label: 'Harper College',
    value: 'Harper College'
  },
  {
    id: '2699',
    label: 'Harran University',
    value: 'Harran University'
  },
  {
    id: '2700',
    label: 'Harris-Stowe State University',
    value: 'Harris-Stowe State University'
  },
  {
    id: '2701',
    label: 'Harrisburg Area Community College-Harrisburg',
    value: 'Harrisburg Area Community College-Harrisburg'
  },
  {
    id: '2702',
    label: 'Hartnell College',
    value: 'Hartnell College'
  },
  {
    id: '2703',
    label: 'Hartwick College',
    value: 'Hartwick College'
  },
  {
    id: '2704',
    label: 'Harvard University',
    value: 'Harvard University'
  },
  {
    id: '2705',
    label: 'Harvey Mudd College',
    value: 'Harvey Mudd College'
  },
  {
    id: '2706',
    label: 'Hasan Kalyoncu University',
    value: 'Hasan Kalyoncu University'
  },
  {
    id: '2707',
    label: 'Hashemite University',
    value: 'Hashemite University'
  },
  {
    id: '2708',
    label: 'Haskell Indian Nations University',
    value: 'Haskell Indian Nations University'
  },
  {
    id: '2709',
    label: 'Hasselt University',
    value: 'Hasselt University'
  },
  {
    id: '2710',
    label: 'Hastings College',
    value: 'Hastings College'
  },
  {
    id: '2711',
    label: 'Hatyai University',
    value: 'Hatyai University'
  },
  {
    id: '2712',
    label: 'Haverford College in Pennsylvania',
    value: 'Haverford College in Pennsylvania'
  },
  {
    id: '2713',
    label: 'Hawaii Community College',
    value: 'Hawaii Community College'
  },
  {
    id: '2714',
    label: 'Hawaii Pacific University',
    value: 'Hawaii Pacific University'
  },
  {
    id: '2715',
    label: 'Hawassa University',
    value: 'Hawassa University'
  },
  {
    id: '2716',
    label: 'Hawkeye Community College',
    value: 'Hawkeye Community College'
  },
  {
    id: '2717',
    label: 'Hawler Medical University',
    value: 'Hawler Medical University'
  },
  {
    id: '2718',
    label: 'Haynal Imre University of Health Sciences Budapest',
    value: 'Haynal Imre University of Health Sciences Budapest'
  },
  {
    id: '2719',
    label: 'Haywood Community College',
    value: 'Haywood Community College'
  },
  {
    id: '2720',
    label: 'Hazara University',
    value: 'Hazara University'
  },
  {
    id: '2721',
    label: 'Hazard Community and Technical College',
    value: 'Hazard Community and Technical College'
  },
  {
    id: '2722',
    label: 'Health Sciences University',
    value: 'Health Sciences University'
  },
  {
    id: '2723',
    label: 'Health sciences University of Mongolia',
    value: 'Health sciences University of Mongolia'
  },
  {
    id: '2724',
    label: 'Heartland Community College',
    value: 'Heartland Community College'
  },
  {
    id: '2725',
    label: 'Hebei Academy of Fine Art',
    value: 'Hebei Academy of Fine Art'
  },
  {
    id: '2726',
    label: 'Hebei Agricultural University',
    value: 'Hebei Agricultural University'
  },
  {
    id: '2727',
    label: 'Hebei Medical University',
    value: 'Hebei Medical University'
  },
  {
    id: '2728',
    label: 'Hebei Normal University',
    value: 'Hebei Normal University'
  },
  {
    id: '2729',
    label: 'Hebei United University',
    value: 'Hebei United University'
  },
  {
    id: '2730',
    label: 'Hebei University',
    value: 'Hebei University'
  },
  {
    id: '2731',
    label: 'Hebei University of Economics and Trade',
    value: 'Hebei University of Economics and Trade'
  },
  {
    id: '2732',
    label: 'Hebei University of Science and Technology',
    value: 'Hebei University of Science and Technology'
  },
  {
    id: '2733',
    label: 'Hebei University of Technology',
    value: 'Hebei University of Technology'
  },
  {
    id: '2734',
    label: 'Hebrew University of Jerusalem',
    value: 'Hebrew University of Jerusalem'
  },
  {
    id: '2735',
    label: 'Hebron University',
    value: 'Hebron University'
  },
  {
    id: '2736',
    label: 'Hefei University of Technology',
    value: 'Hefei University of Technology'
  },
  {
    id: '2737',
    label: 'Hehai University',
    value: 'Hehai University'
  },
  {
    id: '2738',
    label: 'Heidelberg College',
    value: 'Heidelberg College'
  },
  {
    id: '2739',
    label: 'Heilongjiang August 1st Reclamation University',
    value: 'Heilongjiang August 1st Reclamation University'
  },
  {
    id: '2740',
    label: 'Heilongjiang Commercial University',
    value: 'Heilongjiang Commercial University'
  },
  {
    id: '2741',
    label: 'Heilongjiang University',
    value: 'Heilongjiang University'
  },
  {
    id: '2742',
    label: 'Heinrich-Heine Universität Düsseldorf',
    value: 'Heinrich-Heine Universität Düsseldorf'
  },
  {
    id: '2743',
    label: 'Heisei International University',
    value: 'Heisei International University'
  },
  {
    id: '2744',
    label: 'Helena College University of Montana',
    value: 'Helena College University of Montana'
  },
  {
    id: '2745',
    label: 'Hellenic Army Academy',
    value: 'Hellenic Army Academy'
  },
  {
    id: '2746',
    label: 'Hellenic Mediterranean University',
    value: 'Hellenic Mediterranean University'
  },
  {
    id: '2747',
    label: 'Hellenic Open University',
    value: 'Hellenic Open University'
  },
  {
    id: '2748',
    label: 'Help University College',
    value: 'Help University College'
  },
  {
    id: '2749',
    label: 'Helsinki School of Economics and Business Administration',
    value: 'Helsinki School of Economics and Business Administration'
  },
  {
    id: '2750',
    label: 'Helsinki University of Technology',
    value: 'Helsinki University of Technology'
  },
  {
    id: '2751',
    label: 'Helwan University',
    value: 'Helwan University'
  },
  {
    id: '2752',
    label: 'Hemchandracharay North Gujarat University',
    value: 'Hemchandracharay North Gujarat University'
  },
  {
    id: '2753',
    label: 'Hemwati Nandan Bahuguna Garhwal University',
    value: 'Hemwati Nandan Bahuguna Garhwal University'
  },
  {
    id: '2754',
    label: 'Henan Agriculture University',
    value: 'Henan Agriculture University'
  },
  {
    id: '2755',
    label: 'Henan Buddhist College',
    value: 'Henan Buddhist College'
  },
  {
    id: '2756',
    label: 'Henan Normal University',
    value: 'Henan Normal University'
  },
  {
    id: '2757',
    label: 'Henan Univeristy',
    value: 'Henan Univeristy'
  },
  {
    id: '2758',
    label: 'Henderson Community College',
    value: 'Henderson Community College'
  },
  {
    id: '2759',
    label: 'Hendrix College',
    value: 'Hendrix College'
  },
  {
    id: '2760',
    label: 'Hennepin Technical College',
    value: 'Hennepin Technical College'
  },
  {
    id: '2761',
    label: 'Henry Ford College',
    value: 'Henry Ford College'
  },
  {
    id: '2762',
    label: 'Herat University',
    value: 'Herat University'
  },
  {
    id: '2763',
    label: 'Heriot-Watt University',
    value: 'Heriot-Watt University'
  },
  {
    id: '2764',
    label: 'Herkimer County Community College',
    value: 'Herkimer County Community College'
  },
  {
    id: '2765',
    label: 'Hertie School of Governance',
    value: 'Hertie School of Governance'
  },
  {
    id: '2766',
    label: 'Herzen State Pedagogical University of Russia',
    value: 'Herzen State Pedagogical University of Russia'
  },
  {
    id: '2767',
    label: 'Hesston College',
    value: 'Hesston College'
  },
  {
    id: '2768',
    label: 'Heythrop College, University of London',
    value: 'Heythrop College, University of London'
  },
  {
    id: '2769',
    label: 'HfH – University of Applied Sciences of Special Needs Education',
    value: 'HfH – University of Applied Sciences of Special Needs Education'
  },
  {
    id: '2770',
    label: 'Hibbing Community College',
    value: 'Hibbing Community College'
  },
  {
    id: '2771',
    label: 'Hidayatullah National Law University, Raipur',
    value: 'Hidayatullah National Law University, Raipur'
  },
  {
    id: '2772',
    label: 'Higashi Nippon International University',
    value: 'Higashi Nippon International University'
  },
  {
    id: '2773',
    label: 'High Institute for Banking & Financial Studies',
    value: 'High Institute for Banking & Financial Studies'
  },
  {
    id: '2774',
    label: 'High Point University',
    value: 'High Point University'
  },
  {
    id: '2775',
    label: 'Higher Colleges of Technology',
    value: 'Higher Colleges of Technology'
  },
  {
    id: '2776',
    label: 'Higher Institute of Agriculture and Animal Husbandry',
    value: 'Higher Institute of Agriculture and Animal Husbandry'
  },
  {
    id: '2777',
    label: 'Higher Institute of Business Administration',
    value: 'Higher Institute of Business Administration'
  },
  {
    id: '2778',
    label: 'Higher School o Business in Tarnow',
    value: 'Higher School o Business in Tarnow'
  },
  {
    id: '2779',
    label: 'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz',
    value: 'Higher School o Business/National Louis University(WSB/NLU) in Nowy Sacz'
  },
  {
    id: '2780',
    label: 'Higher School of Economics',
    value: 'Higher School of Economics'
  },
  {
    id: '2781',
    label: 'Higher School of Psychology',
    value: 'Higher School of Psychology'
  },
  {
    id: '2782',
    label: 'Higher School of University and Advanced Studies Pisa',
    value: 'Higher School of University and Advanced Studies Pisa'
  },
  {
    id: '2783',
    label: 'Higher Technological Institute',
    value: 'Higher Technological Institute'
  },
  {
    id: '2784',
    label: 'Highland Community College',
    value: 'Highland Community College'
  },
  {
    id: '2785',
    label: 'Highland Community College',
    value: 'Highland Community College'
  },
  {
    id: '2786',
    label: 'Highline Community College',
    value: 'Highline Community College'
  },
  {
    id: '2787',
    label: 'Hiiraan University',
    value: 'Hiiraan University'
  },
  {
    id: '2788',
    label: 'Hijiyama University',
    value: 'Hijiyama University'
  },
  {
    id: '2789',
    label: 'Hill College',
    value: 'Hill College'
  },
  {
    id: '2790',
    label: 'Hillsborough Community College',
    value: 'Hillsborough Community College'
  },
  {
    id: '2791',
    label: 'Hillsdale College',
    value: 'Hillsdale College'
  },
  {
    id: '2792',
    label: 'Himachal Pradesh Agricultural University',
    value: 'Himachal Pradesh Agricultural University'
  },
  {
    id: '2793',
    label: 'Himachal Pradesh University',
    value: 'Himachal Pradesh University'
  },
  {
    id: '2794',
    label: 'Himeji Dokkyo University',
    value: 'Himeji Dokkyo University'
  },
  {
    id: '2795',
    label: 'Himeji Institute of Technology',
    value: 'Himeji Institute of Technology'
  },
  {
    id: '2796',
    label: 'Hinds Community College',
    value: 'Hinds Community College'
  },
  {
    id: '2797',
    label: 'Hindustan Institute of Technology and Science',
    value: 'Hindustan Institute of Technology and Science'
  },
  {
    id: '2798',
    label: 'Hiram College',
    value: 'Hiram College'
  },
  {
    id: '2799',
    label: 'Hirosaki Gakuin University',
    value: 'Hirosaki Gakuin University'
  },
  {
    id: '2800',
    label: 'Hirosaki University',
    value: 'Hirosaki University'
  },
  {
    id: '2801',
    label: "Hiroshima Bunkyo Women's University",
    value: "Hiroshima Bunkyo Women's University"
  },
  {
    id: '2802',
    label: 'Hiroshima City University',
    value: 'Hiroshima City University'
  },
  {
    id: '2803',
    label: 'Hiroshima Institute of Technology',
    value: 'Hiroshima Institute of Technology'
  },
  {
    id: '2804',
    label: 'Hiroshima International University',
    value: 'Hiroshima International University'
  },
  {
    id: '2805',
    label: 'Hiroshima Jogakuin University',
    value: 'Hiroshima Jogakuin University'
  },
  {
    id: '2806',
    label: 'Hiroshima Kokusai Gakuin University',
    value: 'Hiroshima Kokusai Gakuin University'
  },
  {
    id: '2807',
    label: 'Hiroshima Prefectural University',
    value: 'Hiroshima Prefectural University'
  },
  {
    id: '2808',
    label: 'Hiroshima Shudo University',
    value: 'Hiroshima Shudo University'
  },
  {
    id: '2809',
    label: 'Hiroshima University',
    value: 'Hiroshima University'
  },
  {
    id: '2810',
    label: 'Hiroshima University of Economics',
    value: 'Hiroshima University of Economics'
  },
  {
    id: '2811',
    label: "Hiroshima Women's University",
    value: "Hiroshima Women's University"
  },
  {
    id: '2812',
    label: 'Hitec University',
    value: 'Hitec University'
  },
  {
    id: '2813',
    label: 'Hitit University',
    value: 'Hitit University'
  },
  {
    id: '2814',
    label: 'Hitotsubashi University',
    value: 'Hitotsubashi University'
  },
  {
    id: '2815',
    label: 'Ho Chi Minh City Open University',
    value: 'Ho Chi Minh City Open University'
  },
  {
    id: '2816',
    label: 'Ho Chi Minh City University of Agriculture and Forestry',
    value: 'Ho Chi Minh City University of Agriculture and Forestry'
  },
  {
    id: '2817',
    label: 'Ho Chi Minh City University of Architecture',
    value: 'Ho Chi Minh City University of Architecture'
  },
  {
    id: '2818',
    label: 'Ho Chi Minh City University of Economics',
    value: 'Ho Chi Minh City University of Economics'
  },
  {
    id: '2819',
    label: 'Ho Chi Minh City University of Foreign Languages and Information Technology',
    value: 'Ho Chi Minh City University of Foreign Languages and Information Technology'
  },
  {
    id: '2820',
    label: 'Ho Chi Minh City University of Law',
    value: 'Ho Chi Minh City University of Law'
  },
  {
    id: '2821',
    label: 'Ho Chi Minh City University of Medicine and Pharmacy',
    value: 'Ho Chi Minh City University of Medicine and Pharmacy'
  },
  {
    id: '2822',
    label: 'Ho Chi Minh City University of Natural Sciences',
    value: 'Ho Chi Minh City University of Natural Sciences'
  },
  {
    id: '2823',
    label: 'Ho Chi Minh City University of Pedagogics',
    value: 'Ho Chi Minh City University of Pedagogics'
  },
  {
    id: '2824',
    label: 'Ho Chi Minh City University of Social Sciences and Humanities',
    value: 'Ho Chi Minh City University of Social Sciences and Humanities'
  },
  {
    id: '2825',
    label: 'Ho Chi Minh City University of Technology',
    value: 'Ho Chi Minh City University of Technology'
  },
  {
    id: '2826',
    label: 'Ho Chi Minh City University of Transport',
    value: 'Ho Chi Minh City University of Transport'
  },
  {
    id: '2827',
    label: 'Ho Technical University',
    value: 'Ho Technical University'
  },
  {
    id: '2828',
    label: 'Hobart and William Smith Colleges',
    value: 'Hobart and William Smith Colleges'
  },
  {
    id: '2829',
    label: 'Hochschule Albstadt-Sigmaringen',
    value: 'Hochschule Albstadt-Sigmaringen'
  },
  {
    id: '2830',
    label: 'Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften',
    value: 'Hochschule Anhalt (FH), Hochschule für angewandte Wissenschaften'
  },
  {
    id: '2831',
    label: 'Hochschule Bonn-Rhein-Sieg',
    value: 'Hochschule Bonn-Rhein-Sieg'
  },
  {
    id: '2832',
    label: 'Hochschule Bremen',
    value: 'Hochschule Bremen'
  },
  {
    id: '2833',
    label: 'Hochschule Bremerhaven',
    value: 'Hochschule Bremerhaven'
  },
  {
    id: '2834',
    label: 'Hochschule Coburg',
    value: 'Hochschule Coburg'
  },
  {
    id: '2835',
    label: 'Hochschule Darmstadt',
    value: 'Hochschule Darmstadt'
  },
  {
    id: '2836',
    label: 'Hochschule Düsseldorf',
    value: 'Hochschule Düsseldorf'
  },
  {
    id: '2837',
    label: 'Hochschule Esslingen',
    value: 'Hochschule Esslingen'
  },
  {
    id: '2838',
    label: 'Hochschule Fulda',
    value: 'Hochschule Fulda'
  },
  {
    id: '2839',
    label: 'Hochschule Hannover',
    value: 'Hochschule Hannover'
  },
  {
    id: '2840',
    label: 'Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)',
    value: 'Hochschule Harz, Hochschule für angewandte Wissenschaften (FH)'
  },
  {
    id: '2841',
    label: 'Hochschule Luzern',
    value: 'Hochschule Luzern'
  },
  {
    id: '2842',
    label: 'Hochschule Magdeburg-Stendal (FH)',
    value: 'Hochschule Magdeburg-Stendal (FH)'
  },
  {
    id: '2843',
    label: 'Hochschule Mittweida, University of Applied Sciences',
    value: 'Hochschule Mittweida, University of Applied Sciences'
  },
  {
    id: '2844',
    label: 'Hochschule München',
    value: 'Hochschule München'
  },
  {
    id: '2845',
    label: 'Hochschule Neu-Ulm, Hochschule Neu-Ulm University of applied sciences',
    value: 'Hochschule Neu-Ulm, Hochschule Neu-Ulm University of applied sciences'
  },
  {
    id: '2846',
    label: 'Hochschule Ulm, Hochschule für Angewandte Wissenschaft',
    value: 'Hochschule Ulm, Hochschule für Angewandte Wissenschaft'
  },
  {
    id: '2847',
    label: 'Hochschule Vechta',
    value: 'Hochschule Vechta'
  },
  {
    id: '2848',
    label: 'Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung',
    value: 'Hochschule Wismar, Fachhochschule für Technik, Wirtschaft und Gestaltung'
  },
  {
    id: '2849',
    label: 'Hochschule Zittau/Görlitz (FH)',
    value: 'Hochschule Zittau/Görlitz (FH)'
  },
  {
    id: '2850',
    label: 'Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie',
    value: 'Hochschule für Bankwirtschaft (HfB), Private Fachhochschule der Bankakademie'
  },
  {
    id: '2851',
    label: 'Hochschule für Fernsehen und Film München',
    value: 'Hochschule für Fernsehen und Film München'
  },
  {
    id: '2852',
    label: 'Hochschule für Gestaltung und Kunst Zürich',
    value: 'Hochschule für Gestaltung und Kunst Zürich'
  },
  {
    id: '2853',
    label: 'Hochschule für Internationales Management',
    value: 'Hochschule für Internationales Management'
  },
  {
    id: '2854',
    label: 'Hochschule für Jüdische Studien Heidelberg',
    value: 'Hochschule für Jüdische Studien Heidelberg'
  },
  {
    id: '2855',
    label: 'Hochschule für Künste Bremen',
    value: 'Hochschule für Künste Bremen'
  },
  {
    id: '2856',
    label: 'Hochschule für Musik Carl Maria von Weber',
    value: 'Hochschule für Musik Carl Maria von Weber'
  },
  {
    id: '2857',
    label: 'Hochschule für Musik Detmold',
    value: 'Hochschule für Musik Detmold'
  },
  {
    id: '2858',
    label: 'Hochschule für Musik und Darstellende Kunst Stuttgart',
    value: 'Hochschule für Musik und Darstellende Kunst Stuttgart'
  },
  {
    id: '2859',
    label: 'Hochschule für Musik und Tanz Köln',
    value: 'Hochschule für Musik und Tanz Köln'
  },
  {
    id: '2860',
    label: 'Hochschule für Musik und Theater München',
    value: 'Hochschule für Musik und Theater München'
  },
  {
    id: '2861',
    label: 'Hochschule für Musik und Theater „Felix Mendelssohn Bartholdy“ Leipzig',
    value: 'Hochschule für Musik und Theater „Felix Mendelssohn Bartholdy“ Leipzig'
  },
  {
    id: '2862',
    label: 'Hochschule für Musik, Theater und Medien Hannover',
    value: 'Hochschule für Musik, Theater und Medien Hannover'
  },
  {
    id: '2863',
    label: 'Hochschule für Philosophie München',
    value: 'Hochschule für Philosophie München'
  },
  {
    id: '2864',
    label: 'Hochschule für Politik (HFP)',
    value: 'Hochschule für Politik (HFP)'
  },
  {
    id: '2865',
    label: 'Hochschule für Technik und Wirtschaft Berlin',
    value: 'Hochschule für Technik und Wirtschaft Berlin'
  },
  {
    id: '2866',
    label: 'Hochschule für Technik und Wirtschaft Dresden (FH)',
    value: 'Hochschule für Technik und Wirtschaft Dresden (FH)'
  },
  {
    id: '2867',
    label: 'Hochschule für Technik und Wirtschaft des Saarlandes',
    value: 'Hochschule für Technik und Wirtschaft des Saarlandes'
  },
  {
    id: '2868',
    label: 'Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)',
    value: 'Hochschule für Technik, Wirtschaft und Kultur Leipzig (FH)'
  },
  {
    id: '2869',
    label: 'Hochschule für Wirtschaft und Gesellschaft Ludwigshafen',
    value: 'Hochschule für Wirtschaft und Gesellschaft Ludwigshafen'
  },
  {
    id: '2870',
    label: 'Hocking College',
    value: 'Hocking College'
  },
  {
    id: '2871',
    label: 'Hodeidah University',
    value: 'Hodeidah University'
  },
  {
    id: '2872',
    label: 'Hofstra University',
    value: 'Hofstra University'
  },
  {
    id: '2873',
    label: 'Hogere Zeevaartschool - Maritime Academy',
    value: 'Hogere Zeevaartschool - Maritime Academy'
  },
  {
    id: '2874',
    label: 'Hogeschool Antwerpen',
    value: 'Hogeschool Antwerpen'
  },
  {
    id: '2875',
    label: 'Hogeschool Gent',
    value: 'Hogeschool Gent'
  },
  {
    id: '2876',
    label: 'Hogeschool Inholland',
    value: 'Hogeschool Inholland'
  },
  {
    id: '2877',
    label: 'Hogeschool Leiden',
    value: 'Hogeschool Leiden'
  },
  {
    id: '2878',
    label: 'Hogeschool Rotterdam',
    value: 'Hogeschool Rotterdam'
  },
  {
    id: '2879',
    label: 'Hogeschool Utrecht',
    value: 'Hogeschool Utrecht'
  },
  {
    id: '2880',
    label: 'Hogeschool West-Vlaanderen (TU)',
    value: 'Hogeschool West-Vlaanderen (TU)'
  },
  {
    id: '2881',
    label: 'Hogeschool van Amsterdam',
    value: 'Hogeschool van Amsterdam'
  },
  {
    id: '2882',
    label: 'Hogeschool voor Wetenschap & Kunst',
    value: 'Hogeschool voor Wetenschap & Kunst'
  },
  {
    id: '2883',
    label: 'Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel',
    value: 'Hogeschool voor Wetenschap en Kunst (VLEKHO), Brussel'
  },
  {
    id: '2884',
    label: 'Hogeschool voor de Kunsten Utrecht (HKU)',
    value: 'Hogeschool voor de Kunsten Utrecht (HKU)'
  },
  {
    id: '2885',
    label: 'Hohai University Changzhou',
    value: 'Hohai University Changzhou'
  },
  {
    id: '2886',
    label: 'Hokkaido Information University',
    value: 'Hokkaido Information University'
  },
  {
    id: '2887',
    label: 'Hokkaido Institute of Pharmaceutical Sciences',
    value: 'Hokkaido Institute of Pharmaceutical Sciences'
  },
  {
    id: '2888',
    label: 'Hokkaido Institute of Technology',
    value: 'Hokkaido Institute of Technology'
  },
  {
    id: '2889',
    label: 'Hokkaido Tokai University',
    value: 'Hokkaido Tokai University'
  },
  {
    id: '2890',
    label: 'Hokkaido University',
    value: 'Hokkaido University'
  },
  {
    id: '2891',
    label: 'Hokkaido University of Education',
    value: 'Hokkaido University of Education'
  },
  {
    id: '2892',
    label: 'Hokkaido University of Health Sciences',
    value: 'Hokkaido University of Health Sciences'
  },
  {
    id: '2893',
    label: 'Hokkaigakuen University',
    value: 'Hokkaigakuen University'
  },
  {
    id: '2894',
    label: 'Hokkaigakuen University of Kitami',
    value: 'Hokkaigakuen University of Kitami'
  },
  {
    id: '2895',
    label: 'Hokuriku University',
    value: 'Hokuriku University'
  },
  {
    id: '2896',
    label: 'Hokusei Gakuen University',
    value: 'Hokusei Gakuen University'
  },
  {
    id: '2897',
    label: 'Holar University College',
    value: 'Holar University College'
  },
  {
    id: '2898',
    label: 'Hollins College',
    value: 'Hollins College'
  },
  {
    id: '2899',
    label: 'Holmes Community College',
    value: 'Holmes Community College'
  },
  {
    id: '2900',
    label: 'Holmes Institute',
    value: 'Holmes Institute'
  },
  {
    id: '2901',
    label: 'Holy Angel University',
    value: 'Holy Angel University'
  },
  {
    id: '2902',
    label: 'Holy Spirit University of Kaslik',
    value: 'Holy Spirit University of Kaslik'
  },
  {
    id: '2903',
    label: 'Holyoke Community College',
    value: 'Holyoke Community College'
  },
  {
    id: '2904',
    label: 'Honam Theological University and Seminary',
    value: 'Honam Theological University and Seminary'
  },
  {
    id: '2905',
    label: 'Honam University',
    value: 'Honam University'
  },
  {
    id: '2906',
    label: 'Hong Kong Baptist University',
    value: 'Hong Kong Baptist University'
  },
  {
    id: '2907',
    label: 'Hong Kong Chu Hai College',
    value: 'Hong Kong Chu Hai College'
  },
  {
    id: '2908',
    label: 'Hong Kong Metropolitan University',
    value: 'Hong Kong Metropolitan University'
  },
  {
    id: '2909',
    label: 'Hong Kong Shue Yan University',
    value: 'Hong Kong Shue Yan University'
  },
  {
    id: '2910',
    label: 'Hongik University',
    value: 'Hongik University'
  },
  {
    id: '2911',
    label: 'Honolulu Community College',
    value: 'Honolulu Community College'
  },
  {
    id: '2912',
    label: 'Hood College',
    value: 'Hood College'
  },
  {
    id: '2913',
    label: 'Hood Theological',
    value: 'Hood Theological'
  },
  {
    id: '2914',
    label: 'Hope Africa University',
    value: 'Hope Africa University'
  },
  {
    id: '2915',
    label: 'Hope College',
    value: 'Hope College'
  },
  {
    id: '2916',
    label: 'Hopkinsville Community College',
    value: 'Hopkinsville Community College'
  },
  {
    id: '2917',
    label: 'Hormozgan University of Medical Sciences',
    value: 'Hormozgan University of Medical Sciences'
  },
  {
    id: '2918',
    label: 'Horry-Georgetown Technical College',
    value: 'Horry-Georgetown Technical College'
  },
  {
    id: '2919',
    label: 'Hosei University',
    value: 'Hosei University'
  },
  {
    id: '2920',
    label: 'Hoseo University',
    value: 'Hoseo University'
  },
  {
    id: '2921',
    label: 'Hoshi University',
    value: 'Hoshi University'
  },
  {
    id: '2922',
    label: 'Hotelschool The Hague',
    value: 'Hotelschool The Hague'
  },
  {
    id: '2923',
    label: 'Houdegbe North American University Benin',
    value: 'Houdegbe North American University Benin'
  },
  {
    id: '2924',
    label: 'Housatonic Community College',
    value: 'Housatonic Community College'
  },
  {
    id: '2925',
    label: 'Houston Baptist University',
    value: 'Houston Baptist University'
  },
  {
    id: '2926',
    label: 'Houston Community College',
    value: 'Houston Community College'
  },
  {
    id: '2927',
    label: 'Howard College',
    value: 'Howard College'
  },
  {
    id: '2928',
    label: 'Howard Community College',
    value: 'Howard Community College'
  },
  {
    id: '2929',
    label: 'Howard University',
    value: 'Howard University'
  },
  {
    id: '2930',
    label: 'Howest University of Applied Sciences',
    value: 'Howest University of Applied Sciences'
  },
  {
    id: '2931',
    label: 'Hsuan Chuang University',
    value: 'Hsuan Chuang University'
  },
  {
    id: '2932',
    label: 'Huachiew Chalermprakiet University',
    value: 'Huachiew Chalermprakiet University'
  },
  {
    id: '2933',
    label: 'Huafan University',
    value: 'Huafan University'
  },
  {
    id: '2934',
    label: 'Huaihai Institute of Technology',
    value: 'Huaihai Institute of Technology'
  },
  {
    id: '2935',
    label: 'Huaihua Medical College',
    value: 'Huaihua Medical College'
  },
  {
    id: '2936',
    label: 'Huaihua Radio and Television University',
    value: 'Huaihua Radio and Television University'
  },
  {
    id: '2937',
    label: 'Huanghe Science & Technology University',
    value: 'Huanghe Science & Technology University'
  },
  {
    id: '2938',
    label: 'Huaqiao University Quanzhuo',
    value: 'Huaqiao University Quanzhuo'
  },
  {
    id: '2939',
    label: 'Huazhong Agricultural University',
    value: 'Huazhong Agricultural University'
  },
  {
    id: '2940',
    label: 'Huazhong University of Science and Technology',
    value: 'Huazhong University of Science and Technology'
  },
  {
    id: '2941',
    label: 'Hubei Business College',
    value: 'Hubei Business College'
  },
  {
    id: '2942',
    label: 'Hubei University',
    value: 'Hubei University'
  },
  {
    id: '2943',
    label: 'Hubert Kairuki Memorial University',
    value: 'Hubert Kairuki Memorial University'
  },
  {
    id: '2944',
    label: 'Hudson County Community College',
    value: 'Hudson County Community College'
  },
  {
    id: '2945',
    label: 'Hudson Valley Community College',
    value: 'Hudson Valley Community College'
  },
  {
    id: '2946',
    label: 'Hue University',
    value: 'Hue University'
  },
  {
    id: '2947',
    label: 'Hue University of Agriculture and Forestry',
    value: 'Hue University of Agriculture and Forestry'
  },
  {
    id: '2948',
    label: 'Huizhou University',
    value: 'Huizhou University'
  },
  {
    id: '2949',
    label: 'Humber College',
    value: 'Humber College'
  },
  {
    id: '2950',
    label: 'Humboldt State University',
    value: 'Humboldt State University'
  },
  {
    id: '2951',
    label: 'Humboldt Universität Berlin',
    value: 'Humboldt Universität Berlin'
  },
  {
    id: '2952',
    label: 'Hunan Agricultural University',
    value: 'Hunan Agricultural University'
  },
  {
    id: '2953',
    label: 'Hunan Normal University',
    value: 'Hunan Normal University'
  },
  {
    id: '2954',
    label: 'Hunan University',
    value: 'Hunan University'
  },
  {
    id: '2955',
    label: 'Hungarian Academy of Craft and Design',
    value: 'Hungarian Academy of Craft and Design'
  },
  {
    id: '2956',
    label: 'Hungarian Academy of Fine Arts Budapest',
    value: 'Hungarian Academy of Fine Arts Budapest'
  },
  {
    id: '2957',
    label: 'Hungarian University of Physical Education',
    value: 'Hungarian University of Physical Education'
  },
  {
    id: '2958',
    label: 'Hunter College CUNY',
    value: 'Hunter College CUNY'
  },
  {
    id: '2959',
    label: 'Huntingdon College',
    value: 'Huntingdon College'
  },
  {
    id: '2960',
    label: 'Huntington Junior College',
    value: 'Huntington Junior College'
  },
  {
    id: '2961',
    label: 'Huntington University',
    value: 'Huntington University'
  },
  {
    id: '2962',
    label: 'Huron University College',
    value: 'Huron University College'
  },
  {
    id: '2963',
    label: 'Huron University USA in London',
    value: 'Huron University USA in London'
  },
  {
    id: '2964',
    label: 'Huston-Tillotson University',
    value: 'Huston-Tillotson University'
  },
  {
    id: '2965',
    label: 'Hutchinson Community College',
    value: 'Hutchinson Community College'
  },
  {
    id: '2966',
    label: 'Hvanneyri Agricultural University',
    value: 'Hvanneyri Agricultural University'
  },
  {
    id: '2967',
    label: 'Hyogo College of Medicine',
    value: 'Hyogo College of Medicine'
  },
  {
    id: '2968',
    label: 'Hyogo University',
    value: 'Hyogo University'
  },
  {
    id: '2969',
    label: 'Hyogo University of Education',
    value: 'Hyogo University of Education'
  },
  {
    id: '2970',
    label: 'Hyrcania Institute of Higher Education',
    value: 'Hyrcania Institute of Higher Education'
  },
  {
    id: '2971',
    label: 'Hyupsung University',
    value: 'Hyupsung University'
  },
  {
    id: '2972',
    label: 'Häme University of Applied Sciences',
    value: 'Häme University of Applied Sciences'
  },
  {
    id: '2973',
    label: 'I-Shou University',
    value: 'I-Shou University'
  },
  {
    id: '2974',
    label: 'I.M. Sechenov Moscow Medical Academy',
    value: 'I.M. Sechenov Moscow Medical Academy'
  },
  {
    id: '2975',
    label: 'IASE (Institute of Advanced Studies) Deemed University',
    value: 'IASE (Institute of Advanced Studies) Deemed University'
  },
  {
    id: '2976',
    label: 'IBAIS University',
    value: 'IBAIS University'
  },
  {
    id: '2977',
    label: 'ICFAI University',
    value: 'ICFAI University'
  },
  {
    id: '2978',
    label: 'ICFAI University',
    value: 'ICFAI University'
  },
  {
    id: '2979',
    label: 'ICFAI University, Dehradun',
    value: 'ICFAI University, Dehradun'
  },
  {
    id: '2980',
    label: 'ICFAI University, Himachal Pradesh',
    value: 'ICFAI University, Himachal Pradesh'
  },
  {
    id: '2981',
    label: 'ICFAI University, Hyderabad',
    value: 'ICFAI University, Hyderabad'
  },
  {
    id: '2982',
    label: 'ICFAI University, Jaipur',
    value: 'ICFAI University, Jaipur'
  },
  {
    id: '2983',
    label: 'ICFAI University, Jharkhand',
    value: 'ICFAI University, Jharkhand'
  },
  {
    id: '2984',
    label: 'ICFAI University, Meghalaya',
    value: 'ICFAI University, Meghalaya'
  },
  {
    id: '2985',
    label: 'ICFAI University, Mizoram',
    value: 'ICFAI University, Mizoram'
  },
  {
    id: '2986',
    label: 'ICFAI University, Nagaland',
    value: 'ICFAI University, Nagaland'
  },
  {
    id: '2987',
    label: 'ICFAI University, Raipur',
    value: 'ICFAI University, Raipur'
  },
  {
    id: '2988',
    label: 'ICFAI University, Sikkim',
    value: 'ICFAI University, Sikkim'
  },
  {
    id: '2989',
    label: 'ICI University',
    value: 'ICI University'
  },
  {
    id: '2990',
    label: 'IDRAC (Institut de recherche en action commerciale)',
    value: 'IDRAC (Institut de recherche en action commerciale)'
  },
  {
    id: '2991',
    label: 'IE University',
    value: 'IE University'
  },
  {
    id: '2992',
    label: 'IESE Business School',
    value: 'IESE Business School'
  },
  {
    id: '2993',
    label: 'IIT Bhubaneswar',
    value: 'IIT Bhubaneswar'
  },
  {
    id: '2994',
    label: 'ILIRIA College',
    value: 'ILIRIA College'
  },
  {
    id: '2995',
    label: 'IMD - International Institute for Management Development',
    value: 'IMD - International Institute for Management Development'
  },
  {
    id: '2996',
    label: 'IMT Atlantique',
    value: 'IMT Atlantique'
  },
  {
    id: '2997',
    label: 'INSEAD',
    value: 'INSEAD'
  },
  {
    id: '2998',
    label: 'INU Champollion',
    value: 'INU Champollion'
  },
  {
    id: '2999',
    label: 'ISCTE - Instituto Universitário de Lisboa',
    value: 'ISCTE - Instituto Universitário de Lisboa'
  },
  {
    id: '3000',
    label: 'ISFORT - Institut de Formation en Technologie Alimentaire',
    value: 'ISFORT - Institut de Formation en Technologie Alimentaire'
  },
  {
    id: '3001',
    label: 'IST-Hochschule fur Management',
    value: 'IST-Hochschule fur Management'
  },
  {
    id: '3002',
    label: 'IT University of Copenhagen',
    value: 'IT University of Copenhagen'
  },
  {
    id: '3003',
    label: 'ITMO University',
    value: 'ITMO University'
  },
  {
    id: '3004',
    label: 'ITT Technical Institute',
    value: 'ITT Technical Institute'
  },
  {
    id: '3005',
    label: 'Iact College',
    value: 'Iact College'
  },
  {
    id: '3006',
    label: 'Ibaraki Christian College',
    value: 'Ibaraki Christian College'
  },
  {
    id: '3007',
    label: 'Ibaraki Prefectural University of Health Sciences',
    value: 'Ibaraki Prefectural University of Health Sciences'
  },
  {
    id: '3008',
    label: 'Ibaraki University',
    value: 'Ibaraki University'
  },
  {
    id: '3009',
    label: 'Ibn Sina National College for Medical Studies',
    value: 'Ibn Sina National College for Medical Studies'
  },
  {
    id: '3010',
    label: 'Ibn Sina University',
    value: 'Ibn Sina University'
  },
  {
    id: '3011',
    label: 'Ibra College of Technology',
    value: 'Ibra College of Technology'
  },
  {
    id: '3012',
    label: 'Ibrahim Babangida University',
    value: 'Ibrahim Babangida University'
  },
  {
    id: '3013',
    label: 'Icahn School of Medicine at Mount Sinai',
    value: 'Icahn School of Medicine at Mount Sinai'
  },
  {
    id: '3014',
    label: 'Iceland Academy of the Arts',
    value: 'Iceland Academy of the Arts'
  },
  {
    id: '3015',
    label: 'Iceland University of Education',
    value: 'Iceland University of Education'
  },
  {
    id: '3016',
    label: 'Idaho State University',
    value: 'Idaho State University'
  },
  {
    id: '3017',
    label: 'Igbinedion University',
    value: 'Igbinedion University'
  },
  {
    id: '3018',
    label: 'Igdir University',
    value: 'Igdir University'
  },
  {
    id: '3019',
    label: 'Ilam University',
    value: 'Ilam University'
  },
  {
    id: '3020',
    label: 'Ilam University of Medical Sciences',
    value: 'Ilam University of Medical Sciences'
  },
  {
    id: '3021',
    label: 'Ilia Chavchavadze State University',
    value: 'Ilia Chavchavadze State University'
  },
  {
    id: '3022',
    label: 'Ilisagvik College',
    value: 'Ilisagvik College'
  },
  {
    id: '3023',
    label: 'Illinois Benedictine College',
    value: 'Illinois Benedictine College'
  },
  {
    id: '3024',
    label: 'Illinois Central College',
    value: 'Illinois Central College'
  },
  {
    id: '3025',
    label: 'Illinois Eastern Community Colleges',
    value: 'Illinois Eastern Community Colleges'
  },
  {
    id: '3026',
    label: 'Illinois Institute of Technology',
    value: 'Illinois Institute of Technology'
  },
  {
    id: '3027',
    label: 'Illinois State University',
    value: 'Illinois State University'
  },
  {
    id: '3028',
    label: 'Illinois Valley Community College',
    value: 'Illinois Valley Community College'
  },
  {
    id: '3029',
    label: 'Illinois Wesleyan University',
    value: 'Illinois Wesleyan University'
  },
  {
    id: '3030',
    label: 'Ilsa Independent College',
    value: 'Ilsa Independent College'
  },
  {
    id: '3031',
    label: 'Imam Abdulrahman Bin Faisal University',
    value: 'Imam Abdulrahman Bin Faisal University'
  },
  {
    id: '3032',
    label: 'Imam Khomeini International University',
    value: 'Imam Khomeini International University'
  },
  {
    id: '3033',
    label: 'Imam Reza University',
    value: 'Imam Reza University'
  },
  {
    id: '3034',
    label: 'Imam Sadegh University',
    value: 'Imam Sadegh University'
  },
  {
    id: '3035',
    label: 'Immanuel Kant State University of Russia',
    value: 'Immanuel Kant State University of Russia'
  },
  {
    id: '3036',
    label: 'Imo State University',
    value: 'Imo State University'
  },
  {
    id: '3037',
    label: 'Imperial College London',
    value: 'Imperial College London'
  },
  {
    id: '3038',
    label: 'Imperial College School of Medicine',
    value: 'Imperial College School of Medicine'
  },
  {
    id: '3039',
    label: 'Imperial Valley College',
    value: 'Imperial Valley College'
  },
  {
    id: '3040',
    label: 'Incheon Catholic University',
    value: 'Incheon Catholic University'
  },
  {
    id: '3041',
    label: 'Inchon National University of Education',
    value: 'Inchon National University of Education'
  },
  {
    id: '3042',
    label: 'Inchon University',
    value: 'Inchon University'
  },
  {
    id: '3043',
    label: 'Independence Community College',
    value: 'Independence Community College'
  },
  {
    id: '3044',
    label: 'Independent University, Bangladesh',
    value: 'Independent University, Bangladesh'
  },
  {
    id: '3045',
    label: 'Indian Agricultural Research Institute',
    value: 'Indian Agricultural Research Institute'
  },
  {
    id: '3046',
    label: 'Indian Board of Alternative Medicine',
    value: 'Indian Board of Alternative Medicine'
  },
  {
    id: '3047',
    label: 'Indian Hills Community College',
    value: 'Indian Hills Community College'
  },
  {
    id: '3048',
    label: 'Indian Institute of Information Technology Allahabad',
    value: 'Indian Institute of Information Technology Allahabad'
  },
  {
    id: '3049',
    label: 'Indian Institute of Information Technology Sri City',
    value: 'Indian Institute of Information Technology Sri City'
  },
  {
    id: '3050',
    label: 'Indian Institute of Information Technology and Management - Kerala',
    value: 'Indian Institute of Information Technology and Management - Kerala'
  },
  {
    id: '3051',
    label: 'Indian Institute of Information Technology, Guwahati',
    value: 'Indian Institute of Information Technology, Guwahati'
  },
  {
    id: '3052',
    label: 'Indian Institute of Information Technology, Kota',
    value: 'Indian Institute of Information Technology, Kota'
  },
  {
    id: '3053',
    label: 'Indian Institute of Information Technology, Vadodara',
    value: 'Indian Institute of Information Technology, Vadodara'
  },
  {
    id: '3054',
    label: 'Indian Institute of Management, Tiruchirappalli',
    value: 'Indian Institute of Management, Tiruchirappalli'
  },
  {
    id: '3055',
    label: 'Indian Institute of Science',
    value: 'Indian Institute of Science'
  },
  {
    id: '3056',
    label: 'Indian Institute of Technology, Bombay',
    value: 'Indian Institute of Technology, Bombay'
  },
  {
    id: '3057',
    label: 'Indian Institute of Technology, Delhi',
    value: 'Indian Institute of Technology, Delhi'
  },
  {
    id: '3058',
    label: 'Indian Institute of Technology, Gandhinagar',
    value: 'Indian Institute of Technology, Gandhinagar'
  },
  {
    id: '3059',
    label: 'Indian Institute of Technology, Guwahati',
    value: 'Indian Institute of Technology, Guwahati'
  },
  {
    id: '3060',
    label: 'Indian Institute of Technology, Hyderabad',
    value: 'Indian Institute of Technology, Hyderabad'
  },
  {
    id: '3061',
    label: 'Indian Institute of Technology, Indore',
    value: 'Indian Institute of Technology, Indore'
  },
  {
    id: '3062',
    label: 'Indian Institute of Technology, Jodhpur',
    value: 'Indian Institute of Technology, Jodhpur'
  },
  {
    id: '3063',
    label: 'Indian Institute of Technology, Kanpur',
    value: 'Indian Institute of Technology, Kanpur'
  },
  {
    id: '3064',
    label: 'Indian Institute of Technology, Kharagpur',
    value: 'Indian Institute of Technology, Kharagpur'
  },
  {
    id: '3065',
    label: 'Indian Institute of Technology, Madras',
    value: 'Indian Institute of Technology, Madras'
  },
  {
    id: '3066',
    label: 'Indian Institute of Technology, Patna',
    value: 'Indian Institute of Technology, Patna'
  },
  {
    id: '3067',
    label: 'Indian Institute of Technology, Roorkee',
    value: 'Indian Institute of Technology, Roorkee'
  },
  {
    id: '3068',
    label: 'Indian Law Institue',
    value: 'Indian Law Institue'
  },
  {
    id: '3069',
    label: 'Indian River State College',
    value: 'Indian River State College'
  },
  {
    id: '3070',
    label: 'Indian School of Business Management and Administration',
    value: 'Indian School of Business Management and Administration'
  },
  {
    id: '3071',
    label: 'Indian School of Mines (ISM)',
    value: 'Indian School of Mines (ISM)'
  },
  {
    id: '3072',
    label: 'Indian Statistical Institute',
    value: 'Indian Statistical Institute'
  },
  {
    id: '3073',
    label: 'Indian University',
    value: 'Indian University'
  },
  {
    id: '3074',
    label: 'Indian Veterinary Research Institute',
    value: 'Indian Veterinary Research Institute'
  },
  {
    id: '3075',
    label: 'Indiana Institute of Technology',
    value: 'Indiana Institute of Technology'
  },
  {
    id: '3076',
    label: 'Indiana State University',
    value: 'Indiana State University'
  },
  {
    id: '3077',
    label: 'Indiana University',
    value: 'Indiana University'
  },
  {
    id: '3078',
    label: 'Indiana University - Bloomington',
    value: 'Indiana University - Bloomington'
  },
  {
    id: '3079',
    label: 'Indiana University Southeast',
    value: 'Indiana University Southeast'
  },
  {
    id: '3080',
    label: 'Indiana University at South Bend',
    value: 'Indiana University at South Bend'
  },
  {
    id: '3081',
    label: 'Indiana University of Pennsylvania',
    value: 'Indiana University of Pennsylvania'
  },
  {
    id: '3082',
    label: 'Indiana University/Purdue University at Columbus',
    value: 'Indiana University/Purdue University at Columbus'
  },
  {
    id: '3083',
    label: 'Indiana University/Purdue University at Fort Wayne',
    value: 'Indiana University/Purdue University at Fort Wayne'
  },
  {
    id: '3084',
    label: 'Indiana University/Purdue University at Indianapolis',
    value: 'Indiana University/Purdue University at Indianapolis'
  },
  {
    id: '3085',
    label: 'Indiana Wesleyan University, Marion',
    value: 'Indiana Wesleyan University, Marion'
  },
  {
    id: '3086',
    label: 'Indira Gandhi Agricultural University',
    value: 'Indira Gandhi Agricultural University'
  },
  {
    id: '3087',
    label: 'Indira Gandhi Institute of Development and Research',
    value: 'Indira Gandhi Institute of Development and Research'
  },
  {
    id: '3088',
    label: 'Indira Gandhi Institute of Medical Sciences',
    value: 'Indira Gandhi Institute of Medical Sciences'
  },
  {
    id: '3089',
    label: 'Indira Gandhi National Open University',
    value: 'Indira Gandhi National Open University'
  },
  {
    id: '3090',
    label: 'Indraprastha Institute of Information Technology, Delhi',
    value: 'Indraprastha Institute of Information Technology, Delhi'
  },
  {
    id: '3091',
    label: 'Indus University',
    value: 'Indus University'
  },
  {
    id: '3092',
    label: 'Indus University',
    value: 'Indus University'
  },
  {
    id: '3093',
    label: 'Informatics Institute of Technology',
    value: 'Informatics Institute of Technology'
  },
  {
    id: '3094',
    label: 'Information Technology University, Lahore',
    value: 'Information Technology University, Lahore'
  },
  {
    id: '3095',
    label: 'Information and Communications Technology Academy',
    value: 'Information and Communications Technology Academy'
  },
  {
    id: '3096',
    label: 'Information and Communications University',
    value: 'Information and Communications University'
  },
  {
    id: '3097',
    label: 'Inha University',
    value: 'Inha University'
  },
  {
    id: '3098',
    label: 'Iniciativa Universidad del Atlantico',
    value: 'Iniciativa Universidad del Atlantico'
  },
  {
    id: '3099',
    label: 'Inje University',
    value: 'Inje University'
  },
  {
    id: '3100',
    label: 'Inner Mongolia Agricultural University',
    value: 'Inner Mongolia Agricultural University'
  },
  {
    id: '3101',
    label: 'Inner Mongolia Normal University',
    value: 'Inner Mongolia Normal University'
  },
  {
    id: '3102',
    label: 'Inner Mongolia Polytechnic University',
    value: 'Inner Mongolia Polytechnic University'
  },
  {
    id: '3103',
    label: 'Inner Mongolia University',
    value: 'Inner Mongolia University'
  },
  {
    id: '3104',
    label: 'Inoorero University',
    value: 'Inoorero University'
  },
  {
    id: '3105',
    label: 'Insel Gruppe',
    value: 'Insel Gruppe'
  },
  {
    id: '3106',
    label: 'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh',
    value: 'Insituto Universitario - Centro Latinoamericano de Economia Humana - IU Claeh'
  },
  {
    id: '3107',
    label: 'Insper Instituto de Ensino e Pesquisa',
    value: 'Insper Instituto de Ensino e Pesquisa'
  },
  {
    id: '3108',
    label: 'Institucion Universitaria de Envigado',
    value: 'Institucion Universitaria de Envigado'
  },
  {
    id: '3109',
    label: 'Institución Universitaria Iberoamericana',
    value: 'Institución Universitaria Iberoamericana'
  },
  {
    id: '3110',
    label: 'Institue of Historical Research, University of London',
    value: 'Institue of Historical Research, University of London'
  },
  {
    id: '3111',
    label: 'Institue of Technology, Tralee',
    value: 'Institue of Technology, Tralee'
  },
  {
    id: '3112',
    label: 'Institut Armand-Frappier, Université du Québec',
    value: 'Institut Armand-Frappier, Université du Québec'
  },
  {
    id: '3113',
    label: "Institut Catholique d'Arts et Métiers Lille",
    value: "Institut Catholique d'Arts et Métiers Lille"
  },
  {
    id: '3114',
    label: 'Institut Catholique de Paris',
    value: 'Institut Catholique de Paris'
  },
  {
    id: '3115',
    label: 'Institut Catholique de Toulouse',
    value: 'Institut Catholique de Toulouse'
  },
  {
    id: '3116',
    label: 'Institut Commercial de Nancy',
    value: 'Institut Commercial de Nancy'
  },
  {
    id: '3117',
    label: 'Institut Le Rosey',
    value: 'Institut Le Rosey'
  },
  {
    id: '3118',
    label: 'Institut National Polytechnique de Lorraine',
    value: 'Institut National Polytechnique de Lorraine'
  },
  {
    id: '3119',
    label: 'Institut National Polytechnique de Toulouse',
    value: 'Institut National Polytechnique de Toulouse'
  },
  {
    id: '3120',
    label: 'Institut National de la Recherche Scientifique, Université du Québec',
    value: 'Institut National de la Recherche Scientifique, Université du Québec'
  },
  {
    id: '3121',
    label: 'Institut National des Postes et Télécommunications',
    value: 'Institut National des Postes et Télécommunications'
  },
  {
    id: '3122',
    label: 'Institut National des Sciences Appliquées de Lyon',
    value: 'Institut National des Sciences Appliquées de Lyon'
  },
  {
    id: '3123',
    label: 'Institut National des Sciences Appliquées de Rennes',
    value: 'Institut National des Sciences Appliquées de Rennes'
  },
  {
    id: '3124',
    label: 'Institut National des Sciences Appliquées de Rouen',
    value: 'Institut National des Sciences Appliquées de Rouen'
  },
  {
    id: '3125',
    label: 'Institut National des Sciences Appliquées de Toulouse',
    value: 'Institut National des Sciences Appliquées de Toulouse'
  },
  {
    id: '3126',
    label: 'Institut National des Télécommunications',
    value: 'Institut National des Télécommunications'
  },
  {
    id: '3127',
    label: 'Institut Prima Bestari - Pine Academy',
    value: 'Institut Prima Bestari - Pine Academy'
  },
  {
    id: '3128',
    label: 'Institut Sains & Teknologi Akprind',
    value: 'Institut Sains & Teknologi Akprind'
  },
  {
    id: '3129',
    label: 'Institut Sains dan Teknologi Al Kamal',
    value: 'Institut Sains dan Teknologi Al Kamal'
  },
  {
    id: '3130',
    label: "Institut Sup'Biotech de Paris",
    value: "Institut Sup'Biotech de Paris"
  },
  {
    id: '3131',
    label: 'Institut Supérieur Agricole de Beauvais',
    value: 'Institut Supérieur Agricole de Beauvais'
  },
  {
    id: '3132',
    label: "Institut Supérieur d'Agriculture Lille",
    value: "Institut Supérieur d'Agriculture Lille"
  },
  {
    id: '3133',
    label: "Institut Supérieur d'Agriculture Rhone-Alpes",
    value: "Institut Supérieur d'Agriculture Rhone-Alpes"
  },
  {
    id: '3134',
    label: "Institut Supérieur de Commerce et d'Administration des Entreprises",
    value: "Institut Supérieur de Commerce et d'Administration des Entreprises"
  },
  {
    id: '3135',
    label: 'Institut Supérieur de Gestion de Tunis',
    value: 'Institut Supérieur de Gestion de Tunis'
  },
  {
    id: '3136',
    label: 'Institut Supérieur de Management et de Technologie (MATCI)',
    value: 'Institut Supérieur de Management et de Technologie (MATCI)'
  },
  {
    id: '3137',
    label: "Institut Supérieure d'Electronique de Paris",
    value: "Institut Supérieure d'Electronique de Paris"
  },
  {
    id: '3138',
    label: "Institut Supérieure d'Electronique du Nord",
    value: "Institut Supérieure d'Electronique du Nord"
  },
  {
    id: '3139',
    label: 'Institut Teknologi Adhi Tama Surabaya',
    value: 'Institut Teknologi Adhi Tama Surabaya'
  },
  {
    id: '3140',
    label: 'Institut Teknologi Bandung',
    value: 'Institut Teknologi Bandung'
  },
  {
    id: '3141',
    label: 'Institut Teknologi Brunei',
    value: 'Institut Teknologi Brunei'
  },
  {
    id: '3142',
    label: 'Institut Teknologi Sepuluh Nopember',
    value: 'Institut Teknologi Sepuluh Nopember'
  },
  {
    id: '3143',
    label: 'Institut Textile et Chimique de Lyon',
    value: 'Institut Textile et Chimique de Lyon'
  },
  {
    id: '3144',
    label: "Institut d'Agriculture, de Technologie et d'Education de Kibungo",
    value: "Institut d'Agriculture, de Technologie et d'Education de Kibungo"
  },
  {
    id: '3145',
    label: "Institut d'Etudes Politiques de Bordeaux",
    value: "Institut d'Etudes Politiques de Bordeaux"
  },
  {
    id: '3146',
    label: "Institut d'Etudes Politiques de Paris (Sciences Po)",
    value: "Institut d'Etudes Politiques de Paris (Sciences Po)"
  },
  {
    id: '3147',
    label: "Institut d'études politiques de Rennes",
    value: "Institut d'études politiques de Rennes"
  },
  {
    id: '3148',
    label: 'Institut de Recherche et Coordination Acoustique/Musique',
    value: 'Institut de Recherche et Coordination Acoustique/Musique'
  },
  {
    id: '3149',
    label: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique",
    value: "Institut de Recherche et d'Enseignement Supérieur aux Techniques de l'électronique"
  },
  {
    id: '3150',
    label: 'Institut des Hautes Etudes de Management',
    value: 'Institut des Hautes Etudes de Management'
  },
  {
    id: '3151',
    label: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand",
    value: "Institut des Sciences de l'Ingénieur de Clermont-Ferrand"
  },
  {
    id: '3152',
    label: "Institut des Sciences de l'Ingénieur de Montpellier",
    value: "Institut des Sciences de l'Ingénieur de Montpellier"
  },
  {
    id: '3153',
    label: 'Institut des Sciences de la Matière et du Rayonnement',
    value: 'Institut des Sciences de la Matière et du Rayonnement'
  },
  {
    id: '3154',
    label: 'Institut des hautes études économiques et commerciales',
    value: 'Institut des hautes études économiques et commerciales'
  },
  {
    id: '3155',
    label: 'Institut polytechnique de Grenoble',
    value: 'Institut polytechnique de Grenoble'
  },
  {
    id: '3156',
    label: 'Institut polytechnique des sciences avancees',
    value: 'Institut polytechnique des sciences avancees'
  },
  {
    id: '3157',
    label: "Institut privé d'enseignement supérieur",
    value: "Institut privé d'enseignement supérieur"
  },
  {
    id: '3158',
    label: 'Institute Français de la Mode',
    value: 'Institute Français de la Mode'
  },
  {
    id: '3159',
    label: 'Institute Of Technology, Australia',
    value: 'Institute Of Technology, Australia'
  },
  {
    id: '3160',
    label: 'Institute for Advanced Studies Lucca',
    value: 'Institute for Advanced Studies Lucca'
  },
  {
    id: '3161',
    label: 'Institute for Command Engineers of The Ministry for Emergency Situations',
    value: 'Institute for Command Engineers of The Ministry for Emergency Situations'
  },
  {
    id: '3162',
    label: 'Institute for World Politics',
    value: 'Institute for World Politics'
  },
  {
    id: '3163',
    label: 'Institute for the Economy in Transition',
    value: 'Institute for the Economy in Transition'
  },
  {
    id: '3164',
    label: 'Institute of Accountancy Arusha',
    value: 'Institute of Accountancy Arusha'
  },
  {
    id: '3165',
    label: 'Institute of Architecture "Ion Mincu" Bucharest',
    value: 'Institute of Architecture "Ion Mincu" Bucharest'
  },
  {
    id: '3166',
    label: 'Institute of Business Administration (IBA)',
    value: 'Institute of Business Administration (IBA)'
  },
  {
    id: '3167',
    label: 'Institute of Business Administration Sukkur',
    value: 'Institute of Business Administration Sukkur'
  },
  {
    id: '3168',
    label: 'Institute of Business and Politics',
    value: 'Institute of Business and Politics'
  },
  {
    id: '3169',
    label: 'Institute of Commerce and Business',
    value: 'Institute of Commerce and Business'
  },
  {
    id: '3170',
    label: 'Institute of Education, University of London',
    value: 'Institute of Education, University of London'
  },
  {
    id: '3171',
    label: 'Institute of Finance',
    value: 'Institute of Finance'
  },
  {
    id: '3172',
    label: 'Institute of Finance Management',
    value: 'Institute of Finance Management'
  },
  {
    id: '3173',
    label: 'Institute of Industrial Electronics Engineering',
    value: 'Institute of Industrial Electronics Engineering'
  },
  {
    id: '3174',
    label: 'Institute of Information Technology',
    value: 'Institute of Information Technology'
  },
  {
    id: '3175',
    label: 'Institute of Management Sciences, Lahore (IMS)',
    value: 'Institute of Management Sciences, Lahore (IMS)'
  },
  {
    id: '3176',
    label: 'Institute of Management Sciences, Peshawar',
    value: 'Institute of Management Sciences, Peshawar'
  },
  {
    id: '3177',
    label: 'Institute of Management Studies',
    value: 'Institute of Management Studies'
  },
  {
    id: '3178',
    label: 'Institute of Management and Business Technology',
    value: 'Institute of Management and Business Technology'
  },
  {
    id: '3179',
    label: 'Institute of Management and Economy',
    value: 'Institute of Management and Economy'
  },
  {
    id: '3180',
    label: 'Institute of Public Administration',
    value: 'Institute of Public Administration'
  },
  {
    id: '3181',
    label: 'Institute of Science and Technology',
    value: 'Institute of Science and Technology'
  },
  {
    id: '3182',
    label: 'Institute of Social Studies',
    value: 'Institute of Social Studies'
  },
  {
    id: '3183',
    label: 'Institute of Space Technology',
    value: 'Institute of Space Technology'
  },
  {
    id: '3184',
    label: 'Institute of Teachers Education, Batu Lintang',
    value: 'Institute of Teachers Education, Batu Lintang'
  },
  {
    id: '3185',
    label: 'Institute of Teachers Education, Darul Aman',
    value: 'Institute of Teachers Education, Darul Aman'
  },
  {
    id: '3186',
    label: "Institute of Teachers Education, Dato' Razali Ismail",
    value: "Institute of Teachers Education, Dato' Razali Ismail"
  },
  {
    id: '3187',
    label: 'Institute of Teachers Education, Ilmu Khas',
    value: 'Institute of Teachers Education, Ilmu Khas'
  },
  {
    id: '3188',
    label: 'Institute of Teachers Education, Ipoh',
    value: 'Institute of Teachers Education, Ipoh'
  },
  {
    id: '3189',
    label: 'Institute of Teachers Education, Islamic Education',
    value: 'Institute of Teachers Education, Islamic Education'
  },
  {
    id: '3190',
    label: 'Institute of Teachers Education, Keningau',
    value: 'Institute of Teachers Education, Keningau'
  },
  {
    id: '3191',
    label: 'Institute of Teachers Education, Kent',
    value: 'Institute of Teachers Education, Kent'
  },
  {
    id: '3192',
    label: 'Institute of Teachers Education, Kota Bharu',
    value: 'Institute of Teachers Education, Kota Bharu'
  },
  {
    id: '3193',
    label: 'Institute of Teachers Education, Malay Language',
    value: 'Institute of Teachers Education, Malay Language'
  },
  {
    id: '3194',
    label: 'Institute of Teachers Education, Melaka',
    value: 'Institute of Teachers Education, Melaka'
  },
  {
    id: '3195',
    label: 'Institute of Teachers Education, Penang',
    value: 'Institute of Teachers Education, Penang'
  },
  {
    id: '3196',
    label: 'Institute of Teachers Education, Perlis',
    value: 'Institute of Teachers Education, Perlis'
  },
  {
    id: '3197',
    label: 'Institute of Teachers Education, Raja Melewar',
    value: 'Institute of Teachers Education, Raja Melewar'
  },
  {
    id: '3198',
    label: 'Institute of Teachers Education, Rajang',
    value: 'Institute of Teachers Education, Rajang'
  },
  {
    id: '3199',
    label: 'Institute of Teachers Education, Sarawak',
    value: 'Institute of Teachers Education, Sarawak'
  },
  {
    id: '3200',
    label: 'Institute of Teachers Education, Sultan Abdul Halim',
    value: 'Institute of Teachers Education, Sultan Abdul Halim'
  },
  {
    id: '3201',
    label: 'Institute of Teachers Education, Sultan Mizan',
    value: 'Institute of Teachers Education, Sultan Mizan'
  },
  {
    id: '3202',
    label: 'Institute of Teachers Education, Tawau',
    value: 'Institute of Teachers Education, Tawau'
  },
  {
    id: '3203',
    label: 'Institute of Teachers Education, Technical Education',
    value: 'Institute of Teachers Education, Technical Education'
  },
  {
    id: '3204',
    label: 'Institute of Teachers Education, Temenggong Ibrahim',
    value: 'Institute of Teachers Education, Temenggong Ibrahim'
  },
  {
    id: '3205',
    label: 'Institute of Teachers Education, Tengku Ampuan Afzan',
    value: 'Institute of Teachers Education, Tengku Ampuan Afzan'
  },
  {
    id: '3206',
    label: 'Institute of Teachers Education, Tuanku Bainun',
    value: 'Institute of Teachers Education, Tuanku Bainun'
  },
  {
    id: '3207',
    label: 'Institute of Teachers Education, Tun Hussein Onn',
    value: 'Institute of Teachers Education, Tun Hussein Onn'
  },
  {
    id: '3208',
    label: 'Institute of Technical Education',
    value: 'Institute of Technical Education'
  },
  {
    id: '3209',
    label: 'Institute of Technology and Management',
    value: 'Institute of Technology and Management'
  },
  {
    id: '3210',
    label: 'Instituto Infnet',
    value: 'Instituto Infnet'
  },
  {
    id: '3211',
    label: 'Instituto Mauá de Tecnologia',
    value: 'Instituto Mauá de Tecnologia'
  },
  {
    id: '3212',
    label: 'Instituto Militar de Engenharia',
    value: 'Instituto Militar de Engenharia'
  },
  {
    id: '3213',
    label: 'Instituto Nacional de Matemática Pura e Aplicada - IMPA',
    value: 'Instituto Nacional de Matemática Pura e Aplicada - IMPA'
  },
  {
    id: '3214',
    label: 'Instituto Piaget',
    value: 'Instituto Piaget'
  },
  {
    id: '3215',
    label: 'Instituto Politecnico do Porto',
    value: 'Instituto Politecnico do Porto'
  },
  {
    id: '3216',
    label: 'Instituto Politécnico Nacional',
    value: 'Instituto Politécnico Nacional'
  },
  {
    id: '3217',
    label: 'Instituto Politécnico Nacional, Campus Guanajuato',
    value: 'Instituto Politécnico Nacional, Campus Guanajuato'
  },
  {
    id: '3218',
    label: 'Instituto Politécnico da Guarda',
    value: 'Instituto Politécnico da Guarda'
  },
  {
    id: '3219',
    label: 'Instituto Politécnico de Beja',
    value: 'Instituto Politécnico de Beja'
  },
  {
    id: '3220',
    label: 'Instituto Politécnico de Bragança',
    value: 'Instituto Politécnico de Bragança'
  },
  {
    id: '3221',
    label: 'Instituto Politécnico de Castelo Branco',
    value: 'Instituto Politécnico de Castelo Branco'
  },
  {
    id: '3222',
    label: 'Instituto Politécnico de Coimbra',
    value: 'Instituto Politécnico de Coimbra'
  },
  {
    id: '3223',
    label: 'Instituto Politécnico de Leiria',
    value: 'Instituto Politécnico de Leiria'
  },
  {
    id: '3224',
    label: 'Instituto Politécnico de Lisboa',
    value: 'Instituto Politécnico de Lisboa'
  },
  {
    id: '3225',
    label: 'Instituto Politécnico de Portalegre',
    value: 'Instituto Politécnico de Portalegre'
  },
  {
    id: '3226',
    label: 'Instituto Politécnico de Santarém',
    value: 'Instituto Politécnico de Santarém'
  },
  {
    id: '3227',
    label: 'Instituto Politécnico de Setúbal',
    value: 'Instituto Politécnico de Setúbal'
  },
  {
    id: '3228',
    label: 'Instituto Politécnico de Tomar',
    value: 'Instituto Politécnico de Tomar'
  },
  {
    id: '3229',
    label: 'Instituto Politécnico de Viana do Castelo',
    value: 'Instituto Politécnico de Viana do Castelo'
  },
  {
    id: '3230',
    label: 'Instituto Politécnico do Cávado e do Ave',
    value: 'Instituto Politécnico do Cávado e do Ave'
  },
  {
    id: '3231',
    label: 'Instituto Superior Bissaya Barreto',
    value: 'Instituto Superior Bissaya Barreto'
  },
  {
    id: '3232',
    label: 'Instituto Superior D. Afonso III - INUAF',
    value: 'Instituto Superior D. Afonso III - INUAF'
  },
  {
    id: '3233',
    label: 'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez"',
    value: 'Instituto Superior Minero Metalúrgico "Dr. Antonio Núñez Jiménez"'
  },
  {
    id: '3234',
    label: 'Instituto Superior Novas Profissões - INP',
    value: 'Instituto Superior Novas Profissões - INP'
  },
  {
    id: '3235',
    label: 'Instituto Superior Politécnico Gaya',
    value: 'Instituto Superior Politécnico Gaya'
  },
  {
    id: '3236',
    label: 'Instituto Superior Politécnico José Antonio Echeverría',
    value: 'Instituto Superior Politécnico José Antonio Echeverría'
  },
  {
    id: '3237',
    label: 'Instituto Superior Politécnico e Universitário',
    value: 'Instituto Superior Politécnico e Universitário'
  },
  {
    id: '3238',
    label: 'Instituto Superior de Assistentes e Intérpretes',
    value: 'Instituto Superior de Assistentes e Intérpretes'
  },
  {
    id: '3239',
    label: 'Instituto Superior de Ciências Educativas',
    value: 'Instituto Superior de Ciências Educativas'
  },
  {
    id: '3240',
    label: 'Instituto Superior de Ciências Empresariais e de Turismo',
    value: 'Instituto Superior de Ciências Empresariais e de Turismo'
  },
  {
    id: '3241',
    label: 'Instituto Superior de Ciênicas e Tecnologia de Moçambique',
    value: 'Instituto Superior de Ciênicas e Tecnologia de Moçambique'
  },
  {
    id: '3242',
    label: 'Instituto Superior de Entre Douro e Vouga',
    value: 'Instituto Superior de Entre Douro e Vouga'
  },
  {
    id: '3243',
    label: 'Instituto Superior de Gestão - ISG',
    value: 'Instituto Superior de Gestão - ISG'
  },
  {
    id: '3244',
    label: 'Instituto Superior de Informática e Gestão',
    value: 'Instituto Superior de Informática e Gestão'
  },
  {
    id: '3245',
    label: 'Instituto Superior de Línguas e Administração',
    value: 'Instituto Superior de Línguas e Administração'
  },
  {
    id: '3246',
    label: 'Instituto Superior de Paços de Brandão',
    value: 'Instituto Superior de Paços de Brandão'
  },
  {
    id: '3247',
    label: 'Instituto Superior de Psicologia Aplicada',
    value: 'Instituto Superior de Psicologia Aplicada'
  },
  {
    id: '3248',
    label: 'Instituto Superior de Relações Internacionais (ISRI)',
    value: 'Instituto Superior de Relações Internacionais (ISRI)'
  },
  {
    id: '3249',
    label: 'Instituto Superior de Saúde do Alto Ave',
    value: 'Instituto Superior de Saúde do Alto Ave'
  },
  {
    id: '3250',
    label: 'Instituto Superior de Tecnologias Avançadas - ISTEC',
    value: 'Instituto Superior de Tecnologias Avançadas - ISTEC'
  },
  {
    id: '3251',
    label: 'Instituto Superior de Transportes e Comunicações',
    value: 'Instituto Superior de Transportes e Comunicações'
  },
  {
    id: '3252',
    label: 'Instituto Tecnologico de Durango',
    value: 'Instituto Tecnologico de Durango'
  },
  {
    id: '3253',
    label: 'Instituto Tecnologico de Minatitlan',
    value: 'Instituto Tecnologico de Minatitlan'
  },
  {
    id: '3254',
    label: 'Instituto Tecnológico Autonómo de México',
    value: 'Instituto Tecnológico Autonómo de México'
  },
  {
    id: '3255',
    label: 'Instituto Tecnológico Metropolitano',
    value: 'Instituto Tecnológico Metropolitano'
  },
  {
    id: '3256',
    label: 'Instituto Tecnológico Superior de Nuevo Casas Grandes',
    value: 'Instituto Tecnológico Superior de Nuevo Casas Grandes'
  },
  {
    id: '3257',
    label: 'Instituto Tecnológico de Aeronáutica',
    value: 'Instituto Tecnológico de Aeronáutica'
  },
  {
    id: '3258',
    label: 'Instituto Tecnológico de Aguascalientes',
    value: 'Instituto Tecnológico de Aguascalientes'
  },
  {
    id: '3259',
    label: 'Instituto Tecnológico de Buenos Aires',
    value: 'Instituto Tecnológico de Buenos Aires'
  },
  {
    id: '3260',
    label: 'Instituto Tecnológico de Celaya',
    value: 'Instituto Tecnológico de Celaya'
  },
  {
    id: '3261',
    label: 'Instituto Tecnológico de Costa Rica',
    value: 'Instituto Tecnológico de Costa Rica'
  },
  {
    id: '3262',
    label: 'Instituto Tecnológico de Las Américas',
    value: 'Instituto Tecnológico de Las Américas'
  },
  {
    id: '3263',
    label: 'Instituto Tecnológico de León',
    value: 'Instituto Tecnológico de León'
  },
  {
    id: '3264',
    label: 'Instituto Tecnológico de Morelia',
    value: 'Instituto Tecnológico de Morelia'
  },
  {
    id: '3265',
    label: 'Instituto Tecnológico de Occidente del Estado de Hidalgo',
    value: 'Instituto Tecnológico de Occidente del Estado de Hidalgo'
  },
  {
    id: '3266',
    label: 'Instituto Tecnológico de Querétaro',
    value: 'Instituto Tecnológico de Querétaro'
  },
  {
    id: '3267',
    label: 'Instituto Tecnológico de San Luis Potosí',
    value: 'Instituto Tecnológico de San Luis Potosí'
  },
  {
    id: '3268',
    label: 'Instituto Tecnológico de Santo Domingo',
    value: 'Instituto Tecnológico de Santo Domingo'
  },
  {
    id: '3269',
    label: 'Instituto Tecnológico de Sonora',
    value: 'Instituto Tecnológico de Sonora'
  },
  {
    id: '3270',
    label: 'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)',
    value: 'Instituto Tecnológico y de Estudios Superiores de Monterrey (ITESM)'
  },
  {
    id: '3271',
    label: 'Instituto Tecnológico y de Estudios Superiores de Occidente',
    value: 'Instituto Tecnológico y de Estudios Superiores de Occidente'
  },
  {
    id: '3272',
    label: 'Instituto Universitario Aeronáutico',
    value: 'Instituto Universitario Aeronáutico'
  },
  {
    id: '3273',
    label: 'Instituto Universitario CEMA',
    value: 'Instituto Universitario CEMA'
  },
  {
    id: '3274',
    label: 'Instituto Universitario Nacional del Arte',
    value: 'Instituto Universitario Nacional del Arte'
  },
  {
    id: '3275',
    label: 'Instituto Universitario de Ciencias de la Salud - Buenos Aires',
    value: 'Instituto Universitario de Ciencias de la Salud - Buenos Aires'
  },
  {
    id: '3276',
    label: 'Instituto Universitario de Estudios Navales y Marítimos',
    value: 'Instituto Universitario de Estudios Navales y Marítimos'
  },
  {
    id: '3277',
    label: 'Instituto Universitario de La Paz',
    value: 'Instituto Universitario de La Paz'
  },
  {
    id: '3278',
    label: 'Instituto Universitario de la Policia Federal',
    value: 'Instituto Universitario de la Policia Federal'
  },
  {
    id: '3279',
    label: 'Instituto de Artes Visuais, Design e Marketing - IADE',
    value: 'Instituto de Artes Visuais, Design e Marketing - IADE'
  },
  {
    id: '3280',
    label: 'Instituto de Enseñanza Superior Oteima',
    value: 'Instituto de Enseñanza Superior Oteima'
  },
  {
    id: '3281',
    label: 'Instituto de Enseñanza Superior del Ejército',
    value: 'Instituto de Enseñanza Superior del Ejército'
  },
  {
    id: '3282',
    label: 'Instituto de Estudios Superiores de la Sierra',
    value: 'Instituto de Estudios Superiores de la Sierra'
  },
  {
    id: '3283',
    label: 'Instituts Supérieurs de Formation des Maîtres',
    value: 'Instituts Supérieurs de Formation des Maîtres'
  },
  {
    id: '3284',
    label: 'Instituts Supérieurs des Etudes Technologiques',
    value: 'Instituts Supérieurs des Etudes Technologiques'
  },
  {
    id: '3285',
    label: 'Integral University',
    value: 'Integral University'
  },
  {
    id: '3286',
    label: 'Intercollege',
    value: 'Intercollege'
  },
  {
    id: '3287',
    label: 'Interdenominational Theological Center',
    value: 'Interdenominational Theological Center'
  },
  {
    id: '3288',
    label: 'International Ataturk Alatoo University',
    value: 'International Ataturk Alatoo University'
  },
  {
    id: '3289',
    label: 'International Banking Institute, St. Petersburg',
    value: 'International Banking Institute, St. Petersburg'
  },
  {
    id: '3290',
    label: 'International Blacksea University',
    value: 'International Blacksea University'
  },
  {
    id: '3291',
    label: 'International Buddhist College',
    value: 'International Buddhist College'
  },
  {
    id: '3292',
    label: 'International Buddhist University',
    value: 'International Buddhist University'
  },
  {
    id: '3293',
    label: 'International Budo University',
    value: 'International Budo University'
  },
  {
    id: '3294',
    label: 'International Burch University',
    value: 'International Burch University'
  },
  {
    id: '3295',
    label: 'International Business School Kelajak ILMI',
    value: 'International Business School Kelajak ILMI'
  },
  {
    id: '3296',
    label: 'International Business School of Scandinavia',
    value: 'International Business School of Scandinavia'
  },
  {
    id: '3297',
    label: 'International Business University of Beijing',
    value: 'International Business University of Beijing'
  },
  {
    id: '3298',
    label: 'International Centre for Isclamic Science',
    value: 'International Centre for Isclamic Science'
  },
  {
    id: '3299',
    label: 'International Christian University',
    value: 'International Christian University'
  },
  {
    id: '3300',
    label: 'International College of Tourism and Hotel Management',
    value: 'International College of Tourism and Hotel Management'
  },
  {
    id: '3301',
    label: 'International College of the Cayman Islands',
    value: 'International College of the Cayman Islands'
  },
  {
    id: '3302',
    label: 'International Colleges of Islamic Science',
    value: 'International Colleges of Islamic Science'
  },
  {
    id: '3303',
    label: 'International Culinary Institute (ICI)',
    value: 'International Culinary Institute (ICI)'
  },
  {
    id: '3304',
    label: 'International Culture University',
    value: 'International Culture University'
  },
  {
    id: '3305',
    label: 'International East-European University',
    value: 'International East-European University'
  },
  {
    id: '3306',
    label: 'International Hellenic University',
    value: 'International Hellenic University'
  },
  {
    id: '3307',
    label: 'International Higher School for Advanced Studies Trieste',
    value: 'International Higher School for Advanced Studies Trieste'
  },
  {
    id: '3308',
    label: 'International Hotel Management Institute',
    value: 'International Hotel Management Institute'
  },
  {
    id: '3309',
    label: 'International Institute for Geo - Information Science and Earth Observation',
    value: 'International Institute for Geo - Information Science and Earth Observation'
  },
  {
    id: '3310',
    label: 'International Institute for Higher Education',
    value: 'International Institute for Higher Education'
  },
  {
    id: '3311',
    label: 'International Institute for Population Sciences',
    value: 'International Institute for Population Sciences'
  },
  {
    id: '3312',
    label: 'International Institute of Information Technology, Bangalore',
    value: 'International Institute of Information Technology, Bangalore'
  },
  {
    id: '3313',
    label: 'International Institute of Information Technology, Bhubaneswar',
    value: 'International Institute of Information Technology, Bhubaneswar'
  },
  {
    id: '3314',
    label: 'International Institute of Information Technology, Pune',
    value: 'International Institute of Information Technology, Pune'
  },
  {
    id: '3315',
    label: 'International Islamic College',
    value: 'International Islamic College'
  },
  {
    id: '3316',
    label: 'International Islamic College of Penang',
    value: 'International Islamic College of Penang'
  },
  {
    id: '3317',
    label: 'International Islamic University',
    value: 'International Islamic University'
  },
  {
    id: '3318',
    label: 'International Islamic University',
    value: 'International Islamic University'
  },
  {
    id: '3319',
    label: 'International Islamic University Chittagong',
    value: 'International Islamic University Chittagong'
  },
  {
    id: '3320',
    label: 'International Kazakh-Turkish University',
    value: 'International Kazakh-Turkish University'
  },
  {
    id: '3321',
    label: 'International Medical & Technological University',
    value: 'International Medical & Technological University'
  },
  {
    id: '3322',
    label: 'International Medical University',
    value: 'International Medical University'
  },
  {
    id: '3323',
    label: "International People's College",
    value: "International People's College"
  },
  {
    id: '3324',
    label: 'International Sakharov Environmental University',
    value: 'International Sakharov Environmental University'
  },
  {
    id: '3325',
    label: 'International School of Business Management',
    value: 'International School of Business Management'
  },
  {
    id: '3326',
    label: 'International School of Management',
    value: 'International School of Management'
  },
  {
    id: '3327',
    label: 'International School of Management ISM Dortmund',
    value: 'International School of Management ISM Dortmund'
  },
  {
    id: '3328',
    label: 'International School of Management in Prešov',
    value: 'International School of Management in Prešov'
  },
  {
    id: '3329',
    label: 'International School of New Media, University of Lübeck',
    value: 'International School of New Media, University of Lübeck'
  },
  {
    id: '3330',
    label: 'International Technological University',
    value: 'International Technological University'
  },
  {
    id: '3331',
    label: 'International Theravada Buddhist Missionary University',
    value: 'International Theravada Buddhist Missionary University'
  },
  {
    id: '3332',
    label: 'International Tourism Institute',
    value: 'International Tourism Institute'
  },
  {
    id: '3333',
    label: 'International Turkmen Turkish University',
    value: 'International Turkmen Turkish University'
  },
  {
    id: '3334',
    label: 'International University',
    value: 'International University'
  },
  {
    id: '3335',
    label: 'International University College',
    value: 'International University College'
  },
  {
    id: '3336',
    label: 'International University College of Nursing (IUCN)',
    value: 'International University College of Nursing (IUCN)'
  },
  {
    id: '3337',
    label: 'International University College of Technology Twintech (IUCTT)',
    value: 'International University College of Technology Twintech (IUCTT)'
  },
  {
    id: '3338',
    label: 'International University Institute of Luxembourg',
    value: 'International University Institute of Luxembourg'
  },
  {
    id: '3339',
    label: 'International University School of Medicine (IUSOM)',
    value: 'International University School of Medicine (IUSOM)'
  },
  {
    id: '3340',
    label: 'International University for Graduate Studies',
    value: 'International University for Graduate Studies'
  },
  {
    id: '3341',
    label: 'International University for Science & Technology',
    value: 'International University for Science & Technology'
  },
  {
    id: '3342',
    label: 'International University in Geneva',
    value: 'International University in Geneva'
  },
  {
    id: '3343',
    label: 'International University in Germany',
    value: 'International University in Germany'
  },
  {
    id: '3344',
    label: 'International University of Africa (IUA)',
    value: 'International University of Africa (IUA)'
  },
  {
    id: '3345',
    label: 'International University of Business Agriculture and Technology',
    value: 'International University of Business Agriculture and Technology'
  },
  {
    id: '3346',
    label: 'International University of Engineering, Moscow',
    value: 'International University of Engineering, Moscow'
  },
  {
    id: '3347',
    label: 'International University of Fundamental Studies, St. Petersburg',
    value: 'International University of Fundamental Studies, St. Petersburg'
  },
  {
    id: '3348',
    label: 'International University of Health and Welfare',
    value: 'International University of Health and Welfare'
  },
  {
    id: '3349',
    label: 'International University of Japan',
    value: 'International University of Japan'
  },
  {
    id: '3350',
    label: 'International University of Kagoshima',
    value: 'International University of Kagoshima'
  },
  {
    id: '3351',
    label: 'International University of Korea',
    value: 'International University of Korea'
  },
  {
    id: '3352',
    label: 'International University of Kyrgyzstan',
    value: 'International University of Kyrgyzstan'
  },
  {
    id: '3353',
    label: 'International University of Management',
    value: 'International University of Management'
  },
  {
    id: '3354',
    label: 'International University of Novi Pazar',
    value: 'International University of Novi Pazar'
  },
  {
    id: '3355',
    label: 'International University of Sarajevo',
    value: 'International University of Sarajevo'
  },
  {
    id: '3356',
    label: 'International University of Southern Europe',
    value: 'International University of Southern Europe'
  },
  {
    id: '3357',
    label: 'International University of Travnik',
    value: 'International University of Travnik'
  },
  {
    id: '3358',
    label: 'Internationale Fachhochschule Aalen',
    value: 'Internationale Fachhochschule Aalen'
  },
  {
    id: '3359',
    label: 'Internationale Fachhochschule Bad Honnef',
    value: 'Internationale Fachhochschule Bad Honnef'
  },
  {
    id: '3360',
    label: 'Internationales Hochschulinstitut Zittau',
    value: 'Internationales Hochschulinstitut Zittau'
  },
  {
    id: '3361',
    label: 'Internationle Akademie für Philosophie',
    value: 'Internationle Akademie für Philosophie'
  },
  {
    id: '3362',
    label: 'Inver Hills Community College',
    value: 'Inver Hills Community College'
  },
  {
    id: '3363',
    label: 'Inönü University',
    value: 'Inönü University'
  },
  {
    id: '3364',
    label: 'Iona College',
    value: 'Iona College'
  },
  {
    id: '3365',
    label: 'Ionian University',
    value: 'Ionian University'
  },
  {
    id: '3366',
    label: 'Iowa Central Community College',
    value: 'Iowa Central Community College'
  },
  {
    id: '3367',
    label: 'Iowa Lakes Community College',
    value: 'Iowa Lakes Community College'
  },
  {
    id: '3368',
    label: 'Iowa State University',
    value: 'Iowa State University'
  },
  {
    id: '3369',
    label: 'Iowa Western Community College',
    value: 'Iowa Western Community College'
  },
  {
    id: '3370',
    label: 'Iqra University',
    value: 'Iqra University'
  },
  {
    id: '3371',
    label: 'Iran University of Medical Sciences',
    value: 'Iran University of Medical Sciences'
  },
  {
    id: '3372',
    label: 'Iran University of Science and Technology Tehran',
    value: 'Iran University of Science and Technology Tehran'
  },
  {
    id: '3373',
    label: 'Iraq University College',
    value: 'Iraq University College'
  },
  {
    id: '3374',
    label: 'Irbid National University',
    value: 'Irbid National University'
  },
  {
    id: '3375',
    label: 'Irish International University (Distance Education)',
    value: 'Irish International University (Distance Education)'
  },
  {
    id: '3376',
    label: 'Irish University Business School, Cebu',
    value: 'Irish University Business School, Cebu'
  },
  {
    id: '3377',
    label: 'Irkutsk State Academy of Economics',
    value: 'Irkutsk State Academy of Economics'
  },
  {
    id: '3378',
    label: 'Irkutsk State Technical University',
    value: 'Irkutsk State Technical University'
  },
  {
    id: '3379',
    label: 'Irkutsk State University',
    value: 'Irkutsk State University'
  },
  {
    id: '3380',
    label: 'Irvine Valley College',
    value: 'Irvine Valley College'
  },
  {
    id: '3381',
    label: 'Isabela State University',
    value: 'Isabela State University'
  },
  {
    id: '3382',
    label: 'Isfahan University',
    value: 'Isfahan University'
  },
  {
    id: '3383',
    label: 'Isfahan University of Medical Sciences',
    value: 'Isfahan University of Medical Sciences'
  },
  {
    id: '3384',
    label: 'Isfahan University of Technology',
    value: 'Isfahan University of Technology'
  },
  {
    id: '3385',
    label: 'Ishik University',
    value: 'Ishik University'
  },
  {
    id: '3386',
    label: 'Ishinomaki Senshu University',
    value: 'Ishinomaki Senshu University'
  },
  {
    id: '3387',
    label: 'Isik University',
    value: 'Isik University'
  },
  {
    id: '3388',
    label: 'Iskenderun Tecnical University',
    value: 'Iskenderun Tecnical University'
  },
  {
    id: '3389',
    label: 'Islamia University of Bahawalpur',
    value: 'Islamia University of Bahawalpur'
  },
  {
    id: '3390',
    label: 'Islamic Azad University, Ahar',
    value: 'Islamic Azad University, Ahar'
  },
  {
    id: '3391',
    label: 'Islamic Azad University, Aliabad',
    value: 'Islamic Azad University, Aliabad'
  },
  {
    id: '3392',
    label: 'Islamic Azad University, Arak',
    value: 'Islamic Azad University, Arak'
  },
  {
    id: '3393',
    label: 'Islamic Azad University, Ardabil',
    value: 'Islamic Azad University, Ardabil'
  },
  {
    id: '3394',
    label: 'Islamic Azad University, Bandar Abbas',
    value: 'Islamic Azad University, Bandar Abbas'
  },
  {
    id: '3395',
    label: 'Islamic Azad University, Bojnourd',
    value: 'Islamic Azad University, Bojnourd'
  },
  {
    id: '3396',
    label: 'Islamic Azad University, Bostanabad',
    value: 'Islamic Azad University, Bostanabad'
  },
  {
    id: '3397',
    label: 'Islamic Azad University, Boukan',
    value: 'Islamic Azad University, Boukan'
  },
  {
    id: '3398',
    label: 'Islamic Azad University, Central Tehran Branch',
    value: 'Islamic Azad University, Central Tehran Branch'
  },
  {
    id: '3399',
    label: 'Islamic Azad University, Dehaghan',
    value: 'Islamic Azad University, Dehaghan'
  },
  {
    id: '3400',
    label: 'Islamic Azad University, Falavarjan',
    value: 'Islamic Azad University, Falavarjan'
  },
  {
    id: '3401',
    label: 'Islamic Azad University, Fars Science & Research Branch',
    value: 'Islamic Azad University, Fars Science & Research Branch'
  },
  {
    id: '3402',
    label: 'Islamic Azad University, Garmsar',
    value: 'Islamic Azad University, Garmsar'
  },
  {
    id: '3403',
    label: 'Islamic Azad University, Gorgan',
    value: 'Islamic Azad University, Gorgan'
  },
  {
    id: '3404',
    label: 'Islamic Azad University, Hamedan',
    value: 'Islamic Azad University, Hamedan'
  },
  {
    id: '3405',
    label: 'Islamic Azad University, Janah',
    value: 'Islamic Azad University, Janah'
  },
  {
    id: '3406',
    label: 'Islamic Azad University, Karaj',
    value: 'Islamic Azad University, Karaj'
  },
  {
    id: '3407',
    label: 'Islamic Azad University, Kazerun',
    value: 'Islamic Azad University, Kazerun'
  },
  {
    id: '3408',
    label: 'Islamic Azad University, Kerman',
    value: 'Islamic Azad University, Kerman'
  },
  {
    id: '3409',
    label: 'Islamic Azad University, Khorasgan',
    value: 'Islamic Azad University, Khorasgan'
  },
  {
    id: '3410',
    label: 'Islamic Azad University, Lahijan',
    value: 'Islamic Azad University, Lahijan'
  },
  {
    id: '3411',
    label: 'Islamic Azad University, Mahshar',
    value: 'Islamic Azad University, Mahshar'
  },
  {
    id: '3412',
    label: 'Islamic Azad University, Majlesi',
    value: 'Islamic Azad University, Majlesi'
  },
  {
    id: '3413',
    label: 'Islamic Azad University, Mashhad',
    value: 'Islamic Azad University, Mashhad'
  },
  {
    id: '3414',
    label: 'Islamic Azad University, Maybod',
    value: 'Islamic Azad University, Maybod'
  },
  {
    id: '3415',
    label: 'Islamic Azad University, Mehriz',
    value: 'Islamic Azad University, Mehriz'
  },
  {
    id: '3416',
    label: 'Islamic Azad University, Mobarakeh',
    value: 'Islamic Azad University, Mobarakeh'
  },
  {
    id: '3417',
    label: 'Islamic Azad University, Najafabad',
    value: 'Islamic Azad University, Najafabad'
  },
  {
    id: '3418',
    label: 'Islamic Azad University, North Tehran',
    value: 'Islamic Azad University, North Tehran'
  },
  {
    id: '3419',
    label: 'Islamic Azad University, Parand',
    value: 'Islamic Azad University, Parand'
  },
  {
    id: '3420',
    label: 'Islamic Azad University, Qazvin',
    value: 'Islamic Azad University, Qazvin'
  },
  {
    id: '3421',
    label: 'Islamic Azad University, Qom',
    value: 'Islamic Azad University, Qom'
  },
  {
    id: '3422',
    label: 'Islamic Azad University, Quchan',
    value: 'Islamic Azad University, Quchan'
  },
  {
    id: '3423',
    label: 'Islamic Azad University, Region One',
    value: 'Islamic Azad University, Region One'
  },
  {
    id: '3424',
    label: 'Islamic Azad University, Roodehen',
    value: 'Islamic Azad University, Roodehen'
  },
  {
    id: '3425',
    label: 'Islamic Azad University, Sanandaj',
    value: 'Islamic Azad University, Sanandaj'
  },
  {
    id: '3426',
    label: 'Islamic Azad University, Saveh',
    value: 'Islamic Azad University, Saveh'
  },
  {
    id: '3427',
    label: 'Islamic Azad University, Semnan',
    value: 'Islamic Azad University, Semnan'
  },
  {
    id: '3428',
    label: 'Islamic Azad University, Shabestar',
    value: 'Islamic Azad University, Shabestar'
  },
  {
    id: '3429',
    label: 'Islamic Azad University, Shahr-e-rey',
    value: 'Islamic Azad University, Shahr-e-rey'
  },
  {
    id: '3430',
    label: 'Islamic Azad University, Shahrood',
    value: 'Islamic Azad University, Shahrood'
  },
  {
    id: '3431',
    label: 'Islamic Azad University, Shahryar',
    value: 'Islamic Azad University, Shahryar'
  },
  {
    id: '3432',
    label: 'Islamic Azad University, Shiraz',
    value: 'Islamic Azad University, Shiraz'
  },
  {
    id: '3433',
    label: 'Islamic Azad University, Shirvan',
    value: 'Islamic Azad University, Shirvan'
  },
  {
    id: '3434',
    label: 'Islamic Azad University, Someh Sara',
    value: 'Islamic Azad University, Someh Sara'
  },
  {
    id: '3435',
    label: 'Islamic Azad University, Tabriz',
    value: 'Islamic Azad University, Tabriz'
  },
  {
    id: '3436',
    label: 'Islamic Azad University, Tafresh',
    value: 'Islamic Azad University, Tafresh'
  },
  {
    id: '3437',
    label: 'Islamic Azad University, Tehran Science & Research Branch',
    value: 'Islamic Azad University, Tehran Science & Research Branch'
  },
  {
    id: '3438',
    label: 'Islamic Azad University, Tehran South',
    value: 'Islamic Azad University, Tehran South'
  },
  {
    id: '3439',
    label: 'Islamic Azad University, Theran Medical',
    value: 'Islamic Azad University, Theran Medical'
  },
  {
    id: '3440',
    label: 'Islamic Azad University, Varamin',
    value: 'Islamic Azad University, Varamin'
  },
  {
    id: '3441',
    label: 'Islamic Azad University, Yazd',
    value: 'Islamic Azad University, Yazd'
  },
  {
    id: '3442',
    label: 'Islamic College for Sciences and Technologies',
    value: 'Islamic College for Sciences and Technologies'
  },
  {
    id: '3443',
    label: 'Islamic University College',
    value: 'Islamic University College'
  },
  {
    id: '3444',
    label: 'Islamic University Kushtia',
    value: 'Islamic University Kushtia'
  },
  {
    id: '3445',
    label: 'Islamic University in Uganda',
    value: 'Islamic University in Uganda'
  },
  {
    id: '3446',
    label: 'Islamic University of Gaza',
    value: 'Islamic University of Gaza'
  },
  {
    id: '3447',
    label: 'Islamic University of Medinah',
    value: 'Islamic University of Medinah'
  },
  {
    id: '3448',
    label: 'Islamic University of Rotterdam',
    value: 'Islamic University of Rotterdam'
  },
  {
    id: '3449',
    label: 'Islamic University of Technology',
    value: 'Islamic University of Technology'
  },
  {
    id: '3450',
    label: 'Island Drafting & Technical Institute',
    value: 'Island Drafting & Technical Institute'
  },
  {
    id: '3451',
    label: 'Isothermal Community College',
    value: 'Isothermal Community College'
  },
  {
    id: '3452',
    label: 'Isra University',
    value: 'Isra University'
  },
  {
    id: '3453',
    label: 'Istanbul 29Mayis  University',
    value: 'Istanbul 29Mayis  University'
  },
  {
    id: '3454',
    label: 'Istanbul Arel University',
    value: 'Istanbul Arel University'
  },
  {
    id: '3455',
    label: 'Istanbul Aydin University',
    value: 'Istanbul Aydin University'
  },
  {
    id: '3456',
    label: 'Istanbul Bilgi University',
    value: 'Istanbul Bilgi University'
  },
  {
    id: '3457',
    label: 'Istanbul Esenyurt University',
    value: 'Istanbul Esenyurt University'
  },
  {
    id: '3458',
    label: 'Istanbul Gelisim University',
    value: 'Istanbul Gelisim University'
  },
  {
    id: '3459',
    label: 'Istanbul Kemerburgaz  University',
    value: 'Istanbul Kemerburgaz  University'
  },
  {
    id: '3460',
    label: 'Istanbul Kultur University',
    value: 'Istanbul Kultur University'
  },
  {
    id: '3461',
    label: 'Istanbul Medeniyet University',
    value: 'Istanbul Medeniyet University'
  },
  {
    id: '3462',
    label: 'Istanbul Medipol University',
    value: 'Istanbul Medipol University'
  },
  {
    id: '3463',
    label: 'Istanbul Rumeli University',
    value: 'Istanbul Rumeli University'
  },
  {
    id: '3464',
    label: 'Istanbul Sabahattin Zaim University',
    value: 'Istanbul Sabahattin Zaim University'
  },
  {
    id: '3465',
    label: 'Istanbul Technical University',
    value: 'Istanbul Technical University'
  },
  {
    id: '3466',
    label: 'Istanbul Ticaret University',
    value: 'Istanbul Ticaret University'
  },
  {
    id: '3467',
    label: 'Istanbul University',
    value: 'Istanbul University'
  },
  {
    id: '3468',
    label: 'Istanbul Şehir University',
    value: 'Istanbul Şehir University'
  },
  {
    id: '3469',
    label: 'Istituto Universitario di Studi Superiori',
    value: 'Istituto Universitario di Studi Superiori'
  },
  {
    id: '3470',
    label: 'Itasca Community College',
    value: 'Itasca Community College'
  },
  {
    id: '3471',
    label: 'Itawamba Community College',
    value: 'Itawamba Community College'
  },
  {
    id: '3472',
    label: 'Ithaca College',
    value: 'Ithaca College'
  },
  {
    id: '3473',
    label: 'Ittihad Private University',
    value: 'Ittihad Private University'
  },
  {
    id: '3474',
    label: 'Ittihad University',
    value: 'Ittihad University'
  },
  {
    id: '3475',
    label: 'Ivano-Frankivsk National University of Oli and Gas',
    value: 'Ivano-Frankivsk National University of Oli and Gas'
  },
  {
    id: '3476',
    label: 'Ivanovo State Academy of Medicine',
    value: 'Ivanovo State Academy of Medicine'
  },
  {
    id: '3477',
    label: 'Ivanovo State Power University',
    value: 'Ivanovo State Power University'
  },
  {
    id: '3478',
    label: 'Ivanovo State Textile Academy',
    value: 'Ivanovo State Textile Academy'
  },
  {
    id: '3479',
    label: 'Ivanovo State University',
    value: 'Ivanovo State University'
  },
  {
    id: '3480',
    label: 'Ivanovo State University of Chemistry and Technology',
    value: 'Ivanovo State University of Chemistry and Technology'
  },
  {
    id: '3481',
    label: 'Ivy Tech Community College',
    value: 'Ivy Tech Community College'
  },
  {
    id: '3482',
    label: 'Iwaki Meisei University',
    value: 'Iwaki Meisei University'
  },
  {
    id: '3483',
    label: 'Iwate Medical University',
    value: 'Iwate Medical University'
  },
  {
    id: '3484',
    label: 'Iwate Prefectural University',
    value: 'Iwate Prefectural University'
  },
  {
    id: '3485',
    label: 'Iwate University',
    value: 'Iwate University'
  },
  {
    id: '3486',
    label: 'Izhevsk State Technical University',
    value: 'Izhevsk State Technical University'
  },
  {
    id: '3487',
    label: 'Izmir Bakırçay University',
    value: 'Izmir Bakırçay University'
  },
  {
    id: '3488',
    label: 'Izmir Democracy University',
    value: 'Izmir Democracy University'
  },
  {
    id: '3489',
    label: 'Izmir Institute of Technology',
    value: 'Izmir Institute of Technology'
  },
  {
    id: '3490',
    label: 'Izmir Katip Celebi University',
    value: 'Izmir Katip Celebi University'
  },
  {
    id: '3491',
    label: 'Izmir Tınaztepe University',
    value: 'Izmir Tınaztepe University'
  },
  {
    id: '3492',
    label: 'Izmir University of Economics',
    value: 'Izmir University of Economics'
  },
  {
    id: '3493',
    label: 'IÉSEG School of Management',
    value: 'IÉSEG School of Management'
  },
  {
    id: '3494',
    label: 'J F Drake State Community and Technical College',
    value: 'J F Drake State Community and Technical College'
  },
  {
    id: '3495',
    label: 'J Sargeant Reynolds Community College',
    value: 'J Sargeant Reynolds Community College'
  },
  {
    id: '3496',
    label: 'JCT Lev Academic Center',
    value: 'JCT Lev Academic Center'
  },
  {
    id: '3497',
    label: 'JIS College of Engineering',
    value: 'JIS College of Engineering'
  },
  {
    id: '3498',
    label: 'JIS Institute of Advanced Studies & Research',
    value: 'JIS Institute of Advanced Studies & Research'
  },
  {
    id: '3499',
    label: 'JIS Institute of Pharmacy',
    value: 'JIS Institute of Pharmacy'
  },
  {
    id: '3500',
    label: 'JIS School of Medical Science & Research',
    value: 'JIS School of Medical Science & Research'
  },
  {
    id: '3501',
    label: 'JIS School of Polytechnic',
    value: 'JIS School of Polytechnic'
  },
  {
    id: '3502',
    label: 'JIS University',
    value: 'JIS University'
  },
  {
    id: '3503',
    label: 'Jackson College',
    value: 'Jackson College'
  },
  {
    id: '3504',
    label: 'Jackson State Community College',
    value: 'Jackson State Community College'
  },
  {
    id: '3505',
    label: 'Jackson State University',
    value: 'Jackson State University'
  },
  {
    id: '3506',
    label: 'Jacksonville College-Main Campus',
    value: 'Jacksonville College-Main Campus'
  },
  {
    id: '3507',
    label: 'Jacksonville State University',
    value: 'Jacksonville State University'
  },
  {
    id: '3508',
    label: 'Jacksonville University',
    value: 'Jacksonville University'
  },
  {
    id: '3509',
    label: 'Jacobs University Bremen',
    value: 'Jacobs University Bremen'
  },
  {
    id: '3510',
    label: 'Jadara University',
    value: 'Jadara University'
  },
  {
    id: '3511',
    label: 'Jadavpur University',
    value: 'Jadavpur University'
  },
  {
    id: '3512',
    label: 'Jagannath University',
    value: 'Jagannath University'
  },
  {
    id: '3513',
    label: 'Jagiellonian University Cracow',
    value: 'Jagiellonian University Cracow'
  },
  {
    id: '3514',
    label: 'Jahangirnagar University',
    value: 'Jahangirnagar University'
  },
  {
    id: '3515',
    label: 'Jahrom University of Medical Sciences',
    value: 'Jahrom University of Medical Sciences'
  },
  {
    id: '3516',
    label: 'Jai Narain Vyas University',
    value: 'Jai Narain Vyas University'
  },
  {
    id: '3517',
    label: 'Jai Prakash University',
    value: 'Jai Prakash University'
  },
  {
    id: '3518',
    label: 'Jain Vishva Bharati Institute',
    value: 'Jain Vishva Bharati Institute'
  },
  {
    id: '3519',
    label: 'Jaipur National University',
    value: 'Jaipur National University'
  },
  {
    id: '3520',
    label: 'Jalalabad State University Kyrgyzstan',
    value: 'Jalalabad State University Kyrgyzstan'
  },
  {
    id: '3521',
    label: 'James A Rhodes State College',
    value: 'James A Rhodes State College'
  },
  {
    id: '3522',
    label: 'James Cook University of North Queensland',
    value: 'James Cook University of North Queensland'
  },
  {
    id: '3523',
    label: 'James H Faulkner State Community College',
    value: 'James H Faulkner State Community College'
  },
  {
    id: '3524',
    label: 'James Madison University',
    value: 'James Madison University'
  },
  {
    id: '3525',
    label: 'James Sprunt Community College',
    value: 'James Sprunt Community College'
  },
  {
    id: '3526',
    label: 'Jamestown Community College',
    value: 'Jamestown Community College'
  },
  {
    id: '3527',
    label: 'Jami Institute of Technology',
    value: 'Jami Institute of Technology'
  },
  {
    id: '3528',
    label: 'Jamia Al Dirasat Ul Islami Karachi',
    value: 'Jamia Al Dirasat Ul Islami Karachi'
  },
  {
    id: '3529',
    label: 'Jamia Hamdard University',
    value: 'Jamia Hamdard University'
  },
  {
    id: '3530',
    label: 'Jamia Millia Islamia University',
    value: 'Jamia Millia Islamia University'
  },
  {
    id: '3531',
    label: 'Janos Selye University',
    value: 'Janos Selye University'
  },
  {
    id: '3532',
    label: 'Janus Pannonius University',
    value: 'Janus Pannonius University'
  },
  {
    id: '3533',
    label: 'Japan Advanced Institute of Science and Technology',
    value: 'Japan Advanced Institute of Science and Technology'
  },
  {
    id: '3534',
    label: 'Japan College of Social Work',
    value: 'Japan College of Social Work'
  },
  {
    id: '3535',
    label: "Japan Women's University",
    value: "Japan Women's University"
  },
  {
    id: '3536',
    label: 'Japanese Red Cross College of Nursing',
    value: 'Japanese Red Cross College of Nursing'
  },
  {
    id: '3537',
    label: 'Jaramogi Oginga Odinga University of Science and Technology',
    value: 'Jaramogi Oginga Odinga University of Science and Technology'
  },
  {
    id: '3538',
    label: 'Jarvis Christian College',
    value: 'Jarvis Christian College'
  },
  {
    id: '3539',
    label: 'Jatiya Kabi Kazi Nazrul Islam University',
    value: 'Jatiya Kabi Kazi Nazrul Islam University'
  },
  {
    id: '3540',
    label: 'Jawaharlal Nehru Agricultural University',
    value: 'Jawaharlal Nehru Agricultural University'
  },
  {
    id: '3541',
    label: 'Jawaharlal Nehru Centre for Advanced  Scientific Research',
    value: 'Jawaharlal Nehru Centre for Advanced  Scientific Research'
  },
  {
    id: '3542',
    label: 'Jawaharlal Nehru Technological University Hyderabad',
    value: 'Jawaharlal Nehru Technological University Hyderabad'
  },
  {
    id: '3543',
    label: 'Jawaharlal Nehru University',
    value: 'Jawaharlal Nehru University'
  },
  {
    id: '3544',
    label: 'Jawzjan University',
    value: 'Jawzjan University'
  },
  {
    id: '3545',
    label: 'Jaypee Institute of Information Technology',
    value: 'Jaypee Institute of Information Technology'
  },
  {
    id: '3546',
    label: 'Jazan University',
    value: 'Jazan University'
  },
  {
    id: '3547',
    label: 'Jefferson College',
    value: 'Jefferson College'
  },
  {
    id: '3548',
    label: 'Jefferson Community College',
    value: 'Jefferson Community College'
  },
  {
    id: '3549',
    label: 'Jefferson Community and Technical College',
    value: 'Jefferson Community and Technical College'
  },
  {
    id: '3550',
    label: 'Jefferson Davis Community College',
    value: 'Jefferson Davis Community College'
  },
  {
    id: '3551',
    label: 'Jefferson State Community College',
    value: 'Jefferson State Community College'
  },
  {
    id: '3552',
    label: 'Jeju International University',
    value: 'Jeju International University'
  },
  {
    id: '3553',
    label: 'Jeju National University',
    value: 'Jeju National University'
  },
  {
    id: '3554',
    label: 'Jeonju National University of Education',
    value: 'Jeonju National University of Education'
  },
  {
    id: '3555',
    label: 'Jeonju University',
    value: 'Jeonju University'
  },
  {
    id: '3556',
    label: 'Jeppiar Engineering College',
    value: 'Jeppiar Engineering College'
  },
  {
    id: '3557',
    label: 'Jerash Private University',
    value: 'Jerash Private University'
  },
  {
    id: '3558',
    label: 'Jerusalem University College',
    value: 'Jerusalem University College'
  },
  {
    id: '3559',
    label: 'Jesus University',
    value: 'Jesus University'
  },
  {
    id: '3560',
    label: 'Jewish University in Moscow',
    value: 'Jewish University in Moscow'
  },
  {
    id: '3561',
    label: 'Jiangsu University of Science and Technology',
    value: 'Jiangsu University of Science and Technology'
  },
  {
    id: '3562',
    label: 'Jiangxi Agricultural University',
    value: 'Jiangxi Agricultural University'
  },
  {
    id: '3563',
    label: 'Jiangxi Normal University',
    value: 'Jiangxi Normal University'
  },
  {
    id: '3564',
    label: 'Jiangxi University of Finance and Economics',
    value: 'Jiangxi University of Finance and Economics'
  },
  {
    id: '3565',
    label: 'Jiangxi University of Traditional Chinese Medicine',
    value: 'Jiangxi University of Traditional Chinese Medicine'
  },
  {
    id: '3566',
    label: 'Jiaying University',
    value: 'Jiaying University'
  },
  {
    id: '3567',
    label: 'Jichi Medical School',
    value: 'Jichi Medical School'
  },
  {
    id: '3568',
    label: 'Jigjiga University',
    value: 'Jigjiga University'
  },
  {
    id: '3569',
    label: 'Jikei University School of Medicine',
    value: 'Jikei University School of Medicine'
  },
  {
    id: '3570',
    label: 'Jilin Agricultural University',
    value: 'Jilin Agricultural University'
  },
  {
    id: '3571',
    label: 'Jilin University',
    value: 'Jilin University'
  },
  {
    id: '3572',
    label: 'Jilin University of Technology',
    value: 'Jilin University of Technology'
  },
  {
    id: '3573',
    label: 'Jimei University',
    value: 'Jimei University'
  },
  {
    id: '3574',
    label: 'Jimma University',
    value: 'Jimma University'
  },
  {
    id: '3575',
    label: 'Jinan University',
    value: 'Jinan University'
  },
  {
    id: '3576',
    label: 'Jinan University',
    value: 'Jinan University'
  },
  {
    id: '3577',
    label: 'Jingdezhen China Institute',
    value: 'Jingdezhen China Institute'
  },
  {
    id: '3578',
    label: 'Jinnah University for Women',
    value: 'Jinnah University for Women'
  },
  {
    id: '3579',
    label: 'Jishou University',
    value: 'Jishou University'
  },
  {
    id: '3580',
    label: "Jissen Women's University",
    value: "Jissen Women's University"
  },
  {
    id: '3581',
    label: 'Jiwaji University',
    value: 'Jiwaji University'
  },
  {
    id: '3582',
    label: 'Jobu University',
    value: 'Jobu University'
  },
  {
    id: '3583',
    label: 'Jodhpur National University',
    value: 'Jodhpur National University'
  },
  {
    id: '3584',
    label: 'Joetsu University of Education',
    value: 'Joetsu University of Education'
  },
  {
    id: '3585',
    label: 'Johann Wolfgang Goethe Universität Frankfurt am Main',
    value: 'Johann Wolfgang Goethe Universität Frankfurt am Main'
  },
  {
    id: '3586',
    label: 'Johannes Kepler Universität Linz',
    value: 'Johannes Kepler Universität Linz'
  },
  {
    id: '3587',
    label: 'Johannes-Gutenberg Universität Mainz',
    value: 'Johannes-Gutenberg Universität Mainz'
  },
  {
    id: '3588',
    label: 'John A Gupton College',
    value: 'John A Gupton College'
  },
  {
    id: '3589',
    label: 'John A Logan College',
    value: 'John A Logan College'
  },
  {
    id: '3590',
    label: 'John Brown University',
    value: 'John Brown University'
  },
  {
    id: '3591',
    label: 'John Cabot University',
    value: 'John Cabot University'
  },
  {
    id: '3592',
    label: 'John F. Kennedy University',
    value: 'John F. Kennedy University'
  },
  {
    id: '3593',
    label: 'John Tyler Community College',
    value: 'John Tyler Community College'
  },
  {
    id: '3594',
    label: 'John Wood Community College',
    value: 'John Wood Community College'
  },
  {
    id: '3595',
    label: 'Johns Hopkins University',
    value: 'Johns Hopkins University'
  },
  {
    id: '3596',
    label: 'Johns Hopkins University Applied Physics Laboratory',
    value: 'Johns Hopkins University Applied Physics Laboratory'
  },
  {
    id: '3597',
    label: 'Johns Hopkins University, SAIS Bologna Center',
    value: 'Johns Hopkins University, SAIS Bologna Center'
  },
  {
    id: '3598',
    label: 'Johnson & Wales University',
    value: 'Johnson & Wales University'
  },
  {
    id: '3599',
    label: 'Johnson Bible College',
    value: 'Johnson Bible College'
  },
  {
    id: '3600',
    label: 'Johnson C Smith Theological Seminary *',
    value: 'Johnson C Smith Theological Seminary *'
  },
  {
    id: '3601',
    label: 'Johnson C. Smith University',
    value: 'Johnson C. Smith University'
  },
  {
    id: '3602',
    label: 'Johnson County Community College',
    value: 'Johnson County Community College'
  },
  {
    id: '3603',
    label: 'Johnston Community College',
    value: 'Johnston Community College'
  },
  {
    id: '3604',
    label: 'Johore Bharu Primeir Polytechnic',
    value: 'Johore Bharu Primeir Polytechnic'
  },
  {
    id: '3605',
    label: 'Joliet Junior College',
    value: 'Joliet Junior College'
  },
  {
    id: '3606',
    label: 'Jomo Kenyatta University of Agriculture and Technology',
    value: 'Jomo Kenyatta University of Agriculture and Technology'
  },
  {
    id: '3607',
    label: 'Jones College',
    value: 'Jones College'
  },
  {
    id: '3608',
    label: 'Jones County Junior College',
    value: 'Jones County Junior College'
  },
  {
    id: '3609',
    label: 'Joong-Ang Sangha University',
    value: 'Joong-Ang Sangha University'
  },
  {
    id: '3610',
    label: 'Joongbu University',
    value: 'Joongbu University'
  },
  {
    id: '3611',
    label: 'Jordan Academy of Music / Higher Institute of Music',
    value: 'Jordan Academy of Music / Higher Institute of Music'
  },
  {
    id: '3612',
    label: 'Jordan University of Science and Technology',
    value: 'Jordan University of Science and Technology'
  },
  {
    id: '3613',
    label: 'Josai International University',
    value: 'Josai International University'
  },
  {
    id: '3614',
    label: 'Josai University',
    value: 'Josai University'
  },
  {
    id: '3615',
    label: 'Joseph Ayo Babalola University',
    value: 'Joseph Ayo Babalola University'
  },
  {
    id: '3616',
    label: 'Jubail Industrial College',
    value: 'Jubail Industrial College'
  },
  {
    id: '3617',
    label: 'Jubail University College',
    value: 'Jubail University College'
  },
  {
    id: '3618',
    label: 'Judson College',
    value: 'Judson College'
  },
  {
    id: '3619',
    label: 'Jumeira University',
    value: 'Jumeira University'
  },
  {
    id: '3620',
    label: 'Jungwon University',
    value: 'Jungwon University'
  },
  {
    id: '3621',
    label: 'Juniata College',
    value: 'Juniata College'
  },
  {
    id: '3622',
    label: 'Juntendo University',
    value: 'Juntendo University'
  },
  {
    id: '3623',
    label: 'Justus Liebig Universität Gießen',
    value: 'Justus Liebig Universität Gießen'
  },
  {
    id: '3624',
    label: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala',
    value: 'Józef Tyszkiewicz College of Business and Computer Science in Bielsko-Biala'
  },
  {
    id: '3625',
    label: 'Jönköping University College',
    value: 'Jönköping University College'
  },
  {
    id: '3626',
    label: 'KASB Institute of Technology',
    value: 'KASB Institute of Technology'
  },
  {
    id: '3627',
    label: 'KBU International College',
    value: 'KBU International College'
  },
  {
    id: '3628',
    label: 'KC University',
    value: 'KC University'
  },
  {
    id: '3629',
    label: 'KCG College of Technology',
    value: 'KCG College of Technology'
  },
  {
    id: '3630',
    label: 'KDI School of Public Policy and Management',
    value: 'KDI School of Public Policy and Management'
  },
  {
    id: '3631',
    label: 'KDU College Sdn Bhd',
    value: 'KDU College Sdn Bhd'
  },
  {
    id: '3632',
    label: 'KIET Group of Institutions',
    value: 'KIET Group of Institutions'
  },
  {
    id: '3633',
    label: 'KROK Economics and Law University',
    value: 'KROK Economics and Law University'
  },
  {
    id: '3634',
    label: 'KTH Royal Institute of Technology',
    value: 'KTH Royal Institute of Technology'
  },
  {
    id: '3635',
    label: 'Kabarak University',
    value: 'Kabarak University'
  },
  {
    id: '3636',
    label: 'Kabardino-Balkarian State University',
    value: 'Kabardino-Balkarian State University'
  },
  {
    id: '3637',
    label: 'Kaboora Institute of Higher Education',
    value: 'Kaboora Institute of Higher Education'
  },
  {
    id: '3638',
    label: 'Kabul Education University',
    value: 'Kabul Education University'
  },
  {
    id: '3639',
    label: 'Kabul Health Sciences Institute',
    value: 'Kabul Health Sciences Institute'
  },
  {
    id: '3640',
    label: 'Kabul Medical University',
    value: 'Kabul Medical University'
  },
  {
    id: '3641',
    label: 'Kabul University',
    value: 'Kabul University'
  },
  {
    id: '3642',
    label: 'Kadir Has University',
    value: 'Kadir Has University'
  },
  {
    id: '3643',
    label: 'Kaduna State University',
    value: 'Kaduna State University'
  },
  {
    id: '3644',
    label: 'Kafkas University',
    value: 'Kafkas University'
  },
  {
    id: '3645',
    label: 'Kafr El-Sheikh University',
    value: 'Kafr El-Sheikh University'
  },
  {
    id: '3646',
    label: 'Kagawa Institute of Nutrition',
    value: 'Kagawa Institute of Nutrition'
  },
  {
    id: '3647',
    label: 'Kagawa Medical School',
    value: 'Kagawa Medical School'
  },
  {
    id: '3648',
    label: 'Kagawa University',
    value: 'Kagawa University'
  },
  {
    id: '3649',
    label: 'Kagoshima Immaculate Heart University',
    value: 'Kagoshima Immaculate Heart University'
  },
  {
    id: '3650',
    label: 'Kagoshima University',
    value: 'Kagoshima University'
  },
  {
    id: '3651',
    label: "Kagoshima Women's College",
    value: "Kagoshima Women's College"
  },
  {
    id: '3652',
    label: 'Kahramanmaras Sütcü Imam University',
    value: 'Kahramanmaras Sütcü Imam University'
  },
  {
    id: '3653',
    label: 'Kakatiya University',
    value: 'Kakatiya University'
  },
  {
    id: '3654',
    label: 'Kalamazoo College',
    value: 'Kalamazoo College'
  },
  {
    id: '3655',
    label: 'Kalamazoo Valley Community College',
    value: 'Kalamazoo Valley Community College'
  },
  {
    id: '3656',
    label: 'Kalinga University',
    value: 'Kalinga University'
  },
  {
    id: '3657',
    label: 'Kaliningrad State Technical University',
    value: 'Kaliningrad State Technical University'
  },
  {
    id: '3658',
    label: 'Kalmar University College',
    value: 'Kalmar University College'
  },
  {
    id: '3659',
    label: 'Kalmyk State University',
    value: 'Kalmyk State University'
  },
  {
    id: '3660',
    label: 'Kaluga State Pedagogical University',
    value: 'Kaluga State Pedagogical University'
  },
  {
    id: '3661',
    label: 'Kalø Økologisk Agricultural College',
    value: 'Kalø Økologisk Agricultural College'
  },
  {
    id: '3662',
    label: "Kamakura Women's College",
    value: "Kamakura Women's College"
  },
  {
    id: '3663',
    label: 'Kamal-Ol-Molk University',
    value: 'Kamal-Ol-Molk University'
  },
  {
    id: '3664',
    label: 'Kamchatka State University Vitus Bering',
    value: 'Kamchatka State University Vitus Bering'
  },
  {
    id: '3665',
    label: 'Kameshwar Singh Darbhanga Sanskrit University',
    value: 'Kameshwar Singh Darbhanga Sanskrit University'
  },
  {
    id: '3666',
    label: 'Kampala International University',
    value: 'Kampala International University'
  },
  {
    id: '3667',
    label: 'Kampala University',
    value: 'Kampala University'
  },
  {
    id: '3668',
    label: 'Kanagawa Dental College',
    value: 'Kanagawa Dental College'
  },
  {
    id: '3669',
    label: 'Kanagawa Institute of Technology',
    value: 'Kanagawa Institute of Technology'
  },
  {
    id: '3670',
    label: 'Kanagawa University',
    value: 'Kanagawa University'
  },
  {
    id: '3671',
    label: 'Kanawha Valley Community and Technical College',
    value: 'Kanawha Valley Community and Technical College'
  },
  {
    id: '3672',
    label: 'Kanazawa College of Art',
    value: 'Kanazawa College of Art'
  },
  {
    id: '3673',
    label: 'Kanazawa College of Economics',
    value: 'Kanazawa College of Economics'
  },
  {
    id: '3674',
    label: 'Kanazawa Gakuin University',
    value: 'Kanazawa Gakuin University'
  },
  {
    id: '3675',
    label: 'Kanazawa Institute of Technology',
    value: 'Kanazawa Institute of Technology'
  },
  {
    id: '3676',
    label: 'Kanazawa Medical University',
    value: 'Kanazawa Medical University'
  },
  {
    id: '3677',
    label: 'Kanazawa University',
    value: 'Kanazawa University'
  },
  {
    id: '3678',
    label: 'Kanda University of International Studies',
    value: 'Kanda University of International Studies'
  },
  {
    id: '3679',
    label: 'Kandahar University',
    value: 'Kandahar University'
  },
  {
    id: '3680',
    label: 'Kangnam University',
    value: 'Kangnam University'
  },
  {
    id: '3681',
    label: 'Kangnung National University',
    value: 'Kangnung National University'
  },
  {
    id: '3682',
    label: 'Kangwon National University',
    value: 'Kangwon National University'
  },
  {
    id: '3683',
    label: 'Kankakee Community College',
    value: 'Kankakee Community College'
  },
  {
    id: '3684',
    label: 'Kannada University',
    value: 'Kannada University'
  },
  {
    id: '3685',
    label: 'Kannur University',
    value: 'Kannur University'
  },
  {
    id: '3686',
    label: 'Kano State University of Technology Wudil',
    value: 'Kano State University of Technology Wudil'
  },
  {
    id: '3687',
    label: 'Kanpur University',
    value: 'Kanpur University'
  },
  {
    id: '3688',
    label: 'Kansai Gaidai University',
    value: 'Kansai Gaidai University'
  },
  {
    id: '3689',
    label: 'Kansai Medical University',
    value: 'Kansai Medical University'
  },
  {
    id: '3690',
    label: 'Kansai University',
    value: 'Kansai University'
  },
  {
    id: '3691',
    label: 'Kansai University of International Studies',
    value: 'Kansai University of International Studies'
  },
  {
    id: '3692',
    label: 'Kansai University of Social Welfare',
    value: 'Kansai University of Social Welfare'
  },
  {
    id: '3693',
    label: 'Kansas City Kansas Community College',
    value: 'Kansas City Kansas Community College'
  },
  {
    id: '3694',
    label: 'Kansas State University',
    value: 'Kansas State University'
  },
  {
    id: '3695',
    label: 'Kansas Wesleyan University',
    value: 'Kansas Wesleyan University'
  },
  {
    id: '3696',
    label: 'Kantipur Engineering College',
    value: 'Kantipur Engineering College'
  },
  {
    id: '3697',
    label: 'Kanto Gakuen University',
    value: 'Kanto Gakuen University'
  },
  {
    id: '3698',
    label: 'Kanto Gakuin University',
    value: 'Kanto Gakuin University'
  },
  {
    id: '3699',
    label: 'Kaohsiung Medical College',
    value: 'Kaohsiung Medical College'
  },
  {
    id: '3700',
    label: 'Kaohsuing Open University',
    value: 'Kaohsuing Open University'
  },
  {
    id: '3701',
    label: 'Kapiolani Community College',
    value: 'Kapiolani Community College'
  },
  {
    id: '3702',
    label: 'Kaplan University',
    value: 'Kaplan University'
  },
  {
    id: '3703',
    label: 'Kar University',
    value: 'Kar University'
  },
  {
    id: '3704',
    label: 'Karabuk University',
    value: 'Karabuk University'
  },
  {
    id: '3705',
    label: 'Karachi Institute Of Economics And Technology',
    value: 'Karachi Institute Of Economics And Technology'
  },
  {
    id: '3706',
    label: 'Karachi Institute of Information Technology',
    value: 'Karachi Institute of Information Technology'
  },
  {
    id: '3707',
    label: 'Karachi Medical and Dental College',
    value: 'Karachi Medical and Dental College'
  },
  {
    id: '3708',
    label: 'Karachi School of Art',
    value: 'Karachi School of Art'
  },
  {
    id: '3709',
    label: 'Karadeniz Technical University',
    value: 'Karadeniz Technical University'
  },
  {
    id: '3710',
    label: 'Karaganda State Buketov University',
    value: 'Karaganda State Buketov University'
  },
  {
    id: '3711',
    label: 'Karaganda State Technical University',
    value: 'Karaganda State Technical University'
  },
  {
    id: '3712',
    label: 'Karakoram International University',
    value: 'Karakoram International University'
  },
  {
    id: '3713',
    label: 'Karatay University',
    value: 'Karatay University'
  },
  {
    id: '3714',
    label: 'Karatina University',
    value: 'Karatina University'
  },
  {
    id: '3715',
    label: 'Kardan University',
    value: 'Kardan University'
  },
  {
    id: '3716',
    label: 'Karel De Grote Hogeschool',
    value: 'Karel De Grote Hogeschool'
  },
  {
    id: '3717',
    label: 'Karelian State Pedagogical University',
    value: 'Karelian State Pedagogical University'
  },
  {
    id: '3718',
    label: 'Karlshochschule International University',
    value: 'Karlshochschule International University'
  },
  {
    id: '3719',
    label: 'Karlsruher Institut für Technologie',
    value: 'Karlsruher Institut für Technologie'
  },
  {
    id: '3720',
    label: 'Karlstad University',
    value: 'Karlstad University'
  },
  {
    id: '3721',
    label: 'Karnatak University',
    value: 'Karnatak University'
  },
  {
    id: '3722',
    label: 'Karnataka State Law University',
    value: 'Karnataka State Law University'
  },
  {
    id: '3723',
    label: 'Karnataka State Open University',
    value: 'Karnataka State Open University'
  },
  {
    id: '3724',
    label: 'Karolinska Institute Stockholm',
    value: 'Karolinska Institute Stockholm'
  },
  {
    id: '3725',
    label: 'Karpagam University',
    value: 'Karpagam University'
  },
  {
    id: '3726',
    label: 'Karunya Institute Of Technology',
    value: 'Karunya Institute Of Technology'
  },
  {
    id: '3727',
    label: 'Karwan Institute of Higher Education',
    value: 'Karwan Institute of Higher Education'
  },
  {
    id: '3728',
    label: 'Kasem Bundit University',
    value: 'Kasem Bundit University'
  },
  {
    id: '3729',
    label: 'Kasetsart University',
    value: 'Kasetsart University'
  },
  {
    id: '3730',
    label: 'Kashan University',
    value: 'Kashan University'
  },
  {
    id: '3731',
    label: 'Kashan University of Medical Sciences',
    value: 'Kashan University of Medical Sciences'
  },
  {
    id: '3732',
    label: 'Kaskaskia College',
    value: 'Kaskaskia College'
  },
  {
    id: '3733',
    label: 'Kassala University',
    value: 'Kassala University'
  },
  {
    id: '3734',
    label: 'Kastamonu University',
    value: 'Kastamonu University'
  },
  {
    id: '3735',
    label: 'Kateb Institute of Higher Education',
    value: 'Kateb Institute of Higher Education'
  },
  {
    id: '3736',
    label: 'Kathmandu University',
    value: 'Kathmandu University'
  },
  {
    id: '3737',
    label: 'Katholieke Hogeschool Kempen',
    value: 'Katholieke Hogeschool Kempen'
  },
  {
    id: '3738',
    label: 'Katholieke Hogeschool Leuven',
    value: 'Katholieke Hogeschool Leuven'
  },
  {
    id: '3739',
    label: 'Katholieke Hogeschool Limburg',
    value: 'Katholieke Hogeschool Limburg'
  },
  {
    id: '3740',
    label: 'Katholieke Universiteit Brussel',
    value: 'Katholieke Universiteit Brussel'
  },
  {
    id: '3741',
    label: 'Katholieke Universiteit Leuven',
    value: 'Katholieke Universiteit Leuven'
  },
  {
    id: '3742',
    label: 'Katholieke Universiteit Leuven, Campus Kortrijk',
    value: 'Katholieke Universiteit Leuven, Campus Kortrijk'
  },
  {
    id: '3743',
    label: 'Katholisch-Theologische Privatuniversität Linz',
    value: 'Katholisch-Theologische Privatuniversität Linz'
  },
  {
    id: '3744',
    label: 'Katholische Fachhochschule Berlin (KFB)',
    value: 'Katholische Fachhochschule Berlin (KFB)'
  },
  {
    id: '3745',
    label:
      'Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege',
    value:
      'Katholische Fachhochschule Freiburg, Hochschule für Sozialwesen, Religionspädagogik und Pflege'
  },
  {
    id: '3746',
    label: 'Katholische Fachhochschule Mainz',
    value: 'Katholische Fachhochschule Mainz'
  },
  {
    id: '3747',
    label: 'Katholische Fachhochschule Norddeutschland',
    value: 'Katholische Fachhochschule Norddeutschland'
  },
  {
    id: '3748',
    label: 'Katholische Fachhochschule Nordrhein-Westfalen',
    value: 'Katholische Fachhochschule Nordrhein-Westfalen'
  },
  {
    id: '3749',
    label: 'Katholische Hochschule für Soziale Arbeit Saarbrücken',
    value: 'Katholische Hochschule für Soziale Arbeit Saarbrücken'
  },
  {
    id: '3750',
    label: 'Katholische Stiftungsfachhochschule München',
    value: 'Katholische Stiftungsfachhochschule München'
  },
  {
    id: '3751',
    label: 'Katholische Universität Eichstätt',
    value: 'Katholische Universität Eichstätt'
  },
  {
    id: '3752',
    label: 'Katowice School of Economics',
    value: 'Katowice School of Economics'
  },
  {
    id: '3753',
    label: 'Katsina University',
    value: 'Katsina University'
  },
  {
    id: '3754',
    label: 'Kauai Community College',
    value: 'Kauai Community College'
  },
  {
    id: '3755',
    label: 'Kaunas Medical Academy',
    value: 'Kaunas Medical Academy'
  },
  {
    id: '3756',
    label: 'Kaunas University of Technology',
    value: 'Kaunas University of Technology'
  },
  {
    id: '3757',
    label: "Kawamura Gakuen Woman's University",
    value: "Kawamura Gakuen Woman's University"
  },
  {
    id: '3758',
    label: 'Kawasaki Medical School',
    value: 'Kawasaki Medical School'
  },
  {
    id: '3759',
    label: 'Kaya University',
    value: 'Kaya University'
  },
  {
    id: '3760',
    label: 'Kazak American University',
    value: 'Kazak American University'
  },
  {
    id: '3761',
    label: 'Kazak National Technical University',
    value: 'Kazak National Technical University'
  },
  {
    id: '3762',
    label: 'Kazak State University of Agriculture',
    value: 'Kazak State University of Agriculture'
  },
  {
    id: '3763',
    label: 'Kazakh British Technical University',
    value: 'Kazakh British Technical University'
  },
  {
    id: '3764',
    label: 'Kazakhstan Institute of Management, Economics, and Strategic Research',
    value: 'Kazakhstan Institute of Management, Economics, and Strategic Research'
  },
  {
    id: '3765',
    label: 'Kazan State Academy of Architecture and Civil Engineering',
    value: 'Kazan State Academy of Architecture and Civil Engineering'
  },
  {
    id: '3766',
    label: 'Kazan State Finance and Economics Institute',
    value: 'Kazan State Finance and Economics Institute'
  },
  {
    id: '3767',
    label: 'Kazan State Music Conservatory',
    value: 'Kazan State Music Conservatory'
  },
  {
    id: '3768',
    label: 'Kazan State Pedagogical University',
    value: 'Kazan State Pedagogical University'
  },
  {
    id: '3769',
    label: 'Kazan State University',
    value: 'Kazan State University'
  },
  {
    id: '3770',
    label: 'Kazan State University of Medicine',
    value: 'Kazan State University of Medicine'
  },
  {
    id: '3771',
    label: 'Kazan State University of Technology',
    value: 'Kazan State University of Technology'
  },
  {
    id: '3772',
    label: 'Kean College',
    value: 'Kean College'
  },
  {
    id: '3773',
    label: 'Kebbi State University of Science and Technology',
    value: 'Kebbi State University of Science and Technology'
  },
  {
    id: '3774',
    label: 'Keele University',
    value: 'Keele University'
  },
  {
    id: '3775',
    label: 'Keene State College',
    value: 'Keene State College'
  },
  {
    id: '3776',
    label: 'Keiai University',
    value: 'Keiai University'
  },
  {
    id: '3777',
    label: 'Keimyung University',
    value: 'Keimyung University'
  },
  {
    id: '3778',
    label: 'Keio University',
    value: 'Keio University'
  },
  {
    id: '3779',
    label: 'Keio University',
    value: 'Keio University'
  },
  {
    id: '3780',
    label: 'Keisen Jogaku-en College',
    value: 'Keisen Jogaku-en College'
  },
  {
    id: '3781',
    label: 'Keiser University',
    value: 'Keiser University'
  },
  {
    id: '3782',
    label: 'Keiwa College',
    value: 'Keiwa College'
  },
  {
    id: '3783',
    label: 'Keller Graduate School of Management',
    value: 'Keller Graduate School of Management'
  },
  {
    id: '3784',
    label: 'Kellogg Community College',
    value: 'Kellogg Community College'
  },
  {
    id: '3785',
    label: 'Kemerovo State University',
    value: 'Kemerovo State University'
  },
  {
    id: '3786',
    label: 'Kenai Peninsula College',
    value: 'Kenai Peninsula College'
  },
  {
    id: '3787',
    label: 'Kennebec Valley Community College',
    value: 'Kennebec Valley Community College'
  },
  {
    id: '3788',
    label: 'Kennesaw State University',
    value: 'Kennesaw State University'
  },
  {
    id: '3789',
    label: 'Kent State University',
    value: 'Kent State University'
  },
  {
    id: '3790',
    label: 'Kentucky Community & Technical College System',
    value: 'Kentucky Community & Technical College System'
  },
  {
    id: '3791',
    label: 'Kentucky State University',
    value: 'Kentucky State University'
  },
  {
    id: '3792',
    label: 'Kenya College of Accountancy',
    value: 'Kenya College of Accountancy'
  },
  {
    id: '3793',
    label: 'Kenya Medical Training College',
    value: 'Kenya Medical Training College'
  },
  {
    id: '3794',
    label: 'Kenya Methodist University',
    value: 'Kenya Methodist University'
  },
  {
    id: '3795',
    label: 'Kenyatta University',
    value: 'Kenyatta University'
  },
  {
    id: '3796',
    label: 'Kenyon College',
    value: 'Kenyon College'
  },
  {
    id: '3797',
    label: 'Kerala Agricultural University',
    value: 'Kerala Agricultural University'
  },
  {
    id: '3798',
    label: 'Kerala University of Fisheries and Ocean Sciences',
    value: 'Kerala University of Fisheries and Ocean Sciences'
  },
  {
    id: '3799',
    label: 'Kerbala University',
    value: 'Kerbala University'
  },
  {
    id: '3800',
    label: 'Kerman Graduate University of Technology',
    value: 'Kerman Graduate University of Technology'
  },
  {
    id: '3801',
    label: 'Kerman University of Medical Sciences',
    value: 'Kerman University of Medical Sciences'
  },
  {
    id: '3802',
    label: 'Kermanshah University of Medical Sciences',
    value: 'Kermanshah University of Medical Sciences'
  },
  {
    id: '3803',
    label: 'Kermanshah University of Technology',
    value: 'Kermanshah University of Technology'
  },
  {
    id: '3804',
    label: 'Kessben College',
    value: 'Kessben College'
  },
  {
    id: '3805',
    label: 'Kettering University',
    value: 'Kettering University'
  },
  {
    id: '3806',
    label: 'Keweenaw Bay Ojibwa Community College',
    value: 'Keweenaw Bay Ojibwa Community College'
  },
  {
    id: '3807',
    label: 'Khabarovsk State Academy of Economics and Law',
    value: 'Khabarovsk State Academy of Economics and Law'
  },
  {
    id: '3808',
    label: 'Khabarovsk State University of Technology',
    value: 'Khabarovsk State University of Technology'
  },
  {
    id: '3809',
    label: 'Khajeh Nasir Toosi University of Technology',
    value: 'Khajeh Nasir Toosi University of Technology'
  },
  {
    id: '3810',
    label: 'Khalifa University',
    value: 'Khalifa University'
  },
  {
    id: '3811',
    label: 'Khalifa University of Science, Technology and Research',
    value: 'Khalifa University of Science, Technology and Research'
  },
  {
    id: '3812',
    label: 'Khana-e-Noor Institute of Higher Education',
    value: 'Khana-e-Noor Institute of Higher Education'
  },
  {
    id: '3813',
    label: 'Kharkiv National University',
    value: 'Kharkiv National University'
  },
  {
    id: '3814',
    label: 'Kharkiv National University',
    value: 'Kharkiv National University'
  },
  {
    id: '3815',
    label: 'Kharkiv National University of Economics',
    value: 'Kharkiv National University of Economics'
  },
  {
    id: '3816',
    label: 'Kharkiv National University of Radio Electronics',
    value: 'Kharkiv National University of Radio Electronics'
  },
  {
    id: '3817',
    label: 'Kharkiv State Medical University',
    value: 'Kharkiv State Medical University'
  },
  {
    id: '3818',
    label: 'Kharkiv State Technical University of Construction and Architecture',
    value: 'Kharkiv State Technical University of Construction and Architecture'
  },
  {
    id: '3819',
    label: 'Kharkiv State Transport Technical University',
    value: 'Kharkiv State Transport Technical University'
  },
  {
    id: '3820',
    label: 'Khazar University',
    value: 'Khazar University'
  },
  {
    id: '3821',
    label: 'Kherson State University',
    value: 'Kherson State University'
  },
  {
    id: '3822',
    label: 'Khomeinishahr Payame Noor University',
    value: 'Khomeinishahr Payame Noor University'
  },
  {
    id: '3823',
    label: 'Khon Kaen University',
    value: 'Khon Kaen University'
  },
  {
    id: '3824',
    label: 'Khulna University',
    value: 'Khulna University'
  },
  {
    id: '3825',
    label: 'Khulna University of Engineering And Technology',
    value: 'Khulna University of Engineering And Technology'
  },
  {
    id: '3826',
    label: 'Khurasan University',
    value: 'Khurasan University'
  },
  {
    id: '3827',
    label: 'Kibi International University',
    value: 'Kibi International University'
  },
  {
    id: '3828',
    label: 'Kiev National Economic University',
    value: 'Kiev National Economic University'
  },
  {
    id: '3829',
    label: 'Kiev National University of Construction and Architecture',
    value: 'Kiev National University of Construction and Architecture'
  },
  {
    id: '3830',
    label: 'Kiev School Of Economics',
    value: 'Kiev School Of Economics'
  },
  {
    id: '3831',
    label: 'Kiev Slavonic University',
    value: 'Kiev Slavonic University'
  },
  {
    id: '3832',
    label: 'Kigali Health Institute',
    value: 'Kigali Health Institute'
  },
  {
    id: '3833',
    label: 'Kigali Independent University',
    value: 'Kigali Independent University'
  },
  {
    id: '3834',
    label: 'Kigali Institute of Education',
    value: 'Kigali Institute of Education'
  },
  {
    id: '3835',
    label: 'Kigali Institute of Science & Technology',
    value: 'Kigali Institute of Science & Technology'
  },
  {
    id: '3836',
    label: 'Kilgore College',
    value: 'Kilgore College'
  },
  {
    id: '3837',
    label: 'Kilian Community College',
    value: 'Kilian Community College'
  },
  {
    id: '3838',
    label: 'Kilimanjaro Christian Medical College',
    value: 'Kilimanjaro Christian Medical College'
  },
  {
    id: '3839',
    label: 'Kilis 7 Aralık University',
    value: 'Kilis 7 Aralık University'
  },
  {
    id: '3840',
    label: 'King Abdul Aziz University',
    value: 'King Abdul Aziz University'
  },
  {
    id: '3841',
    label: 'King Abdullah University of Science and Technology',
    value: 'King Abdullah University of Science and Technology'
  },
  {
    id: '3842',
    label: 'King Fahad University of Petroleum and Minerals',
    value: 'King Fahad University of Petroleum and Minerals'
  },
  {
    id: '3843',
    label: 'King Fahd Security College',
    value: 'King Fahd Security College'
  },
  {
    id: '3844',
    label: 'King Faisal University',
    value: 'King Faisal University'
  },
  {
    id: '3845',
    label: 'King Khaled University',
    value: 'King Khaled University'
  },
  {
    id: '3846',
    label: "King Mongkut's Institute of Technology Ladkrabang",
    value: "King Mongkut's Institute of Technology Ladkrabang"
  },
  {
    id: '3847',
    label: "King Mongkut's University of Technology North Bangkok",
    value: "King Mongkut's University of Technology North Bangkok"
  },
  {
    id: '3848',
    label: "King Mongkut's University of Technology Thonburi",
    value: "King Mongkut's University of Technology Thonburi"
  },
  {
    id: '3849',
    label: 'King Saud University',
    value: 'King Saud University'
  },
  {
    id: '3850',
    label: 'King Saud bin Abdulaziz University for Health Sciences',
    value: 'King Saud bin Abdulaziz University for Health Sciences'
  },
  {
    id: '3851',
    label: 'King University',
    value: 'King University'
  },
  {
    id: '3852',
    label: "King's College",
    value: "King's College"
  },
  {
    id: '3853',
    label: "King's College London, University of London",
    value: "King's College London, University of London"
  },
  {
    id: '3854',
    label: "King's University College",
    value: "King's University College"
  },
  {
    id: '3855',
    label: 'Kingston College',
    value: 'Kingston College'
  },
  {
    id: '3856',
    label: 'Kingston University',
    value: 'Kingston University'
  },
  {
    id: '3857',
    label: 'Kinjo Gakuin University',
    value: 'Kinjo Gakuin University'
  },
  {
    id: '3858',
    label: 'Kinki University',
    value: 'Kinki University'
  },
  {
    id: '3859',
    label: 'Kirchliche Hochschule Bethel',
    value: 'Kirchliche Hochschule Bethel'
  },
  {
    id: '3860',
    label: 'Kirikkale University',
    value: 'Kirikkale University'
  },
  {
    id: '3861',
    label: 'Kiriri Womens University of Science and Technology',
    value: 'Kiriri Womens University of Science and Technology'
  },
  {
    id: '3862',
    label: 'Kirklareli University',
    value: 'Kirklareli University'
  },
  {
    id: '3863',
    label: 'Kirkwood Community College',
    value: 'Kirkwood Community College'
  },
  {
    id: '3864',
    label: 'Kirtland Community College',
    value: 'Kirtland Community College'
  },
  {
    id: '3865',
    label: 'Kishwaukee College',
    value: 'Kishwaukee College'
  },
  {
    id: '3866',
    label: 'Kisii University',
    value: 'Kisii University'
  },
  {
    id: '3867',
    label: 'Kitakyushu University',
    value: 'Kitakyushu University'
  },
  {
    id: '3868',
    label: 'Kitami Institute of Technology',
    value: 'Kitami Institute of Technology'
  },
  {
    id: '3869',
    label: 'Kitasato University',
    value: 'Kitasato University'
  },
  {
    id: '3870',
    label: 'Kkottongnae University',
    value: 'Kkottongnae University'
  },
  {
    id: '3871',
    label: 'Klaipeda University',
    value: 'Klaipeda University'
  },
  {
    id: '3872',
    label: 'Klamath Community College',
    value: 'Klamath Community College'
  },
  {
    id: '3873',
    label: 'Knightsbridge University',
    value: 'Knightsbridge University'
  },
  {
    id: '3874',
    label: 'Knowledge College for Science and Technology',
    value: 'Knowledge College for Science and Technology'
  },
  {
    id: '3875',
    label: 'Knowledge International University',
    value: 'Knowledge International University'
  },
  {
    id: '3876',
    label: 'Knox College',
    value: 'Knox College'
  },
  {
    id: '3877',
    label: 'Knoxville College',
    value: 'Knoxville College'
  },
  {
    id: '3878',
    label: 'Kobe City University of ForeignStudies',
    value: 'Kobe City University of ForeignStudies'
  },
  {
    id: '3879',
    label: 'Kobe Design University',
    value: 'Kobe Design University'
  },
  {
    id: '3880',
    label: 'Kobe Gakuin University',
    value: 'Kobe Gakuin University'
  },
  {
    id: '3881',
    label: 'Kobe International University',
    value: 'Kobe International University'
  },
  {
    id: '3882',
    label: 'Kobe Jogakuin University',
    value: 'Kobe Jogakuin University'
  },
  {
    id: '3883',
    label: 'Kobe Pharmaceutical University',
    value: 'Kobe Pharmaceutical University'
  },
  {
    id: '3884',
    label: "Kobe Shinwa Women's University",
    value: "Kobe Shinwa Women's University"
  },
  {
    id: '3885',
    label: "Kobe Shoin Women's University",
    value: "Kobe Shoin Women's University"
  },
  {
    id: '3886',
    label: 'Kobe University',
    value: 'Kobe University'
  },
  {
    id: '3887',
    label: 'Kobe University of Mercantile Marine',
    value: 'Kobe University of Mercantile Marine'
  },
  {
    id: '3888',
    label: "Kobe Women's University",
    value: "Kobe Women's University"
  },
  {
    id: '3889',
    label: 'Kocaeli University',
    value: 'Kocaeli University'
  },
  {
    id: '3890',
    label: 'Kochi Medical School',
    value: 'Kochi Medical School'
  },
  {
    id: '3891',
    label: 'Kochi University',
    value: 'Kochi University'
  },
  {
    id: '3892',
    label: 'Kochi University of Technology',
    value: 'Kochi University of Technology'
  },
  {
    id: '3893',
    label: "Kochi Women's University",
    value: "Kochi Women's University"
  },
  {
    id: '3894',
    label: 'Kodiak College',
    value: 'Kodiak College'
  },
  {
    id: '3895',
    label: 'Kodolanyi Janos University College',
    value: 'Kodolanyi Janos University College'
  },
  {
    id: '3896',
    label: 'Koforidua Technical University',
    value: 'Koforidua Technical University'
  },
  {
    id: '3897',
    label: 'Kogakkan University',
    value: 'Kogakkan University'
  },
  {
    id: '3898',
    label: 'Kogakuin University',
    value: 'Kogakuin University'
  },
  {
    id: '3899',
    label: 'Kogi State University',
    value: 'Kogi State University'
  },
  {
    id: '3900',
    label: 'Kohat University of Science and Technology  (KUST)',
    value: 'Kohat University of Science and Technology  (KUST)'
  },
  {
    id: '3901',
    label: 'Kohinoor School of Hospitality Management',
    value: 'Kohinoor School of Hospitality Management'
  },
  {
    id: '3902',
    label: "Koka Women's College",
    value: "Koka Women's College"
  },
  {
    id: '3903',
    label: 'Kokand State Pedagogical Institute',
    value: 'Kokand State Pedagogical Institute'
  },
  {
    id: '3904',
    label: 'Kokugakuin University',
    value: 'Kokugakuin University'
  },
  {
    id: '3905',
    label: 'Kokushikan University',
    value: 'Kokushikan University'
  },
  {
    id: '3906',
    label: 'Kolej Universiti Insaniah',
    value: 'Kolej Universiti Insaniah'
  },
  {
    id: '3907',
    label: 'Komar University of Science and Technology',
    value: 'Komar University of Science and Technology'
  },
  {
    id: '3908',
    label: 'Komazawa University',
    value: 'Komazawa University'
  },
  {
    id: '3909',
    label: 'Komsomolsk-on-Amur State Technical University',
    value: 'Komsomolsk-on-Amur State Technical University'
  },
  {
    id: '3910',
    label: 'Konan University',
    value: 'Konan University'
  },
  {
    id: '3911',
    label: "Konan Women's University",
    value: "Konan Women's University"
  },
  {
    id: '3912',
    label: 'Kongju National University',
    value: 'Kongju National University'
  },
  {
    id: '3913',
    label: 'Kongju National University of Education',
    value: 'Kongju National University of Education'
  },
  {
    id: '3914',
    label: 'Konkan Agricultural University',
    value: 'Konkan Agricultural University'
  },
  {
    id: '3915',
    label: 'Konkuk University',
    value: 'Konkuk University'
  },
  {
    id: '3916',
    label: 'Konya Gida Tarim University',
    value: 'Konya Gida Tarim University'
  },
  {
    id: '3917',
    label: 'Konya Technical University',
    value: 'Konya Technical University'
  },
  {
    id: '3918',
    label: 'Konyang University',
    value: 'Konyang University'
  },
  {
    id: '3919',
    label: 'Kookmin University',
    value: 'Kookmin University'
  },
  {
    id: '3920',
    label: 'Korea Advanced Institute of Science & Technology',
    value: 'Korea Advanced Institute of Science & Technology'
  },
  {
    id: '3921',
    label: 'Korea Aerospace University',
    value: 'Korea Aerospace University'
  },
  {
    id: '3922',
    label: 'Korea Armed Forces Nursing Academy',
    value: 'Korea Armed Forces Nursing Academy'
  },
  {
    id: '3923',
    label: 'Korea Baptist Theological University and Seminary',
    value: 'Korea Baptist Theological University and Seminary'
  },
  {
    id: '3924',
    label: 'Korea Maritime University',
    value: 'Korea Maritime University'
  },
  {
    id: '3925',
    label: 'Korea Maritime and Ocean University',
    value: 'Korea Maritime and Ocean University'
  },
  {
    id: '3926',
    label: 'Korea Military Academy',
    value: 'Korea Military Academy'
  },
  {
    id: '3927',
    label: 'Korea National Open University',
    value: 'Korea National Open University'
  },
  {
    id: '3928',
    label: 'Korea National Sport University',
    value: 'Korea National Sport University'
  },
  {
    id: '3929',
    label: 'Korea National University of Arts',
    value: 'Korea National University of Arts'
  },
  {
    id: '3930',
    label: 'Korea National University of Cultural Heritage',
    value: 'Korea National University of Cultural Heritage'
  },
  {
    id: '3931',
    label: 'Korea National University of Education',
    value: 'Korea National University of Education'
  },
  {
    id: '3932',
    label: 'Korea National University of Physical Education',
    value: 'Korea National University of Physical Education'
  },
  {
    id: '3933',
    label: 'Korea Nazarene University',
    value: 'Korea Nazarene University'
  },
  {
    id: '3934',
    label: 'Korea Polytechnic University',
    value: 'Korea Polytechnic University'
  },
  {
    id: '3935',
    label: 'Korea University',
    value: 'Korea University'
  },
  {
    id: '3936',
    label: 'Korea University',
    value: 'Korea University'
  },
  {
    id: '3937',
    label: 'Korea University of Technology and Education',
    value: 'Korea University of Technology and Education'
  },
  {
    id: '3938',
    label: 'Korean Bible University',
    value: 'Korean Bible University'
  },
  {
    id: '3939',
    label: 'Korean National Police University',
    value: 'Korean National Police University'
  },
  {
    id: '3940',
    label: "Koriyama Women's University and College",
    value: "Koriyama Women's University and College"
  },
  {
    id: '3941',
    label: 'Koshien University',
    value: 'Koshien University'
  },
  {
    id: '3942',
    label: 'Kosin University',
    value: 'Kosin University'
  },
  {
    id: '3943',
    label: 'Kossuth Lajos University',
    value: 'Kossuth Lajos University'
  },
  {
    id: '3944',
    label: 'Kostanai State University',
    value: 'Kostanai State University'
  },
  {
    id: '3945',
    label: 'Kostroma State Technological University',
    value: 'Kostroma State Technological University'
  },
  {
    id: '3946',
    label: 'Kota Bharu Polytechnic',
    value: 'Kota Bharu Polytechnic'
  },
  {
    id: '3947',
    label: 'Kota Kinabalu Polytechnic',
    value: 'Kota Kinabalu Polytechnic'
  },
  {
    id: '3948',
    label: 'Kotelawala Defence University',
    value: 'Kotelawala Defence University'
  },
  {
    id: '3949',
    label: 'Koya University (Kurdistan Region)',
    value: 'Koya University (Kurdistan Region)'
  },
  {
    id: '3950',
    label: 'Koyasan University',
    value: 'Koyasan University'
  },
  {
    id: '3951',
    label: 'Koç University',
    value: 'Koç University'
  },
  {
    id: '3952',
    label: 'Krasnoyarsk State Technical University',
    value: 'Krasnoyarsk State Technical University'
  },
  {
    id: '3953',
    label: 'Krirk University',
    value: 'Krirk University'
  },
  {
    id: '3954',
    label: 'Krishna University',
    value: 'Krishna University'
  },
  {
    id: '3955',
    label: 'Kuala Lumpur Infrastructure University College',
    value: 'Kuala Lumpur Infrastructure University College'
  },
  {
    id: '3956',
    label: 'Kuala Lumpur Metropolitan University',
    value: 'Kuala Lumpur Metropolitan University'
  },
  {
    id: '3957',
    label: 'Kuala Terengganu City Polytechnic',
    value: 'Kuala Terengganu City Polytechnic'
  },
  {
    id: '3958',
    label: 'Kuban State University',
    value: 'Kuban State University'
  },
  {
    id: '3959',
    label: 'Kuban State University of Agriculture',
    value: 'Kuban State University of Agriculture'
  },
  {
    id: '3960',
    label: 'Kuban State University of Technology',
    value: 'Kuban State University of Technology'
  },
  {
    id: '3961',
    label: 'Kuching Polytechnic',
    value: 'Kuching Polytechnic'
  },
  {
    id: '3962',
    label: 'Kumamoto Gakuen University',
    value: 'Kumamoto Gakuen University'
  },
  {
    id: '3963',
    label: 'Kumamoto Institute of Technology',
    value: 'Kumamoto Institute of Technology'
  },
  {
    id: '3964',
    label: 'Kumamoto Prefectural University',
    value: 'Kumamoto Prefectural University'
  },
  {
    id: '3965',
    label: 'Kumamoto University',
    value: 'Kumamoto University'
  },
  {
    id: '3966',
    label: 'Kumaraguru College of Technology',
    value: 'Kumaraguru College of Technology'
  },
  {
    id: '3967',
    label: 'Kumasi Technical University',
    value: 'Kumasi Technical University'
  },
  {
    id: '3968',
    label: 'Kumaun University',
    value: 'Kumaun University'
  },
  {
    id: '3969',
    label: 'Kumoh National University of Technology',
    value: 'Kumoh National University of Technology'
  },
  {
    id: '3970',
    label: 'Kun Shan University',
    value: 'Kun Shan University'
  },
  {
    id: '3971',
    label: 'Kung Shan Institute of Technology',
    value: 'Kung Shan Institute of Technology'
  },
  {
    id: '3972',
    label: 'Kunitachi College of Music',
    value: 'Kunitachi College of Music'
  },
  {
    id: '3973',
    label: 'Kunmimg University of Science and Technology',
    value: 'Kunmimg University of Science and Technology'
  },
  {
    id: '3974',
    label: 'Kunsan National University',
    value: 'Kunsan National University'
  },
  {
    id: '3975',
    label: 'Kunstakademie Düsseldorf.',
    value: 'Kunstakademie Düsseldorf.'
  },
  {
    id: '3976',
    label: 'Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung',
    value: 'Kunsthochschule Berlin-Weissensee, Hochschule für Gestaltung'
  },
  {
    id: '3977',
    label: 'Kurashiki Sakuyo University',
    value: 'Kurashiki Sakuyo University'
  },
  {
    id: '3978',
    label: 'Kurashiki University of Science and the Arts',
    value: 'Kurashiki University of Science and the Arts'
  },
  {
    id: '3979',
    label: 'Kurdistan University of Medical Sciences',
    value: 'Kurdistan University of Medical Sciences'
  },
  {
    id: '3980',
    label: 'Kure University',
    value: 'Kure University'
  },
  {
    id: '3981',
    label: 'Kurgan International University',
    value: 'Kurgan International University'
  },
  {
    id: '3982',
    label: 'Kursk State Medical University',
    value: 'Kursk State Medical University'
  },
  {
    id: '3983',
    label: 'Kursk State Technical University',
    value: 'Kursk State Technical University'
  },
  {
    id: '3984',
    label: 'Kursk State University',
    value: 'Kursk State University'
  },
  {
    id: '3985',
    label: 'Kurukshetra University',
    value: 'Kurukshetra University'
  },
  {
    id: '3986',
    label: 'Kurume Institute of Technology',
    value: 'Kurume Institute of Technology'
  },
  {
    id: '3987',
    label: 'Kurume University',
    value: 'Kurume University'
  },
  {
    id: '3988',
    label: 'Kushiro Public University of Economics',
    value: 'Kushiro Public University of Economics'
  },
  {
    id: '3989',
    label: 'Kutztown University of Pennsylvania',
    value: 'Kutztown University of Pennsylvania'
  },
  {
    id: '3990',
    label: 'Kuvempu University',
    value: 'Kuvempu University'
  },
  {
    id: '3991',
    label: 'Kuwait International Law School',
    value: 'Kuwait International Law School'
  },
  {
    id: '3992',
    label: 'Kuwait Maastricht Business School',
    value: 'Kuwait Maastricht Business School'
  },
  {
    id: '3993',
    label: 'Kuwait University',
    value: 'Kuwait University'
  },
  {
    id: '3994',
    label: 'Kuzbass State Technical University',
    value: 'Kuzbass State Technical University'
  },
  {
    id: '3995',
    label: 'Kwame Nkrumah University of Science and Technology',
    value: 'Kwame Nkrumah University of Science and Technology'
  },
  {
    id: '3996',
    label: 'Kwan Dong University',
    value: 'Kwan Dong University'
  },
  {
    id: '3997',
    label: 'Kwangju National University of Education',
    value: 'Kwangju National University of Education'
  },
  {
    id: '3998',
    label: 'Kwangju University',
    value: 'Kwangju University'
  },
  {
    id: '3999',
    label: "Kwangju Women's University",
    value: "Kwangju Women's University"
  },
  {
    id: '4000',
    label: 'Kwangshin University',
    value: 'Kwangshin University'
  },
  {
    id: '4001',
    label: 'Kwangwoon University',
    value: 'Kwangwoon University'
  },
  {
    id: '4002',
    label: 'Kwansei Gakuin University',
    value: 'Kwansei Gakuin University'
  },
  {
    id: '4003',
    label: 'Kwantleen University College',
    value: 'Kwantleen University College'
  },
  {
    id: '4004',
    label: 'Kwara State Polytecnic',
    value: 'Kwara State Polytecnic'
  },
  {
    id: '4005',
    label: 'Kwara State University',
    value: 'Kwara State University'
  },
  {
    id: '4006',
    label: 'Kwararafa University',
    value: 'Kwararafa University'
  },
  {
    id: '4007',
    label: "Kwassui Women's College",
    value: "Kwassui Women's College"
  },
  {
    id: '4008',
    label: 'Kyambogo University',
    value: 'Kyambogo University'
  },
  {
    id: '4009',
    label: 'Kyonggi University',
    value: 'Kyonggi University'
  },
  {
    id: '4010',
    label: 'Kyorin University',
    value: 'Kyorin University'
  },
  {
    id: '4011',
    label: 'Kyoritsu Pharmaceutical University',
    value: 'Kyoritsu Pharmaceutical University'
  },
  {
    id: '4012',
    label: "Kyoritsu Woman's University",
    value: "Kyoritsu Woman's University"
  },
  {
    id: '4013',
    label: 'Kyoto Bunkyo University',
    value: 'Kyoto Bunkyo University'
  },
  {
    id: '4014',
    label: 'Kyoto City University of Arts',
    value: 'Kyoto City University of Arts'
  },
  {
    id: '4015',
    label: 'Kyoto Gakuen University',
    value: 'Kyoto Gakuen University'
  },
  {
    id: '4016',
    label: 'Kyoto Institute of Technology',
    value: 'Kyoto Institute of Technology'
  },
  {
    id: '4017',
    label: 'Kyoto Notre Dame University',
    value: 'Kyoto Notre Dame University'
  },
  {
    id: '4018',
    label: 'Kyoto Pharmaceutical University',
    value: 'Kyoto Pharmaceutical University'
  },
  {
    id: '4019',
    label: 'Kyoto Prefectural University',
    value: 'Kyoto Prefectural University'
  },
  {
    id: '4020',
    label: 'Kyoto Prefectural University of Medicine',
    value: 'Kyoto Prefectural University of Medicine'
  },
  {
    id: '4021',
    label: 'Kyoto Sangyo University',
    value: 'Kyoto Sangyo University'
  },
  {
    id: '4022',
    label: 'Kyoto Seika University',
    value: 'Kyoto Seika University'
  },
  {
    id: '4023',
    label: "Kyoto Tachibana Women's University",
    value: "Kyoto Tachibana Women's University"
  },
  {
    id: '4024',
    label: 'Kyoto University',
    value: 'Kyoto University'
  },
  {
    id: '4025',
    label: 'Kyoto University of Art and Design',
    value: 'Kyoto University of Art and Design'
  },
  {
    id: '4026',
    label: 'Kyoto University of Education',
    value: 'Kyoto University of Education'
  },
  {
    id: '4027',
    label: 'Kyoto University of Foreign Studies',
    value: 'Kyoto University of Foreign Studies'
  },
  {
    id: '4028',
    label: "Kyoto Women's University",
    value: "Kyoto Women's University"
  },
  {
    id: '4029',
    label: 'Kyrgyz National Agrarian University',
    value: 'Kyrgyz National Agrarian University'
  },
  {
    id: '4030',
    label: 'Kyrgyz National University',
    value: 'Kyrgyz National University'
  },
  {
    id: '4031',
    label: 'Kyrgyz Russian Slavic University',
    value: 'Kyrgyz Russian Slavic University'
  },
  {
    id: '4032',
    label: 'Kyrgyz State Technical University',
    value: 'Kyrgyz State Technical University'
  },
  {
    id: '4033',
    label: 'Kyrgyz State University of Construction, Transportation and Architecture',
    value: 'Kyrgyz State University of Construction, Transportation and Architecture'
  },
  {
    id: '4034',
    label: 'Kyrgyz Turkish  Manas University',
    value: 'Kyrgyz Turkish  Manas University'
  },
  {
    id: '4035',
    label: 'Kyung Hee University',
    value: 'Kyung Hee University'
  },
  {
    id: '4036',
    label: 'Kyungdong University',
    value: 'Kyungdong University'
  },
  {
    id: '4037',
    label: 'Kyunghee University',
    value: 'Kyunghee University'
  },
  {
    id: '4038',
    label: 'Kyungil University',
    value: 'Kyungil University'
  },
  {
    id: '4039',
    label: 'Kyungnam University',
    value: 'Kyungnam University'
  },
  {
    id: '4040',
    label: 'Kyungpook National University',
    value: 'Kyungpook National University'
  },
  {
    id: '4041',
    label: 'Kyungsung University',
    value: 'Kyungsung University'
  },
  {
    id: '4042',
    label: 'Kyungwon University',
    value: 'Kyungwon University'
  },
  {
    id: '4043',
    label: 'Kyungwoon University',
    value: 'Kyungwoon University'
  },
  {
    id: '4044',
    label: 'Kyushu Dental College',
    value: 'Kyushu Dental College'
  },
  {
    id: '4045',
    label: 'Kyushu Institute of Design',
    value: 'Kyushu Institute of Design'
  },
  {
    id: '4046',
    label: 'Kyushu Institute of Technology',
    value: 'Kyushu Institute of Technology'
  },
  {
    id: '4047',
    label: 'Kyushu International University',
    value: 'Kyushu International University'
  },
  {
    id: '4048',
    label: 'Kyushu Kyoritsu University',
    value: 'Kyushu Kyoritsu University'
  },
  {
    id: '4049',
    label: 'Kyushu Sangyo University',
    value: 'Kyushu Sangyo University'
  },
  {
    id: '4050',
    label: 'Kyushu Tokai University',
    value: 'Kyushu Tokai University'
  },
  {
    id: '4051',
    label: 'Kyushu University',
    value: 'Kyushu University'
  },
  {
    id: '4052',
    label: 'Kyushu University of Nursing and SocialWelfare',
    value: 'Kyushu University of Nursing and SocialWelfare'
  },
  {
    id: '4053',
    label: "Kyushu Women's University",
    value: "Kyushu Women's University"
  },
  {
    id: '4054',
    label: 'Károl Gáspár University of the Reformed Church',
    value: 'Károl Gáspár University of the Reformed Church'
  },
  {
    id: '4055',
    label: 'L E Fletcher Technical Community College',
    value: 'L E Fletcher Technical Community College'
  },
  {
    id: '4056',
    label: "L'Université de l'Entreprise",
    value: "L'Université de l'Entreprise"
  },
  {
    id: '4057',
    label: 'L.D.College of Engineering',
    value: 'L.D.College of Engineering'
  },
  {
    id: '4058',
    label: 'L.N. Gumilyov Eurasian National University',
    value: 'L.N. Gumilyov Eurasian National University'
  },
  {
    id: '4059',
    label: 'LICCSAL Business College',
    value: 'LICCSAL Business College'
  },
  {
    id: '4060',
    label: 'LSB College',
    value: 'LSB College'
  },
  {
    id: '4061',
    label: 'La Salle - Universitat Rámon Llull',
    value: 'La Salle - Universitat Rámon Llull'
  },
  {
    id: '4062',
    label: 'La Salle Universities - International Programmes',
    value: 'La Salle Universities - International Programmes'
  },
  {
    id: '4063',
    label: 'La Sierra University',
    value: 'La Sierra University'
  },
  {
    id: '4064',
    label: 'La Trobe University',
    value: 'La Trobe University'
  },
  {
    id: '4065',
    label: 'LaGrange College',
    value: 'LaGrange College'
  },
  {
    id: '4066',
    label: 'LaSalle University',
    value: 'LaSalle University'
  },
  {
    id: '4067',
    label: 'Labette Community College',
    value: 'Labette Community College'
  },
  {
    id: '4068',
    label: 'Lac Courte Oreilles Ojibwa Community College',
    value: 'Lac Courte Oreilles Ojibwa Community College'
  },
  {
    id: '4069',
    label: 'Lackawanna College',
    value: 'Lackawanna College'
  },
  {
    id: '4070',
    label: 'Ladoke Akintola University of Technology',
    value: 'Ladoke Akintola University of Technology'
  },
  {
    id: '4071',
    label: 'Lafayette College',
    value: 'Lafayette College'
  },
  {
    id: '4072',
    label: 'Lagos City Polytechnic',
    value: 'Lagos City Polytechnic'
  },
  {
    id: '4073',
    label: 'Lagos State Polytechnic',
    value: 'Lagos State Polytechnic'
  },
  {
    id: '4074',
    label: 'Lagos State University',
    value: 'Lagos State University'
  },
  {
    id: '4075',
    label: 'Lahore College for Women University',
    value: 'Lahore College for Women University'
  },
  {
    id: '4076',
    label: 'Lahore University of Management Sciences',
    value: 'Lahore University of Management Sciences'
  },
  {
    id: '4077',
    label: 'Lahti Polytechnic',
    value: 'Lahti Polytechnic'
  },
  {
    id: '4078',
    label: 'Laikipia University',
    value: 'Laikipia University'
  },
  {
    id: '4079',
    label: 'Lake Area Technical Institute',
    value: 'Lake Area Technical Institute'
  },
  {
    id: '4080',
    label: 'Lake Erie College of Osteopathic Medicine',
    value: 'Lake Erie College of Osteopathic Medicine'
  },
  {
    id: '4081',
    label: 'Lake Forest College',
    value: 'Lake Forest College'
  },
  {
    id: '4082',
    label: 'Lake Land College',
    value: 'Lake Land College'
  },
  {
    id: '4083',
    label: 'Lake Michigan College',
    value: 'Lake Michigan College'
  },
  {
    id: '4084',
    label: 'Lake Region State College',
    value: 'Lake Region State College'
  },
  {
    id: '4085',
    label: 'Lake Superior College',
    value: 'Lake Superior College'
  },
  {
    id: '4086',
    label: 'Lake Superior State University',
    value: 'Lake Superior State University'
  },
  {
    id: '4087',
    label: 'Lake Tahoe Community College',
    value: 'Lake Tahoe Community College'
  },
  {
    id: '4088',
    label: 'Lakehead University',
    value: 'Lakehead University'
  },
  {
    id: '4089',
    label: 'Lakeland Community College',
    value: 'Lakeland Community College'
  },
  {
    id: '4090',
    label: 'Lakes Region Community College',
    value: 'Lakes Region Community College'
  },
  {
    id: '4091',
    label: 'Lakeshore Technical College',
    value: 'Lakeshore Technical College'
  },
  {
    id: '4092',
    label: 'Lakshmibai National Institute of Physical Education',
    value: 'Lakshmibai National Institute of Physical Education'
  },
  {
    id: '4093',
    label: 'Lalit Narayan Mithila University',
    value: 'Lalit Narayan Mithila University'
  },
  {
    id: '4094',
    label: 'Lamar Community College',
    value: 'Lamar Community College'
  },
  {
    id: '4095',
    label: 'Lamar Institute of Technology',
    value: 'Lamar Institute of Technology'
  },
  {
    id: '4096',
    label: 'Lamar State College-Orange',
    value: 'Lamar State College-Orange'
  },
  {
    id: '4097',
    label: 'Lamar State College-Port Arthur',
    value: 'Lamar State College-Port Arthur'
  },
  {
    id: '4098',
    label: 'Lamar University',
    value: 'Lamar University'
  },
  {
    id: '4099',
    label: 'Lambton College',
    value: 'Lambton College'
  },
  {
    id: '4100',
    label: 'Lampang College of Commerce and Technology',
    value: 'Lampang College of Commerce and Technology'
  },
  {
    id: '4101',
    label: 'Lampang Rajabhat University',
    value: 'Lampang Rajabhat University'
  },
  {
    id: '4102',
    label: 'Lampang Vocational College',
    value: 'Lampang Vocational College'
  },
  {
    id: '4103',
    label: 'Lamphun College of Agriculture and Technology',
    value: 'Lamphun College of Agriculture and Technology'
  },
  {
    id: '4104',
    label: 'Lancaster University',
    value: 'Lancaster University'
  },
  {
    id: '4105',
    label: 'Lander University',
    value: 'Lander University'
  },
  {
    id: '4106',
    label: 'Landmark University',
    value: 'Landmark University'
  },
  {
    id: '4107',
    label: 'Lane College',
    value: 'Lane College'
  },
  {
    id: '4108',
    label: 'Lane Community College',
    value: 'Lane Community College'
  },
  {
    id: '4109',
    label: 'Laney College',
    value: 'Laney College'
  },
  {
    id: '4110',
    label: 'Langara College',
    value: 'Langara College'
  },
  {
    id: '4111',
    label: 'Langston University',
    value: 'Langston University'
  },
  {
    id: '4112',
    label: 'Lanier Technical College',
    value: 'Lanier Technical College'
  },
  {
    id: '4113',
    label: 'Lankaran State University',
    value: 'Lankaran State University'
  },
  {
    id: '4114',
    label: 'Lansbridge University',
    value: 'Lansbridge University'
  },
  {
    id: '4115',
    label: 'Lansing Community College',
    value: 'Lansing Community College'
  },
  {
    id: '4116',
    label: 'Lanzhou University',
    value: 'Lanzhou University'
  },
  {
    id: '4117',
    label: 'Lappeenranta University of Technology',
    value: 'Lappeenranta University of Technology'
  },
  {
    id: '4118',
    label: 'Laramie County Community College',
    value: 'Laramie County Community College'
  },
  {
    id: '4119',
    label: 'Laredo Community College',
    value: 'Laredo Community College'
  },
  {
    id: '4120',
    label: 'Las Positas College',
    value: 'Las Positas College'
  },
  {
    id: '4121',
    label: 'Lasell College',
    value: 'Lasell College'
  },
  {
    id: '4122',
    label: 'Lassen Community College',
    value: 'Lassen Community College'
  },
  {
    id: '4123',
    label: 'Latter-day Saints Business College',
    value: 'Latter-day Saints Business College'
  },
  {
    id: '4124',
    label: 'Latvia University of Life Sciences and Technologies',
    value: 'Latvia University of Life Sciences and Technologies'
  },
  {
    id: '4125',
    label: 'Latvian Academy of Arts',
    value: 'Latvian Academy of Arts'
  },
  {
    id: '4126',
    label: 'Latvian Academy of Culture',
    value: 'Latvian Academy of Culture'
  },
  {
    id: '4127',
    label: 'Latvian Academy of Music',
    value: 'Latvian Academy of Music'
  },
  {
    id: '4128',
    label: 'Latvian Academy of Sports Education',
    value: 'Latvian Academy of Sports Education'
  },
  {
    id: '4129',
    label: 'Latvian Maritime Academy',
    value: 'Latvian Maritime Academy'
  },
  {
    id: '4130',
    label: 'Laurea University of Applied Sciences',
    value: 'Laurea University of Applied Sciences'
  },
  {
    id: '4131',
    label: 'Laurentian University of Sudbury',
    value: 'Laurentian University of Sudbury'
  },
  {
    id: '4132',
    label: 'Laurus College',
    value: 'Laurus College'
  },
  {
    id: '4133',
    label: 'Lausanne Hotel School (EHL)',
    value: 'Lausanne Hotel School (EHL)'
  },
  {
    id: '4134',
    label: 'Law University of Bratislava',
    value: 'Law University of Bratislava'
  },
  {
    id: '4135',
    label: 'Lawrence Technological University',
    value: 'Lawrence Technological University'
  },
  {
    id: '4136',
    label: 'Lawrence University',
    value: 'Lawrence University'
  },
  {
    id: '4137',
    label: 'Lawson State Community College',
    value: 'Lawson State Community College'
  },
  {
    id: '4138',
    label: 'Lazarski School of Commerce and Law',
    value: 'Lazarski School of Commerce and Law'
  },
  {
    id: '4139',
    label: 'Le Cordon Bleu College of Culinary Arts-Chicago',
    value: 'Le Cordon Bleu College of Culinary Arts-Chicago'
  },
  {
    id: '4140',
    label: 'Le Moyne College',
    value: 'Le Moyne College'
  },
  {
    id: '4141',
    label: 'LeMoyne-Owen College',
    value: 'LeMoyne-Owen College'
  },
  {
    id: '4142',
    label: 'LeTourneau University',
    value: 'LeTourneau University'
  },
  {
    id: '4143',
    label: 'Lead City University of Ibadan',
    value: 'Lead City University of Ibadan'
  },
  {
    id: '4144',
    label: 'Leading University',
    value: 'Leading University'
  },
  {
    id: '4145',
    label: 'Lebanese American University',
    value: 'Lebanese American University'
  },
  {
    id: '4146',
    label: 'Lebanese International University',
    value: 'Lebanese International University'
  },
  {
    id: '4147',
    label: 'Lebanese University',
    value: 'Lebanese University'
  },
  {
    id: '4148',
    label: 'Lebanon Valley College',
    value: 'Lebanon Valley College'
  },
  {
    id: '4149',
    label: 'Lee College',
    value: 'Lee College'
  },
  {
    id: '4150',
    label: 'Leech Lake Tribal College',
    value: 'Leech Lake Tribal College'
  },
  {
    id: '4151',
    label: 'Leeds Beckett University',
    value: 'Leeds Beckett University'
  },
  {
    id: '4152',
    label: 'Leeds Trinity University',
    value: 'Leeds Trinity University'
  },
  {
    id: '4153',
    label: 'Leeward Community College',
    value: 'Leeward Community College'
  },
  {
    id: '4154',
    label: 'Lehigh Carbon Community College',
    value: 'Lehigh Carbon Community College'
  },
  {
    id: '4155',
    label: 'Lehigh University',
    value: 'Lehigh University'
  },
  {
    id: '4156',
    label: 'Lenoir Community College',
    value: 'Lenoir Community College'
  },
  {
    id: '4157',
    label: 'Lenoir-Rhyne College',
    value: 'Lenoir-Rhyne College'
  },
  {
    id: '4158',
    label: 'Lenoir-Rhyne University',
    value: 'Lenoir-Rhyne University'
  },
  {
    id: '4159',
    label: 'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw',
    value: 'Leon Kozminski Academy of Entrepreneurship and Mangement in Warsaw'
  },
  {
    id: '4160',
    label: 'Les Roches Gruyère University of Applied Sciences',
    value: 'Les Roches Gruyère University of Applied Sciences'
  },
  {
    id: '4161',
    label: 'Les Roches International School of Hotel Management',
    value: 'Les Roches International School of Hotel Management'
  },
  {
    id: '4162',
    label: 'Lesley University',
    value: 'Lesley University'
  },
  {
    id: '4163',
    label: 'Leuphana Universität Lüneburg',
    value: 'Leuphana Universität Lüneburg'
  },
  {
    id: '4164',
    label: 'Lewis & Clark College',
    value: 'Lewis & Clark College'
  },
  {
    id: '4165',
    label: 'Lewis University',
    value: 'Lewis University'
  },
  {
    id: '4166',
    label: 'Lewis and Clark Community College',
    value: 'Lewis and Clark Community College'
  },
  {
    id: '4167',
    label: 'Lewis-Clark State College',
    value: 'Lewis-Clark State College'
  },
  {
    id: '4168',
    label: 'Leyte Normal University',
    value: 'Leyte Normal University'
  },
  {
    id: '4169',
    label: 'Leyte State University',
    value: 'Leyte State University'
  },
  {
    id: '4170',
    label: 'Liaocheng Teachers University',
    value: 'Liaocheng Teachers University'
  },
  {
    id: '4171',
    label: 'Liaoning Normal University',
    value: 'Liaoning Normal University'
  },
  {
    id: '4172',
    label: 'Liaoning Technical University',
    value: 'Liaoning Technical University'
  },
  {
    id: '4173',
    label: 'Liaoning University',
    value: 'Liaoning University'
  },
  {
    id: '4174',
    label: 'Liaquat University of Medical & Health Sciences Jamshoro',
    value: 'Liaquat University of Medical & Health Sciences Jamshoro'
  },
  {
    id: '4175',
    label: 'Libera Università degli Studi di Scienze Umane e Tecnologiche',
    value: 'Libera Università degli Studi di Scienze Umane e Tecnologiche'
  },
  {
    id: '4176',
    label: 'Liberty University',
    value: 'Liberty University'
  },
  {
    id: '4177',
    label: 'Libya Open University',
    value: 'Libya Open University'
  },
  {
    id: '4178',
    label: 'Libyan International Medical University (LIMU)',
    value: 'Libyan International Medical University (LIMU)'
  },
  {
    id: '4179',
    label: 'Liceo de Cagayan University',
    value: 'Liceo de Cagayan University'
  },
  {
    id: '4180',
    label: 'Liepaja Pedagogical Higher School',
    value: 'Liepaja Pedagogical Higher School'
  },
  {
    id: '4181',
    label: 'Lillehammer University College',
    value: 'Lillehammer University College'
  },
  {
    id: '4182',
    label: 'Lilongwe University of Agriculture and Natural Resources',
    value: 'Lilongwe University of Agriculture and Natural Resources'
  },
  {
    id: '4183',
    label: 'Limkokwing University College of Creative Technology',
    value: 'Limkokwing University College of Creative Technology'
  },
  {
    id: '4184',
    label: 'Lincoln Land Community College',
    value: 'Lincoln Land Community College'
  },
  {
    id: '4185',
    label: 'Lincoln Memorial University',
    value: 'Lincoln Memorial University'
  },
  {
    id: '4186',
    label: 'Lincoln University',
    value: 'Lincoln University'
  },
  {
    id: '4187',
    label: 'Lincoln University',
    value: 'Lincoln University'
  },
  {
    id: '4188',
    label: 'Lindenwood University',
    value: 'Lindenwood University'
  },
  {
    id: '4189',
    label: 'Linfield College',
    value: 'Linfield College'
  },
  {
    id: '4190',
    label: 'Ling Tung University',
    value: 'Ling Tung University'
  },
  {
    id: '4191',
    label: 'Lingdong University of Science and Technology',
    value: 'Lingdong University of Science and Technology'
  },
  {
    id: '4192',
    label: 'Lingnan University',
    value: 'Lingnan University'
  },
  {
    id: '4193',
    label: 'Linguistic University of Nizhny Novgorod',
    value: 'Linguistic University of Nizhny Novgorod'
  },
  {
    id: '4194',
    label: 'Link Campus University of Malta',
    value: 'Link Campus University of Malta'
  },
  {
    id: '4195',
    label: 'Linköping University',
    value: 'Linköping University'
  },
  {
    id: '4196',
    label: 'Linn State Technical College',
    value: 'Linn State Technical College'
  },
  {
    id: '4197',
    label: 'Linn-Benton Community College',
    value: 'Linn-Benton Community College'
  },
  {
    id: '4198',
    label: 'Linton University College',
    value: 'Linton University College'
  },
  {
    id: '4199',
    label: 'Linuwel School',
    value: 'Linuwel School'
  },
  {
    id: '4200',
    label: 'Lipeck State Technical University',
    value: 'Lipeck State Technical University'
  },
  {
    id: '4201',
    label: 'Lipscomb University',
    value: 'Lipscomb University'
  },
  {
    id: '4202',
    label: 'Liszt Ferenc Academy of Music Budapest',
    value: 'Liszt Ferenc Academy of Music Budapest'
  },
  {
    id: '4203',
    label: 'Lithuania Christian College',
    value: 'Lithuania Christian College'
  },
  {
    id: '4204',
    label: 'Lithunian Academy of Music and Theatre',
    value: 'Lithunian Academy of Music and Theatre'
  },
  {
    id: '4205',
    label: 'Lithunian Institute of Physical Education',
    value: 'Lithunian Institute of Physical Education'
  },
  {
    id: '4206',
    label: 'Lithunian University of Agriculture',
    value: 'Lithunian University of Agriculture'
  },
  {
    id: '4207',
    label: 'Lithunian Veterinary Academy',
    value: 'Lithunian Veterinary Academy'
  },
  {
    id: '4208',
    label: 'Little Big Horn College',
    value: 'Little Big Horn College'
  },
  {
    id: '4209',
    label: 'Little Priest Tribal College',
    value: 'Little Priest Tribal College'
  },
  {
    id: '4210',
    label: 'Liverpool Hope University College',
    value: 'Liverpool Hope University College'
  },
  {
    id: '4211',
    label: 'Liverpool John Moores University',
    value: 'Liverpool John Moores University'
  },
  {
    id: '4212',
    label: 'Livingstone College',
    value: 'Livingstone College'
  },
  {
    id: '4213',
    label: 'Lock Haven University',
    value: 'Lock Haven University'
  },
  {
    id: '4214',
    label: 'Logistics Engineering University of PLA',
    value: 'Logistics Engineering University of PLA'
  },
  {
    id: '4215',
    label: 'Loma Linda University',
    value: 'Loma Linda University'
  },
  {
    id: '4216',
    label: 'Lomonosov Moscow State University',
    value: 'Lomonosov Moscow State University'
  },
  {
    id: '4217',
    label: 'London Business School',
    value: 'London Business School'
  },
  {
    id: '4218',
    label: 'London College of Science & Technology',
    value: 'London College of Science & Technology'
  },
  {
    id: '4219',
    label: 'London Guildhall University',
    value: 'London Guildhall University'
  },
  {
    id: '4220',
    label: 'London Institute of Management and Technology',
    value: 'London Institute of Management and Technology'
  },
  {
    id: '4221',
    label: 'London Interdisciplinary School',
    value: 'London Interdisciplinary School'
  },
  {
    id: '4222',
    label: 'London Metropolitan University',
    value: 'London Metropolitan University'
  },
  {
    id: '4223',
    label: 'London School of Business & Finance',
    value: 'London School of Business & Finance'
  },
  {
    id: '4224',
    label: 'London School of Economics and Political Science, University of London',
    value: 'London School of Economics and Political Science, University of London'
  },
  {
    id: '4225',
    label: 'London School of Hygiene & Tropical Medicine, University of London',
    value: 'London School of Hygiene & Tropical Medicine, University of London'
  },
  {
    id: '4226',
    label: 'London School of Jewish Studies',
    value: 'London School of Jewish Studies'
  },
  {
    id: '4227',
    label: 'Lone Star College System',
    value: 'Lone Star College System'
  },
  {
    id: '4228',
    label: 'Long Beach City College',
    value: 'Long Beach City College'
  },
  {
    id: '4229',
    label: 'Long Island University',
    value: 'Long Island University'
  },
  {
    id: '4230',
    label: 'Longwood University',
    value: 'Longwood University'
  },
  {
    id: '4231',
    label: 'Lorain County Community College',
    value: 'Lorain County Community College'
  },
  {
    id: '4232',
    label: 'Loras College',
    value: 'Loras College'
  },
  {
    id: '4233',
    label: 'Lord Fairfax Community College',
    value: 'Lord Fairfax Community College'
  },
  {
    id: '4234',
    label: 'Lorestan University',
    value: 'Lorestan University'
  },
  {
    id: '4235',
    label: 'Lorestan University of Medical Sciences',
    value: 'Lorestan University of Medical Sciences'
  },
  {
    id: '4236',
    label: 'Los Angeles City College',
    value: 'Los Angeles City College'
  },
  {
    id: '4237',
    label: 'Los Angeles Community College District',
    value: 'Los Angeles Community College District'
  },
  {
    id: '4238',
    label: 'Los Angeles County College of Nursing and Allied Health',
    value: 'Los Angeles County College of Nursing and Allied Health'
  },
  {
    id: '4239',
    label: 'Los Angeles Harbor College',
    value: 'Los Angeles Harbor College'
  },
  {
    id: '4240',
    label: 'Los Angeles Mission College',
    value: 'Los Angeles Mission College'
  },
  {
    id: '4241',
    label: 'Los Angeles Pierce College',
    value: 'Los Angeles Pierce College'
  },
  {
    id: '4242',
    label: 'Los Angeles Southwest College',
    value: 'Los Angeles Southwest College'
  },
  {
    id: '4243',
    label: 'Los Angeles Trade Technical College',
    value: 'Los Angeles Trade Technical College'
  },
  {
    id: '4244',
    label: 'Los Angeles Valley College',
    value: 'Los Angeles Valley College'
  },
  {
    id: '4245',
    label: 'Los Medanos College',
    value: 'Los Medanos College'
  },
  {
    id: '4246',
    label: 'Los Rios Community College District',
    value: 'Los Rios Community College District'
  },
  {
    id: '4247',
    label: 'Loughborough University',
    value: 'Loughborough University'
  },
  {
    id: '4248',
    label: 'Louisburg College',
    value: 'Louisburg College'
  },
  {
    id: '4249',
    label: 'Louisiana College',
    value: 'Louisiana College'
  },
  {
    id: '4250',
    label: 'Louisiana Delta Community College',
    value: 'Louisiana Delta Community College'
  },
  {
    id: '4251',
    label: 'Louisiana State University',
    value: 'Louisiana State University'
  },
  {
    id: '4252',
    label: 'Louisiana State University at Alexandria',
    value: 'Louisiana State University at Alexandria'
  },
  {
    id: '4253',
    label: 'Louisiana State University-Eunice',
    value: 'Louisiana State University-Eunice'
  },
  {
    id: '4254',
    label: 'Louisiana Tech University',
    value: 'Louisiana Tech University'
  },
  {
    id: '4255',
    label: 'Louvain School of Management',
    value: 'Louvain School of Management'
  },
  {
    id: '4256',
    label: 'Lovely Professional University',
    value: 'Lovely Professional University'
  },
  {
    id: '4257',
    label: 'Lower Columbia College',
    value: 'Lower Columbia College'
  },
  {
    id: '4258',
    label: 'Loyola College',
    value: 'Loyola College'
  },
  {
    id: '4259',
    label: 'Loyola Marymount University',
    value: 'Loyola Marymount University'
  },
  {
    id: '4260',
    label: 'Loyola University Chicago',
    value: 'Loyola University Chicago'
  },
  {
    id: '4261',
    label: 'Loyola-ICAM College of Engineering and Technology',
    value: 'Loyola-ICAM College of Engineering and Technology'
  },
  {
    id: '4262',
    label: 'Ludong University',
    value: 'Ludong University'
  },
  {
    id: '4263',
    label: 'Ludwig-Maximilians-Universität München',
    value: 'Ludwig-Maximilians-Universität München'
  },
  {
    id: '4264',
    label: 'Ludwik Solski State Academy of Theatre in Cracow',
    value: 'Ludwik Solski State Academy of Theatre in Cracow'
  },
  {
    id: '4265',
    label: 'Luhansk State Medical University',
    value: 'Luhansk State Medical University'
  },
  {
    id: '4266',
    label: 'Luhansk Taras Shevchenko National Pedagogical University',
    value: 'Luhansk Taras Shevchenko National Pedagogical University'
  },
  {
    id: '4267',
    label: 'Luiss Guido Carli University',
    value: 'Luiss Guido Carli University'
  },
  {
    id: '4268',
    label: 'Lulea University of Technology',
    value: 'Lulea University of Technology'
  },
  {
    id: '4269',
    label: 'Lumbini Buddhist University',
    value: 'Lumbini Buddhist University'
  },
  {
    id: '4270',
    label: 'Luna Community College',
    value: 'Luna Community College'
  },
  {
    id: '4271',
    label: 'Lund University',
    value: 'Lund University'
  },
  {
    id: '4272',
    label: 'LungHwa University of Science and Technology',
    value: 'LungHwa University of Science and Technology'
  },
  {
    id: '4273',
    label: 'Luoyang Normal University',
    value: 'Luoyang Normal University'
  },
  {
    id: '4274',
    label: 'Lupane State University',
    value: 'Lupane State University'
  },
  {
    id: '4275',
    label: 'Lurleen B Wallace Community College',
    value: 'Lurleen B Wallace Community College'
  },
  {
    id: '4276',
    label: 'Luther College',
    value: 'Luther College'
  },
  {
    id: '4277',
    label: 'Luther College',
    value: 'Luther College'
  },
  {
    id: '4278',
    label: 'Luther Seminary',
    value: 'Luther Seminary'
  },
  {
    id: '4279',
    label: 'Lutheran School Of Theology In Aarhus',
    value: 'Lutheran School Of Theology In Aarhus'
  },
  {
    id: '4280',
    label: 'Lutherische Theologische Hochschule Oberursel',
    value: 'Lutherische Theologische Hochschule Oberursel'
  },
  {
    id: '4281',
    label: 'Luxun Academy of Fine Art',
    value: 'Luxun Academy of Fine Art'
  },
  {
    id: '4282',
    label: 'Luzerne County Community College',
    value: 'Luzerne County Community College'
  },
  {
    id: '4283',
    label: 'Luzhou Medical College',
    value: 'Luzhou Medical College'
  },
  {
    id: '4284',
    label: 'Lviv National Medical Univeristy',
    value: 'Lviv National Medical Univeristy'
  },
  {
    id: '4285',
    label: 'Lviv National University Ivan Franko',
    value: 'Lviv National University Ivan Franko'
  },
  {
    id: '4286',
    label: 'Lviv Polytechnic National University',
    value: 'Lviv Polytechnic National University'
  },
  {
    id: '4287',
    label: 'Lviv University',
    value: 'Lviv University'
  },
  {
    id: '4288',
    label: 'Lyceum of the Philippines University',
    value: 'Lyceum of the Philippines University'
  },
  {
    id: '4289',
    label: 'Lycoming College',
    value: 'Lycoming College'
  },
  {
    id: '4290',
    label: 'Lynchburg College',
    value: 'Lynchburg College'
  },
  {
    id: '4291',
    label: 'Lyndon State College',
    value: 'Lyndon State College'
  },
  {
    id: '4292',
    label: 'Lynn University',
    value: 'Lynn University'
  },
  {
    id: '4293',
    label: 'Lyon College',
    value: 'Lyon College'
  },
  {
    id: '4294',
    label: 'MCI-Management Center Innsbruck',
    value: 'MCI-Management Center Innsbruck'
  },
  {
    id: '4295',
    label: 'MEF University',
    value: 'MEF University'
  },
  {
    id: '4296',
    label: 'MTI College',
    value: 'MTI College'
  },
  {
    id: '4297',
    label: 'Maakhir University',
    value: 'Maakhir University'
  },
  {
    id: '4298',
    label: 'Maasai Mara University',
    value: 'Maasai Mara University'
  },
  {
    id: '4299',
    label: 'Maastricht University',
    value: 'Maastricht University'
  },
  {
    id: '4300',
    label: 'MacCormac College',
    value: 'MacCormac College'
  },
  {
    id: '4301',
    label: 'MacEwan University',
    value: 'MacEwan University'
  },
  {
    id: '4302',
    label: 'Macalester College',
    value: 'Macalester College'
  },
  {
    id: '4303',
    label: 'Macau Polytechnic Institute',
    value: 'Macau Polytechnic Institute'
  },
  {
    id: '4304',
    label: 'Macedonian Academy of Sciences and Arts',
    value: 'Macedonian Academy of Sciences and Arts'
  },
  {
    id: '4305',
    label: 'Macomb Community College',
    value: 'Macomb Community College'
  },
  {
    id: '4306',
    label: 'Macquarie University',
    value: 'Macquarie University'
  },
  {
    id: '4307',
    label: 'Madawalabu University',
    value: 'Madawalabu University'
  },
  {
    id: '4308',
    label: 'Madenat Alelem University College',
    value: 'Madenat Alelem University College'
  },
  {
    id: '4309',
    label: 'Madhya Pradesh Bhoj (Open) University',
    value: 'Madhya Pradesh Bhoj (Open) University'
  },
  {
    id: '4310',
    label: 'Madison Area Technical College',
    value: 'Madison Area Technical College'
  },
  {
    id: '4311',
    label: 'Madisonville Community College',
    value: 'Madisonville Community College'
  },
  {
    id: '4312',
    label: 'Madonna University',
    value: 'Madonna University'
  },
  {
    id: '4313',
    label: 'Madras Institute of Technology',
    value: 'Madras Institute of Technology'
  },
  {
    id: '4314',
    label: 'Madurai Kamaraj University',
    value: 'Madurai Kamaraj University'
  },
  {
    id: '4315',
    label: 'Mae Fah Luang University',
    value: 'Mae Fah Luang University'
  },
  {
    id: '4316',
    label: 'Maejo University',
    value: 'Maejo University'
  },
  {
    id: '4317',
    label: 'Magadh University',
    value: 'Magadh University'
  },
  {
    id: '4318',
    label: 'Magnitogorsk State University',
    value: 'Magnitogorsk State University'
  },
  {
    id: '4319',
    label: 'Mahachulalongkorn Buddhist University',
    value: 'Mahachulalongkorn Buddhist University'
  },
  {
    id: '4320',
    label: 'Mahamakut Buddhist University',
    value: 'Mahamakut Buddhist University'
  },
  {
    id: '4321',
    label: 'Mahanakorn University of Technology',
    value: 'Mahanakorn University of Technology'
  },
  {
    id: '4322',
    label: 'Maharaja Ganga Singh University, Bikaner',
    value: 'Maharaja Ganga Singh University, Bikaner'
  },
  {
    id: '4323',
    label: 'Maharaja Sayajirao University of Baroda',
    value: 'Maharaja Sayajirao University of Baroda'
  },
  {
    id: '4324',
    label: 'Maharishi Dayanand University, Rohtak',
    value: 'Maharishi Dayanand University, Rohtak'
  },
  {
    id: '4325',
    label: 'Maharishi University of Management',
    value: 'Maharishi University of Management'
  },
  {
    id: '4326',
    label: 'Maharshi Dayanand Sarswati University Ajmer',
    value: 'Maharshi Dayanand Sarswati University Ajmer'
  },
  {
    id: '4327',
    label: 'Mahasarakham University',
    value: 'Mahasarakham University'
  },
  {
    id: '4328',
    label: 'Mahatma Gandhi Chitrakut Gramoday University',
    value: 'Mahatma Gandhi Chitrakut Gramoday University'
  },
  {
    id: '4329',
    label: 'Mahatma Gandhi Kashi Vidyapith',
    value: 'Mahatma Gandhi Kashi Vidyapith'
  },
  {
    id: '4330',
    label: 'Mahatma Gandhi University, Kerala',
    value: 'Mahatma Gandhi University, Kerala'
  },
  {
    id: '4331',
    label: 'Mahatma Jyotiba Phule Rohilkhand University Bareilly',
    value: 'Mahatma Jyotiba Phule Rohilkhand University Bareilly'
  },
  {
    id: '4332',
    label: 'Mahatma Phule Agricultural University',
    value: 'Mahatma Phule Agricultural University'
  },
  {
    id: '4333',
    label: 'Mahidol University',
    value: 'Mahidol University'
  },
  {
    id: '4334',
    label: 'Mahidol University International College',
    value: 'Mahidol University International College'
  },
  {
    id: '4335',
    label: 'Mahsa University College for Health and Medical Science',
    value: 'Mahsa University College for Health and Medical Science'
  },
  {
    id: '4336',
    label: 'Maj Institute of Pharmacology Polish Academy of Sciences',
    value: 'Maj Institute of Pharmacology Polish Academy of Sciences'
  },
  {
    id: '4337',
    label: 'Majan University College',
    value: 'Majan University College'
  },
  {
    id: '4338',
    label: 'Majmaah University',
    value: 'Majmaah University'
  },
  {
    id: '4339',
    label: 'Makanlal Chutur Vedi University',
    value: 'Makanlal Chutur Vedi University'
  },
  {
    id: '4340',
    label: 'Makerere University',
    value: 'Makerere University'
  },
  {
    id: '4341',
    label: 'Malaspina University College',
    value: 'Malaspina University College'
  },
  {
    id: '4342',
    label: 'Malaviya National Institute of Technology Jaipur',
    value: 'Malaviya National Institute of Technology Jaipur'
  },
  {
    id: '4343',
    label: 'Malawi University of Business and Applied Sciences',
    value: 'Malawi University of Business and Applied Sciences'
  },
  {
    id: '4344',
    label: 'Malawi University of Science and Technology',
    value: 'Malawi University of Science and Technology'
  },
  {
    id: '4345',
    label: 'Malayer University',
    value: 'Malayer University'
  },
  {
    id: '4346',
    label: 'Malaysia University of Science and Technology (MUST)',
    value: 'Malaysia University of Science and Technology (MUST)'
  },
  {
    id: '4347',
    label: 'Maldives National University',
    value: 'Maldives National University'
  },
  {
    id: '4348',
    label: 'Malekan Payame Noor University',
    value: 'Malekan Payame Noor University'
  },
  {
    id: '4349',
    label: 'Malmö University College',
    value: 'Malmö University College'
  },
  {
    id: '4350',
    label: 'Malone College',
    value: 'Malone College'
  },
  {
    id: '4351',
    label: 'Maltepe University',
    value: 'Maltepe University'
  },
  {
    id: '4352',
    label: 'Mamasani University of Technology',
    value: 'Mamasani University of Technology'
  },
  {
    id: '4353',
    label: 'Mamoun Private University for Science and Technology',
    value: 'Mamoun Private University for Science and Technology'
  },
  {
    id: '4354',
    label: 'Management Development Institute of Singapore, Tashkent',
    value: 'Management Development Institute of Singapore, Tashkent'
  },
  {
    id: '4355',
    label: 'Management and Science University',
    value: 'Management and Science University'
  },
  {
    id: '4356',
    label: 'Manarat International University',
    value: 'Manarat International University'
  },
  {
    id: '4357',
    label: 'Manchester Community College',
    value: 'Manchester Community College'
  },
  {
    id: '4358',
    label: 'Manchester Community College',
    value: 'Manchester Community College'
  },
  {
    id: '4359',
    label: 'Mandalay Technological University',
    value: 'Mandalay Technological University'
  },
  {
    id: '4360',
    label: 'Mandhu College',
    value: 'Mandhu College'
  },
  {
    id: '4361',
    label: 'Mangalore University',
    value: 'Mangalore University'
  },
  {
    id: '4362',
    label: 'Mangosuthu Univerisity of Technology',
    value: 'Mangosuthu Univerisity of Technology'
  },
  {
    id: '4363',
    label: 'Manhattan Area Technical College',
    value: 'Manhattan Area Technical College'
  },
  {
    id: '4364',
    label: 'Manhattan College',
    value: 'Manhattan College'
  },
  {
    id: '4365',
    label: 'Manila Central University',
    value: 'Manila Central University'
  },
  {
    id: '4366',
    label: 'Manipal University',
    value: 'Manipal University'
  },
  {
    id: '4367',
    label: 'Manipur University',
    value: 'Manipur University'
  },
  {
    id: '4368',
    label: 'Manor College',
    value: 'Manor College'
  },
  {
    id: '4369',
    label: 'Mansfield University of Pennsylvania',
    value: 'Mansfield University of Pennsylvania'
  },
  {
    id: '4370',
    label: 'Mansoura University',
    value: 'Mansoura University'
  },
  {
    id: '4371',
    label: 'Manuel L. Quezon University',
    value: 'Manuel L. Quezon University'
  },
  {
    id: '4372',
    label: 'Manuel S. Enverga University Foundation',
    value: 'Manuel S. Enverga University Foundation'
  },
  {
    id: '4373',
    label: 'Mapúa University',
    value: 'Mapúa University'
  },
  {
    id: '4374',
    label: 'Mar Athanasios College for Advanced Studies',
    value: 'Mar Athanasios College for Advanced Studies'
  },
  {
    id: '4375',
    label: 'Mara Poly-Tech College',
    value: 'Mara Poly-Tech College'
  },
  {
    id: '4376',
    label: 'Marathwada Agricultural University',
    value: 'Marathwada Agricultural University'
  },
  {
    id: '4377',
    label: 'Marcus Oldham College',
    value: 'Marcus Oldham College'
  },
  {
    id: '4378',
    label: 'Mardin Artuklu University',
    value: 'Mardin Artuklu University'
  },
  {
    id: '4379',
    label: 'Maria Curie-Sklodowska University Lublin',
    value: 'Maria Curie-Sklodowska University Lublin'
  },
  {
    id: '4380',
    label: 'Maria Grzegorzewska College for Special Education',
    value: 'Maria Grzegorzewska College for Special Education'
  },
  {
    id: '4381',
    label: 'Mariano Marcos State University',
    value: 'Mariano Marcos State University'
  },
  {
    id: '4382',
    label: 'Marietta College',
    value: 'Marietta College'
  },
  {
    id: '4383',
    label: 'Marij State Technical University',
    value: 'Marij State Technical University'
  },
  {
    id: '4384',
    label: 'Marij State University',
    value: 'Marij State University'
  },
  {
    id: '4385',
    label: 'Marine Biological Laboratory',
    value: 'Marine Biological Laboratory'
  },
  {
    id: '4386',
    label: 'Marion Military Institute',
    value: 'Marion Military Institute'
  },
  {
    id: '4387',
    label: 'Marion Technical College',
    value: 'Marion Technical College'
  },
  {
    id: '4388',
    label: 'Marist College',
    value: 'Marist College'
  },
  {
    id: '4389',
    label: 'Maritime University Constanta',
    value: 'Maritime University Constanta'
  },
  {
    id: '4390',
    label: 'Maritime University in Szczecin',
    value: 'Maritime University in Szczecin'
  },
  {
    id: '4391',
    label: 'Marlboro College',
    value: 'Marlboro College'
  },
  {
    id: '4392',
    label: 'Marmara University',
    value: 'Marmara University'
  },
  {
    id: '4393',
    label: 'Marquette University',
    value: 'Marquette University'
  },
  {
    id: '4394',
    label: 'Marshall University',
    value: 'Marshall University'
  },
  {
    id: '4395',
    label: 'Marshalltown Community College',
    value: 'Marshalltown Community College'
  },
  {
    id: '4396',
    label: 'Martin Community College',
    value: 'Martin Community College'
  },
  {
    id: '4397',
    label: 'Martin-Luther Universität Halle-Wittenberg',
    value: 'Martin-Luther Universität Halle-Wittenberg'
  },
  {
    id: '4398',
    label: 'Mary Baldwin College',
    value: 'Mary Baldwin College'
  },
  {
    id: '4399',
    label: 'Mary Washington College',
    value: 'Mary Washington College'
  },
  {
    id: '4400',
    label: 'Maryam Institute of Higher Education',
    value: 'Maryam Institute of Higher Education'
  },
  {
    id: '4401',
    label: 'Marymount Manhattan College',
    value: 'Marymount Manhattan College'
  },
  {
    id: '4402',
    label: 'Marymount University',
    value: 'Marymount University'
  },
  {
    id: '4403',
    label: 'Maryville College',
    value: 'Maryville College'
  },
  {
    id: '4404',
    label: 'Maryville University',
    value: 'Maryville University'
  },
  {
    id: '4405',
    label: 'Marywood University',
    value: 'Marywood University'
  },
  {
    id: '4406',
    label: 'Masaryk University',
    value: 'Masaryk University'
  },
  {
    id: '4407',
    label: 'Masdar University Of Science And Technology',
    value: 'Masdar University Of Science And Technology'
  },
  {
    id: '4408',
    label: 'Maseno University',
    value: 'Maseno University'
  },
  {
    id: '4409',
    label: 'Mashhad University of Medical Sciences',
    value: 'Mashhad University of Medical Sciences'
  },
  {
    id: '4410',
    label: 'Masinde Muliro University of Science and Technology',
    value: 'Masinde Muliro University of Science and Technology'
  },
  {
    id: '4411',
    label: 'Massachusetts Bay Community College',
    value: 'Massachusetts Bay Community College'
  },
  {
    id: '4412',
    label: 'Massachusetts Institute of Technology',
    value: 'Massachusetts Institute of Technology'
  },
  {
    id: '4413',
    label: 'Massasoit Community College',
    value: 'Massasoit Community College'
  },
  {
    id: '4414',
    label: 'Massey University',
    value: 'Massey University'
  },
  {
    id: '4415',
    label: 'Mat-Su College',
    value: 'Mat-Su College'
  },
  {
    id: '4416',
    label: 'Matej Bel University in Banská Bystrica',
    value: 'Matej Bel University in Banská Bystrica'
  },
  {
    id: '4417',
    label: 'Matn University',
    value: 'Matn University'
  },
  {
    id: '4418',
    label: 'Matsumoto Dental University',
    value: 'Matsumoto Dental University'
  },
  {
    id: '4419',
    label: 'Matsusaka University',
    value: 'Matsusaka University'
  },
  {
    id: '4420',
    label: 'Matsuyama University',
    value: 'Matsuyama University'
  },
  {
    id: '4421',
    label: 'Maulana Azad National Institute of Technology Bhopal',
    value: 'Maulana Azad National Institute of Technology Bhopal'
  },
  {
    id: '4422',
    label: 'Mawlana Bhashani Science And Technology University',
    value: 'Mawlana Bhashani Science And Technology University'
  },
  {
    id: '4423',
    label: 'Maxim Gorky Institute of Literature',
    value: 'Maxim Gorky Institute of Literature'
  },
  {
    id: '4424',
    label: 'Mayland Community College',
    value: 'Mayland Community College'
  },
  {
    id: '4425',
    label: 'Mayo Clinic',
    value: 'Mayo Clinic'
  },
  {
    id: '4426',
    label: 'Maysville Community and Technical College',
    value: 'Maysville Community and Technical College'
  },
  {
    id: '4427',
    label: 'Mazandaran University of Iran',
    value: 'Mazandaran University of Iran'
  },
  {
    id: '4428',
    label: 'Mazandaran University of Medical Science',
    value: 'Mazandaran University of Medical Science'
  },
  {
    id: '4429',
    label: 'Mazandaran University of Science & Technology',
    value: 'Mazandaran University of Science & Technology'
  },
  {
    id: '4430',
    label: 'Mbarara University of Science and Technology',
    value: 'Mbarara University of Science and Technology'
  },
  {
    id: '4431',
    label: 'McDaniel College',
    value: 'McDaniel College'
  },
  {
    id: '4432',
    label: 'McDowell Technical Community College',
    value: 'McDowell Technical Community College'
  },
  {
    id: '4433',
    label: 'McGill University',
    value: 'McGill University'
  },
  {
    id: '4434',
    label: 'McHenry County College',
    value: 'McHenry County College'
  },
  {
    id: '4435',
    label: 'McLennan Community College',
    value: 'McLennan Community College'
  },
  {
    id: '4436',
    label: 'McMaster University',
    value: 'McMaster University'
  },
  {
    id: '4437',
    label: 'McMurry University',
    value: 'McMurry University'
  },
  {
    id: '4438',
    label: 'McNeese State University',
    value: 'McNeese State University'
  },
  {
    id: '4439',
    label: 'McPherson College',
    value: 'McPherson College'
  },
  {
    id: '4440',
    label: 'Medical Academy in Bialystok',
    value: 'Medical Academy in Bialystok'
  },
  {
    id: '4441',
    label: 'Medical Academy in Gdansk',
    value: 'Medical Academy in Gdansk'
  },
  {
    id: '4442',
    label: 'Medical Academy in Lodz',
    value: 'Medical Academy in Lodz'
  },
  {
    id: '4443',
    label: 'Medical Academy in Lublin',
    value: 'Medical Academy in Lublin'
  },
  {
    id: '4444',
    label: 'Medical Academy in Warszawa',
    value: 'Medical Academy in Warszawa'
  },
  {
    id: '4445',
    label: 'Medical Academy in Wroclaw',
    value: 'Medical Academy in Wroclaw'
  },
  {
    id: '4446',
    label: 'Medical Academy of Latvia',
    value: 'Medical Academy of Latvia'
  },
  {
    id: '4447',
    label: 'Medical Academy Karol Marcinkowski in Poznan',
    value: 'Medical Academy Karol Marcinkowski in Poznan'
  },
  {
    id: '4448',
    label: 'Medical Academy Ludwik Rydygier in Bydgoszcz',
    value: 'Medical Academy Ludwik Rydygier in Bydgoszcz'
  },
  {
    id: '4449',
    label: 'Medical College of Georgia',
    value: 'Medical College of Georgia'
  },
  {
    id: '4450',
    label: 'Medical College of Wisconsin',
    value: 'Medical College of Wisconsin'
  },
  {
    id: '4451',
    label: 'Medical Faculty, Osh State University',
    value: 'Medical Faculty, Osh State University'
  },
  {
    id: '4452',
    label: 'Medical University Pleven',
    value: 'Medical University Pleven'
  },
  {
    id: '4453',
    label: 'Medical University Plovdiv',
    value: 'Medical University Plovdiv'
  },
  {
    id: '4454',
    label: 'Medical University Varna',
    value: 'Medical University Varna'
  },
  {
    id: '4455',
    label: 'Medical University of Sofia',
    value: 'Medical University of Sofia'
  },
  {
    id: '4456',
    label: 'Medical University of South Carolina',
    value: 'Medical University of South Carolina'
  },
  {
    id: '4457',
    label: 'Medizinische Hochschule Hannover',
    value: 'Medizinische Hochschule Hannover'
  },
  {
    id: '4458',
    label: 'Medizinische Universität Graz',
    value: 'Medizinische Universität Graz'
  },
  {
    id: '4459',
    label: 'Medizinische Universität Innsbruck',
    value: 'Medizinische Universität Innsbruck'
  },
  {
    id: '4460',
    label: 'Medizinische Universität Lübeck',
    value: 'Medizinische Universität Lübeck'
  },
  {
    id: '4461',
    label: 'Medizinische Universität Wien',
    value: 'Medizinische Universität Wien'
  },
  {
    id: '4462',
    label: 'Megatrend University of Applied Sciences',
    value: 'Megatrend University of Applied Sciences'
  },
  {
    id: '4463',
    label: 'Meharry Medical College',
    value: 'Meharry Medical College'
  },
  {
    id: '4464',
    label: 'Mehmet Akif Ersoy University',
    value: 'Mehmet Akif Ersoy University'
  },
  {
    id: '4465',
    label: 'Mehrabyan Medical Institute and Medical College',
    value: 'Mehrabyan Medical Institute and Medical College'
  },
  {
    id: '4466',
    label: 'Mehran University of Engineering & Technology',
    value: 'Mehran University of Engineering & Technology'
  },
  {
    id: '4467',
    label: 'Meiji College of Pharmacy',
    value: 'Meiji College of Pharmacy'
  },
  {
    id: '4468',
    label: 'Meiji Gakuin University',
    value: 'Meiji Gakuin University'
  },
  {
    id: '4469',
    label: 'Meiji University',
    value: 'Meiji University'
  },
  {
    id: '4470',
    label: 'Meiji University of Oriental Medicine',
    value: 'Meiji University of Oriental Medicine'
  },
  {
    id: '4471',
    label: 'Meijo University',
    value: 'Meijo University'
  },
  {
    id: '4472',
    label: 'Meikai University',
    value: 'Meikai University'
  },
  {
    id: '4473',
    label: 'Meio University',
    value: 'Meio University'
  },
  {
    id: '4474',
    label: 'Meisei University',
    value: 'Meisei University'
  },
  {
    id: '4475',
    label: 'Mejiro University',
    value: 'Mejiro University'
  },
  {
    id: '4476',
    label: 'Mekelle Institute of Technology',
    value: 'Mekelle Institute of Technology'
  },
  {
    id: '4477',
    label: 'Mekelle University',
    value: 'Mekelle University'
  },
  {
    id: '4478',
    label: 'Melaka City Polytechnic',
    value: 'Melaka City Polytechnic'
  },
  {
    id: '4479',
    label: 'Melaka Islamic University College',
    value: 'Melaka Islamic University College'
  },
  {
    id: '4480',
    label: 'Memorial University of Newfoundland',
    value: 'Memorial University of Newfoundland'
  },
  {
    id: '4481',
    label: 'Mendel University of Agriculture and Forestry',
    value: 'Mendel University of Agriculture and Forestry'
  },
  {
    id: '4482',
    label: 'Mendeleev Russian University of Chemical Technology',
    value: 'Mendeleev Russian University of Chemical Technology'
  },
  {
    id: '4483',
    label: 'Mendocino College',
    value: 'Mendocino College'
  },
  {
    id: '4484',
    label: 'Menoufia University',
    value: 'Menoufia University'
  },
  {
    id: '4485',
    label: 'Merced College',
    value: 'Merced College'
  },
  {
    id: '4486',
    label: 'Mercer County Community College',
    value: 'Mercer County Community College'
  },
  {
    id: '4487',
    label: 'Mercer University',
    value: 'Mercer University'
  },
  {
    id: '4488',
    label: 'Mercy College',
    value: 'Mercy College'
  },
  {
    id: '4489',
    label: 'Mercyhurst University',
    value: 'Mercyhurst University'
  },
  {
    id: '4490',
    label: 'Meredith College',
    value: 'Meredith College'
  },
  {
    id: '4491',
    label: 'Meridian Community College',
    value: 'Meridian Community College'
  },
  {
    id: '4492',
    label: 'Merkur Internationale FH Karlsruhe',
    value: 'Merkur Internationale FH Karlsruhe'
  },
  {
    id: '4493',
    label: 'Merlimau Polytechnic',
    value: 'Merlimau Polytechnic'
  },
  {
    id: '4494',
    label: 'Merritt College',
    value: 'Merritt College'
  },
  {
    id: '4495',
    label: 'Mersin University',
    value: 'Mersin University'
  },
  {
    id: '4496',
    label: 'Meru University of Science and Technology',
    value: 'Meru University of Science and Technology'
  },
  {
    id: '4497',
    label: 'Merz Akademie, Hochschule für Gestaltung Stuttgart',
    value: 'Merz Akademie, Hochschule für Gestaltung Stuttgart'
  },
  {
    id: '4498',
    label: 'Mesa Community College',
    value: 'Mesa Community College'
  },
  {
    id: '4499',
    label: 'Mesabi Range Community and Technical College',
    value: 'Mesabi Range Community and Technical College'
  },
  {
    id: '4500',
    label: 'Mesalands Community College',
    value: 'Mesalands Community College'
  },
  {
    id: '4501',
    label: 'Messiah College',
    value: 'Messiah College'
  },
  {
    id: '4502',
    label: 'Methodist Theological University',
    value: 'Methodist Theological University'
  },
  {
    id: '4503',
    label: 'Metropolia University of Applied Sciences',
    value: 'Metropolia University of Applied Sciences'
  },
  {
    id: '4504',
    label: 'Metropolitan Community College (MO)',
    value: 'Metropolitan Community College (MO)'
  },
  {
    id: '4505',
    label: 'Metropolitan Community College (NE)',
    value: 'Metropolitan Community College (NE)'
  },
  {
    id: '4506',
    label: 'Metropolitan State College of Denver',
    value: 'Metropolitan State College of Denver'
  },
  {
    id: '4507',
    label: 'Metropolitan State University',
    value: 'Metropolitan State University'
  },
  {
    id: '4508',
    label: 'Metropolitan University',
    value: 'Metropolitan University'
  },
  {
    id: '4509',
    label: 'Metropolitan University',
    value: 'Metropolitan University'
  },
  {
    id: '4510',
    label: 'Miami Dade College',
    value: 'Miami Dade College'
  },
  {
    id: '4511',
    label: 'Miami University of Ohio',
    value: 'Miami University of Ohio'
  },
  {
    id: '4512',
    label: 'Michael Okpara University of Agriculture',
    value: 'Michael Okpara University of Agriculture'
  },
  {
    id: '4513',
    label: 'Michigan State University',
    value: 'Michigan State University'
  },
  {
    id: '4514',
    label: 'Michigan Technological University',
    value: 'Michigan Technological University'
  },
  {
    id: '4515',
    label: 'Mid Michigan Community College',
    value: 'Mid Michigan Community College'
  },
  {
    id: '4516',
    label: 'Mid-America Nazarene College',
    value: 'Mid-America Nazarene College'
  },
  {
    id: '4517',
    label: 'Mid-Plains Community College',
    value: 'Mid-Plains Community College'
  },
  {
    id: '4518',
    label: 'Mid-South Community College',
    value: 'Mid-South Community College'
  },
  {
    id: '4519',
    label: 'Mid-State Technical College',
    value: 'Mid-State Technical College'
  },
  {
    id: '4520',
    label: 'Mid-Sweden University',
    value: 'Mid-Sweden University'
  },
  {
    id: '4521',
    label: 'Middle East Technical University',
    value: 'Middle East Technical University'
  },
  {
    id: '4522',
    label: 'Middle East University',
    value: 'Middle East University'
  },
  {
    id: '4523',
    label: 'Middle Georgia State College',
    value: 'Middle Georgia State College'
  },
  {
    id: '4524',
    label: 'Middle Tennessee State University',
    value: 'Middle Tennessee State University'
  },
  {
    id: '4525',
    label: 'Middlebury College',
    value: 'Middlebury College'
  },
  {
    id: '4526',
    label: 'Middlesbrough College',
    value: 'Middlesbrough College'
  },
  {
    id: '4527',
    label: 'Middlesex Community College',
    value: 'Middlesex Community College'
  },
  {
    id: '4528',
    label: 'Middlesex Community College',
    value: 'Middlesex Community College'
  },
  {
    id: '4529',
    label: 'Middlesex County College',
    value: 'Middlesex County College'
  },
  {
    id: '4530',
    label: 'Middlesex University - London',
    value: 'Middlesex University - London'
  },
  {
    id: '4531',
    label: 'Midlands State University',
    value: 'Midlands State University'
  },
  {
    id: '4532',
    label: 'Midlands Technical College',
    value: 'Midlands Technical College'
  },
  {
    id: '4533',
    label: 'Midwestern State University',
    value: 'Midwestern State University'
  },
  {
    id: '4534',
    label: 'Midwestern University',
    value: 'Midwestern University'
  },
  {
    id: '4535',
    label: 'Mie University',
    value: 'Mie University'
  },
  {
    id: '4536',
    label: 'Miles College',
    value: 'Miles College'
  },
  {
    id: '4537',
    label: 'Miles Community College',
    value: 'Miles Community College'
  },
  {
    id: '4538',
    label: 'Miles School of Law',
    value: 'Miles School of Law'
  },
  {
    id: '4539',
    label: 'Military Institute of Science and Technology',
    value: 'Military Institute of Science and Technology'
  },
  {
    id: '4540',
    label: 'Military Technical College',
    value: 'Military Technical College'
  },
  {
    id: '4541',
    label: 'Military University Shoumen',
    value: 'Military University Shoumen'
  },
  {
    id: '4542',
    label: 'Military academy of Lithuania',
    value: 'Military academy of Lithuania'
  },
  {
    id: '4543',
    label: 'Millersville University of Pennsylvania',
    value: 'Millersville University of Pennsylvania'
  },
  {
    id: '4544',
    label: 'Milligan College',
    value: 'Milligan College'
  },
  {
    id: '4545',
    label: 'Millikin University',
    value: 'Millikin University'
  },
  {
    id: '4546',
    label: 'Millsaps College',
    value: 'Millsaps College'
  },
  {
    id: '4547',
    label: 'Milwaukee Area Technical College',
    value: 'Milwaukee Area Technical College'
  },
  {
    id: '4548',
    label: 'Milwaukee School of Engineering',
    value: 'Milwaukee School of Engineering'
  },
  {
    id: '4549',
    label: 'Mimar Sinan Fine Arts University',
    value: 'Mimar Sinan Fine Arts University'
  },
  {
    id: '4550',
    label: 'Mimar Sinan University',
    value: 'Mimar Sinan University'
  },
  {
    id: '4551',
    label: "Mimasaka Women's College",
    value: "Mimasaka Women's College"
  },
  {
    id: '4552',
    label: 'Minamikyushu University',
    value: 'Minamikyushu University'
  },
  {
    id: '4553',
    label: 'Mindanao State University',
    value: 'Mindanao State University'
  },
  {
    id: '4554',
    label: 'Mindanao State University - Iligan Institute of Technology',
    value: 'Mindanao State University - Iligan Institute of Technology'
  },
  {
    id: '4555',
    label: 'Mineral Area College',
    value: 'Mineral Area College'
  },
  {
    id: '4556',
    label: 'Ming Chuan University',
    value: 'Ming Chuan University'
  },
  {
    id: '4557',
    label: 'Mingechevir State University',
    value: 'Mingechevir State University'
  },
  {
    id: '4558',
    label: 'Minhaj University Lahore',
    value: 'Minhaj University Lahore'
  },
  {
    id: '4559',
    label: 'Minia University',
    value: 'Minia University'
  },
  {
    id: '4560',
    label: 'Minneapolis College of Art and Design',
    value: 'Minneapolis College of Art and Design'
  },
  {
    id: '4561',
    label: 'Minneapolis Community and Technical College',
    value: 'Minneapolis Community and Technical College'
  },
  {
    id: '4562',
    label: 'Minnesota State College-Southeast Technical',
    value: 'Minnesota State College-Southeast Technical'
  },
  {
    id: '4563',
    label: 'Minnesota State Community and Technical College',
    value: 'Minnesota State Community and Technical College'
  },
  {
    id: '4564',
    label: 'Minnesota State University Moorhead',
    value: 'Minnesota State University Moorhead'
  },
  {
    id: '4565',
    label: 'Minnesota State University, Mankato',
    value: 'Minnesota State University, Mankato'
  },
  {
    id: '4566',
    label: 'Minnesota West Community and Technical College',
    value: 'Minnesota West Community and Technical College'
  },
  {
    id: '4567',
    label: 'Minot State University',
    value: 'Minot State University'
  },
  {
    id: '4568',
    label: 'Minsk Institute of Management',
    value: 'Minsk Institute of Management'
  },
  {
    id: '4569',
    label: 'Minsk State Linguistic University',
    value: 'Minsk State Linguistic University'
  },
  {
    id: '4570',
    label: 'MiraCosta College',
    value: 'MiraCosta College'
  },
  {
    id: '4571',
    label: 'Miryang National University',
    value: 'Miryang National University'
  },
  {
    id: '4572',
    label: 'Misamis University',
    value: 'Misamis University'
  },
  {
    id: '4573',
    label: 'Misan University',
    value: 'Misan University'
  },
  {
    id: '4574',
    label: 'Misr International University',
    value: 'Misr International University'
  },
  {
    id: '4575',
    label: 'Misr University for Sience and Technology',
    value: 'Misr University for Sience and Technology'
  },
  {
    id: '4576',
    label: 'Mission College',
    value: 'Mission College'
  },
  {
    id: '4577',
    label: 'Mississippi College',
    value: 'Mississippi College'
  },
  {
    id: '4578',
    label: 'Mississippi Delta Community College',
    value: 'Mississippi Delta Community College'
  },
  {
    id: '4579',
    label: 'Mississippi Gulf Coast Community College',
    value: 'Mississippi Gulf Coast Community College'
  },
  {
    id: '4580',
    label: 'Mississippi State University',
    value: 'Mississippi State University'
  },
  {
    id: '4581',
    label: 'Mississippi University for Women',
    value: 'Mississippi University for Women'
  },
  {
    id: '4582',
    label: 'Mississippi Valley State University',
    value: 'Mississippi Valley State University'
  },
  {
    id: '4583',
    label: 'Missouri Southern State College',
    value: 'Missouri Southern State College'
  },
  {
    id: '4584',
    label: 'Missouri State University',
    value: 'Missouri State University'
  },
  {
    id: '4585',
    label: 'Missouri State University-West Plains',
    value: 'Missouri State University-West Plains'
  },
  {
    id: '4586',
    label: 'Missouri University of Science and Technology',
    value: 'Missouri University of Science and Technology'
  },
  {
    id: '4587',
    label: 'Missouri Western State College',
    value: 'Missouri Western State College'
  },
  {
    id: '4588',
    label: 'Mitchell Community College',
    value: 'Mitchell Community College'
  },
  {
    id: '4589',
    label: 'Mitchell Technical Institute',
    value: 'Mitchell Technical Institute'
  },
  {
    id: '4590',
    label: 'Miva Open University',
    value: 'Miva Open University'
  },
  {
    id: '4591',
    label: "Miyagi Gakuin Women's College",
    value: "Miyagi Gakuin Women's College"
  },
  {
    id: '4592',
    label: 'Miyagi University',
    value: 'Miyagi University'
  },
  {
    id: '4593',
    label: 'Miyagi University of Education',
    value: 'Miyagi University of Education'
  },
  {
    id: '4594',
    label: 'Miyazaki Medical College',
    value: 'Miyazaki Medical College'
  },
  {
    id: '4595',
    label: 'Miyazaki Municipal University',
    value: 'Miyazaki Municipal University'
  },
  {
    id: '4596',
    label: 'Miyazaki Prefectural Nursing University',
    value: 'Miyazaki Prefectural Nursing University'
  },
  {
    id: '4597',
    label: 'Miyazaki University',
    value: 'Miyazaki University'
  },
  {
    id: '4598',
    label: 'Mizan Tepi University',
    value: 'Mizan Tepi University'
  },
  {
    id: '4599',
    label: 'Moberly Area Community College',
    value: 'Moberly Area Community College'
  },
  {
    id: '4600',
    label: 'Modern Acadmy',
    value: 'Modern Acadmy'
  },
  {
    id: '4601',
    label: 'Modern Institute of Managament',
    value: 'Modern Institute of Managament'
  },
  {
    id: '4602',
    label: 'Modern Sciences & Arts University',
    value: 'Modern Sciences & Arts University'
  },
  {
    id: '4603',
    label: 'Modern University For Technology and Information',
    value: 'Modern University For Technology and Information'
  },
  {
    id: '4604',
    label: 'Modern University for the Humanities',
    value: 'Modern University for the Humanities'
  },
  {
    id: '4605',
    label: 'Modesto Junior College',
    value: 'Modesto Junior College'
  },
  {
    id: '4606',
    label: 'Mofid University',
    value: 'Mofid University'
  },
  {
    id: '4607',
    label: 'Mogadishu University',
    value: 'Mogadishu University'
  },
  {
    id: '4608',
    label: 'Mogilev State University A.A. Kuleshov',
    value: 'Mogilev State University A.A. Kuleshov'
  },
  {
    id: '4609',
    label: 'Mohamed bin Zayed University of Artificial Intelligence (MBZUAI)',
    value: 'Mohamed bin Zayed University of Artificial Intelligence (MBZUAI)'
  },
  {
    id: '4610',
    label: 'Mohammad Ali Jinnah University',
    value: 'Mohammad Ali Jinnah University'
  },
  {
    id: '4611',
    label: 'Mohammad Ali Jinnah University, Karachi',
    value: 'Mohammad Ali Jinnah University, Karachi'
  },
  {
    id: '4612',
    label: 'Mohan Lal Sukhadia University',
    value: 'Mohan Lal Sukhadia University'
  },
  {
    id: '4613',
    label: 'Mohave Community College',
    value: 'Mohave Community College'
  },
  {
    id: '4614',
    label: 'Mohawk College',
    value: 'Mohawk College'
  },
  {
    id: '4615',
    label: 'Mohawk Valley Community College',
    value: 'Mohawk Valley Community College'
  },
  {
    id: '4616',
    label: 'Mohi-ud-Din Islamic University',
    value: 'Mohi-ud-Din Islamic University'
  },
  {
    id: '4617',
    label: 'Moi University',
    value: 'Moi University'
  },
  {
    id: '4618',
    label: 'Mokpo Catholic University',
    value: 'Mokpo Catholic University'
  },
  {
    id: '4619',
    label: 'Mokpo National Maritime University',
    value: 'Mokpo National Maritime University'
  },
  {
    id: '4620',
    label: 'Mokpo National University',
    value: 'Mokpo National University'
  },
  {
    id: '4621',
    label: 'Mokwon University Taejon',
    value: 'Mokwon University Taejon'
  },
  {
    id: '4622',
    label: 'Molde University College',
    value: 'Molde University College'
  },
  {
    id: '4623',
    label: 'Moldova Cooperative Trade University',
    value: 'Moldova Cooperative Trade University'
  },
  {
    id: '4624',
    label: 'Moldova State Agricultural University',
    value: 'Moldova State Agricultural University'
  },
  {
    id: '4625',
    label: 'Moldova State University',
    value: 'Moldova State University'
  },
  {
    id: '4626',
    label: 'Moldova State University of Medicine and Pharmacy "N. Testemitsanu"',
    value: 'Moldova State University of Medicine and Pharmacy "N. Testemitsanu"'
  },
  {
    id: '4627',
    label: 'Moldova Technical University',
    value: 'Moldova Technical University'
  },
  {
    id: '4628',
    label: 'Molloy College',
    value: 'Molloy College'
  },
  {
    id: '4629',
    label: 'Monash Univerisity South Africa',
    value: 'Monash Univerisity South Africa'
  },
  {
    id: '4630',
    label: 'Monash University',
    value: 'Monash University'
  },
  {
    id: '4631',
    label: 'Monash University, Malaysia Campus',
    value: 'Monash University, Malaysia Campus'
  },
  {
    id: '4632',
    label: 'Mondragon Univertsitatea',
    value: 'Mondragon Univertsitatea'
  },
  {
    id: '4633',
    label: 'Mongolian National University',
    value: 'Mongolian National University'
  },
  {
    id: '4634',
    label: 'Mongolian State University of Agriculture',
    value: 'Mongolian State University of Agriculture'
  },
  {
    id: '4635',
    label: 'Mongolian State University of Arts and Culture',
    value: 'Mongolian State University of Arts and Culture'
  },
  {
    id: '4636',
    label: 'Mongolian State University of Education',
    value: 'Mongolian State University of Education'
  },
  {
    id: '4637',
    label: 'Mongolian University of Science and Technology',
    value: 'Mongolian University of Science and Technology'
  },
  {
    id: '4638',
    label: 'Monmouth College',
    value: 'Monmouth College'
  },
  {
    id: '4639',
    label: 'Monmouth University',
    value: 'Monmouth University'
  },
  {
    id: '4640',
    label: 'Monroe College',
    value: 'Monroe College'
  },
  {
    id: '4641',
    label: 'Monroe Community College',
    value: 'Monroe Community College'
  },
  {
    id: '4642',
    label: 'Monroe County Community College',
    value: 'Monroe County Community College'
  },
  {
    id: '4643',
    label: 'Montana State University - Billings',
    value: 'Montana State University - Billings'
  },
  {
    id: '4644',
    label: 'Montana State University - Bozeman',
    value: 'Montana State University - Bozeman'
  },
  {
    id: '4645',
    label: 'Montana State University - Northern',
    value: 'Montana State University - Northern'
  },
  {
    id: '4646',
    label: 'Montana Tech',
    value: 'Montana Tech'
  },
  {
    id: '4647',
    label: 'Montanuniversität Leoben',
    value: 'Montanuniversität Leoben'
  },
  {
    id: '4648',
    label: 'Montcalm Community College',
    value: 'Montcalm Community College'
  },
  {
    id: '4649',
    label: 'Montclair State University',
    value: 'Montclair State University'
  },
  {
    id: '4650',
    label: 'Monterey Peninsula College',
    value: 'Monterey Peninsula College'
  },
  {
    id: '4651',
    label: 'Montgomery College',
    value: 'Montgomery College'
  },
  {
    id: '4652',
    label: 'Montgomery Community College',
    value: 'Montgomery Community College'
  },
  {
    id: '4653',
    label: 'Montgomery County Community College',
    value: 'Montgomery County Community College'
  },
  {
    id: '4654',
    label: 'Montreat College',
    value: 'Montreat College'
  },
  {
    id: '4655',
    label: 'Mooreland University',
    value: 'Mooreland University'
  },
  {
    id: '4656',
    label: 'Moorpark College',
    value: 'Moorpark College'
  },
  {
    id: '4657',
    label: 'Moraine Park Technical College',
    value: 'Moraine Park Technical College'
  },
  {
    id: '4658',
    label: 'Moraine Valley Community College',
    value: 'Moraine Valley Community College'
  },
  {
    id: '4659',
    label: 'Moravian College',
    value: 'Moravian College'
  },
  {
    id: '4660',
    label: 'Mordovian State University',
    value: 'Mordovian State University'
  },
  {
    id: '4661',
    label: 'Morehead State University',
    value: 'Morehead State University'
  },
  {
    id: '4662',
    label: 'Morehouse',
    value: 'Morehouse'
  },
  {
    id: '4663',
    label: 'Morehouse School of Medicine',
    value: 'Morehouse School of Medicine'
  },
  {
    id: '4664',
    label: 'Moreno Valley College',
    value: 'Moreno Valley College'
  },
  {
    id: '4665',
    label: 'Morgan Community College',
    value: 'Morgan Community College'
  },
  {
    id: '4666',
    label: 'Morgan State University',
    value: 'Morgan State University'
  },
  {
    id: '4667',
    label: 'Morioka College',
    value: 'Morioka College'
  },
  {
    id: '4668',
    label: 'Morris Brown College',
    value: 'Morris Brown College'
  },
  {
    id: '4669',
    label: 'Morris College',
    value: 'Morris College'
  },
  {
    id: '4670',
    label: 'Morton College',
    value: 'Morton College'
  },
  {
    id: '4671',
    label: 'Moscow External University of the Humanities',
    value: 'Moscow External University of the Humanities'
  },
  {
    id: '4672',
    label: 'Moscow Institute of Physics and Technology',
    value: 'Moscow Institute of Physics and Technology'
  },
  {
    id: '4673',
    label: 'Moscow International Higher Business School (MIRBIS)',
    value: 'Moscow International Higher Business School (MIRBIS)'
  },
  {
    id: '4674',
    label: 'Moscow P. I. Tchaikovsky Conservatory',
    value: 'Moscow P. I. Tchaikovsky Conservatory'
  },
  {
    id: '4675',
    label: 'Moscow Pedagogical State University',
    value: 'Moscow Pedagogical State University'
  },
  {
    id: '4676',
    label: 'Moscow Power Engineering Institute (Technical University)',
    value: 'Moscow Power Engineering Institute (Technical University)'
  },
  {
    id: '4677',
    label: 'Moscow State Academy of Applied Biotechnology',
    value: 'Moscow State Academy of Applied Biotechnology'
  },
  {
    id: '4678',
    label: 'Moscow State Automobile and Road Technical University',
    value: 'Moscow State Automobile and Road Technical University'
  },
  {
    id: '4679',
    label: 'Moscow State Industrial University',
    value: 'Moscow State Industrial University'
  },
  {
    id: '4680',
    label: 'Moscow State Institute of International Relations MFA Russia (MGIMO-University)',
    value: 'Moscow State Institute of International Relations MFA Russia (MGIMO-University)'
  },
  {
    id: '4681',
    label:
      'Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)',
    value:
      'Moscow State Institute of Radiotechnics, Electronics and Automation (Technical University)'
  },
  {
    id: '4682',
    label: 'Moscow State Institute of Steel and Alloys',
    value: 'Moscow State Institute of Steel and Alloys'
  },
  {
    id: '4683',
    label: 'Moscow State Technical University of Civil Aviation',
    value: 'Moscow State Technical University of Civil Aviation'
  },
  {
    id: '4684',
    label: 'Moscow State Textile University A.N. Kosygin',
    value: 'Moscow State Textile University A.N. Kosygin'
  },
  {
    id: '4685',
    label: 'Moscow State University of Civil Engineering',
    value: 'Moscow State University of Civil Engineering'
  },
  {
    id: '4686',
    label: 'Moscow State University of Commerce',
    value: 'Moscow State University of Commerce'
  },
  {
    id: '4687',
    label: 'Moscow State University of Culture',
    value: 'Moscow State University of Culture'
  },
  {
    id: '4688',
    label: 'Moscow State University of Ecological Engineering',
    value: 'Moscow State University of Ecological Engineering'
  },
  {
    id: '4689',
    label: 'Moscow State University of Economics, Statistics and Informatics',
    value: 'Moscow State University of Economics, Statistics and Informatics'
  },
  {
    id: '4690',
    label: 'Moscow State University of Environmental Engineering',
    value: 'Moscow State University of Environmental Engineering'
  },
  {
    id: '4691',
    label: 'Moscow State University of Forestry Engineering',
    value: 'Moscow State University of Forestry Engineering'
  },
  {
    id: '4692',
    label: 'Moscow State University of Geodesy and Cartography',
    value: 'Moscow State University of Geodesy and Cartography'
  },
  {
    id: '4693',
    label: 'Moscow State University of Railway Transport',
    value: 'Moscow State University of Railway Transport'
  },
  {
    id: '4694',
    label: 'Moscow State University of Technology (Stankin)',
    value: 'Moscow State University of Technology (Stankin)'
  },
  {
    id: '4695',
    label: 'Moscow Technical University of Informatics and Communication',
    value: 'Moscow Technical University of Informatics and Communication'
  },
  {
    id: '4696',
    label: 'Moscow University Touro',
    value: 'Moscow University Touro'
  },
  {
    id: '4697',
    label: 'Moscow University for Industry and Finance “Synergy”',
    value: 'Moscow University for Industry and Finance “Synergy”'
  },
  {
    id: '4698',
    label: 'Moshi University College of Cooperative and Business Studies',
    value: 'Moshi University College of Cooperative and Business Studies'
  },
  {
    id: '4699',
    label: 'Motilal Nehru National Institute Of Technology',
    value: 'Motilal Nehru National Institute Of Technology'
  },
  {
    id: '4700',
    label: 'Motlow State Community College',
    value: 'Motlow State Community College'
  },
  {
    id: '4701',
    label: 'Mott Community College',
    value: 'Mott Community College'
  },
  {
    id: '4702',
    label: 'Moulana Azad national Urdu University',
    value: 'Moulana Azad national Urdu University'
  },
  {
    id: '4703',
    label: 'Moultrie Technical College',
    value: 'Moultrie Technical College'
  },
  {
    id: '4704',
    label: 'Mount Allison University',
    value: 'Mount Allison University'
  },
  {
    id: '4705',
    label: 'Mount Holyoke College',
    value: 'Mount Holyoke College'
  },
  {
    id: '4706',
    label: 'Mount Kenya University',
    value: 'Mount Kenya University'
  },
  {
    id: '4707',
    label: 'Mount Meru University',
    value: 'Mount Meru University'
  },
  {
    id: '4708',
    label: 'Mount Royal University',
    value: 'Mount Royal University'
  },
  {
    id: '4709',
    label: 'Mount Saint Joseph College',
    value: 'Mount Saint Joseph College'
  },
  {
    id: '4710',
    label: 'Mount Saint Mary College',
    value: 'Mount Saint Mary College'
  },
  {
    id: '4711',
    label: 'Mount Saint Vincent University',
    value: 'Mount Saint Vincent University'
  },
  {
    id: '4712',
    label: 'Mount Senario College',
    value: 'Mount Senario College'
  },
  {
    id: '4713',
    label: "Mount St. Mary's University",
    value: "Mount St. Mary's University"
  },
  {
    id: '4714',
    label: 'Mount Union College',
    value: 'Mount Union College'
  },
  {
    id: '4715',
    label: 'Mount Wachusett Community College',
    value: 'Mount Wachusett Community College'
  },
  {
    id: '4716',
    label: 'Mountain Empire Community College',
    value: 'Mountain Empire Community College'
  },
  {
    id: '4717',
    label: 'Mountain View College',
    value: 'Mountain View College'
  },
  {
    id: '4718',
    label: 'Mountains of the Moon University',
    value: 'Mountains of the Moon University'
  },
  {
    id: '4719',
    label: 'Mountwest Community and Technical College',
    value: 'Mountwest Community and Technical College'
  },
  {
    id: '4720',
    label: 'Mozyr State Pedagogical Institute',
    value: 'Mozyr State Pedagogical Institute'
  },
  {
    id: '4721',
    label: 'Mt. Hood Community College',
    value: 'Mt. Hood Community College'
  },
  {
    id: '4722',
    label: 'Mt. San Antonio College',
    value: 'Mt. San Antonio College'
  },
  {
    id: '4723',
    label: 'Mt. San Jacinto Community College District',
    value: 'Mt. San Jacinto Community College District'
  },
  {
    id: '4724',
    label: "Mu' tah University",
    value: "Mu' tah University"
  },
  {
    id: '4725',
    label: 'Muadzam Shah Polytechnic',
    value: 'Muadzam Shah Polytechnic'
  },
  {
    id: '4726',
    label: 'Mugla Sitki Kocman University',
    value: 'Mugla Sitki Kocman University'
  },
  {
    id: '4727',
    label: 'Muhammad Nawaz Sharif University of Engineering and Technology, Multan',
    value: 'Muhammad Nawaz Sharif University of Engineering and Technology, Multan'
  },
  {
    id: '4728',
    label: 'Muhimbili University of Health and Allied Sciences (MUHAS)',
    value: 'Muhimbili University of Health and Allied Sciences (MUHAS)'
  },
  {
    id: '4729',
    label: "Mukogawa Women's University",
    value: "Mukogawa Women's University"
  },
  {
    id: '4730',
    label: 'Multimedia University',
    value: 'Multimedia University'
  },
  {
    id: '4731',
    label: 'Multimedia University of Kenya',
    value: 'Multimedia University of Kenya'
  },
  {
    id: '4732',
    label: 'Mulungushi University',
    value: 'Mulungushi University'
  },
  {
    id: '4733',
    label: 'Munster Technological University',
    value: 'Munster Technological University'
  },
  {
    id: '4734',
    label: 'Murdoch University',
    value: 'Murdoch University'
  },
  {
    id: '4735',
    label: 'Murmansk State Technical University',
    value: 'Murmansk State Technical University'
  },
  {
    id: '4736',
    label: 'Murni Nursing College',
    value: 'Murni Nursing College'
  },
  {
    id: '4737',
    label: 'Muroran Institute of Technology',
    value: 'Muroran Institute of Technology'
  },
  {
    id: '4738',
    label: 'Murray State College',
    value: 'Murray State College'
  },
  {
    id: '4739',
    label: 'Murray State University',
    value: 'Murray State University'
  },
  {
    id: '4740',
    label: 'Mus Alparslan University',
    value: 'Mus Alparslan University'
  },
  {
    id: '4741',
    label: 'Musashi Institute of Technology',
    value: 'Musashi Institute of Technology'
  },
  {
    id: '4742',
    label: 'Musashi University',
    value: 'Musashi University'
  },
  {
    id: '4743',
    label: 'Musashino Academy of Music',
    value: 'Musashino Academy of Music'
  },
  {
    id: '4744',
    label: 'Musashino Art University',
    value: 'Musashino Art University'
  },
  {
    id: '4745',
    label: "Musashino Women's University",
    value: "Musashino Women's University"
  },
  {
    id: '4746',
    label: 'Muscat College',
    value: 'Muscat College'
  },
  {
    id: '4747',
    label: 'Music Academy "Felix Nowowiejski" in Bydgoszcz',
    value: 'Music Academy "Felix Nowowiejski" in Bydgoszcz'
  },
  {
    id: '4748',
    label: 'Music Academy "Fryderyk Chopin" in Warszaw',
    value: 'Music Academy "Fryderyk Chopin" in Warszaw'
  },
  {
    id: '4749',
    label: 'Music Academy "Ignacy Jana Paderewski" in Poznan',
    value: 'Music Academy "Ignacy Jana Paderewski" in Poznan'
  },
  {
    id: '4750',
    label: 'Music Academy "Karol Lipinski" in Wroclaw',
    value: 'Music Academy "Karol Lipinski" in Wroclaw'
  },
  {
    id: '4751',
    label: 'Music Academy "Karol Szymanowski" in Katowice',
    value: 'Music Academy "Karol Szymanowski" in Katowice'
  },
  {
    id: '4752',
    label: 'Music Academy "Stanislaw Moniuszko" in Gdansk',
    value: 'Music Academy "Stanislaw Moniuszko" in Gdansk'
  },
  {
    id: '4753',
    label: 'Music Academy in Cracow',
    value: 'Music Academy in Cracow'
  },
  {
    id: '4754',
    label: 'Music Academy in Lodz',
    value: 'Music Academy in Lodz'
  },
  {
    id: '4755',
    label: 'Musikhochschule Lübeck',
    value: 'Musikhochschule Lübeck'
  },
  {
    id: '4756',
    label: 'Muskegon Community College',
    value: 'Muskegon Community College'
  },
  {
    id: '4757',
    label: 'Muskingum College',
    value: 'Muskingum College'
  },
  {
    id: '4758',
    label: 'Mustafa Kemal University',
    value: 'Mustafa Kemal University'
  },
  {
    id: '4759',
    label: 'Mutesa 1 Royal University',
    value: 'Mutesa 1 Royal University'
  },
  {
    id: '4760',
    label: 'Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung',
    value: 'Muthesius-Hochschule, Fachhochschule für Kunst und Gestaltung'
  },
  {
    id: '4761',
    label: 'Mwalimu Nyerere Memorial Academy',
    value: 'Mwalimu Nyerere Memorial Academy'
  },
  {
    id: '4762',
    label: 'Mykolo Romerio university',
    value: 'Mykolo Romerio university'
  },
  {
    id: '4763',
    label: 'Myongji University',
    value: 'Myongji University'
  },
  {
    id: '4764',
    label: 'Mzumbe University (Chuo Kikuu Mzumbe)',
    value: 'Mzumbe University (Chuo Kikuu Mzumbe)'
  },
  {
    id: '4765',
    label: 'Mzuzu University',
    value: 'Mzuzu University'
  },
  {
    id: '4766',
    label: 'Mälardalen University',
    value: 'Mälardalen University'
  },
  {
    id: '4767',
    label: 'Märkische Fachhochschule Iserlohn',
    value: 'Märkische Fachhochschule Iserlohn'
  },
  {
    id: '4768',
    label: 'NABA - Nuova Accademia di Belle Arti, Milan',
    value: 'NABA - Nuova Accademia di Belle Arti, Milan'
  },
  {
    id: '4769',
    label: 'NED University of Engineering and Technology Karachi',
    value: 'NED University of Engineering and Technology Karachi'
  },
  {
    id: '4770',
    label: 'NGO Vladikavkaz Institute of Economics, Management, and Law',
    value: 'NGO Vladikavkaz Institute of Economics, Management, and Law'
  },
  {
    id: '4771',
    label: "NHTI-Concord's Community College",
    value: "NHTI-Concord's Community College"
  },
  {
    id: '4772',
    label: 'NHTV Breda University of Professional Education',
    value: 'NHTV Breda University of Professional Education'
  },
  {
    id: '4773',
    label: 'NOVA School of Science and Technology',
    value: 'NOVA School of Science and Technology'
  },
  {
    id: '4774',
    label: 'NTB - Hochschule für Technik Buchs',
    value: 'NTB - Hochschule für Technik Buchs'
  },
  {
    id: '4775',
    label: 'Nagaland University',
    value: 'Nagaland University'
  },
  {
    id: '4776',
    label: 'Nagano University',
    value: 'Nagano University'
  },
  {
    id: '4777',
    label: 'Nagaoka University of Technology',
    value: 'Nagaoka University of Technology'
  },
  {
    id: '4778',
    label: 'Nagasaki Institute of Applied Science',
    value: 'Nagasaki Institute of Applied Science'
  },
  {
    id: '4779',
    label: 'Nagasaki Prefectural University',
    value: 'Nagasaki Prefectural University'
  },
  {
    id: '4780',
    label: 'Nagasaki University',
    value: 'Nagasaki University'
  },
  {
    id: '4781',
    label: 'Nagoya City University',
    value: 'Nagoya City University'
  },
  {
    id: '4782',
    label: 'Nagoya Economics University',
    value: 'Nagoya Economics University'
  },
  {
    id: '4783',
    label: 'Nagoya Gakuin University',
    value: 'Nagoya Gakuin University'
  },
  {
    id: '4784',
    label: 'Nagoya Institute of Technology',
    value: 'Nagoya Institute of Technology'
  },
  {
    id: '4785',
    label: 'Nagoya University',
    value: 'Nagoya University'
  },
  {
    id: '4786',
    label: 'Nagoya University of Arts',
    value: 'Nagoya University of Arts'
  },
  {
    id: '4787',
    label: 'Nagoya University of Commerce and Business Administration',
    value: 'Nagoya University of Commerce and Business Administration'
  },
  {
    id: '4788',
    label: 'Nagoya University of Foreign Studies',
    value: 'Nagoya University of Foreign Studies'
  },
  {
    id: '4789',
    label: "Nagoya Women's University",
    value: "Nagoya Women's University"
  },
  {
    id: '4790',
    label: 'Nagpur University',
    value: 'Nagpur University'
  },
  {
    id: '4791',
    label: 'Naif Arab University for Security Sciences',
    value: 'Naif Arab University for Security Sciences'
  },
  {
    id: '4792',
    label: 'Nairobi Institute of Business Studies',
    value: 'Nairobi Institute of Business Studies'
  },
  {
    id: '4793',
    label: 'Nairobi Technical Training Institute',
    value: 'Nairobi Technical Training Institute'
  },
  {
    id: '4794',
    label: 'Najaf Abad University of Applied Science and Technology',
    value: 'Najaf Abad University of Applied Science and Technology'
  },
  {
    id: '4795',
    label: 'Najran University',
    value: 'Najran University'
  },
  {
    id: '4796',
    label: 'Nakamura Gakuen University',
    value: 'Nakamura Gakuen University'
  },
  {
    id: '4797',
    label: 'Nakhchivan Private University',
    value: 'Nakhchivan Private University'
  },
  {
    id: '4798',
    label: 'Nakhchivan State University',
    value: 'Nakhchivan State University'
  },
  {
    id: '4799',
    label: 'Nalanda Open University',
    value: 'Nalanda Open University'
  },
  {
    id: '4800',
    label: 'Nambu University',
    value: 'Nambu University'
  },
  {
    id: '4801',
    label: 'Namik Kemal University',
    value: 'Namik Kemal University'
  },
  {
    id: '4802',
    label: 'Namseoul University',
    value: 'Namseoul University'
  },
  {
    id: '4803',
    label: 'Nanchang University',
    value: 'Nanchang University'
  },
  {
    id: '4804',
    label: 'Nangarhar University',
    value: 'Nangarhar University'
  },
  {
    id: '4805',
    label: 'Nanhua University',
    value: 'Nanhua University'
  },
  {
    id: '4806',
    label: 'Nanjing Agricultural University',
    value: 'Nanjing Agricultural University'
  },
  {
    id: '4807',
    label: 'Nanjing Forestry University',
    value: 'Nanjing Forestry University'
  },
  {
    id: '4808',
    label: 'Nanjing Institute of Meteorology',
    value: 'Nanjing Institute of Meteorology'
  },
  {
    id: '4809',
    label: 'Nanjing Medical University',
    value: 'Nanjing Medical University'
  },
  {
    id: '4810',
    label: 'Nanjing Normal University',
    value: 'Nanjing Normal University'
  },
  {
    id: '4811',
    label: 'Nanjing Union Theological Seminary',
    value: 'Nanjing Union Theological Seminary'
  },
  {
    id: '4812',
    label: 'Nanjing University',
    value: 'Nanjing University'
  },
  {
    id: '4813',
    label: 'Nanjing University of Aeronautics and Astronautics',
    value: 'Nanjing University of Aeronautics and Astronautics'
  },
  {
    id: '4814',
    label: 'Nanjing University of Chemical Technology',
    value: 'Nanjing University of Chemical Technology'
  },
  {
    id: '4815',
    label: 'Nanjing University of Economics',
    value: 'Nanjing University of Economics'
  },
  {
    id: '4816',
    label: 'Nanjing University of Information Science and Technology',
    value: 'Nanjing University of Information Science and Technology'
  },
  {
    id: '4817',
    label: 'Nanjing University of Posts and Telecommunications',
    value: 'Nanjing University of Posts and Telecommunications'
  },
  {
    id: '4818',
    label: 'Nanjing University of Science and Technology',
    value: 'Nanjing University of Science and Technology'
  },
  {
    id: '4819',
    label: 'Nanjing University of Traditional Chinese Medicine',
    value: 'Nanjing University of Traditional Chinese Medicine'
  },
  {
    id: '4820',
    label: 'Nankai University',
    value: 'Nankai University'
  },
  {
    id: '4821',
    label: 'Nanyang Polytechnic',
    value: 'Nanyang Polytechnic'
  },
  {
    id: '4822',
    label: 'Nanyang Technological University',
    value: 'Nanyang Technological University'
  },
  {
    id: '4823',
    label: 'Nanzan University',
    value: 'Nanzan University'
  },
  {
    id: '4824',
    label: 'Napa Valley College',
    value: 'Napa Valley College'
  },
  {
    id: '4825',
    label: 'Nara Institute of Science and Technology',
    value: 'Nara Institute of Science and Technology'
  },
  {
    id: '4826',
    label: 'Nara Medical University',
    value: 'Nara Medical University'
  },
  {
    id: '4827',
    label: 'Nara Sangyo University',
    value: 'Nara Sangyo University'
  },
  {
    id: '4828',
    label: 'Nara University',
    value: 'Nara University'
  },
  {
    id: '4829',
    label: 'Nara University of Commerce',
    value: 'Nara University of Commerce'
  },
  {
    id: '4830',
    label: 'Nara University of Education',
    value: 'Nara University of Education'
  },
  {
    id: '4831',
    label: "Nara Women's University",
    value: "Nara Women's University"
  },
  {
    id: '4832',
    label: 'Narendra Deva University of Agriculture and Technology',
    value: 'Narendra Deva University of Agriculture and Technology'
  },
  {
    id: '4833',
    label: 'Naresuan University',
    value: 'Naresuan University'
  },
  {
    id: '4834',
    label: 'Narsee Monjee Institute of Management Studies',
    value: 'Narsee Monjee Institute of Management Studies'
  },
  {
    id: '4835',
    label: 'Narula Institute of Technology',
    value: 'Narula Institute of Technology'
  },
  {
    id: '4836',
    label: 'Naruto University of Education',
    value: 'Naruto University of Education'
  },
  {
    id: '4837',
    label: 'Nasarawa State University Keffi',
    value: 'Nasarawa State University Keffi'
  },
  {
    id: '4838',
    label: 'Nash Community College',
    value: 'Nash Community College'
  },
  {
    id: '4839',
    label: 'Nashua Community College',
    value: 'Nashua Community College'
  },
  {
    id: '4840',
    label: 'Nashville State Community College',
    value: 'Nashville State Community College'
  },
  {
    id: '4841',
    label: 'Nassau Community College',
    value: 'Nassau Community College'
  },
  {
    id: '4842',
    label: 'Nation University',
    value: 'Nation University'
  },
  {
    id: '4843',
    label: 'National Academy for Physical Education and Sports Bucharest',
    value: 'National Academy for Physical Education and Sports Bucharest'
  },
  {
    id: '4844',
    label: 'National Academy for Theatre and Film Arts "Krustju Sarafov"',
    value: 'National Academy for Theatre and Film Arts "Krustju Sarafov"'
  },
  {
    id: '4845',
    label: 'National Academy of Arts',
    value: 'National Academy of Arts'
  },
  {
    id: '4846',
    label: 'National Academy of Fine Arts',
    value: 'National Academy of Fine Arts'
  },
  {
    id: '4847',
    label: 'National Academy of Music "Pantcho Vladigerov"',
    value: 'National Academy of Music "Pantcho Vladigerov"'
  },
  {
    id: '4848',
    label: 'National Aerospace University Kharkov Aviation Institute',
    value: 'National Aerospace University Kharkov Aviation Institute'
  },
  {
    id: '4849',
    label: 'National Agricultural University',
    value: 'National Agricultural University'
  },
  {
    id: '4850',
    label: 'National Aviation Academy',
    value: 'National Aviation Academy'
  },
  {
    id: '4851',
    label: 'National Aviation Univesity',
    value: 'National Aviation Univesity'
  },
  {
    id: '4852',
    label: 'National Central University',
    value: 'National Central University'
  },
  {
    id: '4853',
    label: 'National Changhua University of Education',
    value: 'National Changhua University of Education'
  },
  {
    id: '4854',
    label: 'National Cheng Kung University',
    value: 'National Cheng Kung University'
  },
  {
    id: '4855',
    label: 'National Chengchi University',
    value: 'National Chengchi University'
  },
  {
    id: '4856',
    label: 'National Chi Nan University',
    value: 'National Chi Nan University'
  },
  {
    id: '4857',
    label: 'National Chiayi University',
    value: 'National Chiayi University'
  },
  {
    id: '4858',
    label: 'National Chung Cheng University',
    value: 'National Chung Cheng University'
  },
  {
    id: '4859',
    label: 'National Chung Hsing University, Taichung',
    value: 'National Chung Hsing University, Taichung'
  },
  {
    id: '4860',
    label: 'National Chung Hsing University, Taipei',
    value: 'National Chung Hsing University, Taipei'
  },
  {
    id: '4861',
    label: 'National College for Medical and Technical Studies',
    value: 'National College for Medical and Technical Studies'
  },
  {
    id: '4862',
    label: 'National College of Art and Design',
    value: 'National College of Art and Design'
  },
  {
    id: '4863',
    label: 'National College of Arts',
    value: 'National College of Arts'
  },
  {
    id: '4864',
    label: 'National College of Ireland',
    value: 'National College of Ireland'
  },
  {
    id: '4865',
    label: 'National College of Physical Education and Sports',
    value: 'National College of Physical Education and Sports'
  },
  {
    id: '4866',
    label: 'National Dairy Research Institute',
    value: 'National Dairy Research Institute'
  },
  {
    id: '4867',
    label: 'National Defence Academy of Latvia',
    value: 'National Defence Academy of Latvia'
  },
  {
    id: '4868',
    label: 'National Defence Medical College',
    value: 'National Defence Medical College'
  },
  {
    id: '4869',
    label: 'National Defense University',
    value: 'National Defense University'
  },
  {
    id: '4870',
    label: 'National Dong Hwa University',
    value: 'National Dong Hwa University'
  },
  {
    id: '4871',
    label: 'National Fisheries University',
    value: 'National Fisheries University'
  },
  {
    id: '4872',
    label: 'National Formosa University',
    value: 'National Formosa University'
  },
  {
    id: '4873',
    label: 'National Hsin-Chu Teachers College',
    value: 'National Hsin-Chu Teachers College'
  },
  {
    id: '4874',
    label: 'National Hualien Teachers College',
    value: 'National Hualien Teachers College'
  },
  {
    id: '4875',
    label: 'National Ilan University',
    value: 'National Ilan University'
  },
  {
    id: '4876',
    label: 'National Institute of Applied Sciences of Toulouse',
    value: 'National Institute of Applied Sciences of Toulouse'
  },
  {
    id: '4877',
    label: 'National Institute of Business Management',
    value: 'National Institute of Business Management'
  },
  {
    id: '4878',
    label: 'National Institute of Development Administration',
    value: 'National Institute of Development Administration'
  },
  {
    id: '4879',
    label: 'National Institute of Education',
    value: 'National Institute of Education'
  },
  {
    id: '4880',
    label: 'National Institute of Fitness and Sports Kanoya',
    value: 'National Institute of Fitness and Sports Kanoya'
  },
  {
    id: '4881',
    label: 'National Institute of Industrial Engineering',
    value: 'National Institute of Industrial Engineering'
  },
  {
    id: '4882',
    label: 'National Institute of Mental Health and Neuro Sciences',
    value: 'National Institute of Mental Health and Neuro Sciences'
  },
  {
    id: '4883',
    label: 'National Institute of Technology Agartala',
    value: 'National Institute of Technology Agartala'
  },
  {
    id: '4884',
    label: 'National Institute of Technology Andhra Pradesh',
    value: 'National Institute of Technology Andhra Pradesh'
  },
  {
    id: '4885',
    label: 'National Institute of Technology Arunachal Pradesh',
    value: 'National Institute of Technology Arunachal Pradesh'
  },
  {
    id: '4886',
    label: 'National Institute of Technology Calicut',
    value: 'National Institute of Technology Calicut'
  },
  {
    id: '4887',
    label: 'National Institute of Technology Delhi',
    value: 'National Institute of Technology Delhi'
  },
  {
    id: '4888',
    label: 'National Institute of Technology Durgapur',
    value: 'National Institute of Technology Durgapur'
  },
  {
    id: '4889',
    label: 'National Institute of Technology Goa',
    value: 'National Institute of Technology Goa'
  },
  {
    id: '4890',
    label: 'National Institute of Technology Hamirpur',
    value: 'National Institute of Technology Hamirpur'
  },
  {
    id: '4891',
    label: 'National Institute of Technology Jamshedpur',
    value: 'National Institute of Technology Jamshedpur'
  },
  {
    id: '4892',
    label: 'National Institute of Technology Kurukshetra',
    value: 'National Institute of Technology Kurukshetra'
  },
  {
    id: '4893',
    label: 'National Institute of Technology Manipur',
    value: 'National Institute of Technology Manipur'
  },
  {
    id: '4894',
    label: 'National Institute of Technology Meghalaya',
    value: 'National Institute of Technology Meghalaya'
  },
  {
    id: '4895',
    label: 'National Institute of Technology Mizoram',
    value: 'National Institute of Technology Mizoram'
  },
  {
    id: '4896',
    label: 'National Institute of Technology Nagaland',
    value: 'National Institute of Technology Nagaland'
  },
  {
    id: '4897',
    label: 'National Institute of Technology Patna',
    value: 'National Institute of Technology Patna'
  },
  {
    id: '4898',
    label: 'National Institute of Technology Puducherry',
    value: 'National Institute of Technology Puducherry'
  },
  {
    id: '4899',
    label: 'National Institute of Technology Raipur',
    value: 'National Institute of Technology Raipur'
  },
  {
    id: '4900',
    label: 'National Institute of Technology Rourkela',
    value: 'National Institute of Technology Rourkela'
  },
  {
    id: '4901',
    label: 'National Institute of Technology Sikkim',
    value: 'National Institute of Technology Sikkim'
  },
  {
    id: '4902',
    label: 'National Institute of Technology Silchar',
    value: 'National Institute of Technology Silchar'
  },
  {
    id: '4903',
    label: 'National Institute of Technology Srinagar',
    value: 'National Institute of Technology Srinagar'
  },
  {
    id: '4904',
    label: 'National Institute of Technology Surathkal',
    value: 'National Institute of Technology Surathkal'
  },
  {
    id: '4905',
    label: 'National Institute of Technology Trichy',
    value: 'National Institute of Technology Trichy'
  },
  {
    id: '4906',
    label: 'National Institute of Technology Uttarakhand',
    value: 'National Institute of Technology Uttarakhand'
  },
  {
    id: '4907',
    label: 'National Institute of Technology Warangal',
    value: 'National Institute of Technology Warangal'
  },
  {
    id: '4908',
    label: 'National Institute of the Arts',
    value: 'National Institute of the Arts'
  },
  {
    id: '4909',
    label: 'National Kaohsiung Normal University',
    value: 'National Kaohsiung Normal University'
  },
  {
    id: '4910',
    label: 'National Kaohsiung University of Science and Technology',
    value: 'National Kaohsiung University of Science and Technology'
  },
  {
    id: '4911',
    label: 'National Law School of India University',
    value: 'National Law School of India University'
  },
  {
    id: '4912',
    label: 'National Medical University O.O. Bogomolets',
    value: 'National Medical University O.O. Bogomolets'
  },
  {
    id: '4913',
    label: 'National Metallurgical Academy of Ukraine',
    value: 'National Metallurgical Academy of Ukraine'
  },
  {
    id: '4914',
    label: 'National Military Academy of Afghanistan',
    value: 'National Military Academy of Afghanistan'
  },
  {
    id: '4915',
    label: 'National Mining University',
    value: 'National Mining University'
  },
  {
    id: '4916',
    label: 'National Museum Institute of History of Art, Conservation and Museology',
    value: 'National Museum Institute of History of Art, Conservation and Museology'
  },
  {
    id: '4917',
    label: 'National Open University',
    value: 'National Open University'
  },
  {
    id: '4918',
    label: 'National Open University of Nigeria',
    value: 'National Open University of Nigeria'
  },
  {
    id: '4919',
    label: 'National Park College',
    value: 'National Park College'
  },
  {
    id: '4920',
    label: 'National Park Community College',
    value: 'National Park Community College'
  },
  {
    id: '4921',
    label: 'National Pedagogical University M. Dragomanov',
    value: 'National Pedagogical University M. Dragomanov'
  },
  {
    id: '4922',
    label: 'National Pingtung Teachers College',
    value: 'National Pingtung Teachers College'
  },
  {
    id: '4923',
    label: 'National Pingtung University of Science and Technology',
    value: 'National Pingtung University of Science and Technology'
  },
  {
    id: '4924',
    label: 'National Pirogov Memorial Medical University',
    value: 'National Pirogov Memorial Medical University'
  },
  {
    id: '4925',
    label: 'National Radio Astronomy Observatory',
    value: 'National Radio Astronomy Observatory'
  },
  {
    id: '4926',
    label: 'National Research University of Electronic Technology',
    value: 'National Research University of Electronic Technology'
  },
  {
    id: '4927',
    label: 'National Ribat University',
    value: 'National Ribat University'
  },
  {
    id: '4928',
    label: 'National Sanskrit University',
    value: 'National Sanskrit University'
  },
  {
    id: '4929',
    label: 'National School of Business Management',
    value: 'National School of Business Management'
  },
  {
    id: '4930',
    label: 'National School of Political and Administrative Studies Bucharest',
    value: 'National School of Political and Administrative Studies Bucharest'
  },
  {
    id: '4931',
    label: 'National Sports Academy Sofia',
    value: 'National Sports Academy Sofia'
  },
  {
    id: '4932',
    label: 'National Sun Yat-Sen University',
    value: 'National Sun Yat-Sen University'
  },
  {
    id: '4933',
    label: 'National Taichung Teachers College',
    value: 'National Taichung Teachers College'
  },
  {
    id: '4934',
    label: 'National Tainan Teachers College',
    value: 'National Tainan Teachers College'
  },
  {
    id: '4935',
    label: 'National Taipei College of Nursing',
    value: 'National Taipei College of Nursing'
  },
  {
    id: '4936',
    label: 'National Taipei Teachers College',
    value: 'National Taipei Teachers College'
  },
  {
    id: '4937',
    label: 'National Taipei University',
    value: 'National Taipei University'
  },
  {
    id: '4938',
    label: 'National Taipei University of Technology',
    value: 'National Taipei University of Technology'
  },
  {
    id: '4939',
    label: 'National Taitung Teachers College',
    value: 'National Taitung Teachers College'
  },
  {
    id: '4940',
    label: 'National Taiwan College of Physical Education and Sports',
    value: 'National Taiwan College of Physical Education and Sports'
  },
  {
    id: '4941',
    label: 'National Taiwan College of the Arts',
    value: 'National Taiwan College of the Arts'
  },
  {
    id: '4942',
    label: 'National Taiwan Normal University',
    value: 'National Taiwan Normal University'
  },
  {
    id: '4943',
    label: 'National Taiwan Ocean University',
    value: 'National Taiwan Ocean University'
  },
  {
    id: '4944',
    label: 'National Taiwan University',
    value: 'National Taiwan University'
  },
  {
    id: '4945',
    label: 'National Taiwan University of Science and Technology',
    value: 'National Taiwan University of Science and Technology'
  },
  {
    id: '4946',
    label: 'National Technical University (Kharkiv Polytechnical Institute)',
    value: 'National Technical University (Kharkiv Polytechnical Institute)'
  },
  {
    id: '4947',
    label: 'National Technical University (Kiev Politechnical Institute)',
    value: 'National Technical University (Kiev Politechnical Institute)'
  },
  {
    id: '4948',
    label: 'National Technical University of Athens',
    value: 'National Technical University of Athens'
  },
  {
    id: '4949',
    label: 'National Transport University',
    value: 'National Transport University'
  },
  {
    id: '4950',
    label: 'National Tsinghua University',
    value: 'National Tsinghua University'
  },
  {
    id: '4951',
    label: 'National United University',
    value: 'National United University'
  },
  {
    id: '4952',
    label: 'National University',
    value: 'National University'
  },
  {
    id: '4953',
    label: 'National University',
    value: 'National University'
  },
  {
    id: '4954',
    label: 'National University',
    value: 'National University'
  },
  {
    id: '4955',
    label: 'National University of Arts',
    value: 'National University of Arts'
  },
  {
    id: '4956',
    label: 'National University of Defense Technology',
    value: 'National University of Defense Technology'
  },
  {
    id: '4957',
    label: 'National University of Food Technologies',
    value: 'National University of Food Technologies'
  },
  {
    id: '4958',
    label: 'National University of Internal Affairs',
    value: 'National University of Internal Affairs'
  },
  {
    id: '4959',
    label: 'National University of Ireland',
    value: 'National University of Ireland'
  },
  {
    id: '4960',
    label: 'National University of Ireland, Galway',
    value: 'National University of Ireland, Galway'
  },
  {
    id: '4961',
    label: 'National University of Ireland, Maynooth',
    value: 'National University of Ireland, Maynooth'
  },
  {
    id: '4962',
    label: 'National University of Kaohsiung',
    value: 'National University of Kaohsiung'
  },
  {
    id: '4963',
    label: 'National University of Kiev-Mohyla Academy',
    value: 'National University of Kiev-Mohyla Academy'
  },
  {
    id: '4964',
    label: 'National University of Laos',
    value: 'National University of Laos'
  },
  {
    id: '4965',
    label: 'National University of Lesotho',
    value: 'National University of Lesotho'
  },
  {
    id: '4966',
    label: 'National University of Management',
    value: 'National University of Management'
  },
  {
    id: '4967',
    label: 'National University of Modern Languages',
    value: 'National University of Modern Languages'
  },
  {
    id: '4968',
    label: 'National University of Music',
    value: 'National University of Music'
  },
  {
    id: '4969',
    label: 'National University of Ostroh Academy',
    value: 'National University of Ostroh Academy'
  },
  {
    id: '4970',
    label: 'National University of Rwanda',
    value: 'National University of Rwanda'
  },
  {
    id: '4971',
    label: 'National University of Samoa',
    value: 'National University of Samoa'
  },
  {
    id: '4972',
    label: 'National University of Science and Technology',
    value: 'National University of Science and Technology'
  },
  {
    id: '4973',
    label: 'National University of Science and Technology Bulawayo',
    value: 'National University of Science and Technology Bulawayo'
  },
  {
    id: '4974',
    label: 'National University of Shipbuilding',
    value: 'National University of Shipbuilding'
  },
  {
    id: '4975',
    label: 'National University of Singapore',
    value: 'National University of Singapore'
  },
  {
    id: '4976',
    label: 'National University of Theater and Film Arts',
    value: 'National University of Theater and Film Arts'
  },
  {
    id: '4977',
    label: 'National University of Uzbekistan',
    value: 'National University of Uzbekistan'
  },
  {
    id: '4978',
    label: 'National Yang Ming Chiao Tung University',
    value: 'National Yang Ming Chiao Tung University'
  },
  {
    id: '4979',
    label: 'National Yunlin University of Science and Technology',
    value: 'National Yunlin University of Science and Technology'
  },
  {
    id: '4980',
    label: 'National-Louis University',
    value: 'National-Louis University'
  },
  {
    id: '4981',
    label: 'Naugatuck Valley Community College',
    value: 'Naugatuck Valley Community College'
  },
  {
    id: '4982',
    label: 'Naval Postgraduate School',
    value: 'Naval Postgraduate School'
  },
  {
    id: '4983',
    label: 'Navarro College',
    value: 'Navarro College'
  },
  {
    id: '4984',
    label: 'Navrachana University',
    value: 'Navrachana University'
  },
  {
    id: '4985',
    label: 'Nawroz University',
    value: 'Nawroz University'
  },
  {
    id: '4986',
    label: 'Nayanova University',
    value: 'Nayanova University'
  },
  {
    id: '4987',
    label: 'Nazarbayev University',
    value: 'Nazarbayev University'
  },
  {
    id: '4988',
    label: 'Nazareth College',
    value: 'Nazareth College'
  },
  {
    id: '4989',
    label: 'Ndejje University',
    value: 'Ndejje University'
  },
  {
    id: '4990',
    label: 'Near East School of Theology',
    value: 'Near East School of Theology'
  },
  {
    id: '4991',
    label: 'Near East University',
    value: 'Near East University'
  },
  {
    id: '4992',
    label: 'Nebraska College of Technical Agriculture',
    value: 'Nebraska College of Technical Agriculture'
  },
  {
    id: '4993',
    label: 'Nebraska Indian Community College',
    value: 'Nebraska Indian Community College'
  },
  {
    id: '4994',
    label: 'Necmettin Erbakan University',
    value: 'Necmettin Erbakan University'
  },
  {
    id: '4995',
    label: 'Neelain University',
    value: 'Neelain University'
  },
  {
    id: '4996',
    label: 'Neijiang Teacher University',
    value: 'Neijiang Teacher University'
  },
  {
    id: '4997',
    label: 'Nelson Mandela University',
    value: 'Nelson Mandela University'
  },
  {
    id: '4998',
    label: 'Nelson Marlborough Institute of Technology',
    value: 'Nelson Marlborough Institute of Technology'
  },
  {
    id: '4999',
    label: 'Neosho County Community College',
    value: 'Neosho County Community College'
  },
  {
    id: '5000',
    label: 'Nepal Sanskrit University',
    value: 'Nepal Sanskrit University'
  },
  {
    id: '5001',
    label: 'Netaji Subhas Institute of Technology',
    value: 'Netaji Subhas Institute of Technology'
  },
  {
    id: '5002',
    label: 'Neumann University',
    value: 'Neumann University'
  },
  {
    id: '5003',
    label: 'Nevada System of Higher Education',
    value: 'Nevada System of Higher Education'
  },
  {
    id: '5004',
    label: 'Nevsehir Haci Bektas Veli University',
    value: 'Nevsehir Haci Bektas Veli University'
  },
  {
    id: '5005',
    label: 'Nevsky Institute of Language and Culture',
    value: 'Nevsky Institute of Language and Culture'
  },
  {
    id: '5006',
    label: 'New Bulgarian University',
    value: 'New Bulgarian University'
  },
  {
    id: '5007',
    label: 'New College of California',
    value: 'New College of California'
  },
  {
    id: '5008',
    label: 'New College of Florida',
    value: 'New College of Florida'
  },
  {
    id: '5009',
    label: 'New Economic School',
    value: 'New Economic School'
  },
  {
    id: '5010',
    label: 'New England Institute of Technology',
    value: 'New England Institute of Technology'
  },
  {
    id: '5011',
    label: 'New Era College of Arts, Science & Technology',
    value: 'New Era College of Arts, Science & Technology'
  },
  {
    id: '5012',
    label: 'New Era University',
    value: 'New Era University'
  },
  {
    id: '5013',
    label: 'New Hampshire College',
    value: 'New Hampshire College'
  },
  {
    id: '5014',
    label: 'New Jersey City University',
    value: 'New Jersey City University'
  },
  {
    id: '5015',
    label: 'New Jersey Institute of Technology',
    value: 'New Jersey Institute of Technology'
  },
  {
    id: '5016',
    label: 'New Mexico Highlands University',
    value: 'New Mexico Highlands University'
  },
  {
    id: '5017',
    label: 'New Mexico Institute of Mining & Technology',
    value: 'New Mexico Institute of Mining & Technology'
  },
  {
    id: '5018',
    label: 'New Mexico Junior College',
    value: 'New Mexico Junior College'
  },
  {
    id: '5019',
    label: 'New Mexico Military Institute',
    value: 'New Mexico Military Institute'
  },
  {
    id: '5020',
    label: 'New Mexico State University',
    value: 'New Mexico State University'
  },
  {
    id: '5021',
    label: 'New Mexico State University-Alamogordo',
    value: 'New Mexico State University-Alamogordo'
  },
  {
    id: '5022',
    label: 'New Mexico State University-Carlsbad',
    value: 'New Mexico State University-Carlsbad'
  },
  {
    id: '5023',
    label: 'New Mexico State University-Dona Ana',
    value: 'New Mexico State University-Dona Ana'
  },
  {
    id: '5024',
    label: 'New River Community College',
    value: 'New River Community College'
  },
  {
    id: '5025',
    label: 'New River Community and Technical College',
    value: 'New River Community and Technical College'
  },
  {
    id: '5026',
    label: 'New World University',
    value: 'New World University'
  },
  {
    id: '5027',
    label: 'New York Institute of Technology',
    value: 'New York Institute of Technology'
  },
  {
    id: '5028',
    label: 'New York Institute of Technology',
    value: 'New York Institute of Technology'
  },
  {
    id: '5029',
    label: 'New York Instiute of Technology',
    value: 'New York Instiute of Technology'
  },
  {
    id: '5030',
    label: 'New York University',
    value: 'New York University'
  },
  {
    id: '5031',
    label: 'New York University, Abu Dhabi',
    value: 'New York University, Abu Dhabi'
  },
  {
    id: '5032',
    label: 'Newberry College',
    value: 'Newberry College'
  },
  {
    id: '5033',
    label: 'Newcastle College',
    value: 'Newcastle College'
  },
  {
    id: '5034',
    label: 'Newcastle University',
    value: 'Newcastle University'
  },
  {
    id: '5035',
    label: 'Newcastle University, Medicine Malaysia',
    value: 'Newcastle University, Medicine Malaysia'
  },
  {
    id: '5036',
    label: 'Newport International University',
    value: 'Newport International University'
  },
  {
    id: '5037',
    label: 'Newports Institute of Communication & Economics',
    value: 'Newports Institute of Communication & Economics'
  },
  {
    id: '5038',
    label: 'Ngee Ann Polytechnic',
    value: 'Ngee Ann Polytechnic'
  },
  {
    id: '5039',
    label: 'Niagara County Community College',
    value: 'Niagara County Community College'
  },
  {
    id: '5040',
    label: 'Niagara University',
    value: 'Niagara University'
  },
  {
    id: '5041',
    label: 'Nicholls State University',
    value: 'Nicholls State University'
  },
  {
    id: '5042',
    label: 'Nicola Valley Institute of Technology',
    value: 'Nicola Valley Institute of Technology'
  },
  {
    id: '5043',
    label: 'Nicolaus Copernicus Astronomical Center of Pharmacology Polish Academy of Sciences',
    value: 'Nicolaus Copernicus Astronomical Center of Pharmacology Polish Academy of Sciences'
  },
  {
    id: '5044',
    label: 'Nicolaus Copernicus University of Torun',
    value: 'Nicolaus Copernicus University of Torun'
  },
  {
    id: '5045',
    label: 'Nicolet Area Technical College',
    value: 'Nicolet Area Technical College'
  },
  {
    id: '5046',
    label: 'Niger Delta University',
    value: 'Niger Delta University'
  },
  {
    id: '5047',
    label: 'Nigerian Defence Academy',
    value: 'Nigerian Defence Academy'
  },
  {
    id: '5048',
    label: 'Nigerian Turkish Nile University',
    value: 'Nigerian Turkish Nile University'
  },
  {
    id: '5049',
    label: 'Nihon Fukushi University',
    value: 'Nihon Fukushi University'
  },
  {
    id: '5050',
    label: 'Nihon University',
    value: 'Nihon University'
  },
  {
    id: '5051',
    label: 'Niigata College of Pharmacy',
    value: 'Niigata College of Pharmacy'
  },
  {
    id: '5052',
    label: 'Niigata Sangyo University',
    value: 'Niigata Sangyo University'
  },
  {
    id: '5053',
    label: 'Niigata University',
    value: 'Niigata University'
  },
  {
    id: '5054',
    label: 'Niigata University of International and Information Studies',
    value: 'Niigata University of International and Information Studies'
  },
  {
    id: '5055',
    label: 'Niigata University of Management',
    value: 'Niigata University of Management'
  },
  {
    id: '5056',
    label: 'Nilai University College',
    value: 'Nilai University College'
  },
  {
    id: '5057',
    label: 'Nile University',
    value: 'Nile University'
  },
  {
    id: '5058',
    label: 'Nile Valley University',
    value: 'Nile Valley University'
  },
  {
    id: '5059',
    label: 'Ningbo University',
    value: 'Ningbo University'
  },
  {
    id: '5060',
    label: 'Ningbo University of Technology',
    value: 'Ningbo University of Technology'
  },
  {
    id: '5061',
    label: 'Ningxia Medical College',
    value: 'Ningxia Medical College'
  },
  {
    id: '5062',
    label: 'Ningxia University',
    value: 'Ningxia University'
  },
  {
    id: '5063',
    label: 'Nipissing University',
    value: 'Nipissing University'
  },
  {
    id: '5064',
    label: 'Nippon Bunri University',
    value: 'Nippon Bunri University'
  },
  {
    id: '5065',
    label: 'Nippon Dental University',
    value: 'Nippon Dental University'
  },
  {
    id: '5066',
    label: 'Nippon Institute of Technology',
    value: 'Nippon Institute of Technology'
  },
  {
    id: '5067',
    label: 'Nippon Medical School',
    value: 'Nippon Medical School'
  },
  {
    id: '5068',
    label: 'Nippon Sport Science University',
    value: 'Nippon Sport Science University'
  },
  {
    id: '5069',
    label: 'Nippon Veterinary and Animalscience University',
    value: 'Nippon Veterinary and Animalscience University'
  },
  {
    id: '5070',
    label: 'Nirma University',
    value: 'Nirma University'
  },
  {
    id: '5071',
    label: 'Nisantasi University',
    value: 'Nisantasi University'
  },
  {
    id: '5072',
    label: 'Nishikyushu University',
    value: 'Nishikyushu University'
  },
  {
    id: '5073',
    label: 'Nishinippon Institute of Technology',
    value: 'Nishinippon Institute of Technology'
  },
  {
    id: '5074',
    label: 'Nisho Gakusha University',
    value: 'Nisho Gakusha University'
  },
  {
    id: '5075',
    label: 'Nivadhana University',
    value: 'Nivadhana University'
  },
  {
    id: '5076',
    label: "Nizam's Institute of Medical Sciences",
    value: "Nizam's Institute of Medical Sciences"
  },
  {
    id: '5077',
    label: 'Nizhny Novgorod State Academy of Medicine',
    value: 'Nizhny Novgorod State Academy of Medicine'
  },
  {
    id: '5078',
    label: 'Nizhny Novgorod State Architectural - Building University',
    value: 'Nizhny Novgorod State Architectural - Building University'
  },
  {
    id: '5079',
    label: 'Nizhny Novgorod State Technical University',
    value: 'Nizhny Novgorod State Technical University'
  },
  {
    id: '5080',
    label: 'Nizhny Novgorod State University',
    value: 'Nizhny Novgorod State University'
  },
  {
    id: '5081',
    label: 'Njala University',
    value: 'Njala University'
  },
  {
    id: '5082',
    label: 'Nkumba University',
    value: 'Nkumba University'
  },
  {
    id: '5083',
    label: 'Nnamdi Azikiwe University',
    value: 'Nnamdi Azikiwe University'
  },
  {
    id: '5084',
    label: 'Noakhali University of Science and Technology',
    value: 'Noakhali University of Science and Technology'
  },
  {
    id: '5085',
    label: 'Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen',
    value: 'Nordakademie, Staatlich anerkannte private Fachhochschule mit dualen Studiengängen'
  },
  {
    id: '5086',
    label: 'Nordic School of Public Health',
    value: 'Nordic School of Public Health'
  },
  {
    id: '5087',
    label: 'Norfolk State University',
    value: 'Norfolk State University'
  },
  {
    id: '5088',
    label: 'Normandale Community College',
    value: 'Normandale Community College'
  },
  {
    id: '5089',
    label: 'North American University',
    value: 'North American University'
  },
  {
    id: '5090',
    label: 'North Arkansas College',
    value: 'North Arkansas College'
  },
  {
    id: '5091',
    label: 'North Carolina A&T State University',
    value: 'North Carolina A&T State University'
  },
  {
    id: '5092',
    label: 'North Carolina Central University',
    value: 'North Carolina Central University'
  },
  {
    id: '5093',
    label: 'North Carolina State University',
    value: 'North Carolina State University'
  },
  {
    id: '5094',
    label: 'North Carolina Wesleyan College',
    value: 'North Carolina Wesleyan College'
  },
  {
    id: '5095',
    label: 'North Central Bible College',
    value: 'North Central Bible College'
  },
  {
    id: '5096',
    label: 'North Central College',
    value: 'North Central College'
  },
  {
    id: '5097',
    label: 'North Central Kansas Technical College',
    value: 'North Central Kansas Technical College'
  },
  {
    id: '5098',
    label: 'North Central Michigan College',
    value: 'North Central Michigan College'
  },
  {
    id: '5099',
    label: 'North Central Missouri College',
    value: 'North Central Missouri College'
  },
  {
    id: '5100',
    label: 'North Central State College',
    value: 'North Central State College'
  },
  {
    id: '5101',
    label: 'North Central Texas College',
    value: 'North Central Texas College'
  },
  {
    id: '5102',
    label: 'North China Electric Power University',
    value: 'North China Electric Power University'
  },
  {
    id: '5103',
    label: 'North China University of Technology',
    value: 'North China University of Technology'
  },
  {
    id: '5104',
    label: 'North Country Community College',
    value: 'North Country Community College'
  },
  {
    id: '5105',
    label: 'North Dakota State College of Science',
    value: 'North Dakota State College of Science'
  },
  {
    id: '5106',
    label: 'North Dakota State University',
    value: 'North Dakota State University'
  },
  {
    id: '5107',
    label: 'North Dakota University System',
    value: 'North Dakota University System'
  },
  {
    id: '5108',
    label: 'North East University Bangladesh',
    value: 'North East University Bangladesh'
  },
  {
    id: '5109',
    label: 'North Eastern Hill University',
    value: 'North Eastern Hill University'
  },
  {
    id: '5110',
    label: 'North Florida Community College',
    value: 'North Florida Community College'
  },
  {
    id: '5111',
    label: 'North Georgia Technical College',
    value: 'North Georgia Technical College'
  },
  {
    id: '5112',
    label: 'North Hennepin Community College',
    value: 'North Hennepin Community College'
  },
  {
    id: '5113',
    label: 'North Idaho College',
    value: 'North Idaho College'
  },
  {
    id: '5114',
    label: 'North Iowa Area Community College',
    value: 'North Iowa Area Community College'
  },
  {
    id: '5115',
    label: 'North Island College',
    value: 'North Island College'
  },
  {
    id: '5116',
    label: 'North Kazakhstan State University',
    value: 'North Kazakhstan State University'
  },
  {
    id: '5117',
    label: 'North Lake College',
    value: 'North Lake College'
  },
  {
    id: '5118',
    label: 'North Maharashtra University',
    value: 'North Maharashtra University'
  },
  {
    id: '5119',
    label: 'North Ossetian State University',
    value: 'North Ossetian State University'
  },
  {
    id: '5120',
    label: 'North Park College and Theological Seminary',
    value: 'North Park College and Theological Seminary'
  },
  {
    id: '5121',
    label: 'North Shore Community College',
    value: 'North Shore Community College'
  },
  {
    id: '5122',
    label: 'North South University',
    value: 'North South University'
  },
  {
    id: '5123',
    label: 'North University of Baia Mare',
    value: 'North University of Baia Mare'
  },
  {
    id: '5124',
    label: 'North-West State Technical University',
    value: 'North-West State Technical University'
  },
  {
    id: '5125',
    label: 'North-West Univeristy',
    value: 'North-West Univeristy'
  },
  {
    id: '5126',
    label: 'North-West University',
    value: 'North-West University'
  },
  {
    id: '5127',
    label: 'NorthCap University',
    value: 'NorthCap University'
  },
  {
    id: '5128',
    label: 'NorthWest Arkansas Community College',
    value: 'NorthWest Arkansas Community College'
  },
  {
    id: '5129',
    label: 'Northampton Community College',
    value: 'Northampton Community College'
  },
  {
    id: '5130',
    label: 'Northcentral Technical College',
    value: 'Northcentral Technical College'
  },
  {
    id: '5131',
    label: 'Northeast Agricultural University',
    value: 'Northeast Agricultural University'
  },
  {
    id: '5132',
    label: 'Northeast Alabama Community College',
    value: 'Northeast Alabama Community College'
  },
  {
    id: '5133',
    label: 'Northeast Community College',
    value: 'Northeast Community College'
  },
  {
    id: '5134',
    label: 'Northeast Forest University',
    value: 'Northeast Forest University'
  },
  {
    id: '5135',
    label: 'Northeast Iowa Community College',
    value: 'Northeast Iowa Community College'
  },
  {
    id: '5136',
    label: 'Northeast Mississippi Community College',
    value: 'Northeast Mississippi Community College'
  },
  {
    id: '5137',
    label: 'Northeast Normal University',
    value: 'Northeast Normal University'
  },
  {
    id: '5138',
    label: 'Northeast State Community College',
    value: 'Northeast State Community College'
  },
  {
    id: '5139',
    label: 'Northeast Texas Community College',
    value: 'Northeast Texas Community College'
  },
  {
    id: '5140',
    label: 'Northeast University at Qinhuangdao Campus',
    value: 'Northeast University at Qinhuangdao Campus'
  },
  {
    id: '5141',
    label: 'Northeast Wisconsin Technical College',
    value: 'Northeast Wisconsin Technical College'
  },
  {
    id: '5142',
    label: 'Northeastern Junior College',
    value: 'Northeastern Junior College'
  },
  {
    id: '5143',
    label: 'Northeastern Louisiana University',
    value: 'Northeastern Louisiana University'
  },
  {
    id: '5144',
    label: 'Northeastern Oklahoma A&M College',
    value: 'Northeastern Oklahoma A&M College'
  },
  {
    id: '5145',
    label: 'Northeastern State University',
    value: 'Northeastern State University'
  },
  {
    id: '5146',
    label: 'Northeastern Technical College',
    value: 'Northeastern Technical College'
  },
  {
    id: '5147',
    label: 'Northeastern University',
    value: 'Northeastern University'
  },
  {
    id: '5148',
    label: 'Northeastern University',
    value: 'Northeastern University'
  },
  {
    id: '5149',
    label: 'Northern Alberta Institute of Technology',
    value: 'Northern Alberta Institute of Technology'
  },
  {
    id: '5150',
    label: 'Northern Arizona University',
    value: 'Northern Arizona University'
  },
  {
    id: '5151',
    label: 'Northern Border University',
    value: 'Northern Border University'
  },
  {
    id: '5152',
    label: 'Northern Caribbean University',
    value: 'Northern Caribbean University'
  },
  {
    id: '5153',
    label: 'Northern Essex Community College',
    value: 'Northern Essex Community College'
  },
  {
    id: '5154',
    label: 'Northern Illinois University',
    value: 'Northern Illinois University'
  },
  {
    id: '5155',
    label: 'Northern Jiaotong University',
    value: 'Northern Jiaotong University'
  },
  {
    id: '5156',
    label: 'Northern Kentucky University',
    value: 'Northern Kentucky University'
  },
  {
    id: '5157',
    label: 'Northern Lights College',
    value: 'Northern Lights College'
  },
  {
    id: '5158',
    label: 'Northern Maine Community College',
    value: 'Northern Maine Community College'
  },
  {
    id: '5159',
    label: 'Northern Michigan University',
    value: 'Northern Michigan University'
  },
  {
    id: '5160',
    label: 'Northern Oklahoma College',
    value: 'Northern Oklahoma College'
  },
  {
    id: '5161',
    label: 'Northern Sichuan Medical College',
    value: 'Northern Sichuan Medical College'
  },
  {
    id: '5162',
    label: 'Northern State Medical University',
    value: 'Northern State Medical University'
  },
  {
    id: '5163',
    label: 'Northern State University',
    value: 'Northern State University'
  },
  {
    id: '5164',
    label: 'Northern University Bangladesh',
    value: 'Northern University Bangladesh'
  },
  {
    id: '5165',
    label: 'Northern Virginia Community College',
    value: 'Northern Virginia Community College'
  },
  {
    id: '5166',
    label: 'Northland College',
    value: 'Northland College'
  },
  {
    id: '5167',
    label: 'Northland Community and Technical College',
    value: 'Northland Community and Technical College'
  },
  {
    id: '5168',
    label: 'Northland Pioneer College',
    value: 'Northland Pioneer College'
  },
  {
    id: '5169',
    label: 'Northumbria University',
    value: 'Northumbria University'
  },
  {
    id: '5170',
    label: 'Northwest A&F University',
    value: 'Northwest A&F University'
  },
  {
    id: '5171',
    label: 'Northwest College',
    value: 'Northwest College'
  },
  {
    id: '5172',
    label: 'Northwest Florida State College',
    value: 'Northwest Florida State College'
  },
  {
    id: '5173',
    label: 'Northwest Iowa Community College',
    value: 'Northwest Iowa Community College'
  },
  {
    id: '5174',
    label: 'Northwest Kansas Technical College',
    value: 'Northwest Kansas Technical College'
  },
  {
    id: '5175',
    label: 'Northwest Mississippi Community College',
    value: 'Northwest Mississippi Community College'
  },
  {
    id: '5176',
    label: 'Northwest Missouri State University',
    value: 'Northwest Missouri State University'
  },
  {
    id: '5177',
    label: 'Northwest Nazarene College',
    value: 'Northwest Nazarene College'
  },
  {
    id: '5178',
    label: 'Northwest Normal University Lanzhou',
    value: 'Northwest Normal University Lanzhou'
  },
  {
    id: '5179',
    label: "Northwest Polytechnical University Xi'an",
    value: "Northwest Polytechnical University Xi'an"
  },
  {
    id: '5180',
    label: 'Northwest State Community College',
    value: 'Northwest State Community College'
  },
  {
    id: '5181',
    label: 'Northwest Technical College',
    value: 'Northwest Technical College'
  },
  {
    id: '5182',
    label: "Northwest University Xi'an",
    value: "Northwest University Xi'an"
  },
  {
    id: '5183',
    label: 'Northwest-Shoals Community College',
    value: 'Northwest-Shoals Community College'
  },
  {
    id: '5184',
    label: 'Northwestern College of Iowa',
    value: 'Northwestern College of Iowa'
  },
  {
    id: '5185',
    label: 'Northwestern College-Chicago Campus',
    value: 'Northwestern College-Chicago Campus'
  },
  {
    id: '5186',
    label: 'Northwestern Connecticut Community College',
    value: 'Northwestern Connecticut Community College'
  },
  {
    id: '5187',
    label: 'Northwestern Michigan College',
    value: 'Northwestern Michigan College'
  },
  {
    id: '5188',
    label: 'Northwestern State University',
    value: 'Northwestern State University'
  },
  {
    id: '5189',
    label: 'Northwestern University',
    value: 'Northwestern University'
  },
  {
    id: '5190',
    label: 'Northwestern University In Qatar',
    value: 'Northwestern University In Qatar'
  },
  {
    id: '5191',
    label: 'Northwestern University of the Philippines',
    value: 'Northwestern University of the Philippines'
  },
  {
    id: '5192',
    label: 'Northwood University',
    value: 'Northwood University'
  },
  {
    id: '5193',
    label: 'Norton University',
    value: 'Norton University'
  },
  {
    id: '5194',
    label: 'Nortre Dame Seishin University',
    value: 'Nortre Dame Seishin University'
  },
  {
    id: '5195',
    label: 'Norwalk Community College',
    value: 'Norwalk Community College'
  },
  {
    id: '5196',
    label: "Norway's Information Technology University College",
    value: "Norway's Information Technology University College"
  },
  {
    id: '5197',
    label: 'Norwegian College of Veterinary Medicine',
    value: 'Norwegian College of Veterinary Medicine'
  },
  {
    id: '5198',
    label: 'Norwegian School of Economics and Business Administration',
    value: 'Norwegian School of Economics and Business Administration'
  },
  {
    id: '5199',
    label: 'Norwegian School of Management BI',
    value: 'Norwegian School of Management BI'
  },
  {
    id: '5200',
    label: 'Norwegian State Academy of Music',
    value: 'Norwegian State Academy of Music'
  },
  {
    id: '5201',
    label: 'Norwegian Teacher Academy for Studies in Religion and Education',
    value: 'Norwegian Teacher Academy for Studies in Religion and Education'
  },
  {
    id: '5202',
    label: 'Norwegian University of Life Sciences',
    value: 'Norwegian University of Life Sciences'
  },
  {
    id: '5203',
    label: 'Norwegian University of Science and Technology',
    value: 'Norwegian University of Science and Technology'
  },
  {
    id: '5204',
    label: 'Norwegian University of Sport and Physical Education',
    value: 'Norwegian University of Sport and Physical Education'
  },
  {
    id: '5205',
    label: 'Norwich University',
    value: 'Norwich University'
  },
  {
    id: '5206',
    label: 'Notre Dame University',
    value: 'Notre Dame University'
  },
  {
    id: '5207',
    label: 'Notre Dame University',
    value: 'Notre Dame University'
  },
  {
    id: '5208',
    label: 'Notre Dame of Marbel University',
    value: 'Notre Dame of Marbel University'
  },
  {
    id: '5209',
    label: 'Nottingham Trent University',
    value: 'Nottingham Trent University'
  },
  {
    id: '5210',
    label: 'Nova School of Business and Economics',
    value: 'Nova School of Business and Economics'
  },
  {
    id: '5211',
    label: 'Nova Scotia Agricultural College',
    value: 'Nova Scotia Agricultural College'
  },
  {
    id: '5212',
    label: 'Nova Scotia College of Art and Design',
    value: 'Nova Scotia College of Art and Design'
  },
  {
    id: '5213',
    label: 'Nova Southeastern University',
    value: 'Nova Southeastern University'
  },
  {
    id: '5214',
    label: 'Novena University',
    value: 'Novena University'
  },
  {
    id: '5215',
    label: 'Novgorod State University',
    value: 'Novgorod State University'
  },
  {
    id: '5216',
    label: 'Novosibirsk State Academy of Economics and Mangement',
    value: 'Novosibirsk State Academy of Economics and Mangement'
  },
  {
    id: '5217',
    label: 'Novosibirsk State Agricultural University',
    value: 'Novosibirsk State Agricultural University'
  },
  {
    id: '5218',
    label: 'Novosibirsk State Medical Academy',
    value: 'Novosibirsk State Medical Academy'
  },
  {
    id: '5219',
    label: 'Novosibirsk State Music Academy M. Glinka',
    value: 'Novosibirsk State Music Academy M. Glinka'
  },
  {
    id: '5220',
    label: 'Novosibirsk State Technical University',
    value: 'Novosibirsk State Technical University'
  },
  {
    id: '5221',
    label: 'Novosibirsk State University',
    value: 'Novosibirsk State University'
  },
  {
    id: '5222',
    label: 'Nuclear Institute for Agriculture and Biology (NIAB)',
    value: 'Nuclear Institute for Agriculture and Biology (NIAB)'
  },
  {
    id: '5223',
    label: 'Nugaal University',
    value: 'Nugaal University'
  },
  {
    id: '5224',
    label: 'Nuh Naci Yazgan University',
    value: 'Nuh Naci Yazgan University'
  },
  {
    id: '5225',
    label: 'Nukus State Teachers Training Institute',
    value: 'Nukus State Teachers Training Institute'
  },
  {
    id: '5226',
    label: 'Nunez Community College',
    value: 'Nunez Community College'
  },
  {
    id: '5227',
    label: 'Nyenrode Business University',
    value: 'Nyenrode Business University'
  },
  {
    id: '5228',
    label: 'OSTIM Technical University',
    value: 'OSTIM Technical University'
  },
  {
    id: '5229',
    label: 'Oakland Community College',
    value: 'Oakland Community College'
  },
  {
    id: '5230',
    label: 'Oakland University',
    value: 'Oakland University'
  },
  {
    id: '5231',
    label: 'Oakton Community College',
    value: 'Oakton Community College'
  },
  {
    id: '5232',
    label: 'Oakwood University',
    value: 'Oakwood University'
  },
  {
    id: '5233',
    label: 'Obafemi Awolowo University Ile-Ife',
    value: 'Obafemi Awolowo University Ile-Ife'
  },
  {
    id: '5234',
    label: 'Oberlin College',
    value: 'Oberlin College'
  },
  {
    id: '5235',
    label: 'Obihiro University of Agriculture and Veterinary Medicine',
    value: 'Obihiro University of Agriculture and Veterinary Medicine'
  },
  {
    id: '5236',
    label: 'Obirin University',
    value: 'Obirin University'
  },
  {
    id: '5237',
    label: 'Obninsk State Technical University for Nuclear Power Engineering',
    value: 'Obninsk State Technical University for Nuclear Power Engineering'
  },
  {
    id: '5238',
    label: 'Obong University',
    value: 'Obong University'
  },
  {
    id: '5239',
    label: 'Occidental College',
    value: 'Occidental College'
  },
  {
    id: '5240',
    label: 'Ocean County College',
    value: 'Ocean County College'
  },
  {
    id: '5241',
    label: 'Ocean University of China',
    value: 'Ocean University of China'
  },
  {
    id: '5242',
    label: "Ochanomizu Women's University",
    value: "Ochanomizu Women's University"
  },
  {
    id: '5243',
    label: 'October 6 university',
    value: 'October 6 university'
  },
  {
    id: '5244',
    label: 'Odense University',
    value: 'Odense University'
  },
  {
    id: '5245',
    label: 'Odessa College',
    value: 'Odessa College'
  },
  {
    id: '5246',
    label: 'Odessa National Academy of Food Technologies',
    value: 'Odessa National Academy of Food Technologies'
  },
  {
    id: '5247',
    label: 'Odessa National I.I. Mecnikov University',
    value: 'Odessa National I.I. Mecnikov University'
  },
  {
    id: '5248',
    label: 'Odessa National Marine University',
    value: 'Odessa National Marine University'
  },
  {
    id: '5249',
    label: 'Odessa National Maritime Academy',
    value: 'Odessa National Maritime Academy'
  },
  {
    id: '5250',
    label: 'Odessa National Polytechnic University',
    value: 'Odessa National Polytechnic University'
  },
  {
    id: '5251',
    label: 'Odessa State Academy of Construction and Architecture',
    value: 'Odessa State Academy of Construction and Architecture'
  },
  {
    id: '5252',
    label: 'Odessa State Medical University',
    value: 'Odessa State Medical University'
  },
  {
    id: '5253',
    label: 'Odlar Yurdu University',
    value: 'Odlar Yurdu University'
  },
  {
    id: '5254',
    label: 'Oduduwa University',
    value: 'Oduduwa University'
  },
  {
    id: '5255',
    label: 'Ogeechee Technical College',
    value: 'Ogeechee Technical College'
  },
  {
    id: '5256',
    label: 'Oglethorpe University',
    value: 'Oglethorpe University'
  },
  {
    id: '5257',
    label: 'Ohio Dominican College',
    value: 'Ohio Dominican College'
  },
  {
    id: '5258',
    label: 'Ohio Northern University',
    value: 'Ohio Northern University'
  },
  {
    id: '5259',
    label: 'Ohio State University - Columbus',
    value: 'Ohio State University - Columbus'
  },
  {
    id: '5260',
    label: 'Ohio State University Agricultural Technical Institute',
    value: 'Ohio State University Agricultural Technical Institute'
  },
  {
    id: '5261',
    label: 'Ohio University',
    value: 'Ohio University'
  },
  {
    id: '5262',
    label: 'Ohio Wesleyan University',
    value: 'Ohio Wesleyan University'
  },
  {
    id: '5263',
    label: 'Ohka Gakuen University',
    value: 'Ohka Gakuen University'
  },
  {
    id: '5264',
    label: 'Ohlone College',
    value: 'Ohlone College'
  },
  {
    id: '5265',
    label: "Ohtani Women's University",
    value: "Ohtani Women's University"
  },
  {
    id: '5266',
    label: 'Ohu University',
    value: 'Ohu University'
  },
  {
    id: '5267',
    label: 'Oita Medical University',
    value: 'Oita Medical University'
  },
  {
    id: '5268',
    label: 'Oita University',
    value: 'Oita University'
  },
  {
    id: '5269',
    label: 'Oita University of Nursing and Health Sciences',
    value: 'Oita University of Nursing and Health Sciences'
  },
  {
    id: '5270',
    label: 'Ojai Unified School District',
    value: 'Ojai Unified School District'
  },
  {
    id: '5271',
    label: 'Okan University',
    value: 'Okan University'
  },
  {
    id: '5272',
    label: 'Okanagan University College',
    value: 'Okanagan University College'
  },
  {
    id: '5273',
    label: 'Okayama Prefectural University',
    value: 'Okayama Prefectural University'
  },
  {
    id: '5274',
    label: 'Okayama Shoka University',
    value: 'Okayama Shoka University'
  },
  {
    id: '5275',
    label: 'Okayama University',
    value: 'Okayama University'
  },
  {
    id: '5276',
    label: 'Okayama University of Science',
    value: 'Okayama University of Science'
  },
  {
    id: '5277',
    label: 'Okinawa International University',
    value: 'Okinawa International University'
  },
  {
    id: '5278',
    label: 'Okinawa Prefectural University of Fine Arts',
    value: 'Okinawa Prefectural University of Fine Arts'
  },
  {
    id: '5279',
    label: 'Okinawa University',
    value: 'Okinawa University'
  },
  {
    id: '5280',
    label: 'Oklahoma Baptist University',
    value: 'Oklahoma Baptist University'
  },
  {
    id: '5281',
    label: 'Oklahoma Christian University',
    value: 'Oklahoma Christian University'
  },
  {
    id: '5282',
    label: 'Oklahoma City Community College',
    value: 'Oklahoma City Community College'
  },
  {
    id: '5283',
    label: 'Oklahoma City University',
    value: 'Oklahoma City University'
  },
  {
    id: '5284',
    label: 'Oklahoma State University',
    value: 'Oklahoma State University'
  },
  {
    id: '5285',
    label: 'Olabisi Onabanjo University',
    value: 'Olabisi Onabanjo University'
  },
  {
    id: '5286',
    label: 'Old Dominion University',
    value: 'Old Dominion University'
  },
  {
    id: '5287',
    label: 'Olin College of Engineering',
    value: 'Olin College of Engineering'
  },
  {
    id: '5288',
    label: 'Olivet Nazarene University',
    value: 'Olivet Nazarene University'
  },
  {
    id: '5289',
    label: 'Olympia College',
    value: 'Olympia College'
  },
  {
    id: '5290',
    label: 'Oman College Of Management & Technology',
    value: 'Oman College Of Management & Technology'
  },
  {
    id: '5291',
    label: 'Omar Al-Mukhtar University',
    value: 'Omar Al-Mukhtar University'
  },
  {
    id: '5292',
    label: 'Omdurman Ahlia University',
    value: 'Omdurman Ahlia University'
  },
  {
    id: '5293',
    label: 'Omdurman Islamic University',
    value: 'Omdurman Islamic University'
  },
  {
    id: '5294',
    label: 'Omer Halisdemir University',
    value: 'Omer Halisdemir University'
  },
  {
    id: '5295',
    label: 'Omsk State Agrarian University',
    value: 'Omsk State Agrarian University'
  },
  {
    id: '5296',
    label: 'Omsk State Medicical Academy',
    value: 'Omsk State Medicical Academy'
  },
  {
    id: '5297',
    label: 'Omsk State Pedagogical University',
    value: 'Omsk State Pedagogical University'
  },
  {
    id: '5298',
    label: 'Omsk State Technical University',
    value: 'Omsk State Technical University'
  },
  {
    id: '5299',
    label: 'Omsk State Transport University',
    value: 'Omsk State Transport University'
  },
  {
    id: '5300',
    label: 'Omsk State University',
    value: 'Omsk State University'
  },
  {
    id: '5301',
    label: 'Ondo State University of Science and Technology',
    value: 'Ondo State University of Science and Technology'
  },
  {
    id: '5302',
    label: 'Ondokuz Mayis University Samsun',
    value: 'Ondokuz Mayis University Samsun'
  },
  {
    id: '5303',
    label: 'Ondokuz Mayıs University',
    value: 'Ondokuz Mayıs University'
  },
  {
    id: '5304',
    label: 'Onondaga Community College',
    value: 'Onondaga Community College'
  },
  {
    id: '5305',
    label: 'Ontario College of Art and Design',
    value: 'Ontario College of Art and Design'
  },
  {
    id: '5306',
    label: 'Ontario Tech University',
    value: 'Ontario Tech University'
  },
  {
    id: '5307',
    label: 'Open International University for Alternative Medicines',
    value: 'Open International University for Alternative Medicines'
  },
  {
    id: '5308',
    label: 'Open Polytechnic',
    value: 'Open Polytechnic'
  },
  {
    id: '5309',
    label: 'Open University',
    value: 'Open University'
  },
  {
    id: '5310',
    label: 'Open University Malaysia',
    value: 'Open University Malaysia'
  },
  {
    id: '5311',
    label: 'Open University of Cyprus',
    value: 'Open University of Cyprus'
  },
  {
    id: '5312',
    label: 'Open University of Israel',
    value: 'Open University of Israel'
  },
  {
    id: '5313',
    label: 'Open University of Sri Lanka',
    value: 'Open University of Sri Lanka'
  },
  {
    id: '5314',
    label: 'Open University of Sudan (OUS)',
    value: 'Open University of Sudan (OUS)'
  },
  {
    id: '5315',
    label: 'Open University of Tanzania',
    value: 'Open University of Tanzania'
  },
  {
    id: '5316',
    label: 'Open University of the Netherlands',
    value: 'Open University of the Netherlands'
  },
  {
    id: '5317',
    label: 'Oradea University',
    value: 'Oradea University'
  },
  {
    id: '5318',
    label: 'Oral Roberts University',
    value: 'Oral Roberts University'
  },
  {
    id: '5319',
    label: 'Orange Coast College',
    value: 'Orange Coast College'
  },
  {
    id: '5320',
    label: 'Orange County Community College',
    value: 'Orange County Community College'
  },
  {
    id: '5321',
    label: 'Orangeburg Calhoun Technical College',
    value: 'Orangeburg Calhoun Technical College'
  },
  {
    id: '5322',
    label: 'Ordu University',
    value: 'Ordu University'
  },
  {
    id: '5323',
    label: 'Oregon Graduate Institute of Science and Technology',
    value: 'Oregon Graduate Institute of Science and Technology'
  },
  {
    id: '5324',
    label: 'Oregon Health Sciences University',
    value: 'Oregon Health Sciences University'
  },
  {
    id: '5325',
    label: 'Oregon Institute of Technology',
    value: 'Oregon Institute of Technology'
  },
  {
    id: '5326',
    label: 'Oregon State University',
    value: 'Oregon State University'
  },
  {
    id: '5327',
    label: 'Orenburg State University',
    value: 'Orenburg State University'
  },
  {
    id: '5328',
    label: 'Oriental University',
    value: 'Oriental University'
  },
  {
    id: '5329',
    label: 'Orissa University of Agriculture and Technology',
    value: 'Orissa University of Agriculture and Technology'
  },
  {
    id: '5330',
    label: 'Orkhon University',
    value: 'Orkhon University'
  },
  {
    id: '5331',
    label: 'Oryx International',
    value: 'Oryx International'
  },
  {
    id: '5332',
    label: 'Osaka City University',
    value: 'Osaka City University'
  },
  {
    id: '5333',
    label: 'Osaka College of Music',
    value: 'Osaka College of Music'
  },
  {
    id: '5334',
    label: 'Osaka Dental University',
    value: 'Osaka Dental University'
  },
  {
    id: '5335',
    label: 'Osaka Electro-Communication University',
    value: 'Osaka Electro-Communication University'
  },
  {
    id: '5336',
    label: 'Osaka Gakuin University',
    value: 'Osaka Gakuin University'
  },
  {
    id: '5337',
    label: 'Osaka Institute of Technology',
    value: 'Osaka Institute of Technology'
  },
  {
    id: '5338',
    label: 'Osaka International University',
    value: 'Osaka International University'
  },
  {
    id: '5339',
    label: 'Osaka International University for Women',
    value: 'Osaka International University for Women'
  },
  {
    id: '5340',
    label: 'Osaka Medical College',
    value: 'Osaka Medical College'
  },
  {
    id: '5341',
    label: 'Osaka Prefectural University',
    value: 'Osaka Prefectural University'
  },
  {
    id: '5342',
    label: 'Osaka Sangyo University',
    value: 'Osaka Sangyo University'
  },
  {
    id: '5343',
    label: "Osaka Shoin Women's College",
    value: "Osaka Shoin Women's College"
  },
  {
    id: '5344',
    label: 'Osaka University',
    value: 'Osaka University'
  },
  {
    id: '5345',
    label: 'Osaka University of Arts',
    value: 'Osaka University of Arts'
  },
  {
    id: '5346',
    label: 'Osaka University of Commerce',
    value: 'Osaka University of Commerce'
  },
  {
    id: '5347',
    label: 'Osaka University of Economics',
    value: 'Osaka University of Economics'
  },
  {
    id: '5348',
    label: 'Osaka University of Economics & Law',
    value: 'Osaka University of Economics & Law'
  },
  {
    id: '5349',
    label: 'Osaka University of Education',
    value: 'Osaka University of Education'
  },
  {
    id: '5350',
    label: 'Osaka University of Foreign Studies',
    value: 'Osaka University of Foreign Studies'
  },
  {
    id: '5351',
    label: 'Osaka University of Health and Sport Sciences',
    value: 'Osaka University of Health and Sport Sciences'
  },
  {
    id: '5352',
    label: 'Osaka University of Pharmaceutical Sciences',
    value: 'Osaka University of Pharmaceutical Sciences'
  },
  {
    id: '5353',
    label: "Osaka Women's University",
    value: "Osaka Women's University"
  },
  {
    id: '5354',
    label: 'Oslo School of Architecture',
    value: 'Oslo School of Architecture'
  },
  {
    id: '5355',
    label: 'Osmangazi University',
    value: 'Osmangazi University'
  },
  {
    id: '5356',
    label: 'Osmania University',
    value: 'Osmania University'
  },
  {
    id: '5357',
    label: 'Osmaniye Korkut Ata University',
    value: 'Osmaniye Korkut Ata University'
  },
  {
    id: '5358',
    label: 'Ostbayerische Technische Hochschule Amberg-Weiden',
    value: 'Ostbayerische Technische Hochschule Amberg-Weiden'
  },
  {
    id: '5359',
    label: 'Ostfalia Hochschule für angewandte Wissenschaften',
    value: 'Ostfalia Hochschule für angewandte Wissenschaften'
  },
  {
    id: '5360',
    label: 'Osun State University',
    value: 'Osun State University'
  },
  {
    id: '5361',
    label: 'Otani University',
    value: 'Otani University'
  },
  {
    id: '5362',
    label: 'Otaru University of Commerce',
    value: 'Otaru University of Commerce'
  },
  {
    id: '5363',
    label: 'Otemae University',
    value: 'Otemae University'
  },
  {
    id: '5364',
    label: 'Otemon Gakuin University',
    value: 'Otemon Gakuin University'
  },
  {
    id: '5365',
    label: 'Otero Junior College',
    value: 'Otero Junior College'
  },
  {
    id: '5366',
    label: "Otsuma Women's University",
    value: "Otsuma Women's University"
  },
  {
    id: '5367',
    label: 'Otterbein College',
    value: 'Otterbein College'
  },
  {
    id: '5368',
    label: 'Otto-Friedrich Universität Bamberg',
    value: 'Otto-Friedrich Universität Bamberg'
  },
  {
    id: '5369',
    label: 'Otto-von-Guericke Universität Magdeburg',
    value: 'Otto-von-Guericke Universität Magdeburg'
  },
  {
    id: '5370',
    label: 'Oulu Polytechnic',
    value: 'Oulu Polytechnic'
  },
  {
    id: '5371',
    label: 'Our Lady of Fatima University',
    value: 'Our Lady of Fatima University'
  },
  {
    id: '5372',
    label: 'Our Lady of the Lake University',
    value: 'Our Lady of the Lake University'
  },
  {
    id: '5373',
    label: 'Owens Community College',
    value: 'Owens Community College'
  },
  {
    id: '5374',
    label: 'Owensboro Community and Technical College',
    value: 'Owensboro Community and Technical College'
  },
  {
    id: '5375',
    label: 'Oxford Brookes University',
    value: 'Oxford Brookes University'
  },
  {
    id: '5376',
    label: 'Oxnard College',
    value: 'Oxnard College'
  },
  {
    id: '5377',
    label: 'Ozarka College',
    value: 'Ozarka College'
  },
  {
    id: '5378',
    label: 'Ozarks Technical Community College',
    value: 'Ozarks Technical Community College'
  },
  {
    id: '5379',
    label: 'Ozyegin University',
    value: 'Ozyegin University'
  },
  {
    id: '5380',
    label: 'PES University',
    value: 'PES University'
  },
  {
    id: '5381',
    label: 'PH Zug - Pädagogische Hochschule Zug',
    value: 'PH Zug - Pädagogische Hochschule Zug'
  },
  {
    id: '5382',
    label: 'PH Zürich - Pädagogische Hochschule Zürich',
    value: 'PH Zürich - Pädagogische Hochschule Zürich'
  },
  {
    id: '5383',
    label: 'PHBern - University of Teacher Education Bern',
    value: 'PHBern - University of Teacher Education Bern'
  },
  {
    id: '5384',
    label: 'PHGR - University of Teacher Education Graubünden',
    value: 'PHGR - University of Teacher Education Graubünden'
  },
  {
    id: '5385',
    label: 'PHLU - University of Teacher Education Lucerne',
    value: 'PHLU - University of Teacher Education Lucerne'
  },
  {
    id: '5386',
    label: 'PHSG - Pädagogische Hochschule St.Gallen',
    value: 'PHSG - Pädagogische Hochschule St.Gallen'
  },
  {
    id: '5387',
    label: 'PHSZ - University of Teacher Education Schwyz',
    value: 'PHSZ - University of Teacher Education Schwyz'
  },
  {
    id: '5388',
    label: 'PHTG - Pädagogische Hochschule Thurgau',
    value: 'PHTG - Pädagogische Hochschule Thurgau'
  },
  {
    id: '5389',
    label: 'PSG College of Technology',
    value: 'PSG College of Technology'
  },
  {
    id: '5390',
    label: 'PTPL College',
    value: 'PTPL College'
  },
  {
    id: '5391',
    label: 'Pabna University of Science and Technology',
    value: 'Pabna University of Science and Technology'
  },
  {
    id: '5392',
    label: 'Pace University',
    value: 'Pace University'
  },
  {
    id: '5393',
    label: 'Pacific Adventist University',
    value: 'Pacific Adventist University'
  },
  {
    id: '5394',
    label: 'Pacific International College',
    value: 'Pacific International College'
  },
  {
    id: '5395',
    label: 'Pacific Lutheran University',
    value: 'Pacific Lutheran University'
  },
  {
    id: '5396',
    label: 'Pacific Union College',
    value: 'Pacific Union College'
  },
  {
    id: '5397',
    label: 'Pacific University',
    value: 'Pacific University'
  },
  {
    id: '5398',
    label: 'Pacific Western University',
    value: 'Pacific Western University'
  },
  {
    id: '5399',
    label: 'Pai Chai University',
    value: 'Pai Chai University'
  },
  {
    id: '5400',
    label: 'Paichai University',
    value: 'Paichai University'
  },
  {
    id: '5401',
    label: 'Paine College',
    value: 'Paine College'
  },
  {
    id: '5402',
    label: 'Pak-AIMS (American Institute of Management Sciences)',
    value: 'Pak-AIMS (American Institute of Management Sciences)'
  },
  {
    id: '5403',
    label: 'Pakistan Institute of Engineering and Applied Sciences (PIEAS)',
    value: 'Pakistan Institute of Engineering and Applied Sciences (PIEAS)'
  },
  {
    id: '5404',
    label: 'Paktia University',
    value: 'Paktia University'
  },
  {
    id: '5405',
    label: 'Palacký University Olomouc',
    value: 'Palacký University Olomouc'
  },
  {
    id: '5406',
    label: 'Palawan State University',
    value: 'Palawan State University'
  },
  {
    id: '5407',
    label: 'Palestine Polytechnic University',
    value: 'Palestine Polytechnic University'
  },
  {
    id: '5408',
    label: 'Palestine Technical College-Dier Elbalah',
    value: 'Palestine Technical College-Dier Elbalah'
  },
  {
    id: '5409',
    label: 'Palestine Technical University - Kadoorie',
    value: 'Palestine Technical University - Kadoorie'
  },
  {
    id: '5410',
    label: 'Palm Beach Atlantic College',
    value: 'Palm Beach Atlantic College'
  },
  {
    id: '5411',
    label: 'Palm Beach State College',
    value: 'Palm Beach State College'
  },
  {
    id: '5412',
    label: 'Palo Verde College',
    value: 'Palo Verde College'
  },
  {
    id: '5413',
    label: 'Palomar College',
    value: 'Palomar College'
  },
  {
    id: '5414',
    label: 'Pamir University',
    value: 'Pamir University'
  },
  {
    id: '5415',
    label: 'Pamlico Community College',
    value: 'Pamlico Community College'
  },
  {
    id: '5416',
    label: 'Pamukkale University',
    value: 'Pamukkale University'
  },
  {
    id: '5417',
    label: 'Pan Africa Christian University',
    value: 'Pan Africa Christian University'
  },
  {
    id: '5418',
    label: 'Pan African Christian University College',
    value: 'Pan African Christian University College'
  },
  {
    id: '5419',
    label: 'Pan-African University',
    value: 'Pan-African University'
  },
  {
    id: '5420',
    label: 'Pandit Deendayal Petroleum University',
    value: 'Pandit Deendayal Petroleum University'
  },
  {
    id: '5421',
    label: 'Pandit Ravi Shankar Shukla University',
    value: 'Pandit Ravi Shankar Shukla University'
  },
  {
    id: '5422',
    label: 'Pangasinan State University',
    value: 'Pangasinan State University'
  },
  {
    id: '5423',
    label: 'Panimalar Engineering College',
    value: 'Panimalar Engineering College'
  },
  {
    id: '5424',
    label: 'Panjab University',
    value: 'Panjab University'
  },
  {
    id: '5425',
    label: 'Pannnasatra University of Cambodia',
    value: 'Pannnasatra University of Cambodia'
  },
  {
    id: '5426',
    label: 'Panola College',
    value: 'Panola College'
  },
  {
    id: '5427',
    label: 'Panteios University of Economics and Political Sciences Athens',
    value: 'Panteios University of Economics and Political Sciences Athens'
  },
  {
    id: '5428',
    label: 'Panzhihua University',
    value: 'Panzhihua University'
  },
  {
    id: '5429',
    label: 'Papua New Guinea University of Technology',
    value: 'Papua New Guinea University of Technology'
  },
  {
    id: '5430',
    label: 'Paradise Valley Community College',
    value: 'Paradise Valley Community College'
  },
  {
    id: '5431',
    label: 'Paris Junior College',
    value: 'Paris Junior College'
  },
  {
    id: '5432',
    label: 'Paris-Sorbonne University Abu Dhabi',
    value: 'Paris-Sorbonne University Abu Dhabi'
  },
  {
    id: '5433',
    label: 'Park University',
    value: 'Park University'
  },
  {
    id: '5434',
    label: 'Parkland College',
    value: 'Parkland College'
  },
  {
    id: '5435',
    label: 'Parul University',
    value: 'Parul University'
  },
  {
    id: '5436',
    label: 'Parwan University',
    value: 'Parwan University'
  },
  {
    id: '5437',
    label: 'Pasadena City College',
    value: 'Pasadena City College'
  },
  {
    id: '5438',
    label: 'Pasco-Hernando Community College',
    value: 'Pasco-Hernando Community College'
  },
  {
    id: '5439',
    label: 'Pasco-Hernando State College',
    value: 'Pasco-Hernando State College'
  },
  {
    id: '5440',
    label: 'Passaic County Community College',
    value: 'Passaic County Community College'
  },
  {
    id: '5441',
    label: 'Pathumthani University',
    value: 'Pathumthani University'
  },
  {
    id: '5442',
    label: 'Patna University',
    value: 'Patna University'
  },
  {
    id: '5443',
    label: 'Patrick Henry Community College',
    value: 'Patrick Henry Community College'
  },
  {
    id: '5444',
    label: 'Patuakhali Science and Technology University',
    value: 'Patuakhali Science and Technology University'
  },
  {
    id: '5445',
    label: 'Paul D Camp Community College',
    value: 'Paul D Camp Community College'
  },
  {
    id: '5446',
    label: 'Paul Quinn College',
    value: 'Paul Quinn College'
  },
  {
    id: '5447',
    label: 'Paul University',
    value: 'Paul University'
  },
  {
    id: '5448',
    label: 'Pavlodar University',
    value: 'Pavlodar University'
  },
  {
    id: '5449',
    label: 'Pavol Jozef Safarik University in Kosice',
    value: 'Pavol Jozef Safarik University in Kosice'
  },
  {
    id: '5450',
    label: 'Pawel Wlodkowic University College in Plock',
    value: 'Pawel Wlodkowic University College in Plock'
  },
  {
    id: '5451',
    label: 'Payam Noor University Lavasan',
    value: 'Payam Noor University Lavasan'
  },
  {
    id: '5452',
    label: 'Payame Noor University',
    value: 'Payame Noor University'
  },
  {
    id: '5453',
    label: 'Payame Noor University, Tafresh',
    value: 'Payame Noor University, Tafresh'
  },
  {
    id: '5454',
    label: 'Payap University Chaiang Mai',
    value: 'Payap University Chaiang Mai'
  },
  {
    id: '5455',
    label: 'Peace College',
    value: 'Peace College'
  },
  {
    id: '5456',
    label: 'Pearl River Community College',
    value: 'Pearl River Community College'
  },
  {
    id: '5457',
    label: 'Pedagogical State University "Ion Creanga"',
    value: 'Pedagogical State University "Ion Creanga"'
  },
  {
    id: '5458',
    label: 'Pedagogical University of Bydgoszcz',
    value: 'Pedagogical University of Bydgoszcz'
  },
  {
    id: '5459',
    label: 'Pedagogical University of Czestochowa',
    value: 'Pedagogical University of Czestochowa'
  },
  {
    id: '5460',
    label: 'Pedagogical University of Kielce',
    value: 'Pedagogical University of Kielce'
  },
  {
    id: '5461',
    label: 'Pedagogical University of Krakow',
    value: 'Pedagogical University of Krakow'
  },
  {
    id: '5462',
    label: 'Pedagogical University of Rzeszow',
    value: 'Pedagogical University of Rzeszow'
  },
  {
    id: '5463',
    label: 'Pedagogical University of Slupsk',
    value: 'Pedagogical University of Slupsk'
  },
  {
    id: '5464',
    label: 'Pedagogical University of Zielona Gora',
    value: 'Pedagogical University of Zielona Gora'
  },
  {
    id: '5465',
    label: 'Pedagogical University of the Polish Association for Adult Education in Warsaw',
    value: 'Pedagogical University of the Polish Association for Adult Education in Warsaw'
  },
  {
    id: '5466',
    label: 'Peking University',
    value: 'Peking University'
  },
  {
    id: '5467',
    label: 'Pellissippi State Community College',
    value: 'Pellissippi State Community College'
  },
  {
    id: '5468',
    label: 'Pembroke State University',
    value: 'Pembroke State University'
  },
  {
    id: '5469',
    label: 'Penang International Dental College',
    value: 'Penang International Dental College'
  },
  {
    id: '5470',
    label: 'Pennsylvania College of Technology',
    value: 'Pennsylvania College of Technology'
  },
  {
    id: '5471',
    label: 'Pennsylvania Highlands Community College',
    value: 'Pennsylvania Highlands Community College'
  },
  {
    id: '5472',
    label: 'Pennsylvania Institute of Technology',
    value: 'Pennsylvania Institute of Technology'
  },
  {
    id: '5473',
    label: 'Pennsylvania State University',
    value: 'Pennsylvania State University'
  },
  {
    id: '5474',
    label: 'Pennsylvania State University - Harrisburg',
    value: 'Pennsylvania State University - Harrisburg'
  },
  {
    id: '5475',
    label: 'Pennsylvania State University - Schuylkill Campus',
    value: 'Pennsylvania State University - Schuylkill Campus'
  },
  {
    id: '5476',
    label: 'Pensacola Christian College',
    value: 'Pensacola Christian College'
  },
  {
    id: '5477',
    label: 'Pensacola State College',
    value: 'Pensacola State College'
  },
  {
    id: '5478',
    label: 'Penza State University',
    value: 'Penza State University'
  },
  {
    id: '5479',
    label: "People' s Friendship University of Russia",
    value: "People' s Friendship University of Russia"
  },
  {
    id: '5480',
    label: "People's University of Bangladesh",
    value: "People's University of Bangladesh"
  },
  {
    id: '5481',
    label: 'Pepperdine University',
    value: 'Pepperdine University'
  },
  {
    id: '5482',
    label: 'Perak Islamic College',
    value: 'Perak Islamic College'
  },
  {
    id: '5483',
    label: 'Perbanas Institute',
    value: 'Perbanas Institute'
  },
  {
    id: '5484',
    label: 'Perdana University',
    value: 'Perdana University'
  },
  {
    id: '5485',
    label: 'Periyar University',
    value: 'Periyar University'
  },
  {
    id: '5486',
    label: 'Perlis Islamic Higher Learning Institute',
    value: 'Perlis Islamic Higher Learning Institute'
  },
  {
    id: '5487',
    label: 'Perm State Medical Academy',
    value: 'Perm State Medical Academy'
  },
  {
    id: '5488',
    label: 'Perm State Pedagogical University',
    value: 'Perm State Pedagogical University'
  },
  {
    id: '5489',
    label: 'Perm State Pharmaceutical Academy',
    value: 'Perm State Pharmaceutical Academy'
  },
  {
    id: '5490',
    label: 'Perm State Technical University',
    value: 'Perm State Technical University'
  },
  {
    id: '5491',
    label: 'Perm State University',
    value: 'Perm State University'
  },
  {
    id: '5492',
    label: 'Persian Gulf University',
    value: 'Persian Gulf University'
  },
  {
    id: '5493',
    label: 'Peru State College',
    value: 'Peru State College'
  },
  {
    id: '5494',
    label: 'Petro Mohyla Black Sea State University',
    value: 'Petro Mohyla Black Sea State University'
  },
  {
    id: '5495',
    label: 'Petroleum University of Technology',
    value: 'Petroleum University of Technology'
  },
  {
    id: '5496',
    label: 'Petronas Technology University',
    value: 'Petronas Technology University'
  },
  {
    id: '5497',
    label: 'Petrozavodsk State University',
    value: 'Petrozavodsk State University'
  },
  {
    id: '5498',
    label: 'Petru Maior University of Targu Mures',
    value: 'Petru Maior University of Targu Mures'
  },
  {
    id: '5499',
    label: 'Pham Ngoc Thach University of Medicine',
    value: 'Pham Ngoc Thach University of Medicine'
  },
  {
    id: '5500',
    label: 'Phan Chau Trinh University',
    value: 'Phan Chau Trinh University'
  },
  {
    id: '5501',
    label: 'Pharos International University',
    value: 'Pharos International University'
  },
  {
    id: '5502',
    label: 'Philadelphia College of Osteopathic Medicine',
    value: 'Philadelphia College of Osteopathic Medicine'
  },
  {
    id: '5503',
    label: 'Philadelphia University',
    value: 'Philadelphia University'
  },
  {
    id: '5504',
    label: 'Philadelphia University',
    value: 'Philadelphia University'
  },
  {
    id: '5505',
    label: 'Philander Smith College',
    value: 'Philander Smith College'
  },
  {
    id: '5506',
    label: 'Philippine Christian University',
    value: 'Philippine Christian University'
  },
  {
    id: '5507',
    label: 'Philippine Military Academy',
    value: 'Philippine Military Academy'
  },
  {
    id: '5508',
    label: 'Philippine Normal University',
    value: 'Philippine Normal University'
  },
  {
    id: '5509',
    label: "Philippine Women's University",
    value: "Philippine Women's University"
  },
  {
    id: '5510',
    label: 'Phillips Community College of the University of Arkansas',
    value: 'Phillips Community College of the University of Arkansas'
  },
  {
    id: '5511',
    label: 'Phillips University',
    value: 'Phillips University'
  },
  {
    id: '5512',
    label: 'Phillips-Universität Marburg',
    value: 'Phillips-Universität Marburg'
  },
  {
    id: '5513',
    label: 'Philosophisch-Theologische Hochschule Münster',
    value: 'Philosophisch-Theologische Hochschule Münster'
  },
  {
    id: '5514',
    label: 'Philosophisch-Theologische Hochschule SVD Sankt Augustin',
    value: 'Philosophisch-Theologische Hochschule SVD Sankt Augustin'
  },
  {
    id: '5515',
    label: 'Philosophisch-Theologische Hochschule Sankt Georgen',
    value: 'Philosophisch-Theologische Hochschule Sankt Georgen'
  },
  {
    id: '5516',
    label:
      'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)',
    value:
      'Philosophisch-Theologische Hochschule Vallendar der Gesellschaft des Katholischen Apostolates (Pallottiner)'
  },
  {
    id: '5517',
    label: 'Philosophisch-Theologische Hochschule der Salesianer Don Boscos',
    value: 'Philosophisch-Theologische Hochschule der Salesianer Don Boscos'
  },
  {
    id: '5518',
    label: 'Phoenix College',
    value: 'Phoenix College'
  },
  {
    id: '5519',
    label: 'Phranakhon Si Ayutthaya Rajabhat University',
    value: 'Phranakhon Si Ayutthaya Rajabhat University'
  },
  {
    id: '5520',
    label: 'Physical Education Academy "Eugeniusz Piasecki" in Poznan',
    value: 'Physical Education Academy "Eugeniusz Piasecki" in Poznan'
  },
  {
    id: '5521',
    label: 'Physical Education Academy "Jedrzej Sniadecki" in Gdansk',
    value: 'Physical Education Academy "Jedrzej Sniadecki" in Gdansk'
  },
  {
    id: '5522',
    label: 'Physical Education Academy "Jozef Pilsudski" in Warsaw',
    value: 'Physical Education Academy "Jozef Pilsudski" in Warsaw'
  },
  {
    id: '5523',
    label: 'Physical Education Academy in Katowice',
    value: 'Physical Education Academy in Katowice'
  },
  {
    id: '5524',
    label: 'Physical Education Academy in Wroclaw',
    value: 'Physical Education Academy in Wroclaw'
  },
  {
    id: '5525',
    label: 'Piedmont Community College',
    value: 'Piedmont Community College'
  },
  {
    id: '5526',
    label: 'Piedmont Technical College',
    value: 'Piedmont Technical College'
  },
  {
    id: '5527',
    label: 'Piedmont Virginia Community College',
    value: 'Piedmont Virginia Community College'
  },
  {
    id: '5528',
    label: 'Pierce College at Puyallup',
    value: 'Pierce College at Puyallup'
  },
  {
    id: '5529',
    label: 'Pierpont Community and Technical College',
    value: 'Pierpont Community and Technical College'
  },
  {
    id: '5530',
    label: 'Pikes Peak Community College',
    value: 'Pikes Peak Community College'
  },
  {
    id: '5531',
    label: 'Pima Community College',
    value: 'Pima Community College'
  },
  {
    id: '5532',
    label: 'Pine Technical College',
    value: 'Pine Technical College'
  },
  {
    id: '5533',
    label: 'Pioneer International University',
    value: 'Pioneer International University'
  },
  {
    id: '5534',
    label: 'Piri Reis University',
    value: 'Piri Reis University'
  },
  {
    id: '5535',
    label: 'Pitt Community College',
    value: 'Pitt Community College'
  },
  {
    id: '5536',
    label: 'Pittsburg State University',
    value: 'Pittsburg State University'
  },
  {
    id: '5537',
    label: 'Pittsburgh Technical Institute',
    value: 'Pittsburgh Technical Institute'
  },
  {
    id: '5538',
    label: 'Pitzer College',
    value: 'Pitzer College'
  },
  {
    id: '5539',
    label: 'Pjatigorsk State Linguistic University',
    value: 'Pjatigorsk State Linguistic University'
  },
  {
    id: '5540',
    label: 'Planwel University',
    value: 'Planwel University'
  },
  {
    id: '5541',
    label: 'Platt College',
    value: 'Platt College'
  },
  {
    id: '5542',
    label: 'Plekhanov Russian Academy of Economics',
    value: 'Plekhanov Russian Academy of Economics'
  },
  {
    id: '5543',
    label: 'Plymouth State College',
    value: 'Plymouth State College'
  },
  {
    id: '5544',
    label: 'Pohang University of Science and Technology',
    value: 'Pohang University of Science and Technology'
  },
  {
    id: '5545',
    label: 'Point Park University',
    value: 'Point Park University'
  },
  {
    id: '5546',
    label: 'Pokhara University',
    value: 'Pokhara University'
  },
  {
    id: '5547',
    label: 'Police Academy',
    value: 'Police Academy'
  },
  {
    id: '5548',
    label: 'Police Academy in Bratislava',
    value: 'Police Academy in Bratislava'
  },
  {
    id: '5549',
    label: 'Police Academy of Latvia',
    value: 'Police Academy of Latvia'
  },
  {
    id: '5550',
    label: 'Polish Open University in Warsaw (WSZ/POU)',
    value: 'Polish Open University in Warsaw (WSZ/POU)'
  },
  {
    id: '5551',
    label: 'Polish-Japanese Institute of Information Technology in Warsaw',
    value: 'Polish-Japanese Institute of Information Technology in Warsaw'
  },
  {
    id: '5552',
    label: 'Politeknik Negeri Bandung',
    value: 'Politeknik Negeri Bandung'
  },
  {
    id: '5553',
    label: 'Politeknik Negeri Jakarta',
    value: 'Politeknik Negeri Jakarta'
  },
  {
    id: '5554',
    label: 'Politeknik Negeri Lhokseumawe',
    value: 'Politeknik Negeri Lhokseumawe'
  },
  {
    id: '5555',
    label: 'Politeknik Negeri Malang',
    value: 'Politeknik Negeri Malang'
  },
  {
    id: '5556',
    label: 'Politeknik Negeri Padang',
    value: 'Politeknik Negeri Padang'
  },
  {
    id: '5557',
    label: 'Politeknik Negeri Pontianak',
    value: 'Politeknik Negeri Pontianak'
  },
  {
    id: '5558',
    label: 'Politeknik Negeri Sambas',
    value: 'Politeknik Negeri Sambas'
  },
  {
    id: '5559',
    label: 'Politeknik Negeri Semarang',
    value: 'Politeknik Negeri Semarang'
  },
  {
    id: '5560',
    label: 'Politeknik Pos Indonesia',
    value: 'Politeknik Pos Indonesia'
  },
  {
    id: '5561',
    label: 'Politécnico Columbiano "Jaime Isaza Cadavid"',
    value: 'Politécnico Columbiano "Jaime Isaza Cadavid"'
  },
  {
    id: '5562',
    label: 'Politécnico Grancolombiano - Institución Universitaria',
    value: 'Politécnico Grancolombiano - Institución Universitaria'
  },
  {
    id: '5563',
    label: 'Politécnico de Guanajuato',
    value: 'Politécnico de Guanajuato'
  },
  {
    id: '5564',
    label: 'Polk State College',
    value: 'Polk State College'
  },
  {
    id: '5565',
    label: 'Polonia University in Czêstochowa',
    value: 'Polonia University in Czêstochowa'
  },
  {
    id: '5566',
    label: 'Polotsk State University',
    value: 'Polotsk State University'
  },
  {
    id: '5567',
    label: 'Poltava National Technical University',
    value: 'Poltava National Technical University'
  },
  {
    id: '5568',
    label: 'Poltava University of Consumer Cooperatives in Ukraine',
    value: 'Poltava University of Consumer Cooperatives in Ukraine'
  },
  {
    id: '5569',
    label: 'Polytechnic Ibadan',
    value: 'Polytechnic Ibadan'
  },
  {
    id: '5570',
    label: 'Polytechnic Institute of Bari',
    value: 'Polytechnic Institute of Bari'
  },
  {
    id: '5571',
    label: 'Polytechnic Institute of Milan',
    value: 'Polytechnic Institute of Milan'
  },
  {
    id: '5572',
    label: 'Polytechnic Institute of Turin',
    value: 'Polytechnic Institute of Turin'
  },
  {
    id: '5573',
    label: 'Polytechnic University',
    value: 'Polytechnic University'
  },
  {
    id: '5574',
    label: 'Polytechnic University of New York',
    value: 'Polytechnic University of New York'
  },
  {
    id: '5575',
    label: 'Polytechnic University of Pernambuco',
    value: 'Polytechnic University of Pernambuco'
  },
  {
    id: '5576',
    label: 'Polytechnic University of Puerto Rico',
    value: 'Polytechnic University of Puerto Rico'
  },
  {
    id: '5577',
    label: 'Polytechnic University of Timisoara',
    value: 'Polytechnic University of Timisoara'
  },
  {
    id: '5578',
    label: 'Polytechnic University of Tirana',
    value: 'Polytechnic University of Tirana'
  },
  {
    id: '5579',
    label: 'Polytechnic University of the Philippines',
    value: 'Polytechnic University of the Philippines'
  },
  {
    id: '5580',
    label: 'Polytechnic of Namibia',
    value: 'Polytechnic of Namibia'
  },
  {
    id: '5581',
    label: 'Polytechnical University of Kabul',
    value: 'Polytechnical University of Kabul'
  },
  {
    id: '5582',
    label: 'Pomeranian Academy of Medicine in Szczecin',
    value: 'Pomeranian Academy of Medicine in Szczecin'
  },
  {
    id: '5583',
    label: 'Pomona College',
    value: 'Pomona College'
  },
  {
    id: '5584',
    label: 'Pomor State University',
    value: 'Pomor State University'
  },
  {
    id: '5585',
    label: 'Pondicherry University',
    value: 'Pondicherry University'
  },
  {
    id: '5586',
    label: 'Pondicherry University',
    value: 'Pondicherry University'
  },
  {
    id: '5587',
    label: 'Pontifcia Universitas a S.Thomas Aquinate in Urbe',
    value: 'Pontifcia Universitas a S.Thomas Aquinate in Urbe'
  },
  {
    id: '5588',
    label: 'Pontifcia Università Gregoriana',
    value: 'Pontifcia Università Gregoriana'
  },
  {
    id: '5589',
    label: 'Pontifcia Università Urbaniana',
    value: 'Pontifcia Università Urbaniana'
  },
  {
    id: '5590',
    label: 'Pontifica Università Gregoriana',
    value: 'Pontifica Università Gregoriana'
  },
  {
    id: '5591',
    label: 'Pontificia Universidad Catolica de Chile',
    value: 'Pontificia Universidad Catolica de Chile'
  },
  {
    id: '5592',
    label: 'Pontificia Universidad Catolica de Puerto Rico',
    value: 'Pontificia Universidad Catolica de Puerto Rico'
  },
  {
    id: '5593',
    label: 'Pontificia Universidad Católica del Ecuador',
    value: 'Pontificia Universidad Católica del Ecuador'
  },
  {
    id: '5594',
    label: 'Pontificia Universidad Católica del Perú',
    value: 'Pontificia Universidad Católica del Perú'
  },
  {
    id: '5595',
    label: 'Pontificia Universidad Javeriana',
    value: 'Pontificia Universidad Javeriana'
  },
  {
    id: '5596',
    label: 'Pontificia Universidade Católica do Paraná',
    value: 'Pontificia Universidade Católica do Paraná'
  },
  {
    id: '5597',
    label: 'Pontificia Università Lateranense',
    value: 'Pontificia Università Lateranense'
  },
  {
    id: '5598',
    label: 'Pontificia Università S. Tommaso',
    value: 'Pontificia Università S. Tommaso'
  },
  {
    id: '5599',
    label: 'Pontificia Università della Santa Croce',
    value: 'Pontificia Università della Santa Croce'
  },
  {
    id: '5600',
    label: 'Pontificio Ateneo Antonianum',
    value: 'Pontificio Ateneo Antonianum'
  },
  {
    id: '5601',
    label: 'Pontificio Istituto Orientale',
    value: 'Pontificio Istituto Orientale'
  },
  {
    id: '5602',
    label: 'Pontifícia Universidade Católica de Campinas',
    value: 'Pontifícia Universidade Católica de Campinas'
  },
  {
    id: '5603',
    label: 'Pontifícia Universidade Católica de Minas Gerais',
    value: 'Pontifícia Universidade Católica de Minas Gerais'
  },
  {
    id: '5604',
    label: 'Pontifícia Universidade Católica de São Paulo',
    value: 'Pontifícia Universidade Católica de São Paulo'
  },
  {
    id: '5605',
    label: 'Pontifícia Universidade Católica do Rio Grande do Sul',
    value: 'Pontifícia Universidade Católica do Rio Grande do Sul'
  },
  {
    id: '5606',
    label: 'Pontifícia Universidade Católica do Rio de Janeiro',
    value: 'Pontifícia Universidade Católica do Rio de Janeiro'
  },
  {
    id: '5607',
    label: 'Poole Gakuin University',
    value: 'Poole Gakuin University'
  },
  {
    id: '5608',
    label: 'Popakademie Baden-Württemberg',
    value: 'Popakademie Baden-Württemberg'
  },
  {
    id: '5609',
    label: 'Port Dickson Polytechnic',
    value: 'Port Dickson Polytechnic'
  },
  {
    id: '5610',
    label: 'Porterville College',
    value: 'Porterville College'
  },
  {
    id: '5611',
    label: 'Portland Community College',
    value: 'Portland Community College'
  },
  {
    id: '5612',
    label: 'Portland State University',
    value: 'Portland State University'
  },
  {
    id: '5613',
    label: 'Postgraduate Institute of Agriculture (PGIA)',
    value: 'Postgraduate Institute of Agriculture (PGIA)'
  },
  {
    id: '5614',
    label: 'Postgraduate lnstitute of Medical Education and Research',
    value: 'Postgraduate lnstitute of Medical Education and Research'
  },
  {
    id: '5615',
    label: 'Posts & Telecommunications Institute of Technology',
    value: 'Posts & Telecommunications Institute of Technology'
  },
  {
    id: '5616',
    label: 'Potti Sreeramulu Telugu University',
    value: 'Potti Sreeramulu Telugu University'
  },
  {
    id: '5617',
    label: 'Power and Water Institute of Technology',
    value: 'Power and Water Institute of Technology'
  },
  {
    id: '5618',
    label: 'Poznan School of Banking',
    value: 'Poznan School of Banking'
  },
  {
    id: '5619',
    label: 'Poznan University of Life Sciences',
    value: 'Poznan University of Life Sciences'
  },
  {
    id: '5620',
    label: 'Prague College',
    value: 'Prague College'
  },
  {
    id: '5621',
    label: 'Prague Institute of Chemical Technology',
    value: 'Prague Institute of Chemical Technology'
  },
  {
    id: '5622',
    label: 'Prague International University',
    value: 'Prague International University'
  },
  {
    id: '5623',
    label: 'Prairie State College',
    value: 'Prairie State College'
  },
  {
    id: '5624',
    label: 'Prairie View A&M University',
    value: 'Prairie View A&M University'
  },
  {
    id: '5625',
    label: 'Prasetiya Mulya Business School',
    value: 'Prasetiya Mulya Business School'
  },
  {
    id: '5626',
    label: 'Prathyusha Engineering College',
    value: 'Prathyusha Engineering College'
  },
  {
    id: '5627',
    label: 'Pratt Community College',
    value: 'Pratt Community College'
  },
  {
    id: '5628',
    label: 'Pratt Institute',
    value: 'Pratt Institute'
  },
  {
    id: '5629',
    label: 'Precarpathian University',
    value: 'Precarpathian University'
  },
  {
    id: '5630',
    label: 'Premier University',
    value: 'Premier University'
  },
  {
    id: '5631',
    label: 'Presbyterian College',
    value: 'Presbyterian College'
  },
  {
    id: '5632',
    label: 'Presbyterian University College',
    value: 'Presbyterian University College'
  },
  {
    id: '5633',
    label: 'Presbyterian University and Theological Seminary',
    value: 'Presbyterian University and Theological Seminary'
  },
  {
    id: '5634',
    label: 'Presbyterian University of East Africa',
    value: 'Presbyterian University of East Africa'
  },
  {
    id: '5635',
    label: 'Prescott College',
    value: 'Prescott College'
  },
  {
    id: '5636',
    label: 'Presidency University',
    value: 'Presidency University'
  },
  {
    id: '5637',
    label: 'Preston Institute of Management Sciences and Technology (PIMSAT)',
    value: 'Preston Institute of Management Sciences and Technology (PIMSAT)'
  },
  {
    id: '5638',
    label: 'Preston University, Pakistan Campus',
    value: 'Preston University, Pakistan Campus'
  },
  {
    id: '5639',
    label: 'Primier International University Perak',
    value: 'Primier International University Perak'
  },
  {
    id: '5640',
    label: "Prince George's Community College",
    value: "Prince George's Community College"
  },
  {
    id: '5641',
    label: 'Prince Mohammad Bin Fahd University',
    value: 'Prince Mohammad Bin Fahd University'
  },
  {
    id: '5642',
    label: 'Prince Sultan College for Tourism and Hotel Scinces',
    value: 'Prince Sultan College for Tourism and Hotel Scinces'
  },
  {
    id: '5643',
    label: 'Prince Sultan University',
    value: 'Prince Sultan University'
  },
  {
    id: '5644',
    label: 'Prince William Sound College',
    value: 'Prince William Sound College'
  },
  {
    id: '5645',
    label: 'Prince of Songkla University',
    value: 'Prince of Songkla University'
  },
  {
    id: '5646',
    label: 'Princess Nora Bint Abdulrahman University',
    value: 'Princess Nora Bint Abdulrahman University'
  },
  {
    id: '5647',
    label: 'Princess Sumaya University for Technology',
    value: 'Princess Sumaya University for Technology'
  },
  {
    id: '5648',
    label: 'Princeton University',
    value: 'Princeton University'
  },
  {
    id: '5649',
    label: 'Private College of Dentistry and Pharmacy',
    value: 'Private College of Dentistry and Pharmacy'
  },
  {
    id: '5650',
    label: 'Private Fachhochschule Göttingen',
    value: 'Private Fachhochschule Göttingen'
  },
  {
    id: '5651',
    label: 'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz',
    value: 'Private Fachhochschule für Wirtschaft und Technik Vechta/Diepholz'
  },
  {
    id: '5652',
    label: 'Private FernFachhochschule Darmstadt',
    value: 'Private FernFachhochschule Darmstadt'
  },
  {
    id: '5653',
    label: 'Private Hanseuniversität',
    value: 'Private Hanseuniversität'
  },
  {
    id: '5654',
    label: 'Private Universität Witten/Herdecke',
    value: 'Private Universität Witten/Herdecke'
  },
  {
    id: '5655',
    label: 'Private Universität für Medizinische Informatik und Technik Tirol',
    value: 'Private Universität für Medizinische Informatik und Technik Tirol'
  },
  {
    id: '5656',
    label: 'Privredna Akademija (Business Academy)',
    value: 'Privredna Akademija (Business Academy)'
  },
  {
    id: '5657',
    label: 'Providence College',
    value: 'Providence College'
  },
  {
    id: '5658',
    label: 'Providence University',
    value: 'Providence University'
  },
  {
    id: '5659',
    label: 'Pueblo Community College',
    value: 'Pueblo Community College'
  },
  {
    id: '5660',
    label: 'Pukyong National University',
    value: 'Pukyong National University'
  },
  {
    id: '5661',
    label: 'Pulaski Technical College',
    value: 'Pulaski Technical College'
  },
  {
    id: '5662',
    label: 'Punjab Agricultural University',
    value: 'Punjab Agricultural University'
  },
  {
    id: '5663',
    label: 'Punjab Engineering College',
    value: 'Punjab Engineering College'
  },
  {
    id: '5664',
    label: 'Punjab Institute of Computer science',
    value: 'Punjab Institute of Computer science'
  },
  {
    id: '5665',
    label: 'Punjab Technical University',
    value: 'Punjab Technical University'
  },
  {
    id: '5666',
    label: 'Punjab University College of Information Tecnology',
    value: 'Punjab University College of Information Tecnology'
  },
  {
    id: '5667',
    label: 'Punjabi University Patiala',
    value: 'Punjabi University Patiala'
  },
  {
    id: '5668',
    label: 'Puntland State University',
    value: 'Puntland State University'
  },
  {
    id: '5669',
    label: 'Purbanchal University',
    value: 'Purbanchal University'
  },
  {
    id: '5670',
    label: 'Purdue University',
    value: 'Purdue University'
  },
  {
    id: '5671',
    label: 'Purdue University Calumet',
    value: 'Purdue University Calumet'
  },
  {
    id: '5672',
    label: 'Purdue University Fort Wayne',
    value: 'Purdue University Fort Wayne'
  },
  {
    id: '5673',
    label: 'Purdue University North Central',
    value: 'Purdue University North Central'
  },
  {
    id: '5674',
    label: 'Purdue University Northwest',
    value: 'Purdue University Northwest'
  },
  {
    id: '5675',
    label: 'Pusan National University',
    value: 'Pusan National University'
  },
  {
    id: '5676',
    label: 'Pusan National University of Education',
    value: 'Pusan National University of Education'
  },
  {
    id: '5677',
    label: 'Pusan University of Foreign Studies',
    value: 'Pusan University of Foreign Studies'
  },
  {
    id: '5678',
    label: "Pusan Women's University",
    value: "Pusan Women's University"
  },
  {
    id: '5679',
    label: 'Pwani University',
    value: 'Pwani University'
  },
  {
    id: '5680',
    label: 'Pyeongtaek University',
    value: 'Pyeongtaek University'
  },
  {
    id: '5681',
    label: 'Pyongtaek University',
    value: 'Pyongtaek University'
  },
  {
    id: '5682',
    label: 'Pyongyang University of Science and Technology',
    value: 'Pyongyang University of Science and Technology'
  },
  {
    id: '5683',
    label: 'Pyramid Education Center',
    value: 'Pyramid Education Center'
  },
  {
    id: '5684',
    label: 'Pázmány Péter Catholic University',
    value: 'Pázmány Péter Catholic University'
  },
  {
    id: '5685',
    label: 'Pädagogische Hochschule Erfurt/Mühlhausen',
    value: 'Pädagogische Hochschule Erfurt/Mühlhausen'
  },
  {
    id: '5686',
    label: 'Pädagogische Hochschule Freiburg',
    value: 'Pädagogische Hochschule Freiburg'
  },
  {
    id: '5687',
    label: 'Pädagogische Hochschule Heidelberg',
    value: 'Pädagogische Hochschule Heidelberg'
  },
  {
    id: '5688',
    label: 'Pädagogische Hochschule Karlsruhe',
    value: 'Pädagogische Hochschule Karlsruhe'
  },
  {
    id: '5689',
    label: 'Pädagogische Hochschule Ludwigsburg',
    value: 'Pädagogische Hochschule Ludwigsburg'
  },
  {
    id: '5690',
    label: 'Pädagogische Hochschule Schwäbisch Gmünd',
    value: 'Pädagogische Hochschule Schwäbisch Gmünd'
  },
  {
    id: '5691',
    label: 'Pädagogische Hochschule Weingarten',
    value: 'Pädagogische Hochschule Weingarten'
  },
  {
    id: '5692',
    label: 'Qafqaz University',
    value: 'Qafqaz University'
  },
  {
    id: '5693',
    label: 'Qassim Private College',
    value: 'Qassim Private College'
  },
  {
    id: '5694',
    label: 'Qassim University',
    value: 'Qassim University'
  },
  {
    id: '5695',
    label: 'Qauid-e-Awam University of Engineering Sciences & Technology',
    value: 'Qauid-e-Awam University of Engineering Sciences & Technology'
  },
  {
    id: '5696',
    label: 'Qazvin University of Medical Sciences',
    value: 'Qazvin University of Medical Sciences'
  },
  {
    id: '5697',
    label: 'Qingdao University',
    value: 'Qingdao University'
  },
  {
    id: '5698',
    label: 'Qingdao University of Science and Technology',
    value: 'Qingdao University of Science and Technology'
  },
  {
    id: '5699',
    label: 'Qinghai Normal University',
    value: 'Qinghai Normal University'
  },
  {
    id: '5700',
    label: 'Qinghai Radio & Television University',
    value: 'Qinghai Radio & Television University'
  },
  {
    id: '5701',
    label: 'Qinghai University',
    value: 'Qinghai University'
  },
  {
    id: '5702',
    label: 'Qiongzhou University',
    value: 'Qiongzhou University'
  },
  {
    id: '5703',
    label: 'Qom University',
    value: 'Qom University'
  },
  {
    id: '5704',
    label: 'Qom University of Medical Sciences',
    value: 'Qom University of Medical Sciences'
  },
  {
    id: '5705',
    label: 'Qom University of Technology',
    value: 'Qom University of Technology'
  },
  {
    id: '5706',
    label: 'Quadrat Academy',
    value: 'Quadrat Academy'
  },
  {
    id: '5707',
    label: 'Quaid-i-Azam University',
    value: 'Quaid-i-Azam University'
  },
  {
    id: '5708',
    label: 'Queen Arwa University',
    value: 'Queen Arwa University'
  },
  {
    id: '5709',
    label: 'Queen Mary, University of London',
    value: 'Queen Mary, University of London'
  },
  {
    id: '5710',
    label: "Queen's University",
    value: "Queen's University"
  },
  {
    id: '5711',
    label: 'Queens University',
    value: 'Queens University'
  },
  {
    id: '5712',
    label: 'Queens University of Charlotte',
    value: 'Queens University of Charlotte'
  },
  {
    id: '5713',
    label: 'Queensland University of Technology',
    value: 'Queensland University of Technology'
  },
  {
    id: '5714',
    label: 'Quest University',
    value: 'Quest University'
  },
  {
    id: '5715',
    label: 'Qufu Normal University',
    value: 'Qufu Normal University'
  },
  {
    id: '5716',
    label: 'Quincy College',
    value: 'Quincy College'
  },
  {
    id: '5717',
    label: 'Quincy University',
    value: 'Quincy University'
  },
  {
    id: '5718',
    label: 'Quinebaug Valley Community College',
    value: 'Quinebaug Valley Community College'
  },
  {
    id: '5719',
    label: 'Quinnipiac College',
    value: 'Quinnipiac College'
  },
  {
    id: '5720',
    label: 'Quinsigamond Community College',
    value: 'Quinsigamond Community College'
  },
  {
    id: '5721',
    label: 'Qurtuba University of Science and Infromation Technology',
    value: 'Qurtuba University of Science and Infromation Technology'
  },
  {
    id: '5722',
    label: 'RCSI-Medical University of Bahrain',
    value: 'RCSI-Medical University of Bahrain'
  },
  {
    id: '5723',
    label: 'RMD Engineering College',
    value: 'RMD Engineering College'
  },
  {
    id: '5724',
    label: 'RMIT International University Vietnam',
    value: 'RMIT International University Vietnam'
  },
  {
    id: '5725',
    label: 'RMK College of Engineering and Technology',
    value: 'RMK College of Engineering and Technology'
  },
  {
    id: '5726',
    label: 'RMK Engineering College',
    value: 'RMK Engineering College'
  },
  {
    id: '5727',
    label: 'Rabindra Bharati University',
    value: 'Rabindra Bharati University'
  },
  {
    id: '5728',
    label: 'Radboud University',
    value: 'Radboud University'
  },
  {
    id: '5729',
    label: 'Radford University',
    value: 'Radford University'
  },
  {
    id: '5730',
    label: 'Raffles University',
    value: 'Raffles University'
  },
  {
    id: '5731',
    label: 'Rafsanjan University of Medical Sciences',
    value: 'Rafsanjan University of Medical Sciences'
  },
  {
    id: '5732',
    label: 'Ragheb Esfahani University',
    value: 'Ragheb Esfahani University'
  },
  {
    id: '5733',
    label: 'Rainy River Community College',
    value: 'Rainy River Community College'
  },
  {
    id: '5734',
    label: 'Rajagiri School of Engineering and Technology',
    value: 'Rajagiri School of Engineering and Technology'
  },
  {
    id: '5735',
    label: 'Rajalakshmi Engineering College',
    value: 'Rajalakshmi Engineering College'
  },
  {
    id: '5736',
    label: 'Rajalakshmi Institute of Technology',
    value: 'Rajalakshmi Institute of Technology'
  },
  {
    id: '5737',
    label: 'Rajamangala University of Technology, Lanna Chiang Rai',
    value: 'Rajamangala University of Technology, Lanna Chiang Rai'
  },
  {
    id: '5738',
    label: 'Rajamangala University of Technology, Lanna Nan',
    value: 'Rajamangala University of Technology, Lanna Nan'
  },
  {
    id: '5739',
    label: 'Rajamangala University of Technology, Phra Nakhon',
    value: 'Rajamangala University of Technology, Phra Nakhon'
  },
  {
    id: '5740',
    label: 'Rajarata University of Sri Lanka',
    value: 'Rajarata University of Sri Lanka'
  },
  {
    id: '5741',
    label: 'Rajasthan Agricultural University, Bikaner',
    value: 'Rajasthan Agricultural University, Bikaner'
  },
  {
    id: '5742',
    label: 'Rajasthan Technical University',
    value: 'Rajasthan Technical University'
  },
  {
    id: '5743',
    label: 'Rajasthan Vidyapeeth University',
    value: 'Rajasthan Vidyapeeth University'
  },
  {
    id: '5744',
    label: 'Rajendra Agricultural University',
    value: 'Rajendra Agricultural University'
  },
  {
    id: '5745',
    label: 'Rajitlal Institute of Technology & Health Sciences (RITHS)',
    value: 'Rajitlal Institute of Technology & Health Sciences (RITHS)'
  },
  {
    id: '5746',
    label: 'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)',
    value: 'Rajiv Gandhi Technical University (University of Technology of Madhya Pradesh)'
  },
  {
    id: '5747',
    label: 'Rajiv Gandhi University',
    value: 'Rajiv Gandhi University'
  },
  {
    id: '5748',
    label: 'Rajshahi University of Engineering and Technology',
    value: 'Rajshahi University of Engineering and Technology'
  },
  {
    id: '5749',
    label: 'Rak Medical & Health Sciences University',
    value: 'Rak Medical & Health Sciences University'
  },
  {
    id: '5750',
    label: 'Rakuno Gakuen University',
    value: 'Rakuno Gakuen University'
  },
  {
    id: '5751',
    label: 'Ramaiah University of Applied Sciences',
    value: 'Ramaiah University of Applied Sciences'
  },
  {
    id: '5752',
    label: 'Ramapo College',
    value: 'Ramapo College'
  },
  {
    id: '5753',
    label: 'Ramkhamhaeng University',
    value: 'Ramkhamhaeng University'
  },
  {
    id: '5754',
    label: 'Rana Institute of Higher Education',
    value: 'Rana Institute of Higher Education'
  },
  {
    id: '5755',
    label: 'Ranchi University',
    value: 'Ranchi University'
  },
  {
    id: '5756',
    label: 'Randolph Community College',
    value: 'Randolph Community College'
  },
  {
    id: '5757',
    label: 'Randolph-Macon College',
    value: 'Randolph-Macon College'
  },
  {
    id: '5758',
    label: "Randolph-Macon Woman's College",
    value: "Randolph-Macon Woman's College"
  },
  {
    id: '5759',
    label: 'Ranger College',
    value: 'Ranger College'
  },
  {
    id: '5760',
    label: 'Rangsit University',
    value: 'Rangsit University'
  },
  {
    id: '5761',
    label: 'Rani Durgavati University',
    value: 'Rani Durgavati University'
  },
  {
    id: '5762',
    label: 'Rappahannock Community College',
    value: 'Rappahannock Community College'
  },
  {
    id: '5763',
    label: 'Raritan Valley Community College',
    value: 'Raritan Valley Community College'
  },
  {
    id: '5764',
    label: 'Razi University',
    value: 'Razi University'
  },
  {
    id: '5765',
    label: 'Reading Area Community College',
    value: 'Reading Area Community College'
  },
  {
    id: '5766',
    label: 'Recep Tayip Erdogan University',
    value: 'Recep Tayip Erdogan University'
  },
  {
    id: '5767',
    label: 'Red Rocks Community College',
    value: 'Red Rocks Community College'
  },
  {
    id: '5768',
    label: 'Red Sea University',
    value: 'Red Sea University'
  },
  {
    id: '5769',
    label: 'Redeemer College',
    value: 'Redeemer College'
  },
  {
    id: '5770',
    label: 'Redeemers University',
    value: 'Redeemers University'
  },
  {
    id: '5771',
    label: 'Redlands Community College',
    value: 'Redlands Community College'
  },
  {
    id: '5772',
    label: 'Reed College',
    value: 'Reed College'
  },
  {
    id: '5773',
    label: 'Reedley College',
    value: 'Reedley College'
  },
  {
    id: '5774',
    label: 'Reformed Church University',
    value: 'Reformed Church University'
  },
  {
    id: '5775',
    label: 'Reformed Theological Academy of Debrecen',
    value: 'Reformed Theological Academy of Debrecen'
  },
  {
    id: '5776',
    label: 'Regent College',
    value: 'Regent College'
  },
  {
    id: '5777',
    label: 'Regent University',
    value: 'Regent University'
  },
  {
    id: '5778',
    label: 'Regent University College of Science and Technology',
    value: 'Regent University College of Science and Technology'
  },
  {
    id: '5779',
    label: 'Regis University',
    value: 'Regis University'
  },
  {
    id: '5780',
    label: 'Reitaku University',
    value: 'Reitaku University'
  },
  {
    id: '5781',
    label: 'Renaissance University',
    value: 'Renaissance University'
  },
  {
    id: '5782',
    label: 'Rend Lake College',
    value: 'Rend Lake College'
  },
  {
    id: '5783',
    label: 'Renmin University of China',
    value: 'Renmin University of China'
  },
  {
    id: '5784',
    label: 'Rennes School of Business',
    value: 'Rennes School of Business'
  },
  {
    id: '5785',
    label: 'Rensselaer Polytechnic Institute',
    value: 'Rensselaer Polytechnic Institute'
  },
  {
    id: '5786',
    label: 'Renton Technical College',
    value: 'Renton Technical College'
  },
  {
    id: '5787',
    label: 'Republic Polytechnic',
    value: 'Republic Polytechnic'
  },
  {
    id: '5788',
    label: 'Republic of Korea Air Force Academy',
    value: 'Republic of Korea Air Force Academy'
  },
  {
    id: '5789',
    label: 'Republic of Korea Naval Academy',
    value: 'Republic of Korea Naval Academy'
  },
  {
    id: '5790',
    label: 'Reykjavík University',
    value: 'Reykjavík University'
  },
  {
    id: '5791',
    label: 'Rezekne Higher School',
    value: 'Rezekne Higher School'
  },
  {
    id: '5792',
    label: 'Rheinisch Westfälische Technische Hochschule Aachen',
    value: 'Rheinisch Westfälische Technische Hochschule Aachen'
  },
  {
    id: '5793',
    label: 'Rheinische Fachhochschule Köln',
    value: 'Rheinische Fachhochschule Köln'
  },
  {
    id: '5794',
    label: 'Rheinische Friedrich-Wilhelms Universität Bonn',
    value: 'Rheinische Friedrich-Wilhelms Universität Bonn'
  },
  {
    id: '5795',
    label: 'Rhode Island College',
    value: 'Rhode Island College'
  },
  {
    id: '5796',
    label: 'Rhodes College',
    value: 'Rhodes College'
  },
  {
    id: '5797',
    label: 'Rhodes University',
    value: 'Rhodes University'
  },
  {
    id: '5798',
    label: 'Riara University School of Business and Law',
    value: 'Riara University School of Business and Law'
  },
  {
    id: '5799',
    label: 'Rice University',
    value: 'Rice University'
  },
  {
    id: '5800',
    label: 'Rich Mountain Community College',
    value: 'Rich Mountain Community College'
  },
  {
    id: '5801',
    label: 'Richard Bland College of the College of William and Mary',
    value: 'Richard Bland College of the College of William and Mary'
  },
  {
    id: '5802',
    label: 'Richard Stockton College of New Jersey',
    value: 'Richard Stockton College of New Jersey'
  },
  {
    id: '5803',
    label: 'Richland College',
    value: 'Richland College'
  },
  {
    id: '5804',
    label: 'Richland Community College',
    value: 'Richland Community College'
  },
  {
    id: '5805',
    label: 'Richmond Community College',
    value: 'Richmond Community College'
  },
  {
    id: '5806',
    label: 'Richmond University - The American International University in London',
    value: 'Richmond University - The American International University in London'
  },
  {
    id: '5807',
    label: 'Rider University',
    value: 'Rider University'
  },
  {
    id: '5808',
    label: 'Ridgewater College',
    value: 'Ridgewater College'
  },
  {
    id: '5809',
    label: 'Riga Aviation University',
    value: 'Riga Aviation University'
  },
  {
    id: '5810',
    label: 'Riga Teacher Training and Educational Management Academy',
    value: 'Riga Teacher Training and Educational Management Academy'
  },
  {
    id: '5811',
    label: 'Riga Technical University',
    value: 'Riga Technical University'
  },
  {
    id: '5812',
    label: "Rikkyo University (St. Paul's University)",
    value: "Rikkyo University (St. Paul's University)"
  },
  {
    id: '5813',
    label: 'Rio Hondo College',
    value: 'Rio Hondo College'
  },
  {
    id: '5814',
    label: 'Rio Salado College',
    value: 'Rio Salado College'
  },
  {
    id: '5815',
    label: 'Riphah International Univeristy',
    value: 'Riphah International Univeristy'
  },
  {
    id: '5816',
    label: 'Ripon College',
    value: 'Ripon College'
  },
  {
    id: '5817',
    label: 'Rissho University',
    value: 'Rissho University'
  },
  {
    id: '5818',
    label: 'Ritsumeikan Asia Pacific University',
    value: 'Ritsumeikan Asia Pacific University'
  },
  {
    id: '5819',
    label: 'Ritsumeikan University',
    value: 'Ritsumeikan University'
  },
  {
    id: '5820',
    label: 'River Parishes Community College',
    value: 'River Parishes Community College'
  },
  {
    id: '5821',
    label: 'River Valley Community College',
    value: 'River Valley Community College'
  },
  {
    id: '5822',
    label: 'Riverland Community College',
    value: 'Riverland Community College'
  },
  {
    id: '5823',
    label: 'Rivers State University of Science and Technology',
    value: 'Rivers State University of Science and Technology'
  },
  {
    id: '5824',
    label: 'Riverside City College',
    value: 'Riverside City College'
  },
  {
    id: '5825',
    label: 'Riverside Community College District',
    value: 'Riverside Community College District'
  },
  {
    id: '5826',
    label: 'Rivier College',
    value: 'Rivier College'
  },
  {
    id: '5827',
    label: 'Riyadh College of Dentistry and Pharmacy',
    value: 'Riyadh College of Dentistry and Pharmacy'
  },
  {
    id: '5828',
    label: 'Rizal Technological University',
    value: 'Rizal Technological University'
  },
  {
    id: '5829',
    label: 'Roane State Community College',
    value: 'Roane State Community College'
  },
  {
    id: '5830',
    label: 'Roanoke College',
    value: 'Roanoke College'
  },
  {
    id: '5831',
    label: 'Roanoke-Chowan Community College',
    value: 'Roanoke-Chowan Community College'
  },
  {
    id: '5832',
    label: 'Robert Morris University',
    value: 'Robert Morris University'
  },
  {
    id: '5833',
    label: 'Robert Morris University Illinois',
    value: 'Robert Morris University Illinois'
  },
  {
    id: '5834',
    label: 'Robeson Community College',
    value: 'Robeson Community College'
  },
  {
    id: '5835',
    label: 'Rochester Community and Technical College',
    value: 'Rochester Community and Technical College'
  },
  {
    id: '5836',
    label: 'Rochester Institute of Technology',
    value: 'Rochester Institute of Technology'
  },
  {
    id: '5837',
    label: 'Rochester Institute of Technology, Dubai',
    value: 'Rochester Institute of Technology, Dubai'
  },
  {
    id: '5838',
    label: 'Rock Valley College',
    value: 'Rock Valley College'
  },
  {
    id: '5839',
    label: 'Rockefeller University',
    value: 'Rockefeller University'
  },
  {
    id: '5840',
    label: 'Rockford College',
    value: 'Rockford College'
  },
  {
    id: '5841',
    label: 'Rockhurst College',
    value: 'Rockhurst College'
  },
  {
    id: '5842',
    label: 'Rockingham Community College',
    value: 'Rockingham Community College'
  },
  {
    id: '5843',
    label: 'Rockland Community College',
    value: 'Rockland Community College'
  },
  {
    id: '5844',
    label: 'Rocky Mountain College',
    value: 'Rocky Mountain College'
  },
  {
    id: '5845',
    label: 'Roehampton University of Surrey',
    value: 'Roehampton University of Surrey'
  },
  {
    id: '5846',
    label: 'Roger Williams University',
    value: 'Roger Williams University'
  },
  {
    id: '5847',
    label: 'Rogers State University',
    value: 'Rogers State University'
  },
  {
    id: '5848',
    label: 'Rogue Community College',
    value: 'Rogue Community College'
  },
  {
    id: '5849',
    label: 'Rollins College',
    value: 'Rollins College'
  },
  {
    id: '5850',
    label: 'Romanian-American University',
    value: 'Romanian-American University'
  },
  {
    id: '5851',
    label: 'Roosevelt Academy University College',
    value: 'Roosevelt Academy University College'
  },
  {
    id: '5852',
    label: 'Roosevelt University',
    value: 'Roosevelt University'
  },
  {
    id: '5853',
    label: 'Rosary College',
    value: 'Rosary College'
  },
  {
    id: '5854',
    label: 'Rose State College',
    value: 'Rose State College'
  },
  {
    id: '5855',
    label: 'Rose-Hulman Institute of Technology',
    value: 'Rose-Hulman Institute of Technology'
  },
  {
    id: '5856',
    label: 'Roskilde University',
    value: 'Roskilde University'
  },
  {
    id: '5857',
    label: 'Ross University Caribbean School of Medicine',
    value: 'Ross University Caribbean School of Medicine'
  },
  {
    id: '5858',
    label: 'Rostov State Medical University',
    value: 'Rostov State Medical University'
  },
  {
    id: '5859',
    label: 'Rostov State University',
    value: 'Rostov State University'
  },
  {
    id: '5860',
    label: 'Rotterdam School of Management',
    value: 'Rotterdam School of Management'
  },
  {
    id: '5861',
    label: 'Rovaniemi University of Applied Sciences',
    value: 'Rovaniemi University of Applied Sciences'
  },
  {
    id: '5862',
    label: 'Rowan College',
    value: 'Rowan College'
  },
  {
    id: '5863',
    label: 'Rowan College at Burlington County',
    value: 'Rowan College at Burlington County'
  },
  {
    id: '5864',
    label: 'Rowan-Cabarrus Community College',
    value: 'Rowan-Cabarrus Community College'
  },
  {
    id: '5865',
    label: 'Roxbury Community College',
    value: 'Roxbury Community College'
  },
  {
    id: '5866',
    label: 'Royal Academy of Music',
    value: 'Royal Academy of Music'
  },
  {
    id: '5867',
    label: 'Royal Academy of Music, University of London',
    value: 'Royal Academy of Music, University of London'
  },
  {
    id: '5868',
    label: 'Royal College of Art',
    value: 'Royal College of Art'
  },
  {
    id: '5869',
    label: 'Royal College of Music, University of London',
    value: 'Royal College of Music, University of London'
  },
  {
    id: '5870',
    label: 'Royal College of Physicians of Ireland',
    value: 'Royal College of Physicians of Ireland'
  },
  {
    id: '5871',
    label: 'Royal College of Surgeons',
    value: 'Royal College of Surgeons'
  },
  {
    id: '5872',
    label: 'Royal Danish Academy of Fine Arts, School of Architecture',
    value: 'Royal Danish Academy of Fine Arts, School of Architecture'
  },
  {
    id: '5873',
    label: 'Royal Danish Academy of Fine Arts, School of Visual Art',
    value: 'Royal Danish Academy of Fine Arts, School of Visual Art'
  },
  {
    id: '5874',
    label: 'Royal Danish Academy of Music',
    value: 'Royal Danish Academy of Music'
  },
  {
    id: '5875',
    label: 'Royal Danish School of Educational Sciences',
    value: 'Royal Danish School of Educational Sciences'
  },
  {
    id: '5876',
    label: 'Royal Danish School of Pharmacy',
    value: 'Royal Danish School of Pharmacy'
  },
  {
    id: '5877',
    label: 'Royal Free Hospital School of Medicine, University of London',
    value: 'Royal Free Hospital School of Medicine, University of London'
  },
  {
    id: '5878',
    label: 'Royal Holloway University of London',
    value: 'Royal Holloway University of London'
  },
  {
    id: '5879',
    label: 'Royal Holloway and Bedford New College',
    value: 'Royal Holloway and Bedford New College'
  },
  {
    id: '5880',
    label: 'Royal Melbourne Institute of Technology',
    value: 'Royal Melbourne Institute of Technology'
  },
  {
    id: '5881',
    label: 'Royal Military Academy',
    value: 'Royal Military Academy'
  },
  {
    id: '5882',
    label: 'Royal Military College of Canada',
    value: 'Royal Military College of Canada'
  },
  {
    id: '5883',
    label: 'Royal Roads University',
    value: 'Royal Roads University'
  },
  {
    id: '5884',
    label: 'Royal School of Library and Information Science',
    value: 'Royal School of Library and Information Science'
  },
  {
    id: '5885',
    label: 'Royal University of Agriculture',
    value: 'Royal University of Agriculture'
  },
  {
    id: '5886',
    label: 'Royal University of Bhutan',
    value: 'Royal University of Bhutan'
  },
  {
    id: '5887',
    label: 'Royal University of Fine Arts',
    value: 'Royal University of Fine Arts'
  },
  {
    id: '5888',
    label: 'Royal University of Law and Economics',
    value: 'Royal University of Law and Economics'
  },
  {
    id: '5889',
    label: 'Royal University of Phnom Penh',
    value: 'Royal University of Phnom Penh'
  },
  {
    id: '5890',
    label: 'Royal Veterinary and Agricultural University',
    value: 'Royal Veterinary and Agricultural University'
  },
  {
    id: '5891',
    label: 'Ruhr-Universität Bochum',
    value: 'Ruhr-Universität Bochum'
  },
  {
    id: '5892',
    label: 'Runshaw College',
    value: 'Runshaw College'
  },
  {
    id: '5893',
    label: 'Ruprecht-Karls-Universität Heidelberg',
    value: 'Ruprecht-Karls-Universität Heidelberg'
  },
  {
    id: '5894',
    label: 'Rusangu University',
    value: 'Rusangu University'
  },
  {
    id: '5895',
    label: 'Russell Berrie Nanotechnology Institute',
    value: 'Russell Berrie Nanotechnology Institute'
  },
  {
    id: '5896',
    label: 'Russian Academy of Arts',
    value: 'Russian Academy of Arts'
  },
  {
    id: '5897',
    label: 'Russian Academy of Theatre Arts',
    value: 'Russian Academy of Theatre Arts'
  },
  {
    id: '5898',
    label: 'Russian Customs Academy, Vladivostok Branch',
    value: 'Russian Customs Academy, Vladivostok Branch'
  },
  {
    id: '5899',
    label: 'Russian State Geological Prospecting University',
    value: 'Russian State Geological Prospecting University'
  },
  {
    id: '5900',
    label: 'Russian State Hydrometeorological University',
    value: 'Russian State Hydrometeorological University'
  },
  {
    id: '5901',
    label: 'Russian State Medical University',
    value: 'Russian State Medical University'
  },
  {
    id: '5902',
    label: 'Russian State University for the Humanities',
    value: 'Russian State University for the Humanities'
  },
  {
    id: '5903',
    label: 'Russian-Armenian (Slavonic) State University',
    value: 'Russian-Armenian (Slavonic) State University'
  },
  {
    id: '5904',
    label: 'Rust College',
    value: 'Rust College'
  },
  {
    id: '5905',
    label: 'Rutgers University',
    value: 'Rutgers University'
  },
  {
    id: '5906',
    label: 'Ryazan State Pedagogical University',
    value: 'Ryazan State Pedagogical University'
  },
  {
    id: '5907',
    label: 'Rybinsk State Academy of Aviational Technology',
    value: 'Rybinsk State Academy of Aviational Technology'
  },
  {
    id: '5908',
    label: 'Ryerson Polytechnic University',
    value: 'Ryerson Polytechnic University'
  },
  {
    id: '5909',
    label: 'Ryszard Lazarski University of Commerce and Law in Warsaw',
    value: 'Ryszard Lazarski University of Commerce and Law in Warsaw'
  },
  {
    id: '5910',
    label: 'Ryukoku University',
    value: 'Ryukoku University'
  },
  {
    id: '5911',
    label: 'Ryutsu Keizai University',
    value: 'Ryutsu Keizai University'
  },
  {
    id: '5912',
    label: 'SA Engineering College',
    value: 'SA Engineering College'
  },
  {
    id: '5913',
    label: 'SANS Technology Institute',
    value: 'SANS Technology Institute'
  },
  {
    id: '5914',
    label: 'SASTRA University',
    value: 'SASTRA University'
  },
  {
    id: '5915',
    label: 'SKEMA Business School',
    value: 'SKEMA Business School'
  },
  {
    id: '5916',
    label: 'SMK Fomra Institute of Technology',
    value: 'SMK Fomra Institute of Technology'
  },
  {
    id: '5917',
    label: 'SOWELA Technical Community College',
    value: 'SOWELA Technical Community College'
  },
  {
    id: '5918',
    label: 'SP Jain School of Global Management',
    value: 'SP Jain School of Global Management'
  },
  {
    id: '5919',
    label: 'SRH University of Applied Sciences',
    value: 'SRH University of Applied Sciences'
  },
  {
    id: '5920',
    label: 'SRM Institute Of Science & Technology',
    value: 'SRM Institute Of Science & Technology'
  },
  {
    id: '5921',
    label: 'SRM University',
    value: 'SRM University'
  },
  {
    id: '5922',
    label: 'STIE Perbanas',
    value: 'STIE Perbanas'
  },
  {
    id: '5923',
    label: 'STIKES Bina Sehat PPNI Mojokerto',
    value: 'STIKES Bina Sehat PPNI Mojokerto'
  },
  {
    id: '5924',
    label: 'STIKES RS Anwar Medika',
    value: 'STIKES RS Anwar Medika'
  },
  {
    id: '5925',
    label: 'STMIK AMIKOM Yogyakarta',
    value: 'STMIK AMIKOM Yogyakarta'
  },
  {
    id: '5926',
    label: 'STMIK Sinar Nusantara',
    value: 'STMIK Sinar Nusantara'
  },
  {
    id: '5927',
    label: 'STMIK Widya Cipta Dharma',
    value: 'STMIK Widya Cipta Dharma'
  },
  {
    id: '5928',
    label: 'SUNY Broome Community College',
    value: 'SUNY Broome Community College'
  },
  {
    id: '5929',
    label: 'SUNY Maritime College',
    value: 'SUNY Maritime College'
  },
  {
    id: '5930',
    label: 'SUNY Westchester Community College',
    value: 'SUNY Westchester Community College'
  },
  {
    id: '5931',
    label: 'SUPSI - University of Applied Sciences Southern Switzerland',
    value: 'SUPSI - University of Applied Sciences Southern Switzerland'
  },
  {
    id: '5932',
    label: 'Saad College of Nursing and Allied Health Sciences',
    value: 'Saad College of Nursing and Allied Health Sciences'
  },
  {
    id: '5933',
    label: 'Saba University',
    value: 'Saba University'
  },
  {
    id: '5934',
    label: 'Sabanci University',
    value: 'Sabanci University'
  },
  {
    id: '5935',
    label: 'Sabaragamuwa University of Sri Lanka',
    value: 'Sabaragamuwa University of Sri Lanka'
  },
  {
    id: '5936',
    label: 'Sabzevar School of Medical Sciences',
    value: 'Sabzevar School of Medical Sciences'
  },
  {
    id: '5937',
    label: 'Sabzevar Teacher Training University',
    value: 'Sabzevar Teacher Training University'
  },
  {
    id: '5938',
    label: 'Sacramento City College',
    value: 'Sacramento City College'
  },
  {
    id: '5939',
    label: 'Sacred Heart University',
    value: 'Sacred Heart University'
  },
  {
    id: '5940',
    label: 'Sadat Academy for Management Sciences',
    value: 'Sadat Academy for Management Sciences'
  },
  {
    id: '5941',
    label: 'Sadat Institute of Higher Education',
    value: 'Sadat Institute of Higher Education'
  },
  {
    id: '5942',
    label: 'Saddleback College',
    value: 'Saddleback College'
  },
  {
    id: '5943',
    label: 'Sadjad Institute of Technology',
    value: 'Sadjad Institute of Technology'
  },
  {
    id: '5944',
    label: 'Sadra University',
    value: 'Sadra University'
  },
  {
    id: '5945',
    label: 'Saga Medical School',
    value: 'Saga Medical School'
  },
  {
    id: '5946',
    label: 'Saga University',
    value: 'Saga University'
  },
  {
    id: '5947',
    label: "Sagami Women's University",
    value: "Sagami Women's University"
  },
  {
    id: '5948',
    label: 'Sage Colleges',
    value: 'Sage Colleges'
  },
  {
    id: '5949',
    label: 'Saginaw Chippewa Tribal College',
    value: 'Saginaw Chippewa Tribal College'
  },
  {
    id: '5950',
    label: 'Saginaw Valley State University',
    value: 'Saginaw Valley State University'
  },
  {
    id: '5951',
    label: 'Sahand University of Technology',
    value: 'Sahand University of Technology'
  },
  {
    id: '5952',
    label: 'Sahmyook University',
    value: 'Sahmyook University'
  },
  {
    id: '5953',
    label: 'Saigon University',
    value: 'Saigon University'
  },
  {
    id: '5954',
    label: 'Saint Anselm College',
    value: 'Saint Anselm College'
  },
  {
    id: '5955',
    label: 'Saint Cloud State University',
    value: 'Saint Cloud State University'
  },
  {
    id: '5956',
    label: "Saint Edward's University",
    value: "Saint Edward's University"
  },
  {
    id: '5957',
    label: 'Saint Elizabeth College of Nursing',
    value: 'Saint Elizabeth College of Nursing'
  },
  {
    id: '5958',
    label: "Saint George's Hospital Medical School, University of London",
    value: "Saint George's Hospital Medical School, University of London"
  },
  {
    id: '5959',
    label: "Saint John's University (NY)",
    value: "Saint John's University (NY)"
  },
  {
    id: '5960',
    label: "Saint Joseph's College (IN)",
    value: "Saint Joseph's College (IN)"
  },
  {
    id: '5961',
    label: "Saint Joseph's University",
    value: "Saint Joseph's University"
  },
  {
    id: '5962',
    label: 'Saint Leo University',
    value: 'Saint Leo University'
  },
  {
    id: '5963',
    label: 'Saint Louis College',
    value: 'Saint Louis College'
  },
  {
    id: '5964',
    label: 'Saint Louis University',
    value: 'Saint Louis University'
  },
  {
    id: '5965',
    label: 'Saint Louis University',
    value: 'Saint Louis University'
  },
  {
    id: '5966',
    label: 'Saint Mary College',
    value: 'Saint Mary College'
  },
  {
    id: '5967',
    label: "Saint Mary's College (IN)",
    value: "Saint Mary's College (IN)"
  },
  {
    id: '5968',
    label: "Saint Mary's College of California",
    value: "Saint Mary's College of California"
  },
  {
    id: '5969',
    label: "Saint Mary's University",
    value: "Saint Mary's University"
  },
  {
    id: '5970',
    label: "Saint Mary's University of Minnesota",
    value: "Saint Mary's University of Minnesota"
  },
  {
    id: '5971',
    label: "Saint Michael's College",
    value: "Saint Michael's College"
  },
  {
    id: '5972',
    label: 'Saint Olaf College',
    value: 'Saint Olaf College'
  },
  {
    id: '5973',
    label: 'Saint Paul College',
    value: 'Saint Paul College'
  },
  {
    id: '5974',
    label: 'Saint Paul University',
    value: 'Saint Paul University'
  },
  {
    id: '5975',
    label: 'Saint Vincent College',
    value: 'Saint Vincent College'
  },
  {
    id: '5976',
    label: 'Saint Xavier University',
    value: 'Saint Xavier University'
  },
  {
    id: '5977',
    label: 'Saitama Institute of Technology',
    value: 'Saitama Institute of Technology'
  },
  {
    id: '5978',
    label: 'Saitama Medical School',
    value: 'Saitama Medical School'
  },
  {
    id: '5979',
    label: 'Saitama Prefectural University',
    value: 'Saitama Prefectural University'
  },
  {
    id: '5980',
    label: 'Saitama University',
    value: 'Saitama University'
  },
  {
    id: '5981',
    label: 'Saito College',
    value: 'Saito College'
  },
  {
    id: '5982',
    label: 'Sakarya University',
    value: 'Sakarya University'
  },
  {
    id: '5983',
    label: 'Sakhalin State University',
    value: 'Sakhalin State University'
  },
  {
    id: '5984',
    label: 'Sakushin Gakuin University',
    value: 'Sakushin Gakuin University'
  },
  {
    id: '5985',
    label: 'Salahddin University (Kurdistan Region)',
    value: 'Salahddin University (Kurdistan Region)'
  },
  {
    id: '5986',
    label: 'Salam University',
    value: 'Salam University'
  },
  {
    id: '5987',
    label: 'Salem Community College',
    value: 'Salem Community College'
  },
  {
    id: '5988',
    label: 'Salem University',
    value: 'Salem University'
  },
  {
    id: '5989',
    label: 'Salisbury University',
    value: 'Salisbury University'
  },
  {
    id: '5990',
    label: 'Salish Kootenai College',
    value: 'Salish Kootenai College'
  },
  {
    id: '5991',
    label: 'Salt Lake Community College',
    value: 'Salt Lake Community College'
  },
  {
    id: '5992',
    label: 'Sam Houston State University',
    value: 'Sam Houston State University'
  },
  {
    id: '5993',
    label: 'Samar State University',
    value: 'Samar State University'
  },
  {
    id: '5994',
    label: 'Samara State Academy for Railway Transportation',
    value: 'Samara State Academy for Railway Transportation'
  },
  {
    id: '5995',
    label: 'Samara State Academy of Architecture and Civil Engineering',
    value: 'Samara State Academy of Architecture and Civil Engineering'
  },
  {
    id: '5996',
    label: 'Samara State Aerospace University',
    value: 'Samara State Aerospace University'
  },
  {
    id: '5997',
    label: 'Samara State Agricultural Academy',
    value: 'Samara State Agricultural Academy'
  },
  {
    id: '5998',
    label: 'Samara State Medical University',
    value: 'Samara State Medical University'
  },
  {
    id: '5999',
    label: 'Samara State Technical University',
    value: 'Samara State Technical University'
  },
  {
    id: '6000',
    label: 'Samara State University',
    value: 'Samara State University'
  },
  {
    id: '6001',
    label: 'Samara State University of Economics',
    value: 'Samara State University of Economics'
  },
  {
    id: '6002',
    label: 'Samara State University of Teacher Training',
    value: 'Samara State University of Teacher Training'
  },
  {
    id: '6003',
    label: 'Samarkand State University',
    value: 'Samarkand State University'
  },
  {
    id: '6004',
    label: 'Sambalpur University',
    value: 'Sambalpur University'
  },
  {
    id: '6005',
    label: 'Samchok National University',
    value: 'Samchok National University'
  },
  {
    id: '6006',
    label: 'Samford University',
    value: 'Samford University'
  },
  {
    id: '6007',
    label: 'Sami Shamoon College of Engineering',
    value: 'Sami Shamoon College of Engineering'
  },
  {
    id: '6008',
    label: 'Sampson Community College',
    value: 'Sampson Community College'
  },
  {
    id: '6009',
    label: 'Sampurnanand Sanskrit University',
    value: 'Sampurnanand Sanskrit University'
  },
  {
    id: '6010',
    label: 'San Beda College',
    value: 'San Beda College'
  },
  {
    id: '6011',
    label: 'San Bernardino Valley College',
    value: 'San Bernardino Valley College'
  },
  {
    id: '6012',
    label: 'San Diego Christian College',
    value: 'San Diego Christian College'
  },
  {
    id: '6013',
    label: 'San Diego City College',
    value: 'San Diego City College'
  },
  {
    id: '6014',
    label: 'San Diego Mesa College',
    value: 'San Diego Mesa College'
  },
  {
    id: '6015',
    label: 'San Diego Miramar College',
    value: 'San Diego Miramar College'
  },
  {
    id: '6016',
    label: 'San Diego State University',
    value: 'San Diego State University'
  },
  {
    id: '6017',
    label: 'San Francisco State University',
    value: 'San Francisco State University'
  },
  {
    id: '6018',
    label: 'San Joaquin Delta College',
    value: 'San Joaquin Delta College'
  },
  {
    id: '6019',
    label: 'San Joaquin Valley College-Visalia',
    value: 'San Joaquin Valley College-Visalia'
  },
  {
    id: '6020',
    label: 'San Jose City College',
    value: 'San Jose City College'
  },
  {
    id: '6021',
    label: 'San Jose State University',
    value: 'San Jose State University'
  },
  {
    id: '6022',
    label: 'San Juan Bautista School of Medicine',
    value: 'San Juan Bautista School of Medicine'
  },
  {
    id: '6023',
    label: 'San Juan College',
    value: 'San Juan College'
  },
  {
    id: '6024',
    label: 'San Mateo County Community College District',
    value: 'San Mateo County Community College District'
  },
  {
    id: '6025',
    label: "Sana'a University",
    value: "Sana'a University"
  },
  {
    id: '6026',
    label: 'Sanaag University of Science and Technology',
    value: 'Sanaag University of Science and Technology'
  },
  {
    id: '6027',
    label: 'Sandhills Community College',
    value: 'Sandhills Community College'
  },
  {
    id: '6028',
    label: 'Sang Ji University',
    value: 'Sang Ji University'
  },
  {
    id: '6029',
    label: 'Sang Myung University',
    value: 'Sang Myung University'
  },
  {
    id: '6030',
    label: 'Sangmyung University',
    value: 'Sangmyung University'
  },
  {
    id: '6031',
    label: 'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences',
    value: 'Sanjay Gandhi Postgraduate lnstitute of Medical Sciences'
  },
  {
    id: '6032',
    label: 'Sankei University',
    value: 'Sankei University'
  },
  {
    id: '6033',
    label: 'Sanko University',
    value: 'Sanko University'
  },
  {
    id: '6034',
    label: 'Sanno University',
    value: 'Sanno University'
  },
  {
    id: '6035',
    label: 'Santa Ana College',
    value: 'Santa Ana College'
  },
  {
    id: '6036',
    label: 'Santa Barbara City College',
    value: 'Santa Barbara City College'
  },
  {
    id: '6037',
    label: 'Santa Clara University',
    value: 'Santa Clara University'
  },
  {
    id: '6038',
    label: 'Santa Fe Community College',
    value: 'Santa Fe Community College'
  },
  {
    id: '6039',
    label: 'Santa Monica College',
    value: 'Santa Monica College'
  },
  {
    id: '6040',
    label: 'Santa Rosa Junior College',
    value: 'Santa Rosa Junior College'
  },
  {
    id: '6041',
    label: 'Santiago Canyon College',
    value: 'Santiago Canyon College'
  },
  {
    id: '6042',
    label: 'Sanyo Gakuen University',
    value: 'Sanyo Gakuen University'
  },
  {
    id: '6043',
    label: 'Sapporo Gakuin University',
    value: 'Sapporo Gakuin University'
  },
  {
    id: '6044',
    label: 'Sapporo International University',
    value: 'Sapporo International University'
  },
  {
    id: '6045',
    label: 'Sapporo Medical University',
    value: 'Sapporo Medical University'
  },
  {
    id: '6046',
    label: 'Sapporo University',
    value: 'Sapporo University'
  },
  {
    id: '6047',
    label: 'Sarah Lawrence College',
    value: 'Sarah Lawrence College'
  },
  {
    id: '6048',
    label: 'Sarajevo Film Academy',
    value: 'Sarajevo Film Academy'
  },
  {
    id: '6049',
    label: 'Sarajevo School of Science and Technology',
    value: 'Sarajevo School of Science and Technology'
  },
  {
    id: '6050',
    label: 'Saratov State Academy of Law',
    value: 'Saratov State Academy of Law'
  },
  {
    id: '6051',
    label: 'Saratov State Agrarian University',
    value: 'Saratov State Agrarian University'
  },
  {
    id: '6052',
    label: 'Saratov State Medical University',
    value: 'Saratov State Medical University'
  },
  {
    id: '6053',
    label: 'Saratov State Socio-Economic University',
    value: 'Saratov State Socio-Economic University'
  },
  {
    id: '6054',
    label: 'Saratov State Technical University',
    value: 'Saratov State Technical University'
  },
  {
    id: '6055',
    label: 'Saratov State University',
    value: 'Saratov State University'
  },
  {
    id: '6056',
    label: 'Sardar Patel University',
    value: 'Sardar Patel University'
  },
  {
    id: '6057',
    label: 'Sardar Vallabhai Patel University of Agriculture Amd Technology',
    value: 'Sardar Vallabhai Patel University of Agriculture Amd Technology'
  },
  {
    id: '6058',
    label: 'Sardar Vallabhbhai National Institute of Technology',
    value: 'Sardar Vallabhbhai National Institute of Technology'
  },
  {
    id: '6059',
    label: 'Sardarkrushinagar Dantiwada Agricultural University',
    value: 'Sardarkrushinagar Dantiwada Agricultural University'
  },
  {
    id: '6060',
    label: 'Sarhad University of Science & Information Technology, Peshawar',
    value: 'Sarhad University of Science & Information Technology, Peshawar'
  },
  {
    id: '6061',
    label: 'Saskatchewan Indian Federated College',
    value: 'Saskatchewan Indian Federated College'
  },
  {
    id: '6062',
    label: 'Satakunta University Of Applied Scinces',
    value: 'Satakunta University Of Applied Scinces'
  },
  {
    id: '6063',
    label: 'Sathyabama University',
    value: 'Sathyabama University'
  },
  {
    id: '6064',
    label: 'Sauder School of Business',
    value: 'Sauder School of Business'
  },
  {
    id: '6065',
    label: 'Sauk Valley Community College',
    value: 'Sauk Valley Community College'
  },
  {
    id: '6066',
    label: 'Saurashtra University',
    value: 'Saurashtra University'
  },
  {
    id: '6067',
    label: 'Savannah College of Art and Design',
    value: 'Savannah College of Art and Design'
  },
  {
    id: '6068',
    label: 'Savannah State University',
    value: 'Savannah State University'
  },
  {
    id: '6069',
    label: 'Savannah Technical College',
    value: 'Savannah Technical College'
  },
  {
    id: '6070',
    label: 'Saveetha Engineering College',
    value: 'Saveetha Engineering College'
  },
  {
    id: '6071',
    label: 'Saveetha School of Engineering',
    value: 'Saveetha School of Engineering'
  },
  {
    id: '6072',
    label: 'Saveetha University',
    value: 'Saveetha University'
  },
  {
    id: '6073',
    label: 'Saxion Universities',
    value: 'Saxion Universities'
  },
  {
    id: '6074',
    label: 'Scandinavian Art and Business Institute',
    value: 'Scandinavian Art and Business Institute'
  },
  {
    id: '6075',
    label: 'Schenectady County Community College',
    value: 'Schenectady County Community College'
  },
  {
    id: '6076',
    label: 'Schiller International University',
    value: 'Schiller International University'
  },
  {
    id: '6077',
    label: 'Schiller International University, American College of Switzerland',
    value: 'Schiller International University, American College of Switzerland'
  },
  {
    id: '6078',
    label: 'Schiller International University, Heidelberg',
    value: 'Schiller International University, Heidelberg'
  },
  {
    id: '6079',
    label: 'Schiller International University, London',
    value: 'Schiller International University, London'
  },
  {
    id: '6080',
    label: 'Schiller International University, Madrid',
    value: 'Schiller International University, Madrid'
  },
  {
    id: '6081',
    label: 'Schiller International University, Paris',
    value: 'Schiller International University, Paris'
  },
  {
    id: '6082',
    label: 'Schiller International University, Strasbourg',
    value: 'Schiller International University, Strasbourg'
  },
  {
    id: '6083',
    label: 'Scholars International Academy',
    value: 'Scholars International Academy'
  },
  {
    id: '6084',
    label: 'School of Advanced Study, University of London',
    value: 'School of Advanced Study, University of London'
  },
  {
    id: '6085',
    label: 'School of Banking and Management in Cracow',
    value: 'School of Banking and Management in Cracow'
  },
  {
    id: '6086',
    label: 'School of Business and Finance',
    value: 'School of Business and Finance'
  },
  {
    id: '6087',
    label: 'School of Design and Crafts',
    value: 'School of Design and Crafts'
  },
  {
    id: '6088',
    label: 'School of Economic Sciences',
    value: 'School of Economic Sciences'
  },
  {
    id: '6089',
    label: 'School of Education Pisa',
    value: 'School of Education Pisa'
  },
  {
    id: '6090',
    label: 'School of Finance and Banking',
    value: 'School of Finance and Banking'
  },
  {
    id: '6091',
    label: 'School of Management',
    value: 'School of Management'
  },
  {
    id: '6092',
    label: 'School of Oriental and African Studies, University of London',
    value: 'School of Oriental and African Studies, University of London'
  },
  {
    id: '6093',
    label: 'School of Pedagogical and Technological Education - A.S.PAI.T.E.',
    value: 'School of Pedagogical and Technological Education - A.S.PAI.T.E.'
  },
  {
    id: '6094',
    label: 'School of Pharmacy, University of London',
    value: 'School of Pharmacy, University of London'
  },
  {
    id: '6095',
    label: 'School of Planning and Architecture',
    value: 'School of Planning and Architecture'
  },
  {
    id: '6096',
    label: 'School of Slavonic and East European Studies, University of London',
    value: 'School of Slavonic and East European Studies, University of London'
  },
  {
    id: '6097',
    label: 'School of the Art Institute of Chicago',
    value: 'School of the Art Institute of Chicago'
  },
  {
    id: '6098',
    label: 'Schoolcraft College',
    value: 'Schoolcraft College'
  },
  {
    id: '6099',
    label: 'Schreiner University',
    value: 'Schreiner University'
  },
  {
    id: '6100',
    label: 'Science University of Tokyo',
    value: 'Science University of Tokyo'
  },
  {
    id: '6101',
    label: 'Science University of Tokyo in Yamaguchi',
    value: 'Science University of Tokyo in Yamaguchi'
  },
  {
    id: '6102',
    label: 'Scott Christian University',
    value: 'Scott Christian University'
  },
  {
    id: '6103',
    label: 'Scottsdale Community College',
    value: 'Scottsdale Community College'
  },
  {
    id: '6104',
    label: 'Seattle Central College',
    value: 'Seattle Central College'
  },
  {
    id: '6105',
    label: 'Seattle Colleges',
    value: 'Seattle Colleges'
  },
  {
    id: '6106',
    label: 'Seattle Pacific University',
    value: 'Seattle Pacific University'
  },
  {
    id: '6107',
    label: 'Seattle University',
    value: 'Seattle University'
  },
  {
    id: '6108',
    label: 'Sebatian Kolowa University College',
    value: 'Sebatian Kolowa University College'
  },
  {
    id: '6109',
    label: 'Seberang Perai Polytechnic',
    value: 'Seberang Perai Polytechnic'
  },
  {
    id: '6110',
    label: 'Sebha University',
    value: 'Sebha University'
  },
  {
    id: '6111',
    label: 'Second University of Naples',
    value: 'Second University of Naples'
  },
  {
    id: '6112',
    label: 'Sefako Makgatho Health Sciences University',
    value: 'Sefako Makgatho Health Sciences University'
  },
  {
    id: '6113',
    label: 'Segi University College',
    value: 'Segi University College'
  },
  {
    id: '6114',
    label: 'Sehan University',
    value: 'Sehan University'
  },
  {
    id: '6115',
    label: 'Seian University of Art & Design',
    value: 'Seian University of Art & Design'
  },
  {
    id: '6116',
    label: 'Seigakuin University',
    value: 'Seigakuin University'
  },
  {
    id: '6117',
    label: 'Seijo University',
    value: 'Seijo University'
  },
  {
    id: '6118',
    label: 'Seikei University',
    value: 'Seikei University'
  },
  {
    id: '6119',
    label: 'Seinan Gakuin University',
    value: 'Seinan Gakuin University'
  },
  {
    id: '6120',
    label: 'Seisen University',
    value: 'Seisen University'
  },
  {
    id: '6121',
    label: 'Seiwa College',
    value: 'Seiwa College'
  },
  {
    id: '6122',
    label: 'Sejong University',
    value: 'Sejong University'
  },
  {
    id: '6123',
    label: 'Sekolah Tinggi Akuntansi Negara (STAN)',
    value: 'Sekolah Tinggi Akuntansi Negara (STAN)'
  },
  {
    id: '6124',
    label: 'Selangor Islamic University College',
    value: 'Selangor Islamic University College'
  },
  {
    id: '6125',
    label: 'Selcuk University',
    value: 'Selcuk University'
  },
  {
    id: '6126',
    label: 'Selkirk College',
    value: 'Selkirk College'
  },
  {
    id: '6127',
    label: 'Selma University',
    value: 'Selma University'
  },
  {
    id: '6128',
    label: 'Semera University',
    value: 'Semera University'
  },
  {
    id: '6129',
    label: 'Semey State Medical University',
    value: 'Semey State Medical University'
  },
  {
    id: '6130',
    label: 'Semey State University',
    value: 'Semey State University'
  },
  {
    id: '6131',
    label: 'Seminole State College',
    value: 'Seminole State College'
  },
  {
    id: '6132',
    label: 'Semmelweis University of Medical Sciences',
    value: 'Semmelweis University of Medical Sciences'
  },
  {
    id: '6133',
    label: 'Semnan University',
    value: 'Semnan University'
  },
  {
    id: '6134',
    label: 'Semnan University of Medical Sciences',
    value: 'Semnan University of Medical Sciences'
  },
  {
    id: '6135',
    label: 'Semyung University',
    value: 'Semyung University'
  },
  {
    id: '6136',
    label: 'Sendai University',
    value: 'Sendai University'
  },
  {
    id: '6137',
    label: 'Seneca College',
    value: 'Seneca College'
  },
  {
    id: '6138',
    label: 'Senshu University',
    value: 'Senshu University'
  },
  {
    id: '6139',
    label: 'Senzoku Gakuen College',
    value: 'Senzoku Gakuen College'
  },
  {
    id: '6140',
    label: 'Seokyeong University',
    value: 'Seokyeong University'
  },
  {
    id: '6141',
    label: 'Seonam University',
    value: 'Seonam University'
  },
  {
    id: '6142',
    label: 'Seoul Christian University',
    value: 'Seoul Christian University'
  },
  {
    id: '6143',
    label: 'Seoul City University',
    value: 'Seoul City University'
  },
  {
    id: '6144',
    label: 'Seoul Hanyoung University',
    value: 'Seoul Hanyoung University'
  },
  {
    id: '6145',
    label: 'Seoul Jangsin University',
    value: 'Seoul Jangsin University'
  },
  {
    id: '6146',
    label: 'Seoul National University',
    value: 'Seoul National University'
  },
  {
    id: '6147',
    label: 'Seoul National University of Education',
    value: 'Seoul National University of Education'
  },
  {
    id: '6148',
    label: 'Seoul National University of Science and Technology',
    value: 'Seoul National University of Science and Technology'
  },
  {
    id: '6149',
    label: 'Seoul National University of Technology',
    value: 'Seoul National University of Technology'
  },
  {
    id: '6150',
    label: 'Seoul Teological University',
    value: 'Seoul Teological University'
  },
  {
    id: '6151',
    label: "Seoul Women's University",
    value: "Seoul Women's University"
  },
  {
    id: '6152',
    label: 'Seowon University',
    value: 'Seowon University'
  },
  {
    id: '6153',
    label: 'Seton Hall University',
    value: 'Seton Hall University'
  },
  {
    id: '6154',
    label: 'Setsunan University',
    value: 'Setsunan University'
  },
  {
    id: '6155',
    label: 'Sevastopol National Technical University',
    value: 'Sevastopol National Technical University'
  },
  {
    id: '6156',
    label: 'Sewanee, University of the South',
    value: 'Sewanee, University of the South'
  },
  {
    id: '6157',
    label: 'Seward County Community College and Area Technical School',
    value: 'Seward County Community College and Area Technical School'
  },
  {
    id: '6158',
    label: 'Shaanxi Normal University',
    value: 'Shaanxi Normal University'
  },
  {
    id: '6159',
    label: 'Shah Abdul Latif University Khairpur',
    value: 'Shah Abdul Latif University Khairpur'
  },
  {
    id: '6160',
    label: 'Shahed University',
    value: 'Shahed University'
  },
  {
    id: '6161',
    label: 'Shaheed Benazir Bhutto Women University',
    value: 'Shaheed Benazir Bhutto Women University'
  },
  {
    id: '6162',
    label: 'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology',
    value: 'Shaheed Zulifkar Ali Bhutto Institute of Science and Technology'
  },
  {
    id: '6163',
    label: 'Shahid Bahonar University of Kerman',
    value: 'Shahid Bahonar University of Kerman'
  },
  {
    id: '6164',
    label: 'Shahid Beheshti University',
    value: 'Shahid Beheshti University'
  },
  {
    id: '6165',
    label: 'Shahid Beheshti University of Medical Sciences',
    value: 'Shahid Beheshti University of Medical Sciences'
  },
  {
    id: '6166',
    label: 'Shahid Chamran University of Ahvaz',
    value: 'Shahid Chamran University of Ahvaz'
  },
  {
    id: '6167',
    label: 'Shahid Rajaee Teacher Training University',
    value: 'Shahid Rajaee Teacher Training University'
  },
  {
    id: '6168',
    label: 'Shahjalal University of Science and Technology',
    value: 'Shahjalal University of Science and Technology'
  },
  {
    id: '6169',
    label: 'Shahputra College',
    value: 'Shahputra College'
  },
  {
    id: '6170',
    label: 'Shahrekord University',
    value: 'Shahrekord University'
  },
  {
    id: '6171',
    label: 'Shahrekord University of Medical Sciences',
    value: 'Shahrekord University of Medical Sciences'
  },
  {
    id: '6172',
    label: 'Shahrood University of Medical Sciences',
    value: 'Shahrood University of Medical Sciences'
  },
  {
    id: '6173',
    label: 'Shahrood University of Technology',
    value: 'Shahrood University of Technology'
  },
  {
    id: '6174',
    label: 'Shaikh Zayed University',
    value: 'Shaikh Zayed University'
  },
  {
    id: '6175',
    label: 'Shandong Agricultural University',
    value: 'Shandong Agricultural University'
  },
  {
    id: '6176',
    label: 'Shandong Economic University',
    value: 'Shandong Economic University'
  },
  {
    id: '6177',
    label: 'Shandong Medical University',
    value: 'Shandong Medical University'
  },
  {
    id: '6178',
    label: 'Shandong Normal University',
    value: 'Shandong Normal University'
  },
  {
    id: '6179',
    label: 'Shandong University',
    value: 'Shandong University'
  },
  {
    id: '6180',
    label: 'Shandong University of Art and Design',
    value: 'Shandong University of Art and Design'
  },
  {
    id: '6181',
    label: 'Shandong University of Finance and Economics',
    value: 'Shandong University of Finance and Economics'
  },
  {
    id: '6182',
    label: 'Shandong University of Science and Technology',
    value: 'Shandong University of Science and Technology'
  },
  {
    id: '6183',
    label: 'Shandong University of Technology',
    value: 'Shandong University of Technology'
  },
  {
    id: '6184',
    label: 'Shandong University of Triaditional Chinese Medicine',
    value: 'Shandong University of Triaditional Chinese Medicine'
  },
  {
    id: '6185',
    label: 'Shanghai Business School',
    value: 'Shanghai Business School'
  },
  {
    id: '6186',
    label: 'Shanghai City College',
    value: 'Shanghai City College'
  },
  {
    id: '6187',
    label: 'Shanghai Customs College',
    value: 'Shanghai Customs College'
  },
  {
    id: '6188',
    label: 'Shanghai Dainji University',
    value: 'Shanghai Dainji University'
  },
  {
    id: '6189',
    label: 'Shanghai Fisheries University',
    value: 'Shanghai Fisheries University'
  },
  {
    id: '6190',
    label: 'Shanghai Institue of Foreign Trade',
    value: 'Shanghai Institue of Foreign Trade'
  },
  {
    id: '6191',
    label: 'Shanghai International Studies University',
    value: 'Shanghai International Studies University'
  },
  {
    id: '6192',
    label: 'Shanghai Jiaotong University',
    value: 'Shanghai Jiaotong University'
  },
  {
    id: '6193',
    label: 'Shanghai Lida Polytechnic Institute',
    value: 'Shanghai Lida Polytechnic Institute'
  },
  {
    id: '6194',
    label: 'Shanghai Lixin University of Commerce',
    value: 'Shanghai Lixin University of Commerce'
  },
  {
    id: '6195',
    label: 'Shanghai Maritime University',
    value: 'Shanghai Maritime University'
  },
  {
    id: '6196',
    label: 'Shanghai Medical University',
    value: 'Shanghai Medical University'
  },
  {
    id: '6197',
    label: 'Shanghai Normal University',
    value: 'Shanghai Normal University'
  },
  {
    id: '6198',
    label: 'Shanghai Ouhua Vocational Technical College',
    value: 'Shanghai Ouhua Vocational Technical College'
  },
  {
    id: '6199',
    label: 'Shanghai Sanda University',
    value: 'Shanghai Sanda University'
  },
  {
    id: '6200',
    label: 'Shanghai Second Medical University',
    value: 'Shanghai Second Medical University'
  },
  {
    id: '6201',
    label: 'Shanghai Second Polytechnic University',
    value: 'Shanghai Second Polytechnic University'
  },
  {
    id: '6202',
    label: 'Shanghai Sipo Polytechnic',
    value: 'Shanghai Sipo Polytechnic'
  },
  {
    id: '6203',
    label: 'Shanghai Television University',
    value: 'Shanghai Television University'
  },
  {
    id: '6204',
    label: 'Shanghai TieDao University',
    value: 'Shanghai TieDao University'
  },
  {
    id: '6205',
    label: 'Shanghai University',
    value: 'Shanghai University'
  },
  {
    id: '6206',
    label: 'Shanghai University of Engineering Science',
    value: 'Shanghai University of Engineering Science'
  },
  {
    id: '6207',
    label: 'Shanghai University of Finance and Economics',
    value: 'Shanghai University of Finance and Economics'
  },
  {
    id: '6208',
    label: 'Shanghai University of Science and Technology',
    value: 'Shanghai University of Science and Technology'
  },
  {
    id: '6209',
    label: 'Shanghai University of Traditional Chinese Medicine and Pharmacology',
    value: 'Shanghai University of Traditional Chinese Medicine and Pharmacology'
  },
  {
    id: '6210',
    label: 'ShanghaiTech University',
    value: 'ShanghaiTech University'
  },
  {
    id: '6211',
    label: 'Shannon College of Hotel Management',
    value: 'Shannon College of Hotel Management'
  },
  {
    id: '6212',
    label: 'Shantou University',
    value: 'Shantou University'
  },
  {
    id: '6213',
    label: 'Shanxi Agricultural University',
    value: 'Shanxi Agricultural University'
  },
  {
    id: '6214',
    label: 'Shanxi University',
    value: 'Shanxi University'
  },
  {
    id: '6215',
    label: 'Shaoguan University',
    value: 'Shaoguan University'
  },
  {
    id: '6216',
    label: 'Shaqra University',
    value: 'Shaqra University'
  },
  {
    id: '6217',
    label: 'Sharif University of Technology',
    value: 'Sharif University of Technology'
  },
  {
    id: '6218',
    label: 'Sharif University of Technology, Kish Campus',
    value: 'Sharif University of Technology, Kish Campus'
  },
  {
    id: '6219',
    label: 'Shasta College',
    value: 'Shasta College'
  },
  {
    id: '6220',
    label: 'Shaw University',
    value: 'Shaw University'
  },
  {
    id: '6221',
    label: 'Shawnee Community College',
    value: 'Shawnee Community College'
  },
  {
    id: '6222',
    label: 'Shawnee State University',
    value: 'Shawnee State University'
  },
  {
    id: '6223',
    label: 'Sheffield Hallam University',
    value: 'Sheffield Hallam University'
  },
  {
    id: '6224',
    label: 'Sheikh Bahaei University',
    value: 'Sheikh Bahaei University'
  },
  {
    id: '6225',
    label: 'Shelton State Community College',
    value: 'Shelton State Community College'
  },
  {
    id: '6226',
    label: 'Shemyakin–Ovchinnikov Institute of bioorganic chemistry RAS',
    value: 'Shemyakin–Ovchinnikov Institute of bioorganic chemistry RAS'
  },
  {
    id: '6227',
    label: 'Shenandoah University',
    value: 'Shenandoah University'
  },
  {
    id: '6228',
    label: 'Shendi University',
    value: 'Shendi University'
  },
  {
    id: '6229',
    label: 'Shenkar School of Engineering & Design',
    value: 'Shenkar School of Engineering & Design'
  },
  {
    id: '6230',
    label: 'Shenyang Agricultural University',
    value: 'Shenyang Agricultural University'
  },
  {
    id: '6231',
    label: 'Shenyang Institute of Chemical Technology',
    value: 'Shenyang Institute of Chemical Technology'
  },
  {
    id: '6232',
    label: 'Shenyang Jianzhu University',
    value: 'Shenyang Jianzhu University'
  },
  {
    id: '6233',
    label: 'Shenyang Pharmaceutical University',
    value: 'Shenyang Pharmaceutical University'
  },
  {
    id: '6234',
    label: 'Shenyang Polytechnic University',
    value: 'Shenyang Polytechnic University'
  },
  {
    id: '6235',
    label: 'Shenyang University',
    value: 'Shenyang University'
  },
  {
    id: '6236',
    label: 'Shenzhen University',
    value: 'Shenzhen University'
  },
  {
    id: '6237',
    label: 'Sher-E-Kashmir University of Agricultural Sciences and Technology',
    value: 'Sher-E-Kashmir University of Agricultural Sciences and Technology'
  },
  {
    id: '6238',
    label: 'Sheridan College',
    value: 'Sheridan College'
  },
  {
    id: '6239',
    label: 'Sheridan College',
    value: 'Sheridan College'
  },
  {
    id: '6240',
    label: 'Shibaura Institute of Technology',
    value: 'Shibaura Institute of Technology'
  },
  {
    id: '6241',
    label: 'Shiga Prefecture Agricultural Technology Promotion Center',
    value: 'Shiga Prefecture Agricultural Technology Promotion Center'
  },
  {
    id: '6242',
    label: 'Shiga University',
    value: 'Shiga University'
  },
  {
    id: '6243',
    label: 'Shiga University of Medical Science',
    value: 'Shiga University of Medical Science'
  },
  {
    id: '6244',
    label: 'Shih Chien University',
    value: 'Shih Chien University'
  },
  {
    id: '6245',
    label: 'Shih Hsin University',
    value: 'Shih Hsin University'
  },
  {
    id: '6246',
    label: 'Shihezi University',
    value: 'Shihezi University'
  },
  {
    id: '6247',
    label: 'Shikoku Christian College',
    value: 'Shikoku Christian College'
  },
  {
    id: '6248',
    label: 'Shikoku University',
    value: 'Shikoku University'
  },
  {
    id: '6249',
    label: 'Shimane University',
    value: 'Shimane University'
  },
  {
    id: '6250',
    label: 'Shimane University, Faculty of Medicine',
    value: 'Shimane University, Faculty of Medicine'
  },
  {
    id: '6251',
    label: 'Shimonoseki City University',
    value: 'Shimonoseki City University'
  },
  {
    id: '6252',
    label: 'Shinawatra University',
    value: 'Shinawatra University'
  },
  {
    id: '6253',
    label: 'Shingyeong University',
    value: 'Shingyeong University'
  },
  {
    id: '6254',
    label: 'Shinhan University',
    value: 'Shinhan University'
  },
  {
    id: '6255',
    label: 'Shinshu University',
    value: 'Shinshu University'
  },
  {
    id: '6256',
    label: 'Shippensburg University of Pennsylvania',
    value: 'Shippensburg University of Pennsylvania'
  },
  {
    id: '6257',
    label: "Shirayuri Women's College",
    value: "Shirayuri Women's College"
  },
  {
    id: '6258',
    label: 'Shiraz Payamnoor University',
    value: 'Shiraz Payamnoor University'
  },
  {
    id: '6259',
    label: 'Shiraz University',
    value: 'Shiraz University'
  },
  {
    id: '6260',
    label: 'Shiraz University of Medical Sciences',
    value: 'Shiraz University of Medical Sciences'
  },
  {
    id: '6261',
    label: 'Shiraz University of Technology',
    value: 'Shiraz University of Technology'
  },
  {
    id: '6262',
    label: 'Shivaji University',
    value: 'Shivaji University'
  },
  {
    id: '6263',
    label: 'Shizuoka Prefectural University',
    value: 'Shizuoka Prefectural University'
  },
  {
    id: '6264',
    label: 'Shizuoka Sangyo University',
    value: 'Shizuoka Sangyo University'
  },
  {
    id: '6265',
    label: 'Shizuoka University',
    value: 'Shizuoka University'
  },
  {
    id: '6266',
    label: 'Shobhit University, Meerut',
    value: 'Shobhit University, Meerut'
  },
  {
    id: '6267',
    label: 'Shokei College',
    value: 'Shokei College'
  },
  {
    id: '6268',
    label: 'Shomal University',
    value: 'Shomal University'
  },
  {
    id: '6269',
    label: 'Shonan Institute of Technology',
    value: 'Shonan Institute of Technology'
  },
  {
    id: '6270',
    label: 'Shoreline Community College',
    value: 'Shoreline Community College'
  },
  {
    id: '6271',
    label: 'Shorter College',
    value: 'Shorter College'
  },
  {
    id: '6272',
    label: 'Shoumen University "Konstantin Preslavski"',
    value: 'Shoumen University "Konstantin Preslavski"'
  },
  {
    id: '6273',
    label: 'Showa College of Pharmaceutical Sciences',
    value: 'Showa College of Pharmaceutical Sciences'
  },
  {
    id: '6274',
    label: 'Showa University',
    value: 'Showa University'
  },
  {
    id: '6275',
    label: "Showa Women's University",
    value: "Showa Women's University"
  },
  {
    id: '6276',
    label: "Shreemati Nathibai Damodar Thackersey Women's University",
    value: "Shreemati Nathibai Damodar Thackersey Women's University"
  },
  {
    id: '6277',
    label: 'Shri Jagannath Sanskrit University',
    value: 'Shri Jagannath Sanskrit University'
  },
  {
    id: '6278',
    label: 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha',
    value: 'Shri Lal Bahadur Shastri Rashtriya Sanskrit Vidyapeetha'
  },
  {
    id: '6279',
    label: 'Shridhar University',
    value: 'Shridhar University'
  },
  {
    id: '6280',
    label: 'Shuchiin College',
    value: 'Shuchiin College'
  },
  {
    id: '6281',
    label: "Shujitsu Women's University",
    value: "Shujitsu Women's University"
  },
  {
    id: '6282',
    label: 'Shukutoku University',
    value: 'Shukutoku University'
  },
  {
    id: '6283',
    label: 'Shumei University',
    value: 'Shumei University'
  },
  {
    id: '6284',
    label: 'Siam University',
    value: 'Siam University'
  },
  {
    id: '6285',
    label: 'Siauliai University',
    value: 'Siauliai University'
  },
  {
    id: '6286',
    label: 'Sibelius Academy',
    value: 'Sibelius Academy'
  },
  {
    id: '6287',
    label: 'Siberian Academy of Public Services',
    value: 'Siberian Academy of Public Services'
  },
  {
    id: '6288',
    label: 'Siberian State Industrial University',
    value: 'Siberian State Industrial University'
  },
  {
    id: '6289',
    label: 'Siberian State Medical University',
    value: 'Siberian State Medical University'
  },
  {
    id: '6290',
    label: 'Siberian State Transport University',
    value: 'Siberian State Transport University'
  },
  {
    id: '6291',
    label: 'Siberian State University of Telecommunications and Informatics',
    value: 'Siberian State University of Telecommunications and Informatics'
  },
  {
    id: '6292',
    label: 'Sichuan Agricultural University',
    value: 'Sichuan Agricultural University'
  },
  {
    id: '6293',
    label: 'Sichuan Fine Art Institute',
    value: 'Sichuan Fine Art Institute'
  },
  {
    id: '6294',
    label: 'Sichuan International Studies University',
    value: 'Sichuan International Studies University'
  },
  {
    id: '6295',
    label: 'Sichuan Normal University',
    value: 'Sichuan Normal University'
  },
  {
    id: '6296',
    label: 'Sichuan University',
    value: 'Sichuan University'
  },
  {
    id: '6297',
    label: 'Sido Kanhu Murmu University',
    value: 'Sido Kanhu Murmu University'
  },
  {
    id: '6298',
    label: 'Siebold University of Nagasaki',
    value: 'Siebold University of Nagasaki'
  },
  {
    id: '6299',
    label: 'Siena College',
    value: 'Siena College'
  },
  {
    id: '6300',
    label: 'Sierra College',
    value: 'Sierra College'
  },
  {
    id: '6301',
    label: 'Siirt University',
    value: 'Siirt University'
  },
  {
    id: '6302',
    label: "Sikha 'O' Anushandhan University",
    value: "Sikha 'O' Anushandhan University"
  },
  {
    id: '6303',
    label: 'Sikkim Manipal University of Health, Medical and Technological Sciences',
    value: 'Sikkim Manipal University of Health, Medical and Technological Sciences'
  },
  {
    id: '6304',
    label: 'Silesian Academy of Medicine in Katowice',
    value: 'Silesian Academy of Medicine in Katowice'
  },
  {
    id: '6305',
    label: 'Silesian School of Economics and Languages',
    value: 'Silesian School of Economics and Languages'
  },
  {
    id: '6306',
    label: 'Silesian Technical University of Gliwice',
    value: 'Silesian Technical University of Gliwice'
  },
  {
    id: '6307',
    label: 'Silesian University',
    value: 'Silesian University'
  },
  {
    id: '6308',
    label: 'Silla University',
    value: 'Silla University'
  },
  {
    id: '6309',
    label: 'Silliman University',
    value: 'Silliman University'
  },
  {
    id: '6310',
    label: 'Silpakorn University',
    value: 'Silpakorn University'
  },
  {
    id: '6311',
    label: 'Simmons College',
    value: 'Simmons College'
  },
  {
    id: '6312',
    label: 'Simmons College of Kentucky',
    value: 'Simmons College of Kentucky'
  },
  {
    id: '6313',
    label: 'Simon Fraser University',
    value: 'Simon Fraser University'
  },
  {
    id: '6314',
    label: "Simon's Rock College",
    value: "Simon's Rock College"
  },
  {
    id: '6315',
    label: 'Simpson College',
    value: 'Simpson College'
  },
  {
    id: '6316',
    label: 'Sinai University',
    value: 'Sinai University'
  },
  {
    id: '6317',
    label: 'Sinclair Community College',
    value: 'Sinclair Community College'
  },
  {
    id: '6318',
    label: 'Sindh Agricultural University',
    value: 'Sindh Agricultural University'
  },
  {
    id: '6319',
    label: 'Sindh Madressatul Islam University',
    value: 'Sindh Madressatul Islam University'
  },
  {
    id: '6320',
    label: 'Singapore Institute of Management (SIM)',
    value: 'Singapore Institute of Management (SIM)'
  },
  {
    id: '6321',
    label: 'Singapore Institute of Technology',
    value: 'Singapore Institute of Technology'
  },
  {
    id: '6322',
    label: 'Singapore Management University',
    value: 'Singapore Management University'
  },
  {
    id: '6323',
    label: 'Singapore Polytechnic',
    value: 'Singapore Polytechnic'
  },
  {
    id: '6324',
    label: 'Singapore University of Social Sciences',
    value: 'Singapore University of Social Sciences'
  },
  {
    id: '6325',
    label: 'Singapore University of Technology and Design',
    value: 'Singapore University of Technology and Design'
  },
  {
    id: '6326',
    label: 'Singidunum University',
    value: 'Singidunum University'
  },
  {
    id: '6327',
    label: 'Sinnar University',
    value: 'Sinnar University'
  },
  {
    id: '6328',
    label: 'Sinop University',
    value: 'Sinop University'
  },
  {
    id: '6329',
    label: 'Sir Syed Institute Of Technology Islamabad',
    value: 'Sir Syed Institute Of Technology Islamabad'
  },
  {
    id: '6330',
    label: 'Sir Syed University of Engineering and Technology',
    value: 'Sir Syed University of Engineering and Technology'
  },
  {
    id: '6331',
    label: 'Sirnak University',
    value: 'Sirnak University'
  },
  {
    id: '6332',
    label: 'Sirte University',
    value: 'Sirte University'
  },
  {
    id: '6333',
    label: 'Sisseton Wahpeton College',
    value: 'Sisseton Wahpeton College'
  },
  {
    id: '6334',
    label: 'Sistan and Baluchestan University',
    value: 'Sistan and Baluchestan University'
  },
  {
    id: '6335',
    label: 'Sistema Universitario Ana G. Méndez',
    value: 'Sistema Universitario Ana G. Méndez'
  },
  {
    id: '6336',
    label: 'Sister Nivedita University',
    value: 'Sister Nivedita University'
  },
  {
    id: '6337',
    label: 'Skagit Valley College',
    value: 'Skagit Valley College'
  },
  {
    id: '6338',
    label: 'Skidmore College',
    value: 'Skidmore College'
  },
  {
    id: '6339',
    label: 'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology',
    value: 'Skrjabin Moscow State Academy of Veterinary Medicine and Biotechnology'
  },
  {
    id: '6340',
    label: 'Skyline College',
    value: 'Skyline College'
  },
  {
    id: '6341',
    label: 'Skyline University College, Sharjah',
    value: 'Skyline University College, Sharjah'
  },
  {
    id: '6342',
    label: 'Slippery Rock University of Pennsylvania',
    value: 'Slippery Rock University of Pennsylvania'
  },
  {
    id: '6343',
    label: 'Slobomir P Univerzitet',
    value: 'Slobomir P Univerzitet'
  },
  {
    id: '6344',
    label: 'Slovak Medical University',
    value: 'Slovak Medical University'
  },
  {
    id: '6345',
    label: 'Slovak University of Agriculture in Nitra',
    value: 'Slovak University of Agriculture in Nitra'
  },
  {
    id: '6346',
    label: 'Slovak University of Technology in Bratislava',
    value: 'Slovak University of Technology in Bratislava'
  },
  {
    id: '6347',
    label: 'Smith College',
    value: 'Smith College'
  },
  {
    id: '6348',
    label: 'Smolensk Humanitarian University',
    value: 'Smolensk Humanitarian University'
  },
  {
    id: '6349',
    label: 'Smolensk State Medical Academy',
    value: 'Smolensk State Medical Academy'
  },
  {
    id: '6350',
    label: 'Smolny University',
    value: 'Smolny University'
  },
  {
    id: '6351',
    label: 'Snead State Community College',
    value: 'Snead State Community College'
  },
  {
    id: '6352',
    label: 'Snow College',
    value: 'Snow College'
  },
  {
    id: '6353',
    label: 'Soai University',
    value: 'Soai University'
  },
  {
    id: '6354',
    label: 'Sofia University "St. Kliment Ohridski"',
    value: 'Sofia University "St. Kliment Ohridski"'
  },
  {
    id: '6355',
    label: 'Sogang University',
    value: 'Sogang University'
  },
  {
    id: '6356',
    label: 'Sohag University',
    value: 'Sohag University'
  },
  {
    id: '6357',
    label: 'Sohar University',
    value: 'Sohar University'
  },
  {
    id: '6358',
    label: 'Soka University',
    value: 'Soka University'
  },
  {
    id: '6359',
    label: 'Sokoine University of Agriculture',
    value: 'Sokoine University of Agriculture'
  },
  {
    id: '6360',
    label: 'Sokoto State University',
    value: 'Sokoto State University'
  },
  {
    id: '6361',
    label: 'Sol Plaatje University',
    value: 'Sol Plaatje University'
  },
  {
    id: '6362',
    label: 'Solano Community College',
    value: 'Solano Community College'
  },
  {
    id: '6363',
    label: 'Solapur University',
    value: 'Solapur University'
  },
  {
    id: '6364',
    label: 'Solusi University',
    value: 'Solusi University'
  },
  {
    id: '6365',
    label: 'Solvay Brussels School Economics & Management',
    value: 'Solvay Brussels School Economics & Management'
  },
  {
    id: '6366',
    label: 'Somaiya Vidyavihar',
    value: 'Somaiya Vidyavihar'
  },
  {
    id: '6367',
    label: 'Somaliland University of Technology',
    value: 'Somaliland University of Technology'
  },
  {
    id: '6368',
    label: 'Somerset Community College',
    value: 'Somerset Community College'
  },
  {
    id: '6369',
    label: 'Songwon University',
    value: 'Songwon University'
  },
  {
    id: '6370',
    label: "Sonoda Women's University",
    value: "Sonoda Women's University"
  },
  {
    id: '6371',
    label: 'Sonoma State University',
    value: 'Sonoma State University'
  },
  {
    id: '6372',
    label: 'Soochow University',
    value: 'Soochow University'
  },
  {
    id: '6373',
    label: "Sookmyung Women's University",
    value: "Sookmyung Women's University"
  },
  {
    id: '6374',
    label: 'Soonchunhyang University',
    value: 'Soonchunhyang University'
  },
  {
    id: '6375',
    label: 'Soongsil University',
    value: 'Soongsil University'
  },
  {
    id: '6376',
    label: 'Sophia University',
    value: 'Sophia University'
  },
  {
    id: '6377',
    label: 'Soran University',
    value: 'Soran University'
  },
  {
    id: '6378',
    label: 'Sorbonne Université - Faculté des Sciences (Paris VI)',
    value: 'Sorbonne Université - Faculté des Sciences (Paris VI)'
  },
  {
    id: '6379',
    label: 'Sotheby´s Institute of Art - London',
    value: 'Sotheby´s Institute of Art - London'
  },
  {
    id: '6380',
    label: 'South Arkansas Community College',
    value: 'South Arkansas Community College'
  },
  {
    id: '6381',
    label: 'South Asian Institute of Technology And Medicine (SAITM)',
    value: 'South Asian Institute of Technology And Medicine (SAITM)'
  },
  {
    id: '6382',
    label: 'South Asian University',
    value: 'South Asian University'
  },
  {
    id: '6383',
    label: 'South Bank University',
    value: 'South Bank University'
  },
  {
    id: '6384',
    label: 'South Carelian Polytechnic',
    value: 'South Carelian Polytechnic'
  },
  {
    id: '6385',
    label: 'South Carolina State University',
    value: 'South Carolina State University'
  },
  {
    id: '6386',
    label: 'South Central College',
    value: 'South Central College'
  },
  {
    id: '6387',
    label: 'South China Agricultural University',
    value: 'South China Agricultural University'
  },
  {
    id: '6388',
    label: 'South China Construction University',
    value: 'South China Construction University'
  },
  {
    id: '6389',
    label: 'South China Normal University',
    value: 'South China Normal University'
  },
  {
    id: '6390',
    label: 'South China University',
    value: 'South China University'
  },
  {
    id: '6391',
    label: 'South China University of Technology',
    value: 'South China University of Technology'
  },
  {
    id: '6392',
    label: 'South Dakota School of Mines and Technology',
    value: 'South Dakota School of Mines and Technology'
  },
  {
    id: '6393',
    label: 'South Dakota State University',
    value: 'South Dakota State University'
  },
  {
    id: '6394',
    label: 'South East European University',
    value: 'South East European University'
  },
  {
    id: '6395',
    label: 'South Eastern Kenya University',
    value: 'South Eastern Kenya University'
  },
  {
    id: '6396',
    label: 'South Eastern University of Sri Lanka',
    value: 'South Eastern University of Sri Lanka'
  },
  {
    id: '6397',
    label: 'South Georgia Technical College',
    value: 'South Georgia Technical College'
  },
  {
    id: '6398',
    label: 'South Gujarat University',
    value: 'South Gujarat University'
  },
  {
    id: '6399',
    label: 'South Louisiana Community College',
    value: 'South Louisiana Community College'
  },
  {
    id: '6400',
    label: 'South Mountain Community College',
    value: 'South Mountain Community College'
  },
  {
    id: '6401',
    label: 'South Piedmont Community College',
    value: 'South Piedmont Community College'
  },
  {
    id: '6402',
    label: 'South Plains College',
    value: 'South Plains College'
  },
  {
    id: '6403',
    label: 'South Puget Sound Community College',
    value: 'South Puget Sound Community College'
  },
  {
    id: '6404',
    label: 'South Suburban College',
    value: 'South Suburban College'
  },
  {
    id: '6405',
    label: 'South Texas College of Law',
    value: 'South Texas College of Law'
  },
  {
    id: '6406',
    label: 'South University of Science and Technology of China',
    value: 'South University of Science and Technology of China'
  },
  {
    id: '6407',
    label: 'South Valley University',
    value: 'South Valley University'
  },
  {
    id: '6408',
    label: 'South West University Yucai College',
    value: 'South West University Yucai College'
  },
  {
    id: '6409',
    label: 'South-East Asia University',
    value: 'South-East Asia University'
  },
  {
    id: '6410',
    label: 'South-West University "Neofit Rilski"',
    value: 'South-West University "Neofit Rilski"'
  },
  {
    id: '6411',
    label: 'Southampton Solent University',
    value: 'Southampton Solent University'
  },
  {
    id: '6412',
    label: 'Southcentral Kentucky Community and Technical College',
    value: 'Southcentral Kentucky Community and Technical College'
  },
  {
    id: '6413',
    label: 'Southeast Arkansas College',
    value: 'Southeast Arkansas College'
  },
  {
    id: '6414',
    label: 'Southeast Community College Area',
    value: 'Southeast Community College Area'
  },
  {
    id: '6415',
    label: 'Southeast Kentucky Community and Technical College',
    value: 'Southeast Kentucky Community and Technical College'
  },
  {
    id: '6416',
    label: 'Southeast Missouri Hospital College of Nursing and Health Sciences',
    value: 'Southeast Missouri Hospital College of Nursing and Health Sciences'
  },
  {
    id: '6417',
    label: 'Southeast Missouri State University',
    value: 'Southeast Missouri State University'
  },
  {
    id: '6418',
    label: 'Southeast Technical Institute',
    value: 'Southeast Technical Institute'
  },
  {
    id: '6419',
    label: 'Southeast University',
    value: 'Southeast University'
  },
  {
    id: '6420',
    label: 'Southeast University',
    value: 'Southeast University'
  },
  {
    id: '6421',
    label: 'Southeastern Community College',
    value: 'Southeastern Community College'
  },
  {
    id: '6422',
    label: 'Southeastern Community College',
    value: 'Southeastern Community College'
  },
  {
    id: '6423',
    label: 'Southeastern Illinois College',
    value: 'Southeastern Illinois College'
  },
  {
    id: '6424',
    label: 'Southeastern Louisiana University',
    value: 'Southeastern Louisiana University'
  },
  {
    id: '6425',
    label: 'Southeastern Technical College',
    value: 'Southeastern Technical College'
  },
  {
    id: '6426',
    label: 'Southern Alberta Institute of Technology',
    value: 'Southern Alberta Institute of Technology'
  },
  {
    id: '6427',
    label: 'Southern Arkansas University Tech',
    value: 'Southern Arkansas University Tech'
  },
  {
    id: '6428',
    label: 'Southern College',
    value: 'Southern College'
  },
  {
    id: '6429',
    label: 'Southern College of Technology',
    value: 'Southern College of Technology'
  },
  {
    id: '6430',
    label: 'Southern Connecticut State University',
    value: 'Southern Connecticut State University'
  },
  {
    id: '6431',
    label: 'Southern Crescent Technical College',
    value: 'Southern Crescent Technical College'
  },
  {
    id: '6432',
    label: 'Southern Cross University',
    value: 'Southern Cross University'
  },
  {
    id: '6433',
    label: 'Southern Denmark Business School',
    value: 'Southern Denmark Business School'
  },
  {
    id: '6434',
    label: 'Southern Illinois University-Carbondale',
    value: 'Southern Illinois University-Carbondale'
  },
  {
    id: '6435',
    label: 'Southern Illinois University-Edwardsville',
    value: 'Southern Illinois University-Edwardsville'
  },
  {
    id: '6436',
    label: 'Southern Maine Community College',
    value: 'Southern Maine Community College'
  },
  {
    id: '6437',
    label: 'Southern Medial University',
    value: 'Southern Medial University'
  },
  {
    id: '6438',
    label: 'Southern Methodist University',
    value: 'Southern Methodist University'
  },
  {
    id: '6439',
    label: 'Southern Nazarene University',
    value: 'Southern Nazarene University'
  },
  {
    id: '6440',
    label: 'Southern New Hampshire University',
    value: 'Southern New Hampshire University'
  },
  {
    id: '6441',
    label: 'Southern Oregon State College',
    value: 'Southern Oregon State College'
  },
  {
    id: '6442',
    label: 'Southern State Community College',
    value: 'Southern State Community College'
  },
  {
    id: '6443',
    label: 'Southern Taiwan University of Technology',
    value: 'Southern Taiwan University of Technology'
  },
  {
    id: '6444',
    label: 'Southern Union State Community College',
    value: 'Southern Union State Community College'
  },
  {
    id: '6445',
    label: 'Southern University Bangladesh',
    value: 'Southern University Bangladesh'
  },
  {
    id: '6446',
    label: 'Southern University and A&M College',
    value: 'Southern University and A&M College'
  },
  {
    id: '6447',
    label: 'Southern University at New Orleans',
    value: 'Southern University at New Orleans'
  },
  {
    id: '6448',
    label: 'Southern University at Shreveport',
    value: 'Southern University at Shreveport'
  },
  {
    id: '6449',
    label: 'Southern Ural State University',
    value: 'Southern Ural State University'
  },
  {
    id: '6450',
    label: 'Southern Utah University',
    value: 'Southern Utah University'
  },
  {
    id: '6451',
    label: 'Southern West Virginia Community and Technical College',
    value: 'Southern West Virginia Community and Technical College'
  },
  {
    id: '6452',
    label: 'Southern Yangtze University',
    value: 'Southern Yangtze University'
  },
  {
    id: '6453',
    label: 'Southside Virginia Community College',
    value: 'Southside Virginia Community College'
  },
  {
    id: '6454',
    label: 'Southwest Agricultural University',
    value: 'Southwest Agricultural University'
  },
  {
    id: '6455',
    label: 'Southwest Baptist University',
    value: 'Southwest Baptist University'
  },
  {
    id: '6456',
    label: 'Southwest Forestry University',
    value: 'Southwest Forestry University'
  },
  {
    id: '6457',
    label: 'Southwest Georgia Technical College',
    value: 'Southwest Georgia Technical College'
  },
  {
    id: '6458',
    label: 'Southwest Jiaotong University',
    value: 'Southwest Jiaotong University'
  },
  {
    id: '6459',
    label: 'Southwest Mississippi Community College',
    value: 'Southwest Mississippi Community College'
  },
  {
    id: '6460',
    label: 'Southwest Missouri State University',
    value: 'Southwest Missouri State University'
  },
  {
    id: '6461',
    label: 'Southwest Petroleum University',
    value: 'Southwest Petroleum University'
  },
  {
    id: '6462',
    label: 'Southwest Research Institute',
    value: 'Southwest Research Institute'
  },
  {
    id: '6463',
    label: 'Southwest Tennessee Community College',
    value: 'Southwest Tennessee Community College'
  },
  {
    id: '6464',
    label: 'Southwest Texas Junior College',
    value: 'Southwest Texas Junior College'
  },
  {
    id: '6465',
    label: 'Southwest Texas State University',
    value: 'Southwest Texas State University'
  },
  {
    id: '6466',
    label: 'Southwest University',
    value: 'Southwest University'
  },
  {
    id: '6467',
    label: 'Southwest University of Finance and Economics',
    value: 'Southwest University of Finance and Economics'
  },
  {
    id: '6468',
    label: 'Southwest University of Nationalities',
    value: 'Southwest University of Nationalities'
  },
  {
    id: '6469',
    label: 'Southwest University of Political Science and Law',
    value: 'Southwest University of Political Science and Law'
  },
  {
    id: '6470',
    label: 'Southwest University of Science and Technology',
    value: 'Southwest University of Science and Technology'
  },
  {
    id: '6471',
    label: 'Southwest Virginia Community College',
    value: 'Southwest Virginia Community College'
  },
  {
    id: '6472',
    label: 'Southwest Wisconsin Technical College',
    value: 'Southwest Wisconsin Technical College'
  },
  {
    id: '6473',
    label: 'Southwestern Adventist College',
    value: 'Southwestern Adventist College'
  },
  {
    id: '6474',
    label: 'Southwestern Assemblies of God University',
    value: 'Southwestern Assemblies of God University'
  },
  {
    id: '6475',
    label: 'Southwestern Christian College',
    value: 'Southwestern Christian College'
  },
  {
    id: '6476',
    label: 'Southwestern College (CA)',
    value: 'Southwestern College (CA)'
  },
  {
    id: '6477',
    label: 'Southwestern College (KS)',
    value: 'Southwestern College (KS)'
  },
  {
    id: '6478',
    label: 'Southwestern Community College',
    value: 'Southwestern Community College'
  },
  {
    id: '6479',
    label: 'Southwestern Community College',
    value: 'Southwestern Community College'
  },
  {
    id: '6480',
    label: 'Southwestern Illinois College',
    value: 'Southwestern Illinois College'
  },
  {
    id: '6481',
    label: 'Southwestern Indian Polytechnic Institute',
    value: 'Southwestern Indian Polytechnic Institute'
  },
  {
    id: '6482',
    label: 'Southwestern Michigan College',
    value: 'Southwestern Michigan College'
  },
  {
    id: '6483',
    label: 'Southwestern Oregon Community College',
    value: 'Southwestern Oregon Community College'
  },
  {
    id: '6484',
    label: 'Southwestern University',
    value: 'Southwestern University'
  },
  {
    id: '6485',
    label: 'Southwestern University',
    value: 'Southwestern University'
  },
  {
    id: '6486',
    label: 'Soutsaka Institute of Technology',
    value: 'Soutsaka Institute of Technology'
  },
  {
    id: '6487',
    label: 'Spartan University of Health Sciences',
    value: 'Spartan University of Health Sciences'
  },
  {
    id: '6488',
    label: 'Spartanburg Community College',
    value: 'Spartanburg Community College'
  },
  {
    id: '6489',
    label: 'Spartanburg Methodist College',
    value: 'Spartanburg Methodist College'
  },
  {
    id: '6490',
    label: 'Spelman College',
    value: 'Spelman College'
  },
  {
    id: '6491',
    label: 'Spiru Haret University',
    value: 'Spiru Haret University'
  },
  {
    id: '6492',
    label: 'Spokane Community College',
    value: 'Spokane Community College'
  },
  {
    id: '6493',
    label: 'Spokane Falls Community College',
    value: 'Spokane Falls Community College'
  },
  {
    id: '6494',
    label: 'Spoon River College',
    value: 'Spoon River College'
  },
  {
    id: '6495',
    label: 'Spring Arbor College',
    value: 'Spring Arbor College'
  },
  {
    id: '6496',
    label: 'Spring Hill College',
    value: 'Spring Hill College'
  },
  {
    id: '6497',
    label: 'Springfield Technical Community College',
    value: 'Springfield Technical Community College'
  },
  {
    id: '6498',
    label: 'Sree Chitra Tirunal Institute for Medical Sciences and Technology',
    value: 'Sree Chitra Tirunal Institute for Medical Sciences and Technology'
  },
  {
    id: '6499',
    label: 'Sree Rama Krishna Degree College',
    value: 'Sree Rama Krishna Degree College'
  },
  {
    id: '6500',
    label: 'Sree Sankaracharya University of Sanskrit',
    value: 'Sree Sankaracharya University of Sanskrit'
  },
  {
    id: '6501',
    label: 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya',
    value: 'Sri Chandrasekharendra Saraswathi Viswa Mahavidyalaya'
  },
  {
    id: '6502',
    label: 'Sri Krishnadevaraya University',
    value: 'Sri Krishnadevaraya University'
  },
  {
    id: '6503',
    label: 'Sri Lanka Institute of Advanced Technical Education',
    value: 'Sri Lanka Institute of Advanced Technical Education'
  },
  {
    id: '6504',
    label: 'Sri Lanka Institute of Information Technology (SLIT)',
    value: 'Sri Lanka Institute of Information Technology (SLIT)'
  },
  {
    id: '6505',
    label: "Sri Padmavati Women's University",
    value: "Sri Padmavati Women's University"
  },
  {
    id: '6506',
    label: 'Sri Ramachardra Medical College and Research Institute',
    value: 'Sri Ramachardra Medical College and Research Institute'
  },
  {
    id: '6507',
    label: 'Sri Sai Ram Engineering College',
    value: 'Sri Sai Ram Engineering College'
  },
  {
    id: '6508',
    label: 'Sri Sai Ram Institute of Technology',
    value: 'Sri Sai Ram Institute of Technology'
  },
  {
    id: '6509',
    label: 'Sri Sant Gajanan Maharaj College of Engineering',
    value: 'Sri Sant Gajanan Maharaj College of Engineering'
  },
  {
    id: '6510',
    label: 'Sri Sathya Sai Institute of Higher Learning',
    value: 'Sri Sathya Sai Institute of Higher Learning'
  },
  {
    id: '6511',
    label: 'Sri Sivasubramaniya Nadar Institutions',
    value: 'Sri Sivasubramaniya Nadar Institutions'
  },
  {
    id: '6512',
    label: 'Sri Venkateswara College of Engineering',
    value: 'Sri Venkateswara College of Engineering'
  },
  {
    id: '6513',
    label: 'Sri Venkateswara Institute of Medical Sciences',
    value: 'Sri Venkateswara Institute of Medical Sciences'
  },
  {
    id: '6514',
    label: 'Sri Venkateswara University',
    value: 'Sri Venkateswara University'
  },
  {
    id: '6515',
    label: 'Srinakharinwirot University',
    value: 'Srinakharinwirot University'
  },
  {
    id: '6516',
    label: 'Sripatum University',
    value: 'Sripatum University'
  },
  {
    id: '6517',
    label: 'St Charles Community College',
    value: 'St Charles Community College'
  },
  {
    id: '6518',
    label: 'St Clements University - Higher Education School',
    value: 'St Clements University - Higher Education School'
  },
  {
    id: '6519',
    label: 'St Cloud Technical and Community College',
    value: 'St Cloud Technical and Community College'
  },
  {
    id: '6520',
    label: "St Joseph's College Of Engineering",
    value: "St Joseph's College Of Engineering"
  },
  {
    id: '6521',
    label: "St Joseph's Institute Of Technology",
    value: "St Joseph's Institute Of Technology"
  },
  {
    id: '6522',
    label: "St Mary's University",
    value: "St Mary's University"
  },
  {
    id: '6523',
    label: "St Theresa's Medical University",
    value: "St Theresa's Medical University"
  },
  {
    id: '6524',
    label: 'St. Ambrose University',
    value: 'St. Ambrose University'
  },
  {
    id: '6525',
    label: 'St. Andrew Georgian University',
    value: 'St. Andrew Georgian University'
  },
  {
    id: '6526',
    label: "St. Andrew's University",
    value: "St. Andrew's University"
  },
  {
    id: '6527',
    label: 'St. Andrews Presbyterian College',
    value: 'St. Andrews Presbyterian College'
  },
  {
    id: '6528',
    label: 'St. Anne University',
    value: 'St. Anne University'
  },
  {
    id: '6529',
    label: 'St. Augustine International University',
    value: 'St. Augustine International University'
  },
  {
    id: '6530',
    label: 'St. Augustine University of Tanzania',
    value: 'St. Augustine University of Tanzania'
  },
  {
    id: '6531',
    label: "St. Augustine's University",
    value: "St. Augustine's University"
  },
  {
    id: '6532',
    label: 'St. Bonaventure University',
    value: 'St. Bonaventure University'
  },
  {
    id: '6533',
    label: 'St. Clair College',
    value: 'St. Clair College'
  },
  {
    id: '6534',
    label: 'St. Clair County Community College',
    value: 'St. Clair County Community College'
  },
  {
    id: '6535',
    label: 'St. Elizabeth’s College of Health and Social Sciences in Bratislava',
    value: 'St. Elizabeth’s College of Health and Social Sciences in Bratislava'
  },
  {
    id: '6536',
    label: 'St. Francis College',
    value: 'St. Francis College'
  },
  {
    id: '6537',
    label: 'St. Francis Xavier University',
    value: 'St. Francis Xavier University'
  },
  {
    id: '6538',
    label: "St. George's University",
    value: "St. George's University"
  },
  {
    id: '6539',
    label: "St. George's University (Australia)",
    value: "St. George's University (Australia)"
  },
  {
    id: '6540',
    label: "St. John's & St. Mary's Institute of Technology",
    value: "St. John's & St. Mary's Institute of Technology"
  },
  {
    id: '6541',
    label: "St. John's College - Annapolis",
    value: "St. John's College - Annapolis"
  },
  {
    id: '6542',
    label: "St. John's College - Santa Fe",
    value: "St. John's College - Santa Fe"
  },
  {
    id: '6543',
    label: "St. John's University",
    value: "St. John's University"
  },
  {
    id: '6544',
    label: 'St. Johns River State College',
    value: 'St. Johns River State College'
  },
  {
    id: '6545',
    label: 'St. Joseph College (CT)',
    value: 'St. Joseph College (CT)'
  },
  {
    id: '6546',
    label: 'St. Joseph Group of Institutes',
    value: 'St. Joseph Group of Institutes'
  },
  {
    id: '6547',
    label: 'St. Joseph University Beirut',
    value: 'St. Joseph University Beirut'
  },
  {
    id: '6548',
    label: "St. Joseph's College (ME)",
    value: "St. Joseph's College (ME)"
  },
  {
    id: '6549',
    label: 'St. Lawrence University',
    value: 'St. Lawrence University'
  },
  {
    id: '6550',
    label: 'St. Louis College of Pharmacy',
    value: 'St. Louis College of Pharmacy'
  },
  {
    id: '6551',
    label: "St. Luke' s College of Nursing",
    value: "St. Luke' s College of Nursing"
  },
  {
    id: '6552',
    label: 'St. Marianna University School of Medicine',
    value: 'St. Marianna University School of Medicine'
  },
  {
    id: '6553',
    label: "St. Martin's College",
    value: "St. Martin's College"
  },
  {
    id: '6554',
    label: "St. Mary's College of Maryland",
    value: "St. Mary's College of Maryland"
  },
  {
    id: '6555',
    label: "St. Mary's University",
    value: "St. Mary's University"
  },
  {
    id: '6556',
    label: 'St. Matthews University',
    value: 'St. Matthews University'
  },
  {
    id: '6557',
    label: 'St. Paul University',
    value: 'St. Paul University'
  },
  {
    id: '6558',
    label: 'St. Pauls University',
    value: 'St. Pauls University'
  },
  {
    id: '6559',
    label: 'St. Petersburg College',
    value: 'St. Petersburg College'
  },
  {
    id: '6560',
    label: 'St. Petersburg Institute of Arts and Restoration',
    value: 'St. Petersburg Institute of Arts and Restoration'
  },
  {
    id: '6561',
    label: 'St. Petersburg Institute of Sales Management and Economics',
    value: 'St. Petersburg Institute of Sales Management and Economics'
  },
  {
    id: '6562',
    label: 'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture',
    value: 'St. Petersburg Repin State Academic Institute of Painting Sculpture and Achitecture'
  },
  {
    id: '6563',
    label: 'St. Petersburg State Agrarian University',
    value: 'St. Petersburg State Agrarian University'
  },
  {
    id: '6564',
    label: 'St. Petersburg State Chemical Pharmaceutical Academy',
    value: 'St. Petersburg State Chemical Pharmaceutical Academy'
  },
  {
    id: '6565',
    label: 'St. Petersburg State Cinema and TV University',
    value: 'St. Petersburg State Cinema and TV University'
  },
  {
    id: '6566',
    label: 'St. Petersburg State Conservatory',
    value: 'St. Petersburg State Conservatory'
  },
  {
    id: '6567',
    label: 'St. Petersburg State Electrotechnical University',
    value: 'St. Petersburg State Electrotechnical University'
  },
  {
    id: '6568',
    label: 'St. Petersburg State Forest-Technical Academy',
    value: 'St. Petersburg State Forest-Technical Academy'
  },
  {
    id: '6569',
    label: 'St. Petersburg State Institute of Technology (Technological University)',
    value: 'St. Petersburg State Institute of Technology (Technological University)'
  },
  {
    id: '6570',
    label: 'St. Petersburg State Marine Technical University',
    value: 'St. Petersburg State Marine Technical University'
  },
  {
    id: '6571',
    label: 'St. Petersburg State Medical Academy',
    value: 'St. Petersburg State Medical Academy'
  },
  {
    id: '6572',
    label: 'St. Petersburg State Mining Institute (Technical University)',
    value: 'St. Petersburg State Mining Institute (Technical University)'
  },
  {
    id: '6573',
    label: 'St. Petersburg State Mountain Institut',
    value: 'St. Petersburg State Mountain Institut'
  },
  {
    id: '6574',
    label: 'St. Petersburg State Pediatric Medical Academy',
    value: 'St. Petersburg State Pediatric Medical Academy'
  },
  {
    id: '6575',
    label: 'St. Petersburg State Polytechnical University',
    value: 'St. Petersburg State Polytechnical University'
  },
  {
    id: '6576',
    label: 'St. Petersburg State Technical University',
    value: 'St. Petersburg State Technical University'
  },
  {
    id: '6577',
    label: 'St. Petersburg State Technological University of Plant Polymers',
    value: 'St. Petersburg State Technological University of Plant Polymers'
  },
  {
    id: '6578',
    label: 'St. Petersburg State Theatre Arts Academy',
    value: 'St. Petersburg State Theatre Arts Academy'
  },
  {
    id: '6579',
    label: 'St. Petersburg State University',
    value: 'St. Petersburg State University'
  },
  {
    id: '6580',
    label: 'St. Petersburg State University of Aerospace Instrumentation',
    value: 'St. Petersburg State University of Aerospace Instrumentation'
  },
  {
    id: '6581',
    label: 'St. Petersburg State University of Architecture and Civil Engineering',
    value: 'St. Petersburg State University of Architecture and Civil Engineering'
  },
  {
    id: '6582',
    label: 'St. Petersburg State University of Civil Aviation',
    value: 'St. Petersburg State University of Civil Aviation'
  },
  {
    id: '6583',
    label: 'St. Petersburg State University of Culture and Arts',
    value: 'St. Petersburg State University of Culture and Arts'
  },
  {
    id: '6584',
    label: 'St. Petersburg State University of Economics and Finance',
    value: 'St. Petersburg State University of Economics and Finance'
  },
  {
    id: '6585',
    label: 'St. Petersburg State University of Engineering and Economics',
    value: 'St. Petersburg State University of Engineering and Economics'
  },
  {
    id: '6586',
    label: 'St. Petersburg State University of Technology and Design',
    value: 'St. Petersburg State University of Technology and Design'
  },
  {
    id: '6587',
    label: 'St. Petersburg State University of Telecommunication',
    value: 'St. Petersburg State University of Telecommunication'
  },
  {
    id: '6588',
    label: 'St. Petersburg State University of Waterway Communications',
    value: 'St. Petersburg State University of Waterway Communications'
  },
  {
    id: '6589',
    label: 'St. Petersburg StateMechnikov  Medical Academy',
    value: 'St. Petersburg StateMechnikov  Medical Academy'
  },
  {
    id: '6590',
    label: 'St. Thomas University',
    value: 'St. Thomas University'
  },
  {
    id: '6591',
    label: 'St. Thomas University (FL)',
    value: 'St. Thomas University (FL)'
  },
  {
    id: '6592',
    label: 'St.Cyril and Methodius University',
    value: 'St.Cyril and Methodius University'
  },
  {
    id: '6593',
    label: "St.James's School of Medicine, Bonaire",
    value: "St.James's School of Medicine, Bonaire"
  },
  {
    id: '6594',
    label: 'St.Kliment Ohridski University',
    value: 'St.Kliment Ohridski University'
  },
  {
    id: '6595',
    label: "St.Patrick's International College, London",
    value: "St.Patrick's International College, London"
  },
  {
    id: '6596',
    label: 'Staatlich anerkannte Fachhochschule für Kunsttherapie',
    value: 'Staatlich anerkannte Fachhochschule für Kunsttherapie'
  },
  {
    id: '6597',
    label: 'Staatliche Hochschule für Musik',
    value: 'Staatliche Hochschule für Musik'
  },
  {
    id: '6598',
    label: 'Staatliche Studienakademie Bautzen',
    value: 'Staatliche Studienakademie Bautzen'
  },
  {
    id: '6599',
    label: 'Staffordshire University',
    value: 'Staffordshire University'
  },
  {
    id: '6600',
    label: 'Stamford International University',
    value: 'Stamford International University'
  },
  {
    id: '6601',
    label: 'Stamford University',
    value: 'Stamford University'
  },
  {
    id: '6602',
    label: 'Stanford University',
    value: 'Stanford University'
  },
  {
    id: '6603',
    label: 'Stanly Community College',
    value: 'Stanly Community College'
  },
  {
    id: '6604',
    label: 'Stark State College',
    value: 'Stark State College'
  },
  {
    id: '6605',
    label: 'Staszic Pila College',
    value: 'Staszic Pila College'
  },
  {
    id: '6606',
    label: 'State Center Community College District',
    value: 'State Center Community College District'
  },
  {
    id: '6607',
    label: 'State Engineering University of Armenia',
    value: 'State Engineering University of Armenia'
  },
  {
    id: '6608',
    label: 'State Fair Community College',
    value: 'State Fair Community College'
  },
  {
    id: '6609',
    label: 'State Film, Television and Theatre Higher School Leon Schiller in Lodz',
    value: 'State Film, Television and Theatre Higher School Leon Schiller in Lodz'
  },
  {
    id: '6610',
    label: 'State Maritine Academy',
    value: 'State Maritine Academy'
  },
  {
    id: '6611',
    label: 'State Pedagogical University in Kryvyi Rih',
    value: 'State Pedagogical University in Kryvyi Rih'
  },
  {
    id: '6612',
    label: 'State University of Aerospace Technologies',
    value: 'State University of Aerospace Technologies'
  },
  {
    id: '6613',
    label: 'State University of Management',
    value: 'State University of Management'
  },
  {
    id: '6614',
    label: 'State University of New York College at Brockport',
    value: 'State University of New York College at Brockport'
  },
  {
    id: '6615',
    label: 'State University of New York College at Buffalo',
    value: 'State University of New York College at Buffalo'
  },
  {
    id: '6616',
    label: 'State University of New York College at Cortland',
    value: 'State University of New York College at Cortland'
  },
  {
    id: '6617',
    label: 'State University of New York College at Geneseo',
    value: 'State University of New York College at Geneseo'
  },
  {
    id: '6618',
    label: 'State University of New York College at New Paltz',
    value: 'State University of New York College at New Paltz'
  },
  {
    id: '6619',
    label: 'State University of New York College at Oneonta',
    value: 'State University of New York College at Oneonta'
  },
  {
    id: '6620',
    label: 'State University of New York College at Potsdam',
    value: 'State University of New York College at Potsdam'
  },
  {
    id: '6621',
    label: 'State University of New York College of Agriculture and Technology at Cobleskill',
    value: 'State University of New York College of Agriculture and Technology at Cobleskill'
  },
  {
    id: '6622',
    label: 'State University of New York College of Environmental Science and Forestry',
    value: 'State University of New York College of Environmental Science and Forestry'
  },
  {
    id: '6623',
    label: 'State University of New York College of Technology at Alfred',
    value: 'State University of New York College of Technology at Alfred'
  },
  {
    id: '6624',
    label: 'State University of New York Polytechnic Institute',
    value: 'State University of New York Polytechnic Institute'
  },
  {
    id: '6625',
    label: 'State University of New York System',
    value: 'State University of New York System'
  },
  {
    id: '6626',
    label: 'State University of New York at Albany',
    value: 'State University of New York at Albany'
  },
  {
    id: '6627',
    label: 'State University of New York at Binghamton',
    value: 'State University of New York at Binghamton'
  },
  {
    id: '6628',
    label: 'State University of New York at Buffalo',
    value: 'State University of New York at Buffalo'
  },
  {
    id: '6629',
    label: 'State University of New York at Farmingdale',
    value: 'State University of New York at Farmingdale'
  },
  {
    id: '6630',
    label: 'State University of New York at Fredonia',
    value: 'State University of New York at Fredonia'
  },
  {
    id: '6631',
    label: 'State University of New York at Oneonta',
    value: 'State University of New York at Oneonta'
  },
  {
    id: '6632',
    label: 'State University of New York at Oswego',
    value: 'State University of New York at Oswego'
  },
  {
    id: '6633',
    label: 'State University of New York at Plattsburgh',
    value: 'State University of New York at Plattsburgh'
  },
  {
    id: '6634',
    label: 'State University of New York at Stony Brook',
    value: 'State University of New York at Stony Brook'
  },
  {
    id: '6635',
    label: 'State University of Tetova',
    value: 'State University of Tetova'
  },
  {
    id: '6636',
    label: 'Stavropol State Technical University',
    value: 'Stavropol State Technical University'
  },
  {
    id: '6637',
    label: 'Stavropol State University',
    value: 'Stavropol State University'
  },
  {
    id: '6638',
    label: 'Steinbeis-Hochschule-Berlin',
    value: 'Steinbeis-Hochschule-Berlin'
  },
  {
    id: '6639',
    label: 'Stenden University',
    value: 'Stenden University'
  },
  {
    id: '6640',
    label: 'Stephen F. Austin State University',
    value: 'Stephen F. Austin State University'
  },
  {
    id: '6641',
    label: 'Stephens College',
    value: 'Stephens College'
  },
  {
    id: '6642',
    label: 'Stetson University',
    value: 'Stetson University'
  },
  {
    id: '6643',
    label: 'Stevens Institute of Technology',
    value: 'Stevens Institute of Technology'
  },
  {
    id: '6644',
    label: 'Stevenson University',
    value: 'Stevenson University'
  },
  {
    id: '6645',
    label: 'Stillman College',
    value: 'Stillman College'
  },
  {
    id: '6646',
    label: 'Stockholm Institute of Education',
    value: 'Stockholm Institute of Education'
  },
  {
    id: '6647',
    label: 'Stockholm School of Economics',
    value: 'Stockholm School of Economics'
  },
  {
    id: '6648',
    label: 'Stockholm School of Economics in Riga',
    value: 'Stockholm School of Economics in Riga'
  },
  {
    id: '6649',
    label: 'Stockholm University',
    value: 'Stockholm University'
  },
  {
    id: '6650',
    label: 'Stone Child College',
    value: 'Stone Child College'
  },
  {
    id: '6651',
    label: 'Stratford College London',
    value: 'Stratford College London'
  },
  {
    id: '6652',
    label: 'Stratford University',
    value: 'Stratford University'
  },
  {
    id: '6653',
    label: 'Strathmore University',
    value: 'Strathmore University'
  },
  {
    id: '6654',
    label: 'Strayer College',
    value: 'Strayer College'
  },
  {
    id: '6655',
    label: 'Střední průmyslová škola Mladá Boleslav',
    value: 'Střední průmyslová škola Mladá Boleslav'
  },
  {
    id: '6656',
    label: 'Suan Dusit Rajabhat University',
    value: 'Suan Dusit Rajabhat University'
  },
  {
    id: '6657',
    label: 'Sudan Academy of Sciences',
    value: 'Sudan Academy of Sciences'
  },
  {
    id: '6658',
    label: 'Sudan University for Science and Technology',
    value: 'Sudan University for Science and Technology'
  },
  {
    id: '6659',
    label: 'Suez Canal University',
    value: 'Suez Canal University'
  },
  {
    id: '6660',
    label: 'Suffolk County Community College',
    value: 'Suffolk County Community College'
  },
  {
    id: '6661',
    label: 'Suffolk University',
    value: 'Suffolk University'
  },
  {
    id: '6662',
    label: "Sugino Women's College",
    value: "Sugino Women's College"
  },
  {
    id: '6663',
    label: 'Sugiyama Jogakuen University',
    value: 'Sugiyama Jogakuen University'
  },
  {
    id: '6664',
    label: 'Sukhothai Thammathirat Open University',
    value: 'Sukhothai Thammathirat Open University'
  },
  {
    id: '6665',
    label: 'Sul Ross State University',
    value: 'Sul Ross State University'
  },
  {
    id: '6666',
    label: 'Suleyman Demirel University',
    value: 'Suleyman Demirel University'
  },
  {
    id: '6667',
    label: 'Suleyman Demirel University',
    value: 'Suleyman Demirel University'
  },
  {
    id: '6668',
    label: 'Sullivan County Community College',
    value: 'Sullivan County Community College'
  },
  {
    id: '6669',
    label: 'Sullivan University',
    value: 'Sullivan University'
  },
  {
    id: '6670',
    label: 'Sultan Abdul Halim Muadzam Shah Polytechnic',
    value: 'Sultan Abdul Halim Muadzam Shah Polytechnic'
  },
  {
    id: '6671',
    label: 'Sultan Ahmad Shah Islamic College',
    value: 'Sultan Ahmad Shah Islamic College'
  },
  {
    id: '6672',
    label: 'Sultan Azlan Shah Polytechnic',
    value: 'Sultan Azlan Shah Polytechnic'
  },
  {
    id: '6673',
    label: 'Sultan Haji Ahmad Shah Polytechnic',
    value: 'Sultan Haji Ahmad Shah Polytechnic'
  },
  {
    id: '6674',
    label: 'Sultan Idris Shah Polytechnic',
    value: 'Sultan Idris Shah Polytechnic'
  },
  {
    id: '6675',
    label: 'Sultan Ismail Petra International Islamic College',
    value: 'Sultan Ismail Petra International Islamic College'
  },
  {
    id: '6676',
    label: 'Sultan Mizan Zainal Abidin Polytechnic',
    value: 'Sultan Mizan Zainal Abidin Polytechnic'
  },
  {
    id: '6677',
    label: 'Sultan Qaboos University',
    value: 'Sultan Qaboos University'
  },
  {
    id: '6678',
    label: 'Sultan Salahuddin Abdul Aziz Shah Polytechnic',
    value: 'Sultan Salahuddin Abdul Aziz Shah Polytechnic'
  },
  {
    id: '6679',
    label: 'Sultanah Bahiyah Polytechnic',
    value: 'Sultanah Bahiyah Polytechnic'
  },
  {
    id: '6680',
    label: 'Sumgait State University',
    value: 'Sumgait State University'
  },
  {
    id: '6681',
    label: 'Summit University of Louisiana',
    value: 'Summit University of Louisiana'
  },
  {
    id: '6682',
    label: 'Sumy National Agrarian University',
    value: 'Sumy National Agrarian University'
  },
  {
    id: '6683',
    label: 'Sumy State University',
    value: 'Sumy State University'
  },
  {
    id: '6684',
    label: 'Sun Yat-Sen University',
    value: 'Sun Yat-Sen University'
  },
  {
    id: '6685',
    label: 'Sun Yat-Sen University of Medical Sciences',
    value: 'Sun Yat-Sen University of Medical Sciences'
  },
  {
    id: '6686',
    label: 'Sunchon National University',
    value: 'Sunchon National University'
  },
  {
    id: '6687',
    label: 'Sung Kyun Kwan University',
    value: 'Sung Kyun Kwan University'
  },
  {
    id: '6688',
    label: 'Sungkonghoe University',
    value: 'Sungkonghoe University'
  },
  {
    id: '6689',
    label: 'Sungkyul University',
    value: 'Sungkyul University'
  },
  {
    id: '6690',
    label: 'Sungkyunkwan University',
    value: 'Sungkyunkwan University'
  },
  {
    id: '6691',
    label: "Sungshin Women's University",
    value: "Sungshin Women's University"
  },
  {
    id: '6692',
    label: 'Sunmoon University',
    value: 'Sunmoon University'
  },
  {
    id: '6693',
    label: 'Sunway University College',
    value: 'Sunway University College'
  },
  {
    id: '6694',
    label: 'Sunyani Technical University',
    value: 'Sunyani Technical University'
  },
  {
    id: '6695',
    label: 'Sur University College',
    value: 'Sur University College'
  },
  {
    id: '6696',
    label: 'Suranaree University of Technology',
    value: 'Suranaree University of Technology'
  },
  {
    id: '6697',
    label: 'Surgut State University',
    value: 'Surgut State University'
  },
  {
    id: '6698',
    label: 'Surry Community College',
    value: 'Surry Community College'
  },
  {
    id: '6699',
    label: 'Surugadai University',
    value: 'Surugadai University'
  },
  {
    id: '6700',
    label: 'Susquehanna University',
    value: 'Susquehanna University'
  },
  {
    id: '6701',
    label: 'Sussex County Community College',
    value: 'Sussex County Community College'
  },
  {
    id: '6702',
    label: 'Suwon Catholic University',
    value: 'Suwon Catholic University'
  },
  {
    id: '6703',
    label: 'Suwon University',
    value: 'Suwon University'
  },
  {
    id: '6704',
    label: 'Suzhou University',
    value: 'Suzhou University'
  },
  {
    id: '6705',
    label: 'Suzuka International University',
    value: 'Suzuka International University'
  },
  {
    id: '6706',
    label: 'Suzuka University of Medical Science',
    value: 'Suzuka University of Medical Science'
  },
  {
    id: '6707',
    label: 'Svey Reong University',
    value: 'Svey Reong University'
  },
  {
    id: '6708',
    label: 'Swami Ramanand Teerth Marathwada University',
    value: 'Swami Ramanand Teerth Marathwada University'
  },
  {
    id: '6709',
    label: 'Swansea Metropolitan University',
    value: 'Swansea Metropolitan University'
  },
  {
    id: '6710',
    label: 'Swansea University',
    value: 'Swansea University'
  },
  {
    id: '6711',
    label: 'Swarthmore College',
    value: 'Swarthmore College'
  },
  {
    id: '6712',
    label: 'Swedish School of Economics and Business Administration, Finland',
    value: 'Swedish School of Economics and Business Administration, Finland'
  },
  {
    id: '6713',
    label: 'Swedish University of Agricultural Sciences',
    value: 'Swedish University of Agricultural Sciences'
  },
  {
    id: '6714',
    label: 'Sweet Briar College',
    value: 'Sweet Briar College'
  },
  {
    id: '6715',
    label: 'Swinburne University of Technology',
    value: 'Swinburne University of Technology'
  },
  {
    id: '6716',
    label: 'Swinburne University of Technology, Sarawak Campus',
    value: 'Swinburne University of Technology, Sarawak Campus'
  },
  {
    id: '6717',
    label: 'Swiss Business School Zurich (SBS)',
    value: 'Swiss Business School Zurich (SBS)'
  },
  {
    id: '6718',
    label: 'Swiss German University',
    value: 'Swiss German University'
  },
  {
    id: '6719',
    label: 'Swiss Management Center',
    value: 'Swiss Management Center'
  },
  {
    id: '6720',
    label: 'Syktyvkar State University',
    value: 'Syktyvkar State University'
  },
  {
    id: '6721',
    label: 'Sylhet Agricultural University',
    value: 'Sylhet Agricultural University'
  },
  {
    id: '6722',
    label: 'Sylhet Engineering College',
    value: 'Sylhet Engineering College'
  },
  {
    id: '6723',
    label: 'Sylhet International University',
    value: 'Sylhet International University'
  },
  {
    id: '6724',
    label: 'Symbiosis International University',
    value: 'Symbiosis International University'
  },
  {
    id: '6725',
    label: 'Syracuse University',
    value: 'Syracuse University'
  },
  {
    id: '6726',
    label: 'Syrian International Private University for Science & Technology',
    value: 'Syrian International Private University for Science & Technology'
  },
  {
    id: '6727',
    label: 'Syrian Virtual University',
    value: 'Syrian Virtual University'
  },
  {
    id: '6728',
    label: 'Szent István University',
    value: 'Szent István University'
  },
  {
    id: '6729',
    label: 'Séchenyi István University',
    value: 'Séchenyi István University'
  },
  {
    id: '6730',
    label: 'Södertörn University College',
    value: 'Södertörn University College'
  },
  {
    id: '6731',
    label: 'TED University',
    value: 'TED University'
  },
  {
    id: '6732',
    label: 'TPM College',
    value: 'TPM College'
  },
  {
    id: '6733',
    label: 'TaTung University',
    value: 'TaTung University'
  },
  {
    id: '6734',
    label: 'Tabari Institute of Higher Education',
    value: 'Tabari Institute of Higher Education'
  },
  {
    id: '6735',
    label: 'Tabor College',
    value: 'Tabor College'
  },
  {
    id: '6736',
    label: 'Tabriz College of Technology',
    value: 'Tabriz College of Technology'
  },
  {
    id: '6737',
    label: 'Tabriz Islamic Art University',
    value: 'Tabriz Islamic Art University'
  },
  {
    id: '6738',
    label: 'Tabriz University',
    value: 'Tabriz University'
  },
  {
    id: '6739',
    label: 'Tabriz University of Medical Sciences',
    value: 'Tabriz University of Medical Sciences'
  },
  {
    id: '6740',
    label: 'Tacoma Community College',
    value: 'Tacoma Community College'
  },
  {
    id: '6741',
    label: 'Taegu National University of Education',
    value: 'Taegu National University of Education'
  },
  {
    id: '6742',
    label: 'Taegu University',
    value: 'Taegu University'
  },
  {
    id: '6743',
    label: 'Taejon National University of Technology',
    value: 'Taejon National University of Technology'
  },
  {
    id: '6744',
    label: 'Taejon University',
    value: 'Taejon University'
  },
  {
    id: '6745',
    label: 'Taeshin Christian University',
    value: 'Taeshin Christian University'
  },
  {
    id: '6746',
    label: 'Tafila Technical University',
    value: 'Tafila Technical University'
  },
  {
    id: '6747',
    label: 'Taft College',
    value: 'Taft College'
  },
  {
    id: '6748',
    label: 'Taganrog State Pedagogical Institute',
    value: 'Taganrog State Pedagogical Institute'
  },
  {
    id: '6749',
    label: 'Taganrog State University of Radioengineering',
    value: 'Taganrog State University of Radioengineering'
  },
  {
    id: '6750',
    label: 'Tai Solarin University of Education',
    value: 'Tai Solarin University of Education'
  },
  {
    id: '6751',
    label: 'TaiChung Healthcare and Management University',
    value: 'TaiChung Healthcare and Management University'
  },
  {
    id: '6752',
    label: 'Taibah University',
    value: 'Taibah University'
  },
  {
    id: '6753',
    label: 'Taif University',
    value: 'Taif University'
  },
  {
    id: '6754',
    label: 'Tainan National College of the Arts',
    value: 'Tainan National College of the Arts'
  },
  {
    id: '6755',
    label: 'Taipei Medical College',
    value: 'Taipei Medical College'
  },
  {
    id: '6756',
    label: 'Taipei Municipal Teachers College',
    value: 'Taipei Municipal Teachers College'
  },
  {
    id: '6757',
    label: 'Taipei Physical Education College',
    value: 'Taipei Physical Education College'
  },
  {
    id: '6758',
    label: 'Taisho University',
    value: 'Taisho University'
  },
  {
    id: '6759',
    label: 'Taiyuan University of Technology',
    value: 'Taiyuan University of Technology'
  },
  {
    id: '6760',
    label: 'Taiz University',
    value: 'Taiz University'
  },
  {
    id: '6761',
    label: 'Taizhou College',
    value: 'Taizhou College'
  },
  {
    id: '6762',
    label: 'Taj International College',
    value: 'Taj International College'
  },
  {
    id: '6763',
    label: 'Tajik Agrarian University',
    value: 'Tajik Agrarian University'
  },
  {
    id: '6764',
    label: 'Tajik National University',
    value: 'Tajik National University'
  },
  {
    id: '6765',
    label: 'Tajikistan International University',
    value: 'Tajikistan International University'
  },
  {
    id: '6766',
    label: 'Takachiho University',
    value: 'Takachiho University'
  },
  {
    id: '6767',
    label: 'Takamatsu University',
    value: 'Takamatsu University'
  },
  {
    id: '6768',
    label: 'Takarazuka University of Art and Design',
    value: 'Takarazuka University of Art and Design'
  },
  {
    id: '6769',
    label: 'Takasaki City University of Economics',
    value: 'Takasaki City University of Economics'
  },
  {
    id: '6770',
    label: 'Takestan Islamic Azad University',
    value: 'Takestan Islamic Azad University'
  },
  {
    id: '6771',
    label: 'Takhar University',
    value: 'Takhar University'
  },
  {
    id: '6772',
    label: 'Takhatmal Shrivallabh Homoeopathic Medical College',
    value: 'Takhatmal Shrivallabh Homoeopathic Medical College'
  },
  {
    id: '6773',
    label: 'Takoradi Technical University',
    value: 'Takoradi Technical University'
  },
  {
    id: '6774',
    label: 'Takushoku University',
    value: 'Takushoku University'
  },
  {
    id: '6775',
    label: 'Talladega College',
    value: 'Talladega College'
  },
  {
    id: '6776',
    label: 'Tallahassee Community College',
    value: 'Tallahassee Community College'
  },
  {
    id: '6777',
    label: 'Tallinn University',
    value: 'Tallinn University'
  },
  {
    id: '6778',
    label: 'Tallinn University of Technology',
    value: 'Tallinn University of Technology'
  },
  {
    id: '6779',
    label: 'Tama Art University',
    value: 'Tama Art University'
  },
  {
    id: '6780',
    label: 'Tama Institute of Management and Information Sciences',
    value: 'Tama Institute of Management and Information Sciences'
  },
  {
    id: '6781',
    label: 'Tamagawa University',
    value: 'Tamagawa University'
  },
  {
    id: '6782',
    label: 'Tambov State Technical University',
    value: 'Tambov State Technical University'
  },
  {
    id: '6783',
    label: 'Tamil Nadu Agricultural University',
    value: 'Tamil Nadu Agricultural University'
  },
  {
    id: '6784',
    label: 'Tamil Nadu Dr. Ambedkar Law University',
    value: 'Tamil Nadu Dr. Ambedkar Law University'
  },
  {
    id: '6785',
    label: 'Tamil Nadu Dr. M.G.R. Medical University',
    value: 'Tamil Nadu Dr. M.G.R. Medical University'
  },
  {
    id: '6786',
    label: 'Tamil Nadu Veterinary and Animal Sciences University',
    value: 'Tamil Nadu Veterinary and Animal Sciences University'
  },
  {
    id: '6787',
    label: 'Tamil University',
    value: 'Tamil University'
  },
  {
    id: '6788',
    label: 'Tamkang University',
    value: 'Tamkang University'
  },
  {
    id: '6789',
    label: 'Tampere Polytechnic',
    value: 'Tampere Polytechnic'
  },
  {
    id: '6790',
    label: 'Tampere University of Technology',
    value: 'Tampere University of Technology'
  },
  {
    id: '6791',
    label: 'Tansian University',
    value: 'Tansian University'
  },
  {
    id: '6792',
    label: 'Tanta University',
    value: 'Tanta University'
  },
  {
    id: '6793',
    label: 'Taoist College Singapore',
    value: 'Taoist College Singapore'
  },
  {
    id: '6794',
    label: 'Taraba State University',
    value: 'Taraba State University'
  },
  {
    id: '6795',
    label: 'Taras Shevchenko National University of Kyiv',
    value: 'Taras Shevchenko National University of Kyiv'
  },
  {
    id: '6796',
    label: 'Tarbiat Modares University',
    value: 'Tarbiat Modares University'
  },
  {
    id: '6797',
    label: 'Targu-Mures University of Theatre',
    value: 'Targu-Mures University of Theatre'
  },
  {
    id: '6798',
    label: 'Tarleton State University',
    value: 'Tarleton State University'
  },
  {
    id: '6799',
    label: 'Tarsus University',
    value: 'Tarsus University'
  },
  {
    id: '6800',
    label: 'Tartu Health Care College',
    value: 'Tartu Health Care College'
  },
  {
    id: '6801',
    label: 'Tashkent Automobile and Roads Institute',
    value: 'Tashkent Automobile and Roads Institute'
  },
  {
    id: '6802',
    label: 'Tashkent Institute of Irrigation and Melioration',
    value: 'Tashkent Institute of Irrigation and Melioration'
  },
  {
    id: '6803',
    label: 'Tashkent Islam University',
    value: 'Tashkent Islam University'
  },
  {
    id: '6804',
    label: 'Tashkent Medical Academy',
    value: 'Tashkent Medical Academy'
  },
  {
    id: '6805',
    label: 'Tashkent Pharmaceutical Institute',
    value: 'Tashkent Pharmaceutical Institute'
  },
  {
    id: '6806',
    label: 'Tashkent School of Finance',
    value: 'Tashkent School of Finance'
  },
  {
    id: '6807',
    label: 'Tashkent State Agrarian University',
    value: 'Tashkent State Agrarian University'
  },
  {
    id: '6808',
    label: 'Tashkent State Technical University',
    value: 'Tashkent State Technical University'
  },
  {
    id: '6809',
    label: 'Tashkent State University of Economics',
    value: 'Tashkent State University of Economics'
  },
  {
    id: '6810',
    label: 'Tashkent State University of Oriental Studies',
    value: 'Tashkent State University of Oriental Studies'
  },
  {
    id: '6811',
    label: 'Tashkent University of Information Technologies',
    value: 'Tashkent University of Information Technologies'
  },
  {
    id: '6812',
    label: 'Tata Institute of Social Sciences',
    value: 'Tata Institute of Social Sciences'
  },
  {
    id: '6813',
    label: 'Tatung Institute of Technology',
    value: 'Tatung Institute of Technology'
  },
  {
    id: '6814',
    label: 'Taurida National V.I.Vernadsky University',
    value: 'Taurida National V.I.Vernadsky University'
  },
  {
    id: '6815',
    label: 'Tay Nguyen University',
    value: 'Tay Nguyen University'
  },
  {
    id: '6816',
    label: 'Taylor University',
    value: 'Taylor University'
  },
  {
    id: '6817',
    label: "Taylor's University College",
    value: "Taylor's University College"
  },
  {
    id: '6818',
    label: 'Tbilisi Institute of Asia and Africa',
    value: 'Tbilisi Institute of Asia and Africa'
  },
  {
    id: '6819',
    label: 'Tbilisi Pedagogical University',
    value: 'Tbilisi Pedagogical University'
  },
  {
    id: '6820',
    label: 'Tbilisi State Medical University',
    value: 'Tbilisi State Medical University'
  },
  {
    id: '6821',
    label: 'Tbilisi State University',
    value: 'Tbilisi State University'
  },
  {
    id: '6822',
    label: 'Teacher Training College of Revalidation, Rehabilitation and Physical Education',
    value: 'Teacher Training College of Revalidation, Rehabilitation and Physical Education'
  },
  {
    id: '6823',
    label: 'Team Lingue International House',
    value: 'Team Lingue International House'
  },
  {
    id: '6824',
    label: 'Technical Career Institutes',
    value: 'Technical Career Institutes'
  },
  {
    id: '6825',
    label: 'Technical College of the Lowcountry',
    value: 'Technical College of the Lowcountry'
  },
  {
    id: '6826',
    label: 'Technical Institute of Dubnica in Dubnica nad Váhom',
    value: 'Technical Institute of Dubnica in Dubnica nad Váhom'
  },
  {
    id: '6827',
    label: 'Technical University in Kosice',
    value: 'Technical University in Kosice'
  },
  {
    id: '6828',
    label: 'Technical University in Zvolen',
    value: 'Technical University in Zvolen'
  },
  {
    id: '6829',
    label: 'Technical University of Bialystok',
    value: 'Technical University of Bialystok'
  },
  {
    id: '6830',
    label: 'Technical University of Civil Engineering Bucharest',
    value: 'Technical University of Civil Engineering Bucharest'
  },
  {
    id: '6831',
    label: 'Technical University of Cluj-Napoca',
    value: 'Technical University of Cluj-Napoca'
  },
  {
    id: '6832',
    label: 'Technical University of Cracow',
    value: 'Technical University of Cracow'
  },
  {
    id: '6833',
    label: 'Technical University of Crete',
    value: 'Technical University of Crete'
  },
  {
    id: '6834',
    label: 'Technical University of Czestochowa',
    value: 'Technical University of Czestochowa'
  },
  {
    id: '6835',
    label: 'Technical University of Denmark',
    value: 'Technical University of Denmark'
  },
  {
    id: '6836',
    label: 'Technical University of Gabrovo',
    value: 'Technical University of Gabrovo'
  },
  {
    id: '6837',
    label: 'Technical University of Gdansk',
    value: 'Technical University of Gdansk'
  },
  {
    id: '6838',
    label: 'Technical University of Iasi',
    value: 'Technical University of Iasi'
  },
  {
    id: '6839',
    label: 'Technical University of Kenya',
    value: 'Technical University of Kenya'
  },
  {
    id: '6840',
    label: 'Technical University of Kielce',
    value: 'Technical University of Kielce'
  },
  {
    id: '6841',
    label: 'Technical University of Koszalin',
    value: 'Technical University of Koszalin'
  },
  {
    id: '6842',
    label: 'Technical University of Liberec',
    value: 'Technical University of Liberec'
  },
  {
    id: '6843',
    label: 'Technical University of Lodz',
    value: 'Technical University of Lodz'
  },
  {
    id: '6844',
    label: 'Technical University of Lublin',
    value: 'Technical University of Lublin'
  },
  {
    id: '6845',
    label: 'Technical University of Mining and Metallurgy Ostrava',
    value: 'Technical University of Mining and Metallurgy Ostrava'
  },
  {
    id: '6846',
    label: 'Technical University of Mombasa',
    value: 'Technical University of Mombasa'
  },
  {
    id: '6847',
    label: 'Technical University of Opole',
    value: 'Technical University of Opole'
  },
  {
    id: '6848',
    label: 'Technical University of Poznan',
    value: 'Technical University of Poznan'
  },
  {
    id: '6849',
    label: 'Technical University of Radom',
    value: 'Technical University of Radom'
  },
  {
    id: '6850',
    label: 'Technical University of Rzeszow',
    value: 'Technical University of Rzeszow'
  },
  {
    id: '6851',
    label: 'Technical University of Sofia',
    value: 'Technical University of Sofia'
  },
  {
    id: '6852',
    label: 'Technical University of Szczecin',
    value: 'Technical University of Szczecin'
  },
  {
    id: '6853',
    label: 'Technical University of Timisoara',
    value: 'Technical University of Timisoara'
  },
  {
    id: '6854',
    label: 'Technical University of Varna',
    value: 'Technical University of Varna'
  },
  {
    id: '6855',
    label: 'Technical University of Wroclaw',
    value: 'Technical University of Wroclaw'
  },
  {
    id: '6856',
    label: 'Technical University of Zielona Gora',
    value: 'Technical University of Zielona Gora'
  },
  {
    id: '6857',
    label: 'Technikum Wien',
    value: 'Technikum Wien'
  },
  {
    id: '6858',
    label: 'Technion - Israel Institute of Technology',
    value: 'Technion - Israel Institute of Technology'
  },
  {
    id: '6859',
    label: 'Technische Fachhochschule Berlin',
    value: 'Technische Fachhochschule Berlin'
  },
  {
    id: '6860',
    label: 'Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum',
    value: 'Technische Fachhochschule Georg Agricola für Rohstoff, Energie und, Umwelt zu Bochum'
  },
  {
    id: '6861',
    label: 'Technische Fachhochschule Wildau',
    value: 'Technische Fachhochschule Wildau'
  },
  {
    id: '6862',
    label: 'Technische Hochschule Brandenburg',
    value: 'Technische Hochschule Brandenburg'
  },
  {
    id: '6863',
    label: 'Technische Hochschule Deggendorf',
    value: 'Technische Hochschule Deggendorf'
  },
  {
    id: '6864',
    label: 'Technische Hochschule Ingolstadt',
    value: 'Technische Hochschule Ingolstadt'
  },
  {
    id: '6865',
    label: 'Technische Hochschule Köln',
    value: 'Technische Hochschule Köln'
  },
  {
    id: '6866',
    label: 'Technische Hochschule Mittelhessen',
    value: 'Technische Hochschule Mittelhessen'
  },
  {
    id: '6867',
    label: 'Technische Universität Bergakademie Freiberg',
    value: 'Technische Universität Bergakademie Freiberg'
  },
  {
    id: '6868',
    label: 'Technische Universität Berlin',
    value: 'Technische Universität Berlin'
  },
  {
    id: '6869',
    label: 'Technische Universität Carolo-Wilhelmina zu Braunschweig',
    value: 'Technische Universität Carolo-Wilhelmina zu Braunschweig'
  },
  {
    id: '6870',
    label: 'Technische Universität Chemnitz',
    value: 'Technische Universität Chemnitz'
  },
  {
    id: '6871',
    label: 'Technische Universität Clausthal',
    value: 'Technische Universität Clausthal'
  },
  {
    id: '6872',
    label: 'Technische Universität Darmstadt',
    value: 'Technische Universität Darmstadt'
  },
  {
    id: '6873',
    label: 'Technische Universität Dortmund',
    value: 'Technische Universität Dortmund'
  },
  {
    id: '6874',
    label: 'Technische Universität Dresden',
    value: 'Technische Universität Dresden'
  },
  {
    id: '6875',
    label: 'Technische Universität Graz',
    value: 'Technische Universität Graz'
  },
  {
    id: '6876',
    label: 'Technische Universität Hamburg',
    value: 'Technische Universität Hamburg'
  },
  {
    id: '6877',
    label: 'Technische Universität Ilmenau',
    value: 'Technische Universität Ilmenau'
  },
  {
    id: '6878',
    label: 'Technische Universität München',
    value: 'Technische Universität München'
  },
  {
    id: '6879',
    label: 'Technische Universität Wien',
    value: 'Technische Universität Wien'
  },
  {
    id: '6880',
    label: 'Technological University Dublin',
    value: 'Technological University Dublin'
  },
  {
    id: '6881',
    label: 'Technological University of Iceland',
    value: 'Technological University of Iceland'
  },
  {
    id: '6882',
    label: 'Technological University of Podillya',
    value: 'Technological University of Podillya'
  },
  {
    id: '6883',
    label: 'Technological University of the Philippines',
    value: 'Technological University of the Philippines'
  },
  {
    id: '6884',
    label: 'Tecnologico de Baja California, Universidad',
    value: 'Tecnologico de Baja California, Universidad'
  },
  {
    id: '6885',
    label: 'Tehran University of Medical Sciences',
    value: 'Tehran University of Medical Sciences'
  },
  {
    id: '6886',
    label: 'Tehran University of Teacher Training',
    value: 'Tehran University of Teacher Training'
  },
  {
    id: '6887',
    label: 'Teikyo Heisei University',
    value: 'Teikyo Heisei University'
  },
  {
    id: '6888',
    label: 'Teikyo University of Science and Technology',
    value: 'Teikyo University of Science and Technology'
  },
  {
    id: '6889',
    label: 'Tel Aviv University',
    value: 'Tel Aviv University'
  },
  {
    id: '6890',
    label: 'Temasek Polytechnic',
    value: 'Temasek Polytechnic'
  },
  {
    id: '6891',
    label: 'Temple College',
    value: 'Temple College'
  },
  {
    id: '6892',
    label: 'Temple University',
    value: 'Temple University'
  },
  {
    id: '6893',
    label: 'Temple University Japan',
    value: 'Temple University Japan'
  },
  {
    id: '6894',
    label: 'Tennessee State University',
    value: 'Tennessee State University'
  },
  {
    id: '6895',
    label: 'Tennessee Technological University',
    value: 'Tennessee Technological University'
  },
  {
    id: '6896',
    label: 'Tenri University',
    value: 'Tenri University'
  },
  {
    id: '6897',
    label: 'Ternopil Academy of National Economy',
    value: 'Ternopil Academy of National Economy'
  },
  {
    id: '6898',
    label: "Ternopil State Ivan Pul'uj Technical University",
    value: "Ternopil State Ivan Pul'uj Technical University"
  },
  {
    id: '6899',
    label: 'Terra State Community College',
    value: 'Terra State Community College'
  },
  {
    id: '6900',
    label: 'Terrant County College',
    value: 'Terrant County College'
  },
  {
    id: '6901',
    label: 'Texarkana College',
    value: 'Texarkana College'
  },
  {
    id: '6902',
    label: 'Texas A&M International University',
    value: 'Texas A&M International University'
  },
  {
    id: '6903',
    label: 'Texas A&M University - College Station',
    value: 'Texas A&M University - College Station'
  },
  {
    id: '6904',
    label: 'Texas A&M University - Commerce',
    value: 'Texas A&M University - Commerce'
  },
  {
    id: '6905',
    label: 'Texas A&M University - Corpus Christi',
    value: 'Texas A&M University - Corpus Christi'
  },
  {
    id: '6906',
    label: 'Texas A&M University - Kingsville',
    value: 'Texas A&M University - Kingsville'
  },
  {
    id: '6907',
    label: 'Texas A&M University-Texarkana',
    value: 'Texas A&M University-Texarkana'
  },
  {
    id: '6908',
    label: 'Texas Christian University',
    value: 'Texas Christian University'
  },
  {
    id: '6909',
    label: 'Texas College',
    value: 'Texas College'
  },
  {
    id: '6910',
    label: 'Texas Southern University',
    value: 'Texas Southern University'
  },
  {
    id: '6911',
    label: 'Texas State Technical College-Harlingen',
    value: 'Texas State Technical College-Harlingen'
  },
  {
    id: '6912',
    label: 'Texas State Technical College-Marshall',
    value: 'Texas State Technical College-Marshall'
  },
  {
    id: '6913',
    label: 'Texas State Technical College-Waco',
    value: 'Texas State Technical College-Waco'
  },
  {
    id: '6914',
    label: 'Texas State Technical College-West Texas',
    value: 'Texas State Technical College-West Texas'
  },
  {
    id: '6915',
    label: 'Texas State University',
    value: 'Texas State University'
  },
  {
    id: '6916',
    label: 'Texas Tech University',
    value: 'Texas Tech University'
  },
  {
    id: '6917',
    label: 'Texas Tech University-Health Sciences Center',
    value: 'Texas Tech University-Health Sciences Center'
  },
  {
    id: '6918',
    label: 'Texas Wesleyan University',
    value: 'Texas Wesleyan University'
  },
  {
    id: '6919',
    label: "Texas Woman's University",
    value: "Texas Woman's University"
  },
  {
    id: '6920',
    label: 'Texila American University',
    value: 'Texila American University'
  },
  {
    id: '6921',
    label: 'Textile Institute of Pakistan',
    value: 'Textile Institute of Pakistan'
  },
  {
    id: '6922',
    label: 'Tezpur University',
    value: 'Tezpur University'
  },
  {
    id: '6923',
    label: 'Tezukayama Gakuin University',
    value: 'Tezukayama Gakuin University'
  },
  {
    id: '6924',
    label: 'Tezukayama University',
    value: 'Tezukayama University'
  },
  {
    id: '6925',
    label: 'Thaddeus Stevens College of Technology',
    value: 'Thaddeus Stevens College of Technology'
  },
  {
    id: '6926',
    label: 'Thai Nguyen University of Agriculture and Forestry',
    value: 'Thai Nguyen University of Agriculture and Forestry'
  },
  {
    id: '6927',
    label: 'Thaksin University',
    value: 'Thaksin University'
  },
  {
    id: '6928',
    label: 'Thamar University',
    value: 'Thamar University'
  },
  {
    id: '6929',
    label: 'Thames Valley University',
    value: 'Thames Valley University'
  },
  {
    id: '6930',
    label: 'Thammasat University',
    value: 'Thammasat University'
  },
  {
    id: '6931',
    label: 'Thapar Institute of Engineering and Technology',
    value: 'Thapar Institute of Engineering and Technology'
  },
  {
    id: '6932',
    label: 'The Academy of Public Administration',
    value: 'The Academy of Public Administration'
  },
  {
    id: '6933',
    label: 'The Achievers University',
    value: 'The Achievers University'
  },
  {
    id: '6934',
    label: 'The American University in London',
    value: 'The American University in London'
  },
  {
    id: '6935',
    label: 'The Art Institutes',
    value: 'The Art Institutes'
  },
  {
    id: '6936',
    label: 'The CTL Eurocollege',
    value: 'The CTL Eurocollege'
  },
  {
    id: '6937',
    label: 'The Chinese University of Hong Kong',
    value: 'The Chinese University of Hong Kong'
  },
  {
    id: '6938',
    label: 'The Citadel',
    value: 'The Citadel'
  },
  {
    id: '6939',
    label: 'The College of New Jersey',
    value: 'The College of New Jersey'
  },
  {
    id: '6940',
    label: 'The College of The Bahamas',
    value: 'The College of The Bahamas'
  },
  {
    id: '6941',
    label: 'The Computer Engineering Research Group,University of Toronto',
    value: 'The Computer Engineering Research Group,University of Toronto'
  },
  {
    id: '6942',
    label: 'The Education University of Hong Kong',
    value: 'The Education University of Hong Kong'
  },
  {
    id: '6943',
    label: 'The Emirates Academy of Hotel Managment',
    value: 'The Emirates Academy of Hotel Managment'
  },
  {
    id: '6944',
    label: 'The Federal Polytechnic Offa',
    value: 'The Federal Polytechnic Offa'
  },
  {
    id: '6945',
    label: 'The Global College Lahore',
    value: 'The Global College Lahore'
  },
  {
    id: '6946',
    label: 'The Hang Seng University of Hong Kong',
    value: 'The Hang Seng University of Hong Kong'
  },
  {
    id: '6947',
    label: 'The Hong Kong Academy for Performing Arts',
    value: 'The Hong Kong Academy for Performing Arts'
  },
  {
    id: '6948',
    label: 'The Hong Kong Polytechnic University',
    value: 'The Hong Kong Polytechnic University'
  },
  {
    id: '6949',
    label: 'The Hong Kong University of Science and Technology',
    value: 'The Hong Kong University of Science and Technology'
  },
  {
    id: '6950',
    label: 'The Interdisciplinary Center Herzliya',
    value: 'The Interdisciplinary Center Herzliya'
  },
  {
    id: '6951',
    label: 'The Jewish Theological Seminary',
    value: 'The Jewish Theological Seminary'
  },
  {
    id: '6952',
    label: 'The Kingdom University',
    value: 'The Kingdom University'
  },
  {
    id: '6953',
    label: 'The Manchester Metropolitan University',
    value: 'The Manchester Metropolitan University'
  },
  {
    id: '6954',
    label: 'The New College',
    value: 'The New College'
  },
  {
    id: '6955',
    label: 'The Petroleum Institute',
    value: 'The Petroleum Institute'
  },
  {
    id: '6956',
    label: 'The Principia',
    value: 'The Principia'
  },
  {
    id: '6957',
    label: "The Queen's University Belfast",
    value: "The Queen's University Belfast"
  },
  {
    id: '6958',
    label: 'The Robert Gordon University',
    value: 'The Robert Gordon University'
  },
  {
    id: '6959',
    label: 'The Scripps Research Institute',
    value: 'The Scripps Research Institute'
  },
  {
    id: '6960',
    label: 'The Superior College',
    value: 'The Superior College'
  },
  {
    id: '6961',
    label: 'The University of Agriculture, Peshawar',
    value: 'The University of Agriculture, Peshawar'
  },
  {
    id: '6962',
    label: 'The University of Alabama',
    value: 'The University of Alabama'
  },
  {
    id: '6963',
    label: 'The University of Hong Kong',
    value: 'The University of Hong Kong'
  },
  {
    id: '6964',
    label: 'The University of Montana Western',
    value: 'The University of Montana Western'
  },
  {
    id: '6965',
    label: 'The University of North Carolina at Greensboro',
    value: 'The University of North Carolina at Greensboro'
  },
  {
    id: '6966',
    label: 'The University of Nottingham Ningbo China',
    value: 'The University of Nottingham Ningbo China'
  },
  {
    id: '6967',
    label: 'The University of Olivet',
    value: 'The University of Olivet'
  },
  {
    id: '6968',
    label: 'The University of Sheffield',
    value: 'The University of Sheffield'
  },
  {
    id: '6969',
    label: 'The University of Texas at Austin',
    value: 'The University of Texas at Austin'
  },
  {
    id: '6970',
    label: 'The University of Texas at Rio Grande Valley',
    value: 'The University of Texas at Rio Grande Valley'
  },
  {
    id: '6971',
    label: 'The University of Texas at San Antonio',
    value: 'The University of Texas at San Antonio'
  },
  {
    id: '6972',
    label: 'The World Islamic Sciences & Education University',
    value: 'The World Islamic Sciences & Education University'
  },
  {
    id: '6973',
    label: 'Theatre Academy Finland',
    value: 'Theatre Academy Finland'
  },
  {
    id: '6974',
    label: 'Theologische Fakultät Paderborn',
    value: 'Theologische Fakultät Paderborn'
  },
  {
    id: '6975',
    label: 'Theologische Hochschule Friedensau',
    value: 'Theologische Hochschule Friedensau'
  },
  {
    id: '6976',
    label: 'Thi Qar University',
    value: 'Thi Qar University'
  },
  {
    id: '6977',
    label: 'Thiagarajar College of Engineering',
    value: 'Thiagarajar College of Engineering'
  },
  {
    id: '6978',
    label: 'Thierry Graduate School of Leadership',
    value: 'Thierry Graduate School of Leadership'
  },
  {
    id: '6979',
    label: 'Third University of Rome',
    value: 'Third University of Rome'
  },
  {
    id: '6980',
    label: 'Thiruvalluvar University',
    value: 'Thiruvalluvar University'
  },
  {
    id: '6981',
    label: 'Thomas College',
    value: 'Thomas College'
  },
  {
    id: '6982',
    label: 'Thomas Edison State University',
    value: 'Thomas Edison State University'
  },
  {
    id: '6983',
    label: 'Thomas Jefferson University',
    value: 'Thomas Jefferson University'
  },
  {
    id: '6984',
    label: 'Thomas More College',
    value: 'Thomas More College'
  },
  {
    id: '6985',
    label: 'Thomas More Hogeschool',
    value: 'Thomas More Hogeschool'
  },
  {
    id: '6986',
    label: 'Thomas Nelson Community College',
    value: 'Thomas Nelson Community College'
  },
  {
    id: '6987',
    label: 'Thompson Rivers University',
    value: 'Thompson Rivers University'
  },
  {
    id: '6988',
    label: 'Three Rivers Community College',
    value: 'Three Rivers Community College'
  },
  {
    id: '6989',
    label: 'Three Rivers Community College',
    value: 'Three Rivers Community College'
  },
  {
    id: '6990',
    label: 'Thunderbird School of Global Management',
    value: 'Thunderbird School of Global Management'
  },
  {
    id: '6991',
    label: 'Tianjin Academy of Fine Art',
    value: 'Tianjin Academy of Fine Art'
  },
  {
    id: '6992',
    label: 'Tianjin Medical University',
    value: 'Tianjin Medical University'
  },
  {
    id: '6993',
    label: 'Tianjin Normal University',
    value: 'Tianjin Normal University'
  },
  {
    id: '6994',
    label: 'Tianjin Open University',
    value: 'Tianjin Open University'
  },
  {
    id: '6995',
    label: 'Tianjin Polytechnic University',
    value: 'Tianjin Polytechnic University'
  },
  {
    id: '6996',
    label: 'Tianjin University',
    value: 'Tianjin University'
  },
  {
    id: '6997',
    label: 'Tianjin University of Commerce',
    value: 'Tianjin University of Commerce'
  },
  {
    id: '6998',
    label: 'Tianjin University of Finance & Economics',
    value: 'Tianjin University of Finance & Economics'
  },
  {
    id: '6999',
    label: 'Tianjin University of Technology',
    value: 'Tianjin University of Technology'
  },
  {
    id: '7000',
    label: 'Tibet Agricultural and Animal Husbandry College',
    value: 'Tibet Agricultural and Animal Husbandry College'
  },
  {
    id: '7001',
    label: 'Tibet Tibetan Medical College',
    value: 'Tibet Tibetan Medical College'
  },
  {
    id: '7002',
    label: 'Tibet University',
    value: 'Tibet University'
  },
  {
    id: '7003',
    label: 'Tibet University of Nationalities',
    value: 'Tibet University of Nationalities'
  },
  {
    id: '7004',
    label: 'Tidewater Community College',
    value: 'Tidewater Community College'
  },
  {
    id: '7005',
    label: 'Tierärztliche Hochschule Hannover',
    value: 'Tierärztliche Hochschule Hannover'
  },
  {
    id: '7006',
    label: 'Tietgen Business College',
    value: 'Tietgen Business College'
  },
  {
    id: '7007',
    label: 'Tiffin University',
    value: 'Tiffin University'
  },
  {
    id: '7008',
    label: 'Tiffin University Prague',
    value: 'Tiffin University Prague'
  },
  {
    id: '7009',
    label: 'Tikrit University',
    value: 'Tikrit University'
  },
  {
    id: '7010',
    label: 'Tilak Maharashtra Vidyapeeth',
    value: 'Tilak Maharashtra Vidyapeeth'
  },
  {
    id: '7011',
    label: 'Tilburg University',
    value: 'Tilburg University'
  },
  {
    id: '7012',
    label: 'Tilka Manjhi Bhagalpur University',
    value: 'Tilka Manjhi Bhagalpur University'
  },
  {
    id: '7013',
    label: 'Tillamook Bay Community College',
    value: 'Tillamook Bay Community College'
  },
  {
    id: '7014',
    label: 'Timirjazev Moscow Academy of Agricultutre',
    value: 'Timirjazev Moscow Academy of Agricultutre'
  },
  {
    id: '7015',
    label: 'Tiraspol State University',
    value: 'Tiraspol State University'
  },
  {
    id: '7016',
    label: 'Tishreen University',
    value: 'Tishreen University'
  },
  {
    id: '7017',
    label: 'Tobb Economics and Technology University',
    value: 'Tobb Economics and Technology University'
  },
  {
    id: '7018',
    label: 'Togliatti State University',
    value: 'Togliatti State University'
  },
  {
    id: '7019',
    label: 'Toho College of Music',
    value: 'Toho College of Music'
  },
  {
    id: '7020',
    label: 'Toho Gakuen School of Music',
    value: 'Toho Gakuen School of Music'
  },
  {
    id: '7021',
    label: 'Toho University',
    value: 'Toho University'
  },
  {
    id: '7022',
    label: 'Tohoku Bunka Gakuen University',
    value: 'Tohoku Bunka Gakuen University'
  },
  {
    id: '7023',
    label: 'Tohoku College of Pharmacy',
    value: 'Tohoku College of Pharmacy'
  },
  {
    id: '7024',
    label: 'Tohoku Fukushi University',
    value: 'Tohoku Fukushi University'
  },
  {
    id: '7025',
    label: 'Tohoku Gakuin University',
    value: 'Tohoku Gakuin University'
  },
  {
    id: '7026',
    label: 'Tohoku Institute of Technology',
    value: 'Tohoku Institute of Technology'
  },
  {
    id: '7027',
    label: 'Tohoku University',
    value: 'Tohoku University'
  },
  {
    id: '7028',
    label: 'Tohoku University of Art and Design',
    value: 'Tohoku University of Art and Design'
  },
  {
    id: '7029',
    label: "Tohoku Women's College",
    value: "Tohoku Women's College"
  },
  {
    id: '7030',
    label: "Tohono O'Odham Community College",
    value: "Tohono O'Odham Community College"
  },
  {
    id: '7031',
    label: 'Tohwa University',
    value: 'Tohwa University'
  },
  {
    id: '7032',
    label: 'Toin University of Yokohama',
    value: 'Toin University of Yokohama'
  },
  {
    id: '7033',
    label: 'Tokai Gakuen University',
    value: 'Tokai Gakuen University'
  },
  {
    id: '7034',
    label: 'Tokai University Educational System',
    value: 'Tokai University Educational System'
  },
  {
    id: '7035',
    label: "Tokai Women's College",
    value: "Tokai Women's College"
  },
  {
    id: '7036',
    label: 'Tokiwa University',
    value: 'Tokiwa University'
  },
  {
    id: '7037',
    label: 'Tokoha Gakuen University',
    value: 'Tokoha Gakuen University'
  },
  {
    id: '7038',
    label: 'Tokushima Bunri University',
    value: 'Tokushima Bunri University'
  },
  {
    id: '7039',
    label: 'Tokushima University',
    value: 'Tokushima University'
  },
  {
    id: '7040',
    label: 'Tokuyama University',
    value: 'Tokuyama University'
  },
  {
    id: '7041',
    label: 'Tokyo College of Music',
    value: 'Tokyo College of Music'
  },
  {
    id: '7042',
    label: 'Tokyo Denki University',
    value: 'Tokyo Denki University'
  },
  {
    id: '7043',
    label: 'Tokyo Dental College',
    value: 'Tokyo Dental College'
  },
  {
    id: '7044',
    label: 'Tokyo Engineering University',
    value: 'Tokyo Engineering University'
  },
  {
    id: '7045',
    label: 'Tokyo Gakugei University',
    value: 'Tokyo Gakugei University'
  },
  {
    id: '7046',
    label: 'Tokyo Institute of Polytechnics',
    value: 'Tokyo Institute of Polytechnics'
  },
  {
    id: '7047',
    label: 'Tokyo Institute of Technology',
    value: 'Tokyo Institute of Technology'
  },
  {
    id: '7048',
    label: 'Tokyo International University',
    value: 'Tokyo International University'
  },
  {
    id: '7049',
    label: 'Tokyo Kasei University',
    value: 'Tokyo Kasei University'
  },
  {
    id: '7050',
    label: 'Tokyo Keizai University',
    value: 'Tokyo Keizai University'
  },
  {
    id: '7051',
    label: 'Tokyo Medical College',
    value: 'Tokyo Medical College'
  },
  {
    id: '7052',
    label: 'Tokyo Medical and Dental University',
    value: 'Tokyo Medical and Dental University'
  },
  {
    id: '7053',
    label: 'Tokyo Metropolitan Institute of Technology',
    value: 'Tokyo Metropolitan Institute of Technology'
  },
  {
    id: '7054',
    label: 'Tokyo Metropolitan University',
    value: 'Tokyo Metropolitan University'
  },
  {
    id: '7055',
    label: 'Tokyo Metropolitan University of Health Sciences',
    value: 'Tokyo Metropolitan University of Health Sciences'
  },
  {
    id: '7056',
    label: 'Tokyo National University of Fine Arts and Music',
    value: 'Tokyo National University of Fine Arts and Music'
  },
  {
    id: '7057',
    label: 'Tokyo University',
    value: 'Tokyo University'
  },
  {
    id: '7058',
    label: 'Tokyo University of Agriculture',
    value: 'Tokyo University of Agriculture'
  },
  {
    id: '7059',
    label: 'Tokyo University of Agriculture and Technology',
    value: 'Tokyo University of Agriculture and Technology'
  },
  {
    id: '7060',
    label: 'Tokyo University of Art and Design',
    value: 'Tokyo University of Art and Design'
  },
  {
    id: '7061',
    label: 'Tokyo University of Fisheries',
    value: 'Tokyo University of Fisheries'
  },
  {
    id: '7062',
    label: 'Tokyo University of Foreign Studies',
    value: 'Tokyo University of Foreign Studies'
  },
  {
    id: '7063',
    label: 'Tokyo University of Information Sciences',
    value: 'Tokyo University of Information Sciences'
  },
  {
    id: '7064',
    label: 'Tokyo University of Mercantile Marine',
    value: 'Tokyo University of Mercantile Marine'
  },
  {
    id: '7065',
    label: 'Tokyo University of Pharmacy and Life Science',
    value: 'Tokyo University of Pharmacy and Life Science'
  },
  {
    id: '7066',
    label: "Tokyo Woman's Christian University",
    value: "Tokyo Woman's Christian University"
  },
  {
    id: '7067',
    label: "Tokyo Women's College of Physical Education",
    value: "Tokyo Women's College of Physical Education"
  },
  {
    id: '7068',
    label: "Tokyo Women's Medial College",
    value: "Tokyo Women's Medial College"
  },
  {
    id: '7069',
    label: 'Tomakomai Komazawa University',
    value: 'Tomakomai Komazawa University'
  },
  {
    id: '7070',
    label: 'Tomas Bata University in Zlin',
    value: 'Tomas Bata University in Zlin'
  },
  {
    id: '7071',
    label: 'Tompkins Cortland Community College',
    value: 'Tompkins Cortland Community College'
  },
  {
    id: '7072',
    label: 'Tomsk Polytechnic University',
    value: 'Tomsk Polytechnic University'
  },
  {
    id: '7073',
    label: 'Tomsk State Pedagogical University',
    value: 'Tomsk State Pedagogical University'
  },
  {
    id: '7074',
    label: 'Tomsk State University',
    value: 'Tomsk State University'
  },
  {
    id: '7075',
    label: 'Tomsk State University of Architecture and Building',
    value: 'Tomsk State University of Architecture and Building'
  },
  {
    id: '7076',
    label: 'Tomsk State University of Control Systems and Radioelectronics',
    value: 'Tomsk State University of Control Systems and Radioelectronics'
  },
  {
    id: '7077',
    label: 'Tongji Medical University',
    value: 'Tongji Medical University'
  },
  {
    id: '7078',
    label: 'Tongji University',
    value: 'Tongji University'
  },
  {
    id: '7079',
    label: 'Tongmyung University of Information Technology',
    value: 'Tongmyung University of Information Technology'
  },
  {
    id: '7080',
    label: 'Torbat Heydarieh University of Medical Sciences',
    value: 'Torbat Heydarieh University of Medical Sciences'
  },
  {
    id: '7081',
    label: 'Toronto Metropolitan University',
    value: 'Toronto Metropolitan University'
  },
  {
    id: '7082',
    label: 'Toros University',
    value: 'Toros University'
  },
  {
    id: '7083',
    label: 'Torrens University Australia',
    value: 'Torrens University Australia'
  },
  {
    id: '7084',
    label: 'Tottori University',
    value: 'Tottori University'
  },
  {
    id: '7085',
    label: 'Tottori University of Environmental Studies',
    value: 'Tottori University of Environmental Studies'
  },
  {
    id: '7086',
    label: 'Tougaloo College',
    value: 'Tougaloo College'
  },
  {
    id: '7087',
    label: 'Touro College',
    value: 'Touro College'
  },
  {
    id: '7088',
    label: 'Towson University',
    value: 'Towson University'
  },
  {
    id: '7089',
    label: 'Toyama Medical and Pharmaceutical University',
    value: 'Toyama Medical and Pharmaceutical University'
  },
  {
    id: '7090',
    label: 'Toyama Prefectural University',
    value: 'Toyama Prefectural University'
  },
  {
    id: '7091',
    label: 'Toyama University',
    value: 'Toyama University'
  },
  {
    id: '7092',
    label: 'Toyama University of International Studies',
    value: 'Toyama University of International Studies'
  },
  {
    id: '7093',
    label: "Toyo Eiwa Women's University",
    value: "Toyo Eiwa Women's University"
  },
  {
    id: '7094',
    label: 'Toyo Gakuen University',
    value: 'Toyo Gakuen University'
  },
  {
    id: '7095',
    label: 'Toyo University',
    value: 'Toyo University'
  },
  {
    id: '7096',
    label: 'Toyohashi University of Technology',
    value: 'Toyohashi University of Technology'
  },
  {
    id: '7097',
    label: 'Toyota Technological Institute',
    value: 'Toyota Technological Institute'
  },
  {
    id: '7098',
    label: 'Trakia University Stara Zagora',
    value: 'Trakia University Stara Zagora'
  },
  {
    id: '7099',
    label: 'Trakya University',
    value: 'Trakya University'
  },
  {
    id: '7100',
    label: 'Transport and Telecommunication Institute',
    value: 'Transport and Telecommunication Institute'
  },
  {
    id: '7101',
    label: 'Transylvania University',
    value: 'Transylvania University'
  },
  {
    id: '7102',
    label: 'Treasure Valley Community College',
    value: 'Treasure Valley Community College'
  },
  {
    id: '7103',
    label: 'Trent University',
    value: 'Trent University'
  },
  {
    id: '7104',
    label: 'Trenton State College',
    value: 'Trenton State College'
  },
  {
    id: '7105',
    label: 'Tri-County Community College',
    value: 'Tri-County Community College'
  },
  {
    id: '7106',
    label: 'Tri-County Technical College',
    value: 'Tri-County Technical College'
  },
  {
    id: '7107',
    label: 'Tribhuvan University',
    value: 'Tribhuvan University'
  },
  {
    id: '7108',
    label: 'Tribhuvan University - Thapathali Campus',
    value: 'Tribhuvan University - Thapathali Campus'
  },
  {
    id: '7109',
    label: 'Tribhuvan University Kathmandu',
    value: 'Tribhuvan University Kathmandu'
  },
  {
    id: '7110',
    label: 'Trident Technical College',
    value: 'Trident Technical College'
  },
  {
    id: '7111',
    label: 'Trident University',
    value: 'Trident University'
  },
  {
    id: '7112',
    label: 'Trine University',
    value: 'Trine University'
  },
  {
    id: '7113',
    label: 'Trinidad State Junior College',
    value: 'Trinidad State Junior College'
  },
  {
    id: '7114',
    label: 'Trinity Bible College & Graduate School',
    value: 'Trinity Bible College & Graduate School'
  },
  {
    id: '7115',
    label: 'Trinity College (CT)',
    value: 'Trinity College (CT)'
  },
  {
    id: '7116',
    label: 'Trinity College (DC)',
    value: 'Trinity College (DC)'
  },
  {
    id: '7117',
    label: 'Trinity College Carmarthen',
    value: 'Trinity College Carmarthen'
  },
  {
    id: '7118',
    label: 'Trinity College of Music',
    value: 'Trinity College of Music'
  },
  {
    id: '7119',
    label: 'Trinity Theological Seminary',
    value: 'Trinity Theological Seminary'
  },
  {
    id: '7120',
    label: 'Trinity University',
    value: 'Trinity University'
  },
  {
    id: '7121',
    label: 'Trinity University School of Medicine',
    value: 'Trinity University School of Medicine'
  },
  {
    id: '7122',
    label: 'Trinity Valley Community College',
    value: 'Trinity Valley Community College'
  },
  {
    id: '7123',
    label: 'Trinity Western University',
    value: 'Trinity Western University'
  },
  {
    id: '7124',
    label: 'Tripura University',
    value: 'Tripura University'
  },
  {
    id: '7125',
    label: 'Trisakti Institute of Tourism',
    value: 'Trisakti Institute of Tourism'
  },
  {
    id: '7126',
    label: 'Triton College',
    value: 'Triton College'
  },
  {
    id: '7127',
    label: 'Troy State University',
    value: 'Troy State University'
  },
  {
    id: '7128',
    label: 'Troy University',
    value: 'Troy University'
  },
  {
    id: '7129',
    label: 'Truckee Meadows Community College',
    value: 'Truckee Meadows Community College'
  },
  {
    id: '7130',
    label: 'Truman State University',
    value: 'Truman State University'
  },
  {
    id: '7131',
    label: 'Tshwane University of Technology',
    value: 'Tshwane University of Technology'
  },
  {
    id: '7132',
    label: 'Tsinghua University',
    value: 'Tsinghua University'
  },
  {
    id: '7133',
    label: 'Tsuda College',
    value: 'Tsuda College'
  },
  {
    id: '7134',
    label: 'Tsukuba University',
    value: 'Tsukuba University'
  },
  {
    id: '7135',
    label: 'Tsuru University',
    value: 'Tsuru University'
  },
  {
    id: '7136',
    label: 'Tsurumi University',
    value: 'Tsurumi University'
  },
  {
    id: '7137',
    label: 'Tucson University',
    value: 'Tucson University'
  },
  {
    id: '7138',
    label: 'Tufts University',
    value: 'Tufts University'
  },
  {
    id: '7139',
    label: 'Tula State University',
    value: 'Tula State University'
  },
  {
    id: '7140',
    label: 'Tulane University',
    value: 'Tulane University'
  },
  {
    id: '7141',
    label: 'Tulsa Community College',
    value: 'Tulsa Community College'
  },
  {
    id: '7142',
    label: 'Tumaini University - Iringa University College',
    value: 'Tumaini University - Iringa University College'
  },
  {
    id: '7143',
    label: 'Tumkur University',
    value: 'Tumkur University'
  },
  {
    id: '7144',
    label: 'Tunceli University',
    value: 'Tunceli University'
  },
  {
    id: '7145',
    label: 'Tunghai University',
    value: 'Tunghai University'
  },
  {
    id: '7146',
    label: 'Tunku Abdul Rahman Chinese College',
    value: 'Tunku Abdul Rahman Chinese College'
  },
  {
    id: '7147',
    label: 'Tunku Syed Sirajuddin Polytechnic',
    value: 'Tunku Syed Sirajuddin Polytechnic'
  },
  {
    id: '7148',
    label: 'Tunxis Community College',
    value: 'Tunxis Community College'
  },
  {
    id: '7149',
    label: 'Turar Ryskulov Kazakh Economic University',
    value: 'Turar Ryskulov Kazakh Economic University'
  },
  {
    id: '7150',
    label: 'Turin Polytechnic University In Tashkent',
    value: 'Turin Polytechnic University In Tashkent'
  },
  {
    id: '7151',
    label: 'Turkish Aeronautical Association University',
    value: 'Turkish Aeronautical Association University'
  },
  {
    id: '7152',
    label: 'Turkish Naval Academy',
    value: 'Turkish Naval Academy'
  },
  {
    id: '7153',
    label: 'Turku School of Economics and Business Administration',
    value: 'Turku School of Economics and Business Administration'
  },
  {
    id: '7154',
    label: 'Tusculum College',
    value: 'Tusculum College'
  },
  {
    id: '7155',
    label: 'Tuskegee University',
    value: 'Tuskegee University'
  },
  {
    id: '7156',
    label: 'Tuva State University',
    value: 'Tuva State University'
  },
  {
    id: '7157',
    label: 'Tver State Medical Academy',
    value: 'Tver State Medical Academy'
  },
  {
    id: '7158',
    label: 'Tver State Technical University',
    value: 'Tver State Technical University'
  },
  {
    id: '7159',
    label: 'Tver State University',
    value: 'Tver State University'
  },
  {
    id: '7160',
    label: 'Tyler Junior College',
    value: 'Tyler Junior College'
  },
  {
    id: '7161',
    label: 'Tyumen State Agricultural Academy',
    value: 'Tyumen State Agricultural Academy'
  },
  {
    id: '7162',
    label: 'Tyumen State University',
    value: 'Tyumen State University'
  },
  {
    id: '7163',
    label: 'Tyumen State University of Gas and Oil',
    value: 'Tyumen State University of Gas and Oil'
  },
  {
    id: '7164',
    label: 'Tzu Chi College of Medicine and Humanities',
    value: 'Tzu Chi College of Medicine and Humanities'
  },
  {
    id: '7165',
    label: 'Télé-université, Université du Québec',
    value: 'Télé-université, Université du Québec'
  },
  {
    id: '7166',
    label: 'Türk Hava Kurumu Üniversitesi',
    value: 'Türk Hava Kurumu Üniversitesi'
  },
  {
    id: '7167',
    label: 'Türkisch-Deutsche Universität',
    value: 'Türkisch-Deutsche Universität'
  },
  {
    id: '7168',
    label: 'U1 University',
    value: 'U1 University'
  },
  {
    id: '7169',
    label: 'UC Hastings College of the Law',
    value: 'UC Hastings College of the Law'
  },
  {
    id: '7170',
    label: 'UCSI University',
    value: 'UCSI University'
  },
  {
    id: '7171',
    label: 'UMIT Tirol',
    value: 'UMIT Tirol'
  },
  {
    id: '7172',
    label: 'UNESCO-IHE Institute for Water Education',
    value: 'UNESCO-IHE Institute for Water Education'
  },
  {
    id: '7173',
    label: 'UNINOVE',
    value: 'UNINOVE'
  },
  {
    id: '7174',
    label: 'Ubonratchathani University',
    value: 'Ubonratchathani University'
  },
  {
    id: '7175',
    label: 'Udmurt State University',
    value: 'Udmurt State University'
  },
  {
    id: '7176',
    label: 'Ueno Gakuen College',
    value: 'Ueno Gakuen College'
  },
  {
    id: '7177',
    label: 'Ufa State Aviation Technical University',
    value: 'Ufa State Aviation Technical University'
  },
  {
    id: '7178',
    label: 'Ufa State Petroleum Technological University',
    value: 'Ufa State Petroleum Technological University'
  },
  {
    id: '7179',
    label: 'Ufuk University',
    value: 'Ufuk University'
  },
  {
    id: '7180',
    label: 'Uganda Christian University',
    value: 'Uganda Christian University'
  },
  {
    id: '7181',
    label: "Uganda Martyr's University",
    value: "Uganda Martyr's University"
  },
  {
    id: '7182',
    label: 'Uiduk University',
    value: 'Uiduk University'
  },
  {
    id: '7183',
    label: 'Uinversity of Babylon',
    value: 'Uinversity of Babylon'
  },
  {
    id: '7184',
    label: 'Ukhta State Technical University',
    value: 'Ukhta State Technical University'
  },
  {
    id: '7185',
    label: 'Ukrainian Academy of Banking',
    value: 'Ukrainian Academy of Banking'
  },
  {
    id: '7186',
    label: 'Ukrainian Academy of Pharmacy',
    value: 'Ukrainian Academy of Pharmacy'
  },
  {
    id: '7187',
    label: 'Ukrainian Medical Stomatological Academy',
    value: 'Ukrainian Medical Stomatological Academy'
  },
  {
    id: '7188',
    label: 'Ukrainian National Forestry University',
    value: 'Ukrainian National Forestry University'
  },
  {
    id: '7189',
    label: 'Ulaanbaatar University',
    value: 'Ulaanbaatar University'
  },
  {
    id: '7190',
    label: 'Ulsan National Institute of Science and Technology',
    value: 'Ulsan National Institute of Science and Technology'
  },
  {
    id: '7191',
    label: 'Ulsan University',
    value: 'Ulsan University'
  },
  {
    id: '7192',
    label: 'Ulster County Community College',
    value: 'Ulster County Community College'
  },
  {
    id: '7193',
    label: 'Ulster University',
    value: 'Ulster University'
  },
  {
    id: '7194',
    label: 'Uludag University',
    value: 'Uludag University'
  },
  {
    id: '7195',
    label: 'Ulyanovsk Higher School of Civil Aviation',
    value: 'Ulyanovsk Higher School of Civil Aviation'
  },
  {
    id: '7196',
    label: 'Ulyanovsk State Agricultural Academy',
    value: 'Ulyanovsk State Agricultural Academy'
  },
  {
    id: '7197',
    label: 'Ulyanovsk State Pedagogical University',
    value: 'Ulyanovsk State Pedagogical University'
  },
  {
    id: '7198',
    label: 'Ulyanovsk State Technical University',
    value: 'Ulyanovsk State Technical University'
  },
  {
    id: '7199',
    label: 'Ulyanovsk State University',
    value: 'Ulyanovsk State University'
  },
  {
    id: '7200',
    label: "Umaru Musa Yar'Adua University",
    value: "Umaru Musa Yar'Adua University"
  },
  {
    id: '7201',
    label: 'Umea University',
    value: 'Umea University'
  },
  {
    id: '7202',
    label: 'Umm Al-Qura University',
    value: 'Umm Al-Qura University'
  },
  {
    id: '7203',
    label: 'Ummah University',
    value: 'Ummah University'
  },
  {
    id: '7204',
    label: 'Umpqua Community College',
    value: 'Umpqua Community College'
  },
  {
    id: '7205',
    label: 'Umutara Polytechnic',
    value: 'Umutara Polytechnic'
  },
  {
    id: '7206',
    label: 'Ungku Omar Premier Polytechnic',
    value: 'Ungku Omar Premier Polytechnic'
  },
  {
    id: '7207',
    label: 'Uniformed Services University of the Health Sciences',
    value: 'Uniformed Services University of the Health Sciences'
  },
  {
    id: '7208',
    label: 'Union College',
    value: 'Union College'
  },
  {
    id: '7209',
    label: 'Union County College',
    value: 'Union County College'
  },
  {
    id: '7210',
    label: 'Union Institute',
    value: 'Union Institute'
  },
  {
    id: '7211',
    label: 'Union University',
    value: 'Union University'
  },
  {
    id: '7212',
    label: 'United Arab Emirates University',
    value: 'United Arab Emirates University'
  },
  {
    id: '7213',
    label: 'United Business Institute',
    value: 'United Business Institute'
  },
  {
    id: '7214',
    label: 'United International University',
    value: 'United International University'
  },
  {
    id: '7215',
    label: 'United Medical and Dental Schools, University of London',
    value: 'United Medical and Dental Schools, University of London'
  },
  {
    id: '7216',
    label: 'United Nations University',
    value: 'United Nations University'
  },
  {
    id: '7217',
    label: 'United States Air Force Academy',
    value: 'United States Air Force Academy'
  },
  {
    id: '7218',
    label: 'United States Coast Guard Academy',
    value: 'United States Coast Guard Academy'
  },
  {
    id: '7219',
    label: 'United States International University',
    value: 'United States International University'
  },
  {
    id: '7220',
    label: 'United States International University',
    value: 'United States International University'
  },
  {
    id: '7221',
    label: 'United States Merchant Marine Academy',
    value: 'United States Merchant Marine Academy'
  },
  {
    id: '7222',
    label: 'United States Military Academy',
    value: 'United States Military Academy'
  },
  {
    id: '7223',
    label: 'United States Naval Academy',
    value: 'United States Naval Academy'
  },
  {
    id: '7224',
    label: 'Unitomo Surabaya',
    value: 'Unitomo Surabaya'
  },
  {
    id: '7225',
    label: 'Unity  University',
    value: 'Unity  University'
  },
  {
    id: '7226',
    label: 'Unity Environmental College',
    value: 'Unity Environmental College'
  },
  {
    id: '7227',
    label: 'Univerisity of Mpumalanga',
    value: 'Univerisity of Mpumalanga'
  },
  {
    id: '7228',
    label: 'Universidad "Arturo Prat"',
    value: 'Universidad "Arturo Prat"'
  },
  {
    id: '7229',
    label: 'Universidad "Juan Agustín Maza"',
    value: 'Universidad "Juan Agustín Maza"'
  },
  {
    id: '7230',
    label: 'Universidad APEC',
    value: 'Universidad APEC'
  },
  {
    id: '7231',
    label: 'Universidad Abierta Interactiva',
    value: 'Universidad Abierta Interactiva'
  },
  {
    id: '7232',
    label: 'Universidad Abierta Interamericana',
    value: 'Universidad Abierta Interamericana'
  },
  {
    id: '7233',
    label: 'Universidad Abierta Para Adultos',
    value: 'Universidad Abierta Para Adultos'
  },
  {
    id: '7234',
    label: 'Universidad Abierta y a Distancia de Panama',
    value: 'Universidad Abierta y a Distancia de Panama'
  },
  {
    id: '7235',
    label: 'Universidad Academia de Humanismo Cristiano',
    value: 'Universidad Academia de Humanismo Cristiano'
  },
  {
    id: '7236',
    label: 'Universidad Adolfo Ibáñez',
    value: 'Universidad Adolfo Ibáñez'
  },
  {
    id: '7237',
    label: 'Universidad Adventista Dominicana',
    value: 'Universidad Adventista Dominicana'
  },
  {
    id: '7238',
    label: 'Universidad Adventista de Bolivia',
    value: 'Universidad Adventista de Bolivia'
  },
  {
    id: '7239',
    label: 'Universidad Adventista de Centro América',
    value: 'Universidad Adventista de Centro América'
  },
  {
    id: '7240',
    label: 'Universidad Adventista de Chile',
    value: 'Universidad Adventista de Chile'
  },
  {
    id: '7241',
    label: 'Universidad Adventista de Colombia',
    value: 'Universidad Adventista de Colombia'
  },
  {
    id: '7242',
    label: 'Universidad Adventista del Plata',
    value: 'Universidad Adventista del Plata'
  },
  {
    id: '7243',
    label: 'Universidad Agraria del Ecuador',
    value: 'Universidad Agraria del Ecuador'
  },
  {
    id: '7244',
    label: 'Universidad Agroforestal Fernando A.Meriño',
    value: 'Universidad Agroforestal Fernando A.Meriño'
  },
  {
    id: '7245',
    label: 'Universidad Alas Peruanas',
    value: 'Universidad Alas Peruanas'
  },
  {
    id: '7246',
    label: 'Universidad Albert Einstein',
    value: 'Universidad Albert Einstein'
  },
  {
    id: '7247',
    label: 'Universidad Alberto Hurtado',
    value: 'Universidad Alberto Hurtado'
  },
  {
    id: '7248',
    label: 'Universidad Alfonso X El Sabio',
    value: 'Universidad Alfonso X El Sabio'
  },
  {
    id: '7249',
    label: 'Universidad Amazonica de Pando',
    value: 'Universidad Amazonica de Pando'
  },
  {
    id: '7250',
    label: 'Universidad Americana',
    value: 'Universidad Americana'
  },
  {
    id: '7251',
    label: 'Universidad Americana',
    value: 'Universidad Americana'
  },
  {
    id: '7252',
    label: 'Universidad Ana G. Méndez',
    value: 'Universidad Ana G. Méndez'
  },
  {
    id: '7253',
    label: 'Universidad Andina Nestor Caceares Velasquez',
    value: 'Universidad Andina Nestor Caceares Velasquez'
  },
  {
    id: '7254',
    label: 'Universidad Andina Simón Bolivar',
    value: 'Universidad Andina Simón Bolivar'
  },
  {
    id: '7255',
    label: 'Universidad Andina Simón Bolívar',
    value: 'Universidad Andina Simón Bolívar'
  },
  {
    id: '7256',
    label: 'Universidad Andina del Cusco',
    value: 'Universidad Andina del Cusco'
  },
  {
    id: '7257',
    label: 'Universidad Antonio Nariño',
    value: 'Universidad Antonio Nariño'
  },
  {
    id: '7258',
    label: 'Universidad Antonio de Nebrija',
    value: 'Universidad Antonio de Nebrija'
  },
  {
    id: '7259',
    label: 'Universidad Anáhuac',
    value: 'Universidad Anáhuac'
  },
  {
    id: '7260',
    label: 'Universidad Anáhuac del Sur',
    value: 'Universidad Anáhuac del Sur'
  },
  {
    id: '7261',
    label: 'Universidad Arcis',
    value: 'Universidad Arcis'
  },
  {
    id: '7262',
    label: 'Universidad Argentina "John F. Kennedy"',
    value: 'Universidad Argentina "John F. Kennedy"'
  },
  {
    id: '7263',
    label: 'Universidad Argentina de la Empresa',
    value: 'Universidad Argentina de la Empresa'
  },
  {
    id: '7264',
    label: 'Universidad Atlantida Argentina',
    value: 'Universidad Atlantida Argentina'
  },
  {
    id: '7265',
    label: 'Universidad Austral Buenos Aires',
    value: 'Universidad Austral Buenos Aires'
  },
  {
    id: '7266',
    label: 'Universidad Austral de Chile',
    value: 'Universidad Austral de Chile'
  },
  {
    id: '7267',
    label: 'Universidad Autonoma  de Durango',
    value: 'Universidad Autonoma  de Durango'
  },
  {
    id: '7268',
    label: 'Universidad Autonoma España de Durango',
    value: 'Universidad Autonoma España de Durango'
  },
  {
    id: '7269',
    label: 'Universidad Autonoma Monterrey',
    value: 'Universidad Autonoma Monterrey'
  },
  {
    id: '7270',
    label: 'Universidad Autonoma de Chiriqui',
    value: 'Universidad Autonoma de Chiriqui'
  },
  {
    id: '7271',
    label: 'Universidad Autonoma del Sur',
    value: 'Universidad Autonoma del Sur'
  },
  {
    id: '7272',
    label: 'Universidad Autónoma "Benito Juárez" de Oaxaca',
    value: 'Universidad Autónoma "Benito Juárez" de Oaxaca'
  },
  {
    id: '7273',
    label: 'Universidad Autónoma Agraria "Antonio Narro"',
    value: 'Universidad Autónoma Agraria "Antonio Narro"'
  },
  {
    id: '7274',
    label: 'Universidad Autónoma Gabriel René Moreno',
    value: 'Universidad Autónoma Gabriel René Moreno'
  },
  {
    id: '7275',
    label: 'Universidad Autónoma Latinoamericana',
    value: 'Universidad Autónoma Latinoamericana'
  },
  {
    id: '7276',
    label: 'Universidad Autónoma Metropolitana',
    value: 'Universidad Autónoma Metropolitana'
  },
  {
    id: '7277',
    label: 'Universidad Autónoma Metropolitana - Azcapotzalco',
    value: 'Universidad Autónoma Metropolitana - Azcapotzalco'
  },
  {
    id: '7278',
    label: 'Universidad Autónoma Metropolitana - Iztapalapa',
    value: 'Universidad Autónoma Metropolitana - Iztapalapa'
  },
  {
    id: '7279',
    label: 'Universidad Autónoma Metropolitana - Xochimilco',
    value: 'Universidad Autónoma Metropolitana - Xochimilco'
  },
  {
    id: '7280',
    label: 'Universidad Autónoma Tomás Frías',
    value: 'Universidad Autónoma Tomás Frías'
  },
  {
    id: '7281',
    label: 'Universidad Autónoma de Aguascalientes',
    value: 'Universidad Autónoma de Aguascalientes'
  },
  {
    id: '7282',
    label: 'Universidad Autónoma de Asunción',
    value: 'Universidad Autónoma de Asunción'
  },
  {
    id: '7283',
    label: 'Universidad Autónoma de Baja California',
    value: 'Universidad Autónoma de Baja California'
  },
  {
    id: '7284',
    label: 'Universidad Autónoma de Baja California Sur',
    value: 'Universidad Autónoma de Baja California Sur'
  },
  {
    id: '7285',
    label: 'Universidad Autónoma de Bucaramanga',
    value: 'Universidad Autónoma de Bucaramanga'
  },
  {
    id: '7286',
    label: 'Universidad Autónoma de Campeche',
    value: 'Universidad Autónoma de Campeche'
  },
  {
    id: '7287',
    label: 'Universidad Autónoma de Centro América',
    value: 'Universidad Autónoma de Centro América'
  },
  {
    id: '7288',
    label: 'Universidad Autónoma de Chapingo',
    value: 'Universidad Autónoma de Chapingo'
  },
  {
    id: '7289',
    label: 'Universidad Autónoma de Chiapas',
    value: 'Universidad Autónoma de Chiapas'
  },
  {
    id: '7290',
    label: 'Universidad Autónoma de Chihuahua',
    value: 'Universidad Autónoma de Chihuahua'
  },
  {
    id: '7291',
    label: 'Universidad Autónoma de Ciudad Juárez',
    value: 'Universidad Autónoma de Ciudad Juárez'
  },
  {
    id: '7292',
    label: 'Universidad Autónoma de Coahuila',
    value: 'Universidad Autónoma de Coahuila'
  },
  {
    id: '7293',
    label: 'Universidad Autónoma de Colombia',
    value: 'Universidad Autónoma de Colombia'
  },
  {
    id: '7294',
    label: 'Universidad Autónoma de Entre Ríos',
    value: 'Universidad Autónoma de Entre Ríos'
  },
  {
    id: '7295',
    label: 'Universidad Autónoma de Fresnillo',
    value: 'Universidad Autónoma de Fresnillo'
  },
  {
    id: '7296',
    label: 'Universidad Autónoma de Guadalajara',
    value: 'Universidad Autónoma de Guadalajara'
  },
  {
    id: '7297',
    label: 'Universidad Autónoma de Guerrero',
    value: 'Universidad Autónoma de Guerrero'
  },
  {
    id: '7298',
    label: 'Universidad Autónoma de Las Américas',
    value: 'Universidad Autónoma de Las Américas'
  },
  {
    id: '7299',
    label: 'Universidad Autónoma de Madrid',
    value: 'Universidad Autónoma de Madrid'
  },
  {
    id: '7300',
    label: 'Universidad Autónoma de Manizales',
    value: 'Universidad Autónoma de Manizales'
  },
  {
    id: '7301',
    label: 'Universidad Autónoma de Nayarit',
    value: 'Universidad Autónoma de Nayarit'
  },
  {
    id: '7302',
    label: 'Universidad Autónoma de Nuevo León',
    value: 'Universidad Autónoma de Nuevo León'
  },
  {
    id: '7303',
    label: 'Universidad Autónoma de Occidente',
    value: 'Universidad Autónoma de Occidente'
  },
  {
    id: '7304',
    label: 'Universidad Autónoma de Querétaro',
    value: 'Universidad Autónoma de Querétaro'
  },
  {
    id: '7305',
    label: 'Universidad Autónoma de San Luis Potosí',
    value: 'Universidad Autónoma de San Luis Potosí'
  },
  {
    id: '7306',
    label: 'Universidad Autónoma de Santa Ana',
    value: 'Universidad Autónoma de Santa Ana'
  },
  {
    id: '7307',
    label: 'Universidad Autónoma de Santo Domingo',
    value: 'Universidad Autónoma de Santo Domingo'
  },
  {
    id: '7308',
    label: 'Universidad Autónoma de Sinaloa',
    value: 'Universidad Autónoma de Sinaloa'
  },
  {
    id: '7309',
    label: 'Universidad Autónoma de Tamaulipas',
    value: 'Universidad Autónoma de Tamaulipas'
  },
  {
    id: '7310',
    label: 'Universidad Autónoma de Tlaxcala',
    value: 'Universidad Autónoma de Tlaxcala'
  },
  {
    id: '7311',
    label: 'Universidad Autónoma de Veracruz Villa Rica',
    value: 'Universidad Autónoma de Veracruz Villa Rica'
  },
  {
    id: '7312',
    label: 'Universidad Autónoma de Yucatán',
    value: 'Universidad Autónoma de Yucatán'
  },
  {
    id: '7313',
    label: 'Universidad Autónoma de Zacatecas',
    value: 'Universidad Autónoma de Zacatecas'
  },
  {
    id: '7314',
    label: 'Universidad Autónoma de la Ciudad de México',
    value: 'Universidad Autónoma de la Ciudad de México'
  },
  {
    id: '7315',
    label: 'Universidad Autónoma de la Laguna',
    value: 'Universidad Autónoma de la Laguna'
  },
  {
    id: '7316',
    label: 'Universidad Autónoma del Beni "José Ballivián"',
    value: 'Universidad Autónoma del Beni "José Ballivián"'
  },
  {
    id: '7317',
    label: 'Universidad Autónoma del Caribe',
    value: 'Universidad Autónoma del Caribe'
  },
  {
    id: '7318',
    label: 'Universidad Autónoma del Carmen',
    value: 'Universidad Autónoma del Carmen'
  },
  {
    id: '7319',
    label: 'Universidad Autónoma del Estado de Hidalgo',
    value: 'Universidad Autónoma del Estado de Hidalgo'
  },
  {
    id: '7320',
    label: 'Universidad Autónoma del Estado de Morelos',
    value: 'Universidad Autónoma del Estado de Morelos'
  },
  {
    id: '7321',
    label: 'Universidad Autónoma del Estado de México',
    value: 'Universidad Autónoma del Estado de México'
  },
  {
    id: '7322',
    label: 'Universidad Autónoma del Noreste',
    value: 'Universidad Autónoma del Noreste'
  },
  {
    id: '7323',
    label: 'Universidad Autónoma del Paraguay',
    value: 'Universidad Autónoma del Paraguay'
  },
  {
    id: '7324',
    label: 'Universidad Bellas Artes',
    value: 'Universidad Bellas Artes'
  },
  {
    id: '7325',
    label: "Universidad Bernardo O'Higgins",
    value: "Universidad Bernardo O'Higgins"
  },
  {
    id: '7326',
    label: 'Universidad Bicentenaria de Aragua',
    value: 'Universidad Bicentenaria de Aragua'
  },
  {
    id: '7327',
    label: 'Universidad Blas Pascal',
    value: 'Universidad Blas Pascal'
  },
  {
    id: '7328',
    label: 'Universidad Bolivariana',
    value: 'Universidad Bolivariana'
  },
  {
    id: '7329',
    label: 'Universidad Bolivariana de Venezuela',
    value: 'Universidad Bolivariana de Venezuela'
  },
  {
    id: '7330',
    label: 'Universidad Braulio Carrillo',
    value: 'Universidad Braulio Carrillo'
  },
  {
    id: '7331',
    label: 'Universidad CAECE, Buenos Aires',
    value: 'Universidad CAECE, Buenos Aires'
  },
  {
    id: '7332',
    label: 'Universidad CES',
    value: 'Universidad CES'
  },
  {
    id: '7333',
    label: 'Universidad CEU Fernando III',
    value: 'Universidad CEU Fernando III'
  },
  {
    id: '7334',
    label: 'Universidad Capitain General Gerardo Barrios',
    value: 'Universidad Capitain General Gerardo Barrios'
  },
  {
    id: '7335',
    label: 'Universidad Cardenal Herrera-CEU',
    value: 'Universidad Cardenal Herrera-CEU'
  },
  {
    id: '7336',
    label: 'Universidad Carlos III de Madrid',
    value: 'Universidad Carlos III de Madrid'
  },
  {
    id: '7337',
    label: 'Universidad Casa Grande',
    value: 'Universidad Casa Grande'
  },
  {
    id: '7338',
    label: 'Universidad Catolica "Redemptoris Mater"',
    value: 'Universidad Catolica "Redemptoris Mater"'
  },
  {
    id: '7339',
    label: 'Universidad Catolica de Azogues',
    value: 'Universidad Catolica de Azogues'
  },
  {
    id: '7340',
    label: 'Universidad Catolica de La Santísima Concepción',
    value: 'Universidad Catolica de La Santísima Concepción'
  },
  {
    id: '7341',
    label: 'Universidad Catolica de Temuco',
    value: 'Universidad Catolica de Temuco'
  },
  {
    id: '7342',
    label: 'Universidad Catolica de Valparaiso',
    value: 'Universidad Catolica de Valparaiso'
  },
  {
    id: '7343',
    label: 'Universidad Catolica del Maule',
    value: 'Universidad Catolica del Maule'
  },
  {
    id: '7344',
    label: 'Universidad Catolica del Norte',
    value: 'Universidad Catolica del Norte'
  },
  {
    id: '7345',
    label: 'Universidad Católica Andres Bello',
    value: 'Universidad Católica Andres Bello'
  },
  {
    id: '7346',
    label: 'Universidad Católica Anselmo Llorente',
    value: 'Universidad Católica Anselmo Llorente'
  },
  {
    id: '7347',
    label: 'Universidad Católica Argentina Santa Maria de los Buenos Aires',
    value: 'Universidad Católica Argentina Santa Maria de los Buenos Aires'
  },
  {
    id: '7348',
    label: 'Universidad Católica Boliviana, Cochabamba',
    value: 'Universidad Católica Boliviana, Cochabamba'
  },
  {
    id: '7349',
    label: 'Universidad Católica Boliviana, La Paz',
    value: 'Universidad Católica Boliviana, La Paz'
  },
  {
    id: '7350',
    label: 'Universidad Católica Boliviana, Santa Cruz',
    value: 'Universidad Católica Boliviana, Santa Cruz'
  },
  {
    id: '7351',
    label: 'Universidad Católica Boliviana, Tarija',
    value: 'Universidad Católica Boliviana, Tarija'
  },
  {
    id: '7352',
    label: 'Universidad Católica Cardenal Raúl Silva Henríquez',
    value: 'Universidad Católica Cardenal Raúl Silva Henríquez'
  },
  {
    id: '7353',
    label: 'Universidad Católica Cecilio Acosta',
    value: 'Universidad Católica Cecilio Acosta'
  },
  {
    id: '7354',
    label: 'Universidad Católica Madre y Maestra',
    value: 'Universidad Católica Madre y Maestra'
  },
  {
    id: '7355',
    label: 'Universidad Católica Nordestana',
    value: 'Universidad Católica Nordestana'
  },
  {
    id: '7356',
    label: 'Universidad Católica Nuestra Senora de la Asunción',
    value: 'Universidad Católica Nuestra Senora de la Asunción'
  },
  {
    id: '7357',
    label: 'Universidad Católica Popular del Risaralda',
    value: 'Universidad Católica Popular del Risaralda'
  },
  {
    id: '7358',
    label: 'Universidad Católica San Antonio',
    value: 'Universidad Católica San Antonio'
  },
  {
    id: '7359',
    label: 'Universidad Católica Santa María La Antigua',
    value: 'Universidad Católica Santa María La Antigua'
  },
  {
    id: '7360',
    label: 'Universidad Católica Santo Toribio de Mogrovejo',
    value: 'Universidad Católica Santo Toribio de Mogrovejo'
  },
  {
    id: '7361',
    label: 'Universidad Católica Tecnológica del Cibao',
    value: 'Universidad Católica Tecnológica del Cibao'
  },
  {
    id: '7362',
    label: 'Universidad Católica de Colombia',
    value: 'Universidad Católica de Colombia'
  },
  {
    id: '7363',
    label: 'Universidad Católica de Cuenca',
    value: 'Universidad Católica de Cuenca'
  },
  {
    id: '7364',
    label: 'Universidad Católica de Cuyo',
    value: 'Universidad Católica de Cuyo'
  },
  {
    id: '7365',
    label: 'Universidad Católica de Córdoba',
    value: 'Universidad Católica de Córdoba'
  },
  {
    id: '7366',
    label: 'Universidad Católica de Honduras',
    value: 'Universidad Católica de Honduras'
  },
  {
    id: '7367',
    label: 'Universidad Católica de La Plata',
    value: 'Universidad Católica de La Plata'
  },
  {
    id: '7368',
    label: 'Universidad Católica de Manizales',
    value: 'Universidad Católica de Manizales'
  },
  {
    id: '7369',
    label: 'Universidad Católica de Occidente',
    value: 'Universidad Católica de Occidente'
  },
  {
    id: '7370',
    label: 'Universidad Católica de Salta',
    value: 'Universidad Católica de Salta'
  },
  {
    id: '7371',
    label: 'Universidad Católica de Santa Fé',
    value: 'Universidad Católica de Santa Fé'
  },
  {
    id: '7372',
    label: 'Universidad Católica de Santa María',
    value: 'Universidad Católica de Santa María'
  },
  {
    id: '7373',
    label: 'Universidad Católica de Santiago de Guayaquil',
    value: 'Universidad Católica de Santiago de Guayaquil'
  },
  {
    id: '7374',
    label: 'Universidad Católica de Santiago del Estero',
    value: 'Universidad Católica de Santiago del Estero'
  },
  {
    id: '7375',
    label: 'Universidad Católica de Santo Domingo',
    value: 'Universidad Católica de Santo Domingo'
  },
  {
    id: '7376',
    label: 'Universidad Católica de Valencia',
    value: 'Universidad Católica de Valencia'
  },
  {
    id: '7377',
    label: 'Universidad Católica de Ávila',
    value: 'Universidad Católica de Ávila'
  },
  {
    id: '7378',
    label: 'Universidad Católica del Norte',
    value: 'Universidad Católica del Norte'
  },
  {
    id: '7379',
    label: 'Universidad Católica del Oriente',
    value: 'Universidad Católica del Oriente'
  },
  {
    id: '7380',
    label: 'Universidad Católica del Táchira',
    value: 'Universidad Católica del Táchira'
  },
  {
    id: '7381',
    label: 'Universidad Católica del Uruguay',
    value: 'Universidad Católica del Uruguay'
  },
  {
    id: '7382',
    label: 'Universidad Central',
    value: 'Universidad Central'
  },
  {
    id: '7383',
    label: 'Universidad Central',
    value: 'Universidad Central'
  },
  {
    id: '7384',
    label: 'Universidad Central',
    value: 'Universidad Central'
  },
  {
    id: '7385',
    label: 'Universidad Central',
    value: 'Universidad Central'
  },
  {
    id: '7386',
    label: 'Universidad Central de Bayamón',
    value: 'Universidad Central de Bayamón'
  },
  {
    id: '7387',
    label: 'Universidad Central de Las Villas',
    value: 'Universidad Central de Las Villas'
  },
  {
    id: '7388',
    label: 'Universidad Central de Venezuela',
    value: 'Universidad Central de Venezuela'
  },
  {
    id: '7389',
    label: 'Universidad Central del Caribe',
    value: 'Universidad Central del Caribe'
  },
  {
    id: '7390',
    label: 'Universidad Central del Ecuador',
    value: 'Universidad Central del Ecuador'
  },
  {
    id: '7391',
    label: 'Universidad Central del Este',
    value: 'Universidad Central del Este'
  },
  {
    id: '7392',
    label: 'Universidad Central del Valle del Cauca',
    value: 'Universidad Central del Valle del Cauca'
  },
  {
    id: '7393',
    label: 'Universidad Centro Occidental Lisandro Alvarado',
    value: 'Universidad Centro Occidental Lisandro Alvarado'
  },
  {
    id: '7394',
    label: 'Universidad Centroamericana',
    value: 'Universidad Centroamericana'
  },
  {
    id: '7395',
    label: 'Universidad Centroamericana "José Simeón Canas"',
    value: 'Universidad Centroamericana "José Simeón Canas"'
  },
  {
    id: '7396',
    label: 'Universidad Centroamericana de Ciencias Empresariales (UCEM)',
    value: 'Universidad Centroamericana de Ciencias Empresariales (UCEM)'
  },
  {
    id: '7397',
    label: 'Universidad Champagnat',
    value: 'Universidad Champagnat'
  },
  {
    id: '7398',
    label: 'Universidad Champagnat',
    value: 'Universidad Champagnat'
  },
  {
    id: '7399',
    label: 'Universidad Chapultepéc',
    value: 'Universidad Chapultepéc'
  },
  {
    id: '7400',
    label: 'Universidad Chileno Britanica de Cultura',
    value: 'Universidad Chileno Britanica de Cultura'
  },
  {
    id: '7401',
    label: 'Universidad Ciencias Comerciales',
    value: 'Universidad Ciencias Comerciales'
  },
  {
    id: '7402',
    label: 'Universidad Cientifica Latinoamericana de Hidalgo',
    value: 'Universidad Cientifica Latinoamericana de Hidalgo'
  },
  {
    id: '7403',
    label: 'Universidad Cientifica del Sur',
    value: 'Universidad Cientifica del Sur'
  },
  {
    id: '7404',
    label: 'Universidad Columbia del Paraguay',
    value: 'Universidad Columbia del Paraguay'
  },
  {
    id: '7405',
    label: 'Universidad Complutense de Madrid',
    value: 'Universidad Complutense de Madrid'
  },
  {
    id: '7406',
    label: 'Universidad Comunera',
    value: 'Universidad Comunera'
  },
  {
    id: '7407',
    label: 'Universidad Cooperativa de Colombia',
    value: 'Universidad Cooperativa de Colombia'
  },
  {
    id: '7408',
    label: 'Universidad Cristiana de las Asambleas de Dios',
    value: 'Universidad Cristiana de las Asambleas de Dios'
  },
  {
    id: '7409',
    label: 'Universidad Cristóbal Colón',
    value: 'Universidad Cristóbal Colón'
  },
  {
    id: '7410',
    label: 'Universidad Cuauhtémoc',
    value: 'Universidad Cuauhtémoc'
  },
  {
    id: '7411',
    label: 'Universidad César Vallejo',
    value: 'Universidad César Vallejo'
  },
  {
    id: '7412',
    label: 'Universidad Diego Portales',
    value: 'Universidad Diego Portales'
  },
  {
    id: '7413',
    label: 'Universidad Distral "Francisco José de Caldas"',
    value: 'Universidad Distral "Francisco José de Caldas"'
  },
  {
    id: '7414',
    label: 'Universidad Domingo Savio',
    value: 'Universidad Domingo Savio'
  },
  {
    id: '7415',
    label: 'Universidad Dominicana O&M',
    value: 'Universidad Dominicana O&M'
  },
  {
    id: '7416',
    label: 'Universidad Dominico-Americana',
    value: 'Universidad Dominico-Americana'
  },
  {
    id: '7417',
    label: 'Universidad Don Bosco',
    value: 'Universidad Don Bosco'
  },
  {
    id: '7418',
    label: 'Universidad Don Vasco',
    value: 'Universidad Don Vasco'
  },
  {
    id: '7419',
    label: 'Universidad Dr. Andreas Bello',
    value: 'Universidad Dr. Andreas Bello'
  },
  {
    id: '7420',
    label: 'Universidad Dr. Jose Matias Delgado',
    value: 'Universidad Dr. Jose Matias Delgado'
  },
  {
    id: '7421',
    label: 'Universidad Dr. Rafael Belloso Chacín',
    value: 'Universidad Dr. Rafael Belloso Chacín'
  },
  {
    id: '7422',
    label: 'Universidad EAFIT',
    value: 'Universidad EAFIT'
  },
  {
    id: '7423',
    label: 'Universidad EAN',
    value: 'Universidad EAN'
  },
  {
    id: '7424',
    label: 'Universidad El Bosque',
    value: 'Universidad El Bosque'
  },
  {
    id: '7425',
    label: 'Universidad Empresarial',
    value: 'Universidad Empresarial'
  },
  {
    id: '7426',
    label: 'Universidad Empresarial Mateo Kuljis',
    value: 'Universidad Empresarial Mateo Kuljis'
  },
  {
    id: '7427',
    label: 'Universidad Empresarial Siglio 21',
    value: 'Universidad Empresarial Siglio 21'
  },
  {
    id: '7428',
    label: 'Universidad España de Durango',
    value: 'Universidad España de Durango'
  },
  {
    id: '7429',
    label: 'Universidad Especializada de Las Americas',
    value: 'Universidad Especializada de Las Americas'
  },
  {
    id: '7430',
    label: 'Universidad Estatal a Distancia',
    value: 'Universidad Estatal a Distancia'
  },
  {
    id: '7431',
    label: 'Universidad Estatal de Bolívar',
    value: 'Universidad Estatal de Bolívar'
  },
  {
    id: '7432',
    label: 'Universidad Eugenio Maria de Hostos',
    value: 'Universidad Eugenio Maria de Hostos'
  },
  {
    id: '7433',
    label: 'Universidad Europea de Madrid',
    value: 'Universidad Europea de Madrid'
  },
  {
    id: '7434',
    label: 'Universidad Europea del Atlántico',
    value: 'Universidad Europea del Atlántico'
  },
  {
    id: '7435',
    label: 'Universidad Evangélica de El Salvador',
    value: 'Universidad Evangélica de El Salvador'
  },
  {
    id: '7436',
    label: 'Universidad Experimental Felix Adam',
    value: 'Universidad Experimental Felix Adam'
  },
  {
    id: '7437',
    label: 'Universidad Externado de Colombia',
    value: 'Universidad Externado de Colombia'
  },
  {
    id: '7438',
    label: 'Universidad Federico Henríquez y Carvajal',
    value: 'Universidad Federico Henríquez y Carvajal'
  },
  {
    id: '7439',
    label: 'Universidad Femenina del Sagrado Corazón',
    value: 'Universidad Femenina del Sagrado Corazón'
  },
  {
    id: '7440',
    label: 'Universidad Fermin Toro',
    value: 'Universidad Fermin Toro'
  },
  {
    id: '7441',
    label: 'Universidad Fidélitas',
    value: 'Universidad Fidélitas'
  },
  {
    id: '7442',
    label: 'Universidad Finis Terrae',
    value: 'Universidad Finis Terrae'
  },
  {
    id: '7443',
    label: 'Universidad Franciscana de México',
    value: 'Universidad Franciscana de México'
  },
  {
    id: '7444',
    label: 'Universidad Francisco Gavidia',
    value: 'Universidad Francisco Gavidia'
  },
  {
    id: '7445',
    label: 'Universidad Francisco Marroquín',
    value: 'Universidad Francisco Marroquín'
  },
  {
    id: '7446',
    label: 'Universidad Francisco de Aguirre',
    value: 'Universidad Francisco de Aguirre'
  },
  {
    id: '7447',
    label: 'Universidad Francisco de Paula Santander',
    value: 'Universidad Francisco de Paula Santander'
  },
  {
    id: '7448',
    label: 'Universidad Francisco de Vitoria',
    value: 'Universidad Francisco de Vitoria'
  },
  {
    id: '7449',
    label: 'Universidad Franco-Mexicana',
    value: 'Universidad Franco-Mexicana'
  },
  {
    id: '7450',
    label: 'Universidad Fray Luca Paccioli',
    value: 'Universidad Fray Luca Paccioli'
  },
  {
    id: '7451',
    label: 'Universidad Fundepos Alma Mater',
    value: 'Universidad Fundepos Alma Mater'
  },
  {
    id: '7452',
    label: 'Universidad Gabriela Mistral',
    value: 'Universidad Gabriela Mistral'
  },
  {
    id: '7453',
    label: 'Universidad Galileo',
    value: 'Universidad Galileo'
  },
  {
    id: '7454',
    label: 'Universidad Gran Mariscal de Ayacucho',
    value: 'Universidad Gran Mariscal de Ayacucho'
  },
  {
    id: '7455',
    label: 'Universidad Guadalajara Lamar',
    value: 'Universidad Guadalajara Lamar'
  },
  {
    id: '7456',
    label: 'Universidad Hispanoamericana',
    value: 'Universidad Hispanoamericana'
  },
  {
    id: '7457',
    label: 'Universidad Hispanoamericana',
    value: 'Universidad Hispanoamericana'
  },
  {
    id: '7458',
    label: 'Universidad Hispanoamericana',
    value: 'Universidad Hispanoamericana'
  },
  {
    id: '7459',
    label: 'Universidad ICESI',
    value: 'Universidad ICESI'
  },
  {
    id: '7460',
    label: 'Universidad INCCA de Colombia',
    value: 'Universidad INCCA de Colombia'
  },
  {
    id: '7461',
    label: 'Universidad Iberoamericana',
    value: 'Universidad Iberoamericana'
  },
  {
    id: '7462',
    label: 'Universidad Iberoamericana',
    value: 'Universidad Iberoamericana'
  },
  {
    id: '7463',
    label: 'Universidad Iberoamericana de Ciencia y Tecnologia',
    value: 'Universidad Iberoamericana de Ciencia y Tecnologia'
  },
  {
    id: '7464',
    label: 'Universidad Iberoamericana de Ciencias y Tecnologia',
    value: 'Universidad Iberoamericana de Ciencias y Tecnologia'
  },
  {
    id: '7465',
    label: 'Universidad Iberoamericana, Campus León',
    value: 'Universidad Iberoamericana, Campus León'
  },
  {
    id: '7466',
    label: 'Universidad Industrial de Santander',
    value: 'Universidad Industrial de Santander'
  },
  {
    id: '7467',
    label: 'Universidad Insurgentes Plantel León',
    value: 'Universidad Insurgentes Plantel León'
  },
  {
    id: '7468',
    label: 'Universidad Interamericana',
    value: 'Universidad Interamericana'
  },
  {
    id: '7469',
    label: 'Universidad Interamericana de Educacion a Distancia de Panama',
    value: 'Universidad Interamericana de Educacion a Distancia de Panama'
  },
  {
    id: '7470',
    label: 'Universidad Interamericana de Puerto Rico',
    value: 'Universidad Interamericana de Puerto Rico'
  },
  {
    id: '7471',
    label: 'Universidad Interamericana de Puerto Rico, Bayamón',
    value: 'Universidad Interamericana de Puerto Rico, Bayamón'
  },
  {
    id: '7472',
    label: 'Universidad Interamericana de Puerto Rico, San Germán',
    value: 'Universidad Interamericana de Puerto Rico, San Germán'
  },
  {
    id: '7473',
    label: 'Universidad Intercontinental',
    value: 'Universidad Intercontinental'
  },
  {
    id: '7474',
    label: 'Universidad Internacional Isabel I',
    value: 'Universidad Internacional Isabel I'
  },
  {
    id: '7475',
    label: 'Universidad Internacional Jefferson',
    value: 'Universidad Internacional Jefferson'
  },
  {
    id: '7476',
    label: 'Universidad Internacional Menéndez Pelayo',
    value: 'Universidad Internacional Menéndez Pelayo'
  },
  {
    id: '7477',
    label: 'Universidad Internacional SEK, Quito',
    value: 'Universidad Internacional SEK, Quito'
  },
  {
    id: '7478',
    label: 'Universidad Internacional de Andalucía',
    value: 'Universidad Internacional de Andalucía'
  },
  {
    id: '7479',
    label: 'Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza',
    value: 'Universidad Internacional de Andalucía, Sede Antonio Machado de Baeza'
  },
  {
    id: '7480',
    label: 'Universidad Internacional de Andalucía, Sede La Rábida',
    value: 'Universidad Internacional de Andalucía, Sede La Rábida'
  },
  {
    id: '7481',
    label: 'Universidad Internacional de Integración de América Latina',
    value: 'Universidad Internacional de Integración de América Latina'
  },
  {
    id: '7482',
    label: 'Universidad Internacional de Valencia',
    value: 'Universidad Internacional de Valencia'
  },
  {
    id: '7483',
    label: 'Universidad Internacional de las Américas',
    value: 'Universidad Internacional de las Américas'
  },
  {
    id: '7484',
    label: 'Universidad Internacional del Ecuador',
    value: 'Universidad Internacional del Ecuador'
  },
  {
    id: '7485',
    label: 'Universidad International SEK, Santiago',
    value: 'Universidad International SEK, Santiago'
  },
  {
    id: '7486',
    label: 'Universidad Isaac Newton',
    value: 'Universidad Isaac Newton'
  },
  {
    id: '7487',
    label: 'Universidad Isaac Newton',
    value: 'Universidad Isaac Newton'
  },
  {
    id: '7488',
    label: 'Universidad Itaca',
    value: 'Universidad Itaca'
  },
  {
    id: '7489',
    label: 'Universidad José Antonio Páez',
    value: 'Universidad José Antonio Páez'
  },
  {
    id: '7490',
    label: 'Universidad José Cecilio del Valle',
    value: 'Universidad José Cecilio del Valle'
  },
  {
    id: '7491',
    label: 'Universidad José Maria Vargas',
    value: 'Universidad José Maria Vargas'
  },
  {
    id: '7492',
    label: 'Universidad José Santos Ossa',
    value: 'Universidad José Santos Ossa'
  },
  {
    id: '7493',
    label: 'Universidad Juan Misael Saracho',
    value: 'Universidad Juan Misael Saracho'
  },
  {
    id: '7494',
    label: 'Universidad Justo Sierra',
    value: 'Universidad Justo Sierra'
  },
  {
    id: '7495',
    label: 'Universidad Juárez Autónoma de Tabasco',
    value: 'Universidad Juárez Autónoma de Tabasco'
  },
  {
    id: '7496',
    label: 'Universidad Juárez del Estado de Durango',
    value: 'Universidad Juárez del Estado de Durango'
  },
  {
    id: '7497',
    label: 'Universidad Jóse Vasconcelos',
    value: 'Universidad Jóse Vasconcelos'
  },
  {
    id: '7498',
    label: 'Universidad Kino',
    value: 'Universidad Kino'
  },
  {
    id: '7499',
    label: 'Universidad La Gran Colombia',
    value: 'Universidad La Gran Colombia'
  },
  {
    id: '7500',
    label: 'Universidad La Republica',
    value: 'Universidad La Republica'
  },
  {
    id: '7501',
    label: 'Universidad La Salle',
    value: 'Universidad La Salle'
  },
  {
    id: '7502',
    label: 'Universidad La Salle Cuernavaca',
    value: 'Universidad La Salle Cuernavaca'
  },
  {
    id: '7503',
    label: 'Universidad Laica "Eloy Alfaro" de Manabi',
    value: 'Universidad Laica "Eloy Alfaro" de Manabi'
  },
  {
    id: '7504',
    label: 'Universidad Laica "Vicente Rocafuerte" de Guayaquil',
    value: 'Universidad Laica "Vicente Rocafuerte" de Guayaquil'
  },
  {
    id: '7505',
    label: 'Universidad Lasallista Benavente',
    value: 'Universidad Lasallista Benavente'
  },
  {
    id: '7506',
    label: 'Universidad Latina',
    value: 'Universidad Latina'
  },
  {
    id: '7507',
    label: 'Universidad Latina de Costa Rica',
    value: 'Universidad Latina de Costa Rica'
  },
  {
    id: '7508',
    label: 'Universidad Latina de Panamá',
    value: 'Universidad Latina de Panamá'
  },
  {
    id: '7509',
    label: 'Universidad Latinoamericana',
    value: 'Universidad Latinoamericana'
  },
  {
    id: '7510',
    label: 'Universidad Latinoamericana de Ciencia y Tecnologia, Panamá',
    value: 'Universidad Latinoamericana de Ciencia y Tecnologia, Panamá'
  },
  {
    id: '7511',
    label: 'Universidad Latinoamericana de Ciencia y Tecnología',
    value: 'Universidad Latinoamericana de Ciencia y Tecnología'
  },
  {
    id: '7512',
    label: 'Universidad Libre de Colombia',
    value: 'Universidad Libre de Colombia'
  },
  {
    id: '7513',
    label: 'Universidad Libre de Costa Rica',
    value: 'Universidad Libre de Costa Rica'
  },
  {
    id: '7514',
    label: 'Universidad Los Andes',
    value: 'Universidad Los Andes'
  },
  {
    id: '7515',
    label: 'Universidad Los Angeles de Chimbote',
    value: 'Universidad Los Angeles de Chimbote'
  },
  {
    id: '7516',
    label: 'Universidad Loyola Andalucía',
    value: 'Universidad Loyola Andalucía'
  },
  {
    id: '7517',
    label: 'Universidad Luterana Salvadorena',
    value: 'Universidad Luterana Salvadorena'
  },
  {
    id: '7518',
    label: 'Universidad Madero',
    value: 'Universidad Madero'
  },
  {
    id: '7519',
    label: 'Universidad Maimónides',
    value: 'Universidad Maimónides'
  },
  {
    id: '7520',
    label: 'Universidad Marcelino Champagnat',
    value: 'Universidad Marcelino Champagnat'
  },
  {
    id: '7521',
    label: 'Universidad Mariana',
    value: 'Universidad Mariana'
  },
  {
    id: '7522',
    label: 'Universidad Mariano Egaña',
    value: 'Universidad Mariano Egaña'
  },
  {
    id: '7523',
    label: 'Universidad Mariano Gálvez',
    value: 'Universidad Mariano Gálvez'
  },
  {
    id: '7524',
    label: 'Universidad Maritima de Chile',
    value: 'Universidad Maritima de Chile'
  },
  {
    id: '7525',
    label: 'Universidad Mayor',
    value: 'Universidad Mayor'
  },
  {
    id: '7526',
    label: 'Universidad Mayor de San Andrés',
    value: 'Universidad Mayor de San Andrés'
  },
  {
    id: '7527',
    label: 'Universidad Mayor de San Simón',
    value: 'Universidad Mayor de San Simón'
  },
  {
    id: '7528',
    label: 'Universidad Mesoamericana de San Agustín',
    value: 'Universidad Mesoamericana de San Agustín'
  },
  {
    id: '7529',
    label: 'Universidad Metropolitana',
    value: 'Universidad Metropolitana'
  },
  {
    id: '7530',
    label: 'Universidad Metropolitana',
    value: 'Universidad Metropolitana'
  },
  {
    id: '7531',
    label: 'Universidad Metropolitana Castro Carazo',
    value: 'Universidad Metropolitana Castro Carazo'
  },
  {
    id: '7532',
    label: 'Universidad Metropolitana Latin Campus',
    value: 'Universidad Metropolitana Latin Campus'
  },
  {
    id: '7533',
    label: 'Universidad Metropolitana de Asunción',
    value: 'Universidad Metropolitana de Asunción'
  },
  {
    id: '7534',
    label: 'Universidad Metropolitana de Ciencias de la Educación',
    value: 'Universidad Metropolitana de Ciencias de la Educación'
  },
  {
    id: '7535',
    label: 'Universidad Metropolitana de Honduras',
    value: 'Universidad Metropolitana de Honduras'
  },
  {
    id: '7536',
    label: 'Universidad Metropolitana de Monterrey',
    value: 'Universidad Metropolitana de Monterrey'
  },
  {
    id: '7537',
    label: 'Universidad Mexicana',
    value: 'Universidad Mexicana'
  },
  {
    id: '7538',
    label: 'Universidad Mexicana del Noreste',
    value: 'Universidad Mexicana del Noreste'
  },
  {
    id: '7539',
    label: 'Universidad Michoacana de San Nicolás de Hidalgo',
    value: 'Universidad Michoacana de San Nicolás de Hidalgo'
  },
  {
    id: '7540',
    label: 'Universidad Miguel Hernández de Elche',
    value: 'Universidad Miguel Hernández de Elche'
  },
  {
    id: '7541',
    label: 'Universidad Miguel de Cervantes',
    value: 'Universidad Miguel de Cervantes'
  },
  {
    id: '7542',
    label: 'Universidad Militar Nueva Granada',
    value: 'Universidad Militar Nueva Granada'
  },
  {
    id: '7543',
    label: 'Universidad Modular Abierta',
    value: 'Universidad Modular Abierta'
  },
  {
    id: '7544',
    label: 'Universidad Monsenor Oscar Arnulfo Romeo',
    value: 'Universidad Monsenor Oscar Arnulfo Romeo'
  },
  {
    id: '7545',
    label: 'Universidad Monteávila',
    value: 'Universidad Monteávila'
  },
  {
    id: '7546',
    label: 'Universidad Motolinía del Pedegral',
    value: 'Universidad Motolinía del Pedegral'
  },
  {
    id: '7547',
    label: 'Universidad Motolinía del Pedregal',
    value: 'Universidad Motolinía del Pedregal'
  },
  {
    id: '7548',
    label: 'Universidad México Americana del Norte',
    value: 'Universidad México Americana del Norte'
  },
  {
    id: '7549',
    label: 'Universidad México Internacional',
    value: 'Universidad México Internacional'
  },
  {
    id: '7550',
    label: 'Universidad NUR',
    value: 'Universidad NUR'
  },
  {
    id: '7551',
    label: 'Universidad Nacional',
    value: 'Universidad Nacional'
  },
  {
    id: '7552',
    label: 'Universidad Nacional Abierta',
    value: 'Universidad Nacional Abierta'
  },
  {
    id: '7553',
    label: 'Universidad Nacional Abierta y a Distancia',
    value: 'Universidad Nacional Abierta y a Distancia'
  },
  {
    id: '7554',
    label: 'Universidad Nacional Agraria',
    value: 'Universidad Nacional Agraria'
  },
  {
    id: '7555',
    label: 'Universidad Nacional Agraria La Molina',
    value: 'Universidad Nacional Agraria La Molina'
  },
  {
    id: '7556',
    label: 'Universidad Nacional Agraria de la Selva',
    value: 'Universidad Nacional Agraria de la Selva'
  },
  {
    id: '7557',
    label: 'Universidad Nacional Andrés Bello',
    value: 'Universidad Nacional Andrés Bello'
  },
  {
    id: '7558',
    label: 'Universidad Nacional Autonoma de Nicaragua',
    value: 'Universidad Nacional Autonoma de Nicaragua'
  },
  {
    id: '7559',
    label: 'Universidad Nacional Autónoma de Honduras',
    value: 'Universidad Nacional Autónoma de Honduras'
  },
  {
    id: '7560',
    label: 'Universidad Nacional Autónoma de México',
    value: 'Universidad Nacional Autónoma de México'
  },
  {
    id: '7561',
    label: 'Universidad Nacional Daniel Alcides Carrion',
    value: 'Universidad Nacional Daniel Alcides Carrion'
  },
  {
    id: '7562',
    label: 'Universidad Nacional Evangélica',
    value: 'Universidad Nacional Evangélica'
  },
  {
    id: '7563',
    label: 'Universidad Nacional Experimental "Francisco de Miranda"',
    value: 'Universidad Nacional Experimental "Francisco de Miranda"'
  },
  {
    id: '7564',
    label: 'Universidad Nacional Experimental "Rafael Maria Baralt"',
    value: 'Universidad Nacional Experimental "Rafael Maria Baralt"'
  },
  {
    id: '7565',
    label: 'Universidad Nacional Experimental "Simón Rodriguez"',
    value: 'Universidad Nacional Experimental "Simón Rodriguez"'
  },
  {
    id: '7566',
    label: 'Universidad Nacional Experimental Politécnica "Antonio José de Sucre"',
    value: 'Universidad Nacional Experimental Politécnica "Antonio José de Sucre"'
  },
  {
    id: '7567',
    label: 'Universidad Nacional Experimental Romulo Gallegos',
    value: 'Universidad Nacional Experimental Romulo Gallegos'
  },
  {
    id: '7568',
    label: 'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum"',
    value: 'Universidad Nacional Experimental Sur del Lago "Jesús Maria Semprum"'
  },
  {
    id: '7569',
    label: 'Universidad Nacional Experimental de Guayana',
    value: 'Universidad Nacional Experimental de Guayana'
  },
  {
    id: '7570',
    label: 'Universidad Nacional Experimental de la Fuerza Armada',
    value: 'Universidad Nacional Experimental de la Fuerza Armada'
  },
  {
    id: '7571',
    label: 'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora"',
    value: 'Universidad Nacional Experimental de los Llanos Occidentales "Ezequiel Zamora"'
  },
  {
    id: '7572',
    label: 'Universidad Nacional Experimental del Táchira',
    value: 'Universidad Nacional Experimental del Táchira'
  },
  {
    id: '7573',
    label: 'Universidad Nacional Federico Villarreal',
    value: 'Universidad Nacional Federico Villarreal'
  },
  {
    id: '7574',
    label: 'Universidad Nacional Hermilio Valdizan',
    value: 'Universidad Nacional Hermilio Valdizan'
  },
  {
    id: '7575',
    label: 'Universidad Nacional Jorge Basadre Grohmann',
    value: 'Universidad Nacional Jorge Basadre Grohmann'
  },
  {
    id: '7576',
    label: 'Universidad Nacional José Faustino Sánchez Carrión',
    value: 'Universidad Nacional José Faustino Sánchez Carrión'
  },
  {
    id: '7577',
    label: 'Universidad Nacional Mayor de San Marcos',
    value: 'Universidad Nacional Mayor de San Marcos'
  },
  {
    id: '7578',
    label: 'Universidad Nacional Pedro Henríquez Ureña',
    value: 'Universidad Nacional Pedro Henríquez Ureña'
  },
  {
    id: '7579',
    label: 'Universidad Nacional Pedro Ruíz Gallo',
    value: 'Universidad Nacional Pedro Ruíz Gallo'
  },
  {
    id: '7580',
    label: 'Universidad Nacional San Luis Gonzaga',
    value: 'Universidad Nacional San Luis Gonzaga'
  },
  {
    id: '7581',
    label: 'Universidad Nacional Santiago Antúnez de Mayolo',
    value: 'Universidad Nacional Santiago Antúnez de Mayolo'
  },
  {
    id: '7582',
    label: 'Universidad Nacional Siglo XX Llallagua',
    value: 'Universidad Nacional Siglo XX Llallagua'
  },
  {
    id: '7583',
    label: 'Universidad Nacional de Asunción',
    value: 'Universidad Nacional de Asunción'
  },
  {
    id: '7584',
    label: 'Universidad Nacional de Cajamarca',
    value: 'Universidad Nacional de Cajamarca'
  },
  {
    id: '7585',
    label: 'Universidad Nacional de Catamarca',
    value: 'Universidad Nacional de Catamarca'
  },
  {
    id: '7586',
    label: 'Universidad Nacional de Chimborazo',
    value: 'Universidad Nacional de Chimborazo'
  },
  {
    id: '7587',
    label: 'Universidad Nacional de Colombia',
    value: 'Universidad Nacional de Colombia'
  },
  {
    id: '7588',
    label: 'Universidad Nacional de Cuyo',
    value: 'Universidad Nacional de Cuyo'
  },
  {
    id: '7589',
    label: 'Universidad Nacional de Córdoba',
    value: 'Universidad Nacional de Córdoba'
  },
  {
    id: '7590',
    label: 'Universidad Nacional de Educación Enrique Guzmán y Valle',
    value: 'Universidad Nacional de Educación Enrique Guzmán y Valle'
  },
  {
    id: '7591',
    label: 'Universidad Nacional de Educación a Distancia',
    value: 'Universidad Nacional de Educación a Distancia'
  },
  {
    id: '7592',
    label: 'Universidad Nacional de Entre Ríos',
    value: 'Universidad Nacional de Entre Ríos'
  },
  {
    id: '7593',
    label: 'Universidad Nacional de Formosa',
    value: 'Universidad Nacional de Formosa'
  },
  {
    id: '7594',
    label: 'Universidad Nacional de General San Martín',
    value: 'Universidad Nacional de General San Martín'
  },
  {
    id: '7595',
    label: 'Universidad Nacional de General Sarmiento',
    value: 'Universidad Nacional de General Sarmiento'
  },
  {
    id: '7596',
    label: 'Universidad Nacional de Guinea Ecuatorial',
    value: 'Universidad Nacional de Guinea Ecuatorial'
  },
  {
    id: '7597',
    label: 'Universidad Nacional de Ingenieria',
    value: 'Universidad Nacional de Ingenieria'
  },
  {
    id: '7598',
    label: 'Universidad Nacional de Ingeniería',
    value: 'Universidad Nacional de Ingeniería'
  },
  {
    id: '7599',
    label: 'Universidad Nacional de Jujuy',
    value: 'Universidad Nacional de Jujuy'
  },
  {
    id: '7600',
    label: 'Universidad Nacional de La Libertad, Trujillo',
    value: 'Universidad Nacional de La Libertad, Trujillo'
  },
  {
    id: '7601',
    label: 'Universidad Nacional de La Matanza',
    value: 'Universidad Nacional de La Matanza'
  },
  {
    id: '7602',
    label: 'Universidad Nacional de La Pampa',
    value: 'Universidad Nacional de La Pampa'
  },
  {
    id: '7603',
    label: 'Universidad Nacional de La Plata',
    value: 'Universidad Nacional de La Plata'
  },
  {
    id: '7604',
    label: 'Universidad Nacional de La Rioja',
    value: 'Universidad Nacional de La Rioja'
  },
  {
    id: '7605',
    label: 'Universidad Nacional de Lanus',
    value: 'Universidad Nacional de Lanus'
  },
  {
    id: '7606',
    label: 'Universidad Nacional de Loja',
    value: 'Universidad Nacional de Loja'
  },
  {
    id: '7607',
    label: 'Universidad Nacional de Lomas de Zamora',
    value: 'Universidad Nacional de Lomas de Zamora'
  },
  {
    id: '7608',
    label: 'Universidad Nacional de Luján',
    value: 'Universidad Nacional de Luján'
  },
  {
    id: '7609',
    label: 'Universidad Nacional de Mar del Plata',
    value: 'Universidad Nacional de Mar del Plata'
  },
  {
    id: '7610',
    label: 'Universidad Nacional de Misiones',
    value: 'Universidad Nacional de Misiones'
  },
  {
    id: '7611',
    label: 'Universidad Nacional de Piura',
    value: 'Universidad Nacional de Piura'
  },
  {
    id: '7612',
    label: 'Universidad Nacional de Quilmes',
    value: 'Universidad Nacional de Quilmes'
  },
  {
    id: '7613',
    label: 'Universidad Nacional de Rosario',
    value: 'Universidad Nacional de Rosario'
  },
  {
    id: '7614',
    label: 'Universidad Nacional de Río Cuarto',
    value: 'Universidad Nacional de Río Cuarto'
  },
  {
    id: '7615',
    label: 'Universidad Nacional de Salta',
    value: 'Universidad Nacional de Salta'
  },
  {
    id: '7616',
    label: 'Universidad Nacional de San Agustin',
    value: 'Universidad Nacional de San Agustin'
  },
  {
    id: '7617',
    label: 'Universidad Nacional de San Antonio Abad',
    value: 'Universidad Nacional de San Antonio Abad'
  },
  {
    id: '7618',
    label: 'Universidad Nacional de San Cristóbal de Huamanga',
    value: 'Universidad Nacional de San Cristóbal de Huamanga'
  },
  {
    id: '7619',
    label: 'Universidad Nacional de San Juan',
    value: 'Universidad Nacional de San Juan'
  },
  {
    id: '7620',
    label: 'Universidad Nacional de San Luis',
    value: 'Universidad Nacional de San Luis'
  },
  {
    id: '7621',
    label: 'Universidad Nacional de San Martín',
    value: 'Universidad Nacional de San Martín'
  },
  {
    id: '7622',
    label: 'Universidad Nacional de Santa - Chimbote',
    value: 'Universidad Nacional de Santa - Chimbote'
  },
  {
    id: '7623',
    label: 'Universidad Nacional de Santiago del Estero',
    value: 'Universidad Nacional de Santiago del Estero'
  },
  {
    id: '7624',
    label: 'Universidad Nacional de Tres de Febrero',
    value: 'Universidad Nacional de Tres de Febrero'
  },
  {
    id: '7625',
    label: 'Universidad Nacional de Tucumán',
    value: 'Universidad Nacional de Tucumán'
  },
  {
    id: '7626',
    label: 'Universidad Nacional de Tumbes',
    value: 'Universidad Nacional de Tumbes'
  },
  {
    id: '7627',
    label: 'Universidad Nacional de Ucayali',
    value: 'Universidad Nacional de Ucayali'
  },
  {
    id: '7628',
    label: 'Universidad Nacional de Villa María',
    value: 'Universidad Nacional de Villa María'
  },
  {
    id: '7629',
    label: 'Universidad Nacional de la Amazonía Peruana',
    value: 'Universidad Nacional de la Amazonía Peruana'
  },
  {
    id: '7630',
    label: 'Universidad Nacional de la Patagonia "San Juan Bosco"',
    value: 'Universidad Nacional de la Patagonia "San Juan Bosco"'
  },
  {
    id: '7631',
    label: 'Universidad Nacional de la Patagonia Austral',
    value: 'Universidad Nacional de la Patagonia Austral'
  },
  {
    id: '7632',
    label: 'Universidad Nacional del Altiplano',
    value: 'Universidad Nacional del Altiplano'
  },
  {
    id: '7633',
    label: 'Universidad Nacional del Callao',
    value: 'Universidad Nacional del Callao'
  },
  {
    id: '7634',
    label: 'Universidad Nacional del Centro de la Provincia de Buenos Aires',
    value: 'Universidad Nacional del Centro de la Provincia de Buenos Aires'
  },
  {
    id: '7635',
    label: 'Universidad Nacional del Centro del Perú',
    value: 'Universidad Nacional del Centro del Perú'
  },
  {
    id: '7636',
    label: 'Universidad Nacional del Comahue',
    value: 'Universidad Nacional del Comahue'
  },
  {
    id: '7637',
    label: 'Universidad Nacional del Este',
    value: 'Universidad Nacional del Este'
  },
  {
    id: '7638',
    label: 'Universidad Nacional del Litoral',
    value: 'Universidad Nacional del Litoral'
  },
  {
    id: '7639',
    label: 'Universidad Nacional del Nordeste',
    value: 'Universidad Nacional del Nordeste'
  },
  {
    id: '7640',
    label: 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires',
    value: 'Universidad Nacional del Noroeste de la Provincia de Buenos Aires'
  },
  {
    id: '7641',
    label: 'Universidad Nacional del Sur',
    value: 'Universidad Nacional del Sur'
  },
  {
    id: '7642',
    label: 'Universidad Nicaragüense de Ciencia y Tecnológica',
    value: 'Universidad Nicaragüense de Ciencia y Tecnológica'
  },
  {
    id: '7643',
    label: 'Universidad Norbert Wiener',
    value: 'Universidad Norbert Wiener'
  },
  {
    id: '7644',
    label: 'Universidad Notarial Argentina',
    value: 'Universidad Notarial Argentina'
  },
  {
    id: '7645',
    label: 'Universidad Nuestra Senora de La Paz',
    value: 'Universidad Nuestra Senora de La Paz'
  },
  {
    id: '7646',
    label: 'Universidad Nueva Esparta',
    value: 'Universidad Nueva Esparta'
  },
  {
    id: '7647',
    label: 'Universidad Nueva San Salvador',
    value: 'Universidad Nueva San Salvador'
  },
  {
    id: '7648',
    label: 'Universidad Nuevo Mundo',
    value: 'Universidad Nuevo Mundo'
  },
  {
    id: '7649',
    label: 'Universidad ORT Uruguay',
    value: 'Universidad ORT Uruguay'
  },
  {
    id: '7650',
    label: 'Universidad Obrera de Mexico',
    value: 'Universidad Obrera de Mexico'
  },
  {
    id: '7651',
    label: 'Universidad Odontológica Dominicana',
    value: 'Universidad Odontológica Dominicana'
  },
  {
    id: '7652',
    label: 'Universidad Olmeca',
    value: 'Universidad Olmeca'
  },
  {
    id: '7653',
    label: 'Universidad Pablo de Olavide',
    value: 'Universidad Pablo de Olavide'
  },
  {
    id: '7654',
    label: 'Universidad Panamericana',
    value: 'Universidad Panamericana'
  },
  {
    id: '7655',
    label: 'Universidad Panamericana',
    value: 'Universidad Panamericana'
  },
  {
    id: '7656',
    label: 'Universidad Panamericana de San Salvador',
    value: 'Universidad Panamericana de San Salvador'
  },
  {
    id: '7657',
    label: 'Universidad Particular Inca Garcilaso de la Vega',
    value: 'Universidad Particular Inca Garcilaso de la Vega'
  },
  {
    id: '7658',
    label: 'Universidad Particular de Chiclayo',
    value: 'Universidad Particular de Chiclayo'
  },
  {
    id: '7659',
    label: 'Universidad Pedagogica Nacional',
    value: 'Universidad Pedagogica Nacional'
  },
  {
    id: '7660',
    label: 'Universidad Pedagógica "José Martí", Camagüey',
    value: 'Universidad Pedagógica "José Martí", Camagüey'
  },
  {
    id: '7661',
    label: 'Universidad Pedagógica Experimental Libertador',
    value: 'Universidad Pedagógica Experimental Libertador'
  },
  {
    id: '7662',
    label: 'Universidad Pedagógica Nacional',
    value: 'Universidad Pedagógica Nacional'
  },
  {
    id: '7663',
    label: 'Universidad Pedagógica Nacional "Francisco Morazán"',
    value: 'Universidad Pedagógica Nacional "Francisco Morazán"'
  },
  {
    id: '7664',
    label: 'Universidad Pedagógica Nacional, Unidad León',
    value: 'Universidad Pedagógica Nacional, Unidad León'
  },
  {
    id: '7665',
    label: 'Universidad Pedagógica Veracruzana',
    value: 'Universidad Pedagógica Veracruzana'
  },
  {
    id: '7666',
    label: 'Universidad Pedagógica de El Salvador',
    value: 'Universidad Pedagógica de El Salvador'
  },
  {
    id: '7667',
    label: 'Universidad Pedagógica y Tecnológica de Colombia',
    value: 'Universidad Pedagógica y Tecnológica de Colombia'
  },
  {
    id: '7668',
    label: 'Universidad Peruana Cayetano Heredia',
    value: 'Universidad Peruana Cayetano Heredia'
  },
  {
    id: '7669',
    label: 'Universidad Peruana Union',
    value: 'Universidad Peruana Union'
  },
  {
    id: '7670',
    label: 'Universidad Peruana de Ciencias Aplicadas',
    value: 'Universidad Peruana de Ciencias Aplicadas'
  },
  {
    id: '7671',
    label: 'Universidad Peruana de Ciencias e Informática',
    value: 'Universidad Peruana de Ciencias e Informática'
  },
  {
    id: '7672',
    label: 'Universidad Piloto de Colombia',
    value: 'Universidad Piloto de Colombia'
  },
  {
    id: '7673',
    label: 'Universidad Politecnica de Nicaragua',
    value: 'Universidad Politecnica de Nicaragua'
  },
  {
    id: '7674',
    label: 'Universidad Politécnica Salesiana',
    value: 'Universidad Politécnica Salesiana'
  },
  {
    id: '7675',
    label: 'Universidad Politécnica de Cartagena',
    value: 'Universidad Politécnica de Cartagena'
  },
  {
    id: '7676',
    label: 'Universidad Politécnica de Cataluna',
    value: 'Universidad Politécnica de Cataluna'
  },
  {
    id: '7677',
    label: 'Universidad Politécnica de El Salvador',
    value: 'Universidad Politécnica de El Salvador'
  },
  {
    id: '7678',
    label: 'Universidad Politécnica de Madrid',
    value: 'Universidad Politécnica de Madrid'
  },
  {
    id: '7679',
    label: 'Universidad Politécnica de Valencia',
    value: 'Universidad Politécnica de Valencia'
  },
  {
    id: '7680',
    label: 'Universidad Pontificia Bolivariana',
    value: 'Universidad Pontificia Bolivariana'
  },
  {
    id: '7681',
    label: 'Universidad Pontificia Comillas',
    value: 'Universidad Pontificia Comillas'
  },
  {
    id: '7682',
    label: 'Universidad Pontificia de Salamanca',
    value: 'Universidad Pontificia de Salamanca'
  },
  {
    id: '7683',
    label: 'Universidad Popular Autonóma del Estado de Puebla',
    value: 'Universidad Popular Autonóma del Estado de Puebla'
  },
  {
    id: '7684',
    label: 'Universidad Popular de Nicaragua (UPONIC)',
    value: 'Universidad Popular de Nicaragua (UPONIC)'
  },
  {
    id: '7685',
    label: 'Universidad Popular del Cesar',
    value: 'Universidad Popular del Cesar'
  },
  {
    id: '7686',
    label: 'Universidad Privada Abierta Latinoamericana',
    value: 'Universidad Privada Abierta Latinoamericana'
  },
  {
    id: '7687',
    label: 'Universidad Privada Antenor Orrego',
    value: 'Universidad Privada Antenor Orrego'
  },
  {
    id: '7688',
    label: 'Universidad Privada Boliviana',
    value: 'Universidad Privada Boliviana'
  },
  {
    id: '7689',
    label: 'Universidad Privada Franz Tamayo (UNIFRANZ)',
    value: 'Universidad Privada Franz Tamayo (UNIFRANZ)'
  },
  {
    id: '7690',
    label: 'Universidad Privada Juan Mejía Baca',
    value: 'Universidad Privada Juan Mejía Baca'
  },
  {
    id: '7691',
    label: 'Universidad Privada Los Andes',
    value: 'Universidad Privada Los Andes'
  },
  {
    id: '7692',
    label: 'Universidad Privada San Juan Bautista',
    value: 'Universidad Privada San Juan Bautista'
  },
  {
    id: '7693',
    label: 'Universidad Privada San Pedro',
    value: 'Universidad Privada San Pedro'
  },
  {
    id: '7694',
    label: 'Universidad Privada Sergio Bernales',
    value: 'Universidad Privada Sergio Bernales'
  },
  {
    id: '7695',
    label: 'Universidad Privada de Santa Cruz de la Sierra',
    value: 'Universidad Privada de Santa Cruz de la Sierra'
  },
  {
    id: '7696',
    label: 'Universidad Privada de Tacna',
    value: 'Universidad Privada de Tacna'
  },
  {
    id: '7697',
    label: 'Universidad Privada del Este',
    value: 'Universidad Privada del Este'
  },
  {
    id: '7698',
    label: 'Universidad Privada del Norte',
    value: 'Universidad Privada del Norte'
  },
  {
    id: '7699',
    label: 'Universidad Privada del Valle',
    value: 'Universidad Privada del Valle'
  },
  {
    id: '7700',
    label: 'Universidad Pública de Navarra',
    value: 'Universidad Pública de Navarra'
  },
  {
    id: '7701',
    label: 'Universidad Rafael Landívar',
    value: 'Universidad Rafael Landívar'
  },
  {
    id: '7702',
    label: 'Universidad Rafael Urdaneta',
    value: 'Universidad Rafael Urdaneta'
  },
  {
    id: '7703',
    label: 'Universidad Regiomontana',
    value: 'Universidad Regiomontana'
  },
  {
    id: '7704',
    label: 'Universidad Regional Miguel Hidalgo',
    value: 'Universidad Regional Miguel Hidalgo'
  },
  {
    id: '7705',
    label: 'Universidad Regional del Sureste',
    value: 'Universidad Regional del Sureste'
  },
  {
    id: '7706',
    label: 'Universidad Rey Juan Carlos',
    value: 'Universidad Rey Juan Carlos'
  },
  {
    id: '7707',
    label: 'Universidad Ricardo Palma',
    value: 'Universidad Ricardo Palma'
  },
  {
    id: '7708',
    label: 'Universidad Rural de Guatemala',
    value: 'Universidad Rural de Guatemala'
  },
  {
    id: '7709',
    label: 'Universidad Salesiana',
    value: 'Universidad Salesiana'
  },
  {
    id: '7710',
    label: 'Universidad Salesiana de Bolivia',
    value: 'Universidad Salesiana de Bolivia'
  },
  {
    id: '7711',
    label: 'Universidad Salvadorena "Alberto Masferrer"',
    value: 'Universidad Salvadorena "Alberto Masferrer"'
  },
  {
    id: '7712',
    label: 'Universidad San Francisco Xavier',
    value: 'Universidad San Francisco Xavier'
  },
  {
    id: '7713',
    label: 'Universidad San Francisco Xavier de Chuquisaca',
    value: 'Universidad San Francisco Xavier de Chuquisaca'
  },
  {
    id: '7714',
    label: 'Universidad San Francisco de Quito',
    value: 'Universidad San Francisco de Quito'
  },
  {
    id: '7715',
    label: 'Universidad San Ignacio de Loyola',
    value: 'Universidad San Ignacio de Loyola'
  },
  {
    id: '7716',
    label: 'Universidad San Jorge',
    value: 'Universidad San Jorge'
  },
  {
    id: '7717',
    label: 'Universidad San Juan de la Cruz',
    value: 'Universidad San Juan de la Cruz'
  },
  {
    id: '7718',
    label: 'Universidad San Marcos',
    value: 'Universidad San Marcos'
  },
  {
    id: '7719',
    label: 'Universidad San Martin de Porres',
    value: 'Universidad San Martin de Porres'
  },
  {
    id: '7720',
    label: 'Universidad San Pablo',
    value: 'Universidad San Pablo'
  },
  {
    id: '7721',
    label: 'Universidad San Sebastian',
    value: 'Universidad San Sebastian'
  },
  {
    id: '7722',
    label: 'Universidad Santa Fe',
    value: 'Universidad Santa Fe'
  },
  {
    id: '7723',
    label: 'Universidad Santa Maria Caracas',
    value: 'Universidad Santa Maria Caracas'
  },
  {
    id: '7724',
    label: 'Universidad Santa María Campus Guayaquil',
    value: 'Universidad Santa María Campus Guayaquil'
  },
  {
    id: '7725',
    label: 'Universidad Santa Paula',
    value: 'Universidad Santa Paula'
  },
  {
    id: '7726',
    label: 'Universidad Santiago de Cali',
    value: 'Universidad Santiago de Cali'
  },
  {
    id: '7727',
    label: 'Universidad Santo Tomás',
    value: 'Universidad Santo Tomás'
  },
  {
    id: '7728',
    label: 'Universidad Santo Tomás',
    value: 'Universidad Santo Tomás'
  },
  {
    id: '7729',
    label: 'Universidad Sergio Arboleda',
    value: 'Universidad Sergio Arboleda'
  },
  {
    id: '7730',
    label: 'Universidad Señor de Sipán',
    value: 'Universidad Señor de Sipán'
  },
  {
    id: '7731',
    label: 'Universidad Simón Bolivar',
    value: 'Universidad Simón Bolivar'
  },
  {
    id: '7732',
    label: 'Universidad Simón Bolivar',
    value: 'Universidad Simón Bolivar'
  },
  {
    id: '7733',
    label: 'Universidad Surcolombiana',
    value: 'Universidad Surcolombiana'
  },
  {
    id: '7734',
    label: 'Universidad Tecmilenio',
    value: 'Universidad Tecmilenio'
  },
  {
    id: '7735',
    label: 'Universidad Tecnologica "Vicente Perez Rosales"',
    value: 'Universidad Tecnologica "Vicente Perez Rosales"'
  },
  {
    id: '7736',
    label: 'Universidad Tecnologica Israel',
    value: 'Universidad Tecnologica Israel'
  },
  {
    id: '7737',
    label: 'Universidad Tecnologica de Nezahualcoyotl',
    value: 'Universidad Tecnologica de Nezahualcoyotl'
  },
  {
    id: '7738',
    label: 'Universidad Tecnologica de la Mixteca',
    value: 'Universidad Tecnologica de la Mixteca'
  },
  {
    id: '7739',
    label: 'Universidad Tecnológica América',
    value: 'Universidad Tecnológica América'
  },
  {
    id: '7740',
    label: 'Universidad Tecnológica Centroamericana',
    value: 'Universidad Tecnológica Centroamericana'
  },
  {
    id: '7741',
    label: 'Universidad Tecnológica Equinoccial',
    value: 'Universidad Tecnológica Equinoccial'
  },
  {
    id: '7742',
    label: 'Universidad Tecnológica Indoamérica',
    value: 'Universidad Tecnológica Indoamérica'
  },
  {
    id: '7743',
    label: 'Universidad Tecnológica Metropolitana',
    value: 'Universidad Tecnológica Metropolitana'
  },
  {
    id: '7744',
    label: 'Universidad Tecnológica Nacional',
    value: 'Universidad Tecnológica Nacional'
  },
  {
    id: '7745',
    label: 'Universidad Tecnológica Oteima',
    value: 'Universidad Tecnológica Oteima'
  },
  {
    id: '7746',
    label: 'Universidad Tecnológica San Antonio de Machala',
    value: 'Universidad Tecnológica San Antonio de Machala'
  },
  {
    id: '7747',
    label: 'Universidad Tecnológica de El Salvador',
    value: 'Universidad Tecnológica de El Salvador'
  },
  {
    id: '7748',
    label: 'Universidad Tecnológica de Honduras',
    value: 'Universidad Tecnológica de Honduras'
  },
  {
    id: '7749',
    label: 'Universidad Tecnológica de México',
    value: 'Universidad Tecnológica de México'
  },
  {
    id: '7750',
    label: 'Universidad Tecnológica de Panamá',
    value: 'Universidad Tecnológica de Panamá'
  },
  {
    id: '7751',
    label: 'Universidad Tecnológica de Pereira',
    value: 'Universidad Tecnológica de Pereira'
  },
  {
    id: '7752',
    label: 'Universidad Tecnológica de Santiago',
    value: 'Universidad Tecnológica de Santiago'
  },
  {
    id: '7753',
    label: 'Universidad Tecnológica de los Andes de Apurímac',
    value: 'Universidad Tecnológica de los Andes de Apurímac'
  },
  {
    id: '7754',
    label: 'Universidad Tecnológica del Centro',
    value: 'Universidad Tecnológica del Centro'
  },
  {
    id: '7755',
    label: 'Universidad Tecnológica del Choco "Diego Luis Cordoba"',
    value: 'Universidad Tecnológica del Choco "Diego Luis Cordoba"'
  },
  {
    id: '7756',
    label: 'Universidad Tecnológica del Peru',
    value: 'Universidad Tecnológica del Peru'
  },
  {
    id: '7757',
    label: 'Universidad Tecnológica del Sur',
    value: 'Universidad Tecnológica del Sur'
  },
  {
    id: '7758',
    label: 'Universidad Torcuato di Tella',
    value: 'Universidad Torcuato di Tella'
  },
  {
    id: '7759',
    label: 'Universidad Técnica "Federico Santa María"',
    value: 'Universidad Técnica "Federico Santa María"'
  },
  {
    id: '7760',
    label: 'Universidad Técnica Estatal de Quevedo',
    value: 'Universidad Técnica Estatal de Quevedo'
  },
  {
    id: '7761',
    label: 'Universidad Técnica Federico Santa María',
    value: 'Universidad Técnica Federico Santa María'
  },
  {
    id: '7762',
    label: 'Universidad Técnica Latinoamericana',
    value: 'Universidad Técnica Latinoamericana'
  },
  {
    id: '7763',
    label: 'Universidad Técnica Particular de Loja',
    value: 'Universidad Técnica Particular de Loja'
  },
  {
    id: '7764',
    label: 'Universidad Técnica de Ambato',
    value: 'Universidad Técnica de Ambato'
  },
  {
    id: '7765',
    label: 'Universidad Técnica de Babahoyo',
    value: 'Universidad Técnica de Babahoyo'
  },
  {
    id: '7766',
    label: 'Universidad Técnica de Cotopaxi',
    value: 'Universidad Técnica de Cotopaxi'
  },
  {
    id: '7767',
    label: 'Universidad Técnica de Esmeraldas "Luis Vargas Torres"',
    value: 'Universidad Técnica de Esmeraldas "Luis Vargas Torres"'
  },
  {
    id: '7768',
    label: 'Universidad Técnica de Machala',
    value: 'Universidad Técnica de Machala'
  },
  {
    id: '7769',
    label: 'Universidad Técnica de Manabí',
    value: 'Universidad Técnica de Manabí'
  },
  {
    id: '7770',
    label: 'Universidad Técnica de Oruro',
    value: 'Universidad Técnica de Oruro'
  },
  {
    id: '7771',
    label: 'Universidad Técnica del Norte',
    value: 'Universidad Técnica del Norte'
  },
  {
    id: '7772',
    label: 'Universidad Valle del Grijalva',
    value: 'Universidad Valle del Grijalva'
  },
  {
    id: '7773',
    label: 'Universidad Valle del Momboy',
    value: 'Universidad Valle del Momboy'
  },
  {
    id: '7774',
    label: 'Universidad Veracruzana',
    value: 'Universidad Veracruzana'
  },
  {
    id: '7775',
    label: 'Universidad Veritas',
    value: 'Universidad Veritas'
  },
  {
    id: '7776',
    label: 'Universidad Xicotepetl',
    value: 'Universidad Xicotepetl'
  },
  {
    id: '7777',
    label: 'Universidad Yacambu',
    value: 'Universidad Yacambu'
  },
  {
    id: '7778',
    label: 'Universidad de Aconcagua',
    value: 'Universidad de Aconcagua'
  },
  {
    id: '7779',
    label: 'Universidad de Alcalá de Henares',
    value: 'Universidad de Alcalá de Henares'
  },
  {
    id: '7780',
    label: 'Universidad de Alicante',
    value: 'Universidad de Alicante'
  },
  {
    id: '7781',
    label: 'Universidad de Almería',
    value: 'Universidad de Almería'
  },
  {
    id: '7782',
    label: 'Universidad de América Bogotá',
    value: 'Universidad de América Bogotá'
  },
  {
    id: '7783',
    label: 'Universidad de Antioquía',
    value: 'Universidad de Antioquía'
  },
  {
    id: '7784',
    label: 'Universidad de Antofagasta',
    value: 'Universidad de Antofagasta'
  },
  {
    id: '7785',
    label: 'Universidad de Aquino Bolivia',
    value: 'Universidad de Aquino Bolivia'
  },
  {
    id: '7786',
    label: 'Universidad de Artes, Ciencias y Comunicación',
    value: 'Universidad de Artes, Ciencias y Comunicación'
  },
  {
    id: '7787',
    label: 'Universidad de Atacama',
    value: 'Universidad de Atacama'
  },
  {
    id: '7788',
    label: 'Universidad de Aysen',
    value: 'Universidad de Aysen'
  },
  {
    id: '7789',
    label: 'Universidad de Belgrano',
    value: 'Universidad de Belgrano'
  },
  {
    id: '7790',
    label: 'Universidad de Bogotá "Jorge Tadeo Lozano"',
    value: 'Universidad de Bogotá "Jorge Tadeo Lozano"'
  },
  {
    id: '7791',
    label: 'Universidad de Bologna - Representación en Buenos Aires',
    value: 'Universidad de Bologna - Representación en Buenos Aires'
  },
  {
    id: '7792',
    label: 'Universidad de Buenos Aires',
    value: 'Universidad de Buenos Aires'
  },
  {
    id: '7793',
    label: 'Universidad de Burgos',
    value: 'Universidad de Burgos'
  },
  {
    id: '7794',
    label: 'Universidad de Caldas',
    value: 'Universidad de Caldas'
  },
  {
    id: '7795',
    label: 'Universidad de Camagüey',
    value: 'Universidad de Camagüey'
  },
  {
    id: '7796',
    label: 'Universidad de Cantabria',
    value: 'Universidad de Cantabria'
  },
  {
    id: '7797',
    label: 'Universidad de Carabobo',
    value: 'Universidad de Carabobo'
  },
  {
    id: '7798',
    label: 'Universidad de Cartagena',
    value: 'Universidad de Cartagena'
  },
  {
    id: '7799',
    label: 'Universidad de Cartago',
    value: 'Universidad de Cartago'
  },
  {
    id: '7800',
    label: 'Universidad de Cartago Florencio del Castillo',
    value: 'Universidad de Cartago Florencio del Castillo'
  },
  {
    id: '7801',
    label: 'Universidad de Castilla La Mancha',
    value: 'Universidad de Castilla La Mancha'
  },
  {
    id: '7802',
    label: 'Universidad de Celaya',
    value: 'Universidad de Celaya'
  },
  {
    id: '7803',
    label: 'Universidad de Chile',
    value: 'Universidad de Chile'
  },
  {
    id: '7804',
    label: 'Universidad de Ciego de Avila',
    value: 'Universidad de Ciego de Avila'
  },
  {
    id: '7805',
    label: 'Universidad de Ciencias Aplicadas y Ambientales (UDCA)',
    value: 'Universidad de Ciencias Aplicadas y Ambientales (UDCA)'
  },
  {
    id: '7806',
    label: 'Universidad de Ciencias Empresariales y Sociales',
    value: 'Universidad de Ciencias Empresariales y Sociales'
  },
  {
    id: '7807',
    label: 'Universidad de Ciencias de la Informatica',
    value: 'Universidad de Ciencias de la Informatica'
  },
  {
    id: '7808',
    label: 'Universidad de Ciencias y Artes de Chiapas',
    value: 'Universidad de Ciencias y Artes de Chiapas'
  },
  {
    id: '7809',
    label: 'Universidad de Ciencias y Humanidades',
    value: 'Universidad de Ciencias y Humanidades'
  },
  {
    id: '7810',
    label: 'Universidad de Cienfuegos',
    value: 'Universidad de Cienfuegos'
  },
  {
    id: '7811',
    label: 'Universidad de Colima',
    value: 'Universidad de Colima'
  },
  {
    id: '7812',
    label: 'Universidad de Comunicación Avanzada',
    value: 'Universidad de Comunicación Avanzada'
  },
  {
    id: '7813',
    label: 'Universidad de Concepcion',
    value: 'Universidad de Concepcion'
  },
  {
    id: '7814',
    label: 'Universidad de Concepción del Uruguay',
    value: 'Universidad de Concepción del Uruguay'
  },
  {
    id: '7815',
    label: 'Universidad de Congreso',
    value: 'Universidad de Congreso'
  },
  {
    id: '7816',
    label: 'Universidad de Costa Rica',
    value: 'Universidad de Costa Rica'
  },
  {
    id: '7817',
    label: 'Universidad de Cuautitlan Izcalli',
    value: 'Universidad de Cuautitlan Izcalli'
  },
  {
    id: '7818',
    label: 'Universidad de Cuenca',
    value: 'Universidad de Cuenca'
  },
  {
    id: '7819',
    label: 'Universidad de Cádiz',
    value: 'Universidad de Cádiz'
  },
  {
    id: '7820',
    label: 'Universidad de Córdoba',
    value: 'Universidad de Córdoba'
  },
  {
    id: '7821',
    label: 'Universidad de Córdoba',
    value: 'Universidad de Córdoba'
  },
  {
    id: '7822',
    label: 'Universidad de Deusto',
    value: 'Universidad de Deusto'
  },
  {
    id: '7823',
    label: 'Universidad de El Salvador',
    value: 'Universidad de El Salvador'
  },
  {
    id: '7824',
    label: 'Universidad de Especialidades Turísticas',
    value: 'Universidad de Especialidades Turísticas'
  },
  {
    id: '7825',
    label: 'Universidad de Especialidades del Espíritu Santo',
    value: 'Universidad de Especialidades del Espíritu Santo'
  },
  {
    id: '7826',
    label: 'Universidad de Extremadura',
    value: 'Universidad de Extremadura'
  },
  {
    id: '7827',
    label: 'Universidad de Flores - Buenos Aires',
    value: 'Universidad de Flores - Buenos Aires'
  },
  {
    id: '7828',
    label: 'Universidad de Gerona',
    value: 'Universidad de Gerona'
  },
  {
    id: '7829',
    label: 'Universidad de Granada',
    value: 'Universidad de Granada'
  },
  {
    id: '7830',
    label: 'Universidad de Granma',
    value: 'Universidad de Granma'
  },
  {
    id: '7831',
    label: 'Universidad de Guadalajara',
    value: 'Universidad de Guadalajara'
  },
  {
    id: '7832',
    label: 'Universidad de Guanajuato',
    value: 'Universidad de Guanajuato'
  },
  {
    id: '7833',
    label: 'Universidad de Guanajuato, Campus León',
    value: 'Universidad de Guanajuato, Campus León'
  },
  {
    id: '7834',
    label: 'Universidad de Guayaquil',
    value: 'Universidad de Guayaquil'
  },
  {
    id: '7835',
    label: 'Universidad de Hermosillo',
    value: 'Universidad de Hermosillo'
  },
  {
    id: '7836',
    label: 'Universidad de Holguín',
    value: 'Universidad de Holguín'
  },
  {
    id: '7837',
    label: 'Universidad de Huelva',
    value: 'Universidad de Huelva'
  },
  {
    id: '7838',
    label: 'Universidad de Huánuco',
    value: 'Universidad de Huánuco'
  },
  {
    id: '7839',
    label: 'Universidad de Iberoamérica',
    value: 'Universidad de Iberoamérica'
  },
  {
    id: '7840',
    label: 'Universidad de Jaén',
    value: 'Universidad de Jaén'
  },
  {
    id: '7841',
    label: 'Universidad de La Amazonia',
    value: 'Universidad de La Amazonia'
  },
  {
    id: '7842',
    label: 'Universidad de La Coruña',
    value: 'Universidad de La Coruña'
  },
  {
    id: '7843',
    label: 'Universidad de La Frontera',
    value: 'Universidad de La Frontera'
  },
  {
    id: '7844',
    label: 'Universidad de La Guajira',
    value: 'Universidad de La Guajira'
  },
  {
    id: '7845',
    label: 'Universidad de La Habana',
    value: 'Universidad de La Habana'
  },
  {
    id: '7846',
    label: 'Universidad de La Laguna',
    value: 'Universidad de La Laguna'
  },
  {
    id: '7847',
    label: 'Universidad de La Rioja',
    value: 'Universidad de La Rioja'
  },
  {
    id: '7848',
    label: 'Universidad de La Sabana',
    value: 'Universidad de La Sabana'
  },
  {
    id: '7849',
    label: 'Universidad de La Salle',
    value: 'Universidad de La Salle'
  },
  {
    id: '7850',
    label: 'Universidad de La Salle',
    value: 'Universidad de La Salle'
  },
  {
    id: '7851',
    label: 'Universidad de La Salle, Bajío',
    value: 'Universidad de La Salle, Bajío'
  },
  {
    id: '7852',
    label: 'Universidad de La Serena',
    value: 'Universidad de La Serena'
  },
  {
    id: '7853',
    label: 'Universidad de Lambayeque',
    value: 'Universidad de Lambayeque'
  },
  {
    id: '7854',
    label: 'Universidad de Las Américas',
    value: 'Universidad de Las Américas'
  },
  {
    id: '7855',
    label: 'Universidad de Las Palmas de Gran Canaria',
    value: 'Universidad de Las Palmas de Gran Canaria'
  },
  {
    id: '7856',
    label: 'Universidad de León',
    value: 'Universidad de León'
  },
  {
    id: '7857',
    label: 'Universidad de León',
    value: 'Universidad de León'
  },
  {
    id: '7858',
    label: 'Universidad de Lima',
    value: 'Universidad de Lima'
  },
  {
    id: '7859',
    label: 'Universidad de Los Andes',
    value: 'Universidad de Los Andes'
  },
  {
    id: '7860',
    label: 'Universidad de Los Andes',
    value: 'Universidad de Los Andes'
  },
  {
    id: '7861',
    label: 'Universidad de Los Andes',
    value: 'Universidad de Los Andes'
  },
  {
    id: '7862',
    label: 'Universidad de Los Lagos',
    value: 'Universidad de Los Lagos'
  },
  {
    id: '7863',
    label: 'Universidad de Los Llanos',
    value: 'Universidad de Los Llanos'
  },
  {
    id: '7864',
    label: 'Universidad de Magallanes',
    value: 'Universidad de Magallanes'
  },
  {
    id: '7865',
    label: 'Universidad de Managua (U de M)',
    value: 'Universidad de Managua (U de M)'
  },
  {
    id: '7866',
    label: 'Universidad de Manizales',
    value: 'Universidad de Manizales'
  },
  {
    id: '7867',
    label: 'Universidad de Matanzas Camilo Cienfuegos',
    value: 'Universidad de Matanzas Camilo Cienfuegos'
  },
  {
    id: '7868',
    label: 'Universidad de Medellín',
    value: 'Universidad de Medellín'
  },
  {
    id: '7869',
    label: 'Universidad de Mendoza',
    value: 'Universidad de Mendoza'
  },
  {
    id: '7870',
    label: 'Universidad de Montemorelos',
    value: 'Universidad de Montemorelos'
  },
  {
    id: '7871',
    label: 'Universidad de Monterrey',
    value: 'Universidad de Monterrey'
  },
  {
    id: '7872',
    label: 'Universidad de Montevideo',
    value: 'Universidad de Montevideo'
  },
  {
    id: '7873',
    label: 'Universidad de Morón',
    value: 'Universidad de Morón'
  },
  {
    id: '7874',
    label: 'Universidad de Murcia',
    value: 'Universidad de Murcia'
  },
  {
    id: '7875',
    label: 'Universidad de Málaga',
    value: 'Universidad de Málaga'
  },
  {
    id: '7876',
    label: 'Universidad de Nariño',
    value: 'Universidad de Nariño'
  },
  {
    id: '7877',
    label: 'Universidad de Navarra',
    value: 'Universidad de Navarra'
  },
  {
    id: '7878',
    label: 'Universidad de Norteamerica',
    value: 'Universidad de Norteamerica'
  },
  {
    id: '7879',
    label: 'Universidad de Occidente',
    value: 'Universidad de Occidente'
  },
  {
    id: '7880',
    label: 'Universidad de Oriente',
    value: 'Universidad de Oriente'
  },
  {
    id: '7881',
    label: 'Universidad de Oriente',
    value: 'Universidad de Oriente'
  },
  {
    id: '7882',
    label: 'Universidad de Oriente',
    value: 'Universidad de Oriente'
  },
  {
    id: '7883',
    label: 'Universidad de Oviedo',
    value: 'Universidad de Oviedo'
  },
  {
    id: '7884',
    label: 'Universidad de Palermo',
    value: 'Universidad de Palermo'
  },
  {
    id: '7885',
    label: 'Universidad de Pamplona',
    value: 'Universidad de Pamplona'
  },
  {
    id: '7886',
    label: 'Universidad de Panamá',
    value: 'Universidad de Panamá'
  },
  {
    id: '7887',
    label: 'Universidad de Pinar del Río',
    value: 'Universidad de Pinar del Río'
  },
  {
    id: '7888',
    label: 'Universidad de Piura',
    value: 'Universidad de Piura'
  },
  {
    id: '7889',
    label: 'Universidad de Playa Ancha de Ciencias de la Educacion',
    value: 'Universidad de Playa Ancha de Ciencias de la Educacion'
  },
  {
    id: '7890',
    label: 'Universidad de Puerto Rico',
    value: 'Universidad de Puerto Rico'
  },
  {
    id: '7891',
    label: 'Universidad de Puerto Rico, Aguadilla',
    value: 'Universidad de Puerto Rico, Aguadilla'
  },
  {
    id: '7892',
    label: 'Universidad de Puerto Rico, Arecibo',
    value: 'Universidad de Puerto Rico, Arecibo'
  },
  {
    id: '7893',
    label: 'Universidad de Puerto Rico, Bayamon',
    value: 'Universidad de Puerto Rico, Bayamon'
  },
  {
    id: '7894',
    label: 'Universidad de Puerto Rico, Carolina',
    value: 'Universidad de Puerto Rico, Carolina'
  },
  {
    id: '7895',
    label: 'Universidad de Puerto Rico, Cayey',
    value: 'Universidad de Puerto Rico, Cayey'
  },
  {
    id: '7896',
    label: 'Universidad de Puerto Rico, Ciencias Medicas',
    value: 'Universidad de Puerto Rico, Ciencias Medicas'
  },
  {
    id: '7897',
    label: 'Universidad de Puerto Rico, Humacao',
    value: 'Universidad de Puerto Rico, Humacao'
  },
  {
    id: '7898',
    label: 'Universidad de Puerto Rico, Mayaguez',
    value: 'Universidad de Puerto Rico, Mayaguez'
  },
  {
    id: '7899',
    label: 'Universidad de Puerto Rico, Ponce',
    value: 'Universidad de Puerto Rico, Ponce'
  },
  {
    id: '7900',
    label: 'Universidad de Puerto Rico, Rio Pedras',
    value: 'Universidad de Puerto Rico, Rio Pedras'
  },
  {
    id: '7901',
    label: 'Universidad de Puerto Rico, Utuado',
    value: 'Universidad de Puerto Rico, Utuado'
  },
  {
    id: '7902',
    label: 'Universidad de Quintana Roo',
    value: 'Universidad de Quintana Roo'
  },
  {
    id: '7903',
    label: 'Universidad de Salamanca',
    value: 'Universidad de Salamanca'
  },
  {
    id: '7904',
    label: 'Universidad de San Andres',
    value: 'Universidad de San Andres'
  },
  {
    id: '7905',
    label: 'Universidad de San Andres',
    value: 'Universidad de San Andres'
  },
  {
    id: '7906',
    label: 'Universidad de San Buenaventura',
    value: 'Universidad de San Buenaventura'
  },
  {
    id: '7907',
    label: 'Universidad de San Buenaventura Medellin',
    value: 'Universidad de San Buenaventura Medellin'
  },
  {
    id: '7908',
    label: 'Universidad de San Carlos de Guatemala',
    value: 'Universidad de San Carlos de Guatemala'
  },
  {
    id: '7909',
    label: 'Universidad de San José',
    value: 'Universidad de San José'
  },
  {
    id: '7910',
    label: 'Universidad de San Miguel',
    value: 'Universidad de San Miguel'
  },
  {
    id: '7911',
    label: 'Universidad de San Pablo CEU',
    value: 'Universidad de San Pablo CEU'
  },
  {
    id: '7912',
    label: 'Universidad de San Pedro Sula',
    value: 'Universidad de San Pedro Sula'
  },
  {
    id: '7913',
    label: 'Universidad de Santiago de Chile',
    value: 'Universidad de Santiago de Chile'
  },
  {
    id: '7914',
    label: 'Universidad de Santiago de Compostela',
    value: 'Universidad de Santiago de Compostela'
  },
  {
    id: '7915',
    label: 'Universidad de Sevilla',
    value: 'Universidad de Sevilla'
  },
  {
    id: '7916',
    label: 'Universidad de Sonora',
    value: 'Universidad de Sonora'
  },
  {
    id: '7917',
    label: 'Universidad de Sonsonate',
    value: 'Universidad de Sonsonate'
  },
  {
    id: '7918',
    label: 'Universidad de Sucre',
    value: 'Universidad de Sucre'
  },
  {
    id: '7919',
    label: 'Universidad de Talca',
    value: 'Universidad de Talca'
  },
  {
    id: '7920',
    label: 'Universidad de Tarapacá',
    value: 'Universidad de Tarapacá'
  },
  {
    id: '7921',
    label: 'Universidad de Valencia',
    value: 'Universidad de Valencia'
  },
  {
    id: '7922',
    label: 'Universidad de Valladolid',
    value: 'Universidad de Valladolid'
  },
  {
    id: '7923',
    label: 'Universidad de Valparaiso',
    value: 'Universidad de Valparaiso'
  },
  {
    id: '7924',
    label: 'Universidad de Vigo',
    value: 'Universidad de Vigo'
  },
  {
    id: '7925',
    label: 'Universidad de Viña del Mar',
    value: 'Universidad de Viña del Mar'
  },
  {
    id: '7926',
    label: 'Universidad de Zaragoza',
    value: 'Universidad de Zaragoza'
  },
  {
    id: '7927',
    label: 'Universidad de la Cuenca del Plata',
    value: 'Universidad de la Cuenca del Plata'
  },
  {
    id: '7928',
    label: 'Universidad de la Empresa (UDE)',
    value: 'Universidad de la Empresa (UDE)'
  },
  {
    id: '7929',
    label: 'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)',
    value: 'Universidad de la Fraternidad de Agrupaciones Santo Tomas de Aquino (FASTA)'
  },
  {
    id: '7930',
    label: 'Universidad de la Integración de las Americas (UNIDAD)',
    value: 'Universidad de la Integración de las Americas (UNIDAD)'
  },
  {
    id: '7931',
    label: 'Universidad de la Marina Mercante',
    value: 'Universidad de la Marina Mercante'
  },
  {
    id: '7932',
    label: 'Universidad de la República',
    value: 'Universidad de la República'
  },
  {
    id: '7933',
    label: 'Universidad de la Sierra A.C.',
    value: 'Universidad de la Sierra A.C.'
  },
  {
    id: '7934',
    label: 'Universidad de la Tercera Edad',
    value: 'Universidad de la Tercera Edad'
  },
  {
    id: '7935',
    label: 'Universidad de las Américas',
    value: 'Universidad de las Américas'
  },
  {
    id: '7936',
    label: 'Universidad de las Américas (ULAM)',
    value: 'Universidad de las Américas (ULAM)'
  },
  {
    id: '7937',
    label: 'Universidad de las Américas - Puebla',
    value: 'Universidad de las Américas - Puebla'
  },
  {
    id: '7938',
    label: 'Universidad de las Hespérides',
    value: 'Universidad de las Hespérides'
  },
  {
    id: '7939',
    label: 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense',
    value: 'Universidad de las Regiones Autónomas de la Costa Caribe Nicaragüense'
  },
  {
    id: '7940',
    label: 'Universidad del Aconcagua',
    value: 'Universidad del Aconcagua'
  },
  {
    id: '7941',
    label: 'Universidad del Atlántico',
    value: 'Universidad del Atlántico'
  },
  {
    id: '7942',
    label: 'Universidad del Azuay',
    value: 'Universidad del Azuay'
  },
  {
    id: '7943',
    label: 'Universidad del Bajío',
    value: 'Universidad del Bajío'
  },
  {
    id: '7944',
    label: 'Universidad del Bío-Bío',
    value: 'Universidad del Bío-Bío'
  },
  {
    id: '7945',
    label: 'Universidad del Caribe',
    value: 'Universidad del Caribe'
  },
  {
    id: '7946',
    label: 'Universidad del Cauca',
    value: 'Universidad del Cauca'
  },
  {
    id: '7947',
    label: 'Universidad del Centro Educativo Latinoamericano Rosario',
    value: 'Universidad del Centro Educativo Latinoamericano Rosario'
  },
  {
    id: '7948',
    label: 'Universidad del Centro de México',
    value: 'Universidad del Centro de México'
  },
  {
    id: '7949',
    label: 'Universidad del Cine - Buenos Aires',
    value: 'Universidad del Cine - Buenos Aires'
  },
  {
    id: '7950',
    label: 'Universidad del Cono Sur de las Américas',
    value: 'Universidad del Cono Sur de las Américas'
  },
  {
    id: '7951',
    label: 'Universidad del Desarrollo',
    value: 'Universidad del Desarrollo'
  },
  {
    id: '7952',
    label: 'Universidad del Istmo',
    value: 'Universidad del Istmo'
  },
  {
    id: '7953',
    label: 'Universidad del Istmo',
    value: 'Universidad del Istmo'
  },
  {
    id: '7954',
    label: 'Universidad del Magdalena',
    value: 'Universidad del Magdalena'
  },
  {
    id: '7955',
    label: 'Universidad del Mar',
    value: 'Universidad del Mar'
  },
  {
    id: '7956',
    label: 'Universidad del Mar',
    value: 'Universidad del Mar'
  },
  {
    id: '7957',
    label: 'Universidad del Mayab',
    value: 'Universidad del Mayab'
  },
  {
    id: '7958',
    label: 'Universidad del Museo Social Argentino',
    value: 'Universidad del Museo Social Argentino'
  },
  {
    id: '7959',
    label: 'Universidad del Noreste',
    value: 'Universidad del Noreste'
  },
  {
    id: '7960',
    label: 'Universidad del Noroeste',
    value: 'Universidad del Noroeste'
  },
  {
    id: '7961',
    label: 'Universidad del Norte',
    value: 'Universidad del Norte'
  },
  {
    id: '7962',
    label: 'Universidad del Norte',
    value: 'Universidad del Norte'
  },
  {
    id: '7963',
    label: 'Universidad del Norte',
    value: 'Universidad del Norte'
  },
  {
    id: '7964',
    label: 'Universidad del Norte "Santo Tomás de Aquino"',
    value: 'Universidad del Norte "Santo Tomás de Aquino"'
  },
  {
    id: '7965',
    label: 'Universidad del Pacifico',
    value: 'Universidad del Pacifico'
  },
  {
    id: '7966',
    label: 'Universidad del Pacifico',
    value: 'Universidad del Pacifico'
  },
  {
    id: '7967',
    label: 'Universidad del Pacífico',
    value: 'Universidad del Pacífico'
  },
  {
    id: '7968',
    label: 'Universidad del País Vasco',
    value: 'Universidad del País Vasco'
  },
  {
    id: '7969',
    label: 'Universidad del Quindío',
    value: 'Universidad del Quindío'
  },
  {
    id: '7970',
    label: 'Universidad del Rosario',
    value: 'Universidad del Rosario'
  },
  {
    id: '7971',
    label: 'Universidad del Sagrado Corazon',
    value: 'Universidad del Sagrado Corazon'
  },
  {
    id: '7972',
    label: 'Universidad del Salvador',
    value: 'Universidad del Salvador'
  },
  {
    id: '7973',
    label: 'Universidad del Sinú',
    value: 'Universidad del Sinú'
  },
  {
    id: '7974',
    label: 'Universidad del Tepeyac',
    value: 'Universidad del Tepeyac'
  },
  {
    id: '7975',
    label: 'Universidad del Tolima',
    value: 'Universidad del Tolima'
  },
  {
    id: '7976',
    label: 'Universidad del Turabo',
    value: 'Universidad del Turabo'
  },
  {
    id: '7977',
    label: 'Universidad del Turismo',
    value: 'Universidad del Turismo'
  },
  {
    id: '7978',
    label: 'Universidad del Valle de Atemajac',
    value: 'Universidad del Valle de Atemajac'
  },
  {
    id: '7979',
    label: 'Universidad del Valle de Guatemala',
    value: 'Universidad del Valle de Guatemala'
  },
  {
    id: '7980',
    label: 'Universidad del Valle de México',
    value: 'Universidad del Valle de México'
  },
  {
    id: '7981',
    label: 'Universidad del Valle de Puebla',
    value: 'Universidad del Valle de Puebla'
  },
  {
    id: '7982',
    label: 'Universidad del Valle de Toluca',
    value: 'Universidad del Valle de Toluca'
  },
  {
    id: '7983',
    label: 'Universidad del Valle del Bravo',
    value: 'Universidad del Valle del Bravo'
  },
  {
    id: '7984',
    label: 'Universidad del Valle del Cauca',
    value: 'Universidad del Valle del Cauca'
  },
  {
    id: '7985',
    label: 'Universidad del Zulia',
    value: 'Universidad del Zulia'
  },
  {
    id: '7986',
    label: 'Universidad la Concordia',
    value: 'Universidad la Concordia'
  },
  {
    id: '7987',
    label: 'Universidad para la Cooperación Internacional',
    value: 'Universidad para la Cooperación Internacional'
  },
  {
    id: '7988',
    label: 'Universidade Aberta Lisboa',
    value: 'Universidade Aberta Lisboa'
  },
  {
    id: '7989',
    label: 'Universidade Atlântica',
    value: 'Universidade Atlântica'
  },
  {
    id: '7990',
    label: 'Universidade Autónoma de Lisboa Luís de Camoes',
    value: 'Universidade Autónoma de Lisboa Luís de Camoes'
  },
  {
    id: '7991',
    label: 'Universidade Bandeirante de São Paulo',
    value: 'Universidade Bandeirante de São Paulo'
  },
  {
    id: '7992',
    label: 'Universidade Braz Cubas',
    value: 'Universidade Braz Cubas'
  },
  {
    id: '7993',
    label: 'Universidade Camilo Castelo Branco',
    value: 'Universidade Camilo Castelo Branco'
  },
  {
    id: '7994',
    label: 'Universidade Candido Mendes',
    value: 'Universidade Candido Mendes'
  },
  {
    id: '7995',
    label: 'Universidade Castelo Branco',
    value: 'Universidade Castelo Branco'
  },
  {
    id: '7996',
    label: 'Universidade Catolica Portuguesa',
    value: 'Universidade Catolica Portuguesa'
  },
  {
    id: '7997',
    label: 'Universidade Católica Dom Bosco',
    value: 'Universidade Católica Dom Bosco'
  },
  {
    id: '7998',
    label: 'Universidade Católica de Angola',
    value: 'Universidade Católica de Angola'
  },
  {
    id: '7999',
    label: 'Universidade Católica de Brasília',
    value: 'Universidade Católica de Brasília'
  },
  {
    id: '8000',
    label: 'Universidade Católica de Goiás',
    value: 'Universidade Católica de Goiás'
  },
  {
    id: '8001',
    label: 'Universidade Católica de Moçambique',
    value: 'Universidade Católica de Moçambique'
  },
  {
    id: '8002',
    label: 'Universidade Católica de Pelotas',
    value: 'Universidade Católica de Pelotas'
  },
  {
    id: '8003',
    label: 'Universidade Católica de Pernambuco',
    value: 'Universidade Católica de Pernambuco'
  },
  {
    id: '8004',
    label: 'Universidade Católica de Petrópolis',
    value: 'Universidade Católica de Petrópolis'
  },
  {
    id: '8005',
    label: 'Universidade Católica de Salvador',
    value: 'Universidade Católica de Salvador'
  },
  {
    id: '8006',
    label: 'Universidade Católica de Santos',
    value: 'Universidade Católica de Santos'
  },
  {
    id: '8007',
    label: 'Universidade Cidade de São Paulo',
    value: 'Universidade Cidade de São Paulo'
  },
  {
    id: '8008',
    label: 'Universidade Cruzeiro do Sul',
    value: 'Universidade Cruzeiro do Sul'
  },
  {
    id: '8009',
    label: 'Universidade Eduardo Mondlane',
    value: 'Universidade Eduardo Mondlane'
  },
  {
    id: '8010',
    label: 'Universidade Estadual Paulista',
    value: 'Universidade Estadual Paulista'
  },
  {
    id: '8011',
    label: 'Universidade Estadual Sudoeste da Bahia',
    value: 'Universidade Estadual Sudoeste da Bahia'
  },
  {
    id: '8012',
    label: 'Universidade Estadual da Paraíba',
    value: 'Universidade Estadual da Paraíba'
  },
  {
    id: '8013',
    label: 'Universidade Estadual de Campinas',
    value: 'Universidade Estadual de Campinas'
  },
  {
    id: '8014',
    label: 'Universidade Estadual de Feira de Santana',
    value: 'Universidade Estadual de Feira de Santana'
  },
  {
    id: '8015',
    label: 'Universidade Estadual de Londrina',
    value: 'Universidade Estadual de Londrina'
  },
  {
    id: '8016',
    label: 'Universidade Estadual de Maringá',
    value: 'Universidade Estadual de Maringá'
  },
  {
    id: '8017',
    label: 'Universidade Estadual de Montes Claros',
    value: 'Universidade Estadual de Montes Claros'
  },
  {
    id: '8018',
    label: 'Universidade Estadual de Ponta Grossa',
    value: 'Universidade Estadual de Ponta Grossa'
  },
  {
    id: '8019',
    label: 'Universidade Estadual de Santa Cruz',
    value: 'Universidade Estadual de Santa Cruz'
  },
  {
    id: '8020',
    label: 'Universidade Estadual do Ceará',
    value: 'Universidade Estadual do Ceará'
  },
  {
    id: '8021',
    label: 'Universidade Estadual do Maranhão',
    value: 'Universidade Estadual do Maranhão'
  },
  {
    id: '8022',
    label: 'Universidade Estadual do Norte Fluminense',
    value: 'Universidade Estadual do Norte Fluminense'
  },
  {
    id: '8023',
    label: 'Universidade Estadual do Norte do Parana',
    value: 'Universidade Estadual do Norte do Parana'
  },
  {
    id: '8024',
    label: 'Universidade Estadual do Oeste do Paraná',
    value: 'Universidade Estadual do Oeste do Paraná'
  },
  {
    id: '8025',
    label: 'Universidade Estadual do Piauí',
    value: 'Universidade Estadual do Piauí'
  },
  {
    id: '8026',
    label: 'Universidade Estadual do Vale do Acaraú',
    value: 'Universidade Estadual do Vale do Acaraú'
  },
  {
    id: '8027',
    label: 'Universidade Estácio de Sá',
    value: 'Universidade Estácio de Sá'
  },
  {
    id: '8028',
    label: 'Universidade Federal Fluminense',
    value: 'Universidade Federal Fluminense'
  },
  {
    id: '8029',
    label: 'Universidade Federal Rural de Pernambuco',
    value: 'Universidade Federal Rural de Pernambuco'
  },
  {
    id: '8030',
    label: 'Universidade Federal Rural do Rio de Janeiro',
    value: 'Universidade Federal Rural do Rio de Janeiro'
  },
  {
    id: '8031',
    label: 'Universidade Federal da Bahia',
    value: 'Universidade Federal da Bahia'
  },
  {
    id: '8032',
    label: 'Universidade Federal da Grande Dourados',
    value: 'Universidade Federal da Grande Dourados'
  },
  {
    id: '8033',
    label: 'Universidade Federal da Paraíba',
    value: 'Universidade Federal da Paraíba'
  },
  {
    id: '8034',
    label: 'Universidade Federal de Alagoas',
    value: 'Universidade Federal de Alagoas'
  },
  {
    id: '8035',
    label: 'Universidade Federal de Campina Grande',
    value: 'Universidade Federal de Campina Grande'
  },
  {
    id: '8036',
    label: 'Universidade Federal de Goiás',
    value: 'Universidade Federal de Goiás'
  },
  {
    id: '8037',
    label: 'Universidade Federal de Itajubá',
    value: 'Universidade Federal de Itajubá'
  },
  {
    id: '8038',
    label: 'Universidade Federal de Juiz de Fora',
    value: 'Universidade Federal de Juiz de Fora'
  },
  {
    id: '8039',
    label: 'Universidade Federal de Lavras',
    value: 'Universidade Federal de Lavras'
  },
  {
    id: '8040',
    label: 'Universidade Federal de Mato Grosso',
    value: 'Universidade Federal de Mato Grosso'
  },
  {
    id: '8041',
    label: 'Universidade Federal de Mato Grosso do Sul',
    value: 'Universidade Federal de Mato Grosso do Sul'
  },
  {
    id: '8042',
    label: 'Universidade Federal de Minas Gerais',
    value: 'Universidade Federal de Minas Gerais'
  },
  {
    id: '8043',
    label: 'Universidade Federal de Ouro Preto',
    value: 'Universidade Federal de Ouro Preto'
  },
  {
    id: '8044',
    label: 'Universidade Federal de Pelotas',
    value: 'Universidade Federal de Pelotas'
  },
  {
    id: '8045',
    label: 'Universidade Federal de Pernambuco',
    value: 'Universidade Federal de Pernambuco'
  },
  {
    id: '8046',
    label: 'Universidade Federal de Rondônia',
    value: 'Universidade Federal de Rondônia'
  },
  {
    id: '8047',
    label: 'Universidade Federal de Roraima',
    value: 'Universidade Federal de Roraima'
  },
  {
    id: '8048',
    label: 'Universidade Federal de Santa Catarina',
    value: 'Universidade Federal de Santa Catarina'
  },
  {
    id: '8049',
    label: 'Universidade Federal de Santa Maria',
    value: 'Universidade Federal de Santa Maria'
  },
  {
    id: '8050',
    label: 'Universidade Federal de Sergipe',
    value: 'Universidade Federal de Sergipe'
  },
  {
    id: '8051',
    label: 'Universidade Federal de São Carlos',
    value: 'Universidade Federal de São Carlos'
  },
  {
    id: '8052',
    label: 'Universidade Federal de São João del-Rei',
    value: 'Universidade Federal de São João del-Rei'
  },
  {
    id: '8053',
    label: 'Universidade Federal de São Paulo',
    value: 'Universidade Federal de São Paulo'
  },
  {
    id: '8054',
    label: 'Universidade Federal de Uberlândia',
    value: 'Universidade Federal de Uberlândia'
  },
  {
    id: '8055',
    label: 'Universidade Federal de Viçosa',
    value: 'Universidade Federal de Viçosa'
  },
  {
    id: '8056',
    label: 'Universidade Federal do ABC',
    value: 'Universidade Federal do ABC'
  },
  {
    id: '8057',
    label: 'Universidade Federal do Acre',
    value: 'Universidade Federal do Acre'
  },
  {
    id: '8058',
    label: 'Universidade Federal do Amapá',
    value: 'Universidade Federal do Amapá'
  },
  {
    id: '8059',
    label: 'Universidade Federal do Ceará',
    value: 'Universidade Federal do Ceará'
  },
  {
    id: '8060',
    label: 'Universidade Federal do Espírito Santo',
    value: 'Universidade Federal do Espírito Santo'
  },
  {
    id: '8061',
    label: 'Universidade Federal do Maranhão',
    value: 'Universidade Federal do Maranhão'
  },
  {
    id: '8062',
    label: 'Universidade Federal do Paraná',
    value: 'Universidade Federal do Paraná'
  },
  {
    id: '8063',
    label: 'Universidade Federal do Pará',
    value: 'Universidade Federal do Pará'
  },
  {
    id: '8064',
    label: 'Universidade Federal do Piauí',
    value: 'Universidade Federal do Piauí'
  },
  {
    id: '8065',
    label: 'Universidade Federal do Rio Grande',
    value: 'Universidade Federal do Rio Grande'
  },
  {
    id: '8066',
    label: 'Universidade Federal do Rio Grande do Norte',
    value: 'Universidade Federal do Rio Grande do Norte'
  },
  {
    id: '8067',
    label: 'Universidade Federal do Rio Grande do Sul',
    value: 'Universidade Federal do Rio Grande do Sul'
  },
  {
    id: '8068',
    label: 'Universidade Federal do Rio de Janeiro',
    value: 'Universidade Federal do Rio de Janeiro'
  },
  {
    id: '8069',
    label: 'Universidade Federal do Triângulo Mineiro',
    value: 'Universidade Federal do Triângulo Mineiro'
  },
  {
    id: '8070',
    label: 'Universidade Federal do Vale do São Francisco',
    value: 'Universidade Federal do Vale do São Francisco'
  },
  {
    id: '8071',
    label: 'Universidade Federal dos Vales do Jequitinhonha e Mucuri',
    value: 'Universidade Federal dos Vales do Jequitinhonha e Mucuri'
  },
  {
    id: '8072',
    label: 'Universidade Fernando Pessoa',
    value: 'Universidade Fernando Pessoa'
  },
  {
    id: '8073',
    label: 'Universidade Franciscana',
    value: 'Universidade Franciscana'
  },
  {
    id: '8074',
    label: 'Universidade Gama Filho',
    value: 'Universidade Gama Filho'
  },
  {
    id: '8075',
    label: 'Universidade Gregorio Semedo',
    value: 'Universidade Gregorio Semedo'
  },
  {
    id: '8076',
    label: 'Universidade Ibirapuera',
    value: 'Universidade Ibirapuera'
  },
  {
    id: '8077',
    label: 'Universidade Independente',
    value: 'Universidade Independente'
  },
  {
    id: '8078',
    label: 'Universidade Independente de Angola',
    value: 'Universidade Independente de Angola'
  },
  {
    id: '8079',
    label: 'Universidade Internacional Lisboa',
    value: 'Universidade Internacional Lisboa'
  },
  {
    id: '8080',
    label: 'Universidade Jean Piaget de Cabo Verde',
    value: 'Universidade Jean Piaget de Cabo Verde'
  },
  {
    id: '8081',
    label: 'Universidade Lusiada',
    value: 'Universidade Lusiada'
  },
  {
    id: '8082',
    label: 'Universidade Lusíada de Angola',
    value: 'Universidade Lusíada de Angola'
  },
  {
    id: '8083',
    label: 'Universidade Lusófona de Humanidades e Technologias',
    value: 'Universidade Lusófona de Humanidades e Technologias'
  },
  {
    id: '8084',
    label: 'Universidade Luterana do Brasil',
    value: 'Universidade Luterana do Brasil'
  },
  {
    id: '8085',
    label: 'Universidade Lúrio',
    value: 'Universidade Lúrio'
  },
  {
    id: '8086',
    label: 'Universidade Mackenzie',
    value: 'Universidade Mackenzie'
  },
  {
    id: '8087',
    label: 'Universidade Metodista de Angola',
    value: 'Universidade Metodista de Angola'
  },
  {
    id: '8088',
    label: 'Universidade Metodista de Piracicaba',
    value: 'Universidade Metodista de Piracicaba'
  },
  {
    id: '8089',
    label: 'Universidade Metropolitana de Angola',
    value: 'Universidade Metropolitana de Angola'
  },
  {
    id: '8090',
    label: 'Universidade Metropolitana de Santos',
    value: 'Universidade Metropolitana de Santos'
  },
  {
    id: '8091',
    label: 'Universidade Moderna de Lisboa',
    value: 'Universidade Moderna de Lisboa'
  },
  {
    id: '8092',
    label: 'Universidade Nova de Lisboa',
    value: 'Universidade Nova de Lisboa'
  },
  {
    id: '8093',
    label: 'Universidade Paulista',
    value: 'Universidade Paulista'
  },
  {
    id: '8094',
    label: 'Universidade Pedagógica',
    value: 'Universidade Pedagógica'
  },
  {
    id: '8095',
    label: 'Universidade Portucalense Infante D. Henrique',
    value: 'Universidade Portucalense Infante D. Henrique'
  },
  {
    id: '8096',
    label: 'Universidade Potiguar',
    value: 'Universidade Potiguar'
  },
  {
    id: '8097',
    label: 'Universidade Regional Integrada do Alto Uruguai e das Missões',
    value: 'Universidade Regional Integrada do Alto Uruguai e das Missões'
  },
  {
    id: '8098',
    label: 'Universidade Regional de Blumenau',
    value: 'Universidade Regional de Blumenau'
  },
  {
    id: '8099',
    label: 'Universidade Regional do Cariri',
    value: 'Universidade Regional do Cariri'
  },
  {
    id: '8100',
    label: 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul',
    value: 'Universidade Regional do Noroeste do Estado do Rio Grande do Sul'
  },
  {
    id: '8101',
    label: 'Universidade Salgado de Oliveira',
    value: 'Universidade Salgado de Oliveira'
  },
  {
    id: '8102',
    label: 'Universidade Salvador',
    value: 'Universidade Salvador'
  },
  {
    id: '8103',
    label: 'Universidade Santa Cecília dos Bandeirantes',
    value: 'Universidade Santa Cecília dos Bandeirantes'
  },
  {
    id: '8104',
    label: 'Universidade Santa Úrsula',
    value: 'Universidade Santa Úrsula'
  },
  {
    id: '8105',
    label: 'Universidade São Francisco',
    value: 'Universidade São Francisco'
  },
  {
    id: '8106',
    label: 'Universidade São Judas Tadeu',
    value: 'Universidade São Judas Tadeu'
  },
  {
    id: '8107',
    label: 'Universidade São Marcos',
    value: 'Universidade São Marcos'
  },
  {
    id: '8108',
    label: 'Universidade Tecnica de Angola',
    value: 'Universidade Tecnica de Angola'
  },
  {
    id: '8109',
    label: 'Universidade Tecnica de Lisboa',
    value: 'Universidade Tecnica de Lisboa'
  },
  {
    id: '8110',
    label: 'Universidade Tecnológica Federal do Paraná',
    value: 'Universidade Tecnológica Federal do Paraná'
  },
  {
    id: '8111',
    label: 'Universidade Tiradentes',
    value: 'Universidade Tiradentes'
  },
  {
    id: '8112',
    label: 'Universidade Vale do Paraiba',
    value: 'Universidade Vale do Paraiba'
  },
  {
    id: '8113',
    label: 'Universidade Vale do Rio Doce',
    value: 'Universidade Vale do Rio Doce'
  },
  {
    id: '8114',
    label: 'Universidade Vale do Rio dos Sinos',
    value: 'Universidade Vale do Rio dos Sinos'
  },
  {
    id: '8115',
    label: 'Universidade Veiga de Almeida',
    value: 'Universidade Veiga de Almeida'
  },
  {
    id: '8116',
    label: 'Universidade Virtual do Estado de São Paulo',
    value: 'Universidade Virtual do Estado de São Paulo'
  },
  {
    id: '8117',
    label: 'Universidade Zambeze',
    value: 'Universidade Zambeze'
  },
  {
    id: '8118',
    label: 'Universidade da Amazônia',
    value: 'Universidade da Amazônia'
  },
  {
    id: '8119',
    label: 'Universidade da Beira Interior',
    value: 'Universidade da Beira Interior'
  },
  {
    id: '8120',
    label: 'Universidade da Madeira',
    value: 'Universidade da Madeira'
  },
  {
    id: '8121',
    label: 'Universidade da Região da Campanha',
    value: 'Universidade da Região da Campanha'
  },
  {
    id: '8122',
    label: 'Universidade de Alfenas',
    value: 'Universidade de Alfenas'
  },
  {
    id: '8123',
    label: 'Universidade de Aveiro',
    value: 'Universidade de Aveiro'
  },
  {
    id: '8124',
    label: 'Universidade de Brasília',
    value: 'Universidade de Brasília'
  },
  {
    id: '8125',
    label: 'Universidade de Caxias do Sul',
    value: 'Universidade de Caxias do Sul'
  },
  {
    id: '8126',
    label: 'Universidade de Coimbra',
    value: 'Universidade de Coimbra'
  },
  {
    id: '8127',
    label: 'Universidade de Cruz Alta',
    value: 'Universidade de Cruz Alta'
  },
  {
    id: '8128',
    label: 'Universidade de Evora',
    value: 'Universidade de Evora'
  },
  {
    id: '8129',
    label: 'Universidade de Fortaleza',
    value: 'Universidade de Fortaleza'
  },
  {
    id: '8130',
    label: 'Universidade de Franca',
    value: 'Universidade de Franca'
  },
  {
    id: '8131',
    label: 'Universidade de Guarulhos',
    value: 'Universidade de Guarulhos'
  },
  {
    id: '8132',
    label: 'Universidade de Itaúna',
    value: 'Universidade de Itaúna'
  },
  {
    id: '8133',
    label: 'Universidade de Lisboa',
    value: 'Universidade de Lisboa'
  },
  {
    id: '8134',
    label: 'Universidade de Marília',
    value: 'Universidade de Marília'
  },
  {
    id: '8135',
    label: 'Universidade de Mogi das Cruzes',
    value: 'Universidade de Mogi das Cruzes'
  },
  {
    id: '8136',
    label: 'Universidade de Nova Iguaçu',
    value: 'Universidade de Nova Iguaçu'
  },
  {
    id: '8137',
    label: 'Universidade de Passo Fundo',
    value: 'Universidade de Passo Fundo'
  },
  {
    id: '8138',
    label: 'Universidade de Pernambuco',
    value: 'Universidade de Pernambuco'
  },
  {
    id: '8139',
    label: 'Universidade de Ribeirão Preto',
    value: 'Universidade de Ribeirão Preto'
  },
  {
    id: '8140',
    label: 'Universidade de Santa Cruz do Sul',
    value: 'Universidade de Santa Cruz do Sul'
  },
  {
    id: '8141',
    label: 'Universidade de Santo Amaro',
    value: 'Universidade de Santo Amaro'
  },
  {
    id: '8142',
    label: 'Universidade de São Paulo',
    value: 'Universidade de São Paulo'
  },
  {
    id: '8143',
    label: 'Universidade de Taubaté',
    value: 'Universidade de Taubaté'
  },
  {
    id: '8144',
    label: 'Universidade de Tocantins',
    value: 'Universidade de Tocantins'
  },
  {
    id: '8145',
    label: 'Universidade de Tras-os-Montes e Alto Douro',
    value: 'Universidade de Tras-os-Montes e Alto Douro'
  },
  {
    id: '8146',
    label: 'Universidade de Uberaba',
    value: 'Universidade de Uberaba'
  },
  {
    id: '8147',
    label: 'Universidade do Algarve',
    value: 'Universidade do Algarve'
  },
  {
    id: '8148',
    label: 'Universidade do Amazonas',
    value: 'Universidade do Amazonas'
  },
  {
    id: '8149',
    label: 'Universidade do Estado da Bahia',
    value: 'Universidade do Estado da Bahia'
  },
  {
    id: '8150',
    label: 'Universidade do Estado de Santa Catarina',
    value: 'Universidade do Estado de Santa Catarina'
  },
  {
    id: '8151',
    label: 'Universidade do Estado do Rio Grande do Norte',
    value: 'Universidade do Estado do Rio Grande do Norte'
  },
  {
    id: '8152',
    label: 'Universidade do Estado do Rio de Janeiro',
    value: 'Universidade do Estado do Rio de Janeiro'
  },
  {
    id: '8153',
    label: 'Universidade do Extremo Sul Catarinense',
    value: 'Universidade do Extremo Sul Catarinense'
  },
  {
    id: '8154',
    label: 'Universidade do Grande Rio',
    value: 'Universidade do Grande Rio'
  },
  {
    id: '8155',
    label: 'Universidade do Minho',
    value: 'Universidade do Minho'
  },
  {
    id: '8156',
    label: 'Universidade do Oeste Paulista',
    value: 'Universidade do Oeste Paulista'
  },
  {
    id: '8157',
    label: 'Universidade do Oeste de Santa Catarina',
    value: 'Universidade do Oeste de Santa Catarina'
  },
  {
    id: '8158',
    label: 'Universidade do Porto',
    value: 'Universidade do Porto'
  },
  {
    id: '8159',
    label: 'Universidade do Rio de Janeiro',
    value: 'Universidade do Rio de Janeiro'
  },
  {
    id: '8160',
    label: 'Universidade do Sagrado Coração',
    value: 'Universidade do Sagrado Coração'
  },
  {
    id: '8161',
    label: 'Universidade do Sul de Santa Catarina',
    value: 'Universidade do Sul de Santa Catarina'
  },
  {
    id: '8162',
    label: 'Universidade do Vale do Itajaí',
    value: 'Universidade do Vale do Itajaí'
  },
  {
    id: '8163',
    label: 'Universidade dos Acores',
    value: 'Universidade dos Acores'
  },
  {
    id: '8164',
    label: 'Universidade para o Desenvolvimento do Alto Vale do Itajaí',
    value: 'Universidade para o Desenvolvimento do Alto Vale do Itajaí'
  },
  {
    id: '8165',
    label: 'Universita della Svizzera Italiana',
    value: 'Universita della Svizzera Italiana'
  },
  {
    id: '8166',
    label: 'Universitas 17 Agustus 1945 Banyuwangi',
    value: 'Universitas 17 Agustus 1945 Banyuwangi'
  },
  {
    id: '8167',
    label: 'Universitas 17 Agustus 1945 Cirebon',
    value: 'Universitas 17 Agustus 1945 Cirebon'
  },
  {
    id: '8168',
    label: 'Universitas 17 Agustus 1945 Jakarta',
    value: 'Universitas 17 Agustus 1945 Jakarta'
  },
  {
    id: '8169',
    label: 'Universitas 17 Agustus 1945 Samarinda',
    value: 'Universitas 17 Agustus 1945 Samarinda'
  },
  {
    id: '8170',
    label: 'Universitas 17 Agustus 1945 Semarang',
    value: 'Universitas 17 Agustus 1945 Semarang'
  },
  {
    id: '8171',
    label: 'Universitas 17 Agustus 1945 Surabaya',
    value: 'Universitas 17 Agustus 1945 Surabaya'
  },
  {
    id: '8172',
    label: 'Universitas Advent Indonesia',
    value: 'Universitas Advent Indonesia'
  },
  {
    id: '8173',
    label: 'Universitas Ahmad Dahlan',
    value: 'Universitas Ahmad Dahlan'
  },
  {
    id: '8174',
    label: 'Universitas Airlangga',
    value: 'Universitas Airlangga'
  },
  {
    id: '8175',
    label: 'Universitas Amir Hamzah',
    value: 'Universitas Amir Hamzah'
  },
  {
    id: '8176',
    label: 'Universitas Andalas',
    value: 'Universitas Andalas'
  },
  {
    id: '8177',
    label: 'Universitas Atma Jaya Yogyakarta',
    value: 'Universitas Atma Jaya Yogyakarta'
  },
  {
    id: '8178',
    label: 'Universitas Bakrie',
    value: 'Universitas Bakrie'
  },
  {
    id: '8179',
    label: 'Universitas Bengkulu',
    value: 'Universitas Bengkulu'
  },
  {
    id: '8180',
    label: 'Universitas Bhayangkara Jakarta Raya',
    value: 'Universitas Bhayangkara Jakarta Raya'
  },
  {
    id: '8181',
    label: 'Universitas Bhayangkara Surabaya',
    value: 'Universitas Bhayangkara Surabaya'
  },
  {
    id: '8182',
    label: 'Universitas Bojonegoro',
    value: 'Universitas Bojonegoro'
  },
  {
    id: '8183',
    label: 'Universitas Brawijaya',
    value: 'Universitas Brawijaya'
  },
  {
    id: '8184',
    label: 'Universitas Bunda Mulia Jakarta',
    value: 'Universitas Bunda Mulia Jakarta'
  },
  {
    id: '8185',
    label: 'Universitas Bung Hatta',
    value: 'Universitas Bung Hatta'
  },
  {
    id: '8186',
    label: 'Universitas Cenderawasih',
    value: 'Universitas Cenderawasih'
  },
  {
    id: '8187',
    label: 'Universitas Darma Agung',
    value: 'Universitas Darma Agung'
  },
  {
    id: '8188',
    label: 'Universitas Darma Persada',
    value: 'Universitas Darma Persada'
  },
  {
    id: '8189',
    label: 'Universitas Dian Nuswantoro',
    value: 'Universitas Dian Nuswantoro'
  },
  {
    id: '8190',
    label: 'Universitas Diponegoro',
    value: 'Universitas Diponegoro'
  },
  {
    id: '8191',
    label: 'Universitas Dr. R. Moestopo',
    value: 'Universitas Dr. R. Moestopo'
  },
  {
    id: '8192',
    label: 'Universitas Gadjah Mada',
    value: 'Universitas Gadjah Mada'
  },
  {
    id: '8193',
    label: 'Universitas Gunadarma',
    value: 'Universitas Gunadarma'
  },
  {
    id: '8194',
    label: 'Universitas HKBP Nommensen',
    value: 'Universitas HKBP Nommensen'
  },
  {
    id: '8195',
    label: 'Universitas Haluoleo',
    value: 'Universitas Haluoleo'
  },
  {
    id: '8196',
    label: 'Universitas Hasanuddin',
    value: 'Universitas Hasanuddin'
  },
  {
    id: '8197',
    label: 'Universitas Ibn Chaldun Jakarta',
    value: 'Universitas Ibn Chaldun Jakarta'
  },
  {
    id: '8198',
    label: 'Universitas Ibn Khaldun Bogor',
    value: 'Universitas Ibn Khaldun Bogor'
  },
  {
    id: '8199',
    label: 'Universitas Indonesia',
    value: 'Universitas Indonesia'
  },
  {
    id: '8200',
    label: 'Universitas Indonusa Esa Unggul',
    value: 'Universitas Indonusa Esa Unggul'
  },
  {
    id: '8201',
    label: 'Universitas Islam Bandung',
    value: 'Universitas Islam Bandung'
  },
  {
    id: '8202',
    label: 'Universitas Islam Indonesia',
    value: 'Universitas Islam Indonesia'
  },
  {
    id: '8203',
    label: 'Universitas Islam Jakarta',
    value: 'Universitas Islam Jakarta'
  },
  {
    id: '8204',
    label: 'Universitas Islam Malang',
    value: 'Universitas Islam Malang'
  },
  {
    id: '8205',
    label: 'Universitas Islam Nusantara',
    value: 'Universitas Islam Nusantara'
  },
  {
    id: '8206',
    label: 'Universitas Islam Riau',
    value: 'Universitas Islam Riau'
  },
  {
    id: '8207',
    label: 'Universitas Islam Sultan Agung',
    value: 'Universitas Islam Sultan Agung'
  },
  {
    id: '8208',
    label: 'Universitas Islam Sumatera Utara',
    value: 'Universitas Islam Sumatera Utara'
  },
  {
    id: '8209',
    label: 'Universitas Islam Syekh-Yusuf',
    value: 'Universitas Islam Syekh-Yusuf'
  },
  {
    id: '8210',
    label: 'Universitas Jambi',
    value: 'Universitas Jambi'
  },
  {
    id: '8211',
    label: 'Universitas Janabadra',
    value: 'Universitas Janabadra'
  },
  {
    id: '8212',
    label: 'Universitas Jayabaya',
    value: 'Universitas Jayabaya'
  },
  {
    id: '8213',
    label: 'Universitas Jember',
    value: 'Universitas Jember'
  },
  {
    id: '8214',
    label: 'Universitas Jenderal Achmad Yani',
    value: 'Universitas Jenderal Achmad Yani'
  },
  {
    id: '8215',
    label: 'Universitas Jenderal Soedirman',
    value: 'Universitas Jenderal Soedirman'
  },
  {
    id: '8216',
    label: 'Universitas Katolik Indonesia Atma Jaya',
    value: 'Universitas Katolik Indonesia Atma Jaya'
  },
  {
    id: '8217',
    label: 'Universitas Katolik Parahyangan',
    value: 'Universitas Katolik Parahyangan'
  },
  {
    id: '8218',
    label: 'Universitas Katolik Soegijapranoto',
    value: 'Universitas Katolik Soegijapranoto'
  },
  {
    id: '8219',
    label: 'Universitas Katolik Widya Karya',
    value: 'Universitas Katolik Widya Karya'
  },
  {
    id: '8220',
    label: 'Universitas Katolik Widya Manadala',
    value: 'Universitas Katolik Widya Manadala'
  },
  {
    id: '8221',
    label: 'Universitas Kediri',
    value: 'Universitas Kediri'
  },
  {
    id: '8222',
    label: 'Universitas Khairun',
    value: 'Universitas Khairun'
  },
  {
    id: '8223',
    label: 'Universitas Klabat',
    value: 'Universitas Klabat'
  },
  {
    id: '8224',
    label: 'Universitas Komputer Indonesia',
    value: 'Universitas Komputer Indonesia'
  },
  {
    id: '8225',
    label: 'Universitas Krisnadwipayana',
    value: 'Universitas Krisnadwipayana'
  },
  {
    id: '8226',
    label: 'Universitas Kristen Duta Wacana',
    value: 'Universitas Kristen Duta Wacana'
  },
  {
    id: '8227',
    label: 'Universitas Kristen Indonesia',
    value: 'Universitas Kristen Indonesia'
  },
  {
    id: '8228',
    label: 'Universitas Kristen Indonesia Tomohon',
    value: 'Universitas Kristen Indonesia Tomohon'
  },
  {
    id: '8229',
    label: 'Universitas Kristen Krida Wacana',
    value: 'Universitas Kristen Krida Wacana'
  },
  {
    id: '8230',
    label: 'Universitas Kristen Maranatha',
    value: 'Universitas Kristen Maranatha'
  },
  {
    id: '8231',
    label: 'Universitas Kristen Petra',
    value: 'Universitas Kristen Petra'
  },
  {
    id: '8232',
    label: 'Universitas Kristen Satya Wacana',
    value: 'Universitas Kristen Satya Wacana'
  },
  {
    id: '8233',
    label: 'Universitas Lambung Mangkurat',
    value: 'Universitas Lambung Mangkurat'
  },
  {
    id: '8234',
    label: 'Universitas Lampung',
    value: 'Universitas Lampung'
  },
  {
    id: '8235',
    label: 'Universitas Lancang Kuning',
    value: 'Universitas Lancang Kuning'
  },
  {
    id: '8236',
    label: 'Universitas Ma Chung',
    value: 'Universitas Ma Chung'
  },
  {
    id: '8237',
    label: 'Universitas Maarif Hasyim Latif',
    value: 'Universitas Maarif Hasyim Latif'
  },
  {
    id: '8238',
    label: 'Universitas Madura',
    value: 'Universitas Madura'
  },
  {
    id: '8239',
    label: 'Universitas Mahasaraswati Denpasar',
    value: 'Universitas Mahasaraswati Denpasar'
  },
  {
    id: '8240',
    label: 'Universitas Mahendradatta',
    value: 'Universitas Mahendradatta'
  },
  {
    id: '8241',
    label: 'Universitas Mataram',
    value: 'Universitas Mataram'
  },
  {
    id: '8242',
    label: 'Universitas Mercu Buana',
    value: 'Universitas Mercu Buana'
  },
  {
    id: '8243',
    label: 'Universitas Merdeka Madiun',
    value: 'Universitas Merdeka Madiun'
  },
  {
    id: '8244',
    label: 'Universitas Merdeka Malang',
    value: 'Universitas Merdeka Malang'
  },
  {
    id: '8245',
    label: 'Universitas Methodist Indonesia',
    value: 'Universitas Methodist Indonesia'
  },
  {
    id: '8246',
    label: 'Universitas Muhammadiyah Jakarta',
    value: 'Universitas Muhammadiyah Jakarta'
  },
  {
    id: '8247',
    label: 'Universitas Muhammadiyah Jember',
    value: 'Universitas Muhammadiyah Jember'
  },
  {
    id: '8248',
    label: 'Universitas Muhammadiyah Magelang',
    value: 'Universitas Muhammadiyah Magelang'
  },
  {
    id: '8249',
    label: 'Universitas Muhammadiyah Makassar',
    value: 'Universitas Muhammadiyah Makassar'
  },
  {
    id: '8250',
    label: 'Universitas Muhammadiyah Malang',
    value: 'Universitas Muhammadiyah Malang'
  },
  {
    id: '8251',
    label: 'Universitas Muhammadiyah Mataram',
    value: 'Universitas Muhammadiyah Mataram'
  },
  {
    id: '8252',
    label: 'Universitas Muhammadiyah Sidoarjo',
    value: 'Universitas Muhammadiyah Sidoarjo'
  },
  {
    id: '8253',
    label: 'Universitas Muhammadiyah Sumatra Utara',
    value: 'Universitas Muhammadiyah Sumatra Utara'
  },
  {
    id: '8254',
    label: 'Universitas Muhammadiyah Surakarta',
    value: 'Universitas Muhammadiyah Surakarta'
  },
  {
    id: '8255',
    label: 'Universitas Muhammadiyah Yogyakarta',
    value: 'Universitas Muhammadiyah Yogyakarta'
  },
  {
    id: '8256',
    label: 'Universitas Mulawarman',
    value: 'Universitas Mulawarman'
  },
  {
    id: '8257',
    label: 'Universitas Muria Kudus',
    value: 'Universitas Muria Kudus'
  },
  {
    id: '8258',
    label: 'Universitas Muslim Indonesia',
    value: 'Universitas Muslim Indonesia'
  },
  {
    id: '8259',
    label: 'Universitas Narotama',
    value: 'Universitas Narotama'
  },
  {
    id: '8260',
    label: 'Universitas Nasional Jakarta',
    value: 'Universitas Nasional Jakarta'
  },
  {
    id: '8261',
    label: 'Universitas Nasional Pasim',
    value: 'Universitas Nasional Pasim'
  },
  {
    id: '8262',
    label: 'Universitas Negeri Gorontalo',
    value: 'Universitas Negeri Gorontalo'
  },
  {
    id: '8263',
    label: 'Universitas Negeri Jakarta',
    value: 'Universitas Negeri Jakarta'
  },
  {
    id: '8264',
    label: 'Universitas Negeri Makassar',
    value: 'Universitas Negeri Makassar'
  },
  {
    id: '8265',
    label: 'Universitas Negeri Malang',
    value: 'Universitas Negeri Malang'
  },
  {
    id: '8266',
    label: 'Universitas Negeri Manado',
    value: 'Universitas Negeri Manado'
  },
  {
    id: '8267',
    label: 'Universitas Negeri Medan',
    value: 'Universitas Negeri Medan'
  },
  {
    id: '8268',
    label: 'Universitas Negeri Padang',
    value: 'Universitas Negeri Padang'
  },
  {
    id: '8269',
    label: 'Universitas Negeri Semarang',
    value: 'Universitas Negeri Semarang'
  },
  {
    id: '8270',
    label: 'Universitas Negeri Surabaya',
    value: 'Universitas Negeri Surabaya'
  },
  {
    id: '8271',
    label: 'Universitas Negeri Yogyakarta',
    value: 'Universitas Negeri Yogyakarta'
  },
  {
    id: '8272',
    label: 'Universitas Ngurah Rai',
    value: 'Universitas Ngurah Rai'
  },
  {
    id: '8273',
    label: 'Universitas Nusa Cendana',
    value: 'Universitas Nusa Cendana'
  },
  {
    id: '8274',
    label: 'Universitas PGRI Adi Buana Surabaya',
    value: 'Universitas PGRI Adi Buana Surabaya'
  },
  {
    id: '8275',
    label: 'Universitas Padjadjaran',
    value: 'Universitas Padjadjaran'
  },
  {
    id: '8276',
    label: 'Universitas Pakuan',
    value: 'Universitas Pakuan'
  },
  {
    id: '8277',
    label: 'Universitas Palangka Raya',
    value: 'Universitas Palangka Raya'
  },
  {
    id: '8278',
    label: 'Universitas Pancasakti Tegal',
    value: 'Universitas Pancasakti Tegal'
  },
  {
    id: '8279',
    label: 'Universitas Pancasila',
    value: 'Universitas Pancasila'
  },
  {
    id: '8280',
    label: 'Universitas Paramadina Mulya',
    value: 'Universitas Paramadina Mulya'
  },
  {
    id: '8281',
    label: 'Universitas Pasundan',
    value: 'Universitas Pasundan'
  },
  {
    id: '8282',
    label: 'Universitas Pattimura',
    value: 'Universitas Pattimura'
  },
  {
    id: '8283',
    label: 'Universitas Pekalongan',
    value: 'Universitas Pekalongan'
  },
  {
    id: '8284',
    label: 'Universitas Pelita Harapan',
    value: 'Universitas Pelita Harapan'
  },
  {
    id: '8285',
    label: 'Universitas Pembangunan Nasional "Veteran" Jakarta',
    value: 'Universitas Pembangunan Nasional "Veteran" Jakarta'
  },
  {
    id: '8286',
    label: 'Universitas Pembangunan Nasional "Veteran" Jawa Timur',
    value: 'Universitas Pembangunan Nasional "Veteran" Jawa Timur'
  },
  {
    id: '8287',
    label: 'Universitas Pembangunan Nasional "Veteran" Yogyakarta',
    value: 'Universitas Pembangunan Nasional "Veteran" Yogyakarta'
  },
  {
    id: '8288',
    label: 'Universitas Pembangunan Panca Budi',
    value: 'Universitas Pembangunan Panca Budi'
  },
  {
    id: '8289',
    label: 'Universitas Pendidikan Indonesia',
    value: 'Universitas Pendidikan Indonesia'
  },
  {
    id: '8290',
    label: 'Universitas Persada Indonesia Y.A.I',
    value: 'Universitas Persada Indonesia Y.A.I'
  },
  {
    id: '8291',
    label: 'Universitas Pesantren Darul Ulum Jombang',
    value: 'Universitas Pesantren Darul Ulum Jombang'
  },
  {
    id: '8292',
    label: 'Universitas Proklamasi 45',
    value: 'Universitas Proklamasi 45'
  },
  {
    id: '8293',
    label: 'Universitas Putera Batam',
    value: 'Universitas Putera Batam'
  },
  {
    id: '8294',
    label: 'Universitas Riau',
    value: 'Universitas Riau'
  },
  {
    id: '8295',
    label: 'Universitas Sam Ratulangi',
    value: 'Universitas Sam Ratulangi'
  },
  {
    id: '8296',
    label: 'Universitas Sanata Dharma',
    value: 'Universitas Sanata Dharma'
  },
  {
    id: '8297',
    label: 'Universitas Sarjanawiyata Tamansiswa',
    value: 'Universitas Sarjanawiyata Tamansiswa'
  },
  {
    id: '8298',
    label: 'Universitas Sebelas Maret',
    value: 'Universitas Sebelas Maret'
  },
  {
    id: '8299',
    label: 'Universitas Semarang',
    value: 'Universitas Semarang'
  },
  {
    id: '8300',
    label: 'Universitas Siliwangi',
    value: 'Universitas Siliwangi'
  },
  {
    id: '8301',
    label: 'Universitas Simalungun',
    value: 'Universitas Simalungun'
  },
  {
    id: '8302',
    label: 'Universitas Slamet Riyadi Surakarta',
    value: 'Universitas Slamet Riyadi Surakarta'
  },
  {
    id: '8303',
    label: 'Universitas Sriwijaya',
    value: 'Universitas Sriwijaya'
  },
  {
    id: '8304',
    label: 'Universitas Sultan Ageng Tirtayasa',
    value: 'Universitas Sultan Ageng Tirtayasa'
  },
  {
    id: '8305',
    label: 'Universitas Sumatera Utara',
    value: 'Universitas Sumatera Utara'
  },
  {
    id: '8306',
    label: 'Universitas Surabaya',
    value: 'Universitas Surabaya'
  },
  {
    id: '8307',
    label: 'Universitas Swadaya Gunung Djati',
    value: 'Universitas Swadaya Gunung Djati'
  },
  {
    id: '8308',
    label: 'Universitas Syiah Kuala',
    value: 'Universitas Syiah Kuala'
  },
  {
    id: '8309',
    label: 'Universitas Tadulako',
    value: 'Universitas Tadulako'
  },
  {
    id: '8310',
    label: 'Universitas Tanjungpura',
    value: 'Universitas Tanjungpura'
  },
  {
    id: '8311',
    label: 'Universitas Tarumanagara',
    value: 'Universitas Tarumanagara'
  },
  {
    id: '8312',
    label: 'Universitas Telkom',
    value: 'Universitas Telkom'
  },
  {
    id: '8313',
    label: 'Universitas Terbuka Indonesia',
    value: 'Universitas Terbuka Indonesia'
  },
  {
    id: '8314',
    label: 'Universitas Tidar Magelang',
    value: 'Universitas Tidar Magelang'
  },
  {
    id: '8315',
    label: 'Universitas Trilogi',
    value: 'Universitas Trilogi'
  },
  {
    id: '8316',
    label: 'Universitas Trisakti',
    value: 'Universitas Trisakti'
  },
  {
    id: '8317',
    label: 'Universitas Trunojoyo Madura',
    value: 'Universitas Trunojoyo Madura'
  },
  {
    id: '8318',
    label: 'Universitas Tunas Pembangunan',
    value: 'Universitas Tunas Pembangunan'
  },
  {
    id: '8319',
    label: 'Universitas Udayana',
    value: 'Universitas Udayana'
  },
  {
    id: '8320',
    label: 'Universitas Widya Gama Malang',
    value: 'Universitas Widya Gama Malang'
  },
  {
    id: '8321',
    label: 'Universitas Widyatama',
    value: 'Universitas Widyatama'
  },
  {
    id: '8322',
    label: 'Universitas Wijaya Kusuma Purwokerto',
    value: 'Universitas Wijaya Kusuma Purwokerto'
  },
  {
    id: '8323',
    label: 'Universitas Wijaya Kusuma Surabaya',
    value: 'Universitas Wijaya Kusuma Surabaya'
  },
  {
    id: '8324',
    label: 'Universitas Wisnuwardhana',
    value: 'Universitas Wisnuwardhana'
  },
  {
    id: '8325',
    label: 'Universitas Yarsi',
    value: 'Universitas Yarsi'
  },
  {
    id: '8326',
    label: 'Universitat Autónoma de Barcelona',
    value: 'Universitat Autónoma de Barcelona'
  },
  {
    id: '8327',
    label: 'Universitat Internacional de Catalunya',
    value: 'Universitat Internacional de Catalunya'
  },
  {
    id: '8328',
    label: 'Universitat Jaume I de Castellón',
    value: 'Universitat Jaume I de Castellón'
  },
  {
    id: '8329',
    label: 'Universitat Oberta de Catalunya',
    value: 'Universitat Oberta de Catalunya'
  },
  {
    id: '8330',
    label: 'Universitat Pompeu Fabra',
    value: 'Universitat Pompeu Fabra'
  },
  {
    id: '8331',
    label: 'Universitat Rovira I Virgili Tarragona',
    value: 'Universitat Rovira I Virgili Tarragona'
  },
  {
    id: '8332',
    label: 'Universitat Rámon Llull',
    value: 'Universitat Rámon Llull'
  },
  {
    id: '8333',
    label: 'Universitat de Barcelona',
    value: 'Universitat de Barcelona'
  },
  {
    id: '8334',
    label: 'Universitat de Lleida',
    value: 'Universitat de Lleida'
  },
  {
    id: '8335',
    label: 'Universitat de Vic',
    value: 'Universitat de Vic'
  },
  {
    id: '8336',
    label: 'Universitat de les Illes Balears',
    value: 'Universitat de les Illes Balears'
  },
  {
    id: '8337',
    label: 'Universitatea de Vest "Vasile Goldiş"',
    value: 'Universitatea de Vest "Vasile Goldiş"'
  },
  {
    id: '8338',
    label: 'Universite Amadou Hampaté Ba',
    value: 'Universite Amadou Hampaté Ba'
  },
  {
    id: '8339',
    label: 'Universite des Sciences Islamiques de Constantine',
    value: 'Universite des Sciences Islamiques de Constantine'
  },
  {
    id: '8340',
    label: 'Universiteit Antwerpen',
    value: 'Universiteit Antwerpen'
  },
  {
    id: '8341',
    label: 'Universiteit Antwerpen Management School',
    value: 'Universiteit Antwerpen Management School'
  },
  {
    id: '8342',
    label: 'Universiteit Antwerpen, UFSIA',
    value: 'Universiteit Antwerpen, UFSIA'
  },
  {
    id: '8343',
    label: 'Universiteit Gent',
    value: 'Universiteit Gent'
  },
  {
    id: '8344',
    label: 'Universiteit Leiden',
    value: 'Universiteit Leiden'
  },
  {
    id: '8345',
    label: 'Universiti Darul Iman',
    value: 'Universiti Darul Iman'
  },
  {
    id: '8346',
    label: 'Universiti Industri Selangor',
    value: 'Universiti Industri Selangor'
  },
  {
    id: '8347',
    label: 'Universiti Islam Sultan Sharif Ali',
    value: 'Universiti Islam Sultan Sharif Ali'
  },
  {
    id: '8348',
    label: 'Universiti Kebangsaan Malaysia',
    value: 'Universiti Kebangsaan Malaysia'
  },
  {
    id: '8349',
    label: 'Universiti Kuala Lumpur',
    value: 'Universiti Kuala Lumpur'
  },
  {
    id: '8350',
    label: 'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)',
    value: 'Universiti Kuala Lumpur Malaysian Institute of Information Technology (MIIT)'
  },
  {
    id: '8351',
    label: 'Universiti Malaya',
    value: 'Universiti Malaya'
  },
  {
    id: '8352',
    label: 'Universiti Malaysia Kelantan',
    value: 'Universiti Malaysia Kelantan'
  },
  {
    id: '8353',
    label: 'Universiti Malaysia Perlis',
    value: 'Universiti Malaysia Perlis'
  },
  {
    id: '8354',
    label: 'Universiti Malaysia Sabah',
    value: 'Universiti Malaysia Sabah'
  },
  {
    id: '8355',
    label: 'Universiti Malaysia Sarawak',
    value: 'Universiti Malaysia Sarawak'
  },
  {
    id: '8356',
    label: 'Universiti Malaysia Terengganu',
    value: 'Universiti Malaysia Terengganu'
  },
  {
    id: '8357',
    label: 'Universiti Pendidikan Sultan Idris',
    value: 'Universiti Pendidikan Sultan Idris'
  },
  {
    id: '8358',
    label: 'Universiti Putra Malaysia',
    value: 'Universiti Putra Malaysia'
  },
  {
    id: '8359',
    label: 'Universiti Sains Malaysia',
    value: 'Universiti Sains Malaysia'
  },
  {
    id: '8360',
    label: 'Universiti Sultan Zainal Abidin',
    value: 'Universiti Sultan Zainal Abidin'
  },
  {
    id: '8361',
    label: 'Universiti Teknikal Malaysia Melaka',
    value: 'Universiti Teknikal Malaysia Melaka'
  },
  {
    id: '8362',
    label: 'Universiti Teknologi Malaysia',
    value: 'Universiti Teknologi Malaysia'
  },
  {
    id: '8363',
    label: 'Universiti Teknologi Mara',
    value: 'Universiti Teknologi Mara'
  },
  {
    id: '8364',
    label: 'Universiti Tenaga Nasional',
    value: 'Universiti Tenaga Nasional'
  },
  {
    id: '8365',
    label: 'Universiti Tun Abdul Razak',
    value: 'Universiti Tun Abdul Razak'
  },
  {
    id: '8366',
    label: 'Universiti Tun Hussein Onn Malaysia',
    value: 'Universiti Tun Hussein Onn Malaysia'
  },
  {
    id: '8367',
    label: 'Universiti Tunku Abdul Rahman',
    value: 'Universiti Tunku Abdul Rahman'
  },
  {
    id: '8368',
    label: 'Universiti Utara Malaysia',
    value: 'Universiti Utara Malaysia'
  },
  {
    id: '8369',
    label: 'University "Aurel Vlaicu" Arad',
    value: 'University "Aurel Vlaicu" Arad'
  },
  {
    id: '8370',
    label: 'University "Petre Andrei" Iasi',
    value: 'University "Petre Andrei" Iasi'
  },
  {
    id: '8371',
    label: 'University "Titu Maiorescu"',
    value: 'University "Titu Maiorescu"'
  },
  {
    id: '8372',
    label: 'University "Transilvany" of Brasov',
    value: 'University "Transilvany" of Brasov'
  },
  {
    id: '8373',
    label: 'University Campus Suffolk',
    value: 'University Campus Suffolk'
  },
  {
    id: '8374',
    label: 'University Canada West',
    value: 'University Canada West'
  },
  {
    id: '8375',
    label: 'University Center "César Ritz"',
    value: 'University Center "César Ritz"'
  },
  {
    id: '8376',
    label: 'University Centre of the Westfjords',
    value: 'University Centre of the Westfjords'
  },
  {
    id: '8377',
    label: 'University College Bahrain',
    value: 'University College Bahrain'
  },
  {
    id: '8378',
    label: 'University College Cork',
    value: 'University College Cork'
  },
  {
    id: '8379',
    label: 'University College Dublin',
    value: 'University College Dublin'
  },
  {
    id: '8380',
    label: 'University College London, University of London',
    value: 'University College London, University of London'
  },
  {
    id: '8381',
    label: 'University College Odisee',
    value: 'University College Odisee'
  },
  {
    id: '8382',
    label: 'University College of Applied Sciences',
    value: 'University College of Applied Sciences'
  },
  {
    id: '8383',
    label: 'University College of Arts, Crafts and Design',
    value: 'University College of Arts, Crafts and Design'
  },
  {
    id: '8384',
    label: 'University College of Borås',
    value: 'University College of Borås'
  },
  {
    id: '8385',
    label: 'University College of Cape Breton',
    value: 'University College of Cape Breton'
  },
  {
    id: '8386',
    label: 'University College of Gävle',
    value: 'University College of Gävle'
  },
  {
    id: '8387',
    label: 'University College of Kristianstad',
    value: 'University College of Kristianstad'
  },
  {
    id: '8388',
    label: 'University College of Nabi Akram',
    value: 'University College of Nabi Akram'
  },
  {
    id: '8389',
    label: 'University College of Saint-Boniface',
    value: 'University College of Saint-Boniface'
  },
  {
    id: '8390',
    label: 'University College of Skövde',
    value: 'University College of Skövde'
  },
  {
    id: '8391',
    label: 'University College of Technology & Innovation (UCTI)',
    value: 'University College of Technology & Innovation (UCTI)'
  },
  {
    id: '8392',
    label: 'University College of Trollhättan/Uddevalla',
    value: 'University College of Trollhättan/Uddevalla'
  },
  {
    id: '8393',
    label: 'University College of the Cariboo',
    value: 'University College of the Cariboo'
  },
  {
    id: '8394',
    label: 'University College, Thiruvananthapuram',
    value: 'University College, Thiruvananthapuram'
  },
  {
    id: '8395',
    label: 'University Goce Delcev',
    value: 'University Goce Delcev'
  },
  {
    id: '8396',
    label: 'University Hospital Zurich',
    value: 'University Hospital Zurich'
  },
  {
    id: '8397',
    label: 'University Institute of Architecture Venice',
    value: 'University Institute of Architecture Venice'
  },
  {
    id: '8398',
    label: 'University Institute of Modern Languages',
    value: 'University Institute of Modern Languages'
  },
  {
    id: '8399',
    label: 'University Institute of Naval Studies',
    value: 'University Institute of Naval Studies'
  },
  {
    id: '8400',
    label: 'University Institute of Oriental Studies',
    value: 'University Institute of Oriental Studies'
  },
  {
    id: '8401',
    label: 'University Institute of Teacher Training "Suor Orsola Benincasa"',
    value: 'University Institute of Teacher Training "Suor Orsola Benincasa"'
  },
  {
    id: '8402',
    label: 'University Konstantina Filozov in Nitra',
    value: 'University Konstantina Filozov in Nitra'
  },
  {
    id: '8403',
    label: 'University Lucian Blaga of Sibiu',
    value: 'University Lucian Blaga of Sibiu'
  },
  {
    id: '8404',
    label: 'University Malaysia Pahang',
    value: 'University Malaysia Pahang'
  },
  {
    id: '8405',
    label: 'University Marien Ngouabi Brazzaville',
    value: 'University Marien Ngouabi Brazzaville'
  },
  {
    id: '8406',
    label: 'University Medical School of Debrecen',
    value: 'University Medical School of Debrecen'
  },
  {
    id: '8407',
    label: 'University Medical School of Pécs',
    value: 'University Medical School of Pécs'
  },
  {
    id: '8408',
    label: 'University North',
    value: 'University North'
  },
  {
    id: '8409',
    label: 'University Of Dubai',
    value: 'University Of Dubai'
  },
  {
    id: '8410',
    label: 'University Of Medical Sciences & Technology (UMST)',
    value: 'University Of Medical Sciences & Technology (UMST)'
  },
  {
    id: '8411',
    label: 'University Oil- Gas Ploiesti',
    value: 'University Oil- Gas Ploiesti'
  },
  {
    id: '8412',
    label: 'University Politehnica of Bucharest',
    value: 'University Politehnica of Bucharest'
  },
  {
    id: '8413',
    label: 'University School of Physical Education',
    value: 'University School of Physical Education'
  },
  {
    id: '8414',
    label: 'University Tun Abdul Razak',
    value: 'University Tun Abdul Razak'
  },
  {
    id: '8415',
    label: 'University Vitez In Travnik',
    value: 'University Vitez In Travnik'
  },
  {
    id: '8416',
    label: 'University for Development Studies',
    value: 'University for Development Studies'
  },
  {
    id: '8417',
    label: 'University for Humanistics (UH)',
    value: 'University for Humanistics (UH)'
  },
  {
    id: '8418',
    label: 'University for Information Science and Technology  "St. Paul The Apostle"',
    value: 'University for Information Science and Technology  "St. Paul The Apostle"'
  },
  {
    id: '8419',
    label: 'University for Peace',
    value: 'University for Peace'
  },
  {
    id: '8420',
    label: 'University of Aberdeen',
    value: 'University of Aberdeen'
  },
  {
    id: '8421',
    label: 'University of Abertay Dundee',
    value: 'University of Abertay Dundee'
  },
  {
    id: '8422',
    label: 'University of Abuja',
    value: 'University of Abuja'
  },
  {
    id: '8423',
    label: 'University of Adelaide',
    value: 'University of Adelaide'
  },
  {
    id: '8424',
    label: 'University of Aden',
    value: 'University of Aden'
  },
  {
    id: '8425',
    label: 'University of Advancing Technology',
    value: 'University of Advancing Technology'
  },
  {
    id: '8426',
    label: 'University of Agricultural Sciences, Bangalore',
    value: 'University of Agricultural Sciences, Bangalore'
  },
  {
    id: '8427',
    label: 'University of Agricultural Sciences, Dharwad',
    value: 'University of Agricultural Sciences, Dharwad'
  },
  {
    id: '8428',
    label: 'University of Agriculture Abeokuta',
    value: 'University of Agriculture Abeokuta'
  },
  {
    id: '8429',
    label: 'University of Agriculture Faisalabad',
    value: 'University of Agriculture Faisalabad'
  },
  {
    id: '8430',
    label: 'University of Agriculture Makurdi',
    value: 'University of Agriculture Makurdi'
  },
  {
    id: '8431',
    label: 'University of Agriculture and Veterinary Medicine Bucharest',
    value: 'University of Agriculture and Veterinary Medicine Bucharest'
  },
  {
    id: '8432',
    label: 'University of Agriculture and Veterinary Medicine Cluj-Napoca',
    value: 'University of Agriculture and Veterinary Medicine Cluj-Napoca'
  },
  {
    id: '8433',
    label: 'University of Agriculture and Veterinary Medicine Iasi',
    value: 'University of Agriculture and Veterinary Medicine Iasi'
  },
  {
    id: '8434',
    label: 'University of Agriculture and Veterinary Medicine Timisoara',
    value: 'University of Agriculture and Veterinary Medicine Timisoara'
  },
  {
    id: '8435',
    label: 'University of Aizu',
    value: 'University of Aizu'
  },
  {
    id: '8436',
    label: 'University of Akron',
    value: 'University of Akron'
  },
  {
    id: '8437',
    label: 'University of Akron Wayne College',
    value: 'University of Akron Wayne College'
  },
  {
    id: '8438',
    label: 'University of Akureyri',
    value: 'University of Akureyri'
  },
  {
    id: '8439',
    label: 'University of Al-Qadisiyah',
    value: 'University of Al-Qadisiyah'
  },
  {
    id: '8440',
    label: 'University of Alabama at Birmingham',
    value: 'University of Alabama at Birmingham'
  },
  {
    id: '8441',
    label: 'University of Alabama at Huntsville',
    value: 'University of Alabama at Huntsville'
  },
  {
    id: '8442',
    label: 'University of Alaska',
    value: 'University of Alaska'
  },
  {
    id: '8443',
    label: 'University of Alaska - Anchorage',
    value: 'University of Alaska - Anchorage'
  },
  {
    id: '8444',
    label: 'University of Alaska - Fairbanks',
    value: 'University of Alaska - Fairbanks'
  },
  {
    id: '8445',
    label: 'University of Alaska - Southeast',
    value: 'University of Alaska - Southeast'
  },
  {
    id: '8446',
    label: 'University of Alberta',
    value: 'University of Alberta'
  },
  {
    id: '8447',
    label: 'University of Aleppo',
    value: 'University of Aleppo'
  },
  {
    id: '8448',
    label: 'University of Alexandra Dubček in Trenčín',
    value: 'University of Alexandra Dubček in Trenčín'
  },
  {
    id: '8449',
    label: 'University of Amsterdam',
    value: 'University of Amsterdam'
  },
  {
    id: '8450',
    label: 'University of Anbar',
    value: 'University of Anbar'
  },
  {
    id: '8451',
    label: 'University of Ancona',
    value: 'University of Ancona'
  },
  {
    id: '8452',
    label: 'University of Andorra',
    value: 'University of Andorra'
  },
  {
    id: '8453',
    label: 'University of Applied Science and Technology',
    value: 'University of Applied Science and Technology'
  },
  {
    id: '8454',
    label: 'University of Applied Sciences',
    value: 'University of Applied Sciences'
  },
  {
    id: '8455',
    label: 'University of Applied Sciences Aargau',
    value: 'University of Applied Sciences Aargau'
  },
  {
    id: '8456',
    label: 'University of Applied Sciences Basel (FHBB )',
    value: 'University of Applied Sciences Basel (FHBB )'
  },
  {
    id: '8457',
    label: 'University of Applied Sciences Chur',
    value: 'University of Applied Sciences Chur'
  },
  {
    id: '8458',
    label: 'University of Applied Sciences Solothurn Northwestern Switzerland',
    value: 'University of Applied Sciences Solothurn Northwestern Switzerland'
  },
  {
    id: '8459',
    label: 'University of Applied Sciences of Moldova',
    value: 'University of Applied Sciences of Moldova'
  },
  {
    id: '8460',
    label: 'University of Aquila',
    value: 'University of Aquila'
  },
  {
    id: '8461',
    label: 'University of Architecture, Civil Engineering and Geodesy',
    value: 'University of Architecture, Civil Engineering and Geodesy'
  },
  {
    id: '8462',
    label: 'University of Arizona',
    value: 'University of Arizona'
  },
  {
    id: '8463',
    label: 'University of Arkansas - Fayetteville',
    value: 'University of Arkansas - Fayetteville'
  },
  {
    id: '8464',
    label: 'University of Arkansas - Fort Smith',
    value: 'University of Arkansas - Fort Smith'
  },
  {
    id: '8465',
    label: 'University of Arkansas - Little Rock',
    value: 'University of Arkansas - Little Rock'
  },
  {
    id: '8466',
    label: 'University of Arkansas - Monticello',
    value: 'University of Arkansas - Monticello'
  },
  {
    id: '8467',
    label: 'University of Arkansas Community College-Batesville',
    value: 'University of Arkansas Community College-Batesville'
  },
  {
    id: '8468',
    label: 'University of Arkansas Community College-Hope',
    value: 'University of Arkansas Community College-Hope'
  },
  {
    id: '8469',
    label: 'University of Arkansas Community College-Morrilton',
    value: 'University of Arkansas Community College-Morrilton'
  },
  {
    id: '8470',
    label: 'University of Arkansas System eVersity',
    value: 'University of Arkansas System eVersity'
  },
  {
    id: '8471',
    label: 'University of Arkansas for Medical Sciences',
    value: 'University of Arkansas for Medical Sciences'
  },
  {
    id: '8472',
    label: 'University of Art and Design Cluj-Napoca',
    value: 'University of Art and Design Cluj-Napoca'
  },
  {
    id: '8473',
    label: 'University of Art and Design Helsinki',
    value: 'University of Art and Design Helsinki'
  },
  {
    id: '8474',
    label: 'University of Asia Pacific, Dhanmondi',
    value: 'University of Asia Pacific, Dhanmondi'
  },
  {
    id: '8475',
    label: 'University of Asia and the Pacific',
    value: 'University of Asia and the Pacific'
  },
  {
    id: '8476',
    label: 'University of Athens',
    value: 'University of Athens'
  },
  {
    id: '8477',
    label: 'University of Auckland',
    value: 'University of Auckland'
  },
  {
    id: '8478',
    label: 'University of Aviation and Technology',
    value: 'University of Aviation and Technology'
  },
  {
    id: '8479',
    label: 'University of Bacau',
    value: 'University of Bacau'
  },
  {
    id: '8480',
    label: 'University of Baghdad',
    value: 'University of Baghdad'
  },
  {
    id: '8481',
    label: 'University of Baguio',
    value: 'University of Baguio'
  },
  {
    id: '8482',
    label: 'University of Bahrain',
    value: 'University of Bahrain'
  },
  {
    id: '8483',
    label: 'University of Balochistan',
    value: 'University of Balochistan'
  },
  {
    id: '8484',
    label: 'University of Baltimore',
    value: 'University of Baltimore'
  },
  {
    id: '8485',
    label: 'University of Banja Luka',
    value: 'University of Banja Luka'
  },
  {
    id: '8486',
    label: 'University of Barcelona',
    value: 'University of Barcelona'
  },
  {
    id: '8487',
    label: 'University of Bari',
    value: 'University of Bari'
  },
  {
    id: '8488',
    label: 'University of Basel',
    value: 'University of Basel'
  },
  {
    id: '8489',
    label: 'University of Basrah',
    value: 'University of Basrah'
  },
  {
    id: '8490',
    label: 'University of Batangas',
    value: 'University of Batangas'
  },
  {
    id: '8491',
    label: 'University of Bath',
    value: 'University of Bath'
  },
  {
    id: '8492',
    label: 'University of Bath',
    value: 'University of Bath'
  },
  {
    id: '8493',
    label: 'University of Bedfordshire',
    value: 'University of Bedfordshire'
  },
  {
    id: '8494',
    label: 'University of Belgrade',
    value: 'University of Belgrade'
  },
  {
    id: '8495',
    label: 'University of Belize',
    value: 'University of Belize'
  },
  {
    id: '8496',
    label: 'University of Benghazi',
    value: 'University of Benghazi'
  },
  {
    id: '8497',
    label: 'University of Benin',
    value: 'University of Benin'
  },
  {
    id: '8498',
    label: 'University of Bergamo',
    value: 'University of Bergamo'
  },
  {
    id: '8499',
    label: 'University of Bergen',
    value: 'University of Bergen'
  },
  {
    id: '8500',
    label: 'University of Bialystok',
    value: 'University of Bialystok'
  },
  {
    id: '8501',
    label: 'University of Bihac',
    value: 'University of Bihac'
  },
  {
    id: '8502',
    label: 'University of Birmingham',
    value: 'University of Birmingham'
  },
  {
    id: '8503',
    label: 'University of Bohol',
    value: 'University of Bohol'
  },
  {
    id: '8504',
    label: 'University of Bologna',
    value: 'University of Bologna'
  },
  {
    id: '8505',
    label: 'University of Bolton',
    value: 'University of Bolton'
  },
  {
    id: '8506',
    label: 'University of Botswana',
    value: 'University of Botswana'
  },
  {
    id: '8507',
    label: 'University of Bradford',
    value: 'University of Bradford'
  },
  {
    id: '8508',
    label: 'University of Brescia',
    value: 'University of Brescia'
  },
  {
    id: '8509',
    label: 'University of Bridgeport',
    value: 'University of Bridgeport'
  },
  {
    id: '8510',
    label: 'University of Brighton',
    value: 'University of Brighton'
  },
  {
    id: '8511',
    label: 'University of Bristol',
    value: 'University of Bristol'
  },
  {
    id: '8512',
    label: 'University of British Columbia',
    value: 'University of British Columbia'
  },
  {
    id: '8513',
    label: 'University of Brunei Darussalam',
    value: 'University of Brunei Darussalam'
  },
  {
    id: '8514',
    label: 'University of Bucharest',
    value: 'University of Bucharest'
  },
  {
    id: '8515',
    label: 'University of Buckingham',
    value: 'University of Buckingham'
  },
  {
    id: '8516',
    label: 'University of Bunkyo',
    value: 'University of Bunkyo'
  },
  {
    id: '8517',
    label: 'University of Burao',
    value: 'University of Burao'
  },
  {
    id: '8518',
    label: 'University of Burdwan',
    value: 'University of Burdwan'
  },
  {
    id: '8519',
    label: 'University of Burgundy',
    value: 'University of Burgundy'
  },
  {
    id: '8520',
    label: 'University of Cagayan Valley',
    value: 'University of Cagayan Valley'
  },
  {
    id: '8521',
    label: 'University of Cagliari',
    value: 'University of Cagliari'
  },
  {
    id: '8522',
    label: 'University of Calabar',
    value: 'University of Calabar'
  },
  {
    id: '8523',
    label: 'University of Calabria',
    value: 'University of Calabria'
  },
  {
    id: '8524',
    label: 'University of Calcutta',
    value: 'University of Calcutta'
  },
  {
    id: '8525',
    label: 'University of Calgary',
    value: 'University of Calgary'
  },
  {
    id: '8526',
    label: 'University of Calicut',
    value: 'University of Calicut'
  },
  {
    id: '8527',
    label: 'University of California, Berkeley',
    value: 'University of California, Berkeley'
  },
  {
    id: '8528',
    label: 'University of California, Davis',
    value: 'University of California, Davis'
  },
  {
    id: '8529',
    label: 'University of California, Irvine',
    value: 'University of California, Irvine'
  },
  {
    id: '8530',
    label: 'University of California, Los Angeles',
    value: 'University of California, Los Angeles'
  },
  {
    id: '8531',
    label: 'University of California, Merced',
    value: 'University of California, Merced'
  },
  {
    id: '8532',
    label: 'University of California, Office of the President',
    value: 'University of California, Office of the President'
  },
  {
    id: '8533',
    label: 'University of California, Riverside',
    value: 'University of California, Riverside'
  },
  {
    id: '8534',
    label: 'University of California, San Diego',
    value: 'University of California, San Diego'
  },
  {
    id: '8535',
    label: 'University of California, San Francisco',
    value: 'University of California, San Francisco'
  },
  {
    id: '8536',
    label: 'University of California, Santa Barbara',
    value: 'University of California, Santa Barbara'
  },
  {
    id: '8537',
    label: 'University of California, Santa Cruz',
    value: 'University of California, Santa Cruz'
  },
  {
    id: '8538',
    label: 'University of Cambodia',
    value: 'University of Cambodia'
  },
  {
    id: '8539',
    label: 'University of Cambridge',
    value: 'University of Cambridge'
  },
  {
    id: '8540',
    label: 'University of Camerino',
    value: 'University of Camerino'
  },
  {
    id: '8541',
    label: 'University of Canberra',
    value: 'University of Canberra'
  },
  {
    id: '8542',
    label: 'University of Canterbury',
    value: 'University of Canterbury'
  },
  {
    id: '8543',
    label: 'University of Cape Coast',
    value: 'University of Cape Coast'
  },
  {
    id: '8544',
    label: 'University of Cape Town',
    value: 'University of Cape Town'
  },
  {
    id: '8545',
    label: 'University of Cassino',
    value: 'University of Cassino'
  },
  {
    id: '8546',
    label: 'University of Catania',
    value: 'University of Catania'
  },
  {
    id: '8547',
    label: 'University of Cebu',
    value: 'University of Cebu'
  },
  {
    id: '8548',
    label: 'University of Central Arkansas',
    value: 'University of Central Arkansas'
  },
  {
    id: '8549',
    label: 'University of Central Europe in Skalica',
    value: 'University of Central Europe in Skalica'
  },
  {
    id: '8550',
    label: 'University of Central Florida',
    value: 'University of Central Florida'
  },
  {
    id: '8551',
    label: 'University of Central Lancashire',
    value: 'University of Central Lancashire'
  },
  {
    id: '8552',
    label: 'University of Central Missouri',
    value: 'University of Central Missouri'
  },
  {
    id: '8553',
    label: 'University of Central Oklahoma',
    value: 'University of Central Oklahoma'
  },
  {
    id: '8554',
    label: 'University of Central Punjab',
    value: 'University of Central Punjab'
  },
  {
    id: '8555',
    label: 'University of Charleston',
    value: 'University of Charleston'
  },
  {
    id: '8556',
    label: 'University of Chemical Technology and Metallurgy',
    value: 'University of Chemical Technology and Metallurgy'
  },
  {
    id: '8557',
    label: 'University of Chester',
    value: 'University of Chester'
  },
  {
    id: '8558',
    label: 'University of Chicago',
    value: 'University of Chicago'
  },
  {
    id: '8559',
    label: 'University of Chieti',
    value: 'University of Chieti'
  },
  {
    id: '8560',
    label: 'University of Chittagong',
    value: 'University of Chittagong'
  },
  {
    id: '8561',
    label: 'University of Cincinnati',
    value: 'University of Cincinnati'
  },
  {
    id: '8562',
    label: 'University of Colombo',
    value: 'University of Colombo'
  },
  {
    id: '8563',
    label: 'University of Colorado Anschutz Medical Campus',
    value: 'University of Colorado Anschutz Medical Campus'
  },
  {
    id: '8564',
    label: 'University of Colorado Health Sciences Center',
    value: 'University of Colorado Health Sciences Center'
  },
  {
    id: '8565',
    label: 'University of Colorado at Boulder',
    value: 'University of Colorado at Boulder'
  },
  {
    id: '8566',
    label: 'University of Colorado at Colorado Springs',
    value: 'University of Colorado at Colorado Springs'
  },
  {
    id: '8567',
    label: 'University of Colorado at Denver',
    value: 'University of Colorado at Denver'
  },
  {
    id: '8568',
    label: 'University of Computer Studies, Yangon',
    value: 'University of Computer Studies, Yangon'
  },
  {
    id: '8569',
    label: 'University of Connecticut',
    value: 'University of Connecticut'
  },
  {
    id: '8570',
    label: 'University of Constanta',
    value: 'University of Constanta'
  },
  {
    id: '8571',
    label: 'University of Constanta Medical School',
    value: 'University of Constanta Medical School'
  },
  {
    id: '8572',
    label: 'University of Craiova',
    value: 'University of Craiova'
  },
  {
    id: '8573',
    label: 'University of Crete',
    value: 'University of Crete'
  },
  {
    id: '8574',
    label: 'University of Cyprus',
    value: 'University of Cyprus'
  },
  {
    id: '8575',
    label: 'University of Da Lat',
    value: 'University of Da Lat'
  },
  {
    id: '8576',
    label: 'University of Da Nang',
    value: 'University of Da Nang'
  },
  {
    id: '8577',
    label: 'University of Dallas',
    value: 'University of Dallas'
  },
  {
    id: '8578',
    label: 'University of Dammam',
    value: 'University of Dammam'
  },
  {
    id: '8579',
    label: 'University of Dar Es Salaam',
    value: 'University of Dar Es Salaam'
  },
  {
    id: '8580',
    label: 'University of Dayton',
    value: 'University of Dayton'
  },
  {
    id: '8581',
    label: 'University of Debrecen',
    value: 'University of Debrecen'
  },
  {
    id: '8582',
    label: 'University of Delaware',
    value: 'University of Delaware'
  },
  {
    id: '8583',
    label: 'University of Delhi',
    value: 'University of Delhi'
  },
  {
    id: '8584',
    label: 'University of Denver',
    value: 'University of Denver'
  },
  {
    id: '8585',
    label: 'University of Derby',
    value: 'University of Derby'
  },
  {
    id: '8586',
    label: 'University of Detroit Mercy',
    value: 'University of Detroit Mercy'
  },
  {
    id: '8587',
    label: 'University of Development Alternative',
    value: 'University of Development Alternative'
  },
  {
    id: '8588',
    label: 'University of Dhaka',
    value: 'University of Dhaka'
  },
  {
    id: '8589',
    label: 'University of Dodoma',
    value: 'University of Dodoma'
  },
  {
    id: '8590',
    label: 'University of Dohuk (Kurdistan Region)',
    value: 'University of Dohuk (Kurdistan Region)'
  },
  {
    id: '8591',
    label: 'University of Dongola',
    value: 'University of Dongola'
  },
  {
    id: '8592',
    label: 'University of Dublin, Trinity College',
    value: 'University of Dublin, Trinity College'
  },
  {
    id: '8593',
    label: 'University of Dubrovnik',
    value: 'University of Dubrovnik'
  },
  {
    id: '8594',
    label: 'University of Dubuque',
    value: 'University of Dubuque'
  },
  {
    id: '8595',
    label: 'University of Dundee',
    value: 'University of Dundee'
  },
  {
    id: '8596',
    label: 'University of Durham',
    value: 'University of Durham'
  },
  {
    id: '8597',
    label: 'University of East',
    value: 'University of East'
  },
  {
    id: '8598',
    label: 'University of East Anglia',
    value: 'University of East Anglia'
  },
  {
    id: '8599',
    label: 'University of East Asia',
    value: 'University of East Asia'
  },
  {
    id: '8600',
    label: 'University of East London',
    value: 'University of East London'
  },
  {
    id: '8601',
    label: 'University of East Srarajevo',
    value: 'University of East Srarajevo'
  },
  {
    id: '8602',
    label: 'University of Eastern Africa, Baraton',
    value: 'University of Eastern Africa, Baraton'
  },
  {
    id: '8603',
    label: 'University of Eastern Philippines',
    value: 'University of Eastern Philippines'
  },
  {
    id: '8604',
    label: 'University of Economics Prague',
    value: 'University of Economics Prague'
  },
  {
    id: '8605',
    label: 'University of Economics Varna',
    value: 'University of Economics Varna'
  },
  {
    id: '8606',
    label: 'University of Economics and Innovation Lublin',
    value: 'University of Economics and Innovation Lublin'
  },
  {
    id: '8607',
    label: 'University of Economics in Bratislava',
    value: 'University of Economics in Bratislava'
  },
  {
    id: '8608',
    label: 'University of Edinburgh',
    value: 'University of Edinburgh'
  },
  {
    id: '8609',
    label: 'University of Education',
    value: 'University of Education'
  },
  {
    id: '8610',
    label: 'University of Education Hradec Kralove',
    value: 'University of Education Hradec Kralove'
  },
  {
    id: '8611',
    label: 'University of Education, Winneba',
    value: 'University of Education, Winneba'
  },
  {
    id: '8612',
    label: 'University of El Imam El Mahdi University',
    value: 'University of El Imam El Mahdi University'
  },
  {
    id: '8613',
    label: 'University of Elbasan "Aleksander Xhuvani"',
    value: 'University of Elbasan "Aleksander Xhuvani"'
  },
  {
    id: '8614',
    label: 'University of Eldoret',
    value: 'University of Eldoret'
  },
  {
    id: '8615',
    label: 'University of Electro-Communications',
    value: 'University of Electro-Communications'
  },
  {
    id: '8616',
    label: 'University of Electronic Science and Technology of China',
    value: 'University of Electronic Science and Technology of China'
  },
  {
    id: '8617',
    label: 'University of Engineering and Technology Lahore',
    value: 'University of Engineering and Technology Lahore'
  },
  {
    id: '8618',
    label: 'University of Engineering and Technology Peshawar',
    value: 'University of Engineering and Technology Peshawar'
  },
  {
    id: '8619',
    label: 'University of Engineering and Technology Taxila',
    value: 'University of Engineering and Technology Taxila'
  },
  {
    id: '8620',
    label: 'University of Essex',
    value: 'University of Essex'
  },
  {
    id: '8621',
    label: 'University of Evansville',
    value: 'University of Evansville'
  },
  {
    id: '8622',
    label: 'University of Exeter',
    value: 'University of Exeter'
  },
  {
    id: '8623',
    label: 'University of Faisalabad',
    value: 'University of Faisalabad'
  },
  {
    id: '8624',
    label: 'University of Ferrara',
    value: 'University of Ferrara'
  },
  {
    id: '8625',
    label: 'University of Fiji',
    value: 'University of Fiji'
  },
  {
    id: '8626',
    label: 'University of Finance and Management in Bialystok',
    value: 'University of Finance and Management in Bialystok'
  },
  {
    id: '8627',
    label: 'University of Findlay',
    value: 'University of Findlay'
  },
  {
    id: '8628',
    label: 'University of Fine Arts Belgrade',
    value: 'University of Fine Arts Belgrade'
  },
  {
    id: '8629',
    label: 'University of Florence',
    value: 'University of Florence'
  },
  {
    id: '8630',
    label: 'University of Florida',
    value: 'University of Florida'
  },
  {
    id: '8631',
    label: 'University of Foggia',
    value: 'University of Foggia'
  },
  {
    id: '8632',
    label: 'University of Food Technology',
    value: 'University of Food Technology'
  },
  {
    id: '8633',
    label: 'University of Forestry Sofia',
    value: 'University of Forestry Sofia'
  },
  {
    id: '8634',
    label: 'University of Fort Hare',
    value: 'University of Fort Hare'
  },
  {
    id: '8635',
    label: 'University of Fribourg',
    value: 'University of Fribourg'
  },
  {
    id: '8636',
    label: 'University of Gadarif',
    value: 'University of Gadarif'
  },
  {
    id: '8637',
    label: 'University of Galatzi',
    value: 'University of Galatzi'
  },
  {
    id: '8638',
    label: 'University of Gastronomic Sciences',
    value: 'University of Gastronomic Sciences'
  },
  {
    id: '8639',
    label: 'University of Gdansk',
    value: 'University of Gdansk'
  },
  {
    id: '8640',
    label: 'University of Geneva',
    value: 'University of Geneva'
  },
  {
    id: '8641',
    label: 'University of Genoa',
    value: 'University of Genoa'
  },
  {
    id: '8642',
    label: 'University of Georgia',
    value: 'University of Georgia'
  },
  {
    id: '8643',
    label: 'University of Gezira',
    value: 'University of Gezira'
  },
  {
    id: '8644',
    label: 'University of Ghana',
    value: 'University of Ghana'
  },
  {
    id: '8645',
    label: 'University of Girona',
    value: 'University of Girona'
  },
  {
    id: '8646',
    label: 'University of Gjirokstra "Eqerem Cabej"',
    value: 'University of Gjirokstra "Eqerem Cabej"'
  },
  {
    id: '8647',
    label: 'University of Glamorgan',
    value: 'University of Glamorgan'
  },
  {
    id: '8648',
    label: 'University of Glasgow',
    value: 'University of Glasgow'
  },
  {
    id: '8649',
    label: 'University of Gloucestershire',
    value: 'University of Gloucestershire'
  },
  {
    id: '8650',
    label: 'University of Goroka',
    value: 'University of Goroka'
  },
  {
    id: '8651',
    label: 'University of Great Falls',
    value: 'University of Great Falls'
  },
  {
    id: '8652',
    label: 'University of Greenland',
    value: 'University of Greenland'
  },
  {
    id: '8653',
    label: 'University of Greenwich',
    value: 'University of Greenwich'
  },
  {
    id: '8654',
    label: 'University of Grigol Robakidze',
    value: 'University of Grigol Robakidze'
  },
  {
    id: '8655',
    label: 'University of Groningen',
    value: 'University of Groningen'
  },
  {
    id: '8656',
    label: 'University of Guam',
    value: 'University of Guam'
  },
  {
    id: '8657',
    label: 'University of Guelph',
    value: 'University of Guelph'
  },
  {
    id: '8658',
    label: 'University of Guilan',
    value: 'University of Guilan'
  },
  {
    id: '8659',
    label: 'University of Gujrat',
    value: 'University of Gujrat'
  },
  {
    id: '8660',
    label: 'University of Guyana',
    value: 'University of Guyana'
  },
  {
    id: '8661',
    label: 'University of Haifa',
    value: 'University of Haifa'
  },
  {
    id: '8662',
    label: 'University of Hail',
    value: 'University of Hail'
  },
  {
    id: '8663',
    label: 'University of Halifax, Birmingham Campus',
    value: 'University of Halifax, Birmingham Campus'
  },
  {
    id: '8664',
    label: 'University of Hargeisa',
    value: 'University of Hargeisa'
  },
  {
    id: '8665',
    label: 'University of Hartford',
    value: 'University of Hartford'
  },
  {
    id: '8666',
    label: 'University of Hawaii System',
    value: 'University of Hawaii System'
  },
  {
    id: '8667',
    label: 'University of Hawaii at Hilo',
    value: 'University of Hawaii at Hilo'
  },
  {
    id: '8668',
    label: 'University of Hawaii at Manoa',
    value: 'University of Hawaii at Manoa'
  },
  {
    id: '8669',
    label: 'University of Health Sciences Andhra Pradesh',
    value: 'University of Health Sciences Andhra Pradesh'
  },
  {
    id: '8670',
    label: 'University of Health Sciences Antigua',
    value: 'University of Health Sciences Antigua'
  },
  {
    id: '8671',
    label: 'University of Health and Science',
    value: 'University of Health and Science'
  },
  {
    id: '8672',
    label: 'University of Helsinki',
    value: 'University of Helsinki'
  },
  {
    id: '8673',
    label: 'University of Hertfordshire',
    value: 'University of Hertfordshire'
  },
  {
    id: '8674',
    label: 'University of Holy Quran and Islamic Siences',
    value: 'University of Holy Quran and Islamic Siences'
  },
  {
    id: '8675',
    label: 'University of Hormozgan',
    value: 'University of Hormozgan'
  },
  {
    id: '8676',
    label: 'University of Horticulture and Food Industry',
    value: 'University of Horticulture and Food Industry'
  },
  {
    id: '8677',
    label: 'University of Houston',
    value: 'University of Houston'
  },
  {
    id: '8678',
    label: 'University of Houston-Downtown',
    value: 'University of Houston-Downtown'
  },
  {
    id: '8679',
    label: 'University of Huddersfield',
    value: 'University of Huddersfield'
  },
  {
    id: '8680',
    label: 'University of Hull',
    value: 'University of Hull'
  },
  {
    id: '8681',
    label: 'University of Human Development (Kurdistan Region)',
    value: 'University of Human Development (Kurdistan Region)'
  },
  {
    id: '8682',
    label: 'University of Hyderabad',
    value: 'University of Hyderabad'
  },
  {
    id: '8683',
    label: 'University of Hydraulic Electric Engineering',
    value: 'University of Hydraulic Electric Engineering'
  },
  {
    id: '8684',
    label: 'University of Iasi',
    value: 'University of Iasi'
  },
  {
    id: '8685',
    label: 'University of Ibadan',
    value: 'University of Ibadan'
  },
  {
    id: '8686',
    label: 'University of Iceland',
    value: 'University of Iceland'
  },
  {
    id: '8687',
    label: 'University of Idaho',
    value: 'University of Idaho'
  },
  {
    id: '8688',
    label: 'University of Illinois at Chicago',
    value: 'University of Illinois at Chicago'
  },
  {
    id: '8689',
    label: 'University of Illinois at Springfield',
    value: 'University of Illinois at Springfield'
  },
  {
    id: '8690',
    label: 'University of Illinois, Urbana-Champaign',
    value: 'University of Illinois, Urbana-Champaign'
  },
  {
    id: '8691',
    label: 'University of Ilorin',
    value: 'University of Ilorin'
  },
  {
    id: '8692',
    label: 'University of Indianapolis',
    value: 'University of Indianapolis'
  },
  {
    id: '8693',
    label: 'University of Information Technology & Sciences',
    value: 'University of Information Technology & Sciences'
  },
  {
    id: '8694',
    label: 'University of Information Technology and Management in Rzeszow',
    value: 'University of Information Technology and Management in Rzeszow'
  },
  {
    id: '8695',
    label: 'University of Insubria',
    value: 'University of Insubria'
  },
  {
    id: '8696',
    label: 'University of Insurance and Banking in Warsaw',
    value: 'University of Insurance and Banking in Warsaw'
  },
  {
    id: '8697',
    label: 'University of International Business',
    value: 'University of International Business'
  },
  {
    id: '8698',
    label: 'University of International Business and Economics',
    value: 'University of International Business and Economics'
  },
  {
    id: '8699',
    label: 'University of Ioannina',
    value: 'University of Ioannina'
  },
  {
    id: '8700',
    label: 'University of Iowa',
    value: 'University of Iowa'
  },
  {
    id: '8701',
    label: 'University of Italian Studies for Foreigners of Perugia',
    value: 'University of Italian Studies for Foreigners of Perugia'
  },
  {
    id: '8702',
    label: 'University of Italian Studies for Foreigners of Siena',
    value: 'University of Italian Studies for Foreigners of Siena'
  },
  {
    id: '8703',
    label: 'University of Jaffna',
    value: 'University of Jaffna'
  },
  {
    id: '8704',
    label: 'University of Jamestown',
    value: 'University of Jamestown'
  },
  {
    id: '8705',
    label: 'University of Jammu',
    value: 'University of Jammu'
  },
  {
    id: '8706',
    label: 'University of Jan Evangelista Purkyne',
    value: 'University of Jan Evangelista Purkyne'
  },
  {
    id: '8707',
    label: 'University of Jazeera',
    value: 'University of Jazeera'
  },
  {
    id: '8708',
    label: 'University of Jinan',
    value: 'University of Jinan'
  },
  {
    id: '8709',
    label: 'University of Joensuu',
    value: 'University of Joensuu'
  },
  {
    id: '8710',
    label: 'University of Johannesburg',
    value: 'University of Johannesburg'
  },
  {
    id: '8711',
    label: 'University of Jordan',
    value: 'University of Jordan'
  },
  {
    id: '8712',
    label: 'University of Jos',
    value: 'University of Jos'
  },
  {
    id: '8713',
    label: 'University of Juba',
    value: 'University of Juba'
  },
  {
    id: '8714',
    label: 'University of Jyväskylä',
    value: 'University of Jyväskylä'
  },
  {
    id: '8715',
    label: 'University of Kabianga',
    value: 'University of Kabianga'
  },
  {
    id: '8716',
    label: 'University of Kalamoon',
    value: 'University of Kalamoon'
  },
  {
    id: '8717',
    label: 'University of Kalyani',
    value: 'University of Kalyani'
  },
  {
    id: '8718',
    label: 'University of Kansas',
    value: 'University of Kansas'
  },
  {
    id: '8719',
    label: 'University of Kansas School of Medicine',
    value: 'University of Kansas School of Medicine'
  },
  {
    id: '8720',
    label: 'University of Karachi',
    value: 'University of Karachi'
  },
  {
    id: '8721',
    label: 'University of Kashmir',
    value: 'University of Kashmir'
  },
  {
    id: '8722',
    label: 'University of Kelaniya',
    value: 'University of Kelaniya'
  },
  {
    id: '8723',
    label: 'University of Kent at Canterbury',
    value: 'University of Kent at Canterbury'
  },
  {
    id: '8724',
    label: 'University of Kentucky',
    value: 'University of Kentucky'
  },
  {
    id: '8725',
    label: 'University of Kerala',
    value: 'University of Kerala'
  },
  {
    id: '8726',
    label: 'University of Khartoum',
    value: 'University of Khartoum'
  },
  {
    id: '8727',
    label: "University of King's College",
    value: "University of King's College"
  },
  {
    id: '8728',
    label: 'University of Kirkuk',
    value: 'University of Kirkuk'
  },
  {
    id: '8729',
    label: 'University of Korca "Fan Noli"',
    value: 'University of Korca "Fan Noli"'
  },
  {
    id: '8730',
    label: 'University of Kordofan',
    value: 'University of Kordofan'
  },
  {
    id: '8731',
    label: 'University of Kota',
    value: 'University of Kota'
  },
  {
    id: '8732',
    label: 'University of Kragujevac',
    value: 'University of Kragujevac'
  },
  {
    id: '8733',
    label: 'University of Kufa',
    value: 'University of Kufa'
  },
  {
    id: '8734',
    label: 'University of Kuopio',
    value: 'University of Kuopio'
  },
  {
    id: '8735',
    label: 'University of Kurdistan',
    value: 'University of Kurdistan'
  },
  {
    id: '8736',
    label: 'University of Kurdistan - Hawler',
    value: 'University of Kurdistan - Hawler'
  },
  {
    id: '8737',
    label: 'University of KwaZulu-Natal',
    value: 'University of KwaZulu-Natal'
  },
  {
    id: '8738',
    label: 'University of La Verne',
    value: 'University of La Verne'
  },
  {
    id: '8739',
    label: 'University of Lagos',
    value: 'University of Lagos'
  },
  {
    id: '8740',
    label: 'University of Lahore',
    value: 'University of Lahore'
  },
  {
    id: '8741',
    label: 'University of Lancaster',
    value: 'University of Lancaster'
  },
  {
    id: '8742',
    label: 'University of Lapland',
    value: 'University of Lapland'
  },
  {
    id: '8743',
    label: 'University of Latvia',
    value: 'University of Latvia'
  },
  {
    id: '8744',
    label: 'University of Lecce',
    value: 'University of Lecce'
  },
  {
    id: '8745',
    label: 'University of Leeds',
    value: 'University of Leeds'
  },
  {
    id: '8746',
    label: 'University of Leicester',
    value: 'University of Leicester'
  },
  {
    id: '8747',
    label: 'University of Lethbridge',
    value: 'University of Lethbridge'
  },
  {
    id: '8748',
    label: 'University of Liberal Arts',
    value: 'University of Liberal Arts'
  },
  {
    id: '8749',
    label: 'University of Liberia',
    value: 'University of Liberia'
  },
  {
    id: '8750',
    label: 'University of Limerick',
    value: 'University of Limerick'
  },
  {
    id: '8751',
    label: 'University of Limpopo',
    value: 'University of Limpopo'
  },
  {
    id: '8752',
    label: 'University of Lincoln',
    value: 'University of Lincoln'
  },
  {
    id: '8753',
    label: 'University of Liverpool',
    value: 'University of Liverpool'
  },
  {
    id: '8754',
    label: 'University of Livingstonia',
    value: 'University of Livingstonia'
  },
  {
    id: '8755',
    label: 'University of Liège',
    value: 'University of Liège'
  },
  {
    id: '8756',
    label: 'University of Ljubljana',
    value: 'University of Ljubljana'
  },
  {
    id: '8757',
    label: 'University of Lodz',
    value: 'University of Lodz'
  },
  {
    id: '8758',
    label: 'University of London',
    value: 'University of London'
  },
  {
    id: '8759',
    label: 'University of Louisiana at Lafeyette',
    value: 'University of Louisiana at Lafeyette'
  },
  {
    id: '8760',
    label: 'University of Louisiana at Monroe',
    value: 'University of Louisiana at Monroe'
  },
  {
    id: '8761',
    label: 'University of Louisville',
    value: 'University of Louisville'
  },
  {
    id: '8762',
    label:
      'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.',
    value:
      'University of Lower Silesia of the Association for the Advancement of Education in Wroclaw.'
  },
  {
    id: '8763',
    label: 'University of Lucknow',
    value: 'University of Lucknow'
  },
  {
    id: '8764',
    label: 'University of Lusaka',
    value: 'University of Lusaka'
  },
  {
    id: '8765',
    label: 'University of Luxemburg',
    value: 'University of Luxemburg'
  },
  {
    id: '8766',
    label: 'University of Macau',
    value: 'University of Macau'
  },
  {
    id: '8767',
    label: 'University of Macedonia',
    value: 'University of Macedonia'
  },
  {
    id: '8768',
    label: 'University of Macerata',
    value: 'University of Macerata'
  },
  {
    id: '8769',
    label: 'University of Madras',
    value: 'University of Madras'
  },
  {
    id: '8770',
    label: 'University of Maiduguri',
    value: 'University of Maiduguri'
  },
  {
    id: '8771',
    label: 'University of Maine',
    value: 'University of Maine'
  },
  {
    id: '8772',
    label: 'University of Maine System',
    value: 'University of Maine System'
  },
  {
    id: '8773',
    label: 'University of Maine at Farmington',
    value: 'University of Maine at Farmington'
  },
  {
    id: '8774',
    label: 'University of Maine at Fort Kent',
    value: 'University of Maine at Fort Kent'
  },
  {
    id: '8775',
    label: 'University of Maine at Machias',
    value: 'University of Maine at Machias'
  },
  {
    id: '8776',
    label: 'University of Maine at Presque Island',
    value: 'University of Maine at Presque Island'
  },
  {
    id: '8777',
    label: 'University of Malakand',
    value: 'University of Malakand'
  },
  {
    id: '8778',
    label: 'University of Malawi',
    value: 'University of Malawi'
  },
  {
    id: '8779',
    label: 'University of Malawi | Old',
    value: 'University of Malawi | Old'
  },
  {
    id: '8780',
    label: 'University of Malta',
    value: 'University of Malta'
  },
  {
    id: '8781',
    label: 'University of Management and Marketing',
    value: 'University of Management and Marketing'
  },
  {
    id: '8782',
    label: 'University of Management and Technology',
    value: 'University of Management and Technology'
  },
  {
    id: '8783',
    label: 'University of Management and Technology',
    value: 'University of Management and Technology'
  },
  {
    id: '8784',
    label: 'University of Manchester',
    value: 'University of Manchester'
  },
  {
    id: '8785',
    label: 'University of Manila',
    value: 'University of Manila'
  },
  {
    id: '8786',
    label: 'University of Manitoba',
    value: 'University of Manitoba'
  },
  {
    id: '8787',
    label: 'University of Maribor',
    value: 'University of Maribor'
  },
  {
    id: '8788',
    label: 'University of Marketing and Distribution Sciences',
    value: 'University of Marketing and Distribution Sciences'
  },
  {
    id: '8789',
    label: 'University of Mary Washington',
    value: 'University of Mary Washington'
  },
  {
    id: '8790',
    label: 'University of Maryland - University College',
    value: 'University of Maryland - University College'
  },
  {
    id: '8791',
    label: 'University of Maryland Eastern Shore',
    value: 'University of Maryland Eastern Shore'
  },
  {
    id: '8792',
    label: 'University of Maryland, Baltimore',
    value: 'University of Maryland, Baltimore'
  },
  {
    id: '8793',
    label: 'University of Maryland, Baltimore County',
    value: 'University of Maryland, Baltimore County'
  },
  {
    id: '8794',
    label: 'University of Maryland, College Park',
    value: 'University of Maryland, College Park'
  },
  {
    id: '8795',
    label: 'University of Mashhad Payame Noor',
    value: 'University of Mashhad Payame Noor'
  },
  {
    id: '8796',
    label: 'University of Massachusetts Boston',
    value: 'University of Massachusetts Boston'
  },
  {
    id: '8797',
    label: 'University of Massachusetts at Amherst',
    value: 'University of Massachusetts at Amherst'
  },
  {
    id: '8798',
    label: 'University of Massachusetts at Dartmouth',
    value: 'University of Massachusetts at Dartmouth'
  },
  {
    id: '8799',
    label: 'University of Massachusetts at Lowell',
    value: 'University of Massachusetts at Lowell'
  },
  {
    id: '8800',
    label: 'University of Mauritius',
    value: 'University of Mauritius'
  },
  {
    id: '8801',
    label: 'University of Media Arts',
    value: 'University of Media Arts'
  },
  {
    id: '8802',
    label: 'University of Medicine 1, Yangon',
    value: 'University of Medicine 1, Yangon'
  },
  {
    id: '8803',
    label: 'University of Medicine Magway',
    value: 'University of Medicine Magway'
  },
  {
    id: '8804',
    label: 'University of Medicine and Pharmacology of Oradea',
    value: 'University of Medicine and Pharmacology of Oradea'
  },
  {
    id: '8805',
    label: 'University of Medicine and Pharmacy of Bucharest',
    value: 'University of Medicine and Pharmacy of Bucharest'
  },
  {
    id: '8806',
    label: 'University of Medicine and Pharmacy of Cluj-Napoca',
    value: 'University of Medicine and Pharmacy of Cluj-Napoca'
  },
  {
    id: '8807',
    label: 'University of Medicine and Pharmacy of Iasi',
    value: 'University of Medicine and Pharmacy of Iasi'
  },
  {
    id: '8808',
    label: 'University of Medicine and Pharmacy of Targu Mures',
    value: 'University of Medicine and Pharmacy of Targu Mures'
  },
  {
    id: '8809',
    label: 'University of Medicine and Pharmacy of Timisoara',
    value: 'University of Medicine and Pharmacy of Timisoara'
  },
  {
    id: '8810',
    label: 'University of Melbourne',
    value: 'University of Melbourne'
  },
  {
    id: '8811',
    label: 'University of Memphis',
    value: 'University of Memphis'
  },
  {
    id: '8812',
    label: 'University of Messina',
    value: 'University of Messina'
  },
  {
    id: '8813',
    label: 'University of Miami',
    value: 'University of Miami'
  },
  {
    id: '8814',
    label: 'University of Michigan - Ann Arbor',
    value: 'University of Michigan - Ann Arbor'
  },
  {
    id: '8815',
    label: 'University of Michigan - Dearborn',
    value: 'University of Michigan - Dearborn'
  },
  {
    id: '8816',
    label: 'University of Michigan-Flint',
    value: 'University of Michigan-Flint'
  },
  {
    id: '8817',
    label: 'University of Milan',
    value: 'University of Milan'
  },
  {
    id: '8818',
    label: 'University of Milan - Bicocca',
    value: 'University of Milan - Bicocca'
  },
  {
    id: '8819',
    label: 'University of Mindanao',
    value: 'University of Mindanao'
  },
  {
    id: '8820',
    label: 'University of Mining and Geology "St. Ivan Rils"',
    value: 'University of Mining and Geology "St. Ivan Rils"'
  },
  {
    id: '8821',
    label: 'University of Minnesota',
    value: 'University of Minnesota'
  },
  {
    id: '8822',
    label: 'University of Minnesota - Crookston',
    value: 'University of Minnesota - Crookston'
  },
  {
    id: '8823',
    label: 'University of Minnesota - Duluth',
    value: 'University of Minnesota - Duluth'
  },
  {
    id: '8824',
    label: 'University of Minnesota - Morris',
    value: 'University of Minnesota - Morris'
  },
  {
    id: '8825',
    label: 'University of Miskolc',
    value: 'University of Miskolc'
  },
  {
    id: '8826',
    label: 'University of Mississippi',
    value: 'University of Mississippi'
  },
  {
    id: '8827',
    label: 'University of Mississippi Medical Center',
    value: 'University of Mississippi Medical Center'
  },
  {
    id: '8828',
    label: 'University of Missouri',
    value: 'University of Missouri'
  },
  {
    id: '8829',
    label: 'University of Missouri - Kansas City',
    value: 'University of Missouri - Kansas City'
  },
  {
    id: '8830',
    label: 'University of Missouri - Rolla',
    value: 'University of Missouri - Rolla'
  },
  {
    id: '8831',
    label: 'University of Missouri - Saint Louis',
    value: 'University of Missouri - Saint Louis'
  },
  {
    id: '8832',
    label: 'University of Mkar',
    value: 'University of Mkar'
  },
  {
    id: '8833',
    label: 'University of Modena',
    value: 'University of Modena'
  },
  {
    id: '8834',
    label: 'University of Mohaghegh Ardabili',
    value: 'University of Mohaghegh Ardabili'
  },
  {
    id: '8835',
    label: 'University of Molise',
    value: 'University of Molise'
  },
  {
    id: '8836',
    label: 'University of Moncton',
    value: 'University of Moncton'
  },
  {
    id: '8837',
    label: 'University of Moncton, Edmundston',
    value: 'University of Moncton, Edmundston'
  },
  {
    id: '8838',
    label: 'University of Moncton, Shippagan',
    value: 'University of Moncton, Shippagan'
  },
  {
    id: '8839',
    label: 'University of Montana',
    value: 'University of Montana'
  },
  {
    id: '8840',
    label: 'University of Montenegro',
    value: 'University of Montenegro'
  },
  {
    id: '8841',
    label: 'University of Moratuwa',
    value: 'University of Moratuwa'
  },
  {
    id: '8842',
    label: 'University of Mostar',
    value: 'University of Mostar'
  },
  {
    id: '8843',
    label: 'University of Mosul',
    value: 'University of Mosul'
  },
  {
    id: '8844',
    label: 'University of Mount Olive',
    value: 'University of Mount Olive'
  },
  {
    id: '8845',
    label: 'University of Mumbai',
    value: 'University of Mumbai'
  },
  {
    id: '8846',
    label: 'University of Munawwar-ul-Islam',
    value: 'University of Munawwar-ul-Islam'
  },
  {
    id: '8847',
    label: 'University of Music and Fine Arts',
    value: 'University of Music and Fine Arts'
  },
  {
    id: '8848',
    label: 'University of Mustansiriyah',
    value: 'University of Mustansiriyah'
  },
  {
    id: '8849',
    label: 'University of Mysore',
    value: 'University of Mysore'
  },
  {
    id: '8850',
    label: 'University of Nairobi',
    value: 'University of Nairobi'
  },
  {
    id: '8851',
    label: 'University of Namibia',
    value: 'University of Namibia'
  },
  {
    id: '8852',
    label: 'University of Naples Federico II',
    value: 'University of Naples Federico II'
  },
  {
    id: '8853',
    label: 'University of National and World Economy',
    value: 'University of National and World Economy'
  },
  {
    id: '8854',
    label: 'University of Nebraska, Kearney',
    value: 'University of Nebraska, Kearney'
  },
  {
    id: '8855',
    label: 'University of Nebraska, Lincoln',
    value: 'University of Nebraska, Lincoln'
  },
  {
    id: '8856',
    label: 'University of Nebraska, Omaha',
    value: 'University of Nebraska, Omaha'
  },
  {
    id: '8857',
    label: 'University of Negros Occidental-Recoletos',
    value: 'University of Negros Occidental-Recoletos'
  },
  {
    id: '8858',
    label: 'University of Nevada, Las Vegas',
    value: 'University of Nevada, Las Vegas'
  },
  {
    id: '8859',
    label: 'University of Nevada, Reno',
    value: 'University of Nevada, Reno'
  },
  {
    id: '8860',
    label: 'University of New Brunswick',
    value: 'University of New Brunswick'
  },
  {
    id: '8861',
    label: 'University of New Brunswick, Saint John',
    value: 'University of New Brunswick, Saint John'
  },
  {
    id: '8862',
    label: 'University of New Caledonia',
    value: 'University of New Caledonia'
  },
  {
    id: '8863',
    label: 'University of New England',
    value: 'University of New England'
  },
  {
    id: '8864',
    label: 'University of New England',
    value: 'University of New England'
  },
  {
    id: '8865',
    label: 'University of New Hampshire',
    value: 'University of New Hampshire'
  },
  {
    id: '8866',
    label: 'University of New Haven',
    value: 'University of New Haven'
  },
  {
    id: '8867',
    label: 'University of New Mexico',
    value: 'University of New Mexico'
  },
  {
    id: '8868',
    label: 'University of New Orleans',
    value: 'University of New Orleans'
  },
  {
    id: '8869',
    label: 'University of New South Wales',
    value: 'University of New South Wales'
  },
  {
    id: '8870',
    label: 'University of New York Tirana',
    value: 'University of New York Tirana'
  },
  {
    id: '8871',
    label: 'University of New York in Prague',
    value: 'University of New York in Prague'
  },
  {
    id: '8872',
    label: 'University of Newcastle',
    value: 'University of Newcastle'
  },
  {
    id: '8873',
    label: 'University of Nicosia',
    value: 'University of Nicosia'
  },
  {
    id: '8874',
    label: 'University of Nigeria',
    value: 'University of Nigeria'
  },
  {
    id: '8875',
    label: 'University of Nis',
    value: 'University of Nis'
  },
  {
    id: '8876',
    label: 'University of Nizwa',
    value: 'University of Nizwa'
  },
  {
    id: '8877',
    label: 'University of North Alabama',
    value: 'University of North Alabama'
  },
  {
    id: '8878',
    label: 'University of North Bengal',
    value: 'University of North Bengal'
  },
  {
    id: '8879',
    label: 'University of North Carolina at Asheville',
    value: 'University of North Carolina at Asheville'
  },
  {
    id: '8880',
    label: 'University of North Carolina at Chapel Hill',
    value: 'University of North Carolina at Chapel Hill'
  },
  {
    id: '8881',
    label: 'University of North Carolina at Charlotte',
    value: 'University of North Carolina at Charlotte'
  },
  {
    id: '8882',
    label: 'University of North Carolina at Wilmington',
    value: 'University of North Carolina at Wilmington'
  },
  {
    id: '8883',
    label: 'University of North Dakota',
    value: 'University of North Dakota'
  },
  {
    id: '8884',
    label: 'University of North Florida',
    value: 'University of North Florida'
  },
  {
    id: '8885',
    label: 'University of North Georgia',
    value: 'University of North Georgia'
  },
  {
    id: '8886',
    label: 'University of North London',
    value: 'University of North London'
  },
  {
    id: '8887',
    label: 'University of North Texas',
    value: 'University of North Texas'
  },
  {
    id: '8888',
    label: 'University of North Texas Health Science Center',
    value: 'University of North Texas Health Science Center'
  },
  {
    id: '8889',
    label: 'University of NorthWest',
    value: 'University of NorthWest'
  },
  {
    id: '8890',
    label: 'University of Northampton',
    value: 'University of Northampton'
  },
  {
    id: '8891',
    label: 'University of Northeastern Philippines',
    value: 'University of Northeastern Philippines'
  },
  {
    id: '8892',
    label: 'University of Northern Bahr El-Ghazal',
    value: 'University of Northern Bahr El-Ghazal'
  },
  {
    id: '8893',
    label: 'University of Northern British Columbia',
    value: 'University of Northern British Columbia'
  },
  {
    id: '8894',
    label: 'University of Northern Colorado',
    value: 'University of Northern Colorado'
  },
  {
    id: '8895',
    label: 'University of Northern Iowa',
    value: 'University of Northern Iowa'
  },
  {
    id: '8896',
    label: 'University of Northern Philippines',
    value: 'University of Northern Philippines'
  },
  {
    id: '8897',
    label: 'University of Northern Virginia, Prague Campus',
    value: 'University of Northern Virginia, Prague Campus'
  },
  {
    id: '8898',
    label: 'University of Notre Dame',
    value: 'University of Notre Dame'
  },
  {
    id: '8899',
    label: 'University of Notre Dame Australia',
    value: 'University of Notre Dame Australia'
  },
  {
    id: '8900',
    label: 'University of Nottingham',
    value: 'University of Nottingham'
  },
  {
    id: '8901',
    label: 'University of Nottingham, Malaysia Campus',
    value: 'University of Nottingham, Malaysia Campus'
  },
  {
    id: '8902',
    label: 'University of Nova Gorica',
    value: 'University of Nova Gorica'
  },
  {
    id: '8903',
    label: 'University of Novi Sad',
    value: 'University of Novi Sad'
  },
  {
    id: '8904',
    label: 'University of Nueva Caceres',
    value: 'University of Nueva Caceres'
  },
  {
    id: '8905',
    label: 'University of Occupational and Environmental Health, Japan',
    value: 'University of Occupational and Environmental Health, Japan'
  },
  {
    id: '8906',
    label: 'University of Oklahoma',
    value: 'University of Oklahoma'
  },
  {
    id: '8907',
    label: 'University of Ontario Institute of Technology',
    value: 'University of Ontario Institute of Technology'
  },
  {
    id: '8908',
    label: 'University of Opole',
    value: 'University of Opole'
  },
  {
    id: '8909',
    label: 'University of Oradea',
    value: 'University of Oradea'
  },
  {
    id: '8910',
    label: 'University of Oregon',
    value: 'University of Oregon'
  },
  {
    id: '8911',
    label: 'University of Osijek',
    value: 'University of Osijek'
  },
  {
    id: '8912',
    label: 'University of Oslo',
    value: 'University of Oslo'
  },
  {
    id: '8913',
    label: 'University of Ostrava',
    value: 'University of Ostrava'
  },
  {
    id: '8914',
    label: 'University of Otago',
    value: 'University of Otago'
  },
  {
    id: '8915',
    label: 'University of Ottawa',
    value: 'University of Ottawa'
  },
  {
    id: '8916',
    label: 'University of Oulu',
    value: 'University of Oulu'
  },
  {
    id: '8917',
    label: 'University of Oxford',
    value: 'University of Oxford'
  },
  {
    id: '8918',
    label: 'University of Padua',
    value: 'University of Padua'
  },
  {
    id: '8919',
    label: 'University of Paisley',
    value: 'University of Paisley'
  },
  {
    id: '8920',
    label: 'University of Palermo',
    value: 'University of Palermo'
  },
  {
    id: '8921',
    label: 'University of Palestine',
    value: 'University of Palestine'
  },
  {
    id: '8922',
    label: 'University of Pangasinan',
    value: 'University of Pangasinan'
  },
  {
    id: '8923',
    label: 'University of Pannonia',
    value: 'University of Pannonia'
  },
  {
    id: '8924',
    label: 'University of Papua New Guinea',
    value: 'University of Papua New Guinea'
  },
  {
    id: '8925',
    label: 'University of Pardubice',
    value: 'University of Pardubice'
  },
  {
    id: '8926',
    label: 'University of Parma',
    value: 'University of Parma'
  },
  {
    id: '8927',
    label: 'University of Patras',
    value: 'University of Patras'
  },
  {
    id: '8928',
    label: 'University of Pavia',
    value: 'University of Pavia'
  },
  {
    id: '8929',
    label: 'University of Pecs',
    value: 'University of Pecs'
  },
  {
    id: '8930',
    label: 'University of Peloponnese',
    value: 'University of Peloponnese'
  },
  {
    id: '8931',
    label: 'University of Pennsylvania',
    value: 'University of Pennsylvania'
  },
  {
    id: '8932',
    label: 'University of Peradeniya',
    value: 'University of Peradeniya'
  },
  {
    id: '8933',
    label: 'University of Performing Arts in Bratislava',
    value: 'University of Performing Arts in Bratislava'
  },
  {
    id: '8934',
    label: 'University of Perpetual Help',
    value: 'University of Perpetual Help'
  },
  {
    id: '8935',
    label: 'University of Perpetual Help, System Dalta',
    value: 'University of Perpetual Help, System Dalta'
  },
  {
    id: '8936',
    label: 'University of Perugia',
    value: 'University of Perugia'
  },
  {
    id: '8937',
    label: 'University of Peshawar',
    value: 'University of Peshawar'
  },
  {
    id: '8938',
    label: 'University of Petra',
    value: 'University of Petra'
  },
  {
    id: '8939',
    label: 'University of Petroleum (East China)',
    value: 'University of Petroleum (East China)'
  },
  {
    id: '8940',
    label: 'University of Petroleum and Energy Studies',
    value: 'University of Petroleum and Energy Studies'
  },
  {
    id: '8941',
    label: 'University of Petrosani',
    value: 'University of Petrosani'
  },
  {
    id: '8942',
    label: 'University of Phoenix',
    value: 'University of Phoenix'
  },
  {
    id: '8943',
    label: 'University of Piraeus',
    value: 'University of Piraeus'
  },
  {
    id: '8944',
    label: 'University of Pisa',
    value: 'University of Pisa'
  },
  {
    id: '8945',
    label: 'University of Pitesti',
    value: 'University of Pitesti'
  },
  {
    id: '8946',
    label: 'University of Pittsburgh',
    value: 'University of Pittsburgh'
  },
  {
    id: '8947',
    label: 'University of Pittsburgh Medical Center',
    value: 'University of Pittsburgh Medical Center'
  },
  {
    id: '8948',
    label: 'University of Pittsburgh at Bradford',
    value: 'University of Pittsburgh at Bradford'
  },
  {
    id: '8949',
    label: 'University of Pittsburgh at Johnstown',
    value: 'University of Pittsburgh at Johnstown'
  },
  {
    id: '8950',
    label: 'University of Pittsburgh-Titusville',
    value: 'University of Pittsburgh-Titusville'
  },
  {
    id: '8951',
    label: 'University of Plovdiv',
    value: 'University of Plovdiv'
  },
  {
    id: '8952',
    label: 'University of Plymouth',
    value: 'University of Plymouth'
  },
  {
    id: '8953',
    label: 'University of Portharcourt',
    value: 'University of Portharcourt'
  },
  {
    id: '8954',
    label: 'University of Portland',
    value: 'University of Portland'
  },
  {
    id: '8955',
    label: 'University of Portsmouth',
    value: 'University of Portsmouth'
  },
  {
    id: '8956',
    label: 'University of Portsmouth',
    value: 'University of Portsmouth'
  },
  {
    id: '8957',
    label: 'University of Presov',
    value: 'University of Presov'
  },
  {
    id: '8958',
    label: 'University of Pretoria',
    value: 'University of Pretoria'
  },
  {
    id: '8959',
    label: 'University of Primorska',
    value: 'University of Primorska'
  },
  {
    id: '8960',
    label: 'University of Prince Edward Island',
    value: 'University of Prince Edward Island'
  },
  {
    id: '8961',
    label: 'University of Prishtina',
    value: 'University of Prishtina'
  },
  {
    id: '8962',
    label: 'University of Prizren "Ukshin Hoti"',
    value: 'University of Prizren "Ukshin Hoti"'
  },
  {
    id: '8963',
    label: 'University of Priština',
    value: 'University of Priština'
  },
  {
    id: '8964',
    label: 'University of Public Service',
    value: 'University of Public Service'
  },
  {
    id: '8965',
    label: 'University of Puerto Rico',
    value: 'University of Puerto Rico'
  },
  {
    id: '8966',
    label: 'University of Puget Sound',
    value: 'University of Puget Sound'
  },
  {
    id: '8967',
    label: 'University of Pula',
    value: 'University of Pula'
  },
  {
    id: '8968',
    label: 'University of Pune',
    value: 'University of Pune'
  },
  {
    id: '8969',
    label: 'University of Qatar',
    value: 'University of Qatar'
  },
  {
    id: '8970',
    label: 'University of Queensland',
    value: 'University of Queensland'
  },
  {
    id: '8971',
    label: 'University of Québec',
    value: 'University of Québec'
  },
  {
    id: '8972',
    label: 'University of Rajasthan',
    value: 'University of Rajasthan'
  },
  {
    id: '8973',
    label: 'University of Rajshahi',
    value: 'University of Rajshahi'
  },
  {
    id: '8974',
    label: 'University of Reading',
    value: 'University of Reading'
  },
  {
    id: '8975',
    label: 'University of Redlands',
    value: 'University of Redlands'
  },
  {
    id: '8976',
    label: 'University of Reggio Calabria',
    value: 'University of Reggio Calabria'
  },
  {
    id: '8977',
    label: 'University of Regina',
    value: 'University of Regina'
  },
  {
    id: '8978',
    label: 'University of Regina Carmeli',
    value: 'University of Regina Carmeli'
  },
  {
    id: '8979',
    label: 'University of Resita',
    value: 'University of Resita'
  },
  {
    id: '8980',
    label: 'University of Rhode Island',
    value: 'University of Rhode Island'
  },
  {
    id: '8981',
    label: 'University of Richmond',
    value: 'University of Richmond'
  },
  {
    id: '8982',
    label: 'University of Rijeka',
    value: 'University of Rijeka'
  },
  {
    id: '8983',
    label: 'University of Rochester',
    value: 'University of Rochester'
  },
  {
    id: '8984',
    label: 'University of Roma "La Sapienza"',
    value: 'University of Roma "La Sapienza"'
  },
  {
    id: '8985',
    label: 'University of Roma "Tor Vergata"',
    value: 'University of Roma "Tor Vergata"'
  },
  {
    id: '8986',
    label: 'University of Roorkee',
    value: 'University of Roorkee'
  },
  {
    id: '8987',
    label: 'University of Rousse',
    value: 'University of Rousse'
  },
  {
    id: '8988',
    label: 'University of Ruhuna',
    value: 'University of Ruhuna'
  },
  {
    id: '8989',
    label: 'University of SS. Cyril and Methodius in Trnava',
    value: 'University of SS. Cyril and Methodius in Trnava'
  },
  {
    id: '8990',
    label: 'University of Saint Joseph',
    value: 'University of Saint Joseph'
  },
  {
    id: '8991',
    label: 'University of Saint La Salle',
    value: 'University of Saint La Salle'
  },
  {
    id: '8992',
    label: 'University of Saint Thomas (MN)',
    value: 'University of Saint Thomas (MN)'
  },
  {
    id: '8993',
    label: 'University of Saint Thomas (TX)',
    value: 'University of Saint Thomas (TX)'
  },
  {
    id: '8994',
    label: 'University of Salerno',
    value: 'University of Salerno'
  },
  {
    id: '8995',
    label: 'University of Salford',
    value: 'University of Salford'
  },
  {
    id: '8996',
    label: 'University of San Agustin',
    value: 'University of San Agustin'
  },
  {
    id: '8997',
    label: 'University of San Carlos',
    value: 'University of San Carlos'
  },
  {
    id: '8998',
    label: 'University of San Diego',
    value: 'University of San Diego'
  },
  {
    id: '8999',
    label: 'University of San Francisco',
    value: 'University of San Francisco'
  },
  {
    id: '9000',
    label: 'University of San Jose Recoletos',
    value: 'University of San Jose Recoletos'
  },
  {
    id: '9001',
    label: 'University of San Marino',
    value: 'University of San Marino'
  },
  {
    id: '9002',
    label: 'University of Sannio',
    value: 'University of Sannio'
  },
  {
    id: '9003',
    label: 'University of Santo Tomas',
    value: 'University of Santo Tomas'
  },
  {
    id: '9004',
    label: 'University of Sarajevo',
    value: 'University of Sarajevo'
  },
  {
    id: '9005',
    label: 'University of Sargodha',
    value: 'University of Sargodha'
  },
  {
    id: '9006',
    label: 'University of Saskatchewan',
    value: 'University of Saskatchewan'
  },
  {
    id: '9007',
    label: 'University of Sassari',
    value: 'University of Sassari'
  },
  {
    id: '9008',
    label: 'University of Science & Arts of Oklahoma',
    value: 'University of Science & Arts of Oklahoma'
  },
  {
    id: '9009',
    label: 'University of Science & Technology Bannu',
    value: 'University of Science & Technology Bannu'
  },
  {
    id: '9010',
    label: 'University of Science & Technology Chittagong',
    value: 'University of Science & Technology Chittagong'
  },
  {
    id: '9011',
    label: 'University of Science and Culture',
    value: 'University of Science and Culture'
  },
  {
    id: '9012',
    label: 'University of Science and Technology',
    value: 'University of Science and Technology'
  },
  {
    id: '9013',
    label: 'University of Science and Technology',
    value: 'University of Science and Technology'
  },
  {
    id: '9014',
    label: 'University of Science and Technology Beijing',
    value: 'University of Science and Technology Beijing'
  },
  {
    id: '9015',
    label: "University of Science and Technology Sana'a",
    value: "University of Science and Technology Sana'a"
  },
  {
    id: '9016',
    label: 'University of Science and Technology of China',
    value: 'University of Science and Technology of China'
  },
  {
    id: '9017',
    label: 'University of Science, Arts and Technology',
    value: 'University of Science, Arts and Technology'
  },
  {
    id: '9018',
    label: 'University of Scranton',
    value: 'University of Scranton'
  },
  {
    id: '9019',
    label: 'University of Seychelles',
    value: 'University of Seychelles'
  },
  {
    id: '9020',
    label: 'University of Seychelles - American Institute of Medicine',
    value: 'University of Seychelles - American Institute of Medicine'
  },
  {
    id: '9021',
    label: 'University of Sharjah',
    value: 'University of Sharjah'
  },
  {
    id: '9022',
    label: 'University of Shiga Prefecture',
    value: 'University of Shiga Prefecture'
  },
  {
    id: '9023',
    label: 'University of Shkodra "Luigj Gurakuqi"',
    value: 'University of Shkodra "Luigj Gurakuqi"'
  },
  {
    id: '9024',
    label: 'University of Sibiu',
    value: 'University of Sibiu'
  },
  {
    id: '9025',
    label: 'University of Siena',
    value: 'University of Siena'
  },
  {
    id: '9026',
    label: 'University of Sierra Leone',
    value: 'University of Sierra Leone'
  },
  {
    id: '9027',
    label: 'University of Silesia',
    value: 'University of Silesia'
  },
  {
    id: '9028',
    label: 'University of Silvaner',
    value: 'University of Silvaner'
  },
  {
    id: '9029',
    label: 'University of Sindh',
    value: 'University of Sindh'
  },
  {
    id: '9030',
    label: 'University of Sint Eustatius School of Medicine',
    value: 'University of Sint Eustatius School of Medicine'
  },
  {
    id: '9031',
    label: 'University of Sioux Falls',
    value: 'University of Sioux Falls'
  },
  {
    id: '9032',
    label: 'University of Social Welfare and Rehabilitation Sciences',
    value: 'University of Social Welfare and Rehabilitation Sciences'
  },
  {
    id: '9033',
    label: 'University of Somalia',
    value: 'University of Somalia'
  },
  {
    id: '9034',
    label: 'University of Sopron',
    value: 'University of Sopron'
  },
  {
    id: '9035',
    label: 'University of South Africa',
    value: 'University of South Africa'
  },
  {
    id: '9036',
    label: 'University of South Alabama',
    value: 'University of South Alabama'
  },
  {
    id: '9037',
    label: 'University of South Asia',
    value: 'University of South Asia'
  },
  {
    id: '9038',
    label: 'University of South Australia',
    value: 'University of South Australia'
  },
  {
    id: '9039',
    label: 'University of South Bohemia',
    value: 'University of South Bohemia'
  },
  {
    id: '9040',
    label: 'University of South Carolina',
    value: 'University of South Carolina'
  },
  {
    id: '9041',
    label: 'University of South Carolina - Aiken',
    value: 'University of South Carolina - Aiken'
  },
  {
    id: '9042',
    label: 'University of South Carolina Upstate',
    value: 'University of South Carolina Upstate'
  },
  {
    id: '9043',
    label: 'University of South Dakota',
    value: 'University of South Dakota'
  },
  {
    id: '9044',
    label: 'University of South Florida',
    value: 'University of South Florida'
  },
  {
    id: '9045',
    label: 'University of Southampton',
    value: 'University of Southampton'
  },
  {
    id: '9046',
    label: 'University of Southeastern Philippines',
    value: 'University of Southeastern Philippines'
  },
  {
    id: '9047',
    label: 'University of Southern California',
    value: 'University of Southern California'
  },
  {
    id: '9048',
    label: 'University of Southern Denmark - SDU',
    value: 'University of Southern Denmark - SDU'
  },
  {
    id: '9049',
    label: 'University of Southern Europe (Monaco Business School)',
    value: 'University of Southern Europe (Monaco Business School)'
  },
  {
    id: '9050',
    label: 'University of Southern Indiana',
    value: 'University of Southern Indiana'
  },
  {
    id: '9051',
    label: 'University of Southern Maine',
    value: 'University of Southern Maine'
  },
  {
    id: '9052',
    label: 'University of Southern Mindanao',
    value: 'University of Southern Mindanao'
  },
  {
    id: '9053',
    label: 'University of Southern Mississippi',
    value: 'University of Southern Mississippi'
  },
  {
    id: '9054',
    label: 'University of Southern Philippines Foundation',
    value: 'University of Southern Philippines Foundation'
  },
  {
    id: '9055',
    label: 'University of Southern Queensland',
    value: 'University of Southern Queensland'
  },
  {
    id: '9056',
    label: 'University of Southwestern Louisiana',
    value: 'University of Southwestern Louisiana'
  },
  {
    id: '9057',
    label: 'University of Split',
    value: 'University of Split'
  },
  {
    id: '9058',
    label: 'University of Sri Jayawardenapura',
    value: 'University of Sri Jayawardenapura'
  },
  {
    id: '9059',
    label: 'University of St. Andrews',
    value: 'University of St. Andrews'
  },
  {
    id: '9060',
    label: 'University of St. Augustine for Health Sciences',
    value: 'University of St. Augustine for Health Sciences'
  },
  {
    id: '9061',
    label: "University of St. Jerome's College",
    value: "University of St. Jerome's College"
  },
  {
    id: '9062',
    label: 'University of Stavanger',
    value: 'University of Stavanger'
  },
  {
    id: '9063',
    label: 'University of Stellenbosch',
    value: 'University of Stellenbosch'
  },
  {
    id: '9064',
    label: 'University of Stirling',
    value: 'University of Stirling'
  },
  {
    id: '9065',
    label: 'University of Strathclyde',
    value: 'University of Strathclyde'
  },
  {
    id: '9066',
    label: 'University of Suceava',
    value: 'University of Suceava'
  },
  {
    id: '9067',
    label: 'University of Sudbury',
    value: 'University of Sudbury'
  },
  {
    id: '9068',
    label: 'University of Sufism and Modern Sciences',
    value: 'University of Sufism and Modern Sciences'
  },
  {
    id: '9069',
    label: 'University of Sulaimania (Kurdistan Region)',
    value: 'University of Sulaimania (Kurdistan Region)'
  },
  {
    id: '9070',
    label: 'University of Sunderland',
    value: 'University of Sunderland'
  },
  {
    id: '9071',
    label: 'University of Surrey',
    value: 'University of Surrey'
  },
  {
    id: '9072',
    label: 'University of Sussex',
    value: 'University of Sussex'
  },
  {
    id: '9073',
    label: 'University of Swaziland',
    value: 'University of Swaziland'
  },
  {
    id: '9074',
    label: 'University of Switzerland',
    value: 'University of Switzerland'
  },
  {
    id: '9075',
    label: 'University of Sydney',
    value: 'University of Sydney'
  },
  {
    id: '9076',
    label: 'University of Szczecin',
    value: 'University of Szczecin'
  },
  {
    id: '9077',
    label: 'University of Szeged',
    value: 'University of Szeged'
  },
  {
    id: '9078',
    label: 'University of Tabuk',
    value: 'University of Tabuk'
  },
  {
    id: '9079',
    label: 'University of Tampa',
    value: 'University of Tampa'
  },
  {
    id: '9080',
    label: 'University of Tampere',
    value: 'University of Tampere'
  },
  {
    id: '9081',
    label: 'University of Targu Jiu',
    value: 'University of Targu Jiu'
  },
  {
    id: '9082',
    label: 'University of Tartu',
    value: 'University of Tartu'
  },
  {
    id: '9083',
    label: 'University of Tasmania',
    value: 'University of Tasmania'
  },
  {
    id: '9084',
    label: 'University of Technical Education Ho Chi Minh City',
    value: 'University of Technical Education Ho Chi Minh City'
  },
  {
    id: '9085',
    label: 'University of Technology',
    value: 'University of Technology'
  },
  {
    id: '9086',
    label: 'University of Technology - Iraq',
    value: 'University of Technology - Iraq'
  },
  {
    id: '9087',
    label: 'University of Technology Jamaica',
    value: 'University of Technology Jamaica'
  },
  {
    id: '9088',
    label: 'University of Technology Phnom Penh',
    value: 'University of Technology Phnom Penh'
  },
  {
    id: '9089',
    label: 'University of Technology Sydney',
    value: 'University of Technology Sydney'
  },
  {
    id: '9090',
    label: 'University of Technology and Life Sciences',
    value: 'University of Technology and Life Sciences'
  },
  {
    id: '9091',
    label: 'University of Teesside',
    value: 'University of Teesside'
  },
  {
    id: '9092',
    label: 'University of Tehran',
    value: 'University of Tehran'
  },
  {
    id: '9093',
    label: 'University of Tennessee at Chattanooga',
    value: 'University of Tennessee at Chattanooga'
  },
  {
    id: '9094',
    label: 'University of Tennessee, Knoxville',
    value: 'University of Tennessee, Knoxville'
  },
  {
    id: '9095',
    label: 'University of Tennessee, Martin',
    value: 'University of Tennessee, Martin'
  },
  {
    id: '9096',
    label: 'University of Teramo',
    value: 'University of Teramo'
  },
  {
    id: '9097',
    label: 'University of Texas - Pan American',
    value: 'University of Texas - Pan American'
  },
  {
    id: '9098',
    label: 'University of Texas Medical Branch',
    value: 'University of Texas Medical Branch'
  },
  {
    id: '9099',
    label: 'University of Texas Permian Basin',
    value: 'University of Texas Permian Basin'
  },
  {
    id: '9100',
    label: 'University of Texas Southwestern Medical Center',
    value: 'University of Texas Southwestern Medical Center'
  },
  {
    id: '9101',
    label: 'University of Texas at Arlington',
    value: 'University of Texas at Arlington'
  },
  {
    id: '9102',
    label: 'University of Texas at Brownsville',
    value: 'University of Texas at Brownsville'
  },
  {
    id: '9103',
    label: 'University of Texas at Dallas',
    value: 'University of Texas at Dallas'
  },
  {
    id: '9104',
    label: 'University of Texas at El Paso',
    value: 'University of Texas at El Paso'
  },
  {
    id: '9105',
    label: 'University of Texas at Tyler',
    value: 'University of Texas at Tyler'
  },
  {
    id: '9106',
    label: 'University of Thessaly',
    value: 'University of Thessaly'
  },
  {
    id: '9107',
    label: 'University of Tirana',
    value: 'University of Tirana'
  },
  {
    id: '9108',
    label: 'University of Toledo',
    value: 'University of Toledo'
  },
  {
    id: '9109',
    label: 'University of Toronto',
    value: 'University of Toronto'
  },
  {
    id: '9110',
    label: 'University of Toronto, Mississauga',
    value: 'University of Toronto, Mississauga'
  },
  {
    id: '9111',
    label: 'University of Toronto, Scarborough',
    value: 'University of Toronto, Scarborough'
  },
  {
    id: '9112',
    label: 'University of Trento',
    value: 'University of Trento'
  },
  {
    id: '9113',
    label: 'University of Trieste',
    value: 'University of Trieste'
  },
  {
    id: '9114',
    label: 'University of Trinidad and Tobago',
    value: 'University of Trinidad and Tobago'
  },
  {
    id: '9115',
    label: 'University of Trinity College',
    value: 'University of Trinity College'
  },
  {
    id: '9116',
    label: 'University of Tripoli',
    value: 'University of Tripoli'
  },
  {
    id: '9117',
    label: 'University of Trnava',
    value: 'University of Trnava'
  },
  {
    id: '9118',
    label: 'University of Tromsø',
    value: 'University of Tromsø'
  },
  {
    id: '9119',
    label: 'University of Tulsa',
    value: 'University of Tulsa'
  },
  {
    id: '9120',
    label: 'University of Turin',
    value: 'University of Turin'
  },
  {
    id: '9121',
    label: 'University of Turkish Aeronautical Association',
    value: 'University of Turkish Aeronautical Association'
  },
  {
    id: '9122',
    label: 'University of Turku',
    value: 'University of Turku'
  },
  {
    id: '9123',
    label: 'University of Tuzla',
    value: 'University of Tuzla'
  },
  {
    id: '9124',
    label: 'University of Twente',
    value: 'University of Twente'
  },
  {
    id: '9125',
    label: 'University of Udine',
    value: 'University of Udine'
  },
  {
    id: '9126',
    label: 'University of Urbino',
    value: 'University of Urbino'
  },
  {
    id: '9127',
    label: 'University of Utah',
    value: 'University of Utah'
  },
  {
    id: '9128',
    label: 'University of Uyo',
    value: 'University of Uyo'
  },
  {
    id: '9129',
    label: 'University of Vaasa',
    value: 'University of Vaasa'
  },
  {
    id: '9130',
    label: 'University of Venda',
    value: 'University of Venda'
  },
  {
    id: '9131',
    label: 'University of Venice',
    value: 'University of Venice'
  },
  {
    id: '9132',
    label: 'University of Vermont',
    value: 'University of Vermont'
  },
  {
    id: '9133',
    label: 'University of Verona',
    value: 'University of Verona'
  },
  {
    id: '9134',
    label: 'University of Veterinary & Animal Science',
    value: 'University of Veterinary & Animal Science'
  },
  {
    id: '9135',
    label: 'University of Veterinary Medicine in Kosice',
    value: 'University of Veterinary Medicine in Kosice'
  },
  {
    id: '9136',
    label: 'University of Veterinary Science',
    value: 'University of Veterinary Science'
  },
  {
    id: '9137',
    label: 'University of Veterinary and Pharmaceutical Science',
    value: 'University of Veterinary and Pharmaceutical Science'
  },
  {
    id: '9138',
    label: 'University of Victoria',
    value: 'University of Victoria'
  },
  {
    id: '9139',
    label: 'University of Virginia, Charlottesville',
    value: 'University of Virginia, Charlottesville'
  },
  {
    id: '9140',
    label: 'University of Vlora "Ismail Qemali"',
    value: 'University of Vlora "Ismail Qemali"'
  },
  {
    id: '9141',
    label: 'University of Wah',
    value: 'University of Wah'
  },
  {
    id: '9142',
    label: 'University of Waikato',
    value: 'University of Waikato'
  },
  {
    id: '9143',
    label: 'University of Wales',
    value: 'University of Wales'
  },
  {
    id: '9144',
    label: 'University of Wales College of Medicine',
    value: 'University of Wales College of Medicine'
  },
  {
    id: '9145',
    label: 'University of Wales Institute, Cardiff',
    value: 'University of Wales Institute, Cardiff'
  },
  {
    id: '9146',
    label: 'University of Wales, Bangor',
    value: 'University of Wales, Bangor'
  },
  {
    id: '9147',
    label: 'University of Wales, Lampeter',
    value: 'University of Wales, Lampeter'
  },
  {
    id: '9148',
    label: 'University of Wales, Newport',
    value: 'University of Wales, Newport'
  },
  {
    id: '9149',
    label: 'University of Wales, Swansea',
    value: 'University of Wales, Swansea'
  },
  {
    id: '9150',
    label: 'University of Wamia and Masuria in Olsztyn',
    value: 'University of Wamia and Masuria in Olsztyn'
  },
  {
    id: '9151',
    label: 'University of Warsaw',
    value: 'University of Warsaw'
  },
  {
    id: '9152',
    label: 'University of Warwick',
    value: 'University of Warwick'
  },
  {
    id: '9153',
    label: 'University of Washington',
    value: 'University of Washington'
  },
  {
    id: '9154',
    label: 'University of Wassit',
    value: 'University of Wassit'
  },
  {
    id: '9155',
    label: 'University of Waterloo',
    value: 'University of Waterloo'
  },
  {
    id: '9156',
    label: 'University of West Alabama',
    value: 'University of West Alabama'
  },
  {
    id: '9157',
    label: 'University of West Attica',
    value: 'University of West Attica'
  },
  {
    id: '9158',
    label: 'University of West Bohemia',
    value: 'University of West Bohemia'
  },
  {
    id: '9159',
    label: 'University of West Florida',
    value: 'University of West Florida'
  },
  {
    id: '9160',
    label: 'University of West Hungary',
    value: 'University of West Hungary'
  },
  {
    id: '9161',
    label: 'University of Western Australia',
    value: 'University of Western Australia'
  },
  {
    id: '9162',
    label: 'University of Western Macedonia',
    value: 'University of Western Macedonia'
  },
  {
    id: '9163',
    label: 'University of Western Ontario',
    value: 'University of Western Ontario'
  },
  {
    id: '9164',
    label: 'University of Western Sydney',
    value: 'University of Western Sydney'
  },
  {
    id: '9165',
    label: 'University of Westminster',
    value: 'University of Westminster'
  },
  {
    id: '9166',
    label: 'University of Windsor',
    value: 'University of Windsor'
  },
  {
    id: '9167',
    label: 'University of Winnipeg',
    value: 'University of Winnipeg'
  },
  {
    id: '9168',
    label: 'University of Wisconsin - Eau Claire',
    value: 'University of Wisconsin - Eau Claire'
  },
  {
    id: '9169',
    label: 'University of Wisconsin - Green Bay',
    value: 'University of Wisconsin - Green Bay'
  },
  {
    id: '9170',
    label: 'University of Wisconsin - LaCrosse',
    value: 'University of Wisconsin - LaCrosse'
  },
  {
    id: '9171',
    label: 'University of Wisconsin - Madison',
    value: 'University of Wisconsin - Madison'
  },
  {
    id: '9172',
    label: 'University of Wisconsin - Milwaukee',
    value: 'University of Wisconsin - Milwaukee'
  },
  {
    id: '9173',
    label: 'University of Wisconsin - Oshkosh',
    value: 'University of Wisconsin - Oshkosh'
  },
  {
    id: '9174',
    label: 'University of Wisconsin - Parkside',
    value: 'University of Wisconsin - Parkside'
  },
  {
    id: '9175',
    label: 'University of Wisconsin - Platteville',
    value: 'University of Wisconsin - Platteville'
  },
  {
    id: '9176',
    label: 'University of Wisconsin - River Falls',
    value: 'University of Wisconsin - River Falls'
  },
  {
    id: '9177',
    label: 'University of Wisconsin - Stevens Point',
    value: 'University of Wisconsin - Stevens Point'
  },
  {
    id: '9178',
    label: 'University of Wisconsin - Stout',
    value: 'University of Wisconsin - Stout'
  },
  {
    id: '9179',
    label: 'University of Wisconsin - Superior',
    value: 'University of Wisconsin - Superior'
  },
  {
    id: '9180',
    label: 'University of Wisconsin - Whitewater',
    value: 'University of Wisconsin - Whitewater'
  },
  {
    id: '9181',
    label: 'University of Wisconsin Colleges',
    value: 'University of Wisconsin Colleges'
  },
  {
    id: '9182',
    label: 'University of Wisconsin System',
    value: 'University of Wisconsin System'
  },
  {
    id: '9183',
    label: 'University of Witwatersrand',
    value: 'University of Witwatersrand'
  },
  {
    id: '9184',
    label: 'University of Wollongong',
    value: 'University of Wollongong'
  },
  {
    id: '9185',
    label: 'University of Wollongong - Dubai Campus',
    value: 'University of Wollongong - Dubai Campus'
  },
  {
    id: '9186',
    label: 'University of Wolverhampton',
    value: 'University of Wolverhampton'
  },
  {
    id: '9187',
    label: 'University of Worcester',
    value: 'University of Worcester'
  },
  {
    id: '9188',
    label: 'University of World Economy and Diplomacy',
    value: 'University of World Economy and Diplomacy'
  },
  {
    id: '9189',
    label: 'University of Wroclaw',
    value: 'University of Wroclaw'
  },
  {
    id: '9190',
    label: 'University of Wyoming',
    value: 'University of Wyoming'
  },
  {
    id: '9191',
    label: 'University of York',
    value: 'University of York'
  },
  {
    id: '9192',
    label: 'University of Zadar',
    value: 'University of Zadar'
  },
  {
    id: '9193',
    label: 'University of Zagreb',
    value: 'University of Zagreb'
  },
  {
    id: '9194',
    label: 'University of Zambia',
    value: 'University of Zambia'
  },
  {
    id: '9195',
    label: 'University of Zenica',
    value: 'University of Zenica'
  },
  {
    id: '9196',
    label: 'University of Zilinska',
    value: 'University of Zilinska'
  },
  {
    id: '9197',
    label: 'University of Zimbabwe',
    value: 'University of Zimbabwe'
  },
  {
    id: '9198',
    label: 'University of Zululand',
    value: 'University of Zululand'
  },
  {
    id: '9199',
    label: 'University of lloilo',
    value: 'University of lloilo'
  },
  {
    id: '9200',
    label: 'University of the Air',
    value: 'University of the Air'
  },
  {
    id: '9201',
    label: 'University of the Arts London',
    value: 'University of the Arts London'
  },
  {
    id: '9202',
    label: 'University of the Arts Singapore',
    value: 'University of the Arts Singapore'
  },
  {
    id: '9203',
    label: 'University of the Assumption',
    value: 'University of the Assumption'
  },
  {
    id: '9204',
    label: 'University of the City of Manila',
    value: 'University of the City of Manila'
  },
  {
    id: '9205',
    label: 'University of the Cordilleras',
    value: 'University of the Cordilleras'
  },
  {
    id: '9206',
    label: 'University of the District of Columbia',
    value: 'University of the District of Columbia'
  },
  {
    id: '9207',
    label: 'University of the East',
    value: 'University of the East'
  },
  {
    id: '9208',
    label: 'University of the East, Ramon Magsaysay Memorial Medical Center',
    value: 'University of the East, Ramon Magsaysay Memorial Medical Center'
  },
  {
    id: '9209',
    label: 'University of the Faroe Islands',
    value: 'University of the Faroe Islands'
  },
  {
    id: '9210',
    label: 'University of the Fraser Valley',
    value: 'University of the Fraser Valley'
  },
  {
    id: '9211',
    label: 'University of the Free State',
    value: 'University of the Free State'
  },
  {
    id: '9212',
    label: 'University of the Gambia',
    value: 'University of the Gambia'
  },
  {
    id: '9213',
    label: 'University of the Humanities',
    value: 'University of the Humanities'
  },
  {
    id: '9214',
    label: 'University of the Immaculate Conception',
    value: 'University of the Immaculate Conception'
  },
  {
    id: '9215',
    label: 'University of the Incarnate Word',
    value: 'University of the Incarnate Word'
  },
  {
    id: '9216',
    label: 'University of the Netherlands Antilles, Curacao',
    value: 'University of the Netherlands Antilles, Curacao'
  },
  {
    id: '9217',
    label: 'University of the Ozarks',
    value: 'University of the Ozarks'
  },
  {
    id: '9218',
    label: 'University of the Pacific',
    value: 'University of the Pacific'
  },
  {
    id: '9219',
    label: 'University of the People',
    value: 'University of the People'
  },
  {
    id: '9220',
    label: 'University of the Philippines Baguio',
    value: 'University of the Philippines Baguio'
  },
  {
    id: '9221',
    label: 'University of the Philippines Diliman',
    value: 'University of the Philippines Diliman'
  },
  {
    id: '9222',
    label: 'University of the Philippines Los Banos',
    value: 'University of the Philippines Los Banos'
  },
  {
    id: '9223',
    label: 'University of the Philippines Manila',
    value: 'University of the Philippines Manila'
  },
  {
    id: '9224',
    label: 'University of the Philippines Mindanao',
    value: 'University of the Philippines Mindanao'
  },
  {
    id: '9225',
    label: 'University of the Philippines Open University',
    value: 'University of the Philippines Open University'
  },
  {
    id: '9226',
    label: 'University of the Philippines Visayas',
    value: 'University of the Philippines Visayas'
  },
  {
    id: '9227',
    label: 'University of the Philippines Visayas - Cebu High School',
    value: 'University of the Philippines Visayas - Cebu High School'
  },
  {
    id: '9228',
    label: 'University of the Punjab',
    value: 'University of the Punjab'
  },
  {
    id: '9229',
    label: 'University of the Punjab, Gujranwala Campus',
    value: 'University of the Punjab, Gujranwala Campus'
  },
  {
    id: '9230',
    label: 'University of the Ryukyus',
    value: 'University of the Ryukyus'
  },
  {
    id: '9231',
    label: 'University of the Sacred Heart Tokyo',
    value: 'University of the Sacred Heart Tokyo'
  },
  {
    id: '9232',
    label: 'University of the South Pacific',
    value: 'University of the South Pacific'
  },
  {
    id: '9233',
    label: 'University of the Southern Caribbean',
    value: 'University of the Southern Caribbean'
  },
  {
    id: '9234',
    label: 'University of the Sunshine Coast',
    value: 'University of the Sunshine Coast'
  },
  {
    id: '9235',
    label: 'University of the Thai Chamber of Commerce',
    value: 'University of the Thai Chamber of Commerce'
  },
  {
    id: '9236',
    label: 'University of the Virgin Islands',
    value: 'University of the Virgin Islands'
  },
  {
    id: '9237',
    label: 'University of the Visayas',
    value: 'University of the Visayas'
  },
  {
    id: '9238',
    label: 'University of the Visual & Performing Arts',
    value: 'University of the Visual & Performing Arts'
  },
  {
    id: '9239',
    label: 'University of the West Indies St. Augustine',
    value: 'University of the West Indies St. Augustine'
  },
  {
    id: '9240',
    label: 'University of the West Indies, Cave Hill',
    value: 'University of the West Indies, Cave Hill'
  },
  {
    id: '9241',
    label: 'University of the West Indies, Mona',
    value: 'University of the West Indies, Mona'
  },
  {
    id: '9242',
    label: 'University of the West of England, Bristol',
    value: 'University of the West of England, Bristol'
  },
  {
    id: '9243',
    label: 'University of the Western Cape',
    value: 'University of the Western Cape'
  },
  {
    id: '9244',
    label: 'University “Pavaresia” Vlore',
    value: 'University “Pavaresia” Vlore'
  },
  {
    id: '9245',
    label: 'Università Pontifcia Salesiana',
    value: 'Università Pontifcia Salesiana'
  },
  {
    id: '9246',
    label: 'Università Pontificia Salesiana',
    value: 'Università Pontificia Salesiana'
  },
  {
    id: '9247',
    label: 'Università eCampus',
    value: 'Università eCampus'
  },
  {
    id: '9248',
    label: 'Universität Augsburg',
    value: 'Universität Augsburg'
  },
  {
    id: '9249',
    label: 'Universität Bayreuth',
    value: 'Universität Bayreuth'
  },
  {
    id: '9250',
    label: 'Universität Bern',
    value: 'Universität Bern'
  },
  {
    id: '9251',
    label: 'Universität Bielefeld',
    value: 'Universität Bielefeld'
  },
  {
    id: '9252',
    label: 'Universität Bremen',
    value: 'Universität Bremen'
  },
  {
    id: '9253',
    label: 'Universität Duisburg-Essen',
    value: 'Universität Duisburg-Essen'
  },
  {
    id: '9254',
    label: 'Universität Erfurt',
    value: 'Universität Erfurt'
  },
  {
    id: '9255',
    label: 'Universität Flensburg',
    value: 'Universität Flensburg'
  },
  {
    id: '9256',
    label: 'Universität Graz',
    value: 'Universität Graz'
  },
  {
    id: '9257',
    label: 'Universität Hamburg',
    value: 'Universität Hamburg'
  },
  {
    id: '9258',
    label: 'Universität Hannover',
    value: 'Universität Hannover'
  },
  {
    id: '9259',
    label: 'Universität Hildesheim',
    value: 'Universität Hildesheim'
  },
  {
    id: '9260',
    label: 'Universität Hohenheim',
    value: 'Universität Hohenheim'
  },
  {
    id: '9261',
    label: 'Universität Innsbruck',
    value: 'Universität Innsbruck'
  },
  {
    id: '9262',
    label: 'Universität Kaiserslautern',
    value: 'Universität Kaiserslautern'
  },
  {
    id: '9263',
    label: 'Universität Kassel',
    value: 'Universität Kassel'
  },
  {
    id: '9264',
    label: 'Universität Klagenfurt',
    value: 'Universität Klagenfurt'
  },
  {
    id: '9265',
    label: 'Universität Koblenz-Landau',
    value: 'Universität Koblenz-Landau'
  },
  {
    id: '9266',
    label: 'Universität Konstanz',
    value: 'Universität Konstanz'
  },
  {
    id: '9267',
    label: 'Universität Köln',
    value: 'Universität Köln'
  },
  {
    id: '9268',
    label: 'Universität Leipzig',
    value: 'Universität Leipzig'
  },
  {
    id: '9269',
    label: 'Universität Liechtenstein',
    value: 'Universität Liechtenstein'
  },
  {
    id: '9270',
    label: 'Universität Luzern',
    value: 'Universität Luzern'
  },
  {
    id: '9271',
    label: 'Universität Lüneburg',
    value: 'Universität Lüneburg'
  },
  {
    id: '9272',
    label: 'Universität Mannheim',
    value: 'Universität Mannheim'
  },
  {
    id: '9273',
    label: 'Universität Mozarteum Salzburg',
    value: 'Universität Mozarteum Salzburg'
  },
  {
    id: '9274',
    label: 'Universität Osnabrück',
    value: 'Universität Osnabrück'
  },
  {
    id: '9275',
    label: 'Universität Paderborn',
    value: 'Universität Paderborn'
  },
  {
    id: '9276',
    label: 'Universität Passau',
    value: 'Universität Passau'
  },
  {
    id: '9277',
    label: 'Universität Polsdam',
    value: 'Universität Polsdam'
  },
  {
    id: '9278',
    label: 'Universität Potsdam',
    value: 'Universität Potsdam'
  },
  {
    id: '9279',
    label: 'Universität Regensburg',
    value: 'Universität Regensburg'
  },
  {
    id: '9280',
    label: 'Universität Rostock',
    value: 'Universität Rostock'
  },
  {
    id: '9281',
    label: 'Universität Salzburg',
    value: 'Universität Salzburg'
  },
  {
    id: '9282',
    label: 'Universität Siegen',
    value: 'Universität Siegen'
  },
  {
    id: '9283',
    label: 'Universität St. Gallen (HSG)',
    value: 'Universität St. Gallen (HSG)'
  },
  {
    id: '9284',
    label: 'Universität Stuttgart',
    value: 'Universität Stuttgart'
  },
  {
    id: '9285',
    label: 'Universität Trier',
    value: 'Universität Trier'
  },
  {
    id: '9286',
    label: 'Universität Ulm',
    value: 'Universität Ulm'
  },
  {
    id: '9287',
    label: 'Universität Vienna',
    value: 'Universität Vienna'
  },
  {
    id: '9288',
    label: 'Universität Zürich',
    value: 'Universität Zürich'
  },
  {
    id: '9289',
    label: 'Universität der Bundeswehr Hamburg',
    value: 'Universität der Bundeswehr Hamburg'
  },
  {
    id: '9290',
    label: 'Universität der Bundeswehr München',
    value: 'Universität der Bundeswehr München'
  },
  {
    id: '9291',
    label: 'Universität der Künste Berlin',
    value: 'Universität der Künste Berlin'
  },
  {
    id: '9292',
    label: 'Universität des Saarlandes',
    value: 'Universität des Saarlandes'
  },
  {
    id: '9293',
    label: 'Universität für Bodenkultur Wien',
    value: 'Universität für Bodenkultur Wien'
  },
  {
    id: '9294',
    label: 'Universität für Musik und darstellende Kunst  Wien',
    value: 'Universität für Musik und darstellende Kunst  Wien'
  },
  {
    id: '9295',
    label: 'Universität für Musik und darstellende Kunst Graz',
    value: 'Universität für Musik und darstellende Kunst Graz'
  },
  {
    id: '9296',
    label: 'Universität für angewandte Kunst Wien',
    value: 'Universität für angewandte Kunst Wien'
  },
  {
    id: '9297',
    label: 'Universität für künstlerische und industrielle Gestaltung Linz',
    value: 'Universität für künstlerische und industrielle Gestaltung Linz'
  },
  {
    id: '9298',
    label: 'Université Abdelmalek Essadi',
    value: 'Université Abdelmalek Essadi'
  },
  {
    id: '9299',
    label: 'Université Abdou Moumouni de Niamey',
    value: 'Université Abdou Moumouni de Niamey'
  },
  {
    id: '9300',
    label: 'Université Abou Bekr Belkaid, Tlemcen',
    value: 'Université Abou Bekr Belkaid, Tlemcen'
  },
  {
    id: '9301',
    label: 'Université Alioune Diop de Bambey',
    value: 'Université Alioune Diop de Bambey'
  },
  {
    id: '9302',
    label: 'Université Amar Telidji',
    value: 'Université Amar Telidji'
  },
  {
    id: '9303',
    label: 'Université Badji Mokhtar',
    value: 'Université Badji Mokhtar'
  },
  {
    id: '9304',
    label: 'Université Blaise Pascal (Clermont-Ferrand II)',
    value: 'Université Blaise Pascal (Clermont-Ferrand II)'
  },
  {
    id: '9305',
    label: 'Université Bordeaux I',
    value: 'Université Bordeaux I'
  },
  {
    id: '9306',
    label: 'Université Bourgogne - Franche-Comté',
    value: 'Université Bourgogne - Franche-Comté'
  },
  {
    id: '9307',
    label: 'Université Cadi Ayyad',
    value: 'Université Cadi Ayyad'
  },
  {
    id: '9308',
    label: 'Université Catholique de Bukavu',
    value: 'Université Catholique de Bukavu'
  },
  {
    id: '9309',
    label: 'Université Catholique de Kabgayi',
    value: 'Université Catholique de Kabgayi'
  },
  {
    id: '9310',
    label: 'Université Catholique de Lille',
    value: 'Université Catholique de Lille'
  },
  {
    id: '9311',
    label: 'Université Catholique de Louvain',
    value: 'Université Catholique de Louvain'
  },
  {
    id: '9312',
    label: 'Université Catholique de Lyon',
    value: 'Université Catholique de Lyon'
  },
  {
    id: '9313',
    label: "Université Catholique de l' Ouest",
    value: "Université Catholique de l' Ouest"
  },
  {
    id: '9314',
    label: 'Université Centrale',
    value: 'Université Centrale'
  },
  {
    id: '9315',
    label: 'Université Champollion',
    value: 'Université Champollion'
  },
  {
    id: '9316',
    label: 'Université Charles-de-Gaulle (Lille III)',
    value: 'Université Charles-de-Gaulle (Lille III)'
  },
  {
    id: '9317',
    label: 'Université Cheikh Anta Diop',
    value: 'Université Cheikh Anta Diop'
  },
  {
    id: '9318',
    label: 'Université Chouaib Doukkali',
    value: 'Université Chouaib Doukkali'
  },
  {
    id: '9319',
    label: 'Université Chrétienne Bilingue du Congo',
    value: 'Université Chrétienne Bilingue du Congo'
  },
  {
    id: '9320',
    label: "Université Chrétienne du Nord d'Haiti",
    value: "Université Chrétienne du Nord d'Haiti"
  },
  {
    id: '9321',
    label: 'Université Claude Bernard (Lyon I)',
    value: 'Université Claude Bernard (Lyon I)'
  },
  {
    id: '9322',
    label: 'Université Clermont Auvergne',
    value: 'Université Clermont Auvergne'
  },
  {
    id: '9323',
    label: 'Université Dakar Bourguiba',
    value: 'Université Dakar Bourguiba'
  },
  {
    id: '9324',
    label: 'Université Denis Diderot (Paris VII)',
    value: 'Université Denis Diderot (Paris VII)'
  },
  {
    id: '9325',
    label: 'Université Diderot Paris 7',
    value: 'Université Diderot Paris 7'
  },
  {
    id: '9326',
    label: 'Université Euro-Afrique',
    value: 'Université Euro-Afrique'
  },
  {
    id: '9327',
    label: 'Université Ez-Zitouna',
    value: 'Université Ez-Zitouna'
  },
  {
    id: '9328',
    label: "Université Française d'Égypte",
    value: "Université Française d'Égypte"
  },
  {
    id: '9329',
    label: 'Université François Rabelais de Tours',
    value: 'Université François Rabelais de Tours'
  },
  {
    id: '9330',
    label: 'Université Gamal Abdel Nasser de Conakry',
    value: 'Université Gamal Abdel Nasser de Conakry'
  },
  {
    id: '9331',
    label: 'Université Gaston Berger Saint-Louis',
    value: 'Université Gaston Berger Saint-Louis'
  },
  {
    id: '9332',
    label: 'Université Grenoble Alpes',
    value: 'Université Grenoble Alpes'
  },
  {
    id: '9333',
    label: 'Université Hassan II - Aïn Chock',
    value: 'Université Hassan II - Aïn Chock'
  },
  {
    id: '9334',
    label: 'Université Hassan II - Mohammadia',
    value: 'Université Hassan II - Mohammadia'
  },
  {
    id: '9335',
    label: 'Université Hassan Ier - Settat',
    value: 'Université Hassan Ier - Settat'
  },
  {
    id: '9336',
    label: 'Université Henri Poincaré (Nancy I)',
    value: 'Université Henri Poincaré (Nancy I)'
  },
  {
    id: '9337',
    label: 'Université Ibn Toufail',
    value: 'Université Ibn Toufail'
  },
  {
    id: '9338',
    label: 'Université Ibn Zohr Agadir',
    value: 'Université Ibn Zohr Agadir'
  },
  {
    id: '9339',
    label: 'Université Jean Monnet',
    value: 'Université Jean Monnet'
  },
  {
    id: '9340',
    label: 'Université Jean Moulin (Lyon III)',
    value: 'Université Jean Moulin (Lyon III)'
  },
  {
    id: '9341',
    label: 'Université Julius Nyerere Kankan',
    value: 'Université Julius Nyerere Kankan'
  },
  {
    id: '9342',
    label: 'Université Kasdi Merbah Ouargla',
    value: 'Université Kasdi Merbah Ouargla'
  },
  {
    id: '9343',
    label: 'Université Kofi Annan',
    value: 'Université Kofi Annan'
  },
  {
    id: '9344',
    label: 'Université Kongo',
    value: 'Université Kongo'
  },
  {
    id: '9345',
    label: 'Université Laval',
    value: 'Université Laval'
  },
  {
    id: '9346',
    label: 'Université Libre de Bruxelles',
    value: 'Université Libre de Bruxelles'
  },
  {
    id: '9347',
    label: 'Université Libre de Tunis',
    value: 'Université Libre de Tunis'
  },
  {
    id: '9348',
    label: 'Université Louis Pasteur (Strasbourg I)',
    value: 'Université Louis Pasteur (Strasbourg I)'
  },
  {
    id: '9349',
    label: 'Université Lumière de Bujumbura',
    value: 'Université Lumière de Bujumbura'
  },
  {
    id: '9350',
    label: 'Université Lumiére (Lyon II)',
    value: 'Université Lumiére (Lyon II)'
  },
  {
    id: '9351',
    label: "Université M'hamed Bouguerra de Boumerdes",
    value: "Université M'hamed Bouguerra de Boumerdes"
  },
  {
    id: '9352',
    label: 'Université Mentouri Constantine',
    value: 'Université Mentouri Constantine'
  },
  {
    id: '9353',
    label: 'Université Michel de Montaigne (Bordeaux III )',
    value: 'Université Michel de Montaigne (Bordeaux III )'
  },
  {
    id: '9354',
    label: 'Université Mohammed Ier',
    value: 'Université Mohammed Ier'
  },
  {
    id: '9355',
    label: 'Université Mohammed Premier Oujda',
    value: 'Université Mohammed Premier Oujda'
  },
  {
    id: '9356',
    label: 'Université Mohammed V - Agdal',
    value: 'Université Mohammed V - Agdal'
  },
  {
    id: '9357',
    label: 'Université Mohammed V - Souissi',
    value: 'Université Mohammed V - Souissi'
  },
  {
    id: '9358',
    label: 'Université Montesquieu (Bordeaux IV)',
    value: 'Université Montesquieu (Bordeaux IV)'
  },
  {
    id: '9359',
    label: 'Université Moulay Ismail Meknès',
    value: 'Université Moulay Ismail Meknès'
  },
  {
    id: '9360',
    label: 'Université Mouloud Mammeri de Tizi Ouzou',
    value: 'Université Mouloud Mammeri de Tizi Ouzou'
  },
  {
    id: '9361',
    label: 'Université Nancy II',
    value: 'Université Nancy II'
  },
  {
    id: '9362',
    label: "Université Notre Dame d'Haïti",
    value: "Université Notre Dame d'Haïti"
  },
  {
    id: '9363',
    label: 'Université Omar Bongo',
    value: 'Université Omar Bongo'
  },
  {
    id: '9364',
    label: 'Université Panthéon-Assas (Paris II)',
    value: 'Université Panthéon-Assas (Paris II)'
  },
  {
    id: '9365',
    label: 'Université Panthéon-Sorbonne (Paris I)',
    value: 'Université Panthéon-Sorbonne (Paris I)'
  },
  {
    id: '9366',
    label: 'Université Paris Nanterre (Paris X)',
    value: 'Université Paris Nanterre (Paris X)'
  },
  {
    id: '9367',
    label: 'Université Paris Nord (Paris XIII)',
    value: 'Université Paris Nord (Paris XIII)'
  },
  {
    id: '9368',
    label: 'Université Paris Ouest Paris X Nanterre',
    value: 'Université Paris Ouest Paris X Nanterre'
  },
  {
    id: '9369',
    label: 'Université Paris Sud (Paris XI)',
    value: 'Université Paris Sud (Paris XI)'
  },
  {
    id: '9370',
    label: 'Université Paris est Créteil Val de Marne Paris XII',
    value: 'Université Paris est Créteil Val de Marne Paris XII'
  },
  {
    id: '9371',
    label: 'Université Paris est Créteil Val de Marne Paris XII',
    value: 'Université Paris est Créteil Val de Marne Paris XII'
  },
  {
    id: '9372',
    label: 'Université Paris-Dauphine (Paris IX)',
    value: 'Université Paris-Dauphine (Paris IX)'
  },
  {
    id: '9373',
    label: 'Université Paris-Est Marne-la-Vallée',
    value: 'Université Paris-Est Marne-la-Vallée'
  },
  {
    id: '9374',
    label: 'Université Paris-Saclay',
    value: 'Université Paris-Saclay'
  },
  {
    id: '9375',
    label: 'Université Paris-Sorbonne (Paris IV)',
    value: 'Université Paris-Sorbonne (Paris IV)'
  },
  {
    id: '9376',
    label: 'Université Paul Sabatier (Toulouse III)',
    value: 'Université Paul Sabatier (Toulouse III)'
  },
  {
    id: '9377',
    label: 'Université Paul Valéry (Montpellier III)',
    value: 'Université Paul Valéry (Montpellier III)'
  },
  {
    id: '9378',
    label: 'Université Pierre Mendès France',
    value: 'Université Pierre Mendès France'
  },
  {
    id: '9379',
    label: 'Université Protestante au Congo',
    value: 'Université Protestante au Congo'
  },
  {
    id: '9380',
    label: 'Université Quisqueya',
    value: 'Université Quisqueya'
  },
  {
    id: '9381',
    label: 'Université Rennes I',
    value: 'Université Rennes I'
  },
  {
    id: '9382',
    label: 'Université Rennes II - Haute-Bretagne',
    value: 'Université Rennes II - Haute-Bretagne'
  },
  {
    id: '9383',
    label: 'Université René Descartes (Paris V)',
    value: 'Université René Descartes (Paris V)'
  },
  {
    id: '9384',
    label: 'Université Robert Schuman (Strasbourg III)',
    value: 'Université Robert Schuman (Strasbourg III)'
  },
  {
    id: '9385',
    label: "Université Senghor d'Alexandrie",
    value: "Université Senghor d'Alexandrie"
  },
  {
    id: '9386',
    label: 'Université Shalom de Bunia',
    value: 'Université Shalom de Bunia'
  },
  {
    id: '9387',
    label: 'Université Sidi Mohammed Ben Abdellah',
    value: 'Université Sidi Mohammed Ben Abdellah'
  },
  {
    id: '9388',
    label: 'Université Sorbonne-Nouvelle (Paris III)',
    value: 'Université Sorbonne-Nouvelle (Paris III)'
  },
  {
    id: '9389',
    label: 'Université Thierno Amadou Diallo',
    value: 'Université Thierno Amadou Diallo'
  },
  {
    id: '9390',
    label: 'Université Val-de-Marne (Paris XII)',
    value: 'Université Val-de-Marne (Paris XII)'
  },
  {
    id: '9391',
    label: 'Université Victor Segalen (Bordeaux II)',
    value: 'Université Victor Segalen (Bordeaux II)'
  },
  {
    id: '9392',
    label: 'Université Vincennes Saint-Denis (Paris VIII)',
    value: 'Université Vincennes Saint-Denis (Paris VIII)'
  },
  {
    id: '9393',
    label: 'Université Virtuelle de Tunis',
    value: 'Université Virtuelle de Tunis'
  },
  {
    id: '9394',
    label: "Université d'Abobo-Adjamé",
    value: "Université d'Abobo-Adjamé"
  },
  {
    id: '9395',
    label: "Université d'Abomey-Calavi (UAC)",
    value: "Université d'Abomey-Calavi (UAC)"
  },
  {
    id: '9396',
    label: "Université d'Aix-Marseille",
    value: "Université d'Aix-Marseille"
  },
  {
    id: '9397',
    label: "Université d'Aix-Marseille III",
    value: "Université d'Aix-Marseille III"
  },
  {
    id: '9398',
    label: "Université d'Alger",
    value: "Université d'Alger"
  },
  {
    id: '9399',
    label: "Université d'Alger 3",
    value: "Université d'Alger 3"
  },
  {
    id: '9400',
    label: "Université d'Angers",
    value: "Université d'Angers"
  },
  {
    id: '9401',
    label: "Université d'Antananarivo",
    value: "Université d'Antananarivo"
  },
  {
    id: '9402',
    label: "Université d'Antsiranana",
    value: "Université d'Antsiranana"
  },
  {
    id: '9403',
    label: "Université d'Artois",
    value: "Université d'Artois"
  },
  {
    id: '9404',
    label: "Université d'Auvergne (Clermont-Ferrand I)",
    value: "Université d'Auvergne (Clermont-Ferrand I)"
  },
  {
    id: '9405',
    label: "Université d'Avignon",
    value: "Université d'Avignon"
  },
  {
    id: '9406',
    label: "Université d'Etat d'Haiti",
    value: "Université d'Etat d'Haiti"
  },
  {
    id: '9407',
    label: "Université d'Evry Val d'Essonne",
    value: "Université d'Evry Val d'Essonne"
  },
  {
    id: '9408',
    label: "Université d'Oran Es-Senia",
    value: "Université d'Oran Es-Senia"
  },
  {
    id: '9409',
    label: "Université d'Orléans",
    value: "Université d'Orléans"
  },
  {
    id: '9410',
    label: 'Université de 7 Novembre à Carthage',
    value: 'Université de 7 Novembre à Carthage'
  },
  {
    id: '9411',
    label: 'Université de Bamako',
    value: 'Université de Bamako'
  },
  {
    id: '9412',
    label: 'Université de Bamenda',
    value: 'Université de Bamenda'
  },
  {
    id: '9413',
    label: 'Université de Bandundu Ville',
    value: 'Université de Bandundu Ville'
  },
  {
    id: '9414',
    label: 'Université de Bangui',
    value: 'Université de Bangui'
  },
  {
    id: '9415',
    label: 'Université de Batna',
    value: 'Université de Batna'
  },
  {
    id: '9416',
    label: 'Université de Biskra',
    value: 'Université de Biskra'
  },
  {
    id: '9417',
    label: 'Université de Blida',
    value: 'Université de Blida'
  },
  {
    id: '9418',
    label: 'Université de Bouaké',
    value: 'Université de Bouaké'
  },
  {
    id: '9419',
    label: 'Université de Bretagne Occidentale',
    value: 'Université de Bretagne Occidentale'
  },
  {
    id: '9420',
    label: 'Université de Bretagne Sud',
    value: 'Université de Bretagne Sud'
  },
  {
    id: '9421',
    label: 'Université de Buéa',
    value: 'Université de Buéa'
  },
  {
    id: '9422',
    label: 'Université de Béjaia',
    value: 'Université de Béjaia'
  },
  {
    id: '9423',
    label: 'Université de Caen Basse Normandie',
    value: 'Université de Caen Basse Normandie'
  },
  {
    id: '9424',
    label: 'Université de Cergy-Pontoise',
    value: 'Université de Cergy-Pontoise'
  },
  {
    id: '9425',
    label: 'Université de Chlef',
    value: 'Université de Chlef'
  },
  {
    id: '9426',
    label: 'Université de Cocody',
    value: 'Université de Cocody'
  },
  {
    id: '9427',
    label: 'Université de Corse Pascal Paoli',
    value: 'Université de Corse Pascal Paoli'
  },
  {
    id: '9428',
    label: 'Université de Djibouti',
    value: 'Université de Djibouti'
  },
  {
    id: '9429',
    label: 'Université de Douala',
    value: 'Université de Douala'
  },
  {
    id: '9430',
    label: 'Université de Dschang',
    value: 'Université de Dschang'
  },
  {
    id: '9431',
    label: 'Université de Fianarantsoa',
    value: 'Université de Fianarantsoa'
  },
  {
    id: '9432',
    label: 'Université de Franche Comté',
    value: 'Université de Franche Comté'
  },
  {
    id: '9433',
    label: 'Université de Gabès',
    value: 'Université de Gabès'
  },
  {
    id: '9434',
    label: 'Université de Gafsa',
    value: 'Université de Gafsa'
  },
  {
    id: '9435',
    label: 'Université de Guelma',
    value: 'Université de Guelma'
  },
  {
    id: '9436',
    label: 'Université de Haute-Alsace',
    value: 'Université de Haute-Alsace'
  },
  {
    id: '9437',
    label: 'Université de Jendouba',
    value: 'Université de Jendouba'
  },
  {
    id: '9438',
    label: 'Université de Kairouan',
    value: 'Université de Kairouan'
  },
  {
    id: '9439',
    label: 'Université de Kinshasa',
    value: 'Université de Kinshasa'
  },
  {
    id: '9440',
    label: 'Université de Kisangani',
    value: 'Université de Kisangani'
  },
  {
    id: '9441',
    label: 'Université de La Rochelle',
    value: 'Université de La Rochelle'
  },
  {
    id: '9442',
    label: 'Université de Lausanne',
    value: 'Université de Lausanne'
  },
  {
    id: '9443',
    label: 'Université de Limoges',
    value: 'Université de Limoges'
  },
  {
    id: '9444',
    label: 'Université de Lomé',
    value: 'Université de Lomé'
  },
  {
    id: '9445',
    label: 'Université de Lorraine',
    value: 'Université de Lorraine'
  },
  {
    id: '9446',
    label: 'Université de Lubumbashi',
    value: 'Université de Lubumbashi'
  },
  {
    id: '9447',
    label: "Université de M'sila",
    value: "Université de M'sila"
  },
  {
    id: '9448',
    label: 'Université de Mahajanga',
    value: 'Université de Mahajanga'
  },
  {
    id: '9449',
    label: 'Université de Mbuji Mayi',
    value: 'Université de Mbuji Mayi'
  },
  {
    id: '9450',
    label: 'Université de Metz',
    value: 'Université de Metz'
  },
  {
    id: '9451',
    label: 'Université de Monastir',
    value: 'Université de Monastir'
  },
  {
    id: '9452',
    label: 'Université de Mons-Hainaut',
    value: 'Université de Mons-Hainaut'
  },
  {
    id: '9453',
    label: 'Université de Montpellier I',
    value: 'Université de Montpellier I'
  },
  {
    id: '9454',
    label: 'Université de Montpellier II',
    value: 'Université de Montpellier II'
  },
  {
    id: '9455',
    label: 'Université de Montréal',
    value: 'Université de Montréal'
  },
  {
    id: '9456',
    label: 'Université de Mostaganem',
    value: 'Université de Mostaganem'
  },
  {
    id: '9457',
    label: "Université de N'Djamena",
    value: "Université de N'Djamena"
  },
  {
    id: '9458',
    label: 'Université de Nantes',
    value: 'Université de Nantes'
  },
  {
    id: '9459',
    label: 'Université de Neuchâtel',
    value: 'Université de Neuchâtel'
  },
  {
    id: '9460',
    label: 'Université de Ngaoundéré',
    value: 'Université de Ngaoundéré'
  },
  {
    id: '9461',
    label: 'Université de Nice-Sophia Antipolis',
    value: 'Université de Nice-Sophia Antipolis'
  },
  {
    id: '9462',
    label: 'Université de Nimes',
    value: 'Université de Nimes'
  },
  {
    id: '9463',
    label: 'Université de Nouakchott',
    value: 'Université de Nouakchott'
  },
  {
    id: '9464',
    label: 'Université de Ouagadougou',
    value: 'Université de Ouagadougou'
  },
  {
    id: '9465',
    label: 'Université de Paris IV Sorbonne',
    value: 'Université de Paris IV Sorbonne'
  },
  {
    id: '9466',
    label: "Université de Pau et des Pays de l'Adour",
    value: "Université de Pau et des Pays de l'Adour"
  },
  {
    id: '9467',
    label: 'Université de Perpignan',
    value: 'Université de Perpignan'
  },
  {
    id: '9468',
    label: 'Université de Picardie Jules-Verne',
    value: 'Université de Picardie Jules-Verne'
  },
  {
    id: '9469',
    label: 'Université de Poitiers',
    value: 'Université de Poitiers'
  },
  {
    id: '9470',
    label: 'Université de Provence (Aix-Marseille I)',
    value: 'Université de Provence (Aix-Marseille I)'
  },
  {
    id: '9471',
    label: 'Université de Reims Champagne-Ardenne',
    value: 'Université de Reims Champagne-Ardenne'
  },
  {
    id: '9472',
    label: 'Université de Rennes 2',
    value: 'Université de Rennes 2'
  },
  {
    id: '9473',
    label: 'Université de Rouen - Haute Normandie',
    value: 'Université de Rouen - Haute Normandie'
  },
  {
    id: '9474',
    label: 'Université de Saida',
    value: 'Université de Saida'
  },
  {
    id: '9475',
    label: 'Université de Savoie',
    value: 'Université de Savoie'
  },
  {
    id: '9476',
    label: 'Université de Sfax',
    value: 'Université de Sfax'
  },
  {
    id: '9477',
    label: 'Université de Sherbrooke',
    value: 'Université de Sherbrooke'
  },
  {
    id: '9478',
    label: 'Université de Sidi-Bel-Abbès (Djillali Liabès)',
    value: 'Université de Sidi-Bel-Abbès (Djillali Liabès)'
  },
  {
    id: '9479',
    label: 'Université de Skikda',
    value: 'Université de Skikda'
  },
  {
    id: '9480',
    label: 'Université de Strasbourg',
    value: 'Université de Strasbourg'
  },
  {
    id: '9481',
    label: 'Université de Sétif',
    value: 'Université de Sétif'
  },
  {
    id: '9482',
    label: 'Université de Technologie de Belfort Montbéliard',
    value: 'Université de Technologie de Belfort Montbéliard'
  },
  {
    id: '9483',
    label: 'Université de Technologie de Compiègne',
    value: 'Université de Technologie de Compiègne'
  },
  {
    id: '9484',
    label: 'Université de Technologie de Troyes',
    value: 'Université de Technologie de Troyes'
  },
  {
    id: '9485',
    label: 'Université de Thiès',
    value: 'Université de Thiès'
  },
  {
    id: '9486',
    label: 'Université de Toamasina',
    value: 'Université de Toamasina'
  },
  {
    id: '9487',
    label: 'Université de Toliary',
    value: 'Université de Toliary'
  },
  {
    id: '9488',
    label: 'Université de Toulouse',
    value: 'Université de Toulouse'
  },
  {
    id: '9489',
    label: 'Université de Toulouse-le-Mirail (Toulouse II)',
    value: 'Université de Toulouse-le-Mirail (Toulouse II)'
  },
  {
    id: '9490',
    label: 'Université de Tunis',
    value: 'Université de Tunis'
  },
  {
    id: '9491',
    label: 'Université de Tunis El Manar',
    value: 'Université de Tunis El Manar'
  },
  {
    id: '9492',
    label: 'Université de Valenciennes et du Hainaut-Cambrésis',
    value: 'Université de Valenciennes et du Hainaut-Cambrésis'
  },
  {
    id: '9493',
    label: 'Université de Versailles Saint-Quentin-en-Yvelines',
    value: 'Université de Versailles Saint-Quentin-en-Yvelines'
  },
  {
    id: '9494',
    label: 'Université de Yaoundé I',
    value: 'Université de Yaoundé I'
  },
  {
    id: '9495',
    label: 'Université de Yaoundé II',
    value: 'Université de Yaoundé II'
  },
  {
    id: '9496',
    label: 'Université de Ziguinchor',
    value: 'Université de Ziguinchor'
  },
  {
    id: '9497',
    label: 'Université de la Manouba',
    value: 'Université de la Manouba'
  },
  {
    id: '9498',
    label: 'Université de la Méditerranée (Aix Marseille II)',
    value: 'Université de la Méditerranée (Aix Marseille II)'
  },
  {
    id: '9499',
    label: 'Université de la Polynésie Française',
    value: 'Université de la Polynésie Française'
  },
  {
    id: '9500',
    label: 'Université de la Reunion',
    value: 'Université de la Reunion'
  },
  {
    id: '9501',
    label: 'Université des Antilles',
    value: 'Université des Antilles'
  },
  {
    id: '9502',
    label: 'Université des Antilles et de la Guyane',
    value: 'Université des Antilles et de la Guyane'
  },
  {
    id: '9503',
    label: 'Université des Montagnes',
    value: 'Université des Montagnes'
  },
  {
    id: '9504',
    label: 'Université des Sciences Humaines (Strasbourg II)',
    value: 'Université des Sciences Humaines (Strasbourg II)'
  },
  {
    id: '9505',
    label: 'Université des Sciences Sociales (Toulouse I)',
    value: 'Université des Sciences Sociales (Toulouse I)'
  },
  {
    id: '9506',
    label: 'Université des Sciences et Technologies de Lille (Lille I)',
    value: 'Université des Sciences et Technologies de Lille (Lille I)'
  },
  {
    id: '9507',
    label: 'Université des Sciences et de la Technologie Houari Boumediène',
    value: 'Université des Sciences et de la Technologie Houari Boumediène'
  },
  {
    id: '9508',
    label: "Université des Sciences et de la Technologie d'Oran",
    value: "Université des Sciences et de la Technologie d'Oran"
  },
  {
    id: '9509',
    label: 'Université du Burundi',
    value: 'Université du Burundi'
  },
  {
    id: '9510',
    label: 'Université du Centre, Sousse',
    value: 'Université du Centre, Sousse'
  },
  {
    id: '9511',
    label: 'Université du Droit et de la Sante (Lille II)',
    value: 'Université du Droit et de la Sante (Lille II)'
  },
  {
    id: '9512',
    label: 'Université du Havre',
    value: 'Université du Havre'
  },
  {
    id: '9513',
    label: "Université du Littoral Cote d'Opale",
    value: "Université du Littoral Cote d'Opale"
  },
  {
    id: '9514',
    label: 'Université du Maine',
    value: 'Université du Maine'
  },
  {
    id: '9515',
    label: 'Université du Québec en Abitibi-Témiscamingue',
    value: 'Université du Québec en Abitibi-Témiscamingue'
  },
  {
    id: '9516',
    label: 'Université du Québec en Outaouais',
    value: 'Université du Québec en Outaouais'
  },
  {
    id: '9517',
    label: 'Université du Québec à Chicoutimi',
    value: 'Université du Québec à Chicoutimi'
  },
  {
    id: '9518',
    label: 'Université du Québec à Montréal',
    value: 'Université du Québec à Montréal'
  },
  {
    id: '9519',
    label: 'Université du Québec à Rimouski',
    value: 'Université du Québec à Rimouski'
  },
  {
    id: '9520',
    label: 'Université du Québec à Trois-Rivières',
    value: 'Université du Québec à Trois-Rivières'
  },
  {
    id: '9521',
    label: 'Université du Sahel',
    value: 'Université du Sahel'
  },
  {
    id: '9522',
    label: 'Université du Sud, Toulon et Var',
    value: 'Université du Sud, Toulon et Var'
  },
  {
    id: '9523',
    label: "Université Épiscopale d'Haiti",
    value: "Université Épiscopale d'Haiti"
  },
  {
    id: '9524',
    label: 'Universum College',
    value: 'Universum College'
  },
  {
    id: '9525',
    label: 'Univerzitet u Mostaru "Dzemal Bijedic"',
    value: 'Univerzitet u Mostaru "Dzemal Bijedic"'
  },
  {
    id: '9526',
    label: 'Univesidade Agostinho Neto',
    value: 'Univesidade Agostinho Neto'
  },
  {
    id: '9527',
    label: 'Up Learn Business School',
    value: 'Up Learn Business School'
  },
  {
    id: '9528',
    label: 'Upper Iowa University',
    value: 'Upper Iowa University'
  },
  {
    id: '9529',
    label: 'Upper Nile University',
    value: 'Upper Nile University'
  },
  {
    id: '9530',
    label: 'Uppsala University',
    value: 'Uppsala University'
  },
  {
    id: '9531',
    label: 'Ural Academy of Public Administration',
    value: 'Ural Academy of Public Administration'
  },
  {
    id: '9532',
    label: 'Ural Federal University',
    value: 'Ural Federal University'
  },
  {
    id: '9533',
    label: 'Ural Gorkij State University',
    value: 'Ural Gorkij State University'
  },
  {
    id: '9534',
    label: 'Ural State Academy of Architecture and Arts',
    value: 'Ural State Academy of Architecture and Arts'
  },
  {
    id: '9535',
    label: 'Ural State Academy of Law',
    value: 'Ural State Academy of Law'
  },
  {
    id: '9536',
    label: 'Ural State Academy of Mining and Geology',
    value: 'Ural State Academy of Mining and Geology'
  },
  {
    id: '9537',
    label: 'Ural State Academy of Railway Transport',
    value: 'Ural State Academy of Railway Transport'
  },
  {
    id: '9538',
    label: 'Ural State Conservatory',
    value: 'Ural State Conservatory'
  },
  {
    id: '9539',
    label: 'Ural State Forestry Technical Academy',
    value: 'Ural State Forestry Technical Academy'
  },
  {
    id: '9540',
    label: 'Ural State University of Economics',
    value: 'Ural State University of Economics'
  },
  {
    id: '9541',
    label: 'Urban College of Boston',
    value: 'Urban College of Boston'
  },
  {
    id: '9542',
    label: 'Urmia University',
    value: 'Urmia University'
  },
  {
    id: '9543',
    label: 'Urmia University of Medical Sciences',
    value: 'Urmia University of Medical Sciences'
  },
  {
    id: '9544',
    label: 'Urmia University of Technology',
    value: 'Urmia University of Technology'
  },
  {
    id: '9545',
    label: 'Ursinus College',
    value: 'Ursinus College'
  },
  {
    id: '9546',
    label: 'Urumqi Vocational University',
    value: 'Urumqi Vocational University'
  },
  {
    id: '9547',
    label: 'Usak University',
    value: 'Usak University'
  },
  {
    id: '9548',
    label: 'Uskudar University',
    value: 'Uskudar University'
  },
  {
    id: '9549',
    label: 'Usmanu Danfodiyo University Sokoto',
    value: 'Usmanu Danfodiyo University Sokoto'
  },
  {
    id: '9550',
    label: 'Utah State University',
    value: 'Utah State University'
  },
  {
    id: '9551',
    label: 'Utah Tech University',
    value: 'Utah Tech University'
  },
  {
    id: '9552',
    label: 'Utah Valley State College',
    value: 'Utah Valley State College'
  },
  {
    id: '9553',
    label: 'Utah Valley University',
    value: 'Utah Valley University'
  },
  {
    id: '9554',
    label: 'Utica College',
    value: 'Utica College'
  },
  {
    id: '9555',
    label: 'Utkal University',
    value: 'Utkal University'
  },
  {
    id: '9556',
    label: 'Utrecht University',
    value: 'Utrecht University'
  },
  {
    id: '9557',
    label: 'Utsunomiya University',
    value: 'Utsunomiya University'
  },
  {
    id: '9558',
    label: 'Uttar Pradesh Technical University',
    value: 'Uttar Pradesh Technical University'
  },
  {
    id: '9559',
    label: 'Uttarakhand Open University',
    value: 'Uttarakhand Open University'
  },
  {
    id: '9560',
    label: 'Uttarakhand Technical University',
    value: 'Uttarakhand Technical University'
  },
  {
    id: '9561',
    label: 'Uttaranchal University',
    value: 'Uttaranchal University'
  },
  {
    id: '9562',
    label: 'Uva Wellassa University',
    value: 'Uva Wellassa University'
  },
  {
    id: '9563',
    label: 'Uzbek State World Languages University',
    value: 'Uzbek State World Languages University'
  },
  {
    id: '9564',
    label: 'Uzhgorod National University',
    value: 'Uzhgorod National University'
  },
  {
    id: '9565',
    label: 'Uzima University College',
    value: 'Uzima University College'
  },
  {
    id: '9566',
    label: 'VBS Purvanchal University',
    value: 'VBS Purvanchal University'
  },
  {
    id: '9567',
    label: "VERN' University of Applied Sciences",
    value: "VERN' University of Applied Sciences"
  },
  {
    id: '9568',
    label: 'VIA University College',
    value: 'VIA University College'
  },
  {
    id: '9569',
    label: 'VIT Bhopal',
    value: 'VIT Bhopal'
  },
  {
    id: '9570',
    label: 'VNU University of Engineering and Technology',
    value: 'VNU University of Engineering and Technology'
  },
  {
    id: '9571',
    label: 'Vaal University of Technology',
    value: 'Vaal University of Technology'
  },
  {
    id: '9572',
    label: 'Vaasa University of Applied Sciences',
    value: 'Vaasa University of Applied Sciences'
  },
  {
    id: '9573',
    label: 'Vaganova Academy of Russian Ballet',
    value: 'Vaganova Academy of Russian Ballet'
  },
  {
    id: '9574',
    label: 'Valahia University of Targoviste',
    value: 'Valahia University of Targoviste'
  },
  {
    id: '9575',
    label: 'Valdosta State University',
    value: 'Valdosta State University'
  },
  {
    id: '9576',
    label: 'Valencia College',
    value: 'Valencia College'
  },
  {
    id: '9577',
    label: 'Vali-e-Asr University of Rafsanjan',
    value: 'Vali-e-Asr University of Rafsanjan'
  },
  {
    id: '9578',
    label: 'Valley City State University',
    value: 'Valley City State University'
  },
  {
    id: '9579',
    label: 'Valley Forge Military Academy and College',
    value: 'Valley Forge Military Academy and College'
  },
  {
    id: '9580',
    label: 'Valley View University',
    value: 'Valley View University'
  },
  {
    id: '9581',
    label: 'Valparaiso University',
    value: 'Valparaiso University'
  },
  {
    id: '9582',
    label: 'Vance-Granville Community College',
    value: 'Vance-Granville Community College'
  },
  {
    id: '9583',
    label: 'Vancouver Community College',
    value: 'Vancouver Community College'
  },
  {
    id: '9584',
    label: 'Vanderbilt University',
    value: 'Vanderbilt University'
  },
  {
    id: '9585',
    label: 'Vanier College',
    value: 'Vanier College'
  },
  {
    id: '9586',
    label: 'Vardhaman Mahaveer Open University',
    value: 'Vardhaman Mahaveer Open University'
  },
  {
    id: '9587',
    label: 'Varna Free University',
    value: 'Varna Free University'
  },
  {
    id: '9588',
    label: 'Vassar College',
    value: 'Vassar College'
  },
  {
    id: '9589',
    label: 'Veer Kunwar Singh University',
    value: 'Veer Kunwar Singh University'
  },
  {
    id: '9590',
    label: 'Veer Surendra Sai University of Technology',
    value: 'Veer Surendra Sai University of Technology'
  },
  {
    id: '9591',
    label: 'Velammal Engineering College',
    value: 'Velammal Engineering College'
  },
  {
    id: '9592',
    label: 'Velammal Institute of Technology',
    value: 'Velammal Institute of Technology'
  },
  {
    id: '9593',
    label: 'Veliko Turnovo University "Cyril and Methodius"',
    value: 'Veliko Turnovo University "Cyril and Methodius"'
  },
  {
    id: '9594',
    label: 'Vellore Institute of Technology',
    value: 'Vellore Institute of Technology'
  },
  {
    id: '9595',
    label: 'Ventura College',
    value: 'Ventura College'
  },
  {
    id: '9596',
    label: 'Verde Valley School',
    value: 'Verde Valley School'
  },
  {
    id: '9597',
    label: 'Veritas University',
    value: 'Veritas University'
  },
  {
    id: '9598',
    label: 'Vermilion Community College',
    value: 'Vermilion Community College'
  },
  {
    id: '9599',
    label: 'Vermont Technical College',
    value: 'Vermont Technical College'
  },
  {
    id: '9600',
    label: 'Vernon College',
    value: 'Vernon College'
  },
  {
    id: '9601',
    label: 'Vesalius College',
    value: 'Vesalius College'
  },
  {
    id: '9602',
    label: 'Veterinärmedizinische Universität Wien',
    value: 'Veterinärmedizinische Universität Wien'
  },
  {
    id: '9603',
    label: 'Victor Valley College',
    value: 'Victor Valley College'
  },
  {
    id: '9604',
    label: 'Victoria College',
    value: 'Victoria College'
  },
  {
    id: '9605',
    label: 'Victoria International University',
    value: 'Victoria International University'
  },
  {
    id: '9606',
    label: 'Victoria University',
    value: 'Victoria University'
  },
  {
    id: '9607',
    label: 'Victoria University',
    value: 'Victoria University'
  },
  {
    id: '9608',
    label: 'Victoria University Toronto, University of Toronto',
    value: 'Victoria University Toronto, University of Toronto'
  },
  {
    id: '9609',
    label: 'Victoria University of Wellington',
    value: 'Victoria University of Wellington'
  },
  {
    id: '9610',
    label: 'Vidyasagar University',
    value: 'Vidyasagar University'
  },
  {
    id: '9611',
    label: 'Vidzeme University College',
    value: 'Vidzeme University College'
  },
  {
    id: '9612',
    label: 'Vietnam Maritime University',
    value: 'Vietnam Maritime University'
  },
  {
    id: '9613',
    label: 'Vietnam National University Hanoi',
    value: 'Vietnam National University Hanoi'
  },
  {
    id: '9614',
    label: 'Vietnam National University Ho Chi Minh City',
    value: 'Vietnam National University Ho Chi Minh City'
  },
  {
    id: '9615',
    label: 'Vietnamese - German University',
    value: 'Vietnamese - German University'
  },
  {
    id: '9616',
    label: 'Vignan University',
    value: 'Vignan University'
  },
  {
    id: '9617',
    label: 'Vikram University',
    value: 'Vikram University'
  },
  {
    id: '9618',
    label: 'Vikrama Simhapuri University',
    value: 'Vikrama Simhapuri University'
  },
  {
    id: '9619',
    label: 'Villa Julie College',
    value: 'Villa Julie College'
  },
  {
    id: '9620',
    label: 'Villanova University',
    value: 'Villanova University'
  },
  {
    id: '9621',
    label: 'Vilnius Academy of Arts',
    value: 'Vilnius Academy of Arts'
  },
  {
    id: '9622',
    label: 'Vilnius Gediminas Technical University',
    value: 'Vilnius Gediminas Technical University'
  },
  {
    id: '9623',
    label: 'Vilnius Pedagogical University',
    value: 'Vilnius Pedagogical University'
  },
  {
    id: '9624',
    label: 'Vilnius University',
    value: 'Vilnius University'
  },
  {
    id: '9625',
    label: "Vinayaka Mission's Research Foundation-Deemed University",
    value: "Vinayaka Mission's Research Foundation-Deemed University"
  },
  {
    id: '9626',
    label: 'Vinnica National Technical University',
    value: 'Vinnica National Technical University'
  },
  {
    id: '9627',
    label: 'Vinoba Bhave University',
    value: 'Vinoba Bhave University'
  },
  {
    id: '9628',
    label: 'Virgen Milagrosa University Foundation',
    value: 'Virgen Milagrosa University Foundation'
  },
  {
    id: '9629',
    label: 'Virginia Commonwealth University',
    value: 'Virginia Commonwealth University'
  },
  {
    id: '9630',
    label: 'Virginia Community College System',
    value: 'Virginia Community College System'
  },
  {
    id: '9631',
    label: 'Virginia Highlands Community College',
    value: 'Virginia Highlands Community College'
  },
  {
    id: '9632',
    label: 'Virginia Intermont College',
    value: 'Virginia Intermont College'
  },
  {
    id: '9633',
    label: 'Virginia Military Institute',
    value: 'Virginia Military Institute'
  },
  {
    id: '9634',
    label: 'Virginia State University',
    value: 'Virginia State University'
  },
  {
    id: '9635',
    label: 'Virginia Tech',
    value: 'Virginia Tech'
  },
  {
    id: '9636',
    label: 'Virginia Union University',
    value: 'Virginia Union University'
  },
  {
    id: '9637',
    label: 'Virginia University of Lynchburg',
    value: 'Virginia University of Lynchburg'
  },
  {
    id: '9638',
    label: 'Virginia Wesleyan College',
    value: 'Virginia Wesleyan College'
  },
  {
    id: '9639',
    label: 'Virginia Western Community College',
    value: 'Virginia Western Community College'
  },
  {
    id: '9640',
    label: 'Virtual University of Pakistan',
    value: 'Virtual University of Pakistan'
  },
  {
    id: '9641',
    label: 'Vistula University',
    value: 'Vistula University'
  },
  {
    id: '9642',
    label: 'Visva-Bharati University',
    value: 'Visva-Bharati University'
  },
  {
    id: '9643',
    label: 'Visvesvaraya National Institute of Technology',
    value: 'Visvesvaraya National Institute of Technology'
  },
  {
    id: '9644',
    label: 'Visvesvaraya Technological University',
    value: 'Visvesvaraya Technological University'
  },
  {
    id: '9645',
    label: 'Vitebsk State Academy of Veterinary Medicine',
    value: 'Vitebsk State Academy of Veterinary Medicine'
  },
  {
    id: '9646',
    label: 'Vitebsk State Medical University',
    value: 'Vitebsk State Medical University'
  },
  {
    id: '9647',
    label: 'Vitebsk State Technological University',
    value: 'Vitebsk State Technological University'
  },
  {
    id: '9648',
    label: 'Vitebsk State University',
    value: 'Vitebsk State University'
  },
  {
    id: '9649',
    label: 'Viterbo State University',
    value: 'Viterbo State University'
  },
  {
    id: '9650',
    label: 'Vladimir State University',
    value: 'Vladimir State University'
  },
  {
    id: '9651',
    label: 'Vladivostock State University of Economics',
    value: 'Vladivostock State University of Economics'
  },
  {
    id: '9652',
    label: 'Vlerick Brussels Campus',
    value: 'Vlerick Brussels Campus'
  },
  {
    id: '9653',
    label: 'Vlerick Leuven Gent Management School',
    value: 'Vlerick Leuven Gent Management School'
  },
  {
    id: '9654',
    label: 'Volga State Academy of Water Transport',
    value: 'Volga State Academy of Water Transport'
  },
  {
    id: '9655',
    label: 'Volgograd Medical Academy',
    value: 'Volgograd Medical Academy'
  },
  {
    id: '9656',
    label: 'Volgograd State Academy of Physical Education',
    value: 'Volgograd State Academy of Physical Education'
  },
  {
    id: '9657',
    label: 'Volgograd State Pedagogical University',
    value: 'Volgograd State Pedagogical University'
  },
  {
    id: '9658',
    label: 'Volgograd State Technical University',
    value: 'Volgograd State Technical University'
  },
  {
    id: '9659',
    label: 'Volgograd State University',
    value: 'Volgograd State University'
  },
  {
    id: '9660',
    label: 'Volgograd State University of Architecture and Civil Engineering (VolgGASU)',
    value: 'Volgograd State University of Architecture and Civil Engineering (VolgGASU)'
  },
  {
    id: '9661',
    label: 'Vologda State Pedagogical University',
    value: 'Vologda State Pedagogical University'
  },
  {
    id: '9662',
    label: 'Volunteer State Community College',
    value: 'Volunteer State Community College'
  },
  {
    id: '9663',
    label: 'Volyn National University Lesja Ukrainka',
    value: 'Volyn National University Lesja Ukrainka'
  },
  {
    id: '9664',
    label: 'Vongchavalitkul University',
    value: 'Vongchavalitkul University'
  },
  {
    id: '9665',
    label: 'Voorhees University',
    value: 'Voorhees University'
  },
  {
    id: '9666',
    label: 'Voronezh State Academy of Technology',
    value: 'Voronezh State Academy of Technology'
  },
  {
    id: '9667',
    label: 'Voronezh State Agricultural University',
    value: 'Voronezh State Agricultural University'
  },
  {
    id: '9668',
    label: 'Voronezh State Medical Academy',
    value: 'Voronezh State Medical Academy'
  },
  {
    id: '9669',
    label: 'Voronezh State Pedagogical University',
    value: 'Voronezh State Pedagogical University'
  },
  {
    id: '9670',
    label: 'Voronezh State Technical University',
    value: 'Voronezh State Technical University'
  },
  {
    id: '9671',
    label: 'Voronezh State University',
    value: 'Voronezh State University'
  },
  {
    id: '9672',
    label: 'Vrije Universiteit Amsterdam',
    value: 'Vrije Universiteit Amsterdam'
  },
  {
    id: '9673',
    label: 'Vrije Universiteit Brussel',
    value: 'Vrije Universiteit Brussel'
  },
  {
    id: '9674',
    label: 'Vyatka State Pedagogical University',
    value: 'Vyatka State Pedagogical University'
  },
  {
    id: '9675',
    label: 'Vytautas Magnus University',
    value: 'Vytautas Magnus University'
  },
  {
    id: '9676',
    label: 'Växjö University',
    value: 'Växjö University'
  },
  {
    id: '9677',
    label: 'Wabash College',
    value: 'Wabash College'
  },
  {
    id: '9678',
    label: 'Wadi International University',
    value: 'Wadi International University'
  },
  {
    id: '9679',
    label: 'Wageningen University',
    value: 'Wageningen University'
  },
  {
    id: '9680',
    label: 'Wagner College',
    value: 'Wagner College'
  },
  {
    id: '9681',
    label: 'Wah Medical College',
    value: 'Wah Medical College'
  },
  {
    id: '9682',
    label: 'Wakayama Medical College',
    value: 'Wakayama Medical College'
  },
  {
    id: '9683',
    label: 'Wakayama University',
    value: 'Wakayama University'
  },
  {
    id: '9684',
    label: 'Wake Forest Baptist Health',
    value: 'Wake Forest Baptist Health'
  },
  {
    id: '9685',
    label: 'Wake Forest University',
    value: 'Wake Forest University'
  },
  {
    id: '9686',
    label: 'Wake Technical Community College',
    value: 'Wake Technical Community College'
  },
  {
    id: '9687',
    label: 'Wakefield College',
    value: 'Wakefield College'
  },
  {
    id: '9688',
    label: 'Wakkanai Hokusei Gakuen University',
    value: 'Wakkanai Hokusei Gakuen University'
  },
  {
    id: '9689',
    label: 'Wako University',
    value: 'Wako University'
  },
  {
    id: '9690',
    label: 'Walailak University',
    value: 'Walailak University'
  },
  {
    id: '9691',
    label: 'Walcz College',
    value: 'Walcz College'
  },
  {
    id: '9692',
    label: 'Walden University',
    value: 'Walden University'
  },
  {
    id: '9693',
    label: 'Walla Walla Community College',
    value: 'Walla Walla Community College'
  },
  {
    id: '9694',
    label: 'Walla Walla University',
    value: 'Walla Walla University'
  },
  {
    id: '9695',
    label: 'Walsh College',
    value: 'Walsh College'
  },
  {
    id: '9696',
    label: 'Walter Sisulu University for Technology and Science',
    value: 'Walter Sisulu University for Technology and Science'
  },
  {
    id: '9697',
    label: 'Walters State Community College',
    value: 'Walters State Community College'
  },
  {
    id: '9698',
    label: 'Warnborough University',
    value: 'Warnborough University'
  },
  {
    id: '9699',
    label: 'Warren County Community College',
    value: 'Warren County Community College'
  },
  {
    id: '9700',
    label: 'Warren Wilson College',
    value: 'Warren Wilson College'
  },
  {
    id: '9701',
    label: 'Warsaw School of Economics',
    value: 'Warsaw School of Economics'
  },
  {
    id: '9702',
    label: 'Warsaw School of Information Technology',
    value: 'Warsaw School of Information Technology'
  },
  {
    id: '9703',
    label: 'Warsaw School of Social Psychology',
    value: 'Warsaw School of Social Psychology'
  },
  {
    id: '9704',
    label: 'Warsaw University of Technology',
    value: 'Warsaw University of Technology'
  },
  {
    id: '9705',
    label: 'Wartburg College',
    value: 'Wartburg College'
  },
  {
    id: '9706',
    label: 'Waseda University',
    value: 'Waseda University'
  },
  {
    id: '9707',
    label: 'Washburn University',
    value: 'Washburn University'
  },
  {
    id: '9708',
    label: 'Washington & Jefferson College',
    value: 'Washington & Jefferson College'
  },
  {
    id: '9709',
    label: 'Washington & Lee University',
    value: 'Washington & Lee University'
  },
  {
    id: '9710',
    label: 'Washington Bible College/Capital Bible Seminary',
    value: 'Washington Bible College/Capital Bible Seminary'
  },
  {
    id: '9711',
    label: 'Washington College',
    value: 'Washington College'
  },
  {
    id: '9712',
    label: 'Washington County Community College',
    value: 'Washington County Community College'
  },
  {
    id: '9713',
    label: 'Washington State Community College',
    value: 'Washington State Community College'
  },
  {
    id: '9714',
    label: 'Washington State University',
    value: 'Washington State University'
  },
  {
    id: '9715',
    label: 'Washington State University at Vancouver',
    value: 'Washington State University at Vancouver'
  },
  {
    id: '9716',
    label: 'Washington University, Saint Louis',
    value: 'Washington University, Saint Louis'
  },
  {
    id: '9717',
    label: 'Washtenaw Community College',
    value: 'Washtenaw Community College'
  },
  {
    id: '9718',
    label: 'Water Resources University',
    value: 'Water Resources University'
  },
  {
    id: '9719',
    label: 'Waterford Institute Of Technology',
    value: 'Waterford Institute Of Technology'
  },
  {
    id: '9720',
    label: 'Waubonsee Community College',
    value: 'Waubonsee Community College'
  },
  {
    id: '9721',
    label: 'Waukesha County Technical College',
    value: 'Waukesha County Technical College'
  },
  {
    id: '9722',
    label: 'Wawasan Open University',
    value: 'Wawasan Open University'
  },
  {
    id: '9723',
    label: 'Wayamba University of Sri Lanka',
    value: 'Wayamba University of Sri Lanka'
  },
  {
    id: '9724',
    label: 'Wayland Baptist University',
    value: 'Wayland Baptist University'
  },
  {
    id: '9725',
    label: 'Wayne Community College',
    value: 'Wayne Community College'
  },
  {
    id: '9726',
    label: 'Wayne County Community College District',
    value: 'Wayne County Community College District'
  },
  {
    id: '9727',
    label: 'Wayne State University',
    value: 'Wayne State University'
  },
  {
    id: '9728',
    label: 'Waynesburg College',
    value: 'Waynesburg College'
  },
  {
    id: '9729',
    label: "Wayo Women's University",
    value: "Wayo Women's University"
  },
  {
    id: '9730',
    label: 'Weatherford College',
    value: 'Weatherford College'
  },
  {
    id: '9731',
    label: 'Weber State University',
    value: 'Weber State University'
  },
  {
    id: '9732',
    label: 'Webster University',
    value: 'Webster University'
  },
  {
    id: '9733',
    label: 'Webster University Geneva',
    value: 'Webster University Geneva'
  },
  {
    id: '9734',
    label: 'Webster University, Thailand',
    value: 'Webster University, Thailand'
  },
  {
    id: '9735',
    label: 'Webster University, Vienna',
    value: 'Webster University, Vienna'
  },
  {
    id: '9736',
    label: 'Weizmann Institute of Science',
    value: 'Weizmann Institute of Science'
  },
  {
    id: '9737',
    label: 'Wellesley College',
    value: 'Wellesley College'
  },
  {
    id: '9738',
    label: 'Wells College',
    value: 'Wells College'
  },
  {
    id: '9739',
    label: 'Wellspring University',
    value: 'Wellspring University'
  },
  {
    id: '9740',
    label: 'Wenatchee Valley College',
    value: 'Wenatchee Valley College'
  },
  {
    id: '9741',
    label: 'Wentworth Institute of Technology',
    value: 'Wentworth Institute of Technology'
  },
  {
    id: '9742',
    label: 'Wentworth Military Academy & Junior College',
    value: 'Wentworth Military Academy & Junior College'
  },
  {
    id: '9743',
    label: 'Wenzhou Medical College',
    value: 'Wenzhou Medical College'
  },
  {
    id: '9744',
    label: 'Wenzhou University',
    value: 'Wenzhou University'
  },
  {
    id: '9745',
    label: 'Wesley College',
    value: 'Wesley College'
  },
  {
    id: '9746',
    label: 'Wesley University of Science and Technology',
    value: 'Wesley University of Science and Technology'
  },
  {
    id: '9747',
    label: 'Wesleyan University',
    value: 'Wesleyan University'
  },
  {
    id: '9748',
    label: 'Wesleyan University Philippines',
    value: 'Wesleyan University Philippines'
  },
  {
    id: '9749',
    label: 'West Bengal University of Animal and Fishery Sciences',
    value: 'West Bengal University of Animal and Fishery Sciences'
  },
  {
    id: '9750',
    label: 'West Bengal University of Technology',
    value: 'West Bengal University of Technology'
  },
  {
    id: '9751',
    label: 'West Chester University of Pennsylvania',
    value: 'West Chester University of Pennsylvania'
  },
  {
    id: '9752',
    label: 'West China University of Medical Sciences',
    value: 'West China University of Medical Sciences'
  },
  {
    id: '9753',
    label: 'West Coast University',
    value: 'West Coast University'
  },
  {
    id: '9754',
    label: 'West Coast University (WCU)',
    value: 'West Coast University (WCU)'
  },
  {
    id: '9755',
    label: 'West Coast University (WCU)',
    value: 'West Coast University (WCU)'
  },
  {
    id: '9756',
    label: 'West Georgia College',
    value: 'West Georgia College'
  },
  {
    id: '9757',
    label: 'West Georgia Technical College',
    value: 'West Georgia Technical College'
  },
  {
    id: '9758',
    label: 'West Herts College',
    value: 'West Herts College'
  },
  {
    id: '9759',
    label: 'West Hills College-Coalinga',
    value: 'West Hills College-Coalinga'
  },
  {
    id: '9760',
    label: 'West Kentucky Community and Technical College',
    value: 'West Kentucky Community and Technical College'
  },
  {
    id: '9761',
    label: 'West Liberty State College',
    value: 'West Liberty State College'
  },
  {
    id: '9762',
    label: 'West Los Angeles College',
    value: 'West Los Angeles College'
  },
  {
    id: '9763',
    label: 'West Minster International College',
    value: 'West Minster International College'
  },
  {
    id: '9764',
    label: 'West Pomeranian Business School in Szczecin',
    value: 'West Pomeranian Business School in Szczecin'
  },
  {
    id: '9765',
    label: 'West Shore Community College',
    value: 'West Shore Community College'
  },
  {
    id: '9766',
    label: 'West Suffolk College',
    value: 'West Suffolk College'
  },
  {
    id: '9767',
    label: 'West Texas A&M University',
    value: 'West Texas A&M University'
  },
  {
    id: '9768',
    label: 'West University of Timisoara',
    value: 'West University of Timisoara'
  },
  {
    id: '9769',
    label: 'West Valley College',
    value: 'West Valley College'
  },
  {
    id: '9770',
    label: 'West Virginia Northern Community College',
    value: 'West Virginia Northern Community College'
  },
  {
    id: '9771',
    label: 'West Virginia State University',
    value: 'West Virginia State University'
  },
  {
    id: '9772',
    label: 'West Virginia University',
    value: 'West Virginia University'
  },
  {
    id: '9773',
    label: 'West Virginia University at Parkersburg',
    value: 'West Virginia University at Parkersburg'
  },
  {
    id: '9774',
    label: 'West Virginia Wesleyan College',
    value: 'West Virginia Wesleyan College'
  },
  {
    id: '9775',
    label: 'West Visayas State University',
    value: 'West Visayas State University'
  },
  {
    id: '9776',
    label: 'Western Carolina University',
    value: 'Western Carolina University'
  },
  {
    id: '9777',
    label: 'Western Connecticut State University',
    value: 'Western Connecticut State University'
  },
  {
    id: '9778',
    label: 'Western Dakota Technical Institute',
    value: 'Western Dakota Technical Institute'
  },
  {
    id: '9779',
    label: 'Western Delta University',
    value: 'Western Delta University'
  },
  {
    id: '9780',
    label: 'Western Galilee College',
    value: 'Western Galilee College'
  },
  {
    id: '9781',
    label: 'Western Governors University',
    value: 'Western Governors University'
  },
  {
    id: '9782',
    label: 'Western Illinois University',
    value: 'Western Illinois University'
  },
  {
    id: '9783',
    label: 'Western Iowa Tech Community College',
    value: 'Western Iowa Tech Community College'
  },
  {
    id: '9784',
    label: 'Western Kazakhstan Agricultural University',
    value: 'Western Kazakhstan Agricultural University'
  },
  {
    id: '9785',
    label: 'Western Kentucky University',
    value: 'Western Kentucky University'
  },
  {
    id: '9786',
    label: 'Western Michigan University',
    value: 'Western Michigan University'
  },
  {
    id: '9787',
    label: 'Western Mindanao State University',
    value: 'Western Mindanao State University'
  },
  {
    id: '9788',
    label: 'Western Montana College',
    value: 'Western Montana College'
  },
  {
    id: '9789',
    label: 'Western Nebraska Community College',
    value: 'Western Nebraska Community College'
  },
  {
    id: '9790',
    label: 'Western New England College',
    value: 'Western New England College'
  },
  {
    id: '9791',
    label: 'Western New England University',
    value: 'Western New England University'
  },
  {
    id: '9792',
    label: 'Western New Mexico University',
    value: 'Western New Mexico University'
  },
  {
    id: '9793',
    label: 'Western Oklahoma State College',
    value: 'Western Oklahoma State College'
  },
  {
    id: '9794',
    label: 'Western Piedmont Community College',
    value: 'Western Piedmont Community College'
  },
  {
    id: '9795',
    label: 'Western State Colorado University',
    value: 'Western State Colorado University'
  },
  {
    id: '9796',
    label: 'Western Technical College',
    value: 'Western Technical College'
  },
  {
    id: '9797',
    label: 'Western Texas College',
    value: 'Western Texas College'
  },
  {
    id: '9798',
    label: 'Western University',
    value: 'Western University'
  },
  {
    id: '9799',
    label: 'Western University',
    value: 'Western University'
  },
  {
    id: '9800',
    label: 'Western Washington University',
    value: 'Western Washington University'
  },
  {
    id: '9801',
    label: 'Western Wyoming Community College',
    value: 'Western Wyoming Community College'
  },
  {
    id: '9802',
    label: 'Westfield State College',
    value: 'Westfield State College'
  },
  {
    id: '9803',
    label: 'Westfälische Wilhelms-Universität Münster',
    value: 'Westfälische Wilhelms-Universität Münster'
  },
  {
    id: '9804',
    label: 'Westhill University',
    value: 'Westhill University'
  },
  {
    id: '9805',
    label: 'Westminster College of Fulton',
    value: 'Westminster College of Fulton'
  },
  {
    id: '9806',
    label: 'Westminster College of New Wilmington',
    value: 'Westminster College of New Wilmington'
  },
  {
    id: '9807',
    label: 'Westminster College of Salt Lake City',
    value: 'Westminster College of Salt Lake City'
  },
  {
    id: '9808',
    label: 'Westminster International University in Tashkent',
    value: 'Westminster International University in Tashkent'
  },
  {
    id: '9809',
    label: 'Westminster Theological Seminary',
    value: 'Westminster Theological Seminary'
  },
  {
    id: '9810',
    label: 'Westminster University',
    value: 'Westminster University'
  },
  {
    id: '9811',
    label: 'Westmont College',
    value: 'Westmont College'
  },
  {
    id: '9812',
    label: 'Westmoreland County Community College',
    value: 'Westmoreland County Community College'
  },
  {
    id: '9813',
    label: 'Westsächsische Hochschule Zwickau (FH)',
    value: 'Westsächsische Hochschule Zwickau (FH)'
  },
  {
    id: '9814',
    label: 'Wharton County Junior College',
    value: 'Wharton County Junior College'
  },
  {
    id: '9815',
    label: 'Whatcom Community College',
    value: 'Whatcom Community College'
  },
  {
    id: '9816',
    label: 'Wheaton College',
    value: 'Wheaton College'
  },
  {
    id: '9817',
    label: 'Wheaton College, Norton MA',
    value: 'Wheaton College, Norton MA'
  },
  {
    id: '9818',
    label: 'Wheeling Jesuit College',
    value: 'Wheeling Jesuit College'
  },
  {
    id: '9819',
    label: 'White Earth Tribal and Community College',
    value: 'White Earth Tribal and Community College'
  },
  {
    id: '9820',
    label: 'White Mountains Community College',
    value: 'White Mountains Community College'
  },
  {
    id: '9821',
    label: 'Whitman College',
    value: 'Whitman College'
  },
  {
    id: '9822',
    label: 'Whittier College',
    value: 'Whittier College'
  },
  {
    id: '9823',
    label: 'Whitworth College',
    value: 'Whitworth College'
  },
  {
    id: '9824',
    label: 'Wichita Area Technical College',
    value: 'Wichita Area Technical College'
  },
  {
    id: '9825',
    label: 'Wichita State University',
    value: 'Wichita State University'
  },
  {
    id: '9826',
    label: 'Widener University',
    value: 'Widener University'
  },
  {
    id: '9827',
    label: 'Wilberforce University',
    value: 'Wilberforce University'
  },
  {
    id: '9828',
    label: 'Wiley College',
    value: 'Wiley College'
  },
  {
    id: '9829',
    label: 'Wilfrid Laurier University',
    value: 'Wilfrid Laurier University'
  },
  {
    id: '9830',
    label: 'Wilkes Community College',
    value: 'Wilkes Community College'
  },
  {
    id: '9831',
    label: 'Wilkes University',
    value: 'Wilkes University'
  },
  {
    id: '9832',
    label: 'Willamette University',
    value: 'Willamette University'
  },
  {
    id: '9833',
    label: 'William Gilbert College',
    value: 'William Gilbert College'
  },
  {
    id: '9834',
    label: 'William Howard Taft University',
    value: 'William Howard Taft University'
  },
  {
    id: '9835',
    label: 'William Jewell College',
    value: 'William Jewell College'
  },
  {
    id: '9836',
    label: 'William Mitchell College of Law',
    value: 'William Mitchell College of Law'
  },
  {
    id: '9837',
    label: 'William Paterson University',
    value: 'William Paterson University'
  },
  {
    id: '9838',
    label: 'William Penn College',
    value: 'William Penn College'
  },
  {
    id: '9839',
    label: 'William Woods University',
    value: 'William Woods University'
  },
  {
    id: '9840',
    label: 'William and Catherine Booth College',
    value: 'William and Catherine Booth College'
  },
  {
    id: '9841',
    label: 'Williams College',
    value: 'Williams College'
  },
  {
    id: '9842',
    label: 'Williamsburg Technical College',
    value: 'Williamsburg Technical College'
  },
  {
    id: '9843',
    label: 'Williston State College',
    value: 'Williston State College'
  },
  {
    id: '9844',
    label: 'Wilmington College',
    value: 'Wilmington College'
  },
  {
    id: '9845',
    label: 'Wilmington University',
    value: 'Wilmington University'
  },
  {
    id: '9846',
    label: 'Wilson Community College',
    value: 'Wilson Community College'
  },
  {
    id: '9847',
    label: 'Wimbledon School of Art',
    value: 'Wimbledon School of Art'
  },
  {
    id: '9848',
    label: 'Windsor University School of Medicine',
    value: 'Windsor University School of Medicine'
  },
  {
    id: '9849',
    label: 'Windward Community College',
    value: 'Windward Community College'
  },
  {
    id: '9850',
    label: 'Winona State University',
    value: 'Winona State University'
  },
  {
    id: '9851',
    label: 'Winston-Salem State University',
    value: 'Winston-Salem State University'
  },
  {
    id: '9852',
    label: 'Winthrop University',
    value: 'Winthrop University'
  },
  {
    id: '9853',
    label: 'Wiregrass Georgia Technical College',
    value: 'Wiregrass Georgia Technical College'
  },
  {
    id: '9854',
    label: 'Wirtschaftsuniversität Wien',
    value: 'Wirtschaftsuniversität Wien'
  },
  {
    id: '9855',
    label: 'Wisconsin Indianhead Technical College',
    value: 'Wisconsin Indianhead Technical College'
  },
  {
    id: '9856',
    label: 'Wisconsin Lutheran College',
    value: 'Wisconsin Lutheran College'
  },
  {
    id: '9857',
    label: 'Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule',
    value: 'Wissenschaftliche Hochschule für Unternehmensführung, Otto-Beisheim Hochschule'
  },
  {
    id: '9858',
    label: 'Wittenberg University',
    value: 'Wittenberg University'
  },
  {
    id: '9859',
    label: 'Wittenborg University',
    value: 'Wittenborg University'
  },
  {
    id: '9860',
    label: 'Wofford College',
    value: 'Wofford College'
  },
  {
    id: '9861',
    label: 'Wolaita Sodo University',
    value: 'Wolaita Sodo University'
  },
  {
    id: '9862',
    label: 'Wolkite University',
    value: 'Wolkite University'
  },
  {
    id: '9863',
    label: 'Wollega University',
    value: 'Wollega University'
  },
  {
    id: '9864',
    label: 'Wollo University',
    value: 'Wollo University'
  },
  {
    id: '9865',
    label: "Women's College of Fine Arts",
    value: "Women's College of Fine Arts"
  },
  {
    id: '9866',
    label: "Women's University in Africa",
    value: "Women's University in Africa"
  },
  {
    id: '9867',
    label: 'Wonkwang University',
    value: 'Wonkwang University'
  },
  {
    id: '9868',
    label: 'Woodbury University',
    value: 'Woodbury University'
  },
  {
    id: '9869',
    label: 'Woodland Community College',
    value: 'Woodland Community College'
  },
  {
    id: '9870',
    label: 'Woods Hole Oceanographic Institution',
    value: 'Woods Hole Oceanographic Institution'
  },
  {
    id: '9871',
    label: 'Woosong University',
    value: 'Woosong University'
  },
  {
    id: '9872',
    label: 'Woosuk University',
    value: 'Woosuk University'
  },
  {
    id: '9873',
    label: 'Wor-Wic Community College',
    value: 'Wor-Wic Community College'
  },
  {
    id: '9874',
    label: 'Worcester Polytechnic Institute',
    value: 'Worcester Polytechnic Institute'
  },
  {
    id: '9875',
    label: 'World Maritime University',
    value: 'World Maritime University'
  },
  {
    id: '9876',
    label: 'World University of Bangladesh',
    value: 'World University of Bangladesh'
  },
  {
    id: '9877',
    label: 'Wright State University',
    value: 'Wright State University'
  },
  {
    id: '9878',
    label: 'Wudanshan Taoist College',
    value: 'Wudanshan Taoist College'
  },
  {
    id: '9879',
    label: 'Wuhan Automobile Polytechnical University',
    value: 'Wuhan Automobile Polytechnical University'
  },
  {
    id: '9880',
    label: 'Wuhan Technical University of Surveying and Mapping',
    value: 'Wuhan Technical University of Surveying and Mapping'
  },
  {
    id: '9881',
    label: 'Wuhan Transportation University',
    value: 'Wuhan Transportation University'
  },
  {
    id: '9882',
    label: 'Wuhan University',
    value: 'Wuhan University'
  },
  {
    id: '9883',
    label: 'Wuhan University School of Medicine',
    value: 'Wuhan University School of Medicine'
  },
  {
    id: '9884',
    label: 'Wuhan University of Hydraulic and Electric Engineering',
    value: 'Wuhan University of Hydraulic and Electric Engineering'
  },
  {
    id: '9885',
    label: 'Wuhan University of Technology',
    value: 'Wuhan University of Technology'
  },
  {
    id: '9886',
    label: 'Wuxi University of Light Industry',
    value: 'Wuxi University of Light Industry'
  },
  {
    id: '9887',
    label: 'Wuyi University',
    value: 'Wuyi University'
  },
  {
    id: '9888',
    label: 'Wytheville Community College',
    value: 'Wytheville Community College'
  },
  {
    id: '9889',
    label: 'Xavier University',
    value: 'Xavier University'
  },
  {
    id: '9890',
    label: 'Xavier University',
    value: 'Xavier University'
  },
  {
    id: '9891',
    label: 'Xavier University of Louisiana',
    value: 'Xavier University of Louisiana'
  },
  {
    id: '9892',
    label: "Xi'an Academy of Fine Art",
    value: "Xi'an Academy of Fine Art"
  },
  {
    id: '9893',
    label: "Xi'an Eurasia University",
    value: "Xi'an Eurasia University"
  },
  {
    id: '9894',
    label: "Xi'an Highway University",
    value: "Xi'an Highway University"
  },
  {
    id: '9895',
    label: "Xi'an International Studies University",
    value: "Xi'an International Studies University"
  },
  {
    id: '9896',
    label: "Xi'an Jiaotong University",
    value: "Xi'an Jiaotong University"
  },
  {
    id: '9897',
    label: "Xi'an Jiaotong-Liverpool University",
    value: "Xi'an Jiaotong-Liverpool University"
  },
  {
    id: '9898',
    label: "Xi'an University of Architecture and Technology",
    value: "Xi'an University of Architecture and Technology"
  },
  {
    id: '9899',
    label: "Xi'an University of Electronic Science and Technology",
    value: "Xi'an University of Electronic Science and Technology"
  },
  {
    id: '9900',
    label: "Xi'an University of Technology",
    value: "Xi'an University of Technology"
  },
  {
    id: '9901',
    label: 'Xiamen University',
    value: 'Xiamen University'
  },
  {
    id: '9902',
    label: 'Xiangtan Normal University',
    value: 'Xiangtan Normal University'
  },
  {
    id: '9903',
    label: 'Xiangtan University',
    value: 'Xiangtan University'
  },
  {
    id: '9904',
    label: 'Xihua University',
    value: 'Xihua University'
  },
  {
    id: '9905',
    label: 'Xijiang University',
    value: 'Xijiang University'
  },
  {
    id: '9906',
    label: 'Xinghai Conservatory of Music',
    value: 'Xinghai Conservatory of Music'
  },
  {
    id: '9907',
    label: 'Xinjiang Agriculture University',
    value: 'Xinjiang Agriculture University'
  },
  {
    id: '9908',
    label: 'Xinjiang Normal University',
    value: 'Xinjiang Normal University'
  },
  {
    id: '9909',
    label: 'Xinjiang University',
    value: 'Xinjiang University'
  },
  {
    id: '9910',
    label: 'Xinjiang University of Finance and Economics',
    value: 'Xinjiang University of Finance and Economics'
  },
  {
    id: '9911',
    label: 'Xuzhou Normal University',
    value: 'Xuzhou Normal University'
  },
  {
    id: '9912',
    label: 'YPC-iTWEB College',
    value: 'YPC-iTWEB College'
  },
  {
    id: '9913',
    label: 'YTI Career Institute',
    value: 'YTI Career Institute'
  },
  {
    id: '9914',
    label: 'Yaba College of Technology',
    value: 'Yaba College of Technology'
  },
  {
    id: '9915',
    label: 'Yakima Valley Community College',
    value: 'Yakima Valley Community College'
  },
  {
    id: '9916',
    label: 'Yakutsk State University',
    value: 'Yakutsk State University'
  },
  {
    id: '9917',
    label: 'Yale University',
    value: 'Yale University'
  },
  {
    id: '9918',
    label: 'Yalova University',
    value: 'Yalova University'
  },
  {
    id: '9919',
    label: 'Yamagata University',
    value: 'Yamagata University'
  },
  {
    id: '9920',
    label: 'Yamaguchi Prefectural University',
    value: 'Yamaguchi Prefectural University'
  },
  {
    id: '9921',
    label: 'Yamaguchi University',
    value: 'Yamaguchi University'
  },
  {
    id: '9922',
    label: 'Yamanashi Gakuin University',
    value: 'Yamanashi Gakuin University'
  },
  {
    id: '9923',
    label: 'Yamanashi Medical University',
    value: 'Yamanashi Medical University'
  },
  {
    id: '9924',
    label: 'Yamanashi University',
    value: 'Yamanashi University'
  },
  {
    id: '9925',
    label: 'Yan Shan University',
    value: 'Yan Shan University'
  },
  {
    id: '9926',
    label: "Yan'an University",
    value: "Yan'an University"
  },
  {
    id: '9927',
    label: 'Yanbian University',
    value: 'Yanbian University'
  },
  {
    id: '9928',
    label: 'Yanbian University of Science and Technology',
    value: 'Yanbian University of Science and Technology'
  },
  {
    id: '9929',
    label: 'Yanbu Industrial College',
    value: 'Yanbu Industrial College'
  },
  {
    id: '9930',
    label: 'Yanbu Technical Institute',
    value: 'Yanbu Technical Institute'
  },
  {
    id: '9931',
    label: 'Yanbu University College',
    value: 'Yanbu University College'
  },
  {
    id: '9932',
    label: 'Yangtze Normal University',
    value: 'Yangtze Normal University'
  },
  {
    id: '9933',
    label: 'Yangtze University',
    value: 'Yangtze University'
  },
  {
    id: '9934',
    label: 'Yangzhou University',
    value: 'Yangzhou University'
  },
  {
    id: '9935',
    label: 'Yantai Education Institute & Yantai Television University',
    value: 'Yantai Education Institute & Yantai Television University'
  },
  {
    id: '9936',
    label: 'Yantai University',
    value: 'Yantai University'
  },
  {
    id: '9937',
    label: 'Yarmouk Private University',
    value: 'Yarmouk Private University'
  },
  {
    id: '9938',
    label: 'Yarmouk University',
    value: 'Yarmouk University'
  },
  {
    id: '9939',
    label: 'Yaroslavl International University of Business and New Technologies',
    value: 'Yaroslavl International University of Business and New Technologies'
  },
  {
    id: '9940',
    label: 'Yaroslavl State Medical Academy',
    value: 'Yaroslavl State Medical Academy'
  },
  {
    id: '9941',
    label: 'Yaroslavl State Pedagogical University',
    value: 'Yaroslavl State Pedagogical University'
  },
  {
    id: '9942',
    label: 'Yaroslavl State Technical University',
    value: 'Yaroslavl State Technical University'
  },
  {
    id: '9943',
    label: 'Yaroslavl State Theatre Institute',
    value: 'Yaroslavl State Theatre Institute'
  },
  {
    id: '9944',
    label: 'Yaroslavl State University',
    value: 'Yaroslavl State University'
  },
  {
    id: '9945',
    label: 'Yasar University',
    value: 'Yasar University'
  },
  {
    id: '9946',
    label: 'Yashawantrao Chavan Maharashtra Open University',
    value: 'Yashawantrao Chavan Maharashtra Open University'
  },
  {
    id: '9947',
    label: "Yasuda Women's University",
    value: "Yasuda Women's University"
  },
  {
    id: '9948',
    label: 'Yasuj University',
    value: 'Yasuj University'
  },
  {
    id: '9949',
    label: 'Yasuj University of Medical Sciences',
    value: 'Yasuj University of Medical Sciences'
  },
  {
    id: '9950',
    label: 'Yavapai College',
    value: 'Yavapai College'
  },
  {
    id: '9951',
    label: 'Yazd Medical University',
    value: 'Yazd Medical University'
  },
  {
    id: '9952',
    label: 'Yazd University',
    value: 'Yazd University'
  },
  {
    id: '9953',
    label: 'Yeditepe University',
    value: 'Yeditepe University'
  },
  {
    id: '9954',
    label: 'Yelets State University',
    value: 'Yelets State University'
  },
  {
    id: '9955',
    label: 'Yemenia University',
    value: 'Yemenia University'
  },
  {
    id: '9956',
    label: 'Yeni Yuzyil University',
    value: 'Yeni Yuzyil University'
  },
  {
    id: '9957',
    label: 'Yerevan Haibusak University',
    value: 'Yerevan Haibusak University'
  },
  {
    id: '9958',
    label: 'Yerevan State Medical University',
    value: 'Yerevan State Medical University'
  },
  {
    id: '9959',
    label: 'Yerevan State University',
    value: 'Yerevan State University'
  },
  {
    id: '9960',
    label: 'Yeshiva University',
    value: 'Yeshiva University'
  },
  {
    id: '9961',
    label: 'Yeungnam University',
    value: 'Yeungnam University'
  },
  {
    id: '9962',
    label: 'Yewon Arts University',
    value: 'Yewon Arts University'
  },
  {
    id: '9963',
    label: 'Yildirim Beyazit University',
    value: 'Yildirim Beyazit University'
  },
  {
    id: '9964',
    label: 'Yildiz Technical University',
    value: 'Yildiz Technical University'
  },
  {
    id: '9965',
    label: 'Yokkaichi University',
    value: 'Yokkaichi University'
  },
  {
    id: '9966',
    label: 'Yokohama City University',
    value: 'Yokohama City University'
  },
  {
    id: '9967',
    label: 'Yokohama College of Commerce',
    value: 'Yokohama College of Commerce'
  },
  {
    id: '9968',
    label: 'Yokohama National University',
    value: 'Yokohama National University'
  },
  {
    id: '9969',
    label: 'Yong In University',
    value: 'Yong In University'
  },
  {
    id: '9970',
    label: 'Yonsei University',
    value: 'Yonsei University'
  },
  {
    id: '9971',
    label: 'York College of Pennsylvania',
    value: 'York College of Pennsylvania'
  },
  {
    id: '9972',
    label: 'York County Community College',
    value: 'York County Community College'
  },
  {
    id: '9973',
    label: 'York St. John University',
    value: 'York St. John University'
  },
  {
    id: '9974',
    label: 'York Technical College',
    value: 'York Technical College'
  },
  {
    id: '9975',
    label: 'York University',
    value: 'York University'
  },
  {
    id: '9976',
    label: 'Yorker International University, Milano',
    value: 'Yorker International University, Milano'
  },
  {
    id: '9977',
    label: 'Yosemite Community College District',
    value: 'Yosemite Community College District'
  },
  {
    id: '9978',
    label: 'Yosu National University',
    value: 'Yosu National University'
  },
  {
    id: '9979',
    label: 'Young Harris College',
    value: 'Young Harris College'
  },
  {
    id: '9980',
    label: 'Youngsan University',
    value: 'Youngsan University'
  },
  {
    id: '9981',
    label: 'Youngsan University of Son Studies',
    value: 'Youngsan University of Son Studies'
  },
  {
    id: '9982',
    label: 'Youngstown State University',
    value: 'Youngstown State University'
  },
  {
    id: '9983',
    label: 'Yuan Ze University',
    value: 'Yuan Ze University'
  },
  {
    id: '9984',
    label: 'Yuba College',
    value: 'Yuba College'
  },
  {
    id: '9985',
    label: 'Yugra State University',
    value: 'Yugra State University'
  },
  {
    id: '9986',
    label: 'Yukon College',
    value: 'Yukon College'
  },
  {
    id: '9987',
    label: 'Yuksek ihtisas University',
    value: 'Yuksek ihtisas University'
  },
  {
    id: '9988',
    label: 'Yunnan Agriculture University',
    value: 'Yunnan Agriculture University'
  },
  {
    id: '9989',
    label: 'Yunnan Normal University',
    value: 'Yunnan Normal University'
  },
  {
    id: '9990',
    label: 'Yunnan University',
    value: 'Yunnan University'
  },
  {
    id: '9991',
    label: 'Yüzüncü Yil (Centennial) University',
    value: 'Yüzüncü Yil (Centennial) University'
  },
  {
    id: '9992',
    label: 'ZHAW - Zürcher Hochschule für Angewandte Wissenschaften',
    value: 'ZHAW - Zürcher Hochschule für Angewandte Wissenschaften'
  },
  {
    id: '9993',
    label: 'ZHdK - Zurich University of the Arts',
    value: 'ZHdK - Zurich University of the Arts'
  },
  {
    id: '9994',
    label: 'Zabol University',
    value: 'Zabol University'
  },
  {
    id: '9995',
    label: 'Zachodniopomorska School of Science and Engineering',
    value: 'Zachodniopomorska School of Science and Engineering'
  },
  {
    id: '9996',
    label: 'Zagazig University',
    value: 'Zagazig University'
  },
  {
    id: '9997',
    label: 'Zagreb School of Economics and Management',
    value: 'Zagreb School of Economics and Management'
  },
  {
    id: '9998',
    label: 'Zagreb University of Applied Sciences',
    value: 'Zagreb University of Applied Sciences'
  },
  {
    id: '9999',
    label: 'Zahedan University of Medical Sciences',
    value: 'Zahedan University of Medical Sciences'
  },
  {
    id: '10000',
    label: 'Zaman University',
    value: 'Zaman University'
  },
  {
    id: '10001',
    label: 'Zambian Open University',
    value: 'Zambian Open University'
  },
  {
    id: '10002',
    label: 'Zane State College',
    value: 'Zane State College'
  },
  {
    id: '10003',
    label: 'Zanjan Institute for Advanced Studies in Basic Sciences',
    value: 'Zanjan Institute for Advanced Studies in Basic Sciences'
  },
  {
    id: '10004',
    label: 'Zanjan University',
    value: 'Zanjan University'
  },
  {
    id: '10005',
    label: 'Zanjan University of Medical Sciences',
    value: 'Zanjan University of Medical Sciences'
  },
  {
    id: '10006',
    label: 'Zaporizhzhe National Technical University',
    value: 'Zaporizhzhe National Technical University'
  },
  {
    id: '10007',
    label: 'Zaporizhzhe National University',
    value: 'Zaporizhzhe National University'
  },
  {
    id: '10008',
    label: 'Zarka Private University',
    value: 'Zarka Private University'
  },
  {
    id: '10009',
    label: 'Zawiya Academy',
    value: 'Zawiya Academy'
  },
  {
    id: '10010',
    label: 'Zawiya University',
    value: 'Zawiya University'
  },
  {
    id: '10011',
    label: 'Zayed University',
    value: 'Zayed University'
  },
  {
    id: '10012',
    label: 'Zenith University College',
    value: 'Zenith University College'
  },
  {
    id: '10013',
    label: 'Zetech College',
    value: 'Zetech College'
  },
  {
    id: '10014',
    label: 'Zhanjiang Ocean University',
    value: 'Zhanjiang Ocean University'
  },
  {
    id: '10015',
    label: 'Zhejiang Forestry University',
    value: 'Zhejiang Forestry University'
  },
  {
    id: '10016',
    label: 'Zhejiang Gongshang University',
    value: 'Zhejiang Gongshang University'
  },
  {
    id: '10017',
    label: 'Zhejiang Normal University',
    value: 'Zhejiang Normal University'
  },
  {
    id: '10018',
    label: 'Zhejiang Sci-Tech University',
    value: 'Zhejiang Sci-Tech University'
  },
  {
    id: '10019',
    label: 'Zhejiang University',
    value: 'Zhejiang University'
  },
  {
    id: '10020',
    label: 'Zhejiang University of Technology',
    value: 'Zhejiang University of Technology'
  },
  {
    id: '10021',
    label: 'Zhengda Software College',
    value: 'Zhengda Software College'
  },
  {
    id: '10022',
    label: 'Zhengzhou Grain University',
    value: 'Zhengzhou Grain University'
  },
  {
    id: '10023',
    label: 'Zhengzhou University of Technology',
    value: 'Zhengzhou University of Technology'
  },
  {
    id: '10024',
    label: 'Zhetysu State University',
    value: 'Zhetysu State University'
  },
  {
    id: '10025',
    label: 'Zhezkazgan Baikonurov University',
    value: 'Zhezkazgan Baikonurov University'
  },
  {
    id: '10026',
    label: 'Zhongnan University of Economics and Law',
    value: 'Zhongnan University of Economics and Law'
  },
  {
    id: '10027',
    label: 'Zhongnan University of Finance and Economics',
    value: 'Zhongnan University of Finance and Economics'
  },
  {
    id: '10028',
    label: 'Zhongnan University of Technology',
    value: 'Zhongnan University of Technology'
  },
  {
    id: '10029',
    label: 'Zhongshan University',
    value: 'Zhongshan University'
  },
  {
    id: '10030',
    label: 'Zia-ud-Din University',
    value: 'Zia-ud-Din University'
  },
  {
    id: '10031',
    label: 'Zimbabwe Ezekiel Guti University',
    value: 'Zimbabwe Ezekiel Guti University'
  },
  {
    id: '10032',
    label: 'Zimbabwe Open University',
    value: 'Zimbabwe Open University'
  },
  {
    id: '10033',
    label: 'Zonguldak Karaelmas University',
    value: 'Zonguldak Karaelmas University'
  },
  {
    id: '10034',
    label: 'Zurich University of Applied Sciences Winterthur',
    value: 'Zurich University of Applied Sciences Winterthur'
  },
  {
    id: '10035',
    label: 'Zuyd University',
    value: 'Zuyd University'
  },
  {
    id: '10036',
    label: 'ifs University College',
    value: 'ifs University College'
  },
  {
    id: '10037',
    label: 'karamanoglu mehmet bey University',
    value: 'karamanoglu mehmet bey University'
  },
  {
    id: '10038',
    label: 'yale-NUS',
    value: 'yale-NUS'
  },
  {
    id: '10039',
    label: 'École Polytechnique',
    value: 'École Polytechnique'
  },
  {
    id: '10040',
    label: 'École Polytechnique de Montréal, Université de Montréal',
    value: 'École Polytechnique de Montréal, Université de Montréal'
  },
  {
    id: '10041',
    label: 'École de technologie supérieure, Université du Québec',
    value: 'École de technologie supérieure, Université du Québec'
  },
  {
    id: '10042',
    label: 'École des Hautes Études Commerciales',
    value: 'École des Hautes Études Commerciales'
  },
  {
    id: '10043',
    label: 'École des Hautes Études Commerciales (HEC Business School)',
    value: 'École des Hautes Études Commerciales (HEC Business School)'
  },
  {
    id: '10044',
    label: "École nationale d'administration publique, Université du Québec",
    value: "École nationale d'administration publique, Université du Québec"
  },
  {
    id: '10045',
    label: 'Óbuda University',
    value: 'Óbuda University'
  },
  {
    id: '10046',
    label: 'Örebro University',
    value: 'Örebro University'
  },
  {
    id: '10047',
    label: 'İzmir University of Economics',
    value: 'İzmir University of Economics'
  }
];
