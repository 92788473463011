import React, { useEffect } from 'react';
import { resetDropdownId } from 'src/store/common/common.slice';
import { useAppDispatch } from 'src/store/hooks';

export type TSubMenuModal = {
  children: React.ReactNode;
  setOpenSubMenuModal?: (value: boolean) => void | React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * Wrap this component where the behavior similar to drop down / sub menu cards etc. is required
 * Clicking anywhere outside will close the drop down/sub menu modal
 * This component ONLY adds a event listener on the Window, where clicking on window triggers a state change
 * using "setOpenSubMenuModal".
 * Event stopPropagation needs to be handled in the parent component.
 * Styles needs be handled in the parent component.
 * @property {React.ReactNode} children - The content to be displayed within the sub-menu modal.
 * @property {function} setOpenSubMenuModal - A function to control the visibility of the sub-menu modal.
 *  This function should accept a boolean value to indicate whether the modal should be open or closed.
 */
const SubMenuModal = ({ children, setOpenSubMenuModal }: TSubMenuModal) => {
  const dispatch = useAppDispatch();

  const closeSubMenuModal = () => {
    if (setOpenSubMenuModal) {
      setOpenSubMenuModal(false);
    }
    dispatch(resetDropdownId());
  };

  useEffect(() => {
    window.addEventListener('click', closeSubMenuModal);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('click', closeSubMenuModal);
    };
  }, []);

  return <>{children}</>;
};

export default SubMenuModal;
