import { HTMLAttributes, ReactNode, RefObject } from 'react';
import classNames from 'classnames';

import './card.scss';

export type TCard = {
  children: ReactNode;
  ref?: RefObject<HTMLDivElement>;
} & HTMLAttributes<HTMLDivElement>;

const Card = ({ children, className, ...rest }: TCard) => {
  return (
    <div className={classNames('card-component', className)} {...rest}>
      {children}
    </div>
  );
};

export default Card;
