import classNames from 'classnames';
import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

import './singleChevronIcon.scss';

export type TSingleChevronIcon = {
  direction?: 'up' | 'right' | 'down' | 'left';
} & TSvgIcon;

const SingleChevronIcon = ({
  direction = 'down',
  color = colors.neutral[800],
  className,
  ...rest
}: TSingleChevronIcon) => {
  return (
    <svg
      width="14"
      height="8"
      viewBox="0 0 14 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classNames('single-chevron-icon', `single-chevron-icon--${direction}`, className)}
      {...rest}
    >
      <path
        d="M1 1.0625L7 7.0625L13 1.0625"
        stroke="#535353"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SingleChevronIcon;
