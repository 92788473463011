import { Form, Formik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import Button from 'src/components/button/Button';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TCreateCommentRequest, TPost } from 'src/services/apiEndpoint.types.ts';
import { createComment } from 'src/store/socialMedia/socialMedia.slice';
import { TUserTypes } from 'src/constants/user.constants';

import './createComment.scss';
import createCommentValidationSchema from './createComment.validationSchema';

export type TCreateComment = {
  onClose: () => void;
  post: TPost;
  handleGetComments: () => void;
  viewerId: string;
  viewerType: TUserTypes;
};

export type TCreateCommentFormValues = {
  text: string;
};

// This component must know id and type of the viewer
const CreateComment = ({
  onClose,
  post,
  handleGetComments,
  viewerId,
  viewerType
}: TCreateComment) => {
  const initialValues: TCreateCommentFormValues = { text: '' };

  const dispatch = useAppDispatch();

  const createCommentLoader: boolean = useAppSelector(
    (store) => store.socialMedia.createCommentLoader
  );
  const { profile } = useAppSelector((store) => store.profile);
  const { myCompany, companyProfilePic } = useAppSelector((store) => store.company);

  const createCommentProfilePic = viewerType === 'User' ? profile?.profilePic : companyProfilePic;
  const createCommentName = viewerType === 'User' ? profile?.name : myCompany?.name;

  const handleSubmit = async (
    { text }: TCreateCommentFormValues,
    { resetForm }: { resetForm: () => void }
  ) => {
    // create comment api call
    const payload: TCreateCommentRequest = {
      userId: viewerId,
      userType: viewerType,
      postId: post?.post?.id,
      text,
      timestamp: Date.now(),
      commentId: uuidv4()
    };

    dispatch(createComment(payload)).then(() => {
      handleGetComments();
      resetForm();
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={createCommentValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, values }) => {
        return (
          <Form noValidate className="create-comment">
            <div className="create-comment__header">
              <ProfilePicture
                url={createCommentProfilePic || ''}
                fallback={createCommentName[0] || ''}
              />
              <InputField
                placeholder="Comment"
                id="text"
                variant={componentSizeVariantsEnum.SMALL}
                onChange={(e) => {
                  setFieldValue('text', e.target.value);
                }}
                value={values.text}
                errorMessage={errors.text}
                isTouched={touched.text}
              />
            </div>
            <div className="create-comment__buttons">
              <Button variant="secondary" size="small" onClick={onClose}>
                Cancel
              </Button>
              <Button variant="primary" size="small" type="submit" loading={createCommentLoader}>
                Comment
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default CreateComment;
