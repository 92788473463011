import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import { Button, Modal, Typography } from 'src/components/common';
import { useUrlParamValue } from 'src/hooks';

import './navigationModal.scss';

const NavigationModal = () => {
  // Hooks
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();

  // State
  const [isModalOpen, setIsModalOpen] = useState<boolean>(true);

  // Constants
  const programId = paramValue({ paramName: 'id' });

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleNavigateToLogin = () => {
    navigate(`/login?redirectUrl=apply-to-program-external?id=${programId}`);
  };

  const handleNavigateToSignUp = () => {
    navigate('/sign-up');
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      className="ape-navigation-modal"
    >
      <img src={hTractionLogoWithText} />
      <Typography>
        To apply to this program, please log in if you have an account or continue as an external
        applicant.
      </Typography>
      <Button size="large" variant="primary" onClick={handleNavigateToLogin}>
        Login
      </Button>
      <Button size="large" variant="secondary" onClick={handleCloseModal}>
        Continue as External Applicant
      </Button>
      <div className="ape-navigation-modal__footer">
        <Typography as="span" variant="body-2" fontWeight="medium">
          Don’t have an account?
        </Typography>
        <Typography
          as="span"
          variant="body-2"
          fontWeight="bold"
          className="ape-navigation-modal__footer__sign-up"
          onClick={handleNavigateToSignUp}
        >
          Sign Up
        </Typography>
      </div>
    </Modal>
  );
};

export default NavigationModal;
