import { FC, Ref } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Typography } from 'src/components/common';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';

import './editProblemsSolutions.scss';

import { TIdeaProblemsSolutionsData } from '../../ideaHub.types';
import { TEditIdeaFormValues } from '../IdeaDetails';

export type TEditProblemsSolutionsFormValues = TIdeaProblemsSolutionsData;

export type TEditProblemsSolutionsProps = TIdeaProblemsSolutionsData & {
  submitButtonRef: Ref<FormikProps<TEditIdeaFormValues>>;
  updateFormValues: ({ values }: { values: TEditIdeaFormValues }) => void;
  handleNext: () => void;
  goToNextTab: ({ fromEditDetails }: { fromEditDetails: boolean }) => void;
};

const EditProblemsSolutions: FC<TEditProblemsSolutionsProps> = ({
  identifiedProblem,
  solutionEffectiveness,
  uniqueValueProposition,
  submitButtonRef,
  updateFormValues,
  handleNext,
  goToNextTab
}: TEditProblemsSolutionsProps) => {
  const initialValues: TEditProblemsSolutionsFormValues = {
    identifiedProblem,
    solutionEffectiveness,
    uniqueValueProposition
  };

  const handleSubmit = (values: TEditProblemsSolutionsFormValues) => {
    updateFormValues({ values });
    goToNextTab({ fromEditDetails: false });
  };

  return (
    <div className="edit-problems-solutions">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="edit-idea-edit-details__heading"
      >
        Problems & Solutions
      </Typography>

      {/* Problems and solutions form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={submitButtonRef}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="edit-problems-solutions__form">
              {/* Identified Problem */}
              <TextAreaField
                label="Identified Problem"
                id="identifiedProblem"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Clearly state and elaborate on the problem your idea aims to address"
                onChange={(e) => {
                  setFieldValue('identifiedProblem', e.target.value);
                  updateFormValues({ values: { identifiedProblem: e.target.value } });
                }}
                value={values.identifiedProblem}
              />

              {/* Solution Effectiveness */}
              <TextAreaField
                label="Solution Effectiveness"
                id="solutionEffectiveness"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Explain how effective your proposed solution is in addressing the identified problem."
                onChange={(e) => {
                  setFieldValue('solutionEffectiveness', e.target.value);
                  updateFormValues({ values: { solutionEffectiveness: e.target.value } });
                }}
                value={values.solutionEffectiveness}
              />

              {/* Unique Value Proposition */}
              <TextAreaField
                label="Unique Value Proposition"
                id="uniqueValueProposition"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Describe the distinctive value your idea offers"
                onChange={(e) => {
                  setFieldValue('uniqueValueProposition', e.target.value);
                  updateFormValues({ values: { uniqueValueProposition: e.target.value } });
                }}
                value={values.uniqueValueProposition}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditProblemsSolutions.defaultProps = {
  identifiedProblem: '',
  solutionEffectiveness: '',
  uniqueValueProposition: ''
};

export default EditProblemsSolutions;
