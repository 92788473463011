import { FC, createContext } from 'react';
import { useUrlParamValue } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { Breadcrumbs, TBreadcrumb, TTabItem, Tabs } from 'src/components/common';

import './ideaSkeleton.scss';
import IdeaDetails from '../ideaDetails/IdeaDetails';
import {
  TIdeaStatus,
  TIdeaCollaborationType,
  TIdeaViewType,
  TIdeaSkeltonTabs,
  TGetIdea,
  TIdeaDocument,
  TEvaluatorResponse,
  TIdeaSharedFor
} from '../ideaHub.types';
import { ViewDocuments } from '../documents';
import useAllowedActions from '../ideaDetails/ideaDetailsActions/useAllowedActions';
import Discussion from '../discussion/Discussion';

export type TIdeaSkeletonProps = {
  breadCrumbs: TBreadcrumb[];
  viewType: TIdeaViewType;
  ideaStatus: TIdeaStatus;
  ideaCollaborationType: TIdeaCollaborationType;
  changeTab: (value: string) => void;
  ideaData: TGetIdea;
  documents: TIdeaDocument[];
  ratings: TEvaluatorResponse[];
  ideaSharedFor?: TIdeaSharedFor;
  ideaRatedByUser?: boolean;
};

export const IdeaViewTypeContext = createContext<TIdeaViewType>('emptyIdea');

const IdeaSkeleton: FC<TIdeaSkeletonProps> = ({
  viewType,
  ideaStatus,
  ideaCollaborationType,
  breadCrumbs,
  changeTab,
  ideaData,
  documents,
  ratings,
  ideaSharedFor,
  ideaRatedByUser
}: TIdeaSkeletonProps) => {
  // Hooks
  const navigate = useNavigate();
  const { paramValue } = useUrlParamValue();
  const { checkButtonsVisibility } = useAllowedActions({
    ideaStatus,
    ideaCollaborationType,
    currentIdeaDetailsSection: 'details',
    ideaSharedFor,
    viewType
  });

  const currentTab: TIdeaSkeltonTabs = paramValue({ paramName: 'tab' }) as TIdeaSkeltonTabs;

  const { showUploadButton, canUserEditIdea, canUserAccessDiscussion } = checkButtonsVisibility();

  // Constants
  const ideaCoFounderIds = ideaData?.collaborators
    ?.filter((user) => user?.IdeaCollaboratorMapping?.collaborationType === 'CoFounder')
    ?.map((cf) => cf?.id);

  const tabItems: TTabItem[] = [
    {
      key: 'ideaDetails',
      text: 'Idea Details',
      disabled: false,
      component: (
        <IdeaDetails
          ideaStatus={ideaStatus}
          ideaCollaborationType={ideaCollaborationType}
          ideaData={ideaData}
          ratings={ratings}
          ideaSharedFor={ideaSharedFor}
          ideaRatedByUser={ideaRatedByUser}
        />
      )
    },
    {
      key: 'document',
      text: 'Document',
      disabled: false,
      component: <ViewDocuments documents={documents} showUploadButton={showUploadButton} />
    }
  ];

  if (canUserAccessDiscussion) {
    tabItems.push({
      key: 'discussion',
      text: 'Discussion',
      disabled: false,
      component: (
        <Discussion
          ideaId={ideaData?.idea?.id}
          ideaOwnerId={ideaData?.idea?.ownerId}
          ideaCoFounderIds={ideaCoFounderIds}
        />
      )
    });
  }

  if (viewType === 'editIdea' && !canUserEditIdea) {
    navigate('/access-denied');
  }

  if (currentTab === 'discussion' && !canUserAccessDiscussion) {
    navigate('/access-denied');
  }

  return (
    <IdeaViewTypeContext.Provider value={viewType}>
      <div className="idea-skeleton">
        <Breadcrumbs breadcrumbs={breadCrumbs} />
        <Tabs activeItem={currentTab} onTabChange={changeTab} items={tabItems} />
      </div>
    </IdeaViewTypeContext.Provider>
  );
};

IdeaSkeleton.defaultProps = {
  breadCrumbs: [{ label: 'Idea Hub', url: '' }],
  viewType: 'emptyIdea'
};

export default IdeaSkeleton;
