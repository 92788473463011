import { useMemo } from 'react';
import { useUrlParamValue } from 'src/hooks';

import { TSearchQueryTypes } from './Filters';
import {
  TFilterDropdownOptions,
  companyFilters,
  connectionFilters,
  industryFilters,
  resultTypeFilters,
  postedInFilters,
  postedByFiltersAnnouncement,
  postedByFiltersPost
} from './filtersList';

const useFilterOptionsByType = () => {
  // Hooks
  const { paramValue } = useUrlParamValue();

  const resultType = paramValue({ paramName: 'resultType' });

  const filterOptionsByType: {
    label: string;
    value: string;
    type: TSearchQueryTypes;
    options: TFilterDropdownOptions[];
  }[] = useMemo(() => {
    if (resultType === 'all') {
      return [
        { label: 'All', value: 'all', type: 'resultType', options: [] },
        {
          label: 'Aspiring Entrepreneur & Entrepreneur',
          value: 'people',
          type: 'resultType',
          options: []
        },
        { label: 'Mentor & Investor', value: 'mentor-investor', type: 'resultType', options: [] },
        { label: 'Company', value: 'company', type: 'resultType', options: [] },
        { label: 'Announcement', value: 'announcement', type: 'resultType', options: [] },
        { label: 'Post', value: 'post', type: 'resultType', options: [] }
      ];
    } else if (resultType === 'people' || resultType === 'mentor-investor') {
      const resultTypeLabel =
        resultType === 'people' ? 'Aspiring Entrepreneur & Entrepreneur' : 'Mentor & Investor';
      return [
        {
          label: resultTypeLabel,
          value: resultType,
          type: 'resultType',
          options: resultTypeFilters
        },
        { label: 'Location', value: 'location', type: 'location', options: [] },
        {
          label: 'Connections',
          value: 'connections',
          type: 'connection',
          options: connectionFilters
        }
      ];
    } else if (resultType === 'company') {
      return [
        { label: 'Company', value: 'company', type: 'resultType', options: resultTypeFilters },
        { label: 'Location', value: 'location', type: 'location', options: [] },
        {
          label: 'Company Type',
          value: 'company-type',
          type: 'company',
          options: companyFilters
        },
        { label: 'Industry', value: 'industry', type: 'industry', options: industryFilters }
      ];
    } else if (resultType === 'announcement') {
      return [
        {
          label: 'Announcement',
          value: resultType,
          type: 'resultType',
          options: resultTypeFilters
        },
        { label: 'Posted in', value: 'posted-in', type: 'postedIn', options: postedInFilters },
        {
          label: 'Posted by',
          value: 'posted-by',
          type: 'postedBy',
          options: postedByFiltersAnnouncement
        }
      ];
    } else if (resultType === 'post') {
      return [
        {
          label: 'Post',
          value: resultType,
          type: 'resultType',
          options: resultTypeFilters
        },
        { label: 'Posted in', value: 'posted-in', type: 'postedIn', options: postedInFilters },
        { label: 'Posted by', value: 'posted-by', type: 'postedBy', options: postedByFiltersPost }
      ];
    }
    return [];
  }, [resultType]);

  return filterOptionsByType;
};

export default useFilterOptionsByType;
