import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectCardRadio, {
  TSelectCardRadioButton
} from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  preSelectUserType,
  resetRedirection,
  saveServiceProviderStartupsOnboardingInfo
} from 'src/store/auth/auth.slice';
import { Role } from 'src/constants/user.constants';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { useGetIdentity, useSignOut } from 'src/hooks';

import './onboardingUserType.scss';

const options: TSelectCardRadioButton[] = [
  {
    label: 'Aspiring Entrepreneur',
    value: 'Aspiring Entrepreneur',
    id: 'aspiring-entrepreneur'
  },
  { label: 'Entrepreneur', value: 'Entrepreneur', id: 'entrepreneur' }
  // {
  //   label: 'Service providers/Startups',
  //   value: 'Service providers/Startup',
  //   id: 'service-providers-startup'
  // }
];

const OnboardingUserType = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');

  /*
   ** Hooks
   */
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const signOut = useSignOut();

  const { preselectedUserType, shouldRedirectToDashboard, loading } = useAppSelector(
    (store) => store.auth
  );
  const { profile } = useAppSelector((store) => store.profile);

  const { authenticatedEmail, authenticatedId } = getIdentities();

  // Constants
  const isMentorOrInvestor =
    profile?.role === 'Mentor' ||
    profile?.role === 'Investor' ||
    profile?.role === 'Mentor and Investor';

  const handleContinue = () => {
    if (!preselectedUserType) {
      setErrorMessage('Please select a role from the above options to continue');
      /* eslint-disable no-useless-return */
      return;
    }

    if (preselectedUserType === Role.SERVICE_PROVIDERS_STARTUP) {
      dispatch(
        saveServiceProviderStartupsOnboardingInfo({
          id: authenticatedId,
          email: authenticatedEmail,
          role: Role.SERVICE_PROVIDERS_STARTUP
        })
      );
    } else {
      navigate(`/tell-me-more?userType=${preselectedUserType}`);
    }
  };

  const handleSignOut = () => {
    signOut();
  };

  useEffect(() => {
    if (shouldRedirectToDashboard) navigate('/dashboard');

    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToDashboard]);

  useEffect(() => {
    if (!profile?.onboardingComplete && isMentorOrInvestor) {
      navigate('/tell-me-more?userType=MENTOR_INVESTOR');
    } else if (profile.onboardingComplete) {
      navigate('/dashboard');
    }
  }, [profile.onboardingComplete, profile?.role]);

  useEffect(() => {
    if (preselectedUserType) {
      setErrorMessage('');
    }
  }, [preselectedUserType]);

  return (
    <div className="onboarding-user-type">
      <BackNavigation text="Back" onClick={handleSignOut} />
      <Typography
        as="h3"
        variant="heading-3"
        fontWeight="semiBold"
        className="onboarding-user-type__heading"
      >
        Hello,{' '}
        <Typography
          as="span"
          variant="heading-3"
          fontWeight="semiBold"
          className="onboarding-user-type__heading--highlight"
        >
          {profile.name}
        </Typography>
        !<br></br>Let’s hear it from{' '}
        <Typography
          as="span"
          variant="heading-3"
          fontWeight="semiBold"
          className="onboarding-user-type__heading--highlight"
        >
          you
        </Typography>
        .
      </Typography>
      <div className="onboarding-user-type__select-type">
        <Typography
          as="p"
          variant="caption"
          fontWeight="semiBold"
          className="onboarding-user-type__select-type__label"
        >
          I am{' '}
          <Typography as="span" variant="caption" fontWeight="regular">
            &#40;Select any one&#41;
          </Typography>
        </Typography>

        {/* Select user type */}
        <SelectCardRadio
          options={options}
          selectedValue={preselectedUserType}
          name="user-type"
          variant={componentSizeVariantsEnum.MEDIUM}
          onChange={(e) => {
            dispatch(preSelectUserType(e.target.value));
          }}
          className="onboarding-user-type__select-type__options"
        />
        {errorMessage && <ErrorMessage message={errorMessage} />}
      </div>
      <Button
        variant="primary"
        size="small"
        type="button"
        loading={loading}
        onClick={handleContinue}
      >
        Continue
      </Button>
    </div>
  );
};

export default OnboardingUserType;
