import { useEffect, useState } from 'react';
import {
  InfiniteScroll,
  Loader,
  Modal,
  Separator,
  SubMenuModal,
  Typography
} from 'src/components/common';
import { SearchIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useDebounce from 'src/hooks/useDebounce/useDebounce';
import {
  TEditProgramMembersRequest,
  TGetProgramMembersRequest,
  TProgramKeyMember
} from 'src/services/apiEndpoint.types.ts';
import { StorageUtils } from 'src/utils';
import { assignSubMenuModalId } from 'src/store/common/common.slice';
import {
  editProgramMembers,
  getProgramKeyMembers,
  incrementProgramKeyMembersPage,
  resetProgramKeyMembers
} from 'src/store/program/program.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';

import './manageProgramMembers.scss';
import { TManageProgramMembers } from './manageProgramMembers.types';

import SearchProgramMemberItem from '../searchProgramMemberItem/SearchProgramMemberItem';
import ProgramMemberListItem from '../programMemberListItem/ProgramMemberListItem';

const searchUsersResultModalId = 'searchUsersResultModalId';

const ManageProgramMembers = ({
  isModalOpen,
  onModalClose,
  fetchProgramMembers
}: TManageProgramMembers) => {
  const [searchInput, setSearchInput] = useState<string>('');
  const [selectedProgramMembers, setSelectedProgramMembers] = useState<TProgramKeyMember[]>([]);

  const companyId = StorageUtils.get('companyId') as string;
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { dropdownId, loading }: { dropdownId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );
  const {
    programKeyMembersPageNumber,
    programKeyMembersPerPage,
    moreProgramKeyMembersLeft,
    programKeyMembers
  } = useAppSelector((store) => store.program);
  const { getIdentities } = useGetIdentity();

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();
  const programId = paramValue({ paramName: 'id' }) as unknown as string;

  const openSearchUsersResultModalId =
    dropdownId === searchUsersResultModalId && (programKeyMembers?.length > 0 || loading);

  const fetchKeyMembers = ({ resetUsers = false }: { resetUsers: boolean }) => {
    if (resetUsers) {
      dispatch(resetProgramKeyMembers());
    }
    if (searchInput) {
      const payload: TGetProgramMembersRequest = {
        filterString: searchInput,
        offset: programKeyMembersPageNumber,
        limit: programKeyMembersPerPage,
        companyId,
        programId
      };
      dispatch(getProgramKeyMembers(payload));
    } else {
      dispatch(resetProgramKeyMembers());
    }
  };

  const updateSelectedUsers = ({ userId }: { userId: string }) => {
    setSelectedProgramMembers((prev) => {
      const currentList: TProgramKeyMember[] = JSON.parse(JSON.stringify(prev));
      const userIndex: number = currentList.findIndex(
        (member: TProgramKeyMember) => member.id === userId
      );

      if (userIndex > -1) {
        currentList.splice(userIndex, 1);
      } else {
        const selectUser = programKeyMembers.find(
          (member: TProgramKeyMember) => member.id === userId
        );
        if (selectUser) {
          currentList.push(selectUser);
        }
      }

      return currentList;
    });
  };

  const handleAddMembers = () => {
    const payload: TEditProgramMembersRequest = {
      programId,
      usersToAdd: selectedProgramMembers.map((item) => item?.id),
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(editProgramMembers(payload)).then(() => {
      fetchProgramMembers();
    });
  };

  useDebounce({
    func: () => fetchKeyMembers({ resetUsers: true }),
    delay: 500,
    dependency: searchInput
  });

  useEffect(() => {
    fetchKeyMembers({ resetUsers: false });
  }, [programKeyMembersPageNumber]);

  const loadMoreItems = () => {
    if (moreProgramKeyMembersLeft && !loading) {
      dispatch(incrementProgramKeyMembersPage(1));
    }
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      className="manage-program-members-modal"
      title="Manage program Members"
    >
      <div className="manage-program-members">
        <div className="manage-program-members__search-users">
          <InputField
            label="Select and search key members to give access to program"
            id="email"
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Search user by name, email address"
            startIcon={<SearchIcon />}
            type="text"
            value={searchInput}
            onChange={(e) => {
              setSearchInput(e.target.value);
            }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(assignSubMenuModalId(searchUsersResultModalId));
            }}
          />
          {openSearchUsersResultModalId && (
            <SubMenuModal>
              <div
                className="manage-program-members__search-users__container"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Typography as="p" variant="caption" fontWeight="semiBold">
                  Search for “{searchInput}”
                </Typography>
                <InfiniteScroll
                  className="manage-program-members__search-users__container__result"
                  onScrollEnd={loadMoreItems}
                  loading={loading}
                  showLoader={false}
                  moreItemsLeft={moreProgramKeyMembersLeft}
                >
                  {programKeyMembers?.length > 0 &&
                    programKeyMembers.map(
                      ({ name = '', role = '', id, profilePic = '' }: TProgramKeyMember, index) => {
                        const selectIndex = selectedProgramMembers.findIndex(
                          (user) => user.id === id
                        );
                        return (
                          <SearchProgramMemberItem
                            key={index}
                            userId={id}
                            profilePic={profilePic}
                            name={name}
                            role={role}
                            updateSelectedUsers={updateSelectedUsers}
                            isUserSelected={selectIndex > -1}
                          />
                        );
                      }
                    )}
                </InfiniteScroll>

                {loading && <Loader />}
              </div>
            </SubMenuModal>
          )}
        </div>
        <Separator />
        <Typography as="p" variant="caption" fontWeight="regular">
          Below is the list of selected key members. Manage users by selecting or deselecting them
          from the list to add or remove them.
        </Typography>
        <div className="manage-program-members__selected-users">
          {selectedProgramMembers.map(
            (
              { name = '', role = '', email = '', id, profilePic = '' }: TProgramKeyMember,
              index
            ) => {
              return (
                <ProgramMemberListItem
                  key={index}
                  profilePic={profilePic}
                  name={name}
                  role={role}
                  email={email}
                  handleRemoveUser={() => updateSelectedUsers({ userId: id })}
                />
              );
            }
          )}
        </div>
        <div className="manage-program-members__buttons">
          <Button variant="secondary" size="small" onClick={onModalClose}>
            Close
          </Button>
          <Button variant="primary" size="small" loading={loading} onClick={handleAddMembers}>
            Save Changes
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ManageProgramMembers;
