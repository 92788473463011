import { Form, Formik } from 'formik';
import { Modal } from 'src/components/common';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Services } from 'src/constants/user.constants';
import { enumToJson } from 'src/utils';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { componentSizeVariantsEnum } from 'src/constants';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { TService } from 'src/services/apiEndpoint.types.ts/company/createCompany.endpoint.types';
import Button from 'src/components/button/Button';
import { useAppSelector } from 'src/store/hooks';

import addEditServiceValidationSchema from './addEditService.validationSchema';
import './addEditService.scss';

export type TAddEditServiceModalMode = 'Add' | 'Edit';

export type TAddEditService = {
  mode: TAddEditServiceModalMode;
  type?: string;
  description?: string;
  openServiceModal: boolean;
  closeServiceModal: () => void;
  handleAddService: ({ values }: { values: TService }) => void;
  handleEditService: ({ values }: { values: TService }) => void;
};

const AddEditService = ({
  mode,
  type = '',
  description = '',
  openServiceModal,
  closeServiceModal,
  handleAddService,
  handleEditService
}: TAddEditService) => {
  const initialValues: TService = {
    type,
    description
  };
  const serviceOptions: TSelectCardRadioButton[] = enumToJson({
    enumData: Services
  });

  const loading: boolean = useAppSelector<boolean>((store) => store.common.loading);

  const handleSubmit = (values: TService) => {
    if (mode === 'Add') {
      handleAddService({ values });
    } else if (mode === 'Edit') {
      handleEditService({ values });
    }
  };

  return (
    <Modal
      isModalOpen={openServiceModal}
      onModalClose={closeServiceModal}
      title={`${mode} Service`}
      className="create-company-add-edit-service"
    >
      <Formik
        initialValues={initialValues}
        validationSchema={addEditServiceValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form className="create-company-add-edit-service__form">
              {/*
               ** Service type
               */}
              <SelectField
                label="Select the service type"
                required
                placeholder="Service type"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={values.type}
                options={serviceOptions}
                id="type"
                onSelection={({ value }) => {
                  setFieldValue('type', value as unknown as string);
                }}
                errorMessage={errors.type}
                isTouched={touched.type}
              ></SelectField>

              {/*
               ** Service description
               */}
              <TextAreaField
                id="description"
                required
                label="Provide service description"
                placeholder="Description"
                onChange={(e) => {
                  setFieldValue('description', e.target.value);
                }}
                value={values.description}
                isTouched={touched.description}
                errorMessage={errors.description}
              />

              <div className="create-company-add-edit-service__form__buttons">
                <Button variant="secondary" type="button" size="small" onClick={closeServiceModal}>
                  Cancel
                </Button>
                <Button variant="primary" type="submit" size="small" loading={loading}>
                  {mode === 'Add' ? 'Add' : 'Save'}
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default AddEditService;
