import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Industries } from 'src/constants/user.constants';
import { enumToJson } from 'src/utils';

import { TSearchQueryTypes } from './Filters';

export type TFilterDropdownOptions = TSelectCardRadioButton & { type: TSearchQueryTypes };

const resultTypeFilters: TFilterDropdownOptions[] = [
  { id: 'all', label: 'All', value: 'all', type: 'resultType' },
  {
    id: 'people',
    label: 'Aspiring Entrepreneur & Entrepreneur',
    value: 'people',
    type: 'resultType'
  },
  {
    id: 'mentor-investor',
    label: 'Mentor & Investor',
    value: 'mentor-investor',
    type: 'resultType'
  },
  { id: 'company', label: 'Company', value: 'company', type: 'resultType' },
  { id: 'announcement', label: 'Announcement', value: 'announcement', type: 'resultType' },
  { id: 'post', label: 'Post', value: 'post', type: 'resultType' }
];

const connectionFilters: TFilterDropdownOptions[] = [
  { id: 'all', label: 'All', value: 'all', type: 'connection' },
  { id: 'followers', label: 'Followers', value: 'followers', type: 'connection' },
  { id: 'following', label: 'Following', value: 'following', type: 'connection' }
];

const companyFilters: TFilterDropdownOptions[] = [
  { id: 'startup', label: 'Startup', value: 'Start up', type: 'company' },
  { id: 'service-provider', label: 'Service provider', value: 'Service Provider', type: 'company' },
  { id: 'incubators', label: 'Incubators', value: 'Incubator', type: 'company' },
  { id: 'accelerators', label: 'Accelerators', value: 'Accelerator', type: 'company' }
];

const postedInFilters: TFilterDropdownOptions[] = [
  { id: 'day', label: 'Last 24 hours', value: '24H', type: 'postedIn' },
  { id: 'week', label: 'Last Week', value: 'WEEK', type: 'postedIn' },
  { id: 'month', label: 'Last month', value: 'MONTH', type: 'postedIn' },
  { id: 'year', label: 'Last year', value: 'YEAR', type: 'postedIn' }
];

const postedByFiltersAnnouncement: TFilterDropdownOptions[] = [
  { id: 'incubators', label: 'Incubators', value: 'Incubator', type: 'postedBy' },
  { id: 'accelerators', label: 'Accelerators', value: 'Accelerator', type: 'postedBy' },
  { id: 'startup', label: 'Startup', value: 'Start up', type: 'postedBy' },
  { id: 'service-provider', label: 'Service provider', value: 'Service Provider', type: 'postedBy' }
];

const postedByFiltersPost: TFilterDropdownOptions[] = [
  {
    label: 'Aspiring Entrepreneur',
    value: 'Aspiring Entrepreneur',
    id: 'aspiring-entrepreneur',
    type: 'postedBy'
  },
  { label: 'Entrepreneur', value: 'Entrepreneur', id: 'entrepreneur', type: 'postedBy' },
  { label: 'Investors', value: 'Investor', id: 'investors', type: 'postedBy' },
  { label: 'Mentors', value: 'Mentor', id: 'mentors', type: 'postedBy' },
  { id: 'incubators', label: 'Incubators', value: 'Incubator', type: 'postedBy' },
  { id: 'accelerators', label: 'Accelerators', value: 'Accelerator', type: 'postedBy' },
  { id: 'startup', label: 'Startup', value: 'Start up', type: 'postedBy' },
  { id: 'service-provider', label: 'Service provider', value: 'Service Provider', type: 'postedBy' }
];

const industryFilters: TFilterDropdownOptions[] = enumToJson({
  enumData: Industries
}).map((item) => ({ ...item, type: 'industry' }));

export {
  companyFilters,
  connectionFilters,
  industryFilters,
  resultTypeFilters,
  postedInFilters,
  postedByFiltersAnnouncement,
  postedByFiltersPost
};
