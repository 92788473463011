import TextAddress from 'src/components/common/formFields/textAddress/TextAddress';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { useGetFormikField } from 'src/hooks';

import './previewField.scss';
import { TPreviewFieldProps } from './previewField.types';

const Address = ({ id, label, validations, required }: TPreviewFieldProps) => {
  // Hooks
  const { getFormikField } = useGetFormikField();

  const [field, fieldMetaData, helpers] = getFormikField({ id: id || '' });

  return (
    <div key={id} className="dynamic-field">
      <Label required={required}>{label}</Label>
      <div className="dynamic-field__field">
        {' '}
        <TextAddress
          id={id}
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Location"
          type="text"
          {...(validations?.maxCharacters < 3000 && { textLengthLimit: validations.maxCharacters })}
          value={field?.value}
          onChange={(e) => {
            helpers?.setValue(e.target.value);
          }}
          isTouched={fieldMetaData?.touched}
          errorMessage={fieldMetaData?.error}
        />
      </div>
    </div>
  );
};

export default Address;
