import Button from 'src/components/button/Button';
import { Card, Typography } from 'src/components/common';
import { colors } from 'src/constants';
import { formatDate } from 'src/utils';
import { useAppSelector } from 'src/store/hooks';

import { TProgramClosedProps } from './programClosed.types';
import './programClosed.scss';

const ProgramClosed = ({ title, endDate, companyName }: TProgramClosedProps) => {
  // Hooks
  const viewCompany = useAppSelector((store) => store.company.viewCompany);

  return (
    <Card className="program-closed">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        style={{ color: colors.neutral[1000] }}
      >
        Application Form Closed!
      </Typography>{' '}
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1000] }}
      >
        The application period for {title} has ended, and the form is now closed.
      </Typography>{' '}
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1000] }}
      >
        The deadline was {formatDate({ dateString: endDate })}, and {viewCompany?.name || 'Company'}{' '}
        is no longer accepting new applications.
      </Typography>{' '}
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1000] }}
      >
        For those who applied, {viewCompany?.name || 'Company'} will be reviewing the submitted
        applications and will be in touch soon.
      </Typography>{' '}
      <Button size="small" variant="tertiary">
        Application Closed!
      </Button>
    </Card>
  );
};

export default ProgramClosed;
