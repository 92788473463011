import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useUpdateFormValue, useValidateErrors } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { EyeOffIcon, EyeIcon, PasswordIcon } from 'src/components/common/common.icons';
import { forceChangePassword } from 'src/store/auth/auth.slice';
import { toggleTouchedFields } from 'src/utils';

import './forceChangePassword.scss';
import forgotPasswordValidationSchema from './forceChangePassword.validationSchema';
import { forgotPasswordFormFields } from './forgotPassword.constants';

export type TForceChangePasswordFormValues = {
  newPassword: string;
  confirmPassword: string;
};

const allFieldsUntouched = toggleTouchedFields({
  fields: forgotPasswordFormFields,
  isTouched: false
});
const allFieldsTouched = toggleTouchedFields({ fields: forgotPasswordFormFields, isTouched: true });

const ForceChangePassword = () => {
  const [formValues, setFormValues] =
    useState<TForceChangePasswordFormValues>(forgotPasswordFormFields);
  const [touchedFormFields, setTouchedFormFields] =
    useState<Record<keyof TForceChangePasswordFormValues, boolean>>(allFieldsUntouched);
  const [errors, setErrors] =
    useState<Record<keyof TForceChangePasswordFormValues, string>>(forgotPasswordFormFields);
  const [maskPassword, setMaskPassword] = useState<{
    newPassword: boolean;
    confirmPassword: boolean;
  }>({ newPassword: true, confirmPassword: true });

  // Hooks
  const { updateFormValue } = useUpdateFormValue();
  const { validateErrors } = useValidateErrors();

  const {
    loading,
    forceChangePasswordEmail,
    forceChangePasswordToken,
    forceChangePasswordChallengeName
  } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();
  const updateForm = ({ fieldName, value }: { fieldName: string; value: string }) => {
    const setFormValue = setFormValues as Dispatch<SetStateAction<Record<string, string>>>;
    updateFormValue({ fieldName, value, setFormValue });
  };

  const validateForm = async () => {
    return await validateErrors({
      validationSchema: forgotPasswordValidationSchema,
      formValues,
      errorsInitialState: forgotPasswordFormFields,
      setErrors
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouchedFormFields(allFieldsTouched);

    const validationErrors = await validateForm();

    if (validationErrors?.length) {
      return validateErrors;
    }

    // forgot password api call
    dispatch(
      forceChangePassword({
        email: forceChangePasswordEmail,
        newPassword: formValues.newPassword,
        sessionToken: forceChangePasswordToken,
        challengeName: forceChangePasswordChallengeName
      })
    );
  };

  useEffect(() => {
    validateForm();
  }, [JSON.stringify(formValues)]);

  return (
    <div className="force-change-password">
      <BackNavigation text="Back to login" route="/login" />
      <div className="force-change-password__header">
        <Typography
          as="h3"
          variant="heading-3"
          fontWeight="semiBold"
          className="force-change-password__header__heading"
        >
          Set New Password
        </Typography>
        <Typography
          as="p"
          variant="body-2"
          fontWeight="regular"
          className="force-change-password__header__caption"
        >
          Reimagine. Reset...Secure your path forward
        </Typography>
      </div>
      {/* Form starts */}
      <form className="force-change-password__form" onSubmit={handleSubmit} noValidate>
        {/* Enter new password */}
        <InputField
          label="Enter New Password"
          id="newPassword"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="New Password"
          startIcon={<PasswordIcon />}
          endIcon={maskPassword.newPassword ? <EyeOffIcon /> : <EyeIcon />}
          maskField={maskPassword.newPassword}
          endIconClickHandler={() =>
            setMaskPassword((prev) => ({ ...prev, newPassword: !prev.newPassword }))
          }
          onChange={(e) => {
            updateForm({ fieldName: 'newPassword', value: e.target.value });
          }}
          errorMessage={errors.newPassword}
          isTouched={touchedFormFields.newPassword}
        />

        {/* Confirm password */}
        <InputField
          label="Confirm Password"
          id="confirmPassword"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="Confirm Password"
          startIcon={<PasswordIcon />}
          endIcon={maskPassword.confirmPassword ? <EyeOffIcon /> : <EyeIcon />}
          maskField={maskPassword.confirmPassword}
          endIconClickHandler={() =>
            setMaskPassword((prev) => ({ ...prev, confirmPassword: !prev.confirmPassword }))
          }
          onChange={(e) => {
            updateForm({ fieldName: 'confirmPassword', value: e.target.value });
          }}
          errorMessage={errors.confirmPassword}
          isTouched={touchedFormFields.confirmPassword}
        />

        <Button variant="primary" size="small" type="submit" loading={loading}>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Continue
          </Typography>
        </Button>
      </form>
      {/* Form ends */}
    </div>
  );
};

export default ForceChangePassword;
