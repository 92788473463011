import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ArchiveIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="23"
      height="21"
      viewBox="0 0 23 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M3.5 6.4341C3.33599 6.42986 3.2169 6.42037 3.10982 6.39907C2.31644 6.24126 1.69624 5.62106 1.53843 4.82768C1.5 4.63448 1.5 4.40216 1.5 3.9375C1.5 3.47284 1.5 3.24052 1.53843 3.04732C1.69624 2.25394 2.31644 1.63374 3.10982 1.47593C3.30302 1.4375 3.53534 1.4375 4 1.4375H19C19.4647 1.4375 19.697 1.4375 19.8902 1.47593C20.6836 1.63374 21.3038 2.25394 21.4616 3.04732C21.5 3.24052 21.5 3.47284 21.5 3.9375C21.5 4.40216 21.5 4.63448 21.4616 4.82768C21.3038 5.62106 20.6836 6.24126 19.8902 6.39907C19.7831 6.42037 19.664 6.42986 19.5 6.4341M9.5 11.4375H13.5M3.5 6.4375H19.5V14.6375C19.5 16.3177 19.5 17.1577 19.173 17.7995C18.8854 18.364 18.4265 18.8229 17.862 19.1105C17.2202 19.4375 16.3802 19.4375 14.7 19.4375H8.3C6.61984 19.4375 5.77976 19.4375 5.13803 19.1105C4.57354 18.8229 4.1146 18.364 3.82698 17.7995C3.5 17.1577 3.5 16.3177 3.5 14.6375V6.4375Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArchiveIcon;
