import { Form, Formik } from 'formik';
import { Card, Typography } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { TCreateCompanyFormValues } from 'src/pages';
import { useAppSelector } from 'src/store/hooks';
import { TCompanyLogoData } from 'src/pages/createCompany/CreateCompany';
import SearchAndSelect from 'src/components/common/formFields/searchAndSelect/SearchAndSelect';
import { useGetPlaces } from 'src/hooks';

import './contactDetails.scss';
import contactDetailsValidationSchema from './contactDetails.validationSchema';
export type TContactDetailsFormValues = {
  contactNumber?: number;
  companyEmailAddress?: string;
  contactUsUrl?: string;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  state?: string;
  city?: string;
  cityQuery?: string;
  zipCode?: string;
};

export type TContactDetails = {
  handleCreateCompany: ({ values }: { values: Partial<TContactDetailsFormValues> }) => void;
  createCompanyFormValues: Partial<TCreateCompanyFormValues>;
  companyLogoData: TCompanyLogoData;
  updateCreateCompanyFormValues: ({
    values
  }: {
    values: Partial<TContactDetailsFormValues>;
  }) => void;
  showCancelButton: boolean;
  handleCancel: () => void;
};

const ContactDetails = ({
  createCompanyFormValues,
  handleCreateCompany,
  updateCreateCompanyFormValues,
  showCancelButton,
  handleCancel
}: TContactDetails) => {
  const initialValues: TContactDetailsFormValues = {
    contactNumber: createCompanyFormValues.contactNumber,
    companyEmailAddress: createCompanyFormValues.companyEmailAddress || '',
    contactUsUrl: createCompanyFormValues.contactUsUrl || '',
    addressLine1: createCompanyFormValues.addressLine1 || '',
    addressLine2: createCompanyFormValues.addressLine2 || '',
    country: createCompanyFormValues.country || '',
    state: createCompanyFormValues.state || '',
    city: createCompanyFormValues.city || '',
    zipCode: createCompanyFormValues.zipCode
  };

  // Hooks
  const { getPlaces, places } = useGetPlaces({ types: ['(cities)'] });

  const loading: boolean = useAppSelector((store) => store.common.loading);

  const handleSubmit = (values: TContactDetailsFormValues) => {
    handleCreateCompany({ values });
  };

  return (
    <Card className="create-company-company-details">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        Contact Details
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={contactDetailsValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form className="create-company-page-details__form">
              {/*
               ** Contact Number
               */}
              <InputField
                label="Contact Number"
                id="contactNumber"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Contact Number"
                type="number"
                onBlur={() => {
                  updateCreateCompanyFormValues({ values });
                }}
              />

              {/*
               ** Email Address
               */}
              <InputField
                label="Email Address"
                id="companyEmailAddress"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                type="text"
                onBlur={() => {
                  updateCreateCompanyFormValues({ values });
                }}
              />

              {/*
               ** Contact us url
               */}
              <InputField
                label="Enter the contact us URL"
                id="contactUsUrl"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="URL"
                type="text"
                onBlur={() => {
                  updateCreateCompanyFormValues({ values });
                }}
              />

              <Typography as="p" variant="body-2" fontWeight="semiBold">
                Address
              </Typography>

              {/*
               ** Address line 1
               */}
              <InputField
                label="Address line 1"
                id="addressLine1"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Address line 1"
                type="text"
                onBlur={() => {
                  updateCreateCompanyFormValues({ values });
                }}
              />

              {/*
               ** Address line 2
               */}
              <InputField
                label="Address line 2"
                id="addressLine2"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Address line 2"
                type="text"
                onBlur={() => {
                  updateCreateCompanyFormValues({ values });
                }}
              />

              {/*
               ** Address fields grid
               */}

              <div className="create-company-company-details__form__grid">
                <InputField
                  label="Country"
                  id="country"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Country"
                  type="text"
                  onBlur={() => {
                    updateCreateCompanyFormValues({ values });
                  }}
                />
                <InputField
                  label="State"
                  id="state"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="State"
                  type="text"
                  onBlur={() => {
                    updateCreateCompanyFormValues({ values });
                  }}
                />
                <SearchAndSelect
                  label="City"
                  placeholder="City"
                  variant={componentSizeVariantsEnum.SMALL}
                  selectedValue={values.cityQuery || ''}
                  options={places}
                  onSelection={({ value }) => {
                    setFieldValue('city', value as unknown as string);
                    setFieldValue('cityQuery', value as unknown as string);
                  }}
                  onInputChange={(e) => {
                    const query = e.target.value;
                    setFieldValue('cityQuery', query as unknown as string);
                    getPlaces({
                      input: query.toString()
                    });
                  }}
                  errorMessage={errors.city}
                  isTouched={touched.city}
                />
                <InputField
                  label="Zip Code"
                  id="zipCode"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Zip Code"
                  type="number"
                  onBlur={() => {
                    updateCreateCompanyFormValues({ values });
                  }}
                />
              </div>

              <div className="create-company-page-details__form__buttons">
                {showCancelButton && (
                  <Button variant="secondary" type="button" size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
                <Button variant="primary" type="submit" size="small" loading={loading}>
                  Continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default ContactDetails;
