import axios from 'axios';

import {
  TCreateCommentRequest,
  TCreatePostRequest,
  TCreatePostUploadMediaRequest,
  TDeleteCommentRequest,
  TDeleteMediaRequest,
  TDeletePostRequest,
  TDeleteReplyToCommentRequest,
  TDontRecommendRequest,
  TEditCommentRequest,
  TEditPostRequest,
  TEditReplyToCommentRequest,
  TFollowRequest,
  TGetCommentsRequest,
  TGetNewsFeedCompanyRequest,
  TGetNewsFeedRequest,
  TGetPostPresignedUrlRequest,
  TGetPostRequest,
  TGetRepliesRequest,
  TGetUserStatsRequest,
  TLikeReplyRequest,
  TSavePostRequest,
  TUnFollowRequest,
  TUnLikePostRequest,
  TUnLikeReplyRequest,
  TUnSavePostRequest
} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';
import { TLikeCommentRequest } from './apiEndpoint.types.ts/socialMedia/likeComment.endpoint.types.js';
import { TUnLikeCommentRequest } from './apiEndpoint.types.ts/socialMedia/unLikeComment.endpoint.types.js';
import { TReplyToCommentRequest } from './apiEndpoint.types.ts/socialMedia/ReplyToComment.endpoint.types.js';

export class SocialMediaAPIService extends BaseAPIService {
  private getNewsFeedAllPath: string = '/posts/news-feed-all';
  private getNewsFeedPopularPath: string = '/posts/news-feed-popular';
  private getNewsFeedFollowingPath: string = '/posts/news-feed-following';
  private getNewsFeedSelfPostedPath: string = '/posts/news-feed-self-posted';
  private getNewsFeedSavedPostsPath: string = '/posts/news-feed-saved-posts';
  private getNewsFeedOwnPopularPath: string = '/posts/news-feed-own-popular';
  private getNewsFeedCompanyPostsPath: string = '/posts/news-feed-company-posts';
  private getNewsFeedCompanyAnnouncementsPath: string = '/posts/news-feed-company-announcement';
  private createPostPath: string = '/posts/create-post';
  private editPostPath: string = '/posts/edit-post';
  private getPostPath: string = '/posts/get-post';
  private deletePostPath: string = '/posts/delete-post';
  private savePostPath: string = '/posts/save-post';
  private unSavePostPath: string = '/posts/unsave-post';
  private createCommentPath: string = '/posts/create-comment';
  private likePostPath: string = '/posts/like-post';
  private unlikePostPath: string = '/posts/unlike-post';
  private editCommentPath: string = '/posts/edit-comment';
  private getCommentsPath: string = '/posts/get-comments';
  private deleteCommentPath: string = '/posts/delete-comment';
  private likeCommentPath: string = '/posts/like-comment';
  private unlikeCommentPath: string = '/posts/unlike-comment';
  private getRepliesPath: string = '/posts/get-replies';
  private replyToCommentPath: string = '/posts/reply-to-comment';
  private editReplyToCommentPath: string = '/posts/edit-reply-to-comment';
  private deleteReplyToCommentPath: string = '/posts/delete-reply-to-comment';
  private likeReplyPath: string = '/posts/like-reply';
  private unLikeReplyPath: string = '/posts/unlike-reply';
  private getPostPresignedUrlPath: string = '/post/get-presigned-url';
  private followPath: string = '/posts/follow';
  private unFollowPath: string = '/posts/unfollow';
  private getUserStatsPath: string = '/posts/get-user-stats';
  private deleteMediaPath: string = '/posts/delete-media';
  private dontRecommendPostPath: string = '/posts/dont-recommend-post';

  constructor() {
    // Social media api service has a different endpoint from base service.
    super();
    this.axiosInstance = axios.create({
      baseURL: process.env.REACT_APP_API_SOCIAL_MEDIA_ENDPOINT
    });
    this.configureInterceptors();
  }

  public async createPost<TCreatePostResponse>(createPostRequest: TCreatePostRequest) {
    const createPostResponse: APIResponse<TCreatePostResponse> = await this.post(
      this.createPostPath,
      createPostRequest,
      true
    );
    return createPostResponse;
  }

  public async editPost<TEditPostResponse>(editPostRequest: TEditPostRequest) {
    const editPostResponse: APIResponse<TEditPostResponse> = await this.post(
      this.editPostPath,
      editPostRequest,
      true
    );
    return editPostResponse;
  }

  public async getPost<TGetPostResponse>(getPostRequest: TGetPostRequest) {
    const getPostResponse: APIResponse<TGetPostResponse> = await this.get(
      this.getPostPath,
      true,
      getPostRequest
    );
    return getPostResponse;
  }

  public async deletePost<TDeletePostResponse>(deletePostRequest: TDeletePostRequest) {
    const deletePostResponse: APIResponse<TDeletePostResponse> = await this.delete(
      this.deletePostPath,
      true,
      deletePostRequest
    );
    return deletePostResponse;
  }

  public async savePost<TSavePostResponse>(savePostRequest: TSavePostRequest) {
    const savePostResponse: APIResponse<TSavePostResponse> = await this.post(
      this.savePostPath,
      savePostRequest,
      true
    );
    return savePostResponse;
  }

  public async unSavePost<TUnSavePostResponse>(unSavePostRequest: TUnSavePostRequest) {
    const unSavePostResponse: APIResponse<TUnSavePostResponse> = await this.post(
      this.unSavePostPath,
      unSavePostRequest,
      true
    );
    return unSavePostResponse;
  }

  public async uploadPostImage<TCreatePostUploadMediaResponse>(
    uploadPostImageRequest: TCreatePostUploadMediaRequest
  ): Promise<APIResponse<TCreatePostUploadMediaResponse>> {
    const { data, includeAuthorizationHeaders = true } = uploadPostImageRequest;
    const uploadPostImageResponse: APIResponse<TCreatePostUploadMediaResponse> = await this.put(
      uploadPostImageRequest.signedUrl,
      data,
      includeAuthorizationHeaders
    );
    return uploadPostImageResponse;
  }

  public async getNewsFeed<TGetNewsFeedResponse>(getNewsFeedRequest: TGetNewsFeedRequest) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedAllPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedPopular<TGetNewsFeedResponse>(getNewsFeedRequest: TGetNewsFeedRequest) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedPopularPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedFollowing<TGetNewsFeedResponse>(getNewsFeedRequest: TGetNewsFeedRequest) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedFollowingPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedSelfPosted<TGetNewsFeedResponse>(
    getNewsFeedRequest: TGetNewsFeedRequest
  ) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedSelfPostedPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedSavedPosts<TGetNewsFeedResponse>(
    getNewsFeedRequest: TGetNewsFeedRequest
  ) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedSavedPostsPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedOwnPopular<TGetNewsFeedResponse>(
    getNewsFeedRequest: TGetNewsFeedRequest
  ) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedResponse> = await this.get(
      this.getNewsFeedOwnPopularPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedCompanyPosts<TGetNewsFeedCompanyResponse>(
    getNewsFeedRequest: TGetNewsFeedCompanyRequest
  ) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedCompanyResponse> = await this.get(
      this.getNewsFeedCompanyPostsPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async getNewsFeedCompanyAnnouncements<TGetNewsFeedCompanyResponse>(
    getNewsFeedRequest: TGetNewsFeedCompanyRequest
  ) {
    const getNewsFeedResponse: APIResponse<TGetNewsFeedCompanyResponse> = await this.get(
      this.getNewsFeedCompanyAnnouncementsPath,
      true,
      getNewsFeedRequest
    );
    return getNewsFeedResponse;
  }

  public async createComment<TCreateCommentResponse>(createCommentRequest: TCreateCommentRequest) {
    const createCommentResponse: APIResponse<TCreateCommentResponse> = await this.post(
      this.createCommentPath,
      createCommentRequest,
      true
    );
    return createCommentResponse;
  }

  public async editComment<TEditCommentResponse>(editCommentRequest: TEditCommentRequest) {
    const editCommentResponse: APIResponse<TEditCommentResponse> = await this.post(
      this.editCommentPath,
      editCommentRequest,
      true
    );
    return editCommentResponse;
  }

  public async likePost<TLikePostResponse>(likePostRequest: TUnLikePostRequest) {
    const likePostResponse: APIResponse<TLikePostResponse> = await this.post(
      this.likePostPath,
      likePostRequest,
      true
    );
    return likePostResponse;
  }

  public async unLikePost<TUnLikePostResponse>(unLikePostRequest: TUnLikePostRequest) {
    const unlikePostResponse: APIResponse<TUnLikePostResponse> = await this.post(
      this.unlikePostPath,
      unLikePostRequest,
      true
    );
    return unlikePostResponse;
  }

  public async getComments<TGetCommentsResponse>(getCommentsRequest: TGetCommentsRequest) {
    const getCommentsResponse: APIResponse<TGetCommentsResponse> = await this.get(
      this.getCommentsPath,
      true,
      getCommentsRequest
    );
    return getCommentsResponse;
  }

  public async deleteComment<TDeleteCommentResponse>(deleteCommentRequest: TDeleteCommentRequest) {
    const deleteCommentResponse: APIResponse<TDeleteCommentResponse> = await this.delete(
      this.deleteCommentPath,
      true,
      deleteCommentRequest
    );
    return deleteCommentResponse;
  }

  public async likeComment<TLikeCommentResponse>(likeCommentRequest: TLikeCommentRequest) {
    const likeCommentResponse: APIResponse<TLikeCommentResponse> = await this.post(
      this.likeCommentPath,
      likeCommentRequest,
      true
    );
    return likeCommentResponse;
  }

  public async unLikeComment<TUnLikeCommentResponse>(unLikeCommentRequest: TUnLikeCommentRequest) {
    const unlikeCommentResponse: APIResponse<TUnLikeCommentResponse> = await this.post(
      this.unlikeCommentPath,
      unLikeCommentRequest,
      true
    );
    return unlikeCommentResponse;
  }

  public async getReplies<TGetRepliesResponse>(getRepliesRequest: TGetRepliesRequest) {
    const getRepliesResponse: APIResponse<TGetRepliesResponse> = await this.get(
      this.getRepliesPath,
      true,
      getRepliesRequest
    );
    return getRepliesResponse;
  }

  public async replyToComment<TReplyToCommentResponse>(
    replyToCommentRequest: TReplyToCommentRequest
  ) {
    const replyToCommentResponse: APIResponse<TReplyToCommentResponse> = await this.post(
      this.replyToCommentPath,
      replyToCommentRequest,
      true
    );
    return replyToCommentResponse;
  }

  public async editReplyToComment<TEditReplyToCommentResponse>(
    editReplyToCommentRequest: TEditReplyToCommentRequest
  ) {
    const editReplyToCommentResponse: APIResponse<TEditReplyToCommentResponse> = await this.post(
      this.editReplyToCommentPath,
      editReplyToCommentRequest,
      true
    );
    return editReplyToCommentResponse;
  }

  public async deleteReplyToComment<TDeleteReplyToCommentResponse>(
    deleteReplyToCommentRequest: TDeleteReplyToCommentRequest
  ) {
    const deleteReplyToCommentResponse: APIResponse<TDeleteReplyToCommentResponse> =
      await this.delete(this.deleteReplyToCommentPath, true, deleteReplyToCommentRequest);
    return deleteReplyToCommentResponse;
  }

  public async likeReply<TLikeReplyResponse>(likeReplyRequest: TLikeReplyRequest) {
    const likeReplyResponse: APIResponse<TLikeReplyResponse> = await this.post(
      this.likeReplyPath,
      likeReplyRequest,
      true
    );
    return likeReplyResponse;
  }

  public async unLikeReply<TUnLikeReplyResponse>(unLikeReplyRequest: TUnLikeReplyRequest) {
    const unlikeReplyResponse: APIResponse<TUnLikeReplyResponse> = await this.post(
      this.unLikeReplyPath,
      unLikeReplyRequest,
      true
    );
    return unlikeReplyResponse;
  }

  public async getPostPresignedUrl<TGetPostPresignedUrlResponse>(
    getPostPresignedUrlRequest: TGetPostPresignedUrlRequest
  ): Promise<APIResponse<TGetPostPresignedUrlResponse>> {
    const getPostPresignedUrlResponse: APIResponse<TGetPostPresignedUrlResponse> = await this.get(
      this.getPostPresignedUrlPath,
      true,
      getPostPresignedUrlRequest
    );
    return getPostPresignedUrlResponse;
  }

  public async follow<TFollowResponse>(followRequest: TFollowRequest) {
    const followResponse: APIResponse<TFollowResponse> = await this.post(
      this.followPath,
      followRequest,
      true
    );
    return followResponse;
  }

  public async unFollow<TUnFollowResponse>(unFollowRequest: TUnFollowRequest) {
    const unFollowResponse: APIResponse<TUnFollowResponse> = await this.delete(
      this.unFollowPath,
      true,
      unFollowRequest
    );
    return unFollowResponse;
  }

  public async getUserStats<TGetUserStatsResponse>(getUserStatsRequest: TGetUserStatsRequest) {
    const getUserStatsResponse: APIResponse<TGetUserStatsResponse> = await this.get(
      this.getUserStatsPath,
      true,
      getUserStatsRequest
    );
    return getUserStatsResponse;
  }

  public async deleteMedia<TDeleteMediaResponse>(deleteMediaRequest: TDeleteMediaRequest) {
    const deleteMediaResponse: APIResponse<TDeleteMediaResponse> = await this.delete(
      this.deleteMediaPath,
      true,
      deleteMediaRequest
    );
    return deleteMediaResponse;
  }

  public async dontRecommendPost<TDontRecommendResponse>(
    dontRecommendPostRequest: TDontRecommendRequest
  ) {
    const dontRecommendPostResponse: APIResponse<TDontRecommendResponse> = await this.post(
      this.dontRecommendPostPath,
      dontRecommendPostRequest,
      true
    );
    return dontRecommendPostResponse;
  }
}
