import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIResponse } from 'src/services/base.api.service';
import { errorMessages } from 'src/constants';
import { externalApplicationFormLoaderId } from 'src/components/company/programs/applyToProgramExternal/applicantDetails/ApplicantDetails';
import { verifyEternalUserEmailLoaderId } from 'src/components/company/programs/applyToProgramExternal/applicantEmailVerification/ApplicantEmailVerification';
import { ExternalApplicationAPIService } from 'src/services/externalApplication.api.service';

import { initialState } from './externalApplication.slice.initialState';

import { AppDispatch } from '../store';
import {
  assignLoaderId,
  openDraftModal,
  openPopup,
  resetLoaderId,
  startLoading,
  stopLoading
} from '../common/common.slice';

export const externalApplicationSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    requestOtpSuccess: (state, action: PayloadAction<string>) => {
      state.otp = action.payload;
    },
    verifyOtpSuccess: (state, action: PayloadAction<{ token: string; user: any }>) => {
      const { token, user } = action.payload;

      state.otp = '';
      state.token = token;
      state.user = user;
    }
  }
});

// Action creators are generated for each case reducer function
export const { requestOtpSuccess, verifyOtpSuccess } = externalApplicationSlice.actions;

export default externalApplicationSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const getOtp = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(assignLoaderId(externalApplicationFormLoaderId));

  try {
    const response: APIResponse<any> = await new ExternalApplicationAPIService().getOtp(
      payload
    );

    const { status, data } = response;

    if (status === 200 && data?.otp) {
      dispatch(requestOtpSuccess(data?.otp));
    }

    return status;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(resetLoaderId());
  }
};

export const resendOtp = (payload: any) => async (dispatch: AppDispatch) => {
  try {
    const response: APIResponse<any> = await new ExternalApplicationAPIService().getOtp(
      payload
    );

    const { status, data } = response;

    if (status === 200 && data?.otp) {
      dispatch(requestOtpSuccess(data?.otp));
    }

    return status;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(resetLoaderId());
  }
};

export const verifyOtp = (payload: any) => async (dispatch: AppDispatch) => {
  dispatch(assignLoaderId(verifyEternalUserEmailLoaderId));

  try {
    const response: APIResponse<any> =
      await new ExternalApplicationAPIService().verifyOtp(payload);

    const { status, data } = response;
    if (data?.applicationType === 'real' || data?.applicationType === 'draft') {
      dispatch(openDraftModal({ isOpen: true, data }))
    }

    const token = data?.token;
    const user = data?.user;

    if (status === 200 && user) {
      dispatch(verifyOtpSuccess({ token, user }));
    }

    return status;
  } catch (err) {
    console.log(err, 'errorDataaaaaaaaaa');

    dispatch(
      openPopup({
        popupMessage: 'Failed to verify OTP',
        popupType: 'error'
      })
    );
  } finally {
    dispatch(resetLoaderId());
  }
};

export const createExternalApplication =
  (payload: any) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<any> =
        await new ExternalApplicationAPIService().createExternalApplication(payload);

      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };
