const parseLinkedinUsername = ({ url }: { url: string }): string | null => {
  const profilePattern = /.*linkedin\.com\/in\/([a-zA-Z0-9-]+)\/?/;
  const companyPattern = /.*linkedin\.com\/company\/([a-zA-Z0-9-]+)\/?/;
  const countryPattern = /.*linkedin\.com\/in\/([a-zA-Z0-9-]+)\/?/;

  let match = url.match(profilePattern);
  if (match) {
    return match[1];
  }

  match = url.match(companyPattern);
  if (match) {
    return match[1];
  }

  match = url.match(countryPattern);
  if (match) {
    return match[1];
  }

  return url;
};

export default parseLinkedinUsername;
