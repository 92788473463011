import { useEffect } from 'react';
import Button from 'src/components/button/Button';
import { Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TProgramMembersListRequest } from 'src/services/apiEndpoint.types.ts';
import { getProgramMembers } from 'src/store/program/program.slice';

import './programMembers.scss';
import ManageProgramMembers from './manageProgramMembers/ManageProgramMembers';
import { TProgramMembersProps } from './programMembers.types';
import ProgramMemberCard from './programMemberCard/ProgramMemberCard';

const companyManageAccessModalId = 'companyManageAccessModalId';

const ProgramMembers = ({ companyId }: TProgramMembersProps) => {
  //  Hooks
  const { paramValue } = useUrlParamValue();
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const programId: string = paramValue({ paramName: 'id' }) as string;

  const { programMembersPageNumber, programMembersPerPage, programMembers } = useAppSelector(
    (store) => store.program
  );
  const { modalId }: { modalId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );

  // const selfCompany = useContext(SelfCompanyContext);
  const { loggedInCompanyId, loggedInUserType, authenticatedId } = getIdentities();

  // Constants
  const isManageAccessModalOpen = modalId === companyManageAccessModalId;
  const isViewerProgramCreator = loggedInCompanyId === companyId;

  const openManageAccessModalOpen = () => {
    dispatch(assignModalId(companyManageAccessModalId));
  };

  const closeManageAccessModalOpen = () => {
    dispatch(assignModalId(''));
    // dispatch(resetSearchUsers());
  };

  const fetchProgramMembers = () => {
    const payload: TProgramMembersListRequest = {
      programId: programId,
      limit: programMembersPerPage,
      offset: programMembersPageNumber,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getProgramMembers(payload));
  };

  useEffect(() => {
    fetchProgramMembers();

    // return () => {
    //   dispatch(resetProgramMembers());
    // };
  }, [programMembersPageNumber, programMembersPerPage]);

  return (
    <div className="program-members">
      <div className="program-members__header">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Program&nbsp;Members
        </Typography>

        {isViewerProgramCreator && (
          <Button variant="secondary" size="small" onClick={openManageAccessModalOpen}>
            Manage members
          </Button>
        )}
      </div>
      <div className="program-members__members">
        {programMembers.map((program, index) => {
          return (
            <ProgramMemberCard
              key={index}
              {...program}
              fetchProgramMembers={fetchProgramMembers}
              isViewerProgramCreator={isViewerProgramCreator}
            />
          );
        })}
      </div>
      {/* {programMembersLoading && <Loader />} */}
      {isManageAccessModalOpen && (
        <ManageProgramMembers
          isModalOpen={isManageAccessModalOpen}
          onModalClose={closeManageAccessModalOpen}
          fetchProgramMembers={fetchProgramMembers}
        />
      )}
    </div>
  );
};

export default ProgramMembers;
