import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { StorageUtils, textFormatter } from 'src/utils';
import {
  assignConfirmModalId,
  assignModalId,
  assignSubMenuModalId,
  resetConfirmModalId,
  toggleNavBar
} from 'src/store/common/common.slice';
import { useGetIdentity, useSignOut } from 'src/hooks';

import InviteUser from './inviteUser/InviteUser';
import './header.scss';

import {
  HamburgerIcon,
  NotificationIcon,
  PlusIcon,
  SingleChevronIcon
} from '../common/common.icons';
import ProfilePicture from '../profile/profilePicture/ProfilePicture';
import Typography from '../common/typography/Typography';
import SubMenuModal from '../common/subMenuModal/SubMenuModal';
import { ConfirmModal, Dropdown, TDropdown } from '../common';
import { SearchBar } from '../search';
import Button from '../button/Button';
import { inviteUserModalId } from '../cards/inviteNetwork';

const signOutModalId = 'sign-up-modal-id';

const Header = () => {
  const { profile } = useAppSelector((store) => store.profile);
  const { companyProfilePic, myCompany } = useAppSelector((store) => store.company);
  const { dropdownId, confirmModalId, modalId } = useAppSelector((store) => store.common);
  const { highlightNotificationBell, ideaHubNotificationCount } = useAppSelector(
    (store) => store.notifications
  );

  // Constants
  const hasNotifications = ideaHubNotificationCount > 0 || highlightNotificationBell;
  const isSignOutModalOpen = confirmModalId === signOutModalId;

  // Hooks
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuthorized } = useAppSelector((store) => store.auth);
  const { getIdentities } = useGetIdentity();
  const signOut = useSignOut();

  const handleSignOut = () => {
    signOut();
  };

  const openSignOutModal = () => {
    dispatch(assignConfirmModalId(signOutModalId));
  };

  const closeSignOutModal = () => {
    dispatch(resetConfirmModalId());
  };

  const handleNavBar = () => {
    dispatch(toggleNavBar());
  };
  const isCompanyCreated = Boolean(StorageUtils.get('companyId'));
  const { loggedInUserType } = getIdentities();
  const headerProfilePic = loggedInUserType === 'User' ? profile?.profilePic : companyProfilePic;
  const headerName = loggedInUserType === 'User' ? profile?.name : myCompany.name;
  const openInviteUserModal = modalId === inviteUserModalId;

  const navigateCompany = () => {
    if (isCompanyCreated) {
      navigate(`/my-company?tab=about&prev=${window.location.pathname}`);
    } else {
      navigate(`/create-company?prev=${window.location.pathname}`);
    }
  };

  const openNotifications = () => {
    navigate('/notifications?tab=all');
  };

  const navigateToSettings = () => {
    navigate('/settings');
  };

  const headerDropdownItems = useMemo(() => {
    const items = [
      {
        label: isCompanyCreated ? 'Company Profile' : 'Create Company Page',
        onClick: navigateCompany
      },

      { label: 'Settings', onClick: navigateToSettings },
      { label: 'Logout', onClick: openSignOutModal }
    ];

    if (loggedInUserType === 'User') {
      items.unshift({ label: 'My Profile', onClick: () => navigate('/my-profile') });
    }

    return items;
  }, [isCompanyCreated]);

  const headerOptions: TDropdown = {
    id: 'header-dropdown',
    size: 'large',
    alignment: 'left',
    items: headerDropdownItems
  };
  console.log('triggered');
  const openDropdown = () => {
    if (dropdownId) {
      dispatch(assignSubMenuModalId(''));
    } else {
      dispatch(assignSubMenuModalId(headerOptions.id));
    }
  };

  const handleOpenInviteUserModal = () => {
    dispatch(assignModalId(inviteUserModalId));
  };

  const handleCloseInviteUserModal = () => {
    dispatch(assignModalId(''));
  };

  return (
    <div className="header-component">
      <div className="header-component__search">
        <SearchBar />
      </div>
      <div className="header-component__right">
        <Button
          variant="secondary"
          size="extraSmall"
          startIcon={<PlusIcon />}
          className="header-component__right__invite-user"
          onClick={handleOpenInviteUserModal}
        >
          Invite User
        </Button>
        <div className="header-component__right__notifications" onClick={openNotifications}>
          {hasNotifications && (
            <div className="header-component__right__notifications__new-notification"></div>
          )}
          <NotificationIcon />
        </div>
        <div className="header-component__right__menu" >
          <div className="header-component__right__menu__profile-picture" onClick={(e) => {
            e.stopPropagation();
            openDropdown();
          }}>
            <ProfilePicture
              url={headerProfilePic || ''}
              fallback={textFormatter({ value: headerName[0] || '' })}
              userType={loggedInUserType}
            />
          </div>
          <Typography as="p" variant="body-2" fontWeight="medium" className='cursor_pointer' onClick={(e) => {
            e.stopPropagation();
            openDropdown();
          }}>
          </Typography>
          <div
            className="header-component__right__menu__arrow"
            onClick={(e) => {
              e.stopPropagation();
              openDropdown();
            }}
          >
            <SingleChevronIcon direction="down" />
          </div>{' '}
          {isAuthorized && <div className="header-component__open-navigation" onClick={handleNavBar}>
            <HamburgerIcon />
          </div>}
          <SubMenuModal>
            <Dropdown {...headerOptions} />
          </SubMenuModal>
        </div>
      </div>
      {isSignOutModalOpen && (
        <ConfirmModal
          title="Are you sure you want to logout from your account?"
          openConfirmModal={isSignOutModalOpen}
          onClose={closeSignOutModal}
          onSubmit={handleSignOut}
          submitText="Logout"
          type="error"
        />
      )}
      {openInviteUserModal && (
        <InviteUser isModalOpen={openInviteUserModal} closeModal={handleCloseInviteUserModal} />
      )}
    </div>
  );
};

export default Header;
