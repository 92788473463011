import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ThumbsUpIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.16732 14.2293V6.896M0.833984 8.22933V12.896C0.833984 13.6324 1.43094 14.2293 2.16732 14.2293H11.1181C12.1053 14.2293 12.9448 13.5091 13.0949 12.5334L13.8128 7.86678C13.9992 6.65526 13.0619 5.56266 11.8361 5.56266H9.50065C9.13246 5.56266 8.83398 5.26419 8.83398 4.896V2.53989C8.83398 1.63199 8.09799 0.895996 7.19009 0.895996C6.97354 0.895996 6.7773 1.02353 6.68936 1.22141L4.34328 6.50009C4.23628 6.74084 3.99753 6.896 3.73407 6.896H2.16732C1.43094 6.896 0.833984 7.49295 0.833984 8.22933Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ThumbsUpIcon;
