import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const LeftAlignIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="8"
      viewBox="0 0 15 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11 4.1875H1.25M14 1.1875H1.25M8.49064 7.1875C3.51146 7.1875 6.22918 7.1875 1.25 7.1875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LeftAlignIcon;
