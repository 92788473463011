import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TProgramListRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { programList, resetPrograms } from 'src/store/program/program.slice';
import { Separator, Typography } from 'src/components/common';
import Button from 'src/components/button/Button';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';

import { TFilterProgram, TProgramFilterType, TProgramType, TProgramsProps } from './programs.types';
import './programs.scss';

import CreateProgramCard from '../createProgramCard/CreateProgramCard';
import ProgramCard from '../programCard/ProgramCard';
import ProgramFilters from '../programFilters/ProgramFilters';
import { httpRequest } from 'src/services/commonApis';

const Programs = ({ companyId, isViewerProgramCreator = true }: TProgramsProps) => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const { paramValue } = useUrlParamValue();
  const navigate = useNavigate();
  const { programsPageNumber, programsPerPage, programs } = useAppSelector(
    (store) => store.program
  );
  const { isUserKeyMember } = useAppSelector((store) => store.company.viewCompany);
  const dispatch = useAppDispatch();
  const selfCompany = useContext(SelfCompanyContext);

  // React state
  const [userProgramFilter, setUserProgramFilter] = useState<'All' | 'Access'>('All');
  const [draftProgram, setDraftProgram] = useState<any>([])

  const { loggedInUserType, loggedInUserId } = getIdentities();

  const programListParams = useMemo(() => {
    const programType: TProgramType = (paramValue({ paramName: 'programType' }) ||
      'Active') as TProgramType;
    const filterProgram: TFilterProgram = (paramValue({ paramName: 'filterProgram' }) ||
      'All') as TFilterProgram;

    return { programType, filterProgram };
  }, [window.location.search]);

  const updateParams = ({ param, value }: { param: TProgramFilterType; value: string }) => {
    let path = '';

    if (selfCompany) {
      path = `${window.location.pathname}?tab=programs&${param}=${value}`;
    } else {
      path = `${window.location.pathname}?id=${companyId}&tab=programs&${param}=${value}`;
    }

    navigate(path);
  };

  const getProgramList = ({ refreshPrograms }: { refreshPrograms: boolean }) => {
    const { programType } = programListParams;
    // const { filterProgram } = programListParams;
    const payload: TProgramListRequest = {
      companyId,
      limit: programsPerPage,

      offset: refreshPrograms ? 1 : programsPageNumber,
      ...(loggedInUserType === 'User' && loggedInUserId && { userId: loggedInUserId }),
      ...(programType && { programStatus: programType }),
      ...(userProgramFilter && loggedInUserType === 'User' && { filterProgram: userProgramFilter })
    };

    dispatch(programList(payload));
  };

  useEffect(() => {
    getProgramList({ refreshPrograms: false });
  }, [programsPageNumber, programsPerPage, window.location.search, userProgramFilter]);

  useEffect(() => {
    return () => {
      dispatch(resetPrograms());
    };
  }, []);
  const { programType } = programListParams;

  const getDraftProgram = async (route: string) => {
    const response = await httpRequest(route, 'GET', null, 'json', 'program');
    if (response?.data?.programs && response?.data?.programs?.length > 0) {
      setDraftProgram(response?.data?.programs)
    }
  }

  console.log(isViewerProgramCreator, 'isViewerProgramCreator');
  useEffect(() => {
    if (programType === 'All') {
      const { programType } = programListParams;
      // const { filterProgram } = programListParams;
      const payload: any = {
        offset: programsPageNumber,
        ...(loggedInUserType === 'User' && loggedInUserId && { userId: loggedInUserId }),
        ...(programType && { programStatus: 'Draft' }),
        ...(userProgramFilter && loggedInUserType === 'User' && { filterProgram: userProgramFilter })
      };
      const route = `program/list?companyId=${companyId}&limit=${programsPerPage}&offset=${payload?.offset}&programStatus=Draft`;
      getDraftProgram(route)
    }
  }, [programType, programsPageNumber, programsPerPage, window.location.search, userProgramFilter])

  const modifiedProgramList = programType === 'All' ? [...programs, ...draftProgram] : programs;
  return (
    <div className="company-programs">
      <div className="company-programs__header">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Programs
        </Typography>
        {loggedInUserType === 'User' && isUserKeyMember && isViewerProgramCreator && <Separator />}
        {loggedInUserType === 'Company' && isViewerProgramCreator && <ProgramFilters updateParams={updateParams} />}
        {loggedInUserType === 'User' && (isUserKeyMember) && isViewerProgramCreator && (
          <div className="company-programs__header__buttons">
            <Button
              size="small"
              className="company-programs__header__buttons__all"
              state={userProgramFilter === 'All' ? 'active' : 'default'}
              variant="tertiary"
              onClick={() => {
                setUserProgramFilter('All');
              }}
            >
              All Programs
            </Button>
            <Button
              size="small"
              className="company-programs__header__buttons__access"
              state={userProgramFilter === 'Access' ? 'active' : 'default'}
              variant="tertiary"
              onClick={() => {
                setUserProgramFilter('Access');
              }}
            >
              Access Programs
            </Button>
          </div>
        )}
      </div>
      <div className="company-programs__programs">
        {loggedInUserType === 'Company' && selfCompany && <CreateProgramCard />}
        {modifiedProgramList?.map((program, index) => {
          const isDraft = program?.ProgramDraft !== undefined
          return (
            <ProgramCard
              key={index}
              {...program}
              isViewerProgramCreator={isViewerProgramCreator}
              getProgramList={getProgramList}
              programType={isDraft ? 'Draft' : programType}
              programMemberView={userProgramFilter === 'Access'}
            />
          )
        })}
      </div>
    </div>
  );
};

export default Programs;
