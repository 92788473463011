import { ReactNode } from 'react';
import { getMimeType, textEllipsis } from 'src/utils';
import {
  DownloadIcon,
  FileIcon2,
  ImageIcon,
  TrashIcon,
  VideoIcon
} from 'src/components/common/common.icons';
import { Tooltip, Typography } from 'src/components/common';
import { colors } from 'src/constants';
import { TTextEllipsisResponse } from 'src/utils/textEllipsis/textEllipsis.types';
import { useAppDispatch } from 'src/store/hooks';
import { removeChatFile } from 'src/store/chat/chat.slice';

import { TFileCardProps } from './fileCard.types';
import './fileCard.scss';

const FileCard = ({
  fileName,
  id,
  key,
  showDownloadButton = true,
  showDeleteButton,
  ...rest
}: TFileCardProps) => {
  // Hooks
  const dispatch = useAppDispatch();

  // Utils
  const mimeType = getMimeType({ fileName });
  const renderFileName: TTextEllipsisResponse = textEllipsis({ charLimit: 29, text: fileName });

  const icons: Record<typeof mimeType, ReactNode> = {
    'image/jpeg': <ImageIcon />,
    'image/png': <ImageIcon />,
    'image/gif': <ImageIcon />,
    'image/bmp': <ImageIcon />,
    'image/webp': <ImageIcon />,
    'video/mp4': <VideoIcon />,
    'video/x-msvideo': <VideoIcon />
  };

  const removeFile = () => {
    dispatch(removeChatFile({ id }));
  };

  return (
    <div className="chat-program-file-card" {...rest}>
      <div className="chat-program-file-card__file">{icons[mimeType] || <FileIcon2 />}</div>
      <div className="chat-program-file-card__text">
        <Tooltip text={fileName} conditionToDisplay={renderFileName?.hasEllipsis}>
          <Typography
            as="p"
            variant="caption"
            fontWeight="semiBold"
            style={{ color: colors.neutral[1000] }}
          >
            {renderFileName?.text}
          </Typography>
        </Tooltip>
      </div>
      {showDownloadButton && (
        <div>
          <DownloadIcon />
        </div>
      )}
      {showDeleteButton && (
        <div onClick={removeFile}>
          <TrashIcon />
        </div>
      )}
    </div>
  );
};

export default FileCard;
