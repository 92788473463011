import { useMemo } from 'react';
import { TUserTypes } from 'src/constants/user.constants';
import { useGetIdentity } from 'src/hooks';
import { useSideNavItems } from 'src/utils';
import { useAppSelector } from 'src/store/hooks';

import { TNavigation } from './Navigation';

const useSideNavigationItems = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const SIDE_NAV_ITEMS = useSideNavItems();
  const { ideaHubNotificationCount, totalUnreadConversationCount } = useAppSelector(
    (store) => store.notifications
  );

  const { loggedInUserType }: { loggedInUserType: TUserTypes } = getIdentities();

  const sideNavItems = useMemo(() => {
    return SIDE_NAV_ITEMS.filter(
      (sideNavItem: TNavigation) =>
        sideNavItem.availableToUserType === loggedInUserType ||
        sideNavItem.availableToUserType === 'All'
    );
  }, [loggedInUserType, ideaHubNotificationCount, totalUnreadConversationCount, SIDE_NAV_ITEMS]);

  return sideNavItems;
};

export default useSideNavigationItems;
