/* eslint-disable no-undef */

import { useEffect } from 'react';

export type TUseDebounce = {
  func: () => void;
  delay: number;
  dependency: string | number;
  trigger?: boolean; // trigger key is optional, useful when the func need to called when a condition has to be met
};

const useDebounce = ({ func, delay, dependency, trigger = true }: TUseDebounce) => {
  useEffect(() => {
    let searchTimeout: string | number | NodeJS.Timeout | undefined;

    if (trigger) {
      searchTimeout = setTimeout(() => {
        func();
      }, delay);
    }

    return () => clearTimeout(searchTimeout);
  }, [dependency]);
};

export default useDebounce;
