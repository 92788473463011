import { useEffect, useState } from 'react';

import { TUseCalculateElementHeightProps } from './useCalculateElementHeight.types';

/*
This hook is useful when a height of particular element is creating vertical scroll.
The primary output of this hook should be a number which restricts the height of the concerned element.
Can be use on only one element in a screen.

*/
const useCalculateElementHeight = ({
  ref,
  triggers = [],
  heightOffset = 0
}: TUseCalculateElementHeightProps) => {
  const [elementHeight, setElementHeight] = useState<number>(0);

  const calculateElementHeight = () => {
    if (ref.current) {
      // This step is to allow the table to take the normal height
      // ref.current.style.height = 'auto';
      const { y: heightOfContentAboveElement } = ref.current.getBoundingClientRect();

      const elementHeightCalculated =
        window.innerHeight - heightOfContentAboveElement - heightOffset;

      setElementHeight(elementHeightCalculated);
    }
  };

  useEffect(() => {
    calculateElementHeight();
    // RecalculateToastMsgs if the screen size is changed.
    window.addEventListener('resize', calculateElementHeight);
    return () => window.removeEventListener('resize', calculateElementHeight);
  }, [ref, ...triggers]);

  return { elementHeight };
};

export default useCalculateElementHeight;
