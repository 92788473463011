export type TSelectDeselectCheckbox = {
  arr: (string | number)[];
  value: string | number;
};

/**
 * This function, given a value and array
 * If the item is already present in the array, it will be removed
 * If the item is not present in the array, the item will be added to the array.
 * @param {(string|number)[]} arr - The array of values to select from.
 * @param {string|number} value - The value to select or deselect.
 */
const selectDeselectCheckbox = ({ arr, value }: TSelectDeselectCheckbox) => {
  try {
    const index = arr.indexOf(value);
    if (index > -1) {
      arr.splice(index, 1);
    } else {
      arr.push(value);
    }
    return arr;
  } catch {
    return arr;
  }
};

export default selectDeselectCheckbox;
