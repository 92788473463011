import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const CommentIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.83333 5.89583H7.5M4.83333 8.22917H9.5M7.5 13.5625C10.8137 13.5625 13.5 10.8762 13.5 7.5625C13.5 4.24879 10.8137 1.5625 7.5 1.5625C4.18629 1.5625 1.5 4.24879 1.5 7.5625C1.5 8.3606 1.65582 9.1223 1.93871 9.81884C1.99285 9.95215 2.01992 10.0188 2.032 10.0727C2.04381 10.1254 2.04813 10.1644 2.04814 10.2184C2.04814 10.2736 2.03812 10.3338 2.01807 10.4541L1.62275 12.826C1.58135 13.0744 1.56065 13.1986 1.59917 13.2884C1.63289 13.367 1.69552 13.4296 1.77412 13.4633C1.86393 13.5018 1.98812 13.4811 2.23651 13.4397L4.60843 13.0444C4.72872 13.0244 4.78887 13.0144 4.84409 13.0144C4.8981 13.0144 4.93711 13.0187 4.98981 13.0305C5.04369 13.0426 5.11035 13.0697 5.24366 13.1238C5.9402 13.4067 6.7019 13.5625 7.5 13.5625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CommentIcon;
