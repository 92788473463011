import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const CompanyIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="22"
      height="20"
      viewBox="0 0 22 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5 9.0625H3.6C3.03995 9.0625 2.75992 9.0625 2.54601 9.17149C2.35785 9.26737 2.20487 9.42035 2.10899 9.60851C2 9.82242 2 10.1024 2 10.6625V19.0625M15.5 9.0625H18.4C18.9601 9.0625 19.2401 9.0625 19.454 9.17149C19.6422 9.26737 19.7951 9.42035 19.891 9.60851C20 9.82242 20 10.1024 20 10.6625V19.0625M15.5 19.0625V4.2625C15.5 3.1424 15.5 2.58234 15.282 2.15452C15.0903 1.77819 14.7843 1.47223 14.408 1.28049C13.9802 1.0625 13.4201 1.0625 12.3 1.0625H9.7C8.57989 1.0625 8.01984 1.0625 7.59202 1.28049C7.21569 1.47223 6.90973 1.77819 6.71799 2.15452C6.5 2.58234 6.5 3.1424 6.5 4.2625V19.0625M21 19.0625H1M10 5.0625H12M10 9.0625H12M10 13.0625H12"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CompanyIcon;
