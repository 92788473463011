import { Dispatch, SetStateAction } from 'react';
import { ValidationError } from 'yup';

export type TUseValidateErrors = {
  validationSchema: any;
  formValues: Record<string, any>;
  errorsInitialState: Record<string, string>;
  setErrors: Dispatch<SetStateAction<Record<string, string>>>;
};

/**
 * A custom hook for validating errors.
 * @returns {Object} An object containing the validateErrors function.
 */
const useValidateErrors = () => {
  const validateErrors = ({
    validationSchema,
    formValues,
    errorsInitialState,
    setErrors
  }: TUseValidateErrors) => {
    const errors = validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        // then block runs when there are no errors
        // So the errors are reset to initial state here
        setErrors(errorsInitialState);
        return [];
      })
      .catch((validationError: any) => {
        const errors: Record<string, string> = {};

        validationError.inner.forEach((error: ValidationError) => {
          const field = error.path;
          if (field) {
            errors[field as string] = error.message;
          }
        });

        setErrors(errors);
        return validationError.errors;
      });

    return errors;
  };

  return { validateErrors };
};

export default useValidateErrors;
