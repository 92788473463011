import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import { Button, Modal, Typography } from 'src/components/common';
import { useUrlParamValue } from 'src/hooks';

import './activeUserModal.scss';
import { useDispatch, useSelector } from 'react-redux';
import { openExistingUserModal } from 'src/store/common/common.slice';

const ActiveUserModal = () => {
  const draftState = useSelector((state: any) => state?.common?.existingUser);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const handleCloseModal = () => {
    dispatch(openExistingUserModal({ isOpen: false, programId: null }))
  };

  const handleNavigateToLogin = () => {
    navigate(`/login?redirectUrl=apply-to-program-external?id=${draftState?.programId}`, { replace: true });
    dispatch(openExistingUserModal({ isOpen: false, programId: null }))
  };

  return (
    <Modal
      isModalOpen={draftState?.isOpen}
      onModalClose={handleCloseModal}
      className="ape-navigation-modal"
    >
      <img src={hTractionLogoWithText} />
      <Typography>
        Your account already exists.To apply to this program, please log in
      </Typography>
      <Button size="large" variant="primary" onClick={handleNavigateToLogin}>
        Login
      </Button>
    </Modal>
  );
};

export default ActiveUserModal;
