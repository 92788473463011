import React, { useEffect, useId, useState } from 'react';
import { Loader, Typography } from 'src/components/common';
import { TProgramMembersProps } from '../programMembers/programMembers.types';
import { useAppSelector } from 'src/store/hooks';
import { TGetProgramData } from 'src/services/apiEndpoint.types.ts';
import { httpRequest } from 'src/services/commonApis';
import { TProgramData, TProgramStage, TprogramStageCriteria } from './evaluation.types';
import GradeContainForm from './GradeContainForm/GradeContainForm';
import GradesItem from './GradesItem/GradesItem';
import cx from './evaluation.module.scss';
import EmptyStageDetail from './EmptyStageDetail/EmptyStageDetail';
import { Col, Row } from 'react-bootstrap';
import { StorageUtils } from 'src/utils';

export default function Evaluation({ companyId }: TProgramMembersProps) {
  const viewProgram: TGetProgramData = useAppSelector((store: any) => store.program.viewProgram);
  const [selectedStage, setSelectedStage] = useState<TProgramStage | null>(null);
  const [programData, setProgramData] = useState<TProgramData[]>([]);
  const [stageData, setStageData] = useState<TProgramStage[]>([]);
  const [loading, setLoading] = useState<boolean>(false)
const userType:string = StorageUtils.get('userType')
const userId:string = StorageUtils.get('userId')
  function stageHandler(stage: TProgramStage) {
    setSelectedStage(stage)
  }
  useEffect(() => {
    const stageWiseData = stageData?.map((stage: TProgramStage) => {
      const initialData = programData.find((program: TProgramData) => program?.stageId === stage?.id);
    
      return {
        stageId: stage?.id,
        overallComment: stage?.overallComment ? stage?.overallComment : initialData?.overallComment ? initialData?.overallComment : false,
        mode: stage.stagesDetails?.some((criteriaItem: TprogramStageCriteria) => criteriaItem?.criteriaName !== '') ? 'update' : initialData?.mode ? initialData?.mode : 'create',
        data: stage?.stagesDetails
          ? stage?.stagesDetails
          : [{
              criteriaName: '',
              rating: '',
              comments: false,
              overallComment: false
            }]
      };
    });
    setProgramData(stageWiseData);
  }, [stageData]);

  async function getStages() {
    setLoading(true)
let currentLoggedinUser = companyId
    if (userType === 'User') {
      currentLoggedinUser=userId
    }
    const response = await httpRequest(`program/stage?programId=${viewProgram?.id}&userId=${currentLoggedinUser}&userType=${userType}`, 'GET', null, 'json', 'program');
    if (response?.data?.length > 0) {
      if (!selectedStage) {
        setSelectedStage(response?.data?.[0])
      }
      setStageData(response?.data)
    }
    setLoading(false)
  }
  useEffect(() => {
    if (viewProgram) {
      getStages()
    }
  }, [viewProgram])

  return (
    <>
      {loading && <Loader />}
      {!loading && <div className={`${cx.evalutaion}`}>
        <div className={cx.header}>
          <Typography variant='subHeading-2' fontWeight='semiBold'>
            Evaluation Criteria
          </Typography>
        </div>

        <div className={`${cx.container}`}>
          <Row>
            <Col md={3}>
              {stageData?.length > 0 && <div className={`${cx.gradesBox}`}>
                <h5 className={'title'}>Stages</h5>
                <ul className={`${cx.gradeList}`}>
                  {stageData.map((stage: TProgramStage, i: number) => {
                    return (
                      <GradesItem key={i} stageData={stage} selectedStage={selectedStage} stageHandler={stageHandler} />
                    )
                  })}
                </ul>
              </div>}
            </Col>
            <Col md={9}>
              {selectedStage && programData?.length > 0 && <GradeContainForm key={selectedStage?.id} getStages={getStages} selectedStage={selectedStage} programData={programData} setProgramData={setProgramData} />}
            </Col>
          </Row>
          {!selectedStage && <EmptyStageDetail key='emptyPage' title={stageData?.length === 0 ? 'Please define stage first' : 'Please select stage'} />}
        </div>
      </div>}
    </>
  )
}
