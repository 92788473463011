import { Tooltip, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import Button from 'src/components/button/Button';
import { textEllipsis } from 'src/utils';

import './selectedEvaluator.scss';

export type TSelectedEvaluatorProps = {
  profilePic: string;
  name: string;
  role: string;
  email: string;
  handleRemoveUser: () => void;
};

const SelectedEvaluator = ({
  profilePic,
  name = '',
  role = '',
  email = '',
  handleRemoveUser
}: TSelectedEvaluatorProps) => {
  const nameEllipsis = textEllipsis({ charLimit: 12, text: name });
  const emailEllipsis = textEllipsis({ charLimit: 18, text: email });

  return (
    <div className="selected-evaluator">
      <div className="selected-evaluator__user-info">
        <ProfilePicture
          url={profilePic}
          fallback={name[0]}
          className="selected-evaluator__user-info__profile-pic"
        />
        <div className="selected-evaluator__user-info__user">
          <div className="selected-evaluator__user-info__user__row">
            <Tooltip text={name} conditionToDisplay={nameEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="semiBold"
                className="selected-evaluator__user-info__user__row__name"
              >
                {nameEllipsis?.text}
              </Typography>
            </Tooltip>
            <hr></hr>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="selected-evaluator__user-info__user__row__role"
            >
              {role}
            </Typography>
          </div>
          <Tooltip text={email} conditionToDisplay={emailEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="selected-evaluator__user-info__user__row__email"
            >
              {emailEllipsis?.text}
            </Typography>
          </Tooltip>
        </div>
      </div>
      <Button
        variant="tertiary"
        size="extraSmall"
        className="selected-evaluator__action"
        onClick={handleRemoveUser}
      >
        Remove
      </Button>
    </div>
  );
};

export default SelectedEvaluator;
