import { InputHTMLAttributes, ReactNode, RefObject } from 'react';
import { nanoid } from 'nanoid';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';

import './textAreaField.scss';

import Label from '../label/Label';
import TextArea from '../textArea/TextArea';
import { TypographyVariant } from '../../typography/typography.types';
import Typography from '../../typography/Typography';
import ErrorMessage from '../errorMessage/ErrorMessage';

type TTextAreaFieldProps = {
  label?: ReactNode;
  id: string;
  variant?: componentSizeVariantsEnum;
  TextAreaFieldClassName?: string;
  textLengthLimit?: number;
  errorMessage?: string;
  countWords?: boolean;
  required?: boolean;
  isTouched?: boolean;
  createProgramStep?:any
  inputRef?: RefObject<HTMLTextAreaElement>;
} & InputHTMLAttributes<HTMLTextAreaElement>;

/**
 * Props for the TextAreaField component.
 * @typedef {Object} TTextAreaFieldProps
 * @param {string} label - The label for the text area field.
 * @param {string} [id] - The ID attribute for the text area field.
 * @param {componentSizeVariantsEnum} [variant] - The variant of the text area field.
 * @param {string} [TextAreaFieldClassName] - Additional class name(s) for the text area field.
 * @param {number} [textLengthLimit] - The maximum number of characters allowed in the text area.
 * @param {string} [errorMessage] - The error message to be displayed.
 * @param {InputHTMLAttributes<HTMLTextAreaElement>} [HTMLTextAreaElement] - HTML attributes of the text area element.
 */
const TextAreaField = ({
  label,
  id,
  variant = componentSizeVariantsEnum.MEDIUM,
  TextAreaFieldClassName,
  textLengthLimit,
  className,
  value,
  errorMessage,
  countWords = false,
  required = false,
  isTouched = false,
  inputRef,
  createProgramStep,
  ...rest
}: TTextAreaFieldProps) => {
  // Creates unique id, if not available
  const inputId: string = id || nanoid();

  const textLimitTypographyVariant: Record<componentSizeVariantsEnum, TypographyVariant> = {
    [componentSizeVariantsEnum.SMALL]: 'caption',
    [componentSizeVariantsEnum.MEDIUM]: 'caption',
    [componentSizeVariantsEnum.LARGE]: 'body-2'
  };
  console.log(createProgramStep, 'createProgramStepcreateProgramStep')
  const textAreaValue = (value || '') as string;

  const contentLength = countWords
    ? textAreaValue.trim().split(/\s+/)?.length
    : textAreaValue?.length;
  console.log(variant, 'varianttt')

  return (
    <div
      className={classNames(
        className,
        'textarea-field-component',
        `textarea-field-component--${variant}`
      )}
    >
      {label && (
        <Label htmlFor={inputId} variant={variant} required={required}>
          {label}
        </Label>
      )}
      <TextArea
        {...rest}
        variant={variant}
        className={TextAreaFieldClassName}
        id={inputId}
        value={value}
        inputRef={inputRef}
        createProgramStep={createProgramStep}
      />{' '}
      {/* Typography is dynamic and it depends on the variant */}
      {textLengthLimit && (
        <Typography
          as="p"
          variant={textLimitTypographyVariant[variant]}
          fontWeight="regular"
          className={classNames(
            'textarea-field-component__text-length',
            `textarea-field-component__text-length--${variant}`
          )}
        >
          {contentLength}&#47;{textLengthLimit}
        </Typography>
      )}
      {isTouched && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default TextAreaField;
