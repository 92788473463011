export const defaultFields = {
  title: '',
  startDate: '',
  endDate: '',
  stages: NaN,
  stageTitles: []
};

export const defaultErrors = {
  title: '',
  startDate: '',
  endDate: '',
  stages: '',
  stageTitles: ''
};
