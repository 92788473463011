import { TIdeaCollaborationType, TIdeaCollaborator } from 'src/components/ideaHub/ideaHub.types';

import './editCollaborators.scss';

import SelectedCollaborator from '../selectedCollaborator/SelectedCollaborator';

export type TEditCollaboratorsProps = {
  ideaId: string;
  selectedCollaborators: TSelectedCollaborator[];
  updateSelectedUsers: ({ userId }: { userId: string }) => void;
  updatedCollaborationType: ({ id, type }: { id: string; type: TIdeaCollaborationType }) => void;
  collaborators: TIdeaCollaborator[];
};

export type TSelectedCollaborator = {
  id: string;
  name: string;
  role: string;
  email: string;
  profilePic: string;
  collaborationType: TIdeaCollaborationType;
};

const EditCollaborators = ({
  ideaId,
  selectedCollaborators,
  updateSelectedUsers,
  updatedCollaborationType,
  collaborators
}: TEditCollaboratorsProps) => {
  return (
    <div className="edit-collaborators">
      <div className="edit-collaborators__selected-users">
        {collaborators.map(
          (
            {
              name = '',
              role = '',
              email = '',
              id,
              profilePic = '',
              IdeaCollaboratorMapping
            }: TIdeaCollaborator,
            index
          ) => {
            return (
              <SelectedCollaborator
                key={index}
                profilePic={profilePic}
                name={name}
                role={role}
                email={email}
                id={id}
                collaborationType={IdeaCollaboratorMapping?.collaborationType}
                handleRemoveUser={() => updateSelectedUsers({ userId: id })}
                updatedCollaborationType={updatedCollaborationType}
              />
            );
          }
        )}
      </div>
    </div>
  );
};

export default EditCollaborators;
