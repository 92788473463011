import { InputHTMLAttributes, useState, ReactNode } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';

import './inputElement.scss';

type TInputProps = {
  variant?: componentSizeVariantsEnum;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  maskField?: boolean;
  startIconClickHandler?: () => void;
  endIconClickHandler?: () => void;
  inputRef?: (el: HTMLInputElement) => void;
} & InputHTMLAttributes<HTMLInputElement>;

/**
 * Props for the Input component.
 * @typedef {Object} TInputProps
 * @param {componentSizeVariantsEnum} [variant] - The variant of the input.
 * @param {ReactNode} [startIcon] - The icon element to be displayed at the start.
 * @param {ReactNode} [endIcon] - The icon element to be displayed at the end.
 * @param {() = void} [startIconClickHandler] - Click handler for the left icon.
 * @param {() = void} [endIconClickHandler] - Click handler for the right icon, (use case: toggling visibility of fields upon clicking of icon)
 * @param {boolean} [maskField] - Masking the input field, so that the text is not readable, useful for password fields
 * @param {InputHTMLAttributes<HTMLInputElement>} [HTMLInputElement] - HTML attributes of the input element.
 */
const InputElement = ({
  variant = componentSizeVariantsEnum.MEDIUM,
  startIcon,
  endIcon,
  startIconClickHandler,
  endIconClickHandler,
  className,
  disabled,
  maskField = false,
  onBlur,
  onFocus,
  inputRef,
  ...rest
}: TInputProps) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false);
  return (
    <div
      className={classNames(className, 'input-component', `input-component--${variant}`, {
        'input-component--disabled': disabled,
        'input-component--focus': inputFocused
      })}
    >
      {startIcon && (
        <div
          className={classNames(
            'input-component__icon',
            `input-component__icon--${variant}`,
            'align-icon-left'
          )}
          onClick={startIconClickHandler}
        >
          {startIcon}
        </div>
      )}
      {endIcon && (
        <div
          className={classNames(
            'input-component__icon',
            `input-component__icon--${variant}`,
            'align-icon-right'
          )}
          onClick={endIconClickHandler}
        >
          {endIcon}
        </div>
      )}
      <input
        {...rest}
        type={maskField ? 'password' : rest.type} // if text has to be masked, set the input type to password.
        disabled={disabled}
        className={classNames(
          'input-component__input',
          `input-component__input--${variant}`,
          `${className}__input`,
          {
            'input-component__input--disabled': disabled,
            'has-icon': startIcon || endIcon,
            'align-input-left': startIcon,
            'align-input-right': endIcon
          }
        )}
        onFocus={(e) => {
          setInputFocused(true);
          if (onFocus) {
            onFocus(e);
          }
        }}
        onBlur={(e) => {
          setInputFocused(false);
          if (onBlur) {
            onBlur(e);
          }
        }}
        ref={inputRef}
      />
    </div>
  );
};

export default InputElement;
