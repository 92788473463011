import Label from 'src/components/common/formFields/label/Label';
import './previewField.scss';
import { TPreviewOptionsFieldProps } from './previewField.types';

const TableFields = ({ id, label, options, type, required }: TPreviewOptionsFieldProps) => {
  console.log(options, 'labelidniddddd')
  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__field">
        <Label required={required}>{label}</Label>
          <div>
            <table className="simple-table">
              <thead>
                <tr>
                  {options.map((option, index) => (
                    <th key={index}>{option}</th>
                  ))}
                </tr>
                 <tr>
                  {options.map((option, index) => (
                    <td key={index}>{'value'}</td>
                  ))}
                </tr>

              </thead>
            </table>
          </div>
      </div>
    </div>
  );
};

export default TableFields;
