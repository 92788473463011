import Typography from 'src/components/common/typography/Typography';

import './about.scss';
import CompanyInfo, { TCompanyInfo } from './companyInfo/CompanyInfo';
import ContactInfo, { TContactInfo } from './contactInfo/ContactInfo';

export type TAbout = {
  about: string;
  foundedYear: number;
  industry: string;
  type: string;
} & TContactInfo &
  TCompanyInfo;

const About = ({
  about,
  foundedYear,
  industry,
  address,
  email,
  linkedin,
  contactNumber,
  addressLine1,
  addressLine2,
  city,
  state,
  country,
  zipCode,
  type
}: TAbout) => {
  return (
    <div className="about-company">
      <Typography
        as="h5"
        variant="subHeading-2"
        fontWeight="semiBold"
        className="about-company__heading"
      >
        About
      </Typography>{' '}
      {/*
       ** About Company
       */}
      <CompanyInfo about={about} foundedYear={foundedYear} industry={industry} type={type} />
      {/*
       ** Contact Info
       */}
      <ContactInfo
        address={address}
        email={email}
        linkedin={linkedin}
        contactNumber={contactNumber}
        addressLine1={addressLine1}
        addressLine2={addressLine2}
        city={city}
        state={state}
        country={country}
        zipCode={zipCode}
      />
    </div>
  );
};

export default About;
