import { Form, Formik } from 'formik';
import classNames from 'classnames';
import { Modal, Typography } from 'src/components/common';
import Label from 'src/components/common/formFields/label/Label';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import Button from 'src/components/button/Button';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TSubmitEvaluationRequest } from 'src/services/apiEndpoint.types.ts';
import { submitEvaluation } from 'src/store/ideaHub/ideaHub.slice';

import './rateIdea.scss';
import rateIdeaValidationSchema from './rateIdea.validationSchema';

export type TRateIdeaProps = {
  isModalOpen: boolean;
  handleCloseModal: () => void;
  loggedInUserId: string;
  ideaId: string;
};

export type TRateIdeaFormValues = {
  productMarketFit: number;
  marketSize: number;
  uniqueness: number;
  additionalFeedback: string;
};

const RateIdea = ({ isModalOpen, handleCloseModal, loggedInUserId, ideaId }: TRateIdeaProps) => {
  // Hooks
  const loading: boolean = useAppSelector((store) => store.common.loading);
  const dispatch = useAppDispatch();

  const initialValues: TRateIdeaFormValues = {
    productMarketFit: 0,
    marketSize: 0,
    uniqueness: 0,
    additionalFeedback: ''
  };

  const parameters: { id: string; label: string }[] = [
    { id: 'productMarketFit', label: 'Product market fit' },
    { id: 'marketSize', label: 'Market size' },
    { id: 'uniqueness', label: 'Uniqueness' }
  ];

  const ratingLevels = [1, 2, 3, 4, 5] as const;

  const handleSubmit = (values: TRateIdeaFormValues) => {
    const { marketSize, productMarketFit, uniqueness, additionalFeedback } = values;

    const payload: TSubmitEvaluationRequest = {
      ideaId,
      userId: loggedInUserId,
      productMarketFit,
      marketSize,
      uniqueness,
      details: additionalFeedback
    };

    dispatch(submitEvaluation(payload));
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={handleCloseModal}
      title="Rate Idea"
      className="rate-idea"
    >
      <Typography as="p" variant="body-2" fontWeight="regular" className="rate-idea__heading">
        Please take a moment to rate the idea on a scale of 1 to 5, with 5 being the highest
      </Typography>

      <Formik
        initialValues={initialValues}
        validationSchema={rateIdeaValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form className="rate-idea__form">
              <div className="rate-idea__form__parameters">
                {parameters.map(({ id, label }, index) => {
                  const parameterId: keyof TRateIdeaFormValues = id as keyof TRateIdeaFormValues;

                  return (
                    <div key={index} className="rate-idea__form__parameters__parameter">
                      <Label required>{label}</Label>
                      <div className="rate-idea__form__parameters__parameter__levels">
                        {ratingLevels.map((level) => (
                          <div
                            key={level}
                            className={classNames(
                              'rate-idea__form__parameters__parameter__levels__level',
                              {
                                'rate-idea__form__parameters__parameter__levels__level--is-selected':
                                  values[parameterId] === level
                              }
                            )}
                            onClick={() => {
                              setFieldValue(id, level);
                            }}
                          >
                            <Typography as="p" variant="caption" fontWeight="medium">
                              {level}
                            </Typography>
                          </div>
                        ))}
                      </div>
                      {/* eslint-disable indent */}
                      {touched[parameterId] && errors[parameterId] && (
                        <ErrorMessage message={errors[parameterId]} />
                      )}
                    </div>
                  );
                })}
              </div>
              <TextAreaField
                label="Please provide any additional feedback you have about the idea"
                placeholder="Additional feedback"
                id="additionalFeedback"
                required
                value={values.additionalFeedback}
                errorMessage={errors.additionalFeedback}
                isTouched={touched.additionalFeedback}
                onChange={(e) => setFieldValue('additionalFeedback', e.target.value)}
              />
              <div className="rate-idea__form__buttons">
                <Button size="small" variant="secondary" type="button" onClick={handleCloseModal}>
                  Close
                </Button>
                <Button size="small" variant="primary" type="submit" loading={loading}>
                  Submit
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default RateIdea;
