import { Card, Typography } from 'src/components/common';
import { FC } from 'react';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';

import './personCard.scss';

export type TPeopleCard = {
  person: {
    id: string;
    name: string;
    email: string;
    profilePic: string;
    role: string;
    isOwner?: boolean;
  };
  showEmail?: boolean;
};

const PersonCard: FC<TPeopleCard> = ({ person }) => {
  let isCoFounder = false;
  const isOwner = person?.isOwner;
  // New requirement to show Co-founder or Owner in first row, followed by name

  try {
    isCoFounder =
      (
        person as unknown as {
          IdeaCollaboratorMapping: { collaborationType: string };
        }
      )?.IdeaCollaboratorMapping?.collaborationType === 'CoFounder';
  } catch {}

  return (
    <Card className="person-card">
      <ProfilePicture
        url={person.profilePic || ''}
        fallback={person?.name[0]}
        className="person-card__profile-pic"
      />
      <div className="person-card__person-details">
        <div className="person-card__person-details__row">
          <Typography
            as="span"
            variant="body-2"
            fontWeight="semiBold"
            className="person-card__person-details__row__name"
          >
            {person?.name}
          </Typography>

          {(isCoFounder || isOwner) && (
            <>
              <hr></hr>
              <Typography
                as="span"
                variant="caption"
                fontWeight="regular"
                className="person-card__person-details__row__role"
              >
                {isCoFounder ? 'Co-Founder' : 'Owner'}
              </Typography>
            </>
          )}
        </div>

        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="person-card__person-details__email"
        >
          {person?.role}
        </Typography>
      </div>
    </Card>
  );
};

PersonCard.defaultProps = {
  showEmail: true
};

export default PersonCard;
