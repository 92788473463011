import { useEffect } from 'react';
import { onMessage, isSupported } from 'firebase/messaging';
import { useAppDispatch } from 'src/store/hooks';
import {
  getAllNotificationsSuccessViaFCM,
  // getInviteNotificationsSuccessViaFCM,
  updateChatNotificationCount,
  updateIdeaHubNotificationCount,
  updateNotificationsBell
} from 'src/store/notifications/notifications.slice';
import {
  TGetIdeaRequestsCountRequest,
  TGetInviteNotification
} from 'src/services/apiEndpoint.types.ts';
import { useGetIdentity } from 'src/hooks';
import { getIdeaRequestsCount } from 'src/store/ideaHub/ideaHub.slice';

import { messaging } from './firebaseConfig';
import useGetToken from './useGetToken';

const useGetNotifications = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const getTokenFunc = useGetToken();

  // Constants
  const { authenticatedEmail, loggedInUserId } = getIdentities();

  const listenToNotifications = async () => {
    const supported = await isSupported();

    if (!supported) {
      return;
    }

    await getTokenFunc();

    onMessage(messaging, (payload) => {
      console.log('Notification received. ', payload);

      try {
        const newNotification = payload?.data?.data as unknown as string;

        console.log(22, JSON.parse(newNotification));
        const parsedNotification = JSON.parse(newNotification) as unknown as TGetInviteNotification;

        if (parsedNotification?.type === 'navIdeaHubCount') {
          dispatch(updateIdeaHubNotificationCount(parsedNotification?.totalPendingRequest || 0));
        } else if (parsedNotification?.type === 'navChatCount') {
          const totalUnreadConversationCount =
            parsedNotification?.totalUnreadConversationCount || 0;
          const totalUnreadMessageCount = parsedNotification?.totalUnreadMessageCount || 0;

          dispatch(
            updateChatNotificationCount({ totalUnreadConversationCount, totalUnreadMessageCount })
          );
        } else if (parsedNotification?.type === 'all') {
          dispatch(getAllNotificationsSuccessViaFCM(newNotification));
          dispatch(updateNotificationsBell(true));
        } else {
          dispatch(getAllNotificationsSuccessViaFCM(newNotification));
          dispatch(updateNotificationsBell(true));
        }
      } catch (e) {
        console.log(e);
      }
    });
  };

  const getIdeaNotificationsCount = () => {
    const payload: TGetIdeaRequestsCountRequest = {
      userId: loggedInUserId
    };

    dispatch(getIdeaRequestsCount(payload));
  };

  useEffect(() => {
    listenToNotifications();
    if (loggedInUserId) {
      getIdeaNotificationsCount();
    }
  }, [authenticatedEmail]);

  return listenToNotifications;
};

export default useGetNotifications;
