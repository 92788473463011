import { Dispatch, SetStateAction, useContext } from 'react';
import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { EditIcon, TrashIcon, UniversityIcon } from 'src/components/common/common.icons';
import { textFormatter } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';

import './instituteCard.scss';
import ProfilePicture from '../../profilePicture/ProfilePicture';
import DeleteEducation from '../deleteEducation/DeleteEducation';
import { updateEducationModalId } from '../Education';

export type TInstituteCard = {
  institutionName: string;
  degreeName: string;
  field: string;
  start: string;
  end: string;
  currentlyStudying: boolean;
  instituteIndex: number;
  educationToUpdate: number;
  setInstituteToUpdate: Dispatch<SetStateAction<number>>; // function to set index of experience to update
  setUpdateInstituteMode: Dispatch<SetStateAction<'Add' | 'Update'>>; // function to set mode of experience update
};

const deleteEducationModalId = 'delete-education-modal';

const InstituteCard = ({
  institutionName,
  degreeName,
  field,
  start,
  end,
  currentlyStudying,
  instituteIndex,
  educationToUpdate,
  setInstituteToUpdate,
  setUpdateInstituteMode
}: TInstituteCard) => {
  const dispatch = useAppDispatch();
  const modalId = useAppSelector<string>((store) => store.common.modalId);

  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.

  const openUpdateModal = () => {
    setInstituteToUpdate(instituteIndex);
    setUpdateInstituteMode('Update');
    dispatch(assignModalId(updateEducationModalId));
  };

  const openDeleteModal = () => {
    setInstituteToUpdate(instituteIndex);
    dispatch(assignModalId(deleteEducationModalId));
  };

  const closeModal = () => {
    dispatch(resetModalId());
  };

  return (
    <Card className="institute-card">
      <ProfilePicture
        url={'profilePic'}
        fallback={<UniversityIcon />}
        className="institute-card__picture"
      />
      <div className="institute-card__content">
        <div className="institute-card__content__header">
          <div className="institute-card__content__header__institute">
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="institute-card__content__header__institute__name"
            >
              {textFormatter({ value: institutionName })}
            </Typography>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="regular"
              className="institute-card__content__header__institute__degree"
            >
              {textFormatter({ value: degreeName })}
              <hr></hr>
              {textFormatter({ value: field })}
            </Typography>
          </div>
          {isSelfUser && (
            <div className="institute-card__content__header__actions">
              <div
                className="institute-card__content__header__actions__action"
                onClick={openUpdateModal}
              >
                {<EditIcon />}
              </div>
              <div
                className="institute-card__content__header__actions__action"
                onClick={openDeleteModal}
              >
                {<TrashIcon />}
              </div>
            </div>
          )}
        </div>
        <Typography
          as="p"
          variant="caption"
          fontWeight="regular"
          className="institute-card__content__duration"
        >
          {textFormatter({ value: `${start} - ${currentlyStudying ? 'Present' : end}` })}
        </Typography>
      </div>

      {modalId === deleteEducationModalId && (
        <DeleteEducation
          isModalOpen={modalId === deleteEducationModalId}
          onModalClose={closeModal}
          educationToUpdate={educationToUpdate}
        />
      )}
    </Card>
  );
};

export default InstituteCard;
