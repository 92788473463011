import { TUser } from 'src/services/apiEndpoint.types.ts';

export type TInitialState = {
  // View program
  viewProgram: any;
  otp: string;
  token: string;
  user: TUser;
};

export const initialState: TInitialState = {
  viewProgram: {
    id: '',
    companyId: '',
    userId: '',
    title: '',
    startDate: '',
    endDate: '',
    stages: 0,
    programDetails: [],
    applicationForm: { sections: [] },
    status: 'Active',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    company: { id: '', email: '', name: '' }
  },
  otp: '',
  token: '',
  user: {
    id: '',
    email: '',
    name: '',
    role: '',
    gender: '',
    dob: '',
    address: '',
    country: '',
    state: '',
    city: '',
    zipCode: '',
    phoneNumber: '',
    linkedin: '',
    introduction: '',
    about: '',
    profession: '',
    experience: '',
    education: '',
    background: '',
    createdAt: '',
    updatedAt: '',
    deletedAt: '',
    profilePic: '',
    userIndustries: [],
    userExpertise: [],
    onboardingComplete: false
  }
};
