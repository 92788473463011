import classNames from 'classnames';

import { TActionIcon } from './actionIcon.types';
import './actionIcon.scss';

const ActionIcon = ({ children, active, className, ...rest }: TActionIcon) => {
  return (
    <div
      className={classNames('action-icon-component', className, {
        'action-icon-component--is-active': active
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

export default ActionIcon;
