import { useNavigate } from 'react-router-dom';
import { Card, Typography } from 'src/components/common';
import { PlusIconCircle } from 'src/components/common/common.icons';

import './createProgramCard.scss';

const CreateProgramCard = () => {
  // Hooks
  const navigate = useNavigate();

  const goToCreateProgram = () => {
    navigate('/create-program');
  };

  return (
    <>
      <Card className="create-program-card" onClick={goToCreateProgram}>
        <PlusIconCircle />
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Create Program
        </Typography>
      </Card>
    </>
  );
};

export default CreateProgramCard;
