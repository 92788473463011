import { useEffect } from 'react';
import { Form, Formik } from 'formik';
import { Modal } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
// import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Industries } from 'src/constants/user.constants';
import { assignModalId } from 'src/store/common/common.slice';
import { convertToString, enumToJson } from 'src/utils';
import { TEditCompanyRequest, TGetCompany } from 'src/services/apiEndpoint.types.ts';
import { editCompany } from 'src/store/company/company.slice';
import SearchAndSelect from 'src/components/common/formFields/searchAndSelect/SearchAndSelect';
import { useGetPlaces } from 'src/hooks';

import './editCompany.scss';
import editCompanyValidationSchema from './editCompany.validationSchema';

import useCompanyTypes from '../companyTypes/useCompanyTypes';

export type TEditCompany = {
  isModalOpen: boolean; // whether the modal is open
};

export type TEditCompanyValues = {
  id: string;
  name: string;
  type: string;
  industry?: string;
  introduction?: string;
  about?: string;
  contactNumber?: number;
  companyEmailAddress?: string;
  website?: string;
  contactUsUrl?: string;
  foundedYear?: number;
  addressLine1?: string;
  addressLine2?: string;
  country?: string;
  state?: string;
  city?: string;
  cityQuery?: string;
  zipCode?: string;
  linkedin?: string;
};

const EditCompany = ({ isModalOpen }: TEditCompany) => {
  const loading: boolean = useAppSelector<boolean>((store) => store.common.loading);
  const myCompany: TGetCompany = useAppSelector<TGetCompany>((store) => store.company.myCompany);

  const initialValues: TEditCompanyValues = {
    id: myCompany?.id,
    name: myCompany?.name || '',
    type: myCompany?.type || '',
    industry: myCompany?.industry || '',
    introduction: myCompany?.introduction || '',
    about: myCompany?.about || '',
    contactNumber: myCompany?.contactNumber || undefined,
    companyEmailAddress: myCompany?.companyEmailAddress || '',
    website: myCompany?.website || '',
    contactUsUrl: myCompany?.contactUsUrl || '',
    foundedYear: myCompany?.foundedYear || undefined,
    addressLine1: myCompany?.addressLine1 || '',
    addressLine2: myCompany?.addressLine2 || '',
    country: myCompany?.country || '',
    state: myCompany?.state || '',
    city: myCompany?.city || '',
    cityQuery: myCompany?.city || '',
    zipCode: myCompany?.zipCode || '',
    linkedin: myCompany?.linkedin || ''
  };

  // Hooks
  const { getPlaces, places } = useGetPlaces({ types: ['(cities)'] });
  const dispatch = useAppDispatch();

  const availableCompanyTypes = useCompanyTypes();
  const industriesOptions: TSelectCardRadioButton[] = enumToJson({
    enumData: Industries
  });

  const handleSubmit = async (values: TEditCompanyValues) => {
    const {
      name,
      type,
      industry,
      introduction,
      about,
      contactNumber,
      companyEmailAddress,
      website,
      foundedYear,
      contactUsUrl,
      addressLine1,
      addressLine2,
      country,
      state,
      city,
      zipCode,
      linkedin
    } = values;

    const payload: TEditCompanyRequest = {
      id: myCompany?.id,
      name,
      type,
      about: about || '',
      website: website || '',
      introduction: introduction || '',
      foundedYear: foundedYear || 0,
      contactNumber: contactNumber || 0,
      companyEmailAddress: companyEmailAddress || '',
      contactUsUrl: contactUsUrl || '',
      addressLine1: addressLine1 || '',
      addressLine2: addressLine2 || '',
      country: country || '',
      state: state || '',
      city: city || '',
      zipCode: convertToString({ num: zipCode || '', fallbackValue: '' }),
      industry: industry || '',
      linkedin: linkedin || ''
    };

    dispatch(editCompany(payload));
  };

  const closeModal = () => {
    dispatch(assignModalId(''));
  };

  useEffect(() => {
    // when edit profile modal is opened, fill the form with existing values.
    if (isModalOpen) {
      // update initial state
    }
  }, [isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      title="Edit Company Profile"
      className="edit-company-modal"
    >
      <div className="edit-company">
        {/* Form starts */}
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={editCompanyValidationSchema}
          enableReinitialize
        >
          {({ setFieldValue, values, errors, touched }) => {
            return (
              <Form className="edit-company__form" noValidate>
                <div className="edit-company__form__fields">
                  {/*
                   ** Company Name
                   */}
                  <InputField
                    required
                    label="Company Name"
                    id="name"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Company Name"
                    type="text"
                  />
                  {/*
                   ** Company Name
                   */}
                  <SelectField
                    required
                    label="Company Type"
                    placeholder="Select Company Type"
                    variant={componentSizeVariantsEnum.SMALL}
                    selectedValue={values.type}
                    options={availableCompanyTypes}
                    onSelection={({ value }) => {
                      setFieldValue('type', value as unknown as string);
                    }}
                  />
                  {/*
                   ** Industry
                   */}
                  <SelectField
                    label="Industry"
                    placeholder="Select Industry"
                    variant={componentSizeVariantsEnum.SMALL}
                    selectedValue={values.industry || ''}
                    options={industriesOptions}
                    onSelection={({ value }) => {
                      setFieldValue('industry', value as unknown as string);
                    }}
                  />
                  {/*
                   ** Introduction
                   */}
                  <InputField
                    label="Quick Introduction"
                    id="introduction"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Quick Introduction"
                    type="text"
                  />

                  {/*
                   ** About me
                   */}
                  <TextAreaEditor
                    label="About me"
                    id="about"
                    variant={componentSizeVariantsEnum.SMALL}
                    value={values.about}
                    onChange={(e: { target: { value: any; }; }) => {
                      setFieldValue('about', e.target.value);
                    }}
                  />
                  {/*
                   ** Grid of four fields -> phone number, email address, gender, and dob
                   */}
                  <div className="edit-company__form__fields__grid">
                    {/*
                     ** Phone number
                     */}
                    <InputField
                      label={
                        <Typography as="p" variant="caption" fontWeight="semiBold">
                          Contact number
                        </Typography>
                      }
                      id="contactNumber"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Contact number"
                      type="number"
                      onWheel={(e) => {
                        e.currentTarget.blur();
                      }}
                    />

                    {/*
                     ** Email Address
                     */}
                    <InputField
                      label="Email Address"
                      id="companyEmailAddress"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="example123@gmail.com"
                      type="text"
                    />
                  </div>

                  {/*
                   ** Website url
                   */}
                  <InputField
                    label="Website URL"
                    id="website"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="www.abcd.com"
                    type="text"
                  />

                  {/*
                   ** Founded on
                   */}
                  <InputField
                    label="Founded on"
                    id="foundedYear"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="Founded on"
                    type="number"
                    onWheel={(e) => {
                      e.currentTarget.blur();
                    }}
                  />

                  {/*
                   ** Contact us url
                   */}
                  <InputField
                    label="Contact us URL"
                    id="contactUsUrl"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="www.abcd.com"
                    type="text"
                  />

                  {/*
                   ** linkedin url
                   */}
                  <InputField
                    label="LinkedIn company page URL"
                    id="linkedin"
                    variant={componentSizeVariantsEnum.SMALL}
                    placeholder="linkedin.com/company/companyName"
                    type="link"
                  />

                  {/*
                   ** Address fields
                   */}
                  <div className="edit-company__form__fields__address">
                    <Typography as="p" variant="body-2" fontWeight="semiBold">
                      Address
                    </Typography>
                    {/*
                     ** Address lines
                     */}
                    <InputField
                      label="Address line 1"
                      id="addressLine1"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Address line"
                      type="text"
                    />
                    <InputField
                      label="Address line 2"
                      id="addressLine2"
                      variant={componentSizeVariantsEnum.SMALL}
                      placeholder="Address line"
                      type="text"
                    />
                    <div className="edit-company__form__fields__address__grid">
                      <InputField
                        label="Country"
                        id="country"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Country"
                        type="text"
                      />
                      <InputField
                        label="State"
                        id="state"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="State"
                        type="text"
                      />
                      <SearchAndSelect
                        label="City"
                        placeholder="City"
                        variant={componentSizeVariantsEnum.SMALL}
                        selectedValue={values.cityQuery || ''}
                        options={places}
                        onSelection={({ value }) => {
                          setFieldValue('city', value as unknown as string);
                          setFieldValue('cityQuery', value as unknown as string);
                        }}
                        onInputChange={(e) => {
                          const query = e.target.value;
                          setFieldValue('cityQuery', query as unknown as string);
                          getPlaces({
                            input: query.toString()
                          });
                        }}
                        errorMessage={errors.city}
                        isTouched={touched.city}
                      />
                      <InputField
                        label="Zip Code"
                        id="zipCode"
                        variant={componentSizeVariantsEnum.SMALL}
                        placeholder="Zip Code"
                        type="string"
                      />
                    </div>
                  </div>
                </div>
                <div className="edit-company__form__buttons">
                  <Button variant="secondary" type="button" size="small" onClick={closeModal}>
                    Close
                  </Button>

                  <Button variant="primary" type="submit" size="small" loading={loading}>
                    Save Changes
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </Modal>
  );
};

export default EditCompany;
