const getMimeType = ({ fileName }: { fileName: string }) => {
  try {
    const extension = fileName.split('.').pop()?.toLowerCase();
    const mimeTypes: { [key: string]: string } = {
      pdf: 'application/pdf',
      txt: 'text/plain',
      html: 'text/html',
      htm: 'text/html',
      jpg: 'image/jpeg',
      jpeg: 'image/jpeg',
      png: 'image/png',
      gif: 'image/gif',
      bmp: 'image/bmp',
      webp: 'image/webp',
      svg: 'image/svg+xml',
      json: 'application/json',
      xml: 'application/xml',
      csv: 'text/csv',
      zip: 'application/zip',
      mp3: 'audio/mpeg',
      wav: 'audio/wav',
      mp4: 'video/mp4',
      avi: 'video/x-msvideo'
      // Add more extensions and their MIME types as needed
    };

    return mimeTypes[extension || ''] || 'application/octet-stream'; // Default MIME type if unknown
  } catch {
    return 'application/octet-stream';
  }
};

export default getMimeType;
