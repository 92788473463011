import {
  Dashboard,
  IdeaHub,
  MyProfile,
  ViewPostOwned,
  ViewPostSingle,
  ViewProfile,
  CreateCompany,
  MyCompany,
  ViewCompany,
  Search,
  Settings,
  Chat,
  Resources,
  ViewEmptyIdea,
  ViewIdea,
  EditIdea,
  CreateProgram,
  ViewProgram,
  ViewProgramApplication,
  ViewMyApplication,
  // ApplyToProgram,
  Notifications
} from 'src/pages';
import { TRoute } from 'src/types/routes.types';

const getProtectedRoutes: TRoute[] = [
  {
    name: 'chat',
    title: 'Chat',
    component: Chat,
    path: '/chat',
    availableToUserType: 'All'
  },
  {
    name: 'dashboard',
    title: 'Dashboard',
    component: Dashboard,
    path: '/dashboard',
    availableToUserType: 'All'
  },
  {
    name: 'view-post',
    title: 'View Post',
    component: ViewPostSingle,
    path: '/view-post',
    availableToUserType: 'All'
  },
  {
    name: 'view-post-owned',
    title: 'View Post Owned',
    component: ViewPostOwned,
    path: '/view-post-owned',
    availableToUserType: 'All'
  },
  {
    name: 'view-profile',
    title: 'View Profile',
    component: ViewProfile,
    path: '/view-profile',
    availableToUserType: 'All'
  },
  {
    name: 'create-company',
    title: 'Create Company',
    component: CreateCompany,
    path: '/create-company',
    availableToUserType: 'All'
  },
  {
    name: 'my-company',
    title: 'View Company',
    component: MyCompany,
    path: '/my-company',
    availableToUserType: 'All'
  },
  {
    name: 'view-company',
    title: 'View Company',
    component: ViewCompany,
    path: '/view-company',
    availableToUserType: 'All'
  },
  {
    name: 'view-empty-idea',
    title: 'View Empty Idea',
    component: ViewEmptyIdea,
    path: '/view-empty-idea',
    availableToUserType: 'User'
  },
  {
    name: 'resources',
    title: 'Resources',
    component: Resources,
    path: '/resources',
    availableToUserType: 'All'
  },
  {
    name: 'search',
    title: 'Search',
    component: Search,
    path: '/search',
    availableToUserType: 'All'
  },
  {
    name: 'settings',
    title: 'Settings',
    component: Settings,
    path: '/settings',
    availableToUserType: 'All'
  },
  {
    name: 'idea-hub',
    title: 'Idea Hub',
    component: IdeaHub,
    path: '/idea-hub',
    availableToUserType: 'User'
  },
  {
    name: 'view-idea',
    title: 'View Idea',
    component: ViewIdea,
    path: '/view-idea',
    availableToUserType: 'User'
  },
  {
    name: 'edit-idea',
    title: 'edit Idea',
    component: EditIdea,
    path: '/edit-idea',
    availableToUserType: 'User'
  },
  {
    name: 'my-profile',
    title: 'My Profile',
    component: MyProfile,
    path: '/my-profile',
    availableToUserType: 'All'
  },
  {
    name: 'create-program',
    title: 'Create Program',
    component: CreateProgram,
    path: '/create-program',
    availableToUserType: 'Company'
  },
  {
    name: 'view-program',
    title: 'View Program',
    component: ViewProgram,
    path: '/view-program',
    availableToUserType: 'All'
  },
  {
    name: 'view-program-application',
    title: 'View Program Application',
    component: ViewProgramApplication,
    path: '/view-program-application',
    availableToUserType: 'All'
  },
  {
    name: 'view-my-application',
    title: 'View My Application',
    component: ViewMyApplication,
    path: '/view-my-application',
    availableToUserType: 'All'
  },
  {
    name: 'notifications',
    title: 'Notifications',
    component: Notifications,
    path: '/notifications',
    availableToUserType: 'All'
  }
];

export default getProtectedRoutes;
