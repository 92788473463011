import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId } from 'src/store/common/common.slice';

const useToggleOpenModal = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const currentModalId = useAppSelector((store) => store.common.modalId);

  const toggleOpenModal = ({ modalId }: { modalId: string }) => {
    if (currentModalId === modalId) {
      dispatch(assignModalId(''));
    } else {
      dispatch(assignModalId(modalId));
    }
  };

  return toggleOpenModal;
};

export default useToggleOpenModal;
