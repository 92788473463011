import { ReactNode } from 'react';
import classNames from 'classnames';

import Typography from '../typography/Typography';

export type TTabHeader = {
  activeItem: string;
  children: ReactNode;
  disabled?: boolean;
  onTabChange: (value: string) => void;
  tabId: string;
};

const TabHeader = ({ activeItem, disabled, onTabChange, children, tabId }: TTabHeader) => {
  return (
    <Typography
      as="span"
      variant="body-2"
      fontWeight="regular"
      onClick={() => {
        if (!disabled) {
          onTabChange(tabId);
        }
      }}
      className={classNames('tabs-component__tabs__item', {
        'tabs-component__tabs__item--is-active': activeItem === tabId,
        'tabs-component__tabs__item--is-disabled': disabled
      })}
    >
      {children}
    </Typography>
  );
};

export default TabHeader;
