import { useEffect } from 'react';
import CreateEditPostModal from 'src/components/posts/createEditPostModal/CreateEditPostModal';
import { TPostType } from 'src/constants';
import { TUserTypes } from 'src/constants/user.constants';
import { TPost } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import { TCreatePostMedia, updatePostMedia } from 'src/store/socialMedia/socialMedia.slice';

export type TEditPost = {
  isModalOpen: boolean;
  onModalClose: () => void;
  post?: TPost;
  refetchPost?: () => void;
  companyProfile: boolean;
  postType?: TPostType;
  viewerId: string;
  viewerType: TUserTypes;
};

const EditPost = ({
  isModalOpen,
  onModalClose,
  post,
  refetchPost,
  companyProfile,
  postType,
  viewerId,
  viewerType
}: TEditPost) => {
  const dispatch = useAppDispatch();

  const postText = post?.post?.text;
  const editPostId = post?.post?.id;
  const postTitle = post?.post?.title;
  const postCustomButton = post?.post?.customButton;
  const postRedirectUrl = post?.post?.redirectUrl;

  useEffect(() => {
    const urls = post?.post?.urls;
    if (isModalOpen && urls) {
      const media = (JSON.parse(post?.post?.urls) || []) as unknown as TCreatePostMedia[];

      dispatch(updatePostMedia({ urls: media }));
    }
  }, [isModalOpen]);

  return (
    <CreateEditPostModal
      title="Edit Post"
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      postText={postText}
      editMode={true}
      editPostId={editPostId}
      refetchPost={refetchPost}
      companyProfile={companyProfile}
      type={postType}
      postTitle={postTitle}
      postCustomButton={postCustomButton}
      postRedirectUrl={postRedirectUrl}
      viewerId={viewerId}
      viewerType={viewerType}
    />
  );
};

export default EditPost;
