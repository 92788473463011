import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const TextIcon = ({ color = colors.neutral[1100], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1 3.4375C1 2.73859 1 2.38913 1.11418 2.11347C1.26642 1.74593 1.55843 1.45392 1.92597 1.30168C2.20163 1.1875 2.55109 1.1875 3.25 1.1875H10.75C11.4489 1.1875 11.7984 1.1875 12.074 1.30168C12.4416 1.45392 12.7336 1.74593 12.8858 2.11347C13 2.38913 13 2.73859 13 3.4375M4.75 13.1875H9.25M7 1.1875V13.1875"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TextIcon;
