import { AxiosError } from 'axios';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APIResponse } from 'src/services/base.api.service';
import {
  TApplicant,
  TApplicantsListData,
  TApplicantsListRequest,
  TApplicantsListResponse,
  TApplicationDemoRequest,
  TApplicationDemoResponse,
  TAppliedProgram,
  TAppliedProgramsListData,
  TAppliedProgramsListRequest,
  TAppliedProgramsListResponse,
  TArchiveProgramRequest,
  TArchiveProgramResponse,
  TCloseProgramRequest,
  TCloseProgramResponse,
  TCreateApplicationRequest,
  TCreateApplicationResponse,
  TCreateProgramRequest,
  TCreateProgramResponse,
  TEditProgramMembersRequest,
  TEditProgramMembersResponse,
  TGetApplicationRequest,
  TGetApplicationResponse,
  TGetKeyMembersResponseData,
  TGetProgramApplication,
  TGetProgramData,
  TGetProgramDocumentRequest,
  TGetProgramDocumentResponse,
  TGetProgramMembersRequest,
  TGetProgramMembersResponse,
  TGetProgramRequest,
  TGetProgramResponse,
  TProgramKeyMember,
  TProgramList,
  TProgramListData,
  TProgramListRequest,
  TProgramListResponse,
  TProgramMember,
  TProgramMembersListData,
  TProgramMembersListRequest,
  TProgramMembersListResponse,
  TPromoteApplicationRequest,
  TPromoteApplicationResponse,
  TRespondToKeyMemberSelectionRequest,
  TRespondToKeyMemberSelectionResponse,
  TUploadApplicationFileAction,
  TUploadApplicationFileResponse,
  TUploadFileToS3Request,
  TUploadFileToS3Response,
  TUploadProgramFileAction,
  TUploadProgramFileResponse
} from 'src/services/apiEndpoint.types.ts';
import { ProgramAPIService } from 'src/services/program.service';
import { errorMessages } from 'src/constants';

import { initialState } from './program.slice.initialState';

import { AppDispatch } from '../store';
import { openPopup, startLoading, stopLoading } from '../common/common.slice';

export const programSlice = createSlice({
  name: 'program',
  initialState,
  reducers: {
    // Programs
    programListSuccess: (state, action: PayloadAction<TProgramListData>) => {
      let currentPrograms: TProgramList[] = JSON.parse(JSON.stringify(state?.programs)) || [];

      const { programs, currentPage, itemsPerPage, totalPages } = action.payload;

      if (currentPage === 1) {
        currentPrograms = programs;
      } else {
        programs.forEach((program: TProgramList) => {
          const id = program?.id;

          const indexInExistingPrograms = currentPrograms.findIndex(
            (program) => program?.id === id
          );

          if (indexInExistingPrograms < 0) {
            currentPrograms.push(program);
          }
        });
      }

      state.programs = currentPrograms;
      state.moreProgramsLeft = currentPage < totalPages;
      state.programsPageNumber = currentPage;
      state.programsPerPage = itemsPerPage;
    },
    programListError: () => { },
    incrementProgramsPage: (state, action: PayloadAction<number>) => {
      state.programsPageNumber = state.programsPageNumber + action.payload;
    },
    resetPrograms: (state) => {
      state.programsPageNumber = 1;
      state.moreProgramsLeft = true;
      state.programs = [];
    },

    // View program
    getProgramSuccess: (state, action: PayloadAction<TGetProgramData>) => {
      const programData: TGetProgramData = action.payload;

      state.viewProgram = programData;
    },
    getProgramError: () => { },
    resetViewProgram: (state) => {
      state.viewProgram = initialState.viewProgram;
    },

    //  Applicants
    getApplicantsSuccess: (state, action: PayloadAction<TApplicantsListData>) => {
      let currentApplicants: TApplicant[] = JSON.parse(JSON.stringify(state?.applicants)) || [];

      const { programApplications, currentPage, itemsPerPage, totalPages, stages } = action.payload;

      if (currentPage === 1) {
        currentApplicants = programApplications;
      } else {
        programApplications.forEach((application: TApplicant) => {
          const id = application?.id;

          const indexInExistingPrograms = currentApplicants.findIndex(
            (program) => program?.id === id
          );

          if (indexInExistingPrograms < 0) {
            currentApplicants.push(application);
          }
        });
      }

      state.applicants = currentApplicants;
      state.moreApplicantsLeft = currentPage < totalPages;
      state.applicantsPageNumber = currentPage;
      state.applicantsPerPage = itemsPerPage;
      state.viewSubmittedApplicationStages = stages;
    },
    getApplicantsError: () => { },
    incrementApplicantsPage: (state, action: PayloadAction<number>) => {
      state.applicantsPageNumber = state.applicantsPageNumber + action.payload;
    },
    resetApplicants: (state) => {
      state.applicantsPageNumber = 1;
      state.moreApplicantsLeft = true;
      state.applicants = [];
    },

    // View Application
    getApplicationSuccess: (state, action: PayloadAction<TGetProgramApplication>) => {
      const programData = action.payload;

      state.viewApplication = programData;
    },
    getApplicationError: () => { },
    resetViewApplication: (state) => {
      state.viewApplication = initialState.viewApplication;
    },

    // Program members
    geProgramMembersListSuccess: (state, action: PayloadAction<TProgramMembersListData>) => {
      const currentList: TProgramMember[] = JSON.parse(JSON.stringify(state?.programMembers)) || [];

      const { programMembers, currentPage, itemsPerPage, totalPages } = action.payload;

      programMembers.forEach((program: TProgramMember) => {
        const id = program?.id;

        const indexInExistingList = currentList.findIndex((program) => program?.id === id);

        if (indexInExistingList < 0) {
          currentList.push(program);
        }
      });

      state.programMembers = currentList;
      state.moreProgramMembersLeft = state.programMembersPageNumber < totalPages;
      state.programMembersPageNumber = currentPage;
      state.programMembersPerPage = itemsPerPage;
    },
    getProgramMembersError: () => { },
    incrementProgramMembersPage: (state, action: PayloadAction<number>) => {
      state.programMembersPageNumber = state.programMembersPageNumber + action.payload;
    },
    resetProgramMembers: (state) => {
      state.programMembersPageNumber = 1;
      state.moreProgramMembersLeft = true;
      state.programMembers = [];
    },

    // Applied programs
    getAppliedProgramsSuccess: (state, action: PayloadAction<TAppliedProgramsListData>) => {
      const currentList: TAppliedProgram[] =
        JSON.parse(JSON.stringify(state?.appliedPrograms)) || [];

      const { programs, currentPage, itemsPerPage, totalPages } = action.payload;

      programs.forEach((item: TAppliedProgram) => {
        const id = item?.id;

        const indexInExistingPrograms = currentList.findIndex((program) => program?.id === id);

        if (indexInExistingPrograms < 0) {
          currentList.push(item);
        }
      });

      state.appliedPrograms = currentList;
      state.moreAppliedProgramsLeft = state.appliedProgramsPageNumber < totalPages;
      state.applicantsPageNumber = currentPage;
      state.appliedProgramsPerPage = itemsPerPage;
    },
    getAppliedProgramsError: () => { },
    incrementAppliedProgramsPage: (state, action: PayloadAction<number>) => {
      state.appliedProgramsPageNumber = state.appliedProgramsPageNumber + action.payload;
    },
    resetAppliedPrograms: (state) => {
      state.appliedProgramsPageNumber = 1;
      state.moreAppliedProgramsLeft = true;
      state.appliedPrograms = [];
    },

    // Program key members
    // Program members
    getProgramKeyMembersListSuccess: (state, action: PayloadAction<TGetKeyMembersResponseData>) => {
      const currentList: TProgramKeyMember[] =
        JSON.parse(JSON.stringify(state?.programKeyMembers)) || [];

      const { companyKeyMembers, currentPage, itemsPerPage, totalPages } = action.payload;

      companyKeyMembers.forEach((item: TProgramKeyMember) => {
        const id = item?.id;

        const indexInExistingList = currentList.findIndex((program) => program?.id === id);

        if (indexInExistingList < 0) {
          currentList.push(item);
        }
      });

      state.programKeyMembers = currentList;
      state.moreProgramKeyMembersLeft = state.programMembersPageNumber < totalPages;
      state.programKeyMembersPageNumber = currentPage;
      state.programKeyMembersPerPage = itemsPerPage;
    },
    getProgramKeyMembersListError: () => { },
    incrementProgramKeyMembersPage: (state, action: PayloadAction<number>) => {
      state.programKeyMembersPageNumber = state.programKeyMembersPageNumber + action.payload;
    },
    resetProgramKeyMembers: (state) => {
      state.programKeyMembersPageNumber = 1;
      state.moreProgramKeyMembersLeft = true;
      state.programKeyMembers = [];
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  programListSuccess,
  programListError,
  incrementProgramsPage,
  resetPrograms,
  getProgramSuccess,
  getProgramError,
  resetViewProgram,
  getApplicantsSuccess,
  getApplicantsError,
  incrementApplicantsPage,
  resetApplicants,
  getApplicationSuccess,
  getApplicationError,
  resetViewApplication,
  geProgramMembersListSuccess,
  getProgramMembersError,
  incrementProgramMembersPage,
  resetProgramMembers,
  getAppliedProgramsSuccess,
  getAppliedProgramsError,
  incrementAppliedProgramsPage,
  resetAppliedPrograms,
  getProgramKeyMembersListSuccess,
  getProgramKeyMembersListError,
  incrementProgramKeyMembersPage,
  resetProgramKeyMembers
} = programSlice.actions;

export default programSlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;

  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] ||
        message ||
        errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const createProgram = (payload: TCreateProgramRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  try {
    const response: APIResponse<TCreateProgramResponse> =
      await new ProgramAPIService().createProgram(payload);

    if (response?.status === 200) {
      console.log(response, 'respomse')
      if (response?.data?.data?.data?.draft) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully added a new Draft!',
            popupType: 'success',
            navigateTo: '/my-company?tab=programs'
          })
        );
      } else {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully added a new program!',
            popupType: 'success',
            navigateTo: '/my-company?tab=programs'
          })
        );
      }
    }

    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const programList = (payload: TProgramListRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const response: APIResponse<TProgramListResponse> = await new ProgramAPIService().programList(
      payload
    );

    const { status, data } = response;
    if (status === 200 && data) {
      dispatch(programListSuccess(data?.data));
    }

    return response;
  } catch (err: any) {
    if (err.code === 'ERR_NETWORK') {
      console.log(err, 'errData');
      
      try {
        const response: APIResponse<TProgramListResponse> = await new ProgramAPIService().programList(
          payload
        );

        const { status, data } = response;
        if (status === 200 && data) {
          dispatch(programListSuccess(data?.data));
        }

        return response;
      } catch (err: any) {
        dispatch(programListError());
      }
    }
  } finally {
    dispatch(stopLoading());
  }
};

export const getProgram = (payload: TGetProgramRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  try {
    const response: APIResponse<TGetProgramResponse> = await new ProgramAPIService().getProgram(
      payload
    );

    const { status, data } = response;
    if (status === 200 && data?.data?.id) {
      dispatch(getProgramSuccess(data?.data));
    }
    console.log(response.message, 'response')

    return response;
  } catch (err: any) {
    if (err?.code === 'ERR_NETWORK') {
      try {
        const response: APIResponse<TGetProgramResponse> = await new ProgramAPIService().getProgram(
          payload
        );

        const { status, data } = response;
        if (status === 200 && data?.data?.id) {
          dispatch(getProgramSuccess(data?.data));
        }

        return response;
      } catch (err: any) {

      }
    }
    dispatch(getProgramError());
  } finally {
    dispatch(stopLoading());
  }
};

export const createApplication =
  (payload: TCreateApplicationRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TCreateApplicationResponse> =
        await new ProgramAPIService().createApplication(payload);

      if (response?.status === 200) {
        dispatch(resetAppliedPrograms())
        console.log(payload, 'draft')
        if (!payload.draft) {   
        dispatch(
          openPopup({
            popupMessage: 'You have successfully applied to this program!',
            popupType: 'success',
            navigateTo: '/my-profile?tab=appliedPrograms'
          })
        ); 
      } else {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully Created Draft of this program!',
            popupType: 'success',
            navigateTo: '/my-profile?tab=appliedPrograms'
          })
        );
      }
      }

      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const closeProgram = (payload: TCloseProgramRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  try {
    const response: APIResponse<TCloseProgramResponse> = await new ProgramAPIService().closeProgram(
      payload
    );

    if (response?.status === 200) {
      dispatch(
        openPopup({
          popupMessage: 'You have successfully closed this program.',
          popupType: 'success'
        })
      );
    }

    dispatch(resetPrograms());
    return response;
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const archiveProgram =
  (payload: TArchiveProgramRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TArchiveProgramResponse> =
        await new ProgramAPIService().archiveProgram(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully archived this program.',
            popupType: 'success'
          })
        );
      }
      dispatch(resetPrograms());
      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const getApplicants = (payload: TApplicantsListRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());

  try {
    const response: APIResponse<TApplicantsListResponse> =
      await new ProgramAPIService().applicantsList(payload);

    const { status, data } = response;
    if (status === 200 && data) {
      dispatch(getApplicantsSuccess(data?.data));
    }

    return response;
  } catch {
    dispatch(getApplicantsError());
  } finally {
    dispatch(stopLoading());
  }
};

export const getApplication =
  (payload: TGetApplicationRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TGetApplicationResponse> =
        await new ProgramAPIService().getApplication(payload);

      const { status, data } = response;
      if (status === 200 && data) {
        dispatch(getApplicationSuccess(data?.data));
      }

      return response;
    } catch {
      dispatch(getApplicationError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const getProgramMembers =
  (payload: TProgramMembersListRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TProgramMembersListResponse> =
        await new ProgramAPIService().programMembersList(payload);

      const { status, data } = response;
      if (status === 200 && data) {
        dispatch(geProgramMembersListSuccess(data?.data));
      }

      return response;
    } catch {
      dispatch(getProgramMembersError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const editProgramMembers =
  (payload: TEditProgramMembersRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TEditProgramMembersResponse> =
        await new ProgramAPIService().editProgramMembers(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully updated program members.',
            popupType: 'success'
          })
        );
      }
      // reset program members
      dispatch(resetProgramMembers());
      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const shareDemoInvite =
  (payload: TApplicationDemoRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TApplicationDemoResponse> =
        await new ProgramAPIService().createApplicationDemo(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage:
              "The demo invitation has been successfully send to the applicant's email address",
            popupType: 'success'
          })
        );
      }
      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const promoteApplication =
  (payload: TPromoteApplicationRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TPromoteApplicationResponse> =
        await new ProgramAPIService().promoteApplication(payload);

      if (response?.status === 200) {
        dispatch(
          openPopup({
            popupMessage: 'You have successfully passed this application to the next stage',
            popupType: 'success'
          })
        );
      }
      return response;
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };

export const appliedProgramsList =
  (payload: TAppliedProgramsListRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TAppliedProgramsListResponse> =
        await new ProgramAPIService().appliedProgramsList(payload);

      const { status, data } = response;
      if (status === 200 && data) {
        dispatch(getAppliedProgramsSuccess(data?.data));
      }

      return response;
    } catch {
      dispatch(programListError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const getProgramKeyMembers =
  (payload: TGetProgramMembersRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    try {
      const response: APIResponse<TGetProgramMembersResponse> =
        await new ProgramAPIService().keyMembersList(payload);

      const { status, data } = response;
      if (status === 200 && data) {
        dispatch(getProgramKeyMembersListSuccess(data?.data));
      }

      return response;
    } catch {
      dispatch(getProgramKeyMembersListError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const uploadFileToS3 =
  (payload: TUploadFileToS3Request) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TUploadFileToS3Response> =
        await new ProgramAPIService().uploadFileToS3({
          s3Key: payload.s3Key,
          data: payload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        console.info('success');
      }
    } catch { }
  };

export const getUploadProgramFile =
  (payload: TUploadProgramFileAction) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    const { data: fileData, ...uploadPayload } = payload;

    try {
      const response: APIResponse<TUploadProgramFileResponse> =
        await new ProgramAPIService().uploadProgramFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        console.log(data);
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };

        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
    } finally {
      dispatch(stopLoading());
    }
  };

export const getUploadApplicationFile =
  (payload: TUploadApplicationFileAction) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());

    const { data: fileData, ...uploadPayload } = payload;

    try {
      const response: APIResponse<TUploadApplicationFileResponse> =
        await new ProgramAPIService().uploadApplicationFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        console.log(data);
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };

        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
       try {
      const response: APIResponse<TUploadApplicationFileResponse> =
        await new ProgramAPIService().uploadApplicationFile(uploadPayload);

      const { status, data } = response;
      if (status === 200 && data) {
        console.log(data);
        const uploadFileToS3Payload: TUploadFileToS3Request = {
          s3Key: data?.data || '',
          data: fileData,
          includeAuthorizationHeaders: false
        };

        dispatch(uploadFileToS3(uploadFileToS3Payload));
      }

      return response;
    } catch {
      
    }
    } finally {
      dispatch(stopLoading());
    }
  };

export const getProgramDocument =
  (payload: TGetProgramDocumentRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status, data }: APIResponse<TGetProgramDocumentResponse> =
        await new ProgramAPIService().getDocument(payload);
      if (status === 200 && data?.data) {
        window.open(data?.data?.presignedUrl);
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const respondToKeyMemberSelection =
  (payload: TRespondToKeyMemberSelectionRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TRespondToKeyMemberSelectionResponse> =
        await new ProgramAPIService().respondToKeyMemberSelection(payload);
      if (status === 200) {
        return status;
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };
