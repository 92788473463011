import * as Yup from 'yup';
import { MONTH_YEAR } from 'src/constants/regex.constants';

const updateWorkExperienceValidationSchema = Yup.object().shape({
  employmentType: Yup.string().required('Please select your employment type.'),
  jobTitle: Yup.string().required('Please enter your  job title.'),
  companyName: Yup.string().required('Please enter the company name.'),
  start: Yup.string()
    .matches(MONTH_YEAR, 'Please select the start month and year.')
    .test('is time in the future', 'Start time cannot be in the future', function (value) {
      if (!value) {
        return;
      }
      const [startMonth, startYear] = (value || '').split('/');

      const startDate: number = new Date(parseInt(startYear), parseInt(startMonth) - 1).getTime();
      return new Date().getTime() >= startDate; // Return true if start date is greater than current time
    })
    .required('Please select the start month and year.')
});

export const updateWorkExperienceValidationEndSchema = Yup.object().shape({
  end: Yup.string()
    .matches(MONTH_YEAR, 'Please select the end month and year.')
    .test('is-greater-than-start', 'End time cannot be lesser than start time', function (value) {
      const { start } = this.parent;
      if (!start || !value) return true; // Skip validation if start or end is empty

      const [startMonth, startYear] = (start || '').split('/');
      const [endMonth, endYear] = (value || '').split('/');

      const startDate: number = new Date(startYear, parseInt(startMonth) - 1).getTime();
      const endDate: number = new Date(parseInt(endYear), parseInt(endMonth) - 1).getTime();
      return endDate >= startDate; // Return true if end date is greater than or equal to start date
    })
});

export default updateWorkExperienceValidationSchema;
