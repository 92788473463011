const ALPHANUMERIC = /^[a-zA-Z0-9\s]+$/;
const ALPHA_LOWERCASE = /[a-z]/;
const ALPHA_UPPERCASE = /[A-Z]/;
const EMAIL = /^[a-z0-9A-Z._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
const DECIMAL_NUMBER = /^[0-9]*\.?[0-9]*$/;
const MONTH_YEAR = /^(0[1-9]|1[0-2])\/\d{4}$/;
const PASSWORD = /[!@#$%^&*()_+{}[\]:;<>,.?~\\/"'`/\\-\s|=]/; // from https://owasp.org/www-community/password-special-characters
const WHOLE_NUMBER = /^[0-9]*$/;

export {
  ALPHANUMERIC,
  ALPHA_LOWERCASE,
  ALPHA_UPPERCASE,
  EMAIL,
  DECIMAL_NUMBER,
  MONTH_YEAR,
  PASSWORD,
  WHOLE_NUMBER
};
