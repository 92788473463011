import { Link } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import Button from 'src/components/button/Button';

import './topNavigation.scss';

const TopNavigation = () => {
  return (
    <div className="landing-page__top-navigation">
      <img src={hTractionLogoWithText} className="landing-page__top-navigation__logo" />
      <div className="landing-page__top-navigation__buttons">
        <Link to="/login">
          <Button
            className="landing-page__top-navigation__buttons__button"
            variant="secondary"
            size="small"
          >
            Login
          </Button>
        </Link>
        <Link to="/sign-up">
          <Button className="landing-page__top-navigation__buttons__button" size="small">
            Sign up
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default TopNavigation;
