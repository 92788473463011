import { ValidationError } from 'yup';

import { TValidateFormProps } from './validateForm.types';

const validateForm = async ({ values, schema, updateErrors }: TValidateFormProps) => {
  const errors = await schema
    .validate(values, { abortEarly: false })
    .then(() => {
      updateErrors({ errors: {} });
      return [];
    })
    .catch((validationError: any) => {
      const errors: Record<string, string> = {};

      validationError.inner.forEach((error: ValidationError) => {
        const field = error.path;
        if (field) {
          errors[field as string] = error.message;
        }
      });
      updateErrors({ errors });
      return validationError.errors;
    });

  return errors;
};

export default validateForm;
