import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Typography } from 'src/components/common';
import { useAppSelector } from 'src/store/hooks';

import './pageNotFound.scss';

const PageNotFound = () => {
  const isAuthorized: boolean = useAppSelector<boolean>((store) => store.auth.isAuthorized);

  const navigate = useNavigate();

  const handleHome = () => {
    if (isAuthorized) {
      navigate('/dashboard');
    } else {
      navigate('/');
    }
  };

  return (
    <div className="page-not-found">
      <div className="page-not-found__content">
        <Typography
          variant="display-3"
          fontWeight="bold"
          className="page-not-found__content__heading"
        >
          <span className="page-not-found__content__heading--highlight">404</span> Page Not Found!
        </Typography>
        <Typography as="p" variant="body-1" fontWeight="regular">
          The page you&apos;re looking for doesn&apos;t exist. Let&apos;s get you back on track.
        </Typography>
        <Button variant="primary" size="large" onClick={handleHome}>
          Go Home
        </Button>
      </div>
    </div>
  );
};

export default PageNotFound;
