import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const UniversityIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1 20.5002H19M4 17.5002V9.50022M8 17.5002V9.50022M12 17.5002V9.50022M16 17.5002V9.50022M18 6.50022L10.424 1.76522C10.2702 1.66908 10.1933 1.62102 10.1108 1.60227C10.0379 1.5857 9.96214 1.5857 9.88921 1.60227C9.80673 1.62102 9.72982 1.66908 9.576 1.76522L2 6.50022H18Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UniversityIcon;
