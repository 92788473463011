import { getToken, isSupported } from 'firebase/messaging';
import { useGetIdentity } from 'src/hooks';
import { useAppDispatch } from 'src/store/hooks';
import { registerDevice } from 'src/store/notifications/notifications.slice';

import { messaging } from './firebaseConfig';
const useGetToken = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();

  // Constants
  const { authenticatedId, loggedInUserType } = getIdentities();

  const getTokenFunc = async () => {
    const supported = await isSupported();

    if (!supported) {
      return;
    }

    try {
      const token = await getToken(messaging, {
        vapidKey: process.env.REACT_APP_FIREBASE_VAPID_KEY
      });

      if (authenticatedId) {
        // Send token to server
        dispatch(
          registerDevice({ userId: authenticatedId, userType: loggedInUserType, deviceId: token })
        );
      }
    } catch (e) {
      console.log(e);
    }
  };

  return getTokenFunc;
};

export default useGetToken;
