import { FC, Ref } from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Typography } from 'src/components/common';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import { componentSizeVariantsEnum } from 'src/constants';

import './editMarketDetails.scss';

import { TIdeaMarketDetailsData } from '../../ideaHub.types';
import { TEditIdeaFormValues } from '../IdeaDetails';

export type TEditMarketDetailsFormValues = TIdeaMarketDetailsData;

export type TEditMarketDetailsProps = TIdeaMarketDetailsData & {
  submitButtonRef: Ref<FormikProps<TEditIdeaFormValues>>;
  updateFormValues: ({ values }: { values: TEditIdeaFormValues }) => void;
  handleNext: () => void;
  goToNextTab: ({ fromEditDetails }: { fromEditDetails: boolean }) => void;
};

const EditMarketDetails: FC<TEditMarketDetailsProps> = ({
  marketSize,
  targetAudience,
  marketTrends,
  submitButtonRef,
  updateFormValues,
  handleNext,
  goToNextTab
}: TEditMarketDetailsProps) => {
  const initialValues: TEditMarketDetailsFormValues = {
    marketSize,
    targetAudience,
    marketTrends
  };

  const handleSubmit = (values: TEditIdeaFormValues) => {
    updateFormValues({ values });
    goToNextTab({ fromEditDetails: false });
  };

  return (
    <div className="edit-market-details">
      <Typography
        variant="subHeading-2"
        fontWeight="semiBold"
        className="edit-idea-edit-details__heading"
      >
        Market Details
      </Typography>

      {/* Market details form */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        innerRef={submitButtonRef}
        enableReinitialize
      >
        {({ values, setFieldValue }) => {
          return (
            <Form className="edit-market-details__form">
              {/* Market Size */}
              <TextAreaField
                label="Market Size"
                id="marketSize"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Elaborate on your idea with details."
                onChange={(e) => {
                  setFieldValue('marketSize', e.target.value);
                  updateFormValues({ values: { marketSize: e.target.value } });
                }}
                value={values.marketSize}
              />

              {/* Target Audience */}
              <TextAreaField
                label="Target Audience"
                id="targetAudience"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Describe the specific demographic or group your idea."
                onChange={(e) => {
                  setFieldValue('targetAudience', e.target.value);
                  updateFormValues({ values: { targetAudience: e.target.value } });
                }}
                value={values.targetAudience}
              />

              {/* Market Trends */}
              <TextAreaField
                label="Market Trends?"
                id="marketTrends"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Outline current and emerging trends relevant to your idea."
                onChange={(e) => {
                  setFieldValue('marketTrends', e.target.value);
                  updateFormValues({ values: { marketTrends: e.target.value } });
                }}
                value={values.marketTrends}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

EditMarketDetails.defaultProps = {
  marketSize: '',
  targetAudience: '',
  marketTrends: ''
};

export default EditMarketDetails;
