import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'src/components/button/Button';

import './scrollTopPagination.scss';
import { TScrollTopPaginationProps } from './scrollTopPagination.types';

import Loader from '../loader/Loader';

let elementScrolledBy: number = 50;

const ScrollTopPagination = ({
  id,
  children,
  className,
  onScrollTop,
  loading,
  scrollToBottomOnLoad,
  offset,
  moreItemsLeft = true,
  showLoader = true,
  ...rest
}: TScrollTopPaginationProps) => {
  const [counter, setCounter] = useState<number>(0);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const ele = infiniteScrollRef.current;

    if (!ele) {
      return;
    }

    if (ele) {
      const scrollTop = ele.scrollTop;
      const scrollHeight = ele.scrollHeight;
      const percentScrolled = scrollTop / scrollHeight;

      const isUserScrollingUp: boolean = scrollTop < elementScrolledBy;
      elementScrolledBy = scrollTop;

      if (isUserScrollingUp && percentScrolled < 0.25 && !loading) {
        setCounter((prev) => prev + 1);
      }
    }
  };

  const loadMoreItems = () => {
    if (moreItemsLeft && !loading) {
      setCounter((prev) => prev + 1);
    }
  };

  const scrollChatWindow = () => {
    const ele = infiniteScrollRef.current;

    if (ele && offset === 1) {
      const scrollHeight = ele.scrollHeight;
      setTimeout(() => {
        ele.scrollTo(0, scrollHeight);
      }, 250);
    }
  };

  useEffect(() => {
    if (counter) {
      onScrollTop();
    }
  }, [counter]);

  useEffect(() => {
    const ele = infiniteScrollRef.current;

    if (ele) {
      ele.addEventListener('scroll', handleScroll);
      return () => {
        ele.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    if (scrollToBottomOnLoad && infiniteScrollRef?.current && !loading) {
      scrollChatWindow();
    }
  }, [id, infiniteScrollRef?.current?.scrollHeight, loading]);

  return (
    <>
      <div
        className={classNames('scroll-top-pagination-component', className)}
        {...rest}
        ref={infiniteScrollRef}
      >
        {!loading && moreItemsLeft && (
          <Button
            className="scroll-top-pagination-component__load-more"
            variant="tertiary"
            size="extraSmall"
            onClick={loadMoreItems}
          >
            {moreItemsLeft ? 'Load more messages' : <>-</>}
          </Button>
        )}
        {loading && showLoader && <Loader />}
        {children}
      </div>
    </>
  );
};

export default ScrollTopPagination;
