import { useNavigate } from 'react-router-dom';
import Button from 'src/components/button/Button';
import { Card, Typography } from 'src/components/common';
import { TickMarkIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { formatDate, StorageUtils } from 'src/utils';

import './appliedProgramCard.scss';
import { TAppliedProgramCardProps } from './appliedProgramCard.types';

import ProfilePicture from '../../profilePicture/ProfilePicture';

const AppliedProgramCard = ({ id, Program, dataType, draftData }: TAppliedProgramCardProps) => {
  // Hooks
  const navigate = useNavigate();
  console.log(Program, 'ProgramProgram')

  const handleViewProgram = () => {
    navigate(`/view-my-application?id=${id}&programId=${Program?.id}&tab=programDetails`);
  };
  const handleCompleteDraft = () => {
    StorageUtils.set('ApplicationDraft', draftData)
    navigate(`/apply-to-program?id=${Program?.id}`)
  }
  return (
    <Card className="applied-program-card">
      <div className="applied-program-card__header">
        <div className="applied-program-card__header__left">
          <ProfilePicture url={''} fallback={'C'} />
          <div className="applied-program-card__header__left__user-info">
            <Typography as="p" variant="body-2" fontWeight="semiBold">
              {Program?.Company?.name}
            </Typography>
          </div>
        </div>
        <div className="applied-program-card__header__right">
          <div className="applied-program-card__header__right__stage">

            {dataType === 'Draft' 
            ? (
              <div>
                <TickMarkIcon color={colors.error[800]} />
                <Typography
                  as="span"
                  variant="caption"
                  fontWeight="medium"
                  style={{ color: colors.error[800] }}
                >
                  Draft
                </Typography>
                </div>
            ) 
          : (
              <div>
                <TickMarkIcon color={colors.success[800]} />
                <Typography
                  as="span"
                  variant="caption"
                  fontWeight="medium"
                  style={{ color: colors.success[800] }}
                >
                  Applied
                </Typography>
                </div>

            )}
          
          </div>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            style={{ color: colors.neutral[800] }}
          >
            {Program?.status}
          </Typography>
        </div>
      </div>
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        {Program?.title}
      </Typography>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1100] }}
      >
        Applied date: {formatDate({ dateString: Program?.updatedAt })}
      </Typography>
      {dataType === 'Draft' 
            ? (
              <div>
                <Button
        variant="secondary"
        size="extraSmall"
        onClick={handleCompleteDraft}
        className="applied-program-card__view-details"
      >
        complete Application
      </Button>
                </div>
            ) 
          : (
              <div>
                 <Button
        variant="secondary"
        size="extraSmall"
        onClick={handleViewProgram}
        className="applied-program-card__view-details"
      >
        View Details
      </Button>
                </div>

            )}
  
    </Card>
  );
};

export default AppliedProgramCard;
