import { Card, Typography } from 'src/components/common';
import { Form, Formik } from 'formik';
// import { PersonIcon, MailIcon, TextIcon } from 'src/components/common/common.icons';
import { PersonIcon, MailIcon } from 'src/components/common/common.icons';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { colors, componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { useToggleOpenModal } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getOtp } from 'src/store/externalApplication/externalApplication.slice';

import './applicantDetails.scss';
import applicantDetailsValidationSchema from './applicantDetails.validationSchema';
import { TApplicantDetailsProps, TApplicationDetailsFormValues } from './applicantDetails.types';

import ApplicantEmailVerification from '../applicantEmailVerification/ApplicantEmailVerification';
import { httpRequest } from 'src/services/commonApis';
import { openExistingUserModal } from 'src/store/common/common.slice';
import ActiveUserModal from '../ActiveUserModal/ActiveUserModal';

const applicantEmailVerificationModalId = 'applicantEmailVerificationModal';
export const externalApplicationFormLoaderId = ' externalApplicationFormLoader';

const ApplicantDetails = ({
  applicantDetails,
  updateApplicantDetails,
  handleNext
}: TApplicantDetailsProps) => {
  const initialValues: TApplicationDetailsFormValues = {
    name: '',
    email: '',
    mobile: ''
  };

  // Hooks
  const dispatch = useAppDispatch();
  const toggleOpenModal = useToggleOpenModal();
  const { modalId, loadingId } = useAppSelector((store) => store.common);

  // Constants
  const isApplicantEmailVerificationModalOpen = modalId === applicantEmailVerificationModalId;
  const programData = useAppSelector((store: any) => store.externalProgramSlice);
  const handleToggleEmailVerificationModal = () => {
    toggleOpenModal({ modalId: applicantEmailVerificationModalId });
  };
  
  const handleSubmit = async (values: TApplicationDetailsFormValues) => {
    console.log(values, 'valuess')
    const payload: any = {
      email: values?.email,
      name: values?.name,
      mobile: values?.mobile
    };
    const response = await httpRequest('auth/sign-in', 'POST', { email: values?.email, password: 'null' }, 'json', 'company');
    if (response?.message?.includes('exists')) {
      dispatch(openExistingUserModal({ isOpen: true, programId: programData?.viewProgram?.id }))
    } else {
      dispatch(getOtp(payload)).then((response?: number) => {
        if (response === 200) {
          handleToggleEmailVerificationModal();
          updateApplicantDetails(values);
        }
      });
    }
  };

  return (
    <>
    <ActiveUserModal />
    <Card className="apply-program-applicant-details">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        style={{ color: colors.neutral[1000] }}
      >
        Application Form
      </Typography>
      <Typography
        as="p"
        variant="caption"
        fontWeight="regular"
        style={{ color: colors.neutral[1000] }}
      >
        To apply for this program, please fill out the application form below. Make sure all fields
        are filled in correctly.
      </Typography>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={applicantDetailsValidationSchema}
      >
        {() => {
          return (
            <Form className="apply-program-applicant-details__form" noValidate>
              <InputField
                label="Full Name"
                id="name"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Full Name"
                startIcon={<PersonIcon />}
                type="text"
                required
              />
              <InputField
                label="Email Address"
                id="email"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                startIcon={<MailIcon />}
                type="email"
                required
              />
              <InputField
                label="Mobile "
                id="mobile"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Phone number"
                // startIcon={<TextIcon />}
                type="number"
                required
              />
              <Button
                variant="primary"
                size="small"
                type="submit"
                loading={loadingId === externalApplicationFormLoaderId}
              >
                Continue
              </Button>
            </Form>
          );
        }}
      </Formik>
      {isApplicantEmailVerificationModalOpen && (
        <ApplicantEmailVerification
          isModalOpen={isApplicantEmailVerificationModalOpen}
          handleCloseModal={handleToggleEmailVerificationModal}
          applicantDetails={applicantDetails}
          handleToggleEmailVerificationModal={handleToggleEmailVerificationModal}
          handleNext={handleNext}
        />
      )}
    </Card>
    </>
  );
};

export default ApplicantDetails;
