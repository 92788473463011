import TextArea from 'src/components/common/formFields/textArea/TextArea';

import cx from '../evaluation.module.scss';
import React from 'react';
import { TProgramData } from '../evaluation.types';

export default function OnlyOverallCritireaForm({ isUpdate, handleOverallCritrial, stageData }: { isUpdate: boolean, handleOverallCritrial: (e:React.ChangeEvent<HTMLInputElement>)=> void, stageData: TProgramData | undefined }) {
  return (
    <div className={`${cx.evaluationForm}`} style={{ padding: '0px 20px 20px 20px' }}>
      <div className={`${cx.commentBox}`}>
        <div className={cx.headingContainer}>
          <input
            type="checkbox"
            disabled={isUpdate}
            className={cx.checkBox}
            id='overallComment'
          checked={stageData?.overallComment || false}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleOverallCritrial(e)}
          />
          <label className="form_label" htmlFor="ratingIndex">Overall Comment Box</label>
        </div>
        <div className={cx.textAreaContainer}>
          <TextArea placeholder='This will be a represtation where you can provide additional comments.' disabled={true} />
        </div>
      </div>
    </div>
  )
}
