import { HTMLAttributes, ReactNode, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import Button from 'src/components/button/Button';

import './infiniteScroll.scss';

import Loader from '../loader/Loader';

export type TInfiniteScroll = {
  children: ReactNode;
  onScrollEnd: () => void;
  moreItemsLeft?: boolean;
  loading?: boolean;
  showLoader?: boolean;
  showFooter?: boolean;
} & HTMLAttributes<HTMLDivElement>;

let elementScrolledBy: number = 0;

const InfiniteScroll = ({
  children,
  className,
  onScrollEnd,
  loading,
  moreItemsLeft = true,
  showLoader = true,
  showFooter = true,
  ...rest
}: TInfiniteScroll) => {
  const [counter, setCounter] = useState<number>(0);
  const infiniteScrollRef = useRef<HTMLDivElement>(null);

  const handleScroll = () => {
    const ele = infiniteScrollRef.current;

    if (!ele) {
      return;
    }

    if (ele) {
      const height = ele.offsetHeight;
      const scrollHeight = ele.scrollHeight;
      const scrollTop = ele.scrollTop;

      const isUserScrollingDown: boolean = scrollTop > elementScrolledBy;
      elementScrolledBy = scrollTop;

      if (height + scrollTop >= scrollHeight * 0.75 && isUserScrollingDown) {
        setCounter((prev) => prev + 1);
      }
    }
  };

  const loadMoreItems = () => {
    if (moreItemsLeft) {
      setCounter((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (counter) {
      onScrollEnd();
    }
  }, [counter]);

  useEffect(() => {
    const ele = infiniteScrollRef.current;

    if (ele) {
      ele.addEventListener('scroll', handleScroll);
      return () => {
        ele.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  return (
    <>
      <div
        className={classNames('infinite-scroll-component', className)}
        {...rest}
        ref={infiniteScrollRef}
      >
        {children}
        {loading && showLoader && <Loader />}
        {!loading && showFooter && moreItemsLeft && (
          <Button
            className="infinite-scroll-component__footer"
            variant="tertiary"
            size="extraSmall"
            onClick={loadMoreItems}
          >
            {moreItemsLeft ? 'Load more items' : <>&bull;</>}
          </Button>
        )}
      </div>
    </>
  );
};

export default InfiniteScroll;
