import cx from '../evaluation.module.scss';

export default function EmptyStageDetail({ title }: { title: string }) {
  return (
    <div className={`${cx.gradesContentBox}`}>
      <div className={`${cx.critiriaHeader}`}>
        <div className={`${cx.evaluationForm} ${cx.empty}`}>
          <p className={`${cx.emptyDetails}`}>{title}</p>
        </div>
      </div>
    </div>
  )
}
