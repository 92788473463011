import { useMemo } from 'react';

import './dynamicField.scss';
import { TDynamicFieldProps } from './dynamicField.types';
import TextField from './TextField';
import TextArea from './TextArea';
import TextEditor from './TextEditor';
import Phone from './PhoneNumber';
import AddLabel from './AddLabel';
import DateTimeSelection from './DateTimeSelection';
import UploadField from './UploadField';
import OptionsField from './OptionsField';
import TableFields from './TableFields';
import CountryField from './countryfield';

const DynamicField = ({
  id,
  label,
  placeholder,
  type,
  sectionId,
  required,
  options,
  validations,
  response,
  files,
  hasCharacterLimit,
  openChangeFieldModal,
  openDeleteFieldModal,
  handleUpdateField,
  errors
}: TDynamicFieldProps) => {
  const fieldComponent = useMemo(() => {
    const fieldProps = {
      id,
      sectionId,
      label,
      placeholder,
      type,
      required,
      options,
      validations,
      response,
      files,
      hasCharacterLimit,
      openChangeFieldModal,
      openDeleteFieldModal,
      handleUpdateField,
      errors
    };
    console.log(type, 'type')
    if (type === 'textInput') {
      return <TextField {...fieldProps} />;
    }
    if (type === 'textArea') {
      return <TextArea {...fieldProps} />;
    }
    if (type === 'address') {
      return <CountryField {...fieldProps} />;
    }
    if (type === 'textEditor') {
      return <TextEditor {...fieldProps} />;
    }
    if (type === 'phoneNumber') {
      return <Phone {...fieldProps} />;
    }
    if (type === 'dropdown') {
      return (
        <OptionsField
          fieldTitle="Dropdown field"
          fieldPlaceholder="Multiple choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'tableColumns') {
      return (
        <TableFields
          fieldTitle="Table Heading"
          fieldPlaceholder="Table Heading"
          {...fieldProps}
        />
      );
    }
    if (type === 'singleChoice') {
      return (
        <OptionsField
          fieldTitle="Single choice field"
          fieldPlaceholder="Single choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'multipleChoice') {
      return (
        <OptionsField
          fieldTitle="Multiple choice field"
          fieldPlaceholder="Multiple choice field title"
          {...fieldProps}
        />
      );
    }
    if (type === 'label') {
      return <AddLabel {...fieldProps} />;
    }
    if (type === 'dateSelection') {
      return (
        <DateTimeSelection
          {...fieldProps}
          fieldTitle="Date selection field"
          placeholder="Select date"
          inputType="date"
        />
      );
    }
    if (type === 'timeSelection') {
      return (
        <DateTimeSelection
          {...fieldProps}
          fieldTitle="Time selection field"
          placeholder="Select time"
          inputType="time"
        />
      );
    }
    if (type === 'fileUpload') {
      return <UploadField {...fieldProps} />;
    }
  }, [
    id,
    label,
    placeholder,
    type,
    sectionId,
    required,
    options,
    hasCharacterLimit,
    validations,
    response,
    files,
    errors
  ]);

  return fieldComponent;
};

export default DynamicField;
