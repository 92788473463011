import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { Button, Tooltip, Typography, VerticalSeparator } from 'src/components/common';
import { colors } from 'src/constants';
import { textEllipsis } from 'src/utils';

import { TUserInfoProps } from './userInfo.types';
import './userInfo.scss';

const UserInfo = ({
  userId,
  name,
  role,
  email,
  profilePic,
  showRemoveButton = false,
  onRemove
}: TUserInfoProps) => {
  // Utils
  const nameWitEllipsis = textEllipsis({ charLimit: showRemoveButton ? 10 : 15, text: name });
  const roleWitEllipsis = textEllipsis({ charLimit: showRemoveButton ? 10 : 15, text: role });
  const emailWitEllipsis = textEllipsis({ charLimit: showRemoveButton ? 20 : 25, text: email });

  const handleRemoveUser = () => {
    if (onRemove) {
      onRemove({ userId });
    }
  };

  return (
    <div className="pd-discussion-user-info">
      <ProfilePicture
        className="pd-discussion-user-info__profile-pic"
        url={profilePic}
        fallback={name?.[0]}
      />

      <div className="pd-discussion-user-info__details">
        <div className="pd-discussion-user-info__details__row">
          <Tooltip text={name} conditionToDisplay={nameWitEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="medium"
              style={{ color: colors?.neutral?.[1100] }}
              className="pd-discussion-user-info__details__row__name"
            >
              {nameWitEllipsis.text}
            </Typography>
          </Tooltip>
          <VerticalSeparator />
          <Tooltip text={role} conditionToDisplay={roleWitEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="span"
              variant="caption"
              fontWeight="regular"
              className="pd-discussion-user-info__details__row__role"
              style={{ color: colors?.neutral?.[800] }}
            >
              {roleWitEllipsis.text}
            </Typography>
          </Tooltip>
        </div>

        <Tooltip text={email} conditionToDisplay={emailWitEllipsis?.hasEllipsis} limitWidth>
          <Typography
            as="p"
            variant="caption"
            fontWeight="regular"
            style={{ color: colors?.neutral?.[800] }}
            className="pd-discussion-user-info__details__email"
          >
            {emailWitEllipsis.text}
          </Typography>
        </Tooltip>
      </div>

      {showRemoveButton && (
        <Button
          variant="tertiary"
          size="extraSmall"
          className="pd-discussion-user-info__remove-button"
          onClick={handleRemoveUser}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default UserInfo;
