import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const ComingSoonIcon = ({ color = colors.primary[600], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="39"
      height="25"
      viewBox="0 0 39 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.41551 9.17422C3.97622 9.26859 3.6224 9.52407 3.35334 9.94096C3.08457 10.3578 2.93033 10.886 2.89042 11.5256C2.80764 12.8551 3.2659 13.4124 4.26596 13.1976C4.56803 13.1328 4.86342 13.0275 5.15244 12.8812C5.44095 12.7353 5.73268 12.5717 6.0266 12.3904L5.92805 13.9687C5.34217 14.3467 4.68949 14.6127 3.96985 14.7675C2.93876 14.989 2.16732 14.8564 1.65512 14.3696C1.14287 13.8827 0.922633 13.0675 0.994083 11.9238C1.03868 11.2083 1.21087 10.5515 1.51122 9.95229C1.81131 9.35309 2.22028 8.85393 2.73869 8.4553C3.25682 8.05616 3.8538 7.78416 4.5296 7.6388C5.26801 7.48011 5.96354 7.49096 6.61618 7.67082L5.95888 9.26218C5.71421 9.19666 5.46811 9.15007 5.22098 9.1225C4.97354 9.09489 4.70525 9.11188 4.41551 9.17422Z"
        fill={color}
      />
      <path
        d="M13.3717 9.23624C13.2992 10.3987 12.962 11.3433 12.3606 12.0691C11.7589 12.7954 10.9146 13.2751 9.82753 13.5089C8.75578 13.739 7.95332 13.6115 7.41943 13.1263C6.88578 12.6409 6.6554 11.8168 6.72784 10.6544C6.79953 9.5043 7.13856 8.56507 7.74489 7.83632C8.35123 7.10753 9.19654 6.62659 10.2804 6.39375C11.3674 6.16018 12.1728 6.28411 12.6959 6.76552C13.2192 7.24697 13.4444 8.07039 13.3717 9.23624ZM8.67101 10.2461C8.58774 11.5822 9.00558 12.1514 9.92433 11.9538C10.3917 11.8533 10.7482 11.6167 10.9944 11.2437C11.2405 10.8707 11.3851 10.3407 11.4277 9.6536C11.4709 8.96373 11.3881 8.47885 11.1796 8.19874C10.9712 7.91859 10.6396 7.82744 10.185 7.92499C9.25987 8.1241 8.75503 8.89772 8.67101 10.2461Z"
        fill={color}
      />
      <path
        d="M16.8867 11.8988L15.7999 7.2223L15.758 7.23141C15.7706 8.0649 15.7622 8.71635 15.7329 9.18567L15.5456 12.1871L13.9102 12.5384L14.3413 5.62847L16.799 5.10075L17.918 9.70014L17.9555 9.69223L19.6587 4.48626L22.121 3.95703L21.6901 10.8665L19.9937 11.2311L20.1827 8.20164C20.1926 8.04398 20.2061 7.86881 20.2226 7.67539C20.2392 7.48244 20.2896 7.00672 20.3737 6.24885L20.3318 6.25772L18.6436 11.5214L16.8867 11.8988Z"
        fill={color}
      />
      <path
        d="M22.6836 10.6518L23.1147 3.74241L24.9697 3.34375L24.5386 10.2532L22.6836 10.6518Z"
        fill={color}
      />
      <path
        d="M32.0407 8.64234L29.6203 9.16218L27.4288 4.83037L27.3869 4.83972C27.3983 5.59214 27.3913 6.17066 27.3662 6.57374L27.1718 9.68847L25.5366 10.0398L25.9676 3.13012L28.3786 2.61199L30.565 6.87975L30.593 6.87386C30.5928 6.18693 30.6059 5.63211 30.6322 5.20981L30.8271 2.08597L32.4719 1.73242L32.0407 8.64234Z"
        fill={color}
      />
      <path
        d="M36.034 3.79225L38.9824 3.15879L38.7501 6.88283C37.9324 7.33198 37.0437 7.65967 36.0843 7.86614C35.0312 8.09231 34.2367 7.95828 33.7006 7.46453C33.1642 6.97031 32.9319 6.15134 33.0033 5.00765C33.0728 3.89206 33.4414 2.9568 34.1095 2.20092C34.7775 1.44453 35.6782 0.944892 36.8123 0.701462C37.242 0.608809 37.6453 0.56274 38.0218 0.5625C38.398 0.5625 38.7244 0.59504 38.9999 0.660085L38.3243 2.25069C37.8476 2.11077 37.3149 2.10385 36.7261 2.23052C36.1872 2.34629 35.7595 2.61315 35.4429 3.03099C35.1266 3.44859 34.9477 3.9867 34.9066 4.64527C34.8664 5.2913 34.9689 5.75497 35.214 6.03632C35.4591 6.31794 35.8324 6.40467 36.334 6.29674C36.608 6.2381 36.862 6.15706 37.0961 6.05382L37.1636 4.97172L35.944 5.23389L36.034 3.79225Z"
        fill={color}
      />
      <path
        d="M8.81312 20.708C8.77764 21.2742 8.60418 21.8074 8.29303 22.3075C7.98134 22.8079 7.55068 23.2373 7.00029 23.5956C6.45013 23.9541 5.81495 24.2108 5.09477 24.3655C4.4936 24.4948 3.99249 24.5604 3.5904 24.5626C3.18804 24.5649 2.77342 24.5072 2.34619 24.3894L2.4832 22.1912C2.93355 22.3329 3.40514 22.4174 3.89888 22.4447C4.39263 22.4721 4.84942 22.4408 5.26925 22.3504C5.63143 22.2728 5.90072 22.1523 6.07739 21.9889C6.25403 21.8256 6.34888 21.6443 6.36121 21.4444C6.36909 21.3195 6.34172 21.2175 6.27989 21.1382C6.21779 21.0593 6.11435 20.9882 5.97042 20.9246C5.82605 20.8613 5.43777 20.7514 4.80605 20.5957C4.23445 20.4515 3.80945 20.2843 3.53081 20.0933C3.2524 19.9028 3.05234 19.6582 2.93112 19.3598C2.80939 19.0614 2.76284 18.6872 2.79092 18.2378C2.8434 17.3969 3.18685 16.6763 3.82103 16.0756C4.45521 15.4754 5.30127 15.0615 6.35922 14.8344C7.29348 14.6333 8.23265 14.6474 9.17678 14.8758L8.31102 16.9409C7.49157 16.731 6.77508 16.6916 6.1621 16.8234C5.84526 16.8917 5.61119 16.9971 5.46017 17.1405C5.30915 17.2841 5.22831 17.4391 5.21797 17.6054C5.20663 17.7845 5.28843 17.9249 5.46263 18.0272C5.63684 18.1294 6.11778 18.2807 6.9052 18.4813C7.66108 18.668 8.1775 18.9317 8.45494 19.2724C8.73204 19.6137 8.85155 20.0921 8.81312 20.708Z"
        fill={color}
      />
      <path
        d="M18.3871 16.8695C18.2912 18.4054 17.846 19.6534 17.0512 20.6125C16.2564 21.5719 15.1408 22.2061 13.7044 22.5146C12.2884 22.8191 11.228 22.6506 10.5229 22.0092C9.81777 21.3682 9.51299 20.2794 9.60907 18.7435C9.70367 17.2238 10.1516 15.9828 10.9528 15.02C11.754 14.0574 12.8706 13.422 14.3031 13.1142C15.739 12.8055 16.8031 12.9691 17.4944 13.605C18.1855 14.2411 18.4831 15.3291 18.3871 16.8695ZM12.1763 18.2039C12.0665 19.9692 12.6184 20.7211 13.8325 20.4602C14.4499 20.3277 14.921 20.0148 15.2462 19.522C15.5714 19.029 15.7621 18.3288 15.8188 17.4214C15.876 16.5096 15.7666 15.8687 15.4911 15.4987C15.2156 15.1286 14.7776 15.0084 14.1767 15.1375C12.9544 15.4001 12.2877 16.4223 12.1763 18.2039Z"
        fill={color}
      />
      <path
        d="M28.0828 14.7875C27.987 16.3234 27.5418 17.5713 26.7469 18.5305C25.9521 19.4899 24.8365 20.124 23.4001 20.4325C21.9842 20.7368 20.9238 20.5685 20.2187 19.9272C19.5135 19.2859 19.2088 18.1974 19.3049 16.6615C19.3995 15.1418 19.8474 13.9006 20.6486 12.9379C21.4498 11.9751 22.5664 11.3397 23.9988 11.0322C25.4347 10.7235 26.4991 10.8871 27.1902 11.523C27.8813 12.1591 28.1789 13.2471 28.0828 14.7875ZM21.8721 16.1219C21.7622 17.8869 22.3141 18.6391 23.5283 18.3782C24.1457 18.2457 24.6168 17.9328 24.942 17.44C25.2672 16.947 25.4579 16.2468 25.5146 15.3394C25.5717 14.4275 25.4623 13.7867 25.1869 13.4166C24.9114 13.0466 24.4734 12.9263 23.8727 13.0554C22.6502 13.3181 21.9835 14.3401 21.8721 16.1219Z"
        fill={color}
      />
      <path
        d="M37.6421 17.2485L34.4441 17.9354L31.5487 12.2122L31.493 12.2241C31.5077 13.2189 31.4988 13.983 31.4658 14.5156L31.2091 18.6306L29.0483 19.0948L29.6177 9.96551L32.8031 9.28108L35.6919 14.9197L35.7291 14.9118C35.7286 14.0041 35.7459 13.2714 35.7809 12.7136L36.0381 8.586L38.2114 8.11914L37.6421 17.2485Z"
        fill={color}
      />
    </svg>
  );
};

export default ComingSoonIcon;
