import { colors } from 'src/constants/colors';
import { TSvgIcon } from 'src/types/common.types';

const PictureIcon = ({ color = colors.neutral[700], ...rest }: TSvgIcon) => {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1 4.7625C1 3.6424 1 3.08234 1.21799 2.65452C1.40973 2.27819 1.71569 1.97223 2.09202 1.78049C2.51984 1.5625 3.0799 1.5625 4.2 1.5625H9.8C10.9201 1.5625 11.4802 1.5625 11.908 1.78049C12.2843 1.97223 12.5903 2.27819 12.782 2.65452C13 3.08234 13 3.6424 13 4.7625V10.3625C13 11.4826 13 12.0427 12.782 12.4705C12.5903 12.8468 12.2843 13.1528 11.908 13.3445C11.4802 13.5625 10.9201 13.5625 9.8 13.5625H4.2C3.0799 13.5625 2.51984 13.5625 2.09202 13.3445C1.71569 13.1528 1.40973 12.8468 1.21799 12.4705C1 12.0427 1 11.4826 1 10.3625V4.7625Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 5.89583L3 9.5625H11L8 4.5625L6.33333 6.89583L5 5.89583Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PictureIcon;
