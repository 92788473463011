import { useEffect, createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import classNames from 'classnames';
import { Loader, Separator, TDropdownOptions, Typography } from 'src/components/common';
import Header from 'src/components/header/Header';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useUrlParamValue, useGetIdentity, useCopyToClipboard } from 'src/hooks';
import {
  FilterResultCards,
  Filters,
  TSearchQueryTypes,
  TSearchResultTypes
} from 'src/components/search';
import UserCard from 'src/components/search/userCard/UserCard';
import {
  TPost,
  TSearchAnnouncementRequest,
  TSearchCompanyRequest,
  TSearchMentorInvestorRequest,
  TSearchPeopleRequest,
  TSearchPostsRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  decreasePageNumber,
  handleFollowSearchResult,
  increasePageNumber,
  searchAnnouncement,
  searchCompany,
  searchMentorInvestor,
  searchPeople,
  searchPosts,
  updatePageNumber
} from 'src/store/search/search.slice';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import { TUserTypes } from 'src/constants/user.constants';
import useHandleFollow from 'src/components/posts/handleFollow/useHandleFollow';
import { SingleChevronIcon } from 'src/components/common/common.icons';

import './search.scss';

export const SearchResultsContext = createContext(false);

const Search = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const { copyToClipboard } = useCopyToClipboard();
  const handleFollow = useHandleFollow();

  const { loggedInUserType, authenticatedId, loggedInUserId, loggedInCompanyId } = getIdentities();

  const {
    people: searchPeopleResult,
    totalPeople,
    mentorInvestors: searchMentorInvestorResult,
    totalMentorInvestor,
    companies: companiesResult,
    totalCompanies,
    announcements: searchAnnouncementsResult,
    posts: searchPostsResult,
    totalAnnouncements,
    totalPosts,
    pageNumber,
    totalPages
  } = useAppSelector((store) => store.search);
  const loading: boolean = useAppSelector((store) => store.common.loading);

  const query = paramValue({ paramName: 'query' });
  const location = paramValue({ paramName: 'location' });
  const connection = paramValue({ paramName: 'connection' });
  const company = paramValue({ paramName: 'company' });
  const industry = paramValue({ paramName: 'industry' });
  const postedIn = paramValue({ paramName: 'postedIn' });
  const postedBy = paramValue({ paramName: 'postedBy' });
  const resultType = paramValue({ paramName: 'resultType' }) as unknown as TSearchResultTypes;

  const showPeopleCards = resultType === 'all' || resultType === 'people';
  const showMentorInvestorCards = resultType === 'all' || resultType === 'mentor-investor';
  const showCompanyCards = resultType === 'all' || resultType === 'company';
  const showAnnouncementCards = resultType === 'all' || resultType === 'announcement';
  const showPostCards = resultType === 'all' || resultType === 'post';
  const showPagination = resultType !== 'all' && totalPages > 0;

  const locationArray: string[] = location ? location.split(',') : [];
  const companiesArray: string[] = company ? company.split(',') : [];
  const industriesArray: string[] = industry ? industry.split(',') : [];

  const currentParams = {
    resultType,
    location,
    connection,
    industry,
    postedIn,
    postedBy,
    company
  };

  const updateParams = ({ type, value }: { type: TSearchQueryTypes; value: string }) => {
    let path: string = `/search?query=${query}`;
    dispatch(updatePageNumber(1));

    if (type === 'resultType') {
      path += `&resultType=${value}`;
    } else {
      Object.keys(currentParams).forEach((key) => {
        const currentValue = currentParams[key as keyof typeof currentParams];
        if (key === type) {
          path += `&${key}=${encodeURIComponent(value)}`;
        } else if (currentValue) {
          path += `&${key}=${encodeURIComponent(currentValue)}`;
        }
      });
    }

    navigate(path);
  };

  const fetchResults = () => {
    const userCardsPageNumber = resultType !== 'all' ? pageNumber : 1;
    const postCardsPageNumber = resultType !== 'all' ? pageNumber : 1;
    const userCardsItemsPerPage = resultType !== 'all' ? 6 : 4;
    const postCardsItemsPerPage = resultType !== 'all' ? 4 : 2;
    if (showPeopleCards) {
      const payload: TSearchPeopleRequest = {
        userId: authenticatedId,
        userType: loggedInUserType,
        filterName: query || '',
        limit: userCardsItemsPerPage,
        offset: userCardsPageNumber,
        ...(locationArray?.length > 0 && { location: locationArray }),
        ...(connection && { connectionsType: connection })
      };

      dispatch(searchPeople(payload));
    }
    if (showMentorInvestorCards) {
      const payload: TSearchMentorInvestorRequest = {
        userId: authenticatedId,
        userType: loggedInUserType,
        filterName: query || '',
        limit: userCardsItemsPerPage,
        offset: userCardsPageNumber,
        ...(locationArray?.length > 0 && { location: locationArray }),
        ...(connection && { connectionsType: connection })
      };

      dispatch(searchMentorInvestor(payload));
    }
    if (showCompanyCards) {
      const payload: TSearchCompanyRequest = {
        userId: authenticatedId,
        userType: loggedInUserType,
        filterName: query || '',
        limit: userCardsItemsPerPage,
        offset: userCardsPageNumber,
        ...(locationArray?.length > 0 && { location: locationArray }),
        ...(companiesArray?.length > 0 && { companyType: companiesArray }),
        ...(industriesArray?.length > 0 && { industry: industriesArray })
      };

      dispatch(searchCompany(payload));
    }
    if (showAnnouncementCards) {
      const postedByArray: string[] = (postedBy || '').split(',');
      const payload: TSearchAnnouncementRequest = {
        userId: authenticatedId,
        text: query || '',
        userType: loggedInUserType,
        postsPerPage: postCardsItemsPerPage,
        pageNumber: postCardsPageNumber,
        ...(postedBy && { type: postedByArray }),
        ...(postedIn && { timebound: postedIn })
      };

      dispatch(searchAnnouncement(payload));
    }
    if (showPostCards) {
      const postedByArray: string[] = (postedBy || '').split(',');
      const payload: TSearchPostsRequest = {
        userId: authenticatedId,
        text: query || '',
        userType: loggedInUserType,
        postsPerPage: postCardsItemsPerPage,
        pageNumber: postCardsPageNumber,
        ...(postedBy && { role: postedByArray }),
        ...(postedIn && { timebound: postedIn })
      };

      dispatch(searchPosts(payload));
    }
  };

  const copyPostLink = (postId: string) => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const redirectToViewPost = ({
    postId,
    ownerId,
    post
  }: {
    postId: string;
    ownerId: string;
    post: TPost;
  }) => {
    const creatorType = post?.userType[0] as TUserTypes;

    let isOwner = false;

    if (creatorType === 'Company' && loggedInUserType === 'User') {
      // Useful when the user has created this post using their alternate company profile
      isOwner = false;
    } else {
      isOwner = creatorType === 'User' ? ownerId === loggedInUserId : ownerId === loggedInCompanyId;
    }

    const postRoute = isOwner ? 'view-post-owned' : 'view-post';

    navigate(`/${postRoute}?postId=${postId}&viewAs=${loggedInUserType}`);
  };

  const handlePagination = ({ selected }: { selected: number }) => {
    dispatch(updatePageNumber(selected + 1));
  };

  const handleIncreasePageNumber = () => {
    dispatch(increasePageNumber());
  };

  const handleDecreasePageNumber = () => {
    dispatch(decreasePageNumber());
  };

  useEffect(() => {
    fetchResults();
  }, [window.location.search, pageNumber]);

  return (
    <SearchResultsContext.Provider value={true}>
      <div className="main-search">
        <Header />
        <div className="main-search__body">
          <div className="main-search__body__header">
            <Typography variant="subHeading-2" fontWeight="semiBold">
              Search for: {query}
            </Typography>
            <Filters updateParams={updateParams} />
          </div>
          {loading && <Loader />}
          {showPeopleCards && searchPeopleResult?.length > 0 && !loading && (
            <>
              <FilterResultCards
                totalResults={totalPeople}
                resultType={resultType}
                title="Aspiring Entrepreneur and Entrepreneur"
                onFetchAll={() => {
                  updateParams({ type: 'resultType', value: 'people' });
                }}
                content={searchPeopleResult.map((person, index) => {
                  const { user, follows } = person;
                  return (
                    <UserCard
                      key={index}
                      follows={follows}
                      cardType="User"
                      searchResultType="people"
                      {...user}
                    />
                  );
                })}
              />
              <Separator />
            </>
          )}

          {showMentorInvestorCards && searchMentorInvestorResult?.length > 0 && !loading && (
            <>
              <FilterResultCards
                totalResults={totalMentorInvestor}
                resultType={resultType}
                title="Mentors & Investors"
                onFetchAll={() => {
                  updateParams({ type: 'resultType', value: 'mentor-investor' });
                }}
                content={searchMentorInvestorResult.map((personItem, index) => {
                  const { user, follows } = personItem;
                  return (
                    <UserCard
                      key={index}
                      follows={follows}
                      cardType="User"
                      searchResultType="mentorInvestors"
                      {...user}
                    />
                  );
                })}
              />
              <Separator />
            </>
          )}

          {showCompanyCards && companiesResult?.length > 0 && !loading && (
            <>
              <FilterResultCards
                totalResults={totalCompanies}
                resultType={resultType}
                title="Companies"
                onFetchAll={() => {
                  updateParams({ type: 'resultType', value: 'company' });
                }}
                content={companiesResult.map((companyItem, index) => {
                  const { comapny, follows } = companyItem;
                  return (
                    <UserCard
                      key={index}
                      follows={follows}
                      role=""
                      cardType="Company"
                      profilePic={comapny?.logo}
                      searchResultType="companies"
                      {...comapny}
                    />
                  );
                })}
              />
              <Separator />
            </>
          )}

          {showAnnouncementCards && searchAnnouncementsResult?.length > 0 && !loading && (
            <>
              <FilterResultCards
                className={classNames({
                  'filter-result-cards--is-announcement': resultType === 'announcement'
                })}
                totalResults={totalAnnouncements}
                resultType={resultType}
                title="Announcements"
                onFetchAll={() => {
                  updateParams({ type: 'resultType', value: 'announcement' });
                }}
                content={searchAnnouncementsResult.map((announcementItem, index) => {
                  const isNotOwner: boolean = announcementItem?.user?.id !== authenticatedId;
                  const postId = announcementItem?.post?.id;
                  const following = announcementItem?.followUserOrCompany;
                  const viewerId = loggedInUserType === 'User' ? loggedInUserId : loggedInCompanyId;
                  const followUserType = announcementItem?.userType[0] as TUserTypes;
                  const followUserId = announcementItem?.user?.id;
                  const followName = announcementItem?.user?.name;

                  const postCardDropdownItems: TDropdownOptions[] = [
                    {
                      label: 'Copy announcement link',
                      onClick: () => {
                        copyPostLink(postId);
                      }
                    },
                    {
                      label: 'View announcement',
                      onClick: () => {
                        redirectToViewPost({
                          postId,
                          ownerId: announcementItem?.user?.id,
                          post: announcementItem
                        });
                      }
                    }
                  ];

                  if (isNotOwner) {
                    postCardDropdownItems.push({
                      label: `${following ? 'Unfollow' : 'Follow'} company name`,
                      onClick: () => {
                        dispatch(
                          handleFollowSearchResult({
                            id: postId,
                            searchResultType: 'announcements'
                          })
                        );
                        handleFollow({
                          following,
                          viewerId,
                          loggedInUserType,
                          followUserId,
                          followUserType,
                          followName
                        });
                      }
                    });
                  }

                  return (
                    <ViewPost
                      key={index}
                      dropdownOptions={postCardDropdownItems}
                      expandPost={false}
                      post={announcementItem}
                      viewerId={authenticatedId}
                      viewerType={loggedInUserType}
                      redirectToViewPost={redirectToViewPost}
                    />
                  );
                })}
              />
              <Separator />
            </>
          )}

          {showPostCards && searchPostsResult?.length > 0 && !loading && (
            <FilterResultCards
              className={classNames({
                'filter-result-cards--is-post': resultType === 'post'
              })}
              totalResults={totalPosts}
              resultType={resultType}
              title="Posts"
              onFetchAll={() => {
                updateParams({ type: 'resultType', value: 'post' });
              }}
              content={searchPostsResult.map((postItem, index) => {
                const postId = postItem?.post?.id;

                const postCardDropdownItems: TDropdownOptions[] = [
                  {
                    label: 'Copy post link',
                    onClick: () => {
                      copyPostLink(postId);
                    }
                  },
                  {
                    label: 'View post',
                    onClick: () => {
                      redirectToViewPost({ postId, ownerId: postItem?.user?.id, post: postItem });
                    }
                  }
                ];

                const handleFollowPost = () => {
                  dispatch(handleFollowSearchResult({ id: postId, searchResultType: 'posts' }));
                };

                return (
                  <ViewPost
                    key={index}
                    dropdownOptions={postCardDropdownItems}
                    expandPost={false}
                    post={postItem}
                    viewerId={authenticatedId}
                    viewerType={loggedInUserType}
                    handleFollowFromSearchResult={handleFollowPost}
                    redirectToViewPost={redirectToViewPost}
                  />
                );
              })}
            />
          )}
          {showPagination && (
            <div className="main-search__body__pagination">
              <div
                className="main-search__body__pagination__previous"
                onClick={handleDecreasePageNumber}
              >
                <SingleChevronIcon />
                <Typography as="span" variant="body-2" fontWeight="regular">
                  Previous
                </Typography>
              </div>
              <ReactPaginate
                className="main-search__body__pagination__pages"
                breakLabel="..."
                forcePage={pageNumber - 1}
                onPageChange={handlePagination}
                pageRangeDisplayed={4}
                marginPagesDisplayed={1}
                pageCount={totalPages}
                renderOnZeroPageCount={null}
              />
              <div
                className="main-search__body__pagination__next"
                onClick={handleIncreasePageNumber}
              >
                <Typography as="span" variant="body-2" fontWeight="regular">
                  Next
                </Typography>
                <SingleChevronIcon />
              </div>
            </div>
          )}
        </div>
      </div>
    </SearchResultsContext.Provider>
  );
};

export default Search;
