import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { signOut } from 'src/store/auth/auth.slice';
import { TDeRegisterDeviceRequest } from 'src/services/apiEndpoint.types.ts';
import { deRegisterDevice } from 'src/store/notifications/notifications.slice';

import useGetIdentity from '../useGetIdentity/useGetIdentity';

const useSignOut = () => {
  // Hooks
  const { getIdentities } = useGetIdentity();
  const dispatch = useAppDispatch();
  const { deviceId } = useAppSelector((store) => store.notifications);

  // Constants
  const { loggedInUserId, loggedInUserType } = getIdentities();

  const handleSignOut = () => {
    const deregisterDevicePayload: TDeRegisterDeviceRequest = {
      userId: loggedInUserId,
      userType: loggedInUserType,
      deviceId
    };

    dispatch(deRegisterDevice(deregisterDevicePayload));

    dispatch(signOut());
  };

  return handleSignOut;
};

export default useSignOut;
