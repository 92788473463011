import React, { useState } from 'react';
import EvaluationCritiriaForm from '../EvaluationCritiriaForm/EvaluationCritiriaForm';
import { EditIcon } from 'src/components/common/common.icons';
import { TprogramStageCriteria, TProgramStage, TProgramData } from '../evaluation.types';
import 'bootstrap/dist/css/bootstrap.min.css';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';
import { httpRequest } from 'src/services/commonApis';
import cx from '../evaluation.module.scss';
import OnlyOverallCritireaForm from '../EvaluationCritiriaForm/OnlyOverallCritireaForm';

interface gradeContainer {
  selectedStage: TProgramStage,
  programData: TProgramData[],
  setProgramData: React.Dispatch<TProgramData[]>,
  getStages: () => Promise<void>
}

export default function GradeContainForm({ selectedStage, programData, setProgramData, getStages }: gradeContainer) {
  const initialValue: TprogramStageCriteria = {
    criteriaName: '',
    rating: '',
    comments: false,
    overallComment: false
  }
  const dispatch = useDispatch()
  const [loading, setLoading] = useState<boolean>(false)
  const stageData = programData?.find((item: TProgramData) => item?.stageId === selectedStage?.id);
  const isUpdate = stageData?.mode === 'update';
  const [errorTrig, setErrorTrig] = useState<boolean>(false)
  const formData = stageData?.data || [initialValue]
  function handleMore() {
    const updatedProgramData = programData.map((program: TProgramData) => {
      if (program?.stageId === selectedStage?.id) {
        return {
          ...program,
          data: [...program?.data, initialValue]
        };
      }
      return program;
    });
    setProgramData(updatedProgramData);
  }
  function handleDeleteForm(index: number) {
    const updatedProgramData = programData.map((program: TProgramData) => {
      if (program.stageId === selectedStage?.id) {
        return {
          ...program,
          data: program?.data?.filter((_: TprogramStageCriteria, i: number) => i !== index)
        };
      }
      return program;
    });
    setProgramData(updatedProgramData);
  }
  function handleCritiriaForm(e: React.ChangeEvent<HTMLInputElement>, index: number) {
    const name: string | number = e.target.id;
    const value: string = e.target.value;
    const programCritiriaArr = programData?.find((item: TProgramData) => item?.stageId === selectedStage?.id)?.data || [initialValue];
    const updatedData: TprogramStageCriteria[] = [...programCritiriaArr];

    if (name === 'comments' || name === 'overallComment') {
      updatedData[index] = {
        ...updatedData[index],
        [name]: e.target.checked
      };
    } else {
      updatedData[index] = {
        ...updatedData[index],
        [name]: name === 'rating' ? (Number.parseInt(value) < 0 ? '' : value) : value
      };
    }

    const updatedProgramData = programData.map((program: TProgramData) => {
      if (program.stageId === selectedStage?.id) {
        return {
          ...program,
          data: updatedData
        };
      }
      return program;
    });
    setProgramData(updatedProgramData);
  }
  function handleOverallCritrial(e: React.ChangeEvent<HTMLInputElement>) {
    console.log(e.target.checked, 'triggered');
    const updatedProgramData = programData.map((program: TProgramData) => {
      if (program.stageId === selectedStage?.id) {
        return {
          ...program,
          overallComment: e.target.checked
        };
      }
      return program;
    });
    setProgramData(updatedProgramData);
  }
  async function handleSubmit() {
    if (formData?.some((item: TprogramStageCriteria) => item?.criteriaName === '')) {
      setErrorTrig(true)
      return
    }
    setLoading(true)
    const data = {
      id: stageData?.stageId,
      overallComment: stageData?.overallComment,
      stagesDetails: formData
    }
    const response = await httpRequest('program/stage', 'PATCH', data, 'json', 'program');
    if (response.data?.id) {
      getStages && getStages();
      const updatedProgramData = programData.map((program: TProgramData) => {
        if (program?.stageId === selectedStage?.id) {
          return {
            ...program,
            mode: 'update'
          };
        }
        return program;
      });
      setProgramData(updatedProgramData);
      const message = isUpdate ? 'Stage criteria updated successfully' : 'Stage criteria added successfully'
      dispatch(
        openPopup({
          popupMessage: message,
          popupType: 'success'
        })
      );
    }
    setLoading(false)
  }
  function handleModeChange() {
    const updatedProgramData = programData.map((program: TProgramData) => {
      if (program?.stageId === selectedStage?.id) {
        return {
          ...program,
          mode: isUpdate ? 'create' : 'update'
        };
      }
      return program;
    });
    setProgramData(updatedProgramData);
  }
  console.log(isUpdate, 'isUpdateee');
  return (
    <div className={`${cx.gradesContentBox}`}>
      <div className={`${cx.critiriaHeader}`}>
        <h5 className={'title'}>{selectedStage?.stageTitle}</h5>
        <div className={cx.actionBtnContainer}>
          {isUpdate && <button className={`${cx.editBtn}`} onClick={handleModeChange}><EditIcon /></button>}
          {!isUpdate && <button className={`${cx.saveBtn}`} onClick={handleSubmit}>
            {
              loading
                ? (<div
                  className='spinner-border spinner-border-sm text-light'
                  role='status'
                ></div>)
                : (
                    'Save Evaluation Criteria'
                  )
            }
          </button>}
        </div>
      </div>
      {formData?.map((formObj: TprogramStageCriteria, index: number) => {
        return (
          <EvaluationCritiriaForm
            key={index}
            formObj={formObj}
            handleCritiriaForm={handleCritiriaForm}
            index={index}
            isUpdate={isUpdate}
            errorTrig={errorTrig}
            handleDeleteForm={handleDeleteForm} />
        )
      })}
      <OnlyOverallCritireaForm isUpdate={isUpdate} handleOverallCritrial={handleOverallCritrial} stageData={stageData} />

      {!isUpdate && <div className={cx.addMoreButton}>
        <button onClick={handleMore}>Add More</button>
      </div>}
    </div>
  )
}
