import { Form, Formik } from 'formik';
import { Button, Modal, Typography } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import { componentSizeVariantsEnum } from 'src/constants';
import { MailIcon } from 'src/components/common/common.icons';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { TInviteMentorInvestorRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { inviteMentorInvestor } from 'src/store/auth/auth.slice';

import {
  TInviteMentorInvestorInitialValues,
  TInviteMentorInvestorProps
} from './inviteMentorInvestor.types';
import './inviteMentorInvestor.scss';
import inviteMentorInvestorValidationSchema from './inviteMentorInvestor.validationSchema';

export const inviteMentorInvestorModalId = 'settingsChangePasswordModal';
export const inviteMentorInvestorLoaderId = 'invite-mentor-investors-modal';

const InviteMentorInvestor = ({ isModalOpen, onModalClose }: TInviteMentorInvestorProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const loaderId: string = useAppSelector((store) => store.common.loadingId);

  const inviteMentorInvestorInitialValues: TInviteMentorInvestorInitialValues = {
    email: '',
    name: '',
    role: ''
  };

  const userTypes: TSelectCardRadioButton[] = [
    {
      label: 'Mentor',
      value: 'Mentor',
      id: 'Mentor'
    },
    {
      label: 'Investor',
      value: 'Investor',
      id: 'Investor'
    },
    {
      label: 'Mentor & Investor',
      value: 'Mentor and Investor',
      id: 'Mentor-Investor'
    }
  ];

  const handleInviteMentorInvestor = (values: TInviteMentorInvestorInitialValues) => {
    const { email, name, role } = values;

    const payload: TInviteMentorInvestorRequest = { email, name, role };

    dispatch(inviteMentorInvestor(payload));
  };

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="Invite Mentor/Investor"
      className="invite-mentor-investor"
    >
      <Typography as="p" variant="caption" fontWeight="regular">
        To invite an mentor/investor, enter the details below. A temporary password will be sent to
        the provided email address.
      </Typography>
      <Formik
        initialValues={inviteMentorInvestorInitialValues}
        validationSchema={inviteMentorInvestorValidationSchema}
        onSubmit={handleInviteMentorInvestor}
        enableReinitialize
      >
        {({ values, errors, touched, setFieldValue }) => {
          return (
            <Form className="invite-mentor-investor__form">
              {/*
               ** Name
               */}
              <InputField
                label="Enter name"
                id="name"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Name"
                required
              />
              {/*
               ** User type
               */}
              <SelectField
                id="role"
                required
                label="Select user type"
                placeholder="User type"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={values.role}
                options={userTypes}
                onSelection={({ value }) => {
                  setFieldValue('role', value as unknown as string);
                }}
                errorMessage={errors?.role}
                isTouched={touched?.role}
              />
              {/*
               ** Email address
               */}
              <InputField
                label="Enter the email address"
                id="email"
                startIcon={<MailIcon />}
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email address"
                required
              />

              <div className="invite-mentor-investor__form__buttons">
                <Button variant="secondary" size="small" type="button" onClick={onModalClose}>
                  Cancel
                </Button>
                <Button
                  variant="primary"
                  size="small"
                  type="submit"
                  loading={loaderId === inviteMentorInvestorLoaderId}
                >
                  Send temporary password
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default InviteMentorInvestor;
