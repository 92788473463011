import { FormEvent, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { useTimer } from 'react-timer-hook';
import classNames from 'classnames';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import VerificationCode from 'src/components/common/formFields/verificationCode/VerificationCode';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { MailIcon } from 'src/components/common/common.icons';
import Button from 'src/components/button/Button';
import {
  confirmSignup,
  pauseResendSignupOtpTimer,
  resendSignupOtp
} from 'src/store/auth/auth.slice';
import ErrorMessage from 'src/components/common/formFields/errorMessage/ErrorMessage';
import { verificationCodeLength } from 'src/constants';

import './emailVerification.scss';

const timerDurationInSeconds = 30;

const time = new Date();

const EmailVerification = () => {
  const [verificationCode, setVerificationCode] = useState<string[]>(['', '', '', '', '', '']);
  const [disableResendButton, setDisableResendButton] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const { email, loading, startResendSignupOtpTimer } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();

  const { seconds, restart, pause, isRunning } = useTimer({
    expiryTimestamp: time,
    onExpire: () => {
      // After expiry of timer, pause the timer and enable resend OTP button
      dispatch(pauseResendSignupOtpTimer());
      setDisableResendButton(false);
    }
  });

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // submit email verification

    if (verificationCode.join('').length !== verificationCodeLength) {
      setErrorMessage('Please enter the code sent to your email address');
      /* eslint-disable no-useless-return */
      return;
    }

    dispatch(
      confirmSignup({
        email,
        otp: verificationCode.join('')
      })
    );
  };

  const resendSignupOTPSubmit = async () => {
    // request new OTP email verification
    dispatch(
      resendSignupOtp({
        email
      })
    );
  };

  useEffect(() => {
    /*
     ** Stop the timer on initial load
     */
    pause();
  }, []);

  useEffect(() => {
    if (verificationCode.join('').length === verificationCodeLength) {
      setErrorMessage('');
    }
  }, [JSON.stringify(verificationCode)]);

  useEffect(() => {
    if (startResendSignupOtpTimer) {
      const currentTime = new Date();
      currentTime.setSeconds(currentTime.getSeconds() + timerDurationInSeconds);
      restart(currentTime);
      setDisableResendButton(true);
    }
  }, [startResendSignupOtpTimer]);

  return (
    <div className="email-verification">
      <BackNavigation text="Back to sign up" route="/sign-up" />
      <Typography
        as="h3"
        variant="heading-3"
        fontWeight="semiBold"
        className="email-verification__heading"
      >
        Email Verification
      </Typography>{' '}
      <Typography
        as="p"
        variant="body-2"
        fontWeight="regular"
        className="email-verification__caption"
      >
        Lets get this sorted
      </Typography>
      {/* Form starts */}
      <form className="email-verification__form" onSubmit={handleSubmit}>
        <InputField
          label="Enter Email Address"
          id="email"
          variant={componentSizeVariantsEnum.SMALL}
          placeholder="abcd@gamil.com"
          startIcon={<MailIcon />}
          disabled
          type="email"
          value={email}
        />
        <VerificationCode
          label="Enter verification code"
          id="verificationCode"
          fieldsCount={6}
          className="email-verification__form__verification-code"
          value={verificationCode}
          setVerificationCode={setVerificationCode}
        />
        {errorMessage && <ErrorMessage message={errorMessage} />}
        <div className="email-verification__form__resend-code">
          <Typography
            as="span"
            variant="caption"
            fontWeight="semiBold"
            className={classNames('email-verification__form__resend-code__link', {
              'email-verification__form__resend-code__link--disabled': disableResendButton
            })}
            onClick={resendSignupOTPSubmit}
          >
            Re-send verification code
          </Typography>
          {isRunning && (
            <Typography as="span" variant="caption" fontWeight="semiBold">
              00:{seconds}
            </Typography>
          )}
        </div>
        <Button variant="primary" size="small" type="submit" loading={loading} disabled={loading}>
          <Typography as="span" variant="body-2" fontWeight="semiBold">
            Continue
          </Typography>
        </Button>
      </form>
      {/* Form ends */}
    </div>
  );
};

export default EmailVerification;
