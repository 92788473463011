import { useEffect, useState } from 'react';
import { TApplicant, TGetApplicationRequest } from 'src/services/apiEndpoint.types.ts';
import Header from 'src/components/header/Header';
import Breadcrumbs, { TBreadcrumb } from 'src/components/common/breadcrumbs/Breadcrumbs';
import { useUrlParamValue } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { getApplication } from 'src/store/program/program.slice';
import './RatingSection.scss';
import { TUserTypes } from 'src/constants/user.constants';
import { Typography } from 'src/components/common';
import ApplicationDetails from '../ApplicationDetails/ApplicationDetails';

const RatingSection = () => {
  // Hooks
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { application: viewApplication }: { application: TApplicant } = useAppSelector(
    (store) => store.program.viewApplication
  );
  const { isAuthorized } = useAppSelector((store) => store.auth);

  // state
  const [loading, setLoading] = useState<boolean>(true)
  // Constants
  const applicationId: string = paramValue({ paramName: 'id' }) as string;
  const userIdByQuery: string = paramValue({ paramName: 'userId' }) as string;
  const userTypeByQuery: TUserTypes = paramValue({ paramName: 'userType' }) as TUserTypes;

  const breadCrumbs: TBreadcrumb[] = [
    { label: 'Company Profile', url: '/my-company' },
    {
      label: 'Program Details',
      url: `/view-program?id=${viewApplication?.programId}&tab=submittedApplications`
    },
    { label: 'Submitted Application', url: '' }
  ];

  const handleGetApplication = () => {
    const payload: TGetApplicationRequest = {
      applicationId: applicationId,
      userId: userIdByQuery,
      userType: userTypeByQuery
    };
    dispatch(getApplication(payload));
  };

  useEffect(() => {
    handleGetApplication();
  }, [applicationId]);

  return (
    <div className="view-program-application">
      {isAuthorized && <Header />}

      <div className="view-program-application__body">
        {isAuthorized && <Breadcrumbs breadcrumbs={breadCrumbs} />}
        <div className="application-actions">
          <div className="application-actions__label">
            <Typography variant="subHeading-2" fontWeight="semiBold">
              Application Details
            </Typography>
          </div>
        </div>
        <ApplicationDetails setLoading={setLoading} loading={loading} />
      </div>
    </div>
  );
};

export default RatingSection;
