import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { EyeIcon, EyeOffIcon, MailIcon, PasswordIcon } from 'src/components/common/common.icons';
import { resetRedirection, signIn } from 'src/store/auth/auth.slice';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import useUrlParamValue from 'src/hooks/useUrlParamValue/useUrlParamValue';
import { TSignInResponse } from 'src/services/apiEndpoint.types.ts';
import { APIResponse } from 'src/services/base.api.service';

import './login.scss';
import signInValidationSchema from './login.validationSchema';

export type TLogInFormValues = {
  email: string;
  password: string;
};

const Login = () => {
  const initialValues: TLogInFormValues = {
    email: '',
    password: ''
  };

  const [maskPassword, setMaskPassword] = useState<boolean>(true);

  // Hooks
  const { paramValue } = useUrlParamValue();

  const prevRoute = paramValue({ paramName: 'prev' });

  const {
    loading,
    shouldRedirectToDashboard,
    shouldRedirectToRoleSelection,
    shouldRedirectToForceChangePassword
  } = useAppSelector((store) => store.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async ({ email, password }: TLogInFormValues) => {
    // login api call
    dispatch(
      signIn({
        email,
        password
      })
    ).then((response: unknown) => {
      const loginResponse = response as unknown as APIResponse<TSignInResponse>;
      const status: number = loginResponse?.status;
      const isOnboardingComplete: boolean = loginResponse?.data?.data?.user?.onboardingComplete;
      const user = loginResponse?.data?.data?.user;
      const userType = loginResponse?.data?.data?.type;
      const isMentorOrInvestor =
        user?.role === 'Mentor' ||
        user?.role === 'Investor' ||
        user?.role === 'Mentor and Investor';

      if (status === 200 && userType === 'User') {
        if (isOnboardingComplete) {
          navigate('/dashboard');
        } else if (isMentorOrInvestor) {
          navigate('/tell-me-more?userType=MENTOR_INVESTOR');
        } else {
          navigate('/onboarding-user-type');
        }
      }
    });
  };

  useEffect(() => {
    if (shouldRedirectToDashboard) {
      navigate('/dashboard');
    }
    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToDashboard]);
  useEffect(() => {
    if (shouldRedirectToRoleSelection && !shouldRedirectToDashboard) {
      navigate('/onboarding-user-type');
    }
    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToRoleSelection, shouldRedirectToDashboard]);

  useEffect(() => {
    if (shouldRedirectToForceChangePassword) {
      navigate('/force-change-password');
    }
    return () => {
      dispatch(resetRedirection());
    };
  }, [shouldRedirectToForceChangePassword]);

  return (
    <div className="log-in">
      <BackNavigation text="Back" route={prevRoute || '/'} />
      <Typography as="h3" variant="heading-3" fontWeight="semiBold" className="log-in__heading">
        Login
      </Typography>
      <Typography as="p" variant="body-2" fontWeight="regular" className="log-in__caption">
        Welcome Back...
      </Typography>

      {/* Form starts */}
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={signInValidationSchema}
      >
        {({ errors, touched, setFieldValue, values }) => {
          return (
            <Form className="log-in__form" noValidate>
              {/* NoValidate is used, because yup is used to validate the user input */}
              <InputField
                label="Enter Email Address"
                id="email"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Email Address"
                startIcon={<MailIcon />}
                type="email"
                value={values.email}
                onChange={(e) => {
                  setFieldValue('email', e.target.value);
                }}
                errorMessage={errors.email}
                isTouched={touched.email}
              />
              <InputField
                label="Enter Password"
                id="password"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Password"
                startIcon={<PasswordIcon />}
                endIcon={maskPassword ? <EyeOffIcon /> : <EyeIcon />}
                maskField={maskPassword}
                endIconClickHandler={() => setMaskPassword((prev) => !prev)}
                value={values.password}
                onChange={(e) => {
                  setFieldValue('password', e.target.value);
                }}
                errorMessage={errors.password}
                isTouched={touched.password}
              />
              <Link to="/forgot-password" className="log-in__form__forgot-password">
                Forgot Password?
              </Link>
              <Button variant="primary" size="small" type="submit" loading={loading}>
                <Typography as="span" variant="body-2" fontWeight="semiBold">
                  Continue
                </Typography>
              </Button>
            </Form>
          );
        }}
      </Formik>

      {/* Form ends */}

      <div className="log-in__footer">
        <Typography as="span" variant="body-2" fontWeight="medium">
          Don’t have an account?
        </Typography>
        <Link to="/sign-up?prev=/login">Sign Up</Link>
      </div>
    </div>
  );
};

export default Login;
