import { MouseEvent, useMemo } from 'react';
import { useToggleOpenDropdown, useUrlParamValue } from 'src/hooks';
import Button from 'src/components/button/Button';
import {
  Dropdown,
  SubMenuModal,
  TDropdown,
  TDropdownOptions,
  Typography
} from 'src/components/common';
import { SingleChevronIcon } from 'src/components/common/common.icons';
import { useAppDispatch } from 'src/store/hooks';
import { resetPrograms } from 'src/store/program/program.slice';

import './programFilters.scss';
import { TProgramFilterProps } from './programFilters.types';

import { TProgramType } from '../programs/programs.types';

const programFilterDropdownId = 'programFilters';

const programFilterLabels: Record<TProgramType, string> = {
  Active: 'Active',
  Archived: 'Archive',
  All: 'All',
  Draft: 'Draft'
};

const ProgramFilters = ({ updateParams }: TProgramFilterProps) => {
  // Hooks
  const dispatch = useAppDispatch();
  const toggleOpenDropdown = useToggleOpenDropdown();
  const { paramValue } = useUrlParamValue();

  const programsFilterBy: TProgramType = paramValue({
    paramName: 'programType'
  }) as TProgramType;

  const handleToggleOpenDropdown = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    toggleOpenDropdown({ dropdownId: programFilterDropdownId });
  };

  const resetExistingData = () => {
    // Reset data when filters are changed
    dispatch(resetPrograms());
  };

  const programsFilterDropdownItems: TDropdownOptions[] = useMemo(() => {
    const items: TDropdownOptions[] = Object.keys(programFilterLabels).map((filter) => {
      const label: string = programFilterLabels[filter as TProgramType];
      return {
        label,
        onClick: () => {
          resetExistingData();
          updateParams({ param: 'programType', value: filter });
        },
        ...(programsFilterBy === filter && { state: filter })
      };
    });

    return items;
  }, [programsFilterBy]);

  const programFilterDropdownProps: TDropdown = {
    id: programFilterDropdownId,
    size: 'large',
    alignment: 'left',
    items: programsFilterDropdownItems
  };

  return (
    <div className="program-filters">
      {' '}
      <Typography as="span" variant="body-2" fontWeight="semiBold">
        Filter:
      </Typography>
      <div className="program-filters__dropdown">
        <Button
          variant="secondary"
          size="small"
          endIcon={<SingleChevronIcon />}
          onClick={handleToggleOpenDropdown}
        >
          <Typography variant="body-2" fontWeight="semiBold">
            {programFilterLabels[programsFilterBy] || 'Active'}
          </Typography>
        </Button>
        <SubMenuModal>
          <Dropdown {...programFilterDropdownProps} />
        </SubMenuModal>
      </div>
    </div>
  );
};

export default ProgramFilters;
