import { useContext, useEffect } from 'react';
import Button from 'src/components/button/Button';
import { Loader, Typography } from 'src/components/common';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { TMember } from 'src/services/apiEndpoint.types.ts';
import { getKeyMembers, resetKeyMembers, resetSearchUsers } from 'src/store/company/company.slice';
import { assignModalId } from 'src/store/common/common.slice';
import { SelfCompanyContext } from 'src/pages/myCompany/MyCompany';

import './keyMembers.scss';
import KeyMemberCard from './keyMemberCard/KeyMemberCard';
import ManageKeyMembers from './manageKeyMembers/ManageKeyMembers';

const companyManageAccessModalId = 'companyManageAccessModalId';

export type TKeyMembers = {
  companyIdToFetch: string;
  isViewerProgramCreator? : boolean;
};

const KeyMembers = ({ companyIdToFetch, isViewerProgramCreator = true }: TKeyMembers) => {
  const dispatch = useAppDispatch();

  const { keyMembers, keyMembersLoading, keyMembersPageNumber, keyMembersItemsPerPage } =
    useAppSelector((store) => store.company);
  const { modalId }: { modalId: string; loading: boolean } = useAppSelector(
    (store) => store.common
  );

  console.log(keyMembers, 'keyMembers');
  const selfCompany = useContext(SelfCompanyContext);

  const isManageAccessModalOpen = modalId === companyManageAccessModalId;

  const openManageAccessModalOpen = () => {
    dispatch(assignModalId(companyManageAccessModalId));
  };

  const closeManageAccessModalOpen = () => {
    dispatch(assignModalId(''));
    dispatch(resetSearchUsers());
  };

  const fetchKeyMembers = () => {
    dispatch(
      getKeyMembers({
        companyId: companyIdToFetch,
        page: keyMembersPageNumber,
        pageSize: keyMembersItemsPerPage
      })
    );
  };

  useEffect(() => {
    fetchKeyMembers();
  }, [keyMembersPageNumber]);

  useEffect(() => {
    return () => {
      dispatch(resetKeyMembers());
    };
  }, []);

  return (
    <div className="company-key-members">
      <div className="company-key-members__header">
        <Typography variant="subHeading-2" fontWeight="semiBold">
          Key&nbsp;Members
        </Typography>
        {selfCompany && (
          <Button variant="tertiary" size="extraSmall" onClick={openManageAccessModalOpen}>
            Manage Access
          </Button>
        )}
      </div>
      <div className="company-key-members__members">
        {keyMembers.map(
          (
            { name = '', role = '', introduction = '', id = '', profilePic = '' }: TMember,
            index
          ) => {
            return (
              <KeyMemberCard
                key={index}
                isViewerProgramCreator={isViewerProgramCreator}
                profilePic={profilePic}
                name={name}
                role={role}
                description={introduction}
                id={id}
              />
            );
          }
        )}
      </div>
      {keyMembersLoading && <Loader />}
      {isManageAccessModalOpen && (
        <ManageKeyMembers
          isModalOpen={isManageAccessModalOpen}
          onModalClose={closeManageAccessModalOpen}
          fetchKeyMembers={fetchKeyMembers}
        />
      )}
    </div>
  );
};

export default KeyMembers;
