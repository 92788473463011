import { HTMLAttributes } from 'react';

type TLoaderIcon = {
  color?: string;
} & HTMLAttributes<SVGElement>;

const LoaderIcon = ({ color = '#fa4616', ...rest }: TLoaderIcon) => {
  return (
    <svg
      {...rest}
      width="10mm"
      height="10mm"
      viewBox="0 0 10 10"
      version="1.1"
      id="svg12792"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs id="defs12789">
        <linearGradient id="linearGradient22079">
          <stop stopColor={color} stopOpacity="1" offset="0" id="stop22075" />
          <stop stopColor="#ffffff" stopOpacity="0" offset="1" id="stop22077" />
        </linearGradient>
        <linearGradient id="linearGradient20615">
          <stop stopColor="#8a8600" stopOpacity="1" offset="0" id="stop20613" />
        </linearGradient>
        <linearGradient
          xlinkHref="#linearGradient22079"
          id="linearGradient22081"
          x1="82.042221"
          y1="19.90596"
          x2="130.98985"
          y2="103.20209"
          gradientUnits="userSpaceOnUse"
          gradientTransform="scale(0.0999999)"
        />
      </defs>
      <g id="layer1">
        <path
          id="path14852"
          fill="url(#linearGradient22079)"
          fillOpacity="1"
          strokeWidth="0"
          strokeDasharray="none"
          d="M 4.9999998,0 A 4.9999949,4.9999949 0 0 0 0,4.9999998 4.9999949,4.9999949 0 0 0 4.9999998,10 4.9999949,4.9999949 0 0 0 9.3571454,7.4523024 C 9.1958954,7.0448898 8.9391599,6.8427724 8.4444889,7.0328969 A 3.999996,3.999996 0 0 1 4.9999998,9.0000099 3.999996,3.999996 0 0 1 0.99998961,4.9999998 3.999996,3.999996 0 0 1 4.9999998,0.99998961 3.999996,3.999996 0 0 1 7.7775019,2.1217289 C 8.2086074,2.1350996 8.4879935,1.9272205 8.473221,1.4035822 A 4.9999949,4.9999949 0 0 0 4.9999998,0 Z"
        />
      </g>
    </svg>
  );
};

export default LoaderIcon;
