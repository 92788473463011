import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { InfiniteScroll, TTabItem, Tabs, Typography } from 'src/components/common';
import Header from 'src/components/header/Header';
import { useGetIdentity, useUrlParamValue } from 'src/hooks';
import {
  IdeasSharedWithMe,
  MyIdeas,
  TIdeaHubFilters,
  TIdeaHubSearchParams,
  TIdeaHubTabs
} from 'src/components/ideaHub';
import { TIdeasSharedWithMeRequest, TMyIdeasRequest } from 'src/services/apiEndpoint.types.ts';
import {
  getMyIdeas,
  ideasSharedWithMe,
  incrementIdeasSharedPage,
  incrementMyIdeasPage,
  resetMyIdeasSharedPage
} from 'src/store/ideaHub/ideaHub.slice';

import './ideaHub.scss';

const IdeaHub = () => {
  // Hooks
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const {
    myIdeasPageNumber,
    myIdeasItemsPerPage,
    myIdeasMoreItemsLeft,
    ideasSharedWithMePageNumber,
    ideasSharedWithMeItemsPerPage,
    ideasSharedWithMeMoreItemsLeft,
    ideasFeedLoading
  } = useAppSelector((store) => store.ideaHub);

  const [fetchIdeasCounter, setFetchIdeasCounter] = useState<number>(1);

  const currentTab: TIdeaHubTabs = paramValue({ paramName: 'tab' }) as TIdeaHubTabs;
  const { loggedInUserId, loggedInUserType } = getIdentities();

  const ideaHubParams = useMemo(() => {
    const tab: string = paramValue({ paramName: 'tab' }) as TIdeaHubTabs;
    const query: string = paramValue({ paramName: 'query' }) as string;
    const filterBy: TIdeaHubFilters = (paramValue({ paramName: 'filterBy' }) ||
      'latest') as TIdeaHubFilters;

    return { tab, query, filterBy };
  }, [window.location.search]);

  const updateParams = ({ param, value }: { param: TIdeaHubSearchParams; value: string }) => {
    const query: string = paramValue({ paramName: 'query' }) as string;
    const filterBy: string = paramValue({ paramName: 'filterBy' }) as string;
    const tab: string = paramValue({ paramName: 'tab' }) as TIdeaHubTabs;
    let path: string = `/idea-hub?tab=${tab}`;

    const currentParams: Record<TIdeaHubSearchParams, string> = {
      query,
      filterBy
    };

    Object.keys(currentParams).forEach((key) => {
      const currentValue: string = currentParams[key as keyof typeof currentParams];

      if (key === param) {
        path += `&${key}=${value}`;
      } else if (currentValue) {
        path += `&${key}=${currentValue}`;
      }
    });

    navigate(path);
  };

  const changeIdeaHubTab = (value: string) => {
    navigate(`/idea-hub?tab=${value as TIdeaHubTabs}`);
  };

  const increaseFetchIdeasCounter = () => {
    setFetchIdeasCounter((prev) => prev + 1);
  };

  const tabItems: TTabItem[] = [
    {
      key: 'myIdea',
      text: 'My Idea',
      disabled: false,
      component: (
        <MyIdeas
          updateParams={updateParams}
          increaseFetchIdeasCounter={increaseFetchIdeasCounter}
        />
      )
    },
    {
      key: 'sharedWithMe',
      text: 'Shared with me',
      disabled: false,
      component: (
        <IdeasSharedWithMe
          updateParams={updateParams}
          increaseFetchIdeasCounter={increaseFetchIdeasCounter}
        />
      )
    }
  ];

  const fetchIdeas = ({ resetPreviousData }: { resetPreviousData: boolean }) => {
    const { query, filterBy } = ideaHubParams;

    if (resetPreviousData) {
      dispatch(resetMyIdeasSharedPage());
    }

    if (currentTab === 'myIdea') {
      // Fetch my ideas
      const payload: TMyIdeasRequest = {
        userId: loggedInUserId,
        orderBy: filterBy,
        offset: resetPreviousData ? 1 : myIdeasPageNumber,
        limit: myIdeasItemsPerPage,
        ...(query && { filterString: query })
      };
      console.log(payload, 'query')
      dispatch(getMyIdeas(payload));
    } else if (currentTab === 'sharedWithMe') {
      // Fetch ideas shared with me
      const payload: TIdeasSharedWithMeRequest = {
        userId: loggedInUserId,
        orderBy: filterBy,
        offset: ideasSharedWithMePageNumber,
        limit: ideasSharedWithMeItemsPerPage,
        ...(query && { filterString: query })
      };
      dispatch(ideasSharedWithMe(payload));
    }
  };

  const loadMoreItems = () => {
    if (myIdeasMoreItemsLeft && !ideasFeedLoading && currentTab === 'myIdea') {
      dispatch(incrementMyIdeasPage(1));
    }
    if (ideasSharedWithMeMoreItemsLeft && !ideasFeedLoading && currentTab === 'sharedWithMe') {
      dispatch(incrementIdeasSharedPage(1));
    }
  };

  useEffect(() => {
    if (ideaHubParams.tab) {
      fetchIdeas({ resetPreviousData: true });
    }
  }, [window.location.search]);

  useEffect(() => {
    if (ideaHubParams.tab) {
      fetchIdeas({ resetPreviousData: false });
    }
  }, [fetchIdeasCounter, myIdeasPageNumber, ideasSharedWithMePageNumber]);

  useEffect(() => {
    // if the url is invalid
    const isTabValid = currentTab === 'myIdea' || currentTab === 'sharedWithMe';
    if (!isTabValid) {
      changeIdeaHubTab('myIdea');
    }
  }, [currentTab]);

  useEffect(() => {
    if (loggedInUserType === 'Company') {
      navigate('/not-found');
    }
  }, [loggedInUserType, window.location.search]);

  return (
    <InfiniteScroll
      className="idea-hub"
      onScrollEnd={loadMoreItems}
      loading={ideasFeedLoading}
      moreItemsLeft={
        currentTab === 'myIdea' ? myIdeasMoreItemsLeft : ideasSharedWithMeMoreItemsLeft
      }
    >
      <Header />
      <div className="idea-hub__body">
        <Typography
          variant="subHeading-2"
          fontWeight="semiBold"
          className="idea-hub__body__heading"
        >
          Idea Hub
        </Typography>
        <Tabs activeItem={currentTab} onTabChange={changeIdeaHubTab} items={tabItems} />
      </div>
    </InfiniteScroll>
  );
};

export default IdeaHub;
