import { useEffect } from 'react';
import { TGetProgramGroupsRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import { getProgramGroups } from 'src/store/chat/chat.slice';
import { useGetIdentity } from 'src/hooks';

import { TUseGetProgramGroups } from './discussionList.types';

/**
 * Fetches chat groups for a program
 */
const useGetProgramGroups = ({ programId }: TUseGetProgramGroups) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { loggedInUserType, authenticatedId } = getIdentities();

  const handleGetProgramGroups = () => {
    const payload: TGetProgramGroupsRequest = {
      programId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getProgramGroups(payload));
  };

  useEffect(() => {
    if (programId) {
      handleGetProgramGroups();
    }
  }, [programId]);

  return handleGetProgramGroups;
};

export default useGetProgramGroups;
