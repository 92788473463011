/* eslint-disable  multiline-ternary */

import { ReactNode, ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import './button.scss';

import Loader from '../common/loader/Loader';

export type TButtonVariants = 'primary' | 'secondary' | 'tertiary';

export type TButtonState = 'default' | 'active';

export type TButtonSizes = 'large' | 'medium' | 'small' | 'extraSmall';

export type TButtonProps = {
  variant?: TButtonVariants;
  size?: TButtonSizes;
  children?: string | ReactNode;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  loading?: boolean;
  state?: TButtonState;
} & ButtonHTMLAttributes<HTMLButtonElement>;

/**
 * Button component, supports multiple variants and states
 * @param {TButtonVariants} [variant] - The variant of the button.
 * @param {TButtonSizes} [size] - The size of the button.
 * @param {string|React.ReactNode} [children] - The content of the button.
 * @param {React.ReactNode} [icon] - The icon element to be displayed within the button.
 * @param {boolean} [startIcon] -The icon element to be positioned at the start of the button.
 * @param {boolean} [endIcon] -The icon element to be positioned at the start of the button.
 * @param {boolean} [loading] - Specifies if the button is in a loading state.
 */
const Button = ({
  variant = 'primary',
  size = 'medium',
  children,
  type = 'button',
  className,
  disabled = false,
  loading = false,
  startIcon,
  endIcon,
  state = 'default',
  ...rest
}: TButtonProps) => {
  const buttonIcon = (icon: ReactNode) => {
    return (
      <div
        className={classNames(
          'button-component__icon',
          `button-component__icon--${variant}`,
          `button-component__icon--${size}`
        )}
      >
        {icon}
      </div>
    );
  };

  return (
    <button
      {...rest}
      className={classNames(
        className,
        'button-component',
        `button-component--${variant}`,
        `button-component--${size}`,
        `button-component--${state}`
      )}
      type={type}
      disabled={disabled || loading}
    >
      {loading ? (
        <Loader className="button-loader" />
      ) : (
        <>
          {startIcon && buttonIcon(startIcon)}
          {children}
          {endIcon && buttonIcon(endIcon)}
        </>
      )}
    </button>
  );
};

export default Button;
