import {
  HTMLAttributes,
  ReactNode,
  useState,
  useMemo,
  ChangeEvent,
  useRef,
  useEffect
} from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { assignSubMenuModalId, resetDropdownId } from 'src/store/common/common.slice';
import { useAppDispatch } from 'src/store/hooks';

import './searchAndSelect.scss';

import Label from '../label/Label';
import ErrorMessage from '../errorMessage/ErrorMessage';
import { SelectIcon } from '../../common.icons';
import SubMenuModal from '../../subMenuModal/SubMenuModal';
import InputElement from '../inputElement/InputElement';

export type TSelectOptions = {
  label: string;
  value: string | number;
  id: string;
};

type TSearchAndSelectProps = {
  errorMessage?: string;
  label: string | ReactNode;
  placeholder: string;
  variant?: componentSizeVariantsEnum;
  options: TSelectOptions[];
  selectedValue: string | number;
  onSelection: ({ value }: { value: string | number }) => void;
  onInputChange: (e: ChangeEvent<HTMLInputElement>) => void;
  isTouched?: boolean;
  required?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const SearchAndSelect = ({
  id,
  errorMessage,
  className,
  label,
  variant = componentSizeVariantsEnum.LARGE,
  options,
  placeholder,
  onSelection,
  selectedValue,
  onInputChange,
  isTouched,
  required,
  ...rest
}: TSearchAndSelectProps) => {
  const [openOptions, setOpenOptions] = useState<boolean>(false);

  // Refs
  const searchAndSelectFieldRef = useRef<HTMLDivElement>(null);

  // Hooks
  const dispatch = useAppDispatch();

  const openDropdown = () => {
    dispatch(assignSubMenuModalId(`search-and-select-${id}`));
    setOpenOptions(true);
  };

  const handleSelection = ({ value }: { value: string | number }) => {
    onSelection({ value });
    setOpenOptions(false);
  };

  const filteredOptions = useMemo(() => {
    if (selectedValue) {
      const currentSearch = String(selectedValue).toLowerCase();
      return options.filter(
        (option) => String(option.value).toLowerCase().indexOf(currentSearch) > -1
      );
    }
    return options;
  }, [selectedValue, JSON.stringify(options)]);

  const handleEscapeKey = (e: KeyboardEvent) => {
    e.stopPropagation();
    if (e.key === 'Escape' || e.key === 'Esc') {
      setOpenOptions(false);
      resetDropdownId();
    }
  };

  useEffect(() => {
    const ele = searchAndSelectFieldRef?.current;

    if (ele) {
      ele.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      ele?.addEventListener('keydown', handleEscapeKey);
    };
  }, [searchAndSelectFieldRef]);

  return (
    <div
      className={classNames(
        className,
        'search-select-component',
        `search-select-component--${variant}`
      )}
      {...rest}
      ref={searchAndSelectFieldRef}
    >
      <Label htmlFor={id} variant={variant} required={required}>
        {label}
      </Label>
      <div className="search-select-component__custom-select" onClick={(e) => e.stopPropagation()}>
        <InputElement
          placeholder={placeholder}
          value={selectedValue}
          endIcon={<SelectIcon />}
          onClick={() => {
            openDropdown();
          }}
          onChange={(e) => {
            onInputChange(e);
          }}
        />
        {/* To close the select when clicked outside */}
        {filteredOptions?.length > 0 && (
          <SubMenuModal setOpenSubMenuModal={setOpenOptions}>
            <ul
              id={id}
              className={classNames('search-select-component__custom-select__options', {
                'search-select-component__custom-select__options--is-open': openOptions
              })}
            >
              {filteredOptions.map((item) => (
                <li
                  value={item.value}
                  key={item.id}
                  className={classNames('search-select-component__custom-select__options__option', {
                    'search-select-component__custom-select__options__option--selected':
                      selectedValue === item.value
                  })}
                  tabIndex={0}
                  onClick={() => handleSelection({ value: item.value })}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSelection({ value: item.value });
                    }
                  }}
                >
                  {item.label}
                </li>
              ))}
            </ul>
          </SubMenuModal>
        )}
      </div>
      {errorMessage && isTouched && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default SearchAndSelect;
