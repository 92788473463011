import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { Modal } from 'src/components/common';
import { toggleTouchedFields } from 'src/utils';
import { useGetPlaces, useUpdateFormValue, useValidateErrors } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import Button from 'src/components/button/Button';
import Typography from 'src/components/common/typography/Typography';
import TextAreaField from 'src/components/common/formFields/textAreaField/TextAreaField';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import { TSelectCardRadioButton } from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { Role } from 'src/constants/user.constants';
import { editProfile } from 'src/store/profile/profile.slice';
import SearchAndSelect from 'src/components/common/formFields/searchAndSelect/SearchAndSelect';
import { countries } from 'src/constants';

import './editProfile.scss';
import { editProfileFormFields, genderOptions, editProfileErrors } from './editProfile.constants';
import editProfileValidationSchema from './editProfile.validationSchema';

export type TEditProfile = {
  isModalOpen: boolean; // whether the modal is open
  setIsModalOpen: Dispatch<SetStateAction<boolean>>; // function to run when the modal is closed
};

export type TEditProfileValues = {
  id: string;
  name: string;
  role: string;
  introduction: string;
  about: string;
  gender: string;
  address: string;
  country: string;
  state: string;
  city: string;
  cityQuery?: string;
  zipCode: string;
  phoneNumber: string;
  linkedin: string;
};

const allFieldsUntouched = toggleTouchedFields({ fields: editProfileFormFields, isTouched: false });
const allFieldsTouched = toggleTouchedFields({ fields: editProfileFormFields, isTouched: true });

const EditProfile = ({ isModalOpen, setIsModalOpen }: TEditProfile) => {
  const [formValues, setFormValues] = useState<TEditProfileValues>(editProfileFormFields);
  const [touchedFormFields, setTouchedFormFields] =
    useState<Record<keyof TEditProfileValues, boolean>>(allFieldsUntouched);
  const [errors, setErrors] = useState<Record<keyof TEditProfileValues, string>>(editProfileErrors);

  // Hooks
  const { updateFormValue } = useUpdateFormValue();
  const { validateErrors } = useValidateErrors();

  const { profile } = useAppSelector((store) => store.profile);
  const { loading } = useAppSelector((store) => store.common);
  const dispatch = useAppDispatch();

  const updateForm = ({ fieldName, value }: { fieldName: string; value: string }) => {
    const setFormValue = setFormValues as Dispatch<SetStateAction<Record<string, string | number>>>;
    updateFormValue({ fieldName, value, setFormValue });
  };

  const { getPlaces, places } = useGetPlaces({ types: ['(cities)'] });

  const validateForm = async () => {
    return await validateErrors({
      validationSchema: editProfileValidationSchema,
      formValues,
      errorsInitialState: editProfileErrors,
      setErrors
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouchedFormFields(allFieldsTouched);

    const validationErrors = await validateForm();

    if (validationErrors?.length) {
      return validateErrors;
    }

    // edit profile api call
    dispatch(
      editProfile({
        id: profile.id,
        name: formValues.name,
        role: formValues.role,
        introduction: formValues.introduction || '',
        about: formValues.about || '',
        address: formValues.address || '',
        country: formValues.country || '',
        state: formValues.state || '',
        city: formValues.city || '',
        zipCode: formValues.zipCode || '',
        phoneNumber: formValues.phoneNumber || 0,
        linkedin: formValues?.linkedin || '',
        ...(formValues.gender && { gender: formValues.gender })
      })
    );
  };

  const closeModal = () => {
    setErrors(editProfileErrors);
    setFormValues(editProfileFormFields);
    setTouchedFormFields(allFieldsUntouched);
    setIsModalOpen(false);
  };

  useEffect(() => {
    validateForm();
  }, [JSON.stringify(formValues)]);

  let roleOptions: TSelectCardRadioButton[] = [];

  if (
    profile.role === Role.MENTOR ||
    profile.role === Role.INVESTOR ||
    profile?.role === Role.MENTOR_INVESTOR
  ) {
    roleOptions = [
      ...roleOptions,
      {
        label: 'Investor',
        value: 'Investor',
        id: 'investor'
      },
      {
        label: 'Mentor',
        value: 'Mentor',
        id: 'mentor'
      },
      {
        label: 'Mentor & Investor',
        value: 'Mentor and Investor',
        id: 'Mentor-Investor'
      }
    ];
  }

  if (profile.role === Role.ENTREPRENEUR || profile.role === Role.ASPIRING_ENTREPRENEUR) {
    roleOptions = [
      ...roleOptions,
      {
        label: 'Aspiring Entrepreneur',
        value: 'Aspiring Entrepreneur',
        id: 'aspiring-entrepreneur'
      },
      { label: 'Entrepreneur', value: 'Entrepreneur', id: 'entrepreneur' }
    ];
  }

  if (profile.role === Role.SERVICE_PROVIDERS_STARTUP) {
    roleOptions = [
      ...roleOptions,
      {
        label: 'Aspiring Entrepreneur',
        value: 'Aspiring Entrepreneur',
        id: 'aspiring-entrepreneur'
      },
      { label: 'Entrepreneur', value: 'Entrepreneur', id: 'entrepreneur' },
      {
        label: 'Investor',
        value: 'Investor',
        id: 'investor'
      },
      {
        label: 'Mentor',
        value: 'Mentor',
        id: 'mentor'
      }
    ];
  }

  useEffect(() => {
    // when edit profile modal is opened, fill the form with existing values.
    if (isModalOpen) {
      const currentValues: TEditProfileValues = {
        id: profile.id || '',
        name: profile.name || '',
        role: profile.role || '',
        introduction: profile.introduction || '',
        about: profile.about || '',
        gender: profile.gender || '',
        address: profile.address || '',
        country: profile.country || '',
        state: profile.state || '',
        city: profile.city || '',
        cityQuery: profile.city || '',
        zipCode: profile.zipCode || '',
        phoneNumber:
          profile.phoneNumber === '0' ? '' : profile?.phoneNumber ? profile?.phoneNumber : '',
        linkedin: profile?.linkedin || ''
      };
      setFormValues(currentValues);
    }
  }, [isModalOpen]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      title="Edit Profile"
      className="edit-profile-modal"
    >
      <div className="edit-profile">
        <form onSubmit={handleSubmit} className="edit-profile__form">
          <div className="edit-profile__form__fields">
            {/*
             ** Name
             */}
            <InputField
              label="Your name"
              id="name"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="My Name"
              type="text"
              value={formValues.name}
              onChange={(e) => {
                updateForm({ fieldName: 'name', value: e.target.value });
              }}
              errorMessage={errors.name}
              isTouched={touchedFormFields.name}
              required
            />
            {/*
             ** Role
             */}
            <SelectField
              label="I am:"
              placeholder="Select your role"
              variant={componentSizeVariantsEnum.SMALL}
              selectedValue={formValues.role}
              options={roleOptions}
              onSelection={({ value }) => {
                updateForm({ fieldName: 'role', value: value as unknown as string });
              }}
              required
            />
            {/*
             ** Introduction
             */}
            <InputField
              label={
                <Typography as="p" variant="caption" fontWeight="semiBold">
                  Quick Introduction{' '}
                </Typography>
              }
              id="introduction"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="My introduction"
              type="text"
              value={formValues.introduction}
              onChange={(e) => {
                updateForm({ fieldName: 'introduction', value: e.target.value });
              }}
              isTouched={touchedFormFields.introduction}
              errorMessage={errors.introduction}
            />

            {/*
             ** About me
             */}
            <TextAreaField
              label="About me"
              id="about"
              variant={componentSizeVariantsEnum.SMALL}
              value={formValues.about}
              onChange={(e) => {
                updateForm({ fieldName: 'about', value: e.target.value });
              }}
              errorMessage={errors.about}
            />
            {/*
             ** Grid of four fields -> phone number, email address, gender, and dob
             */}
            <div className="edit-profile__form__fields__grid">
              {/*
               ** Phone number
               */}
              <InputField
                label={
                  <Typography as="p" variant="caption" fontWeight="semiBold">
                    Contact number
                  </Typography>
                }
                id="phoneNumber"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Contact number"
                type="string"
                value={formValues.phoneNumber || ''}
                onChange={(e) => {
                  updateForm({ fieldName: 'phoneNumber', value: e.target.value });
                }}
                isTouched={touchedFormFields.phoneNumber}
                errorMessage={errors.phoneNumber}
              />
              {/*
               ** Email Address
               */}
              <div className="edit-profile__form__fields__grid__field">
                <Typography as="p" variant="caption" fontWeight="semiBold">
                  Email Address
                </Typography>
                <Typography as="p" variant="caption" fontWeight="regular">
                  {profile.email}
                </Typography>
              </div>
              {/*
               ** Gender
               */}
              <SelectField
                label="Your Gender"
                placeholder="Select your Gender"
                variant={componentSizeVariantsEnum.SMALL}
                selectedValue={formValues.gender}
                options={genderOptions}
                onSelection={({ value }) => {
                  updateForm({ fieldName: 'gender', value: value as unknown as string });
                }}
              />{' '}
              {/*
               ** Linkedin
               */}
              <InputField
                label={
                  <Typography as="p" variant="caption" fontWeight="semiBold">
                    Your LinkedIn profile URL
                  </Typography>
                }
                id="linkedin"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="LinkedIn profile URL"
                type="string"
                value={formValues.linkedin || ''}
                onChange={(e) => {
                  updateForm({ fieldName: 'linkedin', value: e.target.value });
                }}
                isTouched={touchedFormFields.linkedin}
                errorMessage={errors.linkedin}
              />
            </div>
            {/*
             ** Address fields
             */}
            <div className="edit-profile__form__fields__address">
              <Typography as="p" variant="body-2" fontWeight="semiBold">
                Address
              </Typography>
              <InputField
                label="Address line"
                id="address"
                variant={componentSizeVariantsEnum.SMALL}
                placeholder="Address line"
                type="text"
                value={formValues.address}
                onChange={(e) => {
                  updateForm({ fieldName: 'address', value: e.target.value });
                }}
              />
              <div className="edit-profile__form__fields__address__grid">
                {/* <InputField
                  label="Country"
                  id="country"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Country"
                  type="text"
                  value={formValues.country}
                  onChange={(e) => {
                    updateForm({ fieldName: 'country', value: e.target.value });
                  }}
                /> */}
                <SelectField
                  label="Country"
                  placeholder="Country"
                  variant={componentSizeVariantsEnum.SMALL}
                  selectedValue={formValues.country}
                  options={countries}
                  onSelection={({ value }) => {
                    updateForm({ fieldName: 'country', value: value as unknown as string });
                  }}
                />
                <InputField
                  label="State"
                  id="state"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="State"
                  type="text"
                  value={formValues.state}
                  onChange={(e) => {
                    updateForm({ fieldName: 'state', value: e.target.value });
                  }}
                />

                <SearchAndSelect
                  label="City"
                  placeholder="City"
                  variant={componentSizeVariantsEnum.SMALL}
                  selectedValue={formValues.cityQuery || ''}
                  options={places}
                  onSelection={({ value }) => {
                    updateForm({ fieldName: 'city', value: value as unknown as string });
                    updateForm({ fieldName: 'cityQuery', value: value as unknown as string });
                  }}
                  onInputChange={(e) => {
                    const query = e.target.value;
                    updateForm({ fieldName: 'cityQuery', value: query as unknown as string });
                    getPlaces({
                      input: query.toString()
                    });
                  }}
                  errorMessage={errors.city}
                  isTouched={touchedFormFields.city}
                />

                <InputField
                  label="Zip Code"
                  id="zipCode"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="Zip Code"
                  type="text"
                  value={formValues.zipCode}
                  onChange={(e) => {
                    updateForm({ fieldName: 'zipCode', value: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="edit-profile__form__buttons">
            <Button variant="secondary" type="button" size="small" onClick={closeModal}>
              Close
            </Button>

            <Button variant="primary" type="submit" size="small" loading={loading}>
              Save Changes
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default EditProfile;
