import { configureStore } from '@reduxjs/toolkit';

import authSlice from './auth/auth.slice';
import commonSlice from './common/common.slice';
import companySlice from './company/company.slice';
import profileSlice from './profile/profile.slice';
import searchSlice from './search/search.slice';
import socialMediaSlice from './socialMedia/socialMedia.slice';
import ideaHubSlice from './ideaHub/ideaHub.slice';
import programSlice from './program/program.slice';
import chatSlice from './chat/chat.slice';
import notificationsSlice from './notifications/notifications.slice';
import externalProgramSlice from './externalProgram/externalProgram.slice';
import externalApplicationSlice from './externalApplication/externalApplication.slice';

const store = configureStore({
  reducer: {
    auth: authSlice,
    common: commonSlice,
    company: companySlice,
    profile: profileSlice,
    search: searchSlice,
    socialMedia: socialMediaSlice,
    ideaHub: ideaHubSlice,
    program: programSlice,
    chat: chatSlice,
    notifications: notificationsSlice,
    externalProgramSlice: externalProgramSlice,
    externalApplicationSlice: externalApplicationSlice
  }
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export default store;
