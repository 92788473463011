import React, { useEffect, useState } from 'react';
import { TUserTypes } from 'src/constants/user.constants';
import { PlusIconCircle } from 'src/components/common/common.icons';
import { Col, Row } from 'react-bootstrap';
import { TGetCompany, TGetCompanyPresignedUrlActionRequest, TUploadCompanyProfilePictureResponse } from 'src/services/apiEndpoint.types.ts';
import { useAppSelector } from 'src/store/hooks';
import { httpRequest } from 'src/services/commonApis';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import { Loader } from 'src/components/common';

import cx from './resources.module.scss';
import UploadedResourceBox from './UploadedResourceBox/UploadedResourceBox';
import { openPopup } from 'src/store/common/common.slice';
import { useDispatch } from 'react-redux';

export type TActivities = {
  viewCompanyId: string;
  viewerId: string;
  viewerType: TUserTypes;
};

export default function ResoucesData({ viewCompanyId, viewerId, viewerType }: TActivities) {
  const viewCompany: TGetCompany = useAppSelector((store) => store.company.myCompany);
  const [programData, setProgramData] = useState<any>([]);
  const [memberData, setSetMemberData] = useState<any>([]);
  const [resourceData, setResourceData] = useState<any>([]);
  const dispatch = useDispatch()
  const [resourceLoading, setResourceLoading] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  async function getCompanyData() {
    const response: any = await httpRequest(`program/list?companyId=${viewCompany?.id}&programStatus=Active`, 'GET', null, 'json', 'program')
    if (response?.data?.programs) {
      setProgramData(response?.data?.programs)
    }
  }
  async function getMemberData() {
    const response: any = await httpRequest(`company/get-members?companyId=${viewCompany?.id}`, 'GET', null, 'json', 'company')
    if (response?.data?.users) {
      setSetMemberData(response?.data?.users)
    }
  }
  useEffect(() => {
    if (viewCompany) {
      getCompanyData()
      getMemberData()
      getResources()
    }
  }, [viewCompany]);

  async function getResources() {
    setLoading(true)
    const response = await httpRequest(`resource?companyId=${viewCompany?.id}`, 'GET', null, 'json', 'program');
    if (response.body?.length > 0) {
      setResourceData(response.body)
    }
    setLoading(false)
  }

  async function handleResourceUpload(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files) {
      const file = e.target.files[0]
      if (file) {
        const fileName = file?.name;
        const fileType = file?.type;
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);
        reader.onload = async () => {
          try {
            setResourceLoading(true)
            const data = reader.result;
            const uploadProfilePicturePayload: TGetCompanyPresignedUrlActionRequest = {
              id: viewCompany?.id,
              data,
              showPopup: false
            };
            const response = await httpRequest(`resource/upload?id=${uploadProfilePicturePayload.id}&resourceName=${fileName}`, 'GET', null, 'json', 'program');
            if (response?.data?.signedUrl) {
              const res: APIResponse<TUploadCompanyProfilePictureResponse> | any =
                await new CompanyAPIService().uploadCompanyProfilePicture({
                  presignedUrl: response?.data?.signedUrl,
                  data,
                  includeAuthorizationHeaders: false
                });
              if (res.status === 200) {
                const data = await httpRequest(`resource/upload?id=${uploadProfilePicturePayload.id}&resourceName=${fileName}`, 'GET', null, 'json', 'program');
                if (response?.data?.signedUrl) {
                  const finalData = {
                    companyId: `${uploadProfilePicturePayload.id}`,
                    type: fileType,
                    url: data?.data?.key
                  }
                  const uploadRes = await httpRequest('resource', 'POST', finalData, 'json', 'program');
                  if (uploadRes?.message?.includes('successfully')) {
                    getResources()
                    dispatch(
                      openPopup({
                        popupMessage: 'resource uploaded successfully',
                        popupType: 'success'
                      })
                    );
                  }
                }
              }
              console.log(res, 'Upload Company Profile Picture Response');
            } else {
              console.error('No signed URL returned in response');
            }
          } catch (error) {
            console.error('Error in file upload process', error);
          } finally {
            setResourceLoading(false);
          }
        }
      }
    }
    setResourceLoading(false);
  }

  return (
    <>
      {loading && <Loader />}
      {!loading && <div className={cx.resourceContainer}>
        <Row>
          <Col md={3}>
            <div className={cx.imageUploadBox}>
              <div className={cx.uploadContent}>
                {resourceLoading && <Loader />}
                {!resourceLoading && <label htmlFor='upload' className={cx.uploadLabel}>
                  <input className={cx.uploadInput} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleResourceUpload(e)} type='file' id='upload' accept='' />
                  <PlusIconCircle />
                  <h5 className={cx.uploadTitle}>Upload Resources</h5>
                </label>}
              </div>
            </div>
          </Col>
          {resourceData?.map((resourceData: any) => {
            return (
              <UploadedResourceBox key={resourceData?.id} getResources={getResources} resourceData={resourceData} programData={programData} memberData={memberData} />
            )
          })}
        </Row>
      </div>}
    </>
  )
}
