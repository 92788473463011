export type TTextFormatter = {
  value: string | number | null | undefined;
  defaultValue?: string | number;
};

const textFormatter = ({ value, defaultValue = '-' }: TTextFormatter) => {
  try {
    if (typeof value === 'string' && !value.trim()) {
      return '-';
    }
    if (value !== undefined && value !== null) {
      return value;
    }
    throw new Error('Value not found');
  } catch (err) {
    console.log((err as Error)?.message);
  }
  return defaultValue;
};

export default textFormatter;
