import copy from 'copy-to-clipboard';

type TCopyToClipboard = {
  text: string;
};

const useCopyToClipboard = () => {
  const copyToClipboard = async ({ text }: TCopyToClipboard) => {
    if ('clipboard' in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      // clipboard is not available in unsecure contexts, so using the external library.
      copy(text);
    }
  };

  return { copyToClipboard };
};

export default useCopyToClipboard;
