import { useRef } from 'react';
import { ChatList, ChatMessages } from 'src/components/chat';
import Header from 'src/components/header/Header';
import { useCalculateElementHeight, useGetIdentity, useUrlParamValue } from 'src/hooks';
import { TUserTypes } from 'src/constants/user.constants';

import './chat.scss';

const Chat = () => {
  // Hooks
  const chatBodyRef = useRef<HTMLDivElement>(null);
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();

  const selectedUserId: string = paramValue({ paramName: 'userId' }) || '';
  const selectedUserType = paramValue({ paramName: 'userType' }) as unknown as TUserTypes;
  const { loggedInUserId, loggedInUserType, loggedInCompanyId, authenticatedId } = getIdentities();

  const showChatWindowOnMobile: boolean = Boolean(selectedUserId && selectedUserType);

  const { elementHeight } = useCalculateElementHeight({
    ref: chatBodyRef,
    triggers: [window.location.search],
    heightOffset: 64
  });

  return (
    <div className="chat-page">
      <Header />
      <div className="chat-page__body" ref={chatBodyRef} style={{ height: `${elementHeight}px` }}>
        <ChatList
          selectedUserId={selectedUserId}
          selectedUserType={selectedUserType}
          showChatList={!showChatWindowOnMobile}
          loggedInUserId={loggedInUserId}
          loggedInCompanyId={loggedInCompanyId}
          authenticatedId={authenticatedId}
          loggedInUserType={loggedInUserType}
        />
        <ChatMessages
          selectedUserId={selectedUserId}
          selectedUserType={selectedUserType}
          showChatWindowOnMobile={showChatWindowOnMobile}
          directMessageContext="directMessage"
        />
      </div>
    </div>
  );
};

export default Chat;
