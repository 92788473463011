import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './loader.scss';

import LoaderIcon from '../common.icons/LoaderIcon';

export type TLoader = {
  color?: string;
} & HTMLAttributes<HTMLDivElement>;

const Loader = ({ color, className }: TLoader) => {
  return (
    <div className={classNames(className, 'circle-loader-component')}>
      <LoaderIcon color={color} />
    </div>
  );
};

export default Loader;
