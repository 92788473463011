import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NotificationsAPIService } from 'src/services/notifications.api.service';
import { APIResponse } from 'src/services/base.api.service';
import {
  TDeRegisterDeviceRequest,
  TDeRegisterDeviceResponse,
  TGetAllNotification,
  TGetAllNotificationsRequest,
  TGetAllNotificationsResponse,
  TGetInviteNotification,
  TGetInviteNotificationsRequest,
  TGetInviteNotificationsResponse,
  TRegisterDeviceRequest,
  TRegisterDeviceResponse
} from 'src/services/apiEndpoint.types.ts';
import { TNotificationAction } from 'src/components/notifications/notifications.types';
import {
  allNotificationsLoadingId,
  inviteNotificationsLoadingId
} from 'src/pages/notifications/Notifications';

import { notificationInitialState } from './notifications.initialState';

import { AppDispatch } from '../store';
import { startLoading, stopLoading } from '../auth/auth.slice';
import { assignLoaderId, resetLoaderId } from '../common/common.slice';

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState: notificationInitialState,
  reducers: {
    deviceRegistrationSuccess: (state, action: PayloadAction<string>) => {
      state.deviceId = action?.payload;
    },
    getAllNotificationsSuccess: (
      state,
      action: PayloadAction<{
        allNotifications: TGetAllNotification[];
        currentPage: number;
        totalPages: number;
      }>
    ) => {
      try {
        const { allNotifications, currentPage, totalPages } = action.payload;

        const currentAllNotifications: TGetAllNotification[] = JSON.parse(
          JSON.stringify(state.allNotifications)
        );

        allNotifications.forEach((item) => {
          const indexInCurrentList = currentAllNotifications.findIndex(
            (eItem) => eItem?.id === item?.id
          );

          if (indexInCurrentList < 0) {
            currentAllNotifications.push(item);
          }
        });

        state.allNotifications = currentAllNotifications;
        state.allNotificationsPageNumber = currentPage;
        state.allNotificationsMoreItemsLeft = currentPage < totalPages;
        state.highlightNotificationBell = false;
      } catch (e) {
        console.log(e);
      }
    },
    getInviteNotificationsSuccess: (state, action: PayloadAction<TGetInviteNotification[]>) => {
      const inviteNotifications = action.payload;

      state.inviteNotifications = inviteNotifications;
      state.highlightNotificationBell = false;
    },
    /*
     ** New invite notification from FCM
     */
    getInviteNotificationsSuccessViaFCM: (state, action: PayloadAction<string>) => {
      try {
        const inviteNotification: TGetInviteNotification = JSON.parse(action.payload);

        const currentInviteNotifications: TGetInviteNotification[] = JSON.parse(
          JSON.stringify(state.inviteNotifications)
        );

        const indexInCurrentNotifications = currentInviteNotifications.findIndex(
          (CItem) => CItem?.id === inviteNotification?.id
        );

        if (indexInCurrentNotifications < 0) {
          currentInviteNotifications.unshift(inviteNotification);
        }

        state.inviteNotifications = currentInviteNotifications;
      } catch (e) {
        console.log(e);
      }
    },
    /*
     ** All invite notification from FCM
     */
    getAllNotificationsSuccessViaFCM: (state, action: PayloadAction<string>) => {
      try {
        const allNotification: TGetAllNotification = JSON.parse(action.payload);

        const currentAllNotifications: TGetAllNotification[] = JSON.parse(
          JSON.stringify(state.allNotifications)
        );

        const indexInCurrentNotifications = currentAllNotifications.findIndex(
          (CItem) => CItem?.id === allNotification?.id
        );

        if (indexInCurrentNotifications < 0) {
          currentAllNotifications.unshift(allNotification);
        }

        state.allNotifications = currentAllNotifications;
      } catch (e) {
        console.log(e);
      }
    },
    updateInviteNotificationActionStatus: (
      state,
      action: PayloadAction<{ id: string; actionTaken: TNotificationAction }>
    ) => {
      try {
        const { id, actionTaken } = action.payload;

        const currentInviteNotifications: TGetInviteNotification[] = JSON.parse(
          JSON.stringify(state.allNotifications)
        );

        const selectedNotification = currentInviteNotifications.find((item) => item?.id === id);

        if (selectedNotification) {
          selectedNotification.actionTaken = actionTaken;
        }

        state.allNotifications = currentInviteNotifications;
      } catch (e) {
        console.log(e);
      }
    },
    /*
     ** Update idea hub notification count
     */
    updateIdeaHubNotificationCount: (state, action: PayloadAction<number>) => {
      state.ideaHubNotificationCount = action?.payload || 0;
    },
    /*
     ** Update chat notifications count
     */
    updateChatNotificationCount: (
      state,
      action: PayloadAction<{
        totalUnreadConversationCount: number;
        totalUnreadMessageCount: number;
      }>
    ) => {
      const { totalUnreadConversationCount, totalUnreadMessageCount } = action?.payload;

      state.totalUnreadConversationCount = totalUnreadConversationCount || 0;
      state.totalUnreadMessageCount = totalUnreadMessageCount || 0;
    },
    /*
     ** Increment All notifications page
     */
    incrementAllNotificationsPageNumber: (state, action: PayloadAction<number>) => {
      state.allNotificationsPageNumber = state.allNotificationsPageNumber + action?.payload;
    },
    /*
     ** Reset all notifications
     */
    resetAllNotifications: (state) => {
      state.allNotificationsPageNumber = 1;
      state.allNotificationsMoreItemsLeft = true;
      state.allNotifications = [];
    },
    /*
     ** Increment Invite notifications page
     */ incrementInviteNotificationsPageNumber: (state, action: PayloadAction<number>) => {
      state.inviteNotificationsPageNumber = state.inviteNotificationsPageNumber + action?.payload;
    },
    /*
     ** Reset Invite notifications
     */
    resetInviteNotifications: (state) => {
      state.inviteNotificationsPageNumber = 1;
      state.inviteNotificationsMoreItemsLeft = true;
    },
    /*
     ** Update notifications bell
     */
    updateNotificationsBell: (state, action: PayloadAction<boolean>) => {
      state.highlightNotificationBell = action?.payload;
    }
  }
});

export const {
  deviceRegistrationSuccess,
  getAllNotificationsSuccess,
  getInviteNotificationsSuccess,
  updateInviteNotificationActionStatus,
  getAllNotificationsSuccessViaFCM,
  getInviteNotificationsSuccessViaFCM,
  updateIdeaHubNotificationCount,
  updateChatNotificationCount,
  updateNotificationsBell,
  incrementAllNotificationsPageNumber,
  resetAllNotifications,
  incrementInviteNotificationsPageNumber,
  resetInviteNotifications
} = notificationsSlice.actions;

export default notificationsSlice.reducer;

export const registerDevice =
  (payload: TRegisterDeviceRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status }: APIResponse<TRegisterDeviceResponse> =
        await new NotificationsAPIService().registerDevice(payload);
      if (status === 200) {
        dispatch(deviceRegistrationSuccess(payload?.deviceId));
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const deRegisterDevice =
  (payload: TDeRegisterDeviceRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TDeRegisterDeviceResponse> =
        await new NotificationsAPIService().deRegisterDevice(payload);
      if (status === 200) {
        console.log(data);
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const getAllNotifications =
  (payload: TGetAllNotificationsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(assignLoaderId(allNotificationsLoadingId));
      const { status, data }: APIResponse<TGetAllNotificationsResponse> =
        await new NotificationsAPIService().getAllNotifications(payload);
      if (status === 200) {
        dispatch(
          getAllNotificationsSuccess({
            allNotifications: data?.notifications,
            currentPage: data.currentPage,
            totalPages: data?.totalPages
          })
        );
      }
    } catch (err) {
    } finally {
      dispatch(resetLoaderId());
    }
  };

export const getInviteNotifications =
  (payload: TGetInviteNotificationsRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(assignLoaderId(inviteNotificationsLoadingId));
      const { status, data }: APIResponse<TGetInviteNotificationsResponse> =
        await new NotificationsAPIService().getInviteNotifications(payload);
      if (status === 200) {
        dispatch(getInviteNotificationsSuccess(data?.notifications));
      }
    } catch (err) {
    } finally {
      dispatch(resetLoaderId());
    }
  };
