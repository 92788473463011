import { HTMLAttributes } from 'react';
import classNames from 'classnames';

import './like.scss';

import Typography from '../typography/Typography';
import { ThumbsUpIcon } from '../common.icons';

export type TLike = {
  count: number;
  onLike: () => void;
  onUnLike: () => void;
  active?: boolean;
} & HTMLAttributes<HTMLDivElement>;

const Like = ({ count = 0, onLike, onUnLike, className, active = false, ...rest }: TLike) => {
  const handleClick = () => {
    if (active) {
      onUnLike();
    } else {
      onLike();
    }
  };

  return (
    <div
      className={classNames('like-component', className, { 'like-component--is-active': active })}
      {...rest}
      onClick={handleClick}
    >
      <Typography as="span" variant="body-2" fontWeight="medium">
        <ThumbsUpIcon /> <span>{count}</span>
      </Typography>
    </div>
  );
};

export default Like;
