import { useEffect } from 'react';
import { TGetIdeaGroupParticipantsRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import { getIdeaGroupParticipants, resetIdeaGroupsParticipants } from 'src/store/chat/chat.slice';

import { TUseGetGroupParticipants } from './groupChatWindow.types';

/**
 * Fetches members of idea group
 */
const useGetGroupParticipants = ({ groupId }: TUseGetGroupParticipants) => {
  // Hooks
  const dispatch = useAppDispatch();

  const handleGetIdeaGroupParticipants = () => {
    const payload: TGetIdeaGroupParticipantsRequest = {
      ideaConversationId: groupId,
      limit: 1000,
      offset: 1
    };

    dispatch(getIdeaGroupParticipants(payload));
  };

  useEffect(() => {
    if (groupId) {
      handleGetIdeaGroupParticipants();
    }

    return () => {
      dispatch(resetIdeaGroupsParticipants());
    };
  }, [groupId]);

  return handleGetIdeaGroupParticipants;
};

export default useGetGroupParticipants;
