import * as Yup from 'yup';

const rateIdeaValidationSchema = Yup.object().shape({
  productMarketFit: Yup.number()
    .min(1, 'Please rate market fit')
    .required('Please rate market fit'),
  marketSize: Yup.number().min(1, 'Please rate market size').required('Please rate market size'),
  uniqueness: Yup.number().min(1, 'Please rate uniqueness').required('Please rate uniqueness'),
  additionalFeedback: Yup.string().required('Please add additional feedback')
});

export default rateIdeaValidationSchema;
