import { HTMLAttributes, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import { selectDeselectCheckbox } from 'src/utils';

import './multiSelectField.scss';

import Label from '../label/Label';
import ErrorMessage from '../errorMessage/ErrorMessage';
import Typography from '../../typography/Typography';
import { CrossIcon, SelectIcon } from '../../common.icons';
import SubMenuModal from '../../subMenuModal/SubMenuModal';
import Tag from '../../tag/Tag';

export type TSelectOptions = {
  label: string;
  value: string | number;
  id: string;
};

export type TSelectedValuesReturn = {
  selectedValues: (string | number)[];
};

type TMultiSelectFieldProps = {
  errorMessage?: string; // The error message to display.
  label: string | ReactNode; // The label for the select field.
  placeholder: string; // The placeholder text for the select field.
  variant?: componentSizeVariantsEnum; // The variant of the select field.
  options: TSelectOptions[]; // An array of options for the select field.
  selectedValues: (string | number)[]; // Selected values
  showTags?: boolean; // Whether to display a tag for each selected item.
  selectTagsLabel?: ReactNode; // label for the selected tags
  onSelection: ({ selectedValues }: TSelectedValuesReturn) => void; // The callback function triggered when an option is selected.
} & HTMLAttributes<HTMLDivElement>;

/**
 * This is select component that tries to replicate the behavior of native html Select field.
 * Only create to match the the visual design of other components in tha app.
 * Multiple values can be selected from the dropdown
 */
const MultiSelectField = ({
  id,
  errorMessage,
  className,
  label,
  variant = componentSizeVariantsEnum.LARGE,
  options,
  placeholder,
  onSelection,
  selectedValues,
  showTags = false,
  selectTagsLabel,
  ...rest
}: TMultiSelectFieldProps) => {
  const [openOptions, setOpenOptions] = useState<boolean>(false);

  const toggleOptionsDisplay = () => {
    setOpenOptions((prev) => !prev);
  };

  const handleSelection = ({ value }: { value: string | number }) => {
    const updatedArray = selectDeselectCheckbox({
      arr: [...selectedValues],
      value
    });
    onSelection({ selectedValues: updatedArray });
  };

  return (
    <div
      className={classNames(
        className,
        'multi-select-field-component',
        `multi-select-field-component--${variant}`
      )}
      {...rest}
    >
      <Label htmlFor={id} variant={variant}>
        {label}
      </Label>
      <div
        className="multi-select-field-component__custom-select"
        onClick={(e) => e.stopPropagation()}
      >
        <div
          onClick={toggleOptionsDisplay}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              toggleOptionsDisplay();
            }
          }}
          className={classNames('multi-select-field-component__custom-select__select')}
          tabIndex={0}
        >
          <Typography as="span" variant="caption" fontWeight="regular">
            {placeholder}
          </Typography>
          <SelectIcon />
        </div>
        {/* To close the select when clicked outside */}
        <SubMenuModal setOpenSubMenuModal={setOpenOptions}>
          <ul
            id={id}
            className={classNames('multi-select-field-component__custom-select__options', {
              'multi-select-field-component__custom-select__options--is-open': openOptions
            })}
          >
            {options.map((option) => {
              // Whether the option is selected.
              const isOptionSelected: boolean = selectedValues.indexOf(option?.value) > -1;
              return (
                <li
                  value={option.value}
                  key={option.id}
                  className={classNames(
                    'multi-select-field-component__custom-select__options__option',
                    {
                      'multi-select-field-component__custom-select__options__option--selected':
                        isOptionSelected
                    }
                  )}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleSelection({ value: option.value });
                    }
                  }}
                >
                  <input
                    type="checkbox"
                    onChange={() => handleSelection({ value: option.value })}
                    checked={isOptionSelected}
                  />
                  <span>{option.label}</span>
                </li>
              );
            })}
          </ul>
        </SubMenuModal>
      </div>
      <div className="multi-select-field-component__selected">
        {selectTagsLabel && (
          <Typography as="p" variant="caption" fontWeight="semiBold">
            {selectTagsLabel}
          </Typography>
        )}
        {showTags && (
          <div className="multi-select-field-component__selected__tags">
            {selectedValues.map((value) => (
              <Tag
                key={value}
                variant="active"
                size="small"
                endIcon={<CrossIcon />}
                onClick={() => handleSelection({ value })}
              >
                {value}
              </Tag>
            ))}
          </div>
        )}
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} />}
    </div>
  );
};

export default MultiSelectField;
