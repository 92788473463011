import { Dispatch, SetStateAction, useContext } from 'react';
import Card from 'src/components/common/card/Card';
import Typography from 'src/components/common/typography/Typography';
import { CompanyIcon, EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { textFormatter } from 'src/utils';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import calculateDifferenceInMonthYears from 'src/utils/calculateDifferenceInMonthYears/calculateDifferenceInMonthYears';
import { SelfUserContext } from 'src/pages/myProfile/MyProfile';

import './companyCard.scss';

import ProfilePicture from '../../profilePicture/ProfilePicture';
import DeleteWorkExperience from '../deleteWorkExperience/DeleteWorkExperience';

export const updateExperienceModalId = 'update-experience-modal';
const deleteExperienceModalId = 'delete-experience-modal';

export type TCompanyCard = {
  jobTitle: string;
  employmentType: string;
  companyName: string;
  start: string;
  end: string;
  currentlyWorking: boolean;
  description: string;
  experienceIndex: number;
  experienceToUpdate: number;
  setExperienceToUpdate: Dispatch<SetStateAction<number>>; // function to set index of experience to update
  setUpdateWorkExperienceMode: Dispatch<SetStateAction<'Add' | 'Update'>>; // function to set mode of experience update
};

const CompanyCard = ({
  experienceIndex,
  jobTitle,
  employmentType,
  companyName,
  start,
  end,
  currentlyWorking,
  description,
  experienceToUpdate,
  setExperienceToUpdate,
  setUpdateWorkExperienceMode
}: TCompanyCard) => {
  const { years, months } = calculateDifferenceInMonthYears({ date1Str: start, date2Str: end });

  const isSelfUser = useContext(SelfUserContext); // Whether this profile card is viewed by self.

  const dispatch = useAppDispatch();
  const modalId = useAppSelector<string>((store) => store.common.modalId);

  const openUpdateModal = () => {
    setExperienceToUpdate(experienceIndex);
    setUpdateWorkExperienceMode('Update');
    dispatch(assignModalId(updateExperienceModalId));
  };

  const openDeleteModal = () => {
    setExperienceToUpdate(experienceIndex);
    dispatch(assignModalId(deleteExperienceModalId));
  };

  const closeModal = () => {
    dispatch(resetModalId());
  };

  return (
    <Card className="company-card">
      <ProfilePicture
        url={'profilePic'}
        fallback={<CompanyIcon />}
        className="company-card__picture"
      />
      <div className="company-card__content">
        <div className="company-card__content__header">
          <div className="company-card__content__header__company">
            <Typography
              as="p"
              variant="body-2"
              fontWeight="semiBold"
              className="company-card__content__header__company__name"
            >
              {textFormatter({ value: companyName })}
            </Typography>
            <Typography
              as="span"
              variant="body-2"
              fontWeight="regular"
              className="company-card__content__header__company__job-title"
            >
              {textFormatter({ value: jobTitle })}
              <hr></hr>
              {textFormatter({ value: employmentType })}
            </Typography>
          </div>
          {isSelfUser && (
            <div className="company-card__content__header__actions">
              <div
                className="company-card__content__header__actions__action"
                onClick={openUpdateModal}
              >
                {<EditIcon />}
              </div>
              <div
                className="company-card__content__header__actions__action"
                onClick={openDeleteModal}
              >
                {<TrashIcon />}
              </div>
            </div>
          )}
        </div>
        <Typography
          as="span"
          variant="caption"
          fontWeight="regular"
          className="company-card__content__duration"
        >
          {textFormatter({ value: `${start} - ${currentlyWorking ? 'Present' : end}` })}
          {!currentlyWorking && (
            <>
              <hr></hr>
              {textFormatter({ value: `${years} Years ${months} Months` })}
            </>
          )}
        </Typography>
        <Typography
          as="p"
          variant="body-2"
          fontWeight="regular"
          className="company-card__content__description"
        >
          {textFormatter({ value: description })}
        </Typography>
      </div>

      {modalId === deleteExperienceModalId && (
        <DeleteWorkExperience
          isModalOpen={modalId === deleteExperienceModalId}
          onModalClose={closeModal}
          experienceToUpdate={experienceToUpdate}
        />
      )}
    </Card>
  );
};

export default CompanyCard;
