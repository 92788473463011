import React from 'react';
import { Slider } from '@mui/material';

import cx from './progresSlider.module.scss';

interface ProgressInterface {
  min: number;
  max: number;
  value?: number;
  label: String;
  disabled?: boolean;
  onChange?: any
}

const ProgressBar = ({ min, max, value, label, disabled = false, onChange }: ProgressInterface) => {
  console.log(value, 'valueDAta');
  return (
    <div className={`${cx.radiusSlider}`}>
      <span className={`${cx.label}`}>{label}</span>
      <span>{min}</span>
      <Slider
        size="small"
        value={value}
        aria-label="Small"
        valueLabelDisplay={value ? 'on' : 'off'}
        min={min}
        max={max}
        disabled={disabled}
        onChange={onChange}
      />
      <span>{max}</span>
    </div>
  );
};

export default ProgressBar;
