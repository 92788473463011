import classNames from 'classnames';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import Card from 'src/components/common/card/Card';

import './actionCard.scss';
import { TActionCardProps } from './actionCard.types';

const ActionCard = ({
  title,
  description,
  buttonText,
  className,
  onClick,
  ...rest
}: TActionCardProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Card className={classNames('action-card', className)} {...rest}>
      <Typography as="p" variant="body-2" fontWeight="semiBold">
        {title}
      </Typography>{' '}
      <Typography as="p" variant="body-2" fontWeight="regular">
        {description}
      </Typography>
      <Button size="small" variant="primary" onClick={handleClick}>
        {buttonText}
      </Button>
    </Card>
  );
};

export default ActionCard;
