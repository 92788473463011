import { createContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { TDropdownOptions, Typography } from 'src/components/common';
import { TUserTypes } from 'src/constants/user.constants';
import { useAppSelector } from 'src/store/hooks';

import '../myIdeas/myIdeas.scss';

import SearchIdeas from '../searchFilterIdeas/SearchFilterIdeas';
import IdeaCard from '../ideaCard/IdeaCard';
import { TIdeaHubSearchParams, TIdeaSharedWithMe, TIdeaUserActionType } from '../ideaHub.types';

export type TSharedIdeaCard = {
  id: string;
  status: 'Draft' | 'Finalized';
  title: string;
  description: string;
  updatedAt: string;
  collaborators: { name: string; profilePic: string }[];
  acceptedByUser?: boolean;
  accessType?: 'View Access' | 'Edit Access' | 'Discussion Access';
  ideaOwner: { id: string; userType: TUserTypes; name: string; profilePic: string };
};

export type TMyIdeasProps = {
  updateParams: ({ param, value }: { param: TIdeaHubSearchParams; value: string }) => void;
  increaseFetchIdeasCounter: () => void;
};

export const SharedIdeaContext = createContext(false);

const IdeasSharedWithMe = ({ updateParams, increaseFetchIdeasCounter }: TMyIdeasProps) => {
  // Hooks
  const navigate = useNavigate();
  const ideasSharedWithMe: TIdeaSharedWithMe[] = useAppSelector(
    (store) => store.ideaHub.ideasSharedWithMe
  );
  return (
    <SharedIdeaContext.Provider value={true}>
      <div className="idea-hub-my-ideas">
        <Typography
          variant="subHeading-2"
          fontWeight="semiBold"
          className="idea-hub-my-ideas__heading"
        >
          Shared with me
        </Typography>
        <SearchIdeas
          updateParams={updateParams}
          increaseFetchIdeasCounter={increaseFetchIdeasCounter}
        />
        <div className="idea-hub-my-ideas__ideas">
          {ideasSharedWithMe.map((sharedIdea: TIdeaSharedWithMe, index) => {
            const { idea, collaborators, collaboratorCount } = sharedIdea;
            const userActionStatus: TIdeaUserActionType = sharedIdea?.idea?.userActionStatus;
            const isIdeaAccepted: boolean =
              userActionStatus === 'Accepted' || userActionStatus === 'Complete';

            const { id, name, email, profilePic, role } = idea?.owner;

            const collaboratorsFormatted = [...collaborators, { User: idea?.owner }].map((collaborator) => {
              return {
                id: collaborator?.User?.id,
                name: collaborator?.User?.name,
                profilePic: collaborator?.User?.profilePic
              };
            });

            const ideaFilterDropdownItems: TDropdownOptions[] = [
              {
                label: 'View owner’s profile',
                onClick: () => {
                  navigate(`/view-profile?id=${id}`);
                }
              }
            ];

            if (isIdeaAccepted) {
              ideaFilterDropdownItems.unshift({
                label: 'View Idea',
                onClick: () => {
                  navigate(
                    `/view-idea?id=${idea?.id}&collaborationType=${idea?.sharedFor}&tab=ideaDetails`
                  );
                }
              });
            }

            return (
              <IdeaCard
                key={index}
                ideaFilterDropdownItems={ideaFilterDropdownItems}
                {...idea}
                owner={{ id, name, email, profilePic, role }}
                collaborators={collaboratorsFormatted}
                collaboratorCount={collaboratorCount}
              />
            );
          })}
        </div>
      </div>
    </SharedIdeaContext.Provider>
  );
};

export default IdeasSharedWithMe;
