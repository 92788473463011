import { useEffect } from 'react';
import { TGetIdeaGroupsRequest } from 'src/services/apiEndpoint.types.ts';
import { useAppDispatch } from 'src/store/hooks';
import { getIdeaGroups, resetIdeaGroups } from 'src/store/chat/chat.slice';
import { useGetIdentity } from 'src/hooks';

import { TUseGetIdeaGroups } from './discussionList.types';

/**
 * Fetches chat groups for an Idea
 */
const useGetIdeaGroups = ({ ideaId }: TUseGetIdeaGroups) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { getIdentities } = useGetIdentity();

  const { authenticatedId, loggedInUserType } = getIdentities();

  const handleGetIdeaGroups = () => {
    const payload: TGetIdeaGroupsRequest = {
      ideaId,
      userId: authenticatedId,
      userType: loggedInUserType
    };

    dispatch(getIdeaGroups(payload));
  };

  useEffect(() => {
    if (ideaId) {
      handleGetIdeaGroups();
    }

    return () => {
      dispatch(resetIdeaGroups());
    };
  }, [ideaId]);

  return handleGetIdeaGroups;
};

export default useGetIdeaGroups;
