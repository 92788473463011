import { ChangeEvent, DragEvent } from 'react';
import { openPopup } from 'src/store/common/common.slice';
import { useAppDispatch } from 'src/store/hooks';

import { TMediaType } from './useUploadMedia.types';

/* eslint-disable no-unused-vars */
enum ImageFileTypes {
  BMP = 'bmp',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png'
}

/* eslint-disable no-unused-vars */
enum VideoFileTypes {
  MP4 = 'mp4'
}

enum PdfFile {
  PDF = 'pdf'
}

enum ProgramFileTypes {
  BMP = 'bmp',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
  PDF = 'pdf',
  DOC = 'doc',
  DOCX = 'docx',
  MP4 = 'mp4',
  WEBM = 'webm'
}

export type THandleUploadMedia = {
  e: ChangeEvent<HTMLInputElement> | DragEvent<HTMLDivElement>;
  onLoad: (
    data: string | ArrayBuffer | null,
    fileType: string,
    mediaType: TMediaType,
    name: string,
    size: number
  ) => void;
  mediaType: TMediaType;
  dragDrop?: boolean;
};

const useUploadMedia = () => {
  const dispatch = useAppDispatch();

  const handleUploadMedia = async ({
    e,
    onLoad,
    mediaType,
    dragDrop = false
  }: THandleUploadMedia) => {
    const files = dragDrop
      ? ((e as unknown as DragEvent<HTMLDivElement>).dataTransfer.files as FileList)
      : ((e as unknown as ChangeEvent<HTMLInputElement>).target.files as FileList);

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file) {
        const { name, size } = file;
        const fileType = name.split('.').pop() as unknown as
          | 'bmp'
          | 'jpg'
          | 'jpeg'
          | 'png'
          | 'mp4'
          | 'pdf';

        const selectedImageDetail = {
          type: fileType
        };

        const fileTypes =
          mediaType === 'image'
            ? ImageFileTypes
            : mediaType === 'video'
              ? VideoFileTypes
              : mediaType === 'program'
                ? ProgramFileTypes
                : PdfFile;

        const isImageFileType = Object.keys(fileTypes).includes(
          selectedImageDetail.type.toUpperCase()
        );

        if (isImageFileType) {
          // upload image
          const reader = new FileReader();
          reader.readAsArrayBuffer(file);

          reader.onload = async () => {
            const data: string | ArrayBuffer | null = reader.result;
            onLoad(data, fileType, mediaType, name, size);
          };
        } else if (!isImageFileType) {
          dispatch(
            openPopup({
              popupMessage: 'Image should be of type BMP, JPG, JPEG, MP4 or PNG',
              popupType: 'error'
            })
          );
        }
      }
    }

    // Resetting the value of target
    const element = e.target as HTMLInputElement;
    element.value = '';
  };

  return { handleUploadMedia };
};

export default useUploadMedia;
