import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SelectCardRadio, {
  TSelectCardRadioButton
} from 'src/components/common/formFields/selectCardRadio/SelectCardRadio';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import BackNavigation from 'src/components/common/backNavigation/BackNavigation';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import SelectCardCheckbox, {
  TSelectedValuesReturn
} from 'src/components/common/formFields/selectCardCheckbox/SelectCardCheckbox';
import SelectField from 'src/components/common/formFields/selectField/SelectField';
import MultiSelectField from 'src/components/common/formFields/multiSelectField/MultiSelectField';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Expertise, Industries, Profession, Role } from 'src/constants/user.constants';
import { enumToJson } from 'src/utils';
import { saveOnboardingInfo } from 'src/store/auth/auth.slice';
import { TSaveOnboardingInfoRequest } from 'src/services/apiEndpoint.types.ts/saveOnboardingInfo.endpoint.types';
import { useGetIdentity, useSignOut, useUrlParamValue } from 'src/hooks';

import './tellMeMore.scss';

/*
 ** Generate select options based on enums
 */
const expertiseOptions: TSelectCardRadioButton[] = enumToJson({ enumData: Expertise });
const industriesOptions: TSelectCardRadioButton[] = enumToJson({
  enumData: Industries
});
const professionsOptions: TSelectCardRadioButton[] = enumToJson({ enumData: Profession });

const entrepreneurInterestOptions: TSelectCardRadioButton[] = [
  {
    label: 'Elevate & Promote  my startup',
    value: 'Elevate & Promote  my startup',
    id: 'ELEVATE_PROMOTE_STARTUP'
  },
  {
    label: 'Mentor other entrepreneur',
    value: 'Mentor other entrepreneur',
    id: 'MENTOR_OTHER_ENTREPRENEUR'
  },
  {
    label: 'Invest in startup',
    value: 'Invest in startup',
    id: 'Invest in startup'
  },
  {
    label: 'Seek Funding',
    value: 'Seek Funding',
    id: 'SEEK_FUNDING'
  }
];

const startupStageOptions: TSelectCardRadioButton[] = [
  {
    label: 'Early Stage Startup',
    value: 'Early Stage Startup',
    id: 'early-stage-startup'
  },
  {
    label: 'Growth Stage Startup',
    value: 'Growth Stage Startup',
    id: 'growth-stage-startup'
  }
];

const backgroundOptions: TSelectCardRadioButton[] = [
  {
    label: 'Technical',
    value: 'Technical',
    id: 'technical'
  },
  { label: 'Non-technical', value: 'Non-technical', id: 'non-technical' },
  { label: 'Both', value: 'Both', id: 'both' }
];

const aspiringEntrepreneurInterestOptions: TSelectCardRadioButton[] = [
  {
    label: 'Mentor',
    value: 'Mentor',
    id: 'mentor'
  },
  { label: 'Co-founder', value: 'Co-founder', id: 'co-founder' },
  { label: 'Funding', value: 'Funding', id: 'funding' }
];

const mentorInvestorInterestOptions: TSelectCardRadioButton[] = [
  {
    label: 'Mentor a startup',
    value: 'Mentor a startup',
    id: 'mentor-startup'
  },
  {
    label: 'Invest in idea or startup',
    value: 'Invest in idea or startup',
    id: 'invest-idea-startup'
  }
];

const whyAreYouHere = (
  <>
    Why are you{' '}
    <Typography
      as="span"
      variant="heading-3"
      fontWeight="semiBold"
      className="tell-me-more__heading--highlight"
    >
      {' '}
      here
    </Typography>
    ?
  </>
);

const mentorOrInvestor = 'MENTOR_INVESTOR';

/*
 * This page should not be directly accessible via url.
 * Information collected from previous steps is necessary to proceed to next step.
 * In this page, we request additional information from user.
 * The fields are completely dependent on the role they have selected in the previous page.
 * This page is NOT OPTIONAL for mentor and investors
 */
const TellMeMore = () => {
  /*
   ** Hooks
   */
  const { paramValue } = useUrlParamValue();
  const { getIdentities } = useGetIdentity();
  const signOut = useSignOut();

  /*
   ** Constants
   */
  const preselectedUserType = paramValue({ paramName: 'userType' });
  const { authenticatedEmail, authenticatedId } = getIdentities();
  /*
   ** Aspiring entrepreneur state
   */
  const [profession, setUserProfession] = useState<string | number>('');
  const [background, setUserBackground] = useState<string>('');
  const [lookingFor, setLookingFor] = useState<(string | number)[]>([]);

  /*
   ** Entrepreneur state
   */
  const [entrepreneurInterests, setEntrepreneurInterests] = useState<(string | number)[]>([]);
  const [startUpStage, setStartUpStage] = useState<string>('');

  /*
   ** Mentor/Investor state
   */

  const [mentorInvestorInterests, setMentorInvestorInterests] = useState<(string | number)[]>([]);
  const [expertise, setExpertise] = useState<(string | number)[]>([]);
  const [industries, setIndustries] = useState<(string | number)[]>([]);

  const { loading, shouldRedirectToDashboard } = useAppSelector((store) => store.auth);
  const { profile } = useAppSelector((store) => store.profile);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const heading = {
    'Aspiring Entrepreneur': (
      <>
        Tell me{' '}
        <Typography
          as="span"
          variant="heading-3"
          fontWeight="semiBold"
          className="tell-me-more__heading--highlight"
        >
          more
        </Typography>{' '}
        about
        <Typography
          as="span"
          variant="heading-3"
          fontWeight="semiBold"
          className="tell-me-more__heading--highlight"
        >
          {' '}
          yourself
        </Typography>
        .
      </>
    ),
    Entrepreneur: whyAreYouHere,
    MENTOR_INVESTOR: whyAreYouHere
  };

  const handleBackButton = () => {
    if (preselectedUserType === 'MENTOR_INVESTOR') {
      signOut();
    } else {
      navigate('/onboarding-user-type');
    }
  };

  const handleSubmit = () => {
    /*
     ** This form is OPTIONAL except for the users who intend to be mentor or investor
     */
    const payload = {
      id: authenticatedId,
      email: authenticatedEmail,

      /*
       ** Payload for aspiring entrepreneur
       */
      ...(preselectedUserType === Role.ASPIRING_ENTREPRENEUR && {
        role: Role.ASPIRING_ENTREPRENEUR,
        ...(profession && { profession }),
        ...(background && { background }),
        ...(lookingFor?.length && { interests: lookingFor })
      }),
      /*
       ** Payload for Entrepreneur
       */
      ...(preselectedUserType === Role.ENTREPRENEUR && {
        role: Role.ENTREPRENEUR,
        ...(startUpStage && { startUpStage }),
        ...(entrepreneurInterests?.length && { interests: entrepreneurInterests })
      }),
      /*
       ** Payload for Mentor or Investor
       */
      ...(preselectedUserType === mentorOrInvestor && {
        role: profile?.role,
        ...(expertise?.length && { expertise }),
        ...(industries?.length && { industries }),
        ...(mentorInvestorInterests?.length && { interests: mentorInvestorInterests })
      })
    } as unknown as TSaveOnboardingInfoRequest;

    dispatch(saveOnboardingInfo(payload));
  };

  useEffect(() => {
    if (shouldRedirectToDashboard) navigate('/dashboard');
  }, [shouldRedirectToDashboard]);

  useEffect(() => {
    if (profile?.onboardingComplete) {
      navigate('/dashboard');
    }
  }, [profile?.onboardingComplete]);

  return (
    <div className="tell-me-more">
      <BackNavigation text="Back" onClick={handleBackButton} />
      <Typography
        as="h3"
        variant="heading-3"
        fontWeight="semiBold"
        className="tell-me-more__heading"
      >
        {heading[preselectedUserType as keyof typeof heading]}
      </Typography>

      {/*
       ** Entrepreneur only
       */}
      {preselectedUserType === 'Entrepreneur' && (
        <>
          <div className="tell-me-more__entrepreneur">
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__entrepreneur__label"
            >
              I am here to:{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select multiple&#41;
              </Typography>
            </Typography>
            <SelectCardCheckbox
              options={entrepreneurInterestOptions}
              selectedValues={entrepreneurInterests}
              name="userType"
              variant={componentSizeVariantsEnum.SMALL}
              onSelection={({ selectedValues }: TSelectedValuesReturn) => {
                // selected fields are passed here.
                setEntrepreneurInterests(selectedValues);
              }}
              className="tell-me-more__entrepreneur__options"
            />
          </div>

          <div className="tell-me-more__entrepreneur tell-me-more__startup-stage">
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__entrepreneur__label"
            >
              Which stage your startup is in:{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select any one&#41;
              </Typography>
            </Typography>
            <SelectCardRadio
              options={startupStageOptions}
              selectedValue={startUpStage}
              name="investorStartupStage"
              variant={componentSizeVariantsEnum.MEDIUM}
              onChange={(e) => {
                setStartUpStage(e.target.value);
              }}
              className="tell-me-more__entrepreneur__options"
            />
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              className="tell-me-more__startup-stage__footer"
            >
              After signing up, please create your startup profile.
            </Typography>
          </div>
        </>
      )}

      {/*
       ** Mentor and investor only
       */}
      {preselectedUserType === 'MENTOR_INVESTOR' && (
        <>
          <MultiSelectField
            label={
              <Typography
                as="p"
                variant="caption"
                fontWeight="semiBold"
                className="tell-me-more__mentor-investor-interests__label"
              >
                Select your area of expertise{' '}
                <Typography as="span" variant="caption" fontWeight="regular">
                  &#40;Select multiple&#41;
                </Typography>
              </Typography>
            }
            options={expertiseOptions}
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Select area of expertise"
            selectedValues={expertise}
            onSelection={({ selectedValues }) => setExpertise(selectedValues)}
            showTags={true}
          />

          <MultiSelectField
            label={
              <Typography
                as="p"
                variant="caption"
                fontWeight="semiBold"
                className="tell-me-more__mentor-investor-interests__label"
              >
                Select industries{' '}
                <Typography as="span" variant="caption" fontWeight="regular">
                  &#40;Select multiple&#41;
                </Typography>
              </Typography>
            }
            options={industriesOptions}
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Select industries"
            selectedValues={industries}
            onSelection={({ selectedValues }) => setIndustries(selectedValues)}
            showTags={true}
          />

          <div className="tell-me-more__mentor-investor-interests">
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__mentor-investor-interests__label"
            >
              I am here to:{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select multiple&#41;
              </Typography>
            </Typography>
            <SelectCardCheckbox
              options={mentorInvestorInterestOptions}
              selectedValues={mentorInvestorInterests}
              name="mentorInvestorRoleInterests"
              variant={componentSizeVariantsEnum.MEDIUM}
              onSelection={({ selectedValues }: TSelectedValuesReturn) => {
                // selected fields are passed here.
                setMentorInvestorInterests(selectedValues);
              }}
              className="tell-me-more__mentor-investor-interests__options"
            />
          </div>
        </>
      )}

      {/*
       ** Aspiring entrepreneur only
       */}
      {preselectedUserType === 'Aspiring Entrepreneur' && (
        <>
          {/* User professions */}
          <SelectField
            label="My Profession is:"
            options={professionsOptions}
            variant={componentSizeVariantsEnum.SMALL}
            placeholder="Select profession"
            selectedValue={profession}
            onSelection={({ value }) => setUserProfession(value)}
          />

          {/* User background */}
          <div className="tell-me-more__background">
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__background__label"
            >
              My background is:{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select any one&#41;
              </Typography>
            </Typography>
            <SelectCardRadio
              options={backgroundOptions}
              selectedValue={background}
              name="userBackground"
              variant={componentSizeVariantsEnum.MEDIUM}
              onChange={(e) => {
                setUserBackground(e.target.value);
              }}
              className="tell-me-more__background__options"
            />
          </div>

          {/* I am looking for */}
          <div className="tell-me-more__looking-for">
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              className="tell-me-more__looking-for__label"
            >
              I am looking for:{' '}
              <Typography as="span" variant="caption" fontWeight="regular">
                &#40;Select multiple&#41;
              </Typography>
            </Typography>
            <SelectCardCheckbox
              options={aspiringEntrepreneurInterestOptions}
              selectedValues={lookingFor}
              name="userType"
              variant={componentSizeVariantsEnum.MEDIUM}
              onSelection={({ selectedValues }: TSelectedValuesReturn) => {
                // selected fields are passed here.
                setLookingFor(selectedValues);
              }}
              className="tell-me-more__looking-for__options"
            />
          </div>
        </>
      )}

      <div className="tell-me-more__buttons">
        <Button variant="secondary" size="small" type="button" onClick={handleSubmit}>
          Skip
        </Button>
        <Button
          variant="primary"
          size="small"
          type="button"
          onClick={handleSubmit}
          loading={loading}
        >
          Continue
        </Button>
      </div>
    </div>
  );
};

export default TellMeMore;
