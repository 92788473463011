import { Form, Formik } from 'formik';
import { useEffect, useRef } from 'react';
import { Card, Typography } from 'src/components/common';
import TextAreaEditor from 'src/components/common/formFields/textAreaEditor/TextAreaEditor';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Button from 'src/components/button/Button';
import { TrashIcon, UploadIcon } from 'src/components/common/common.icons';
import { TCreateCompanyFormValues } from 'src/pages';
import { useUploadMedia } from 'src/hooks';
import { TCompanyLogoData } from 'src/pages/createCompany/CreateCompany';
import 'react-quill/dist/quill.snow.css'; // Import styles
import pageDetailsValidationSchema from './pageDetails.validationSchema';
import './pageDetails.scss';

export type TPageDetailsFormValues = {
  name: string;
  introduction?: string;
  about?: string;
  website?: string;
};

export type TPageDetails = {
  handleNext: () => void;
  createCompanyFormValues: Partial<TCreateCompanyFormValues>;
  updateCreateCompanyFormValues: ({
    values
  }: {
    values: Partial<TCreateCompanyFormValues>;
  }) => void;
  storageCompanyLogo: (logoData: TCompanyLogoData) => void;
  companyLogoData: TCompanyLogoData;
  removeCompanyLogo: () => void;
  showCancelButton: boolean;
  handleCancel: () => void;
};

const PageDetails = ({
  handleNext,
  createCompanyFormValues,
  updateCreateCompanyFormValues,
  storageCompanyLogo,
  companyLogoData,
  removeCompanyLogo,
  showCancelButton,
  handleCancel
}: TPageDetails) => {
  const uploadImageRef = useRef<HTMLInputElement>(null);
  const selectedImageRef = useRef<HTMLImageElement>(null);
  const dragDropRef = useRef<HTMLDivElement>(null);

  const initialValues: TPageDetailsFormValues = {
    name: createCompanyFormValues.name || '',
    introduction: createCompanyFormValues.introduction || '',
    about: createCompanyFormValues.about || '',
    website: createCompanyFormValues.website || ''
  };

  const { handleUploadMedia } = useUploadMedia();

  const openImageFileManager = async () => {
    if (uploadImageRef.current) {
      uploadImageRef.current.click();
    }
  };

  const onMediaLoad = (data: string | ArrayBuffer | null) => {
    storageCompanyLogo(data);
  };

  const handleSubmit = (values: TPageDetailsFormValues) => {
    updateCreateCompanyFormValues({ values });
    handleNext();
  };

  const renderSelectedImage = () => {
    const ele = selectedImageRef.current;

    if (ele && companyLogoData) {
      const blob = new Blob([companyLogoData], { type: 'image/jpeg' });

      // Create URL for the blob
      const imageUrl = URL.createObjectURL(blob);

      // Set src attribute to the URL of the blob
      ele.src = imageUrl;
    }
  };

  useEffect(() => {
    renderSelectedImage();
  }, [companyLogoData]);

  return (
    <Card className="create-company-page-details">
      <Typography as="p" variant="body-1" fontWeight="semiBold">
        Page Details
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={pageDetailsValidationSchema}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ errors, setFieldValue, values, touched }) => {
          return (
            <Form className="create-company-page-details__form">
              {/*
               ** Company Type
               */}
              <InputField
                label="Enter Company Name"
                required
                placeholder="Company Name"
                variant={componentSizeVariantsEnum.SMALL}
                type="text"
                id="name"
              />
              {/*
               ** Introduction
               */}
              <InputField
                label="Quick Introduction"
                placeholder="Quick Introduction"
                variant={componentSizeVariantsEnum.SMALL}
                type="text"
                id="introduction"
              />
              {/*
               ** About company
               */}
              <TextAreaEditor
                id="about"
                label="Tell us about your company"
                placeholder="About company"
                onChange={(e) => {
                  setFieldValue('about', e.target.value);
                }}
                value={values.about}
                errorMessage={errors.about}
                isTouched={touched?.about}
                textLengthLimit={2000}
              />
              {/*
               ** Website url
               */}
              <InputField
                label="Enter your website URL"
                placeholder="Website URL"
                variant={componentSizeVariantsEnum.SMALL}
                type="text"
                id="website"
              />
              {/*
               ** Upload company logo
               */}
              <Typography as="p" variant="caption" fontWeight="bold">
                Upload your company logo
              </Typography>
              <div
                className="create-company-page-details__form__upload-logo"
                draggable
                ref={dragDropRef}
                onDrop={(e) => {
                  e.preventDefault();
                  handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'image', dragDrop: true });
                }}
                onDragOver={(e) => {
                  e.preventDefault();
                }}
              >
                <span className="create-company-page-details__form__upload-logo__drag-drop">
                  <UploadIcon />
                  <Typography as="p" variant="body-2" fontWeight="regular">
                    Drag and drop your logo&nbsp;&nbsp;or
                  </Typography>
                  <input
                    type="file"
                    hidden
                    ref={uploadImageRef}
                    accept=".bmp,.jpg,.jpeg,.png"
                    className="select-post-image"
                    onChange={(e) => {
                      handleUploadMedia({ e, onLoad: onMediaLoad, mediaType: 'image' });
                    }}
                  />
                </span>
                <Typography
                  as="span"
                  variant="body-2"
                  fontWeight="medium"
                  className="create-company-page-details__form__upload-logo__browse"
                  onClick={openImageFileManager}
                >
                  Browse on your device
                </Typography>
              </div>
              {companyLogoData && (
                <div className="create-company-page-details__form__selected">
                  <div
                    className="create-company-page-details__form__selected__delete"
                    onClick={removeCompanyLogo}
                  >
                    <TrashIcon />
                  </div>
                  <img alt="uploaded logo" ref={selectedImageRef} />
                </div>
              )}
              <div className="create-company-page-details__form__buttons">
                {showCancelButton && (
                  <Button variant="secondary" type="button" size="small" onClick={handleCancel}>
                    Cancel
                  </Button>
                )}
                <Button variant="primary" type="submit" size="small">
                  Continue
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default PageDetails;
