import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { Button, Tooltip, Typography, VerticalSeparator } from 'src/components/common';
import { colors } from 'src/constants';
import { textEllipsis } from 'src/utils';

import { TUserInfoProps } from './userInfo.types';
import './userInfo.scss';

const UserInfo = ({
  name,
  role,
  email,
  profilePic,
  showRemoveButton,
  handleRemoveUser
}: TUserInfoProps) => {
  // Utils
  const nameWitEllipsis = textEllipsis({ charLimit: 10, text: name });
  const roleWitEllipsis = textEllipsis({ charLimit: 10, text: role });
  const emailWitEllipsis = textEllipsis({ charLimit: 25, text: email });

  return (
    <div className="discussion-user-info">
      <div className="discussion-user-info__left">
        {' '}
        <ProfilePicture
          className="discussion-user-info__left__profile-pic"
          url={profilePic}
          fallback={name?.[0]}
        />
        <div className="discussion-user-info__left__details">
          <div className="discussion-user-info__left__details__row">
            <Tooltip text={name} conditionToDisplay={nameWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="medium"
                style={{ color: colors?.neutral?.[1100] }}
                className="discussion-user-info__left__details__row__name"
              >
                {nameWitEllipsis?.text}
              </Typography>
            </Tooltip>
            <VerticalSeparator />
            <Tooltip text={role} conditionToDisplay={roleWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="caption"
                fontWeight="regular"
                style={{ color: colors?.neutral?.[800] }}
                className="discussion-user-info__left__details__row__role"
              >
                {roleWitEllipsis?.text}
              </Typography>
            </Tooltip>
          </div>

          <Tooltip text={email} conditionToDisplay={emailWitEllipsis?.hasEllipsis} limitWidth>
            <Typography
              as="p"
              variant="caption"
              fontWeight="regular"
              style={{ color: colors?.neutral?.[800] }}
              className="discussion-user-info__left__details__email"
            >
              {emailWitEllipsis?.text}
            </Typography>
          </Tooltip>
        </div>
      </div>

      {showRemoveButton && (
        <Button
          variant="tertiary"
          size="extraSmall"
          className="discussion-user-info__remove-button"
          onClick={handleRemoveUser}
        >
          Remove
        </Button>
      )}
    </div>
  );
};

export default UserInfo;
