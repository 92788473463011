import { ActionIcon, Typography } from 'src/components/common';
import { EditIcon, TrashIcon } from 'src/components/common/common.icons';
import { componentSizeVariantsEnum } from 'src/constants';
import InputField from 'src/components/common/formFields/inputField/InputField';

import './dynamicField.scss';
import { TDateTimeSelectionProps } from './dynamicField.types';

import { TCustomApplicationSection } from '../customApplicationForm.types';

const DateTimeSelection = ({
  id,
  sectionId,
  type,
  label,
  placeholder,
  required,
  options,
  validations,
  response,
  files,
  hasCharacterLimit,
  openChangeFieldModal,
  openDeleteFieldModal,
  handleUpdateField,
  fieldTitle,
  errors
}: TDateTimeSelectionProps) => {
  const updateField = ({ fieldChunk }: { fieldChunk: Partial<TCustomApplicationSection> }) => {
    const field: TCustomApplicationSection = {
      id,
      type,
      label,
      placeholder,
      required,
      options,
      validations,
      response,
      files,
      hasCharacterLimit,
      ...fieldChunk
    };

    handleUpdateField({ sectionId, fieldId: id, field });
  };

  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__header">
        <Typography as="span" variant="caption" fontWeight="semiBold">
          {fieldTitle}
        </Typography>
        <div className="dynamic-field__header__actions">
          <div className="dynamic-field__header__actions__required">
            <input
              type="checkbox"
              id="textFieldRequired"
              checked={required}
              onChange={(e) => {
                updateField({ fieldChunk: { required: e.target.checked } });
              }}
            />
            <Typography
              as="label"
              htmlFor="textFieldRequired"
              variant="caption"
              fontWeight="regular"
            >
              Required
            </Typography>
          </div>
          <ActionIcon
            onClick={() => {
              openChangeFieldModal({ id, fieldType: type });
            }}
          >
            <EditIcon />
          </ActionIcon>
          <ActionIcon
            onClick={() => {
              openDeleteFieldModal({ fieldId: id, sectionId });
            }}
          >
            <TrashIcon />
          </ActionIcon>
        </div>
      </div>

      <InputField
        id={id}
        variant={componentSizeVariantsEnum.SMALL}
        placeholder="Enter field title"
        type="text"
        value={label}
        onChange={(e) => {
          updateField({ fieldChunk: { label: e.target.value } });
        }}
        errorMessage={errors?.[id]}
        isTouched={true}
      />
    </div>
  );
};

export default DateTimeSelection;
