import classNames from 'classnames';
import { FC, HTMLAttributes, ReactNode } from 'react';
import { useAppSelector } from 'src/store/hooks';

import './dropdown.scss';

import DropdownOptionCard, {
  TDropdownOptionCardAlignments,
  TDropdownOptionCardSizes,
  TDropdownOptionCardStates
} from '../dropdownOptionCard/DropdownOptionCard';

export type TDropdownOptions = {
  label?: ReactNode;
} & HTMLAttributes<HTMLDivElement>;

export type TDropdown = {
  id: string;
  state?: TDropdownOptionCardStates;
  size?: TDropdownOptionCardSizes;
  alignment?: TDropdownOptionCardAlignments;
  items: TDropdownOptions[];
} & HTMLAttributes<HTMLDivElement>;

const Dropdown: FC<TDropdown> = ({ id, state, size, alignment, items }: TDropdown) => {
  const { dropdownId } = useAppSelector((store) => store.common);

  return (
    <div style={{ overflow: 'hidden' }}>
      <div
        className={classNames('dropdown-component', {
          'dropdown-component--is-open': dropdownId === id
        })}
        id={id}
      >
        {items.map(({ label, ...rest }, index) => {
          return (
            <DropdownOptionCard
              key={index}
              state={state}
              size={size}
              alignment={alignment}
              {...rest}
            >
              {label}
            </DropdownOptionCard>
          );
        })}
      </div>
    </div>
  );
};

Dropdown.defaultProps = {
  size: 'large',
  alignment: 'left'
};

export default Dropdown;
