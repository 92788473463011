/* eslint-disable multiline-ternary */
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ViewPost from 'src/components/posts/viewPost/ViewPost';
import Header from 'src/components/header/Header';
import { BackNavigation, Loader, TDropdownOptions } from 'src/components/common';
import useUrlParamValue from 'src/hooks/useUrlParamValue/useUrlParamValue';
import EditPost from 'src/components/posts/editPost/EditPost';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { assignModalId, resetModalId } from 'src/store/common/common.slice';
import {
  // clearNewsFeed,
  clearPostMedia,
  deletePost,
  getPost
} from 'src/store/socialMedia/socialMedia.slice';
import { TDeletePostActionRequest, TGetPostRequest } from 'src/services/apiEndpoint.types.ts';
import { useCopyToClipboard, useGetIdentity } from 'src/hooks';
import { TUserTypes } from 'src/constants/user.constants';
import PostAnalytics from 'src/components/posts/postAnalytics/PostAnalytics';

import './viewPostOwned.scss';

const editPostModalId = 'edit-post-modal';

// The component can be accessed by both authenticated id and secondary company Id
// Secondary company id: The company, the user creates in addition to their main profile.
// Must be careful about userId and userType in api requests
const ViewPostOwned = () => {
  // Hooks
  const { paramValue } = useUrlParamValue();
  const { copyToClipboard } = useCopyToClipboard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { getIdentities } = useGetIdentity();

  const { modalId, loading } = useAppSelector((store) => store.common);
  const newsFeed = useAppSelector((store) => store.socialMedia.newsFeed);

  const prevRoute = paramValue({ paramName: 'prev' });
  const postId = paramValue({ paramName: 'postId' }) || '';
  const viewAs = paramValue({ paramName: 'viewAs' }) as unknown as TUserTypes;

  const isModalOpen = modalId === editPostModalId;
  const isAnnouncement = (newsFeed[0] || {})?.post?.isAnnouncement;
  const postTypeDropdown = isAnnouncement ? 'announcement' : 'post';
  const { loggedInCompanyId, loggedInUserType, authenticatedId } = getIdentities();

  const viewerId = viewAs === 'Company' ? loggedInCompanyId : authenticatedId;
  const viewerType = viewAs === 'Company' ? 'Company' : loggedInUserType;

  const openCreatePostModal = () => {
    dispatch(assignModalId(editPostModalId));
  };

  const closeCreatePostModal = () => {
    dispatch(resetModalId());
    dispatch(clearPostMedia());
  };

  const copyPostLink = () => {
    copyToClipboard({ text: `${window.location.host}/view-post?postId=${postId}` });
  };

  const handleGetPost = () => {
    const payload: TGetPostRequest = {
      userId: viewerId,
      userType: viewerType,
      postId
    };

    dispatch(getPost(payload));
  };

  const handleDeletePost = () => {
    const payload: TDeletePostActionRequest = {
      userId: viewerId,
      userType: viewerType,
      postId,
      prevRoute: prevRoute || '/dashboard'
    };

    dispatch(deletePost(payload));
  };

  const dropdownOptions: TDropdownOptions[] = [
    { label: `Copy ${postTypeDropdown} link`, onClick: copyPostLink },
    { label: `Edit ${postTypeDropdown}`, onClick: openCreatePostModal },
    { label: `Delete ${postTypeDropdown}`, onClick: handleDeletePost }
  ];

  useEffect(() => {
    handleGetPost();

    return () => {
      // dispatch(clearNewsFeed());
    };
  }, [postId]);

  return (
    <div className="view-post-owned">
      <Header />
      <div className="view-post-owned__body">
        <div className="view-post-owned__body__content">
          {
            <BackNavigation
              text="Back"
              route={prevRoute || '/dashboard'}
              onClick={() => {
                navigate(-1);
              }}
            />
          }
          {newsFeed?.length < 1 || loading ? (
            <Loader />
          ) : (
            <ViewPost
              dropdownOptions={dropdownOptions}
              post={newsFeed[0]}
              viewerId={viewerId}
              viewerType={viewerType}
              expandPost={true}
            />
          )}
        </div>
        <div className="view-post-owned__body__cards">
          {' '}
          <PostAnalytics />
        </div>
        {isModalOpen && (
          <EditPost
            isModalOpen={isModalOpen}
            onModalClose={closeCreatePostModal}
            post={newsFeed[0]}
            refetchPost={handleGetPost}
            companyProfile={Boolean(viewAs === 'Company')}
            postType={isAnnouncement ? 'announcement' : 'post'}
            viewerId={viewerId}
            viewerType={viewerType}
          />
        )}
      </div>
    </div>
  );
};

export default ViewPostOwned;
