import * as XLSX from 'xlsx';

export const exportToExcel = (applicants: any) => {
  if (!applicants || applicants.length === 0) {
    console.error('No applicants data available for export');
    return;
  }

  const stripHtmlTags = (html: string): string => {
    // const doc = new DOMParser().parseFromString(html, 'text/html');
    // return doc.body.textContent || '';
    return html.replace(/<\/?[^>]+(>|$)/g, ' ').trim();
  };

  const data = [];

  for (let i = 0; i < applicants.length; i++) {
    const applicant = applicants[i];

    // Basic user information
    const userData = {
      ID: applicant.User.id,
      Email: applicant.User.email,
      Name: applicant.User.name,
      PhoneNumber: applicant.User.phoneNumber
    };

    // Dynamically add data from all sections and their sectionItems
    const dynamicData: any = {};
    for (let k = 0; k < applicant.response.sections.length; k++) {
      const section = applicant.response.sections[k];

      // Add section title with a unique key
      dynamicData[`SectionTitle.${k + 1}`] = section.sectionTitle;

      for (let j = 0; j < section.sectionItems.length; j++) {
        const item = section.sectionItems[j];
        let itemResponse = item.response;
        if (item?.type === 'textEditor') {
          itemResponse = stripHtmlTags(item.response);
        }
        console.log(itemResponse, 'item')
        // Add each item label and response with a unique key
        dynamicData[`${item.label}.${k + 1}.${j + 1}`] = itemResponse;
      }
    }

    // Combine user data and dynamic data
    data.push({ ...userData, ...dynamicData });
  }

  if (data.length === 0) {
    console.error('No data available after processing applicants');
    return;
  }

  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Applicants');
  XLSX.writeFile(workbook, 'applicants.xlsx');
};
