import { useMemo } from 'react';
import Typography from 'src/components/common/typography/Typography';
import { linkedinLogo } from 'src/assets/images';
import { parseLinkedinUsername, textFormatter } from 'src/utils';
import { TGetCompanyAddress } from 'src/services/apiEndpoint.types.ts';

import './contactInfo.scss';
import LabelValue from '../../labelValue/LabelValue';

export type TContactInfo = {
  address: TGetCompanyAddress;
  email: string;
  linkedin: string | null;
  contactNumber: number;
  addressLine1: string;
  addressLine2: string;
  country: string;
  state: string;
  city: string;
  zipCode: string;
};

const ContactInfo = ({
  addressLine1 = '',
  addressLine2 = '',
  city = '',
  country = '',
  state = '',
  zipCode = '',
  email,
  linkedin,
  contactNumber
}: TContactInfo) => {
  const linkedInUserName = parseLinkedinUsername({ url: linkedin || '' });

  const openLinkedinProfile = () => {
    if (linkedin) {
      window.open(linkedin);
    }
  };

  const companyAddress: string = useMemo(() => {
    try {
      const addressArray = [addressLine1, addressLine2, city, state, country, zipCode];

      let addressString = '';

      addressArray.forEach((field, index) => {
        if (index + 1 >= addressArray?.length) {
          // last field
          addressString += field ? `${field}` : '';
        } else if (field) {
          addressString += field.toString().trim() ? `${field}, ` : '';
        }
      });

      return addressString;
    } catch {
      return '-';
    }
  }, [addressLine1, addressLine2, city, country, state, zipCode]);

  const contactInfoFields = [
    {
      label: 'Phone Number',
      value: textFormatter({ value: contactNumber > 0 ? contactNumber : '-' })
    },
    { label: 'Email Address', value: textFormatter({ value: email }) },
    {
      label: 'Address',
      value: textFormatter({ value: companyAddress })
    },
    {
      label: 'Find Me on LinkedIn',
      value: (
        <div className="my-profile-contact-info__fields__field__value__linkedin">
          <img src={linkedinLogo} />
          <Typography as="p" variant="body-2" fontWeight="medium" onClick={openLinkedinProfile}>
            {textFormatter({ value: linkedInUserName })}
          </Typography>
        </div>
      )
    }
  ];

  return (
    <div className="my-profile-contact-info">
      <Typography
        as="p"
        variant="body-1"
        fontWeight="semiBold"
        className="my-profile-contact-info__heading"
      >
        Contact Info
      </Typography>
      <div className="my-profile-contact-info__fields">
        {contactInfoFields.map(({ label, value }, index) => {
          return <LabelValue key={index} label={label} value={value} />;
        })}
      </div>
    </div>
  );
};

export default ContactInfo;
