import * as Yup from 'yup';

const editDetailsValidationSchema = Yup.object().shape({
  title: Yup.string()
    .required('Please enter idea title')
    .trim()
    .test('word-count', 'Idea title should not exceed 50 words', (value) => {
      if (!value) return true; // If value is empty, validation passes
      const wordCount = value.trim().split(/\s+/).length;
      return wordCount <= 50;
    }),
  domain: Yup.string().required('Please select a domain'),
  description: Yup.string().required('Please add description')
});

export default editDetailsValidationSchema;
