import {
  TChangePasswordRequest,
  TConfirmForgotPasswordRequest,
  TConfirmSignupRequest,
  TForgotPasswordRequest,
  TInviteMentorInvestorRequest,
  TResendSignupOtpRequest,
  TSaveOnboardingInfoRequest,
  TSendInviteRequest,
  TSignInRequest,
  TSignOutRequest,
  TSignupRequest,
  TContactusRequest
  // TContactUsResponse

} from './apiEndpoint.types.ts';
import { APIResponse, BaseAPIService } from './base.api.service';

export class AuthAPIService extends BaseAPIService {
  private signupPath: string = '/auth/sign-up';
  private contacUs: string = 'contactus/submit';
  private signInPath: string = '/auth/sign-in';
  private confirmSignupPath: string = '/auth/confirm-sign-up';
  private resendSignupOtpPath: string = '/auth/resend-sign-up-otp';
  private forgotPasswordPath: string = '/auth/forgot-password';
  private confirmForgotPasswordPath: string = '/auth/confirm-forgot-password';
  private saveOnboardingInfoPath: string = '/profile/save-onboarding-info';
  private signOutPath: string = '/auth/sign-out';
  private forceChangePasswordPath: string = '/auth/force-change-password';
  private sendInvitePath: string = '/other/send-invite';
  private changePasswordPath: string = '/settings/change-password';
  private inviteMentorInvestorPath: string = '/mentor-investor/invite';

  public async signup<TSignupResponse>(
    signupRequest: TSignupRequest
  ): Promise<APIResponse<TSignupResponse>> {
    const signupResponse: APIResponse<TSignupResponse> = await this.post(
      this.signupPath,
      signupRequest,
      false
    );
    return signupResponse;
  }

  public async signIn<TSignInResponse>(
    signInRequest: TSignInRequest
  ): Promise<APIResponse<TSignInResponse>> {
    const signInResponse: APIResponse<TSignInResponse> = await this.post(
      this.signInPath,
      signInRequest,
      false
    );
    return signInResponse;
  }

  public async contactus<TContactUsResponse>(
    contactUsRequest: TContactusRequest
  ): Promise<APIResponse<TContactUsResponse>> {
    const signInResponse: APIResponse<TContactUsResponse> = await this.post(
      this.contacUs,
      contactUsRequest,
      false
    );
    return signInResponse;
  }

  public async confirmSignup<TConfirmSignupResponse>(
    confirmSignupRequest: TConfirmSignupRequest
  ): Promise<APIResponse<TConfirmSignupResponse>> {
    const confirmSignupResponse: APIResponse<TConfirmSignupResponse> = await this.post(
      this.confirmSignupPath,
      confirmSignupRequest,
      false
    );
    return confirmSignupResponse;
  }

  public async resendSignupOtp<TResendSignupOtpResponse>(
    resendSignupOtpRequest: TResendSignupOtpRequest
  ): Promise<APIResponse<TResendSignupOtpResponse>> {
    const resendSignupOtpResponse: APIResponse<TResendSignupOtpResponse> = await this.get(
      this.resendSignupOtpPath,
      false,
      resendSignupOtpRequest
    );
    return resendSignupOtpResponse;
  }

  public async forgotPassword<TForgotPasswordResponse>(
    forgotPasswordRequest: TForgotPasswordRequest
  ): Promise<APIResponse<TForgotPasswordResponse>> {
    const forgotPasswordResponse: APIResponse<TForgotPasswordResponse> = await this.get(
      this.forgotPasswordPath,
      false,
      forgotPasswordRequest
    );
    return forgotPasswordResponse;
  }

  public async confirmForgotPassword<TConfirmForgotPasswordResponse>(
    confirmForgotPasswordRequest: TConfirmForgotPasswordRequest
  ): Promise<APIResponse<TConfirmForgotPasswordResponse>> {
    const confirmForgotPasswordResponse: APIResponse<TConfirmForgotPasswordResponse> =
      await this.post(this.confirmForgotPasswordPath, confirmForgotPasswordRequest, false);
    return confirmForgotPasswordResponse;
  }

  public async saveOnboardingInfo<TSaveOnboardingInfoResponse>(
    saveOnboardingInfoRequest: TSaveOnboardingInfoRequest
  ): Promise<APIResponse<TSaveOnboardingInfoResponse>> {
    const saveOnboardingInfoResponse: APIResponse<TSaveOnboardingInfoResponse> = await this.post(
      this.saveOnboardingInfoPath,
      saveOnboardingInfoRequest,
      true
    );
    return saveOnboardingInfoResponse;
  }

  public async signOut<TSignOutResponse>(
    signOutRequest: TSignOutRequest
  ): Promise<APIResponse<TSignOutResponse>> {
    const signOutResponse: APIResponse<TSignOutResponse> = await this.post(
      this.signOutPath,
      signOutRequest,
      true
    );
    return signOutResponse;
  }

  public async forceChangePassword<TForceChangePasswordResponse>(
    forceChangePasswordRequest: TSignOutRequest
  ): Promise<APIResponse<TForceChangePasswordResponse>> {
    const forceChangePasswordResponse: APIResponse<TForceChangePasswordResponse> = await this.post(
      this.forceChangePasswordPath,
      forceChangePasswordRequest,
      false
    );
    return forceChangePasswordResponse;
  }

  public async sendInvite<TSendInviteResponse>(
    sendInviteRequest: TSendInviteRequest
  ): Promise<APIResponse<TSendInviteResponse>> {
    const sendInviteResponse: APIResponse<TSendInviteResponse> = await this.post(
      this.sendInvitePath,
      sendInviteRequest,
      false
    );
    return sendInviteResponse;
  }

  public async changePassword<TChangePasswordResponse>(
    changePasswordRequest: TChangePasswordRequest
  ): Promise<APIResponse<TChangePasswordResponse>> {
    const changePasswordResponse: APIResponse<TChangePasswordResponse> = await this.post(
      this.changePasswordPath,
      changePasswordRequest,
      true
    );
    return changePasswordResponse;
  }

  public async inviteMentorInvestor<TInviteMentorInvestorResponse>(
    inviteMentorInvestorRequest: TInviteMentorInvestorRequest
  ): Promise<APIResponse<TInviteMentorInvestorResponse>> {
    const inviteMentorInvestorResponse: APIResponse<TInviteMentorInvestorResponse> =
      await this.post(this.inviteMentorInvestorPath, inviteMentorInvestorRequest, true);
    return inviteMentorInvestorResponse;
  }
}
