import { TTextEllipsisProps, TTextEllipsisResponse } from './textEllipsis.types';

const textEllipsis = ({ charLimit, text }: TTextEllipsisProps): TTextEllipsisResponse => {
  try {
    if (charLimit >= text?.length) {
      return { text, hasEllipsis: false };
    }
    return { text: text.slice(0, charLimit) + '...', hasEllipsis: true };
  } catch {
    return { text, hasEllipsis: false };
  }
};

export default textEllipsis;
