import { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { toggleTouchedFields } from 'src/utils';
import { useUpdateFormValue, useValidateErrors } from 'src/hooks';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { Modal, SelectMonthYear } from 'src/components/common';
import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants/common.constants';
import SearchAndSelect from 'src/components/common/formFields/searchAndSelect/SearchAndSelect';
import Typography from 'src/components/common/typography/Typography';
import Button from 'src/components/button/Button';
import { TUpdateEducationRequest } from 'src/services/apiEndpoint.types.ts/updateEducation.endpoint.types';
import { addEducation, updateEducation } from 'src/store/profile/profile.slice';
import { resetModalId } from 'src/store/common/common.slice';
import { educationDegrees } from 'src/constants/educationDegrees.constants';
import { colleges } from 'src/constants/colleges.constants';

import { updateEducationErrors, updateEducationFormFields } from './updateEducation.constants';
import updateEducationValidationSchema, {
  updateEducationValidationEndSchema
} from './updatedEducation.validationSchema';
import './updateEducation.scss';

import { TInstituteCard } from '../instituteCard/InstituteCard';

export type TUpdateEducationValues = {
  institutionName: string;
  degreeName: string;
  field: string;
  start: string;
  end: string;
  currentlyStudying: boolean;
};

const allFieldsUntouched = toggleTouchedFields({
  fields: updateEducationFormFields,
  isTouched: false
});
const allFieldsTouched = toggleTouchedFields({
  fields: updateEducationFormFields,
  isTouched: true
});

export type TUpdateEducation = {
  isModalOpen: boolean; // whether the modal is open
  mode: 'Add' | 'Update';
  educationToUpdate: number; // index of education to update
  education: TInstituteCard[];
};

const UpdateEducation = ({
  educationToUpdate,
  isModalOpen,
  mode = 'Update',
  education
}: TUpdateEducation) => {
  const [formValues, setFormValues] = useState<TUpdateEducationValues>(updateEducationFormFields);
  const [touchedFormFields, setTouchedFormFields] =
    useState<Record<keyof TUpdateEducationValues, boolean>>(allFieldsUntouched);
  const [errors, setErrors] =
    useState<Record<keyof TUpdateEducationValues, string>>(updateEducationErrors);
  const [openStartMonthYearModal, setOpenStartMonthYearModal] = useState<boolean>(false);
  const [openEndMonthYearModal, setOpenEndMonthYearModal] = useState<boolean>(false);

  // get month and value from the month/year string
  const [startMonth = '01', startYear = `${new Date().getFullYear()}`] = (
    formValues.start || ''
  ).split('/');
  const [endMonth = '01', endYear = `${new Date().getFullYear()}`] = (formValues.end || '').split(
    '/'
  );

  // Hooks
  const { updateFormValue } = useUpdateFormValue();
  const { validateErrors } = useValidateErrors();

  const { profile } = useAppSelector((store) => store.profile);
  const { loading } = useAppSelector((store) => store.common);
  const dispatch = useAppDispatch();

  const updateForm = ({ fieldName, value }: { fieldName: string; value: string | boolean }) => {
    const setFormValue = setFormValues as Dispatch<
      SetStateAction<Record<string, string | boolean>>
    >;
    updateFormValue({ fieldName, value, setFormValue });
  };

  const validateForm = async () => {
    return await validateErrors({
      validationSchema: formValues.currentlyStudying
        ? updateEducationValidationSchema
        : updateEducationValidationSchema.concat(updateEducationValidationEndSchema),
      formValues,
      errorsInitialState: updateEducationErrors,
      setErrors
    });
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setTouchedFormFields(allFieldsTouched);

    const validationErrors = await validateForm();

    if (validationErrors?.length) {
      return validateErrors;
    }

    if (!formValues.currentlyStudying && !formValues?.end) {
      setErrors({ ...errors, end: 'Please select the end month and year' })
      return validateErrors;
    }

    const payload: TUpdateEducationRequest = {
      id: profile.id,
      education: [...education]
    };

    if (mode === 'Add') {
      payload.education.push({
        institutionName: formValues.institutionName,
        degreeName: formValues.degreeName,
        field: formValues.field,
        start: formValues.start,
        currentlyStudying: formValues.currentlyStudying,
        ...(formValues.end && { end: formValues.end })
      });
      // add education api call
      dispatch(addEducation(payload));
    } else if (mode === 'Update') {
      payload.education[educationToUpdate] = {
        institutionName: formValues.institutionName,
        degreeName: formValues.degreeName,
        field: formValues.field,
        start: formValues.start,
        currentlyStudying: formValues.currentlyStudying,
        ...(formValues.end && { end: formValues.end })
      };

      // update education api call
      dispatch(updateEducation(payload));
    }
  };

  const closeModal = () => {
    setErrors(updateEducationErrors);
    setFormValues(updateEducationFormFields);
    setTouchedFormFields(allFieldsUntouched);
    dispatch(resetModalId());
  };

  useEffect(() => {
    validateForm();
  }, [JSON.stringify(formValues)]);

  useEffect(() => {
    if (mode === 'Update') {
      const education = profile.education[educationToUpdate];
      setFormValues(education);
    }

    return () => {
      setFormValues(updateEducationFormFields);
    };
  }, [isModalOpen, mode]);

  console.log(formValues, errors, 'formvalues');

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={closeModal}
      title={`${mode === 'Add' ? 'Add' : 'Edit'} Education`}
      className="update-education-modal"
    >
      <div className="update-education">
        <form onSubmit={handleSubmit} className="update-education__form">
          <div className="update-education__form__fields">
            {/*
             ** Name
             */}
            {/* <InputField
              label="Enter Institution Name"
              id="institutionName"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="Institution Name"
              type="text"
              value={formValues.institutionName}
              onChange={(e) => {
                updateForm({ fieldName: 'institutionName', value: e.target.value });
              }}
              errorMessage={errors.institutionName}
              isTouched={touchedFormFields.institutionName}
              required
            /> */}
            <SearchAndSelect
              label="Enter Institution Name"
              id="institutionName"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="Institution Name"
              selectedValue={formValues.institutionName}
              options={colleges}
              onSelection={({ value }) => {
                updateForm({ fieldName: 'institutionName', value: value as unknown as string });
              }}
              onInputChange={(e) => {
                updateForm({
                  fieldName: 'institutionName',
                  value: e.target.value as unknown as string
                });
              }}
              errorMessage={errors.institutionName}
              isTouched={touchedFormFields.institutionName}
              required
            />
            {/*
             ** Role
             */}
            <SearchAndSelect
              label="Enter Degree Name"
              placeholder="Select Degree Name"
              variant={componentSizeVariantsEnum.SMALL}
              selectedValue={formValues.degreeName}
              options={educationDegrees}
              onSelection={({ value }) => {
                updateForm({ fieldName: 'degreeName', value: value as unknown as string });
              }}
              onInputChange={(e) => {
                updateForm({ fieldName: 'degreeName', value: e.target.value as unknown as string });
              }}
              errorMessage={errors.degreeName}
              isTouched={touchedFormFields.degreeName}
              required
            />

            {/*
             ** Company Name
             */}
            <InputField
              label="Field of Study"
              id="field"
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="Enter Field of Study"
              type="text"
              value={formValues.field}
              onChange={(e) => {
                updateForm({ fieldName: 'field', value: e.target.value });
              }}
              errorMessage={errors.field}
              isTouched={touchedFormFields.field}
              required
            />

            <div className="update-education__form__fields__row">
              {/*
               ** Start Month and Year
               */}
              <div className="update-education__form__fields__row__start-month-year">
                {openStartMonthYearModal && (
                  <SelectMonthYear
                    isOpen={openStartMonthYearModal}
                    onClose={() => {
                      setOpenStartMonthYearModal(false);
                    }}
                    startYear={1930}
                    yearsRange={100}
                    currentMonth={startMonth}
                    currentYear={parseInt(startYear)}
                    onSelection={({ month, year }) => {
                      updateForm({ fieldName: 'start', value: `${month}/${year}` });
                      setOpenStartMonthYearModal(false);
                    }}
                  />
                )}
                <InputField
                  label="Start Month and Year"
                  id="start"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="MM/YYYY"
                  type="text"
                  value={formValues.start}
                  onChange={(e) => {
                    updateForm({ fieldName: 'start', value: e.target.value });
                  }}
                  errorMessage={errors.start}
                  isTouched={touchedFormFields.start}
                  onClick={() => {
                    setOpenStartMonthYearModal(true);
                  }}
                  required
                />
              </div>
              {/*
               ** Graduation Date
               */}
              <div className="update-education__form__fields__end">
                {/*
                 ** Month and year selection modals
                 */}
                {openEndMonthYearModal && (
                  <SelectMonthYear
                    isOpen={openEndMonthYearModal}
                    onClose={() => {
                      setOpenEndMonthYearModal(false);
                    }}
                    startYear={1930}
                    yearsRange={100}
                    currentMonth={endMonth}
                    currentYear={parseInt(endYear)}
                    onSelection={({ month, year }) => {
                      updateForm({ fieldName: 'end', value: `${month}/${year}` });
                      setOpenEndMonthYearModal(false);
                    }}
                  />
                )}
                <InputField
                  label="Graduation Date"
                  id="end"
                  variant={componentSizeVariantsEnum.SMALL}
                  placeholder="MM/YYYY"
                  type="text"
                  value={formValues.end}
                  onChange={(e) => {
                    updateForm({ fieldName: 'end', value: e.target.value });
                  }}
                  errorMessage={errors.end}
                  isTouched={touchedFormFields.end}
                  disabled={formValues.currentlyStudying}
                  onClick={() => {
                    setOpenEndMonthYearModal(true);
                  }}
                  required
                />
                <div className="update-education__form__fields__end__currently-working">
                  <input
                    type="checkbox"
                    checked={formValues.currentlyStudying}
                    onChange={(e) => {
                      updateForm({
                        fieldName: 'currentlyStudying',
                        value: e.target.checked
                      });
                      if (e.target.checked) {
                        updateForm({ fieldName: 'end', value: '' });
                      }
                    }}
                  />
                  <Typography as="span" variant="body-2" fontWeight="regular">
                    I am currently enrolled in this institution
                  </Typography>
                </div>
              </div>
            </div>
          </div>
          <div className="update-education__form__buttons">
            <Button variant="secondary" type="button" size="small" onClick={closeModal}>
              Close
            </Button>

            <Button variant="primary" type="submit" size="small" loading={loading}>
              {mode === 'Add' ? 'Add' : 'Save Changes'}
            </Button>
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UpdateEducation;
