import { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Modal } from 'src/components/common';
import { InputField } from 'src/components/common/formFields';
import Button from 'src/components/button/Button';
import { SearchIcon } from 'src/components/common/common.icons';
import { TUser } from 'src/services/apiEndpoint.types.ts';

import { TViewDiscussionMembersProps } from './viewDiscussionMembers.types';
import './viewDiscussionMembers.scss';

import UserInfo from '../userInfo/UserInfo';

const ViewDiscussionMembers = ({
  isModalOpen,
  onModalClose,
  programGroupParticipants
}: TViewDiscussionMembersProps) => {
  const [filterString, setFilterString] = useState<string>('');
  const [discussionMembers, setDiscussionMembers] = useState<TUser[]>(programGroupParticipants);

  const initialValues = {
    text: ''
  };

  const handleSubmit = (values: unknown) => {
    console.log(values);
  };

  useEffect(() => {
    setDiscussionMembers((prev) =>
      prev.filter((user) => {
        const nameLower: string = user?.name?.toLowerCase();
        const emailLower: string = user?.email?.toLowerCase();
        const filterStringLower: string = filterString.toLowerCase();

        if (nameLower.indexOf(filterStringLower) > -1) {
          return true;
        }
        if (emailLower.indexOf(filterStringLower) > -1) {
          return true;
        }

        return false;
      })
    );
  }, [filterString]);

  return (
    <Modal
      isModalOpen={isModalOpen}
      onModalClose={onModalClose}
      title="View Group Members"
      className="view-discussion-members"
    >
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {({ setFieldValue }) => {
          return (
            <Form className="view-discussion-members__form">
              <InputField
                label="Select members in this group"
                id="text"
                placeholder="Search user by name, email address"
                onChange={(e) => {
                  const value = e.target.value;

                  setFieldValue('text', value);
                  setFilterString(value);
                }}
                startIcon={<SearchIcon />}
              />
            </Form>
          );
        }}
      </Formik>
      <div className="view-discussion-members__users">
        {discussionMembers.map((member, index) => {
          return <UserInfo key={index} {...member} userId={member?.id} />;
        })}
      </div>
      <Button
        variant="secondary"
        size="small"
        onClick={onModalClose}
        className="view-discussion-members__close"
      >
        Close
      </Button>
    </Modal>
  );
};

export default ViewDiscussionMembers;
