import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SearchAPIService } from 'src/services/search.api.service';
import {
  TPost,
  TSearchAnnouncementRequest,
  TSearchAnnouncementResponse,
  TSearchAnnouncementResponseData,
  TSearchCitiesRequest,
  TSearchCitiesResponse,
  TSearchCompanyCard,
  TSearchCompanyRequest,
  TSearchCompanyResponse,
  TSearchCompanyResponseData,
  TSearchMentorInvestorRequest,
  TSearchMentorInvestorResponse,
  TSearchMentorInvestorResponseData,
  TSearchPeopleCard,
  TSearchPeopleRequest,
  TSearchPeopleResponse,
  TSearchPeopleResponseData,
  TSearchPostsRequest,
  TSearchPostsResponse,
  TSearchPostsResponseData
} from 'src/services/apiEndpoint.types.ts';
import { APIResponse } from 'src/services/base.api.service';

import { startLoading, stopLoading } from '../common/common.slice';
import { AppDispatch } from '../store';

export type TSearchItemReduxKeys =
  | 'people'
  | 'mentorInvestors'
  | 'companies'
  | 'posts'
  | 'announcements';

type TInitialState = {
  people: TSearchPeopleCard[];
  totalPeople: number;
  totalPeoplePages: number;
  mentorInvestors: TSearchPeopleCard[];
  totalMentorInvestor: number;
  totalMentorInvestorPages: number;
  companies: TSearchCompanyCard[];
  totalCompanies: number;
  totalCompaniesPages: number;
  posts: TPost[];
  totalPosts: number;
  totalPostPages: number;
  announcements: TPost[];
  totalAnnouncements: number;
  totalAnnouncementPages: number;
  cities: string[];
  pageNumber: number;
  totalPages: number;
};

const initialState: TInitialState = {
  people: [],
  totalPeople: 0,
  totalPeoplePages: 0,
  mentorInvestors: [],
  totalMentorInvestor: 0,
  totalMentorInvestorPages: 0,
  companies: [],
  totalCompanies: 0,
  totalCompaniesPages: 0,
  posts: [],
  totalPosts: 0,
  totalPostPages: 0,
  announcements: [],
  totalAnnouncements: 0,
  totalAnnouncementPages: 0,
  cities: [],
  pageNumber: 1,
  totalPages: 1
};

export const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    searchPeopleSuccess: (state, action: PayloadAction<TSearchPeopleResponseData>) => {
      const { users, totalItems, totalPages, currentPage } = action.payload;
      state.people = users;
      state.totalPeople = totalItems;
      state.totalPeoplePages = totalPages;
      state.pageNumber = currentPage;
      state.totalPages = totalPages;
    },
    searchMentorInvestorSuccess: (
      state,
      action: PayloadAction<TSearchMentorInvestorResponseData>
    ) => {
      const { users, totalItems, totalPages, currentPage } = action.payload;
      state.mentorInvestors = users;
      state.totalMentorInvestor = totalItems;
      state.totalMentorInvestorPages = totalPages;
      state.pageNumber = currentPage;
      state.totalPages = totalPages;
    },
    searchCompanySuccess: (state, action: PayloadAction<TSearchCompanyResponseData>) => {
      const { companies, totalItems, totalPages, currentPage } = action.payload;
      state.companies = companies;
      state.totalCompanies = totalItems;
      state.totalCompaniesPages = totalPages;
      state.pageNumber = currentPage;
      state.totalPages = totalPages;
    },
    searchPostsSuccess: (state, action: PayloadAction<TSearchPostsResponseData>) => {
      const { posts, totalPages, totalPosts, currentPage } = action.payload || {};
      state.posts = posts;
      state.totalPostPages = totalPages;
      state.totalPosts = totalPosts;
      state.pageNumber = currentPage;
      state.totalPages = totalPages;
    },
    searchAnnouncementSuccess: (state, action: PayloadAction<TSearchAnnouncementResponseData>) => {
      const { posts: announcements, totalPages, totalPosts, currentPage } = action.payload || {};
      state.announcements = announcements;
      state.totalAnnouncementPages = totalPages;
      state.totalAnnouncements = totalPosts;
      state.pageNumber = currentPage;
      state.totalPages = totalPages;
    },
    searchCitiesSuccess: (state, action: PayloadAction<string[]>) => {
      const cities = action.payload || [];
      state.cities = cities;
    },
    updatePageNumber: (state, action: PayloadAction<number>) => {
      const pageNumber: number = action.payload;
      state.pageNumber = pageNumber;
    },
    increasePageNumber: (state) => {
      if (state.pageNumber < state.totalPages) {
        state.pageNumber++;
      }
    },
    decreasePageNumber: (state) => {
      if (state.pageNumber > 1) {
        state.pageNumber--;
      }
    },
    handleFollowSearchResult: (
      state,
      action: PayloadAction<{
        id: string | string;
        searchResultType: TSearchItemReduxKeys;
      }>
    ) => {
      const { id, searchResultType } = action.payload;
      if (searchResultType === 'people' || searchResultType === 'mentorInvestors') {
        const currentPeople = JSON.parse(
          JSON.stringify(state[searchResultType])
        ) as TSearchPeopleCard[];
        const currentPerson = currentPeople.find((person) => person?.user?.id === id);
        const isFollowing = currentPerson?.follows;
        if (currentPerson && 'follows' in currentPerson) {
          currentPerson.follows = !isFollowing;
        }
        state[searchResultType] = currentPeople;
      } else if (searchResultType === 'companies') {
        const currentCompanies = JSON.parse(
          JSON.stringify(state.companies)
        ) as TSearchCompanyCard[];
        const currentCompany = currentCompanies.find((company) => company?.comapny?.id === id);
        const isFollowing = currentCompany?.follows;
        if (currentCompany && 'follows' in currentCompany) {
          currentCompany.follows = !isFollowing;
        }
        state.companies = currentCompanies;
      } else if (searchResultType === 'announcements' || searchResultType === 'posts') {
        const currentPosts = JSON.parse(JSON.stringify(state[searchResultType])) as TPost[];
        const currentPost = currentPosts.find((post) => post?.post?.id === id);
        const isFollowing = currentPost?.followUserOrCompany;
        if (currentPost && 'followUserOrCompany' in currentPost) {
          currentPost.followUserOrCompany = !isFollowing;
        }
        state[searchResultType] = currentPosts;
      }
    },
    handleLikeSearchPostSuccess: (state, action: PayloadAction<string>) => {
      try {
        const postId: string = action.payload;

        const likedPost = state.posts.find((post) => post?.post?.id === postId);
        const likedAnnouncement = state.announcements.find((post) => post?.post?.id === postId);

        if (likedPost) {
          likedPost.post.likedByUser = true;
          likedPost.post.likeCount++;
        }

        if (likedAnnouncement) {
          likedAnnouncement.post.likedByUser = true;
          likedAnnouncement.post.likeCount++;
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleUnLikeSearchPostSuccess: (state, action: PayloadAction<string>) => {
      try {
        const postId: string = action.payload;

        const likedPost = state.posts.find((post) => post?.post?.id === postId);
        const likedAnnouncement = state.announcements.find((post) => post?.post?.id === postId);

        if (likedPost) {
          likedPost.post.likedByUser = false;
          if (likedPost.post.likeCount > 0) {
            likedPost.post.likeCount--;
          }
        }

        if (likedAnnouncement) {
          likedAnnouncement.post.likedByUser = false;
          if (likedAnnouncement.post.likeCount > 0) {
            likedAnnouncement.post.likeCount--;
          }
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleSaveSearchPostSuccess: (state, action: PayloadAction<string>) => {
      try {
        const postId: string = action.payload;

        const savedPost = state.posts.find((post) => post?.post?.id === postId);
        const savedAnnouncement = state.announcements.find((post) => post?.post?.id === postId);

        if (savedPost) {
          savedPost.post.savedPost = true;
        }

        if (savedAnnouncement) {
          savedAnnouncement.post.savedPost = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    handleUnSaveSearchPostSuccess: (state, action: PayloadAction<string>) => {
      try {
        const postId: string = action.payload;

        const unSavedPost = state.posts.find((post) => post?.post?.id === postId);
        const unSavedAnnouncement = state.announcements.find((post) => post?.post?.id === postId);

        if (unSavedPost) {
          unSavedPost.post.savedPost = false;
        }

        if (unSavedAnnouncement) {
          unSavedAnnouncement.post.savedPost = false;
        }
      } catch (e) {
        console.log(e);
      }
    },
    searchCitiesError: () => {}
  }
});

// Action creators are generated for each case reducer function
export const {
  searchPeopleSuccess,
  searchMentorInvestorSuccess,
  searchCompanySuccess,
  searchPostsSuccess,
  searchAnnouncementSuccess,
  searchCitiesSuccess,
  searchCitiesError,
  updatePageNumber,
  increasePageNumber,
  decreasePageNumber,
  handleFollowSearchResult,
  handleLikeSearchPostSuccess,
  handleUnLikeSearchPostSuccess,
  handleSaveSearchPostSuccess,
  handleUnSaveSearchPostSuccess
} = searchSlice.actions;

export default searchSlice.reducer;

export const searchPeople = (payload: TSearchPeopleRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TSearchPeopleResponse> =
      await new SearchAPIService().searchPeople(payload);
    if (status === 200 && data?.data) {
      dispatch(searchPeopleSuccess(data?.data));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};

export const searchMentorInvestor =
  (payload: TSearchMentorInvestorRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TSearchMentorInvestorResponse> =
        await new SearchAPIService().searchMentorInvestor(payload);
      if (status === 200 && data?.data) {
        dispatch(searchMentorInvestorSuccess(data?.data));
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const searchCompany = (payload: TSearchCompanyRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TSearchCompanyResponse> =
      await new SearchAPIService().searchCompany(payload);
    if (status === 200 && data?.data) {
      dispatch(searchCompanySuccess(data?.data));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};

export const searchAnnouncement =
  (payload: TSearchAnnouncementRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(startLoading());
      const { status, data }: APIResponse<TSearchAnnouncementResponse> =
        await new SearchAPIService().searchAnnouncement(payload);
      if (status === 200 && data?.data) {
        dispatch(searchAnnouncementSuccess(data?.data));
      }
    } catch (err) {
    } finally {
      dispatch(stopLoading());
    }
  };

export const searchPosts = (payload: TSearchPostsRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TSearchPostsResponse> =
      await new SearchAPIService().searchPosts(payload);
    if (status === 200 && data) {
      dispatch(searchPostsSuccess(data?.data));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};

export const searchCities = (payload: TSearchCitiesRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TSearchCitiesResponse> =
      await new SearchAPIService().searchCities(payload);
    if (status === 200 && data) {
      dispatch(searchCitiesSuccess(data?.data));
    }
  } catch (err) {
  } finally {
    dispatch(stopLoading());
  }
};
