import { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { ThumbsUpIcon } from 'src/components/common/common.icons';
import { colors } from 'src/constants';
import { useAppDispatch } from 'src/store/hooks';
import {
  ActionIcon,
  TDropdown,
  TDropdownOptions,
  ThreeDotsDropdown,
  Tooltip,
  Typography
} from 'src/components/common';
import { textEllipsis, timeAgo, transformTextToHyperlink } from 'src/utils';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { useCopyToClipboard } from 'src/hooks';
import {
  TDeleteIdeaMessageRequest,
  TDislikeConversationMessageRequest,
  TDownloadChatFileRequest,
  TLikeConversationMessageRequest
} from 'src/services/apiEndpoint.types.ts';
import {
  clearChatFiles,
  deleteIdeaMessage,
  downloadChatFile,
  likeMessageSelf,
  dislikeMessageSelf,
  likeConversationMessage,
  dislikeConversationMessage
} from 'src/store/chat/chat.slice';
import FileCard from 'src/components/chat/fileCard/FileCard';

import { TChatMessage } from './chatMessage.types';
import './chatMessage.scss';

const ChatMessage = ({
  id,
  message,
  updatedAt,
  User,
  isLiked,
  likeCount,
  loggedInUserId,
  loggedInUserType,
  groupId,
  files,
  userType,
  Company,
  groupMessageContext,
  updateMessageSettings
}: TChatMessage) => {
  // Hooks
  const dispatch = useAppDispatch();
  const { copyToClipboard } = useCopyToClipboard();

  // Ref
  const chatMessageTextRef = useRef<HTMLElement>(null);

  // Constants
  const messageCreatorUserId: string = userType === 'User' ? User?.id : Company?.id;
  const messageType: 'sent' | 'received' =
    loggedInUserId === messageCreatorUserId && loggedInUserType === userType ? 'sent' : 'received';
  const profilePic: string = userType === 'User' ? User.profilePic : Company?.logo;
  const name: string = userType === 'User' ? User?.name : Company?.name;

  // Utils
  const nameWitEllipsis = textEllipsis({ text: name, charLimit: 12 });

  const deleteMessage = () => {
    const payload: TDeleteIdeaMessageRequest = {
      messageId: id,
      eventName:
        groupMessageContext === 'idea'
          ? 'delete-idea-conversation-message'
          : 'delete-program-conversation-message'
    };

    dispatch(deleteIdeaMessage(payload));
  };

  const dropdownItems: TDropdownOptions[] = [
    {
      label: 'Copy',
      onClick: () => {
        copyToClipboard({ text: message });
      }
    },
    {
      label: 'Edit',
      onClick: () => {
        dispatch(clearChatFiles());
        updateMessageSettings({ editMode: true, messageId: id, message });
      }
    },
    {
      label: 'Delete',
      onClick: deleteMessage
    }
  ];

  const dropdownOptionsProps: TDropdown = {
    id: `discussion-message-${id}`,
    size: 'large',
    alignment: 'center',
    items: dropdownItems
  };

  const likeMessage = () => {
    const payload: TLikeConversationMessageRequest = {
      messageId: id,
      userId: loggedInUserId,
      userType: loggedInUserType,
      eventName:
        groupMessageContext === 'idea'
          ? 'like-idea-conversation-message'
          : 'like-program-conversation-message'
    };

    dispatch(likeMessageSelf({ conversationId: groupId, messageId: id }));
    dispatch(likeConversationMessage(payload));
  };

  const unlikeMessage = () => {
    const payload: TDislikeConversationMessageRequest = {
      messageId: id,
      userId: loggedInUserId,
      userType: loggedInUserType,
      eventName:
        groupMessageContext === 'idea'
          ? 'dislike-idea-conversation-message'
          : 'dislike-program-conversation-message'
    };

    dispatch(dislikeMessageSelf({ conversationId: groupId, messageId: id }));
    dispatch(dislikeConversationMessage(payload));
  };

  const downloadFile = ({ s3Key }: { s3Key: string }) => {
    const payload: TDownloadChatFileRequest = { s3Key };

    dispatch(downloadChatFile(payload));
  };

  useEffect(() => {
    const ele: HTMLElement | null = chatMessageTextRef.current;
    if (ele) {
      const text = ele.innerText;
      const replacedText = transformTextToHyperlink({ text });
      ele.innerHTML = replacedText;
    }
  }, [chatMessageTextRef, message]);

  return (
    <div className={classNames('discussion-message', `discussion-message--is-${messageType}`)}>
      <div className="discussion-message__content">
        <ProfilePicture url={profilePic} fallback={name?.[0]} />

        <div className="discussion-message__content__right">
          <div className="discussion-message__content__right__row">
            <Tooltip text={name} conditionToDisplay={nameWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="p"
                variant="body-2"
                fontWeight="semiBold"
                className="discussion-message__content__right__row__name"
              >
                {nameWitEllipsis?.text}
              </Typography>
            </Tooltip>
            <div className="discussion-message__content__right__row__orange-dot"></div>
            <Typography as="span" variant="caption" fontWeight="regular">
              {timeAgo({ timestamp: updatedAt })}
            </Typography>
          </div>

          <Typography
            as="span"
            variant="body-2"
            fontWeight="regular"
            className={classNames(
              'discussion-message__content__right__message',
              `discussion-message__content__right__message--is-${messageType}`
            )}
            style={{ color: colors?.neutral?.[1100] }}
            typographyRef={chatMessageTextRef}
          >
            {message}
          </Typography>
        </div>
      </div>
      {files?.length > 0 && (
        <div className="chat-message__files">
          {files.map(({ key, id, fileName }) => {
            return (
              <FileCard
                key={key}
                id={id}
                fileName={fileName}
                onClick={() => downloadFile({ s3Key: key })}
              />
            );
          })}
        </div>
      )}
      <div className="discussion-message__actions">
        <div className="discussion-message__actions__left">
          <ActionIcon
            active={isLiked}
            onClick={() => {
              if (isLiked) {
                unlikeMessage();
              } else {
                likeMessage();
              }
            }}
          >
            <ThumbsUpIcon />
          </ActionIcon>
          {likeCount > 0 && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="medium"
              style={{ color: colors?.neutral?.[700] }}
            >
              {likeCount}
            </Typography>
          )}
        </div>

        {messageType === 'sent' && <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />}
      </div>
    </div>
  );
};

export default ChatMessage;
