import { Link, useNavigate } from 'react-router-dom';
import { hTractionLogoWithText } from 'src/assets/images';
import Button from 'src/components/button/Button';

const TopNavigation = () => {
  const navigate = useNavigate();

  const handleSignup = () => {
    navigate('/sign-up');
  };

  const handleLogin = () => {
    console.log('Clear storage');
    localStorage.clear();

    // Verify if localStorage is cleared
    if (Object.keys(localStorage).length === 0) {
      navigate('/login');
    } else {
      console.error('Failed to clear local storage');
      // You can show an error message or handle the failure case here
    }
  };

  return (
    <div className="header-pd">
      <div className="container">
        <div className="landing-page__top-navigation p-0">
          <img
            src={hTractionLogoWithText}
            className="landing-page__top-navigation__logo"
            onClick={handleSignup}
            style={{ cursor: 'pointer' }}
          />
          <div className="landing-page__top-navigation__buttons">
            <Button
              className="landing-page__top-navigation__buttons__button"
              variant="secondary"
              size="small"
              onClick={handleLogin}
            >
              Login
            </Button>
            <Link to="/sign-up">
              <Button
                className="landing-page__top-navigation__buttons__button"
                size="small"
              >
                Sign up
              </Button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopNavigation;
