import { Tooltip, Typography } from 'src/components/common';
import ProfilePicture from 'src/components/profile/profilePicture/ProfilePicture';
import { textEllipsis } from 'src/utils';

import './searchMemberItem.scss';
import { TSearchMemberItemProps } from './searchMemberItem';

const SearchMemberItem = ({
  userId,
  profilePic,
  name = '',
  role = '',
  isUserSelected,
  updateSelectedUsers
}: TSearchMemberItemProps) => {
  // Utils
  const nameWitEllipsis = textEllipsis({ charLimit: 12, text: name });
  const roleWitEllipsis = textEllipsis({ charLimit: 12, text: role });

  return (
    <div className="ih-d-search-member-item">
      <div className="ih-d-search-member-item__user-info">
        <input
          type="checkbox"
          checked={isUserSelected}
          onChange={() => updateSelectedUsers({ userId })}
        />
        <ProfilePicture
          url={profilePic}
          fallback={name[0]}
          className="ih-d-search-member-item__user-info__profile-pic"
        />
        <div className="ih-d-search-member-item__user-info__user">
          <div className="ih-d-search-member-item__user-info__user__row">
            <Tooltip text={name} conditionToDisplay={nameWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="body-2"
                fontWeight="semiBold"
                className="ih-d-search-member-item__user-info__user__row__name"
              >
                {nameWitEllipsis.text}
              </Typography>
            </Tooltip>
            <hr></hr>
            <Tooltip text={role} conditionToDisplay={roleWitEllipsis?.hasEllipsis} limitWidth>
              <Typography
                as="span"
                variant="caption"
                fontWeight="regular"
                className="ih-d-search-member-item__user-info__user__row__role"
              >
                {roleWitEllipsis.text}
              </Typography>
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchMemberItem;
