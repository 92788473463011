import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { APIResponse } from 'src/services/base.api.service';
import { CompanyAPIService } from 'src/services/company.service';
import {
  TAddMembersRequest,
  TAddMembersResponse,
  TAddServiceRequest,
  TAddServiceResponse,
  TCreateCompanyRequest,
  TCreateCompanyResponse,
  TDeleteCompanyProfilePictureRequest,
  TDeleteCompanyProfilePictureResponse,
  TDeleteMemberRequest,
  TDeleteMemberResponse,
  TDeleteServiceRequest,
  TDeleteServiceResponse,
  TEditCompanyRequest,
  TEditCompanyResponse,
  TEditServiceRequest,
  TEditServiceResponse,
  TGetCompany,
  TGetCompanyPresignedUrlActionRequest,
  TGetCompanyPresignedUrlResponse,
  TGetCompanyRequest,
  TGetCompanyResponse,
  TGetMembersRequest,
  TGetMembersResponse,
  TGetService,
  TGetServicesRequest,
  TGetServicesResponse,
  TMember,
  TSearchUsersRequest,
  TSearchUsersResponse,
  TUploadCompanyProfilePictureActionRequest,
  TUploadCompanyProfilePictureResponse
} from 'src/services/apiEndpoint.types.ts';
import errorMessages from 'src/constants/error.messages.constants';
import { StorageUtils } from 'src/utils';

import {
  hidePageLoader,
  openPopup,
  resetConfirmModalId,
  resetDropdownId,
  startLoading,
  stopLoading
} from '../common/common.slice';
import { AppDispatch } from '../store';
import { userAuthorizationFailure, userAuthorizationSuccess } from '../auth/auth.slice';

const companyInitialState: TGetCompany = {
  id: '',
  email: '',
  name: '',
  type: '',
  about: '',
  website: '',
  logo: '',
  introduction: '',
  foundedYear: 0,
  industry: '',
  contactNumber: 0,
  companyEmailAddress: '',
  contactUsUrl: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    country: '',
    state: '',
    city: '',
    zipCode: ''
  },
  addressLine1: '',
  addressLine2: '',
  country: '',
  state: '',
  city: '',
  zipCode: '',
  linkedin: '',
  createdAt: '',
  updatedAt: '',
  deletedAt: '',
  isUserKeyMember: false
};

type TCompanySlice = {
  myCompany: TGetCompany;
  myCompanyServices: TGetService[];
  companyProfilePic: string;
  viewCompanyProfilePic: string;
  viewCompany: TGetCompany;
  /*
   ** Search Users
   */
  searchUsers: TMember[];
  searchUsersItemsPerPage: number;
  searchUsersPageNumber: number;
  searchUsersMoreItemsLeft: boolean;
  searchUserLoading: boolean;
  /*
   ** Key members
   */
  keyMembers: TMember[];
  keyMembersItemsPerPage: number;
  keyMembersPageNumber: number;
  keyMembersMoreItemsLeft: boolean;
  keyMembersLoading: boolean;
};

const initialState: TCompanySlice = {
  myCompany: companyInitialState,
  viewCompany: companyInitialState,
  myCompanyServices: [],
  companyProfilePic: '',
  viewCompanyProfilePic: '',
  /*
   ** Search Users
   */
  searchUsers: [],
  searchUsersItemsPerPage: 8,
  searchUsersPageNumber: 1,
  searchUsersMoreItemsLeft: true,
  searchUserLoading: false,
  /*
   ** Key members
   */
  keyMembers: [],
  keyMembersItemsPerPage: 8,
  keyMembersPageNumber: 1,
  keyMembersMoreItemsLeft: true,
  keyMembersLoading: false
};

export const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {
    createCompanySuccess: (state, action: PayloadAction<TGetCompany>) => {
      const company: TGetCompany = action.payload;
      state.myCompany = company;
    },
    getCompanySuccess: (
      state,
      action: PayloadAction<{ company: TGetCompany; profilePic: string }>
    ) => {
      const { company, profilePic }: { company: TGetCompany; profilePic: string } = action.payload;
      state.myCompany = company;
      state.companyProfilePic = profilePic;
    },
    getCompanyError: () => { },
    /*
     ** View company
     */
    viewCompanySuccess: (
      state,
      action: PayloadAction<{ company: TGetCompany; profilePic: string; keyMember: boolean }>
    ) => {
      const {
        company,
        profilePic,
        keyMember
      }: { company: TGetCompany; profilePic: string; keyMember: boolean } = action.payload;
      state.viewCompany = company;
      state.viewCompanyProfilePic = profilePic;
      state.viewCompany.isUserKeyMember = keyMember;
    },
    viewCompanyError: () => { },
    /*
     ** Services
     */
    getMyServicesSuccess: (state, action: PayloadAction<TGetService[]>) => {
      const company: TGetService[] = action.payload;
      state.myCompanyServices = company;
    },
    getMyServicesError: () => { },
    uploadCompanyProfilePictureSuccess: (state, action: PayloadAction<string>) => {
      state.companyProfilePic = action.payload;
    },
    deleteCompanyProfilePictureSuccess: (state) => {
      state.companyProfilePic = '';
    },
    /*
     ** Search Users
     */
    incrementSearchUsersPage: (state, action: PayloadAction<number>) => {
      state.searchUsersPageNumber = state.searchUsersPageNumber + action.payload;
    },
    searchUsersSuccess: (state, action: PayloadAction<any>) => {
      const currentSearchUsers: TMember[] = JSON.parse(JSON.stringify(state.searchUsers));

      const { users, totalPages }: { users: TMember[]; totalPages: number } = action.payload;

      users.forEach((item) => {
        const itemId = item.id;

        const indexInExistingUsers = currentSearchUsers.findIndex((item) => item.id === itemId);

        if (indexInExistingUsers < 0) {
          currentSearchUsers.push(item);
        }
      });

      state.searchUsers = currentSearchUsers;
      state.searchUsersMoreItemsLeft = state.searchUsersPageNumber < totalPages;
    },
    searchUsersError: () => { },
    resetSearchUsers: (state) => {
      state.searchUsers = [];
      state.searchUsersPageNumber = 1;
    },
    searchUserLoading: (state, action: PayloadAction<boolean>) => {
      state.searchUserLoading = action.payload;
    },

    /*
     ** Key members
     */
    incrementKeyMembersPage: (state, action: PayloadAction<number>) => {
      state.keyMembersPageNumber = state.keyMembersPageNumber + action.payload;
    },
    getKeyMembersSuccess: (state, action: PayloadAction<any>) => {
      const currentKeyMembers: TMember[] = JSON.parse(JSON.stringify(state.keyMembers));

      const { users = [], totalPages }: { users: TMember[]; totalPages: number } = action.payload;

      users.forEach((item) => {
        const itemId = item.id;

        const indexInExistingUsers = currentKeyMembers.findIndex((item) => item.id === itemId);

        if (indexInExistingUsers < 0) {
          currentKeyMembers.push(item);
        }
      });

      state.keyMembers = currentKeyMembers;
      state.keyMembersMoreItemsLeft = state.keyMembersPageNumber < totalPages;
    },
    getKeyMembersError: () => { },
    addKeyMembersSuccess: () => { },
    addKeyMembersError: () => { },
    deleteKeyMemberSuccess: (state, action: PayloadAction<string>) => {
      const deleteMemberId = action.payload;

      const currentKeyMembers: TMember[] = JSON.parse(JSON.stringify(state.keyMembers));

      const deletedMemberIndex: number = currentKeyMembers.findIndex(
        (member) => member.id === deleteMemberId
      );

      if (deleteMemberId) {
        currentKeyMembers.splice(deletedMemberIndex, 1);
      }

      state.keyMembers = currentKeyMembers;
    },
    updatekeyMembersLoading: (state, action: PayloadAction<boolean>) => {
      state.keyMembersLoading = action.payload;
    },
    resetKeyMembers: (state) => {
      state.keyMembers = [];
      state.keyMembersPageNumber = 1;
      state.keyMembersMoreItemsLeft = true;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  createCompanySuccess,
  getCompanySuccess,
  getCompanyError,
  viewCompanySuccess,
  uploadCompanyProfilePictureSuccess,
  deleteCompanyProfilePictureSuccess,
  getMyServicesSuccess,
  getMyServicesError,
  incrementSearchUsersPage,
  searchUsersSuccess,
  searchUsersError,
  resetSearchUsers,
  searchUserLoading,
  incrementKeyMembersPage,
  getKeyMembersSuccess,
  getKeyMembersError,
  addKeyMembersSuccess,
  addKeyMembersError,
  deleteKeyMemberSuccess,
  updatekeyMembersLoading,
  resetKeyMembers
} = companySlice.actions;

export default companySlice.reducer;

const errorHandler = (err: AxiosError) => (dispatch: AppDispatch) => {
  const errorResponse = (err as unknown as AxiosError)?.response;
  const message = (errorResponse?.data as { message: string })?.message;
  dispatch(
    openPopup({
      popupMessage:
        errorMessages[message as keyof typeof errorMessages] || errorMessages.unknownError,
      popupType: 'error'
    })
  );
};

export const createCompany = (payload: TCreateCompanyRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status, data }: APIResponse<TCreateCompanyResponse> =
      await new CompanyAPIService().createCompany(payload);
    if (status === 200) {
      const company = data?.data;

      StorageUtils.set('loggedInCompany', company);
      StorageUtils.set('companyId', company?.id);

      dispatch(getCompany({ id: company?.id }));

      dispatch(createCompanySuccess(company));

      dispatch(
        openPopup({
          popupMessage:
            "Congratulations, your company's profile page has been successfully created.",
          popupType: 'success',
          navigateTo: '/my-company'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getCompany = (payload: TGetCompanyRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<TGetCompanyResponse> =
      await new CompanyAPIService().getCompany(payload);
    if (status === 200) {
      dispatch(
        getCompanySuccess({
          company: data?.company,
          profilePic: data?.profilePic
        })
      );
      dispatch(userAuthorizationSuccess());
      dispatch(hidePageLoader());
    }
  } catch (err) {
    if (err?.code === 'ERR_NETWORK') {
      try {
        const { status, data }: APIResponse<TGetCompanyResponse> =
        await new CompanyAPIService().getCompany(payload);
      if (status === 200) {
        dispatch(
          getCompanySuccess({
            company: data?.company,
            profilePic: data?.profilePic
          })
        );
        dispatch(userAuthorizationSuccess());
        dispatch(hidePageLoader());
      }
      } catch (err) { dispatch(userAuthorizationFailure()) }
    }
  } finally {
    dispatch(stopLoading());
  }
};

export const getViewCompany = (payload: TGetCompanyRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<TGetCompanyResponse> =
      await new CompanyAPIService().getCompany(payload);
    if (status === 200) {
      dispatch(
        viewCompanySuccess({
          company: data?.company,
          profilePic: data?.profilePic,
          keyMember: data?.isKeyMember
        })
      );
    }
  } catch (err:any) {
    if (err.code === 'ERR_NETWORK') {
      try {
        const { status, data }: APIResponse<TGetCompanyResponse> =
          await new CompanyAPIService().getCompany(payload);
        if (status === 200) {
          dispatch(
            viewCompanySuccess({
              company: data?.company,
              profilePic: data?.profilePic,
              keyMember: data?.isKeyMember
            })
          );
        }
      } catch (err:any) {
        dispatch(getCompanyError());
      }
    }
  } finally {
    dispatch(stopLoading());
  }
};

export const editCompany = (payload: TEditCompanyRequest) => async (dispatch: AppDispatch) => {
  const { navigateToMyCompany, ...editPayload } = payload;
  dispatch(startLoading());
  try {
    const { status }: APIResponse<TEditCompanyResponse> = await new CompanyAPIService().editCompany(
      editPayload
    );
    if (status === 200) {
      // Refetch company details
      dispatch(getCompany({ id: payload?.id }));
      dispatch(
        openPopup({
          popupMessage: 'You have successfully updated your company profile!',
          popupType: 'success',
          ...(navigateToMyCompany && { navigateTo: '/my-company' })
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const addService = (payload: TAddServiceRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TAddServiceResponse> = await new CompanyAPIService().addService(
      payload
    );
    if (status === 200) {
      dispatch(getServices({ id: payload?.id }));
      dispatch(
        openPopup({
          popupMessage: 'You have successfully add a new service!',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getServices = (payload: TGetServicesRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status, data }: APIResponse<TGetServicesResponse> =
      await new CompanyAPIService().getServices(payload);
    if (status === 200) {
      dispatch(getMyServicesSuccess(data?.data));
    }
  } catch (err) {
    dispatch(getMyServicesError());
  } finally {
    dispatch(stopLoading());
  }
};

export const editService = (payload: TEditServiceRequest) => async (dispatch: AppDispatch) => {
  try {
    dispatch(startLoading());
    const { status }: APIResponse<TEditServiceResponse> = await new CompanyAPIService().editService(
      payload
    );
    if (status === 200) {
      dispatch(getServices({ id: payload?.companyId }));
      dispatch(
        openPopup({
          popupMessage: 'You have successfully updated your services!',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const deleteService = (payload: TDeleteServiceRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status }: APIResponse<TDeleteServiceResponse> =
      await new CompanyAPIService().deleteService(payload);
    if (status === 200) {
      dispatch(getServices({ id: payload?.id }));
      dispatch(resetConfirmModalId());
      dispatch(
        openPopup({
          popupMessage: 'You have successfully deleted this service.',
          popupType: 'success'
        })
      );
    }
  } catch (err) {
    dispatch(getMyServicesError());
  } finally {
    dispatch(stopLoading());
  }
};

export const searchKeyMembers = (payload: TSearchUsersRequest) => async (dispatch: AppDispatch) => {
  dispatch(searchUserLoading(true));
  try {
    const { status, data }: APIResponse<TSearchUsersResponse> =
      await new CompanyAPIService().searchUsers(payload);

    if (status === 200) {
      const { users, pagination } = data?.data;
      dispatch(searchUsersSuccess({ users, totalPages: pagination?.totalPages }));
    }
  } catch (err) {
    dispatch(searchUsersError());
  } finally {
    dispatch(searchUserLoading(false));
  }
};

export const addKeyMembers = (payload: TAddMembersRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status }: APIResponse<TAddMembersResponse> = await new CompanyAPIService().addMembers(
      payload
    );

    if (status === 200) {
      dispatch(resetKeyMembers());
      // dispatch(
      //   openPopup({
      //     popupMessage: 'You have successfully updated your program access!',
      //     popupType: 'success'
      //   })
      // );
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const getKeyMembers = (payload: TGetMembersRequest) => async (dispatch: AppDispatch) => {
  dispatch(updatekeyMembersLoading(true));
  try {
    const { status, data }: APIResponse<TGetMembersResponse> =
      await new CompanyAPIService().getMembers(payload);

    if (status === 200) {
      const { users, pagination } = data?.data;
      dispatch(getKeyMembersSuccess({ users, totalPages: pagination?.totalPages }));
    }
  } catch (err) {
    dispatch(getKeyMembersError());
  } finally {
    dispatch(updatekeyMembersLoading(false));
  }
};

export const deleteKeyMember = (payload: TDeleteMemberRequest) => async (dispatch: AppDispatch) => {
  dispatch(startLoading());
  try {
    const { status }: APIResponse<TDeleteMemberResponse> =
      await new CompanyAPIService().deleteMember(payload);

    if (status === 200) {
      dispatch(deleteKeyMemberSuccess(payload.userId));
    }
  } catch (err) {
    dispatch(errorHandler(err as unknown as AxiosError));
  } finally {
    dispatch(stopLoading());
  }
};

export const uploadCompanyProfilePicture =
  (payload: TUploadCompanyProfilePictureActionRequest) => async (dispatch: AppDispatch) => {
    try {
      dispatch(deleteCompanyProfilePictureSuccess());
      const { status }: APIResponse<TUploadCompanyProfilePictureResponse> =
        await new CompanyAPIService().uploadCompanyProfilePicture({
          presignedUrl: payload.presignedUrl,
          data: payload.data,
          includeAuthorizationHeaders: false
        });
      if (status === 200) {
        dispatch(resetDropdownId());
        dispatch(uploadCompanyProfilePictureSuccess(payload.key));
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    }
  };

export const getCompanyPresignedUrl =
  (payload: TGetCompanyPresignedUrlActionRequest) => async (dispatch: AppDispatch) => {
    dispatch(startLoading());
    try {
      const { status, data }: APIResponse<TGetCompanyPresignedUrlResponse> =
        await new CompanyAPIService().getCompanyPresignedUrl({ id: payload.id });
      if (status === 200 && data?.data) {
        dispatch(
          uploadCompanyProfilePicture({
            presignedUrl: data?.data?.signedUrl,
            key: data?.data?.key,
            data: payload?.data,
            showPopup: payload.showPopup
          })
        );
      }
    } catch (err) {
      dispatch(getKeyMembersError());
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteCompanyProfilePicture =
  (payload: TDeleteCompanyProfilePictureRequest) => async (dispatch: AppDispatch) => {
    try {
      const { status }: APIResponse<TDeleteCompanyProfilePictureResponse> =
        await new CompanyAPIService().deleteProfilePic(payload);
      if (status === 200) {
        dispatch(resetDropdownId());
        dispatch(deleteCompanyProfilePictureSuccess());
      }
    } catch (err) {
      dispatch(errorHandler(err as unknown as AxiosError));
    } finally {
      dispatch(stopLoading());
    }
  };
