import { FC } from 'react';
import {
  // ActionIcon,
  TDropdown,
  ThreeDotsDropdown,
  Tooltip,
  Typography
} from 'src/components/common';
import { colors } from 'src/constants';
import { textEllipsis } from 'src/utils';
import { useToggleManageGroupModalModal } from 'src/components/chat';
import { useAppSelector } from 'src/store/hooks';
import { useGetIdentity } from 'src/hooks';
// import { MicIcon, VideoIcon } from 'src/components/common/common.icons';

import './chatMessagesHeader.scss';
import { TChatMessagesHeaderProps } from './chatMessagesHeader.types';
import useToggleOpenDiscussionMembersModal from './useToggleOpenDiscussionMembersModal';

import ViewDiscussionMembers from '../../viewDiscussionMembers/ViewDiscussionMembers';
import ManageProgramGroupMembers from '../../manageProgramGroupMembers/ManageProgramGroupMembers';

const ChatMessagesHeader: FC<TChatMessagesHeaderProps> = ({
  discussionType,
  headerDropdownOptions,
  groupId,
  companyId,
  programId
}: TChatMessagesHeaderProps) => {
  // Hooks
  const programGroupParticipants = useAppSelector((store) => store.chat.programGroupParticipants);
  const { toggleOpenOpenDiscussionMembersModal, isDiscussionMembersModalOpen } =
    useToggleOpenDiscussionMembersModal();
  const { getIdentities } = useGetIdentity();
  const { toggleManageGroupModalModal, isToggleManageGroupModalModalOpen } =
    useToggleManageGroupModalModal();

  // Constants
  const participants = programGroupParticipants?.[0]?.Users;
  const ownerName = programGroupParticipants?.[0]?.Companies?.[0]?.name;
  const ownerNameWitEllipsis = textEllipsis({ text: ownerName, charLimit: 12 });
  const groupName = programGroupParticipants?.[0]?.groupName;
  const { authenticatedId, loggedInUserType } = getIdentities();

  const dropdownOptionsProps: TDropdown = {
    id: `chat-messages-header-${groupId}`,
    size: 'large',
    alignment: 'center',
    items: headerDropdownOptions
  };

  let membersToRender: string[] = [];
  const remainingMembers: number = participants?.length - 3 > 0 ? participants?.length - 3 : 0;

  try {
    if (participants?.length > 3) {
      for (let i = 0; i < 3; i++) {
        const member = participants?.[i];
        membersToRender.push(member?.name);
      }
    } else {
      membersToRender = participants?.map((member) => member?.name);
    }
  } catch {}

  const openMembersModal = () => {
    if (discussionType === 'programDiscussion') {
      toggleOpenOpenDiscussionMembersModal();
    }
  };

  return (
    <div className="discussion-messages-header">
      <div className="discussion-messages-header__left">
        <Typography
          as="p"
          variant="body-1"
          fontWeight="semiBold"
          style={{ color: colors?.neutral?.[1100] }}
          className="discussion-messages-header__left__heading"
        >
          {discussionType === 'programDiscussion' ? 'Program Discussion' : groupName}
        </Typography>

        <div className="discussion-messages-header__left__members">
          {ownerName && (
            <Tooltip
              key={'owner'}
              text={ownerName}
              conditionToDisplay={ownerNameWitEllipsis?.hasEllipsis}
              limitWidth
            >
              <Typography
                as="p"
                variant="caption"
                fontWeight="regular"
                style={{ color: colors?.neutral?.[1100] }}
              >
                {ownerNameWitEllipsis?.text}&nbsp;(Owner),&nbsp;
              </Typography>
            </Tooltip>
          )}
          {membersToRender &&
            membersToRender?.length > 0 &&
            membersToRender.map((member, index) => {
              const nameWitEllipsis = textEllipsis({ text: member, charLimit: 12 });
              return (
                <Tooltip
                  key={index}
                  text={member}
                  conditionToDisplay={nameWitEllipsis?.hasEllipsis}
                  limitWidth
                >
                  <Typography
                    as="p"
                    variant="caption"
                    fontWeight="regular"
                    style={{ color: colors?.neutral?.[1100] }}
                  >
                    {nameWitEllipsis?.text},&nbsp;
                  </Typography>
                </Tooltip>
              );
            })}
          {remainingMembers > 0 && (
            <Typography
              as="p"
              variant="caption"
              fontWeight="semiBold"
              style={{ color: colors?.neutral?.[1100] }}
              className="discussion-messages-header__left__members__more-users"
              onClick={openMembersModal}
            >
              +{remainingMembers}&nbsp;more users
            </Typography>
          )}
        </div>
      </div>
      <div className="discussion-messages-header__right">
        {/* <ActionIcon>
          <MicIcon />
        </ActionIcon>
        <ActionIcon>
          <VideoIcon />
        </ActionIcon> */}
        {authenticatedId === companyId && (
          <ThreeDotsDropdown dropdownOptions={dropdownOptionsProps} />
        )}
      </div>
      {isDiscussionMembersModalOpen && (
        <ViewDiscussionMembers
          groupId={groupId}
          isModalOpen={isDiscussionMembersModalOpen}
          onModalClose={toggleOpenOpenDiscussionMembersModal}
          programGroupParticipants={programGroupParticipants?.[0]?.Users || []}
        />
      )}

      {isToggleManageGroupModalModalOpen && (
        <ManageProgramGroupMembers
          userId={authenticatedId}
          groupName={groupName}
          userType={loggedInUserType}
          programId={programId}
          groupId={groupId}
          isModalOpen={isToggleManageGroupModalModalOpen}
          onModalClose={toggleManageGroupModalModal}
          groupMembers={programGroupParticipants}
        />
      )}
    </div>
  );
};

ChatMessagesHeader.defaultProps = { headerDropdownOptions: [], members: [] };

export default ChatMessagesHeader;
