import InputField from 'src/components/common/formFields/inputField/InputField';
import { componentSizeVariantsEnum } from 'src/constants';
import Label from 'src/components/common/formFields/label/Label';
import { SingleChevronIcon } from 'src/components/common/common.icons';

import './previewField.scss';
import { TPreviewOptionsFieldProps } from './previewField.types';

const OptionsField = ({ id, label, options, type, required }: TPreviewOptionsFieldProps) => {
  return (
    <div key={id} className="dynamic-field">
      <div className="dynamic-field__field">
        {' '}
        <Label required={required}>{label}</Label>
        {options && options?.length > 0 && type === 'multipleChoice' && (
          <div className="dynamic-field__field__dropdown-options">
            {options.map((option, index) => {
              return (
                <div className="dynamic-field__field__dropdown-options__checkbox" key={index}>
                  <input type="checkbox" disabled checked={index === 0} />
                  <Label>{option}</Label>
                </div>
              );
            })}
          </div>
        )}
        {options && options?.length > 0 && type === 'singleChoice' && (
          <div className="dynamic-field__field__dropdown-options">
            {options.map((option, index) => {
              return (
                <div className="dynamic-field__field__dropdown-options__radio" key={index}>
                  <input type="radio" disabled checked={index === 0} />
                  <Label>{option}</Label>
                </div>
              );
            })}
          </div>
        )}
        {options && options?.length > 0 && type === 'dropdown' && (
          <div className="dynamic-field__field__dropdown-options">
            <InputField
              id={id}
              variant={componentSizeVariantsEnum.SMALL}
              placeholder="Select from below"
              disabled
              type="text"
              endIcon={<SingleChevronIcon direction="down" />}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default OptionsField;
